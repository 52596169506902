<template>
        <span>
         
       <v-card :id="section.Name.split(' ').join('')" class="transparent" flat height="100%"  shaped>
            <v-card shaped dark flat width="90%" class="mx-4 secondary text-xs-center elevatedsectionheader"  elevation="6" :style="{ backgroundImage: 'url(' + require('@/assets/RASectionBG.jpeg') + ')',backgroundSize: 'cover' }">    
              <v-card-title class="align-center">
                {{section.Name}}<v-spacer></v-spacer>
                <v-btn outlined fab small class="elevation-6 mx-1" dark  v-if="CanEdit"  @click="UpdateEditing()">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-subtitle >
               Edit
              </v-card-subtitle>             
            </v-card>

              <v-card class="dbcard" height="100%" shaped >
                <v-card flat height="60" class="overlayed transparent">
                  </v-card>
                <v-card-subtitle class="mx-2 my-2">
                  <v-layout row>
                    <h3 class="primary--text">Activities</h3>
                  </v-layout>
                  </v-card-subtitle>
                  <!-- <v-card-text>
                    <AllMyTasksandAppointments :SystemEntities="SystemEntities" @RemoveActivity="RemoveActivity" :ActivityType="ComputedActivityType" :UserRecord="userLoggedIn" :AllEvents="AppointmentsArray" :ActivitiesArray="ActivitiesArray"
  />
                  </v-card-text> -->
                  <v-expansion-panels class="activitytabs">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Tasks</v-expansion-panel-header>
                    <v-expansion-panel-content>
                       <RecordTasks :TasksQuery="TasksQuery" :SystemEntities="SystemEntities" :Business_Unit="Business_Unit" :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RegardingTypefilter="RegardingTypefilter" :ActivityTypefilter="ActivityTypefilter" :RelatedObj="RelatedObj" :RecordPrimaryField="Record[PrimaryFieldName]" :ActivitiesArray="ActivitiesArray" />
                    </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Appointments</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <Appointments :RelatedObj="RelatedObj" :SystemEntities="SystemEntities" :UserRecord="userLoggedIn" :UsersArray="UsersArray" :AppointmentsQuery="AppointmentsQuery" :Business_Unit="Business_Unit" :datasetsecuritystructure="'Business Unit'"/>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Emails</v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <RecordEmails :EmailsQuery="EmailsQuery" :Business_Unit="Business_Unit" :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RegardingTypefilter="RegardingTypefilter" :ActivityTypefilter="ActivityTypefilter" :RelatedObj="RelatedObj" :RecordPrimaryField="Record[PrimaryFieldName]" :ActivitiesArray="ActivitiesArray" />
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Phone Calls</v-expansion-panel-header>
                        <v-expansion-panel-content>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>
                      <v-expansion-panel-header class="activitytabsheaders">Notes</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                              <v-btn
                                  fab
                                  color="primary"
                                  right
                                  absolute
                                  small
                                  @click="AddNote()"
                                ><v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                            <v-list-item v-for="note in NotesArray" :key="note.itemObjKey" class="notes">
                              <v-list-item-content>
                                {{note.Title}}
                              </v-list-item-content>
                              <v-list-item-content>
                                {{note.Owner.v}}
                              </v-list-item-content>
                              <v-list-item-content>
                                <v-menu offset-y :close-on-content-click="false" bottom left>
                                  <template v-slot:activator="{ on }">
                                      <v-icon
                                      color="blue"
                                      v-on="on" small>
                                      mdi-eye
                                    </v-icon>
                                  </template>
                                  <v-list min-width=300px max-width=500px :color="note.Color" :dark="note.Dark">
                                    <v-menu offset-y :close-on-content-click="false" bottom left>
                                        <template v-slot:activator="{ on }">
                                             <v-btn
                                              fab
                                              right
                                              absolute
                                              small

                                                ><v-icon
                                                color="blue"
                                                v-on="on" small>
                                                mdi-pencil
                                              </v-icon>
                                             </v-btn>
                                          </template>
                                            <v-list min-width=300px max-width=500px :color="note.Color" :dark="note.Dark">
                                              <v-icon
                                                  @click="ToggleNoteDark(note)"
                                                  color="blue"
                                                  small>
                                                  mdi-invert-colors
                                                </v-icon>
                                              <v-menu offset-y :close-on-content-click="false" bottom left>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                      fab
                                                      right
                                                      absolute
                                                      small

                                                        ><v-icon
                                                        color="blue"
                                                        v-on="on" small>
                                                        mdi-palette
                                                      </v-icon>
                                                    </v-btn>
                                                  </template>
                                              <input type="color" id="notebgcolor" v-model="note.Color" @change="LightenColor(note)" />
                                              </v-menu>


                                              <body :bgcolor="note.TitleColor"><h4><v-text-field v-model="note.Title"></v-text-field></h4><br></body>
                                              <v-list-item>
                                              <v-list-item-content>
                                                <v-textarea v-model="note.Description"></v-textarea>
                                              </v-list-item-content>
                                              </v-list-item>
                                               <v-btn @click="UpdateNotesArray()" v-model="note.Description">Save </v-btn>
                                            </v-list>
                                        </v-menu>
                                    <body :bgcolor="note.TitleColor"><h4>{{note.Title}}</h4><br></body>

                                    <v-list-item>
                                      <v-list-item-content>
                                              {{note.Description}}
                                      </v-list-item-content>
                                  </v-list-item>
                                </v-list>
                                  </v-menu>
                              </v-list-item-content>

                            </v-list-item>
                          </v-list>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                  </v-expansion-panels>
                </v-card>
            </v-card>
        </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import Appointments from '@/components/SuitePlugins/Activities/Appointments'
import RecordTasks from '@/components/SuitePlugins/Activities/RecordTasks'
import RecordEmails from '@/components/SuitePlugins/Activities/RecordEmails'
import AllMyTasksandAppointments from '@/views/Activities/MyProfile/AllMyTasksandAppointments'
export default {
    props: ['tab','section','Fields','AppisDarkMode','CanEdit','Record','Cols','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','TasksQuery','EmailsQuery','NotesQuery','PhoneCallsQuery','ActivitiesArray','SingleFormEditing','SystemEntities'],
    components: {
        Appointments,
        RecordTasks,
        RecordEmails,
        AllMyTasksandAppointments
    },
    data(){
        return{
            MutatedRecord: '',
            Editing: false,
            ActivityType: '',
        }
    },
    computed:{
      ComputedActivityType(){
        if(this.ActivityType){
          return this.ActivityType
        }
        else{
          return {
            countname: 'None',
            tasks: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Task' && activity.Ownerid === this.ActiveUser.id}),
            apointments: this.MyActivitiesArray.filter(activity => {return activity.Type.Name === 'Appointment' && activity.Ownerid === this.ActiveUser.id}),
          }
        }
      },
        UsersArray(){
      return this.$store.state.UsersArray
    },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userBU () {
      return this.$store.state.buobj
    },
        HeaderDarkLightStyle(){
            if(this.AppisDarkMode){
                return 'white--text font-weight-light'
            }
            else{
                return 'primary--text font-weight-light'
            }
            },
    },
    created(){
        //alert('yayayayaya')
        this.MutatedRecord = Object.assign({},this.Record)
        console.log('this.AppointmentsQuery',this.AppointmentsQuery)
    },
    methods:{
      UpdateEditing(){
          if(!this.SingleFormEditing){
            this.Editing = !this.Editing
          }
          else{
            this.$emit('OpenDialog',this.section,this.sectionindex,true)
          }
            
            //console.log(this.Record,this.MutatedRecord)
            //this.$emit('UpdateEditableField',this.FieldName,FieldValue,this.AdditionalSaveMethod,this.MutatedRecord)
        },
    }
}
</script>

<style>

</style>