<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      <v-dialog v-model="StatusChangeDialog"
            max-width="400">
            <v-card width="400">
                <v-card-title class="recordtoolbar white--text mediumoverline">Change Status</v-card-title>
                <v-card-text>
                    <v-autocomplete class="mx-5"
                :items="ActiveSession.StatusOptions"
                v-model="Status"
                item-text="Name"
                label="Status"
                autowidth
                return-object
                chips
                flat
                />
                <v-autocomplete class="mx-5"
                    v-if="Status"
                :items="Status.Options"
                v-model="Status_Reason"
                item-text="Name"
                label="Status_Reason"
                autowidth
                return-object
                chips
                flat
                />

                </v-card-text>
                <v-card-actions>
                <v-btn dark color="warning" small @click="ResetStatus(StatusChangeRecord)">Cancel</v-btn>
                <v-spacer></v-spacer>
                <v-btn dark color="success" small @click="UpdateStatus(StatusChangeRecord)">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      <!-- ActiveSession {{ActiveSession}} -->
      <v-navigation-drawer  v-if="RecordActivitiesDialog" v-model="RecordActivitiesDialog" app right width="400">
      <v-card-title class="recordtoolbar white--text justify-space-between">
          Activities
          <v-icon dark @click="ToggleRecordActivitiesDialog()" class="red soloactionicon">mdi-close</v-icon>
      </v-card-title>
      <v-card-subtitle class="overline recordtoolbar white--text justify-space-between">
          {{RelatedObj[ActiveSession.RecordPrimaryField.Name]}}
      </v-card-subtitle>
      <v-tabs>
          <v-tab>Tasks</v-tab>
          <v-tab-item>
              <v-card flat tile>
                  <v-card-text>
                     <RecordTasks :TasksQuery="TasksQuery" :SystemEntities="SystemEntities" :Business_Unit="Business_Unit"
                     :UserRecord="userLoggedIn" :UsersArray="UsersArray" :RegardingTypefilter="RegardingTypefilter" :ActivityTypefilter="ActivityTypefilter"
                     :RelatedObj="RelatedObj" :RecordPrimaryField="ActiveSession.RecordPrimaryField.Name" :ActivitiesArray="ActivitiesArray" />
                  </v-card-text>
              </v-card>
          </v-tab-item>
          <v-tab>Appointments</v-tab>
          <v-tab-item>
              <v-card flat tile>
                  <v-card-text>
                      <RecordAppointments :RelatedObj="RelatedObj" :SystemEntities="SystemEntities" :UserRecord="userLoggedIn"
                      :UsersArray="UsersArray" :AppointmentsQuery="AppointmentsQuery" :Business_Unit="Business_Unit" :datasetsecuritystructure="ActiveSession.datasetsecuritystructure"
                      />
                       </v-card-text>
              </v-card>
          </v-tab-item>
      </v-tabs>
      </v-navigation-drawer>
       <v-dialog max-width="400" persistent v-model="AutomationDialog">	
           <AutomationDialogComponent v-if="AutomationDialog" @ToggleAutomationDialog="ToggleAutomationDialog"
           @CheckifClassifiedEntityandStatusUpdate="CheckifClassifiedEntityandStatusUpdate"
        :System="System" :SystemEntities="SystemEntities" :ActiveSession="ActiveSession" :record="AutomationRecord"
        />
        </v-dialog>	
	 <v-dialog v-model="RecordProcessStageDialog" persistent max-width="400">
            <v-card max-width="400">
              <v-card-title class="recordtoolbar white--text    title font-weight-regular justify-space-between">
                  {{TimelineRecord[ActiveSession.RecordPrimaryField.Name]}}                  
              <v-icon class="soloactionicon" @click="CancelRecordProcessStageDialog()" color="red">mdi-close</v-icon>
              </v-card-title>
                <v-card-text>
                <v-select return-object v-model="TimelineRecord.ActiveProcess" item-text="DisplayName" :items="ActiveSession.EntityProcesses" label="Process"></v-select>
                <v-select v-if="TimelineRecord.ActiveProcess" return-object v-model="TimelineRecord.stagename" item-text="Name" :items="TimelineRecord.ActiveProcess.ProcessStages" label="Stage"></v-select>
                </v-card-text>
              <v-card-actions>
                  <v-btn dark @click="UpdateRecordStage(TimelineRecord)" class="succes">
                      Save</v-btn>
              </v-card-actions>
            </v-card>
        </v-dialog>

      <v-dialog v-model="AssignRecordDialog" persistent max-width="400">
          <v-card max-width="400">
              
              <v-card-title class="title recordtoolbar white--text font-weight-regular justify-space-between">
                  
                    <span><v-avatar
                        color="white"
                        class="subheading recordtoolbar--text"
                        size="24"
                        v-text="unassignedstep"
                    >                    
                    </v-avatar> {{ UnassignedWindowcurrentTitle }}</span>
                    <v-icon class="soloactionicon" @click="CancelAssignRecord()" color="red">mdi-close</v-icon>
                    </v-card-title>
              <v-window class="background" v-model="unassignedstep">
                    <v-window-item :value="1">
                     
                         <v-card-text v-if="EntityHasWebForm && !AssigningRecord.EmailBody" v-html="Step1Body">
                  
                            </v-card-text>
                            <v-card-text v-if="EntityHasWebForm && AssigningRecord.EmailBody" v-html="AssigningRecord.EmailBody">
                  
                            </v-card-text>
                    </v-window-item>

                    <v-window-item :value="2">
                        <v-card-text>
                               <v-text-field v-if="!AssigningRecord[ActiveSession.RecordPrimaryField.Name]" v-model="AssigningRecordPrimaryValue"
                               :label="ActiveSession.RecordPrimaryField.DisplayName"/>
                                <UserLookup :UsersLookupArray="PotentialOwnerUsersArray" :ModelProp="'Owner'" :RecordObj="AssigningRecord" @UpdateUserLookupProp="UpdateUserLookupProp"
                                :FieldLabel="'Owner'" :ReadOnly="false" :Rules="[]"
                                />
                        
                            </v-card-text>
                    </v-window-item>

                    <v-window-item :value="3">
                        <v-card-text>
                           <v-select return-object v-model="ActiveProcess" item-text="DisplayName" :items="ActiveSession.EntityProcesses" label="Process"></v-select>
                            <v-select v-if="ActiveProcess" return-object v-model="ActiveStage" item-text="Name" :items="ActiveProcess.ProcessStages" label="Stage"></v-select>
                        </v-card-text>
                    </v-window-item>
                    <v-window-item :value="4">
                        <v-card-text>
                            This will now update the Record, Click "{{UnAssignedButton}}" below to confirm
                        </v-card-text>
                    </v-window-item>
                    </v-window>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-btn
                        :disabled="unassignedstep === 1"
                        text
                        @click="unassignedstep--"
                    >
                        Back
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        dark
                        :color="UnAssignedButtonColor"
                        depressed
                        @click="AdvanceUnAssignedRecord()"
                    >
                        {{UnAssignedButton}}
                    </v-btn>
                    </v-card-actions>
          </v-card>
      </v-dialog>

      <!-- <v-tabs centered>
          <v-tab>
              Unassigned
          </v-tab>
          <v-tab-item> -->
              <v-list
                v-if="!ActiveRecord"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-alert-rhombus-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">Unassigned</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item :class="AppisDarkMode ? 'darkdetailslistoutline '+record.class : 'detailslistoutline '+record.class" v-for="record in UnassignedArray" :key="record.itemObjKey"  @click="AssignItem(record)">
                    <v-list-item-actions>       
                        <v-badge
                            avatar
                            bordered
                            overlap v-if="record.Created_By"
                        >
                            <template v-slot:badge>
                            <v-avatar>
                                <v-icon small>{{ActiveSession.icon}}</v-icon>
                            </v-avatar>
                            </template>

                            <v-avatar size="40" v-if="record.Created_By.Profile_Photo">
                              <img :src="record.Created_By.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!record.Created_By.Profile_Photo">                               
                                {{record.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{record.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                        </v-badge>  
                        <v-badge
                            avatar
                            bordered
                            overlap v-if="!record.Created_By"
                        >
                            <template v-slot:badge>
                            <v-avatar>
                                <v-icon small>{{ActiveSession.icon}}</v-icon>
                            </v-avatar>
                            </template>
                            <v-avatar color="grey" class="white--text ml-0" size="40" >                               
                              <v-icon>mdi-account-question</v-icon>
                            </v-avatar>
                        </v-badge>  
                    </v-list-item-actions>
                    <v-list-item-content style="padding-left:4px;">
                        <v-list-item-subtitle style="font-size:10px;color: grey;" v-if="record.Created_On && record.Created_On.toDate">
                            {{record.Created_On.toDate()}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle style="font-size:10px;color: grey;" v-else-if="record.Created_On && record.Created_On.seconds">
                            {{new Date(record.Created_On.seconds*1000)}}
                        </v-list-item-subtitle>
                        <v-list-item-title :class="record.class">{{ record[ActiveSession.RecordPrimaryField.Name] }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
              </v-list>
              <v-list
                v-if="!ActiveRecord"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-pipe</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">Processes</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item v-for="process in ProcessesArray" :key="process.itemObjKey" :class="process.class"  @click="PushItem(process)">
                    <v-list-item-avatar class="mx-2">
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="process.headerclass">{{ process.Name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-content>
                       <v-list-item-title > Records</v-list-item-title>
                        <v-list-item-subtitle >{{process.ActiveData.length}}</v-list-item-subtitle>
                    </v-list-item-content>
                    <!-- <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action> -->
                </v-list-item>
            </v-list>
              </v-list>
          <!-- </v-tab-item>
          <v-tab>
              Pipeline
          </v-tab>
          <v-tab-item> -->
            
              <v-card-title v-if="ActiveRecord">                  
        <v-btn  @click="ActiveRecord=''" small><v-icon small>mdi-chevron-left</v-icon>Back</v-btn>
        </v-card-title>
        <v-tabs centered v-if="ActiveRecord" v-model="Tabs">
            <v-tab>Chart
            </v-tab>
            <v-tab-item>
                <DashboardBuilder :UsersArray="UsersArray" :SystemEntities="SystemEntities" :PushedElmnt="ActiveRecord"/>
            </v-tab-item>
            <v-tab>Actions
            </v-tab>

            <v-tab-item v-if="StatusFilter.ID === 1000001">
             
                <v-list
                v-for="(stage,stageindex) in ActiveRecord.ProcessStages" :key="stage.itemObjKey"
                :dark="AppisDarkMode"
                dense                
                nav
                >                
                <v-list-item class="overline">
                <v-list-item-icon>
                    <v-icon :color="ThemeColor">mdi-pipe</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="ThemeColor+'--text'">{{stage.Name}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-divider/>
              <v-list  :dark="AppisDarkMode"  >
                <v-list-item :class="AppisDarkMode ? 'darkdetailslistoutline '+record.class : 'detailslistoutline '+record.class" v-for="record in GetMatchingStatus(stage)" :key="record.itemObjKey">
                    <v-list-item-avatar v-if="$route.name !== 'GroupDashboard'" class="mx-2">
                    <v-icon small>{{ActiveSession.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-avatar  v-if="$route.name === 'GroupDashboard'" class="mx-2">
                        <img v-if="record.Owner.Profile_Photo" :src="record.Owner.Profile_Photo" style="object-fit: cover;" >
                        <img v-if="!record.Owner.Profile_Photo" src="@/assets/BlankProfilePic.png"  style="object-fit: cover;" >
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title :class="record.headerclass">{{ record[ActiveSession.RecordPrimaryField.Name] }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="ActiveSession.HasActivities && record.CanGet || ActiveSession.HasActivities && record.CanView">
                        <v-btn @click="ToggleRecordActivitiesDialog(record)" color="primary lighten-2"><v-icon :color="ThemeHeaderColor">mdi-clipboard-list</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="ActiveSession.IsClassifiedListing && record.Combined_Title && !UserisClassifiedsModerator">
                        <v-btn color="success" :to="ActiveSession.ClassifiedListingPath+'/'+record.Combined_Title" target="_blank"><v-icon :color="ThemeHeaderColor">mdi-star-check</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="ActiveSession.IsClassifiedListing && record.Combined_Title && UserisClassifiedsModerator">
                        <v-btn color="success" :to="ActiveSession.ClassifiedListingEditPath+'/'+record.id" target="_blank"><v-icon :color="ThemeHeaderColor">mdi-star-check</v-icon></v-btn>
                    </v-list-item-action>
                     <v-list-item-action v-if="ActiveSession.IsClassifiedListing && !record.Combined_Title">
                        <v-btn color="pop" :to="'/'+ActiveSession.SingleName.split(' ').join('')+'/'+record.id" target="_blank"><v-icon :color="ThemeHeaderColor">mdi-star-off</v-icon></v-btn>
                    </v-list-item-action>
                   <v-list-item-action>
                         <RecordLiaison :RecordData="record" :ActiveSession="ActiveSession"
                                :OperationalDB="OperationalDB(record)" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                                @ActivateProcessing="ActivateProcessing"
                                :System="System" :SystemEntities="SystemEntities" :CanEdit="record.CanEdit" :ActiveRecord="ActiveRecord"
                                />                        
                    </v-list-item-action>
                     <v-list-item-action v-if="record.CanEdit && !HasProcedure(record,stageindex)">
                        <v-btn color="pink" v-on="on"><v-icon :color="ThemeHeaderColor" @click="ToggleRecordProcessStage(record)">mdi-pipe</v-icon></v-btn>
                    </v-list-item-action>
                    <v-list-item-action v-if="record.CanEdit && HasProcedure(record,stageindex)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn elevation="0" v-bind="attrs" style="rgba(0, 0, 0, 0.26) !important;background-color: rgba(0, 0, 0, 0.12) !important"
                             v-on="on"><v-icon :color="ThemeHeaderColor">mdi-pipe</v-icon></v-btn>
                          </template>
                          The Record has an Active Procedure, and cannot be progressed from Pipeline view
                      </v-tooltip>
                    </v-list-item-action>
                    <v-list-item-action v-if="record.CanEdit">
                        <v-btn color="warning" @click="ActivateRecordStatusChangeDialog(record)"><v-icon :color="ThemeHeaderColor">mdi-power</v-icon></v-btn>
                    </v-list-item-action>

                    <v-list-item-action v-if="record.CanEdit && record.AutomationButtons && record.AutomationButtons.length > 0 || userIsAdmin && record.AutomationButtons && record.AutomationButtons.length > 0">                        
                        <v-btn :color="ThemeColor" @click="ToggleAutomationDialog(record)"><v-icon :color="ThemeHeaderColor">mdi-auto-fix</v-icon></v-btn>                   
                    </v-list-item-action>
                    <!-- <v-list-item-action v-if="record.NotificationCount && record.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="record.NotificationCount && record.NotificationCount>0" overlap>
                            <span slot="badge"> {{record.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="record.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action> -->
                </v-list-item>
            </v-list>
                </v-list>
            </v-tab-item>
        </v-tabs>
              
          <!-- </v-tab-item>
      </v-tabs> -->
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
// import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'
import RecordAppointments from '@/components/SuitePlugins/Activities/Appointments'
import RecordTasks from '@/components/SuitePlugins/Activities/RecordTasks'
import UserLookup from '@/components/General/UserLookup'
import AutomationDialogComponent from '@/components/SuitePlugins/Automations/AutomationDialogComponent'
import RecordLiaison from '@/components/General/RecordLiaison';

export default {
    // UsersArray
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem','ThemeHeaderColor','SystemEntities','NotificationObj','SiteModerators','Notifications','GroupData'],
    components: {
        DashboardBuilder,
        WebFormTab,
        DigitizedFormComponent,
        RecordAppointments,
        RecordTasks,
        UserLookup,
        AutomationDialogComponent,
        RecordLiaison
    },
    data(){
        return{
            GroupDataEntries: [],
            RecordActivitiesDialog: false,
            ActiveAction: '',
            Autobutton: '',
            ProcessRecord: '',
            StatusFilter: {ID:1000001},
            Status: '',
            Status_Reason: '',
            StatusChangeRecord: '',
            StatusChangeDialog: false,
            Galleries: [],
            DocumentRegisters: [],
            TimelineRecord: '',
            Tabs: 1,
            NewLink: '',
            slice: 4,
            DocumentTable: '',
            DocumentType: '',            
            UploadingFileProgress: 0,
            LiaisonUploadFile: '',
            OpenFormComponent: false,
            ActiveForm: '',
            ActiveComment: '',
            AutomationRecord: {},
            AutomationDialog: false,
            FormProcessing: false,
            AutomationCreatedSnackbar: false,
            AutomationSnackbarDetails: '',
            rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
            AutomationButtons: [],
            ActiveRecord: '',
            unassignedstep: 1,
            automationstep: 1,
            ActiveProcess: '',
            ActiveStage: '',
            AssigningRecord: '',
            AssigningRecordPrimaryValue: '',
            AssignRecordDialog: false,
            RecordProcessStageDialog: false,
            DataArray: [],
            items: [
                {
                    icon: 'mdi-alert-rhombus-outline',
                    title: 'Unassigned'
                },
                {
                    icon: '{{ActiveSession.icon}}',
                    title: 'Assigned'
                },
            ],
        }
    },
    watch: {
        ActiveSession: {
            handler: function(oldvalue, newvalue) {
                console.log('here is the thing the sessino changed')
                if(oldvalue !== newvalue){
                    this.DataArray = []
                    this.CallTableData()   
                   this.ActiveRecord = '' 
                   if(this.ActiveSession.automations){
                        this.PrepareAutomations()
                    }
                }
            },
            deep: true
        },
        StoreDataArray: {
            handler: function(newvalue, oldvalue) {
                //swopped old and newval old code reversed
                
                if(oldvalue !== newvalue){
                    console.log(oldvalue, newvalue)
                    console.log('oh yeah babay')
                }
                // if(oldvalue !== newvalue && this.ActiveSession.datasetsecuritystructure || oldvalue !== newvalue && this.ActiveSession.Security_Type){
                //     let tablesecuritystructure = this.ActiveSession.datasetsecuritystructure
                //         if(!tablesecuritystructure){
                //             tablesecuritystructure = this.ActiveSession.Security_Type.Name
                //         }
                //         if(tablesecuritystructure === 'Custom Roles'){
                //             this.DataArray = this.GetCustomRolesTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
                //         }
                //         else if(tablesecuritystructure === 'Business Unit'){
                //             this.DataArray = this.GetBUTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
                //         }
                //         else if(datasetsecuritystructure === 'Group Member' && this.ActiveGroup){
                //             let memberobj = this.userLoggedIn.groups.find(obj => obj.id === this.ActiveGroup.id)
                //             this.DataArray = this.DataArray.map(data => {
                //                 data.CanCreate = memberobj.Administrator || memberobj[this.ActiveSession.id+'Moderator']
                //                 data.CanView = memberobj.Administrator || memberobj[this.ActiveSession.id+'Moderator']
                //                 data.CanEdit = memberobj.Administrator || memberobj[this.ActiveSession.id+'Moderator']
                //                 data.CanDelete = memberobj.Administrator
                //                 return data
                //             })
                //         } 
                // }
            },
            deep: true
        },
        NotificationObj (value) {
            if (value) {
                this.CheckNotificationObj()
                // let Processes = this.ActiveSession.EntityProcesses.map(process => {
                //         console.log(process)
                //         let filteredrecords = process.Data.filter(record => {
                //             record.id === value.ActiveRecord
                //         })
                //         console.log(filteredrecords)
                //         if(filteredrecords.length > 0){
                //             process.Active = true
                //         }
                //         return process
                //     })
                //     console.log(Processes)
                //     let activeprocess = Processes.filter(process => {return process.Active})
                //     console.log(activeprocess)
                //     this.ActiveRecord = activeprocess[0]
            }
        },
        
    },
    computed:{
        ActiveGroup(){
            return this.$store.state.ActiveGroup
        },
        GroupMemberObj(){
            return this.ActiveGroup && this.ActiveGroup.id? this.userLoggedIn.groups.find(obj => obj.id === this.ActiveGroup.id) : ''
        },
        GroupAdmin(){
            return this.ActiveGroup && this.ActiveGroup.id? this.GroupMemberObj.Administrator : ''
        },
        TablePath(){
            return this.ActiveGroup.id ? '' : ''
        },
        TableDB(){
            return this.ActiveGroup ? db.collection('groups').doc(this.ActiveGroup.id).collection('entities').doc(this.ActiveSession.id).collection('datarecords') : db.collection(this.ActiveSession.id.split('_').join('').toLowerCase())
        },
        ActiveRecordProcessStages(){
            return this.ActiveRecord.ProcessStages.map(stage => {
                stage.Records = this.ComputedRecordData
                .filter(record => {
                    return record.stagenameid === stage.Number && record.Status && record.Status.ID === 1000001
                })
                return stage
            })
        },
        StoreArrayName(){
            return this.ActiveSession.topicid.split('_').join('')+'Array'
        },
        StoreDataArray(){
            // let storedata = this.$store.state[this.StoreArrayName]
            // console.log('storedata',this.StoreArrayName,storedata)
            return this.$store.state[this.StoreArrayName] ? this.$store.state[this.StoreArrayName] : []
        },
        // DataArray(){
        //     let tablesecuritystructure = this.ActiveSession.datasetsecuritystructure
        //     if(tablesecuritystructure === 'Custom Roles'){
        //         return this.GetCustomRolesTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
        //     }
        //     else if(tablesecuritystructure === 'Business Unit'){
        //         return this.GetBUTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
        //     }
        // },
        UnreadNotifications(){
            return this.Notifications.filter(not => {
                return !not.Read
            })
        },
        PotentialOwnerUsersArray(){
            let entityprop = this.ActiveSession.DisplayName.split(' ').join('')
            return this.UsersArray
            .filter(user => {

                 let datasetsecuritystructure = this.ActiveSession.datasetsecuritystructure
                //console.log('PotentialOwnerUsersArray ',datasetsecuritystructure,entityprop+'editUnitDown',this.$store.state[entityprop+'editUnitDown'],this.$store.state)
                if(datasetsecuritystructure === 'Business Unit'){
                    return this.$store.state[entityprop+'editAll'] || 
                        this.$store.state[entityprop+'editUnitdown'] && this.userBUChildren.includes(user.Business_Unitid) || 
                        this.$store.state[entityprop+'editUserUnit'] && this.userBUID === user.Business_Unitid || 
                        this.$store.state[entityprop+'editOwner'] && this.userLoggedIn.id === user.id
                }
                else if(datasetsecuritystructure === 'Custom Roles'){
                    return this.$store.state[entityprop+'CanCreate']
                }
                else if(datasetsecuritystructure === 'Group Member'){
                        return this.GroupData.TeamMembers.includes(user.id) || this.GroupData.Administrators.includes(user.id)
                    }   
                
            })
        },
        UserisTimelineRecordFollower(){
            return this.TimelineRecord && this.TimelineRecord.LiaisonFollowingUsers && this.TimelineRecord.LiaisonFollowingUsers.includes(this.userLoggedIn.id)
        },
        TasksQuery(){
            return this.TimelineRecord ?
            db.collection('activities').where('Type.Name','==','Task').where('Regarding_Type', '==', this.ActiveSession.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.TimelineRecord.id)
            : ''
        },
        AppointmentsQuery(){
            return this.TimelineRecord ?
            db.collection('activities').where('Type.Name','==','Appointment').where('Regarding_Type', '==', this.ActiveSession.SingleName.split(' ').join('')).where('Regarding_Record.id', '==', this.TimelineRecord.id)
            : ''
        },
        Business_Unit(){
            return this.TimelineRecord ? this.TimelineRecord.Business_Unit : ''
        },
        RelatedObj(){
            return this.TimelineRecord && this.RecordActivitiesDialog ? {id: this.TimelineRecord.id, [this.ActiveSession.RecordPrimaryField.Name]: this.TimelineRecord[this.ActiveSession.RecordPrimaryField.Name],identifier: this.ActiveSession.RecordPrimaryField.Name,collection: this.ActiveSession.DisplayName.split(' ').join('').toLowerCase(),Link: this.ActiveSession.SingleName.split(' ').join('') }
            : ''
        },
        UserisClassifiedsModerator(){
          return this.UserModerations ? this.UserModerations.find(obj => obj === 'Classifieds') : this.userIsAdmin 
        },
        UserSiteModerator(){
            return this.SiteModerators ? this.SiteModerators.find(obj => obj.id === this.userLoggedIn.id) || this.userIsAdmin : ''
        },
        UserModerations(){
            return this.SiteModerators ? this.SiteModerators.find(obj => obj.id === this.userLoggedIn.id).ModerationPermissions : ''
        },     
        UsersArray(){
            //console.log(this.$store.state)
            return this.$store.state.Users
        },
        
                
        InactiveDataArray(){
            return this.StoreDataArray.filter(record => {
                return record.Status && record.Status.ID !== 1000001
            })
        },
        DocumentsAndGalleries(){
            let arr1 = this.Galleries.map(gallery => {
                gallery.IsGallery = true
                return gallery
                })
            let arr2 = this.DocumentRegisters
            if(this.ActiveComment && this.ActiveComment.FileType.includes('image')){
                return arr1.concat(arr2)
            }
            else{
                return arr2
            }
            
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        
        ComputedRecordData(){
            return this.ActiveRecord.Data
            .map(record => {
                //console.log(record)
                return record
            })
        },
        ProcessesArray(){
            return this.ActiveSession.EntityProcesses
            .map(process => {
                process.PipelineProcess = process.id
                process.ValueType = 'Count',
                process.ThemeColor = this.ThemeColor
                process.Data = this.AssignedArray.filter(record => {
                    return record.ActiveProcess.id === process.id
                })
                process.ActiveData = process.Data.filter(record => {
                    return record.Status && record.Status.ID === 1000001
                })
                process.PrimaryEntity = this.ActiveSession.id
                if(this.AppisDarkMode){
                    process.class = this.ThemeColor+' lighten-2 tag-wrap'
                }
                else{
                    process.class = this.ThemeColor+' lighten-4 tag-wrap'
                }
                process.headerclass = 'overline'
                return process
            })
            
        },
        AutoAdvanceDisabled(){
            if(this.automationstep === 1 && this.Autobutton.Pass){
                return false
            }
            else if(this.automationstep > this.Autobutton.ResultActions.length-1+2){
                return true
            }
            else{
                return typeof this.Autobutton.ResultActions[this.automationstep-2] === 'undefined' || this.Autobutton.ResultActions[this.automationstep-2] && typeof this.Autobutton.ResultActions[this.automationstep-2].Output === 'undefined'
            }
        },
        UnAssignedButtonColor(){
           switch (this.unassignedstep) {
                case 4: return 'red'
                default: return 'green'
            }
        },
        UnAssignedButton(){
           switch (this.unassignedstep) {
                case 4: return 'Process'
                default: return 'Next'
            }
        },
        
        UnassignedWindowcurrentTitle(){
           switch (this.unassignedstep) {
                case 1: return this.Step1Title
                case 2: return this.AssigningRecord[this.ActiveSession.RecordPrimaryField.Name] ? 'Assign Owner' : 'Assign Owner and '+this.ActiveSession.RecordPrimaryField.DisplayName
                case 3: return 'Assign Process'
                default: return 'Process Record'
            }
        },
        Step1Title(){
            if(this.EntityHasWebForm){
                return 'Enquiry'
            }
            else{
                return 'Data'
            }
        },
        Step1Body(){
            let Body = ``
            if(this.EntityHasWebForm){
                Body = `<html>
<body>
<head></head>
<h2>New Enquiry - Contact Us</h2>
<table>`
             let length = this.WebFormHeaders.length
            this.WebFormHeaders.map((field,i) => {
                Body = Body+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+this.AssigningRecord[field.Name]+`</td>
</tr>`
    if(i-1+2 === length){
        Body = Body+`
    </table>
</body>
</html>`
 } 
            })
            }
            return Body
        },
        ComputedHeaders(){
            return this.ActiveSession.AllFields
            .filter(field => {
                return field.IsHeader
            })
        },
         WebFormHeaders(){
            return this.ActiveSession.AllFields
            .filter(field => {
                return field.OnWebForm
            })
        },
        EntityHasWebForm(){
            return this.ActiveSession.Entity_Type && this.ActiveSession.Entity_Type.Name === 'Web Form'
        },
        Computeditems(){
            return this.items.map(item => {
                //item.icon = this.ActiveSession.icon
                return item
            })
        },
        StoreQuery(){
            return this.$store.state[this.ActiveSession.storequery]
        },
        StoreArray(){
            return this.$store.state[this.ActiveSession.storedata]
        },
        UnassignedArray(){
            //console.log(this.ComputedDataArray)
            return this.ComputedDataArray
            .filter(record => {
                return record.PipelineProgress === 'Unassigned'
            })
            .filter(record => {
                return record.Status && record.Status.ID === 1000001
            })
        },
        AssignedArray(){
            return this.ComputedDataArray
            .filter(record => {
                return record.PipelineProgress === 'Assigned'
            })
        },
        ComputedDataArray(){
            //console.log(this.DataArray,this.StoreDataArray)
            return this.StoreDataArray
            .map(originalrecord => {
                let record = Object.assign({},originalrecord)
                if(record.ActiveProcessDocid){
                    record.PipelineProgress = 'Assigned'
                }
                else{
                    record.PipelineProgress = 'Unassigned'
                }
                if(record.Created_By){
                    let userobj = this.UsersArray.find(obj => obj.id === record.Created_By.id)
                    if(userobj && userobj.Profile_Photo){
                        record.Created_By.Profile_Photo = userobj.Profile_Photo
                    }
                } 
                if(record.Ownerid){
                        let owneruserobj = this.UsersArray.find(obj => obj.id === record.Ownerid)
                        if(owneruserobj && owneruserobj.Profile_Photo){
                            record.Owner.Profile_Photo = owneruserobj.Profile_Photo
                        }
                    }               
                return record
            }).map(record => {
                 record.Notifications = this.UnreadNotifications
                    .filter(not => {
                        return not.clientrecords === record.id
                    })
                    record.NotificationCount = record.Notifications.length
                    return record
            })
           
        },

        
    },
    created(){
        //this.EntityProcesses = this.ActiveSession.EntityProcesses
           this.CallTableData()   
        if(this.ActiveSession.automations){
            this.PrepareAutomations()
        }
        if(this.NotificationObj){
           this.CheckNotificationObj()
        }
    },
    methods:{
        GetCustomRolesTableInteraction(Data,TableNameSplit){
            let rules = [
                'CanCreate',
                'CanView',
                'CanEdit',
                'CanDelete'
            ]
            return Data.map(data => {
                rules.map(rule => {
                    data[rule] = this.$store.state[TableNameSplit+rule] || this.ActiveGroup.id
                    
                })
                return data                        
            })
        },
        GetBUTableInteraction(Data,TableNameSplit){
            
            let rules = [
                {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'CanList', PermissionLevel: 4},
                {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'CanList', PermissionLevel: 3},
                {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'CanList', PermissionLevel: 2},
                {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'CanList', PermissionLevel: 1},
                {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'CanGet', PermissionLevel: 4},
                {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'CanGet', PermissionLevel: 3},
                {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'CanGet', PermissionLevel: 2},
                {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'CanGet', PermissionLevel: 1},
                {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},
                {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'CanEdit', PermissionLevel: 4},
                {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'CanEdit', PermissionLevel: 3},
                {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'CanEdit', PermissionLevel: 2},
                {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'CanEdit', PermissionLevel: 1},
                {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'CanDelete', PermissionLevel: 4},
                {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'CanDelete', PermissionLevel: 3},
                {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'CanDelete', PermissionLevel: 2},
                {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'CanDelete', PermissionLevel: 1},
            ]
            return Data.map(data => {
                rules.map(rule => {
                    if(rule.PermissionLevel === 4 || this.ActiveGroup.id){
                       if(this.$store.state[TableNameSplit+rule.Boolean]){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 3){
                       if(this.$store.state[TableNameSplit+rule.Boolean] && this.userBUChildren.includes(data.Business_Unitid)){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 2){
                       if(this.$store.state[TableNameSplit+rule.Boolean] && data.Business_Unitid === this.userBUID){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 1){
                       if(this.$store.state[TableNameSplit+rule.Boolean] && data.Ownerid === this.userLoggedIn.id){
                            data[rule.Action] = true          
                        } 
                    }
                    
                })
                return data                        
            })
        },
        GetTableData(){
            //console.log(this.UnreadNotifications)
            let tablesecuritystructure = this.ActiveSession.datasetsecuritystructure
            if(!tablesecuritystructure){
                tablesecuritystructure = this.ActiveSession.Security_Type.Name
            }
            //console.log(tablesecuritystructure,this.ActiveSession)
            if(tablesecuritystructure === 'Business Unit' && !this.ActiveGroup){
                // && !this.$route.params.id
                this.StoreDataArray = this.GetBUTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
                //console.log(this.DataArray,this.StoreDataArray)
            }
            else if(tablesecuritystructure === 'Custom Roles' && !this.ActiveGroup){
                // && !this.$route.params.id
                this.StoreDataArray = this.GetCustomRolesTableInteraction(this.StoreDataArray,this.ActiveSession.DisplayName.split(' ').join(''))
            }
            else if(tablesecuritystructure === 'Group Member'){
                let memberobj = this.userLoggedIn.groups.find(obj => obj.id === this.ActiveGroup.id)
                this.StoreDataArray = this.GroupDataEntries.map(data => {
                    data.CanCreate = memberobj.Administrator || memberobj.IsTeamMember
                    data.CanView = memberobj.Administrator || memberobj.IsTeamMember
                    data.CanEdit = memberobj.Administrator || memberobj.IsTeamMember
                    data.CanDelete = memberobj.Administrator
                    
                    return data
                })
            }   
        },         
        CallTableData(){
            let vm = this
            let storedata = this.ActiveSession.topicid.split('_').join('')+'Array'
            let storequery = this.ActiveSession.topicid.split('_').join('')+'Query'
            if(!this.ActiveGroup){
                if(vm.$store.state[storedata] && vm.$store.state[storedata].length>0){
                    //vm.GetTableData()
                }
                else{
                    if(vm.$store.state[storequery]){
                        let payload = {                
                        query: vm.$store.state[storequery],
                        arraymutation: 'set'+storedata,
                        getter: 'get'+storedata,
                        arrayname: storedata
                        }
                        vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                            //vm.GetTableData()
                        })
                    }
                    else{
                        //vm.GetTableData()
                    }
                    
                }
            }
            else{
                this.CallGroupPipelineData()
            }
                
        },
        CallGroupPipelineData(){
            this.TableDB.onSnapshot(res => {
                const changes = res.docChanges();
                let length = res.docs.length
                if(length === 0){
                    this.GetTableData()
                }
                else{
                  changes.forEach(change => {
                    if (change.type === 'added') {
                        this.GroupDataEntries.push({
                        ...change.doc.data(),
                        id: change.doc.id
                        })
                    } 
                    if (change.type === 'modified') {
                        let oncheck = this.GroupDataEntries.find(obj => obj.id === change.doc.id)
                        if(oncheck){
                            let index = this.GroupDataEntries.indexOf(oncheck)
                            this.GroupDataEntries[index] = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        }
                    } 
                    if(this.GroupDataEntries.length === length){
                        this.GetTableData()
                    }  
                })  
                }
                  
            })
        },
        OperationalDB(record){
            return this.TableDB.doc(record.id)
        },
        HasProcedure(record,stageindex){
        return record.ActiveProcess.ProcessStages[stageindex].Steps && record.ActiveProcess.ProcessStages[stageindex].Steps.length > 0
        },
        ActivateRecordStatusChangeDialog(record){
            this.StatusChangeRecord = Object.assign({},record)
            this.Status = Object.assign({},record.Status)
            this.Status_Reason = Object.assign({},record.Status_Reason)
            this.StatusChangeDialog = true
        },
        DeactivateRecordStatusChangeDialog(){
            this.StatusChangeDialog = false
            this.StatusChangeRecord = ''
            this.Status = ''
            this.Status_Reason = ''
            
        },
        UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
        ToggleFollowRecordConfirm(record){
            let recordcol = this.TableDB.doc(record.id)
            let LiaisonFollowingUsers = []
            if(this.TimelineRecord.LiaisonFollowingUsers){
                LiaisonFollowingUsers = this.TimelineRecord.LiaisonFollowingUsers
            }
            if(this.UserisTimelineRecordFollower){
                let usermatch = LiaisonFollowingUsers.find(obj => obj === this.userLoggedIn.id)                
                if(usermatch){
                let index = LiaisonFollowingUsers.indexOf(usermatch)
                 LiaisonFollowingUsers.splice(index,1)   
                }                
            }
            else{
                LiaisonFollowingUsers.push(this.userLoggedIn.id)
            }
            console.log('LiaisonFollowingUsers',LiaisonFollowingUsers)
            recordcol.update({
                LiaisonFollowingUsers: LiaisonFollowingUsers
            }).then(doc => {
                this.CloseTimelineRecordDialog()                  
            })
            
        },
        CloseTimelineRecordDialog(){
                this.TimelineDialog = false
                this.TimelineRecord = '' 
        },
        ToggleAutomationDialog(record){
            if(!this.AutomationDialog){
              this.AutomationRecord = Object.assign({},record)
              console.log(this.AutomationRecord,this.AutomationButtons)
              this.AutomationRecord.AutomationButtons = []
            //console.log(record,this.AutomationButtons)   
                this.AutomationButtons.map((auto,index) => {
                    this.AutomationRecord.AutomationButtons.push(auto)
                    if(index-1+2 === this.AutomationButtons.length){
                      this.AutomationDialog = true
                    } 
                })      
            }
            else{
              this.AutomationDialog = false
              this.AutomationRecord = {}
            }
            
        },
        ToggleRecordActivitiesDialog(record){
            if(this.RecordActivitiesDialog){
                this.DeactivateRecordActivitiesDialog()
            }
            else{
                this.ActivateRecordActivitiesDialog(record)
            }
        },
        ActivateRecordActivitiesDialog(record){
            this.TimelineRecord = record
            this.RecordActivitiesDialog = true
        },
         DeactivateRecordActivitiesDialog(record){
            this.TimelineRecord = ''
            this.RecordActivitiesDialog = false
        },
        UpdateRecordStage(TimelineRecord){
            this.RecordProcessUpdate()
            //this.CancelRecordProcessStageDialog()
        },
        RecordProcessUpdate(){
            let opsdb = this.TableDB.doc(this.TimelineRecord.id)
            let entity = this.SystemEntities.find(obj => obj.id === this.ActiveSession.id)
            let process = Object.assign({},entity.EntityProcesses.find(obj => obj.id === this.TimelineRecord.ActiveProcess.id))           
            let processobj = {
                Automations: process.Automations,
                DisplayName: process.DisplayName,
                IsHeader: process.IsHeader,
                Name: process.Name,                
                Number: process.Number,
                ProcessStageInteger: process.ProcessStageInteger,
                ProcessStages: JSON.parse(JSON.stringify(process.ProcessStages)),
                Type: process.Type,
                id: process.id
            }
            let stage = processobj.ProcessStages.find(obj => obj.Number === this.TimelineRecord.stagename.Number)
            console.log(opsdb,entity,process,processobj,this.TimelineRecord)
            opsdb.update({
                ActiveProcess: processobj
            })
            let processname = 'RecordProcess'+processobj.Number
            let processdoc = this.TimelineRecord[processname]
            //console.log(processname)
            //console.log(!processdoc)
            if(!processdoc){
                opsdb.collection('processes').add(processobj).then(doc => {
                opsdb.update({
                    [processname]: doc.id,
                    ActiveProcessDocid: doc.id,
                    stagename: stage,
                    stagenameid: stage.Number
                })
                })
            
                //this.CancelRecordProcessStageDialog()
            }
            else{
                opsdb.collection('processes').doc(processdoc).update({
                DisplayName: processobj.DisplayName,
                Name: processobj.Name,
                ID: processobj.Number,
                ProcessStages: processobj.ProcessStages,
                StageID: stage.Number
                })
                opsdb.update({
                    stagename: stage,
                    stagenameid: stage.Number
                })
                //this.CancelRecordProcessStageDialog()
                }
            },
        CancelRecordProcessStageDialog(){
            this.RecordProcessStageDialog = false
            this.TimelineRecord = ''
            let process = Object.assign({},this.ActiveRecord)
            this.ActiveRecord = ''
            setTimeout(() => {
             this.ActiveRecord = process   
            }, 50);
            
        },
        ToggleRecordProcessStage(record){
            this.RecordProcessStageDialog = true
            this.TimelineRecord = Object.assign({},record)
            //console.log(this.TimelineRecord)
            //TimelineRecord
        },
        
        ResetStatus(record){
        this.Status = Object.assign({},record.Status)
        this.Status_Reason = Object.assign({},record.Status_Reason)
        this.DeactivateRecordStatusChangeDialog()
        },
        UpdateStatus(record){
        this.TableDB.doc(record.id).update({
            Status: this.Status,
            Status_Reason: this.Status_Reason
        })
        let UpdatedObjData = {
            Status: this.Status,
            Status_Reason: this.Status_Reason
        }
        
        this.CheckifClassifiedEntityandStatusUpdate(this.ActiveSession,'Status',UpdatedObjData,record).then(result => {
            this.DeactivateRecordStatusChangeDialog()
        })        
        },
        CheckifClassifiedEntityandStatusUpdate(Table,Prop,UpdatedObjData,RecordData){
            let vm = this
            return new Promise(function(resolve, reject) {	
            let entity = vm.SystemEntities.find(obj => obj.id === Table.id)
            if(entity && entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing' && RecordData.Combined_Title && Prop === 'Status'){
                const functions = firebase.functions();
                const UpdateRecordData = functions.httpsCallable('UpdateRecordData'); 
                let payload = {
                  collection: vm.ActiveSession.DisplayName.split(' ').join('').toLowerCase()+'listings',
                  RecordID: RecordData.id,
                  UpdatedValues: [
                    {Prop: 'Status', Value: UpdatedObjData.Status,Prop: 'Status_Reason', Value: UpdatedObjData.Status_Reason}
                  ]
                }
                UpdateRecordData(payload).then(result => {
                    resolve(result)
                })
            }
            })
        },
        CheckNotificationObj(){
            this.ActiveRecord = this.ProcessesArray.find(obj => obj.Number === this.NotificationObj.ActiveProcessNumber)
            //console.log(this.ActiveRecord)
            this.Tabs = 1
            setTimeout(() => {
              let elmnt = document.getElementById(this.NotificationObj.ActiveRecord+'responses')
                //console.log(this.NotificationObj.ActiveRecord+'responses')
                //console.log(elmnt)
                if(elmnt){
                    elmnt.click()
                }  
            }, 100);
        },
        DateFormatterwithTime(date){
      if(date){
        //console.log(date)
        //console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        let hrnumber = Number(hr)
        let minr = Number(min)
        let secnr = Number(sec)
        var ampm = "am";
        if(isNaN(secnr)){
          secnr = 0
        }
        //console.log(yearnumber,monthnumber,daynumber,hrnumber,minr,secnr)
        let newdate = new Date(yearnumber, monthnumber-1, daynumber,hrnumber,minr,secnr)
        //console.log(newdate)
        return newdate
        }
      else{
        return null
      }
},
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
        ProcessingData(boolean){
            this.FormProcessing = boolean
        },
        RefreshDialog(){
            this.AutomationDialog = false
            let newrecord = Object.assign(this.ProcessRecord)
            this.automationstep = this.automationstep-1
            this.automationstep = this.automationstep-1+2
            this.ProcessRecord = ''
            this.ProcessRecord = newrecord
            this.AutomationDialog = true 
            
        },
        
        PrepareAutomations(){
            //console.log('PrepareAutomations',this.ActiveSession)
            let array = JSON.parse(JSON.stringify(this.ActiveSession.automations))
            let automationswithconditionalbuttons = array.map(auto => {
                auto.Conditions = auto.Conditions.filter(cond => {
                        return cond.CallfromButton
                    })
                return auto
            })
            .filter(auto => {
                return auto.Conditions.length > 0
            })
            //console.log('PrepareAutomations automationswithconditionalbuttons',automationswithconditionalbuttons)
            this.AutomationButtons = automationswithconditionalbuttons.map(auto => {
                return auto.Conditions.map(cond => {
                    let obj = {}
                        obj.ButtonName = cond.ApprovalButtonText,
                        obj.Steps = cond.Steps
                        obj.ResultActions = []
                        cond.ResultActions.map(act => {
                            let matchobj = auto.AutomationActions.find(obj => obj.MethodID === act.MethodID)
                            //console.log('PrepareAutomations ',act,matchobj.Record.id,this.SystemEntities)
                            if(matchobj){
                                let temprecord = matchobj.Record
                                matchobj.Record = this.SystemEntities.find(obj => obj.id === matchobj.Record.id)
                                matchobj.Record.Relationship = temprecord.Relationship
                                if(temprecord.RelationshipField){
                                  matchobj.Record.RelationshipField = temprecord.RelationshipField  
                                }
                                obj.ResultActions.push(matchobj)
                            }
                        })
                        return obj
                    })
                    //the key to minimal intervention is here. possibly specifically in resultactions. 
                    //we need a 2 step window. step 1 = conditions met or unmet. Step 2 being, the dialog
                    //but seeing as multiple actions could apply I think the actions you want is the key
                    //aha!!! we render buttons on datarecord right? Well, we need to render the data, do the comparisons and validations and everything within there,
                    //pusht he "buton" as a prop to the data. That's what we should do. 
               
            }).flat()
            console.log('PrepareAutomations AutomationButtons',this.AutomationButtons)
        },
        PushItem(item){
            this.ActiveRecord = item
            this.ActiveRecord.LookupArrays = []
            this.ActiveRecord.Data
                .map(record => {
                    record.AutomationButtons = []
                //console.log(record,this.AutomationButtons)   
                    this.AutomationButtons.map(auto => {
                        record.AutomationButtons.push(auto)   
                    })          
            }) 
            //console.log(this.ActiveRecord)
        },
        
        AdvanceUnAssignedRecord(){
            if(this.unassignedstep === 4 && this.AssigningRecordPrimaryValue){
                this.AssignRecordandPrimaryValue()
                
            }
            else if(this.unassignedstep === 4){
                this.AssignRecord()
                
            }
            else{
                this.unassignedstep = this.unassignedstep-1+2
            }
        },
        CancelAssignRecord(){     
            let vm = this       
            this.AssignRecordDialog = false
            this.AssigningRecord = ''
            this.ActiveProcess = ''
            this.ActiveStage = ''
            this.AssigningRecordPrimaryValue = ''
            this.unassignedstep = 1
            //vm.DataArray = []
            //setTimeout(() => {
            //    vm.DataArray = vm.StoreArray
            //    console.log(vm.DataArray)
            //}, 1000);
            
        },
        AssignRecordandPrimaryValue(){
            let vm = this
            let NameQuery = []
            let NameStringArray = this.AssigningRecordPrimaryValue.trim()
            var lowertext = "";                
            var p;
            let buildup = ''
            console.log(NameStringArray.length,NameStringArray,this.TableDB,this.ActiveSession,this.AssigningRecord)
            for (p = 0; p < NameStringArray.length; p++) {
                buildup = buildup+ NameStringArray[p]
                    lowertext += buildup.toLowerCase() + ",";
                    }                   
            NameQuery = lowertext.split(',')  
            NameQuery.length = NameQuery.length-1
            let Owner = {Full_Name: this.AssigningRecord.Owner.Full_Name,id: this.AssigningRecord.Owner.id}
            let Business_Unit = ''
            if(this.AssigningRecord.Owner.Business_Unit){
                Business_Unit = {Name: this.AssigningRecord.Owner.Business_Unit.Name,id: this.AssigningRecord.Owner.Business_Unit.id}
            }
            else{
                let userobj = this.UsersArray.find(obj => obj.id === Owner.id)
                Business_Unit = {Name: userobj.Business_Unit.Name,id: userobj.Business_Unit.id}
            }
            let LiaisonFollowingUsers = [
                this.userLoggedIn.id,
            ]
            if(this.userLoggedIn.id !== this.AssigningRecord.Owner.id){
                LiaisonFollowingUsers.push(this.AssigningRecord.Owner.id)
            }
            this.TableDB.doc(this.AssigningRecord.id).update({
                Owner: Owner,
                Ownerid: Owner.id,
                Business_Unit: Business_Unit,
                Business_Unitid: Business_Unit.id,
                LiaisonFollowingUsers: LiaisonFollowingUsers,
                [this.ActiveSession.RecordPrimaryField.Name]: this.AssigningRecordPrimaryValue,
                [this.ActiveSession.RecordPrimaryField.Name+'Query']: NameQuery,
            }).then(doc => {
                vm.AssignProcessConfirm()
            })
        },
        AssignRecord(){
            let vm = this
            let Owner = {Full_Name: this.AssigningRecord.Owner.Full_Name,id: this.AssigningRecord.Owner.id}
             let Business_Unit = ''
            if(this.AssigningRecord.Owner.Business_Unit){
                Business_Unit = {Name: this.AssigningRecord.Owner.Business_Unit.Name,id: this.AssigningRecord.Owner.Business_Unit.id}
            }
            else{
                let userobj = this.UsersArray.find(obj => obj.id === Owner.id)
                Business_Unit = {Name: userobj.Business_Unit.Name,id: userobj.Business_Unit.id}
            }
            let LiaisonFollowingUsers = [
                this.userLoggedIn.id,
            ]
            if(this.userLoggedIn.id !== this.AssigningRecord.Owner.id){
                LiaisonFollowingUsers.push(this.AssigningRecord.Owner.id)
            }
            this.TableDB.doc(this.AssigningRecord.id).update({
                Owner: Owner,
                Ownerid: Owner.id,
                Business_Unit: Business_Unit,
                Business_Unitid: Business_Unit.id,
                LiaisonFollowingUsers: LiaisonFollowingUsers
            }).then(doc => {
                vm.AssignProcessConfirm()
            })
        },
    AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
        console.log(this.ActiveProcess)
        let SavingProcess ={
            Automations: this.ActiveProcess.Automations,
            DisplayName: this.ActiveProcess.DisplayName,
            Name: this.ActiveProcess.Name,
            Number: this.ActiveProcess.Number,
            PipelineProcess: this.ActiveProcess.PipelineProcess,
            PrimaryEntity: this.ActiveProcess.PrimaryEntity,
            ProcessStages: this.ActiveProcess.ProcessStages,
            ProcessStageInteger: this.ActiveProcess.ProcessStageInteger,
            Status: this.ActiveProcess.Status,
            Type: this.ActiveProcess.Type,
            id: this.ActiveProcess.id
        }
        //console.log(SavingProcess)
      this.TableDB.doc(this.AssigningRecord.id).update({
        ActiveProcess: SavingProcess,
        stagename: this.ActiveStage,
        stagenameid: this.ActiveStage.Number
      })
      let processdoc = 'RecordProcess'+SavingProcess.Number
      //console.log(processdoc)
      //console.log(typeof this.AssigningRecord[processdoc],this.AssigningRecord[processdoc])
      //console.log(!this.AssigningRecord[processdoc])
      if(!this.AssigningRecord[processdoc]){
        // let ProcessDoc = 
        this.TableDB.doc(this.AssigningRecord.id).collection('processes').add(SavingProcess).then(doc => {
          this.TableDB.doc(this.AssigningRecord.id).update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
            let record = Object.assign({},this.AssigningRecord)
            record.ActiveProcess = SavingProcess
            this.CreateLiaisonNotificationtoOwner(record,SavingProcess) 
          
          this.CancelAssignRecord()
        })
        // this.StageID = 0
        // this.StageName = ''
      }
      else{
        this.TableDB.doc(this.ActiveGroup.id).collection('processes').doc(this.AssigningRecord[processdoc]).update({
         DisplayName: SavingProcess.DisplayName,
         Name: SavingProcess.Name,
         ID: SavingProcess.Number,
          ProcessStages: SavingProcess.ProcessStages
        })
        this.TableDB.doc(this.ActiveGroup.id).update({
            ActiveProcessDocid: this.AssigningRecord[processdoc]
          })
          this.ActiveProcessDocid = this.AssigningRecord[processdoc]
          this.TableDB.doc(this.ActiveGroup.id).collection('processes').doc(this.ActiveProcessDocid).onSnapshot(stagesnap => {
            var stagedata = stagesnap.data()
            // this.StageName = stagedata.StageName
            // if(this.StageName){
            // this.StageID = stagedata.StageName.Number
            // }
            })
      }
    },
    CreateLiaisonNotificationtoOwner(record){
        let owneruser = record.Owner
        let NewComms = {
          description: this.userLoggedIn.Full_Name+' assigned the record to '+owneruser.Full_Name,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.ActiveSession.title,
          recordlocation: '/'+this.ActiveSession.SingleName.split(' ').join('')+'/'+record.id,
          topicid: this.ActiveSession.topicid,
        }
        if(record.groupid){
            NewComms.groupid = record.groupid
        }
        if(record.ActiveProcess && record.ActiveProcess.Number){
            NewComms.ActiveProcessNumber = record.ActiveProcess.Number
        }
        console.log(NewComms)
        this.SaveNewcomms(NewComms,record)  
    },
    SaveNewcomms(NewComms,record){
        let commscol = this.TableDB.doc(record.id).collection('InternalLiaison')
        console.log(commscol)
        let owneruser = record.Owner
        let owner = {Full_Name: owneruser.Full_Name,id: owneruser.id}
        NewComms.recipientid = owner.id
        
          //console.log(NewComms)
           commscol.add(NewComms).then(doc => {            
            if(NewComms.Created_By.id !== owner.id){
                  let NewNotification = {
                    itemidprop: 'clientrecords',
                    itemid: NewComms.relatedid,
                    topicid: NewComms.topicid,
                    Type: 'Feedback',
                    Header: 'Response on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    Content: NewComms.description,
                    CreatorFullName: NewComms.Created_By.Full_Name,
                    CreatorID: NewComms.Created_By.id,
                    Owner: owner,
                    Ownerid: owner.id,
                    Read: false,
                    Path: '/MyAccount',                
                    IsLiaison: true,
                    Liaisonid: doc.id,
                    Message: NewComms.Created_By.Full_Name+' has responded on '+record[this.ActiveSession.RecordPrimaryField.Name],
                    }
                    if(NewComms.fileurl){
                        NewNotification.fileurl = NewComms.fileurl
                    }
                    if(NewComms.ActiveProcessNumber){
                        NewNotification.ActiveProcessNumber = NewComms.ActiveProcessNumber
                    }
                    if(NewComms.groupid){
                        NewNotification.groupid = NewComms.groupid
                    }
                    console.log(NewNotification)
                this.$store.dispatch('createNotification',NewNotification) 
               }
            }) 
            
      },
        AssignItem(item){
            // if(item.CanEdit){
               this.AssigningRecord = item
                this.AssignRecordDialog = true 
            // }
        },
        GetMatchingStatus(item){
        //    if(this.MenuItem && this.MenuItem.Array){
             return this.ComputedRecordData
            .filter(record => {
                return record.stagenameid === item.Number && record.Status && record.Status.ID === 1000001
            })
            
        },
    }

}
</script>

<style>

</style>