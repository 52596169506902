
<template>
<v-card :class="userLoggedIn? 'transparent': 'transparent'" style="padding-bottom:60px;" width="100%" height="100%">
<!-- <v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout> -->
<SocialSharing v-if="userLoggedIn" :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="UserRecord" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
    <v-parallax v-if="GroupData.logo"
            height="150"
            :src="GroupData.logo"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center"  :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
            <v-parallax v-if="!GroupData.logo"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center"  :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">{{title}}</h4>
              </v-col>
            </v-row>
            </v-parallax> 
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Article.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-layout class="justify-center" v-if="!EditingArticle">
              <v-card class="my-3 mx-3" hover width="90%" >
                  <v-card-actions style="font-size:10px">
                  <v-chip small color="secondary" class="white--text"  v-if="UserRecord.id">
                    {{author}}
                  </v-chip>
                  
                 <v-menu :close-on-content-click="false"
                 v-model="PublishMenu"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="UserRecord.id">
                              {{PubStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <div  v-for="status in PublishStatusOptions" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserIsModerator"
                                 v-if="UserRecord.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateStatus(status.Name)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>

                 <v-spacer></v-spacer>
                  <span  v-if="createdon && createdon.toDate && UserRecord">{{createdon.toDate()}}</span>
                  <v-icon @click="EditItem()" v-if="UserIsModerator || userIsAdmin">mdi-pencil</v-icon>
                </v-card-actions>
                <v-img
                  height="350px"
                  v-bind:src="image"
                  v-if="image"
                ></v-img>
                        <v-card-title class="headline">{{ title }}</v-card-title>
                            
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{articlepubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="articlecontent">
                </v-card-text>
                <v-card-actions>
                  
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <!-- <v-spacer></v-spacer>
                  <v-btn @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn> -->
                </v-card-actions>
               
                <v-card-actions>
              <TaggingComponent :AppisDarkModeCard="AppisDarkModeCard"  :UsercanTag="UserIsModerator" :record="FullArticleData" />
            </v-card-actions>
            <SocialCommentsandLikes v-if="SocialCommentsActive"  :panel="0"
            :PathName="'Article'" :GroupID="$route.name === 'GroupArticle' ? $route.params.id : ''" :CanSocial="UserCanSocialTopic" :notificationitem="'Article'" :RelatedType="'Site Article'" :SocialItem="FullArticleData" :UserRecord="UserRecord" :AppisDarkMode="AppisDarkMode"/>
              </v-card>
              </v-layout>
      <v-layout class="justify-center" v-if="EditingArticle">
        <EditArticle :title="title" :pubdatastring="pubdatastring" :CurrentModerators="Moderators"
        @StopEdit="StopEdit" :caption="caption" :FullArticleData="FullArticleData" :CurrentImage="image" :CurrentImageThumb="imagethumb"
        @ActivateProcessing="ActivateProcessing" :GuestsIncluded="GuestsIncluded" :GroupData="GroupData"
        :SiteModerators="SiteModerators" :CurrentImageShy="ImageShy" :Currentarticlecontent="articlecontent"/>
      </v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import EditArticle from '@/components/SuitePlugins/Articles/Edit/Single'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import SocialSharing from '@/components/SocialNetwork/SocialSharing'
import SocialCommentsandLikes from '@/components/SocialNetwork/SocialCommentsandLikes';

export default {
    props: ['FriendsList','System'],
      components: {
        EditArticle,
        TaggingComponent,
        SocialSharing,
        SocialCommentsandLikes
  },
    data() {
        return {
          PublishMenu: false,
          GuestsIncluded: false,
          PublishStatusOptions: [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            {
              Name: 'Published (Public)',
              Class: 'error',
              Warning: 'Make the item available for viewing by the Public?'
            }
          ],
           ShareItemDialog: false,
          InternalLinkType: '',
          SharedCollection: '',
          LinkComponent: '',
          LinkID: '',
          NoticeCoverIMGProp: 'image',
          FullArticleData: '',
          PublicArticles: [],
          SiteModerators: [],
         Moderators: [],
            articlequil: false,
            snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        articledialog: false,
        articlecontent: '',
        PubStatus: '',
        UserIsNOTModerator: true,
        
        IsAuthor: false,
        author: null,
        authorid: null,
        //userid: this.$store.state.user.id,
        grouparticles: [],
        groups: [],       
        articleid: this.$route.params.id,
        content: null,
        image: '',
        ImageShy: false,
        createdon: '',
        articlepubDate: '',
        UserCanEdit: false,
        ArticlePrivacy: null,
        PublishStatus: null,
        PublishStatusTypes: [
        {index: 0, text: 'Draft'},
        {index: 1, text: 'Published'},
          ],  
          imagethumb: '',
        caption: '',
        title: null,
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                ArticlePrivacy: null,
                PublishStatus: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,
                ArticlePrivacy: null,
                PublishStatus: null,                
            },
            UserRecord: {},
            GroupData: {},
            EditingArticle: false,  
        }
    },
    computed: {
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      SocialCommentsActive(){
        return this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && !this.System.Disable_Guest_Posts
        || this.FullArticleData && this.FullArticleData.Comments && this.FullArticleData.Likes && this.userLoggedIn && this.System.Disable_Guest_Posts && !this.userLoggedIn.IsGuestUser
      },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      UserCanSocialTopic(){
        if(this.$route.name === 'GroupArticle'){
          let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
          return memberobj
        }
        else{
          return this.userLoggedIn
        }
      },
      UserIsModerator(){
          let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
          return this.authorid === this.UserRecord.id || UserModeratorobj
        },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      RouteName(){
          return this.$route.name === 'GroupArticle' ? this.GroupData.name+' Article' : this.System.Name
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
        RelatedArticles () {
            return this.grouparticles.filter(article => {
                return article.articleid === this.articleid
            })
        },
    },
    watch: {
      UserIsModerator(value) {
            if (value) {
              this.$store.commit('SetCurrentItemModerator',value)              
            }
        },
      SocialItemInteractMethod(v){      
        if(v){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      }
    },
    created() {
        document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
        if(this.$route.name === 'GroupArticle'){
          this.InternalLinkType = 'Group-Article/'+this.$route.params.id+'/Article'
          this.SharedCollection = 'grouparticles'
          this.LinkComponent = 'GroupArticleShared'
          this.LinkID = this.$route.params.slug
          this.GetGroup()
        }
        else{
          this.InternalLinkType = 'Article'
          this.SharedCollection = 'sitearticles'
          this.LinkComponent = 'SiteArticleShared'
          this.LinkID = this.$route.params.id
        }
      this.GetRequestingUser()
        
        
        
    },
    methods: {      
      ActivatePublishMenu(){
        this.PublishMenu = !this.PublishMenu
      },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        }      
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      StopEdit(){
        this.EditingArticle = false
        this.ActivateProcessing(false)
      },
      EditItem(){
        this.EditingArticle = true
      },
      GetSiteModerators(){
        let vm = this
        console.log('check for all moderators')
        return new Promise(function(resolve, reject) {
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        })
      },
      CheckStatus(status){
        //console.log('CheckStatus',status)
        if(status === 'Draft'){
          confirm('this will update the Article for all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Internal)'){
          confirm('this will make the Article avialable for viewing by all Internal Users. Do you want to continue?') && this.UpdateStatus(status)
        }
        else if(status === 'Published (Public)'){
          confirm('this will make the Article available for viewing by the Public. Do you want to continue?') && this.UpdateStatus(status)
        }
      },
      DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
      UpdateStatus(status){
          let col = ''
          let wikicol = ''
          let docid = ''
          if(this.$route.name === 'GroupArticle'){
            col = 'grouparticles'
            //wikicol = 'grouparticleswiki'
            docid = this.$route.params.slug
          }
          else{
            col = 'sitearticles'
            wikicol = 'sitearticleswiki'
            docid = this.$route.params.id
          }
          
          let PriorStatus = this.PublishStatus
          let wikipost = {
            caption: this.caption,
            tags: [],
            title: this.title,
            UpdatingUser: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
            UpdatingUserid: this.UserRecord.id,
            author: this.author,
            authorid: this.authorid,
            content: this.articlecontent,
            updatedon: new Date(),
            pubDate: this.DateFormatter(this.pubdatastring),
            Comments: [],
            ImageShy: this.ImageShy,
            PublishStatus: status,
            PublicURL: encodeURI(this.title.split(' ').join('-').split('?').join(''))
          }
          //console.log('PriorStatus',PriorStatus)
        db.collection(col).doc(docid).update({
          PublishStatus: status,
          PublicURL: encodeURI(this.title.split(' ').join('-').split('?').join('')),
          pubDate: this.DateFormatter(this.pubdatastring),
          content: this.articlecontent
        }).then(doc => {
          this.CreateSystemActivity(this.title,docid)
          console.log('did the update on '+doc,status)
          if(status === 'Published (Public)' && this.image && wikicol){
            wikipost.coverimage = this.image
            wikipost.imagethumb = this.imagethumb
          db.collection(wikicol).doc(docid).set(wikipost) 
          
          if(this.FullArticleData.DataTags){
            let datatags = this.FullArticleData.DataTags.filter(tag => {
              return tag.tagtype !== 'Users'
            })
            let datatagsid = datatags.map(tag => {
              return tag.id
            })
            db.collection(wikicol).doc(docid).update({
              DataTags: datatags,
              DataTagsid: datatagsid,
            })
            
          }
          if(this.FullArticleData.Category){
             db.collection(wikicol).doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }        
        }
        else if(status === 'Published (Public)' && !this.image && wikicol){
          db.collection(wikicol).doc(docid).set(wikipost) 
          
          if(this.FullArticleData.DataTags){
            let datatags = this.FullArticleData.DataTags.filter(tag => {
              return tag.tagtype !== 'Users'
            })
            let datatagsid = datatags.map(tag => {
              return tag.id
            })
            db.collection(wikicol).doc(docid).update({
              DataTags: datatags,
              DataTagsid: datatagsid,
            })
          }  
          if(this.FullArticleData.Category){
             db.collection(wikicol).doc(docid).update({
              Category: this.FullArticleData.Category,
            })
          }      
        }
        else if(PriorStatus === 'Published (Public)'){
           db.collection(wikicol).doc(docid).update({
              PublishStatus: status,
            })
        }
        })
        
      },
      CreateSystemActivity(docname,docid){
        const systemactivity = {
              user: this.UserRecord,
              contentvar: 'created a new Article',
              location: '/Article/',
              docname: docname,
              docid: docid,
              type: 'New Article',
            }                          
            this.$store.dispatch('createSystemActivity',systemactivity)
      },
      TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                // this.GetForumDiscussion()
            })
        },
        GetRequestingUser(){
          if(this.userLoggedIn){
            this.UserRecord = this.userLoggedIn
            this.IntranetViewToggle(true)
            this.getArticleInformation()
          }
          else{
            this.getPublicArticleInformation()
          }
        
      },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
        addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },

    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'articlecontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
            UserArticlerelation(){
              console.log('checking rleation to articles')
              console.log(this.authorid)
              console.log(this.Moderators)
              console.log(this.UserRecord)
                if(this.authorid === this.UserRecord.id) {
            this.UserCanEdit = true
            this.IsAuthor = true
        
            this.UserIsNOTModerator = false
            this.GetSiteModerators()
                }
                else {
            this.UserCanEdit = false
            let UserModeratorobj = this.Moderators.find(obj => obj == this.UserRecord.id)
            if(UserModeratorobj){
            
              let vm = this
              this.GetSiteModerators().then(function(result) {
                  if(result){
                    console.log('checking if moderators')
                    let UserModeratorobj = vm.SiteModerators.find(obj => obj.id == vm.UserRecord.id)
                    if(UserModeratorobj){
                      if(UserModeratorobj.ModerationPermissions.includes('Public Articles')){
                        vm.UserIsPublicModerator = true
                      }
                      
                    }
                  }
                })
              
              this.UserIsNOTModerator = false
            }
                }
            },
        UpdateArticle(){
          console.log(this.editor.scrollingContainer.innerHTML)

            const UpdatedArticle = {
                content: this.editor.scrollingContainer.innerHTML
            }

            db.collection('grouparticles').doc(this.articleid).update({
                content: UpdatedArticle.content
            });
              this.snackbar = true
            this.articlequil = false
        },
                UpdateArticleSettings(){


            const UpdatedArticle = {
                PublishStatus: this.editedItem.PublishStatus,
                ArticlePrivacy: this.editedItem.ArticlePrivacy
            }

            db.collection('grouparticles').doc(this.articleid).update({
                PublishStatus: UpdatedArticle.PublishStatus,
                ArticlePrivacy: UpdatedArticle.ArticlePrivacy
            });
              this.snackbar = true
            this.articlequil = false
        },
        onEditorChange() {
// alert('change')
        },
        getPublicArticleInformation(){
          let vm = this
          let query = ''
          let splitat = 2
          if(this.$route.name === 'GroupArticle'){
           query = db.collection('grouparticles').where('groupid','==',this.$route.params.id)
           splitat = 4
          }
          else{
            query = db.collection('sitearticleswiki')
          }
          return new Promise(function(resolve, reject) {
        query.where('PublishStatus','==','Published (Public)').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.PublicArticles.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            resolve('Got Moderators')
        })
        }).then(function(result) {
          // let articlecollection = query.where('PublishStatus','==','Published (Public)')
          
          // articlecollection.onSnapshot(snapshot => {
            console.log(vm.PublicArticles,window.location.pathname,window.location.pathname.split('/')[splitat])
                  // this.UpdateRequestStatus()
                  var articledata = vm.PublicArticles.find(obj => obj.PublicURL == window.location.pathname.split('/')[splitat])
                  if(articledata){
                    console.log(articledata)
                    vm.caption = articledata.caption
                    vm.title = articledata.title
                    vm.content = articledata.content
                    vm.articlecontent = articledata.content
                    vm.author = articledata.author
                    vm.authorid = articledata.authorid
                    vm.PubStatus = articledata.PublishStatus
                    if(articledata.ImageShy){
                      vm.ImageShy = articledata.ImageShy
                    }
                    
                    vm.image = articledata.coverimage
                    vm.imagethumb = articledata.coverimageThumbURL
                    vm.createdon = articledata.createdon
                    vm.pubdatastring = format(articledata.pubDate.toDate(),'yyyy-MM-dd')
                    vm.articlepubDate = vm.TimestampFormatterSTRINGDayText(articledata.pubDate.toDate())
                    vm.PublishStatus = articledata.PublishStatus
                    vm.ArticlePrivacy = articledata.ArticlePrivacy
                    vm.editedItem.ArticlePrivacy = articledata.ArticlePrivacy
                    vm.editedItem.PublishStatus = articledata.PublishStatus
                    console.log(vm.caption)
                  }
                  else{
                    vm.$router.push({ name: '404' })
                  }
                
                })

        // })
        },
    GetSocialComments(docid){
          db.collection('socialsitecomments').where('relatedid','==',docid).onSnapshot(res => {
            if(!this.FullArticleData.Comments){
             this.FullArticleData.Comments = [] 
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  Replies: [],
                  id: change.doc.id
                }
                this.GetSocialLike(commentobj.id,commentobj)
                let oncheck = this.FullArticleData.Comments.find(obj => obj.id === commentobj.id)
                if(!oncheck){
                    this.FullArticleData.Comments.push(commentobj)
                  }
                db.collection('socialsitereplies').where('relatedid','==',commentobj.id).onSnapshot(repliesres => {
                    const replychanges = repliesres.docChanges();
                    replychanges.forEach(repchange => {
                    if (repchange.type === 'added') {
                      let replyobj = {
                        ...repchange.doc.data(),
                          Replies: [],
                          id: repchange.doc.id
                        }
                        this.GetSocialLike(replyobj.id,replyobj)
                        let reponcheck = commentobj.Replies.find(obj => obj.id === replyobj.id)
                        if(!reponcheck){
                         commentobj.Replies.push(replyobj) 
                        }
                      }
                    })
                })
                
              }
            })
          })
        },
    GetSocialLike(docid,docobj){
      db.collection('socialsitelikes').where('relatedid','==',docid).onSnapshot(res => {
            if(!docobj.Likes){
              docobj.Likes = []
            }            
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let commentobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                 let oncheck = docobj.Likes.find(obj => obj.id === commentobj.id)
                  if(!oncheck){
                    docobj.Likes.push(commentobj)
                  } 
               
              }
            })
          })
    },
    async getArticleInformation() {
        let blogcollection = db.collection('sitearticles')
        let docid = this.$route.params.id
        if(this.$route.name === 'GroupArticle'){
          blogcollection = db.collection('grouparticles')
          docid = this.$route.params.slug
        }
        let docref = blogcollection.doc(docid)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var articledata = snapshot.data()
          this.FullArticleData = articledata
          this.FullArticleData.id = docid
          this.$emit('PushRecordName',this.FullArticleData.title)
          this.GetSocialComments(docid)
          this.GetSocialLike(docid,this.FullArticleData)
        console.log("articledata",articledata)
        console.log("pubdsdate",typeof articledata.PublishDate.toDate)
        this.caption = articledata.caption
         console.log(this.caption)
        this.Moderators = articledata.moderatorrolesarrayQuery
        if(articledata.GuestsIncluded){
          this.GuestsIncluded = articledata.GuestsIncluded
        }
        else{
          this.GuestsIncluded = false
        }
        this.title = articledata.title
        this.content = articledata.content
        this.articlecontent = articledata.description
        this.author = articledata.author
        this.authorid = articledata.authorid
        this.PubStatus = articledata.PublishStatus
        if(articledata.ImageShy){
          this.ImageShy = articledata.ImageShy
        }
        
        this.image = articledata.coverimage
        this.imagethumb = articledata.coverimageThumbURL
        this.createdon = articledata.createdon
        this.pubdatastring = format(articledata.PublishDate.toDate(),'yyyy-MM-dd')
        this.articlepubDate = this.TimestampFormatterSTRINGDayText(articledata.PublishDate.toDate())
        this.PublishStatus = articledata.PublishStatus
        this.ArticlePrivacy = articledata.ArticlePrivacy
        this.editedItem.ArticlePrivacy = articledata.ArticlePrivacy
        this.editedItem.PublishStatus = articledata.PublishStatus
      console.log('article id is '+this.articleid)
        console.log('user id is '+this.UserRecord.id)
        console.log('author id is '+this.authorid)
        this.UserArticlerelation()
        },
        error => {
          if(error.code === 'permission-denied'){
            if(!this.userLoggedIn){
              this.$router.push('/404')  
            }
            else{
              this.$router.push('/PermissionError')
            }            
          }
        })
    
        },
      OpenArticledialog() {
        this.articledialog = true
    },
    Closedialog() {
        this.articledialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
     save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.ArticlePrivacy = this.editedItem.ArticlePrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('grouparticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      },
      
   
     saveArticleSettings() {
           if(this.$refs.form.validate()) {
                    var storageRef = firestore.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.ArticlePrivacy = this.editedItem.ArticlePrivacy
                    this.PublishStatus = this.editedItem.PublishStatus
                    this.user = this.$store.state.user.FullName
                    this.userid = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              name: this.name,
                                content: this.content,
                                author: this.user,
                                authorid: this.UserRecord.id,
                                image: this.image
                                
                            }  
                        db.collection('grouparticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      
    }
}
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {
align-content: center;
margin-left: 0.5em
}
    
</style>