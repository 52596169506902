
<template>
    <v-main style="margin-top:60px;">
      <v-dialog v-model="NewTopicdialog">
            <v-card>
                <v-card-title>
                    New Topic
                </v-card-title>
                <v-card-text>
                    <v-list>
                      <!-- DynamicFilter {{DynamicFilter}}
                     RelevantDataFilter {{RelevantDataFilter}} -->
                        <v-text-field v-model="BrandNewTopic.Title" label="Title"/>
                        <v-select v-model="BrandNewTopic.TopicCategory" :items="TopicCategories" label="Category"/>
                        <v-autocomplete item-text="Question" return-object multiple v-model="BrandNewTopic.Responses" :items="FrequentlyAskedQuestions" label="Responses"/>
                        <v-switch v-model="BrandNewTopic.TopicSignedIn" label="Must be Signed In" />
                        <v-combobox
                    v-model="BrandNewTopic.KeyWords"
                    :items="items"
                    label="Response KeyWords"
                    multiple
                    chips
                    >
                    <template v-slot:selection="data">
                        <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                        </v-chip>
                    </template>
                    </v-combobox>
                        <v-layout class="justify-center">
            <v-btn-toggle dark>
                
                <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                H1
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                H2
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                H3
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                <v-icon>mdi-format-paragraph</v-icon>
                </v-btn>
                <v-btn @click="copy()">
                <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('delete',false,'')">
                <v-icon>mdi-delete-forever</v-icon>
                </v-btn>                      
                <v-btn onclick="document.execCommand('cut',false,'')">
                <v-icon>mdi-content-cut</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                <v-icon>mdi-format-align-justify</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyRight',false,'')">
                <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                
                
            </v-btn-toggle>
            </v-layout>
                <v-layout class="justify-center">
                            <v-btn-toggle dark>
                            <v-btn onclick="document.execCommand('undo',false,'')">
                                <v-icon>mdi-undo</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('redo',false,'')">
                                <v-icon>mdi-redo</v-icon>
                            </v-btn>
                            
                            <v-btn onclick="document.execCommand('bold', false, '');">
                                <v-icon>mdi-format-bold</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('italic', false, '');">
                                <v-icon>mdi-format-italic</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('underline', false, '')">
                                <v-icon>mdi-format-underline</v-icon>
                            </v-btn>
                            <v-btn @click="changeColor()">
                                <v-icon>mdi-format-color-fill</v-icon>
                            </v-btn>
                            <v-btn  onclick="document.execCommand('selectAll',false,'')">
                                <v-icon>mdi-select-all</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="link()">mdi-link</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="addphone()">mdi-phone</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="addemail()">mdi-email</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                                <v-icon>mdi-format-strikethrough</v-icon>
                            </v-btn>
                            </v-btn-toggle> 
                            
            </v-layout>
            <!-- <v-btn @click="UpdateResponse(option,resp)">Save</v-btn> -->
                        <v-card min-height="300" contenteditable id="NewResponseinput" v-html="BrandNewTopic.Response"/>
                      
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="CancelCreateNewTopic()">Cancel
                    </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="CreateNewTopic(BrandNewTopic)">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="NewResponseDialog">
            <v-card>
                <v-card-title>
                    New Response
                </v-card-title>
                <v-card-text>
                    <v-list>
                      <!-- DynamicFilter {{DynamicFilter}}
                     RelevantDataFilter {{RelevantDataFilter}} -->
                        <v-text-field v-model="NewResponse.Title" label="Title"/>
                        <v-select v-model="NewResponse.Type" label="Type" item-text="Name" :items="ResponseTypes" return-object/>
                        <v-combobox
                    v-model="NewResponse.KeyWords"
                    :items="items"
                    label="Response KeyWords"
                    multiple
                    chips
                    >
                    <template v-slot:selection="data">
                        <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                        </v-chip>
                    </template>
                    </v-combobox>
                        <v-layout class="justify-center" v-if="NewResponse.Type && NewResponse.Type.Name !== 'Virtual Assist'">
            <v-btn-toggle dark>
                
                <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                H1
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                H2
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                H3
                </v-btn>
                <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                <v-icon>mdi-format-paragraph</v-icon>
                </v-btn>
                <v-btn @click="copy()">
                <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('delete',false,'')">
                <v-icon>mdi-delete-forever</v-icon>
                </v-btn>                      
                <v-btn onclick="document.execCommand('cut',false,'')">
                <v-icon>mdi-content-cut</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                <v-icon>mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                <v-icon>mdi-format-align-justify</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('justifyRight',false,'')">
                <v-icon>mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon>mdi-format-list-bulleted</v-icon>
                </v-btn>
                
                
            </v-btn-toggle>
            </v-layout>
                <v-layout class="justify-center" v-if="NewResponse.Type && NewResponse.Type.Name !== 'Virtual Assist'">
                            <v-btn-toggle dark>
                            <v-btn onclick="document.execCommand('undo',false,'')">
                                <v-icon>mdi-undo</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('redo',false,'')">
                                <v-icon>mdi-redo</v-icon>
                            </v-btn>
                            
                            <v-btn onclick="document.execCommand('bold', false, '');">
                                <v-icon>mdi-format-bold</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('italic', false, '');">
                                <v-icon>mdi-format-italic</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('underline', false, '')">
                                <v-icon>mdi-format-underline</v-icon>
                            </v-btn>
                            <v-btn @click="changeColor()">
                                <v-icon>mdi-format-color-fill</v-icon>
                            </v-btn>
                            <v-btn  onclick="document.execCommand('selectAll',false,'')">
                                <v-icon>mdi-select-all</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="link()">mdi-link</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="addphone()">mdi-phone</v-icon>
                            </v-btn>
                            <v-btn>
                                <v-icon @click="addemail()">mdi-email</v-icon>
                            </v-btn>
                            <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                                <v-icon>mdi-format-strikethrough</v-icon>
                            </v-btn>
                            </v-btn-toggle> 
                            
            </v-layout>
            <!-- <v-btn @click="UpdateResponse(option,resp)">Save</v-btn> -->
                        <v-card min-height="300" v-if="NewResponse.Type && NewResponse.Type.Name !== 'Virtual Assist'" contenteditable id="NewResponseinput" v-html="NewResponse.Response"/>
                        <v-list-item  v-if="NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-select v-model="NewResponse.ReturnType" :items="['Count']" label="Return Type" />
                        </v-list-item>
                        <v-list-item  v-if="NewResponse.ReturnType && NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-select v-model="NewResponse.RelevantDataFilter" :items="VirtualAssistEntities" item-text="DisplayName" label="Table" return-object/>
                        </v-list-item>
                        <v-list-item  v-if="NewResponse.RelevantDataFilter && NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-select v-model="NewResponse.FilterField" :items="RelevantDataFilterFields(NewResponse.RelevantDataFilter,NewResponse)" item-text="Name" label="Filter Field" return-object/>
                        </v-list-item>
                        <!-- <v-list-item v-if="NewResponse.RelevantDataFilter && NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-switch v-model="NewResponse.DynamicFilter" label="Dynamic Filter" />
                        </v-list-item>                     -->
                        <v-list-item>
                            <v-switch v-model="NewResponse.ExtendResponse" label="Extend Response" />
                        </v-list-item>
                        
                        <v-list-item v-if="NewResponse.ExtendResponse">
                            <v-combobox
                                v-model="NewResponse.Responses"
                                :items="ResponseExtensions"
                                label="Response KeyWords"
                                item-text="Title"
                                multiple
                                chips
                                />
                        </v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="CancelCreateNewResponse()">Cancel
                    </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="CreateNewResponse()">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
      
        <v-card class="siteconfigcard" style="margin-top: 60px;">          
    <v-card-title class="my-2 BoldBuilderGradient justify-start mediumoverline">
        Chatbot Manager<v-spacer></v-spacer>
        <v-btn @click="ActivateFAQDialog()">Add New</v-btn>
    </v-card-title>
            <v-card-text>
                <!-- <v-list> -->
                    <v-btn @click="CalculateSystemOptions()">System Calculate</v-btn>
                    <v-btn @click="AppendSystemMeta()">Append Meta</v-btn>
                    <v-btn @click="CreateSystemGreetings()">System Greetings</v-btn>
                    <v-btn @click="AddGlobalChatOption()">Add Topic</v-btn>
                    <v-btn @click="SaveAllGlobalChatOptions()">Save</v-btn>
                    <v-expansion-panels>
                        <v-expansion-panel v-for="option in ChatbotResponsesOptions" :key="option.itemObjKey">
                        <v-expansion-panel-header>
                            {{option.Title}}<v-spacer></v-spacer>{{option.id}}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                           <v-list>
                               <v-text-field v-model="option.Title" label="Title"/>
                               <v-select v-model="option.TopicCategory" :items="TopicCategories" label="Category"/>
                               <v-switch v-model="option.TopicSignedIn" label="Must be Signed In" />
                               <v-switch v-model="option.IsTopic" label="Is Topic" />
                               <v-combobox
                                v-model="option.KeyWords"
                                :items="items"
                                label="Topic KeyWords"
                                multiple
                                chips
                                >
                                <template v-slot:selection="data">
                                    <v-chip
                                    :key="JSON.stringify(data.item)"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    :disabled="data.disabled"
                                    @click:close="data.parent.selectItem(data.item)"
                                    >
                                    <v-avatar
                                        class="accent white--text"
                                        left
                                        v-text="data.item.slice(0, 1).toUpperCase()"
                                    ></v-avatar>
                                    {{ data.item }}
                                    </v-chip>
                                </template>
                                </v-combobox>
                                <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
                        </v-layout>
                            <v-layout class="justify-center">
                                        <v-btn-toggle dark>
                                        <v-btn onclick="document.execCommand('undo',false,'')">
                                            <v-icon>mdi-undo</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('redo',false,'')">
                                            <v-icon>mdi-redo</v-icon>
                                        </v-btn>
                                        
                                        <v-btn onclick="document.execCommand('bold', false, '');">
                                            <v-icon>mdi-format-bold</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('italic', false, '');">
                                            <v-icon>mdi-format-italic</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('underline', false, '')">
                                            <v-icon>mdi-format-underline</v-icon>
                                        </v-btn>
                                        <v-btn @click="changeColor()">
                                            <v-icon>mdi-format-color-fill</v-icon>
                                        </v-btn>
                                        <v-btn  onclick="document.execCommand('selectAll',false,'')">
                                            <v-icon>mdi-select-all</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="link()">mdi-link</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="addphone()">mdi-phone</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="addemail()">mdi-email</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                                            <v-icon>mdi-format-strikethrough</v-icon>
                                        </v-btn>
                                        </v-btn-toggle> 
                                        
                        </v-layout>
                        <v-btn @click="UpdateTopicResponse(option)">Save Topic Response</v-btn>
                                    <v-card min-height="150px" contenteditable :id="option.id+'input'" v-html="option.Response"/>
                                <v-btn @click="AddResponse(option)">Add Response</v-btn>
                               <!-- <v-combobox v-model="option.KeyWords" label="Key Words"/> -->
                               <!-- <v-autocomplete v-model="option.Response" label="How to Response"/> -->
                               <v-expansion-panels>
                                <v-expansion-panel v-for="resp in option.Responses" :key="resp.itemObjKey">
                                <v-expansion-panel-header>
                                    {{resp.Title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                <v-list>
                                  <v-list-item>
                                    <v-switch @click="AddResponse(option,resp)" v-if="resp.Type && resp.Type.Name === 'Virtual Assist'" v-model="resp.ReplicatebyFilter" label="Replicate with Filter" />
                                </v-list-item>
                                    <v-text-field v-model="resp.Title" label="Title"/>
                                    <v-select v-model="resp.Type" label="Type" item-text="Name" :items="ResponseTypes" return-object/>
                                    <v-combobox
                                v-model="resp.KeyWords"
                                :items="items"
                                label="Topic KeyWords"
                                multiple
                                chips
                                >
                                <template v-slot:selection="data">
                                    <v-chip
                                    :key="JSON.stringify(data.item)"
                                    v-bind="data.attrs"
                                    :input-value="data.selected"
                                    :disabled="data.disabled"
                                    @click:close="data.parent.selectItem(data.item)"
                                    >
                                    <v-avatar
                                        class="accent white--text"
                                        left
                                        v-text="data.item.slice(0, 1).toUpperCase()"
                                    ></v-avatar>
                                    {{ data.item }}
                                    </v-chip>
                                </template>
                                </v-combobox>
                                    <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
                        </v-layout>
                            <v-layout class="justify-center">
                                        <v-btn-toggle dark>
                                        <v-btn onclick="document.execCommand('undo',false,'')">
                                            <v-icon>mdi-undo</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('redo',false,'')">
                                            <v-icon>mdi-redo</v-icon>
                                        </v-btn>
                                        
                                        <v-btn onclick="document.execCommand('bold', false, '');">
                                            <v-icon>mdi-format-bold</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('italic', false, '');">
                                            <v-icon>mdi-format-italic</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('underline', false, '')">
                                            <v-icon>mdi-format-underline</v-icon>
                                        </v-btn>
                                        <v-btn @click="changeColor()">
                                            <v-icon>mdi-format-color-fill</v-icon>
                                        </v-btn>
                                        <v-btn  onclick="document.execCommand('selectAll',false,'')">
                                            <v-icon>mdi-select-all</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="link()">mdi-link</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="linkyoutubevideo()">mdi-youtube</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="addphone()">mdi-phone</v-icon>
                                        </v-btn>
                                        <v-btn>
                                            <v-icon @click="addemail()">mdi-email</v-icon>
                                        </v-btn>
                                        <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                                            <v-icon>mdi-format-strikethrough</v-icon>
                                        </v-btn>
                                        </v-btn-toggle> 
                                        
                        </v-layout>
                        <v-btn @click="UpdateResponse(option,resp)">Save</v-btn>
                        
                                    <div contenteditable :id="resp.id+'input'" v-html="resp.Response" v-if="resp.Type && resp.Type.Name !== 'Virtual Assist'"/>
                                    <!-- <v-card min-height="300" v-if="NewResponse.Type && NewResponse.Type.Name !== 'Virtual Assist'" contenteditable id="NewResponseinput" v-html="NewResponse.Response"/>
                        <v-list-item  v-if="NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-select v-model="NewResponse.ReturnType" :items="['Count']" label="Return Type" />
                        </v-list-item>
                        <v-list-item  v-if="DynamicFilter && NewResponse.Type && NewResponse.Type.Name === 'Virtual Assist'">
                            <v-select v-model="NewResponse.FilterField" :items="RelevantDataFilter.Fields" item-text="Name" label="Filter Field" return-object/>
                        </v-list-item> -->
                                    <v-list-item  v-if="resp.Type && resp.Type.Name === 'Virtual Assist'">
                                        <v-select v-model="resp.ReturnType" :items="['Count']" label="Return Type" />
                                    </v-list-item>
                                    <v-list-item  v-if="resp.ReturnType && resp.Type && resp.Type.Name === 'Virtual Assist'">
                                    <v-select v-model="resp.RelevantDataFilter" :items="VirtualAssistEntities" item-text="DisplayName" label="Table" return-object/>
                                </v-list-item>
                                    <v-list-item  v-if="resp.RelevantDataFilter && resp.Type && resp.Type.Name === 'Virtual Assist'">
                                      <v-select v-model="resp.FilterField" :items="RelevantDataFilterFields(resp.RelevantDataFilter,resp)" item-text="Name" label="Filter Field" return-object/>
                                  </v-list-item>
                                  <!-- <v-list-item v-if="resp.RelevantDataFilter && resp.Type && resp.Type.Name === 'Virtual Assist'">
                                      <v-switch v-model="resp.DynamicFilter" label="Dynamic Filter" />
                                  </v-list-item> -->
                                    <v-list-item>
                                        <v-switch v-model="resp.ExtendResponse" label="Extend Response" />
                                    </v-list-item>
                                    <v-list-item v-if="resp.ExtendResponse">
                                        <v-combobox
                                            v-model="resp.Responses"
                                            :items="ResponseExtensions"
                                            label="Response KeyWords"
                                            item-text="Title"
                                            multiple
                                            chips
                                            />
                                    </v-list-item>
                                </v-list>
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                           </v-list>
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
            </v-card-text>
        </v-card>
    </v-main>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'

export default {
  props: ['AppisDarkMode','CurrentRecordName','FriendsList','SystemEntities'],
  components: {SystemTicketsLogging},
  data() {
    return {
        DataFilters: [
          {
            CollectionArray: 'SerialNumbersArray',
            Fields: [
              {
                PrimaryField: 'Name',
              ParentLookupid: 'OEMid',
              ParentLookup: 'OEM',
              ParentField: 'Model',
              ParentFieldid: 'Modelid',
              ParentPrimary: 'Model_Number',
              Name: 'OEM',
              Type: 'Parent Field',
              idfield: 'OEMid',  
              ParentArray: 'ModelsArray',
              ParentQuery: 'ModelsQuery'            
              },
              {
              Name: 'Model',
              Type: 'Lookup Field',
              idfield: 'Modelid',
              PrimaryField: 'Model_Number'              
              },
            ],
            Processes: [
  {
    "ProcessStages": [
      {
        "IsHeader": true,
        "Automations": [],
        "Steps": [],
        "Type": "Stage",
        "Number": 1000001,
        "Name": "Inbound"
      },
      {
        "Steps": [],
        "Type": "Stage",
        "Automations": [],
        "Name": "Stock",
        "IsHeader": true,
        "Number": 1000002
      },
      {
        "Type": "Stage",
        "Automations": [],
        "IsHeader": true,
        "Steps": [],
        "Name": "Outbound",
        "Number": 1000003
      },
      {
        "Name": "Aftermarket",
        "Number": 1000004,
        "Type": "Stage",
        "Steps": [],
        "IsHeader": true,
        "Automations": []
      }
    ],
    "IsHeader": true,
    "Type": "Process",
    "ProcessStageInteger": 1000004,
    "Name": "Lifecycle",
    "Automations": [],
    "Number": 1000001,
    "DisplayName": "Lifecycle Process"
  }
],
            }
        ],
        FrequentlyAskedQuestions: [],
        DefaultBrandNewTopic: {
          Title: '',
              IsTopic: true,
              Responses: [],
                KeyWords: [
                
                ],
                TopicCategory: '',
                TopicSignedIn: true,
        },
        BrandNewTopic: {
          Title: '',
              IsTopic: true,
              Responses: [],
                KeyWords: [
                
                ],
                TopicCategory: '',
                TopicSignedIn: true,
        },
        NewTopicdialog: false,
        NewResponseDialog: false,
        EditedOption: '',
        DynamicFilter: false,
        NewResponse: {
            Title: '',
            KeyWords: [],
            LowKeyWords: [],
            Response: '',
            Type: '',
            TopicSignedIn: true,
            TopicCategory: '',

        },
        ResponseTypes: [
          {
            Name: 'Quirky',
            Triggers: [
                'opinion'
            ]
            },
            {
            Name: 'Search Result',
            Triggers: [
                'what'
            ]
            },
            {
            Name: 'System Technical',
            Triggers: [
                'why'
            ]
            },
          {
            Name: 'Greetings',
            Triggers: [
                'hello'
            ]
            },
            {
            Name: 'How To',
            Triggers: [
                'how'
            ]
            },
            {
            Name: 'Navigation',
            Triggers: [
                'where'
            ]
            },
            {
            Name: 'General',
            Triggers: [
                'learn'
            ]
            },
            {
            Name: 'Virtual Assist',
            Triggers: [
                'assist'
            ]
            }
            ],
        TopicCategories: ['Social','Database','Pages','Wiki','Media Library','Document Library','Greeting Lines'],
        items: [],
       UserRecord: '',
      RecordName: '',
      SocialQuickNavTop: '225px',
      socialquicknav: false,
      MessageDialog: false,
      MessagetoUser: {
            id: 1000001,
            Full_Name: 'Chatbot',
            Profile_Photo: 'https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAFullLogo_tiny?alt=media&token=16a7c732-b4ea-415f-b83f-94c6772ad319'
        },
      groups: [], 
      UsersArray: [],
      ReceivedMessages: [],
      SentMessages: [],
      Notifications: [],
      drawer:false,
      SearchView: false,
      search: '',
      
      ChatbotResponsesOptions: [],
      pubitems: [
        {
          DisplayName:'Social',
          Path:'/Social',
          SubItems: [
            {
            DisplayName: 'Members',
            Path: '/SiteMembers'
            },
            {
            DisplayName: 'Groups',
            Path: '/Groups'
            },
            {
            DisplayName: 'Blogs',
            Path: '/Blogs'
            },
            {
            DisplayName: 'Forums',
            Path: '/Forums'
            },
            {
            DisplayName: 'Polls',
            Path: '/Polls'
            },
            {
            DisplayName: 'Articles',
            Path: '/Articles'
            },
            {
            DisplayName: 'Events',
            Path: '/Events'
            },
          ]
        },
        
        {
          DisplayName:'Intranet',
          Path:'/Intranet',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  computed: {
    VirtualAssistEntities(){
      return this.SystemEntities.map(entity => {
        let entityobj = {
          id: entity.id,
          DisplayName: entity.DisplayName,
          CollectionArray: entity.DisplayName.split(' ').join('')+'Array',
          CollectionQuery: entity.DisplayName.split(' ').join('')+'Query',
          CollectionLocation: entity.DisplayName.split(' ').join('')
        }
        return entityobj
      })
    },
    RelevantDataFilter(){
      if(this.EditedOption && this.EditedOption.CollectionArray){
        return this.DataFilters.find(obj => obj.CollectionArray === this.EditedOption.CollectionArray)
        
      }
      
    },
      ResponseExtensions(){
          return this.ChatbotResponsesOptions.map(resp => {
              return resp.Responses
          }).flat()
      },
      
    
    Conversation(){
      let arr1 = this.SentMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.recipientid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        // msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
        //  msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.filter(msg => {
        if(this.MessagetoUser){
        return msg.senderid == this.MessagetoUser.id
        }
      }).map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
         msg.dark = false
        //  msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
        //  msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },

    ConversationAscending(){
      return this.Conversation.map(msg => {
        console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    AllUserConversations(){
      let arr1 = this.SentMessages.map(msg => {
        msg.ReadType = 'Sent'
        msg.Alignment = 'justify-end'
        msg.Color = 'blue messageblock'
        msg.dark = true
        // msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
        //  msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      let arr2 = this.ReceivedMessages.map(msg => {
        msg.ReadType = 'Received'
        msg.Alignment = 'justify-start'
        msg.Color = 'grey lighten-4 messageblock'
         msg.dark = false
        //  msg.SendTime = this.TimestampFormatterSTRING(msg.Created_On.toDate())
        //  msg.TimestampSort = this.DateFormatterwithTime(msg.SendTime)
        return msg
      })
      console.log(arr1)
      return arr1.concat(arr2)
    },
    AllUserConversationsAscending(){
      return this.AllUserConversations.map(msg => {
        console.log(msg.TimestampSort)
        return msg
      }).sort((a, b) => {
            var key1 = a.TimestampSort;
            var key2 = b.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    ConversingUsers(){
      return this.UsersArray.filter(user => {
        let recipientcheck = this.AllUserConversationsAscending.find(msg => msg.recipientid == user.id)
        let sendercheck = this.AllUserConversationsAscending.find(msg => msg.senderid == user.id)
        if(sendercheck){
          user.lastMessage = Object.assign({},sendercheck)
        //   user.SendTime = this.TimestampFormatterSTRING(sendercheck.Created_On.toDate())
        //   user.TimestampSort = this.DateFormatterwithTime(sendercheck.SendTime)
          return user
        }
        else if(recipientcheck){
          user.lastMessage = Object.assign({},recipientcheck)
        //   user.SendTime = this.TimestampFormatterSTRING(recipientcheck.Created_On.toDate())
        //   user.TimestampSort = this.DateFormatterwithTime(recipientcheck.SendTime)
          return user
        }
        
      }).map(user => {
        let sendercheck = this.UnreadMessages.find(msg => msg.senderid == user.id)
       
        if(sendercheck){
          user.Color = ' grey lighten-2'          
        }
        else{
          user.Color = ' white'
        }
        console.log(user)
        return user
      }).filter(user => {
        return user.id !== this.UserRecord.id
      }).sort((a, b) => {
            var key1 = b.TimestampSort;
            var key2 = a.TimestampSort;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    },
    UnreadMessages(){
      return this.AllUserConversationsAscending.filter(msg => {
        return !msg.Read === true && msg.ReadType === 'Received'
      })
    },
     UnreadNotifications(){
      return this.Notifications.filter(notificaton => {
        return !notificaton.Read === true
      })
    },
    ReadNotifications(){
      return this.Notifications.filter(notificaton => {
        return notificaton.Read === true
      })
    },
    AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return 'blue-grey darken-4 stickytop2'
        }
        else{
          return 'blue-grey lighten-5 stickytop2'
        }
      },
    userLoggedIn () {
      return this.$store.getters.user
    },
    },

  created() {
    this.ScrolltoTop()
    //this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
    this.UserRecord = this.userLoggedIn

    if (this.UserRecord && this.UserRecord.id) {
        //   console.log('this.UserRecord',this.UserRecord.id)
        //   let query = db.collection('notifications').where('Ownerid', '==', this.UserRecord.id)
        this.GetFrequentlyAskedQuestions()
          this.GetReponseOptions()  
          //this.getGroups()
        }
  },
  watch:{
        'search' (){
            this.CheckActivateSearch()
        },
      
  },
  methods: {
    ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    GetFrequentlyAskedQuestions(){
      db.collection('frequentlyaskedquestions').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let faq = {
                ...change.doc.data(),
                id: change.doc.id
            }
            faq.Title = faq.Question
            faq.Response = faq.Answer
              this.FrequentlyAskedQuestions.push(faq)
            } 
        })
      })
    },
      CreateSystemGreetings(){
        confirm('This will auto generate some greeting lines for you which simply gives users a feel that the chatbot actually interacts when they say thigns like "hello", do you want to continue?') && this.ProcessCreateSystemGreetings()
      },
      ProcessCreateSystemGreetings(){
        //Decided not to add defaultresponses. The logic will allow me toc reate them with ease, or else too many options may appear
        let StandardTopic = {
          Title: 'Standard Greeting',
              IsTopic: true,
                KeyWords: [
                'Hello',
                'Good Day',
                'Good Afternoon',
                'How are you',
                ],
                Response: `<p>Good Day</p>
                <p>How may I help you today?</p>`, 
                Responses: [
                  {
                      Title: 'I want to Learn More about the system',
                      KeyWords: [
                      'Learn',
                      'Learn More',
                      'Help Please',
                      'Can you Help',
                      'Please Help',
                      'About the System',
                      'The System',
                      'Need Help',
                      'I Need Help'
                      ],
                      Response: `<p>I can help!</p>
                      <p>What would you like to know?</p>`,
                      Type: {
                        Name: 'General',
                        Triggers: [
                          'learn'
                        ]
                      },
                      TopicSignedIn: true,
                      TopicCategory: 'Greeting Lines',

                  },
                ] ,              
                TopicCategory: 'Greeting Lines',
                TopicSignedIn: true,
        }
        this.CreateNewTopic(StandardTopic)
        
      },
      RelevantDataFilterFields(option,resp){
       let entity = this.SystemEntities.find(obj => obj.id === option.id)
       return entity.AllFields
      },
      CancelCreateNewResponse(){          
          this.NewResponseDialog = false
          this.EditedOption = ''
          this.NewResponse = ''
      },
      CreateNewResponse(){
          let inputelement = document.getElementById('NewResponseinput')
          console.log(inputelement)
          if(inputelement){
              let NewResponse = {
                //   active: true,
                    Response: inputelement.innerHTML, 
                    Title: this.NewResponse.Title,
                    KeyWords: this.NewResponse.KeyWords,
                    LowerKeyWords: this.NewResponse.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                    Type: this.NewResponse.Type,
                    TopicSignedIn: this.NewResponse.TopicSignedIn, 
            }
            if(this.NewResponse.ExtendResponse){
                NewResponse = this.NewResponse.ExtendResponse
            }
            if(this.NewResponse.Responses){
                NewResponse.Responses = this.NewResponse.Responses
            }
            this.AddResponsetoTopic(NewResponse)
          }
          else if(this.NewResponse.Type && this.NewResponse.Type.Name === 'Virtual Assist'){
            let NewResponse = {
                //   active: true,
                    
                    Title: this.NewResponse.Title,
                    KeyWords: this.NewResponse.KeyWords,
                    LowerKeyWords: this.NewResponse.KeyWords.map(keyword => {return keyword.toLowerCase()}),
                    Type: this.NewResponse.Type,
                    TopicSignedIn: this.NewResponse.TopicSignedIn, 
                    ReturnType: this.NewResponse.ReturnType,
                    DynamicFilter: true
            }
            if(this.NewResponse.ReturnType === 'Count'){
              NewResponse.Response = this.NewResponse.ReturnType+' of '+this.NewResponse.RelevantDataFilter .DisplayName
              NewResponse.CollectionArray = this.NewResponse.RelevantDataFilter.CollectionArray
              NewResponse.CollectionLocation = this.NewResponse.RelevantDataFilter.CollectionLocation
              NewResponse.CollectionQuery = this.NewResponse.RelevantDataFilter.CollectionQuery
              NewResponse.RelevantDataFilter = this.NewResponse.RelevantDataFilter
            }
            if(this.NewResponse.ExtendResponse){
                NewResponse = this.NewResponse.ExtendResponse
            }
            if(this.NewResponse.Responses){
                NewResponse.Responses = this.NewResponse.Responses
            }
            // if(this.DynamicFilter){
            //   NewResponse.DynamicFilter = this.DynamicFilter
            //   }
              if(this.NewResponse.FilterField){
                NewResponse.FilterField = this.NewResponse.FilterField
              }
              console.log(NewResponse)
            this.AddResponsetoTopic(NewResponse)
          }
      },
      AddResponsetoTopic(NewResponse){
          //alert('creating')
          db.collection('chatbotresponses').doc(this.EditedOption.id).collection('Responses').add(NewResponse).then(doc => {
              console.log('created a new doc '+doc.id)
          })
      },
      AppendSystemMeta(){
        //THIS IS DANGEROUS WE DON'T WANT TO JUST ADD RESPONSES, POPULATE LET USER CHOOSE. HOWEVER, IS THERE ANY POINT TO SYSTEM CALCULATE IF ROUTE META IS NOT APPENDED?
        //DOES IT CHANGE CHATBOT BEHAVRIOS, CAN CHATBOT HANDLE A TOPIC, THAT WAS NOT GIVEN META DATA WHERE META DATA MAY BE APPLICABLE? OR IS THIS ALL AVHA?
        //COULD BE NICE IF IT IS AVHA MEANS WE ALREADY AHVE A SEPARATION BUT YEAH HAVE TO INTERROGATE
        let routes = this.$router.getRoutes()
        let array = routes.filter(route => {
              return route.meta && route.meta.IsTopic
          })
          console.log(array)
        this.ChatbotResponsesOptions.map(topic => {
          
          let topicobj = array.find(obj => obj.meta.TopicName === topic.Title)
          console.log(topicobj,topic)
          if(topicobj && topicobj.meta.subcat){
            topic.CollectionArray = topicobj.meta.subcat.split(' ').join('')+'Array'
            topic.CollectionLocation = topicobj.path
            topic.CollectionQuery = topicobj.meta.subcat.split(' ').join('')+'Query'
            // db.collection('chatbotresponses').doc(topic.id).update({
            //   CollectionArray: topicobj.meta.subcat.split(' ').join('')+'Array',
            //   CollectionLocation: topicobj.path,
            //   CollectionQuery: topicobj.meta.subcat.split(' ').join('')+'Query',
            // }) 
          }
            if(topicobj && topicobj.meta.Security){
              topic.Security = topicobj.meta.Security
            //   db.collection('chatbotresponses').doc(topic.id).update({
            //   Security: topicobj.meta.Security
            // })
            }
          
          
        })
      },
      CalculateSystemOptions(){
        let routes = this.$router.getRoutes()
          let array = routes.filter(route => {
              return route.meta && route.meta.IsTopic
          })
          .map(route => {
              return route.meta
          })
          console.log(array)
          array.map(item => {
              let NewTopic = {
                //   active: true,
                    Title: item.TopicName,
                    IsTopic: item.IsTopic,
                    KeyWords: [item.TopicName],
                    LowerKeyWords: [item.TopicName.toLowerCase()],
                    TopicCategory: item.TopicCategory,
                    TopicSignedIn: true, 
            }
            //this.AddSelectedGlobalChatOption(NewTopic)
            this.ChatbotResponsesOptions.push(NewTopic)
          })
      },
      AddSelectedGlobalChatOption(Topic){
          let NewOption = {
            //   active: true,
                Title: Topic.Title,
                IsTopic: Topic.IsTopic,
                KeyWords: Topic.KeyWords,
                LowerKeyWords: Topic.LowerKeyWords,
                TopicCategory: Topic.TopicCategory,
                TopicSignedIn: true,               
                
          }
          db.collection('chatbotresponses').add(NewOption).then(doc => {
              console.log('added a new response '+doc.id)
          })
      },
      AddGlobalChatOption(){
        this.NewTopicdialog = true
          
      },
      CreateNewTopic(NewTopic){
        let ResponseOptions = JSON.parse(JSON.stringify(NewTopic.Responses))
        let inputelement = document.getElementById('NewResponseinput')
        if(inputelement){
          NewTopic.Response = inputelement.innerHTML
        }
        NewTopic.LowerKeyWords = NewTopic.KeyWords.map(keyword => {return keyword.toLowerCase()})
        delete NewTopic.Responses
        db.collection('chatbotresponses').add(NewTopic).then(doc => {
          console.log('created brand new topic under '+doc.id)
          if(ResponseOptions && ResponseOptions.length>0){
            ResponseOptions.map(opt => {
                    opt.LowerKeyWords = opt.KeyWords.map(keyword => {return keyword.toLowerCase()})
                    db.collection('chatbotresponses').doc(doc.id).collection('Responses').add(opt).then(optdoc => {
                        console.log('awesome we did option doc '+optdoc.id)
                        this.CancelCreateNewTopic()
                    })
                })
          }
          else{
           this.CancelCreateNewTopic() 
          }
          
        })
        
      },
      CancelCreateNewTopic(){
        this.BrandNewTopic = Object.assign({},this.DefaultBrandNewTopic)
        this.NewTopicdialog = false
      },
      SaveAllGlobalChatOptions(){
        this.ChatbotResponsesOptions.map(resp => {
            resp.LowerKeyWords = resp.KeyWords.map(keyword => {
                return keyword.toLowerCase()
            })
            let ResponseOptions = JSON.parse(JSON.stringify(resp.Responses))
            delete resp.Responses
            db.collection('chatbotresponses').doc(resp.id).set(resp).then(doc => {
                console.log('great we set the response')
                ResponseOptions.map(opt => {
                    opt.LowerKeyWords = opt.KeyWords.map(keyword => {return keyword.toLowerCase()})
                    db.collection('chatbotresponses').doc(resp.id).collection('Responses').doc(opt.id).set(opt).then(optdoc => {
                        console.log('awesome we did option doc')
                    })
                })
                
            })
        })
        
    },
      
    GetReponseOptions(){
        db.collection('chatbotresponses').onSnapshot(res => {
            let listlength = res.docs.length
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                    let obj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                obj.Responses = []
                     db.collection('chatbotresponses').doc(change.doc.id).collection('Responses').onSnapshot(responsedocs => {
                         const respchanges = responsedocs.docChanges();
                         let resplistlength = responsedocs.docs.length
                        if(resplistlength === 0){
                            this.ChatbotResponsesOptions.push(obj)
                        }
                        else{
                          respchanges.forEach(respchange => {
                                if (respchange.type === 'added') {
                                    obj.Responses.push({
                                        ...respchange.doc.data(),
                                        id: respchange.doc.id
                                    })
                                }
                            })  
                            if(obj.Responses.length === resplistlength){
                                this.ChatbotResponsesOptions.push(obj)
                            }
                        }
                        
                     })
                
                }  
                if(this.ChatbotResponsesOptions.length === listlength){
                    console.log(this.ChatbotResponsesOptions)
                }
            })
            
        })
    },
      UpdateResponse(option,resp){
          let inputelmnt = document.getElementById(resp.id+'input')
          if(inputelmnt){
            db.collection('chatbotresponses').doc(option.id).collection('Responses').doc(resp.id).update({
             Response: inputelmnt.innerHTML
         })  
          }
          
      },
      UpdateTopicResponse(option){
          let inputelmnt = document.getElementById(option.id+'input')
          if(inputelmnt){
            db.collection('chatbotresponses').doc(option.id).update({
             Response: inputelmnt.innerHTML
         })  
          }
          
      },
      AddResponse(option,resp){
          this.NewResponseDialog = true
          this.EditedOption = option
          if(resp){
            this.NewResponse = Object.assign({},resp)
            delete this.NewResponse.id
            this.NewResponse.DynamicFilter = true
            this.DynamicFilter = true
          }
        //   if(!option.Responses){
        //       option.Responses = []
        //   }
         
        //   let NewResponse = {
        //             Type: 'How To',
        //             KeyWords: ['View'],
        //             Title: 'How to view Group Blog',
        //             Response: 'That is super easy just follow the link'
        //         }
        //    db.collection('chatbotresponses').doc(option.id).collection('Responses').doc(NewResponse.id).set(NewResponse).then(optdoc => {
        //                 console.log('awesome we did option doc')
        //             })
          
        //    option.active = false
        //    setTimeout(() => {
        //         option.active = true
        //    }, 10);
      },
      changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
    linkyoutubevideo(){
        this.Youtubelink = prompt("Enter the link")
        let validation = this.rules.youtubeurl(this.Youtubelink)

        if(validation === true){
        
        // let finalembed = `<iframe width="560" height="315" src="`+this.ComputedYoutubelinkEmbed+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        document.execCommand("insertHTML", false, this.ComputedYoutubelinkEmbed)
        this.Youtubelink = ''
        }
        else{
            confirm(validation) && this.linkyoutubevideo()
        }
    },


    copy() {
      document.execCommand("copy", false, "");
    },
    
    ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
    
    
    IntranetViewToggle(){
      confirm('This will take you to the data version of the App, do you want to continue?') &&
        this.$emit('IntranetViewToggle',false)
      },
      CancelSubmitSystemTicket(){
      this.TicketLog = false
    },
      ActivateTicketLog(){
        this.UsersArray = [this.UserRecord]
        this.TicketLog = true
      },
    
    
  }

}
</script>

<style>
.messagebox{
  position: fixed;
  bottom: 60px;
  transition: right 0.5s;
  z-index: 101;
}
.messageblock {
  border: none;
  cursor: pointer;
  border-radius: 25px;
}
.messagepost{
  outline: rgb(111, 204, 247);
  display: inline-block;
  padding: 0 25px;
  height: 35px;
  width: 90%;
  font-size: 16px;
  line-height: 50px;
  border-radius: 25px;
  border-color: rgb(231, 231, 231);
  border-style: solid;
  background-color: #f0f2f5;
}
.messagelist {
  height:200px;/* or any height you want */
  overflow-y:auto
}
.messageboxheader{
  z-index: 105;
}
.systembar{
    background-repeat: repeat;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
.waytop{
  z-index: 100;
}
.searchinput{
  background-color: white;
  color: #474747;
}
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  top: 30px;
  z-index: 4
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 4
}

</style>
    

    