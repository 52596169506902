
<template>

    <v-card :class="tab.RowTransparency+' '+tab.CustomClass" :color="tab.RowColor" flat :height="tab.RowHeight" tile v-if="tab.Name === 'Image'">
        <v-layout :class="tab.Justify" row :style="'margin-top:'+tab.RowMarginTop+'px;margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'" v-if="tab.ComparingImages">
            <ImageRenderComparisonComp v-for="(img,imgindex) in ComparedImages" :key="img.itemObjKey"
                    :tab="tab" :style="imgindex === 1 ? 'margin-top: -'+tab.Height+'px;clip-path: polygon(0% 0%,'+ClipPosition+'% 1%,'+ClipPosition+'% 100%,0% 100%);' : ''"
                        :img="img" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                    />
            <input
           :style="'height: '+tab.Height+'px;z-index:3;'"
          type="range"
          min="0"
          max="100"
          :value="ClipPosition"
          aria-label="Percentage of before photo shown"
          class="comparisonslider"
        />
        <!-- <v-slider :style="'height: '+tab.Height+'px;z-index:3;'" v-model="ClipPosition" class="comparisonslider" min="0" max="100">
                </v-slider> -->
        <div class="comparisonslider-line" :style="'left: '+ClipPosition+'%'" aria-hidden="true"></div>
        <div class="comparisonslider-button" :style="'left: '+ClipPosition+'%'" aria-hidden="true">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            <rect width="256" height="256" fill="none"></rect>
            <line
              x1="128"
              y1="40"
              x2="128"
              y2="216"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="16"
            ></line>
            <line
              x1="96"
              y1="128"
              x2="16"
              y2="128"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="16"
            ></line>
            <polyline
              points="48 160 16 128 48 96"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="16"
            ></polyline>
            <line
              x1="160"
              y1="128"
              x2="240"
              y2="128"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="16"
            ></line>
            <polyline
              points="208 96 240 128 208 160"
              fill="none"
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="16"
            ></polyline>
          </svg>
        </div>
                <!-- <v-slider v-model="ClipPosition" min="0" max="100">
                </v-slider> -->
        </v-layout>
            <v-layout :class="tab.Justify" row :style="'margin-top:'+tab.RowMarginTop+'px;margin-bottom:'+tab.RowMarginBottom+'px;margin-left:'+tab.RowMarginLeft+'px;margin-right:'+tab.RowMarginRight+'px'" v-if="!tab.ComparingImages">
                   <!-- <figure> -->
                    
                    <ImageRenderComp v-for="item in RotatingImageTabs" :key="item.itemObjKey"
                        :tab="item" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                        :IMGProp="'ActiveIMG'" :style="item.IMGCycleStyle"
                    />
                    <ImageRenderComp v-if="tab.IMGRotation"
                        :tab="CycleTabs[1]" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                        :IMGProp="'ActiveIMG'" :style="'opacity: 0;'"
                    />
                     <ImageRenderComp  v-if="!tab.IMGRotation"
                        :tab="tab" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                        :IMGProp="'IMG'"
                    />
                    
               
                   <!-- </figure> -->
                        <v-layout style="position: absolute;width:100%;"  row :class="tab.LayoutValue+' mx-3'" v-if="tab.HasTextBox">
                        <v-flex :class="' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall+' mx-2 '"  :style="tab.FlexValue" v-if="tab.HasTextBox">
                            <v-card :class="tab.BoxTransparency"  :color="tab.BGColor.hex" :elevation="tab.Elevation" style="margin:10px;padding:10px" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" >
                               <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                           <HTMLDescription
                                    :tab="tab" :PageContentFont="PageContentFont"
                                    />
                        <v-card-actions v-if="tab.HasActionButton && tab.ButonColor">
                            <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex+';'">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </v-card>
                            </v-flex>
                        </v-layout>
                        <v-layout v-if="tab.HasLogo && tab.LogoIsBottom" row :class="'justify-'+tab.LogoJustify">
                        <v-avatar size="120" tile >
                        <v-img contain v-if="!tab.LogoSRC"
                         src="@/assets/ImageHolder.png"
                        />
                        <v-img contain v-if="tab.LogoSRC"
                         :src="tab.LogoSRC"
                        />
                        </v-avatar>
                        
                        </v-layout>
                        </v-layout>   
                        </v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
import ImageRenderComp from '@/components/WebPages/RenderComponents/ImageRenderComp';
import ImageRenderComparisonComp from '@/components/WebPages/RenderComponents/ImageRenderComparisonComp';



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i'],
    components: {
        TitleTab,
        HTMLDescription,
        ImageRenderComp,
        ImageRenderComparisonComp

        },
    data() {
    return {  
        ClipPosition: 50,
        CycleModel: 0,
        IMGVar: '',  
        TabReady: false,  
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        ComparedImages(){
            if(this.tab.Assets && this.tab.ComparingImages){
                return this.tab.Assets.map((asset) => {
                    return asset.URL
                }).filter((url,i) => {
                    return i === 0 || i === 1
                })
            }
            else{
                return []
            }
        },
        RotatingImageTabs(){
            let newtab = JSON.parse(JSON.stringify(this.tab))
            if(this.tab.Assets && this.tab.IMGRotation && !this.tab.ComparingImages){
                return this.tab.Assets.map((asset) => {
                    let fintab = Object.assign({},newtab)
                    fintab.ActiveIMG = asset.URL
                    return fintab
                }).map((fintab,assetindex) => {
                    if(assetindex === this.CycleModel){
                        fintab.IMGCycleStyle = `position: absolute;animation-name: imgfadein;
animation-timing-function: ease-in-out;
animation-iteration-count: 1;
animation-duration: `+this.Transition*2+`s;`
                    }
                    else{
                        fintab.IMGCycleStyle = 'position: absolute;opacity: 0;transition: '+this.Transition+'s;'
                    }
                    return fintab
                })
            }
            else{
                return []
            }
        },
        CycleTabs(){
            let newtab = JSON.parse(JSON.stringify(this.tab))
            newtab.ActiveIMG = ''
            let tab1 = Object.assign({},newtab)
            tab1.ActiveIMG = this.tab.Assets[this.CycleModel].URL
            let tab2 = Object.assign({},newtab)
            tab2.ActiveIMG = this.tab.Assets[this.NextCycle].URL
            return [
                tab1,
                tab2
            ]
        },
        NextCycle(){
            let nr = 0
            let finalindex = this.tab.Assets.length-1
            if(this.CycleModel-1+2 < finalindex){
                nr = this.CycleModel-1+2
            }
            return nr
        },
        Transition(){
            return this.tab.CycleTime/(this.tab.CycleTime*2)
        },
        IMGCycleStyle(){
            let transition = this.Transition
            let style = `animation-name: imgfader;
animation-timing-function: ease-in-out;
animation-iteration-count: infinite;
animation-duration: `+1+`s;
animation-direction: alternate;`
            return style+'animation-delay: '+transition+(this.CycleModel*transition)+'s;'
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
    IMGRotation(){
        return this.tab.IMGRotation
    }
      
      
      
      
      

    },
    watch: {
        RotatingImageTabs: {
            handler: function(newvalue, oldvalue) {
              //console.log(oldvalue, newvalue)
              clearInterval(this.IMGVar)
                if(this.RotatingImageTabs.length > 0){                    
                    this.PrepareRotation(this.tab)
                }
            },deep: true
        }
    },
    created(){
       this.PrepareRotation(this.tab)
        this.CheckifParallaxClipped(this.tab)
        setTimeout(() => {
           if(this.tab.ComparingImages){
            document.querySelector('.comparisonslider').addEventListener('input', (e) => {
            this.ClipPosition = e.target.value
            })
        } 
        }, 1000);
        
    },
    beforeDestroy() {
        clearInterval(this.IMGVar)
    },
    
    methods: {
        PrepareRotation(tab){
             if(this.tab.IMGRotation){
                if(this.tab.Assets.length > 0){
                    this.IMGVar = setInterval(this.CycleTabImage,this.tab.CycleTime) 
                }
                else{
                    this.tab.ActiveIMG = this.tab.IMG.URL
                }
            }
            else{
                clearInterval(this.IMGVar)
            }
        },
        CycleTabImage(){
            //console.log('firing reassign',this.tab)
            //clearInterval(this.IMGVar)this.CycleModel
            let finalindex = this.tab.Assets.length-1
            if(this.CycleModel-1+2 > finalindex){
                this.CycleModel = 0
            }
            else{
                this.CycleModel++
            }
            this.tab.ActiveIMG = this.tab.Assets[this.CycleModel].URL
        },
        TabFontStyle(tab){
            return tab.FontBGColor && !tab.FontUnderline ? 'background-color: '+tab.FontBGColor+';width: 50%;' :
            tab.FontUnderline && tab.FontUnderlineColor ? 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' :
            ''
        },
        TitleStyle(tab){
            if(tab.HeaderBGColor){
                return tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) :
                'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 
                'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
         GetBlob(asset){
            let vm = this
        // console.log(asset)
        return new Promise(function(resolve, reject) {
            
        var img = document.createElement("IMG");
        img.setAttribute('crossOrigin','anonymous')
        img.setAttribute('src',asset)
        document.body.appendChild(img)
        img.onload = function() {
          //console.log(img.naturalWidth)
        var c = document.createElement("canvas");
        c.setAttribute('crossOrigin','anonymous')
        var ctx = c.getContext("2d");
        c.width = img.naturalWidth
        c.height = img.naturalHeight
        ctx.drawImage(img, 0, 0);
        var dataURL = c.toDataURL("image/png");
        //console.log('dataURL',dataURL)
        document.body.removeChild(img)
        resolve(dataURL)
        //vm.TabReady = true
        //.replace(/^data:image\/(png|jpg);base64,/, "");    
        }
        })
        },
        CheckifParallaxClipped(tab){
        //console.log(tab)
        if(tab.Clipped && tab.ClippingIMG){
          this.GetBlob(tab.ClippingIMG).then(result => {
            
            tab.ClippingStyle = `-webkit-mask-image: url(`+result+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`
                                        // was -webkit-mask-size: auto;
                                        // console.log('clippingstyle',clippingstyle)
                                        //console.log(tab)
                                        this.TabReady = true
          })
          }
          else{
            this.TabReady = true
          }
      },
        CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){  
                     this.TabReady = true            
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                     this.TabReady = true
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                    return tab.ClippingStyle
                }
                
                
            } 
               
            else{
                this.TabReady = true
            }              
            
        },
        ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
     OpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex){
         this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex)
     },
     
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>

</style>

        

    