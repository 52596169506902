
          <template>
<v-card
      flat
      :class="userLoggedIn? 'transparent' : 'transparent'"
      
    >

  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the My Profile help sheet. What would you want to know?</div>
      </v-sheet>
    </v-bottom-sheet>
<v-layout class="justify-center">
  <v-flex lg8 md10 style="padding-bottom:50px;">
  <v-card
    class="mx-auto"
    width="100%"
    tile
  >
      <v-layout row class="justify-start" style="margin-top:40px;">
         <v-col
                  :xl="3" :lg="3" :md="3" :sm="3" :xs="3"
                  cols="3">
          <v-avatar
              color="grey"
              :size="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs? 100 :150"
              class="mx-10"                     
            >
            <v-img v-if="UserData.Profile_Photo" :src="UserData.Profile_Photo"></v-img>
            <v-img v-if="!UserData.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>
       </v-col>
       <v-col
                  :xl="8" :lg="8" :md="8" :sm="8" :xs="8"
                  cols="6"
                         class="mx-10" style="align-self:center;"                   
                  >
                   <v-divider style="border: 1px solid black;width:40%;margin: auto;"/>
                      <v-card-title class="justify-center mediumoverline" style="color:#000000;text-align: center;align-self:center;">
                      {{UserData.Full_Name}}
                      </v-card-title>
                      
                      <v-divider style="border: 1px solid black;width:40%;margin: auto;"/>
                       <v-card-title v-if="UserData.Position" class="justify-center" style="color:#000000;text-align: center;align-self:center;font-size:14px;">{{System.Name}} - {{UserData.Position}}</v-card-title>
       </v-col>
       
       
  </v-layout>
      <v-divider></v-divider>
      <v-btn v-if="Editing && usercanEdit" @click="UpdateUserValues()">Save</v-btn>
      <v-list
      v-list width="100%"
        nav
        dense
      >
      <v-list-item class="background justify-center">
        <v-chip-group >
          <v-chip :href="'callto:'+UserData.Mobile_Number" color="orange white--text">Call Me</v-chip>
          <v-chip :href="'callto:'+UserData.Mobile_Number" color="green white--text">Whatsapp</v-chip>
        </v-chip-group>
      </v-list-item>
     <v-divider>
      </v-divider>
      <v-list-item-group
        
          color="primary"
        >
      <v-list-item class="justify-center" v-for="link in CardLinks" :key="link.itemObjKey">
        <v-btn v-if="!Editing" :href="'//'+link.URL" width="100%" class="primary">{{link.Name}}</v-btn><v-icon @click="Editing = !Editing" v-if="usercanEdit">mdi-pencil</v-icon>
         <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="UserData" :field="link.FullField" @onPhotoFileselected="onPhotoFileselected"
          :CurrentEntity="NavlistEntity" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
          :SystemEntities="SystemEntities" :PrimaryFieldName="'Full_Name'" :RelatedObj="''" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            />
        <!-- <span v-if="Editing" :href="'//'+link.URL" width="100%" class="primary">{{link.Name}}</span> -->
      </v-list-item>
      
      </v-list-item-group>
       <v-divider>
      </v-divider>
        <v-list-item-group
        
          color="primary"
        >
         <v-layout class="justify-center" v-if="FinalBUAddress">
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-flex xl6 lg6 md6 sm12 xs12>
              <v-list-item-title class="caption" v-html="FinalBUAddressName"></v-list-item-title>
            </v-flex>
             <v-flex xl6 lg6 md6 sm12 xs12>
               <div class="mapouter">
                <div class="gmap_canvas">
                <iframe width="200" height="170" id="gmap_canvas" style="padding:10px;" :src="FinalBuAddressMapped" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
            </div></div>
            </v-flex>
        </v-layout>
          <v-list-item :href="'callto:'+UserData.Mobile_Number">
            <v-list-item-icon>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Mobile_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="UserData.Work_Number" :href="'callto:'+UserData.Work_Number">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Work_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="UserData.Fax_Number">
            <v-list-item-icon>
              <v-icon>mdi-fax</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Fax_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :href="'mailto:'+UserData.Email">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Email}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="System.ClientAppURL">
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{System.ClientAppURL}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>


        <v-divider>
      </v-divider>
      <v-list-item class="background justify-center">
       <v-img height="100" contain src="@/assets/logo.png"/>
      </v-list-item>
     <v-divider>
      </v-divider>
      <v-list-item class="justify-center">
        <v-chip-group>
          <v-icon color="#1773ea">mdi-facebook</v-icon>
          <v-icon color="#0077b0">mdi-linkedin</v-icon>          
        </v-chip-group>
      </v-list-item>
      <v-divider>
      </v-divider>
       <v-list-item class="justify-center">
        <v-btn :href="VCardLink">Download</v-btn>
      </v-list-item>
      </v-list>
  </v-card>
  </v-flex>
</v-layout>

  </v-card>
</template>




<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
export default {
  props: ['AppisDarkMode','System','SystemEntities','CategoricalNavlists'],
  middleware: 'auth',
components: {
  FieldValueEditerComponent
},
  data() {
    return {
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: '',
      
        VCardLink: '',
        Editing: false,
        UserisFeaturingGroupAdmin: false,
        
        VCardLink: '',
        EditingFeaturedMember: false,
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
        
      UserData: {
          tags: [],
          WriteupSections: []
      },
      
    }
  },

  created(){
      this.GetBusinessUser()
        if(this.userLoggedIn){
      this.IntranetViewToggle(true)
    }  
    // window.addEventListener('scroll', this.handleScroll)

  },
  computed: { 
    GroupCategoryTypes(){
      return this.System.Group_Categories ? this.System.Group_Categories.Options : []
    },
    CardLinks(){
      return this.NavlistFields ? this.NavlistFields.map(field => {
        let fieldobj = {
         Name: field.DisplayName,
         URL: this.UserData[field.Name] ,
         FullField: field,
        }
        return fieldobj
      }) : []
    },
    NavlistEntity(){
      return this.CategoricalNavlists.find(obj => obj.id === 'Business_Members')
    },
    NavlistFields(){
      return this.NavlistEntity ? this.NavlistEntity.AdditionalFields : []
    },
    usercanEdit(){
      return this.userIsAdmin
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    FinalBuAddressMapped(){
      return this.FinalBUAddress+'&t=&z=13&ie=UTF8&iwloc=&output=embed'
    },
    FinalBUAddress(){
    let combinedlink = this.UserData.AddressLine1+'+'+this.UserData.AddressLine2+'+'+this.UserData.AddressLineCity+'+'+this.UserData.AddressLinePostalCode+'+'+this.UserData.AddressLineProvince+'+'+this.UserData.AddressLineCountry
    let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
    return FinalLink
    },
    FinalBUAddressName(){
      return `<p>`+this.UserData.AddressLine1+`<br>
      `+this.UserData.AddressLine2+`<br>
      `+this.UserData.AddressLineCity+`<br>
      `+this.UserData.AddressLinePostalCode+`<br>
      `+this.UserData.AddressLineProvince+`<br>
      `+this.UserData.AddressLineCountry+`</p>`
    
    },

   
    },
  methods: {
    UpdateUserValues(){
      this.NavlistFields.map(field => {
        if(typeof this.UserData[field.Name] !== 'undefined'){
          db.collection('businessmembers').doc(this.$route.params.id).update({
            [field.Name]: this.UserData[field.Name]
          })
        }
      })
    },
    ProcessingData(boolean){
            this.FormProcessing = boolean
        },
      ConfirmSubmission(tab,obj,docid){
            console.log(tab,obj,docid)
            tab.FormSubmitted = true
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record here`
        },
    CreateVCard(member){      
         let item = `BEGIN:VCARD%0D%0AVERSION:3.0%0D%0AN;CHARSET=utf-8:`+member.Surname.split(' ').join('%20')+`;`+member.Name.split(' ').join('%20')+`%0D%0AFN;CHARSET=utf-8:`+member.Name.split(' ').join('%20')+`%20`+member.Surname.split(' ').join('%20')
         if(member.Position){
           item = item+`%0D%0AORG;CHARSET=utf-8:Company%0D%0ATITLE;CHARSET=utf-8:`+member.Position.split(' ').join('%20')
         }
         if(member.AddressLine1 && member.AddressLine2 && member.AddressLineCity && member.AddressLinePostalCode && member.AddressLineProvince && member.AddressLineCountry){
 
           item = item+`%0D%0AADR;WORK;PREF;CHARSET=utf-8:;;`+member.AddressLine1.split(' ').join('%20')+`;`+member.AddressLineCity.split(' ').join('%20')+`;`+member.AddressLineProvince.split(' ').join('%20')+`;`+member.AddressLinePostalCode.split(' ').join('%20')+`;`+member.AddressLineCountry.split(' ').join('%20')
         }
         if(member.Email){
           item = item+`%0D%0AEMAIL:`+member.Email
         }
         if(member.Work_Number){
           item = item+`%0D%0ATEL;TYPE=WORK,VOICE:`+member.Work_Number
         }
         if(member.Mobile_Number){
           item = item+`%0D%0ATEL;TYPE=CELL,VOICE:`+member.Mobile_Number
         }
         if(member.Fax_Number){
           item = item+`%0D%0ATEL;TYPE=WORK,FAX:`+member.Mobile_Number
         }
         if(member.Birthday){
           let birthdayjava = member.Birthday.toDate()
           let cardbday = format(birthdayjava,'yyyy-MM-dd')
           item = item+`%0D%0ABDAY;VALUE=DATE:`+cardbday
         }
         
         item = item+`%0D%0AEND:VCARD`
         console.log(item)
      return item
       },

    isOdd(num) {
      return num % 2;
      },
    IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      
    DateFormatter(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
    },
    
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    
      
    ResetFilters() {},
    
    ResetSearch() {
      this.search = ''
    },
      
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      
      GetBusinessUser() {
          let vm = this
          db.collection('businessmembers').doc(this.$route.params.id).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            this.UserData = userdata
            let vcard = vm.CreateVCard(userdata)
            this.VCardLink = 'data:text/vcard;charset=UTF-8,'+vcard
            console.log(this.VCardLink)
          })
        },
  }
}


</script>

<style>

.gmap_canvas {overflow:hidden;background:none!important;height:170px;width:200px;}
.mapouter{position:relative;text-align:right;height:170px;width:200px;}
</style>
    

    