<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
       <v-dialog v-model="Attending_UsersSTDDialog"  max-width="500px">
      <v-card width="100%">
        <v-card-title>
          Dialog
        </v-card-title>
        <v-card-text>
          <v-layout class="mx-3" v-for="field in Attending_Usersheaders" :key="field.itemObjKey">
              <v-select v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.text" v-model="editedAttending_Users[field.value]"></v-select>                           
              <v-text-field v-if="field.Type === 'Single Line Text'" :label="field.text" v-model="editedAttending_Users[field.value]"></v-text-field>
              <v-text-field type="number" v-if="field.Type === 'Number Field'" :label="field.text" v-model="editedAttending_Users[field.value]"></v-text-field>
              <v-textarea v-if="field.Type === 'Multiple Lines Text' " :label="field.text" v-model="editedAttending_Users[field.value]"></v-textarea>
              <v-switch v-if="field.Type === 'Boolean' " :label="field.text" v-model="editedAttending_Users[field.value]"></v-switch>
              <v-autocomplete v-if="field.Type === 'Lookup' " :label="field.text" v-model="editedAttending_Users[field.value]"></v-autocomplete>
              <v-menu v-if="field.Type === 'Date'"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="200px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :label="field.text"
                    prepend-icon="mdi-calendar-month"
                    readonly
                    v-on="on"
                      v-model="editedAttending_Users[header.text]"
                  ></v-text-field>
                  </template>
                <v-date-picker></v-date-picker>
              </v-menu>
                <label :for="field.text">{{field.text}}</label>
              <input type="file"  v-if="field.Type === 'Single File Upload'" :id="field.text">
              </v-layout> 
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelAttending_UsersSTDDialog()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SaveAttending_UsersSTDDialog()">Save</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>

  <v-dialog v-model="Attending_UsersLookupDialog"  max-width="500px">
      <v-card width="100%">
        <v-card-title>
          Dialog<v-spacer></v-spacer><v-icon color="red" @click="DeactivateAttending_UsersLookupDialog()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-window  v-model="subcollectstep">
            <v-window-item v-for="(item,i) in SelectedSubcollectionArray" :key="item.itemObjKey" :value="i-1+2">
              
              <v-layout class="justify-center">
                  <v-card min-width=400px max-width=600px>
                    <v-card-title>
                      <span class="headline"><strong>Step {{i-1+2}} of {{SelectedSubcollectionArray.length}}</strong> - Link</span>
                    </v-card-title>
                    <v-card-text>
                      <v-layout class="mx-3" v-for="field in item.Fields" :key="field.itemObjKey">
                                  <v-select v-if="field.Type === 'Option Set'" :items="field.Options" item-text="Name" :label="field.text" v-model="field.Inputvalue"></v-select>
                                  <v-icon v-if="field.Type === 'Option Set'" v-model="field.Inputvalue">mdi-cogs</v-icon>                            
                                  <v-text-field v-if="field.Type === 'Single Line Text'" :label="field.text" v-model="field.Inputvalue"></v-text-field>
                                  <v-text-field type="number" v-if="field.Type === 'Number Field'" :label="field.text" v-model="field.Inputvalue"></v-text-field>
                                  <v-textarea v-if="field.Type === 'Multiple Lines Text' " :label="field.text" v-model="field.Inputvalue"></v-textarea>
                                  <v-switch v-if="field.Type === 'Boolean' " :label="field.text" v-model="field.Inputvalue"></v-switch>
                                  <v-autocomplete v-if="field.Type === 'Lookup' " :label="field.text" v-model="field.Inputvalue"  :items="field.array" :item-text="field.identifier"></v-autocomplete>
                                  <v-menu v-if="field.Type === 'Date'"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="200px"
                                  v-model="field.Menu"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        :label="field.text"
                                        prepend-icon="mdi-calendar-month"
                                        readonly
                                        v-on="on"
                                         v-model="field.Inputvalue"
                                      ></v-text-field>
                                      </template>
                                    <v-date-picker v-model="field.Inputvalue" @input="field.Menu = false"></v-date-picker>
                                  </v-menu>
                                   <label :for="field.text">{{field.text}}</label>
                                  <input type="file"  v-if="field.Type === 'Single File Upload'" :id="field.text">
                                  </v-layout>     
                    </v-card-text>
                    <v-card-actions>
                      <v-btn
                        :disabled="subcollectstep === 1"
                        text
                        @click="subcollectstep--"
                      >
                        Back
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                      v-if="i-1+2 < SelectedSubcollectionArray.length"
                        color="primary"
                        depressed
                        @click="CreateSubCollectionItem(item), subcollectstep++"
                      >
                        Create and Next
                      </v-btn>
                      <v-btn
                      v-if="i-1+2 === SelectedSubcollectionArray.length"
                        color="primary"
                        depressed
                        @click="CreateSubCollectionItem(item),Attending_UsersLookupDialog = !Attending_UsersLookupDialog"
                      >
                        Create and Finish
                      </v-btn>
                    </v-card-actions>
                  </v-card>
              </v-layout>
            </v-window-item>
          </v-window>
        </v-card-text>
      </v-card>
    </v-dialog>
  <LookupComponentSubCollections @LinkSubCollectionArray="LinkSubCollectionArray" :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @CancelSelection="CancelSCSelection" @CancelAddParentDialog="CancelSCAddParentDialog" :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="FieldFilter" :RelatedObj="RelatedObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup" :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="SCViewExistingLookupDialog" :SubCollectionLookupArrays="SubCollectionLookupArrays" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
      <!-- <v-row class="mx-3">
        <v-col
          v-for="card in SiteEventsFilteredandSearchedPaginated" :key="card.id"
          class="d-flex child-flex"
          >
          <v-card shaped
         :dark="AppisDarkMode"
        class="my-3 mx-3"
        max-width="400px"
            >
            <v-card-title class="pop white--text">
                           <i>{{card.status}}</i>
                        </v-card-title>
            <v-card-title class="largeoverline">
                    {{card.name}}
                  </v-card-title>
                  <v-card-subtitle>
                            <v-img width="350px" :src="card.EventImage" max-height="150px" contain  style="background-color:white;"/>
                        </v-card-subtitle>
              <v-list-item>
                <v-list-item-content>
                  
                  
                  <v-card-subtitle >
                    {{card.details.substr(0,150)}}
                  </v-card-subtitle>
                  <v-card-subtitle v-if="!card.details">
                   <i class="caption"> There is no information in the details Field of this record as yet</i>
                  </v-card-subtitle>
                </v-list-item-content>
              
            </v-list-item>
            <v-card-actions :class="VCardActionDarkMode">
              <v-btn
                  color="accent"
                  text :to="'/SiteEvent/'+card.id"
                >
                  View
                </v-btn>
              <v-btn
                  color="accent"
                  text @click="editItem(card)"
                >
                  Edit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-card outlined :dark="AppisDarkMode" width="100%">
        <v-pagination
        :total-visible="10"
          v-model="CardPage"
          circle
          :length="Math.round(SiteEventsFilteredandSearched.length/cardsPerPage)"
        ></v-pagination>
        
        <v-text-field
          :value="cardsPerPage"
          label="Items per page"
          type="number"
          min="-1"
          max="15"
          @input="cardsPerPage = parseInt($event, 10)"
        ></v-text-field>
        </v-card> -->
        <div v-if="!ActiveRecord">
        <v-list  :dark="AppisDarkMode">
          <v-list-item v-for="event in MenuItem.Array" :key="event.itemObjKey" @click="PushItem(event)">
            <!-- <span v-html="event.Notice"/> -->
                    <v-list-item-icon>
                    <v-icon small>mdi-calendar-star</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title :class="event.class">{{ event.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="event.NotificationCount && event.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="event.NotificationCount && event.NotificationCount>0" overlap>
                            <span slot="badge"> {{event.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="event.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action>
                </v-list-item>
        </v-list>
        </div>
        <!-- <SiteEvents v-if="!ActiveRecord" @PushItem="PushItem" :AppisDarkMode="AppisDarkMode" :MyDashboardView="true" /> -->
        <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <!-- <v-btn :to="'/Event/'+ActiveRecord.id">View</v-btn> -->
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <v-tabs v-if="ActiveRecord" centered> 
            <v-tab>
                Planning
            </v-tab>
            <v-tab-item>
                Kind of like a Tab of Tabs, like micro has on site settings
                Here is where the bulk of the work actualy happens. In this section some of the things become "records of their own" due to the level of complexity.
                <p>Bear in mind different event types as in Exhibition, Conference, Training, Self Host, etc</p>
                <ul>
                    <li>
                        Meeting minutes
                    </li>
                    <li>
                        Event Layout Planning
                    </li>
                    <li>
                        Logistics and Assets (where we start manging Event Stock, custom entities marked for event assets like ser # etc)
                    </li>
                    <li>
                        Promo Items
                    </li>
                    <li>
                        By now it is obvious these things are a little cluttered but we want to manae things as in 5 steps. Bloew is a sample
                    </li>
                </ul>
               <v-list>
                   <v-list-item>
                       <v-list-item-content >
                          <v-list-item-title>Event Layout</v-list-item-title>                    
                          <v-list-item-subtitle>
                            <v-progress-linear value="15"></v-progress-linear>
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            Book the Stand
                          </v-list-item-subtitle>
                          
                        </v-list-item-content>
                   </v-list-item>
               </v-list>
                

            </v-tab-item>           
            <v-tab>
                Attendees
            </v-tab>
            <v-tab-item>
                <v-data-table
                        :dark="AppisDarkMode"
                        :headers="Attending_Usersheaders"
                        :items="Attending_Users"
                        class="elevation-1"
                        >
                        <template v-slot:[`item.LookupObj`]="{ item }">
                          <span v-if="item.LookupObj">
                            <router-link :to="'/'+item.LookupObj.single+'/'+item.LookupObj.id">{{item.LookupObj[item.LinkedFromIdentifier]}}</router-link>
                          </span>
                        </template>
                      <template v-slot:[`item.action`]="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editAttending_UsersItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="deleteAttending_UsersItem(item)"
                      >
                        mdi-trash-can-outline
                      </v-icon>
                    </template>

                    </v-data-table>
            </v-tab-item>
            
            <v-tab>
                Mailing List
            </v-tab>
            <v-tab-item>
                MailChimp API
            </v-tab-item>
            <v-tab>
                Photos
            </v-tab>
            <v-tab-item>
                <v-card flat min-height="200px"  :dark="AppisDarkMode">
            <v-btn v-if="CanEditEventPhotos && !EditingEventPhotos" @click="EditingEventPhotos = !EditingEventPhotos"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-pencil</v-icon></v-btn><v-btn v-if="EditingEventPhotos" @click="EditingEventPhotos = !EditingEventPhotos,CancelEditingEventPhotosDialog()"
                          fab
                          small raised color="pop"
                          top
                          left
                          absolute
                          
                        ><v-icon color="white">mdi-cancel</v-icon></v-btn>
                        
                      <v-dialog v-model="UploadingEventPhotos" max-width="500px">
                   <v-card width="100%" min-height="300">
                          <v-img
                        width="500"
                        src="@/assets/RABaseBG-Soft.png"
                        ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                          <v-card-title class="mediumoverline white--text">
                            Uploading...
                          </v-card-title></div>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="EventPhotosprogressbar" style="display:none">
                            <ul id="EventPhotosprogressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                      </v-img>
                   </v-card>
                 </v-dialog>
               
              <v-pagination
                v-model="EventPhotosPage"
                :length="EventPhotosImagesUploads.length/12"
                :total-visible="7"
              ></v-pagination>
              <v-dialog v-model="EventPhotosSlideShowDialog" max-width="800">
              <v-carousel v-model="EventPhotosSlideShow">
                  <v-carousel-item
                    v-for="card in EventPhotosImagesUploadsPagination"
                    :key="card.itemObjKey"
                  >
                    <v-sheet
                      :color="color"
                      height="100%"
                      tile
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="display-3">
                          <!-- Image {{ i + 1 }} -->
                          <v-img :src="card.url" contain max-height="500"/>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
            </v-dialog>
            <v-layout class="justify-center">
            <input
                style="display: none"
                type="file"
                @change="AddEventPhotos($event)"
                ref="EventPhotosfileInputer"
                :multiple="true"
                accept="image/*"
                >
                <v-btn @click="$refs.EventPhotosfileInputer.click()" outlined  small class="elevation-6">
                  <v-icon>mdi-plus-thick</v-icon>Add Images</v-btn>

              <v-btn @click="EventPhotosSlideShowDialog = true" outlined  small class="elevation-6"><v-icon>mdi-file-presentation-box</v-icon>SlideShow</v-btn>
            </v-layout>
              <v-layout row class="align-center mx-3">
                 <v-col
                v-for="(card,i) in EventPhotosImagesUploadsPagination"
                :key="card.itemObjKey"
                class="d-flex child-flex"
                cols="2"
              >
              <v-hover
                v-slot:default="{ hover }"
                open-delay="200"
              >
                <v-card class="mx-auto"  @click="OpenorEditingEventPhotos(card,i)"
                 :elevation="hover ? 16 : 2">
                  <v-img
                    :src="card.ThumbURL"
                    :lazy-src="`https://picsum.photos/10/6?image=${i * 5 + 10}`"
                    aspect-ratio="1"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-row>
                    </template>
                     <v-expand-transition>
                     <div
                      v-if="hover && !EditingEventPhotos"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      View
                     </div>
                     <div
                      v-if="hover && EditingEventPhotos"
                      class="d-flex transition-fast-in-fast-out primary v-card--reveal display-1 white--text"
                      style="height: 100%;"
                    >
                      Edit
                     </div>
                   </v-expand-transition>
                  </v-img>
                </v-card>
              </v-hover>
                 </v-col>
              </v-layout>
                </v-card>
            </v-tab-item>
            <v-tab>
                Report
            </v-tab>
            <v-tab-item>
                The final output, optional could be blog
            </v-tab-item>
            <v-tab>
                Notifications
            </v-tab>
            <v-tab-item>
                <ItemNotifications :query="ComputedNotificationsQuery"
               />
            </v-tab-item>
            <v-tab>
                Location
            </v-tab>
            <v-tab-item>
                <v-btn dark color="green white--text" target="_blank" :to="ActiveRecord.EventLocationLink">
                    View on Google Maps</v-btn>
                    <v-text-field @change="UpdateEventLocationEmbed()" label="Insert Embedded Location" v-model="ActiveRecord.EventLocationEmbed"/>
                <v-layout row class="justify-center">
                <div v-html="ActiveRecord.EventLocationEmbed"/>
                </v-layout>
            </v-tab-item>
        </v-tabs>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
// import SiteEvents from '@/views/SiteEvents'
import LookupComponentSubCollections from '@/components/Database/LookupComponentSubCollection'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem'],
    components: {
        // SiteEvents,
        LookupComponentSubCollections,
        ItemNotifications

    },
    data(){
        return{
          SubCollectionLookupArrays: [],
            EventPhotosPage: 1,
            EventPhotosSlideShow: 1,
            EventPhotosSlideShowDialog: false,
            EventPhotosImagesUploads: [], 
            EditingEventPhotos: false,
            EditingEventPhotosDialog: false,
            editedEventPhotosItem: '',
            editedEventPhotosItemIndex: -1,
            EventPhotosEditOption: '',            
            UploadingEventPhotos: false,
            EventPhotostotallist: 0,
            EventPhotoslistuploadcount: 0,
            EventPhotosuploadTotal: 0,
            EventPhotosrunningpercentage: 0,
            EventPhotosrunningProgress: 0,
            EventPhotosuploadPercentage: 0,
            Attending_Usersheaders: [
        { text: 'Attendance Confirmation', value: 'Attendance_Confirmation', Type: 'Option Set',Options: [
  {
    "ID": 1000001,
    "Name": "Going"
  },
  {
    "Name": "Maybe",
    "ID": 1000002
  },
  {
    "Name": "Not Going",
    "ID": 1000003
  }
]},
        { text: 'Full Name', value: 'Full_Name', Type: 'Single Line Text'},
        { text: 'Name', value: 'Name', Type: 'Single Line Text'},
        { text: 'Needs Accomodation', value: 'Needs_Accomodation', Type: 'Boolean'},
        { text: 'Shirts Needed', value: 'Shirts_Needed', Type: 'Number Field'},
        { text: 'Surname', value: 'Surname', Type: 'Single Line Text'},
        { text: 'Linked From', value: 'LookupObj'},
        { text: 'Action', value: 'action'}
      ],
      Attending_Users: [],
      editedAttending_Users: {
      Attendance_Confirmation: '',
      Full_Name: '',
      Name: '',
      Needs_Accomodation: '',
      Shirts_Needed: '',
      Surname: '',
      },
      defaultAttending_Users: {
      Attendance_Confirmation: '',
      Full_Name: '',
      Name: '',
      Needs_Accomodation: '',
      Shirts_Needed: '',
      Surname: '',
      },
      Attending_Userssearch: '',
      Attending_UsersLookupArrays: [],      
      SelectedSubcollectionArray: [],
      subcollectstep: 1,
      SubCollectionItemDialog: '',
      SCViewExistingLookupDialog: false,
      SCAddNewParentLookupDialog: false,
      SCLookupArrays: [],
      SCFieldFilter: '',
      SCParentTitle: '',
      SCParentCollection:  '',
      SCNewParentPrimary: '',
      SCParentLookup: '',
      SCNewParentAdditional: [],
      SCLookupArray: [],
      SCLookupArrayHeaders: [],
      Attending_UsersLookupDialog: false,
      Attending_UsersSTDDialog: false,
            ActiveRecord: '',
        SiteEventsArray: [],
        search: '',
        cardsPerPage: 6,
        CardPage: 1,
        }
    },
    computed:{
      ComputedSiteEventsArray(){
        return this.SiteEventsArray
        .map(record => {
          if(this.MenuItem && this.MenuItem.Array){
            let arrayitem = this.MenuItem.Array.find(obj => obj.id === record.id)
            record.Notice = arrayitem.Notice
          }
          return record
        })
      },
      ComputedNotificationsQuery(){
            return db.collection('notifications').where('eventid','==',this.ActiveRecord.id)
        },
        EventPhotosImagesUploadsPagination(){
        let displaylimit = 12
        let displayrange = displaylimit*this.EventPhotosPage-displaylimit
        this.EventPhotosSlideShow = displayrange
        return this.EventPhotosImagesUploads.slice(displayrange, displayrange+displaylimit)
        },
        SiteEventsQuery(){
        return this.$store.state.SiteEventsQuery 
        },
        
        SiteEventsStore() {
        return this.$store.getters.getSiteEventsArray
        },
        SiteEventsSearched() {
            return this.SiteEventsArray.filter(SiteEvent => {
            if(SiteEvent.name){return SiteEvent.name.toLowerCase().includes(this.search.toLowerCase())}
            })
            },
        SiteEventsFilteredandSearchedPaginated(){
            let displayrange = this.cardsPerPage*this.CardPage-this.cardsPerPage
             return this.SiteEventsFilteredandSearched.slice(displayrange, displayrange+this.cardsPerPage)
        },
        SiteEventsFilteredandSearched() {
            return this.SiteEventsSearched
            .filter(siteevent => {
                return siteevent.Render === true
            })
            .filter(item => {
               return item.Ownerid === this.userLoggedIn.id
            })
            },
            userIsAdmin () {
                return this.$store.state.IsAdmin
            },
            userLoggedIn () {
                return this.$store.getters.user
            },
            userBUID () {
                return this.$store.getters.userBUID
            },
            userBUChildren () {
                return this.$store.getters.userBUChildren
            },
            userBUParents () {
                return this.$store.getters.userBUParents
            },
    },
    created(){
        this.GetQuery(this.MenuItem)
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined && value.id) {
              this.Attending_Users = []
          this.GetAttendingUsers(value.id)
          this.GetEventPhotos(value.id)
          }
      },
    },
    methods:{
      GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id)
                console.log(item.groupcollectionname,'where Administrators array-contains',this.userLoggedIn.id)
                this.GetEvents(query)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetEvents(query)
            }
        },
        UpdateEventLocationEmbed(){
            db.collection('siteevents').doc(this.ActiveRecord.id).update({
                EventLocationEmbed: this.ActiveRecord.EventLocationEmbed
            })
        },
        AddEventPhotos(event){
      this.$emit('ActivateMultipleFilesUploadNotification',true)   
      let fileList = event.target.files || e.dataTransfer.files
      this.EventPhotosrunningpercentage = 0
      this.EventPhotosrunningProgress = 0 
      //this.UploadingEventPhotos = true
      setTimeout(() => {
      this.EventPhotostotallist = fileList.length
      this.EventPhotosuploadTotal = fileList.length*100
      const topelmnt = document.getElementById('uploadfilesprogressbar')
      topelmnt.style.display = "block";
      const elmnt = document.getElementById('uploadfilesprogressgroupbar') 
      let oldtotalprogresselementptag = document.getElementById("uploadfilestotalprogressbarptag")
      let oldtotalprogresselement = document.getElementById("uploadfilestotalprogressbar")
      if(oldtotalprogresselementptag){
        oldtotalprogresselementptag.remove();
      }   

      if(oldtotalprogresselement){
        oldtotalprogresselement.remove();
      }
  
      if(elmnt.childNodes[0]){
        elmnt.innerHTML = '';
      }     
      const totalprogresselementptag = document.createElement('p')
      totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")
      totalprogresselementptag.setAttribute('id','uploadfilestotalprogressbarptag')
      const totalprogresselement = document.createElement('progress')
      totalprogresselement.setAttribute('id','uploadfilestotalprogressbar')
      totalprogresselement.setAttribute('max','100')
      totalprogresselement.setAttribute('value',1)
      var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.EventPhotostotallist+' files uploaded');
      totalprogresselementptag.appendChild(startingtotaltext);
      elmnt.append(totalprogresselement) 
      elmnt.append(totalprogresselementptag) 
      // this.totalelement  = document.createElement('p')
      Array.from(Array(fileList.length).keys()).map((x,index) => {
        this.PrepareEventPhotosThumbnails(fileList[x],index,fileList.length)
      })
      }, 300);
      },
      PrepareEventPhotosThumbnails(file,index,listlength){
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
              //this.EventPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            console.log(blobfile)
            var storageRef = firebase.storage().ref('SiteEvents/'+vm.$route.params.id+'/EventPhotos/'+ filename+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      console.log(thumburl)
                      vm.AddEventPhotosSingle(file,index,listlength,thumburl)
                    })
              })
      }
      },
      AddEventPhotosSingle(file,fileindex,listlength,thumburl) {
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        const totalprogresselement = document.getElementById('uploadfilestotalprogressbar')
        const totalprogresselementptag = document.getElementById('uploadfilestotalprogressbarptag')
        const elmnt = document.getElementById('uploadfilesprogressgroupbar')
        const singleuploadptag = document.createElement('p')
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")
        const singleuploaddiv = document.createElement('progress')
        singleuploaddiv.setAttribute('max','100')
        singleuploaddiv.setAttribute('value',1)
        var textnode = document.createTextNode("0% complete");
        singleuploaddiv.appendChild(textnode); 
        elmnt.append(singleuploaddiv) 
        elmnt.append(singleuploadptag)    
        let vm = this
        //const ImageArray = this.EventPhotosImagesUploads
        var storagelocation = 'SiteEvents/'+this.ActiveRecord.id+'/EventPhotos/'+new Date()+'/'+filename
        var storageRef = firebase.storage().ref(storagelocation);        
        var uploadTask = storageRef.put(file);
        uploadTask.on('state_changed', function(snapshot){
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                vm.EventPhotosuploadPercentage = Number(progress.toFixed(2))
                singleuploaddiv.setAttribute('value',vm.EventPhotosuploadPercentage)
                var textnode = document.createTextNode(vm.EventPhotosuploadPercentage+"% complete for "+filename);
                if(singleuploadptag.childNodes[0]){
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])
                }         // Create a text node
                singleuploadptag.appendChild(textnode);
                if(vm.EventPhotosuploadPercentage === 100){
                  vm.EventPhotosrunningProgress = vm.EventPhotosrunningProgress+vm.EventPhotosuploadPercentage
                  vm.EventPhotosrunningpercentage = Number(((vm.EventPhotosrunningProgress/vm.EventPhotosuploadTotal)*100).toFixed(2))                  
                  vm.EventPhotoslistuploadcount = vm.EventPhotoslistuploadcount-1+2
                  var totaltext = document.createTextNode(vm.EventPhotosrunningpercentage+'% complete  - '+vm.EventPhotoslistuploadcount+' of '+vm.EventPhotostotallist+' files uploaded');
                  totalprogresselement.setAttribute('value',vm.EventPhotosrunningpercentage)
                  if(totalprogresselementptag.childNodes[0]){
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])
                  }         // Create a text node
                  totalprogresselementptag.appendChild(totaltext);
                }
                switch (snapshot.state) {
                  case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused');
                    break;
                  case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running');
                    break;
                }
              }, function(error) {
                // Handle unsuccessful uploads
              }, function() {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                  console.log('File available at', downloadURL);
                });
              });
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      const newimage = {
                              Name: filename,
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Created_Byid: this.userLoggedIn.id,          
                              Created_On: new Date(), 
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Modified_Byid: this.userLoggedIn.id,
                              Modified_On: new Date(),
                              
                              StorageRef: storagelocation
                              }
                    //ImageArray.push(newimage)
                    db.collection('siteevents').doc(this.ActiveRecord.id).collection('eventphotosimagesuploads').add(newimage).then(imagedoc => {
                      console.log('created new doc '+imagedoc)
                      })
                      if(this.EventPhotosrunningpercentage === 100){
                      //this.UploadingEventPhotos = false
                      this.$emit('ActivateMultipleFilesUploadNotification',false)
                      this.EventPhotostotallist = 0
                      this.EventPhotosuploadTotal = 0
                      this.EventPhotosrunningpercentage = 0
                      this.EventPhotosrunningProgress = 0
                      this.EventPhotosuploadPercentage = 0
                      this.EventPhotoslistuploadcount = 0
                      //elmnt.style.display = "none";
                      }
                  })
      },
        DeactivateAttending_UsersLookupDialog(){
    this.Attending_UsersLookupDialog = false
  },
  editAttending_UsersItem (item) {
  this.editedIndex = this.Attending_Users.indexOf(item)
  this.editedAttending_Users = Object.assign({}, item)
  
  this.Attending_UsersSTDDialog = true
},
deleteAttending_UsersItem (item) {
  let deleteitem = this.Attending_Users.find(obj => obj.id === item.id)
  let deleteindex = this.Attending_Users.indexOf(deleteitem)
  confirm('This will delete the item from this list. Are you sure?') &&
  this.ProcessAttending_UsersItem(item,deleteindex)
},
ProcessAttending_UsersItem (item,deleteindex) {
  db.collection('siteevents').doc( this.ActiveRecord.id).collection('Attending_Users').doc(item.id).delete()
  this.Attending_Users.splice(deleteindex, 1)
},
  CancelAttending_UsersSTDDialog(){
      this.editedAttending_Users = Object.assign({},this.defaultAttending_Users)
      this.Attending_UsersSTDDialog = false
    },
    SaveAttending_UsersSTDDialog(){
      db.collection('siteevents').doc( this.ActiveRecord.id).collection('Attending_Users').doc(this.editedAttending_Users.id).set(this.editedAttending_Users).then(doc => {
        console.log('updated')
        this.CancelAttending_UsersSTDDialog()
      })
    },
  CreateSubCollectionItem(item){
    console.log(item)  
    let NewObj = {
    Created_By: item.Created_By,
    Created_Byid: item.Created_Byid,          
    Created_On: item.Created_On,
    Modified_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
    Modified_Byid: this.userLoggedIn.id,
    Modified_On: new Date(),
    LookupObj: item.LookupObj,
    LinkedFromIdentifier: item.LinkedFromIdentifier
  }
    let headersarray = this.Attending_Usersheaders.filter(header => {return header.value !== 'action' && header.value !== 'LookupObj'})
    headersarray.map(header => {
      let itemheaderobj = item.Fields.find(obj => obj.value == header.value)
      let itemheadervalue = itemheaderobj.Inputvalue
      NewObj[header.value] = itemheadervalue
    })  
     
    db.collection('siteevents').doc( this.ActiveRecord.id).collection('Attending_Users').add(NewObj).then(doc => {
      console.log("done obj")
    })
  },
  LinkSubCollectionArray(array,lookupobj){
    this.SelectedSubcollectionArray = array
    if(lookupobj.ParentType === 'Attending_Users' && lookupobj.ArrayName === 'Contacts'){
      this.SelectedSubcollectionArray.map(item => {
        console.log(item)
        item.Fields = JSON.parse(JSON.stringify(this.Attending_Usersheaders))
        let Full_NameObj = item.Fields.find(obj => obj.value === 'Full_Name')
        
        Full_NameObj.Inputvalue = item.Full_Name
          item.LookupObj = {
            Full_Name: item.Full_Name,
            id: item.id,
            list: 'Contacts',
            single: 'Contact'
          }
          item.LinkedFromIdentifier = 'Full_Name'
        return item
      })
      this.CancelSCAddParentDialog()
      console.log('this is Contacts for Attending_Users')
      this.Attending_UsersLookupDialog = true
      this.SubCollectionItemDialog = 'Attending_Users'
    }
    if(lookupobj.ParentType === 'Attending_Users' && lookupobj.ArrayName === 'Users'){
      this.SelectedSubcollectionArray.map(item => {
        console.log(item)
        item.Fields = JSON.parse(JSON.stringify(this.Attending_Usersheaders))
        let Full_NameObj = item.Fields.find(obj => obj.value === 'Full_Name')
        
        Full_NameObj.Inputvalue = item.Full_Name
        let NameObj = item.Fields.find(obj => obj.value === 'Name')
        
        NameObj.Inputvalue = item.Name
        let SurnameObj = item.Fields.find(obj => obj.value === 'Surname')
        
        SurnameObj.Inputvalue = item.Surname
          item.LookupObj = {
            Full_Name: item.Full_Name,
            id: item.id,
            list: 'Users',
            single: 'User'
          }
          item.LinkedFromIdentifier = 'Full_Name'
        return item
      })
      this.CancelSCAddParentDialog()
      console.log('this is Users for Attending_Users')
      this.Attending_UsersLookupDialog = true
      this.SubCollectionItemDialog = 'Attending_Users'
    }
    console.log(this.SelectedSubcollectionArray)
  },
    Attending_UsersdialogActivate(){

        this.SCViewExistingLookupDialog = true
        this.Attending_UsersLookupArrays = [
          {
          ArrayName:  'Contacts',
          ArrayData: this.ContactsSubColArrayComputed,
          ArrayHeaders: this.ContactsArrayHeaders,
          identifier: 'Full_Name',
          ParentType: 'Attending_Users'
          },
          {
          ArrayName:  'Users',
          ArrayData: this.UsersSubColArrayComputed,
          ArrayHeaders: this.UsersArrayHeaders,
          identifier: 'Full_Name',
          ParentType: 'Attending_Users'
          },
        ] 
        this.SubCollectionLookupArrays = this.Attending_UsersLookupArrays
    },
    
      GetContactsSubColArray(){
          db.collection('contacts').onSnapshot(res => {
              const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      this.ContactsSubColArray.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                  }
              })
          })
      },
      GetUsersSubColArray(){
          db.collection('users').onSnapshot(res => {
              const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      this.UsersSubColArray.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                  }
              })
          })
      },
      CancelSCAddParentDialog(){
      console.log('will cancel')
      this.SCViewExistingLookupDialog = false
      this.AddNewParentLookupDialog = false
      this.ParentTitle = ''
      this.ParentCollection = ''
      this.NewParentPrimary = ''
      this.ParentLookup = ''
      this.NewParentAdditional = []
      this.FieldFilter = ''
      this.LookupArray = []
      this.LookupArrayHeaders = []
      this.SubCollectionLookupArrays = []
    },

    CancelSCSelection(){
      this.CancelSCAddParentDialog()
      
    },
        GetEventPhotos(eventid){
            db.collection('siteevents').doc(eventid).collection('eventphotosimagesuploads').onSnapshot(res => {
                      const changes = res.docChanges();
                      changes.forEach(change => {
                        let existing = this.EventPhotosImagesUploads.find(obj => obj.id === change.doc.id)
                        if(!existing){
                          if (change.type === 'added') {
                            this.EventPhotosImagesUploads.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        }
                      })
                    })
        },
        GetAttendingUsers(eventid){
            console.log(eventid)
            db.collection('siteevents').doc(eventid).collection('Attending_Users').onSnapshot(res => {
              const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    this.Attending_Users.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                })
            })
        },
        PushItem(item){
            this.ActiveRecord = this.SiteEventsArray.find(obj => obj.id === item.id)
        },
        GetEvents(query){
          // GetAttendingUsers
          let routecol = ''
          if(this.$route.params.id){
            routecol = 'groupevents'
          }
          else{
            routecol = 'siteevents'
          }
          query.onSnapshot(res => {
            let listlength = res.docs.length
            if(listlength === 0){
              this.$emit('PushArray',this.MenuItem,this.SiteEventsArray)
            }
              const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    let eventobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                    eventobj.Attending_Users = []
                    
                    db.collection(routecol).doc(eventobj.id).collection('Attending_Users').onSnapshot(attres => {
                      let attlistlength = attres.docs.length
                      if(attlistlength === 0){
                        this.$emit('PushArray',this.MenuItem,this.SiteEventsArray)
                        this.SiteEventsArray.push(eventobj) 
                      }
                      const attchanges = attres.docChanges();
                        attchanges.forEach(attchange => {
                          if (attchange.type === 'added') {
                            this.Attending_Users.push({
                              ...attchange.doc.data(),
                              id: attchange.doc.id
                            })
                            eventobj.Attending_Users.push({
                              ...attchange.doc.data(),
                              id: attchange.doc.id
                            })
                          }
                          if(eventobj.Attending_Users.length === attlistlength){
                            
                            this.SiteEventsArray.push(eventobj) 
                            if(this.SiteEventsArray.length === listlength){
                              this.$emit('PushArray',this.MenuItem,this.SiteEventsArray)
                            }
                          }
                          
                        })
                    })
                  }
                })
            })
        }
    }

}
</script>

<style>

</style>


    