<template>
  <div>
      <!-- <AppOrderingSystems v-if="SuiteBuilderNavItem.title === 'Goods & Ordering'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <AppDirectories v-if="SuiteBuilderNavItem.title === 'Directories & Personals'"
        :System="System" :SystemEntities="SystemEntities"
        />
        <AppServices v-if="SuiteBuilderNavItem.title === 'Service Apps'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <AppTeamPlugins v-if="SuiteBuilderNavItem.title === 'Team'"
        :System="System" :SystemEntities="SystemEntities"
        /> -->
        <v-card-title class="justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/RAFullLogo.png"/>
        </v-avatar>
      {{SuiteBuilderNavItem.title}}
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Installed
      </v-card-subtitle>
      <v-card-text >
        <!-- not enough. this returns plugins, but if type is "app" return each app instance -->
        <v-list-item v-for="plug in InstalledPluginsofType" :key="plug.itemObjKey" class="detailslistoutline">
            {{plug.Name}}
        </v-list-item>
        <!-- <v-list-item>
            Activities
        </v-list-item>
        <v-list-item>
            Timesheets
        </v-list-item>
        <v-list-item>
            Forums
        </v-list-item>
        <v-list-item>
            Goals
        </v-list-item> -->
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Available
      </v-card-subtitle>
     <v-card-text class="BuilderGradient">
            <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="TypePlugins" :tab="systemguideelmnt" @ActivateModulePlugins="ActivateModulePlugins"
            :ListPreviewOnly="true" v-if="!RefreshingPlugins"
              />
          </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Analytics
      </v-card-subtitle>
      <v-card-text >
         Analytics Quick Card
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import AppDirectories from '@/components/SuiteBuilder/FeaturedPlugins/AppDirectories';
import AppServices from '@/components/SuiteBuilder/FeaturedPlugins/AppServices';
import AppTeamPlugins from '@/components/SuiteBuilder/FeaturedPlugins/AppTeamPlugins';
import AppOrderingSystems from '@/components/SuiteBuilder/FeaturedPlugins/AppOrderingSystems';	
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
export default {
    props: ['System','SystemEntities','SuiteBuilderNavItem'],
    components: {AppOrderingSystems,AppDirectories,AppServices,AppTeamPlugins,RAPluginCarousel},
    data() {
        return {
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
        }
    },	
    computed:{
        InstalledPluginsofType(){
          return this.AllTypePlugins.filter(plug => {
            return this.PluginDataBase[plug.id] && this.PluginDataBase[plug.id].Active
          })          
        },
        AllTypePlugins(){
            return this.ALLRAPluginsTypeMatch(this.SuiteBuilderNavItem.TypeFilter).filter(plug => {
                return plug.Featured
            })
        },
        TypePlugins(){
            return this.RAPluginsTypeMatch(this.SuiteBuilderNavItem.TypeFilter).filter(plug => {
                return plug.Featured
            })
        },
        PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        PluginsPath(){
        return this.$store.state.PluginsPath
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        RAPluginsMatched(categorymatches){
         let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        })
      },
      RAPluginsTypeMatch(type){
          return this.PurchasablePlugins.filter(plug => {
          return plug.Type && plug.Type.ID === type.ID
          // || plug.Module_Category.Name === 'General'
        })
      },
      ALLRAPluginsTypeMatch(type){
          return this.PurchasablePlugins.filter(plug => {
          return plug.Type && plug.Type.ID === type.ID
          // || plug.Module_Category.Name === 'General'
        })
      }
    }
}
</script>

<style>

</style>



