<template>

   <v-card flat tile class="transparent">
   
       <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
            <v-list-item>
                 <v-switch @change="CheckArticleCols(EditedTab)" label="Has Article Cols" v-model="EditedTab.HasArticleCols" />
                <v-switch v-if="EditedTab.HasArticleCols" label="Cols Wrap Box" v-model="EditedTab.ColsWrapBox" /> 
            </v-list-item>                   
             <v-list-item v-if="EditedTab.HasArticleCols">
                <v-switch  label="Cols Border" v-model="EditedTab.ColsBorder" /> 
             </v-list-item>
            <v-list-item v-if="EditedTab.HasArticleCols && EditedTab.ColsBorder">
                <v-text-field
                label="Border Width"
                  v-model.number="EditedTab.ColsBorderWidth"
                  class="mt-0 pt-0"
                  type="number"
                />  
                 <v-text-field
                label="Border Radius"
                  v-model.number="EditedTab.ColsBorderRadius"
                  class="mt-0 pt-0"
                  type="number"
                /> 
             </v-list-item>
              <v-list-item v-if="EditedTab.HasArticleCols && EditedTab.ColsBorder">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                    <v-list-item v-on="on">
                        <v-icon left>mdi-palette-advanced</v-icon>
                        Cols Border Color
                        </v-list-item>
                        </template>
                    <v-color-picker v-model="EditedTab.ColsBorderColor"></v-color-picker>
                    </v-menu>
              </v-list-item>
            <v-select :items="['Each Paragraph','First Paragraph']" clearable v-model="EditedTab.HiglightInitialLetter"
                 label="Highlight Initial Letter"
                 />
             <v-list-item>
             </v-list-item>
            <v-list-item>
                 <v-select :items="[1,2,3]" clearable v-model="EditedTab.TotalArticleCols"
                 label="Total Cols"
                 />
            </v-list-item>
            <v-tabs>
                <v-tab>Writeup</v-tab>
                <v-tab-item>
                     <v-list v-if="!EditedTab.ContentFromField" class="justify-center">		
                       		
                      <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery'|| EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Parallax' ||
                      EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                         <v-btn-toggle dense >									
                                                									
                            <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">									
                                H1									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">									
                                H2									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">									
                                H3									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">									
                                 <v-icon>mdi-format-paragraph </v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box'  || EditedTab.Name === 'Parallax'||
                       EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Image'">									
                         <v-btn-toggle dense >									
                            									
                            <v-btn onclick="document.execCommand('justifyLeft',false,'')">									
                                <v-icon x-small>mdi-format-align-left</v-icon>									
                            </v-btn>									
                            <!-- <v-btn>									
                                <v-icon>mdi-format-align-center</v-icon>									
                            </v-btn> -->									
                            <v-btn onclick="document.execCommand('justifyCenter',false,'')">									
                                <v-icon x-small>mdi-format-align-justify</v-icon>									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('justifyRight',false,'')">									
                                <v-icon x-small>mdi-format-align-right</v-icon>									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('insertUnorderedList',false,'')">									
                                <v-icon x-small>mdi-format-list-bulleted</v-icon>									
                            </v-btn>									
                            									
                            									
                            </v-btn-toggle>									
                                 									
                      </v-list-item>									
                      <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box'  || EditedTab.Name === 'Parallax' ||
                      EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Image'">									
                          <v-btn-toggle dense >									
                            <v-btn onclick="document.execCommand('fontSize', 0, '12px');">									
                                <v-icon x-small>mdi-format-title</v-icon>									
                            </v-btn>									
                    									
                            									
                            <v-btn onclick="document.execCommand('bold', false, '');">									
                                <v-icon x-small>mdi-format-bold</v-icon>									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('italic', false, '');">									
                                <v-icon x-small>mdi-format-italic</v-icon>									
                            </v-btn>									
                            <v-btn onclick="document.execCommand('underline', false, '')">									
                                <v-icon x-small>mdi-format-underline</v-icon>									
                            </v-btn>									
                           									
                            </v-btn-toggle>									
                      </v-list-item>									
                  			<v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box'  || EditedTab.Name === 'Parallax' ||
                      EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Image'">									
                          <v-btn-toggle dense >									
                             
                             <v-btn small @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>								
                           									
                            </v-btn-toggle>									
                      </v-list-item>							
                       <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box'  || EditedTab.Name === 'Parallax' || 
                       EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Image'">									
                          <v-btn-toggle dense >									
                        									
                                <v-btn>									
                                    <v-icon @click="NoPropsemit('link')">mdi-link</v-icon>									
                                </v-btn>									
                                <v-btn>									
                                    <v-icon x-small @click="NoPropsemit('addphone')">mdi-phone</v-icon>									
                                </v-btn>									
                                <v-btn>									
                                    <v-icon x-small @click="NoPropsemit('addemail')">mdi-email</v-icon>									
                                </v-btn>									
                                <v-btn onclick="document.execCommand('strikeThrough',false,'')">									
                                    <v-icon x-small>mdi-format-strikethrough</v-icon>									
                                </v-btn>									
                                </v-btn-toggle>									
                      </v-list-item>	
                       <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery'|| EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Parallax' ||
                      EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">
                      <v-switch dense label="Code View" v-model="CodeView" prepend-icon="mdi-code-tags"/>
                        </v-list-item>								
                      <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box'  || EditedTab.Name === 'Parallax' ||
                       EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single' || EditedTab.Name === 'Image'">									
                          <v-card v-if="!CodeView" width="90%" min-height="300px" outlined flat v-html="EditedTab.Description" contenteditable  @blur="WriteupUpdate(WriteupUpdateMethod)"								
                          :id="WriteupEditorid">									
                                 </v-card>	
                                 
                                  <v-textarea v-if="CodeView" width="90%" min-height="300px" outlined flat v-model="EditedTab.Description" contenteditable								
                          :id="WriteupEditorid">									
                                  </v-textarea>									
                      </v-list-item>									
                      <!-- <v-list-item class="justify-center" dense v-if="EditedTab.Name === 'Gallery' || EditedTab.Name === 'Info Sheet' || EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Card Block Single' || EditedTab.Name === 'Card Row Single'  || EditedTab.Name === 'Card Row' || EditedTab.Name === 'Store Product Single'">									
                          <v-btn @click="WriteupUpdate(WriteupUpdateMethod)">Update</v-btn>									
                      </v-list-item> -->
                      </v-list>	
                </v-tab-item>
                <v-tab>Font Styling</v-tab>
                <v-tab-item>
                    <v-list-item>
                          <v-select v-if="EditedTab.Name !== 'Gallery'" v-model="EditedTab.FontSize" label="Font Size" :items="[10,12,14,16,20,24,30,36,42]"									
                    />
                    </v-list-item>
                    <!-- <v-list-item>
                    <v-autocomplete clearable v-model="EditedTab.FontBGColor" label="BG Color" :items="$store.state.CSSColors"
                    >
                    
                <template v-slot:item="data">
                    <v-list-item-avatar>
                    <v-avatar :style="'background-color: '+data.item+';'">
                    </v-avatar>
                    </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-subtitle v-html="data.item"></v-list-item-subtitle>
                        </v-list-item-content>
                        </template>
                    </v-autocomplete>
                  
                </v-list-item> -->
                <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="200px"
        v-if="EditedTab.Name !== 'Card Row Single'"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Font BG Color
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.FontBGColor"></v-color-picker>
          </v-menu>
          <v-icon right v-if="EditedTab.FontBGColor" @click="EditedTab.FontBGColor = ''">mdi-close</v-icon>
                 <v-list-item v-if="EditedTab.Name !== 'Card Row Single'">
         <v-select clearable v-model="EditedTab.FontBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
                <v-list-item v-if="EditedTab.Name !== 'Card Row Single'">
                    <v-switch @change="!EditedTab.FontUnderline ? EditedTab.FontUnderlineColor = '' : ''" v-model="EditedTab.FontUnderline" label="Underline"
                    />
                </v-list-item>
                 <v-color-picker  v-if="EditedTab.FontUnderline" v-model="EditedTab.FontUnderlineColor"></v-color-picker>
               
                    <v-list-item>
                        Font Color
                    </v-list-item>
                   <v-color-picker v-model="EditedTab.FontColor"></v-color-picker>										
                  		
       
                </v-tab-item>
            </v-tabs>
           
                  </v-card-text>      
        </v-card>
</template>






<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            CodeView: false,
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        FontOptions(){
            return this.$store.state.FontOptions
        },
    },
    created(){

    },
    methods:{
        changeColor() {
        var color = prompt("Enter your color in hex ex:#f1f233");
        document.execCommand("foreColor", false, color);
        },
        CheckArticleCols(tab){
            if(typeof tab.TotalArticleCols === 'undefined'){
                tab.TotalArticleCols = 2
            }
            if(typeof tab.ColsBorderColor === 'undefined'){
                tab.ColsBorderColor = {hexa: '#FF0000'}
                tab.ColsBorderWidth = 8
                tab.ColsBorderRadius = 0
            }
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
