<template>

    <v-dialog v-model="RAAppAdminDialog" max-width="500">
    <v-card
    class="mx-auto RAWebBtnGraddark"
    max-width="500"
    dark
  >
    <!-- <v-img src="@/assets/RABaseBG.jpeg"> -->
      
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>Sign in to RapidApps    </span>
      <v-avatar
        size="45"
      >
      <v-img  v-if="userLoggedIn && userLoggedIn.Profile_Photo" :src="userLoggedIn.Profile_Photo">
      </v-img>
      <v-img v-if="!userLoggedIn || userLoggedIn && userLoggedIn.Profile_Photo" src="@/assets/BlankProfilePic.png">
      </v-img>
      </v-avatar>
    </v-card-title>
    <v-card-subtitle v-if="userLoggedIn">
      {{userLoggedIn.Full_Name}}
    </v-card-subtitle>
    <v-card-text>
      <v-text-field type="text" v-model="raloginemail" placeholder="Email" size="12"/><br>
        <v-text-field type="password" v-model="raloginpassword" placeholder="Password" @keydown.enter="loginClientAppRAAdmin"/>
       <span class="caption">You may be signed in to RapidApps, but in order to speak to your data on your app, please sign into your app as an administrator</span>
    </v-card-text>
    <v-card-actions><v-btn @click="loginClientAppRAAdmin">Sign In</v-btn></v-card-actions>
    <!-- </v-img> -->
    </v-card>
    </v-dialog>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','RAAppAdminDialog','RADB','RAApp','SystemGuides'],
    components: {},
    data() {
        return {
            raloginemail: '',
            raloginpassword: '',
        }
    },
    computed:{
      
        userLoggedIn () {
            return this.$store.getters.user
            },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
    },
    

    

    created(){
    },
    methods:{
        
        loginClientAppRAAdmin(){
      let vm = this
      const user = {
        email: this.raloginemail,
        password: this.raloginpassword
      }
      let payload = {
        user: user,
        SystemName: 'RapidApps',
        ClientFireStore: this.RAApp
      }
      
      this.$store.dispatch('signInClientAppAction', payload).then(response => {
        console.log(response)
        console.log(response.user)
        console.log(response.user.uid)
        if(response){
          vm.RADB.collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = user.uid
            console.log(userdata)
            // vm.$store.commit('setUserObj', userdata)
            alert('successfully signed in')
            vm.$emit('ToggleRAAppAdminDialog',false)
          })
          
        }
         
      })

    
    },
    }
}
</script>

<style>
.iconpulse{
  animation: simplepulsing 1.0s infinite;
}
@keyframes simplepulsing {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}

}
</style>