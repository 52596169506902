
<template>
<div>
  <div class="flipcard" v-if="tab.FlipCard" @click="OpenDialog(tab,i)">
          <div class="flipcardfront" :style="tab.LabelBGColor ? 
         tab.ContainIMG ? `background-size: contain;color: `+tab.LabelBGColor.hexa+`;background-image: url('`+tab.IMG+`');` : 
         `background-size: cover;color: `+tab.LabelBGColor.hexa+`;background-image: url('`+tab.IMG+`');` : 
         tab.ContainIMG ? `background-size: contain; background-image: url('`+tab.IMG+`');` :  `background-size: cover; background-image: url('`+tab.IMG+`');`">
						<div class="flipcardinner" :style="tab.LabelGradient ? tab.LabelGradient : ''">
							<p  :style="'text-align: center;padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)+'font-size: '+tab.TitleSize+'rem;rem;'">{{tab.Title}}</p>
              <span v-html="smart_substr(tab.Description,20)"></span>
						</div>
					</div>
					<div class="flipcardback" :style="'background: '+tab.BGColor.hex+';'">
						<div class="flipcardinner" style="height: 100%;">
              <v-list-item dense>
                <v-list-item-content :style="'color:'+tab.FontColor.hex+';font-weight: 300;'">
                  {{tab.Title}}
                </v-list-item-content>
                <v-list-item-avatar style="position: absolute;padding: 0px important;right: 0px;margin-top:-15px;" size="80" :tile="tab.TiledIMG">
                  <v-img  :src="tab.IMG"/>
                </v-list-item-avatar>
              </v-list-item>
              <div style="padding-top: 5px;">
              <!-- <span :style="'color:'+tab.FontColor.hex">{{tab.Title}}</span> -->
						  <span :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex" v-html="tab.Description"></span>
              </div>
              <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && tab.Fancy" :class="tab.ButtonAlignment">
      <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
            :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="ActionButtonOpenDialog" :System="System"						
            />
      </v-list-item>
      <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && !tab.Fancy" :class="tab.ButtonAlignment">
        <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"										
            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
            :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="ActionButtonOpenDialog"									
            />
      </v-list-item>
						</div>
					</div>
          </div>
  <v-card v-if="!tab.FlipCard" :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3'" height="100%" :color="tab.BGColor.hex" :dark="tab.IsDark" :elevation="tab.Elevation" :tile="tab.Tile"  :outlined="tab.Outlined" :shaped="tab.Shaped"
  :style="tab.TiledIMG ? 'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px' :
  'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'" @click="OpenDialog(tab,i)">
   <div>
        <!-- <v-card-title :class="tab.Center" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
    <strong>{{tab.Title}}</strong>
    </v-card-title>
    <v-card-text v-html="tab.Description" :class="tab.FillHeight+' d-flex'" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">
        </v-card-text>
        <v-card-text v-if="tab.HasImage">
        <v-layout :class="tab.Justify" row>
            <v-avatar size="110" tile>
        <v-img  v-if="tab.HasImage && tab.IMG"
        :contain="!tab.Clipped"
        :height="tab.Height"
                :src="tab.IMG"
                :style="CheckifClipped(tab)"
            
                />
                <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
        :height="tab.Height"
                src="@/assets/ImageHolder.png"
                />
            </v-avatar>
        </v-layout>
    </v-card-text> -->
        <v-list-item  v-if="$vuetify.breakpoint.xs">
        <v-list-item-title v-if="tab.Title" :style="'text-align: center;padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
          {{tab.Title}}
        </v-list-item-title>
    </v-list-item>
    <v-list-item style="padding: 0px;" v-if="tab.HasImage && tab.IMGonTop || tab.HasImage && MiniView" class="justify-center">
      <v-avatar :tile="tab.TiledIMG || tab.FullIMG" :style="AvatarStyle(tab)" >
      <img  v-if="tab.HasImage && tab.IMG"
        :contain="!tab.Clipped"
                :src="tab.IMG"
                :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)"
            
                />
                <img :contain="!tab.Clipped"  :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                src="@/assets/ImageHolder.png"
                />
      </v-avatar>
    </v-list-item>
        <v-list-item three-line >
             <v-list-item-avatar :style="$vuetify.breakpoint.xs ? 'align-self: center;' : ''"
        v-if="!MiniView && tab.HasImage && tab.IMG && !tab.IMGonTop"
        size="100"
      >
       <img  v-if="tab.HasImage && tab.IMG"
        :contain="!tab.Clipped"
        :height="tab.Height"
                :src="tab.IMG"
                 :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)"
            
                />
                <img :contain="!tab.Clipped"  :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
        :height="tab.Height"
                src="@/assets/ImageHolder.png"
                />
      </v-list-item-avatar>
      <v-list-item-content>    
          <v-list-item-avatar
        v-if="$vuetify.breakpoint.sm && tab.HasImage && tab.IMG && !tab.IMGonTop || $vuetify.breakpoint.xs && tab.HasImage && tab.IMG && !tab.IMGonTop"
        size="100"
        class="justify-center"
      >
       <img  v-if="tab.HasImage && tab.IMG"
        :contain="!tab.Clipped"
        :height="tab.Height"
                :src="tab.IMG"
                 :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)"
            
                />
                <img :contain="!tab.Clipped"  :style="tab.ContainIMG ? 'object-fit: contain;'+CheckifClipped(tab) : 'object-fit: cover;'+CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
        :height="tab.Height"
                src="@/assets/ImageHolder.png"
                />
      </v-list-item-avatar>    
        <v-list-item-title v-if="tab.Title && !$vuetify.breakpoint.xs" :style="'text-align: center;padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
          {{tab.Title}}
        </v-list-item-title>
        
       <div v-html="tab.Description" :class="tab.FillHeight+' d-flex'" :style="DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex+';padding-top: 0px; padding-left: 0px;'"></div>
      </v-list-item-content>

     
    </v-list-item>
    
    <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && tab.Fancy" :class="tab.ButtonAlignment">
         <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"								
              :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
              :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="ActionButtonOpenDialog" @DeleteTabAsset="DeleteTabAsset"							
              />
      </v-list-item>
      <v-list-item style="padding-bottom: 10px;" v-if="tab.HasActionButton && !tab.Fancy" :class="tab.ButtonAlignment">
          <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :System="System"								
              :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
              :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="ActionButtonOpenDialog" @DeleteTabAsset="DeleteTabAsset"			
              />
      </v-list-item>
   </div>
    </v-card>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbed';


export default {
    props: ['System','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
      ActionButtonEmbed
        },
    data() {
    return {      
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){


    },
    
    methods: {
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      AvatarStyle(tab){
        if(typeof tab.IMGHeight !== 'undefined'){
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:'+tab.IMGHeight+'px;' : 'height:'+tab.IMGHeight+'px;width: '+tab.IMGHeight+'px;min0width:'+tab.IMGHeight+'px;'
        }
        else{
          return tab.FullIMG ? 'padding: 0px;width: 100%;height:150px;' : 'height:150px;width: 150px;min0width:150px;'
        }
      },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      ActionButtonOpenDialog(tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog){
        this.$emit('OpenDialog',tab,tabindex,IsElement,CustomBoxTabIndex,isrow,rowindex,ContentDialog)
      },
      DeleteTabAsset(tab,photoindex){
            this.$emit('DeleteTabAsset',tab,photoindex)
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
            //      L 0,0
            //      L 1,0
            //      L 1,1
            //      C .65 .8, .35 .8, 0 1
            //      Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>
</style>