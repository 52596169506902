<template>
  <v-main class="background" :style="MobileView ? 'height: 100%;' : 'margin-top:50px;height: 100%;'">
          <v-dialog persistent v-model="SharePluginDialog" max-width="400">
      <v-card max-height="650px">
        <v-card-title class="overline">Share Plugin
        </v-card-title>
        <v-card-subtitle>
          <v-icon>
            {{SharingPlugin.Icon}}
          </v-icon>
          {{SharingPlugin.Name}}
        </v-card-subtitle>
        <v-card-text>
          <v-select :items="OtherApps" label="App to Share With" item-text="id" v-model="SharewithApp" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelPluginShare()" dark color="warning">
            Cancel
          </v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn @click="SharePlugin(SharingPlugin)" dark color="success">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
          </v-dialog>
    <v-dialog persistent v-model="NewTablesDialog" max-width="400">
      <v-card max-height="650px">
        <v-card-title class="BuilderGradient mediumoverline">Table Configuration
        </v-card-title>
        <v-card-text>
           <v-list dense v-for="table in ConfigRequiredImmediateTables" :key="table.itemObjKey">
          <v-list-item dense>
            {{table.Entity_Type.Name}}
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field :rules="CheckExistingTables('DisplayName')"
            :label="'Table Name (Multiple '+table.DisplayName+')'" :disabled="table.DisplayName === 'User Companies'"
            v-model="table.DisplayName"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field :rules="CheckExistingTables('SingleName')"
            :label="'Single (1 '+table.SingleName+')'" :disabled="table.DisplayName === 'User Companies'"
            v-model="table.SingleName"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field
            label="Icon" :disabled="table.DisplayName === 'User Companies'"
            v-model="table.Table_Icon"
          ></v-text-field>
          <v-icon>{{table.Table_Icon}}</v-icon>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-text-field :disabled="table.DisplayName === 'User Companies'"
            label="Primary Field Name" :readonly="table.Entity_Type && table.Entity_Type.Name === 'Store Order'"
            v-model="table.Primary_Field_Name"
          ></v-text-field>
          </v-list-item>
          <v-list-item dense class="settingsfont">
            <v-select
            :label="table.Security_Structure && table.Security_Structure.Name === 'Warehouse' ? 'Security Structure (Cannot Change)' : 'Security Structure'" item-text="Name"
            :items="EntitySecurityOptions" return-object :readonly="table.Security_Structure && table.Security_Structure.Name === 'Warehouse'"
            v-model="table.Security_Structure" :disabled="table.DisplayName === 'User Companies'"
          ></v-select>
          </v-list-item>
        </v-list>
        </v-card-text>
        <v-card-actions class="BuilderGradient">
      <v-btn
        text
        @click="CancelInstallTablesandPlugin()"
      >
        Cancel
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        depressed
        @click="PreparePluginTables()"
      >
        Install
        {{DefaultStatusField.DefaultLevel1Option}}
      </v-btn>
    </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="NewDashDialog" max-width="400">
      <v-card>
        <v-card-title class="mediumoverline">
          New {{NewDashboardType.title}}
        </v-card-title>
        <v-card-text>
          <v-text-field label="Name" v-model="NewDashboardName" />
          <v-select item-text="id" :items="AppEntities" v-model="NewDashboardEntity" label="Data Table"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn :disabled="!NewDashboardEntity" @click="NavigateRelevantNewDash()" dark color="success">
            Go
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <div v-if="ActiveAppComponent" :style="MobileView ? '' : 'padding: 20px;'">
       
         <v-card elevation="0" class="siteconfigcard">
        <v-card-title class="mediumoverline SoftBuilderGradient" v-if="!ActiveChildComponent">
        {{ActiveAppComponent.title}}<v-spacer></v-spacer><span v-if="ActiveAppComponent.title === 'Plugins'">
          {{CurrencyFormatter(PluginsTotal,$store.state.DefaultCurrency.Currency)}}
          </span>
      </v-card-title>
      <v-card-subtitle style="text-align: -webkit-center;" class="mediumoverline SoftBuilderGradient" v-if="ActiveChildComponent">
         <v-btn icon @click="ActiveChildComponent = ''">
             <v-icon>
                 mdi-chevron-left
             </v-icon>
         </v-btn>
         <span>{{ActiveChildComponent.title}}</span>
      </v-card-subtitle>
      <v-card-text v-if="ActiveAppComponent.title === 'Plugins'">
        <v-list>
          <v-virtual-scroll 
                width="100%"
                  class="transparent"
                    :items="AppPlugins"
                    :item-height="35"
                    :height="250"
                  >
                  <template v-slot:default="{ item }">
                    <v-list-item >
                      <v-list-item-avatar>
                        <v-icon>
                          {{item.Icon}}
                        </v-icon>
                      </v-list-item-avatar>
                      
                      <v-list-item-content>
                          {{item.Name}}
                      </v-list-item-content>
                      
                      <v-list-item-content>
                        {{CurrencyFormatter(item.Price,$store.state.DefaultCurrency.Currency)}}
                      </v-list-item-content>
                       <v-list-item-avatar>
                        <v-icon @click="item.App_Shareable ? ActivatePluginShare(item) : ''" :color="item.App_Shareable ? 'green' : 'grey lighten-1'">
                          {{item.App_Shareable ? 'mdi-power-plug-outline' : 'mdi-power-plug-off-outline'}}
                        </v-icon>
                      </v-list-item-avatar>
                    </v-list-item>
                  </template>
          </v-virtual-scroll>
        </v-list>
       <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RAPluginsMatched(['Database Builder','DBB','Social Network Builder','SNB','Website Builder','WB','General'])" :tab="systemguideelmnt"
            :ListPreviewOnly="false" :Squished="true" :PluginPicker="true" @PickPlugin="PickPlugin"
              />
      </v-card-text>
      <v-card-text v-if="!ActiveChildComponent && ActiveAppComponent.ComponentName">
          <component :is="ActiveComponent" @ActivateActiveChildComponent="ActivateActiveChildComponent" :CategoricalNavlists="CategoricalNavlists" :RADB="RADB"
            :MenuItem="ActiveAppComponent" :UsersArray="UsersArray" :ChildComponent="ActiveAppComponent" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveAppComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications" :SystemGuides="SystemGuides"
            :GroupLandingPageViews="GroupLandingPageViews" :System="System" :EntityRecord="ActiveAppComponent.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
            :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :UnreadNotifications="UnreadNotifications"
          />
      </v-card-text>
      <v-card-text v-if="MountedChildrenSummaries && MountedChildrenSummaries.length > 0 && !ActiveChildComponent">
         
          <div v-for="(childcomp,childindex) in MountedChildrenSummaryComponents" :key="childcomp.itemObjKey">
              <v-card-subtitle style="text-align: -webkit-center;font-weight:200;" class="largeoverline">
               {{MountedChildrenSummaries[childindex].ComponentName}}
            </v-card-subtitle>
           <component 
           :is="childcomp" class="background"
            :MenuItem="ActiveAppComponent" :UsersArray="UsersArray" :CategoricalNavlists="CategoricalNavlists" :RADB="RADB" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveAppComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications" :SystemGuides="SystemGuides"
            :GroupLandingPageViews="GroupLandingPageViews" :System="System" :EntityRecord="ActiveAppComponent.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
            :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" @ActivateActiveChildComponent="ActivateActiveChildComponent" :ChildComponent="MountedChildrenSummaries[childindex]"
            :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :UnreadNotifications="UnreadNotifications"
          />
          </div>
      </v-card-text>
      <v-card-text v-if="MountedChildrenNonSummaries && MountedChildrenNonSummaries.length > 0 && !ActiveChildComponent">
        <div  v-for="(childcomp,childindex) in MountedChildrenNonSummaryComponents" :key="childcomp.itemObjKey">
           <v-card-subtitle style="text-align: -webkit-center;font-weight:200;" class="largeoverline">
               {{MountedChildrenNonSummaries[childindex].ComponentName}}
            </v-card-subtitle>
           <component
           :is="childcomp"
            :MenuItem="ActiveAppComponent" :UsersArray="UsersArray" :CategoricalNavlists="CategoricalNavlists" :RADB="RADB" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveAppComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications" :SystemGuides="SystemGuides"
            :GroupLandingPageViews="GroupLandingPageViews" :System="System" :EntityRecord="ActiveAppComponent.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
            :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" @ActivateActiveChildComponent="ActivateActiveChildComponent" :ChildComponent="MountedChildrenNonSummaries[childindex]"
            :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :UnreadNotifications="UnreadNotifications"
          />
        </div>
      </v-card-text>
      <v-card-text v-if="ActiveChildComponent">{{ActiveChildComponent.ComponentName}}
          <component :is="ActiveChildRenderComponent" v-if="ActiveChildComponent.ComponentName"
            :MenuItem="ActiveChildComponent" :UsersArray="UsersArray" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveChildComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications"
            :GroupLandingPageViews="GroupLandingPageViews" :System="System" :EntityRecord="ActiveChildComponent.EntityRecord" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
            :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" :CategoricalNavlists="CategoricalNavlists" :RADB="RADB" :SystemGuides="SystemGuides"
            :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :UnreadNotifications="UnreadNotifications"
          />
      </v-card-text>
      </v-card>
     </div>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
export default {
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries','UnreadNotifications',
    ,'MyActivitiesArray','MyGoals','MyGoalsisEmpty','RADB','CategoricalNavlists','Notifications','SystemGuides','FrequentlyAskedQuestions'],
    components: {RAPluginCarousel},
    data() {
        return {
          SharePluginDialog: false,
          SharingPlugin: '',
          SharewithApp: '',
          NewTablesDialog: false,
          NewPlugin: '',
          RawPlugin: '',
          InstallingTables: [],
          EntitySecurityOptions: [{ID:1000001,Name:"Custom Roles"},{ID:1000002,Name:"Business Unit"},{ID:1000003,Name:"Warehouse"}],
          NewDashboardEntity: '',
          NewDashDialog: false,
          NewDashRoute: '',
          NewDashboardType: '',
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
            ActiveAppComponent: '',
            ActiveChildComponent: ''
        }
    },	
    computed:{
      SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
      NeedsRASync(){
            if(!this.SystemisRA){
              return this.$route.meta && this.$route.meta.TableBuilder || this.SystemConfigorRA && this.tab.Entityid === 'entities'
            }            
        },
      DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
      ReservedDisplayNameProps(){
        return this.$store.state.ReservedDisplayNameProps
      },
      ReservedSingleNameProps(){
        return this.$store.state.ReservedSingleNameProps
      },
      MobileView(){
            return this.$vuetify.breakpoint.xs
        },
        SmallView(){
            return this.$vuetify.breakpoint.sm
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      PluginsTotal(){
        return this.AppPlugins.map(plug => {
          return plug.Price
        }).reduce((a, b) => a + b, 0)
      },
      WarehouseappOutbound(){
        return this.ActiveWarehouseApp && this.ActiveWarehouseApp.Plugins.find(obj => obj.id === 'Warehouse_Outbound')
      },
      ActiveWarehouseApp(){
        return this.ActiveWarehouse ? this.AppsDataBase.find(obj => obj.id === this.ActiveWarehouse.Primary_Appid) : ''
      },
      ActiveWarehouse(){
        return this.$store.state.ActiveWarehouse
      },
      ConfigRequiredImmediateTables(){
        return this.InstallingTables.filter(table => {
          return !table.System_Required
        })
      },
      
     AppandSharedPlugins(){
            return this.AppPlugins.concat(
              this.ActiveSuiteApp.SharedPlugins .map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            }) 
            )
        },
        AppPlugins(){
            return this.ActiveSuiteApp.Plugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug.id)
                if(!plugobj){
                    plugobj = plug
                }
                return plugobj
            }).filter(plug => {
                return plug.Name
            })
        },
        MountedChildrenSummaryComponents(){
            return this.MountedChildrenSummaries.map(child => {
                return () => import(`@/`+child.SummaryComponentPath+`.vue`);
            })
        },
        MountedChildrenNonSummaryComponents(){
            return this.MountedChildrenNonSummaries.map(child => {
                return () => import(`@/`+child.ComponentPath+`.vue`);
            })
        },
        MountedChildrenSummaries(){
            return this.ActiveAppComponent && this.ActiveAppComponent.Children ? 
            this.ActiveAppComponent.Children.filter(child => {
                console.log(child)
                return child.ComponentName && child.SummaryComponentPath
            }) : []
        },
        MountedChildrenNonSummaries(){
            return this.ActiveAppComponent && this.ActiveAppComponent.Children ? 
            this.ActiveAppComponent.Children.filter(child => {
                console.log(child)
                return child.ComponentName && !child.SummaryComponentPath
            }) : []
        },
        ActiveChildRenderComponent(){
            if(this.ActiveChildComponent.ComponentPath){
                return () => import(`@/`+this.ActiveChildComponent.ComponentPath+`.vue`);	 
            }
        },
        ActiveComponent(){
            if(this.ActiveAppComponent.ComponentPath && !this.ActiveAppComponent.SummaryComponentPath){
                return () => import(`@/`+this.ActiveAppComponent.ComponentPath+`.vue`);	 
            }
            else if(this.ActiveAppComponent.SummaryComponentPath){
                return () => import(`@/`+this.ActiveAppComponent.SummaryComponentPath+`.vue`);	 
            }
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        SuiteApp(){
            return this.AppsDataBase.find(obj => obj.id === this.$route.params.id)
        },
        AppEntities(){
            return this.SystemEntities.filter(ent => {
                return ent.Primary_Appid === this.ActiveSuiteApp.id
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
        UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        PluginsPath(){
        return this.$store.state.PluginsPath
        },
        AppsPath(){
        return this.$store.state.AppsPath
        },
        UsersArray(){
            return this.$store.state.Users
        },
        OtherApps(){
          return this.AppsDataBase.filter(app => {
            return app.id !== this.ActiveSuiteApp.id
          })
        },
        UsersforProperties(){
      return this.UsersArray.map(user =>{
        let userobj = {
          Full_Name: user.Full_Name,
          Name: user.Name,
          Surname: user.Surname,
          id: user.id,
          Email: user.Email
        }
        return userobj
      })
    }	,
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['setActiveAppComponent','PurchasePlugin']   
        if(v && acceptedmethods.includes(v)){
            //console.log(this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      SuiteApp: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){  
                  this.$store.commit('setActiveSuiteApp',newvalue)              
              }
            },deep: true
      },
    },
    created(){
        if(this.SuiteApp){
            this.$store.commit('setActiveSuiteApp',this.SuiteApp) 
        }
        console.log(this.UnreadNotifications,this.UsersArray)
        //console.log('created this.ActiveSuiteApp',this.ActiveSuiteApp)
    },
    methods:{
      UpdateAppProp(prop,value){
        this.AppsPath.doc(this.ActiveSuiteApp.id).update({
          [prop]: value
        })
      },
      ActivatePluginShare(plug){
        this.SharingPlugin = plug
        this.SharePluginDialog = true
      },
      SharePlugin(plug){
        let plugobj = {
          Name: plug.Name,
          Module_Category: plug.Module_Category,
          id: plug.id,
          Active: true,
          Primary_Appid: this.ActiveSuiteApp.id
        }
        let appplug = this.ActiveSuiteApp.Plugins.find(obj => obj.id === plug.id)
        if(appplug.Installation_Tables){
          plugobj.Installation_Tables = appplug.Installation_Tables
        }
        if(plug.App_Shareable){
          this.AppsPath.doc(this.SharewithApp).collection('sharedplugins').doc(plug.id).set(plugobj).then(sharedplugdoc => {
          this.CancelPluginShare()
        })
        }
        else{
          this.CancelPluginShare()
        }
        
      },
      CancelPluginShare(){
        this.SharePluginDialog = false
        this.SharingPlugin = ''
        this.SharewithApp = ''
      },
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      CheckExistingTables(prop){
        let tablepropslist = this.SystemEntities.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let photolibpropslist = this.SystemPhotoLibraries.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let doclibpropslist = this.SystemDocumentLibraries.map(ent => {
          return ent[prop].toLowerCase().split(' ').join('')
        })
        let officalprops = this['Reserved'+prop+'Props'].map(prop => {
          return prop.toLowerCase().split(' ').join('')
        })
        let finalpropslist = tablepropslist.concat(officalprops,photolibpropslist,doclibpropslist)
        return [ 
            v => !!v || "This field is required",
            v => ( v && !finalpropslist.includes(v.toLowerCase().split(' ').join('')) ) || "This "+prop+" is already in use",
        ]
      },
      CancelInstallTablesandPlugin(){
        this.InstallingTables = []
        this.NewTablesDialog = false
        this.RawPlugin = ''
        this.NewPlugin = ''
      },
      FixTableNaming(){
        let vm = this
        return new Promise(function(resolve, reject) {
          vm.InstallingTables.map((table,tableindex)=> {
            let previousprimaryfieldid = table.RecordPrimaryField.Name
            let previousid = table.id
            if(previousprimaryfieldid !== table.Primary_Field_Name.split(' ').join('_')){
              console.log(previousprimaryfieldid,table.Primary_Field_Name.split(' ').join('_'))
              table.RecordPrimaryField.id = table.Primary_Field_Name.split(' ').join('_')
              table.RecordPrimaryField.Name = table.Primary_Field_Name.split(' ').join('_')
              table.RecordPrimaryField.DisplayName = table.Primary_Field_Name
              table.Primary_Field_Name = table.Primary_Field_Name.split(' ').join('_')
              table.id = table.DisplayName.split(' ').join('_')
              let fieldentry = table.AllFields.find(obj => obj.id === previousprimaryfieldid)
              fieldentry.id = table.Primary_Field_Name
              fieldentry.Name = table.Primary_Field_Name
              fieldentry.DisplayName = table.RecordPrimaryField.DisplayName
              table.SingleBuildTabs.map(tab => {
                if(tab.Elements){
                  tab.Elements.map(elmnt => {
                    if(elmnt.Fields){
                      let primfield = elmnt.Fields.find(obj => obj.id === previousprimaryfieldid)
                      if(primfield){
                       primfield.DisplayName = table.RecordPrimaryField.DisplayName
                       primfield.Name = table.Primary_Field_Name 
                      }                      
                    }
                  })
                }
              }) 
            }
            if(previousid !== table.DisplayName.split(' ').join('_')){
              table.id = table.DisplayName.split(' ').join('_')
            }            
             if(tableindex-1+2 === vm.InstallingTables.length){
              //console.log(Installation_Tables)
              resolve(vm.InstallingTables)
            }
          })
        })
      },
      FixLookupReferences(){
        let vm = this
        return new Promise(function(resolve, reject) {
          vm.InstallingTables.map((table,tableindex)=> {
            table.datasetsecuritystructure = table.Security_Structure.Name
            if(table.datasetsecuritystructure === 'Business Unit'){
              let bufield = {id: 'Business_Unit',Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'Business Unit', RelatedRecord: 'Business Units',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 1, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
              let ownerfield = {id: 'Owner',Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: true, IsHeader: true,HeaderIndex: 2, UsedonCompForm: true, UsedonForm: true,  SystemRequired: true}
              let bufieldon = table.AllFields.find(obj => obj.id === bufield.id)
              if(!bufieldon){
                table.AllFields.push(bufield)
              }
              let ownerfieldon = table.AllFields.find(obj => obj.id === ownerfield.id)
              if(!ownerfieldon){
                table.AllFields.push(ownerfield)
              }
            }
            let lookupfields = table.AllFields.filter(field => {
              return field.Type === 'Lookup'
            })
            lookupfields.map(lufield => {
              let entity = vm.InstallingTables.find(obj => obj.PluginEntityid === lufield.RelatedBuildID)
              if(entity && !entity.System_Required){
                //while there is a default template for each entity, if the entity is "System_Reqruied" the id, Name, and sec strucure will be readonly
                  //therefore no need to change the id either.
                  //we could have separated Icon. Sec Structure, Name, id, and primary field. but system_reuired truly means all of that
                lufield.LookupFieldName = entity.Primary_Field_Name.split(' ').join('_')
                lufield.RelatedLocation = entity.SingleName
                lufield.RelatedRecord = entity.DisplayName
                lufield.RelatedBuildID = entity.id 
              }              
            })
            let lusubcols = table.SubCollections.filter(subcol => {
              return subcol.LookupBuilds
            })
            lusubcols.map(subcol => {
              subcol.LookupBuilds.map(lubuild => {
                let entity = vm.InstallingTables.find(obj => obj.PluginEntityid === lubuild.id)
                if(entity && !entity.System_Required){
                  lubuild.single = entity.SingleName.split(' ').join('')
                  lubuild.tempname = entity.SingleName
                  lubuild.temppluralname = entity.DisplayName
                  lubuild.recordprimaryfield = entity.RecordPrimaryField
                  lubuild.identifier = entity.Primary_Field_Name
                  lubuild.id = entity.id
                  //actually Headers and LinkedFields as well...
                }                
              })
            })
            if(tableindex-1+2 === vm.InstallingTables.length){
              resolve(vm.InstallingTables)
            }
          })
        })
      },
      PreparePluginTables(){
        this.FixTableNaming().then(result => {
            this.FixLookupReferences().then(result => {
              //console.log(result)
              this.InstallTablesandPlugin()
            })
          })
      },
      InstallTablesandPlugin(){
        this.InstallingTables.map(table => {
            table.Primary_Appid = this.ActiveSuiteApp.id
            table.Plugins = [this.RawPlugin.id]
            table.Pluginid = this.RawPlugin.id
          })
          //let tableplugins = [this.RawPlugin]
          let totaltables = this.InstallingTables.length
          let counter = 0
          // tableplugins.map((plug,plugindex) => {
            let plug = this.RawPlugin
               plug.Installation_Tables.map(table => {
                let tablematch = this.InstallingTables.find(obj => obj.PluginEntityid === table.id && obj.PluginInstanceIndex === table.PluginInstanceIndex)
                if(tablematch){
                table.id = tablematch.id
                table.DisplayName = tablematch.DisplayName
                table.Entity_Type = tablematch.Entity_Type
                }
                
                counter = counter-1+2
                if(counter === totaltables){
                  this.StoreNewTablesPlugin()
                }
               })
          // })
      },
      AddTableSubCol(tableobj,subcol,subcolentry){
        let vm = this
        return new Promise(function(resolve, reject) {
        if(vm.RADB){
          vm.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(ranewdoc => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
              resolve(newsubcoldoc)
            })
          })          
        }
        else{
           db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).collection(subcol).doc(subcolentry.id).set(subcolentry).then(newsubcoldoc => {
            resolve(newsubcoldoc)
          })
        }
        })
      },
      AddTable(tableobj,finaltable){
        let vm = this
        return new Promise(function(resolve, reject) {
        if(vm.RADB){
          vm.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).set(finaltable).then(ranewdoc => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).set(finaltable).then(newdoc => {
              resolve(newdoc)
            })
          })          
        }
        else{
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(tableobj.id).set(finaltable).then(newdoc => {
            resolve(newdoc)
          })
        }
        })
      },
      StoreNewTablesPlugin(){
        let vm = this
        let tables = JSON.parse(JSON.stringify(this.InstallingTables))
        let totaltables = tables.length
        if(totaltables === 0){
          this.FinalizeStorePlugin(this.RawPlugin,this.NewPlugin)
        }
        else{
          tables.map((preppedtable,tableindex)=> {
               let tableobj = Object.assign({},preppedtable)
                  this.InstallingTables[tableindex] = {id: tableobj.id,DisplayName: tableobj.DisplayName}
                  //console.log(tableobj.id)
                  let subcols = ['AllFields','SubCollections','EntityProcesses','DigitizedForms','DocumentRegisters','Galleries','automations']
                  let finaltable = Object.assign({},tableobj)
                  delete finaltable.id
                  subcols.map(prop => {
                    delete finaltable[prop]
                  })
                  finaltable.Created_On = new Date()
                  finaltable.Modified_On = new Date()
                  finaltable.Created_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Created_Byid = vm.userLoggedIn.id,
                  finaltable.Modified_By = {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                  finaltable.Modified_Byid = vm.userLoggedIn.id
                  //console.log(tableobj.id,finaltable)
                  vm.AddTable(tableobj,finaltable).then(newdoc => {
                   if(finaltable.Entity_Type.Name === 'Classified Listing'){
                      let clsfdentity = {
                        SingleName: finaltable.SingleName,
                        PublishType: 'Public',
                        ClassifiedTabs: []
                      }
                      console.log(clsfdentity)
                      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('classifiedentities').doc(finaltable.DisplayName.split(' ').join('_')).set(clsfdentity)
                    }
                    let subcolentries = subcols.map(subcol => {
                      return tableobj[subcol]
                    }).flat()
                    let totalsubcolentries = subcolentries.length
                    let entrycounter = 0
                    subcols.map((subcol,subcolindex) => {                      
                      tableobj[subcol].map(subcolentry => {
                        if(this.RawPlugin.id === 'Online_Store' && tableobj.Entity_Type.Name === 'Store Order' && subcol === 'SubCollections' && subcolentry.id === 'Cart_Items'){
                          let storprodent = this.AppEntities.find(obj => obj.id === this.ActiveSuiteApp.Warehouse_Product_Master)
                          subcolentry.LookupBuilds[0].id = storprodent.id
                          subcolentry.LookupBuilds[0].single = storprodent.SingleName.split(' ').join('')
                          subcolentry.LookupBuilds[0].tempname = storprodent.SingleName
                          subcolentry.LookupBuilds[0].temppluralname = storprodent.DisplayName
                        }
                        //console.log(subcol,subcolentry.id)
                        vm.AddTableSubCol(tableobj,subcol,subcolentry).then(newdoc => {
                        entrycounter = entrycounter-1+2
                          if(tableindex-1+2 === totaltables && entrycounter === totalsubcolentries){
                            //DONE?
                            this.FinalizeStorePlugin(this.RawPlugin,this.NewPlugin)
                          }
                        })
                      })
                    })
                  })
                
                })
        }
      },
      NavigateRelevantNewDash(){
        let ref = ''
        let newdocid = ''
        if(this.NewDashboardType.title === 'Custom Dashboard'){
          ref = db.collection('customdashboards').doc()
          newdocid = ref.id
          let dashobj = {			
                DashboardName: this.NewDashboardName,			
                Created_By: this.UsersforProperties.find(obj => obj.id === this.userLoggedIn.id),			
                Created_Byid: this.userLoggedIn.id,			
                Owner: this.UsersforProperties.find(obj => obj.id === this.userLoggedIn.id),			
                Ownerid: this.userLoggedIn.id,			
                Created_On: new Date(),   			
            }
            dashobj.DashboardEntity = this.NewDashboardEntity
            dashobj.DashboardElements = []
           db.collection('customdashboards').doc(newdocid).set(dashobj).then(newdashdoc => {
             this.$router.push('/CustomDashboard/'+newdocid)
             this.CancelNewdashboardDial()
           })
        }
        if(this.NewDashboardType.title === 'Pipeline'){
          let ent = this.SystemEntities.find(obj => obj.id === this.NewDashboardEntity)
          let integer = 1000000+ent.EntityProcesses.length-1+2
          let NewProcess = {Name: this.NewDashboardName,DisplayName: this.NewDashboardName, Number: integer, Automations: [], Type:'Process', IsHeader: true, ProcessStages: [],ProcessStageInteger: 1000000,Status: 'Active'}
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.NewDashboardEntity).collection('EntityProcesses').doc(NewProcess.Number.toString()).set(NewProcess).then(doc => {
              this.$router.push('/PipelineManagement/'+this.NewDashboardEntity)
          })
          
          
        }
      },
        NewDashboardDialog(dashboard,haltdial){
          this.NewDashboardType = dashboard
          if(!haltdial){
           this.NewDashDialog = true 
          }          
        },
        CancelNewdashboardDial(){
          this.NewDashboardType = ''
          this.NewDashboardDialog = false
        },
        PurchasePlugin(pack){
          console.log(this.PluginsPath)
        if(pack.Level && pack.Level.Name === 'Suite' && !pack.Owned){
          let plugobj = {
              id: pack.id,
              Name: pack.Name,
              Module_Category: pack.Module_Category,
              Active: true
            }
          this.PluginsPath.doc(plugobj.id).set(plugobj)
        }
        console.log(pack)
        pack.Plugins.map(rawplug => {
          let plug = Object.assign({},rawplug)
            let plugobj = {
              id: plug.id,
              Name: plug.Name,
              Module_Category: plug.Module_Category,
              Active: true
            }
            plugobj.Active = true
            if(plug.Installation_Tables){
              let entcol = ''
              if(this.RADB){
                entcol = this.RADB.collection('entities')
              }
              else{
                entcol = db.collection('entities')
              }
              this.PreparePluginEntities(entcol,plug.Installation_Tables).then(InstallingTables => {
                this.InstallingTables = InstallingTables
                this.NewTablesDialog = true
                this.RawPlugin = Object.assign({},plug)
                this.NewPlugin = plugobj
                this.$store.commit('SetAlertSound','success_sound')
                this.$store.commit('EmitMarketDialog',false)
                this.$store.commit('EmitMarketDialogObject','')
                this.$store.commit('EmitMarketDialogOptions',[])
                this.$store.commit('EmitMarketDialogType','')
                this.$store.commit('EmitMarketDialogDescription','')
                this.$store.commit('EmitMarketDialogTitle','')        
                this.$store.commit('EmitMarketDialogAvatar','')
                //console.log(this.InstallingTables,plugobj)
              })              
            }
            else{
              this.AppsPath.doc(this.ActiveSuiteApp.id).collection('plugins').doc(plug.id).set(plugobj).then(obj => {
                this.$store.commit('SetAlertSound','success_sound')
                this.$store.commit('EmitMarketDialog',false)
                this.$store.commit('EmitMarketDialogObject','')
                this.$store.commit('EmitMarketDialogOptions',[])
                this.$store.commit('EmitMarketDialogType','')
                this.$store.commit('EmitMarketDialogDescription','')
                this.$store.commit('EmitMarketDialogTitle','')        
                this.$store.commit('EmitMarketDialogAvatar','')
                this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
                if(this.NewDashboardType){
                 this.NewDashDialog = true 
                }
              })
            }
            console.log(plugobj)
        })
      },
      FinalizeStorePlugin(plug,plugobj){
        //JUST A FAIR WARNING - THERE IS NO SYNC WHATSOEVER HAPPENING ON PLUGIN ENTITY INSTALLS SO FAR, NOTHING INSTALLING BACK TO RA, SO TREAD CAREFULLY
        console.log(plug,plugobj)
        if(plug.Level && plug.Level.Name === 'Suite' && !plug.Owned){
          this.PluginsPath.doc(plugobj.id).set(plugobj)
        }
        let appplug = Object.assign({},this.ActiveSuiteApp.Plugins.find(obj => obj.id === plugobj.id))
        console.log(appplug.Installation_Tables,this.InstallingTables)
        if(appplug && appplug.Installation_Tables){
          plugobj.Installation_Tables = appplug.Installation_Tables.concat(this.InstallingTables.map(table => {
            let tableobj = plug.Installation_Tables.find(obj => obj.id === table.id)
            return tableobj
          }))
        }
        else if(plug.Installation_Tables){
          plugobj.Installation_Tables = plug.Installation_Tables.map(table => {
            let tableobj = {
              id: table.id,
              DisplayName: table.DisplayName,
              Entity_Type: table.Entity_Type
            }
            return tableobj
          })
        }
        
        this.AppsPath.doc(this.ActiveSuiteApp.id).collection('plugins').doc(plug.id).set(plugobj).then(obj => {
          if(plugobj.id === 'Warehouse_&_Products'){
            let articleent = plugobj.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Inventory Article')
            let storprodent = plugobj.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Store Product')
            this.UpdateAppProp('Article_Master',articleent.id)
            this.UpdateAppProp('Warehouse_Product_Master',storprodent.id)
          }
          else if(plugobj.id === 'Online_Store'){
            let storordent = plugobj.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Store Order')
            this.UpdateAppProp('Store_Order_Master',storordent.id)
            let SONumberAuto = {
              id: storordent.id+'_SO_Number',
              Digits: 6,
              DisplayName: 'SO Number',
              Last_Numbers: [],
              Last_Number: 0,
              Last_Value: '',
              Name: 'SO_Number',
              NumberingString: '0',
              Type: 'AutoNumber Field',
              Suffixes: [],
              Prefixes: [
                {Prefix_Value: 'SO-',Type: 'Standard'}
              ]
            }
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(SONumberAuto.id).set(SONumberAuto)
            let invartent = this.SystemEntities.find(obj => obj.id === this.ActiveSuiteApp.Article_Master)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ActiveSuiteApp.Article_Master).update({
              Has_Store: true,
              Cart_Entity: storordent.id,
              NavList: [],
              ListPrimaryField: invartent.AllFields.find(obj => obj.id === 'Product_Group')
            })
          }
          else if(plugobj.id === 'User_Companies'){
            let defaultcompany = {
              Company_Name: this.System.Name,
              id: 'Non_Guest'
            }
            defaultcompany.Created_On = new Date()
            defaultcompany.Modified_On = new Date()
            defaultcompany.Created_By = {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
            defaultcompany.Created_Byid = this.userLoggedIn.id,
            defaultcompany.User = {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
            defaultcompany.Userid = this.userLoggedIn.id,
            defaultcompany.Modified_By = {Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname,Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
            defaultcompany.Modified_Byid = this.userLoggedIn.id
            defaultcompany.Business_Unit = {
              id: 'Head_Office',
              Name: 'Head Office'
            }
            defaultcompany.Business_Unitid = 'Head_Office'
            defaultcompany.Status = this.DefaultStatusField.DefaultOption
            defaultcompany.Status_Reason = this.DefaultStatusField.DefaultLevel2Option
            this.AddUserCompanyField()
            db.collection('usercompanies').doc(defaultcompany.id).set(defaultcompany).then(addedcomp => {
              this.UsersArray.map(user => {
                if(user.Business_Unitid !== 'Guest'){
                  db.collection('userroles').doc(user.id).update({
                    Company: {Company_Name: defaultcompany.Company_Name, id: defaultcompany.id},
                    Companyid: defaultcompany.id
                  })
                }
              })
            })
            
          }
          else if(plugobj.id === 'Cash_Loans'){
            let storordent = plugobj.Installation_Tables.find(obj => obj.Entity_Type.Name === 'Cash Loan')
            let SONumberAuto = {
              id: storordent.id+'_Order_Number',
              Digits: 6,
              DisplayName: 'Order Number',
              Last_Numbers: [],
              Last_Number: 0,
              Last_Value: '',
              Name: 'Order_Number',
              NumberingString: '0',
              Type: 'AutoNumber Field',
              Suffixes: [],
              Prefixes: [
                {Prefix_Value: 'LO-',Type: 'Standard'}
              ]
            }
            this.GetGeneralLedger()
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('AutoNumbers').doc(SONumberAuto.id).set(SONumberAuto)
          }
          this.AddUserCompanyField()
        })
        
      },
      GetGeneralLedger(){
        let vm = this
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Ledgers').doc('General').get().then(clientsnap => {
          let clientdata = clientsnap.data()
          if(!clientdata){
            vm.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID).collection('Ledgers').doc('General').get().then(rasnap => {
              let radata = rasnap.data()
              db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Ledgers').doc('General').set(radata)
            })
          }
        })
      },
      AddUserCompanyField(){
        let newfield = {Name: 'Company', DisplayName: 'Company', Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'User_Companies',Pluginid: 'User_Companies',
        RelatedLocation: 'UserCompany', RelatedRecord: 'User Companies',Primary: false, Created: true, IsMandatory: false, IsHeader: true, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true}
        if(this.NeedsRASync){
          this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(rausercolfield => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(configusercolfield => {
              this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(rafield => {
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(configfield => {
                  
                })
              })
            })
          })
        }
        else{
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Users').collection('AllFields').doc(newfield.Name).set(newfield).then(configusercolfield => {
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc('Billing_Accounts').collection('AllFields').doc(newfield.Name).set(newfield).then(configfield => {
                  
                })
          })
        }
        
      },
      PreparePluginEntities(query,entities){
         //so what if the plugin installs BA, but we already have BA? 
         let vm = this
         let tables = []
         let totaltables = entities.length
          return new Promise(function(resolve, reject) {
            entities.map((table,tableindex)=> {
                vm.GetAllEntityProps(query,table).then(tableobj => {
                  tables.push(tableobj)
                  if(tableindex-1+2 === totaltables){
                    resolve(tables)
                  }
                })
            })
          })
      },
      GetAllEntityProps(configdb,table){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
          configdb.doc(table.id).get().then(tablensap => {
            let build = tablensap.data()
            build.id = table.id
            build.PluginEntityid = table.id
            vm.GetEntityAllFields(configdb,build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(configdb,build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(configdb,build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(configdb,build).then(function(formsresult) {
                            vm.GetDocumentRegisters(configdb,build).then(function(docregresult) {  
                                vm.GetGalleries(configdb,build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(configdb,build).then(function(automationsresult) {
                                    //console.log(build)
                                    resolve(build)
                                    
                                
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
          })        
        })
        },
    GetEntityAllFields(configdb,build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
                //console.log(configdb.doc(build.id).collection('AllFields'))
            configdb.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(configdb,build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(configdb,build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(configdb,build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(configdb,build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(configdb,build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(configdb,build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        PickPlugin(SelectedPlugin){
            console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
        },
        RAPluginsMatched(categorymatches){
       let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id) && !plug.Exclusively_as_Extension || newappplugins.includes(plug.id) && !plug.Strictly_One_Purchase
        }).map(plug => {
              if(this.PluginDataBase[plug.id] && this.PluginDataBase[plug.id].Active){
                  plug.Owned = true
              }
              return plug
          })
          .filter(plug => {
            //okay we filter out and filter back in..just as a note we leave it. however ideally on purchase if suite level and not owned, ensure to add to Suite as well as App, that's the point
            return plug.Owned || plug.Level && plug.Level.Name === 'App'
            || !plug.Owned
          })
      },
        ActivateActiveChildComponent(child){
          //console.log(child)
            this.ActiveChildComponent = child
        },
        setActiveAppComponent(item){
            console.log(item)
            this.ActiveAppComponent = item
        }
    }
}
</script>

<style>

</style>



