
<template>					
   <v-card tile flat height="100%" width="100%" style="margin-top:65px;">					
     <div class="hidden-sm-and-up" v-if="!ViewOnly">					
     <!-- {{SaveDashboardElements}} -->		
     			
     </div>				
    <v-dialog v-model="CopyPDFDialog">
      <v-card>
        <v-card-title>
          Replicate PDF
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field label="New Function Name" v-model="NewFunctionName"/>
            <v-switch label="Custom id?" v-model="NewFunctionidCustom"/>
            <v-text-field v-if="NewFunctionidCustom" label="New Custom id" v-model="NewFunctionCustomid"/>
         
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn dark color="warning" @click="DeactivateCopyPDFDialog()">Cancel</v-btn>
          <v-spacer>
          </v-spacer>
          <v-btn dark color="success" @click="ProcessCopyPDFDialog()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      	<PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[FunctionData]"				
        :FullItem="FullItem" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" 					
        />	
        
         <v-toolbar height="25" class="background" width="100%" dense style="position: fixed;z-index:3;margin-top:-25px;">
          <!-- <v-btn @click="PDFPropertiesDialog = !PDFPropertiesDialog" plain>PDF Properties</v-btn> -->
          <v-spacer></v-spacer>
            <v-btn @click="ToggleHideToolbars()" plain>Hide Toolbars</v-btn>
            
              <v-spacer></v-spacer>
              <!-- <v-btn @click="PagesDialog = !PagesDialog" plain>Pages</v-btn> -->
         </v-toolbar>
        		<v-toolbar height="25" class="background" width="100%" dense style="position: fixed;z-index:3;margin-top:2px;">
          <!-- <v-btn @click="PDFPropertiesDialog = !PDFPropertiesDialog" plain>PDF Properties</v-btn> -->
          <v-spacer></v-spacer>
            <v-btn @click="ActivateCopyPDFDialog()" plain>Copy PDF</v-btn>
            
              <v-spacer></v-spacer>
              <!-- <v-btn @click="PagesDialog = !PagesDialog" plain>Pages</v-btn> -->
         </v-toolbar>		
        <DataSingleViewComponent v-if="SelectedRecord" :SystemEntities="PreparedSystemEntities" :System="System" :CategoricalNavlists="[]"
        :FunctionEntityid="FunctionEntityid" :PushedID="SelectedRecord.id" :UsersArray="UsersArray" @updateFullItem="updateFullItem" :dashboardelementindex="0"/>					
        <DataCollectionViewComponent v-if="FunctionEntity && !RefresingPreview" :FunctionEntityid="FunctionEntityid" :SystemEntities="PreparedSystemEntities" :System="System"
        :UsersArray="UsersArray" @updatearray="updatearray" :dashboardelementindex="0"/>					
        <v-layout class="black justify-center fill-height" row>					
            <v-flex xl2 lg2 md3 sm3 xs3 v-if="!HideToolbars">				
                 <v-navigation-drawer class="" width="100%" :permanent="PDFPropertiesDialog" v-model="PDFPropertiesDialog">
                   <v-list width="100%" class="transparent" v-if="!EditingPageElements">					
                     <v-list-item @click="SaveFunction()">					
                         Save	
                     </v-list-item>					
                     <!-- <v-list-item @click="FixStructure()">					
                         FixStructure					
                     </v-list-item>	 -->				
                     					
                     <v-list-item >					
                         <v-switch @change="RefreshLivePreview()" label="Live Preview Mode" v-model="LivePreviewMode" />					
                     </v-list-item>					
                      <v-list-item v-if="LivePreviewMode && FullItem" @click="RefreshLivePreview()">					
                        Refresh Preview					
                     </v-list-item>					
                        <v-list-item>					
                             <v-text-field v-model="FunctionDisplayName" label="Display Name"/>					
                        </v-list-item>					
                        <v-list-item>					
                             <v-select return-object v-if="GotEntities" :items="ComputedSystemEntities" item-text="id" label="Entity" v-model="FunctionEntity"/>					
                        </v-list-item>    					
                        <v-list-item v-if="GotEntities && FunctionEntity && FunctionEntityPrimaryFieldName && LivePreviewMode">					
                             <v-select return-object :items="FullArray" :item-text="FunctionEntityPrimaryFieldName" label="Selected Record" v-model="SelectedRecord"/>					
                        </v-list-item>                    					
                         <v-list-item>					
                            <v-select return-object :items="FunctionOutputTypes" item-text="DisplayName" label="FunctionOutput" v-model="FunctionOutput"/>					
                        </v-list-item>					
                         <v-list-item class="mx-3" v-if="SelectedRecord" @click="ExportTest()">					
                           Test PDF <v-icon color="red">mdi-file-pdf</v-icon><v-icon>mdi-export</v-icon>					
                        </v-list-item>					
                        					
                        <!-- <v-list-item>					
                            <v-select return-object :items="UsersArray" item-text="Full_Name" label="Who can View" multiple v-model="WhocanView"/>					
                        </v-list-item>	 -->				
                    </v-list>					
                     <v-list  v-if="EditingPageElements">					
                        <!-- <v-list-item >					
                            ActiveElementDialog  {{ActiveElementDialog}}					
                        </v-list-item> -->					
                        <v-list-item>                            					
                            <v-btn @click="ToggleActiveElementDialog()" outlined class="elevation-6 mx-1" >					
                                Add Element					
                            </v-btn>					
                        </v-list-item>                       					
                        <v-list-item @click="ActivateActiveElement(elmnt,elmntindex)" style="border: 1px solid #dedee9;" v-for="(elmnt,elmntindex) in ActivePage.Elements" :key="elmnt.itemObjKey">
                          <v-list-item-content @drop.prevent="elmntdragfinish(elmnt,elmntindex)"	@dragover.prevent								
                            :draggable="true" @dragstart="elmntdragstart(elmnt,elmntindex)">			
                            {{elmnt.Name}}		
                          </v-list-item-content>			
                        </v-list-item>					
                    </v-list>
                 </v-navigation-drawer>
            </v-flex>
            <v-navigation-drawer v-if="ActiveElementDialog" class="" permanent v-model="ActiveElementDialog">
              <v-list-item-title class="mediumoverline mx-3 my-1">
                <v-btn color="red" @click="DeActivateActiveElement()" icon><v-icon>mdi-close</v-icon></v-btn>
                    {{ currentTitle }} 
                    <v-btn color="green" @click="SaveElementChanges()" icon><v-icon>mdi-content-save</v-icon></v-btn>
                  </v-list-item-title>
                  <!-- <v-card-actions>					
                          <v-btn @click="DeActivateActiveElement()">Close</v-btn>					
                          <v-spacer></v-spacer>					
                          <v-btn @click="SaveElementChanges()">					
                              Save					
                          </v-btn>					
                      </v-card-actions>	 -->
                <v-list dense class="transparent my-6" width="100%" v-if="step === 1">
                  
                    <v-list-item >					
                        <v-text-field v-model="ActiveElement.Name" label="Element Name"/>                        					
                    </v-list-item>
                  <v-list-item >					
                        <v-select :items="WidthOptions" v-model="ActiveElement.WidthOption" label="Width Option"/>                        					
                    </v-list-item>					
                    <v-list-item v-if="ActiveElement.WidthOption">					
                        <v-select :items="OutputTypes" v-model="ActiveElement.OutputType" label="Output Type"/>                        					
                    </v-list-item>					
                </v-list>				
               
              <v-list dense class="transparent my-6" width="100%" v-if="step === 2 && ActiveElement.OutputType && ActiveElement.OutputType === 'Rectangle'">
                <v-list-item v-if="ActiveElement.OutputType && ActiveElement.OutputType === 'Rectangle'">
                        <v-select :items="RectangleTypes" label="Rectangle Type" v-model="ActiveElement.RectangleType"/>                  					
                  </v-list-item>
                <v-list-item v-if="ActiveElement.OutputType && ActiveElement.OutputType === 'Rectangle'">					
                      <v-text-field v-model.number="ActiveElement.RectangleWidth" type="number" label="Rectangle Width"/>                  					
                  </v-list-item>					
                  <v-list-item v-if="ActiveElement.OutputType && ActiveElement.OutputType === 'Rectangle'">					
                      <v-text-field v-model.number="ActiveElement.RectangleHeight" type="number" label="Rectangle Height"/>                  					
                  </v-list-item>	
                <v-menu			
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on" style="padding-right:40px;">					
                     Border Color			
                  </v-list-item>
                  </template>
                   <v-color-picker  v-model="ActiveElement.DrawColor"></v-color-picker>  	
                </v-menu>
                <v-menu 
                  :close-on-content-click="false"                            									
                  transition="scale-transition"                            									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on" style="padding-right:40px;">					
                     Fill Color			
                  </v-list-item>
                  </template>
                    <v-color-picker v-model="ActiveElement.FillColor"></v-color-picker> 	
                </v-menu>						
              </v-list>
              <v-list dense class="transparent my-6" width="100%" v-if="step === 2 && ActiveElement.OutputType && ActiveElement.OutputType !== 'Rectangle'">		
                  	<v-list-item v-if="ActiveElement.OutputType && ActiveElement.OutputType !== 'Rectangle'">					
                        <v-select :items="ComputedDataSourceTypes" v-model="ActiveElement.DataSource" label="Data Source"/>                        					
                  </v-list-item>			
                    <v-list-item v-if="ActiveElement.DataSource === 'Data Text' || ActiveElement.DataSource === 'Image Field' || ActiveElement.DataSource === 'User Value'">					
                      <v-select item-text="DisplayName" return-object :items="GetRelevantFields(ActivePage,ActiveElement.DataSource)" v-model="ActiveElement.DataField" label="Data Field"/>                  					
                  </v-list-item>	
                  <v-list-item v-if="ActiveElement.DataField && ActiveElement.DataField.Type === 'Lookup'">					
                      <v-select item-text="DisplayName" return-object :items="GetRelevantLookupEntityFields(ActiveElement.DataField)" v-model="ActiveElement.DataLookupFieldName" label="Lookup Data Field"/>                  					
                  </v-list-item>				
                  <v-list-item v-if="ActiveElement.DataSource === 'Data Text' && ActiveElement.DataFieldType === 'Common Field'">					
                      <v-select :items="DataFieldCommonFieldTypes" v-model="ActiveElement.DataLookupFieldCommonFieldType" label="Common Field Type"/>                  					
                  </v-list-item> 				
             
                  <v-list-item v-if="ActiveElement.DataFieldType === 'Lookup'">					
                      <v-select :items="DataFieldTypes" v-model="ActiveElement.DataLookupFieldType" label="DataLookupFieldType"/>               					
                  </v-list-item>					
                  <v-list-item v-if="ActiveElement.DataFieldType === 'Lookup'">			   					
                      <v-text-field v-model="ActiveElement.DataLookupFieldName" label="DataLookupFieldName"/>           					
                  </v-list-item>	
                  <v-list-item v-if="ActiveElement.DataLookupFieldType === 'Lookup'">					
                      <v-select :items="DataFieldTypes" v-model="ActiveElement.DataLookupFieldType" label="DataLookupFieldType"/>               					
                  </v-list-item>			
                  <v-list-item v-if="ActiveElement.DataSource === 'Custom Text'">	
                    <ContentEditableField style="padding:15px;"
                       :FieldObject="ActiveElement" :FieldName="'TextValue'"
                       :FieldValue="ActiveElement.TextValue" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
           					
                  </v-list-item> 					
                  <v-list-item v-if="ActiveElement.DataSource === 'Custom Text' || ActiveElement.OutputType !== 'Image'">					
                      <v-text-field v-model="ActiveElement.FontSize" type="number" label="Font Size"/>                  					
                  </v-list-item> 					
                  <v-menu 									
                  :close-on-content-click="false"                            									
                  transition="scale-transition"   v-if="ActiveElement.DataSource === 'Custom Text' || ActiveElement.DataSource && ActiveElement.OutputType !== 'Image'"                          									
                  >									
                  <template v-slot:activator="{ on }">						
                  <v-list-item v-on="on" style="padding-right:40px;" v-if="ActiveElement.DataSource === 'Custom Text' || ActiveElement.DataSource && ActiveElement.OutputType !== 'Image'">					
                     Font Color			
                  </v-list-item>
                  </template>
                  <v-color-picker v-model="ActiveElement.FontColor"></v-color-picker>  
                </v-menu>						
                  <v-list-item v-if="ActiveElement.DataSource === 'Custom Text' ||ActiveElement.DataSource && ActiveElement.OutputType !== 'Image'">					
                      <v-select :items="FontStyles" v-model="ActiveElement.FontStyle" label="Font Style"/>                        					
                  </v-list-item>					
                </v-list>
                <v-list dense class="transparent my-6" width="100%" v-if="step === 3"> 					
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-select :items="PositionTypes" v-model="ActiveElement.Position" label="Position"/>                        					
                  </v-list-item>					
                          
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-text-field v-model.number="ActiveElement.PaddingTop" type="number" label="Padding Top"/>                      					
                  </v-list-item>					
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-text-field v-model.number="ActiveElement.PaddingLeft" type="number" label="PaddingLeft"/>                  					
                  </v-list-item>	
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-select :items="JustifyTypes" v-model="ActiveElement.Justify" label="Justify Type"/>                  					
                  </v-list-item>						
              <!-- </v-list>
              <v-list dense class="transparent my-6" width="100%" v-if="step === 3"> 	 -->
                <v-menu 									
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                          									
                    >									
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                        Top Position
                      </v-list-item>
                    </template>
                    <v-list>
                      <v-list-item v-if="ActiveElement.OutputType">					
                          <v-switch v-model="ActiveElement.InheritTopPosition" label="InheritTopPosition"/>                  					
                      </v-list-item>					
                      <v-list-item v-if="ActiveElement.OutputType && ActiveElement.InheritTopPosition">					
                          <v-select return-object :items="ActivePageIndexedElements" item-text="Name" v-model="ActiveElement.InheritedTopPositionParent" label="Inherited Top Parent"/>                  					
                      </v-list-item>
                      <v-list-item v-if="ActiveElement.OutputType && ActiveElement.InheritTopPosition">					
                          <v-select :items="PositionInheritTypes" v-model="ActiveElement.InheritedTopPosition" label="Inherited Top Position"/>                  					
                      </v-list-item>	
                    </v-list>
                </v-menu>			
                  <v-menu 									
                    :close-on-content-click="false"                            									
                    transition="scale-transition"                          									
                    >									
                    <template v-slot:activator="{ on }">
                      <v-list-item v-on="on">
                          Width Position
                        </v-list-item>
                      </template>
                      <v-list>
                        <v-list-item v-if="ActiveElement.OutputType">					
                        <v-switch v-model="ActiveElement.InheritWidthPosition" label="InheritWidthPosition"/>                  					
                    </v-list-item>					
                    <v-list-item v-if="ActiveElement.OutputType && ActiveElement.InheritWidthPosition">					
                        <v-select return-object :items="ActivePageIndexedElements" item-text="Name" v-model="ActiveElement.InheritedWidthPositionParent" label="Inherited Width Parent"/>                  					
                    </v-list-item>					
                    <v-list-item v-if="ActiveElement.OutputType && ActiveElement.InheritWidthPosition">					
                        <v-select :items="PositionInheritTypes" v-model="ActiveElement.InheritedWidthPosition" label="Inherited Width Position"/>                  					
                    </v-list-item>
                    </v-list>
                </v-menu>	
              </v-list>
              <v-list  dense class="transparent my-6" width="100%" v-if="step === 4">					
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-switch v-model="ActiveElement.AllowPageRollover" label="AllowPageRollover"/>                        					
                  </v-list-item>	
              </v-list>	
              <v-list  dense class="transparent my-6" width="100%" v-if="step === 4">	
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-switch v-model="ActiveElement.AllowColumnRollover" label="AllowColumnRollover"/>                        					
                  </v-list-item>			
              </v-list>	
              <v-list  dense class="transparent my-6" width="100%" v-if="step === 4">		
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-switch v-model="ActiveElement.ForceNewColumn" label="ForceNewColumn"/>                        					
                  </v-list-item>				
              </v-list>	
              <v-list  dense class="transparent my-6" width="100%" v-if="step === 4">		
                  <v-list-item v-if="ActiveElement.OutputType">					
                      <v-switch v-model="ActiveElement.ForceNewPage" label="ForceNewPage"/>                        					
                  </v-list-item>					
              </v-list>	
                    <v-card-actions>
                      <v-btn
                        :disabled="step === 1"
                        text
                        @click="step--"
                      >
                        Back
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        :disabled="step === 4"
                        color="primary"
                        depressed
                        @click="step++"
                      >
                        Next
                      </v-btn>
                    </v-card-actions>	
                  </v-navigation-drawer>
                   			
            <v-flex :xl="HideToolbars? 12 :8" :lg="HideToolbars? 12 :8" :md="HideToolbars? 12 :6" :sm="HideToolbars? 12 :6" :xs="HideToolbars? 12 :6">					
                						
                            
                <v-card class="blue-grey darken-3" v-if="ActivePage" dark tile flat outlined height="100%" width="100%" style="padding:50px;" id="mainbuildercomp">					
                <v-layout class="justify-center">		
                <!-- <span v-if="Pages[0].Elements[6]">  {{Pages[0].Elements[6].TextValue}}</span> -->					
                    <!-- DocumentDisplayHeight {{DocumentDisplayHeight}} -->	
                <v-btn  @click="ExportTest()" fixed color="red" style="right: 20px;bottom:100px;" v-if="HideToolbars && SelectedRecord">Test<v-icon>mdi-export</v-icon></v-btn>
                <v-btn fixed v-if="ModePageArray[ActivePageNumber-2] && HideToolbars"  fab style="left:20px;"  dark class="accent growleft" @click="ActivePageNumber--">					
                    <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>					
                  </v-btn>
                <v-card v-if="DocumentDisplayWidth !== 0 && DocumentDisplayHeight !== 0" flat tile light :width="DocumentDisplayWidth" :height="DocumentDisplayHeight" :style="ActivePageStyling">	
                  		 	
                  <!-- <PDFExportFunctionPreviewer :FunctionData="FunctionData" :ActivePage="ActivePage" v-if="FullItem && !RefresingPreview" :DocumentDisplayRatio="DocumentDisplayRatio"					
                  @TogglePage="TogglePage" :FunctionPages="Pages" @ActivateActiveElement="ActivateActiveElement" :ExportingTest="ExportingTest"					
                :FullItem="FullItem"/> -->					
                <PDFExporter v-if="DisplayRecord && !RefresingPreview" :ExporttoPDFFunctions="[FunctionData]" @ActivateActiveElement="ActivateActiveElement"					
                :ActivePage="ActivePage" :DocumentDisplayRatio="DocumentDisplayRatio" :ExportingTest="ExportingTest"					
                :FullItem="DisplayRecord" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog"  :FunctionData="FunctionData" :FunctionPages="Pages"					
                />					
                </v-card>		
                 <v-btn fixed v-if="ModePageArray[ActivePageNumber] && HideToolbars"  fab style="right:20px;"  dark class="accent growright" @click="ActivePageNumber++">					
                  <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>					
                </v-btn>			
                </v-layout>				
            </v-card>
            
               		
            </v-flex>					
            <v-flex xl2 lg2 md3 sm3 xs3 v-if="!HideToolbars">
              <v-navigation-drawer class="" width="100%" right :permanent="PagesDialog" v-model="PagesDialog">					
              <!-- NewPageDefault -->					
                 <v-list class="transparent" v-if="ActivePage">					
                   <v-list-item @click="AddNewPage()">					
                       Add New Page					
                     </v-list-item>					
                   <v-list-item-title class="overline mx-3">					
                     Page Number {{ActivePage.PageNumber}}</v-list-item-title>					
                     					
                     <v-list-item>					
                         <v-btn v-if="ModePageArray[ActivePageNumber-2]"  fab :style="ArrowLeftStyle"  dark class="accent growleft" @click="ActivePageNumber--">					
                        <v-icon class="mx-10" x-large color="white">mdi-chevron-left</v-icon>					
                        </v-btn>					
             					
                        <v-btn v-if="ModePageArray[ActivePageNumber]"  fab :style="ArrowRightStyle"  dark class="accent growright" @click="ActivePageNumber++">					
                        <v-icon class="mx-10" x-large color="white">mdi-chevron-right</v-icon>					
                        </v-btn>					
                     </v-list-item>					
                     <v-list-item v-if="!LivePreviewMode">					
                            <v-switch label="Edit Elements" @change="DeActivateActiveElement()" v-model="EditingPageElements" />					
                        </v-list-item>					
                        <v-list-item>					
                             <v-text-field v-model="ActivePage.PageName" label="Display Name"/>					
                        </v-list-item>					
                         <v-list-item>					
                             <v-switch v-model="ActivePage.PageHasWaterMark" label="Page Has WaterMark"/>					
                        </v-list-item>					
                        <v-list-item v-if="ActivePage.PageHasWaterMark">					
                        <v-select return-object v-model="ActivePage.PageWaterMarkObject" label="WaterMark" :items="SystemAssets" item-text="Name">
                          <template v-slot:item="{ item }">
                            <v-list>
                              <v-list-item>
                                <v-list-item-avatar tile size="100">
                                  <img :src="item.ThumbURL" style="object-fit: cover;"/>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  {{item.Name}}
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                        </template>
                        </v-select>					
                             <!-- <v-text-field v-model="ActivePage.PageWaterMarkName" label="WaterMark Name"/>			 -->		
                        </v-list-item>					
                        <v-list-item v-if="ActivePage.PageHasWaterMark">					
                             <v-text-field v-model.number="ActivePage.PagePaddingRight" type="number" label="Page Padding Right"/>					
                        </v-list-item>					
                        <v-list-item>					
                             <v-switch v-model="ActivePage.PageDatafromArray" label="Page Data from Array"/>					
                        </v-list-item>					
                        <v-list-item v-if="ActivePage.PageDatafromArray">					
                             <v-select :items="ArrayTypes" v-model="ActivePage.ArrayType" label="Array Type"/>					
                        </v-list-item>					
                        <v-list-item v-if="ActivePage.PageDatafromArray && ActivePage.ArrayType">					
                             <v-select :items="DynamicArrayItems(ActivePage.ArrayType)" v-model="ActivePage.DynamicArrayName" label="Dynamic Array"/>					
                        </v-list-item>					
                        <v-list-item v-if="ActivePage.PageDatafromArray">					
                             <v-switch v-model="ActivePage.AddPageperArrayItem" label="Add Page per Array Item"/>					
                        </v-list-item>					
                    </v-list>		
              </v-navigation-drawer>			
            </v-flex>					
         </v-layout>					
    <v-row v-if="LoadingItems"					
      align="center"					
      justify="center"					
    > 					
    <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">					
              <v-container bg fill-height grid-list-md text-xs-center>					
              <v-layout row wrap align-center class="justify-center">					
              <v-card-text class="justify-center">					
                <v-layout class="justify-center">					
                   <v-progress-circular size="225"					
                      :width="15"					
                      color="purple"					
                      indeterminate					
                    >Loading</v-progress-circular>					
                  </v-layout>					
              </v-card-text>					
              </v-layout>					
              </v-container>					
        </v-card>					
     					
    </v-row>					
    					
    <!-- <v-layout row class="justify-center mx-3 my-3"  v-for="(row,i) in ComputedFunctionPages" :key="row.itemObjKey">					
       <v-flex xl12 lg12 md12 sm12 xs12>					
       </v-flex>					
    </v-layout>			 -->		
      <!-- </v-layout> -->					
      <!-- </v-flex> -->					
   </v-card>					
</template>					
					
<script>					
import firebase from 'firebase';					
import db from '@/main'					
import format from 'date-fns/format'					
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'						
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent'			
import DataSingleViewComponent from '@/components/Database/DataSingleViewComponent'	
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {					
  props: ['PushedElmnt','SystemEntities','System','UsersArray','IntranetView','RelatedObj','PageFilter','PageView','RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray','CompSize','AllowsHistoric','ReferenceView','DocTableCall','RenderComp','AppisDarkMode'],			
  components: {					
      PDFExporter,	
      DataCollectionViewComponent,
      DataSingleViewComponent,
      ContentEditableField		
  },					
  data() {					
    return {	
      POEntity: {
        id: 'Purchase_Orders',
        DisplayName: 'Puchase Orders',
        SingleName: 'Purchase Order',
        Table_Icon: 'mdi-cart-register',
        Entity_Type: {
          ID: 2000001,Name: 'Purchase Orders'
        },
        Security_Structure: {ID: 1000004,Name: 'Purchase Orders'},
        AllFields: [
          {id: 'PO_Number',Name: 'PO_Number',DisplayName: 'PO Number',Type: 'Single Line Text',Primary: true},
          {id: 'Company_Name',Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},
          {id: 'Delivery',Name: 'Delivery',DisplayName: 'Delivery',Type: 'Boolean'},
          {id: 'Payment_Terms',Name: 'Payment_Terms',DisplayName: 'Payment Terms',Type: 'Single Line Text',ReadOnly: true},
          {id: 'PO_Bill_To_Address',Name: 'PO_Bill_To_Address',DisplayName: 'PO_Bill_To_Address',Type: 'Common Field',CommonFieldType: 'Postal Address'},
          {id: 'PO_Delivery_Address',Name: 'PO_Delivery_Address',DisplayName: 'PO_Delivery_Address',Type: 'Common Field',CommonFieldType: 'Physical Address'},
          {id: 'Supplier_Collection_Address',Name: 'Supplier_Collection_Address',DisplayName: 'Supplier_Collection_Address',Type: 'Common Field',CommonFieldType: 'Physical Address'},
          {id: 'Supplier_Invoice_Address',Name: 'Supplier_Invoice_Address',DisplayName: 'Supplier_Invoice_Address',Type: 'Common Field',CommonFieldType: 'Postal Address'},
          {id: 'Created_On', Name: 'Created_On',DisplayName: 'PO Date',text: 'PO Date',value: 'Created_On',Type: 'Date'},
          // {id: 'Payment_Terms', Name: 'Payment_Terms',DisplayName: 'Payment Terms',text: 'Payment Terms',value: 'Payment_Terms',Type: 'Single Line Text'}, 
          {id: 'Shipping_Method', Name: 'Shipping_Method',DisplayName: 'Shipping Method',text: 'Shipping Method',value: 'Shipping_Method',Type: 'Option Set',Options:[{ID: 1000001,Name: 'FOB'}]},
          {id: 'Promised_Date', Name: 'Promised_Date',DisplayName: 'Promised Date',text: 'Promised Date',value: 'Promised_Date',Type: 'Date'},
          {id: 'Sub_Total',DisplayName: 'Sub Total',Name: 'Sub_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Grand_Total',DisplayName: 'Grand Total',Name: 'Grand_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Tax_Total',DisplayName: 'Tax Total',Name: 'Tax_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Freight_Total',DisplayName: 'Freight Total',Name: 'Freight_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Discount_Total',DisplayName: 'Discount Total',Name: 'Discount_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Supplier',DisplayName: 'Supplier',Name: 'Supplier',class: 'overline',TableHidden: true,RelatedBuildID: 'Store_Suppliers',RelatedLocation: 'StoreSupplier',LookupFieldName: 'Supplier_Name',Type: 'Lookup'},
          {id: 'Warehouse',DisplayName: 'Warehouse',Name: 'Warehouse',class: 'overline',TableHidden: true,RelatedBuildID: 'Warehouses',RelatedLocation: 'Warehouse',LookupFieldName: 'Name',Type: 'Lookup'},
          {id: 'Footer_Note',DisplayName: 'Notes',Name: 'Footer_Note',Type: 'Multiple Lines Text',class: 'overline',TableHidden: true},          
        ],
        Galleries: [],
        DocumentRegisters: [],
        EntityProcesses: [],
        SubCollections: [

        ],
        RecordPrimaryField: {id: 'PO_Number',Name: 'PO_Number',DisplayName: 'PO Number',Type: 'Single Line Text',Primary: true},
        PrimaryFieldName: 'PO_Number'
      },
      SOEntity: {
        id: 'Sales_Invoices',
        DisplayName: 'Sales Invoices',
        SingleName: 'Sales Invoice',
        Table_Icon: 'mdi-cart-register',
        Entity_Type: {
          ID: 2000001,Name: 'Sales Invoices'
        },
        Security_Structure: {ID: 1000004,Name: 'Sales Invoices'},
        AllFields: [
          {id: 'Invoice_Number',Name: 'Invoice_Number',DisplayName: 'Invoice Number',Type: 'Single Line Text',Primary: true},
          {id: 'Company_Name',Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},
          {id: 'Customer_ID',Name: 'Customer_ID',DisplayName: 'Customer ID',Type: 'Single Line Text'},
          {id: 'Customer_Contact',Name: 'Customer_Contact',DisplayName: 'Customer Contact',Type: 'Single Line Text'},
          {id: 'Customer_Company',Name: 'Customer_Company',DisplayName: 'Customer Company',Type: 'Single Line Text'},
          {id: 'Delivery',Name: 'Delivery',DisplayName: 'Delivery',Type: 'Boolean'},
          // {id: 'Payment_Terms',Name: 'Payment_Terms',DisplayName: 'Payment Terms',Type: 'Single Line Text',ReadOnly: true}, presumed COD
          {id: 'PO_Bill_To_Address',Name: 'PO_Bill_To_Address',DisplayName: 'PO_Bill_To_Address',Type: 'Common Field',CommonFieldType: 'Postal Address'},
          {id: 'PO_Delivery_Address',Name: 'PO_Delivery_Address',DisplayName: 'PO_Delivery_Address',Type: 'Common Field',CommonFieldType: 'Physical Address'},
          {id: 'Supplier_Collection_Address',Name: 'Supplier_Collection_Address',DisplayName: 'Supplier_Collection_Address',Type: 'Common Field',CommonFieldType: 'Physical Address'},
          {id: 'SO_Delivery_Address',Name: 'SO_Delivery_Address',DisplayName: 'SO Delivery Address',Type: 'Common Field',CommonFieldType: 'Physical Address'},
          {id: 'Created_On', Name: 'Created_On',DisplayName: 'Invoice Date',text: 'Invoice Date',value: 'Created_On',Type: 'Date'},
          // {id: 'Payment_Terms', Name: 'Payment_Terms',DisplayName: 'Payment Terms',text: 'Payment Terms',value: 'Payment_Terms',Type: 'Single Line Text'}, always COD on SO for now
          {id: 'Shipping_Method', Name: 'Shipping_Method',DisplayName: 'Shipping Method',text: 'Shipping Method',value: 'Shipping_Method',Type: 'Option Set',Options:[{ID: 1000001,Name: 'FOB'}]},
          // {id: 'Promised_Date', Name: 'Promised_Date',DisplayName: 'Promised Date',text: 'Promised Date',value: 'Promised_Date',Type: 'Date'},
          {id: 'Sub_Total',DisplayName: 'Sub Total',Name: 'Sub_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Grand_Total',DisplayName: 'Grand Total',Name: 'Grand_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Tax_Total',DisplayName: 'Tax Total',Name: 'Tax_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Freight_Total',DisplayName: 'Freight Total',Name: 'Freight_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Discount_Total',DisplayName: 'Discount Total',Name: 'Discount_Total',ReadOnly: true,Type: 'Number Field',IsCurrency: true},
          {id: 'Supplier',DisplayName: 'Supplier',Name: 'Supplier',class: 'overline',TableHidden: true,RelatedBuildID: 'Store_Suppliers',RelatedLocation: 'StoreSupplier',LookupFieldName: 'Supplier_Name',Type: 'Lookup'},
          {id: 'Warehouse',DisplayName: 'Warehouse',Name: 'Warehouse',class: 'overline',TableHidden: true,RelatedBuildID: 'Warehouses',RelatedLocation: 'Warehouse',LookupFieldName: 'Name',Type: 'Lookup'},
          {id: 'Footer_Note',DisplayName: 'Notes',Name: 'Footer_Note',Type: 'Multiple Lines Text',class: 'overline',TableHidden: true},          
        ],
        Galleries: [],
        DocumentRegisters: [],
        EntityProcesses: [],
        SubCollections: [

        ],
        RecordPrimaryField: {id: 'PO_Number',Name: 'PO_Number',DisplayName: 'PO Number',Type: 'Single Line Text',Primary: true},
        PrimaryFieldName: 'PO_Number'
      },
      CopyPDFDialog: false,
      NewFunctionName: '',
      NewFunctionidCustom: false,
      NewFunctionCustomid: '',
      elmntdraggingindex: -1,
      elmntdragging: '',
      PDFPropertiesDialog: true,
      PagesDialog: true,
      HideToolbars: false,
      LoadingItems: false,
      ActiveElementDialog: false,
      GotEntities: false,				
      NewPageDefault: {					
              PageNumber: 0,					
              PageID: 0,					
              PageName: '',					
              Elements: [],					
              PageHasWaterMark: false,					
              PagePaddingRight: 20,					
              PageWaterMarkObject: '',					
              AddPageperArrayItem: false,					
            },					
      SystemAssets: [],					
      ExportingTest: false,					
        RectangleTypes: ['F','FD'],					
        mode: 'rgba',					
        modes: ['hsla', 'rgba', 'hexa'],					
        FullItem: '',					
        FullArray: [],					
        LookupArrays: [],					
        HasData: false,					
        LastPageID: 1000001,					
        LastElementID: 1000000,					
        ActivePageNumber: 1,					
        MainBuilderWidth: 0,					
        MainBuilderHeight: 0,					
        // PreparedSystemEntities: [],					
        RefresingPreview: true,					
      	FunctionData: '',				
          editedelementindex: -1,					
          ActiveElement: {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}},					
          EditingPageElements: false,					
          LivePreviewMode: false,					
        FunctionDisplayName: 'Export PDF',					
        ArrayTypes: [					
            'STD Collection',					
            'SubCollection'					
        ],					
        OutputTypes: [					
            'Rectangle',					
            'Text',					
            'Image'					
        ],					
        DataFieldTypes: [					
            'Lookup',					
            'Single Line Text',					
            'HTML',					
            'Common Field'					
        ],					
        DataFieldCommonFieldTypes: [					
            'Email',					
            'URL Link',					
            'Physical Address',					
            'Postal Address'					
        ],					
        WidthOptions: [					
            'Full Page',					
            'Half Page',					
            'Third Page'					
        ],					
        FontStyles: [					
            'bold',					
            'normal'					
        ],					
        DataSourceTypes: [					
            'Custom Text',					
            'Data Text',					
            'Image Field',					
            'User Value',
            'App Logo'			
        ],					
        PositionInheritTypes: [					
            'Start',					
            'End'					
        ],					
        JustifyTypes: [					
            'start',					
            'center',					
            'end'					
        ],					
        PositionTypes: ['Dynamic'],					
        FunctionOutputTypes: [					
            {					
            DisplayName: 'A4 Portrait',					
            orientation: 'p',					
            unit: 'pt',					
            format: 'a4',					
            Width: 595.28,					
            Height: 841.89,					
            PixelWidth: 2480,					
            PixelHeight: 3507,					
          },					
          {					
            DisplayName: 'A4 Landscape',					
            orientation: 'l',					
            unit: 'pt',					
            format: 'a4',					
            Width: 841.89,					
            Height: 595.28,					
            PixelWidth: 3507,					
            PixelHeight: 2480,					
          },					
        ],					
        FunctionOutput: '',					
        FunctionEntity: '',					
        FunctionEntityPrimaryFieldName: '',					
        SelectedRecord: '',					
        ExportingtoPDFDialog: false,					
        HasIndex: false,					
        IndexPageNumber: '',					
        Pages: []	,
        step: 1,
			
    }					
    					
  },					
					
  mounted() {					
    					
  },					
  					
  created(){  					
		if(this.SuitehasWarehouse){
      let bta = this.POEntity.AllFields.find(obj => obj.id === 'PO_Bill_To_Address')
      this.PrepareAddressField(bta)
      let pda = this.POEntity.AllFields.find(obj => obj.id === 'PO_Delivery_Address')
      this.PrepareAddressField(pda)
      let sca = this.POEntity.AllFields.find(obj => obj.id === 'Supplier_Collection_Address')
      this.PrepareAddressField(sca)
      let sia = this.POEntity.AllFields.find(obj => obj.id === 'Supplier_Invoice_Address')
      this.PrepareAddressField(sia)
      let SObta = this.SOEntity.AllFields.find(obj => obj.id === 'PO_Bill_To_Address')
      this.PrepareAddressField(SObta)
      let SOpda = this.SOEntity.AllFields.find(obj => obj.id === 'PO_Delivery_Address')
      this.PrepareAddressField(SOpda)
      let SOsca = this.SOEntity.AllFields.find(obj => obj.id === 'Supplier_Collection_Address')
      this.PrepareAddressField(SOsca)
      let SOsia = this.SOEntity.AllFields.find(obj => obj.id === 'SO_Delivery_Address')
      this.PrepareAddressField(SOsia)
    }			
      					
    document.body.scrollTop = 0;					
    document.documentElement.scrollTop = 0;					
    window.onload = setTimeout(() => {this.CheckRouting()},1500)					
  },					
  computed: {
    AppsDataBase(){
        return this.$store.state.AppsDataBase
    },
    AllAppPlugins(){
      return this.AppsDataBase.map(app => {
        let appobj = Object.assign({},app)
        return appobj.Plugins
      }).flat()
    },
    SuitehasWarehouse(){
      return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
    },
    WarehouseEntities(){
      return [this.POEntity,this.SOEntity]
    },
    PreparedSystemEntities(){
      if(this.SuitehasWarehouse){
        //should we still do this if we got templates???
        return this.WarehouseEntities.concat(this.SystemEntities)
      }
      else{
        return this.SystemEntities
      }
    },
    ComputedDataSourceTypes(){
      return this.DataSourceTypes.filter(type => {
        if(this.ActiveElement && this.ActiveElement.OutputType === 'Image'){
          return type === 'Image Field' || type === 'App Logo'
        }
        else{
          return type
        }
      })
    }	,		
    DisplayRecord(){
      if(this.FullItem){
        return this.FullItem
      }
      else{
        let sampleobj = {PDFExportSample: true}
        this.Pages.map(page => {
          if(page.Elements){
            page.Elements.map(elmnt => {
              //console.log('elmnt',elmnt)  
              if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type !== 'Option Set' && elmnt.DataField.Type !== 'Lookup' && elmnt.DataField.Type !== 'Radio Group'){
                sampleobj[elmnt.DataField.Name] = elmnt.DataField.DisplayName
              }
              else if(elmnt.DataSource === 'Data Text' && elmnt.DataField && elmnt.DataField.Type === 'Option Set'){
                sampleobj[elmnt.DataField.Name] = {ID: 1000001, Name: elmnt.DataField.DisplayName}
              }
              //console.log('sampleobj',sampleobj)
            })
          }
        })
        return sampleobj
      }
      
    },	
    currentTitle(){
      switch (this.step) {
          case 1: return 'Basic'
          case 2: return 'Type'
          case 3: return 'Place'
          default: return 'Adv.'
        }

    },
    FunctionEntityid(){
      if(this.FunctionEntity && this.FunctionEntity.id){
        return this.FunctionEntity.id
      }
      else if(this.FunctionEntity){
        return this.FunctionEntity
      }
    },
      ActivePageIndexedElements(){					
        let pageobj = this.Pages.find(obj => obj.PageNumber === this.ActivePage.PageNumber)					
        let pageindex = this.Pages.indexOf(pageobj)					
        //console.log(this.Pages[pageindex].Elements)					
        return this.Pages[pageindex].Elements.map((elmnt,elmntindex) => {					
            let elmntobj = {					
                Name: elmnt.Name,					
                Index: elmntindex,					
                ElementID: elmnt.ElementID					
            }					
            //console.log('elmntobj',elmntobj)					
            return elmntobj					
        })  					
      },					
      ActiveElementDialogName(){					
          return this.editedelementindex === -1 ? 'New Element' : 'Edit -'+this.ActiveElement.Name					
      },					
      ModePageArray(){					
          if(this.LivePreviewMode){					
              return this.FunctionExportPages					
          }					
          else{					
              return this.Pages					
          }					
      },			
      ActiveWatermark(){
        if(this.ActivePage.PageHasWaterMark && this.ActivePage.PageWaterMarkObject){
          return this.ActivePage.PageWaterMarkObject.Path
        } 
      },	
      ActivePageStyling(){				
        //console.log(this.ActivePage.PageHasWaterMark,this.ActivePage.PageWaterMarkObject)	
          if(this.ActiveWatermark){					
             return { backgroundImage: `url('`+this.ActiveWatermark+`')`,backgroundSize: `cover` }					
          }					
          else{					
              return ''					
          }					
          					
         // return this.ActivePage					
      },					
    ActivePage(){					
        // if(this.LivePreviewMode && this.FunctionExportPages){					
         return this.ModePageArray? this.ModePageArray.find(obj => obj.PageNumber === this.ActivePageNumber): ''   					
        // }					
        					
    },					
    DocumentDisplayRatio(){					
        if(this.FunctionOutput.orientation === 'p'){					
            return (this.DocumentDisplayHeight/this.FunctionOutput.Height)					
        }					
        else{					
          return (this.DocumentDisplayWidth/this.FunctionOutput.Width)					
        }					
    },					
    DocumentDisplayWidth(){					
       if(this.FunctionOutput.orientation === 'p'){					
            let ratio = 1					
            //console.log(this.MainBuilderHeight)					
            if(this.FunctionOutput.PixelHeight>this.MainBuilderHeight){					
                ratio = this.MainBuilderHeight/this.FunctionOutput.PixelHeight					
            }					
          return this.FunctionOutput.PixelWidth*ratio					
        }					
        else{					
            let ratio = 1					
            if(this.FunctionOutput.PixelWidth>this.MainBuilderWidth){					
                ratio = 1					
            }					
          return this.MainBuilderWidth*ratio					
        }					
        					
    },					
    DocumentDisplayHeight(){					
        if(this.FunctionOutput.orientation === 'p'){					
            let ratio = 1					
            if(this.FunctionOutput.PixelHeight>this.MainBuilderHeight){					
                ratio = 1					
            }					
          return this.MainBuilderHeight*ratio					
        }					
        else{					
            let ratio = 1					
            //console.log(this.MainBuilderWidth)					
            if(this.FunctionOutput.PixelWidth>this.MainBuilderWidth){					
                ratio = this.MainBuilderWidth/this.FunctionOutput.PixelWidth					
            }					
          return this.FunctionOutput.PixelHeight*ratio					
        }					
    },					
    					
    DashboardEntityStatus(){					
      let statusobj = this.FunctionEntity.AllFields.find(obj => obj.id === 'Status')					
      return statusobj.Options					
    },					
    				
    EntityHasbusinessUnits(){					
      if(this.FunctionEntity){					
        let bufield = this.FunctionEntity.AllFields.find(obj => obj.id === 'Business_Unit')					
        if(bufield){					
          return true					
        }					
        else{					
          return false					
        }					
      }					
      else{					
        return false					
      }					
      					
    },					
    DataSetHasRecordOwner(){					
      if(this.FunctionEntity){					
        return this.FunctionEntity.datasethasrecordowner					
      }					
    },					
    testdate(){					
      var d = new Date('2021-04-04')					
      d.setHours(23,59,59,999)					
      return d					
    },					
    newtestdate(){					
      					
      return this.EndofWeek(this.ComputedToday)					
    },					
    LibraryColors(){					
      let colors = [					
        'red',					
        'pink',					
        'purple',					
        'deep-puprle',					
        'indigo',					
        'blue',					
        'light-blue',					
        'cyan',					
        'teal',					
        'green',					
        'light-green',					
        'yellow',					
        'amber',					
        'orange',					
        'deep-orange',					
        'brown',					
        'blue-grey',					
        'grey'					
      ]					
      let variations = [					
        ' lighten-5',					
        ' lighten-4',					
        ' lighten-3',					
        ' lighten-2',					
        ' lighten-1',					
        '',					
        ' darken-1',					
        ' darken-2',					
        ' darken-3',					
        ' darken-4',					
        ' darken-5',					
      ]					
      let finalarray = []					
      variations.map(vary => {					
        colors.map(col => {					
          let obj = col+vary					
          finalarray.push(obj)					
        })					
      })					
      return finalarray					
					
    },					
    DashboardBGColor(){					
      if(!this.BGColor){					
        return 'grey lighten-3'					
      }					
      else{					
        return this.BGColor					
      }					
    },					
    ActivityPreFilterFields(){					
      return [{					
        id: 'activitytype',					
            Name: 'activitytype',					
            DisplayName: 'Activity Type',					
            IsHeader: true,					
            IsMandatory: true,					
            SystemRequired: true,					
            Type: 'Static Option Set',					
            Options: [					
              {					
                ID: 1000001,					
                Name: 'Task'					
              },					
              {					
                ID: 1000002,					
                Name: 'Appointment'					
              },					
              {					
                ID: 1000003,					
                Name: 'Email'					
              },					
              {					
                ID: 1000004,					
                Name: 'Phone Call'					
              },					
              ],            					
            UsedonForm: true					
          }]					
    },					
    CheckButton(){					
      return 'Create'					
    },					
    RootElementOptions(){					
      let array = []					
      let activityobj = {					
        ConfigName: 'Activities in Timeline',					
        ConfigID: 1000001,					
        Options: this.ElementOptionsbyRoot.filter(opt => {					
          return opt.ActivitiesinTimeline					
        }),					
        HasChart: true,					
        HasDataContext: true,					
        ShowTitle: false,					
        ChartPosition: 'Top',					
        DataContextStyle: 'List',					
        ChartType: 'bar',					
        ChartLabelsfromTimeline: true					
        					
      }					
      array.push(activityobj)					
      let datatimelineobj = {					
        ConfigID: 1000002,					
        ConfigName: 'Data in Timeline',					
        Options: this.ElementOptionsbyRoot.filter(opt => {					
          return opt.DatainTimeline					
        }),					
        HasChart: true,					
        HasDataContext: true,					
        ChartPosition: 'Top',					
        ChartLabelsfromTimeline: true,					
        DataContextStyle: 'List',					
        ChartType: 'line'					
      }					
      array.push(datatimelineobj)					
      let datachartscontextobj = {					
        ConfigID: 1000003,					
        ConfigName: 'Charts and Context',					
        Options: this.ElementOptionsbyRoot.filter(opt => {					
          return opt.DatainTimeline					
        }),					
        ChartType: 'pie',					
        ShowTitle: true,					
        HasChart: true,					
        HasDataContext: true,					
        ChartPosition: 'Right',					
        DataContextStyle: 'List'					
      }					
      array.push(datachartscontextobj)					
      let dataprocessobj = {					
        ConfigID: 1000004,					
        ConfigName: 'Data in Process',					
        Options: this.ElementOptionsbyRoot.filter(opt => {					
          return opt.DatainTimeline					
        }),        					
        ShowTitle: true,					
        HasChart: true,					
        HasDataContext: false,					
        ChartPosition: '',					
        ChartLabelsfromProcess: true,					
        DataContextStyle: ''					
      }					
      if(this.FunctionEntity.EntityProcesses && this.FunctionEntity.EntityProcesses.length>0){					
        array.push(dataprocessobj)					
      }					
      let goalmeasurementobj = {					
        ConfigID: 1000005,					
        ConfigName: 'Goal Measurements',					
        Options: this.ElementOptionsbyRoot.filter(opt => {					
          return opt.GoalMeasurements					
        }),   					
        ChartType: 'line',     					
        ShowTitle: false,					
        ShowSummary: true,					
        ShowSummaryOnly: true,					
        HasChart: true,					
        HasDataContext: true,					
        ChartPosition: 'Top',					
        ChartLabelsfromTimeline: true,					
        DataContextStyle: 'List',					
        AccumulativeGraph: true,					
      }					
      if(this.FunctionEntity.GoalMeasurements && this.FunctionEntity.GoalMeasurements.length>0){					
        array.push(goalmeasurementobj)					
      }					
      					
      return array					
    },					
    ElementTypeOptions(){					
      return [					
        {					
          Name: 'line',					
          Icon: 'mdi-chart-line-variant' 					
        },					
        {					
          Name: 'bar',					
          Icon: 'mdi-chart-bar' 					
        },					
        {					
          Name: 'pie',					
          Icon: 'mdi-chart-pie' 					
        },					
         {					
          Name: 'doughnut',					
          Icon: 'mdi-chart-arc' 					
        },					
       					
      ]					
    },					
    				
					
    ElementOptionsbyRoot(){					
      let array = []					
      //console.log('now')					
      if(this.FunctionEntity && this.FunctionEntity.id){					
        let RelatedChild = this.PreparedSystemEntities.find(obj => obj.id === 'Activities')					
        let PrimaryDateFilter = RelatedChild.AllFields.find(obj => obj.id === 'duedate')					
        let PreFilterField = RelatedChild.AllFields.find(obj => obj.id === 'activitytype')					
        let ChildRelationshipsField = RelatedChild.AllFields.find(obj => obj.id === 'regardingrecord')					
        let taskprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')					
        let appointmentprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')					
        let ActivitiesOptions = [					
          {					
            Name: 'Tasks for '+this.FunctionEntity.DisplayName,					
            ActivitiesinTimeline: true,					
            PrimaryLabelType: 'Name of Child',					
            RelatedChild: RelatedChild,					
            PrimaryDateFilter: PrimaryDateFilter,					
            ValueType: 'Count',					
            ChildRelationshipsField: ChildRelationshipsField,					
            PreFilterField: PreFilterField,					
            PreFilter: taskprefilter					
          },					
          					
          {					
            Name: 'Appointments for '+this.FunctionEntity.DisplayName,					
            ActivitiesinTimeline: true,					
            PrimaryLabelType: 'Name of Child',					
            RelatedChild: RelatedChild,					
            PrimaryDateFilter: PrimaryDateFilter,					
            ValueType: 'Count',					
            ChildRelationshipsField: ChildRelationshipsField,					
            PreFilterField: PreFilterField,					
            PreFilter: appointmentprefilter 					
          }					
        ]					
        array = array.concat(ActivitiesOptions)					
        let fromtimelineobj = {					
            Name: this.FunctionEntity.DisplayName+' by Timeline',					
            PrimaryLabelType: 'From Timeline',					
            PrimaryLabelField: 'thisTimeline',					
            DatainTimeline: true					
        }					
        array.push(fromtimelineobj)					
        // GoalMeasurements					
        this.FunctionEntity.GoalMeasurements.map(goal => {					
          let goalobj = {					
            Name: goal.Name,					
            PrimaryLabelType: goal.PrimaryFilterType,					
            PrimaryLabelField: goal.PrimaryFilterField,					
            PrimaryFilterType: goal.PrimaryFilterType,					
            PrimaryFilterField: goal.PrimaryFilterField,					
            PrimaryDateFilter: goal.PrimaryDateFilter, 					
            ValueType: goal.ValueType,                       					
            GoalMeasurements: true,					
            GoalID: goal.id,					
        }					
        if(goal.ValueField){					
          goalobj.ValueField = goal.ValueField					
        }					
        if(goal.ValueCurrency){					
          goalobj.ValueCurrency = goal.ValueCurrency					
        }					
        if(goal.PreFilterField){					
          goalobj.PreFilterField = goal.PreFilterField					
        }					
        if(goal.PreFilter){					
          goalobj.PreFilter = goal.PreFilter					
        }					
        array.push(goalobj)					
        })					
        let optionsets = this.FunctionEntity.AllFields.filter(field => {					
          return field.Type === 'Option Set'					
        })					
        let lookups = this.FunctionEntity.AllFields.filter(field => {					
          return field.Type === 'Lookup'					
        })					
        let concatarray = optionsets.concat(lookups)					
        					
        let types = concatarray					
        .map(field => {					
          if(field.Type === 'Lookup'){					
            //console.log(field)					
            let parentobj = this.PreparedSystemEntities.find(obj => obj.buildid === field.RelatedBuildID)					
            let primaryfield = parentobj.AllFields.find(obj => obj.Primary === true)					
            return {					
              Name: this.FunctionEntity.DisplayName+' by '+field.DisplayName+' ('+primaryfield.DisplayName+')',					
              DatainTimeline: true,					
              PrimaryLabelType: 'Lookup',					
              PrimaryLabelField: field,					
            }					
          }					
          else{					
            return {					
            Name: this.FunctionEntity.DisplayName+' by '+field.DisplayName,					
            PrimaryLabelType: 'Option Set',					
             PrimaryLabelField: field,					
            DatainTimeline: true 					
          }					
          }         					
        })					
        					
        array = array.concat(types)					
        let parenttypes = []					
        let parents = lookups.map(field => {					
        let parentobj = this.PreparedSystemEntities.find(obj => obj.buildid === field.RelatedBuildID)					
          parentobj.OptionSets = parentobj.AllFields.filter(field => {					
            return field.Type === 'Option Set'					
          })					
          parentobj.ParentField = field					
          return parentobj					
        })					
					
        parents.map (parent => {					
          parent.OptionSets.map(field => {					
            let obj = {					
                Name: this.FunctionEntity.DisplayName+' by '+parent.DisplayName+' and '+field.DisplayName,					
                PrimaryLabelType: 'Option Set from Lookup',					
                PrimaryLabelLookup: parent.ParentField, 					
                PrimaryLabelField: field,					
                DatainTimeline: true					
              }					
              parenttypes.push(obj)					
          })					
        })					
        array = array.concat(parenttypes)					
      }					
      return array					
    },					
    RelatedChildEntities(){					
      return this.PreparedSystemEntities.map(entity => {					
        entity.ChildLookupFields = entity.AllFields.filter(field => {					
          return field.Type === 'Lookup' && field.RelatedBuildID === this.FunctionEntity.id || field.Type === 'Dynamic Lookup'					
        })					
        return entity					
      })					
      .filter(entity => {					
        return entity.ChildLookupFields.length>0					
      })					
    },					
    SaveDashboardElements(){					
      let array = this.DashboardElements.map(row => {					
        let obj = {					
          Type: 'Row'					
        }					
        obj.Elements = row.Elements.map(elmnt => {					
          let elmntobj = {}					
          if(elmnt.ChartBGObj){					
            elmntobj.ChartBGObj = elmnt.ChartBGObj					
          }					
          // if(elmnt.Preset){					
          //   elmntobj.Preset = elmnt.Preset					
          // }					
          elmntobj.ConfigID = elmnt.ConfigID					
          elmntobj.ConfigName = elmnt.ConfigName					
          if(elmnt.PrimaryFilterType){					
            elmntobj.PrimaryFilterType = elmnt.PrimaryFilterType					
          }					
          if(typeof elmnt.ShowSummary !== 'undefined'){					
            elmntobj.ShowSummary = elmnt.ShowSummary					
          }					
          if(typeof elmnt.ShowSummaryOnly !== 'undefined'){					
            elmntobj.ShowSummaryOnly = elmnt.ShowSummaryOnly					
          }					
          if(typeof elmnt.AccumulativeGraph !== 'undefined'){					
            elmntobj.AccumulativeGraph = elmnt.AccumulativeGraph					
          }					
          if(elmnt.GoalID){					
            elmntobj.GoalID = elmnt.GoalID					
          }					
          if(elmnt.GoalMeasurements){					
            elmntobj.GoalMeasurements = elmnt.GoalMeasurements					
          }					
        					
          if(elmnt.PrimaryFilterLookup){					
            elmntobj.PrimaryFilterLookup = elmnt.PrimaryFilterLookup					
          }					
          if(elmnt.PrimaryFilterField){					
            elmntobj.PrimaryFilterField = elmnt.PrimaryFilterField					
          }					
          if(elmnt.PrimaryLabelType){					
            elmntobj.PrimaryLabelType = elmnt.PrimaryLabelType					
          }					
          if(elmnt.PrimaryLabelLookup){					
            elmntobj.PrimaryLabelLookup = elmnt.PrimaryLabelLookup					
          }					
          if(elmnt.PrimaryLabelField){					
            elmntobj.PrimaryLabelField = elmnt.PrimaryLabelField					
          }					
          if(elmnt.PreFilterField){					
            elmntobj.PreFilterField = elmnt.PreFilterField					
          }					
          if(elmnt.PreFilter){					
            elmntobj.PreFilter = elmnt.PreFilter					
          }					
          if(elmnt.SecondaryFilterField){					
            elmntobj.SecondaryFilterField = elmnt.SecondaryFilterField					
          }					
          if(elmnt.RelatedChild && elmnt.RelatedChild.id){					
            elmntobj.RelatedChild = elmnt.RelatedChild.id					
          }					
          if(elmnt.RelatedChild && !elmnt.RelatedChild.id){					
            elmntobj.RelatedChild = elmnt.RelatedChild					
          }					
          					
          if(elmnt.ChildRelationshipsField){					
            elmntobj.ChildRelationshipsField = elmnt.ChildRelationshipsField					
          }					
          if(elmnt.ChartType){					
            elmntobj.ChartType = elmnt.ChartType					
          }					
          if(elmnt.ColumnWidth){					
            elmntobj.ColumnWidth = elmnt.ColumnWidth					
          }					
          if(elmnt.PrimaryDateFilter){					
            elmntobj.PrimaryDateFilter = elmnt.PrimaryDateFilter					
          }					
          if(elmnt.Description){					
            elmntobj.Description = elmnt.Description					
          }					
          if(elmnt.ChartLabelsfromTimeline){					
            elmntobj.ChartLabelsfromTimeline = elmnt.ChartLabelsfromTimeline					
          }					
          if(elmnt.ChartLabelsfromProcess){					
            elmntobj.ChartLabelsfromProcess = elmnt.ChartLabelsfromProcess					
          }					
          					
          if(elmnt.HasChart){					
            elmntobj.HasChart = elmnt.HasChart					
          }					
          if(elmnt.HasDataContext){					
            elmntobj.HasDataContext = elmnt.HasDataContext					
          }					
          if(elmnt.ShowTitle){					
            elmntobj.ShowTitle = elmnt.ShowTitle					
          }					
          if(elmnt.ChartPosition){					
            elmntobj.ChartPosition = elmnt.ChartPosition					
          }					
          if(elmnt.DataContextStyle){					
            elmntobj.DataContextStyle = elmnt.DataContextStyle					
          }					
          					
          if(elmnt.EntityRelatedfield){					
            elmntobj.EntityRelatedfield = elmnt.EntityRelatedfield					
          }					
          if(elmnt.PipelineProcess && elmnt.PipelineProcess.id){					
            elmntobj.PipelineProcess = elmnt.PipelineProcess.id					
          }					
          else if(elmnt.PipelineProcess && !elmnt.PipelineProcess.id){					
            elmntobj.PipelineProcess = elmnt.PipelineProcess					
          }					
          if(elmnt.ActivityType){					
            elmntobj.ActivityType = elmnt.ActivityType					
          }					
          if(elmnt.ActivityDateFilter){					
           elmntobj.ActivityDateFilter = elmnt.ActivityDateFilter					
          }					
           					
          if(elmnt.SublabelField){					
            elmntobj.SublabelField = elmnt.SublabelField					
          }					
          if(elmnt.SublabelFromRelatedLookup){					
            elmntobj.SublabelFromRelatedLookup = elmnt.SublabelFromRelatedLookup					
          }					
          if(elmnt.SublabelFromRelatedChild){					
            elmntobj.SublabelFromRelatedChild = elmnt.SublabelFromRelatedChild					
          }					
          elmnt.FullArray = []					
          elmnt.LookupArrays = []					
          if(elmnt.Sort){					
            elmntobj.Sort = elmnt.Sort					
          }					
          if(elmnt.TimeFilterOption){					
            elmntobj.TimeFilterOption = elmnt.TimeFilterOption					
          }					
          if(elmnt.Title){					
            elmntobj.Title = elmnt.Title					
          }					
          if(elmnt.Preset){					
            elmntobj.Type = elmnt.Preset					
          }					
          if(elmnt.ValueCurrency){					
            elmntobj.ValueCurrency = elmnt.ValueCurrency					
          }					
          if(elmnt.ValueField){					
            elmntobj.ValueField = elmnt.ValueField					
          }					
          if(elmnt.ValueType){					
            elmntobj.ValueType = elmnt.ValueType					
          }					
					
          return elmntobj					
        })					
        return obj					
      })					
      return array					
                  					
    },					
    //if ChartLabelsisDynamic then DynamicChartLabelsProp could be					
    CurrentFiscalLabels(){					
      //to return the months of the quarter. But possible return as objects for looping purposes, TBD					
      let variabletableobj = {					
        ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          					
        ChartLabelsVariables: ['LastJanuaryStart','LastFebruaryStart','LastMarchStart','LastAprilStart','LastMayStart','LastJuneStart','LastJulyStart','LastAugustStart','LastSeptemberStart','LastOctoberStart','LastNovemberStart','LastDecemberStart',					
        'ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],					
      }					
      let Qvarobj = {					
        Labels: [],					
        ChartLabelsVariables : []					
      }					
      //console.log(this.CurrentFiscalQuarter)					
      if(this.CurrentFiscalQuarter === 'Quarter 1'){					
        let arr = [0,1,2]					
        arr.map((number,i) => {					
          let index = number+this.FiscalEndMonth+1					
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]					
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]					
        })					
        //console.log(Qvarobj)					
        return Qvarobj					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){					
         let arr = [2,4,5]					
        arr.map((number,i) => {					
          let index = number+this.FiscalEndMonth+1					
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]					
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]					
        })					
        //console.log(Qvarobj)					
        return Qvarobj					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){					
         let arr = [6,7,8]					
        arr.map((number,i) => {					
          let index = number+this.FiscalEndMonth+1					
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]					
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]					
        })					
        //console.log(Qvarobj)					
        return Qvarobj					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 4'){					
         let arr = [9,10,11]					
        arr.map((number,i) => {					
          let index = number+this.FiscalEndMonth+1					
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]					
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]					
        })					
        //console.log(Qvarobj)					
        return Qvarobj					
      }					
    },					
    CurrentMonthLabels(){					
      //to return the weeks of the month. But possible return as objects for looping purposes, TBD					
       let variabletableobj = {					
        ChartLabels: ['Week 1','Week 2','Week 3','Week 4','Week 5'],          					
        ChartLabelsVariables: ['MonthWeek1Start','MonthWeek2Start','MonthWeek3Start','MonthWeek4Start','MonthWeek5Start'],					
      }					
      let Wvarobj = {					
        Labels: [],					
        ChartLabelsVariables : []					
      }					
      let length = 4					
      let newtoday = new Date(this.Today)					
      var firstdayofmonth = 1;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofmonth)					
      d.setDate(firstdayofmonth+28)					
      d.setHours(0,0,0,0);					
      let week5month = d.getMonth()					
      if(week5month === month){					
        length = 5					
      }					
      for(var i = 0; i < length; i++){					
        Wvarobj.Labels[i] = variabletableobj.ChartLabels[i]					
        Wvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[i]					
      }					
      return Wvarobj					
    },					
    MonthWeek1Start(){					
      let newtoday = new Date(this.Today)					
      var firstdayofmonth = 1;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofmonth)					
      d.setHours(0,0,0,0);					
      return d					
    },					
    MonthWeek2Start(){					
      let newtoday = this.EndofWeek(this.MonthWeek1Start)					
      var firstdayofweek = newtoday.getDate()-1+2;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofweek)					
      // d.setDate(firstdayofmonth)					
      d.setHours(0,0,0,0);					
      return d					
    },					
    MonthWeek3Start(){					
      let newtoday = this.EndofWeek(this.MonthWeek2Start)					
      var firstdayofweek = newtoday.getDate()-1+2;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofweek)					
      // d.setDate(firstdayofmonth)					
      d.setHours(0,0,0,0);					
      return d					
    },					
    MonthWeek4Start(){					
      let newtoday = this.EndofWeek(this.MonthWeek3Start)					
      var firstdayofweek = newtoday.getDate()-1+2;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofweek)					
      // d.setDate(firstdayofmonth)					
      d.setHours(0,0,0,0);					
      return d					
    },					
    MonthWeek5Start(){					
      let newtoday = this.EndofWeek(this.MonthWeek4Start)					
      var firstdayofweek = newtoday.getDate()-1+2;					
      var year = newtoday.getFullYear();					
      var month = newtoday.getMonth();					
      var d = new Date(year, month, firstdayofweek)					
      // d.setDate(firstdayofmonth)					
      d.setHours(0,0,0,0);					
      return d					
    },					
    // MonthWeek1Start(){					
    //   let newtoday = new Date(this.Today)					
    //   var firstdayofmonth = 1;					
    //   var year = newtoday.getFullYear();					
    //   var month = newtoday.getMonth();					
    //   var d = new Date(year, month, firstdayofmonth)					
    //   d.setHours(0,0,0,0);					
    //   return d					
    // },					
    // MonthWeek2Start(){					
    //   let newtoday = new Date(this.Today)					
    //   var firstdayofmonth = 1;					
    //   var year = newtoday.getFullYear();					
    //   var month = newtoday.getMonth();					
    //   var d = new Date(year, month, firstdayofmonth)					
    //   d.setDate(firstdayofmonth+7)					
    //   d.setHours(0,0,0,0);					
    //   return d					
    // },					
    // MonthWeek3Start(){					
    //   let newtoday = new Date(this.Today)					
    //   var firstdayofmonth = 1;					
    //   var year = newtoday.getFullYear();					
    //   var month = newtoday.getMonth();					
    //   var d = new Date(year, month, firstdayofmonth)					
    //   d.setDate(firstdayofmonth+14)					
    //   d.setHours(0,0,0,0);					
    //   return d					
    // },					
    // MonthWeek4Start(){					
    //   let newtoday = new Date(this.Today)					
    //   var firstdayofmonth = 1;					
    //   var year = newtoday.getFullYear();					
    //   var month = newtoday.getMonth();					
    //   var d = new Date(year, month, firstdayofmonth)					
    //   d.setDate(firstdayofmonth+21)					
    //   d.setHours(0,0,0,0);					
    //   return d					
    // },					
    // MonthWeek5Start(){					
    //   let newtoday = new Date(this.Today)					
    //   var firstdayofmonth = 1;					
    //   var year = newtoday.getFullYear();					
    //   var month = newtoday.getMonth();					
    //   var d = new Date(year, month, firstdayofmonth)					
    //   d.setDate(firstdayofmonth+28)					
    //   d.setHours(0,0,0,0);					
    //   return d					
    // },					
    LastJanuaryStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 0;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastFebruaryStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 1;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastMarchStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 2;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastAprilStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 3;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastMayStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 4;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastJuneStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 5;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastJulyStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 6;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastAugustStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 7;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastSeptemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 8;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastOctoberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 9;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastNovemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 10;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    LastDecemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 11;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year-1, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisJanuaryStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 0;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisFebruaryStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 1;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisMarchStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 2;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisAprilStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 3;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisMayStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 4;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisJuneStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 5;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisJulyStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 6;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisAugustStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 7;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisSeptemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 8;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisOctoberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 9;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisNovemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 10;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    ThisDecemberStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = 11;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      return d					
    },					
    CurrentFiscalQuarterFull(){					
      return 'Fiscal '+this.CurrentFiscalYear+' '+this.CurrentFiscalQuarter					
    },					
    CurrentFiscalQuarter(){					
      if(this.ComputedToday < this.FiscalQ1End){					
        return 'Quarter 1'					
      }					
      else if(this.ComputedToday < this.FiscalQ2Start){					
        return 'Quarter 1'					
      }					
      else if(this.ComputedToday < this.FiscalQ2End){					
        return 'Quarter 2'					
      }					
      else if(this.ComputedToday < this.FiscalQ3Start){					
        return 'Quarter 2'					
      }					
      else if(this.ComputedToday < this.FiscalQ3End){					
        return 'Quarter 3'					
      }					
      else if(this.ComputedToday < this.FiscalQ4Start){					
        return 'Quarter 3'					
      }					
      else if(this.ComputedToday < this.FiscalQ4End){					
        return 'Quarter 4'					
      }					
      else {					
       return 'Quarter 4'					
      }					
    },					
    CurrentFiscalYear(){					
      return this.FiscalNextStart.getFullYear()					
    },					
    CurrentFiscalQStart(){					
      if(this.CurrentFiscalQuarter === 'Quarter 1'){					
        return this.FiscalQ1Start					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){					
        return this.FiscalQ2Start					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){					
        return this.FiscalQ3Start					
      }      					
      else {					
        return this.FiscalQ4Start					
      }					
    },					
    CurrentFiscalQEnd(){					
      if(this.CurrentFiscalQuarter === 'Quarter 1'){					
        return this.FiscalQ1End					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){					
        return this.FiscalQ2End					
      }					
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){					
        return this.FiscalQ3End					
      }					
      else {					
        return this.FiscalQ4End					
      }					
    },					
    FiscalQ1Start(){					
      var year = this.FiscalStart.getFullYear();					
      var month = this.FiscalStart.getMonth();					
      var day = this.FiscalStart.getDate();					
      return new Date(year, month, day);					
    },					
    FiscalQ1End(){					
      var year = this.FiscalQ1Start.getFullYear();					
      var month = this.FiscalQ1Start.getMonth();					
      var day = this.FiscalQ1Start.getDate();					
      return new Date(year, month+3, day);					
    },					
    FiscalQ2Start(){					
      var year = this.FiscalQ1End.getFullYear();					
      var month = this.FiscalQ1End.getMonth();					
      var day = this.FiscalQ1End.getDate();					
      return new Date(year, month, day);					
    },					
    FiscalQ2End(){					
      var year = this.FiscalQ2Start.getFullYear();					
      var month = this.FiscalQ2Start.getMonth();					
      var day = this.FiscalQ2Start.getDate();					
      return new Date(year, month+3, day);					
    },					
    FiscalQ3Start(){					
      var year = this.FiscalQ2End.getFullYear();					
      var month = this.FiscalQ2End.getMonth();					
      var day = this.FiscalQ2End.getDate();					
      return new Date(year, month, day);					
    },					
    FiscalQ3End(){					
      var year = this.FiscalQ3Start.getFullYear();					
      var month = this.FiscalQ3Start.getMonth();					
      var day = this.FiscalQ3Start.getDate();					
      return new Date(year, month+3, day);					
    },					
    FiscalQ4Start(){					
      var year = this.FiscalQ3End.getFullYear();					
      var month = this.FiscalQ3End.getMonth();					
      var day = this.FiscalQ3End.getDate();					
      return new Date(year, month, day);					
    },					
    FiscalQ4End(){					
      var year = this.FiscalQ4Start.getFullYear();					
      var month = this.FiscalQ4Start.getMonth();					
      var day = this.FiscalQ4Start.getDate();					
      return new Date(year, month+3, day);					
    },					
    FiscalStart(){					
      var year = this.ComputedToday.getFullYear();					
      var month = this.FiscalEndMonth+1;					
      var day = this.ComputedToday.getDate();					
      var d = new Date(year, month, day)					
      //d.setMonth(this.FiscalEndMonth)					
      d.setDate(1);					
      d.setHours(0,0,0,0);					
      if(this.ComputedToday<d){					
        let newdate = new Date(year-1, month, day)					
        newdate.setDate(1)					
        //console.log(newdate)					
        return newdate					
      }					
      else{					
        return d					
      }					
      					
    },					
    FiscalNextStart(){					
      var year = this.FiscalStart.getFullYear();					
      var month = this.FiscalStart.getMonth();					
      var day = this.FiscalStart.getDate();					
      return new Date(year + 1, month, day);					
    },					
    DashboardEntityPrimaryFieldName(){					
      return this.DashboardEntityPrimaryField.Name					
    },					
    DashboardEntityPrimaryField(){					
      if(this.FunctionEntity){					
        let obj = this.FunctionEntity.AllFields.find(obj => obj.Primary)					
        return obj					
      }					
    },					
      DashboardEntityTypes(){					
        if(this.FunctionEntity && this.FunctionEntity.HasActivities){					
          return ['Card Roll','Pie Chart','Static Timeline Filter','Pipeline','Activities']					
        }					
        else{					
          return ['Card Roll','Pie Chart','Static Timeline Filter','Pipeline']					
        }					
      },					
      DashboardActivityTypes(){					
        return ['Task','Appointment']					
      },					
      FunnelSublabelfields(){					
          return this.FunctionEntity.AllFields					
          .filter(field => {					
              return field.Type === 'Option Set' && field.Options.length < 8					
          })					
      },					
      ThisMonday(){					
        let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+1))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisTuesday(){					
        let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+2))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisWednesday(){					
        let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+3))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisThursday(){					
        let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+4))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisFriday(){					
      let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+5))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisSaturday(){					
       let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+6))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      ThisSunday(){					
        let newtoday = new Date(this.Today)					
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
        let date = new Date(newtoday.setDate(lastdaylastweek+7))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      LastweekLastDate(){					
        let newtoday = new Date(this.Today)					
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
          let date = new Date(newtoday.setDate(lastdaylastweek))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      NextweekFirstDate(){					
        let newtoday = new Date(this.Today)					
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();					
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))					
          date.setHours(0,0,0,0)					
          return date					
      },					
      CurrentYear(){					
          return this.ComputedToday.getFullYear()					
      },					
      ThreeYearsStartDate(){					
          let threeyrsback = this.CurrentYear-2					
          let date = new Date(threeyrsback.toString()+'-01-01')					
          date.setHours(0,0,0,0)					
          return date					
      },					
      CurrentyearStartDate(){					
          let date = new Date(this.CurrentYear.toString()+'-01-01')					
          date.setHours(0,0,0,0)					
          return date					
      },					
          					
      ComputedToday(){					
          let date = new Date(this.Today)					
          date.setHours(0,0,0,0)					
          return date					
      },					
      SimpleSystemEntities(){					
          return this.ComputedSystemEntities.map(ent => {					
              return ent.id					
          })					
      },			

      ComputedSystemEntities(){					
          return this.PreparedSystemEntities					
          .map(entity => {					
              entity.RelatedFields = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Lookup' || field.Type === 'Option Set'					
              })					
              entity.NumberFields = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Number Field' || field.Type === 'Calculated Field'					
              })					
              entity.DateFields = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Date'					
              })					
              entity.OptionSets = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Option Set'					
              })					
              entity.RadioGroups = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Radio Group'					
              })					
              entity.LookupFields = entity.AllFields					
              .filter(field => {					
                  return field.Type === 'Lookup'					
              })					
              return entity					
          })					
      },					
      ViewOnly(){					
          return this.$route.name === 'CustomDashboard' || this.PushedElmnt					
      },					
      userIsAdmin () {					
            return this.$store.state.IsAdmin					
        },					
        userLoggedIn () {					
            return this.$store.getters.user					
        },					
        userBUID () {					
            return this.$store.getters.userBUID					
        },					
        userBUChildren () {					
            return this.$store.getters.userBUChildren					
        },					
        userBUParents () {					
            return this.$store.getters.userBUParents					
        },					
        // FullItem(){					
        //     if(this.FunctionEntity && this.SelectedRecord){					
        //         let fullobject = this.SelectedRecord					
        //         fullobject.RecordPrimaryFieldName = this.FunctionEntityPrimaryFieldName					
        //         //fullobject.Cart_Items = [this.Cart_Items]					
        //         // fullobject.Business_Unit = this.Business_Unit					
        //         // fullobject.Company = this.Company					
        //         // fullobject.Contact = this.Contact					
        //         // fullobject.Created_By = this.Created_By					
        //         // fullobject.Owner = this.Owner					
        //         return fullobject  					
        //     }					
            					
        // },					
      FunctionExportPages(){					
          //console.log(this.FullItem)					
        if(this.FunctionData && this.DisplayRecord){					
            					
          let pdffunction = this.FunctionData					
          let pagescount = 0					
          let FunctionElements = []					
            let FunctionPages = JSON.parse(JSON.stringify(pdffunction.Pages))	
            //console.log('FunctionPages',FunctionPages)				
            FunctionPages.map(page => {					
              let pageobj = Object.assign({},page)					
              delete pageobj.Elements					
              //let pagenumber = page.PageNumber					
              if(page.PageDatafromArray && page.ArrayType && page.DynamicArrayName){
                let array = []
                if(page.ArrayType === 'SubCollection'){
                 array = this.FullItem.SubCollections.find(obj => obj.id === page.DynamicArrayName)
                }
                console.log(array)			
                if(!page.AddPageperArrayItem){					
                    pagescount++					
                  }					
                  array.map((arritem,arritemindex) => {					
                    if(page.AddPageperArrayItem){					
                      pagescount++					
                    }					
                    page.Elements.map(elmnt => {                					
                    let elmntobj = Object.assign({},elmnt)					
                    elmntobj.fullobj = arritem					
                    elmntobj.PageObj = pageobj					
                      if(elmnt.ForceNewPage){					
                        					
                        if(elmnt.OutputType === 'Boolean Response'){					
                          if(arritem[elmnt.DataField]){					
                            //alert(arritem[elmnt.DataField])					
                            if(elmnt.BooleanTrueResponseType === 'Static' && elmnt.BooleanTrueResponse){					
                              //alert(elmnt.BooleanTrueResponse)					
                              					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              					
                              FunctionElements.push(elmntobj)					
                            }					
                            else if(elmnt.BooleanTrueResponseType === 'Dynamic' && arritem[elmnt.BooleanTrueResponseField]){					
                              elmntobj.BooleanTrueResponse = arritem[elmnt.BooleanTrueResponseField]					
                              //alert(elmntobj.BooleanTrueResponse)					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }					
                          }					
                          else if(!arritem[elmnt.DataField]){					
                            if(elmnt.BooleanFalseResponseType === 'Static' && elmnt.BooleanFalseResponse){					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }					
                             else if(elmnt.BooleanFalseResponseType === 'Dynamic' && arritem[elmnt.BooleanFalseResponseField]){					
                              elmntobj.BooleanFalseResponse = arritem[elmnt.BooleanFalseResponseField]					
                              pagescount++					
                              elmntobj.pagenumber = pagescount					
                              FunctionElements.push(elmntobj)					
                            }                            					
                          }					
                        }					
                        else{					
                          pagescount++					
                          elmntobj.pagenumber = pagescount					
                          FunctionElements.push(elmntobj)					
                        }                        					
                      }					
                      else{					
                        if(elmnt.OutputType === 'Boolean Response'){					
                          if(arritem[elmnt.DataField] && elmnt.BooleanTrueResponseType === 'Dynamic' && arritem[elmnt.BooleanTrueResponseField]){					
                           elmntobj.BooleanTrueResponse = arritem[elmnt.BooleanTrueResponseField]					
                          }					
                          else if(!arritem[elmnt.DataField] && elmnt.BooleanFalseResponseType === 'Dynamic' && arritem[elmnt.BooleanFalseResponseField]){					
                            elmntobj.BooleanFalseResponse = arritem[elmnt.BooleanFalseResponseField]                            					
                          }					
                        }					
                        elmntobj.pagenumber = pagescount					
                        FunctionElements.push(elmntobj)					
                      }					
                      					
                    })					
                    					
                  })					
                }					
                else{					
                  pagescount++					
                  page.Elements.map((elmnt,elmntindex) => {					
                    if(elmnt.ForceNewPage && elmntindex !== 0){					
                      pagescount++					
                    }					
                    let elmntobj = Object.assign({},elmnt)					
                    elmntobj.PageObj = pageobj          					
   					
                  					
                  elmntobj.pagenumber = pagescount					
                  FunctionElements.push(elmntobj)					
                  })					
                  					
                }             					
            })					
            					
            let exportpages = []					
            FunctionElements.map(elmnt => {					
              let pageobj = Object.assign({},elmnt.PageObj)					
              pageobj.PageNumber = elmnt.pagenumber					
              let oncheck = exportpages.find(obj => obj.PageNumber === pageobj.PageNumber)					
              if(!oncheck){					
                exportpages.push(pageobj)					
              }              					
            })					
            return exportpages.map(page => {					
              page.Elements = FunctionElements.filter(elmnt => {					
                return elmnt.pagenumber === page.PageNumber					
              })					
              return page					
            })					
        }					
      },					
    					
    formTitle () {					
      return this.editedIndex === -1 ? 'New Capital Equipment Job' : 'Edit Capital Equipment Job'					
    },					
    formSubTitle(){					
      return this.editedIndex === -1 ? 'Fill the form below and add a new Capital Equipment Job' : 'Edit the Capital Equipment Job '+this.editedItem.Job_Number					
    },					
    formSubTitleIcon(){					
      return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'					
    },					
    formSubTitleIconColor(){					
      return this.editedIndex === -1 ? 'green' : 'orange'					
    },					
    userIsAdmin () {					
    return this.$store.state.IsAdmin					
    },					
    userLoggedIn () {					
        return this.$store.getters.user					
    },					
    userBUID () {					
        return this.$store.getters.userBUID					
    },					
    userBUChildren () {					
        return this.$store.getters.userBUChildren					
    },					
    userBUParents () {					
        return this.$store.getters.userBUParents					
    },					
    },					
  watch: {					
    DisplayRecord: {
            handler: function(oldvalue, newvalue) {              
              if(oldvalue !== newvalue){
                this.RefreshLivePreview()           
              }
            },
            deep: true
        },
      HasData(value){					
          if (value !== null && value !== undefined && value === true) {					
          this.$emit('ActivateStandbyDialog',false)					
          }					
      },					
      userLoggedIn (value) {					
        //if (value !== null && value !== undefined) {					
         // this.$router.push('/')					
        //}					
      },					
        SelectedRecord(value){					
          if(value){					
                //console.log('now we need to call "single version and return data')					
                //console.log(value)					
          }					
      },					
      ActivePage(value){					
          if(value){	            
              this.GetSizes()					
          }					
      },					
      FunctionExportPages(value){					
          if(value && value.length > 0){		
           setTimeout(() => {
             this.GetSizes()	
           }, 1000);				
           				
          }					
      }					
    },					
  methods: {
    PrepareAddressField(field){
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
    ProcessCopyPDFDialog(){
      let newfunction = JSON.parse(JSON.stringify(this.FunctionData))
      newfunction.FunctionDisplayName = this.NewFunctionName
      newfunction.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}					
      newfunction.Created_Byid = this.userLoggedIn.id	
      newfunction.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}					
      newfunction.Modified_Byid = this.userLoggedIn.id	
      newfunction.Created_On	= new Date()
      newfunction.Modified_On	= new Date()
      let ref = db.collection('pdfexportfunctions').doc()
      let newdocid = '' 
      if(this.NewFunctionidCustom){
        newdocid = this.NewFunctionCustomid.split(' ').join('_')
      }
      else{
        newdocid = ref.id
      }
      db.collection('pdfexportfunctions').doc(newdocid).set(newfunction).then(newfunctiondoc => {
        this.$router.push('/PDFExportFunctionBuilder/'+newdocid)
      })
    },
    ActivateCopyPDFDialog(){
      this.CopyPDFDialog = true
    },
    DeactivateCopyPDFDialog(){
      this.CopyPDFDialog = false
      this.NewFunctionName = ''
      this.NewFunctionidCustom = false
      this.NewFunctionCustomid = ''
    },
    elmntdragstart(elmnt, elmntindex){
            this.elmntdragging = elmnt
            this.elmntdraggingindex = elmntindex
        },
        elmntdragfinish(elmnt,elmntindex){
            //console.log(elmnt,elmntindex)
            this.ActivePage.Elements.splice(elmntindex,0,this.ActivePage.Elements.splice(this.elmntdraggingindex,1)[0]) 
            setTimeout(() => {
                    this.elmntdraggingindex = -1
                    this.elmntdragging = ''
            }, 50);          
        },	
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      //console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      //console.log(this.field,FieldObject,this.Record)
    //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
    //   alert('should have passed updateemit')
    },
    ToggleHideToolbars(){
      if(this.HideToolbars){
        this.PagesDialog = true
        this.PDFPropertiesDialog = true
        this.HideToolbars = false
        this.RefreshLivePreview()
      }
      else{
        this.PagesDialog = false
        this.PDFPropertiesDialog = false
        this.HideToolbars = true
        this.RefreshLivePreview()
      }
    }	,			
    AddNewPage(){					
      let newpage = Object.assign({},this.NewPageDefault)					
      this.LastPageID++					
      newpage.PageID = this.LastPageID					
      let lastpagenumber = this.Pages.length					
      lastpagenumber++					
      newpage.PageNumber = lastpagenumber					
      this.Pages.push(newpage)					
      this.SaveFunction()					
    },					
    					
    ExportTest(){					
      this.ExportingTest = true					
      setTimeout(() => {					
          this.ExportingTest = false					
      }, 3000);					
      //console.log(this.ExportingTest)					
    },					
    //   TogglePage(){					
    //       this.ActivePageNumber--					
    //       this.ActivePageNumber++					
    //   },					
      FixStructure(){					
          this.Pages.map(page => {					
              page.Elements = page.Elements.map(elmnt => {					
            //   delete elmnt.UpdatesPageHeightEndPosition					
            //   delete elmnt.UpdatesPageHeightStartPosition					
            //   delete elmnt.UpdatesPageWidthEndPosition					
            //   delete elmnt.UpdatesPageWidthStartPosition					
              if(elmnt.DrawColor && !elmnt.DrawColor.rgba){					
                  elmnt.DrawColor.rgba = {					
                      r: elmnt.DrawColor.r,					
                      g: elmnt.DrawColor.g,					
                      b: elmnt.DrawColor.b,					
                      a: 255					
                  }					
              }					
              if(elmnt.FillColor && !elmnt.FillColor.rgba){					
                  elmnt.FillColor.rgba = {					
                      r: elmnt.FillColor.r,					
                      g: elmnt.FillColor.g,					
                      b: elmnt.FillColor.b,					
                      a: 255					
                  }					
              }					
              if(elmnt.FontColor && !elmnt.FontColor.rgba){					
                  elmnt.FontColor.rgba = {					
                      r: elmnt.FontColor.r,					
                      g: elmnt.FontColor.g,					
                      b: elmnt.FontColor.b,					
                      a: 255					
                  }					
              }					
            //   elmnt.HeightPositionStart = 0					
            //   elmnt.HeightPositionEnd = 0					
            //   elmnt.WidthPositionStart = 0					
            //   elmnt.WidthPositionEnd = 0					
              return elmnt					
          })					
          return page					
          })					
      },					
      GetRelevantLookupEntityFields(lookupfield){					
        let entity = this.PreparedSystemEntities.find(obj => obj.id === lookupfield.RelatedBuildID)					
        return entity.AllFields					
      },					
      GetRelevantFields(page,DataSource){					
        if(DataSource !== 'User Value'){					
          //console.log(page,page.PageDatafromArray,page.ArrayType,this.FunctionEntity)					
          if(page.PageDatafromArray){					
              if(page.ArrayType === 'SubCollection'){					
                  let subcol = this.FunctionEntity.SubCollections.find(subcol => subcol.id === page.DynamicArrayName) 					
                  //console.log(subcol)					
                  if(subcol){					
                      return subcol.Headers.map(header => {					
                        header.Name = header.Name.split(' ').join('_')					
                        return header					
                      })					
                  }					
              }  					
          }					
          else{					
              return this.FunctionEntity.AllFields.map(field => {					
                let fieldobj = Object.assign({},field)					
                return fieldobj					
              })					
          }					
        }					
        else{					
          return [					
            {					
              Name: 'Full_Name',					
              DisplayName: 'Full Name',					
              Type: 'Single Line Text'					
            }					
          ]					
        }					
        					
      },					
      DynamicArrayItems(arraytype){					
          if(arraytype === 'SubCollection'){					
           return this.FunctionEntity.SubCollections.map(subcol => {return subcol.id})   					
          }          					
      },					
      ToggleDialog(dialogname){					
      this[dialogname] = !this[dialogname]					
    },					
        SaveElementChanges(){					
            if(this.editedelementindex === -1){					
              this.LastElementID++					
              this.ActiveElement.ElementID = this.LastElementID					
                //this.ActivePage.Elements.push(this.ActiveElement)					
                let pageobj = this.Pages.find(obj => obj.PageNumber === this.ActivePage.PageNumber)					
                let pageindex = this.Pages.indexOf(pageobj)					
                this.Pages[pageindex].Elements.push(this.ActiveElement)					
                this.DeActivateActiveElement()					
                this.RefreshLivePreview()					
                this.SaveFunction()					
            }					
            else{					
              this.ActivePage.Elements[this.editedelementindex] = Object.assign({},this.ActiveElement)					
              let pageobj = this.Pages.find(obj => obj.PageNumber === this.ActivePage.PageNumber)					
              let pageindex = this.Pages.indexOf(pageobj)					
              this.Pages[pageindex].Elements[this.editedelementindex] = Object.assign({},this.ActiveElement)					
              this.DeActivateActiveElement()					
              this.RefreshLivePreview()					
            }					
        },					
        ToggleActiveElementDialog(){					
            this.DeActivateActiveElement(true)					
        },					
      DeActivateActiveElement(reactivate){					
          this.ActiveElementDialog = false					
          this.ActiveElement =  {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 0,g:0,b:0}}					
          this.editedelementindex = -1					
          if(reactivate){					
              this.ActiveElementDialog = true					
          }
          else{
           this.step = 1 
          }					
      },					
      RefreshLivePreview(){					
        this.RefresingPreview = true					
          setTimeout(() => {					
            this.RefresingPreview = false					
          }, 200);					
      },					
      ActivateActiveElement(elmnt,elmntindex){					
          this.editedelementindex = elmntindex					
          					
          this.ActiveElement = elmnt					
          this.ActiveElementDialog = true					
      },					
      updateFullItem(FullItem){					
          this.FullItem = FullItem					
          console.log('updateFullItem',this.FullItem)					
      },					
      updatearray(elementindex,array,lookuparrays,activitiesarray){ 			
        //alert('yep we updating')		
          console.log(elementindex,array,lookuparrays,activitiesarray)      					
        //   let dashelmnt = this.DashboardElements[elementindex]					
        if(!this.PushedElmnt){					
          this.FullArray = array					
          this.LookupArrays = lookuparrays					
          this.HasData = true					
          if(activitiesarray){					
            this.DashboardActivities = activitiesarray					
          }					
        }					
          console.log(this.FullArray,this.LookupArrays,activitiesarray)					
      },					
   	GetSizes(){				
        let elmnt = document.getElementById('mainbuildercomp')					
        setTimeout(() => {					
        //console.log(elmnt.getBoundingClientRect())					
        if(elmnt && this.ActivePage && this.MainBuilderHeight === 0){					
            this.MainBuilderHeight = elmnt.clientHeight					
            this.MainBuilderWidth = elmnt.clientWidth					
        }    					
        }, 1000);					
        					
    },					
     					
    GetComponentOne(elmnt){					
      if(!elmnt.ChartPosition){					
        if(elmnt.HasChart){					
          // console.log('from here')					
          return {CompName: 'DashboardElmtChart',Style: 'Pipeline'}					
        }					
        else if(elmnt.HasDataContext){          					
          return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}					
        }					
      }					
      else if(elmnt.ChartPosition === 'Top' && elmnt.HasChart){					
        return {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}					
      }					
      else if(elmnt.ChartPosition === 'Right' || elmnt.HasDataContext && !elmnt.HasChart){					
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}					
      }					
      else if(elmnt.ChartPosition === 'Left'){					
        return {CompName: 'DashboardElmtChart',Style: 'Pie'}					
      }					
    },					
    GetComponentTwo(elmnt){					
      if(elmnt.ChartPosition === 'Top'){					
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}					
      }					
      else if(elmnt.ChartPosition === 'Right'){					
        return {CompName: 'DashboardElmtChart',Style: 'Pie'}					
      }					
      else if(elmnt.ChartPosition === 'Left'){					
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}					
      }  					
    },					
   					
      					
      TimestampFormatterSTRING(d){					
       					
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];					
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]					
      					
        var hr = d.getHours();					
       					
        var min = d.getMinutes();					
        if (min < 10) {					
            min = "0" + min;					
        }					
        if(hr < 10){					
          hr = "0"+hr					
        }					
        					
        var date = d.getDate();					
        var month = months[d.getMonth()];					
        var year = d.getFullYear();					
        // 2020-03-15 09:00'					
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;					
        //console.log(finaldate)					
        return finaldate					
      },					
      ActivateActiveDashboardTable(arritem,IsParent,item){					
          //console.log(arritem.ContextData)					
          if(IsParent && item){					
              this.ActiveDashboardComponent = this.DataCollectionViewComponent					
              this.ActiveDashboardArray = item.ContextData 					
              this.ActiveDashboardTable = true					
          }					
          else if(IsParent){					
              alert('First select a Filter')					
          }					
          else{					
            this.ActiveDashboardComponent = this.DataCollectionViewComponent					
            this.ActiveDashboardArray = arritem.ContextData 					
            this.ActiveDashboardTable = true 					
          }					
          					
          					
      },					
      DeactivateActiveDashboardTable(){					
          this.ActiveDashboardTable = false					
          this.ActiveDashboardArray = []					
          this.ActiveDashboardComponent = ''					
      },					
      SaveFunction(){					
        console.log(this.Pages)					
          if(this.FunctionData){					
            // this.Saving = true					
              if(this.FunctionDisplayName){					
                db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    FunctionDisplayName: this.FunctionDisplayName,					
                })  					
              }					
              if(this.FunctionEntity && this.FunctionEntity.id){					
                  db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    FunctionEntity: this.FunctionEntity.id,					
                })					
              }					
              else if(this.FunctionEntity){					
                  db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    FunctionEntity: this.FunctionEntity,					
                })					
              }					
              if(this.FunctionOutput){					
                db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    FunctionOutput: this.FunctionOutput,					
                })  					
              }					
              if(this.Pages){					
                db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    Pages: this.Pages,					
                })  					
              }					
              if(typeof this.HasIndex !== 'undefined'){					
                db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    HasIndex: this.HasIndex,					
                })  					
              }					
              					
              if(this.IndexPageNumber){					
                db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    IndexPageNumber: this.IndexPageNumber,					
                })  					
              }					
               					
              db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    LastElementID: this.LastElementID,					
                })					
              db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
                    LastPageID: this.LastPageID,					
                })					
                       					
              					
            //   if(this.DashboardElements){					
                  					
                  					
            //         console.log(this.SaveDashboardElements)					
            //     db.collection('pdfexportfunctions').doc(this.$route.params.slug).update({					
            //         DashboardElements: this.SaveDashboardElements					
            //     }).then(done => {					
            //       // this.Saving = false					
            //     })					
            //   }					
              					
              					
          }					
          else{					
          					
            let newfunction = {					
                FunctionDisplayName: this.FunctionDisplayName,					
                Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},					
                Created_Byid: this.userLoggedIn.id,					
                FunctionOutput: this.FunctionOutput,					
                Pages: this.Pages,					
                HasIndex: this.HasIndex,					
                IndexPageNumber: this.IndexPageNumber,					
                LastPageID: this.LastPageID,					
                LastElementID: this.LastElementID					
            }					
            if(this.FunctionEntity && this.FunctionEntity.id){					
              newfunction.FunctionEntity = this.FunctionEntity.id					
            }					
            else if(this.FunctionEntity){					
              newfunction.FunctionEntity = this.FunctionEntity					
            }					
           db.collection('pdfexportfunctions').doc(this.$route.params.slug).set(newfunction)   					
          }					
          					
      },					
        					
        DateFormatter(date){					
            if(date){					
                let removeT = date.split('T').join(' ')					
                let removeFWDslash = date.split('/').join('-')					
            let yearstring = removeFWDslash.split('-')[0]					
                let monthstring = removeFWDslash.split('-')[1]					
                let daystring = removeFWDslash.split('-')[2]					
                let yearnumber = Number(yearstring)					
                let monthnumber = Number(monthstring)					
                let daynumber = Number(daystring)					
                return new Date(yearnumber, monthnumber-1, daynumber)					
                }					
            else{					
                return null					
            }					
        },					
 					
        CheckRouting(){	        				
                this.GetRequestingUser()					
                //this.GetBusinessUnits()					
        },					
       				
        		
        GetSystemAssets(){          					
          db.collection('systemassets').onSnapshot(res => {					
          const changes = res.docChanges();					
            changes.forEach((change,i) => {					
              if (change.type === 'added') {					
                this.SystemAssets.push({					
                      ...change.doc.data(),					
                      id: change.doc.id					
                  })					
              }					
            })					
          })					
        },					
        GetRequestingUser(filteredcall){					
      let vm = this					
      if(this.userLoggedIn){					
        this.GetSystemAssets()				
              this.GotEntities = true				
              if(!this.PushedElmnt){					
                vm.GetFunctionDetails()					
              }					
              else{					
                this.FunctionEntity = this.PreparedSystemEntities.find(obj => obj.id === this.PushedElmnt.PrimaryEntity)					
                this.FunctionEntityPrimaryFieldName = this.FunctionEntity.Primary_Field_Name				
                this.FullArray = this.PushedElmnt.Data					
                this.LookupArrays = this.PushedElmnt.LookupArrays					
                					
                let StaticRow = {					
                  Type: 'Row',					
                  Elements: []					
                }					
               					
                  if(this.PushedElmnt.GoalMeasurements){					
                    let newelement = {					
                      GoalObj: this.PushedElmnt,					
                      GoalTargets: this.PushedElmnt.Targets.map(target => {					
                        //console.log(typeof target.StartDate.toDate !== 'undefined',target.StartDate)					
                        if(target.StartDate && typeof target.StartDate.toDate !== 'undefined'){					
                          let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.StartDate.toDate())					
                          target.StartDate = new Date(PrimaryDateSTRING)					
                        }					
                        if(target.EndDate && typeof target.EndDate.toDate !== 'undefined'){					
                          let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.EndDate.toDate())					
                          target.EndDate = new Date(PrimaryDateSTRING)					
                          target.EndDate = this.EndofDay(target.EndDate)					
                        }					
                        return target					
                      }),					
                      AccumulativeGraph: true,					
                      ShowSummary: true,					
                      ShowSummaryOnly: true,					
                      Sort: 'Descending (Value)',					
                      PrimaryLabelType: 'From Timeline',					
                      ColumnWidth: 'Full Column',					
                      ValueType: this.PushedElmnt.ValueType,					
                      Title: this.PushedElmnt.Name,					
                      ComponentOne: this.PushedElmnt.ComponentOne,					
                      ComponentTwo: this.PushedElmnt.ComponentTwo,					
                      ChartPosition: this.PushedElmnt.ChartPosition,					
                      HasChart: true,					
                      ChartType: 'line',					
                      ChartLabelsfromTimeline: true,					
                      HasDataContext: true,					
                      DataContextStyle: 'List',					
                      PrimaryDateFilter: this.PushedElmnt.PrimaryDateFilter,					
                      PrimaryDateObj: this.PushedElmnt.PrimaryDateObj,					
                      GoalMeasurements: true,					
                      PrimaryFilterType: this.PushedElmnt.PrimaryFilterType,					
                      PrimaryFilterField: this.PushedElmnt.PrimaryFilterField,					
                      TimeFilterOption: this.PushedElmnt.ExportTimelineType,					
                      ChartBG: this.PushedElmnt.ThemeColor,					
                    }					
                  					
                  StaticRow.Elements.push(newelement)                  					
                  					
                }					
                else if(this.PushedElmnt.PipelineProcess){					
                  //let baseobj = this.RootElementOptions.find(obj => obj.ConfigID === 1000004)					
                  //ChooseOption then CheckAdvanceStep					
					
                    let newelement = {					
                      PipelineProcess: this.PushedElmnt.PipelineProcess,					
                      ValueType: this.PushedElmnt.ValueType,					
                      Sort: 'Descending (Value)',					
                      Title: this.PushedElmnt.Name,					
                      ShowTitle: true,					
                      ChartLabelsfromProcess: true,					
                      HasChart: true,					
                      HasDataContext: false,                      					
                      ChartBG: this.PushedElmnt.ThemeColor,					
                    }					
                  					
                  StaticRow.Elements.push(newelement)  					
                }					
                // this.ActiveDashboardComponent = this.FunctionEntity.id					
                this.BGColor = this.PushedElmnt.ThemeColor+' lighten-5'					
                this.Today = new Date()					
                this.DashboardElements.push(StaticRow)					
                this.HasData = true					
                //console.log(this.DashboardElements)					
                //console.log(this.FunctionExportPages)					
              }					
      }					
      else {					
        vm.RoutetoLoginPage()					
          }					
          					
      },					
      RoutetoLoginPage(){					
        this.$router.push('/login')					
      },					
      RoutetoErrorPage(){					
        this.$router.push('/PermissionError')					
      },					
    					
    GetFunctionDetails(){					
        db.collection('pdfexportfunctions').doc(this.$route.params.slug).onSnapshot(snapshot => {					
            let functiondata = snapshot.data()					
            console.log(functiondata)					
            if(functiondata){					
                this.RefreshLivePreview()
                this.FunctionData = functiondata					
                if(functiondata.Pages){					
                    this.Pages = functiondata.Pages					
                }					
                if(functiondata.LastPageID)	{				
                  this.LastPageID = functiondata.LastPageID					
                }					
                if(functiondata.LastElementID)	{				
                  this.LastElementID = functiondata.LastElementID					
                }					
                if(functiondata.FunctionDisplayName){					
                    this.FunctionDisplayName = functiondata.FunctionDisplayName					
                }					
                if(functiondata.FunctionOutput){					
                    this.FunctionOutput = functiondata.FunctionOutput					
                }					
                if(functiondata.FunctionEntity){					
                  //console.log(functiondata.FunctionEntity,this.PreparedSystemEntities)					
                    this.FunctionEntity = this.PreparedSystemEntities.find(obj => obj.id === functiondata.FunctionEntity)					
                    //console.log('FunctionEntity',this.FunctionEntity)					
                    let primaryfield = this.FunctionEntity.AllFields.find(obj => obj.Primary === true)					
                    //console.log(primaryfield)					
                    this.FunctionEntityPrimaryFieldName = primaryfield.Name					
                    //console.log(this.FunctionEntityPrimaryFieldName)					
                    //console.log(typeof this.FunctionEntity.EntityProcesses,this.FunctionEntity.EntityProcesses.length,this.FunctionEntity.EntityProcesses)					
                    if(this.FunctionEntity.HasActivities){					
                      //console.log('has activitie')					
                    }					
                //     if(functiondata.Owner){					
                //     this.Owner = functiondata.Owner					
                // }					
                					
                }					
                 else{					
                    this.$emit('ActivateStandbyDialog',false)					
                }					
                					
            }					
            else{					
              let defaultpage = {					
              PageNumber: 1,					
              PageID: 1000001,					
              PageName: 'Front Page',					
              Elements: [],					
              PageHasWaterMark: false,					
              PagePaddingRight: 20,					
              PageWaterMarkObject: '',					
              AddPageperArrayItem: false,					
            }					
            this.Pages.push(defaultpage)					
              //alert('not getting anything, emitting okay')	
                this.RefreshLivePreview()				
                this.$emit('ActivateStandbyDialog',false)					
            }					
        })					
    },		    			
    					
  					
  }					
}					
					
</script>					
					
 <style>					
  					
    					
</style>					
<style scoped>					
.v-data-table{					
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;					
  color:#757575;					
}					
</style>	
    