
import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import firebase from 'firebase'
import db from '@/main'
//import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  //plugins: [createPersistedState()],
  state: {
    InvoiceTypes: [
      {ID: 1000001,Name: 'Store Order'},
      {ID: 1000002,Name: 'Subscription Order'},
      {ID: 1000003,Name: 'Project'},
      {ID: 1000004,Name: 'Cash Loan'},
    ],
    CompanyLoanOrders: [],
    CompanyStoreOrders: [],
    CompanyBillingAccounts: [],
    UserisCompanyAdmin: false,
    TrackingOrders: [],
    ReceivedMessages: [],
    SentMessages: [],
    ActiveMemberGroup: '',
    MemberGroupsArray: [],
    EntitiesArray: [],
    ReservedDisplayNameProps: ['Modules','Plugins','Tests','Sample Records','Documents','Users','Business Units','Activities','System Tickets','Sales Invoices','Purchase Invoices',
  'Statement Invoices','Subscription Orders','Journal Entries','Store Suppliers','Billing Accounts','User Roles','Warehouses','User Companies','Member Groups',
  'Site Articles','Site Blogs','Site Classifieds','Site Events','Site Forums','Groups','Site Meetups','Site Polls',
  'Site Articles Wiki','Site Blogs Wiki','Password Resets','PDF Export Functions','Notifications','Notices','Goals','Friend Requests','Frequently Asked Questions','Data Imports','Client Records',
    'Group Members','Featured Group Members','Featured Members','Token Transactions','Payment Invoices','Sales Quotes','Timesheets','User Invites','Site Moderators','Chatbot Messages',
  'Social Site Images','Social Site Likes','Social Site Comments','Social Site Posts','System Activities','Site Map Tags','Banner Ads','System Assets','Landing Pages'],
    ReservedSingleNameProps: ['Module','Plugin','Test','Sample Record','Document','Users','Business Unit','Activitie','System Ticket','Sales Invoice','Purchase Invoice',
    'Statement Invoice','Subscription Order','Journal Entry','Store Supplier','Billing Account','User Role','Warehouse','User Company','Member Group',
    'Site Article','Site Blog','Site Classified','Site Event','Site Forum','Group','Site Meetup','Site Poll',
    'Site Article Wiki','Site Blog Wiki','Password Reset','PDF Export Function','Notification','Notice','Goal','Friend Request','Frequently Asked Question','Data Import','Client Record',
      'Group Member','Featured Group Member','Featured Member','Token Transaction','Payment Invoice','Sales Quote','Timesheet','User Invite','Site Moderator','Chatbot Message',
    'Social Site Image','Social Site Like','Social Site Comment','Social Site Post','System Activitie','Site Map Tag','Banner Ad','System Asset','Landing Page'],
    ElementAnimationDialog: false,
    BURoles:[
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'CanList', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'CanList', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'CanList', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'CanList', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'CanView', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'CanView', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'CanView', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'CanView', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'CanEdit', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'CanEdit', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'CanEdit', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'CanEdit', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'CanDelete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'CanDelete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'CanDelete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'CanDelete', PermissionLevel: 1},
    ],
    DefaultEntities: [
      {DisplayName: 'Activities',Table_Icon: 'mdi-clipboard-list',Security: 'Business Unit'},
      {DisplayName: 'Business Units',Security: 'Business Unit',Security: 'Custom Roles'},
      {DisplayName: 'System Tickets',Table_Icon: 'mdi-ticket-confirmation',Security: 'Custom Roles'},
      {DisplayName: 'Users',Table_Icon: 'mdi-clipboard-list',Security: 'Custom Roles'}
    ],
    DefaultStatusField: {
      "Created": true,
      "SystemRequired": true,
      "Name": "Status",
      "Primary": false,
      "DefaultLevel2Option": {
          "LevelFieldName": "Status_Reason",
          "ID": 1000003,
          "Level": {
              "name": "Status Reason",
              "FieldName": "Status_Reason",
              "children": [],
              "id": 2
          },
          "Name": "Open"
      },
      "UsedonCompForm": true,
      "IsHeader": true,
      "Type": "Option Set",
      "IsFilter": true,
      "DefaultOption": {
          "LevelFieldName": "Status",
          "Level": {
              "name": "Status",
              "children": [
                  {
                      "id": 2,
                      "name": "Status Reason",
                      "FieldName": "Status_Reason",
                      "children": []
                  }
              ],
              "id": 1,
              "FieldName": "Status"
          },
          "Name": "Active",
          "Options": [
              {
                  "LevelFieldName": "Status_Reason",
                  "ID": 1000003,
                  "Name": "Open",
                  "Level": {
                      "name": "Status Reason",
                      "id": 2,
                      "children": [],
                      "FieldName": "Status_Reason"
                  }
              }
          ],
          "HasDeepHierarchy": true,
          "ID": 1000001
      },
      "IsMandatory": true,
      "Options": [
          {
              "Name": "Active",
              "LevelFieldName": "Status",
              "HasDeepHierarchy": true,
              "Options": [
                  {
                      "ID": 1000003,
                      "LevelFieldName": "Status_Reason",
                      "Name": "Open",
                      "Level": {
                          "FieldName": "Status_Reason",
                          "children": [],
                          "name": "Status Reason",
                          "id": 2
                      }
                  }
              ],
              "ID": 1000001,
              "Level": {
                  "children": [
                      {
                          "id": 2,
                          "FieldName": "Status_Reason",
                          "name": "Status Reason",
                          "children": []
                      }
                  ],
                  "name": "Status",
                  "id": 1,
                  "FieldName": "Status"
              }
          },
          {
              "LevelFieldName": "Status",
              "ID": 1000002,
              "Name": "InActive",
              "HasDeepHierarchy": true,
              "Options": [
                  {
                      "Level": {
                          "id": 2,
                          "name": "Status Reason",
                          "FieldName": "Status_Reason",
                          "children": []
                      },
                      "ID": 1000004,
                      "LevelFieldName": "Status_Reason",
                      "Name": "Closed"
                  }
              ],
              "Level": {
                  "name": "Status",
                  "id": 1,
                  "children": [
                      {
                          "name": "Status Reason",
                          "id": 2,
                          "FieldName": "Status_Reason",
                          "children": []
                      }
                  ],
                  "FieldName": "Status"
              }
          }
      ],
      "DisplayName": "Status",
      "Levels": [
          {
              "FieldName": "Status",
              "id": 1,
              "children": [
                  {
                      "children": [],
                      "name": "Status Reason",
                      "id": 2,
                      "FieldName": "Status_Reason"
                  }
              ],
              "name": "Status"
          },
          {
              "id": 2,
              "name": "Status Reason",
              "FieldName": "Status_Reason",
              "children": []
          }
      ],
      "lastassignedinteger": 1000004,
      "HasDeepHierarchy": true,
      "LastLevel": 2,
      "UsedonForm": false,
      "id": "Status",
      "LevelTree": [
          {
              "children": [
                  {
                      "name": "Status Reason",
                      "FieldName": "Status_Reason",
                      "children": [],
                      "id": 2
                  }
              ],
              "name": "Status",
              "FieldName": "Status",
              "id": 1
          }
      ]
  },
    CSSBlendModes: ['color','color-burn','color-dodge','darken','difference','exclusion','hard-light','hue','lighten','luminosity','multiply','normal','overlay',
    'plus-lighter','saturation','screen','soft-light','unset',
    ],
    CSSColors: [
      'black','silver','gray','white','maroon','red','purple','fuchsia','green','lime','olive','yellow','navy','blue','teal','aqua','aliceblue','antiquewhite','aqua','aquamarine',
      'azure','beige','bisque','black','blanchedalmond','blue','blueviolet','brown','burlywood','cadetblue','chartreuse','chocolate','coral','cornflowerblue','cornsilk','crimson',
      'cyan','darkblue','darkcyan','darkgoldenrod','darkgray','darkgreen','darkgrey','darkkhaki','darkmagenta','darkolivegreen','darkorange','darkorchid','darkred','darksalmon','darkseagreen',
      'darkslateblue','darkslategray','darkslategrey','darkturquoise','darkviolet','deeppink','deepskyblue','dimgray','dimgrey','dodgerblue','firebrick','floralwhite','forestgreen','fuchsia',
      'gainsboro','ghostwhite','gold','goldenrod','gray','green','greenyellow','grey','honeydew','hotpink','indianred','indigo','ivory','khaki','lavender','lavenderblush','lawngreen','lemonchiffon',
      'lightblue','lightcoral','lightcyan','lightgoldenrodyellow','lightgray','lightgreen','lightgrey','lightpink','lightsalmon','lightseagreen','lightskyblue','lightslategray','lightslategrey',
      'lightsteelblue','lightyellow','lime','limegreen','linen','magenta','maroon','mediumaquamarine','mediumblue','mediumorchid','mediumpurple','mediumseagreen','mediumslateblue','mediumspringgreen',
      'mediumturquoise','mediumvioletred','midnightblue','mintcream','mistyrose','moccasin','navajowhite','navy','oldlace','olive','olivedrab','orange','orangered','orchid','palegoldenrod','palegreen',
      'paleturquoise','palevioletred','papayawhip','peachpuff','peru','pink','plum','powderblue','purple','red','rosybrown','royalblue','saddlebrown','salmon','sandybrown','seagreen','seashell','sienna',
      'silver','skyblue','slateblue','slategray','slategrey','snow','springgreen','steelblue','tan','teal','thistle','tomato','turquoise','violet','wheat','white','whitesmoke','yellow','yellowgreen',
    ],
    FontOptions: ['Architects Daughter','Bahianita','Bangers','Bebas Neue','Berkshire Swash','Caveat','Courgette','Crimson Pro','Dancing Script','Gudea','Hind','Impact','Indie Flower','Lato','Lobster','Macondo Swash Caps','Maven Pro','Metrophobic','Michroma','Montserrat','Mulish','Mukta Vaani',
                  'News Cycle','Niramit','NTR','Nunito Sans','Orbitron','Oswald','Padauk','Palanquin','Parisienne','Playfair Display','Poiret One','Poppins','Quicksand','Raleway','Redressed','Roboto','Tangerine','Tulpen One','Verdana','Vibes','Yellowtail'],
    StorePagePresets: [],
    RAStorePagePresets: [],
    ActiveChatRecord: '',
    TimesheetRelated: '',
    StopwatchDialog: false,
    ActiveSuiteMenuItems: [],
    EntityMasterFields: [],
    SystemMasterFields: [],
    RelatedRecordObj: '',
    StoreEntity: '',
    ActiveWarehouse: '',
    AppBuilderHelperActive: false,
    AppBuilderHelperStep: 0,
    AlertSound: '',
    ConfirmMSGDialog: false,
    ConfirmMSGText: '',
    ConfirmMSGTitle: '',
    ConfirmMSGIcon: '',
    ConfirmMSGIconColor: '',
    AppQuickStartDialog: false,
    RAPluginPackages: [],
    RAPlugins: [],
    RAApp: '',
    RADB: '',
    PluginsPath: '',
    AppsPath: '',
    WebsiteisActive: false,
    SocialNetworkisActive: false,
    DatabaseisActive: false,
    DocumentationisActive: false,
    PluginDataBase: {},
    AppsDataBase: [],
    ActiveSuiteApp: '',
    SubscriptionPackageTypes: [
      {ID: 1000002,Name: 'Suite Plugin Package',Prefix: 'SP-IN-'},
      {ID: 1000003,Name: 'App Membership',Prefix: 'ST-IN-AM-'},//subscribe to app, using packages offered by app. maybe senseless
      {ID: 1000004,Name: 'Group Ownership',Prefix: 'ST-IN-GO-'},//subscribtion plan to own a Group was 'Group Package'
      {ID: 1000005,Name: 'Site Channel',Prefix: 'ST-IN-SC-'},//subscribe to channel content e.g. sitearticles or custom collab moderator space e.g. "fashionbloggers" collab, was "Site Content"
      {ID: 1000006,Name: 'Group Directory Listing',Prefix: 'ST-IN-GDL-'},//featuring in a App offered directory was "Group Directory"
      {ID: 1000007,Name: 'Group Membership',Prefix: 'ST-IN-GM-'},//subscribing to group through group offered package
      {ID: 1000008,Name: 'Group Advertisement',Prefix: 'ST-IN-GA-'},//banners and jazz, offered by App
      {ID: 1000009,Name: 'Group Featured Member',Prefix: 'ST-IN-GFM-'},//featuredgroupmembers, offered by...group...featuredmembers is suite non group, featuredgroupmembers is this one
    ],
    SystemConfigTabs: [],
    SystemConfigEntity: '',
    ActiveDocumentationLibrary: '',
    MiniRecordDialog: false,
    MiniRecordDialogTitle: '',
    MiniRecordDialogDescription: '',
    MiniRecordPath: '',
    MiniRecordTabs: [],
    MiniRecordObject: '',
    MiniRecordDialogIMG: '',
    
    UploadFilesList: [],
    UploadFileStorageRef: '',
    UploadFilesDBStorageRef: '',
    setPhotoTagObjects: [],

    SystemAssets: [],
    RASystemAssets: [],
    ContentBlocks: [],
    RenderedContentBlocks: [],
    SiteDialog: false,
    EditedPage: '',
    BuilderView: '',
    GroupScopeTabs: [],
    GroupRoutes: ['GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll','FeaturedMemberEdit','FeaturedMemberSingle',
      'GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupPageEditor','GroupPageBuilder','GroupNewPoll','GroupWebFormEditor','GroupWebFormBuilder'],
    GroupidRoutes: ['GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll',
    'GroupSingle','GroupEdit','GroupNewArticle','GroupNewMeetup','GroupNewBlog','GroupPageEditor','GroupPageBuilder','GroupNewPoll'],
    GroupslugRoutes: ['FeaturedMember','FeaturedMemberEdit'],
    IsGroupView: false,

    IMGProp: '',
    SelectedGalleryAsset: '',
    EditedTab: '',
    EditedPageProperties: {},
    dragging: '',
    draggingtab: '',									
    draggingtabindex: -1,									
    draggingelement: '',									
    draggingelementindex: -1,									
    draggingrow: '',									
    draggingrowindex: -1,
    GalleryLibraries: [],
    GalleryAssets: [],

    ActiveGroup: '',
    UserMemberObj: '',
    GroupForbidden: false,
    // WarehouseSuppliersQuery: '',
    // WarehouseSuppliersArray: [],
    PDFDocLineItems: [],
    PDFFunctionName: '',
    PDFHeaderObj: '',
    PDFFooterObj: '',
    PDFExportDoc: '',
    PDFDownloadOnly: true,
    ExportingtoPDFDialog: false,
    OrderConfirmationDialog: false,
    OrderConfirmationPDF: '',
    PDFDataRecord: '',

    UserAvailableTokens: 0,
    CatNavlists: [],
    MarketDialogObject: '',
    MarketDialogOptions: [],
    MarketDialog: false,
    MarketDialogType: '',
    MarketDialogTitle: '',
    MarketDialogAvatar: '',
    MarketDialogInteractMethod: '',
    MarketDialogDescription: '',
    CustomProcessingDialog: false,
    CustomProcessingDialogText: '',
    WarehousesQuery: '',
    WarehousesArray: [],
    AppMode: {ID: 1000001, Name: 'Bird View',icon: 'mdi-bird'},
    SocialItemInteractMethod: '',
    SocialItemInteractMethodProp: '',
    InvitedGroups: [],
    CurrentEntity: '',
    Cart_Entity: '',
    CurrentItemModerator: '',
    StoreItemPublisher: '',
    StoreSearch: '',
    ViewOrderNavbar: false,
    CanReceive: false,
    CanViewMovementHistory: false,
    SingleNavTabs: [],
    CurrentDataTabs: [],
    SelectedDataTabs: [],
    SocialEntities: [],
    PuchaseOrdersQuery: true,
    SalesInvoicesQuery: '',
    SalesInvoicesArray: [],
    //<!-- user: {
    //  authenticated: false,
    //  id: null,
    //  Full_Name: null
    //    }, -->
    // GymSessionsArray: [],
    Currencies: [
      {Name:'US Dollar',Currency:'USD',LangForm: 'en-US'},
      {Name:'Euro',Currency:'EUR',LangForm: 'en-US'},
      {Name:'British Pound',Currency:'GBP',LangForm: 'en-US'},
      {Name:'Australian Dollar',Currency:'AUD',LangForm: 'en-AU'},
      {Name:'New Zealand Dollar',Currency:'NZD',LangForm: 'en-NZ'},
      {Name:'Japanese Yen',Currency:'JPY',LangForm: 'en-US'},
      {Name:'Rand',Currency:'ZAR',LangForm: 'en-ZA'},
      {Name:'Zimbabwe Dollar',Currency:'ZWL',LangForm: 'en-ZW'},      
    ],
    DefaultCurrency: {Name:'Rand',Currency:'ZAR',LangForm: 'en-ZA'},
    DefaultTax_Percentage: 15,
    Friendships: [],
    Users: [],
    ViewInstanceID: '',
    ReroutePath: '',
    TableData: [],
    SystemObj: {},
    SystemEntities: [],
    IsAdmin: false,
    IsEagleViewer: false,
    SiteContentAdmin: false,
    BusinessProfileAdmin: false,
    RAUserObj: null,
    userobj: null,
    buobj: null,
    LastRoute: null,
    useridtoken: null,
    user: null,
    status: null,
    error: null,
    RMDocumentsArray: [],
    FriendshipsArray: [],
    BusinessUnitsArray: [],
    BusinessUnitsCanCreate: false,
    BusinessUnitsCanView: false,
    BusinessUnitsCanList: false,
    BusinessUnitsCanEdit: false,
    BusinessUnitsCanDelete: false,
    BusinessUnitsQuery: '',
    UsersArray: [],
    UsersCanCreate: false,
    UsersCanView: false,
    UsersCanList: false,
    UsersCanEdit: false,
    UsersCanDelete: false,
    UsersQuery: '',
    UserInfoRequired: false,
    UserRequiredInfo: [],
    MyCompany: '',

  },
  mutations: {
    setUserisCompanyAdmin (state, payload){
      state.UserisCompanyAdmin = payload
    },
    setMyCompany (state, payload){
      state.MyCompany = payload
    },
    SetActiveMemberGroup (state, payload){
      console.log('SetActiveMemberGroup',payload)
      state.ActiveMemberGroup = payload
    },
    SetEditedPageProperties (state, payload){
      state.EditedPageProperties = payload
    },
    ToggleElementAnimationDialog (state, payload){
      state.ElementAnimationDialog = !state.ElementAnimationDialog
    },
    SetActiveChatRecord (state, payload) {
      state.ActiveChatRecord = payload
    },
    SetTimesheetRelated (state, payload) {
      state.TimesheetRelated = payload
    },
    SetStopwatchDialog (state, payload) {
      state.StopwatchDialog = payload
    },
    SetActiveSuiteMenuItems (state, payload) {
      state.ActiveSuiteMenuItems = payload
    },
    setRelatedRecordObj (state, payload) {
      state.RelatedRecordObj = payload
    },
    setStoreEntity (state, payload) {
      state.StoreEntity = payload
    },
    setActiveWarehouse (state, payload) {
      state.ActiveWarehouse = payload
    },
    setAppBuilderHelperStep (state, payload) {
      state.AppBuilderHelperStep = payload
    },
    setAppBuilderHelperActive (state, payload) {
      state.AppBuilderHelperActive = payload
    },
    setConfirmMSGText (state, payload) {
      state.ConfirmMSGText = payload
    },
    setConfirmMSGDialog (state, payload) {
      state.ConfirmMSGDialog = payload
    },
    setConfirmMSGTitle (state, payload) {
      state.ConfirmMSGTitle = payload
    },
    setConfirmMSGIcon (state, payload) {
      state.ConfirmMSGIcon = payload
    },
    setConfirmMSGIconColor (state, payload) {
      state.ConfirmMSGIconColor = payload
    },
    SetAlertSound (state, payload) {
      state.AlertSound = payload
    },
    setRAApp (state, payload) {
      state.RAApp = payload
    },
    setRADB (state, payload) {
      state.RADB = payload
    },
    setAppsPath (state, payload) {
      state.AppsPath = payload
    },
    setPluginsPath (state, payload) {
      state.PluginsPath = payload
    },
    setWebsiteisActive (state, payload) {
      state.WebsiteisActive = payload
    },
    setSocialNetworkisActive (state, payload) {
      state.SocialNetworkisActive = payload
    },
    setDatabaseisActive (state, payload) {
      state.DatabaseisActive = payload
    },
    setDocumentationisActive (state, payload) {
      state.DocumentationisActive = payload
    },
    setPluginDataBase (state, payload) {
      state.PluginDataBase = payload
    },
    setActiveSuiteApp (state, payload) {
      state.ActiveSuiteApp = payload
    },
    
    setAppsDataBase (state, payload) {
      state.AppsDataBase = payload
    },
    setAppQuickStartDialog (state, payload) {
      state.AppQuickStartDialog = payload
    },
    setActiveDocumentationLibrary (state, payload) {
      state.ActiveDocumentationLibrary = payload
    },
    SetSystemConfigEntity (state, payload) {
      state.SystemConfigEntity = payload
    },
    SetSystemConfigTabs (state, payload) {
      state.SystemConfigTabs = payload
    },
    setUploadFilesList (state, payload) {
      state.UploadFilesList = payload
    },
    setPhotoTagObjects (state, payload) {
      state.PhotoTagObjects = payload
    },
    setUploadFileStorageRef (state, payload) {
      state.UploadFileStorageRef = payload
    },
    setUploadFilesDBStorageRef (state, payload) {
      state.UploadFilesDBStorageRef = payload
    },
    setGroupForbidden (state, payload) {
      state.GroupForbidden = payload
    },
    setUserMemberObj (state, payload) {
      state.UserMemberObj = payload
    },
    setSiteDialog (state, payload) {
      state.SiteDialog = payload
    },
    setMiniRecordDialog (state, payload) {
      state.MiniRecordDialog = payload
    },
    setMiniRecordDialogTitle (state, payload) {
      state.MiniRecordDialogTitle = payload
    },
    setMiniRecordObject (state, payload) {
      state.MiniRecordObject = payload
    },
    setMiniRecordDialogIMG (state, payload) {
      state.MiniRecordDialogIMG = payload
    },
    setMiniRecordDialogDescription (state, payload) {
      state.MiniRecordDialogDescription = payload
    },
    setMiniRecordPath (state, payload) {
      state.MiniRecordPath = payload
    },
    setMiniRecordTabs (state, payload) {
      state.MiniRecordTabs = payload
    },
    setSystemAssets (state, payload) {
      state.SystemAssets = payload
    },
    setContentBlocks (state, payload) {
      state.ContentBlocks = payload
    },
    setRenderedContentBlocks (state, payload) {
      state.RenderedContentBlocks = payload
    },
    setIsGroupView (state, payload) {
      state.IsGroupView = payload
    },
    setGroupScopeTabs (state, payload) {
      state.GroupScopeTabs = payload
    },
    setBuilderView (state, payload) {
      state.BuilderView = payload
    },
    setEditedPage (state, payload) {
      state.EditedPage = payload
    },

    setIMGProp (state, payload) {
      state.IMGProp = payload
    },
    setSelectedGalleryAsset (state, payload) {
      state.SelectedGalleryAsset = payload
    },
    setEditedTab (state, payload) {
      state.EditedTab = payload
    },
    setdragging (state, payload) {
      state.dragging = payload
    },
    setdraggingtab (state, payload) {
      state.draggingtab = payload
    },
    setdraggingtabindex (state, payload) {
      state.draggingtabindex = payload
    },
    setdraggingelement (state, payload) {
      state.draggingelement = payload
    },
    setdraggingelementindex (state, payload) {
      state.draggingelementindex = payload
    },
    setdraggingrow (state, payload) {
      state.draggingrow = payload
    },
    setdraggingrowindex (state, payload) {
      state.draggingrowindex = payload
    },
    setGalleryLibraries (state, payload) {
      state.GalleryLibraries = payload
    },
    setGalleryAssets (state, payload) {
      state.GalleryAssets = payload
    },
    setActiveGroup (state, payload) {
      state.ActiveGroup = payload
    },
    EmitMarketDialogOptions(state, payload) {
      state.MarketDialogOptions = payload
    },
    EmitMarketDialogObject(state, payload) {
      state.MarketDialogObject = payload
    },
    EmitMarketDialog(state, payload) {
      state.MarketDialog = payload
    },
    EmitMarketDialogType (state, payload) {
      state.MarketDialogType = payload
    },
    EmitMarketDialogDescription (state, payload) {
      state.MarketDialogDescription = payload
    },
    EmitMarketDialogTitle (state, payload) {
      state.MarketDialogTitle = payload
    },
    EmitMarketDialogAvatar (state, payload) {
      state.MarketDialogAvatar = payload
    },    
    EmitMarketDialogInteractMethod (state, payload) {
      state.MarketDialogInteractMethod = payload
    }, 
    setPDFExportDoc (state, payload) {
      state.PDFExportDoc = payload
    },
    setPDFFunctionName (state, payload) {
      state.PDFFunctionName = payload
    },
    setPDFHeaderObj (state, payload) {
      state.PDFHeaderObj = payload
    },
    setPDFFooterObj (state, payload) {
      state.PDFFooterObj = payload
    },
    setExportingtoPDFDialog (state, payload) {
      state.ExportingtoPDFDialog = payload
    },
    setPDFDownloadOnly (state, payload) {
      state.PDFDownloadOnly = payload
    },
    setPDFDocLineItems (state, payload) {
      state.PDFDocLineItems = payload
    },
    setUserAvailableTokens (state, payload) {
      state.UserAvailableTokens = payload
    },
    setOrderConfirmationDialog (state, payload) {
      state.OrderConfirmationDialog = payload
    },
    setPDFDataRecord (state, payload) {
      state.PDFDataRecord = payload
    },
    setOrderConfirmationPDF (state, payload) {
      state.OrderConfirmationPDF = payload
    },
    setCustomProcessingDialog (state, payload) {
      state.CustomProcessingDialog = payload
    },
    setCustomProcessingDialogText (state, payload) {
      state.CustomProcessingDialogText = payload
    },
    setUserRequiredInfo(state, payload) {
      state.UserRequiredInfo = payload
    },
    setUserInfoRequired (state, payload) {
      state.UserInfoRequired = payload
    },
    setUserAdmin (state, payload) {
      state.IsAdmin = payload
    },
    setUserEagleView (state, payload) {
      state.IsEagleViewer = payload
    },
    setSiteContentAdmin (state, payload) {
      state.SiteContentAdmin = payload
    },
    setBusinessProfileAdmin (state, payload) {
      state.BusinessProfileAdmin = payload
    },
    SetSystemObj (state, payload) {
      state.SystemObj = payload.system
    },
    SetSystemDefaultCurrency (state, payload) {
      state.DefaultCurrency = payload
    },
    SetUserStateObj (state, payload) {
      state.user = payload
    },
    AddCatNavlistTable (state, payload) {
      state.CatNavlists.push(payload)
    },
    SetCatNavlistTableQuery (state, payload) {
      let entry = state.CatNavlists.find(obj => obj.EntityID === payload.EntityID)
      if(entry){
        entry.Query = payload.Query
      }
    },
    SetUserIdToken (state, payload) {
      state.useridtoken = payload
    },
    SetLastRoute(state, payload) {
      //console.log(typeof payload.Date.toDate)
      //console.log(typeof payload.Date)
      if(typeof payload.toDate !== 'undefined'){
       let d = payload.Date.toDate()  
        var hr = d.getHours();      
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        payload.Date = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min; 
      }
      
      state.LastRoute = payload
    },
    
    
    setRAUserObj (state, payload) {
      state.RAUserObj = payload
    },
    setUserObj (state, payload) {
      console.log('setUserObj',payload)
      state.userobj = payload
    },
    SetAppMode (state, payload) {
      state.AppMode = payload
    },
    SetInvitedGroups (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      //alert('setting invited groups')
      state.InvitedGroups = payload
    },
    SetCurrentEntity (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      state.CurrentEntity = payload
    },
    SetCartEntity (state, payload) {
      //console.log('setting SetCurrentEntity as '+payload)
      state.Cart_Entity = payload
    },
    
    SetCurrentItemModerator (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CurrentItemModerator = payload
    },
    SetStoreItemPublisher (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.StoreItemPublisher = payload
    },
    SetViewOrderNavbar (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.ViewOrderNavbar = payload
    },
    SetStoreSearch (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.StoreSearch = payload
    },
    SetCanReceive (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CanReceive = payload
    },
    SetCanViewMovementHistory (state, payload) {
      //console.log('setting SetCurrentItemModerator as '+payload)
      state.CanViewMovementHistory = payload
    },
    SetSelectedDataTabs (state, payload) {
      state.SelectedDataTabs = payload
    },
    SetSingleNavTabs (state, payload) {
      state.SingleNavTabs = payload
    },
    SetCurrentDataTabs (state, payload) {
      state.CurrentDataTabs = payload
    },
    SetSocialItemInteractMethod (state, payload) {
      state.SocialItemInteractMethod = payload
    },
    SetSocialItemInteractMethodProp (state, payload) {
      state.SocialItemInteractMethodProp = payload
    },
    SetRandomInteractProp (state, payload) {
      state.RandomInteractProp = payload
    },
    SetEntityObj (state, payload) {
      state[payload.ArrayName] = []
      state[payload.QueryName] = ''
    },
    setUserBUObj (state, payload) {
      state.buobj = payload
    },
    setCollectionInteractPermission (state,payload){
      state[payload.queryname] = payload.boolean
    },
    setCollectionQuery (state, payload) {
      state[payload.queryname] = payload.query
    },
    setReroutePath (state, payload){
      state.ReroutePath = payload
    },
    setViewInstanceID (state, payload){
      state.ViewInstanceID = payload
    },
    setSocialEntities (state, payload){
      state.SocialEntities = payload
    },
    setCompanyCollectionArray (state, payload) {
      state.MyCompany[payload.prop][payload.arrayname] = payload.array
      console.log('state.MyCompany',state.MyCompany)
    },
    setCollectionArray (state, payload) {
      state[payload.arrayname] = payload.array
      let entry = state.TableData.find(obj => obj.Name === payload.arrayname)
      if(entry){
        let index = state.TableData.indexOf(entry)
        state.TableData[index].Data = payload.array
      }
      //console.log('state.TableData',state.TableData)
    },
    setCatNavlistData (state, payload) {
      //console.log(payload.EntityID+' is now '+payload.array)
      let entry = state.CatNavlists.find(obj => obj.EntityID === payload.EntityID)
      if(entry){
        entry.Data = payload.array
      }
      //console.log('state.TableData',state.TableData)
    },
    
    setUser (state, payload) {
      state.user = payload
      // state.user.id = payload.id
      // state.user.BusinessUnit = payload.BusinessUnit
      // state.user.FullName = payload.FirstName+' '+payload.Surname
    },

    removeUser (state) {
      state.user = null
    },

    setStatus (state, payload) {
      state.status = payload
    },

    setError (state, payload) {
      state.error = payload
    },
    //SET_USER (state, user) {
      //state.authUser = user

    //},
   //authUser(state, user) {
      //state.user.id = user.uid
      //state.user.Full_Name = user.Full_Name
      //state.user.authenticated = true
     // console.log(state.user.Full_Name)
    //}, -->

  },
  actions: {
    loginWithFirebase ({ commit },payload) {
      //console.log(payload)
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
          .then(function(result) {
            console.log(result.user.uid)
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            console.log('login user id',result.user.uid)
              //vm.$emit('AssignAuth',result.user)
          }).catch(function(error) {
            console.log(error)
            if(error.code === 'auth/wrong-password'){
              alert('This password does not seem correct')
            }
            else if(error.code === 'auth/user-not-found'){
              alert('We do not recognize this email')
            }
            // if(error.message.toLowerCase().includes('password is invalid'))
            // Handle error.
          });


    },
    CopyRouteToClipBoard ({ commit }, payload) {
      let path = '' 
      let route = payload.route
      let successmsg = payload.successmsg
      if(window.location.port.length > 0){          
          path = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+route
      }
      else{
          path = window.location.protocol+'//'+window.location.hostname+route
      }
      navigator.clipboard.writeText(path).then(function() {
        let snackbarcontent = {
            snackbartimeout: 4000,
            snackbartext: successmsg,
            snackbartop: true,
            boolean: true
          }
          commit('SetSocialItemInteractMethodProp', snackbarcontent)
          commit('SetSocialItemInteractMethod', 'CommitSnackbar')
      }, function(err) {
      console.error('Async: Could not copy text: ', err);
      });
    },
    AssignQueryString (state, payload) {
      return new Promise(function(resolve, reject) { 
        console.log(payload)
        let NewDataObj = payload.NewDataObj
        let header = payload.header
        let String = NewDataObj[header.propvalue].trim()
        //console.log('String','"String"')
        var lowertext = "";                
        var p;
        let buildup = ''
        //console.log(String.length,String)
        for (p = 0; p < String.length; p++) {
            buildup = buildup+String[p]
            if(p === String.length-1){
              lowertext += buildup.toLowerCase();
            }
            else{
              lowertext += buildup.toLowerCase() + ",";
            }
          }
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(NewDataObj[header.propvalue].trim().split(' '))         
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.concat(lowertext.split(','))  
          NewDataObj.SearchQuery = NewDataObj.SearchQuery.map(entry => {
            return entry.toLowerCase()
          })
          resolve(NewDataObj)
        })
        
    },
    signInClientAppAction ({ commit }, payload) {
      return new Promise(function(resolve, reject) { 
      let check = firebase.apps.find(obj => obj.name === payload.SystemName) 
      //console.log('yes this one',check)    
      if(!check){
        const clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
         
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
      else{
        const clientApp = check  
        clientApp.auth().signInWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          resolve(response)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      }
    })
    },
    UserLoginStatus({ commit }, payload) {
    //console.log('will try set status')
      db.collection('users').doc(payload.uid).update({
          onlinestatus: payload.status
        }) 
},
    GetActiveDocumentationLibrary({ commit }, payload) {
      return new Promise((resolve, reject) => {
        // payload.query.onSnapshot(snapshot => {
          console.log('libraryobj',payload)
          let libraryobj = payload
          libraryobj.Pages = []
          let pagequery = libraryobj.Query
          pagequery.collection('Pages').onSnapshot(res => {
            let pageslength = res.docs.length
            const changes = res.docChanges();									
                  changes.forEach(change => {									
                    if (change.type === 'added') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      libraryobj.Pages.push(pageobj)
                    }
                    if (change.type === 'modified') {									
                        let pageobj = {									
                        ...change.doc.data(),									
                        id: change.doc.id									
                      }	
                      let oncheck = libraryobj.Pages.find(obj => obj.id === pageobj.id)
                      if(oncheck){
                          let index = libraryobj.Pages.indexOf(oncheck)
                          libraryobj.Pages.splice(index,1,pageobj)
                      }
                    }
                    if(libraryobj.Pages.length === pageslength){
                      commit('setActiveDocumentationLibrary', libraryobj)  
                    }
                  })
        })
        })
      // })
    },
    GetUsersArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      db.collection('users').onSnapshot(res => {
        let arraylength = res.docs.length
        let array = this.getters.getUsersArray
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let baseobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              baseobj.groups = []
              //console.log(baseobj.id)
              // array.push(baseobj)
              
              db.collection('groupmembers').doc(baseobj.id).collection('groups').where('Status','==','Active').onSnapshot(groupsres => {
                
                let groupslength = groupsres.docs.length
                //console.log(groupslength)
                const groupchanges = groupsres.docChanges();
                groupchanges.forEach(groupchange => {
                    if (groupchange.type === 'added') {
                        baseobj.groups.push({
                          ...groupchange.doc.data(),
                          id: groupchange.doc.id
                        })
                      }
                      //console.log(baseobj)
                      //console.log(groupslength,baseobj.groups.length)
                      if(baseobj.groups.length === groupslength){
                        array.push(baseobj)
                        //console.log('this one '+baseobj.id+' now has all groups')
                      }
                      
                    })
                    if(groupslength === 0){
                      //console.log('this one '+baseobj.id+' has NO groups')
                      array.push(baseobj)
                    }
                    let setcollection = {
                      arrayname : 'Users', 
                      array: array
                    }   
                  commit('setCollectionArray', setcollection)  
                  if(array.length === arraylength){
                    resolve('Completed')
                  }          
              })
            }
          })        
          
      })       
    })
  },
  //systembuilder stuff
  ClientAdminsignUpAction ({ commit }, payload) {
    return new Promise(function(resolve, reject) {     
    let check = firebase.apps.find(obj => obj.name === payload.SystemName)
    //console.log(check)     
     const userobj = payload.user
    if(check){
      const clientApp = check       
        clientApp.auth().createUserWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {
          resolve(response)
            
        }).catch(error => {
          console.log(error)
        });
    }
    else{
      const clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)        
      const clientDB = clientApp.firestore()
        clientApp.auth().createUserWithEmailAndPassword(payload.user.email, payload.user.password)
        .then((response) => {            
          resolve(response)
        }).catch(error => {
          console.log(error)
        });
    }
  })
    
  },
  ProcessClientBU ({ commit }, payload) {
    // commit('setStatus', 'loading')
    return new Promise(function(resolve, reject) {
    //console.log('I work',firebase.apps.length)
    //console.log(firebase.apps)
    //console.log(payload.ClientFireStore)
    //console.log(payload.SystemName)
    //console.log(payload.NewclientBU)
    let check = firebase.apps.find(obj => obj.name === payload.SystemName)
    //console.log(check)
    let clientApp = {}
    if(check){
      clientApp = check
    }
    else{
      clientApp = firebase.initializeApp(payload.ClientFireStore, payload.SystemName)
    }

      const clientDB = clientApp.firestore()
      clientDB.collection('businessunits').doc(payload.NewclientBU.Name.split(' ').join('_')).set(payload.NewclientBU)
      .then(doc => {
        let childarrayDBRules = {
          [payload.NewclientBU.Name.split(' ').join('_')]: true
        }
        let childarrayQuery = [
          payload.NewclientBU.Name.split(' ').join('_')
        ]
        clientDB.collection('businessunits').doc(payload.NewclientBU.Name.split(' ').join('_')).update({
          childarrayDBRules: childarrayDBRules,
          childarrayQuery: childarrayQuery
        })
        .then(response => {
          resolve(payload.NewclientBU.Name.split(' ').join('_'))
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
      })
      // clientDB.collection('businessunits').add(payload.NewclientBU)
      // .then(doc => {
      //   let childarrayDBRules = {
      //     [doc.id]: true
      //   }
      //   let childarrayQuery = [
      //     doc.id
      //   ]
      //   clientDB.collection('businessunits').doc(doc.id).update({
      //     childarrayDBRules: childarrayDBRules,
      //     childarrayQuery: childarrayQuery
      //   })
      //   .then(response => {
      //     resolve(doc.id)
      //   })
      //   .catch((error) => {
      //     commit('setStatus', 'failure')
      //     commit('setError', error.message)
      //     alert(error.message)
      //   })
      // })
      .catch((error) => {
        commit('setStatus', 'failure')
        commit('setError', error.message)
        alert(error.message)
      })
    })
    
  },
  //systembuilder stuff
  GetArrayandSubcols({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let query = payload.query
        let provider = payload.provider
        query.onSnapshot(res => {
          let arraylength = res.docs.length
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: []
        }   
        commit('setCollectionArray', setcollection)
      }
      else{
          let array = []
          if(this.state[payload.arrayname]){
            array = this.state[payload.arrayname]
          }
          const changes = res.docChanges();
          changes.forEach((change,i) => {
            if (change.type === 'added') {
              let itemobj = change.doc.data()
              itemobj.id = change.doc.id
              if(provider){
                itemobj.Provider = provider 
              }
              itemobj[payload.SubcolName] = []
              query.doc(itemobj.id).collection(payload.Subcolpath).onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    itemobj[payload.SubcolName].push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj[payload.SubcolName].length === subcollength){
                      let oncheck = array.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        array.push(itemobj)
                      }                      
                    }
                  }
                 
                })
                if(subcollength === 0){
                  array.push(itemobj)
                }
              })
            }
             if(change.type === 'modified'){
                    let itemobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                   // console.log('itemobj change',itemobj)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = array.indexOf(oncheck)
                      itemobj[payload.SubcolName] = oncheck[payload.SubcolName]
                      array.splice(index,1,itemobj)
                     // console.log('array update',array)
                    }
                  }
                  if(change.type === 'removed'){
                    let itemobj = {
                      ...change.doc.data(),
                      id: change.doc.id
                    }
                   // console.log('itemobj change',itemobj)
                    let oncheck = array.find(obj => obj.id === itemobj.id)
                    if(oncheck){
                      let index = array.indexOf(oncheck)
                      array.splice(index,1)
                     // console.log('array update',array)
                    }
                  }
          })
          let setcollection = {
            arrayname : payload.arrayname, 
            array: array
          }   
          //console.log('store getting that query now ',payload.arrayname)
          commit('setCollectionArray', setcollection) 
          //console.log(payload.arrayname,'array.length ',array.length,'arraylength',arraylength)
          if(array.length === arraylength){
            //console.log('store resolving not as array ',payload.arrayname,payload)
            resolve('Completed NOT Empty')
          }
        } 
        })
    })
  },
  GetArrayandMultipleSubcols({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let query = payload.query
      let provider = payload.provider
      query.onSnapshot(res => {
        let arraylength = res.docs.length
        let SubCollections = payload.SubCollections
        let subcolslength = payload.SubCollections.length
    if(!res.docs){
      resolve('Completed Empty')
      let setcollection = {
        arrayname : payload.arrayname, 
        array: []
      }   
      commit('setCollectionArray', setcollection)
    }
    else{
        let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let itemobj = change.doc.data()
            itemobj.id = change.doc.id
            if(provider){
              itemobj.Provider = provider 
            }
            SubCollections.map((scol,scoli) => {
              itemobj[scol.SubcolName] = []
              query.doc(itemobj.id).collection(scol.Subcolpath).onSnapshot(subcolres => {
                const subcolchanges = subcolres.docChanges();
                let subcollength = subcolres.docs.length
                subcolchanges.forEach((subcolchange,i) => {
                  if (subcolchange.type === 'added') {
                    itemobj[scol.SubcolName].push({
                      ...subcolchange.doc.data(),
                      id: subcolchange.doc.id})
                    if(itemobj[scol.SubcolName].length === subcollength && scoli-1+2 === subcolslength){
                      let oncheck = array.find(obj => obj.id === itemobj.id)
                      if(!oncheck){
                        array.push(itemobj)
                      }                      
                    }
                  }
                
                })
                if(subcollength === 0 && scoli-1+2 === subcolslength){
                  array.push(itemobj)
                }
              })
            })            
          }
           if(change.type === 'modified'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    SubCollections.map((scol,scoli) => {
                      itemobj[scol.SubcolName] = oncheck[scol.SubcolName]
                    })
                    array.splice(index,1,itemobj)
                   // console.log('array update',array)
                  }
                }
                if(change.type === 'removed'){
                  let itemobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                 // console.log('itemobj change',itemobj)
                  let oncheck = array.find(obj => obj.id === itemobj.id)
                  if(oncheck){
                    let index = array.indexOf(oncheck)
                    array.splice(index,1)
                   // console.log('array update',array)
                  }
                }
        })
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection) 
        if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      } 
      })
  })
},
    GetCompanyCollectionArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      if(!Array.isArray(payload.query)){
        //console.log('not array',payload)
        this.dispatch('GetCompanyCollectionArrayQuery',payload).then(result => {
          resolve(result)
        })
      }
      else{
        //console.log('as array',payload)
        let length = payload.query.length
        //console.log('store array payload.query',payload.query,payload)
        payload.query.map((singlequery,singlequeryindex) => {
          //console.log('store payload.arrayname',payload.arrayname,singlequeryindex,length)
          let newquery = Object.assign(payload)
          newquery.query = singlequery
          newquery.AsArray = true
          this.dispatch('GetCompanyCollectionArrayQuery',newquery).then(result => {
            //console.log('store array result',payload.arrayname,result,singlequeryindex,length)
            if(singlequeryindex-1+2 === length){
            resolve(result)
            }           
          })         
        })
      }
    })
    },
    GetCollectionArray({ commit }, payload) {
      return new Promise((resolve, reject) => {
      if(!Array.isArray(payload.query)){
        //console.log('not array',payload)
        this.dispatch('GetCollectionArrayQuery',payload).then(result => {
          resolve(result)
        })
      }
      else{
        //console.log('as array',payload)
        let length = payload.query.length
        //console.log('store array payload.query',payload.query,payload)
        payload.query.map((singlequery,singlequeryindex) => {
          //console.log('store payload.arrayname',payload.arrayname,singlequeryindex,length)
          let newquery = Object.assign(payload)
          newquery.query = singlequery
          newquery.AsArray = true
          this.dispatch('GetCollectionArrayQuery',newquery).then(result => {
            //console.log('store array result',payload.arrayname,result,singlequeryindex,length)
            if(singlequeryindex-1+2 === length){
             resolve(result)
            }           
          })         
        })
      }
    })
  },
  GetCompanyCollectionArrayQuery({ commit }, payload) {
    //alert('ah hell yeah I call')
    return new Promise((resolve, reject) => {
    payload.query.onSnapshot(res => {
      let arraylength = res.docs.length
      let counter = 0
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: [],
          prop: payload.prop
        }   
        commit('setCompanyCollectionArray', setcollection)
      }
      else{
       let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }              
          }
          if (change.type === 'modified') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1, {
              ...change.doc.data(),
              id: change.doc.id
            });
          }
          if (change.type === 'removed') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1);
          }
        })        
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array,
          prop: payload.prop
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCompanyCollectionArray', setcollection)  
       
        if(payload.AsArray && counter === arraylength){
          //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
          resolve('Completed NOT Empty')
        }
        else if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      }                  
    })       
  })
},
  GetCollectionArrayQuery({ commit }, payload) {
    //alert('ah hell yeah I call')
    return new Promise((resolve, reject) => {
    payload.query.onSnapshot(res => {
      let arraylength = res.docs.length
      let counter = 0
      if(!res.docs){
        resolve('Completed Empty')
        let setcollection = {
          arrayname : payload.arrayname, 
          array: []
        }   
        commit('setCollectionArray', setcollection)
      }
      else{
       let array = []
        if(this.state[payload.arrayname]){
          array = this.state[payload.arrayname]
        }
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let newobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }              
          }
          if (change.type === 'modified') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1, {
              ...change.doc.data(),
              id: change.doc.id
            });
          }
          if (change.type === 'removed') {
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            let arrayobjindex = array.indexOf(arrayobj)
            array.splice(arrayobjindex, 1);
          }
        })        
        let setcollection = {
          arrayname : payload.arrayname, 
          array: array
        }   
        //console.log('store getting that query now ',payload.arrayname)
        commit('setCollectionArray', setcollection)  
       
        if(payload.AsArray && counter === arraylength){
          //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
          resolve('Completed NOT Empty')
        }
        else if(array.length === arraylength){
          //console.log('store resolving not as array ',payload.arrayname,payload)
          resolve('Completed NOT Empty')
        }
      }                  
    })       
  })
},
GetCatNavlistDataArray({ commit }, payload) {
  //alert('ah hell yeah I call')
  return new Promise((resolve, reject) => {
    let query = payload.Query
    query.onSnapshot(res => {
    let arraylength = res.docs.length
    let counter = 0
    if(!res.docs){
      resolve('Completed Empty')
      let setcollection = {
        EntityID : payload.EntityID, 
        array: []
      }   
      commit('setCatNavlistData', setcollection)
    }
    else{
     let array = []
      if(this.state[payload.arrayname]){
        array = this.state[payload.arrayname]
      }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let newobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          if(payload.CategoricalListType === 'Store Products'){
            newobj.Warehouses = []
            array.push(newobj)
            return query.doc(newobj.id).collection('Warehouses').onSnapshot(res => {
              let whlength = res.docs.length
              //console.log(whlength+' warehosues for '+newobj.id)
                if(whlength === 0){
                   //done
                  
                  counter++
                }
                const changes = res.docChanges();									
                changes.forEach(change => {									
                    if (change.type === 'added') {
                      newobj.Warehouses.push({									
                        ...change.doc.data(),									
                        id: change.doc.id									
                    })
                    if(newobj.Warehouses.length === whlength){
                      //console.log('got all warehouses for '+newobj.id)
                      newobj.Stock_Qty = newobj.Warehouses.map(wh => {
                            if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                        }).reduce((a, b) => a + b, 0)
                        //array.push(newobj)
                        //console.log(array)
                        counter++
                        //done
                    }
                    }
                })
            })
          }
          else{
            let arrayobj = array.find(obj => obj.id == change.doc.id)
            if(!arrayobj){
              array.push(newobj)
              counter++
            }
          }
                        
        }
        if (change.type === 'modified') {
          let newobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let arrayobj = array.find(obj => obj.id == change.doc.id)
          let arrayobjindex = array.indexOf(arrayobj)
          if(payload.CategoricalListType === 'Store Products'){
            array.splice(arrayobjindex, 1, newobj);
            newobj.Warehouses = []
            query.doc(newobj.id).collection('Warehouses').onSnapshot(res => {
              let length = res.docs.length
                if(length === 0){
                   //done
                   
                  counter++
                }
                const changes = res.docChanges();									
                changes.forEach(change => {									
                    if (change.type === 'added') {
                      newobj.Warehouses.push({									
                        ...change.doc.data(),									
                        id: change.doc.id									
                    })
                    if(newobj.Warehouses.length === length){
                      newobj.Stock_Qty = newobj.Warehouses.map(wh => {
                            if(!wh.Available){
                                wh.Available = 0
                            }
                            return wh.Available
                        }).reduce((a, b) => a + b, 0)
                        array.splice(arrayobjindex, 1, newobj);
                        counter++
                        //done
                    }
                    }
                })
            })
          }
          else{
            array.splice(arrayobjindex, 1, newobj);
          }          
        }
        if (change.type === 'removed') {
          let arrayobj = array.find(obj => obj.id == change.doc.id)
          let arrayobjindex = array.indexOf(arrayobj)
          array.splice(arrayobjindex, 1);
        }
      })        
      let setcollection = {
        EntityID : payload.EntityID, 
        array: array
      }   
      //console.log('store getting that query now ',payload.arrayname)
      commit('setCatNavlistData', setcollection)  
     
      if(payload.AsArray && counter === arraylength){
        //console.log('store resolving as array ',payload.arrayname,payload,counter,arraylength)
        resolve('Completed NOT Empty')
      }
      else if(array.length === arraylength){
        //console.log('store resolving not as array ',payload.arrayname,payload)
        resolve('Completed NOT Empty')
      }
    }                  
  })       
})
},
    SetUserObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        payload.db.collection('users').doc(payload.userid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = payload.userid
            //console.log(userdata)
             if(!userdata.Full_Name && !payload.RapidAppsDB){
               let info = []
                if(this.getters.getRequiredInformation){
                  info = this.getters.getRequiredInformation
                }
                info.push('Name') 
                info.push('Surname')                          
                commit('setUserRequiredInfo',info)
                commit('setUserInfoRequired', true) 
              }
              payload.db.collection('userroles').doc(payload.userid).onSnapshot(snapshot => {
              let userroles = snapshot.data()
              userdata.rolesarrayQuery = userroles.rolesarrayQuery
              userdata.rolesarrayDBRules = userroles.rolesarrayDBRules
              userdata.Business_Unit = userroles.Business_Unit
              userdata.Business_Unitid = userroles.Business_Unitid
              let varrolesprops = ['LoanEntities','TrackedEntity','StoreEntities','TrackFilter','CompanyLoanEntities','CompanyStoreEntities',
                'MonetizationOfficer','IsEagleViewer','BusinessProfileAdmin','SiteContentAdmin','IsGuestUser','IsSystemAdmin','IsTeamActivityManager','CanInviteUsers','InviteGroups','CanExport','CanImport',
                'BusinessAccounting','Warehouses','IsSystemAdmin','Regionid','Contact','DBNavList','UserTableFilters'
              ] 
              varrolesprops.map(role => {
                if(typeof userroles[role] !== 'undefined'){
                  userdata[role] = userroles[role]
                }
              })
              if(userroles.MemberGroup){
                userdata.MemberGroup = userroles.MemberGroup
                userdata.MemberGroupid = userroles.MemberGroupid
              }
              else{
                userdata.MemberGroup = null
                  userdata.MemberGroupid = null
              }
              if(userroles.Company){
                userdata.Company = userroles.Company
                userdata.Companyid = userroles.Companyid
              }
              //if(this.state.SystemObj.Has_Store){
                userdata.BillingAccounts = []
                db.collection('billingaccounts').where('Userid','==',payload.userid).onSnapshot(res => {
                  const changes = res.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              userdata.BillingAccounts.push(accountdata)
                            }
                            if (change.type === 'modified') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              let oncheck = userdata.BillingAccounts.find(obj => obj.id === accountdata.id)
                              if(oncheck){
                                let index = userdata.BillingAccounts.indexOf(oncheck)
                                userdata.BillingAccounts.splice(index,1,accountdata)
                              }
                            }
                            if (change.type === 'removed') {
                              let accountdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              let oncheck = userdata.BillingAccounts.find(obj => obj.id === accountdata.id)
                              if(oncheck){
                                let index = userdata.BillingAccounts.indexOf(oncheck)
                                userdata.BillingAccounts.splice(index,1)
                              }
                            }
                            
                          })
                })
              //}
              if(!payload.RapidAppsDB){
              commit('setSiteContentAdmin', userroles.SiteContentAdmin) 
              commit('setBusinessProfileAdmin', userroles.setBusinessProfileAdmin)
              commit('setUserEagleView', userroles.IsEagleViewer)
              commit('setUserAdmin', userroles.IsSystemAdmin)
              }
              userdata.SubordinateArrayDBrules = userroles.SubordinateArrayDBrules
              userdata.Subordinatearrayquery = userroles.Subordinatearrayquery
              userdata.CreditTokens = []
              if(userdata.Market_Accountid){
                let marketacctprops = [{ArrayName: 'CreditTokens',Query: db.collection('billingaccounts').doc(userdata.Market_Accountid).collection('credittokens').where('Userid','==',payload.userid).where('Active','==',true).orderBy('Date','asc')},
                {ArrayName: 'ContentPurchases',Query: db.collection('billingaccounts').doc(userdata.Market_Accountid).collection('contentpurchases').where('Userid','==',payload.userid)}]
                marketacctprops.map(entry => {
                  userdata[entry.ArrayName] = []
                  entry.Query.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        let newobj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        if(!arrayobj){
                          userdata[entry.ArrayName].push(newobj)
                        }              
                      }
                      if (change.type === 'modified') {
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                        userdata[entry.ArrayName].splice(arrayobjindex, 1, {
                          ...change.doc.data(),
                          id: change.doc.id
                        });
                      }
                      if (change.type === 'removed') {
                        let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                        let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                        userdata[entry.ArrayName].splice(arrayobjindex, 1);
                      }
                    }) 
                  })
                })
              }
              //basically if you have "Market_Accountid" it will call, so when it's creaed updated user record
              let usersocialpackagesarray = [
                //{ArrayName: 'Subscriptions',Query: db.collection('subscriptionorders').where('Userid','==',payload.userid)},
                //suborders need //where('Type.Name','==','Group Membership')
                //where('Type.Name','==','Site Content Channel') or we truly could call absolutely everything?
                {ArrayName: 'ContentPurchases',Query: db.collection('users').doc(payload.userid).collection('contentpurchases')},
                // {ArrayName: 'CreditTokens',Query: db.collection('billingaccounts').doc(payload.userid).collection('credittokens').where('Active','==',true).orderBy('Date','asc')},
                {ArrayName: 'AccountCredits',Query: db.collection('users').doc(payload.userid).collection('credits')},
                {ArrayName: 'AccountDebits',Query: db.collection('users').doc(payload.userid).collection('debits')}
              ]
              usersocialpackagesarray.map(entry => {
                userdata[entry.ArrayName] = []
                entry.Query.onSnapshot(res => {
                  const changes = res.docChanges();
                  changes.forEach(change => {
                    if (change.type === 'added') {
                      let newobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      if(!arrayobj){
                        userdata[entry.ArrayName].push(newobj)
                      }              
                    }
                    if (change.type === 'modified') {
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                      userdata[entry.ArrayName].splice(arrayobjindex, 1, {
                        ...change.doc.data(),
                        id: change.doc.id
                      });
                    }
                    if (change.type === 'removed') {
                      let arrayobj = userdata[entry.ArrayName].find(obj => obj.id == change.doc.id)
                      let arrayobjindex = userdata[entry.ArrayName].indexOf(arrayobj)
                      userdata[entry.ArrayName].splice(arrayobjindex, 1);
                    }
                  }) 
                })
              })
             
             // let AdminTest = queryroles.find(obj => obj === 'System Admin')   
              // if(typeof AdminTest !== 'undefined'){
              //   commit('setUserAdmin', true)
              // }
              // let EagleTest = queryroles.find(obj => obj === 'Eagle view')   
              //   if(typeof EagleTest !== 'undefined'){
              //     commit('setUserEagleView', true)
              //   }
              payload.db.collection('sitemoderators').doc(userdata.id).onSnapshot(modsnapshot => {
                let moderatordata = modsnapshot.data()
                if(moderatordata && moderatordata.ModerationPermissions){
                  userdata.ModerationPermissions = moderatordata.ModerationPermissions
                  //console.log(userdata.id)
                  payload.db.collection('groupmembers').doc(userdata.id).collection('groups').where('Status','==','Active').onSnapshot(groupssnapshot => {
                    //console.log('getting groupmembers collection',groupssnapshot.docs.length)
                        userdata.groups = []
                        let listlength = groupssnapshot.docs.length
                        if(listlength === 0){
                          if(!payload.RapidAppsDB){
                           commit('setUserObj', userdata)   
                           resolve(userdata) 
                          }
                          else{
                            commit('setRAUserObj', userdata)   
                            resolve(userdata) 
                          }
                          
                        }
                        else{
                          const changes = groupssnapshot.docChanges();
                          changes.forEach(change => {
                            if (change.type === 'added') {
                              let usergroupmembershipdata = {
                                ...change.doc.data(),
                                id: change.doc.id
                              }
                              if(usergroupmembershipdata.Administrator){
                                userdata.groups.push(usergroupmembershipdata)
                              }
                              else{
                                userdata.groups.push(usergroupmembershipdata)
                              }                              
                            }
                            if(userdata.groups.length === listlength){
                              if(!payload.RapidAppsDB){
                              commit('setUserObj', userdata)   
                              resolve(userdata)
                              }
                              else{
                                commit('setRAUserObj', userdata)   
                                resolve(userdata)
                              }
                            }
                          })
                        }
                    })
                   
                }
                else{
                  payload.db.collection('groupmembers').doc(userdata.id).collection('groups').onSnapshot(groupssnapshot => {
                    userdata.groups = []
                    let listlength = groupssnapshot.docs.length
                    if(listlength === 0){
                      if(!payload.RapidAppsDB){
                      commit('setUserObj', userdata)   
                      resolve(userdata)
                      }
                      else{
                        commit('setRAUserObj', userdata)   
                        resolve(userdata)
                      }
                    }
                    else{
                      const changes = groupssnapshot.docChanges();
                      changes.forEach(change => {
                        if (change.type === 'added') {
                          let usergroupmembershipdata = {
                            ...change.doc.data(),
                            id: change.doc.id
                          }
                          if(usergroupmembershipdata.Administrator){
                            userdata.groups.push(usergroupmembershipdata)
                          }
                          else{
                            userdata.groups.push(usergroupmembershipdata)
                          }                          
                        }
                        if(userdata.groups.length === listlength){
                          if(!payload.RapidAppsDB){
                          commit('setUserObj', userdata)   
                          resolve(userdata)
                          }
                          else{
                          commit('setRAUserObj', userdata)   
                          resolve(userdata)
                          }
                        }
                      })
                    }
                })
                }
              })         
                 
            })     
          })   
        })
    },
    AddEntityObj ({ commit }, payload) {
      commit('SetEntityObj', payload)
    },
    SetUserBUObj ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          db.collection('businessunits').doc(payload).onSnapshot(snapshot => {
            let budata = snapshot.data()
            budata.id = payload
            //console.log(budata)            
            commit('setUserBUObj', budata)    
            resolve('Completed setUserBUObj')        
          })   
        })
    },
    SetUserQueryPermission({ commit },payload) {
      //console.log('SetUserQueryPermission',payload)
        let setcollectionquery = {
          queryname : payload.collectionstate, 
          query: payload.query
        } 
        commit('setCollectionQuery', setcollectionquery) 
      
    },
    SetUserInteractPermission({ commit },payload) {
      //console.log('SetUserInteractPermission ',payload)
      let setcollectioninteract = {
        queryname : payload.collectionstate, 
        boolean: payload.boolean
      } 
      commit('setCollectionInteractPermission', setcollectioninteract) 
    
    },
    createUserDoc({ commit },payload) {
      commit('setStatus', 'loading')
      const userdoc = {
        Title: payload.Title,
        Name: payload.Name,
        Surname: payload.Surname,
        BusinessUnit: payload.BusinessUnit,
        EmployeeCode: payload.EmployeeCode,
        Position: payload.Position,
        Gender: payload.Gender
      }
      //console.log('userdoc')
      //console.log(userdoc)
      db.collection('users').add(userdoc).then({

      })
    },

    createSystemActivity({ commit },payload) {
      commit('setStatus', 'loading')
      const systemactivity = {
        creatorid: payload.user.id,
        creatorname: payload.user.Full_Name,
        post: `<a href="/SiteMember/`+payload.user.id+`">`+payload.user.Full_Name+`</a> `+payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        postvar: payload.contentvar+`<a href="`+payload.location+payload.docid+`">`+payload.docname+`</a>`,
        type: payload.type,
        createdon: new Date()
      }
      if(payload.user.Profile_Photo){        
        systemactivity.creatorimg = payload.user.Profile_Photo
      }
      if(payload.user.Company){
        //there is however another side to this coin....if the systemactivity is a "DUAL" activity like say a new friendship, then two companies apply
        //both companies would or should get notifications in fact
        systemactivity.Company = {Company_Name: payload.user.Company.Company_Name,id: payload.user.Companyid}
        systemactivity.Companyid = payload.user.Companyid
      }
      db.collection('systemactivities').add(systemactivity).then({

      })
    },
    signUpAction ({ commit }, payload) {
      commit('setStatus', 'loading')
      return new Promise(function(resolve, reject) {
      const functions = firebase.functions();
          const CreateUserviaSignup = functions.httpsCallable('CreateUserviaSignup');
          CreateUserviaSignup(payload).then(result => {
            resolve(result)
          })
        })
    },
    createNotification({ commit },payload) {
      commit('setStatus', 'loading')
      let NewNotification = {
        [payload.itemidprop]: payload.itemid,
        Type: payload.Type,
        Header: payload.Header,
        Content: payload.Content,
        CreatorFullName: payload.CreatorFullName,
        CreatorID: payload.CreatorID,
        Owner: payload.Owner,
        Ownerid: payload.Ownerid,
        Read: false,
        Path: payload.Path,
        Message: payload.Message,
        Date: new Date()
        }
        if(payload.ActiveProcessNumber){
          NewNotification.ActiveProcessNumber = payload.ActiveProcessNumber
        }
        if(payload.topicid){
          NewNotification.topicid = payload.topicid
        }
        if(payload.fileurl){
          NewNotification.fileurl = payload.fileurl
        }
        if(payload.IsLiaison){
          NewNotification.IsLiaison = payload.IsLiaison
          NewNotification.Liaisonid = payload.Liaisonid
        }
        if(payload.groupid){
          NewNotification.groupid = payload.groupid
        }
        db.collection('notifications').add(NewNotification).then(notedoc => {

        })
    },


    signUpbyAdminImportAction ({ commit }, payload) {
      return new Promise((resolve, reject) => {
       
        var config = {
  apiKey: "AIzaSyBizqYTsY9oNY_0U1L6evK9a22kjUyr9ps",
  authDomain: "rapidapps-7d6e7.firebaseapp.com",
  databaseURL: "https://rapidapps-7d6e7.firebaseapp.com",
  projectId: "rapidapps-7d6e7",
  storageBucket: "rapidapps-7d6e7.appspot.com",
  messagingSenderId: "674721806504",
  appId: "1:674721806504:web:4596bc44637e9f37eb6a9d",
  measurementId: "G-J7T6TGDTTB"
};
        // const secondaryApp = firebase.initializeApp(config, "Secondary");
        if (firebase.apps.length === 1) {
          let secondaryApp = firebase.initializeApp(config, "Secondary")
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email,  payload.password)
        .then((response) => {
          const userdocid = response.user.uid
          // alert('success')
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          //console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          //console.log('logging more from store before create')
          //console.log(response)
          resolve(response);
          db.collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email
          })
          db.collection('userroles').doc(userdocid).set({
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          //console.log('here is userdocid',userdocid)
          
          db.collection('systemactivities').add(systemactivity).then({
            
          })
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        else{
          let secondaryApp = firebase.apps[1]
          secondaryApp.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          // alert('success')
          const userdocid = response.user.uid
          const systemactivity = {
            creatorid: userdocid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+userdocid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          //console.log(systemactivity)
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.Profile_Photo
          }
          //console.log('logging more from store before create')
          //console.log(response)
          resolve(response);
          db.collection('users').doc(userdocid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          
            
        }).catch((error) => {
       
          alert(error.message)
          reject(error);
        })
        }
        
      
      })
    },

    signUpbyAdminAction ({ commit }, payload) {
      commit('setStatus', 'loading')
      firebase.auth().createUserWithEmailAndPassword(payload.Email, payload.password)
        .then((response) => {
          alert('success')
          const userdocid = response.user.uid
          db.collection('users').doc(response.user.uid).set({
            Name: payload.Name,
            Surname: payload.Surname,
            Full_Name: payload.Full_Name,
            Gender: payload.Gender,
            Title: payload.Title,
            Email: payload.Email,
            SubordinateArrayDBrules:{[userdocid]: true},
            Subordinatearrayquery: [userdocid]
          })
          const systemactivity = {
            creatorid: response.user.uid,
            creatorname: payload.Full_Name,
            post: `<a href="/User/`+response.user.uid+`">`+payload.Full_Name+`</a> joined RapidApps`,
            type: `New User`,
            createdon: new Date()
          }
          if(payload.Profile_Photo){        
            systemactivity.creatorimg = payload.user.Profile_Photo
          }
          db.collection('systemactivities').add(systemactivity).then({
    
          })
        })

        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },

    signInAction ({ commit }, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then((response) => {
          commit('setUser', response.user.uid)
          commit('setStatus', 'success')
          commit('setError', null)
          db.collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = response.user.uid
            //console.log(userdata)
            commit('setUserObj', userdata)
          })
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    },


    signOutAction ({ commit }) {
      firebase.auth().signOut()
        .then((response) => {
          commit('setUser', null)
          commit('setStatus', 'success')
          commit('setError', null)
        })
        .catch((error) => {
          commit('setStatus', 'failure')
          commit('setError', error.message)
          alert(error.message)
        })
    }
  },

  getters: {
    GymSessionsArray(state){
      return state.GymSessionsArray
    },
    getRequiredInformation(state){
      return state.UserRequiredInfo
    },
    getArrayByDynamic: (state) => (ArrayName) => {
      return state[ArrayName];
    },
    getFriendshipsArray(state){
      return state.FriendshipsArray
    },
    getRMDocumentsArray(state){
      return state.RMDocumentsArray
    },
    
    getBusinessUnitsArray(state){
      return state.BusinessUnitsArray
   },
    getUsersArray(state){
      return state.Users
   },
    userBUID (state) {
      return state.userobj.Business_Unitid
    },
    userBUChildren (state) {
      return state.buobj.childarrayQuery
    },
    userBUParents (state) {
      return state.buobj.childarrayQuery
    },
    status (state) {
      return state.status
    },

    user (state) {
      return state.userobj
    },

    error (state) {
      return state.error
    }
  }
})

  