<template>
  <div>
    <v-btn @click="ActiveConfigPath = ''" v-if="ActiveConfigPath">Back</v-btn>
     <component :is="ActiveConfigComponent" v-if="ActiveConfigPath"
     :CategoricalNavlists="CategoricalNavlists" :RADB="RADB"
            :MenuItem="ActiveAppComponent" :UsersArray="UsersArray" :ChildComponent="ActiveAppComponent" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
            :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
            :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveAppComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications" :SystemGuides="SystemGuides"
            :GroupLandingPageViews="GroupLandingPageViews" :System="System" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin" :SitePages="SitePages"
            :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SubscriptionPackages="SubscriptionPackages"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries" @GoBack="GoBack" :SiteModerators="SiteModerators"
    />
      <v-card elevation="0" class="siteconfigcard" v-if="CurrentModuleActive && SuiteBuilderView && !ActiveConfigComponent">
        <v-card-title>
          <span  class="mediumoverline">
          {{ModuleName}}
          </span>
      </v-card-title>
      <v-card-text>
        <!--  -->
      <v-tabs style="margin-top: 20px;" centered>
          <v-tab>Overview</v-tab>
          <v-tab-item>
              <RAModulePlugin v-if="Module.Module_Category && Module.Module_Category.Name === 'Module'"
        :System="System" :SystemEntities="SystemEntities" :Module="Module" @ActivateActiveConfigPath="ActivateActiveConfigPath"
        /> 
          </v-tab-item>
          <v-tab>Validator</v-tab>
          <v-tab-item>
              <ModuleValidator :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
              :Module="Module" :SystemEntities="SystemEntities" :CategoricalNavlists="CategoricalNavlists" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>
          </v-tab-item>
      </v-tabs>
      
      </v-card-text>
      </v-card>
      <div v-if="!CurrentModuleActive || !SuiteBuilderView">
          <RAModulePlugin v-if="Module.Module_Category && Module.Module_Category.Name === 'Module'"
        :System="System" :SystemEntities="SystemEntities" :Module="Module"
        />  
      </div>
      <div v-if="CurrentModuleActive && Module.id === 'Database_Builder' && !ActiveConfigComponent">
         <ModuleValidator :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
              :Module="Module" :SystemEntities="SystemEntities" :CategoricalNavlists="CategoricalNavlists" :SitePages="SitePages"
                :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>  
      </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import RAModulePlugin from '@/components/SuiteBuilder/Main/RAModulePlugin';	
import ModuleValidator from '@/components/SuiteBuilder/Main/ModuleValidator'
export default {
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries','SiteModerators','SubscriptionPackages',
    ,'Module','SitePages','MyActivitiesArray','MyGoals','MyGoalsisEmpty','RADB','CategoricalNavlists','Notifications','SystemGuides','FrequentlyAskedQuestions'],
    components: {RAPluginCarousel,RAModulePlugin,ModuleValidator},
    data() {
        return {
            ActiveConfigPath: '',
            SingleRecordNavMenuItem: {
            Icon: 'mdi-alert',
            DisplayName: 'Alerts',
            Name: 'Alerts',
            DataViewName: 'Data Single Dashboard',
            SystemDashboard: true,
            ShowComponents: true,
            DashboardType: 'Alerts', 
            PreDialog: true,
            IMG: require('@/assets/logo.png'),
            Writeup: `<p>The Alerts Section serves as a configuration required for both published and unpublished changes.</p>`,          
            Elements: [],
            Children: [
              {
            Icon: 'mdi-database',
            DisplayName: 'Database Module',
            Name: 'Database Module',
            SystemDashboard: true,
            DashboardType: 'Database Module'
          },
          {
            Icon: 'mdi-share-variant',
            DisplayName: 'Social Module',
            Name: 'Social Module',
            SystemDashboard: true,
            DashboardType: 'Social Module'
          },
          {
            Icon: 'mdi-search-web',
            DisplayName: 'Website Module',
            Name: 'Website Module',
            SystemDashboard: true,
            DashboardType: 'Website Module'
          },
           
            ]
          },
            SystemGuides: [],
          RefreshingPlugins: false,
            SelectedModule: '',
            systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
        }
    },	
    computed:{
      ActiveConfigComponent(){
        if(this.ActiveConfigPath){          
         return () => import(`@/`+this.ActiveConfigPath+`.vue`);
        }
      },
        RADB(){
            return this.$store.state.RADB
        },
        RAApp(){
            return this.$store.state.RAApp
        },
        ActiveDB(){
            return db
        },
      SelectedModulePlugins(){
          let plugins = []
          if(this.Module){
            if(this.Module.Name === 'Database Builder'){
              return this.RAPluginsMatched(['Database Builder','DBB'])
            }
            else if(this.Module.Name === 'Social Network Builder'){
              return this.RAPluginsMatched(['Social Network Builder','SNB'])
            }
            else if(this.Module.Name === 'Website Builder'){
              return this.RAPluginsMatched(['Website Builder','WB'])
            }
            else if(this.Module.Name === 'Documentation Builder'){
              return this.RAPluginsMatched(['Documentation Builder','DBB'])
            }
          }
          return plugins
        },
      RelevantPlugins(){
        return this.SelectedModulePlugins
      },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
      BuilderView(){
    return this.$store.state.BuilderView
    },
        WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
        },
        SuiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Suite Builder'
        },
        DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
        },
        NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
        },
      CurrentModuleActive(){
        //return false
        return this.PluginDataBase[this.ModuleName.split(' ').join('_')] && this.PluginDataBase[this.ModuleName.split(' ').join('_')].Active
      },
      NoActiveModule(){
        return !this.WebsiteisActive && !this.SocialNetworkisActive && !this.DatabaseisActive && !this.DocumentationisActive
      },
      WebsiteisActive(){
        return this.$store.state.WebsiteisActive
      },
      SocialNetworkisActive(){
        return this.$store.state.SocialNetworkisActive
      },
      DatabaseisActive(){
        return this.$store.state.DatabaseisActive
      },
      DocumentationisActive(){
        return this.$store.state.DocumentationisActive
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ModuleName(){
            return this.Module.Name
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
      GoBack(path){
        this.ActiveConfigPath = path
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      ActivateActiveConfigPath(path){
        this.ActiveConfigPath = path
        this.ScrolltoTop()
      },
      SelectBuilderView(Module){
        this.$store.commit('setBuilderView',{Name: Module.Name})
      },
      RAPluginsMatched(categorymatches){
         let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        })
      },
      RefreshPlugins(){
        this.RefreshingPlugins = true
        setTimeout(() => {
          this.RefreshingPlugins = false
        }, 10);
      },
        DeslectModule(){
        this.SelectedModule = ''
        this.RefreshPlugins()
        },
        ActivateModulePlugins(item){
            this.SelectedModule = item
            this.RefreshPlugins()
            
        },
        PickPlugin(plugin){
          this.EmitMarketDialog(plugin)
        },
        EmitMarketDialog(SelectedPlugin,prop){
        console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
      },
    }
}
</script>

<style>

</style>



