<template>
  <v-main style="margin-top:60px;" class="SoftBuilderGradient">
    <v-dialog v-model="GreetingDialog" max-width="500">
      <v-card>
         <v-card-title class="subtleoverline justify-center" style="font-weight: 400;font-size: 18px !important;">Welcome to your Suite!
        </v-card-title>
        <v-card-text>
          <!-- <v-img height="150" contain src="@/assets/RAFullLogo.png"/> -->
          <!-- <v-img height="150" contain src="https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/LandingPagesShare%2Fwebsite-loading-speed-optimisation-vector-illustration-5de2455056206.png?alt=media&token=39ac7c7a-c899-4cd1-ba7b-269ad58d3962"/> -->
          <v-img height="150" contain src="https://firebasestorage.googleapis.com/v0/b/rapidapps-7d6e7.appspot.com/o/Adhoc%2Fpartypopperthing.png?alt=media&token=476286e3-1c2d-47ff-9678-52cdcca6499b"/>
        </v-card-text>
        <v-card-text style="font-weight: 400;">
          We have created this view for you to familiarize your self with the Suite Builder.
          We know that you are excited and we are just as excited with you to get started, but familirization with your Suite Builder is key to maximizing your investment with us.          
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn :to="'/Documentation/Suite_Builder/Introduction'" outlined dark color="pink">
              Read More</v-btn>
            <v-btn @click="DeactivateGreetingDialog()" outlined dark color="purple">
              Not Now</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
<v-row class="text-center" style="margin-top: -100px;" v-if="OldStyle">
      <v-col
        class="mb-5"
        cols="12"
      >
          <v-img height="200" contain src="@/assets/RAFullLogo.png"/>
           <div class="RACubes" style="z-index: -10;position: absolute;">
            <div class="cube">
              <div class="cube__face cube__face--front"></div>
              <div class="cube__face cube__face--back"></div>
              <div class="cube__face cube__face--right"></div>
              <div class="cube__face cube__face--left"></div>
              <div class="cube__face cube__face--top"></div>
              <div class="cube__face cube__face--bottom"></div>
              
              <div class="square" style="margin-left:-0px;">
                <div style="margin-top:-0px;" class="one"></div>
                <div style="margin-top:-0px;" class="two"></div>
                <div style="margin-top:-0px;" class="three"></div>
                <div style="margin-top:-0px;" class="four"></div>
             </div>
            </div>
          </div>
      </v-col>
        
         <!-- <v-layout row justify-center> -->
             <v-col class="mb-4" style="margin-top:20px;">
             <h1 class="display-2 font-weight-bold mb-3">
          Welcome to your Suite
        </h1>

        <p class="subheading font-weight-regular">
          For help and collaboration with other RapidApps developers,
          <br>please join our online
          <a
            href="https://www.rapidapps.co.za"
            target="_blank"
          >Community</a>
        </p>
             </v-col>
        <!-- </v-row> -->
         <!-- </v-layout> -->
        <!-- <v-layout row justify-center> -->
         
        <!-- </v-layout>
    <v-row class="text-center">
     -->
      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          {{SelectedModule ? SelectedModule.Name+' Plugins' : 'The Modules'}}
        </h2>
        <v-row justify="center" v-if="SelectedModule">
          <v-btn dark @click="DeslectModule()" outlined class="purple">
            Back (All Modules)
          </v-btn>
        </v-row>
        <v-row justify="center">
          <v-card-text class="BuilderGradient">
            <RAPluginCarousel :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="RelevantPlugins" :tab="systemguideelmnt" @ActivateModulePlugins="ActivateModulePlugins"
            :ListPreviewOnly="true" v-if="!RefreshingPlugins"
              />
          </v-card-text>
        </v-row>
      </v-col>
      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Learn More?
        </h2>

        <v-row justify="center">
          <v-card>
            <v-card-text>
              A more concise information page about this section exist in the Suite Builder Documentation
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn :to="'/Documentation/Suite_Builder/Introduction'" outlined dark class="BuilderGradient">
                Go there now
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
    <v-row  class="justify-center" v-if="!OldStyle">
      
      <div style="padding: 30px;" class="flex xl12 lg12 md12 sm12 xs12">
        <SuiteMainHome v-if="SuiteBuilderNavItem.title === 'Home'"
        :System="System" :SystemEntities="SystemEntities"
        />   
        <SuiteStatistics v-if="SuiteBuilderNavItem.title === 'Statistics'"
        :System="System" :SystemEntities="SystemEntities"
        />  
        <!-- <AppOrderingSystems v-if="SuiteBuilderNavItem.title === 'Goods & Ordering'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <AppDirectories v-if="SuiteBuilderNavItem.title === 'Directories & Personals'"
        :System="System" :SystemEntities="SystemEntities"
        />
        <AppServices v-if="SuiteBuilderNavItem.title === 'Service Apps'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <AppTeamPlugins v-if="SuiteBuilderNavItem.title === 'Team'"
        :System="System" :SystemEntities="SystemEntities"
        /> -->
        <FeaturedPluginMain v-if="SuiteBuilderNavItem.TypeFilter"
        :System="System" :SystemEntities="SystemEntities" :SuiteBuilderNavItem="SuiteBuilderNavItem"
        /> 
        <SuiteSettings v-if="SuiteBuilderNavItem.title === 'Settings'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <SuiteSecurity v-if="SuiteBuilderNavItem.title === 'Security'"
        :System="System" :SystemEntities="SystemEntities"
        />
        <SuiteLibraries v-if="SuiteBuilderNavItem.title === 'Libraries'"
        :System="System" :SystemEntities="SystemEntities"
         :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
        /> 
         <SuiteWebForms v-if="SuiteBuilderNavItem.title === 'Web Forms'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <SuiteSubscriptions v-if="SuiteBuilderNavItem.title === 'Subscriptions'"
        :System="System" :SystemEntities="SystemEntities"
        /> 
        <SuiteMembers v-if="SuiteBuilderNavItem.title === 'Members'" :DBNavLists="DBNavLists"
        :System="System" :SystemEntities="SystemEntities" :SystemTableFilters="SystemTableFilters"
        />
        
        <SuiteModule v-if="SuiteBuilderNavItem.Module_Category && SuiteBuilderNavItem.Module_Category.Name === 'Module'" :SitePages="SitePages"
          :Module="SuiteBuilderNavItem" :CategoricalNavlists="CategoricalNavlists" :SystemDocumentLibraries="SystemDocumentLibraries"
         :UsersArray="UsersArray" :ChildComponent="ActiveAppComponent" @PickPlugin="PickPlugin" @NewDashboardDialog="NewDashboardDialog"
          @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" :MyGoals="MyGoals" :SystemEntities="SystemEntities" :GroupData="GroupData"
          :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :MyActivitiesArray="MyActivitiesArray"
          :TeamView="true" :ThemeColor="ThemeColor" :ActiveSession="ActiveAppComponent" :ThemeHeaderColor="ThemeHeaderColor" :Notifications="Notifications" :SystemGuides="SystemGuides"
          :GroupLandingPageViews="GroupLandingPageViews" :System="System" :UserisTeamMember="UserisTeamMember" :GroupAdmin="GroupAdmin"
          :AppisDarkMode="RenderDark" :NotificationObj="NotificationObj" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
          :SystemPhotoLibraries="SystemPhotoLibraries" :SiteModerators="SiteModerators" :SubscriptionPackages="SubscriptionPackages"
        />  
    </div>
    </v-row>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RAPluginCarousel from '@/components/WebPages/RenderComponents/RAPluginCarousel';
import SuiteSettings from '@/components/SuiteBuilder/Main/SuiteSettings';
import SuiteMembers from '@/components/SuiteBuilder/Main/SuiteMembers';	
import SuiteSecurity from '@/components/SuiteBuilder/Main/SuiteSecurity';
import SuiteSubscriptions from '@/components/SuiteBuilder/Main/SuiteSubscriptions';	
import SuiteMainHome from '@/components/SuiteBuilder/Main/SuiteMainHome';	
import SuiteLibraries from '@/components/SuiteBuilder/Main/SuiteLibraries';
import SuiteWebForms from '@/components/SuiteBuilder/Main/SuiteWebForms';
import SuiteStatistics from '@/components/SuiteBuilder/Main/SuiteStatistics';	
import AppDirectories from '@/components/SuiteBuilder/FeaturedPlugins/AppDirectories';
import AppServices from '@/components/SuiteBuilder/FeaturedPlugins/AppServices';
import AppTeamPlugins from '@/components/SuiteBuilder/FeaturedPlugins/AppTeamPlugins';
import AppOrderingSystems from '@/components/SuiteBuilder/FeaturedPlugins/AppOrderingSystems';	
import FeaturedPluginMain from '@/components/SuiteBuilder/FeaturedPlugins/FeaturedPluginMain';	
import SuiteModule from '@/components/SuiteBuilder/Main/SuiteModule';	
export default { 
    props: ['System','SystemEntities','SystemPhotoLibraries','SystemDocumentLibraries','SiteModerators','SubscriptionPackages','SystemTableFilters','DBNavLists',
    ,'Module','SitePages','MyActivitiesArray','MyGoals','MyGoalsisEmpty','RADB','CategoricalNavlists','Notifications','SystemGuides','FrequentlyAskedQuestions'],
    components: {RAPluginCarousel,SuiteMainHome,SuiteLibraries,SuiteWebForms,SuiteSettings,SuiteSubscriptions,SuiteMembers,SuiteSecurity,SuiteStatistics,SuiteModule,
    AppOrderingSystems,AppDirectories,AppServices,AppTeamPlugins,FeaturedPluginMain},
    data() {
        return {
          OldStyle: false,
          SuiteBuilderNavItem: '',
          SelectedModule: '',
          RefreshingPlugins: false,
          systemguideelmnt: {			
              HideGuidesCarousel: false,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
          GreetingDialog: true,
        }
    },	
    computed:{
      AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView || this.DocLibBuilderView || this.$route.name === 'GettingStarted'
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    PluginsPath(){
      return this.$store.state.PluginsPath
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    SuiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Suite Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
      RelevantPlugins(){
        return this.SelectedModule ? this.SelectedModulePlugins : this.ModulePlugins
      },
        ModulePlugins(){
          return this.RAPluginsMatched(['Module'])
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        UnPaidPlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && !this.PluginDataBase[plug.id].Active
          })
      },
      PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
      PurchasablePlugins(){
          return this.RAPlugins.filter(plug => {
              return !this.PluginDataBase[plug.id] && plug.Strictly_One_Purchase || !plug.Strictly_One_Purchase
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
        ComputedRAPlugins(){
            return this.RAPlugins.map(guide => {
                guide.elmnt = Object.assign({},this.systemguideelmnt)
                guide.elmnt.Description = guide.Description
                return guide
            })
        },
        SelectedModulePlugins(){
          let plugins = []
          if(this.SelectedModule){
            if(this.SelectedModule.Name === 'Database Builder'){
              return this.RAPluginsMatched(['Database Builder','DBB'])
            }
            else if(this.SelectedModule.Name === 'Social Network Builder'){
              return this.RAPluginsMatched(['Social Network Builder','SNB'])
            }
            else if(this.SelectedModule.Name === 'Website Builder'){
              return this.RAPluginsMatched(['Website Builder','WB'])
            }
            else if(this.SelectedModule.Name === 'Documentation Builder'){
              return this.RAPluginsMatched(['Documentation Builder','DBB'])
            }
          }
          return plugins
        }
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['SelectSuiteBuilderNavItem']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      this.ScrolltoTop()
      if(this.DBBuilderView){
        this.SuiteBuilderNavItem = {title: 'Database Builder'}
        this.$store.commit('setBuilderView',{Name: 'Suite Builder'})
      }
      else{
        this.SuiteBuilderNavItem = {title: 'Home'}
        this.$store.commit('setBuilderView',{Name: 'Suite Builder'})
      }
      
      
    },
    methods:{
      SelectSuiteBuilderNavItem(item){
        console.log(item)
        this.SuiteBuilderNavItem = item
        this.ScrolltoTop()
      },
      RefreshPlugins(){
        this.RefreshingPlugins = true
        setTimeout(() => {
          this.RefreshingPlugins = false
        }, 10);
      },
      DeslectModule(){
        this.SelectedModule = ''
        this.RefreshPlugins()
      },
      ActivateModulePlugins(item){
        this.SelectedModule = item
        this.RefreshPlugins()
        
      },
      ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
      RAPluginsMatched(categorymatches){
        let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.PurchasablePlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          || plug.Module_Category.Name === 'General'
        })
      },
      DeactivateGreetingDialog(){
        this.GreetingDialog = false
        // this.$store.commit('setAppBuilderHelperActive',true)
        // this.$store.commit('setAppBuilderHelperStep',1)
      }
    }
}
</script>

<style>

</style>
