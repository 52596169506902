
<template>
    <v-row
    class="fill-height ma-0 cloudsbgcolor"
    align="center"
    justify="center">
    <div class="moving-clouds" 
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <div class="moving-clouds2" 
        style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <v-card
            class="mx-auto"
            max-width="500"
            min-width="350"            
            >
            <v-img
            
            src="@/assets/RABaseBG-Soft.png"
            width="350">
            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="pop lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="windowstep"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="windowstep">
                <v-window-item :value="1">
                    <v-card-text>
                    <v-text-field
                        label="Email"
                        v-model="UserEmail"
                        disabled
                    ></v-text-field>              
                    <span class="caption grey--text text--darken-1">
                        The password reset is for the above email. Please verify this is correct
                    </span>
                    </v-card-text>
                </v-window-item>
               
                <v-window-item :value="2">
                    <v-card-text>
                    <v-text-field
                        label="Password"
                        type="password"
                        v-model="password"
                    ></v-text-field>
                    <v-text-field
                        label="Confirm Password"
                        type="password"
                        v-model="rePassword"
                        :rules="[rules.required, rules.min8Chars, passwordConfirmationRule]"
                    ></v-text-field>
                    <span class="caption grey--text text--darken-1">
                        Please enter a password for your account
                    </span>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                    <div class="pa-4 text-center">
                    <v-img
                        class="mb-4"
                        contain
                        height="128"
                        src="@/assets/logo.png"
                    ></v-img>
                    <h3 class="title font-weight-light mb-2">
                        Welcome to {{System.Name}}
                    </h3>
                    <span class="caption grey--text">Thank you!</span><br>
                    <span class="caption grey--text">An Email will be sent shortly, to confirm the password reset process status. You may close the page</span>
                    </div>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                         
                <v-btn
                    :disabled="windowstep === 1 || windowstep === 3"
                    text
                    @click="windowstep--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                v-if="StepProgressTitle !== 'Change Password' || StepProgressTitle === 'Change Password' && this.password.length>1 && this.password === this.rePassword"
                    dark
                    :disabled="windowstep === 3"
                    color="pop"
                    depressed
                    @click="ProgressWindow()"
                >
                
                    {{StepProgressTitle}}
                </v-btn>
                </v-card-actions>
            </v-img>
            </v-card> 
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['System'],
    data(){
        return{
            UserEmail: '',
            Userid: '',
            password: '',
            rePassword: '',
            rules: {
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            windowstep: 1
        }
    },
    computed:{
        currentTitle () {
        switch (this.windowstep) {
          case 1: return 'Confirm Email'
          case 2: return 'Choose a Password'
          default: return 'Reset Completed'
            }
        },
        StepProgressTitle(){
            return this.windowstep === 2 ? 'Change Password' : 'Next'
        },
        passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },
    },
    created(){
        this.GetPasswordResetRequest()
    },

    methods:{
        ProgressWindow(){
            if(this.windowstep === 1){
                this.windowstep = 2
            }
            else if(this.windowstep === 2){
                this.ResetPassword()
            }
        },
        GetPasswordResetRequest(){
            db.collection('passwordresets').doc(this.$route.params.id).onSnapshot(snapshot => {
                let passwordresetdata = snapshot.data()
                console.log(passwordresetdata)
                if(typeof passwordresetdata === 'undefined' || passwordresetdata.step === 2){
                    this.$router.push('/Login')
                }
                else{
                    this.UserEmail = passwordresetdata.Email
                    this.Userid = passwordresetdata.Userid

                }
                let ExpiryDate = new Date()
                
                console.log(ExpiryDate) 
                
                if(passwordresetdata.Expired){
                    let LinkedfromObj = {
                        Type: "Password Link",
                        id: this.$route.params.id,
                        Created_On: passwordresetdata.Created_On.toDate(),
                        ExpiryDate: ExpiryDate,
                        CanAction: true,
                        ActionText: 'You can always Request another Password Reset?',
                        ActionLink: '/Login'
                    }
                    console.log(LinkedfromObj)
                    this.$router.push({ name: 'ExpiredLink', params: {LinkedfromObj: LinkedfromObj }})
                }
                
            })
        },
        ResetPassword(){
            const functions = firebase.functions();
            const ResetUserPasswordbyRequest = functions.httpsCallable('ResetUserPasswordbyRequest');
            let EmailBody = `Good Day<div><br></div><div>Your RapidApps Account password has been reset on `+new Date()+`. </div><div>If this was not you, then please contact your System Administrator immediately.</div><div><br></div>
            <div>Thank You</div>`
            let ResetData = {
                ticketid: this.$route.params.id,
                userid: this.Userid,
                Email: this.UserEmail,
                EmailBody: EmailBody,
                newpassword: this.password 
            }
            ResetUserPasswordbyRequest(ResetData).then(result => {
                console.log('supposedly updated and notified user')
                console.log(result);
                }).catch(error => {
                console.log("error",error);
                });

        }
    }
}
</script>

<style>
.moving-clouds {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.moving-clouds2 {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c2 80s linear infinite;


}
@keyframes c2 {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
</style>


  