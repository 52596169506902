
<template>

  
      <v-card flat :class="AppisDarkModeCard" style="margin-top:50px;">
     <v-row
        class="fill-height"
        align="center"
      >
      <v-list class="transparent">
        <v-btn v-if="userIsAdmin" x-small @click="ShowDelete = !ShowDelete">{{ShowDelete? 'Hide Delete' : 'Show Delete'}}</v-btn>
          <v-list-item v-for="sysact in filteredsystemactivities.slice(0,Excerpt)"
          :key="sysact.id">
          <!-- <v-icon @click="Wipeactivity(sysact)" color="white">mdi-delete-forever</v-icon> -->

          <v-list-item-avatar>
              <img v-if="sysact.creatorimg"
                :src="sysact.creatorimg"
                :alt="sysact.creatorname"
                style="object-fit: cover;"
            >
            <img v-if="!sysact.creatorimg"
                src='@/assets/BlankProfilePic.png'
                :alt="sysact.creatorname"
            >
            
          </v-list-item-avatar>
            <!-- <v-list-item-content >
            <v-list-item-title v-html="sysact.post" style="font-size:10px"></v-list-item-title>                    
            <v-list-item-subtitle style="font-size:10px">
              {{sysact.createdon}}
            </v-list-item-subtitle>
             </v-list-item-content> -->
            <v-list-item-content v-html="sysact.post" style="font-size:10px">              
            </v-list-item-content>
            <v-list-item-content style="font-size:10px" v-if="sysact.createdon">
              {{sysact.createdon}}              
            </v-list-item-content> 
            <v-list-item-actions v-if="userIsAdmin && ShowDelete">
              <v-menu v-model="sysact.DeletePostDialog" max-width="300">
               <template v-slot:activator="{ on }">               
                <v-icon v-if="userIsAdmin && ShowDelete" v-on="on" x-small>mdi-delete-forever</v-icon>
               </template>
               <v-card>
                 <v-card-text>
                   Are you sure you want to delete this activity entirely?
                 </v-card-text>
                 <v-card-actions>
                   <v-btn dark plain outlined class="warning" @click="sysact.DeletePostDialog = !sysact.DeletePostDialog">Cancel</v-btn>
                   <v-spacer></v-spacer>
                   <v-btn dark plain outlined class="error" @click="Wipeactivity(sysact)">Delete</v-btn>
                 </v-card-actions>
               </v-card>
              </v-menu>
            </v-list-item-actions>
          </v-list-item>
      </v-list>       
      </v-row>
      </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['clientDB','Description','Excerpt','Filter','HasFilter','AppisDarkModeCard','AppisDarkMode','UserView','UserID'],
    middleware: 'auth',
    data () {
        return {
          ShowDelete: false,
          
        CollectionRef: db.collection('systemactivities'),
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            systemactivities: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {},           

        }
    },
    computed: {
      CollectionQuery(){
        let ref = this.CollectionRef
        if(this.CompanyOnlyFilter){
          ref = ref.where('Companyid','==',this.UserCompanyid)
        }
        return ref
      },
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    UsersStore(){
      return this.$store.state.UsersArray
    },
    filteredsystemactivities() {
      
      return this.systemactivities.filter(sysact => {
          if(this.HasFilter){
          return this.Filter.includes(sysact.type)
        }
          else{
            return sysact
          }
      }).map(act => {
        if(typeof act.createdon.toDate !== 'undefined'){
          act.createdon = this.TimestampFormatterSTRING(act.createdon.toDate())
        }
        return act
      }).sort((a, b) => {
            var key1 = b.createdon;
            var key2 = a.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      }).map(act => {
        let userobj = this.UsersStore.find(obj => obj.id === act.creatorid)
        if(userobj && userobj.Profile_Photo){
          act.creatorimg = userobj.Profile_Photo
        }
        return act
      })
    
    },
    // FilterComputed(){
    //     return this.Filter.map(filter => {
    //         return filter.Name
    //     })
    // }
    },


    created() {
      // this.$store.dispatch('GetUserStateChange')
      //console.log(this.Filter)
      if(this.UserView){
        this.getSystemActivities(this.CollectionQuery.where('creatorid','==',this.UserID))
      }
      else{
        this.getSystemActivities(this.CollectionQuery)
      } 
    
    // this.FocusedViewToggle()
    //   this.IntranetViewToggle()
    // //   this.GetRequestingUser()
},
    methods: {

      Wipeactivity(sysact){
        let vm = this
        let index = this.systemactivities.indexOf(sysact)
        db.collection('systemactivities').doc(sysact.id).delete().then(doc => {
          console.log('succesfully deleted '+sysact.id)
          vm.systemactivities.splice(index,1)
        })
      },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
         getSystemActivities(colref) {
           colref.onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.systemactivities.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
      })
    })

    },
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    // var storageRef = firebase.storage()('Group_Logos/' + this.selectedFile.name);
                    // var uploadTask = storageRef.put(this.selectedFile);
                    //   uploadTask
                    //   .then(snapshot => snapshot.ref.getDownloadURL())
                    //     .then((url) => {     
                    this.name = this.editedItem.Name
                    this.Description = this.editedItem.Description
                    this.user = this.$store.state.user.id
                    this.GroupCategory = this.editedItem.GroupCategory
                    // this.FileURL = url

                        
                        const sysact = { 
                              name: this.name,
                                Description: this.Description,
                                Created_By: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Created_Byid: this.UserRecord.id,
                                Created_On: new Date(),
                                GroupCategory: this.GroupCategory,
                                GroupPrivacy: 'Public',
                                GroupPrivacyIcon: 'mdi-lock',
                                GroupPublishStatus: 'Draft',
                                GroupPublishStatusIcon: 'mdi-eye-off',
                                EventsPrivacy: 'Public',
                                BlogPrivacy: 'Public',
                                MembersPrivacy: 'Public',
                                NewsPrivacy: 'Public',
                                ForumPrivacy: 'Public',
                                // logo: this.FileURL
                                
                            }
                            const groupmemberscollection =   db.collection('groupmembers')
                            let vm = this
                        db.collection('systemactivities').add(sysact).then(function(doc) {
                          console.log(doc.id)
                           const GroupMember = { 
                            Created_On: new Date(),
                            Userid: sysact.Created_By.id,
                            Groupid: doc.id,
                            UserRole: 'Administrator'
                            
                            }
                            groupmemberscollection.add(GroupMember)
          vm.$router.push('/Group/'+GroupMember.Groupid)
                            })
                    this.Closedialog()
                    
                          // }),                         
                        
                   
                            this.dialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}

</style>
    