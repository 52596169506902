<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
             <v-list-item>
                                 <v-switch @change="PrepareForPanels(EditedTab)" v-model="EditedTab.WrapInExpansionPanel" label="Wrap In Expansion Panel"/>                                
                            </v-list-item> 
                            <v-list  v-if="!EditedTab.WrapInExpansionPanel">
                          <v-list-item>
                              <v-chip-group v-if="!DynamicWikiData"
                                    active-class="primary--text"
                                    column
                                >
                                <v-chip
                                    @click="ActivateNewFieldDialog(field,true,EditedTab)"
                                    small  v-for="field in BasicFieldTypes"
                                    :key="field.itemObjKey"
                                    >
                                    {{field.Type}}
                                    </v-chip>                           
                                </v-chip-group>
                                <v-select v-if="DynamicWikiData" multiple @change="AssignDescriptionList(EditedTab)" v-model="EditedTab.ListItems" :items="EntityFieldsandFeatures" label="List Items" return-object item-text="DisplayName"/>                       
                            </v-list-item>  
                            <v-list-item v-for="listitem in EditedTab.ListItems" :key="listitem.itemObjKey">
                                <v-list-item-content>
                                    {{listitem.DisplayName}}
                                </v-list-item-content>
                                <!-- <v-list-item-action v-if="!DynamicWikiData">
                                   <v-menu 									
                                    :close-on-content-click="false"                            									
                                    transition="scale-transition"                            									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                        <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                        </template>	
                                        <v-card width="400">
                                         
                                            <v-card-text>
                                              <SingleTabFieldsSection
                                                    @UpdateEditableField="UpdateEditableField"
                                                :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                                                :sectionindex="''" :tabindex="''" :SampleOnly="true"
                                                :PrimaryFieldName="''" :SingleFormEditing="false"
                                                :Record="listitem.Record" :AdditionalSaveMethod="AdditionalSaveMethod"
                                                :section="''" :tab="''" :Fields="[listitem]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                                                />
                                            </v-card-text>
                                        </v-card>
                                   </v-menu>
                                </v-list-item-action> -->
                            </v-list-item>
                            </v-list>
                            <v-list v-if="EditedTab.WrapInExpansionPanel">
                                <v-btn @click="AddListPanels(EditedTab)">Add Panel</v-btn>
                              
                             <v-list-item  v-for="(panel,panelindex) in EditedTab.Panels"
                                :key="panel.itemObjKey">
                                  <v-text-field :rules="[rules.required]" v-model="panel.Title" label="Title"></v-text-field> 
                                  <v-menu 									
                                    :close-on-content-click="false"                            									
                                    transition="scale-transition"                            									
                                    >									
                                    <template v-slot:activator="{ on }">									
                                        <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                        </template>	
                                        <v-card>
                                            <v-card-title>List Items</v-card-title>
                                            <v-card-text>
                                                <v-chip-group v-if="!DynamicWikiData"
                                                        active-class="primary--text"
                                                        column
                                                    >
                                                    <v-chip
                                                        @click="ActivateNewFieldDialog(field,true,panel)"
                                                        small  v-for="field in BasicFieldTypes"
                                                        :key="field.itemObjKey"
                                                        >
                                                        {{field.Type}}
                                                        </v-chip>                           
                                                    </v-chip-group>
                                                        <v-list-item v-for="listitem in panel.ListItems" :key="listitem.itemObjKey">
                                                            <v-list-item-content>
                                                                {{listitem.DisplayName}}
                                                            </v-list-item-content>
                                                            <!-- <v-list-item-action v-if="!DynamicWikiData">
                                                            <v-menu 									
                                                                :close-on-content-click="false"                            									
                                                                transition="scale-transition"                            									
                                                                >									
                                                                <template v-slot:activator="{ on }">									
                                                                    <v-btn fab icon v-on="on"><v-icon>mdi-cogs</v-icon></v-btn>									
                                                                    </template>	
                                                                    <v-card width="400">
                                                                    
                                                                        <v-card-text>
                                                                        <SingleTabFieldsSection
                                                                                @UpdateEditableField="UpdateEditableField"
                                                                            :SystemEntities="SystemEntities" :RelatedObj="RelatedObj"
                                                                            :sectionindex="''" :tabindex="''" :SampleOnly="true"
                                                                            :PrimaryFieldName="''" :SingleFormEditing="false"
                                                                            :Record="listitem.Record" :AdditionalSaveMethod="AdditionalSaveMethod"
                                                                            :section="''" :tab="''" :Fields="[listitem]" :AppisDarkMode="AppisDarkMode" :CanEdit="true"
                                                                            />
                                                                        </v-card-text>
                                                                    </v-card>
                                                            </v-menu>
                                                            </v-list-item-action> -->
                                                        </v-list-item>
                                                <v-select v-if="DynamicWikiData" multiple @change="AssignDescriptionList(EditedTab)" v-model="panel.ListItems" :items="EntityFieldsandFeatures" label="List Items" return-object item-text="DisplayName"/> 
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-btn @click="RemovePanel(EditedTab,panelindex)">Remove</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                  </v-menu>	
                            </v-list-item>  
                            </v-list> 
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import SingleTabFieldsSection from '@/components/Database/SingleTabFieldsSection';

export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,SingleTabFieldsSection},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        BasicFieldTypes(){
            return this.FieldTypes.filter(field => {
                return field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text' || field.Type === 'Boolean'
            })
        },
    },
    created(){

    },
    methods:{
        AssignDescriptionList(EditedTab){
            this.$emit('AssignDescriptionList',EditedTab)
        },
        RefreshComponent(EditedTab){
            this.$emit('RefreshComponent',EditedTab)
        },
        ActivateNewFieldDialog(field,AddingNewField,ListPanel){
            this.$emit('ActivateNewFieldDialog',field,AddingNewField,ListPanel)
        },
        RefreshPanels(EditedTab){
            EditedTab.WrapInExpansionPanel = false
            EditedTab.WrapInExpansionPanel = true
            this.RefreshComponent(EditedTab)
        },
        RemovePanel(EditedTab,panelindex){
            EditedTab.Panels.splice(panelindex,1)
            this.RefreshPanels(EditedTab)
        },
        PrepareForPanels(EditedTab){
            if(!EditedTab.Panels){
                EditedTab.Panels = []
            }            
        },
        AddListPanels(EditedTab){
            let newpanel = {
                Title: 'Some Title',
                ListItems: [],
            }
            EditedTab.Panels.push(newpanel)
            this.RefreshPanels(EditedTab)
            console.log(EditedTab)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
