          <template>			
   <v-main>
   <v-card tile flat height="100%" width="100%" :class="DashboardBGColor" :style="PushedElmnt || ActiveSession? 'padding-bottom: 100px;' : 'margin-top:65px;padding-bottom: 100px;'">		
     <v-layout column style="position: absolute;top: 20px;right:50px;z-index: 1" v-if="PushedElmnt && $route.name === 'SystemConfig' || PushedElmnt && $route.name === 'GettingStarted'">			
        <v-icon @click="StraigthenDashboardCharts()" class="soloactionicon" x-large>mdi-refresh</v-icon>
        <v-menu			
            class="FormFields"			
            v-model="Todaydialog"			
            :close-on-content-click="false"			
            :nudge-right="40"			
            transition="scale-transition"			
            offset-y			
            min-width="290px"			
            >	
              <template v-slot:activator="{ on }">	
                <v-icon v-on="on" class="soloactionicon" x-large>mdi-calendar</v-icon>	
              </template>
              <v-date-picker class="FormFields" v-model="Today" @input="Todaydialog = false"></v-date-picker>	
        </v-menu>
        

        </v-layout>		
     <div class="hidden-sm-and-up" v-if="!ViewOnly">			
     <!-- {{SaveDashboardElements}} -->			
     </div>			
        <!-- <v-app-bar style="z-index:10;position:fixed;margin-top:-50px;" v-if="FilterbyName">			
            Filtered by {{FilterbyName}} <v-spacer></v-spacer>			
            <v-icon @click="DeactivateFilter()">mdi-close</v-icon>			
        </v-app-bar> -->			
        <v-app-bar height="300" color="white" style="z-index:10;position:fixed;margin-top:10px;" v-if="CheckingFilters && !PushedElmnt && !PushedRows || CheckingFilters && PushedElmnt && PushedElmnt.GoalMeasurements">			
          <v-list dense class="transparent" width="100%">			
            <v-list-item>			
              <v-menu			
                class="FormFields"			
                v-model="Todaydialog"			
                :close-on-content-click="false"			
                :nudge-right="40"			
                transition="scale-transition"			
                offset-y			
                min-width="290px"			
                >			
                <template v-slot:activator="{ on }">			
                    <v-text-field			
                    class="FormFields"			
                    v-model="Today"			
                    label="Dashboard as at Date" 			
                    prepend-icon="mdi-calendar-month"			
                    readonly			
                    v-on="on"			
                    ></v-text-field>			
                </template>			
                <v-date-picker class="FormFields" v-model="Today" @input="Todaydialog = false"></v-date-picker>			
                </v-menu>			
            </v-list-item>			
            <v-list-item v-if="EntityHasbusinessUnits">			
              <v-autocomplete item-text="Name" return-object :items="BusinessUnitsArray" v-model="BusinessUnitFilter" label="Business Unit"/>			
            </v-list-item>			
            <v-list-item v-if="DataSetHasRecordOwner">			
              <v-autocomplete item-text="Name" return-object :items="UsersArray" v-model="OwningUserfilter" label="Owner"/>			
            </v-list-item>	
            <v-list-item>     
              <v-autocomplete clearable item-text="Name" return-object :items="DashboardEntityStatus" @change="SecondaryStatusFilter = ''" v-model="PrimaryStatusFilter" label="Status Filter"/>     
            </v-list-item>
            <v-list-item v-if="PrimaryStatusFilter && PrimaryStatusFilter.HasDeepHierarchy">     
              <v-autocomplete clearable item-text="Name" return-object :items="PrimaryStatusFilter.Options" v-model="SecondaryStatusFilter" label="Status Filter"/>     
            </v-list-item>
          </v-list>			
           			
            <v-spacer></v-spacer>			
            <v-icon @click="CheckingFilters = false">mdi-close</v-icon>			
        </v-app-bar>			
    <v-dialog v-model="ActiveDashboardTable" max-width="1000">			
    <v-card>			
        <v-img src="@/assets/RABaseBG.jpeg">			
        <v-card-title><v-icon color="red" @click="DeactivateActiveDashboardTable()">mdi-close</v-icon></v-card-title>			
        <!-- <component v-if="ActiveDashboardTable" :is="DashboardEntity.id" @updatearray="updatearray" :dashboardelementindex="index"/> -->			
            <component :is="ActiveDashboardComponent" :FunctionEntityid="DashboardEntityid" :System="System" :SystemEntities="FinalSystemEntities"
            :UsersArray="UsersArray" :DashboardView="ActiveDashboardTable" :DashboardArray="ActiveDashboardArray" />			
        </v-img>			
    </v-card>			
</v-dialog> 			
        <v-expansion-panels v-if="!ViewOnly">			
            <v-expansion-panel>			
                <v-expansion-panel-header>			
                    Dashboard Properties			
                </v-expansion-panel-header>			
                <v-expansion-panel-content>			
                  			
                       <v-btn @click="SaveDashboard()">Save</v-btn>			
                    <v-list class="transparent">			
                        <v-list-item>			
                             <v-text-field v-model="DashboardName" label="Dashboard Name"/>			
                        </v-list-item>			
			
                        <v-list-item>			
                             <v-select return-object v-if="GotEntities" :disabled="DashboardEntity.id || DashboardEntity.length > 0" :items="ComputedSystemEntities" item-text="id" label="Entity" v-model="DashboardEntity"/>			
                        </v-list-item>                        			
                         <v-list-item>			
                            <v-select return-object :items="UsersforProperties" item-text="Full_Name" label="Owner" v-model="Owner"/>			
                        </v-list-item>			
                        <v-list-item>			
                            <v-select return-object :items="UsersforProperties" item-text="Full_Name" label="Who can View" multiple v-model="WhocanView"/>			
                        </v-list-item>			
                        <v-list-item>			
                            <v-select return-object :items="UsersforProperties" item-text="Full_Name" label="Who can Edit" multiple v-model="WhocanEdit"/>			
                        </v-list-item>			
                        <v-list-item>			
                            <v-select return-object :items="UsersforProperties" item-text="Full_Name" label="Who can Share" multiple v-model="WhocanShare"/>			
                        </v-list-item>			
                    </v-list>			
                </v-expansion-panel-content>			
            </v-expansion-panel>			
        </v-expansion-panels>			
       <!-- <v-flex xl11 lg11 md12 sm12 xs12 class="mx-3"> -->			
      <!-- <v-layout col class="mx-3 justify-center"> -->			
          			
          <!-- <v-card id="funneltest">			
          </v-card> -->			
         			
          <v-layout column :class="ActiveSuiteApp ? 'app-dashboard-colors-container' : 'dashboard-colors-container'"  v-if="!PushedElmnt">			
            <v-menu			
            :close-on-content-click="false"			
                transition="scale-transition"			
                offset-y			
                min-width="290px"			
              >			
              <template v-slot:activator="{ on }">			
            			
            <v-btn fab bottom right v-if="!ViewOnly && HasData" v-on="on"  dark class="stdparallaxgradient"><v-icon x-large>mdi-palette</v-icon></v-btn>			
              </template>			
              <v-card height="50">			
              <v-autocomplete v-if="GotEntities" :items="LibraryColors" label="BG Color" v-model="BGColor">			
                <template v-slot:item="{ item }">			
                                <span :class="item">{{item}}			
                                    <p style="font-size:12px;">The Quick brown fox jumps over the lazy dog			
                                    </p>			
                                    </span>			
                            </template>			
              </v-autocomplete>			
              </v-card>			
            </v-menu>			
          </v-layout>			
          <v-layout column :class="ActiveSuiteApp ? 'app-dashboard-filters-container' : 'dashboard-filters-container'" v-if="!PushedElmnt || PushedElmnt && PushedElmnt.GoalMeasurements">			
            <v-btn fab bottom right v-if="HasData" @click="CheckingFilters = true" dark color="green"><v-icon x-large>mdi-filter</v-icon></v-btn>			
          </v-layout>			
         <!-- <v-btn v-if="ViewOnly && HasData" :to="'/DashboardBuilder/'+Dashboardid">Edit</v-btn> -->			
          <!-- <v-btn v-if="!ViewOnly && HasData" :to="'/CustomDashboard/'+Dashboardid">Preview</v-btn> -->			
        <v-btn v-if="!ViewOnly && HasData" @click="AddRowasDashboardelement()">Add Row</v-btn>			
        <v-layout column :class="ActiveSuiteApp ? 'app-dashboard-edit-container' : 'dashboard-edit-container'" v-if="!PushedElmnt">		
          	
        <v-btn fab bottom right v-if="CanEditDashboard" :to="'/DashboardBuilder/'+Dashboardid" ><v-icon x-large>mdi-pencil</v-icon></v-btn>			
        <v-btn fab bottom right v-if="!ViewOnly && HasData" :to="'/CustomDashboard/'+Dashboardid"><v-icon x-large>mdi-eye</v-icon></v-btn>			
        </v-layout>			
        <v-layout column :class="ActiveSuiteApp ? 'app-dashboard-straighten-container' : 'dashboard-straighten-container'"   v-if="!PushedElmnt">			
        <v-btn fab bottom right  @click="StraigthenDashboardCharts()"><v-icon x-large>mdi-file-chart</v-icon></v-btn>			
        </v-layout>			
        
        <component v-if="DashboardEntity && !PushedElmnt" :System="System" :SystemEntities="FinalSystemEntities" :FunctionEntityid="DashboardEntityid" v-bind:is="DashboardEntityComponent" :UsersArray="UsersArray" @updatearray="updatearray" :dashboardelementindex="0"/>			
        <v-parallax  v-if="!PushedElmnt && !ActiveSession"			
            height="150"			
            src="@/assets/RABaseBG.jpeg"			
            >			
              			
              <v-row			
              align="center"			
              justify="center"			
              class="fill-height"			
            >			
              <v-col class="parallaxbgcontentgrad text-center" cols="12">		
                <v-card-title class="white--text justify-center largeoverline">{{DashboardName}}
                </v-card-title>
                <v-card-subtitle style="font-size:1.0em !important;" class="overline white--text justify-center">{{CurrentFiscalandQuarterShortDisplay}}
                </v-card-subtitle>	
                <!-- <h1 class="display-2 font-weight-thin mb-4">Features</h1> -->			
               
              </v-col>		
             </v-row>			
            </v-parallax> 			
    <!-- <v-layout row class="red justify-center mx-3 my-3" >			
      <span class="white--text largeoverline">			
        {{DashboardName}}			
      </span>			
    </v-layout> -->			
    <v-row v-if="LoadingItems"			
      align="center"			
      justify="center"			
    > 			
    <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">			
              <v-container bg fill-height grid-list-md text-xs-center>			
              <v-layout row wrap align-center class="justify-center">			
              <v-card-text class="justify-center">			
                <v-layout class="justify-center">			
                   <v-progress-circular size="225"			
                      :width="15"			
                      color="purple"			
                      indeterminate			
                    >Loading</v-progress-circular>			
                  </v-layout>			
              </v-card-text>			
              </v-layout>			
              </v-container>			
        </v-card>			
     			
    </v-row>			
    <v-layout row class="justify-center mx-3 my-3"  v-for="(row,i) in ComputedDashboardElements" :key="row.itemObjKey">			
      
      	<div class="flex xl12 lg12 md12 sm12 xs12">
          <div v-if="!ViewOnly" class="editorstuff row no-gutters mb-6 justify-center" style="margin: 0px;">
            <v-icon class="mx-6" v-if="!ViewOnly" color="red" @click="DeleteRowDialog(row,i)">mdi-delete-forever</v-icon>			
        <!-- <v-icon x-large @click="CheckRowforCharts(row,i)">mdi-refresh</v-icon> -->			
      <v-dialog max-width="500">			
        <template v-slot:activator="{ on }">			
         <v-btn v-if="!ViewOnly && HasData" @click="AssignRow(row)" v-on="on">Add Element</v-btn>			
        </template>			
          <v-card			
            class="mx-auto"			
            max-width="500"			
            height="100%"			
          >			
            <v-card-title class="title font-weight-regular justify-space-between">			
              <span>{{ currentTitle }}</span>			
              <v-avatar			
                color="primary lighten-2"			
                class="subheading white--text"			
                size="24"			
                v-text="step"			
              ></v-avatar>			
            </v-card-title>			
			
            <v-window v-model="step" class="my-4">			
              <v-window-item :value="1">			
                <v-card-text>			
                  <v-list-item @click="SelectRootelementType(opt)" v-for="opt in RootElementOptions" :key="opt.itemObjKey">			
                    {{opt.ConfigName}}			
                  </v-list-item>			
                </v-card-text>			
              </v-window-item>			
              			
               <v-window-item :value="2">			
                <!-- <v-card flat tile class="transparent" height="100%"> -->			
                <v-card-title>			
                  {{RootelementType.ConfigName}}			
                </v-card-title>			
                <v-card-text>			
                <v-expansion-panels>			
                    <v-expansion-panel v-for="opt in RootelementType.Options" :key="opt.itemObjKey">			
                      <v-expansion-panel-header>			
                      {{opt.Name}}			
                      </v-expansion-panel-header>			
                      <v-expansion-panel-content v-if="RootelementType.ConfigName === 'Data in Timeline'">			
                        <v-list-item>			
                          {{RootelementType.ConfigName}}, based on <v-select label="Primary Date Filter" return-object :items="DashboardEntity.DateFields" item-text="id" v-model="opt.PrimaryDateFilter"/>			
                        </v-list-item>			
                        <v-list-item v-if="opt.PrimaryLabelType !== 'From Timeline'">			
                          <v-select label="Date Filter" :items="TimelineFilterOptions" item-text="Name" return-object v-model="opt.TimeFilterOption"/>			
                        </v-list-item>			
                        <v-list-item v-if="opt.PrimaryLabelType === 'From Timeline'">			
                          <v-select label="Date Filter" :items="TimelineFilterOptions" item-text="GiveName" return-object v-model="opt.TimeFilterOption"/>			
                        </v-list-item>			
                        <v-card-actions >                       			
                        <v-spacer></v-spacer>			
                        <v-btn			
                          :disabled="step === 3"			
                          color="primary"			
                          depressed			
                          @click="ChooseOption(opt)"			
                        >			
                          Next			
                        </v-btn>			
                      </v-card-actions>			
                      </v-expansion-panel-content>             			
                      <v-expansion-panel-content v-if="RootelementType.ConfigName === 'Charts and Context'">			
                        <v-list-item>			
                          {{RootelementType.ConfigName}}			
                        </v-list-item>			
                        <!-- <v-list-item>			
                           <v-select label="Process" return-object :items="DashboardEntity.EntityProcesses" item-text="Name" v-model="RootelementType.PipelineProcess"/>			
                        </v-list-item> -->			
                        <v-card-actions >                       			
                        <v-spacer></v-spacer>			
                        <v-btn			
                          :disabled="step === 3"			
                          color="primary"			
                          depressed			
                          @click="ChooseOption(opt)"			
                        >			
                          Next			
                        </v-btn>			
                      </v-card-actions>			
                      </v-expansion-panel-content>			
                     <v-expansion-panel-content v-if="RootelementType.ConfigName === 'Data in Process'">			
                        <v-list-item>			
                          {{RootelementType.ConfigName}}			
                        </v-list-item>			
                        <v-list-item>			
                           <v-select label="Process" return-object :items="DashboardEntity.EntityProcesses" item-text="Name" v-model="RootelementType.PipelineProcess"/>			
                        </v-list-item>			
                        <v-card-actions >                       			
                        <v-spacer></v-spacer>			
                        <v-btn			
                          :disabled="step === 3"			
                          color="primary"			
                          depressed			
                          @click="ChooseOption(opt)"			
                        >			
                          Next			
                        </v-btn>			
                      </v-card-actions>			
                      </v-expansion-panel-content>			
                      <v-expansion-panel-content  v-if="RootelementType.ConfigName === 'Charts and Context'">			
                        <v-list-item>			
                              <v-select v-if="DashboardEntity && HasData" label="Chart Type" :items="['bar','line','pie','doughnut','radar','horizontalBar']" v-model="RootelementType.ChartType"/> 			
                            </v-list-item>			
                            <v-list-item>			
                              <v-select :items="['Cards','List']" v-model="RootelementType.DataContextStyle" label="Style"/>			
                            </v-list-item>			
                             <v-list-item>			
                                <v-switch v-model="RootelementType.HasChart" label="Has Chart"/>			
                            </v-list-item>			
                            <v-list-item>			
                                <v-switch v-model="RootelementType.HasDataContext" label="Has Data Context"/>			
                            </v-list-item>			
                             <v-card-actions >                       			
                              <v-spacer></v-spacer>			
                              <v-btn			
                                :disabled="step === 3"			
                                color="primary"			
                                depressed			
                                @click="ChooseOption(opt)"			
                              >			
                                Next			
                              </v-btn>			
                            </v-card-actions>			
                      </v-expansion-panel-content>			
                      <v-expansion-panel-content  v-if="RootelementType.ConfigName === 'Activities in Timeline'">			
                        <!-- <v-list-item>			
                           <v-select v-if="DashboardEntity && HasData" label="Prefilter Field" return-object :items="ActivityPreFilterFields" item-text="id" v-model="opt.PreFilterField"/>                     			
                            </v-list-item>			
                            <v-list-item>			
                             <v-select v-if="DashboardEntity && HasData && opt.PreFilterField" label="Prefilter" return-object :items="opt.PreFilterField.Options" item-text="Name" v-model="opt.PreFilter"/>			
                            </v-list-item> -->			
                             <v-list-item>			
                          <v-select label="Date Filter" :items="TimelineFilterOptions" item-text="Name" return-object v-model="opt.TimeFilterOption"/>			
                        </v-list-item>			
                         <v-card-actions >                       			
                        <v-spacer></v-spacer>			
                        <v-btn			
                          :disabled="step === 3"			
                          color="primary"			
                          depressed			
                          @click="ChooseOption(opt)"			
                        >			
                          Next			
                        </v-btn>			
                      </v-card-actions>			
                      </v-expansion-panel-content>			
                      <v-expansion-panel-content  v-if="RootelementType.ConfigName === 'Goal Measurements'">			
                        <v-list-item>			
                           <v-select v-if="DashboardEntity && HasData" label="Prefilter Field" return-object :items="DashboardEntity.OptionSets" item-text="id" v-model="opt.PreFilterField"/>                     			
                            </v-list-item>			
                            <v-list-item>			
                             <v-select v-if="DashboardEntity && HasData && opt.PreFilterField" label="Prefilter" return-object :items="opt.PreFilterField.Options" item-text="Name" v-model="opt.PreFilter"/>			
                            </v-list-item>	
                            <v-list-item v-if="DashboardEntity && HasData && opt.PreFilterField && opt.PreFilterField.HasDeepHierarchy && opt.PreFilter && opt.PreFilter.Options">		
                              <v-select label="Secondary Prefilter" return-object :items="opt.PreFilter.Options" item-text="Name" v-model="opt.SecondaryFilterField"/>		
                            </v-list-item>		
                             <v-list-item>			
                          <v-select label="Date Filter" :items="TimelineFilterOptions" item-text="Name" return-object v-model="opt.TimeFilterOption"/>			
                        </v-list-item>			
                         <v-card-actions >                       			
                        <v-spacer></v-spacer>			
                        <v-btn			
                          :disabled="step === 3"			
                          color="primary"			
                          depressed			
                          @click="ChooseOption(opt)"			
                        >			
                          Next			
                        </v-btn>			
                      </v-card-actions>			
                      </v-expansion-panel-content>			
                    </v-expansion-panel>			
                  </v-expansion-panels>			
                </v-card-text>			
            			
              </v-window-item>			
              <v-window-item :value="3">			
                <v-card flat tile class="transparent my-10" height="100%">			
                <v-card-title>			
                  {{RootelementType.ConfigName}}			
                </v-card-title>			
                <v-card-subtitle>			
                  {{SelectedOption.Description}}			
                </v-card-subtitle>			
                <v-layout row class="justify-center">			
                  <v-list dense v-if="RootelementType.ConfigName !== 'Activities in Timeline'">			
                    <v-list-item>			
                      <v-select v-if="DashboardEntity" label="Value Type" return-object :items="['Count','Amount']" item-text="id" v-model="SelectedOption.ValueType"/>			
                    </v-list-item>			
                    <v-list-item>			
                      <v-select v-if="DashboardEntity && SelectedOption.ValueType === 'Amount'" label="Value Field" return-object :items="DashboardEntity.NumberFields" item-text="id" v-model="SelectedOption.ValueField"/>			
                    </v-list-item>			
                    <v-list-item>			
                      <v-select v-if="DashboardEntity && SelectedOption.ValueType === 'Amount'" label="Value Currency" return-object :items="StoreCurrencyNames" item-text="id" v-model="SelectedOption.ValueCurrency"/>  			
                    </v-list-item>			
                  </v-list>			
                  <div v-else>			
                    That's it!			
                  </div>			
                </v-layout>			
               </v-card>			
              </v-window-item>			
             			
            </v-window>			
			
            <v-divider></v-divider>			
			
            <v-card-actions >			
              <v-btn			
                :disabled="step === 1"			
                text			
                @click="step--"			
              >			
                Back			
              </v-btn>			
              <v-spacer></v-spacer>			
              <v-btn			
                :disabled="step !== 3"			
                color="primary"			
                depressed			
                @click="CheckAdvanceStep()"			
              >			
                {{CheckButton}}			
              </v-btn>			
            </v-card-actions>			
          </v-card>			
        </v-dialog>			
          </div>
          <div class="row no-gutters mb-6 justify-center" style="margin: 0px;">
        <div :class="'flex xl'+elmnt.FlexXLRG+' lg'+elmnt.FlexLarge+' md'+elmnt.FlexMedium+' sm'+elmnt.FlexSmall+' xs'+elmnt.FlexXSmall" v-for="(elmnt,index) in row.Elements" :key="elmnt.itemObjKey"
        class="mx-3 my-3"  >			
            <v-expansion-panels class="my-6" v-if="!ViewOnly">			
              <v-select label="Column Width" v-model="elmnt.ColumnWidth" :items="ReturnColWidthOptions(elmnt)" />			
                <v-expansion-panel>			
                    <v-expansion-panel-header>			
                        Element Properties			
                    </v-expansion-panel-header>			
                    <v-expansion-panel-content>			
                      			
                      <v-icon color="red" @click="DeleteElementDialog(row,i,elmnt,index)">mdi-delete-forever</v-icon>			
                      <v-tabs v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData">			
                        <v-tab>			
                          Basics			
                        </v-tab>			
                        <v-tab-item>			
                          <v-list dense>			
                            <v-list-item>			
                              <v-text-field v-model="elmnt.Title" label="Title"/> 			
                            </v-list-item>			
                            <v-list-item>			
                              <v-select label="Column Width" v-model="elmnt.ColumnWidth" :items="ReturnColWidthOptions(elmnt)" />			
                            </v-list-item>			
                            <v-list-item class="justify-center">			
                              <v-switch v-model="elmnt.ShowTitle" label="Show Title"/>	
                                <v-switch v-model="elmnt.HasChart" label="Has Chart"/>
                                 <v-switch v-model="elmnt.HasDataContext" label="Has Data Context"/>			
                              </v-list-item>			
                              <v-list-item>			
                                   <v-select clearable label="Primary Date Filter" return-object :items="GetDateFields(elmnt)" item-text="id" v-model="elmnt.PrimaryDateFilter"/>			
                                 <v-select v-if="elmnt.ConfigID === 1000002 || elmnt.ConfigID === 1000001 || elmnt.ConfigID === 1000005" @change="CheckforCharts(elmnt,i,index)" label="Date Filter" :items="TimelineFilterOptions" item-text="Name" return-object v-model="elmnt.TimeFilterOption"/>			
                              </v-list-item>			
                              <v-list-item>			
                                   <v-select clearable @change="CheckforCharts(elmnt,i,index)" :items="['Radio Group','Option Set','Lookup','Option Set from Lookup']" v-model="elmnt.PrimaryFilterType" label="Primary Filter Type"/>
                               <v-select v-if="elmnt.PrimaryFilterType === 'Radio Group'" label="Primary Filter Field" return-object :items="DashboardEntity.RadioGroups" item-text="id" v-model="elmnt.PrimaryFilterField"/>					
                                  <v-select v-if="elmnt.PrimaryFilterType === 'Option Set'" label="Primary Filter Field" return-object :items="DashboardEntity.OptionSets" item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                                  <v-select v-if="elmnt.PrimaryFilterType === 'Lookup'" label="Primary Filter Field" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                                  <v-select v-if="elmnt.PrimaryFilterType === 'Option Set from Lookup'" label="Primary Filter Lookup" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryFilterLookup"/>			
                                  <v-select v-if="elmnt.PrimaryFilterType === 'Option Set from Lookup' && elmnt.PrimaryFilterLookup" label="Primary Filter Field" return-object :items="GetRelatedOptionSets(elmnt,'PrimaryFilterLookup')" item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                              </v-list-item>
                              <v-list-item>	
                             			<v-select v-if="DashboardEntity && HasData" label="Prefilter Field" return-object :items="GetPreFilterFields(elmnt)" item-text="id" v-model="elmnt.PreFilterField"/>			
                                  <v-select v-if="DashboardEntity && HasData && elmnt.PreFilterField" label="Prefilter" return-object :items="elmnt.PreFilterField.Options" item-text="Name" v-model="elmnt.PreFilter"/>			
                                  <v-select v-if="DashboardEntity && HasData && elmnt.PreFilterField && elmnt.PreFilterField.HasDeepHierarchy && elmnt.PreFilter && elmnt.PreFilter.Options" label="Secondary Prefilter" return-object :items="elmnt.PreFilter.Options" item-text="Name" v-model="elmnt.SecondaryFilterField"/>
                              </v-list-item>
                          </v-list>			
                          			
                          </v-tab-item>			
                          <v-tab  v-if="elmnt.HasChart">			
                          Chart			
                        </v-tab>			
                        <v-tab-item  v-if="elmnt.HasChart">			
                          <v-list dense>			
                            <v-list-item>			
                              <v-select @change="CheckforCharts(elmnt,i,index)" label="Chart Type" :items="ChartTypeOptions(elmnt)" v-model="elmnt.ChartType"/> 			
                            </v-list-item>                          			
                               <v-list-item v-if="elmnt.HasChart && elmnt.ChartType === 'line' && elmnt.ChartLabelsfromTimeline">			
                                <v-switch v-model="elmnt.AccumulativeGraph" label="Accumulative Graph"/>			
                              </v-list-item>                            			
                               <v-list-item v-if="!elmnt.GoalMeasurements">			
                                <v-switch @change="EnsureFromChildnotConfigured(elmnt),CheckforCharts(elmnt,i,index)" v-if="elmnt.EntityRelatedfield  && elmnt.EntityRelatedfield.Type === 'Lookup'" label="Sub Label from Parent" v-model="elmnt.SublabelFromRelatedLookup"/>			
                              </v-list-item>   			
                              <v-list-item v-if="!elmnt.GoalMeasurements">			
                                <v-select clearable label="RelatedField" return-object :items="DashboardEntity.RelatedFields" item-text="id" v-model="elmnt.EntityRelatedfield"/>			
                              </v-list-item>			
                              <v-list-item v-if="!elmnt.GoalMeasurements && DashboardEntity">			
                               <v-select @change="CheckforCharts(elmnt,i,index)" clearable label="Sub Label" :items="GetSublabelFields(elmnt)" item-text="id" return-object v-model="elmnt.SublabelField"/> 			
                              </v-list-item>			
                          </v-list>			
                      			
                          </v-tab-item>			
                        <v-tab v-if="elmnt.HasDataContext">			
                          Context			
                        </v-tab>			
                        <v-tab-item v-if="elmnt.HasDataContext">			
                          <v-list dense>			
                            <v-select @change="CheckforCharts(elmnt,i,index)" label="Sort" :items="['Ascending (Value)','Descending (Value)','AlphaBetically (Descending)','AlphaBetically (Ascending)']" item-text="id" v-model="elmnt.Sort"/>           			
                              <v-select @change="CheckforCharts(elmnt,i,index)" label="Value Type" return-object :items="['Count','Amount']" item-text="id" v-model="elmnt.ValueType"/>			
                              <v-select @change="CheckforCharts(elmnt,i,index)" v-if="elmnt.ValueType === 'Amount'" label="Value Field" return-object :items="DashboardEntity.NumberFields" item-text="id" v-model="elmnt.ValueField"/>			
                              <v-select @change="CheckforCharts(elmnt,i,index)" v-if="elmnt.ValueType === 'Amount'" label="Value Currency" return-object :items="StoreCurrencyNames" item-text="id" v-model="elmnt.ValueCurrency"/> 			
                            <v-list-item>			
                              <v-select @change="CheckforCharts(elmnt,i,index)" :items="['Cards','List']" v-model="elmnt.DataContextStyle" label="Style"/>			
                            </v-list-item>                          			
                              			
                              <v-list-item>			
                                  <v-switch v-model="elmnt.ShowSummary" label="Show Summary"/>			
                              </v-list-item>			
                              <v-list-item>			
                                <v-switch v-model="elmnt.ShowSummaryOnly" label="Show Summary Only"/>			
                              </v-list-item>                         			
                          </v-list>                      			
                          </v-tab-item>			
                      </v-tabs>			
                      <v-switch v-if="userIsAdmin" v-model="OldStyle" label="temp OldStyle"/>			
                      <v-tabs centered v-if="OldStyle && userIsAdmin">			
                        <v-tab>General 			
                        </v-tab>			
                        <v-tab-item >			
                       <v-text-field v-model="elmnt.Title" label="Title"/>    			
                        <v-select label="Column Width" v-model="elmnt.ColumnWidth" :items="ReturnColWidthOptions(elmnt)" />			
                        <v-switch v-model="elmnt.ShowTitle" label="Show Title"/>			
                        <v-switch v-model="elmnt.HasChart" label="Has Chart"/>			
                        			
                       {{elmnt.FlexXLRG}}			
                       <v-select @change="AdjusttoPreset(elmnt),CheckforCharts(elmnt,i,index)" v-if="DashboardEntity" label="Preset" :items="DashboardEntityTypes" item-text="id" v-model="elmnt.Preset"/>			
                    <v-select @change="CheckforCharts(elmnt,i,index)" :items="['Option Set','Lookup','Option Set from Lookup']" v-model="elmnt.PrimaryFilterType" label="Primary Filter Type"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryFilterType === 'Option Set'" label="Primary Filter Field" return-object :items="DashboardEntity.OptionSets" item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryFilterType === 'Lookup'" label="Primary Filter Field" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryFilterType === 'Option Set from Lookup'" label="Primary Filter Lookup" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryFilterLookup"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryFilterType === 'Option Set from Lookup' && elmnt.PrimaryFilterLookup" label="Primary Filter Field" return-object :items="GetRelatedOptionSets(elmnt,'PrimaryFilterLookup')" item-text="id" v-model="elmnt.PrimaryFilterField"/>			
                    <v-select @change="CheckforCharts(elmnt,i,index)" :items="['Option Set','Lookup','Option Set from Lookup','Name of Child']" v-model="elmnt.PrimaryLabelType" label="Primary Label Type"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryLabelType === 'Option Set'" label="Primary Label Field" return-object :items="DashboardEntity.OptionSets" item-text="id" v-model="elmnt.PrimaryLabelField"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryLabelType === 'Lookup'" label="Primary Label Field" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryLabelField"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryLabelType === 'Option Set from Lookup'" label="Primary Label Lookup" :items="DashboardEntity.LookupFields" return-object  item-text="id" v-model="elmnt.PrimaryLabelLookup"/>			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryLabelType === 'Option Set from Lookup' && elmnt.PrimaryLabelLookup" label="Primary Label Field" return-object :items="GetRelatedOptionSets(elmnt,'PrimaryLabelLookup')" item-text="id" v-model="elmnt.PrimaryLabelField"/>			
                    <!-- <v-switch @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity" label="Primary Label From Child" v-model="elmnt.ContextfromChild"/> -->			
                    <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData && elmnt.PrimaryLabelType === 'Name of Child'" label="Child" :items="RelatedChildEntities" item-text="id" v-model="elmnt.RelatedChild"/>			
                    <v-select v-if="DashboardEntity && HasData && elmnt.RelatedChild" label="Relationship Field" :items="elmnt.RelatedChild.ChildLookupFields" item-text="id" v-model="elmnt.ChildRelationshipsField"/>			
                    <v-select v-if="DashboardEntity && HasData" label="Prefilter Field" return-object :items="GetPreFilterFields(elmnt)" item-text="id" v-model="elmnt.PreFilterField"/>			
                     <v-select v-if="DashboardEntity && HasData && elmnt.PreFilterField" label="Prefilter" return-object :items="elmnt.PreFilterField.Options" item-text="Name" v-model="elmnt.PreFilter"/>			
                     <v-select v-if="DashboardEntity && HasData && elmnt.PreFilterField && elmnt.PreFilterField.HasDeepHierarchy && elmnt.PreFilter && elmnt.PreFilter.Options" label="Secondary Prefilter" return-object :items="elmnt.PreFilter.Options" item-text="Name" v-model="elmnt.SecondaryFilterField"/>
                        </v-tab-item>			
                        <v-tab v-if="elmnt.HasChart">Chart 			
                        </v-tab>			
                        <v-tab-item v-if="elmnt.HasChart">			
                         <v-switch @change="EnsurenotChartlabels(elmnt),CheckforCharts(elmnt,i,index)" v-if="DashboardEntity" label="Chart Labels from Process" v-model="elmnt.ChartLabelsfromProcess"/>			
                         <v-select v-if="DashboardEntity && DashboardEntity.EntityProcesses && DashboardEntity.EntityProcesses.length>0 && DashboardEntity.RelatedFields && HasData && elmnt.ChartLabelsfromProcess" label="Process" :items="DashboardEntity.EntityProcesses" item-text="Name" v-model="elmnt.PipelineProcess"/>			
                          <v-switch @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity" label="Chart Labels from Timeline" v-model="elmnt.ChartLabelsfromTimeline"/>			
                          <v-select @change="CheckforCharts(elmnt,i,index)" :items="['Top','Left','Right']" v-model="elmnt.ChartPosition" label="Chart Position"/>			
                          			
                          <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData" label="Primary Date Filter" return-object :items="GetDateFields(elmnt)" item-text="id" v-model="elmnt.PrimaryDateFilter"/>			
                         			
                          <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.EntityRelatedfield" label="Date Filter" :items="TimelineFilterOptions" item-text="Name" return-object v-model="elmnt.TimeFilterOption"/>			
                          <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData" label="Chart Type" :items="ChartTypeOptions(elmnt)" v-model="elmnt.ChartType"/> 			
                          <v-switch @change="EnsureFromChildnotConfigured(elmnt),CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.EntityRelatedfield  && elmnt.EntityRelatedfield.Type === 'Lookup'" label="Sub Label from Parent" v-model="elmnt.SublabelFromRelatedLookup"/>			
                          <v-switch @change="EnsureFromParentnotConfigured(elmnt),CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.RelatedChild" label="Sub Label from Child" v-model="elmnt.SublabelFromRelatedChild"/>			
                          			
                          <v-select v-if="DashboardEntity && DashboardEntity.RelatedFields && HasData" label="RelatedField" return-object :items="DashboardEntity.RelatedFields" item-text="id" v-model="elmnt.EntityRelatedfield"/>			
                        <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity " clearable label="Sub Label" :items="GetSublabelFields(elmnt)" item-text="id" return-object v-model="elmnt.SublabelField"/>                      			
                        </v-tab-item>			
                        <v-tab v-if="elmnt.HasDataContext">Data Context			
                        </v-tab>			
                        <v-tab-item v-if="elmnt.HasDataContext">			
                          			
                          <v-select @change="CheckforCharts(elmnt,i,index)" :items="['Cards','List']" v-model="elmnt.DataContextStyle" label="Style"/>			
                          			
                          <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.EntityRelatedfield" label="Sort" :items="['Ascending (Value)','Descending (Value)','AlphaBetically (Descending)','AlphaBetically (Ascending)']" item-text="id" v-model="elmnt.Sort"/>           			
                        <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.EntityRelatedfield" label="Value Type" return-object :items="['Count','Amount']" item-text="id" v-model="elmnt.ValueType"/>			
                        <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && DashboardEntity.RelatedFields && elmnt.EntityRelatedfield && elmnt.ValueType && elmnt.ValueType === 'Amount'" label="Value Field" return-object :items="DashboardEntity.NumberFields" item-text="id" v-model="elmnt.ValueField"/>			
                        <v-select @change="CheckforCharts(elmnt,i,index)" v-if="DashboardEntity && elmnt.EntityRelatedfield && elmnt.ValueType && elmnt.ValueType === 'Amount'" label="Value Currency" return-object :items="StoreCurrencyNames" item-text="id" v-model="elmnt.ValueCurrency"/>                       			
                        </v-tab-item>			
                      </v-tabs>			
                    </v-expansion-panel-content>			
                </v-expansion-panel>			
            </v-expansion-panels>			
            <v-row class="justify-center my-3" v-if="elmnt.ShowTitle">	
            <v-card dark :class="PushedElmnt? PushedElmnt.ThemeColor+' mx2':'blue mx-2'" round elevation="10" width="85%" height="100" style="z-index:1;">			
                <v-card-title class="justify-center largeoverline" style="align-content:center;height:100%">			
                   {{elmnt.Title}} 			
                   <!--  - {{elmnt.PipelineProcess.Name}} -->			
                </v-card-title>			
                <!-- <v-btn small @click="ToggleDirection(elmnt)">Direction</v-btn> -->			
            </v-card>			
            </v-row>	
            		<!-- v-if="!PushedElmnt"  -->
            <v-menu v-if="!PushedElmnt || $route.name === 'SystemConfig' || $route.name === 'GettingStarted' || $route.name === 'GroupDashboard' || PushedElmnt && PushedElmnt.GoalMeasurements"
            :close-on-content-click="false"			
                transition="scale-transition"			
                offset-y			
                min-width="290px"			
              >			
              <template v-slot:activator="{ on }">			
                <v-btn v-on="on" color="green" outlined fab small class="elevation-6 mx-1" style="z-index:1;" dark><v-icon color="green">mdi-filter</v-icon>			
                </v-btn>			
            </template>			
            <v-card>			
              <v-card-title>			
                Filters			
              </v-card-title>			
              <v-card-text>	
              <v-autocomplete v-if="elmnt.PrimaryFilterField && elmnt.PrimaryFilterType === 'Radio Group'" @change="CheckforCharts(elmnt,i,index)"			
                :label="elmnt.PrimaryFilterField.DisplayName" clearable return-object v-model="elmnt.ActivePrimaryFilter"			
                dense item-text="Name" :items="elmnt.PrimaryFilterField.Options"/>			
                <v-autocomplete v-if="elmnt.PrimaryFilterField && elmnt.PrimaryFilterType === 'Option Set'" @change="CheckforCharts(elmnt,i,index)"			
                :label="elmnt.PrimaryFilterField.DisplayName" clearable return-object v-model="elmnt.ActivePrimaryFilter"			
                dense item-text="Name" :items="elmnt.PrimaryFilterField.Options"/>			
                <v-autocomplete v-if="elmnt.PrimaryFilterField && elmnt.PrimaryFilterType === 'Lookup'" @change="CheckforCharts(elmnt,i,index)"			
                :label="elmnt.PrimaryFilterField.DisplayName" clearable return-object v-model="elmnt.ActivePrimaryFilter"			
                dense :item-text="elmnt.PrimaryFilterField.LookupFieldName" :items="elmnt.FilterArray">			
                <template v-slot:item="{ item }">	
                                <span :style="'font-family:'+item">{{item[elmnt.PrimaryFilterField.LookupFieldName]}}			
                                    <p v-if="elmnt.PrimaryFilterField.RelatedRecord === 'Users'" style="font-size:12px;">{{item.Email}}			
                                    </p>			
                                    </span>			
                            </template></v-autocomplete>
                            			<!--elmnt.TimeFilterOption not clearable
                                   elmnt.ActiveDateFilter clearable-->
                <v-autocomplete v-if="elmnt.PrimaryDateFilter" @change="CheckforCharts(elmnt,i,index)"			
                label="Timeline Filter" return-object v-model="elmnt.TimeFilterOption"			
                dense item-text="Name" :items="TimelineFilterOptions"/>	
               
              </v-card-text>			
            </v-card>			
            </v-menu>			
            			
            <!-- elmnt.HasTwoRows {{elmnt.HasTwoRows}}			
            elmnt.HasTwoColumns {{elmnt.HasTwoColumns}} -->			
            <!-- <v-flex xl12 lg12 md12 sm12 xs12> -->			
              			
            <v-row class="justify-center" :style="PushedElmnt ? 'margin-top:-70px;':'margin-top:-38px;'" v-if="elmnt.HasTwoColumns && elmnt.ComponentOne"> 			
            			
            <v-flex xl12 lg12 md12 sm12 xs12 class="mx-1">			
              <component :is="elmnt.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
              :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentOne" :elmnt="elmnt" :UsersArray="UsersArray" :DashboardEntity="DashboardEntity"			
              :AppisDarkMode="AppisDarkMode"/>   			
            </v-flex>			
            <v-flex xl12 lg12 md12 sm12 xs12 style="margin-top:-1px;" class="elmntcomp2flex mx-1">			
            			
              <component :is="elmnt.ComponentTwo.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
              :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentTwo" :elmnt="elmnt" :UsersArray="UsersArray" :DashboardEntity="DashboardEntity" 			
              :AppisDarkMode="AppisDarkMode"/>      			
            </v-flex>			
            </v-row>			
            <v-row class="justify-center" :style="PushedElmnt ? 'margin-top:-60px;':''"  v-if="elmnt.HasTwoRows && elmnt.ComponentOne && HasData">			
              			
              <v-flex xl10 lg11 md12 sm12 xs12 class="mx-1">			
              <component :is="elmnt.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
              :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentOne" :elmnt="elmnt" :UsersArray="UsersArray" :DashboardEntity="DashboardEntity"  			
              :AppisDarkMode="AppisDarkMode"/>    			
              </v-flex>			
            </v-row>  			
            			
              <v-row class="justify-center" :style="PushedElmnt ? 'margin-top:-60px;':''" v-if="elmnt.ComponentOne && !elmnt.HasTwoColumns && !elmnt.HasTwoRows">			
             			
                <v-flex xl10 lg11 md12 sm12 xs12 class="">			
              <component :is="elmnt.ComponentOne.CompName" @ActivateActiveDashboardTable="ActivateActiveDashboardTable" @CheckforCharts="CheckforCharts" 			
              :HasData="HasData" :i="i" :index="index" :CompProperties="elmnt.ComponentOne" :elmnt="elmnt" :UsersArray="UsersArray" :DashboardEntity="DashboardEntity"  			
              :AppisDarkMode="AppisDarkMode"/>                  			
                </v-flex>			
              </v-row>		
              <!-- {{EndofWeek(new Date('2021-01-01'))}}			
              {{newtestdate}}			
              {{MonthWeek1Start}}			
              {{MonthWeek2Start}}			
              {{MonthWeek3Start}}			
              {{MonthWeek4Start}}			
              {{MonthWeek5Start}}			
              {{EndofWeek(MonthWeek5Start)}} -->			
            <!-- </v-layout> -->			
            <!-- </v-flex> -->			
            			
        </div>	
        </div>
        </div>		
    </v-layout>			
      <!-- </v-layout> -->			
      <!-- </v-flex> -->			
   </v-card>			
   </v-main>
</template>			
			
<script>			
import firebase from 'firebase';			
import db from '@/main'			
import format from 'date-fns/format'		
// import { VueFunnelGraph } from 'vue-funnel-graph-js';			
import DashboardElmtDataContext from '@/components/SuitePlugins/CustomDashboard/DashboardElmtDataContext'			
import DashboardElmtChart from '@/components/SuitePlugins/CustomDashboard/DashboardElmtChart'			
import Chart from 'chart.js';
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent'	
export default {			
  props: ['PushedElmnt','PushedRows','IntranetView','RelatedObj','PageFilter','PageView','RelatedParentObj','RelatedGroupFilter','DashboardView','DashboardArray',
  'CompSize','AllowsHistoric','ReferenceView','DocTableCall','RenderComp','AppisDarkMode','System','SystemEntities','ActiveSession'],	
  // props: {			
	// 	DashboardEntityComponent: {	
	// 		type: String,
	// 		required: true
	// 	}	
	// },		
  components: {			
      DashboardElmtDataContext,			
      DashboardElmtChart,	
      DataCollectionViewComponent,		
      // Vehicle_Forms,			
      // Vehicles,			
      // VueFunnelGraph			
  },			
  data() {			
    return {			

      SecondaryStatusFilter: '',
      PrimaryStatusFilter: '',
      LoadingItems: false,			
      date: new Date('2021-04-04'),			
      BGColor: 'grey lighten-3',			
      OldStyle: false,			
      SelectedOption: '',			
      RootelementType: '',			
      step: 1,			
      Saving: false,			
      TimelineFilterOptions: [			
        {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },
        {			
          ID: 1000001,			
          FilterName: 'This Week (Per Day)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Week',			
          StartDateVariable: 'ThisMonday',			
          EndDateVariable: 'ThisSunday',			
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],			
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'EndofDay',			
        },			
        {			
          ID: 1000002,			
          AccumulatorID: 1000001,			
          FilterName: 'This Month (Per Week)',			
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',			
          GiveName: 'Per Week',			
          Name: 'This Month',			
          StartDateVariable: 'CurrentMonthStart',			
          EndDateVariable: 'CurrentMonthEnd',			
          ChartLabelTrigger: 'CurrentMonthName',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentMonthLabels',         			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofWeek',			
        },			
        {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
        {			
          ID: 1000004,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Q1','Q2','Q3','Q4'],			
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],			
          ChartlabelPeriodEndMethodX: 'EndofQuarter',			
          ChartlabelPeriodEndMethodY: 'EndofQuarter'			
        },			
        {			
          ID: 1000005,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Quarter (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Quarter',			
          StartDateVariable: 'CurrentFiscalQStart',			
          EndDateVariable: 'CurrentFiscalQEnd',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentFiscalLabels',			
          ChartLabelTrigger: 'CurrentFiscalPeriod',			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
          			
        },			
        			
      ],			
      FiscalEndMonth: 1,			
      //March is index 2, on the "11 index/12 month" calendar			
      			
          ActualToday: '',			
          ActualNow: '',			
          DashboardActivities: [],			
          gradientDirection: 'horizontal',			
          height: 300,			
          width: 800,			
			
        CheckingFilters: false,			
        Todaydialog: false,			
        Today: '',			
        // TodayEnd: '',			
        DefaultColors: [],			
        StaticTimeLines: [			
                {			
                    Name: 'Months',			
                    title: 'This Year',			
                    id:1, 			
                    Filter: '',  			
                },			
                {			
                    Name: 'Weekdays',			
                    title: 'This Month',			
                    id:2,			
                    Filter: '', 			
                },			
                {			
                    Name: 'Fiscal Quarters',			
                    title: 'This Week',			
                    id:3,			
                    Filter: '', 			
                },			
                {			
                    Name: 'Fiscal Years',			
                    title: 'Last 3 Years',			
                    id:4,			
                    Filter: '', 			
                }			
            ],			
        ActiveDashboardTable: false,			
        ActiveDashboardArray: [],			
        ActiveDashboardComponent: '',			
        DashboardData: '',			
        DashboardName: '',			
        DashboardEntity: '',			
        FullArray: [],			
        LookupArrays: [],			
        HasData: false,			
        Owner: '',			
        WhocanShare: [],			
        WhocanView: [],			
        WhocanEdit: [],			
        GotEntities: false,			
        BusinessUnitsArray: [],			
      ActiveFilter: '',			
      BusinessUnitFilter: '',			
      OwningUserfilter: '',			
      FilterbyName: '',			
      // SystemEntities: [],			
      DashboardElements: [			
      ],			
      defaultItem: {			
        ColumnWidth: 'Full Column',			
          Title: 'Sales By Type',			
          Type: '',			
          Sort: 'Descending (Value)',			
          Description: 'Lorem Ipsum this is good!',			
          ValueField: '',			
          ValueType: 'Count',			
          ValueCurrency: '',			
          EntityRelatedfield: '',			
          Entity: '',			
          ChartBGObj: {hex: '#2196F3'},			
          Content: [			
              			
          ],			
          FullArray: [],			
          HasData: false,			
      },			
      editedItem: {			
          			
      },			
      editedIndex: -1,			
    }			
    			
  },			
			
  mounted() {			
    			
  },		
  created(){  			
    	console.log('this.PushedElmnt',this.PushedElmnt,this.SystemEntities,this.RouteObj)		
      //this.GetFunnelChart()			
      if(this.PushedElmnt){			
        this.LoadingItems = true			
      }			
      this.PrepareDateFilterOptions()			
      this.$emit('ActivateStandbyDialog',true)			
      for(var prop in this.$vuetify.theme.themes.light){   			
        if (!this.DefaultColors[prop]) {                       			
            this.DefaultColors.push(this.$vuetify.theme.themes.light[prop])			
        }			
    }			
      			
    document.body.scrollTop = 0;			
    document.documentElement.scrollTop = 0;			
    window.onload = setTimeout(() => {this.CheckRouting()},1500)			
  },			
  computed: {
    RouteObj(){
      return this.$route
    },
    ActiveSuiteApp(){
      return this.$store.state.ActiveSuiteApp
    },
    FinalSystemEntities(){
      let arr1 = this.SystemEntities
        return arr1
    },
    Dashboardid(){
      return this.ActiveSession ? this.ActiveSession.id : this.$route.params.slug
    },
    CanEditDashboard(){
      return this.ViewOnly && this.HasData && this.UserinWhocanEdit || this.ViewOnly && this.HasData && this.Owner && this.Owner.id === this.userLoggedIn.id
    },
    UserinWhocanEdit(){
      return this.WhocanEdit.includes(this.userLoggedIn.id)
    },
    UserinWhocanShare(){
      return this.WhocanShare.includes(this.userLoggedIn.id)
    },
    StoreCurrencyNames(){
      return this.$store.state.Currencies.map(curr => {
        return curr.Currency
      })
    }	,
    Todayby2AM(){
      let d = new Date(this.ThisToday)
      d.setHours(2)
      return d
    },
    Todayby4AM(){
      let d = new Date(this.ThisToday)
      d.setHours(4)
      return d
    },
    Todayby6AM(){
      let d = new Date(this.ThisToday)
      d.setHours(6)
      return d
    },
    Todayby8AM(){
      let d = new Date(this.ThisToday)
      d.setHours(8)
      return d
    },
    Todayby10AM(){
      let d = new Date(this.ThisToday)
      d.setHours(10)
      return d
    },
    Todayby12PM(){
      let d = new Date(this.ThisToday)
      d.setHours(12)
      return d
    },
    Todayby14PM(){
      let d = new Date(this.ThisToday)
      d.setHours(14)
      return d
    },
    Todayby16PM(){
      let d = new Date(this.ThisToday)
      d.setHours(16)
      return d
    },
    Todayby18PM(){
      let d = new Date(this.ThisToday)
      d.setHours(18)
      return d
    },
    Todayby20PM(){
      let d = new Date(this.ThisToday)
      d.setHours(20)
      return d
    },
    Todayby22PM(){
      let d = new Date(this.ThisToday)
      d.setHours(22)
      return d
    },
    ThisToday(){
      return new Date(this.Today)
    },	
    ThisTodayEnd(){
      return this.EndofDay(this.Today)
    },
    WindowHeight(){
        return window.innerHeight
      },
    UsersforProperties(){
      return this.UsersArray.map(user =>{
        let userobj = {
          Full_Name: user.Full_Name,
          Name: user.Name,
          Surname: user.Surname,
          id: user.id,
          Email: user.Email
        }
        return userobj
      })
    }	,
    UsersArray(){
      return this.$store.state.Users
    },
    DashboardEntityid(){
      if(this.DashboardEntity && this.DashboardEntity.id){
        return this.DashboardEntity.id
      }
      else{
        return this.DashboardEntity
      }
    },
    DashboardEntityStatus(){
      let statusobj = this.DashboardEntity.AllFields.find(obj => obj.id === 'Status')
      return statusobj.Options
    },
    DashboardEntityComponent() {			
      if(this.DashboardEntity){			
        return DataCollectionViewComponent			
      }			
			
		},	
    EntityHasbusinessUnits(){			
      if(this.DashboardEntity){			
        let bufield = this.DashboardEntity.AllFields.find(obj => obj.id === 'Business_Unit')			
        if(bufield){			
          return true			
        }			
        else{			
          return false			
        }			
      }			
      else{			
        return false			
      }			
      			
    },			
    DataSetHasRecordOwner(){			
      if(this.DashboardEntity){			
        return this.DashboardEntity.datasethasrecordowner			
      }			
    },			
    testdate(){			
      var d = new Date('2021-04-04')			
      d.setHours(23,59,59,999)			
      return d			
    },			
    newtestdate(){			
      			
      return this.EndofWeek(this.ComputedToday)			
    },			
    LibraryColors(){			
      let colors = [			
        'red',			
        'pink',			
        'purple',			
        'deep-puprle',			
        'indigo',			
        'blue',			
        'light-blue',			
        'cyan',			
        'teal',			
        'green',			
        'light-green',			
        'yellow',			
        'amber',			
        'orange',			
        'deep-orange',			
        'brown',			
        'blue-grey',			
        'grey'			
      ]			
      let variations = [			
        ' lighten-5',			
        ' lighten-4',			
        ' lighten-3',			
        ' lighten-2',			
        ' lighten-1',			
        '',			
        ' darken-1',			
        ' darken-2',			
        ' darken-3',			
        ' darken-4',			
        ' darken-5',			
      ]			
      let finalarray = []			
      variations.map(vary => {			
        colors.map(col => {			
          let obj = col+vary			
          finalarray.push(obj)			
        })			
      })			
      return finalarray			
			
    },			
    DashboardBGColor(){			
      if(!this.BGColor){			
        return 'grey lighten-3'			
      }			
      else{			
        return this.BGColor			
      }			
    },			
    ActivityPreFilterFields(){			
      return [{			
        id: 'activitytype',			
            Name: 'activitytype',			
            DisplayName: 'Activity Type',			
            IsHeader: true,			
            IsMandatory: true,			
            SystemRequired: true,			
            Type: 'Static Option Set',			
            Options: [			
              {			
                ID: 1000001,			
                Name: 'Task'			
              },			
              {			
                ID: 1000002,			
                Name: 'Appointment'			
              },			
              {			
                ID: 1000003,			
                Name: 'Email'			
              },			
              {			
                ID: 1000004,			
                Name: 'Phone Call'			
              },			
              ],            			
            UsedonForm: true			
          }]			
    },			
    CheckButton(){			
      return 'Create'			
    },			
    RootElementOptions(){			
      let array = []			
      let activityobj = {			
        ConfigName: 'Activities in Timeline',			
        ConfigID: 1000001,			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.ActivitiesinTimeline			
        }),			
        HasChart: true,			
        HasDataContext: true,			
        ShowTitle: false,			
        ChartPosition: 'Top',			
        DataContextStyle: 'List',			
        ChartType: 'bar',			
        ChartLabelsfromTimeline: true			
        			
      }			
      array.push(activityobj)			
      let datatimelineobj = {			
        ConfigID: 1000002,			
        ConfigName: 'Data in Timeline',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Top',			
        ChartLabelsfromTimeline: true,			
        DataContextStyle: 'List',			
        ChartType: 'line'			
      }			
      array.push(datatimelineobj)			
      let datachartscontextobj = {			
        ConfigID: 1000003,			
        ConfigName: 'Charts and Context',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),			
        ChartType: 'pie',			
        ShowTitle: true,			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Right',			
        DataContextStyle: 'List'			
      }			
      array.push(datachartscontextobj)			
      let dataprocessobj = {			
        ConfigID: 1000004,			
        ConfigName: 'Data in Process',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.DatainTimeline			
        }),        			
        ShowTitle: true,			
        HasChart: true,			
        HasDataContext: false,			
        ChartPosition: '',			
        ChartLabelsfromProcess: true,			
        DataContextStyle: ''			
      }			
      if(this.DashboardEntity.EntityProcesses && this.DashboardEntity.EntityProcesses.length>0){			
        array.push(dataprocessobj)			
      }			
      let goalmeasurementobj = {			
        ConfigID: 1000005,			
        ConfigName: 'Goal Measurements',			
        Options: this.ElementOptionsbyRoot.filter(opt => {			
          return opt.GoalMeasurements			
        }),   			
        ChartType: 'line',     			
        ShowTitle: false,			
        ShowSummary: true,			
        ShowSummaryOnly: true,			
        HasChart: true,			
        HasDataContext: true,			
        ChartPosition: 'Top',			
        ChartLabelsfromTimeline: true,			
        DataContextStyle: 'List',			
        AccumulativeGraph: true,			
      }			
      if(this.DashboardEntity.GoalMeasurements && this.DashboardEntity.GoalMeasurements.length>0){			
        array.push(goalmeasurementobj)			
      }			
      			
      return array			
    },			
    ElementTypeOptions(){			
      return [			
        {			
          Name: 'line',			
          Icon: 'mdi-chart-line-variant' 			
        },			
        {			
          Name: 'bar',			
          Icon: 'mdi-chart-bar' 			
        },			
        {			
          Name: 'pie',			
          Icon: 'mdi-chart-pie' 			
        },			
         {			
          Name: 'doughnut',			
          Icon: 'mdi-chart-arc' 			
        },			
       			
      ]			
    },			
    currentTitle () {			
        switch (this.step) {			
          case 1: return 'What do you want to see?'			
          case 2: return 'Choose Content'		
          case 2: return 'Choose Data Type'	
          default: return 'Account created'			
        }			
      },			
			
    ElementOptionsbyRoot(){			
      let array = []			
      //console.log('now')			
      if(this.DashboardEntity && this.DashboardEntity.id){			
        let RelatedChild = this.FinalSystemEntities.find(obj => obj.id === 'Activities')			
        let PrimaryDateFilter = RelatedChild.AllFields.find(obj => obj.id === 'Due_Date')			
        let PreFilterField = RelatedChild.AllFields.find(obj => obj.id === 'Type')			
        let ChildRelationshipsField = {Name: 'Regarding_Record', DisplayName: 'Regarding Record',id: 'Regarding_Record',Type: 'WildCard'}		
        let taskprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')			
        let appointmentprefilter = PreFilterField.Options.find(obj => obj.Name === 'Task')			
        let ActivitiesOptions = [			
          {			
            Name: 'Tasks for '+this.DashboardEntity.DisplayName,			
            ActivitiesinTimeline: true,			
            PrimaryLabelType: 'Name of Child',			
            RelatedChild: RelatedChild,			
            PrimaryDateFilter: PrimaryDateFilter,			
            ValueType: 'Count',			
            ChildRelationshipsField: ChildRelationshipsField,			
            PreFilterField: PreFilterField,			
            PreFilter: taskprefilter			
          },			
          			
          {			
            Name: 'Appointments for '+this.DashboardEntity.DisplayName,			
            ActivitiesinTimeline: true,			
            PrimaryLabelType: 'Name of Child',			
            RelatedChild: RelatedChild,			
            PrimaryDateFilter: PrimaryDateFilter,			
            ValueType: 'Count',			
            ChildRelationshipsField: ChildRelationshipsField,			
            PreFilterField: PreFilterField,			
            PreFilter: appointmentprefilter 			
          }			
        ]			
        array = array.concat(ActivitiesOptions)			
        let fromtimelineobj = {			
            Name: this.DashboardEntity.DisplayName+' by Timeline',			
            PrimaryLabelType: 'From Timeline',			
            PrimaryLabelField: 'thisTimeline',			
            DatainTimeline: true			
        }			
        array.push(fromtimelineobj)			
        this.DashboardEntity.GoalMeasurements.map(goal => {			
          let goalobj = {			
            Name: goal.Name,			
            PrimaryLabelType: goal.PrimaryFilterType,			
            PrimaryLabelField: goal.PrimaryFilterField,			
            PrimaryFilterType: goal.PrimaryFilterType,			
            PrimaryFilterField: goal.PrimaryFilterField,			
            PrimaryDateFilter: goal.PrimaryDateFilter, 			
            ValueType: goal.ValueType,                       			
            GoalMeasurements: true,			
            GoalID: goal.id,			
        }			
        if(goal.ValueField){			
          goalobj.ValueField = goal.ValueField			
        }			
        if(goal.ValueCurrency){			
          goalobj.ValueCurrency = goal.ValueCurrency			
        }			
        if(goal.PreFilterField){			
          goalobj.PreFilterField = goal.PreFilterField			
        }			
        if(goal.PreFilter){			
          goalobj.PreFilter = goal.PreFilter			
        }			
        array.push(goalobj)			
        })			
        let optionsets = this.DashboardEntity.AllFields.filter(field => {			
          return field.Type === 'Option Set'			
        })			
        let lookups = this.DashboardEntity.AllFields.filter(field => {			
          return field.Type === 'Lookup'			
        })			
        let concatarray = optionsets.concat(lookups)			
        			
        let types = concatarray			
        .map(field => {			
          if(field.Type === 'Lookup'){			
            		
            let parentobj = this.FinalSystemEntities.find(obj => obj.id === field.RelatedBuildID)			
            let primaryfield = parentobj.AllFields.find(obj => obj.Primary === true)			
            console.log(field.id,field.RelatedBuildID,parentobj.id,primaryfield)	
            return {			
              Name: this.DashboardEntity.DisplayName+' by '+field.DisplayName+' ('+primaryfield.DisplayName+')',			
              DatainTimeline: true,			
              PrimaryLabelType: 'Lookup',			
              PrimaryLabelField: field,			
            }			
          }			
          else{			
            return {			
            Name: this.DashboardEntity.DisplayName+' by '+field.DisplayName,			
            PrimaryLabelType: 'Option Set',			
             PrimaryLabelField: field,			
            DatainTimeline: true 			
          }			
          }         			
        })			
        			
        array = array.concat(types)			
        let parenttypes = []			
        let parents = lookups.map(field => {			
        let parentobj = this.FinalSystemEntities.find(obj => obj.id === field.RelatedBuildID)			
          parentobj.OptionSets = parentobj.AllFields.filter(field => {			
            return field.Type === 'Option Set'			
          })			
          parentobj.ParentField = field			
          return parentobj			
        })			
			
        parents.map (parent => {			
          parent.OptionSets.map(field => {			
            let obj = {			
                Name: this.DashboardEntity.DisplayName+' by '+parent.DisplayName+' and '+field.DisplayName,			
                PrimaryLabelType: 'Option Set from Lookup',			
                PrimaryLabelLookup: parent.ParentField, 			
                PrimaryLabelField: field,			
                DatainTimeline: true			
              }			
              parenttypes.push(obj)			
          })			
        })			
        array = array.concat(parenttypes)			
      }			
      return array			
    },			
    RelatedChildEntities(){			
      return this.FinalSystemEntities.map(entity => {			
        entity.ChildLookupFields = entity.AllFields.filter(field => {			
          return field.Type === 'Lookup' && field.RelatedBuildID === this.DashboardEntity.id || field.Type === 'Dynamic Lookup'			
        })			
        return entity			
      })			
      .filter(entity => {			
        return entity.ChildLookupFields.length>0			
      })			
    },			
    SaveDashboardElements(){			
      let array = this.DashboardElements.map(row => {			
        let obj = {			
          Type: 'Row'			
        }			
        obj.Elements = row.Elements.map(elmnt => {			
          let elmntobj = {}			
          if(elmnt.ChartBGObj){			
            elmntobj.ChartBGObj = elmnt.ChartBGObj			
          }			
          // if(elmnt.Preset){			
          //   elmntobj.Preset = elmnt.Preset			
          // }			
          elmntobj.ConfigID = elmnt.ConfigID			
          elmntobj.ConfigName = elmnt.ConfigName			
          if(elmnt.PrimaryFilterType){			
            elmntobj.PrimaryFilterType = elmnt.PrimaryFilterType			
          }			
          if(typeof elmnt.ShowSummary !== 'undefined'){			
            elmntobj.ShowSummary = elmnt.ShowSummary			
          }			
          if(typeof elmnt.ShowSummaryOnly !== 'undefined'){			
            elmntobj.ShowSummaryOnly = elmnt.ShowSummaryOnly			
          }			
          if(typeof elmnt.AccumulativeGraph !== 'undefined'){			
            elmntobj.AccumulativeGraph = elmnt.AccumulativeGraph			
          }			
          if(elmnt.GoalID){			
            elmntobj.GoalID = elmnt.GoalID			
          }			
          if(elmnt.GoalMeasurements){			
            elmntobj.GoalMeasurements = elmnt.GoalMeasurements			
          }			
        			
          if(elmnt.PrimaryFilterLookup){			
            elmntobj.PrimaryFilterLookup = elmnt.PrimaryFilterLookup			
          }			
          if(elmnt.PrimaryFilterField){			
            elmntobj.PrimaryFilterField = elmnt.PrimaryFilterField			
          }			
          if(elmnt.PrimaryLabelType){			
            elmntobj.PrimaryLabelType = elmnt.PrimaryLabelType			
          }			
          if(elmnt.PrimaryLabelLookup){			
            elmntobj.PrimaryLabelLookup = elmnt.PrimaryLabelLookup			
          }			
          if(elmnt.PrimaryLabelField){			
            elmntobj.PrimaryLabelField = elmnt.PrimaryLabelField			
          }			
          if(elmnt.PreFilterField){			
            elmntobj.PreFilterField = elmnt.PreFilterField			
          }			
          if(elmnt.PreFilter){			
            elmntobj.PreFilter = elmnt.PreFilter			
          }			
          if(elmnt.SecondaryFilterField){			
            elmntobj.SecondaryFilterField = elmnt.SecondaryFilterField			
          }			
          if(elmnt.RelatedChild && elmnt.RelatedChild.id){			
            elmntobj.RelatedChild = elmnt.RelatedChild.id			
          }			
          if(elmnt.RelatedChild && !elmnt.RelatedChild.id){			
            elmntobj.RelatedChild = elmnt.RelatedChild			
          }			
          			
          if(elmnt.ChildRelationshipsField){			
            elmntobj.ChildRelationshipsField = elmnt.ChildRelationshipsField			
          }			
          if(elmnt.ChartType){			
            elmntobj.ChartType = elmnt.ChartType			
          }			
          if(elmnt.ColumnWidth){			
            elmntobj.ColumnWidth = elmnt.ColumnWidth			
          }			
          if(elmnt.PrimaryDateFilter){			
            elmntobj.PrimaryDateFilter = elmnt.PrimaryDateFilter			
          }			
          if(elmnt.Description){			
            elmntobj.Description = elmnt.Description			
          }			
          if(elmnt.ChartLabelsfromTimeline){			
            elmntobj.ChartLabelsfromTimeline = elmnt.ChartLabelsfromTimeline			
          }			
          if(elmnt.ChartLabelsfromProcess){			
            elmntobj.ChartLabelsfromProcess = elmnt.ChartLabelsfromProcess			
          }			
          			
          if(elmnt.HasChart){			
            elmntobj.HasChart = elmnt.HasChart			
          }			
          if(elmnt.HasDataContext){			
            elmntobj.HasDataContext = elmnt.HasDataContext			
          }			
          if(elmnt.ShowTitle){			
            elmntobj.ShowTitle = elmnt.ShowTitle			
          }			
          if(elmnt.ChartPosition){			
            elmntobj.ChartPosition = elmnt.ChartPosition			
          }			
          if(elmnt.DataContextStyle){			
            elmntobj.DataContextStyle = elmnt.DataContextStyle			
          }			
          			
          if(elmnt.EntityRelatedfield){			
            elmntobj.EntityRelatedfield = elmnt.EntityRelatedfield			
          }			
          if(elmnt.PipelineProcess && elmnt.PipelineProcess.id){			
            elmntobj.PipelineProcess = elmnt.PipelineProcess.id			
          }			
          else if(elmnt.PipelineProcess && !elmnt.PipelineProcess.id){			
            elmntobj.PipelineProcess = elmnt.PipelineProcess			
          }			
          if(elmnt.ActivityType){			
            elmntobj.ActivityType = elmnt.ActivityType			
          }			
          if(elmnt.ActivityDateFilter){			
           elmntobj.ActivityDateFilter = elmnt.ActivityDateFilter			
          }			
           			
          if(elmnt.SublabelField){			
            elmntobj.SublabelField = elmnt.SublabelField			
          }			
          if(elmnt.SublabelFromRelatedLookup){			
            elmntobj.SublabelFromRelatedLookup = elmnt.SublabelFromRelatedLookup			
          }			
          if(elmnt.SublabelFromRelatedChild){			
            elmntobj.SublabelFromRelatedChild = elmnt.SublabelFromRelatedChild			
          }			
          elmnt.FullArray = []			
          elmnt.LookupArrays = []			
          if(elmnt.Sort){			
            elmntobj.Sort = elmnt.Sort			
          }			
          if(elmnt.TimeFilterOption){			
            elmntobj.TimeFilterOption = elmnt.TimeFilterOption			
          }			
          if(elmnt.Title){			
            elmntobj.Title = elmnt.Title			
          }			
          if(elmnt.Preset){			
            elmntobj.Type = elmnt.Preset			
          }			
          if(elmnt.ValueCurrency){			
            elmntobj.ValueCurrency = elmnt.ValueCurrency			
          }			
          if(elmnt.ValueField){			
            elmntobj.ValueField = elmnt.ValueField			
          }			
          if(elmnt.ValueType){			
            elmntobj.ValueType = elmnt.ValueType			
          }			
			
          return elmntobj			
        })			
        return obj			
      })			
      return array			
                  			
    },			
    //if ChartLabelsisDynamic then DynamicChartLabelsProp could be			
    CurrentFiscalLabels(){			
      //to return the months of the quarter. But possible return as objects for looping purposes, TBD			
      let variabletableobj = {			
        ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
        ChartLabelsVariables: ['LastJanuaryStart','LastFebruaryStart','LastMarchStart','LastAprilStart','LastMayStart','LastJuneStart','LastJulyStart','LastAugustStart','LastSeptemberStart','LastOctoberStart','LastNovemberStart','LastDecemberStart',			
        'ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
      }			
      let Qvarobj = {			
        Labels: [],			
        ChartLabelsVariables : []			
      }			
      console.log(this.CurrentFiscalQuarter)			
      if(this.CurrentFiscalQuarter === 'Quarter 1'){			
        let arr = [0,1,2]			
        arr.map((number,i) => {			
          let index = number+this.FiscalEndMonth+1			
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]			
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]			
        })			
        //console.log(Qvarobj)			
        return Qvarobj			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){			
         let arr = [2,4,5]			
        arr.map((number,i) => {			
          let index = number+this.FiscalEndMonth+1			
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]			
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]			
        })			
        //console.log(Qvarobj)			
        return Qvarobj			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){			
         let arr = [6,7,8]			
        arr.map((number,i) => {			
          let index = number+this.FiscalEndMonth+1			
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]			
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]			
        })			
        //console.log(Qvarobj)			
        return Qvarobj			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 4'){			
         let arr = [9,10,11]			
        arr.map((number,i) => {			
          let index = number+this.FiscalEndMonth+1			
          Qvarobj.Labels[i] = variabletableobj.ChartLabels[index]			
          Qvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[index]			
        })			
        //console.log(Qvarobj)			
        return Qvarobj			
      }			
    },			
    CurrentMonthLabels(){			
      //to return the weeks of the month. But possible return as objects for looping purposes, TBD			
       let variabletableobj = {			
        ChartLabels: ['Week 1','Week 2','Week 3','Week 4','Week 5'],          			
        ChartLabelsVariables: ['MonthWeek1Start','MonthWeek2Start','MonthWeek3Start','MonthWeek4Start','MonthWeek5Start'],			
      }			
      let Wvarobj = {			
        Labels: [],			
        ChartLabelsVariables : []			
      }			
      let length = 4			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setDate(firstdayofmonth+28)			
      d.setHours(0,0,0,0);			
      let week5month = d.getMonth()			
      if(week5month === month){			
        length = 5			
      }			
      for(var i = 0; i < length; i++){			
        Wvarobj.Labels[i] = variabletableobj.ChartLabels[i]			
        Wvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[i]			
      }			
      return Wvarobj			
    },			
    MonthWeek1Start(){			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek2Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek1Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek3Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek2Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek4Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek3Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek5Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek4Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    // MonthWeek1Start(){			
    //   let newtoday = new Date(this.Today)			
    //   var firstdayofmonth = 1;			
    //   var year = newtoday.getFullYear();			
    //   var month = newtoday.getMonth();			
    //   var d = new Date(year, month, firstdayofmonth)			
    //   d.setHours(0,0,0,0);			
    //   return d			
    // },			
    // MonthWeek2Start(){			
    //   let newtoday = new Date(this.Today)			
    //   var firstdayofmonth = 1;			
    //   var year = newtoday.getFullYear();			
    //   var month = newtoday.getMonth();			
    //   var d = new Date(year, month, firstdayofmonth)			
    //   d.setDate(firstdayofmonth+7)			
    //   d.setHours(0,0,0,0);			
    //   return d			
    // },			
    // MonthWeek3Start(){			
    //   let newtoday = new Date(this.Today)			
    //   var firstdayofmonth = 1;			
    //   var year = newtoday.getFullYear();			
    //   var month = newtoday.getMonth();			
    //   var d = new Date(year, month, firstdayofmonth)			
    //   d.setDate(firstdayofmonth+14)			
    //   d.setHours(0,0,0,0);			
    //   return d			
    // },			
    // MonthWeek4Start(){			
    //   let newtoday = new Date(this.Today)			
    //   var firstdayofmonth = 1;			
    //   var year = newtoday.getFullYear();			
    //   var month = newtoday.getMonth();			
    //   var d = new Date(year, month, firstdayofmonth)			
    //   d.setDate(firstdayofmonth+21)			
    //   d.setHours(0,0,0,0);			
    //   return d			
    // },			
    // MonthWeek5Start(){			
    //   let newtoday = new Date(this.Today)			
    //   var firstdayofmonth = 1;			
    //   var year = newtoday.getFullYear();			
    //   var month = newtoday.getMonth();			
    //   var d = new Date(year, month, firstdayofmonth)			
    //   d.setDate(firstdayofmonth+28)			
    //   d.setHours(0,0,0,0);			
    //   return d			
    // },			
    LastJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastFebruaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 1;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastMarchStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 2;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastAprilStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 3;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastMayStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 4;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastJuneStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 5;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastJulyStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 6;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastAugustStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 7;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastSeptemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 8;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastOctoberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 9;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastNovemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 10;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    LastDecemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year-1, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisFebruaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 1;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMarchStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 2;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAprilStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 3;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMayStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 4;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJuneStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 5;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJulyStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 6;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAugustStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 7;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisSeptemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 8;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisOctoberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 9;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisNovemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 10;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisDecemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },
    CurrentFiscalQuarterShort(){
      return this.CurrentFiscalQuarter.split('Quarter').join('Q')
    },	
    CurrentFiscalQuarterFull(){			
      return 'Fiscal '+this.CurrentFiscalYear+' || '+this.CurrentFiscalQuarter			
    },
    CurrentFiscalYearShort(){
      return this.CurrentFiscalYear.split('Fiscal').join('F')
    },
    CurrentFiscalandQuarterShortDisplay(){
      return this.CurrentFiscalQuarterFull
      //.split('Quarter').join('Q').split('Fiscal').join('F')
    },
    CurrentFiscalQuarter(){			
      if(this.ComputedToday < this.FiscalQ1End){			
        return 'Quarter 1'			
      }			
      else if(this.ComputedToday < this.FiscalQ2Start){			
        return 'Quarter 1'			
      }			
      else if(this.ComputedToday < this.FiscalQ2End){			
        return 'Quarter 2'			
      }			
      else if(this.ComputedToday < this.FiscalQ3Start){			
        return 'Quarter 2'			
      }			
      else if(this.ComputedToday < this.FiscalQ3End){			
        return 'Quarter 3'			
      }			
      else if(this.ComputedToday < this.FiscalQ4Start){			
        return 'Quarter 3'			
      }			
      else if(this.ComputedToday < this.FiscalQ4End){			
        return 'Quarter 4'			
      }			
      else {			
       return 'Quarter 4'			
      }			
    },			
    CurrentFiscalYear(){			
      return this.FiscalNextStart.getFullYear()			
    },			
    CurrentFiscalQStart(){			
      if(this.CurrentFiscalQuarter === 'Quarter 1'){			
        return this.FiscalQ1Start			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){			
        return this.FiscalQ2Start			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){			
        return this.FiscalQ3Start			
      }      			
      else {			
        return this.FiscalQ4Start			
      }			
    },			
    CurrentFiscalQEnd(){			
      if(this.CurrentFiscalQuarter === 'Quarter 1'){			
        return this.FiscalQ1End			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 2'){			
        return this.FiscalQ2End			
      }			
      else if(this.CurrentFiscalQuarter === 'Quarter 3'){			
        return this.FiscalQ3End			
      }			
      else {			
        return this.FiscalQ4End			
      }			
    },			
    FiscalQ1Start(){			
      var year = this.FiscalStart.getFullYear();			
      var month = this.FiscalStart.getMonth();			
      var day = this.FiscalStart.getDate();			
      return new Date(year, month, day);			
    },			
    FiscalQ1End(){			
      var year = this.FiscalQ1Start.getFullYear();			
      var month = this.FiscalQ1Start.getMonth();			
      var day = this.FiscalQ1Start.getDate();			
      return new Date(year, month+3, day);			
    },			
    FiscalQ2Start(){			
      var year = this.FiscalQ1End.getFullYear();			
      var month = this.FiscalQ1End.getMonth();			
      var day = this.FiscalQ1End.getDate();			
      return new Date(year, month, day);			
    },			
    FiscalQ2End(){			
      var year = this.FiscalQ2Start.getFullYear();			
      var month = this.FiscalQ2Start.getMonth();			
      var day = this.FiscalQ2Start.getDate();			
      return new Date(year, month+3, day);			
    },			
    FiscalQ3Start(){			
      var year = this.FiscalQ2End.getFullYear();			
      var month = this.FiscalQ2End.getMonth();			
      var day = this.FiscalQ2End.getDate();			
      return new Date(year, month, day);			
    },			
    FiscalQ3End(){			
      var year = this.FiscalQ3Start.getFullYear();			
      var month = this.FiscalQ3Start.getMonth();			
      var day = this.FiscalQ3Start.getDate();			
      return new Date(year, month+3, day);			
    },			
    FiscalQ4Start(){			
      var year = this.FiscalQ3End.getFullYear();			
      var month = this.FiscalQ3End.getMonth();			
      var day = this.FiscalQ3End.getDate();			
      return new Date(year, month, day);			
    },			
    FiscalQ4End(){			
      var year = this.FiscalQ4Start.getFullYear();			
      var month = this.FiscalQ4Start.getMonth();			
      var day = this.FiscalQ4Start.getDate();			
      return new Date(year, month+3, day);			
    },			
    FiscalStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = this.FiscalEndMonth+1;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      //d.setMonth(this.FiscalEndMonth)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      if(this.ComputedToday<d){			
        let newdate = new Date(year-1, month, day)			
        newdate.setDate(1)			
        //console.log(newdate)			
        return newdate			
      }			
      else{			
        return d			
      }			
      			
    },			
    FiscalNextStart(){			
      var year = this.FiscalStart.getFullYear();			
      var month = this.FiscalStart.getMonth();			
      var day = this.FiscalStart.getDate();			
      return new Date(year + 1, month, day);			
    },			
    DashboardEntityPrimaryFieldName(){			
      return this.DashboardEntityPrimaryField.Name			
    },			
    DashboardEntityPrimaryField(){			
      if(this.DashboardEntity){			
        let obj = this.DashboardEntity.AllFields.find(obj => obj.Primary)			
        return obj			
      }			
    },			
      DashboardEntityTypes(){			
        if(this.DashboardEntity && this.DashboardEntity.HasActivities){			
          return ['Card Roll','Pie Chart','Static Timeline Filter','Pipeline','Activities']			
        }			
        else{			
          return ['Card Roll','Pie Chart','Static Timeline Filter','Pipeline']			
        }			
      },			
      DashboardActivityTypes(){			
        return ['Task','Appointment']			
      },			
      FunnelSublabelfields(){			
          return this.DashboardEntity.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' && field.Options.length < 8			
          })			
      },			
      ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisTuesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+2))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisWednesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+3))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisThursday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+4))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisFriday(){			
      let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+5))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSaturday(){			
       let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+6))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      LastweekLastDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      NextweekFirstDate(){			
        let newtoday = new Date(this.Today)			
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek + 7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      CurrentYear(){			
          return this.ComputedToday.getFullYear()			
      },			
      ThreeYearsStartDate(){			
          let threeyrsback = this.CurrentYear-2			
          let date = new Date(threeyrsback.toString()+'-01-01')			
          date.setHours(0,0,0,0)			
          return date			
      },			
      CurrentyearStartDate(){			
          let date = new Date(this.CurrentYear.toString()+'-01-01')			
          date.setHours(0,0,0,0)			
          return date			
      },			
          			
      ComputedToday(){			
          let date = new Date(this.Today)			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ComputedSystemEntities(){			
          return this.FinalSystemEntities			
          .map(entity => {			
              entity.RelatedFields = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Lookup' || field.Type === 'Option Set'			
              })			
              entity.NumberFields = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'			
              })			
              entity.DateFields = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Date'			
              })			
              entity.OptionSets = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Option Set'			
              })			
              entity.RadioGroups = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Radio Group'			
              })
              entity.LookupFields = entity.AllFields			
              .filter(field => {			
                  return field.Type === 'Lookup'			
              })			
              return entity			
          })			
      },			
      ViewOnly(){			
          return this.$route.name === 'CustomDashboard' || this.PushedElmnt	|| this.ActiveSession		
      },			
      userIsAdmin () {			
            return this.$store.state.IsAdmin			
        },
        userIsEagleViewer () {
      return this.$store.state.IsEagleView
    },			
        userLoggedIn () {			
            return this.$store.getters.user			
        },			
        userBUID () {			
            return this.$store.getters.userBUID			
        },			
        userBUChildren () {			
            return this.$store.getters.userBUChildren			
        },			
        userBUParents () {			
            return this.$store.getters.userBUParents			
        },			
      ComputedDashboardElements(){			
          if(this.DashboardEntity && this.HasData && this.GotEntities){			
            let array = this.DashboardElements			
          return array			
          .map(row => {			
            row.Elements = row.Elements.map(elmnt => {		
              //console.log('elmnt',elmnt)	
              if(elmnt.PrimaryFilterType === 'Lookup' && elmnt.PrimaryFilterField){	
                	
                if(elmnt.PrimaryFilterField.RelatedBuildID === 'Users' || elmnt.PrimaryFilterField.RelatedRecord === 'Users'){			
                  elmnt.FilterArray = this.UsersArray			
                }			
                else{			
                  let lookupobj = this.LookupArrays.find(obj => obj.Field === elmnt.PrimaryFilterField.id)			
                  elmnt.FilterArray = lookupobj.Array.map(item => {			
                    item.FieldName = item[elmnt.PrimaryFilterField.LookupFieldName]			
                    return item			
                  })                  			
                }			
                			
              }			
              const currencyformatter = this.PrepareCurrency(elmnt)			
              elmnt = this.PrepareElementComponents(elmnt)			
              let ChartlabelPeriodEndMethodX = '' 			
              let ChartlabelPeriodEndMethodY = ''			
              let ChartLabelsVariables = ''			
              let ChartLabels = ''			
              if(elmnt.TimeFilterOption && !elmnt.TimeFilterOption.ChartLabelsisDynamic){			
                ChartlabelPeriodEndMethodX = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodX			
                ChartlabelPeriodEndMethodY = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodY			
                ChartLabelsVariables = elmnt.TimeFilterOption.ChartLabelsVariables			
                ChartLabels = elmnt.TimeFilterOption.ChartLabels			
                .map((label,i) => {			
                  let obj = {			
                    LabelName: label,			
                    ChartLabelID: i			
                  }			
                  if(elmnt.GoalMeasurements){   			
                      obj.min = this[ChartLabelsVariables[i]]			
                      obj.max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[i]])			
                      //console.log(obj)   			
                      if(!elmnt.GoalTargets){			
                        elmnt = this.GetElementTargets(elmnt)			
                      }             			
                      obj.Targets = elmnt.GoalTargets.filter(target => {return target.StartDate.getTime() === obj.min.getTime() && target.EndDate.getTime() === obj.max.getTime()})			
                      if(elmnt.ActivePrimaryFilter){			
                        obj.Targets = obj.Targets			
                        .filter(target => {			
                          if(elmnt.PrimaryFilterType === 'Lookup'){			
                          return target.Ownerid === elmnt.ActivePrimaryFilter.id			
                          }			
                        })			
                      }			
                       let objtargetvalues = obj.Targets.map(target => {return target.Target})			
                      obj.TargetValues = objtargetvalues.reduce((a, b) => a + b, 0)			
                      //console.log(obj)			
                      }			
                      			
                       			
                  return obj			
                })			
              }			
              else if(elmnt.TimeFilterOption && elmnt.TimeFilterOption.ChartLabelsisDynamic){			
                ChartlabelPeriodEndMethodX = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodX			
                ChartlabelPeriodEndMethodY = elmnt.TimeFilterOption.ChartlabelPeriodEndMethodY			
                ChartLabelsVariables = this[elmnt.TimeFilterOption.DynamicChartLabelsProp].ChartLabelsVariables			
                ChartLabels = this[elmnt.TimeFilterOption.DynamicChartLabelsProp].Labels			
                .map((label,i) => {			
                  let obj = {			
                    LabelName: label,			
                    ChartLabelID: i			
                  }			
                  			
                  if(elmnt.GoalMeasurements){   			
                      obj.min = this[ChartLabelsVariables[i]]			
                      obj.max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[i]])   			
                                 			
                      obj.Targets = elmnt.GoalTargets.filter(target => {return target.StartDate.getTime() === obj.min.getTime() && target.EndDate.getTime() === obj.max.getTime()})			
                       let objtargetvalues = obj.Targets.map(target => {return target.Target})			
                      obj.TargetValues = objtargetvalues.reduce((a, b) => a + b, 0)			
                      //console.log(obj)			
                      }			
                      			
                       			
                  return obj			
                })			
              }			
              if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                if(!elmnt.PipelineProcess.id){			
                  let process = this.DashboardEntity.EntityProcesses.find(obj => obj.id === elmnt.PipelineProcess)			
                  if(process){			
                    elmnt.PipelineProcess = process			
                  }			
                  // console.log(typeof this.DashboardEntity.EntityProcesses,this.DashboardEntity.EntityProcesses.length,this.DashboardEntity.EntityProcesses)			
                }			
                if(elmnt.PipelineProcess && elmnt.PipelineProcess.id){			
                  let array = JSON.parse(JSON.stringify(elmnt.PipelineProcess.ProcessStages))			
                  ChartLabels = array.map(item => {			
                  let obj = {			
                      ChartLabelID: item.Number,			
                      LabelName: item.Name			
                  }                 			
                  return obj			
                })			
                }			
               			
              //  console.log(ChartLabels)			
              }			
              if(elmnt.ChartBGObj){			
                elmnt.ChartBG = elmnt.ChartBGObj.hex			
              }			
              // if(elmnt.Preset === 'Activities'){			
              //   elmnt = this.PrepareActivitiesElementOLD(elmnt)			
              // }			
              if(!elmnt.ChartType){			
                elmnt.ChartType = 'line'			
              }			
              let PrimaryDate = ''			
              let PrimaryDateSTRING = ''			
              if(elmnt.PrimaryDateFilter){			
               PrimaryDate = elmnt.PrimaryDateFilter.Name			
               PrimaryDateSTRING = elmnt.PrimaryDateFilter.Name+'filterdatestring' 			
              }			
              			
              // let PrimaryDateObj =  elmnt.PrimaryDateFilter.Name+'filterdateObj'			
              //So we could have a secondary date object as well. and filte rby that as well.			
              //secondary date becomes relevant now on child related. 			
              //activities by example, is "Due_Date". By default, but could be created date for that matter as well			
              //So you can then select the primary date as "estimated close date" meaning I see veh sales based on estimated close date matching primary date filter and options.			
              //But then we have secondary date.			
              //the bitch is, timeline and activities, because now ou have a timeline for veh sdales AND timeline for activities, you get?			
              //for veh sales estimated clsoed date this year, see activities, by this week.  			
			
			
              //Start by assigning RawData, then filtering by primary filter, datefilter (if applicabe), then timelinefilter if applicable			
              //We also assign contexttitle and contextid to each record			
              //we also assign rawvalue and contextvalue to each item 			
              let FullArray = []			
              let primaryfield = ''			
              if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.RelatedChild.id){			
                			//alert('this type')
                primaryfield = elmnt.RelatedChild.AllFields.find(obj => obj.Primary === true)			
                primaryfield = primaryfield.Name			
                if(elmnt.RelatedChild.id === 'Activities'){			
                    FullArray = this.DashboardActivities			
                    elmnt.IsActivities = true			
                }			
                else{			
                  FullArray = elmnt.ChildArray			
                }			
              }			
              else if(elmnt.GoalMeasurements){			
                FullArray = JSON.parse(JSON.stringify(this.FullArray))			
                // .map(record => {			
			
                // })			
              }			
              else{			
                FullArray = JSON.parse(JSON.stringify(this.FullArray))			
              }			
              //console.log('FullArray',FullArray,primaryfield,elmnt.RelatedChild)			
              elmnt.RawData = JSON.parse(JSON.stringify(FullArray))//First we do super filters for like BU if applicable, or Owner if applicable, or "status"			
              .filter(data => {			
                if(this.BusinessUnitFilter){			
                  return data.Business_Unitid === this.BusinessUnitFilter.id			
                }			
                //Filter by Active Super filter, which is array so it's tricky but I think they would be standard fields so BUSuperFilter etc, you can name them			
                else{			
                  return data			
                }                			
              })			
              .filter(data => {			
                if(this.OwningUserfilter){			
                  return data.Ownerid === this.OwningUserfilter.id			
                }			
                //Filter by Active Super filter, which is array so it's tricky but I think they would be standard fields so BUSuperFilter etc, you can name them			
                else{			
                  return data			
                }                			
              })   
              .filter(data => {			
                if(this.PrimaryStatusFilter){			
                  return data.Status && data.Status.ID === this.PrimaryStatusFilter.ID			
                }			
                //Filter by Active Super filter, which is array so it's tricky but I think they would be standard fields so BUSuperFilter etc, you can name them			
                else{			
                  return data			
                }                			
              }) 
              .filter(data => {			
                if(this.SecondaryStatusFilter){			
                  return data.Status_Reason && data.Status_Reason.ID === this.SecondaryStatusFilter.ID			
                }			
                //Filter by Active Super filter, which is array so it's tricky but I think they would be standard fields so BUSuperFilter etc, you can name them			
                else{			
                  return data			
                }                			
              })               			
              .filter(data => {			
                //Filter by ActivePrimaryLabel, which is what happens when we click "filter" on data context (where available)			
                return data			
              })//ActivityOwnerFilter			
              .filter(data => {			
                //console.log('data',data)
                if(elmnt.RelatedChild && elmnt.RelatedChild.id === 'Activities' && elmnt.ActivityOwnerFilter){			
                return data.Ownerid === elmnt.ActivityOwnerFilter.id			
                }			
                else{			
                  return data			
                }			
              })//PreFilterField              			
              .filter(data => {			
                // console.log(elmnt.PreFilter,elmnt.PreFilterField)		
                //console.log('data',data)
                if(elmnt.RelatedChild && elmnt.RelatedChild.id === 'Activities' && elmnt.ActivityOwnerFilter){			
                return data
                }	
                else if(elmnt.PreFilter && elmnt.PreFilterField){			
                  if(elmnt.PreFilterField.Type === 'Static Option Set'){			
                    return data[elmnt.PreFilterField.Name] === elmnt.PreFilter.Name			
                  }			
                  else if(elmnt.PreFilterField.Type === 'Option Set'){		
                    if(elmnt.SecondaryFilterField){
                      return data[elmnt.PreFilterField.Name] && data[elmnt.PreFilterField.Name].ID === elmnt.PreFilter.ID && data[elmnt.PreFilterField.Levels[1].FieldName] && data[elmnt.PreFilterField.Levels[1].FieldName].ID === elmnt.SecondaryFilterField.ID
                    }	
                    else{
                      return data[elmnt.PreFilterField.Name] && data[elmnt.PreFilterField.Name].ID === elmnt.PreFilter.ID
                    }
                    			
                  }				
                			
                }			
                else{			
                  return data			
                }			
              })//From Timeline fix to get context. because yes we get chart data and labels, but now context = labels...uhm			
              .map(data => {		
                 //console.log('data',data)	
                return data			
              })			
              //Now we assign dateobject			
              .filter(data => {			
                 //console.log('data',data)
                if(elmnt.ActivePrimaryFilter){			
                  if(elmnt.PrimaryFilterType === 'Option Set'){			
                    return data[elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterField.Name].ID === elmnt.ActivePrimaryFilter.ID			
                  }		
                  else if(elmnt.PrimaryFilterType === 'Radio Group'){	
                    let selectedgroups = []
                    if(data[elmnt.PrimaryFilterField.Name]){
                      selectedgroups = data[elmnt.PrimaryFilterField.Name].map(group => {
                        return group.ID
                      })
                    }
                    return data[elmnt.PrimaryFilterField.Name]			
                     && selectedgroups.includes(elmnt.ActivePrimaryFilter.ID)			
                  }	
                  else if(elmnt.PrimaryFilterType === 'Lookup'){			
                    			
                    return data[elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterField.Name].id === elmnt.ActivePrimaryFilter.id			
                  }			
                  else if(elmnt.PrimaryFilterType === 'Option Set from Lookup'){			
                    return data[elmnt.PrimaryFilterLookup.Name] && data[elmnt.PrimaryFilterLookup.Name][elmnt.PrimaryFilterField.Name]			
                     && data[elmnt.PrimaryFilterLookup.Name][elmnt.PrimaryFilterField.Name].ID === elmnt.ActivePrimaryFilter.ID			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){			
                    return data[primaryfield]			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){			
                    return data			
                  } 			
                  			
                }			
                else{			
                  return data			
                }			
                //Filter by primary type filter			
                			
              })//Now we assign dateobject			
              .map(arrayrecord => {			        
                 //console.log('data',arrayrecord)        
                if(arrayrecord[PrimaryDate] && typeof arrayrecord[PrimaryDate].toDate !== 'undefined'){			
                    arrayrecord[PrimaryDateSTRING] = this.TimestampFormatterSTRING(arrayrecord[PrimaryDate].toDate())			
                    arrayrecord.PrimaryDateObj = new Date(arrayrecord[PrimaryDateSTRING])			
                }	
                else if(arrayrecord[PrimaryDate] && typeof arrayrecord[PrimaryDate].seconds !== 'undefined'){
                  arrayrecord[PrimaryDateSTRING] = this.TimestampFormatterSTRING(new Date(arrayrecord[PrimaryDate].seconds*1000))	
                  arrayrecord.PrimaryDateObj = new Date(arrayrecord[PrimaryDate].seconds*1000) 
                }
                return arrayrecord			
              })              			
              .filter(arrayrecord => {			
                //console.log('data',arrayrecord) 
                if(elmnt.ActiveDateFilter){			
                  if(!elmnt.ActiveDateFilter.ChartLabelsisDynamic){			
                      let filterChartlabelPeriodEndMethodX = elmnt.ActiveDateFilter.ChartlabelPeriodEndMethodX			
                      let filterChartLabelsVariables = elmnt.ActiveDateFilter.ChartLabelsVariables			
                      let lastvariable = filterChartLabelsVariables.length-1                    			
                      let min = this[filterChartLabelsVariables[0]]			
                      let max = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])			
                      return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max                    			
                    }			
                    else if(elmnt.ActiveDateFilter.ChartLabelsisDynamic){			
                      let filterChartlabelPeriodEndMethodX = elmnt.ActiveDateFilter.ChartlabelPeriodEndMethodX			
                      let filterChartLabelsVariables = this[elmnt.ActiveDateFilter.DynamicChartLabelsProp].ChartLabelsVariables			
                      let lastvariable = filterChartLabelsVariables.length-1                    			
                      let min = this[filterChartLabelsVariables[0]]			
                      let max = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])			
                      return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max			
                    }			
                }			
                else{			
                  return arrayrecord			
                }			
               //elmnt.ActiveDateFilter			
                //So what I mean is, this is datefilter. I don't think it has anything to do with where chartlabels come from peanut!			
                //The NEXT one, we filter by chartlabeltimeline			
                			
              })//now we filter if ChartLabelsfromTimeline			
              .filter(arrayrecord => {			
                //console.log('data',arrayrecord) 
                if(ChartlabelPeriodEndMethodX && ChartlabelPeriodEndMethodY){                                      			
                    			
                    let lastvariable = ChartLabelsVariables.length-1                    			
                    let min = this[ChartLabelsVariables[0]]			
                    let max = this[ChartlabelPeriodEndMethodX](this[ChartLabelsVariables[lastvariable]])			
                    return arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max			
                  }			
                  else{			
                    return arrayrecord			
                  }			
              })//Now just a little step, for sublabelfields that are lookup, assign the record with ID prop			
              .map(arrayrecord => {	
                //console.log('data',arrayrecord) 		
                if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup' && elmnt.PrimaryLabelType !== 'Name of Child'){			
                  if(arrayrecord[elmnt.SublabelField.Name]){			
                    arrayrecord[elmnt.SublabelField.Name].ID = arrayrecord[elmnt.SublabelField.Name].id			
                  }			
                }			
                else if(elmnt.PrimaryLabelType === 'Name of Child'){			
                  let related = elmnt.ChildRelationshipsField			
                  if(arrayrecord[related]){			
                  			
                  let parentobj = this.FullArray.find(obj => obj.id === arrayrecord[related].id)			
                  // console.log(parentobj,elmnt.SublabelField)                  			
                    if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup' && !elmnt.SublabelFromRelatedChild){			
                      if(parentobj[elmnt.EntityRelatedfield.Name]){			
                        arrayrecord[elmnt.EntityRelatedfield.Name] = parentobj[elmnt.EntityRelatedfield.Name]			
                        arrayrecord[elmnt.EntityRelatedfield.Name].ID = arrayrecord[elmnt.EntityRelatedfield.Name].id			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Option Set' && !elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){			
                      // console.log(parentobj,parentobj[elmnt.SublabelField.Name])			
                      if(parentobj[elmnt.SublabelField.Name]){			
                        arrayrecord[elmnt.SublabelField.Name] = parentobj[elmnt.SublabelField.Name]			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Option Set' && elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){			
                      if(parentobj[elmnt.EntityRelatedfield.Name] && parentobj[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name]){			
                        arrayrecord[elmnt.EntityRelatedfield.Name] = parentobj[elmnt.EntityRelatedfield.Name]			
                        arrayrecord[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] = parentobj[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name]			
                      }			
                    }			
                    else if(elmnt.SublabelField && elmnt.SublabelFromRelatedChild){			
                      if(elmnt.SublabelField && elmnt.SublabelField.Type === 'Lookup'){			
                        if(arrayrecord[elmnt.SublabelField.Name]){			
                            arrayrecord[elmnt.SublabelField.Name].ID = arrayrecord[elmnt.SublabelField.Name].id			
                          }			
                      }			
                    }			
                    // else if(subalefromtimeline)			
                    //Nothing needs to happen here. because sublabel from timeline does NOT affect structure just need to return arrayrecord			
                    			
                  }			
			
                }			
                return arrayrecord			
              })              			
              //Assign ContextValue and RawValue DONE			
              .map(arrayrecord => {			
                //console.log('data',arrayrecord) 
                if(elmnt.ValueType === 'Count'){			
                  arrayrecord.RawValue = 1			
                  arrayrecord.ContextValue = 1			
                }			
                else if(elmnt.ValueType === 'Amount'){	
                  //console.log('elmnt.ValueField',elmnt.ValueField,arrayrecord,arrayrecord[elmnt.ValueField.Name],elmnt.ChartLabels)		
                  if(elmnt.ValueField && elmnt.ValueCurrency && arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = arrayrecord[elmnt.ValueField.Name]			
                    arrayrecord.ContextValue = currencyformatter.format(arrayrecord[elmnt.ValueField.Name])			
                  }		
                  else if(elmnt.ValueField && elmnt.ValueCurrency && !arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = 0			
                    arrayrecord.ContextValue = currencyformatter.format(0)			
                  }	
                  else if(elmnt.ValueField && !elmnt.ValueCurrency && arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = arrayrecord[elmnt.ValueField.Name]			
                    arrayrecord.ContextValue = arrayrecord[elmnt.ValueField.Name]			
                  }	
                  else if(elmnt.ValueField && !elmnt.ValueCurrency && !arrayrecord[elmnt.ValueField.Name]){			
                    arrayrecord.RawValue = 0		
                    arrayrecord.ContextValue = 0		
                  }
                }			
                return arrayrecord			
              })//Assign ContextID to each record based on label config - if lookup then it's .id, it option set then ID, if timeline then index			
              .map(arrayrecord => {			
                //console.log('data',arrayrecord) 
                if(!elmnt.ChartLabelsfromProcess){			
                if(elmnt.PrimaryLabelType === 'Option Set' && elmnt.PrimaryLabelField && arrayrecord[elmnt.PrimaryLabelField.Name] && !elmnt.ChartLabelsfromProcess){			
                  //console.log(elmnt.PrimaryLabelField.Options,arrayrecord[elmnt.PrimaryLabelField.Name])			
                  let titleobj = elmnt.PrimaryLabelField.Options.find(obj => obj.ID === arrayrecord[elmnt.PrimaryLabelField.Name].ID)			
                  arrayrecord.ContextTitle = titleobj ? titleobj.Name : 'undefined'
                  arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelField.Name].ID			
                  if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                  arrayrecord.ChartLabelID = arrayrecord.ContextID			
                  }			
                  else if(elmnt.ChartLabelsfromTimeline){			
                  ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                      let min = this[ChartLabelsVariables[chartlabelindex]]			
                      let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                        if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                          arrayrecord.ChartLabelID = chartlabelindex			
                        }        			
                  })                 			
                  }			
			
                }			
                else if(elmnt.PrimaryLabelType === 'Lookup' && elmnt.PrimaryLabelField && elmnt.PrimaryLabelField.Type === 'Lookup' && arrayrecord[elmnt.PrimaryLabelField.Name]){			
                  			
                  arrayrecord.ContextTitle = arrayrecord[elmnt.PrimaryLabelField.Name][elmnt.PrimaryLabelField.LookupFieldName]			
                  arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelField.Name].id			
                  if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                  arrayrecord.ChartLabelID = arrayrecord.ContextID			
                  }			
                  else if(elmnt.ChartLabelsfromTimeline){			
                 			
                  ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                      let min = this[ChartLabelsVariables[chartlabelindex]]			
                      let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])                      			
                      			
                        if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                          arrayrecord.ChartLabelID = chartlabelindex			
                        }        			
                  })                 			
                  }			
			
                }			
                //arrayrecord[elmnt.PrimaryLabelField.Name]
                else if(elmnt.PrimaryLabelType === 'Option Set from Lookup' && elmnt.PrimaryLabelLookup && arrayrecord[elmnt.PrimaryLabelLookup.Name]){
                  let lookupobj = this.LookupArrays.find(obj => obj.Field === elmnt.PrimaryLabelLookup.id)
                  if(lookupobj){
                    let match = lookupobj.Array.find(obj => obj.id === arrayrecord[elmnt.PrimaryLabelLookup.Name].id)
                    if(match){
                      arrayrecord[elmnt.PrimaryLabelLookup.Name] = match
                    }                    
                    if(arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name]){
                    
                    let titleobj = elmnt.PrimaryLabelField.Options.find(obj => obj.ID === arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name].ID)			
                    arrayrecord.ContextTitle = titleobj.Name			
                    arrayrecord.ContextID = arrayrecord[elmnt.PrimaryLabelLookup.Name][elmnt.PrimaryLabelField.Name].ID			
                    if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                      elmnt.TargetsbyLabel = []			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])                      			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }	
                    }
                  }                  
                }			
                 else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){			
                    arrayrecord.ContextTitle = arrayrecord[primaryfield]			
                    arrayrecord.ContextID = arrayrecord.id			
                   if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }                   			
                  } 			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){			
                    arrayrecord.ContextTitle = elmnt.TimeFilterOption.Name			
                    arrayrecord.ContextID = elmnt.TimeFilterOption.ID			
                   if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){			
                    arrayrecord.ChartLabelID = arrayrecord.ContextID			
                    }			
                    else if(elmnt.ChartLabelsfromTimeline){			
                    ChartLabelsVariables.map((chartlabel,chartlabelindex) => {			
                        let min = this[ChartLabelsVariables[chartlabelindex]]			
                        let max = this[ChartlabelPeriodEndMethodY](this[ChartLabelsVariables[chartlabelindex]])			
                          if(arrayrecord.PrimaryDateObj >= min && arrayrecord.PrimaryDateObj <= max){			
                            arrayrecord.ChartLabelID = chartlabelindex			
                          }        			
                    })                 			
                    }                   			
                  } 			
                  			
                }			
                else if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                    //We already assigned Chartlabels now so we must do something with it here			
                    ChartLabels.map((chartlabel,chartlabelindex) => {			
                      if(arrayrecord.ActiveProcess && arrayrecord.stagenameid){			
                       			
                          arrayrecord.ChartLabelID = arrayrecord.stagenameid			
                          arrayrecord.ContextTitle = arrayrecord.ChartLabelID			
                          arrayrecord.ContextID = arrayrecord.ChartLabelID			
                      }			
                    })			
                  }			
                			
                return arrayrecord			
              })//final filter on pipeline process to return only data that has ativeprocess			
              .filter(arrayrecord => {			
                //console.log('data',arrayrecord) 
                  if(elmnt.ChartLabelsfromProcess && elmnt.PipelineProcess){			
                    return arrayrecord.ActiveProcess			
                  }			
                  else{			
                    return arrayrecord			
                  }			
              })			
              //console.log('elmnt.RawData',elmnt.RawData)			
			
              //Now we have the raw data, based on the filters, BEFORe values, and BEFORE structure to labels. 			
              //however if from child then structure changes			
              // elmnt.ContextfromChild			
			
              let RawData = []			
                  RawData = elmnt.RawData			
              //Now for elmnt RawValue			
               if(elmnt.GoalMeasurements){   			
               elmnt.RawTarget = ChartLabels.map(label => {			
                 return label.TargetValues			
               }).reduce((a, b) => a + b, 0)			
                if(elmnt.ValueType === 'Count'){			
                  elmnt.ContextTarget = elmnt.RawTarget			
                }			
                else if(elmnt.ValueType === 'Amount'){			
                    if(elmnt.ValueField && elmnt.ValueCurrency){			
                      elmnt.ContextTarget = currencyformatter.format(elmnt.RawTarget)			
                    }	
                    else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                      elmnt.ContextTarget = elmnt.RawTarget			
                    }		
                }			
             			
               }			
              elmnt.RawValue = RawData			
              .filter(arrayrecord => {			
                return typeof arrayrecord.RawValue !== 'undefined'			
              })			
              .map(arrayrecord => {			
                //now go get the values, formatted			
                return arrayrecord.RawValue			
              }).reduce((a, b) => a + b, 0)			
              if(elmnt.ValueType === 'Count'){			
                elmnt.ContextValue = elmnt.RawValue			
              }			
              else if(elmnt.ValueType === 'Amount'){			
                  if(elmnt.ValueField && elmnt.ValueCurrency){			
                    elmnt.ContextValue = currencyformatter.format(elmnt.RawValue)			
                  }
                  else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                      elmnt.ContextValue = elmnt.RawValue			
                    }
              }			
              if(elmnt.GoalMeasurements){			
                let achievement = elmnt.RawValue/elmnt.RawTarget			
                console.log(achievement)			
                if(achievement>=1){			
                  elmnt.AchievementClass = 'success--text'			
                }			
                else{			
                  elmnt.AchievementClass = 'red--text'			
                }			
                let achievedpercentage = achievement*100			
                console.log(achievedpercentage)			
                elmnt.AchievedPercentage = achievedpercentage.toFixed(2) 			
                elmnt.Achievement = elmnt.AchievedPercentage+'% Achieved'			
              }			
              //console.log(elmnt.RawValue)			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, Now elmnt.Context Structure (chartlabels)			
			
			
                //HOWEVER THINKING ACTIVITIES OR ANYTHING ELSE THAT COULD BE CHILD RECORDS, WE MUST NOW FIRST GET			
                //CHILDRAWDATA - GET, CHILDRAWVALUE - GET, CHILDCONTEXTVALUE - GET			
                //PRECEDING THIS, TEMP NOTE - filteredateobj should become "duedatefilteradteobj", or "stockdatefilteradteobj" etc 			
                //BECAUSE we will now get filterdate for parent, and then filter date for child			
                //is this maybe mre applicable elsehwere as in, Filter date stock, filter date whatever all on parent?			
			
                //THE POINT IS WE HAVE RAW DATA AND RAWVALUE AND I THINK IT IS GREAT BUT ACTUALLY BEFORE RAW VALUE WE MUST RESTRUCTURE TO CHILD, IF CHILD APPLICABLE. 			
			
                			
			
			
			
                elmnt.Context = []			
                if(elmnt.ChartLabelsfromProcess){		
                  elmnt.Context = ChartLabels.map(chartlabel => {			
                    let obj = {			
                      ContextTitle: chartlabel.LabelName,			
                      ContextID: chartlabel.ChartLabelID			
                    }			
                    return obj			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Option Set' && elmnt.PrimaryLabelField){
                  elmnt.Context = elmnt.PrimaryLabelField.Options			
                  .map(opt => {			
                    let obj = {			
                      ContextTitle: opt.Name,			
                      ContextID: opt.ID			
                    }			
                    return obj			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Lookup' && elmnt.PrimaryLabelField && elmnt.PrimaryLabelField.Type === 'Lookup' ){			
                  //now return from raw data push what is not there - arrayrecord.ContextID = arrayrecord[PrimaryLabelField.Name].id	
                  RawData			
                  .map(data => {			
                    if(data.ContextTitle && data.ContextID){
                      let oncheck = elmnt.Context.find(obj => obj.ContextID === data.ContextID)			
                      if(!oncheck){			
                        elmnt.Context.push({ContextTitle: data.ContextTitle, ContextID: data.ContextID})			
                      }			
                    }			
                  })			
                }			
                else if(elmnt.PrimaryLabelType === 'Option Set from Lookup' && elmnt.PrimaryLabelLookup && elmnt.PrimaryLabelField){		
                  elmnt.Context = elmnt.PrimaryLabelField.Options			
                  .map(opt => {			
                    let obj = {			
                      ContextTitle: opt.Name,			
                      ContextID: opt.ID			
                    }			
                    return obj			
                  })			
                }			
                 else if(elmnt.PrimaryLabelType === 'Name of Child' && elmnt.RelatedChild && elmnt.ChildRelationshipsField){		
                   //console.log('elmnt',elmnt)			
                    elmnt.Context = RawData.map(arrayrecord => {			
                       let obj = {			
                        ContextTitle: arrayrecord[primaryfield],			
                        ContextID: arrayrecord.id,			
                        IsChild: true			
                      }			
                      if(elmnt.RelatedChild.id === 'Activities'){			
                        obj.IsActivities = true			
                        			
                      }			
                      return obj			
                    })			
                 			
                  }			
                  else if(elmnt.PrimaryLabelType === 'From Timeline' && elmnt.TimeFilterOption){	
                   //console.log(elmnt)			
                    elmnt.Context = [{			
                        ContextTitle: elmnt.TimeFilterOption.Name,			
                        ContextID: elmnt.TimeFilterOption.ID,			
                      }]                 			
                  }			
                  			
                  //console.log('elmnt.Context',elmnt.Context,elmnt)			
                 			
                //so what about processes and pipeline? we need to ensure then that PrimaryLabel type is "process" and Primaryfield is "ActiveProcess"			
                //wiht a Contexttitle of "ProcessStages(match).Name, matching ProcessStageInteger as ContextID,"			
                if(!elmnt.ChartLabelsfromTimeline && !elmnt.ChartLabelsfromProcess){		
                  elmnt.ChartLabels = elmnt.Context.map(item => {			
                    let obj = {			
                      LabelName: item.ContextTitle,			
                      ChartLabelID: item.ContextID			
                    }			
                    return obj			
                  })			
                }			
              // }			
               else{			
                 elmnt.ChartLabels = ChartLabels			
               }			
              // console.log(elmnt.ChartLabels)			
              			
			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, elmntContext - STRUCTURE CHECK BUT Now we extend that with data,rawvalue and contextvalue 			
              elmnt.Context.map(contextitem => {			
                contextitem.ContextData = RawData			
                .filter(data => {			
                  return data.ContextID === contextitem.ContextID			
                })			
                 contextitem.RawValue = contextitem.ContextData			
                .map(data => {			
                  return data.RawValue			
                })			
                .reduce((a, b) => a + b, 0) 			
               			
                //console.log(contextitem.RawValue)			
                if(elmnt.ValueType === 'Count'){			
                  contextitem.ContextValue = contextitem.RawValue			
                }			
                else if(elmnt.ValueType === 'Amount'){			
                  if(elmnt.ValueField && elmnt.ValueCurrency){			
                    contextitem.ContextValue = currencyformatter.format(contextitem.RawValue)			
                  }		
                  else if(elmnt.ValueField && !elmnt.ValueCurrency){			
                    contextitem.ContextValue = contextitem.RawValue			
                  }	
                }			
              })			
              //console.log('elmnt.Context',elmnt.Context)			
              			
			
              elmnt.ChartLabels = elmnt.ChartLabels.map(label => {			
                label.DataArray = elmnt.Context.map(contextitem => {			
                  let dataarray = contextitem.ContextData.filter(data => {			
                    return data.ChartLabelID === label.ChartLabelID			
                  })  			
                  return dataarray			
                }).flat()			
                // .filter(item => {			
                //   return item.ChartLabelID			
                // })			
                return label			
              })			
              			
                //console.log('elmnt.ChartLabels',elmnt.ChartLabels)			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, elmntContext CHECK. NOW we need to preapre for charts			
              //Prepare for charts is a little divided action, got a few steps			
              //mainly we want ChartData (which has to respect sublabel if applicable), and Chart Colors.			
              //start with easiest, it's chartcolors			
			
              elmnt.ChartColors = []			
              if(elmnt.ChartLabelsfromTimeline){			
                			
                ChartLabelsVariables.map((variable,index) => {			
                  if(this[variable] > this.ComputedToday){			
                    elmnt.ChartColors[index] = '#ffffff'			
                  }			
                  else if(this[variable] < this.ComputedToday){			
                    elmnt.ChartColors[index] = '#ff0000'			
                  }			
                  else{			
                    elmnt.ChartColors[index] = '#FF9800'			
                  }			
                })			
              }			
              else{			
                if(this.PushedElmnt && this.PushedElmnt.PipelineProcess && !this.SublabelField){			
                  elmnt.Context.map((contextitem,index) => {			
                    elmnt.ChartColors[index] = this.PushedElmnt.ThemeColor			
                  })			
                }			
                else{			
                  elmnt.Context.map((contextitem,index) => {			
                    let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                    elmnt.ChartColors[index] = this.DefaultColors[ranint]			
                  }) 			
                }			
                			
              }			
			
              //Rawdata - CHECK, RawVALUe - CHECK, ContextValue - CHECK, elmntContext CHECK, ChartColors - CHECK, now Chartdata, which must respect sublabels			
              // But this is just a tad tricky it has two parts			
              //with sublabel, without sublabel			
			
              elmnt.ChartData = {			
                labels: elmnt.ChartLabels.map(contextitem => {return contextitem.LabelName}),			
                datasets: []			
              }			
              elmnt.ChartValues = []			
              elmnt.NEWSubLabelsdata = []			
              if(!elmnt.SublabelField && !elmnt.GoalMeasurements){			
                elmnt.ChartData.datasets[0] = {			
                  fill: false,			
                  borderColor: '#ffffff',			
                  backgroundColor: elmnt.ChartColors,			
                  data: []			
                }			
                elmnt.ChartLabels			
                .map((label,labelindex) => {			
                  if(elmnt.AccumulativeGraph && elmnt.ChartType === 'line' && elmnt.ChartLabelsfromTimeline){			
                    elmnt.ChartData.datasets[0].data[labelindex] = RawData			
                    .filter(data => {                      			
                      return data.ChartLabelID <= label.ChartLabelID			
                    })			
                    .map(contextitem => {			
                        return contextitem.RawValue			
                      }).reduce((a, b) => a + b, 0)                  			
                }			
                else{			
                elmnt.ChartData.datasets[0].data[labelindex] = label.DataArray.map(contextitem => {			
                    return contextitem.RawValue			
                  }).reduce((a, b) => a + b, 0)   			
                }			
                  			
                  if(elmnt.ChartLabelsfromProcess){			
                  elmnt.ChartValues = elmnt.ChartData.datasets[0].data			
                }                			
                })			
                			
              }			
              else if(elmnt.GoalMeasurements){			
                //TIMELINES			
                elmnt.Timelines = []			
                //the only step left is like, not to push to timelineitems, if the options max is outside the timefilteroption...			
                //ChartLabelsfirst Start and ChartLabelslast End? obj.min obj.max			
                let rangemax = ChartLabels[ChartLabels.length-1].max			
                this.TimelineFilterOptions.map(opt => {			
                  let TimelineItem = {			
                            Name: opt.Name,			
                            Period: opt.Name,			
                            TimelineID: opt.ID			
                        }			
                    if(!opt.ChartLabelsisDynamic){			
                      //console.log(opt.Name,opt)			
                      let length = opt.ChartLabels.length			
                    TimelineItem.min = this[opt.ChartLabelsVariables[0]]			
                    TimelineItem.max = this[opt.ChartlabelPeriodEndMethodX](this[opt.ChartLabelsVariables[length-1]])    			
                    let targets = elmnt.GoalTargets.filter(obj => {return obj.StartDate.getTime() === TimelineItem.min.getTime() && obj.EndDate.getTime() === TimelineItem.max.getTime()})			
                      TimelineItem.Target = targets.map(target => {return target.Target}).reduce((a, b) => a + b, 0)			
                    }    			
                    else  if(opt.ChartLabelsisDynamic){			
                      //console.log(opt.Name,opt)			
                    let length = this[opt.DynamicChartLabelsProp].Labels.length			
                    let minprop = this[opt.DynamicChartLabelsProp].ChartLabelsVariables[0]			
                    TimelineItem.min = this[minprop]			
                    let maxprop = this[opt.DynamicChartLabelsProp].ChartLabelsVariables[length-1]			
                    TimelineItem.max = this[opt.ChartlabelPeriodEndMethodX](this[maxprop])  			
                    let targets = elmnt.GoalTargets.filter(obj => {return obj.StartDate.getTime() === TimelineItem.min.getTime() && obj.EndDate.getTime() === TimelineItem.max.getTime()})			
                    TimelineItem.Target = targets.map(target => {return target.Target}).reduce((a, b) => a + b, 0)			
                    }    			
                    TimelineItem.ContextData = elmnt.RawData.filter(record => {
                      console.log('record.PrimaryDateObj',record.PrimaryDateObj)
                      return record.PrimaryDateObj >= TimelineItem.min && record.PrimaryDateObj <= TimelineItem.max})			
                    TimelineItem.Achieved = TimelineItem.ContextData.length  			
                    //console.log(data)			
                    TimelineItem.Value = Math.round((TimelineItem.Achieved/TimelineItem.Target)*100)			
                           			
                    let redcolor = Math.round(100-TimelineItem.Value)*2.55			
                    let greencolor = Math.round(TimelineItem.Value*2.55)                 			
                    TimelineItem.Color = 'rgb('+redcolor+','+greencolor+',0)'			
                    //console.log(opt.Name,TimelineItem.max <= rangemax,TimelineItem.max,rangemax,TimelineItem)			
                    if(TimelineItem.max <= rangemax){			
                      			
                      elmnt.Timelines.push(TimelineItem)			
                    }			
                    			
                })			
                elmnt.Timelines.map(TimelineItem => {			
                  let int = (TimelineItem.Achieved/elmnt.RawValue)*100			
                  TimelineItem.PercentageUp = int.toFixed(2)			
                  TimelineItem.IncreaseColor = 'green' 			
                  if(isNaN(TimelineItem.Value)){			
                    TimelineItem.AchievedText = 'No data for '+TimelineItem.Name			
                    TimelineItem.PercentageText = ''			
                  }			
                  else{			
                    TimelineItem.AchievedText = TimelineItem.Value+'% Achieved '+TimelineItem.Name			
                    TimelineItem.PercentageText = TimelineItem.PercentageUp+'% Contribution to Total'			
                  }			
                  if(!isFinite(TimelineItem.Value)){			
                    TimelineItem.Value = 0			
                  }			
                  return TimelineItem			
                })   			
                //TIMELINES DONE			
                elmnt.NEWSubLabelsdata = [			
                  {			
                    Name: 'Target',			
                    ID: 1000001			
                    },			
                    {			
                    Name: 'Achieved',			
                    ID: 1000002			
                    }			
                ]			
                elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                      elmnt.ChartData.datasets[sublabelindex] = {			
                      fill: false,			
                      			
                      backgroundColor: elmnt.ChartColors,			
                      data: [],			
                      label: sublabel.Name			
                    }			
                    if(sublabel.Name === 'Target'){			
                      elmnt.ChartData.datasets[sublabelindex].borderColor = '#455A64'			
                    }			
                    else{			
                      elmnt.ChartData.datasets[sublabelindex].borderColor = '#FFCCBC'			
                    }			
                    elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      if(!elmnt.ChartData.datasets[sublabelindex].data[labelindex]){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = []			
                      }			
                      if(sublabel.Name === 'Target'){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = elmnt.ChartLabels.filter(newlabel => {                      			
                            return newlabel.ChartLabelID <= label.ChartLabelID			
                          }).map(data => {			
                           return data.TargetValues			
                         }).reduce((a, b) => a + b, 0)			
                      }			
                      else{			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = RawData			
                          .filter(data => {                      			
                            return data.ChartLabelID <= label.ChartLabelID			
                          }).map(data => {			
                           return data.RawValue			
                         }).reduce((a, b) => a + b, 0)			
                      }			
                    })			
                })			
              }			
              else if(elmnt.SublabelField){			
                //also rememebr if sublabel is option set, lookup, option set from lookup last one aka SublabelFromRelatedLookup			
                //currently only if option set			
                //elmnt.NEWSubLabels = JSON.parse(JSON.stringify(elmnt.SublabelField.Options.map(opt => {return opt.Name})))			
                if(elmnt.SublabelField.Type === 'Option Set'){			
                elmnt.NEWSubLabelsdata = JSON.parse(JSON.stringify(elmnt.SublabelField.Options))			
                }			
                // else if(sublabelfieldtype is from timeline){			
                      //so if that were the case here is where we return stuff. 			
                      //so SublabelField.Options should be prepared for us here. But how?			
                      //you need one called "achieved" and another called ""			
                // }			
                else{			
                  			
                  let array = RawData			
                  .filter(data => {			
                    return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID			
                  })			
                  .map(data => {			
                    let obj = {			
                      Name: data[elmnt.SublabelField.Name][elmnt.SublabelField.LookupFieldName],			
                      ID: data[elmnt.SublabelField.Name].ID			
                    }			
                    return obj			
                  })			
                  array.map(item => {			
                    let oncheck = elmnt.NEWSubLabelsdata.find(obj => obj.ID === item.ID)			
                    if(!oncheck){			
                      elmnt.NEWSubLabelsdata.push(item)			
                    }			
                  })			
                 			
                }			
                //console.log(elmnt.NEWSubLabelsdata)			
                elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                      elmnt.ChartData.datasets[sublabelindex] = {			
                      fill: false,			
                      borderColor: '#ffffff',			
                      backgroundColor: elmnt.ChartColors,			
                      data: [],			
                      label: sublabel.Name			
                    }			
                    elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      if(!elmnt.ChartData.datasets[sublabelindex].data[labelindex]){			
                        elmnt.ChartData.datasets[sublabelindex].data[labelindex] = []			
                      }			
                      elmnt.ChartData.datasets[sublabelindex].data[labelindex] = label.DataArray			
                      .filter(data => {			
                        if(!elmnt.SublabelFromRelatedLookup){			
                         return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID === sublabel.ID 			
                        }			
                        else{			
                          return data[elmnt.EntityRelatedfield.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name].ID === sublabel.ID			
                        }			
                        			
                      })			
                      .map(data => {			
                        return data.RawValue			
                      }).reduce((a, b) => a + b, 0)  			
                      //   if(elmnt.ChartLabelsfromProcess){			
                      //   elmnt.ChartValues[sublabelindex] = elmnt.ChartData.datasets[sublabelindex].data			
                      // }                			
                    })			
                    			
                })			
                if(elmnt.ChartLabelsfromProcess){			
                   elmnt.ChartLabels			
                    .map((label,labelindex) => {			
                      elmnt.NEWSubLabelsdata.map((sublabel,sublabelindex) => {			
                        if(!elmnt.ChartValues[labelindex]){			
                          elmnt.ChartValues[labelindex] = []			
                        }			
                        elmnt.ChartValues[labelindex][sublabelindex] = label.DataArray			
                      .filter(data => {			
                        if(!elmnt.SublabelFromRelatedLookup){			
                         return data[elmnt.SublabelField.Name] && data[elmnt.SublabelField.Name].ID === sublabel.ID 			
                        }			
                        else{			
                          return data[elmnt.EntityRelatedfield.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name] && data[elmnt.EntityRelatedfield.Name][elmnt.SublabelField.Name].ID === sublabel.ID			
                        }			
                        			
                      })			
                      .map(data => {			
                        return data.RawValue			
                      }).reduce((a, b) => a + b, 0) 			
                      })			
                    })			
                }			
                			
              }			
			
                   			
              console.log(elmnt)			
              return elmnt			
          })			
          .map(elmnt => {			
            elmnt.Context = elmnt.Context			
              .map(context => {			
                  let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                  context.ContextTitleColor = this.DefaultColors[ranint]			
                  return context			
              })			
               let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                  elmnt.ContextTitleColor = this.DefaultColors[ranint]			
            return elmnt			
          })			
          .map(elmnt => {			
              if(elmnt.ColumnWidth === 'Full Column'){			
                elmnt.FlexXLRG = 12			
                elmnt.FlexLarge = 12			
                elmnt.FlexMedium = 12			
              }			
              else if(elmnt.ColumnWidth === 'Half Column'){			
                elmnt.FlexXLRG = 5			
                elmnt.FlexLarge = 5			
                elmnt.FlexMedium = 5			
              }			
              else if(elmnt.ColumnWidth === 'Third Column'){			
                elmnt.FlexXLRG = 3			
                elmnt.FlexLarge = 3			
                elmnt.FlexMedium = 4			
              }			
              elmnt.FlexSmall = 12			
              elmnt.FlexXSmall = 12			
              return elmnt			
          })			
          .map(elmnt => {			
            if(elmnt.Sort === 'Descending (Value)'){			
                               //console.log('is descending')			
                                elmnt.Context = elmnt.Context.sort((a, b) => {			
                                            // console.log('sorting again')			
                                            // console.log(a.Value)			
                                            var key1 = a.RawValue			
                                            var key2 = b.RawValue			
			
                                            if (key1 > key2) {			
                                                return -1;			
                                            } else if (key1 == key2) {			
                                                return 0;			
                                            } else {			
                                                return 1;			
                                            }			
                                    })			
                            }			
                            else if(elmnt.Sort === 'AlphaBetically (Ascending)'){			
                               //console.log('is descending')			
                                elmnt.Context = elmnt.Context.sort(function(a, b) {			
                                    var textA = a.ContextTitle.toUpperCase();			
                                    var textB = b.ContextTitle.toUpperCase();			
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;			
                                });			
                            }			
                            else if(elmnt.Sort === 'AlphaBetically (Descending)'){			
                               //console.log('is descending')			
                                elmnt.Context = elmnt.Context.sort(function(b, a) {			
                                    var textA = a.ContextTitle.toUpperCase();			
                                    var textB = b.ContextTitle.toUpperCase();			
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;			
                                });			
                            }			
                            else{			
                              //console.log('is ascending')			
                              elmnt.Context = elmnt.Context.sort((a, b) => {			
                                          // console.log('sorting again')			
                                          // console.log(a.Value)			
                                          var key1 = a.RawValue			
                                          var key2 = b.RawValue			
			
                                          if (key1 < key2) {			
                                              return -1;			
                                          } else if (key1 == key2) {			
                                              return 0;			
                                          } else {			
                                              return 1;			
                                          }			
                                  })			
                            }			
                            return elmnt			
          })			
          console.log(row.Elements)			
          return row			
          })			
          			
          }			
          else{			
              return []			
          }			
      },			
    			
    formTitle () {			
      return this.editedIndex === -1 ? 'New Capital Equipment Job' : 'Edit Capital Equipment Job'			
    },			
    formSubTitle(){			
      return this.editedIndex === -1 ? 'Fill the form below and add a new Capital Equipment Job' : 'Edit the Capital Equipment Job '+this.editedItem.Job_Number			
    },			
    formSubTitleIcon(){			
      return this.editedIndex === -1 ? 'mdi-plus-thick' : 'mdi-pencil'			
    },			
    formSubTitleIconColor(){			
      return this.editedIndex === -1 ? 'green' : 'orange'			
    },			
    userIsAdmin () {			
    return this.$store.state.IsAdmin			
    },			
    userLoggedIn () {			
        return this.$store.getters.user			
    },			
    userBUID () {			
        return this.$store.getters.userBUID			
    },			
    userBUChildren () {			
        return this.$store.getters.userBUChildren			
    },			
    userBUParents () {			
        return this.$store.getters.userBUParents			
    },			
    },			
  watch: {			
    
    RouteObj: {
      handler: function(newvalue, oldvalue) {        
        if(newvalue !== oldvalue){
          this.HasData = false
          if(this.PushedElmnt){			
            this.LoadingItems = true			
          }			
          this.PrepareDateFilterOptions()			
          this.$emit('ActivateStandbyDialog',true)
              this.CheckRouting()
            }
    },deep: true
    },
      HasData(value){			
          if (value !== null && value !== undefined && value === true) {			
          this.$emit('ActivateStandbyDialog',false)			
          }			
      },			
      userLoggedIn (value) {			
        //if (value !== null && value !== undefined) {			
         // this.$router.push('/')			
        //}			
      },			
     			
      ComputedDashboardElements(value){			
          if(value && value.length > 0){			
            if(this.PushedElmnt){			
              this.LoadingItems = false			
            }			
            			
              value.map((row,i) => {			
                row.Elements.map((elmnt,index) => {			
                if(elmnt.Preset === 'Pie Chart'){			
                    setTimeout(() => {			
                     let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                        if(elmntobj){			
                        //this.GetPieChart(elmntobj,elmnt.ChartData,elmnt.ChartLabels,elmnt.ChartColors)  			
                        }   			
                    }, 100);			
                                    			
                }			
                if(elmnt.ChartLabelsfromTimeline){			
                    setTimeout(() => {			
                     let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                        if(elmntobj){			
                        //this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels,elmnt.ChartColors)  			
                        }   			
                    }, 100);			
                                    			
                }			
                else if(elmnt.Preset === 'Activities'){			
                    setTimeout(() => {			
                     let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                        if(elmntobj){			
                        this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels,elmnt.ChartColors,elmnt.ChartAllData)  			
                        }   			
                    }, 100);			
                                    			
                }			
              })			
              })             			
              			
          }			
      }			
    },			
  methods: {			
    GetElementTargets(elmnt){			
      elmnt.GoalObj = this.DashboardEntity.GoalMeasurements.find(obj => obj.id === elmnt.GoalID)			
        elmnt.GoalTargets = elmnt.GoalObj.Targets			
        elmnt.GoalTargets = elmnt.GoalTargets.map(target => {			
          if(target.StartDate && typeof target.StartDate.toDate !== 'undefined'){			
            let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.StartDate.toDate())			
            target.StartDate = new Date(PrimaryDateSTRING)			
          }			
          if(target.EndDate && typeof target.EndDate.toDate !== 'undefined'){			
            let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.EndDate.toDate())			
            target.EndDate = new Date(PrimaryDateSTRING)			
            target.EndDate = this.EndofDay(target.EndDate)			
          }			
          return target			
        })			
        return elmnt			
    },			
    AssignRow(row){			
      this.Editedrow = row			
    },			
    CheckAdvanceStep(){			
      //console.log(this.SelectedOption)			
      if(this.step === 3){			
  			
        let obj = {			
          ColumnWidth: 'Full Column',			
          Title: this.SelectedOption.Name,			
          // Type: '',			
          Sort: 'Descending (Value)',			
          Description: this.SelectedOption.Description,			
          // ValueField: '',          			
          // ValueCurrency: '',			
          // EntityRelatedfield: '',			
          // Entity: '',			
          ChartBGObj: {hex: '#2196F3'},			
          HasData: false,			
        }			
        if(obj.Title === 'Select Process Type'){			
          obj.Title = this.DashboardEntity.DisplayName+' in '+this.RootelementType.PipelineProcess.Name+' Process'			
        }			
        obj.PrimaryLabelType = this.SelectedOption.PrimaryLabelType			
        if(this.SelectedOption.PrimaryLabelLookup){			
          obj.PrimaryLabelLookup = this.SelectedOption.PrimaryLabelLookup			
        }			
        if(this.SelectedOption.PrimaryLabelField){			
         obj.PrimaryLabelField = this.SelectedOption.PrimaryLabelField 			
        }			
        if(this.SelectedOption.PrimaryFilterType){			
          obj.PrimaryFilterType = this.SelectedOption.PrimaryFilterType			
        }			
        if(this.SelectedOption.PrimaryFilterField){			
         obj.PrimaryFilterField = this.SelectedOption.PrimaryFilterField 			
        }
        if(this.SelectedOption.SecondaryFilterField){			
         obj.SecondaryFilterField = this.SelectedOption.SecondaryFilterField 			
        }		
        if(!this.SelectedOption.ValueType){			
          obj.ValueType = 'Count'			
        }			
        else{			
          obj.ValueType = this.SelectedOption.ValueType			
        }			
        if(this.SelectedOption.PreFilterField){			
          obj.PreFilterField = this.SelectedOption.PreFilterField			
        }			
        if(this.SelectedOption.PreFilter){			
          obj.PreFilter = this.SelectedOption.PreFilter			
        }			
        if(this.SelectedOption.ChildRelationshipsField){			
          obj.ChildRelationshipsField = this.SelectedOption.ChildRelationshipsField			
        }			
        if(this.SelectedOption.RelatedChild){			
          obj.RelatedChild = this.SelectedOption.RelatedChild			
        }			
        if(this.SelectedOption.ValueField){			
          obj.ValueField = this.SelectedOption.ValueField			
        }			
        if(this.SelectedOption.ValueCurrency){			
          obj.ValueCurrency = this.SelectedOption.ValueCurrency			
        }			
			
        if(this.RootelementType.ChartType){			
          obj.ChartType = this.RootelementType.ChartType			
        }			
        if(this.RootelementType.HasChart){			
          obj.HasChart = this.RootelementType.HasChart			
        }			
        if(this.RootelementType.ChartPosition){			
          obj.ChartPosition = this.RootelementType.ChartPosition			
        }			
        if(this.RootelementType.HasDataContext){			
          obj.HasDataContext = this.RootelementType.HasDataContext			
        }			
        if(this.SelectedOption.PrimaryDateFilter){			
          obj.PrimaryDateFilter = this.SelectedOption.PrimaryDateFilter			
        }			
        if(this.SelectedOption.TimeFilterOption){			
          obj.TimeFilterOption = this.SelectedOption.TimeFilterOption			
        }			
        if(this.RootelementType.ChartLabelsfromTimeline){			
          obj.ChartLabelsfromTimeline = this.RootelementType.ChartLabelsfromTimeline			
        }			
        if(this.RootelementType.ChartLabelsfromProcess){			
          obj.ChartLabelsfromProcess = this.RootelementType.ChartLabelsfromProcess			
        }			
        if(this.RootelementType.ShowTitle){			
          obj.ShowTitle = this.RootelementType.ShowTitle			
        }			
        if(this.RootelementType.PipelineProcess){			
          obj.PipelineProcess = this.RootelementType.PipelineProcess			
        }			
        if(this.RootelementType.DataContextStyle){			
          obj.DataContextStyle = this.RootelementType.DataContextStyle			
        }			
        if(this.RootelementType.ShowSummary){			
          obj.ShowSummary = this.RootelementType.ShowSummary			
        }			
        if(this.RootelementType.ShowSummaryOnly){			
          obj.ShowSummaryOnly = this.RootelementType.ShowSummaryOnly			
        }			
        if(this.SelectedOption.GoalMeasurements){			
          obj.GoalMeasurements = this.SelectedOption.GoalMeasurements			
        }			
        if(this.RootelementType.AccumulativeGraph){			
          obj.AccumulativeGraph = this.RootelementType.AccumulativeGraph			
        }			
        			
        if(this.SelectedOption.GoalID){			
          obj.GoalID = this.SelectedOption.GoalID			
        }			
        obj.ConfigName = this.RootelementType.ConfigName			
        obj.ConfigID = this.RootelementType.ConfigID			
        //console.log(obj)			
        this.AddConfiguredDashboardelement(this.Editedrow,obj)			
      }			
      else{			
        this.step = this.step-1+2			
      }			
    },			
    SelectRootelementType(opt){			
      this.RootelementType = opt		
      //console.log(opt)	
      if(this.RootelementType){			
        if(!this.SelectedOption){			
          this.SelectedOption = {}			
        }			
      }			
      this.step = 2			
    },			
    ChooseOption(opt){			
      //console.log(opt)			
      this.SelectedOption = Object.assign({},opt)			
      if(this.RootelementType.ConfigName === 'Data in Timeline'){			
        opt.Description = opt.Name+' based on '+opt.PrimaryDateFilter.DisplayName+' displayed over a period of '+opt.TimeFilterOption.Name			
      }			
      			
      this.step = 3			
    },			
    ChooseElementType(){			
			
    },			
    EnsureFromParentnotConfigured(elmnt){			
      if(elmnt.SublabelFromRelatedChild){			
        elmnt.SublabelFromRelatedLookup = false			
      }			
    },			
    EnsureFromChildnotConfigured(elmnt){			
      if(elmnt.SublabelFromRelatedLookup){			
        elmnt.SublabelFromRelatedChild = false			
      }			
    },			
    GetDateFields(elmnt){			
      if(elmnt.PrimaryLabelType !== 'Name of Child'){			
        return this.DashboardEntity.DateFields			
      }			
      else{			
        if(elmnt.RelatedChild && elmnt.RelatedChild.id){			
          return elmnt.RelatedChild.AllFields.filter(field => {return field.Type === 'Date'})			
        }			
      }			
    },			
    GetChildArray(query){			
      let vm = this			
      return new Promise(function(resolve, reject) {			
      query.onSnapshot(res => {			
        let listlength = res.docs.length			
        if(listlength === 0){			
          resolve([])			
        }			
        let array = []			
        const changes = res.docChanges();			
        changes.forEach(change => {			
          if (change.type === 'added') {			
            this.BusinessUnitsArray.push({			
              ...change.doc.data(),			
              id: change.doc.id			
            })			
          }			
          if(array.length === listlength){			
            resolve(array)			
          }			
        })			
      })			
      })			
    },			
    EnsurenotChartlabels(elmnt){			
      if(elmnt.ChartLabelsfromProcess && elmnt.ChartLabelsfromTimeline){			
        elmnt.ChartLabelsfromTimeline = false			
      }			
    },			
    GetRelatedOptionSets(elmnt,prop){			
      if(elmnt[prop]){			
        let entity = this.FinalSystemEntities.find(obj => obj.id === elmnt[prop].RelatedBuildID)			
        if(entity){			
          return entity.AllFields.filter(field => {return field.Type === 'Option Set'})			
        }        			
      }      			
    },			
    PrepareElementComponents(elmnt){			
      if(elmnt.HasDataContext && elmnt.HasChart){			
                elmnt.HasTwoColumns = this.GetHasTwoColumns(elmnt)			
                elmnt.HasTwoRows = !elmnt.HasTwoColumns			
                elmnt.ComponentOne = this.GetComponentOne(elmnt)			
                elmnt.ComponentTwo = this.GetComponentTwo(elmnt)			
              }			
              // else if(elmnt.HasDataContext && elmnt.HasChart){			
              // }			
              else{			
                elmnt.HasTwoColumns = false			
                elmnt.HasTwoRows = false			
                elmnt.ComponentTwo = ''			
                // console.log('from here')			
                elmnt.ComponentOne = this.GetComponentOne(elmnt)                			
              }			
              			
              return elmnt			
    },			
    PrepareCurrency(elmnt){			
      let currency = this.$store.state.DefaultCurrency.Currency			
      if(elmnt.ValueCurrency){			
          currency = elmnt.ValueCurrency			
      }			
      let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
      let lang = this.$store.state.DefaultCurrency.LangForm
      if(currobj){
        lang = currobj.LangForm
      }
      return new Intl.NumberFormat(lang, {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })			
    },			
    PrepareActivitiesElementOLD(elmnt){			
      elmnt.DashboardActivities = {			
                Color: 'red',			
                Title: elmnt.ActivityDateFilter,			
                Data: this.DashboardActivities.map(item => {			
                      if(item.Due_Date && typeof item.Due_Date.toDate !== 'undefined'){			
                          item.filterdatestring = this.TimestampFormatterSTRING(item.Due_Date.toDate())			
                          item.filterdateObj = new Date(item.filterdatestring)			
                        }			
                      return item			
                  })			
                  .filter(arrayrecord => {                    			
                  if(elmnt.ActivityDateFilter === 'This Week'){			
                    return arrayrecord.filterdateObj && arrayrecord.filterdateObj > this.LastweekLastDate && arrayrecord.filterdateObj < this.NextweekFirstDate			
                  }			
                  else if(elmnt.ActivityDateFilter === 'This Month'){			
                    return arrayrecord.filterdateObj && arrayrecord.filterdateObj.getFullYear() === this.CurrentyearStartDate.getFullYear() && arrayrecord.filterdateObj.getMonth() === this.ComputedToday.getMonth()			
                  }			
                })			
                .filter(item => {			
                  return item.activitytype === elmnt.ActivityType			
                })			
                .filter(item => {			
                  if(elmnt.ActivityOwnerFilter){			
                    return item.Ownerid === elmnt.ActivityOwnerFilter.id			
                  }			
                  else{			
                    return item			
                  }			
                })			
                .filter(item => {                  			
                  if(elmnt.ActivityRecord){			
                    return item.regardingrecord.id === elmnt.ActivityRecord.id			
                  }			
                  else{			
                    return item			
                  }			
                })			
              }			
              elmnt.DashboardActivitiesData = this.DashboardActivities.map(item => {			
                      if(item.Due_Date && typeof item.Due_Date.toDate !== 'undefined'){			
                          item.filterdatestring = this.TimestampFormatterSTRING(item.Due_Date.toDate())			
                          item.filterdateObj = new Date(item.filterdatestring)			
                        }			
                      return item			
                  })			
                  .filter(arrayrecord => {                    			
                  if(elmnt.ActivityDateFilter === 'This Week'){			
                    return arrayrecord.filterdateObj && arrayrecord.filterdateObj > this.LastweekLastDate && arrayrecord.filterdateObj < this.NextweekFirstDate			
                  }			
                  else if(elmnt.ActivityDateFilter === 'This Month'){			
                    return arrayrecord.filterdateObj && arrayrecord.filterdateObj.getFullYear() === this.CurrentyearStartDate.getFullYear() && arrayrecord.filterdateObj.getMonth() === this.ComputedToday.getMonth()			
                  }			
                })			
                .filter(item => {			
                  return item.activitytype === elmnt.ActivityType			
                })			
            elmnt.DashboardActivities.Value = elmnt.DashboardActivities.Data.length			
            elmnt.DataArray = elmnt.DashboardActivitiesData.map(item => {			
              let obj = this.FullArray.find(obj => obj.id === item.regardingrecord.id)			
              return obj			
            })			
            elmnt.FilteredDataArray = elmnt.DashboardActivities.Data.map(item => {			
              let obj = this.FullArray.find(obj => obj.id === item.regardingrecord.id)			
              return obj			
            })			
            elmnt.ChartData = []			
            if(elmnt.ActivityDateFilter === 'This Week'){			
              elmnt.FilteredDataArray			
              .map(data => {			
                return data.Value			
              })			
            }			
            else if(elmnt.ActivityDateFilter === 'This Month'){			
            }			
            elmnt.ChartLabels = []			
            if(elmnt.ActivityDateFilter === 'This Week'){			
              elmnt.SubLabels = []			
              elmnt.DashboardActivities.Data.map(data => {			
                let oncheck = elmnt.SubLabels.find(obj => obj === data.Owner.Full_Name)			
                if(!oncheck){			
                  elmnt.SubLabels.push(data.Owner.Full_Name)			
                }			
              })			
              elmnt.ChartLabels = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']			
              elmnt.ChartData[0] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisMonday && data.filterdateObj < this.ThisTuesday			
              })			
              elmnt.ChartData[1] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisTuesday && data.filterdateObj < this.ThisWednesday			
              })			
              elmnt.ChartData[2] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisWednesday && data.filterdateObj < this.ThisThursday			
              })			
              elmnt.ChartData[3] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisThursday && data.filterdateObj < this.ThisFriday			
              })			
              elmnt.ChartData[4] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisFriday && data.filterdateObj < this.ThisSaturday			
              })			
              elmnt.ChartData[5] = elmnt.DashboardActivities.Data			
              .filter(data => {			
                return data.filterdateObj >= this.ThisSaturday && data.filterdateObj < this.ThisSunday			
              })			
              elmnt.ChartData[6] = elmnt.DashboardActivitiesData			
              .filter(data => {			
                return data.filterdateObj >= this.ThisSunday			
              })			
              // elmnt.ChartType = 'bar'			
              elmnt.ChartColors = []			
              // console.log('this.ThisTuesday',this.ThisTuesday)			
              //   console.log('this.ThisTuesday',this.ComputedToday)			
              // elmnt.ChartLabels.map((label,index) => {			
                if(this.ThisMonday > this.ComputedToday){			
                  elmnt.ChartColors[0] = '#ffffff'			
                }			
                else if(this.ThisMonday < this.ComputedToday){			
                  elmnt.ChartColors[0] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[0] = '#FF9800'			
                }			
                if(this.ThisTuesday > this.ComputedToday){			
                  elmnt.ChartColors[1] = '#ffffff'			
                }			
                			
                else if(this.ThisTuesday < this.ComputedToday){			
                  elmnt.ChartColors[1] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[1] = '#FF9800'			
                }			
                if(this.ThisWednesday > this.ComputedToday){			
                  elmnt.ChartColors[2] = '#ffffff'			
                }			
                else if(this.ThisWednesday < this.ComputedToday){			
                  elmnt.ChartColors[2] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[2] = '#FF9800'			
                }			
                if(this.ThisThursday > this.ComputedToday){			
                  elmnt.ChartColors[3] = '#ffffff'			
                }			
                else if(this.ThisThursday < this.ComputedToday){			
                  elmnt.ChartColors[3] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[3] = '#FF9800'			
                }			
                if(this.ThisFriday > this.ComputedToday){			
                  elmnt.ChartColors[4] = '#ffffff'			
                }			
                else if(this.ThisFriday === this.ComputedToday){			
                  elmnt.ChartColors[4] = '#FF9800'			
                }			
                else{			
                  elmnt.ChartColors[4] = '#ff0000'			
                }			
                if(this.ThisSaturday > this.ComputedToday){			
                  elmnt.ChartColors[5] = '#ffffff'			
                }			
                else if(this.ThisSaturday < this.ComputedToday){			
                  elmnt.ChartColors[5] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[5] = '#FF9800'			
                }			
                if(this.ThisSunday > this.ComputedToday){			
                  elmnt.ChartColors[6] = '#ffffff'			
                }			
                else if(this.ThisSunday < this.ComputedToday){			
                  elmnt.ChartColors[6] = '#ff0000'			
                }			
                else{			
                  elmnt.ChartColors[6] = '#FF9800'			
                }			
              // })			
              //console.log('elmnt.ChartData',elmnt.ChartData)			
              elmnt.ChartAllData = {			
                labels: elmnt.ChartLabels,			
                datasets: []			
              }			
              elmnt.ChartAllData.datasets = elmnt.SubLabels.map((label,i) => {			
                let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
                  let obj = {			
                    label: label,			
                    fill: false,			
                    //borderColor: this.DefaultColors[ranint],			
                    borderColor: '#ffffff',			
                    backgroundColor: elmnt.ChartColors,			
                    data: []			
                  }			
                  elmnt.ChartLabels.map((chartlabel,chartlabelindex) => {			
                        obj.data[chartlabelindex] = 0			
                        let chartdata = elmnt.ChartData[chartlabelindex]			
                        //console.log('elmntchartdata',label,chartdata)			
                        chartdata.map(data => {			
                        if(data.Owner && data.Owner.Full_Name === label){			
                          obj.data[chartlabelindex] = obj.data[chartlabelindex]+1			
                        }			
                  })			
                  })			
                  			
                 			
                  			
                  //console.log('elmntchartdataobj',obj)			
                  return obj			
                })			
              //datasetobj is { 			
            //         label: 'Jacqueline',			
            //         data: 			
            //           [0,1,2,3,1,2,1],			
                    			
            //         // label: "Africa",			
            //         borderColor: "#ff0000",			
            //         fill: false,			
            //          backgroundColor: ChartColors			
            //     }			
              console.log('elmnt.ChartColors',elmnt.ChartColors)			
            }			
            else if(elmnt.ActivityDateFilter === 'This Month'){			
            }			
            return elmnt			
    },			
    GetPreFilterFields(elmnt){			
       if(elmnt.PrimaryLabelType !== 'Name of Child'){			
        return this.DashboardEntity.AllFields			
            .filter(field => {			
                return field.Type === 'Option Set' && field.Options.length < 8			
            })			
      }			
      else{			
        return elmnt.RelatedChild ? elmnt.RelatedChild.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' || field.Type === 'Lookup' || field.Type === 'Static Option Set'			
          }) : []
      }			
    },			
    GetSublabelFields(elmnt){			
      if(elmnt.Preset === 'Pipeline'){			
         if(!elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){			
          return this.DashboardEntity.AllFields			
            .filter(field => {			
                return field.Type === 'Option Set' && field.Options.length < 8			
            })			
         }			
         else if(elmnt.SublabelFromRelatedChild){			
          return elmnt.RelatedChild.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' || field.Type === 'Lookup'			
          })			
        }			
         else{			
          let entity = this.FinalSystemEntities.find(obj => obj.id === elmnt.EntityRelatedfield.RelatedBuildID)			
          return entity.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' && field.Options.length < 8			
          })			
        }			
      }			
      else{			
        if(!elmnt.SublabelFromRelatedLookup && !elmnt.SublabelFromRelatedChild){			
          return this.DashboardEntity.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' || field.Type === 'Lookup'			
          })			
        }			
        else if(elmnt.SublabelFromRelatedChild){			
          return elmnt.RelatedChild.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set' || field.Type === 'Lookup' || field.Type === 'Static Option Set'			
          })			
        }			
        else{			
          let entity = this.FinalSystemEntities.find(obj => obj.id === elmnt.EntityRelatedfield.RelatedBuildID)			
          return entity.AllFields			
          .filter(field => {			
              return field.Type === 'Option Set'			
          })			
        }			
        			
      }			
    },			
    AdjusttoPreset(elmnt){			
     			
      if(elmnt.Preset === 'Static Timeline Filter'){			
        elmnt.HasChart = true			
        elmnt.HasDataContext = true			
        elmnt.ShowTitle = false			
        elmnt.ChartPosition = 'Top'			
        elmnt.DataContextStyle = 'List'			
        elmnt.ChartType = 'bar'			
        elmnt.ChartLabelsfromTimeline = true			
      }			
      else if(elmnt.Preset === 'Activities'){			
        elmnt.HasChart = true			
        elmnt.HasDataContext = true			
        elmnt.ShowTitle = false			
        elmnt.ChartPosition = 'Top'			
        elmnt.DataContextStyle = 'List'			
        elmnt.ChartType = 'bar'			
        elmnt.ChartLabelsfromTimeline = true			
      }			
      else if(elmnt.Preset === 'Pipeline'){			
        elmnt.HasChart = true			
        elmnt.HasDataContext = false			
        elmnt.ShowTitle = true			
        elmnt.ChartPosition = ''			
        elmnt.DataContextStyle = ''			
        elmnt.ChartLabelsfromTimeline = false			
        elmnt.ChartLabelsfromProcess = true			
        if(this.DashboardEntity.EntityProcesses && this.DashboardEntity.EntityProcesses.length>0){			
          elmnt.PipelineProcess = this.DashboardEntity.EntityProcesses[0]			
        }			
      }			
      else if(elmnt.Preset === 'Pie Chart'){			
        elmnt.HasChart = true			
        elmnt.HasDataContext = true			
        elmnt.ShowTitle = true			
        elmnt.ChartPosition = 'Right'			
        elmnt.DataContextStyle = 'List'			
        elmnt.ChartType = 'pie'			
        elmnt.ChartLabelsfromTimeline = false			
      }      			
      else if(elmnt.Preset === 'Card Roll'){			
        elmnt.HasChart = false			
        elmnt.HasDataContext = true			
        elmnt.ShowTitle = true			
        elmnt.ChartPosition = ''			
        elmnt.DataContextStyle = 'Cards'			
        elmnt.ChartLabelsfromTimeline = false			
        			
      }			
      //console.log(elmnt)			
    },			
    GetHasTwoColumns(elmnt){			
      if(elmnt.ChartPosition === 'Top'){			
        return false			
      }			
      else{			
        return true			
      }			
    },			
    GetHasTwoRows(elmnt){			
      if(elmnt.ChartPosition === 'Top'){			
        return true			
      }			
      else{			
        return false			
      }			
    },			
    GetShowTitle(elmnt){			
      if(elmnt.Preset === 'Static Timeline Filter' || elmnt.Preset === 'Activities'){			
        return false			
      }			
      else{			
        return true			
      }			
    },			
    GetComponentOne(elmnt){			
      if(!elmnt.ChartPosition){			
        if(elmnt.HasChart){			
          // console.log('from here')			
          return {CompName: 'DashboardElmtChart',Style: 'Pipeline'}			
        }			
        else if(elmnt.HasDataContext){          			
          return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
      }			
      else if(elmnt.ChartPosition === 'Top' && elmnt.HasChart){			
        return {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}			
      }			
      else if(elmnt.ChartPosition === 'Right' || elmnt.HasDataContext && !elmnt.HasChart){			
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
      }			
      else if(elmnt.ChartPosition === 'Left'){			
        return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
      }			
    },			
    GetComponentTwo(elmnt){			
      if(elmnt.ChartPosition === 'Top'){			
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
      }			
      else if(elmnt.ChartPosition === 'Right'){			
        return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
      }			
      else if(elmnt.ChartPosition === 'Left'){			
        return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
      }  			
    },			
    ChartTypeElement(elmnt){			
      return elmnt.Preset === 'Pie Chart' || elmnt.Preset === 'Activities' || elmnt.Preset === 'Static Timeline Filter' || elmnt.Preset === 'Pipeline'			
    },			
    DataContextTypeElement(elmnt){			
      return elmnt.Preset === 'Pie Chart' || elmnt.Preset === 'Activities' || elmnt.Preset === 'Static Timeline Filter' || elmnt.Preset === 'Card Roll'			
    },			
    ChartTypeOptions(elmnt){			
      if(elmnt.Preset === 'Static Timeline Filter' &&  elmnt.Preset === 'Activities'){			
        return ['bar','line','horizontalBar']			
      }			
      else{			
        return ['bar','line','pie','doughnut','radar','horizontalBar']			
      }			
    },			
   			
    EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofQuarter(date){			
      //console.log(date)			
      var year = date.getFullYear();			
      var month = date.getMonth();			
      var day = date.getDate();			
      return this.EndofMonth(new Date(year, month+2, day));			
    },		
    Endof2Hours(date){			
      var hours = date.getHours()
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)	
      d.setHours(hours,59,59,999)			
      return d			
    },
    EndofDay(date){			
      var day = date.getDate()			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month, day)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    DeleteElementDialog(row,i,elmnt,index){			
      confirm('This will remove the element completely, are you sure?') && this.ProcessDeleteElement(row,i,elmnt,index)			
    },			
    ProcessDeleteElement(row,i,elmnt,index){			
      row.Elements.splice(index,1)			
    },			
    DeleteRowDialog(row,i){			
      confirm('This will remove the row and all elements on it completely, are you sure?') && this.ProcessDeleteRow(row,i)			
    },			
    ProcessDeleteRow(row,i){			
      this.DashboardElements.splice(i,1)			
    },			
    ReturnColWidthOptions(elmnt){			
      if(elmnt.Preset === 'Card Roll' || elmnt.Preset === 'Pie Chart' || elmnt.Preset === 'Pipeline'){			
        return ['Full Column','Half Column']			
      }			
      else{			
        return ['Full Column','Half Column','Third Column','Quarter Column']			
      }			
      			
    },			
      ActivateRecord(obj,prop,value){			
        obj[prop] = value			
        //console.log(obj)			
      },			
      ToggleDirection(elmnt){			
          if(elmnt.direction === 'vertical'){			
              elmnt.direction = 'horizontal'			
              this.HasData = false			
               setTimeout(() => {			
              this.HasData = true			
              }, 100);			
          }			
          else{			
              elmnt.direction = 'vertical'			
              this.HasData = false			
              setTimeout(() => {			
              this.HasData = true			
              }, 100);			
          }			
      },			
      PrepareDateFilterOptions(){			
          this.Today = format(new Date(),'yyyy-MM-dd')			
         this.ActualToday = new Date()			
         this.ActualToday.setHours(0,0,0,0)			
         this.ActualNow = new Date()			
        			
      },			
      			
      TimestampFormatterSTRING(d){			
       			
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];			
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]			
      			
        var hr = d.getHours();			
       			
        var min = d.getMinutes();			
        if (min < 10) {			
            min = "0" + min;			
        }			
        if(hr < 10){			
          hr = "0"+hr			
        }			
        			
        var date = d.getDate();			
        var month = months[d.getMonth()];			
        var year = d.getFullYear();			
        // 2020-03-15 09:00'			
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;			
        //console.log(finaldate)			
        return finaldate			
      },			
      ActivateActiveDashboardTable(arritem,IsParent,item){			
          			
          if(IsParent && item){			
              		
              this.ActiveDashboardArray = item.ContextData 			
              this.ActiveDashboardTable = true			
              this.ActiveDashboardComponent = this.DashboardEntityComponent	
          }			
          else if(IsParent){			
              alert('First select a Filter')			
          }			
          else{			
            	
            this.ActiveDashboardArray = arritem.ContextData 			
            this.ActiveDashboardTable = true 			
            this.ActiveDashboardComponent = this.DashboardEntityComponent		
          }			
          console.log(arritem,IsParent,item,this.ActiveDashboardArray,this.ActiveDashboardTable)			
          			
      },			
      DeactivateActiveDashboardTable(){			
          this.ActiveDashboardTable = false			
          this.ActiveDashboardArray = []			
          this.ActiveDashboardComponent = ''			
      },			
      SaveDashboard(){			
        console.log('this.DashboardData',this.DashboardData)
          if(this.DashboardData){			
            // this.Saving = true			
              if(this.DashboardName){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    DashboardName: this.DashboardName,			
                })  			
              }			
              if(this.BGColor){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    BGColor: this.BGColor,			
                })  			
              }			
              if(this.DashboardEntity){			
                   db.collection('customdashboards').doc(this.Dashboardid).update({			
                    DashboardEntity: this.DashboardEntity.id,			
                })			
              }			
              if(this.Owner){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    Owner: this.Owner,			
                    Ownerid: this.Owner.id			
                })  			
              }			
              if(this.WhocanShare){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    WhocanShare: this.WhocanShare.map(user => {return user.id})			
                })  			
              }			
              if(this.WhocanView){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    WhocanView: this.WhocanView.map(user => {return user.id})			
                })  			
              }			
               if(this.WhocanEdit){			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    WhocanEdit: this.WhocanEdit.map(user => {return user.id})			
                })  			
              }			
              			
              if(this.DashboardElements){			
                  			
                  			
                    console.log(this.SaveDashboardElements)			
                db.collection('customdashboards').doc(this.Dashboardid).update({			
                    DashboardElements: this.SaveDashboardElements			
                }).then(done => {			
                  // this.Saving = false			
                })			
              }			
              			
              			
          }			
          else{			
            let dashobj = {			
                DashboardName: this.DashboardName,			
                Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},			
                Created_Byid: this.userLoggedIn.id,			
                Owner: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},			
                Ownerid: this.userLoggedIn.id,			
                Created_On: new Date(),   			
            }
            dashobj.DashboardEntity = this.DashboardEntity.id
            dashobj.DashboardElements = this.SaveDashboardElements
           db.collection('customdashboards').doc(this.Dashboardid).set(dashobj)   			
          }			
          			
      },			
      StraigthenDashboardCharts(){			
        this.DashboardElements.map((row,i) => {			
        row.Elements.map((elmnt,index) => {			
                if(elmnt.ChartType === 'pie'){  			
                  			
                    setTimeout(() => {			
                     let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                     //console.log(elmntobj)			
                        if(elmntobj){			
                        this.GetPieChart(elmntobj,elmnt.ChartType,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors)  			
                        }   			
                    }, 100);			
                                    			
                }			
                // if(elmnt.ChartLabelsfromTimeline){			
                //     setTimeout(() => {			
                //      let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                //         if(elmntobj){			
                //         this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)  			
                //         }   			
                //     }, 100);			
                                    			
                // }			
                else {			
                    setTimeout(() => {			
                     let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
                        if(elmntobj){			
                        this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)  			
                        }   			
                    }, 100);			
                                    			
                }			
              })			
        })			
      },			
      CheckforCharts(elmnt,i,index){			
          setTimeout(() => {			
            //console.log('row'+i+'element'+index+'chart')
           let elmntobj = document.getElementById('row'+i+'element'+index+'chart')			
          if(elmnt.Preset === 'Pie Chart' && elmnt.ChartData && elmnt.ChartLabels){			
              //console.log(elmnt.ChartData)			
              //console.log(elmnt.ChartLabels)			
            this.GetPieChart(elmntobj,elmnt.ChartType,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors)			
          }  			
          else if(elmnt.ChartLabelsfromTimeline && elmnt.ChartData && elmnt.ChartLabels){			
              //console.log(elmnt.ChartData)			
              //console.log(elmnt.ChartLabels)			
            this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)			
          }  			
           else if(elmnt.Preset === 'Activities'){			
            //console.log('elmnt',elmnt)       			
            this.GetLineorBarChart(elmnt.ChartType,elmntobj,elmnt.ChartData,elmnt.ChartLabels.map(label => {return label.LabelName}),elmnt.ChartColors,elmnt.ChartData)  			
            }   			
           			
          }, 1000);			
          			
      },			
      AddConfiguredDashboardelement(row,obj){			
        //console.log(row,obj)			
          let newelmnt = Object.assign({},obj)			
          row.Elements.push(newelmnt)			
          this.step = 1			
          this.SelectedOption = ''			
          this.RootelementType = ''			
      },			
      AddDashboardelement(row,rowindex){			
          let newelmnt = Object.assign({},this.defaultItem)			
          row.Elements.push(newelmnt)			
      },			
      AddRowasDashboardelement(){			
        let newitem = {			
          Type: 'Row',			
          Elements: []			
        }			
        this.DashboardElements.push(newitem)			
      },			
      updatearray(elementindex,array,lookuparrays,activitiesarray){ 			
          //console.log('elementindex,array,lookuparrays,activitiesarray',elementindex,array,lookuparrays,activitiesarray,this.DashboardEntity)      			
        //   let dashelmnt = this.DashboardElements[elementindex]			
        if(!this.PushedElmnt){			
          this.FullArray = array			
          this.LookupArrays = lookuparrays			
          this.HasData = true			
          if(activitiesarray){			
            this.DashboardActivities = []
            this.PrepareandGetRelevantActivities()
          }			
        }
        //this.HasData = true					
          //console.log(this.FullArray,this.LookupArrays,this.DashboardActivities,this.HasData)			
      },			
    PrepareandGetRelevantActivities(){
      let ActivitiesRegardingQueries = ['ActivitiesRegardingSubordinateOwner','ActivitiesRegardingOwner','ActivitiesRegardingUnit','ActivitiesRegardingUnitStructure']
      ActivitiesRegardingQueries = ActivitiesRegardingQueries.filter(query => {
        return this.userLoggedIn[query]
      })
      this.FullArray.map(record => {
        if(!this.userIsEagleViewer && !this.userIsAdmin){
        if(ActivitiesRegardingQueries.length > 0){
          //console.log(this.userBUChildren,this.userBUID,this.userLoggedIn,ActivitiesRegardingQueries,this.userLoggedIn.Subordinatearrayquery)
          if(this.userLoggedIn.ActivitiesRegardingSubordinateOwner){
            this.userLoggedIn.Subordinatearrayquery.map(subord => {
          let query = db.collection('activities').where('Regarding_Record.Ownerid','==',subord)
          this.GetRelevantActivities(query,record) 
            })
          }
          else if(this.userLoggedIn.ActivitiesRegardingOwner){
          let query = db.collection('activities').where('Regarding_Record.Ownerid','==',this.userLoggedIn.id)
          this.GetRelevantActivities(query,record) 
          }
          else if(this.userLoggedIn.ActivitiesRegardingUnit){
          let query = db.collection('activities').where('Regarding_Record.Business_Unitid','==',this.userBUID)
          this.GetRelevantActivities(query,record) 
          //what if regarding record does not match unit query, but activity is in fact owned by subordinate?
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
              let query = db.collection('activities').where('Ownerid','==',subord)
              //requires index and update of rules to check if not match but subordinate match so pass on this, just double check double calling .where('Regarding_Record.Business_Unitid','!=',this.userBUID)
              this.GetRelevantActivities(query,record) 
            })
          }
          else if(this.userLoggedIn.ActivitiesRegardingUnitStructure){
            this.userBUChildren.map(buid => {
                let query = db.collection('activities').where('Regarding_Record.Business_Unitid','==',buid)
                this.GetRelevantActivities(query,record) 
            })
          //again, what if regarding record does not match unit query, but activity is in fact owned by subordinate?
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
            this.userBUChildren.map(buid => {
                let query = db.collection('activities').where('Ownerid','==',subord)
              //requires index and update of rules to check if not match but subordinate match so pass on this, just double check double calling .where('Regarding_Record.Business_Unitid','!=',buid)
                this.GetRelevantActivities(query,record) 
            })
            
            })
          }

        }
      else{
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
          let query = db.collection('activities').where('Ownerid','==',subord)
          this.GetRelevantActivities(query,record)
        })
        }
      }
      else{
          let query = db.collection('activities')
          this.GetRelevantActivities(query,record)
        }
      })
    },
    GetRelevantActivities(query,RelatedObj){
      let finalquery = query
      if(RelatedObj){
        finalquery = finalquery.where('Regarding_Record.id','==',RelatedObj.id)
      }
      finalquery.onSnapshot(res => {
        const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.DashboardActivities.find(obj => obj.id === actobj.id)
          if(!oncheck){
            this.DashboardActivities.push(actobj)
          }
        }
        if(change.type === 'modified'){
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let item = this.DashboardActivities.find(obj => obj.id === actobj.id)
          if(item){
            let index = this.DashboardActivities.indexOf(item)
            this.DashboardActivities.splice(index,1,actobj)
            if(this.dragging && this.dragging.id === item.id){
              this.ReadingTaskDialog = false
              this.dragging = actobj
              setTimeout(() => {
                this.ReadingTaskDialog = true
              }, 1);
            }
          }
        }
        //console.log('this.DashboardActivities',this.DashboardActivities,this.RelatedObj)

      })
      })
    },
    ActivatePipeline(pipeline,filtername){			
            			
            this.ActiveFilter = pipeline			
            this.FilterbyName = filtername+' - '+pipeline.Name			
        },			
        DeactivateFilter(){			
            this.FilterbyName = ''			
            this.ActiveFilter = ''			
        },			
        GetSTDChart(type,elmnt,ChartData,ChartLabels,ChartColors,ChartAllData){			
          //console.log(elmnt,ChartData,ChartLabels,ChartColors,ChartAllData)			
            elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          //console.log(elmnt.offsetHeight)			
          // canvas.style.width = ''+elmnt.offsetWidth			
          // canvas.style.height = ''+elmnt.offsetHeight 			
         			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 40          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: "#ff0000",			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          //console.log(generateddatasets)			
          new Chart(canvas, {			
            type: type,			
            data: {			
                labels: ChartLabels,			
                datasets: ChartAllData.datasets,			
                //datasets: generateddatasets,			
            },			
            			
            options: {			
                title: {			
                // display: true,			
                text: 'World population per region (in millions)',			
                			
                },			
                legend: {			
                    display: false,			
                    labels: {			
                        fontColor: 'rgb(255, 255, 255)'			
                    },			
                },			
			
                scales: {			
                        xAxes: [{			
                            			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            },			
                            scaleLabel: {			
                            display: false,			
                            labelString: 'Month'			
                            }			
                        }],			
                        yAxes: [{			
                            display: true,			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            maxTicksLimit: 5			
			
                            },			
                        }]			
                    }			
            }			
            })			
        },			
        GetLineorBarChart(type,elmnt,ChartData,ChartLabels,ChartColors,ChartAllData){			
            //console.log(elmnt,ChartData,ChartLabels,ChartColors,ChartAllData)			
            elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          //console.log(elmnt.offsetHeight)			
          // canvas.style.width = ''+elmnt.offsetWidth			
          // canvas.style.height = ''+elmnt.offsetHeight 			
         			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 40          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: "#ff0000",			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          //console.log(generateddatasets)			
          new Chart(canvas, {			
            type: type,			
            data: {			
                labels: ChartLabels,			
                datasets: ChartAllData.datasets,			
                //datasets: generateddatasets,			
            },			
            			
            options: {			
                title: {			
                // display: true,			
                text: 'World population per region (in millions)',			
                			
                },			
                legend: {			
                    display: false,			
                    labels: {			
                        fontColor: 'rgb(255, 255, 255)'			
                    },			
                },			
			
                scales: {			
                        xAxes: [{			
                            			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            },			
                            scaleLabel: {			
                            display: false,			
                            labelString: 'Month'			
                            }			
                        }],			
                        yAxes: [{			
                            display: true,			
                            gridLines: {			
                            display: true,			
                            color: "#aaaaaa",			
                            borderDash: [2, 4],			
			
                            },			
                            ticks: {			
                            fontColor: "#cccccc",			
                            maxTicksLimit: 5			
			
                            },			
                        }]			
                    }			
            }			
            })			
        },			
        GetFunnelChart(){			
            setTimeout(() => {			
             let elmnt = document.getElementById('funneltest')			
            var canvas = document.createElement("CANVAS")			
          canvas.style.width = '100%'			
          canvas.style.height = '100%' 			
          elmnt.appendChild(canvas)			
          new Chart(canvas, {			
                type: 'funnel',			
                data: {			
                    datasets: [{			
                        data: [30, 60, 90],			
                        backgroundColor: [			
                            "#FF6384",			
                            "#36A2EB",			
                            "#FFCE56"			
                        ],			
                        hoverBackgroundColor: [			
                            "#FF6384",			
                            "#36A2EB",			
                            "#FFCE56"			
                        ]			
                    }],			
                    labels: [			
                        "Red",			
                        "Blue",			
                        "Yellow"			
                    ]			
                }			
            })   			
            }, 1000);			
            			
        },			
        GetPieChart(elmnt,type,ChartData,ChartLabels,ChartColors){			
         //console.log(elmnt,type,ChartData,ChartLabels,ChartColors)			
         elmnt.textContent = ''			
          var canvas = document.createElement("CANVAS")			
          canvas.style.width = '100%'			
          canvas.style.height = (this.WindowHeight/2.2)+'px' 			
          elmnt.appendChild(canvas)			
           let namelist = ['Jan','Piet','Gert','Thuli','Jackie','Hennie','Chade','Johan','Lood','Rudi','Mike','Jacques','Sipho','John','Oliver','Louis','Sannie']			
          let surnamelist = ['Fourie','Louw','Snyman','Nel','van Wyk','le Roux','van Staden','Rambo','Krynauw','Beyers','Oosthuizen','Ndlovu','Skoonraadt','van der Merwe','Steyn','Pretorius','Seemonster']			
          let datalength = 7          			
          let generateddatasets = []			
          let labellength = ChartLabels.length			
          for(var x = 0; x < datalength; x++){			
            let randomname = Math.floor(Math.random() * Math.floor(namelist.length))			
            let randomsurname = Math.floor(Math.random() * Math.floor(surnamelist.length))			
            // let obj = {			
            //   label: namelist[randomname]+' '+surnamelist[randomsurname],			
            //   data: [],			
            //   borderColor: "#ff0000",			
            //   backgroundColor: ChartColors,			
            //   fill: false,			
            // }			
            let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
            let obj = {			
              label: namelist[randomname]+' '+surnamelist[randomsurname],			
              data: [],			
              borderColor: this.DefaultColors[ranint],			
              backgroundColor: ChartColors,			
              fill: false,			
            }			
            if(type === 'bar'){			
             			
              obj.backgroundColor = this.DefaultColors[ranint]			
            }			
            else if(type === 'line'){             			
              obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
            }			
            else{			
              //obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
            }			
            for(var y = 0; y < labellength; y++){			
              let randomvalue = Math.floor(Math.random() * Math.floor(5))			
              obj.data[y] = randomvalue			
            }			
            //console.log(obj)			
            generateddatasets[x] = obj			
          }			
          // let Colors = []			
         let ranint = Math.floor(Math.random() * Math.floor(this.DefaultColors.length))			
         if(type === 'bar'){			
             ChartData.datasets.map(obj => {            			
              obj.backgroundColor = this.DefaultColors[ranint]			
               })			
            }			
            else if(type === 'line'){    			
              ChartData.datasets.map(obj => {          			
              obj.borderColor = this.DefaultColors[ranint]			
              obj.backgroundColor = ChartColors			
              })			
            }			
            else{			
             ChartData.datasets.map(obj => { 			
              obj.backgroundColor = ChartColors			
             })			
            }			
          //console.log(generateddatasets)			
          setTimeout(() => {			
            new Chart(canvas, {			
              type: type,			
              data: ChartData,			
              // data: {			
              //   labels: ChartLabels,			
              //   // datasets: generateddatasets			
              //   datasets: [{			
              //     // label: "Population (millions)",			
              //     backgroundColor: ChartColors,			
              //     data: ChartData			
              //   }]			
              // },			
              options: {			
                title: {			
                  display: false,			
                  text: 'Predicted world population (millions) in 2050'			
                },			
                			
              }			
          });			
          }, 500);			
            			
        },			
    			
      			
  DateFormatter(date){			
    if(date){			
      let removeT = date.split('T').join(' ')			
      let removeFWDslash = date.split('/').join('-')			
    let yearstring = removeFWDslash.split('-')[0]			
      let monthstring = removeFWDslash.split('-')[1]			
      let daystring = removeFWDslash.split('-')[2]			
      let yearnumber = Number(yearstring)			
      let monthnumber = Number(monthstring)			
      let daynumber = Number(daystring)			
      return new Date(yearnumber, monthnumber-1, daynumber)			
      }			
    else{			
      return null			
    }			
  },			
 			
  CheckRouting(){			
    			
  			
        			
        this.GetRequestingUser()			
        this.GetBusinessUnits()			
			
  },			
  			
  			
			
  GetBusinessUnits(){			
    db.collection('businessunits').onSnapshot(res => {			
			
    const changes = res.docChanges();			
    changes.forEach(change => {			
      if (change.type === 'added') {			
        this.BusinessUnitsArray.push({			
          ...change.doc.data(),			
          id: change.doc.id			
        })			
      }			
			
			
    })			
  })			
  },			
			
  			
  BURolesCheck(RoleObject){			
        let vm = this			
        let CreatePermissionLevel = 0			
        let GetPermissionLevel = 0			
        let ListPermissionLevel = 0			
        let UpdatePermissionLevel = 0			
        let DeletePermissionLevel = 0			
        let Roleslength = vm.UserRoles.length			
        //console.log(Roleslength)			
        return new Promise(function(resolve, reject) {			
        vm.UserRoles.map((role,i) => {			
          let alldeltest = RoleObject.AlldeleteRoles.find(obj => obj == role)			
          let unitdowndeltest = RoleObject.UnitDowndeleteRoles.find(obj => obj == role)			
          let userunitdeltest = RoleObject.UserUnitdeleteRoles.find(obj => obj == role)			
          let ownerdeltest = RoleObject.OwnerdeleteRoles.find(obj => obj == role)			
          let alledittest = RoleObject.AllupdateRoles.find(obj => obj == role)			
          let unitdownedittest = RoleObject.UnitDownupdateRoles.find(obj => obj == role)			
          let userunitedittest = RoleObject.UserUnitupdateRoles.find(obj => obj == role)			
          let owneredittest = RoleObject.OwnerupdateRoles.find(obj => obj == role)			
          let allviewtest = RoleObject.AllgetRoles.find(obj => obj == role)			
          let unitdownviewtest = RoleObject.UnitDowngetRoles.find(obj => obj == role)			
          let userunitviewtest = RoleObject.UserUnitgetRoles.find(obj => obj == role)			
          let ownerviewtest = RoleObject.OwnergetRoles.find(obj => obj == role)			
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)			
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)			
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)			
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)			
          let allcreatetest = RoleObject.AllcreateRoles.find(obj => obj == role)			
          let peercreatetest = RoleObject.UnitDowncreateRoles.find(obj => obj == role)			
          let parentcreatetest = RoleObject.UserUnitcreateRoles.find(obj => obj == role)			
          let childcreatetest = RoleObject.OwnercreateRoles.find(obj => obj == role)			
          if(typeof alldeltest !== 'undefined' || vm.IsAdmin){			
            DeletePermissionLevel = 4			
          }			
          else if(typeof unitdowndeltest !== 'undefined'){			
            if(DeletePermissionLevel<3){			
              DeletePermissionLevel = 3			
            }			
          }			
          else if(typeof userunitdeltest !== 'undefined'){			
            if(DeletePermissionLevel<2){			
              DeletePermissionLevel = 2			
            }			
          }			
          else if(typeof ownerdeltest !== 'undefined'){			
            if(DeletePermissionLevel<1){			
              DeletePermissionLevel = 1			
            }			
          }			
          if(typeof alledittest !== 'undefined' || vm.IsAdmin){			
            UpdatePermissionLevel = 4			
          }			
          else if(typeof unitdownedittest !== 'undefined'){			
            if(UpdatePermissionLevel<3){			
              UpdatePermissionLevel = 3			
            }			
          }			
          else if(typeof userunitedittest !== 'undefined'){			
            if(UpdatePermissionLevel<2){			
              UpdatePermissionLevel = 2			
            }			
          }			
          else if(typeof owneredittest !== 'undefined'){			
            if(UpdatePermissionLevel<1){			
              UpdatePermissionLevel = 1			
            }			
          }			
          if(typeof allviewtest !== 'undefined' || vm.IsAdmin){			
            GetPermissionLevel = 4			
          }			
          else if(typeof unitdownviewtest !== 'undefined'){			
             if(GetPermissionLevel<3){			
              GetPermissionLevel = 3			
            }			
          }			
          else if(typeof userunitviewtest !== 'undefined'){			
            if(GetPermissionLevel<2){			
              GetPermissionLevel = 2			
            }			
          }			
          else if(typeof ownerviewtest !== 'undefined'){			
            if(GetPermissionLevel<1){			
              GetPermissionLevel = 1			
            }			
          }			
          if(typeof alltest !== 'undefined' || vm.IsAdmin){			
            ListPermissionLevel = 4			
          }			
          else if(typeof peertest !== 'undefined'){			
            if(ListPermissionLevel<3){			
              ListPermissionLevel = 3			
            }			
          }			
          else if(typeof parenttest !== 'undefined'){			
            if(ListPermissionLevel<2){			
              ListPermissionLevel = 2			
            }			
          }			
          else if(typeof childtest !== 'undefined'){			
            if(ListPermissionLevel<1){			
              ListPermissionLevel = 1			
            }			
          }			
          if(typeof allcreatetest !== 'undefined' || vm.IsAdmin){			
            CreatePermissionLevel = 4			
          }			
          else if(typeof peercreatetest !== 'undefined'){			
            if(CreatePermissionLevel<3){			
              CreatePermissionLevel = 3			
            }			
          }			
          else if(typeof parentcreatetest !== 'undefined'){			
            if(CreatePermissionLevel<2){			
              CreatePermissionLevel = 2			
            }			
          }			
          else if(typeof childcreatetest !== 'undefined'){			
            if(CreatePermissionLevel<1){			
              CreatePermissionLevel = 1			
            }			
          }			
            if(i-1+2 === Roleslength){			
              let PermissionLevel = {			
                CreatePermissionLevel: CreatePermissionLevel,			
                GetPermissionLevel: GetPermissionLevel,			
                ListPermissionLevel: ListPermissionLevel,			
                UpdatePermissionLevel: UpdatePermissionLevel,			
                DeletePermissionLevel: DeletePermissionLevel			
			
              }			
              resolve(PermissionLevel)			
            }			
          })			
        })			
      },			
      BUListRolesCheck(RoleObject){			
        let vm = this			
        let ListPermissionLevel = 0			
        let Roleslength = vm.UserRoles.length			
        //console.log(Roleslength)			
        return new Promise(function(resolve, reject) {			
        vm.UserRoles.map((role,i) => {			
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)			
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)			
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)			
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)			
           			
          if(typeof alltest !== 'undefined' || vm.IsAdmin){			
            ListPermissionLevel = 4			
          }			
          else if(typeof peertest !== 'undefined'){			
            if(ListPermissionLevel<3){			
              ListPermissionLevel = 3			
            }			
          }			
          else if(typeof parenttest !== 'undefined'){			
            if(ListPermissionLevel<2){			
              ListPermissionLevel = 2			
            }			
          }			
          else if(typeof childtest !== 'undefined'){			
            if(ListPermissionLevel<1){			
              ListPermissionLevel = 1			
            }          			
          }			
            if(i-1+2 === Roleslength){			
              let PermissionLevel = {			
                ListPermissionLevel: ListPermissionLevel,			
              }			
              resolve(PermissionLevel)			
            }			
          })			
        })			
      },			
      CustomRolesCheck(Roles){			
        let vm = this			
        let PermissionLevel = 0			
        let Roleslength = vm.UserRoles.length			
        //console.log(Roleslength)			
        return new Promise(function(resolve, reject) {			
        vm.UserRoles.map((role,i) => {			
          //console.log(i)			
              let Rolestest = Roles.find(obj => obj == role)			
                if(Rolestest){			
                  PermissionLevel = 1			
                }      			
                if(i-1+2 === Roleslength){			
                  resolve(PermissionLevel)			
                }			
              })			
			
        })			
      },			
        GetEntities(){			
            let vm = this			
            return new Promise(function(resolve, reject) {			
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').onSnapshot(res => {			
                let arraylength = res.docs.length			
                //console.log(arraylength)			
                const changes = res.docChanges();			
                    changes.forEach((change,i) => {			
                    if (change.type === 'added') {			
                        let build = change.doc.data()			
                        build.id = change.doc.id			
                        vm.GetAllEntityProps(build).then(function(allpropsresult) {			
                            if(allpropsresult){			
                            //   console.log("%c "+build.id, "background: blue; color: white")			
                            //  console.log(build)			
                            let oncheck = vm.SystemEntities.find(obj => obj.id == build.id)			
                            if(!oncheck){			
                                vm.SystemEntities.push(build)			
                            }			
                            //console.log(vm.SystemEntities.length)			
                                if(vm.SystemEntities.length === arraylength){			
                                vm.GotEntities = true    			
                                resolve('Method SystemEntities  Finished for ' + vm.SystemEntities.length)			
                                }			
                            }			
                            })			
                    }			
                    			
            })			
            })			
            })			
        },			
        GetEntityAllFields(build){    			
            // console.log('calling fields') 			
            build.AllFields = []			
                   			
            let vm = this			
            return new Promise(function(resolve, reject) {			
                // console.log('attempting to get all fields for '+build.id,build.AllFields)			
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('AllFields').onSnapshot(res => {			
                let arraylength = res.docs.length			
                if(arraylength === 0){			
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)			
                }			
            const changes = res.docChanges();			
            changes.forEach(change => {			
                if (change.type === 'added') {			
                build.AllFields.push({			
                    ...change.doc.data(),			
                    id: change.doc.id			
                })			
                }			
                if(build.AllFields.length === arraylength){			
                    			
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)			
                }			
                })			
                })			
            })			
        },			
        GetGoalMeasurements(build){			
          build.GoalMeasurements = []			
          let vm = this			
            return new Promise(function(resolve, reject) {			
                // console.log('attempting to get all fields for '+build.id,build.AllFields)			
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {			
                let arraylength = res.docs.length			
                if(arraylength === 0){			
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)			
                }			
            const changes = res.docChanges();			
            changes.forEach(change => {			
                if (change.type === 'added') {			
                  let obj = {			
                        ...change.doc.data(),			
                        id: change.doc.id			
                    }			
                    obj.Targets = []			
                    vm.GetGoalTargets(obj).then(result => {			
                        console.log(obj,result)			
                        build.GoalMeasurements.push(obj)			
                        if(build.GoalMeasurements.length === arraylength){                    			
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)			
                        }			
                    })			
                }			
                			
                })			
                })			
            })			
        },			
        GetGoalTargets(obj){			
            let vm = this			
            return new Promise(function(resolve, reject) {			
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {			
                    let listlength = res.docs.length			
                    if(listlength === 0){			
                        resolve('No target for '+obj.id)			
                    }			
                    let counter = 0			
                    const changes = res.docChanges();			
                    changes.forEach((change,i) => {			
                    if (change.type === 'added') {			
                        let targetobj = {			
                            ...change.doc.data(),			
                            id: change.doc.id			
                        }			
                        			
                        let targetslength = targetobj.Targets.length			
                        targetobj.Targets.map((target,i) => {			
                            obj.Targets.push(target)			
                            			
                            //console.log(targetslength,i-1+2)			
                            if(i-1+2 === targetslength){			
                                counter = counter-1+2			
                                if(counter === listlength){			
                                 resolve('Got all targets for ',obj.id)   			
                                }			
                                			
                            }			
                        })                        			
                    }			
                     			
                    })			
                   			
                })			
            })			
			
        },			
        GetEntityProcesses(build){    			
            // console.log('calling fields') 			
            build.EntityProcesses = []            			
            let vm = this			
            return new Promise(function(resolve, reject) {			
                // console.log('attempting to get all fields for '+build.id,build.AllFields)			
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('EntityProcesses').onSnapshot(res => {			
                let arraylength = res.docs.length			
                if(arraylength === 0){			
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)			
                }			
            const changes = res.docChanges();			
            changes.forEach(change => {			
                if (change.type === 'added') {			
                build.EntityProcesses.push({			
                    ...change.doc.data(),			
                    id: change.doc.id			
                })			
                }			
                if(build.EntityProcesses.length === arraylength){			
                    			
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)			
                }			
                })			
                })			
            })			
        },			
        GetEntitySubCollections(build){     			
            // console.log('calling SubCollections')			
            build.SubCollections = []            			
            let vm = this			
            return new Promise(function(resolve, reject) {			
                // console.log('attempting to get all fields for '+build.id,build.AllFields)			
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(build.id).collection('SubCollections').onSnapshot(res => {			
            let arraylength = res.docs.length			
            if(arraylength === 0){			
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)			
                }			
            const changes = res.docChanges();			
            changes.forEach(change => {			
                if (change.type === 'added') {			
                build.SubCollections.push({			
                    ...change.doc.data(),			
                    id: change.doc.id			
                })			
                }			
                if(build.SubCollections.length === arraylength){			
                    			
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)			
                }			
                			
                })			
                })			
            })			
        },			
        GetAllEntityProps(build){			
        let vm = this			
        // console.log('calling props')			
        return new Promise(function(resolve, reject) {			
        vm.GetEntityAllFields(build).then(function(allfieldsresult) {			
                if(allfieldsresult){			
                    // console.log(allfieldsresult)			
                    vm.GetEntitySubCollections(build).then(function(subcolresult) {			
                        // console.log(subcolresult)			
                    if(allfieldsresult && subcolresult){			
                        vm.GetEntityProcesses(build).then(function(processesresult) {			
                        // console.log(processesresult)			
                          vm.GetGoalMeasurements(build).then(function(goalsresult) {			
                            if(allfieldsresult && subcolresult && processesresult && goalsresult){			
                                resolve('got the props for '+build.id)			
                            }			
                          })			
                        })			
                    }			
                    })			
                }           			
            })			
        })			
        },			
        GetRequestingUser(filteredcall){			
      let vm = this			
      if(this.userLoggedIn){			
          // this.GetEntities().then(result => {			
              //console.log('result',this.FinalSystemEntities,this.PushedElmnt,this.PushedRows)			
              this.GotEntities = true
              
              if(!this.PushedElmnt && !this.PushedRows){			
                vm.GetDashboardDetails()			
              }	
              else if(this.PushedRows){
                this.DashboardEntity = this.FinalSystemEntities.find(obj => obj.id === this.PushedElmnt.PrimaryEntity)			
                this.FullArray = this.PushedElmnt.Data
                this.LookupArrays = this.PushedElmnt.LookupArrays
                this.BGColor = this.PushedElmnt.ThemeColor+' lighten-5'			
                // this.Today = new Date()
                // this.Today.setHours(0)
                // this.Today.setMinutes(0)
                // this.Today.setSeconds(0)
               
                this.PushedRows.map(elmnt => {
                      let StaticRow = {			
                      Type: 'Row',			
                      Elements: [elmnt]			
                    }
                    this.DashboardElements.push(StaticRow)
                })               
                this.HasData = true	
                this.GotEntities = true
                //console.log(this.DashboardElements)			
                console.log(this.ComputedDashboardElements,this.DashboardEntity && this.HasData && this.GotEntities,this.DashboardEntity,this.HasData,this.GotEntities)
              }	
              else{			
                this.DashboardEntity = this.FinalSystemEntities.find(obj => obj.id === this.PushedElmnt.PrimaryEntity)			
                this.FullArray = this.PushedElmnt.Data			
                //console.log(this.FullArray)
                this.LookupArrays = this.PushedElmnt.LookupArrays			
                			
                let StaticRow = {			
                  Type: 'Row',			
                  Elements: []			
                }			
               			
                  if(this.PushedElmnt.GoalMeasurements){			
                    let newelement = {			
                      GoalObj: this.PushedElmnt,			
                      GoalTargets: this.PushedElmnt.Targets.map(target => {			
                        //console.log(typeof target.StartDate.toDate !== 'undefined',target.StartDate)			
                        if(target.StartDate && typeof target.StartDate.toDate !== 'undefined'){			
                          let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.StartDate.toDate())			
                          target.StartDate = new Date(PrimaryDateSTRING)			
                        }			
                        if(target.EndDate && typeof target.EndDate.toDate !== 'undefined'){			
                          let PrimaryDateSTRING = this.TimestampFormatterSTRING(target.EndDate.toDate())			
                          target.EndDate = new Date(PrimaryDateSTRING)			
                          target.EndDate = this.EndofDay(target.EndDate)			
                        }			
                        return target			
                      }),			
                      AccumulativeGraph: true,			
                      ShowSummary: true,			
                      ShowSummaryOnly: true,			
                      Sort: 'Descending (Value)',			
                      PrimaryLabelType: 'From Timeline',			
                      ColumnWidth: 'Full Column',			
                      ValueType: this.PushedElmnt.ValueType,			
                      Title: this.PushedElmnt.Name,			
                      ComponentOne: this.PushedElmnt.ComponentOne,			
                      ComponentTwo: this.PushedElmnt.ComponentTwo,			
                      ChartPosition: this.PushedElmnt.ChartPosition,			
                      HasChart: true,			
                      ChartType: 'line',			
                      ChartLabelsfromTimeline: true,			
                      HasDataContext: true,			
                      DataContextStyle: 'List',			
                      PrimaryDateFilter: this.PushedElmnt.PrimaryDateFilter,			
                      PrimaryDateObj: this.PushedElmnt.PrimaryDateObj,			
                      GoalMeasurements: true,			
                      PrimaryFilterType: this.PushedElmnt.PrimaryFilterType,			
                      PrimaryFilterField: this.PushedElmnt.PrimaryFilterField,			
                      TimeFilterOption: this.PushedElmnt.ExportTimelineType,			
                      ChartBG: this.PushedElmnt.ThemeColor,			
                    }			
                  			
                  StaticRow.Elements.push(newelement)                  			
                  			
                }			
                
                else if(this.PushedElmnt.PipelineProcess){			
                  //let baseobj = this.RootElementOptions.find(obj => obj.ConfigID === 1000004)			
                  //ChooseOption then CheckAdvanceStep			
			
                    let newelement = {			
                      PipelineProcess: this.PushedElmnt.PipelineProcess,			
                      ValueType: this.PushedElmnt.ValueType,			
                      Sort: 'Descending (Value)',			
                      Title: this.PushedElmnt.Name,			
                      ShowTitle: true,			
                      ChartLabelsfromProcess: true,			
                      HasChart: true,			
                      HasDataContext: false,                      			
                      ChartBG: this.PushedElmnt.ThemeColor,			
                    }			
                  			
                  StaticRow.Elements.push(newelement)  			
                }			
                else{
                  StaticRow.Elements.push(this.PushedElmnt)
                }
                // this.ActiveDashboardComponent = this.DashboardEntity.id			
                this.BGColor = this.PushedElmnt.ThemeColor+' lighten-5'			
                // this.Today = new Date()			
                this.DashboardElements.push(StaticRow)
                this.HasData = true	
                if(this.$route.name === 'SystemConfig' || this.$route.name === 'GettingStarted'){
                  this.DashboardEntity = this.PushedElmnt.DashboardEntity
                  this.GotEntities = true
                }                		
                //console.log(this.DashboardElements)			
                //console.log(this.ComputedDashboardElements,this.DashboardEntity && this.HasData && this.GotEntities,this.DashboardEntity,this.HasData,this.GotEntities)			
              }			
              			
          // })			
      }			
      else {			
        vm.RoutetoLoginPage()			
          }			
          			
      },			
      RoutetoLoginPage(){			
        this.$router.push('/login')			
      },			
      RoutetoErrorPage(){			
        this.$router.push('/PermissionError')			
      },			
    			
    GetDashboardDetails(){			
      //alert('getting dashboard')
        db.collection('customdashboards').doc(this.Dashboardid).onSnapshot(snapshot => {			
            let dashboarddata = snapshot.data()			
            //console.log(dashboarddata)			
            if(dashboarddata){			
                			
                this.DashboardData = dashboarddata			
                if(dashboarddata.DashboardName){			
                    this.DashboardName = dashboarddata.DashboardName			
                }			
                if(dashboarddata.BGColor){			
                  this.BGColor = dashboarddata.BGColor			
                }			
                if(dashboarddata.DashboardEntity){			
                  //console.log(dashboarddata.DashboardEntity)			
                    this.DashboardEntity = this.FinalSystemEntities.find(obj => obj.id === dashboarddata.DashboardEntity)			
                    //console.log(typeof this.DashboardEntity.EntityProcesses,this.DashboardEntity.EntityProcesses.length,this.DashboardEntity.EntityProcesses)			
                    if(this.DashboardEntity.HasActivities){			
                      //console.log('has activitie')			
                    }			
                    if(dashboarddata.Owner){			
                    this.Owner = dashboarddata.Owner			
                }			
                if(dashboarddata.WhocanView){			
                    this.WhocanView = this.UsersArray.filter(user => {			
                        return this.contains(dashboarddata.WhocanView, user.id)			
                    })			
                }			
                if(dashboarddata.WhocanShare){			
                    this.WhocanShare = this.UsersArray.filter(user => {			
                        return this.contains(dashboarddata.WhocanShare, user.id)			
                    })			
                }			
                if(dashboarddata.WhocanEdit){			
                    this.WhocanEdit = this.UsersArray.filter(user => {			
                        return this.contains(dashboarddata.WhocanEdit, user.id)			
                    })			
                }			
                if(dashboarddata.DashboardElements){			
                    this.DashboardElements = dashboarddata.DashboardElements			
                    .map(row => {			
                      row.Elements = row.Elements			
                      .map(elmnt => {			
                        if(elmnt.GoalMeasurements && !elmnt.GoalObj){			
                          elmnt = this.GetElementTargets(elmnt)			
                          			
                         // console.log('elmnt.GoalTargets',elmnt.GoalTargets)			
                          // this.GetGoalTargets			
                        }			
                        //console.log(elmnt.PipelineProcess,this.DashboardEntity.EntityProcesses)			
                        if(elmnt.PipelineProcess && !elmnt.PipelineProcess.Name){			
                            let process = this.DashboardEntity.EntityProcesses.find(obj => obj.id === elmnt.PipelineProcess)			
                            if(process){			
                              elmnt.PipelineProcess = process			
                            }			
                        }			
                        //console.log(elmnt)			
                        if(elmnt.RelatedChild){			
                          			
                          if(elmnt.RelatedChild === 'Activities'){			
                            elmnt.ChildArray = this.DashboardActivities			
                          }			
                          elmnt.RelatedChild = this.FinalSystemEntities.find(obj => obj.id === elmnt.RelatedChild)			
                          			
                          if(elmnt.RelatedChild !== 'Activities' && elmnt.RelatedChild && elmnt.RelatedChild.id !== 'Activities'){			
                            let storearray = this.$store[elmnt.RelatedChild.DisplayName+'Array']			
                            let query = this.$store[elmnt.RelatedChild.DisplayName+'Query']			
                            //should be enough			
                            if(!storearray || storearray && storearray.length === 0){			
                              elmnt.ChildArray = this.GetChildArray(query)			
                            }			
                            else{			
                            elmnt.ChildArray = storearray			
                            }			
                            			
                          }			
                           			
                        }			
                        return elmnt			
                    })			
                    return row			
                    })                  			
                    			
                }			
                }			
                 else{			
                    this.$emit('ActivateStandbyDialog',false)			
                }			
                			
            }			
            else{	
              //alert('not getting anything, emitting okay')			
                this.$emit('ActivateStandbyDialog',false)			
            }			
        })			
    },			
    contains(a, obj) {			
            for (var i = 0; i < a.length; i++) {			
                if (a[i].includes(obj)) {			
                    return true;			
                }			
            }			
            return false;			
        },			
    ViewItem (item) {			
      if(this.DashboardView || this.IntranetView){			
        let routeData = this.$router.resolve({name: 'capitalequipmentjob',params: {id: item.id}})			
        window.open(routeData.href, '_blank')			
      }			
      else{			
        this.$router.push('/CapitalEquipmentJob/'+item.id)			
      }			
      			
    },			
			
    deleteItem (item) {			
      const index = this.CapitalEquipmentJobsArray.indexOf(item)			
      confirm('Are you sure you want to delete this item?') && db.collection('capitalequipmentjobs').doc(item.id).delete() && this.CapitalEquipmentJobsArray.splice(index, 1)			
    },			
			
    editItem (item) {			
      this.editedIndex = this.CapitalEquipmentJobsArray.indexOf(item)			
      this.editedItem = Object.assign({}, item)			
      			
      this.dialog = true			
    },			
    close () {			
      this.dialog = false			
      this.UndefinedMandatoryFields.map(prop => {			
      			
          let element = document.getElementById(prop)			
            if(element){  			
              var x = element.hasAttribute("class")			
              if(x){        			
                element.removeAttribute("class")			
                }			
              }			
            			
          })			
      setTimeout(() => {			
        this.editedItem = Object.assign({}, this.defaultItem)			
        this.editedIndex = -1			
      }, 300)			
    },  			
    			
			
    save(file) {			
      this.UndefinedMandatoryFields = []			
    const EditedCapitalEquipmentJob = {			
  Job_Number: this.editedItem.Job_Number,			
  Status: this.editedItem.Status,			
  Status_Reason: this.editedItem.Status_Reason,			
  Business_Unit: this.editedItem.Business_Unit.Name,			
    Business_Unitid: this.editedItem.Business_Unit.id,			
      }			
      if (this.editedIndex > -1) {			
       			
    for(var prop in EditedCapitalEquipmentJob) {			
        if (EditedCapitalEquipmentJob.hasOwnProperty(prop)) {			
          if(typeof EditedCapitalEquipmentJob[prop] === 'undefined'){			
            this.UndefinedMandatoryFields.push(prop)			
          }			
        else {			
          let element = document.getElementById(prop)			
            if(element){  			
              var x = element.hasAttribute("class")			
              if(x){        			
                element.removeAttribute("class")			
                }			
              }			
          }			
          }			
    			
        else{			
          //console.log('something is amook')			
        }			
      }			
      //console.log(this.UndefinedMandatoryFields)			
      if(this.UndefinedMandatoryFields.length === 0){			
    db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
      Job_Number: EditedCapitalEquipmentJob.Job_Number,			
      			
      Status: EditedCapitalEquipmentJob.Status,			
      			
      Status_Reason: EditedCapitalEquipmentJob.Status_Reason,			
      			
      			
      Business_Unit: {Name: this.editedItem.Business_Unit.Name,id: this.editedItem.Business_Unit.id},			
      Business_Unitid: this.editedItem.Business_Unit.id,			
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
      Modified_Byid: this.UserRecord.id,			
      Modified_On: new Date()			
            });			
            Object.assign(this.CapitalEquipmentJobsArray[this.editedIndex], this.editedItem)			
            this.close()			
            this.EditedCapitalEquipmentJobsnackbar = true			
      }			
      //here we loop for the optional fields and add them as we go			
          			
  			
    if(typeof this.editedItem.Job_Key !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Job_Key: this.editedItem.Job_Key,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Description !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Description: this.editedItem.Description,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Dispatch_Date !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Dispatch_Date: this.Dispatch_DateObject,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Job_Create_Date !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Job_Create_Date: this.Job_Create_DateObject,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Receiving_Date !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Receiving_Date: this.Receiving_DateObject,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
                if(typeof this.editedItem.Client !== 'undefined' && this.editedItem.Client !== ''){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Client: {id: this.editedItem.Client.id, Company_Name: this.editedItem.Client.Company_Name},			
                   Clientid: this.editedItem.Client.id,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                   })			
               }			
  			
                if(typeof this.editedItem.Serial_Number !== 'undefined' && this.editedItem.Serial_Number !== ''){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Serial_Number: {id: this.editedItem.Serial_Number.id, Serial_Number: this.editedItem.Serial_Number.Serial_Number},			
                   Serial_Numberid: this.editedItem.Serial_Number.id,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Accommodation_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Accommodation_Cost: this.editedItem.Accommodation_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Consumable_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Consumable_Cost: this.editedItem.Consumable_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Cost: this.editedItem.Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Distance_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Distance_Cost: this.editedItem.Distance_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Labour_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Labour_Cost: this.editedItem.Labour_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Outwork_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Outwork_Cost: this.editedItem.Outwork_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Sundry_Cost !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Sundry_Cost: this.editedItem.Sundry_Cost,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
  			
    if(typeof this.editedItem.Agency_Codes !== 'undefined'){			
      db.collection('capitalequipmentjobs').doc(this.editedItem.id).update({			
                   Agency_Codes: this.editedItem.Agency_Codes,			
                  Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                  Modified_Byid: this.UserRecord.id,			
                  Modified_On: new Date()			
                   })			
               }			
      }			
      			
       else {			
        const NewCapitalEquipmentJob = {			
    Job_Number: EditedCapitalEquipmentJob.Job_Number,			
      			
    Status: EditedCapitalEquipmentJob.Status,			
      			
    Status_Reason: EditedCapitalEquipmentJob.Status_Reason,			
      			
    			
      Business_Unit: {Name: this.editedItem.Business_Unit.Name,id: this.editedItem.Business_Unit.id},			
      Business_Unitid: this.editedItem.Business_Unit.id,        			
      Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
      Modified_Byid: this.UserRecord.id,			
      Modified_On: new Date()			
        }			
        			
        for(var prop in NewCapitalEquipmentJob) {			
        if (NewCapitalEquipmentJob.hasOwnProperty(prop)) {			
          if(typeof NewCapitalEquipmentJob[prop] === 'undefined' || NewCapitalEquipmentJob[prop] === ''){			
            this.UndefinedMandatoryFields.push(prop)			
            //console.log(prop)			
            //console.log(NewCapitalEquipmentJob[prop])			
          }			
        else {			
          let element = document.getElementById(prop)			
            if(element){  			
              var x = element.hasAttribute("class")			
              if(x){        			
                element.removeAttribute("class")			
                }			
              }			
          }			
          }			
    			
        else{			
          //console.log('something is amook')			
        }			
      }			
      			
        			
          //console.log(this.UndefinedMandatoryFields)			
      if(this.UndefinedMandatoryFields.length === 0){			
        NewCapitalEquipmentJob.Created_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}			
        NewCapitalEquipmentJob.Created_Byid = this.UserRecord.id,          			
        NewCapitalEquipmentJob.Status = {ID: 1000001, Name: 'Active'},			
        NewCapitalEquipmentJob.Status_Reason = {ID: 1000001, Name: 'Active'},			
        NewCapitalEquipmentJob.Created_On = new Date(),			
        NewCapitalEquipmentJob.Modified_By = {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
        NewCapitalEquipmentJob.Modified_Byid = this.UserRecord.id,			
        NewCapitalEquipmentJob.Modified_On = new Date()			
        NewCapitalEquipmentJob.Job_Number = this.editedItem.Job_Number			
             			
                        let Job_NumberQuery = []			
                        let Job_NumberStringArray = this.editedItem.Job_Number.split('')			
                        var lowertext = "";                			
                        var p;			
                          let buildup = ''			
                        //console.log(Job_NumberStringArray.length,Job_NumberStringArray)			
                          for (p = 0; p < Job_NumberStringArray.length; p++) {			
                              buildup = buildup+ Job_NumberStringArray[p]			
                                lowertext += buildup.toLowerCase() + ",";			
                                }                   			
                          Job_NumberQuery = lowertext.split(',')  			
                          Job_NumberQuery.length = Job_NumberQuery.length-1			
                       // db.collection('capitalequipmentjobs').doc(doc.id).update({			
                          NewCapitalEquipmentJob.Job_NumberQuery = Job_NumberQuery,			
			
                         // })			
                      			
        db.collection('capitalequipmentjobs').add(NewCapitalEquipmentJob).then(doc => {			
          			
        			
           if(typeof this.editedItem.Job_Key !== 'undefined' && this.editedItem.Job_Key !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Job_Key: this.editedItem.Job_Key,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Description !== 'undefined' && this.editedItem.Description !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Description: this.editedItem.Description,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Dispatch_Date !== 'undefined' && this.editedItem.Dispatch_Date !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Dispatch_Date: this.Dispatch_DateObject,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Job_Create_Date !== 'undefined' && this.editedItem.Job_Create_Date !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Job_Create_Date: this.Job_Create_DateObject,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Receiving_Date !== 'undefined' && this.editedItem.Receiving_Date !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Receiving_Date: this.Receiving_DateObject,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
                    			
                      if(typeof this.editedItem.Client !== 'undefined' && this.editedItem.Client !== ''){			
      db.collection('capitalequipmentjobs').doc(doc.id).update({			
                   Client: {id: this.editedItem.Client.id, Company_Name: this.editedItem.Client.Company_Name},			
                   Clientid: this.editedItem.Client.id,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                   })			
                    }			
        			
                    			
                      if(typeof this.editedItem.Serial_Number !== 'undefined' && this.editedItem.Serial_Number !== ''){			
      db.collection('capitalequipmentjobs').doc(doc.id).update({			
                   Serial_Number: {id: this.editedItem.Serial_Number.id, Serial_Number: this.editedItem.Serial_Number.Serial_Number},			
                   Serial_Numberid: this.editedItem.Serial_Number.id,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                   })			
                    }			
        			
           if(typeof this.editedItem.Accommodation_Cost !== 'undefined' && this.editedItem.Accommodation_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Accommodation_Cost: this.editedItem.Accommodation_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Consumable_Cost !== 'undefined' && this.editedItem.Consumable_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Consumable_Cost: this.editedItem.Consumable_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Cost !== 'undefined' && this.editedItem.Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Cost: this.editedItem.Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Distance_Cost !== 'undefined' && this.editedItem.Distance_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Distance_Cost: this.editedItem.Distance_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Labour_Cost !== 'undefined' && this.editedItem.Labour_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Labour_Cost: this.editedItem.Labour_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Outwork_Cost !== 'undefined' && this.editedItem.Outwork_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Outwork_Cost: this.editedItem.Outwork_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Sundry_Cost !== 'undefined' && this.editedItem.Sundry_Cost !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Sundry_Cost: this.editedItem.Sundry_Cost,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }			
        			
           if(typeof this.editedItem.Agency_Codes !== 'undefined' && this.editedItem.Agency_Codes !== ''){			
            db.collection('capitalequipmentjobs').doc(doc.id).update({			
                        Agency_Codes: this.editedItem.Agency_Codes,			
                        Modified_By: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},			
                        Modified_Byid: this.UserRecord.id,			
                        Modified_On: new Date()			
                        })			
                    }                      			
                    			
                			
                                			
                  this.close()			
        })			
        			
      }			
        else{			
      alert('you need to capture the values in '+this.UndefinedMandatoryFields)			
      this.UndefinedMandatoryFields.map(field => {			
        //console.log('getting element by id '+field)			
        let element = document.getElementById(field)			
        			
        element.setAttribute("class", "incompletedmandatory")			
        //console.log('here is element')			
        //console.log(element)			
      })			
    }			
        			
      }			
      			
    },			
  			
  }			
}			
			
</script>			
			
 <style>			
  			
    			
</style>			
<style scoped>			
.v-data-table{			
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;			
  color:#757575;			
}			
</style>