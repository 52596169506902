          
<template>
  <div class="vehicleswiki" :style="FilterProp ?'margin-top:126px;padding-bottom:50px;':''">
  
      <!-- <v-layout row class="fill-height"> -->
        
              <v-col :style="'padding: 0px;'" :cols="12">
               
              <v-card tile :class="CurrentEntityBoxTransparency" :elevation="CurrentEntityElevation"  height="100%">
               <v-pagination
                :total-visible="9"
                    v-model="DataTableListingspage"
                    circle class="recordtoolbar" color="background links--text"
                    :length="Math.ceil(FilteredComputedDataTableListings.length/9)"
                ></v-pagination>
             
                <ParallaxTab v-if="ProductBrandParallax" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="ProductBrandParallax" @OpenDialog="OpenDialog" :i="i"
                    />
                  <v-row style="padding:20px;">
                  <div :class="FlexClasses" v-for="item in ComputedDataTableListingsPaginated.slice(0, 9)"
                    :key="item.itemObjKey" :style="MiniView ? '' : 'padding:10px;'">
                       <ClassifiedListingListItem v-if="CurrentEntity.CategoricalListType !== 'Wiki Listing'" :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                       :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription" @PushActiveProduct="PushActiveProduct"
                       :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                       :FeatureList="FeatureList" :CompareItems="CompareItems" :IMGIsCarousel="CurrentEntity.IMGIsCarousel" :CartItems="CartItems"
                       :FlexXLRG="FlexXLRG" :FlexLarge="FlexLarge" :FlexMedium="FlexMedium" :FlexSmall="FlexSmall" :FlexXSmall="FlexXSmall"	
                       />
                       <WikiListingItem v-if="CurrentEntity.CategoricalListType === 'Wiki Listing'" :TaggedFields="CurrentEntity.TaggedFields" :TagField="CurrentEntity.TagField" :CurrentEntity="CurrentEntity"
                       :HidePrice="CurrentEntity.HidePrice" :HideTitle="CurrentEntity.HideTitle" :HideDescription="CurrentEntity.HideDescription"
                       :HideIMG="CurrentEntity.HideIMG"
                       :item="item" :AppisDarkMode="AppisDarkMode" @ActivateViewportBuilder="ActivateViewportBuilder" @AddtoCompare="AddtoCompare"
                       :FeatureList="FeatureList" :CompareItems="CompareItems"
                       :IMGField="CurrentEntity.IMGField" :DescriptionField="CurrentEntity.DescriptionField" :TitleField="CurrentEntity.TitleField"
                       />
                    </div>
                  </v-row>
                </v-card>
              </v-col>
            
      <!-- </v-layout> -->

  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ClassifiedListingListItem from '@/components/Directories/ClassifiedListingListItem';
import WikiListingItem from '@/components/Directories/WikiListingItem';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTabReadOnly';
export default {
  props: ['LinksGroupsearch','ViewType','DefaultView','ShowPagination','AppisDarkMode','FilterProp','FilterField','FilterRecord','Record','KeyWord','KeyWords'
  ,'ClassifiedsClasses','mainclass','subclass','ActiveFilters','AdditionalFilters','NavList','CurrentEntity','thirdclass','FeatureList','CompareItems','CartItems'],
  components: {
      ClassifiedListingListItem,
      WikiListingItem,
      ParallaxTab
  },
  data() {
    return {
      PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
        HTMLBox: {
                BoxTransparency: 'transparent',
                RowColor: '#00ffff',									
                Name: 'HTML Box',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: ``,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#ffffff'									
                },									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Elevation: 0,
                HeaderFontSize: 50,		
                //DisplayasChip: true						
            },
        CompareItems: [],
        NEWLinksGroupsearch: '',
        DataTableListings: [],
        DataTableListingspage: 1,
        FeatureListisLookup: false,
        FeatureLookupProp: '',
        FlexXLRG:3,									
        FlexLarge: 4,									
        FlexMedium: 4,									
        FlexSmall: 6,									
        FlexXSmall: 6,		
    }
  },

  
  
  created(){  
    //console.log(this.$route.query)
    let newvalue = this.System
        //console.log(this.System)
        if(newvalue.AppDefaultHeader){
            this.PageHeadersFont = newvalue.AppDefaultHeader.Name
            this.PageHeadersFontSize = newvalue.AppDefaultHeader.SizeMultiplier
            }
            if(newvalue.AppDefaultBody){
            this.PageContentFont = newvalue.AppDefaultBody.Name
            }
      this.GetClassifiedListings()
      this.FeatureList  = this.CurrentEntity.FeatureList
      
      //console.log(this.CurrentEntity)
      if(this.CurrentEntity.FeatureListType === 'Lookup'){
        this.FeatureListisLookup = true
        this.FeatureLookupProp = this.CurrentEntity.FeatureListLookupField.Name
        this.GetFeatureListEntries(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('CategoricalNavlists').doc(this.CurrentEntity.id).collection(this.CurrentEntity.FeatureListLookupField.RelatedBuildID))
      }
  },
  computed: {
    FlexClasses(){
      return 'flex xl'+this.FlexXLRG+' lg'+this.FlexLarge+' md'+this.FlexMedium+' sm'+this.FlexSmall+' xs'+this.FlexXSmall+''
    },
    UserisGuest(){
    return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined' 
    
  },
    RouteQuery(){
      return this.$route.query
    },
    RouteQueries(){
        let arr = []
        if(this.RouteQuery){
          for(var prop in this.RouteQuery) {
            arr.push({Prop: prop,Value: this.RouteQuery[prop]})
          }
        }
        return arr
      },
    MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
    VuexStoreCatNavlist(){
      return this.CurrentEntity && this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.name !== 'DirectoryBuilder' ? this.$store.state.CatNavlists.find(obj => obj.EntityID === this.CurrentEntity.id+'_'+this.$route.meta.PageID) :
       this.$store.state.CatNavlists.find(obj => obj.EntityID === this.CurrentEntity.id)    
    },
    VuexStoreStateData(){
        return this.VuexStoreCatNavlist ? this.VuexStoreCatNavlist.Data : []
    },
    System(){
      return this.$store.state.SystemObj
    },
    ProductBrandParallax(){
            if(this.BrandFilter && this.BrandFilterLogo){
                let brand = this.BrandFilter
               let defaulttab = {									
                RowColor: '#ffffff',									
                Name: 'Parallax',									
                SectionType: true,									
                Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-image-area',									
                Height: 200,									
                Elements: [],									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:12,									
                FlexLarge: 12,									
                FlexMedium: 12,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            }
            defaulttab.IMG = brand.Logo
            let titletab = Object.assign({},this.HTMLBox)
            titletab.Title = brand.Name
            if(brand.Store_Read_MoreLink){
                //titletab.ChipRoute = brand.WikiPath
                titletab.HasActionButton = true
                titletab.ButtonAlignment = 'justify-end'
                titletab.ButonColor = {
                    hex: '#ffffff',
                    hexa: '#ffffff'
                }
                titletab.Outlined = true
                titletab.Fancy = true
                if(!brand.Store_Writeup){
                    titletab.ActionButtonName = 'View'
                    titletab.ActionButtonType = 'URL'
                    titletab.ActionButtonRoute = brand.Store_Read_MoreLink   
                }
                else{
                    let actioncontenttab = Object.assign({},this.HTMLBox)
                    actioncontenttab.HasActionButton = true
                    actioncontenttab.ButtonAlignment = 'justify-end'
                    actioncontenttab.ButonColor = {
                        hex: '',
                        hexa: ''
                    }
                    actioncontenttab.ActionButtonName = 'View'
                    actioncontenttab.ActionButtonType = 'URL'
                    actioncontenttab.ActionButtonRoute = brand.Store_Read_MoreLink
                    actioncontenttab.Description = brand.Store_Writeup
                    actioncontenttab.Title = brand.Name
                    if(brand.Logo){
                        actioncontenttab.HasHeaderImage = true
                        actioncontenttab.Justify = 'justify-center'
                        actioncontenttab.HeaderImage = brand.Logo
                    }
                    titletab.ContentDialogType = {
                        ComponentName: 'HTML Box'
                    }
                    //console.log(actioncontenttab)
                    titletab.ActionButtonName = 'Read More'
                    titletab.ActionButtonType = 'Content Dialog'                    
                    titletab.ContentDialogBox = actioncontenttab
                }
                
            }
            //console.log(titletab)
            defaulttab.Elements.push(titletab)
            return defaulttab 
            }
    },
    BrandFilter(){
      return this.ActiveFilters.find(obj => obj.BrandFilter)
    },
    BrandFilterLogo(){
      return this.BrandFilter && this.BrandFilter.Logo ? this.BrandFilter.Logo : ''
    },
    CurrentEntityElevation(){
      return this.CurrentEntity.CategoricalListType ==='Store Products' ? 0 : ''
    },
    CurrentEntityBoxTransparency(){
      return this.CurrentEntity.CategoricalListType ==='Store Products' ? '' : ''
    },
    ComputedKeyWords(){
      return this.FilteredComputedDataTableListings.map(item => {
        let keywords = []
        if(item.KeyWords){
          keywords = item.KeyWords
        }
        return keywords
      }).flat()
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    StoreSearch(){
      if(this.CurrentEntity.CategoricalListType === 'Store Products'){
        return this.$store.state.StoreSearch
      }
    },
    
      ComputedDataTableListingsPaginated(){
        let displayrange = 9*this.DataTableListingspage-9
        return this.FilteredComputedDataTableListings.slice(displayrange, displayrange+9)
      },
      FilteredComputedDataTableListings(){
        return this.ComputedDataTableListings
        .filter(item => {
          if(this.CurrentEntity.QualifiedListings && this.CurrentEntity.QualifiedListings.length > 0){
            return this.CurrentEntity.QualifiedListings.includes(item.Status_Reason.ID)
          }
          else{
            return item
          }
        }).filter(item => {
          if(this.KeyWord){
            return item.KeyWords && item.KeyWords.includes(this.KeyWord)
          }
          else{
            return item
          }
        })
        .filter(item => {
          //console.log(this.StoreSearch)
          if(this.StoreSearch){
            return item.Title.toLowerCase().includes(this.StoreSearch.toLowerCase())
          }
          else{
            return item
          }
        })        
      },
      ComputedDataTableListings(){
        if(this.$route.name === 'WikiCollectionBuilder'){
          return this.DataTableListings
        }
        else{
          return this.DataTableListings
          .map(clsfd => {
            //console.log(this.ActiveFilters)
            clsfd.Render = true
            this.ActiveFilters.map(filter => {
              if(!clsfd[filter.PropName] && !filter.IsOptionList){
                clsfd.Render = false
              }
              else if(!filter.IsOptionList){
                if(filter.Type === 'Option Set'){
                    //console.log(clsfd[filter.PropName].ID,filter.ID)
                  if(clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Lookup'){
                    //console.log(clsfd[filter.PropName].ID,filter.ID)
                  if(clsfd[filter.PropName].id !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Single Option Filter'){
                  if(field.Field.Type === 'Lookup'){
                    if(clsfd[filter.PropName].id !== filter.ID){
                      clsfd.Render = false
                    }
                  }
                  else{
                    if(clsfd[filter.PropName].ID !== filter.ID){
                      clsfd.Render = false
                    }
                  }
                }
                
                else if(filter.Type === 'Text Search' && filter.ActiveFilter){
                  if(!clsfd[filter.PropName] || clsfd[filter.PropName] && !clsfd[filter.PropName].toLowerCase().includes(filter.ActiveFilter.toLowerCase())){
                    clsfd.Render = false
                  }
                }
              }
              else if(filter.Type === 'Number Field' && filter.IsOptionList){
                  if(clsfd[filter.PropName] < filter.Range[0] || clsfd[filter.PropName] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Lookup' && filter.IsOptionList){
                  if(!clsfd[filter.PropName] || clsfd[filter.PropName] && clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
              })
            this.AdditionalFilters.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(!clsfd[filter.Field.Name]){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            this.NavList.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(!clsfd[filter.Field.Name]){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            //console.log(this.FeatureList)
            this.FeatureList.map(feature => {
              
              
                if(feature.Filter){
                  
                  if(!this.FeatureListisLookup && typeof clsfd[feature.Name] === 'undefined'){
                    //console.log(clsfd.Title,feature.Name,clsfd[feature.Name],typeof clsfd[feature.Name] === 'undefined',clsfd.Render)
                    clsfd.Render = false
                  }
                  
                  else if(this.FeatureListisLookup && typeof clsfd[this.FeatureLookupProp] === 'undefined' || this.FeatureListisLookup && clsfd[this.FeatureLookupProp] && !feature.Matches.includes(clsfd[this.FeatureLookupProp].id) ){
                  clsfd.Render = false
                  }
                  // console.log(clsfd.Title,feature.Name,clsfd[feature.Name],typeof clsfd[feature.Name] === 'undefined',clsfd.Render)
                  //console.log(clsfd[this.FeatureLookupProp],feature.Matches)
                }
            })
            //console.log(this.RouteQueries)
            this.RouteQueries.map(routequery => {
              let prop = routequery.Prop
              let val = routequery.Value
              let navlist = this.CurrentEntity.NavList.find(obj => obj.Field && obj.Field.id === prop)
              //actually this match only works for Single Option filter
              if(!navlist){
                //now this check would pass for treeview
                navlist = this.CurrentEntity.NavList.find(obj => obj.NavTreeField === prop)
                if(!navlist){
                  //if still no match we ahve to search for Filters type , which is a damn tricckya rray
                  let filtertypes = this.CurrentEntity.NavList.filter(type => {
                    return type.Type === 'Filter List'
                  })
                  filtertypes.map(filtyp => {
                    navlist = filtyp.Filters.find(obj => obj.id === prop)
                  })
                  //okay we should relaly "break" but nevermind it would now assign the last match
                  if(navlist){
                    let field = navlist
                    if(field.Type === 'Lookup' || field.Type === 'Option Set'){
                      let match = field.Options.find(obj => obj.Name === val)
                      
                      if(!match){
                        clsfd.Render = false
                      }
                      else{
                        if(!clsfd[field.id] || clsfd[field.id] && !clsfd[field.id]
                        || clsfd[field.id] && clsfd[field.id] && clsfd[field.id].ID !== match.ID){
                        clsfd.Render = false
                      }
                      }
                      
                    }
                  }
                  else{
                    //I don't know, maybe just a dud query
                  }
                }
                else{
                  let match = navlist.TreeList.find(obj => obj.name === val)
                  if(!match){
                      clsfd.Render = false
                    }
                    else{
                    if(!clsfd[prop] || clsfd[prop] && clsfd[prop].ID !== match.id){
                        clsfd.Render = false
                      }
                    }
                }
              }
              else{
                //yep the query passed for Single Option Filter, so "Field" prop of "navlist" (actually filter option of entire navlist) will suffice
                let field = navlist.Field 
                if(field.Type === 'Lookup' || field.Type === 'Option Set'){
                  let match = field.Options.find(obj => obj.Name === val)
                  
                  if(!match){
                    clsfd.Render = false
                  }
                  else{
                    if(!clsfd[field.id] || clsfd[field.id] && !clsfd[field.id]
                    || clsfd[field.id] && clsfd[field.id] && clsfd[field.id].ID !== match.ID){
                    clsfd.Render = false
                  }
                  }
                  
                }
              }
            })
            
            return clsfd       
          })
          .filter(clsfd => {
            return clsfd.Render
          })
          .filter(clasfd => {
            //console.log(this.subclass,clasfd[this.mainclass.LevelFieldName])
              if(this.thirdclass){
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID && clasfd[this.subclass.LevelFieldName] && clasfd[this.subclass.LevelFieldName].ID === this.subclass.ID
                && clasfd[this.thirdclass.LevelFieldName] && clasfd[this.thirdclass.LevelFieldName].ID === this.thirdclass.ID
              }
              else if(this.subclass){
                
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID && clasfd[this.subclass.LevelFieldName] && clasfd[this.subclass.LevelFieldName].ID === this.subclass.ID
              }
              else if(this.mainclass){
                return clasfd[this.mainclass.LevelFieldName] && clasfd[this.mainclass.LevelFieldName].ID === this.mainclass.ID
              }
              else{
                return clasfd
              }
          })
        }
          
      },
      
    
    },
  
  watch: {    
    VuexStoreStateData: {
            handler: function(oldvalue, newvalue) {
              //console.log(oldvalue, newvalue)
             if(oldvalue !== newvalue){
               
               this.FixArrayItems()
             }
            },
            deep: true
        },
    KeyWord: {
            handler: function(oldvalue, newvalue) {
             if(oldvalue !== newvalue){
               this.DataTableListingspage = 1
             }
            },
            deep: true
        },
    ComputedKeyWords: {
            handler: function(oldvalue, newvalue) {
              //console.log('ComputedKeyWords',oldvalue,newvalue)
              this.$emit('UpdateComputedKeyWords',this.ComputedKeyWords)
            },
            deep: true
        },
  },
  
  methods: {
    ActivateTreefromChild(clss){
      
      this.$emit('ActivateTreefromChild',clss)
    },
    PushActiveProduct(item){
            this.$emit('PushActiveProduct',item)
        },
    GetFeatureListEntries(query){
      // FeatureList.LookupEntries = []
      //console.log(query)
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
            if (change.type === 'added') {
              let featureitem = {
                ...change.doc.data(),
                id: change.doc.id
            }
            this.FeatureList.map(feature => {
              if(!feature.Matches){
                feature.Matches = []
              }
              if(featureitem[feature.id] && !feature.Matches.includes(featureitem.id)){
                feature.Matches.push(featureitem.id)
              }
              return feature
            })
            }
          })
      })
    },
    AddtoCompare(item){
      this.$emit('AddtoCompare',item)
    },
      ActivateViewportBuilder(item){
        if(this.$route.name !== 'WikiCollectionBuilder'){
          this.$emit('ActivateViewportBuilder',item,'Classified Listing')
        }
        else{
          this.$emit('ActivateViewportBuilder',item,'Wiki Listing')
        }
            
        },
      GetClassifiedListings(){
        //console.log('this.VuexStoreCatNavlist',this.VuexStoreCatNavlist)
          let array = []
          let query = ''
          if(this.CurrentEntity.CategoricalListType === 'Wiki Listing'){
            //alert('yay')
            query = db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'wiki')
            if(this.CurrentEntity.IsFiltered && this.CurrentEntity.FilterField && this.CurrentEntity.FilterbyCurrent){
              query = query.where(this.CurrentEntity.FilterField.Name+'id','==',this.Record.id)
            }
          }
          else if(this.CurrentEntity.CategoricalListType === 'Classified Listing'){
            query = db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings')
          }
          else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
            query = db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()+'store')
          }
          else if(this.CurrentEntity.CategoricalListType === 'Social Groups'){
            
            if(this.CurrentEntity && this.CurrentEntity.ListPrimaryField && this.CurrentEntity.ListPrimaryField.LevelFieldName){
             let primarymatch = this.CurrentEntity.ListPrimaryField.LevelFieldName+'.ID'
             let privacytypes = ['Public']
              if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userLoggedIn){
                privacytypes.push('All Site Members')
                if(!this.UserisGuest){
                  privacytypes.push('Non Guest Site Members')
                }
              }
            query = query = db.collection('groups')
              .where('GroupPrivacy','in',privacytypes).where('GroupPublishStatus','==','Published').where(primarymatch,'==',this.CurrentEntity.ListPrimaryField.ID) 
            
            let pageid = ''
              if(this.$route.meta && this.$route.meta.PageID){
              pageid = this.$route.meta.PageID
              query = query.where('ActiveDirectoryPages','array-contains',pageid)
              }
            }
            else{
              let privacytypes = ['Public']
              if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userLoggedIn){
                privacytypes.push('All Site Members')
                if(!this.UserisGuest){
                  privacytypes.push('Non Guest Site Members')
                }
              }
              
              query = db.collection('groups')
              .where('GroupPrivacy','in',privacytypes).where('GroupPublishStatus','==','Published')
              let pageid = ''
              if(this.$route.meta && this.$route.meta.PageID){
              pageid = this.$route.meta.PageID
              }
              if(pageid){
                
                //console.log(pageid)
                query = query.where('ActiveDirectoryPages','array-contains',pageid)
              }
              
              //query = db.collection('groups').where('GroupPublishStatus','==','Published')GroupPrivacy == 'Public'
            }
            
          }
          else if(this.CurrentEntity.CategoricalListType === 'Business Members'){
            query = db.collection('businessmembers')
            
          }
          if(this.CurrentEntity.QualifiedListings && this.CurrentEntity.QualifiedListings.length > 0){
           query = query.where('Status_Reason.ID','in',this.CurrentEntity.QualifiedListings)
          }
          //console.log(query)
          if(!this.userLoggedIn && this.CurrentEntity.CategoricalListType !== 'Social Groups' && this.CurrentEntity.CategoricalListType !== 'Store Products'){
            query = query.where('PublishType','==','Public')
          }
          // else if(!this.userLoggedIn && this.CurrentEntity.CategoricalListType === 'Social Groups'){
          //   query = query.where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public')
          // }
          if(this.CurrentEntity){
              array = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'Array']
              if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                // let setcollection = {
                //   arrayname : payload.arrayname, 
                //   array: []
                // } 
                //     commit('setCollectionArray', setcollection).then(function(result) {
                //     })
              }
              let storeobjpayl = {
                EntityID: this.CurrentEntity.id,
                Collection: '',
                Data: [],
                Query: query,
                CategoricalListType: this.CurrentEntity.CategoricalListType
              }
              if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                storeobjpayl.Collection = this.CurrentEntity.id.split('_').join('').toLowerCase()+'store'
              }
              else if(this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.meta.PageID){
                storeobjpayl.EntityID = this.CurrentEntity.id+'_'+this.$route.meta.PageID
              }
              //console.log(storeobjpayl)
              //console.log(this.$store.state)
              //this.$store.commit('SetUserStateObj', stateuser)
              this.$store.commit('SetCatNavlistTableQuery',storeobjpayl)
              //console.log(this.VuexStoreCatNavlists,this.VuexStoreCatNavlist,this.VuexStoreStateData)
              // .then(querystoreres => {
                //console.log(querystoreres)
                if(this.VuexStoreCatNavlist && this.VuexStoreStateData.length > 0){
                  console.log('previously loaded list')
                  this.FixArrayItems()
                }
                else{
                  let storedatapayload = {
                    EntityID: this.CurrentEntity.id,
                    Query: query,
                    CategoricalListType: this.CurrentEntity.CategoricalListType
                  }
                  if(this.CurrentEntity.CategoricalListType === 'Social Groups' && this.$route.meta.PageID){
                    storedatapayload.EntityID = this.CurrentEntity.id+'_'+this.$route.meta.PageID
                  }
                  this.$store.dispatch('GetCatNavlistDataArray',storedatapayload).then(result => {
                  //console.log('oooh yaya a result',result)
                  console.log('loaded list new')
                  //this.FixArrayItems()
                  })
                }
              // })
              // /db.collection(this.CurrentEntity.DisplayName.split(' ').join('').toLowerCase()+'listings')
              
              // query.onSnapshot(res => {
              //   //console.log('this.DataTableListings',this.DataTableListings)
              //           const changes = res.docChanges();
              //           changes.forEach(change => {
              //             if (change.type === 'added') {
              //               let obj = {
              //                 ...change.doc.data(),
              //                 id: change.doc.id
              //               }
              //               if(this.CurrentEntity.id === 'Group_Categories'){
              //                 if(obj.logo){
              //                   obj.Photo = {
              //                     ThumbURL: obj.logo
              //                   }
              //                 }
              //                 if(obj.DefaultPage && obj.DefaultPage.Type === 'Group Page'){
              //                   obj.Path = '/Group-Page/'+obj.id+'/'+obj.DefaultPage.Name
              //                 }
              //                 else{
              //                   obj.Path = '/Group/'+obj.id
              //                 }
              //                 obj.Title = obj.name
              //               }
              //               else if(this.CurrentEntity.id === 'Business_Members'){
              //                 obj.Photo = {
              //                     ThumbURL: obj.Profile_Photo
              //                   }
              //                 obj.Path = '/BusinessMember/'+obj.id
              //               }
              //               else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
              //                  obj.Description = obj.DescriptiveText
              //                 obj.Path = '/StoreItem/'+this.CurrentEntity.id+'/'+obj.id
              //               }
              //               this.DataTableListings.push(obj)                            
              //             }
              //           })
              //         })
          }
          //console.log(this.CurrentEntity,array,this.DataTableListings)
          
      },
      FixArrayItems(){
        //let array = JSON.parse(JSON.stringify(this.VuexStoreStateData))
        //console.log(this.VuexStoreStateData,this.$store.state.CatNavlists)
        this.DataTableListings = this.VuexStoreStateData.map(obj => {
          //console.log(obj)
          if(this.CurrentEntity.id === 'Group_Categories'){
                    if(obj.logo){
                      obj.Photo = {
                        ThumbURL: obj.logo
                      }
                    }
                    if(obj.DefaultPage && obj.DefaultPage.Type === 'Group Page'){
                      obj.Path = '/Group-Page/'+obj.id+'/'+obj.DefaultPage.Name
                    }
                    else{
                      obj.Path = '/Group/'+obj.id
                    }
                    obj.Title = obj.name
                  }
                  else if(this.CurrentEntity.id === 'Business_Members'){
                    obj.Photo = {
                        ThumbURL: obj.Profile_Photo
                      }
                    obj.Path = '/BusinessMember/'+obj.id
                  }
                  else if(this.CurrentEntity.CategoricalListType === 'Store Products'){
                      obj.Description = obj.DescriptiveText
                    obj.Path = '/StoreItem/'+this.CurrentEntity.id+'/'+obj.id
                  }
                  return obj
        })
              
      },
    
    
      
  
  }
}

</script>

 <style>
 .hovercard {
     max-height: 200px;
 }
.hovercard:hover {
    background-color: #eb371b;
    max-height: 350px;
}
.hovercard .hovercardstitle {
 color: #eb371b; 
 font-family: 'Roboto', sans-serif;
 text-transform: uppercase; 
 font-size: 1em;
 letter-spacing: 0.1666666667;
   
}
.hovercard:hover .hovercardstitle {
 color: white; 
 font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;  
}
.hovercard .hovercardbutton {
 visibility: hidden 
}
.hovercard .hovercardimage{
height: 70px;
}
.hovercard:hover .hovercardimage{
height: 200px;
}
.hovercard:hover .hovercardbutton {
 visibility: visible;   
}

</style>
<style scoped>

</style>
          
            
        