																								
<template>																								
<v-card color="rgba(255,255,255,0.01)" :dark="RecordisDarkMode" flat>																								
 <v-card width="100%" class="stickytopbanner3 transparent" id="recordownershipbanner2"  flat v-if="!$route.params.id">																								
  <v-parallax																								
  height="60"																								
  src="@/assets/RABaseBG.jpeg">																								
    <v-layout row class="justify-center">																								
     <h2 class="white--text my-3">Records Management - Administration</h2>																								
    </v-layout>																								
  </v-parallax>																								
   </v-card>																								
    																								
         <v-dialog v-model="UploadingRMDocs" max-width="500px" persistent>																								
                   <v-card width="100%" height="100%">																								
                     <v-card-title>Uploading Files</v-card-title>																								
                      <v-row																								
                            class="fill-height ma-0"																								
                            align="center"																								
                            justify="center"																								
                          >																								
                        <v-layout row class="justify-center mx-3 my-3" id="RMDocsprogressbar" style="display:none">																								
                            <ul id="RMDocsprogressgroupbar">																								
                            </ul>																								
                        </v-layout>																								
                      </v-row>																								
                   </v-card>																								
                 </v-dialog> 																								
          <v-layout class="justify-center" :style="RMTopPadding">																								
      <v-flex lg10 md10> 																								
        <v-dialog v-model="UploadItemsDialog"  max-width="500">																								
          <v-card																								
            class="mx-auto"																								
            max-width="500"																								
            dark																								
          >																								
            <v-img src="@/assets/RABaseBG.jpeg">																								
              																								
            <v-card-title class="title font-weight-regular justify-space-between">																								
              <span>Step 1 - Registration Type</span>																								
              <v-avatar																								
                size="45"																								
              >																								
              <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">																								
              </v-img>																								
              <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">																								
              </v-img>																								
              </v-avatar>																								
            </v-card-title>																								
            <v-card-subtitle>																								
              {{UserRecord.Full_Name}}																								
            </v-card-subtitle>																								
           																								
                <v-card-text>																								
                 <v-select  :rules="[rules.required]" :items="['Register Entity','Register Later']"																								
                      label="Select Process" v-model="UploadType"																								
                      item-text="Name">																								
                    </v-select>																								
                </v-card-text>																								
            																								
                <v-card-actions>																								
                  <v-btn																								
              >																								
                Cancel																								
              </v-btn>																								
          <v-spacer></v-spacer>																								
                <v-btn        																								
                  depressed																								
                  color="green"																								
                  @click="ProcessRegistrationType()"																								
                >																								
                  Go																								
                </v-btn>          																								
              </v-card-actions>																								
            </v-img>																								
          </v-card>																								
        </v-dialog>																								
        <v-dialog v-model="UploadItemstoEntityDialog"  max-width="500" persistent>																								
        	<v-card dark max-width="500" class="center" color="rgb(240, 240, 240, 0.95)" v-if="UploadItemstoEntityProcessing">																							
            <!-- <v-container bg fill-height grid-list-md text-xs-center> -->																								
              <v-img src="@/assets/RABaseBG.jpeg"> 																								
        																								
            <v-card-subtitle class="justify-center display-2">																								
              Please wait while the system is processing. Do NOT exit the page until this Window closes																								
            </v-card-subtitle>																								
            <v-card-text class="justify-center">																								
              Processing																								
              <v-layout class="justify-center">																								
                <div class="loader"></div>																								
                </v-layout>																								
            </v-card-text>																								
       																								
              </v-img>																								
            <!-- </v-container> -->																								
          </v-card>																								
																								
          <v-card																								
            class="mx-auto"																								
            max-width="500"																								
            dark																								
            v-if="!UploadItemstoEntityProcessing"																								
          >																								
            <v-img src="@/assets/RABaseBG.jpeg">																								
              		 <v-spacer></v-spacer><v-btn icon @click="CancelNewuploads()"><v-icon x-large color="red">mdi-close</v-icon></v-btn>																						
            <v-card-title class="title font-weight-regular justify-space-between">																								
              <span>Step {{UploadDocstoEntityStep}} - Registration</span>																								
              <v-avatar																								
                size="45"																								
              >																								
              <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">																								
              </v-img>																								
              <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">																								
              </v-img>																								
              </v-avatar>																								
            </v-card-title>																								
            <v-card-subtitle>																								
              {{UserRecord.Full_Name}}																								
            </v-card-subtitle>																								
            <v-window v-model="UploadDocstoEntityStep">																								
              <v-window-item :value="1">																								
                <v-card-text>																								
                <v-autocomplete  :rules="[rules.required]" :items="Entities"																								
                      label="Select Entity" v-model="selectedEntity"																								
                      return-object																								
                      item-text="Name">																								
                    </v-autocomplete>																								
                     <v-switch  :rules="[rules.required]"																								
                      label="Select Record?" v-model="WillUploadRecord"																								
                      >																								
                    </v-switch>																								
                </v-card-text>																								
              </v-window-item>																								
              <v-window-item :value="2">																								
                <v-card-text>																								
                 <v-autocomplete  :rules="[rules.required]" :items="RelevantEntitiyRecords"																								
                  label="Select Record" v-model="selectedRecord" :item-text="[selectedEntity.PrimaryField]" return-object>																								
                  </v-autocomplete>																								
                  <v-switch  :rules="[rules.required]"																								
                      label="Select Table?" v-model="WillUploadTable"																								
                      >																								
                    </v-switch>																								
                </v-card-text>																								
              </v-window-item>																								
              <v-window-item :value="3">																								
                <v-card-text>																								
                 <v-select  :rules="[rules.required]" :items="selectedEntity.Tables"																								
                  label="Select Table" v-model="selectedTable" return-object item-text="Name">																								
                  </v-select>																								
                  <v-switch v-if="selectedTable && selectedTable.Type === 'Document Register'" :rules="[rules.required]"																								
                      label="Select Primary Class?" v-model="WillUploadPrimaryOption"																								
                      >																								
                    </v-switch>																								
                </v-card-text>																								
              </v-window-item>																								
              <v-window-item :value="4">																								
                <v-card-text>																								
                 <v-select  :rules="[rules.required]" :items="selectedTable.PrimaryOptions"																								
                  label="Select Primary Option" item-text="Name" v-model="selectedPrimaryOption" return-object>																								
                  </v-select>																								
                </v-card-text>																								
              </v-window-item>																								
              																								
            </v-window>																								
            																								
                <v-card-actions>																								
                  <v-btn																								
                :disabled="UploadDocstoEntityStep === 1 "																								
                text																								
                @click="UploadDocstoEntityStep--"																								
              >																								
                Back																								
              </v-btn>																								
          <v-spacer></v-spacer>																								
                <v-btn        																								
                  depressed																								
                  color="green"																								
                  @click="ProcessItemsRegistrationwithEntity()"																								
                >																								
                  Go																								
                </v-btn>          																								
              </v-card-actions>																								
            </v-img>																								
          </v-card>																								
        </v-dialog>																								
     <v-dialog v-model="RegisterDocsRecordDialog" max-width="500px">																								
                    																								
                  <v-card width ="600px" color="#ffffff" elevation="16">																								
                      <v-img  src="@/assets/RABaseBG.jpeg">																								
                        <v-card-title>																								
                          <span class="headline">Register Documents to Record</span>																								
                        </v-card-title>																								
                                  <v-card-text> 																								
                                <v-autocomplete  :rules="[rules.required]" :items="RelevantEntitiyRecords"																								
                                  label="Select Record" v-model="selectedRecord"																								
                                  item-text="Job_Number">																								
                                </v-autocomplete>																								
                              </v-card-text>																								
                            <v-card-actions>																								
                              <v-spacer></v-spacer>																								
                                <v-btn dark color="blue darken-1" @click="CancelProcessItemsRegistration()">Cancel</v-btn>																								
                                <v-btn dark color="blue darken-1" @click="ProcessItemsRegistration()" v-if="selectedRecord">Save</v-btn>																								
                            </v-card-actions>																								
                      </v-img>																								
                      </v-card>																								
                  </v-dialog>																								
                  <v-dialog v-model="RegisterDocDialog" max-width="500px">																								
                    																								
                  <v-card width ="600px">																								
                      <v-img  src="@/assets/RABaseBG.jpeg">																								
                        <v-card-title>																								
                          <span class="headline">Register Document - {{selectedDocument.Name}}</span>																								
                        </v-card-title>																								
                        																								
                            																								
                                   <v-card-text>																								
                                <v-layout class="justify-center">																								
                                   <!-- <v-flex lg6 md4> -->																								
                                  <iframe :src="selectedDocument.url" title="W3Schools Free Online Web Tutorials"  width="100%" height="100%"></iframe>																								
                                   <!-- </v-flex> -->																								
                                  </v-layout>																								
                                   </v-card-text>																								
                                   <v-card-text>																								
                                <v-layout class="justify-center">																								
                                  <v-btn icon :href="selectedDocument.url" target="_New" v-if="selectedDocument.url">View																								
                                      </v-btn>																								
                                  </v-layout>																								
                                   </v-card-text>																								
                                  <v-card-text> 																								
                              																								
                                <v-select  :rules="[rules.required]" :items="selectedEntity.Tables"																								
                                  label="Select Table Type" v-model="selectedTable" return-object item-text="Name">																								
                                  </v-select>																								
                                  <v-select  :rules="[rules.required]" :items="selectedTable.PrimaryOptions"																								
                                  label="Select Primary Type" v-model="selectedPrimaryOption" return-object																								
                                  item-text="Name">																								
                                  </v-select>																								
                                  																								
                                  																								
                              </v-card-text>																								
                            <v-card-actions>																								
                              <v-spacer></v-spacer>																								
                                <v-btn dark color="blue darken-1" @click="closeInboundDocumentsdialog()">Cancel</v-btn>																								
                                <v-btn dark color="blue darken-1" @click="saveDocumentRegistration()" v-if="selectedRecord && selectedTable && selectedPrimaryOption">Save</v-btn>																								
                            </v-card-actions>																								
                      </v-img>																								
                      </v-card>																								
																								
                  </v-dialog>																								
      	<v-card height="80px" flat class="transparent"  v-if="!$route.params.id && selectedDocumentsArray && selectedDocumentsArray.length>0">																							
          </v-card>																								
       																								
          <input v-if="!$route.params.id" type="file" style="padding-top:120px" webkitdirectory multiple @change="UploadFilesTest($event)" id="folderuploadinput" accept=".pdf">																								
           																								
         <v-data-table																								
            :headers="documenttouploadheaders"																								
            :items="selectedDocumentsArray"																								
            class="elevation-1"																								
            v-if="selectedDocumentsArray && selectedDocumentsArray.length>0"																								
            >																								
            <template v-slot:top>																								
                            <v-toolbar flat color="blue darken-4 white--text">																								
                                <v-toolbar-title>Upload</v-toolbar-title>																								
                                <v-divider																								
                                class="mx-4"																								
                                inset																								
                                vertical																								
                                ></v-divider>																								
                             																								
                               <v-spacer></v-spacer>																								
                                                          																								
                                <v-menu>																								
                                <template v-slot:activator="{ on }">																								
                                    <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>																								
                                </template>																								
                                <v-list color="primary" dark>																								
                                    																								
                                    <v-list-item>																								
                                        <v-list-item-content @click="UploadItemsProcessDialog()">																								
                                        Upload Items																								
                                        </v-list-item-content>																								
                                    <v-list-item-content>																								
                                        <v-icon>mdi-delete-forever</v-icon>																								
                                        </v-list-item-content>																								
                                    </v-list-item>																								
                                   																								
                                </v-list>																								
                                </v-menu>																								
                            </v-toolbar>																								
                            </template>																								
                            <template v-slot:[`item.actions`]="{ item }">																								
                         <v-btn icon :href="item.localpath" target="_blank">  																								
                        <v-icon																								
                            small																								
                            color="green"																								
                            >																								
                            mdi-eye																								
                        </v-icon>		</v-btn>																						
                        </template>																								
           </v-data-table>																								
       	<v-card height="80px" flat class="transparent"  v-if="!$route.params.id">																							
          </v-card>																								
       <v-tabs centered  v-model="active_tab">																								
         																								
         <v-tab @click="DocumentsSelected=[], Created_ByFilter='',PrimaryFilter='',SecondaryFilter='',TertiaryFilter=''"  v-for="status in RMDocsStatuses" :key="status.itemObjKey">{{status.Name}}																								
         </v-tab>																								
         <v-tab-item  v-for="status in RMDocsStatuses" :key="status.itemObjKey">																								
           <v-data-table																								
                        :headers="documentheaders"																								
                        :items="RMDocumentsArraybyStatusSearched"																								
                        class="elevation-1"																								
                        group-by="RecordName"																								
                        show-group-by																								
                        show-select																								
                        v-model="DocumentsSelected"																								
                        >																								
                        <template v-slot:top>																								
                            <v-toolbar flat color="blue darken-4 white--text">																								
                                <v-toolbar-title>test</v-toolbar-title>																								
                                <v-divider																								
                                class="mx-4"																								
                                inset																								
                                vertical																								
                                ></v-divider>																								
                             																								
                                <v-text-field dark																								
                              v-model="search"																								
                              append-icon="mdi-file-find-outline"																								
                              label="Search"																								
                              single-line																								
                              hide-details																								
                            ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>																								
                            <div width="100px"><br>																								
                              <v-autocomplete class="mx-5"																								
                              dark																								
                              :items="Created_ByUsers"																								
                              v-model="Created_ByFilter"																								
                              item-text="Full_Name"																								
                              label="Full_Name"																								
                              autowidth																								
                              return-object																								
                              chips																								
                              flat																								
                              />																								
                              </div>																								
                            <div width="100px"><br>																								
                              <v-autocomplete class="mx-5"																								
                              dark																								
                              :items="status.PrimaryFilterOptions"																								
                              v-model="PrimaryFilter"																								
                              :item-text="[status.PrimaryFilterName]"																								
                              :label="[status.PrimaryFilterName]"																								
                              autowidth																								
                              return-object																								
                              chips																								
                              flat																								
                              />																								
                              </div>																								
                              <div width="100px" v-if="status.SecondaryFilterName"><br>																								
                              <v-autocomplete class="mx-5"																								
                              dark																								
                              :items="status.SecondaryFilterOptions"																								
                              v-model="SecondaryFilter"																								
                              :item-text="[status.SecondaryFilterName]"																								
                              :label="[status.SecondaryFilterName]"																								
                              autowidth																								
                              return-object																								
                              chips																								
                              flat																								
                              />																								
                              </div>																								
                              <div width="100px" v-if="status.TertiaryFilterName"><br>																								
                              <v-autocomplete class="mx-5"																								
                              dark																								
                              :items="status.TertiaryFilterOptions"																								
                              v-model="TertiaryFilter"																								
                              :item-text="[status.TertiaryFilterName]"																								
                              :label="[status.TertiaryFilterName]"																								
                              autowidth																								
                              return-object																								
                              chips																								
                              flat																								
                              />																								
                              </div>																								
                              																								
                            <v-spacer></v-spacer>                               																								
                                <v-menu>																								
                                <template v-slot:activator="{ on }">																								
                                    <v-icon  v-on="on" dark>mdi-dots-vertical</v-icon>																								
                                </template>																								
                                <v-list color="primary" dark>																								
                                    																								
                                    <v-list-item>																								
                                        <v-list-item-content @click="RemoveItems()">																								
                                        Remove Items																								
                                        </v-list-item-content>																								
                                    <v-list-item-content>																								
                                        <v-icon>mdi-delete-forever</v-icon>																								
                                        </v-list-item-content>																								
                                    </v-list-item>																								
                                    																								
                                    <v-list-item @click="PrepareItemsForRegistration()">																								
                                        <v-list-item-content>                    																								
                                            Append Structure																								
                                        </v-list-item-content>																								
                                        <v-list-item-content>                    																								
                                            <v-icon>mdi-check</v-icon>																								
                                        </v-list-item-content>																								
                                    </v-list-item>																								
                                </v-list>																								
                                </v-menu>																								
                            </v-toolbar>																								
                        <!-- 																								
                        <v-toolbar v-if="ProjectScope" flat dense dark color="secondary">																								
                                <v-layout col class="justify-start">																								
                                <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>																								
                                <v-icon @click="ResetFilters()">mdi-close</v-icon>																								
                                </v-layout>																								
                                </v-toolbar> -->																								
                            </template>																								
                        <template v-slot:[`item.actions`]="{ item }">																								
                            <v-icon																								
                            small																								
                            class="mr-2"																								
                            @click="editItem(item)"																								
                        >																								
                            mdi-pencil																								
                        </v-icon>																								
                        <v-icon																								
                            small																								
                            @click="deleteItem(item)"																								
                        >																								
                            mdi-delete																								
                        </v-icon>																								
                        <v-icon																								
                            small																								
                            @click="viewItem(item)"																								
                        >																								
                            mdi-eye																								
                        </v-icon>																								
                        </template>																								
                        </v-data-table>																								
         </v-tab-item>																								
   																								
          																								
          </v-tabs>																								
           																								
        																								
               																								
           																								
     </v-flex>																								
          </v-layout>																								
 </v-card>																								
</template>																								
																								
<script>																								
import firebase from 'firebase';																								
import db from '@/main'																								
import format from 'date-fns/format'																								
																								
export default {																								
    props: ['RecordselectedEntity','RecordselectedRecord','TableType','PushedUser'],																								
    data() {																								
    return {																								
      search: '',																								
        UploadingRMDocs: false,																								
        RMDocsrunningProgress: 0,																								
        RMDocsrunningpercentage: 0,																								
        RMDocstotallist: 0,																								
        RMDocsuploadTotal: 0,																								
        RMDocsuploadPercentage: 0,																								
        RMDocslistuploadcount: 0,																								
        RMDocsdatacapturecount: 0,																								
        RMDocsRegressCount: 0,																								
        UploadType: '',																								
        selectedEntity: {},																								
        WillUploadPrimaryOption: false,																								
        WillUploadTable: false,																								
        WillUploadRecord: false,																								
        RegisterDocDialog: false,																								
        RegisterDocsRecordDialog: false,																								
        DocumentsArrayList: [],																								
        Entities: [ 				
        ],																								
        DocumentsSelected: [],																								
        selectedEntityIndex: 0,																								
        documenttouploadheaders: [																								
          { text: 'id', value: 'id', sortable: true},																								
          { text: 'Name', value: 'name', sortable: true},																								
            { text: 'Type', value: 'type', sortable: true},																								
            { text: 'Modified On', value: 'lastModifiedDate', sortable: true },																								
            { text: 'Path', value: 'webkitRelativePath', sortable: true },																								
            { text: 'Actions', value: 'actions', sortable: false },																								
        ],																								
        documentheaders: [																								
            { text: 'Name', value: 'Name'},																								
            { text: 'Entity', value: 'Entity.Name'},																								
            { text: 'Old Path', value: 'OldPath', sortable: true },																								
            { text: 'Record', value: 'RecordName', sortable: true },																								
            { text: 'Status', value: 'Status.Name', sortable: true },            																								
            { text: 'Actions', value: 'actions', sortable: false },																								
        ],																								
        editedItem: {																								
      Name: '',																								
      },																								
      defaulteditedItem: {																								
      Name: '',																								
      																								
      },																								
      EntityRecords: [],																								
      selectedRecord: '',																								
      selectedTable: {},																								
      selectedPrimaryOption: '',																								
      UploadItemsDialog: false,																								
      EditingDocuments: false,																								
      UploadItemstoEntityDialog: false,																								
      UploadItemstoEntityProcessing: false,																								
      UploadItemstoCustomClass: false,																								
      UploadItemstoStraight: false,																								
      UserRecord: {Full_Name: ''},																								
      UploadDocstoEntityStep: 1,																								
      RMDocumentsArray: [],																								
      selectedDocument: '',																								
      selectedEntityTable: '',																								
      selectedDocumentPrimaryType: '',																								
      selectedDocumentList: {},																								
      selectedDocumentsArray: [],																								
      RecordData: {},																								
      																								
        rules: {																								
        youtubeurl: value => {																								
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;																								
            return pattern.test(value) || "Not a Youtube Video Link.";																								
          },																								
          min8Chars: value => value.length >= 8 || "Min. 8 characters",																								
          required: value => !!value || "Required.",																								
          email: value => {																								
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;																								
            return pattern.test(value) || "Invalid e-mail.";																								
          },																								
          url: value => {																								
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol																								
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name																								
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address																								
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path																								
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string																								
          '(\\#[-a-z\\d_]*)?$','i'); 																								
            return urlpattern.test(value) || "Invalid Link.";																								
          }																								
        },																								
        active_tab: 0	,																							
        RMDocsStatuses: [																								
          {																								
          Name: 'Assign Entity',																								
          PrimaryFilterOptions: [],																								
          PrimaryFilterName: 'Name'},																								
          {Name: 'Assign Record',																								
          PrimaryFilterOptions: [],																								
          PrimaryFilterName: 'Name'},																								
          {Name: 'Assign Table',																								
          PrimaryFilterOptions: [],																								
          PrimaryFilterName: 'RecordName',																								
          SecondaryFilterOptions: [],																								
          SecondaryFilterName: 'Name'},																								
          {Name: 'Assign Class',																								
          PrimaryFilterOptions: [],																								
          PrimaryFilterName: 'Name',																								
          SecondaryFilterOptions: [],																								
          SecondaryFilterName: 'RecordName',																								
          TertiaryFilterOptions: [],																								
          TertiaryFilterName: 'Name'}																								
        ],																								
        Created_ByFilter: '',																								
        Created_ByUsers: [],																								
        PrimaryFilter: '',																								
        SecondaryFilter: '',																								
        TertiaryFilter: '',																								
        RecordPushtoArray: [],																								
        RecordPushToTableRef: '',																								
        RecordPushtoSubcollectionRef: '',																								
        RecordRemoveFromArray: [],																								
        RecordRemoveFromTableRef: '',																								
        RecordRemoveFromSubcollectionRef: '',																								
																								
        																								
    }																								
																								
    },																								
																								
  created(){																								
    																								
    if(this.userLoggedIn){																								
      this.CheckRouting()																								
    }																								
    																								
      																								
  },																								
																								
    computed:{																								
      RMDocumentsStore(){																								
      return this.$store.getters.getRMDocumentsArray																								
    },																								
      																								
      																									
      userIsAdmin () {																								
      return this.$store.state.IsAdmin																								
    },																								
    userLoggedIn () {																								
      return this.$store.getters.user																								
    },																								
    userBUObject () {																								
      return this.$store.state.buobj																								
    },																								
    userBUID () {																								
      return this.$store.getters.userBUID																								
    },																								
    userBUChildren () {																								
      return this.$store.getters.userBUChildren																								
    },																								
    userBUParents () {																								
      return this.$store.getters.userBUParents																								
    },																								
      SelectedStatus(){																								
          if(this.WillUploadRecord === true && this.WillUploadTable === true && this.WillUploadPrimaryOption === true && this.selectedPrimaryOption){																								
                return {																								
                    ID: 1000005,																								
                    Name: 'Final'																								
                  }																								
                }																								
               else if(this.WillUploadRecord === true && this.WillUploadTable === true && this.selectedTable && this.WillUploadPrimaryOption === false){																								
                return {																								
                    ID: 1000004,																								
                    Name: 'Assign Classes'																								
                  } 																								
                }																								
                else if(this.WillUploadRecord && this.selectedRecord){																								
                return {																								
                    ID: 1000003,																								
                    Name: 'Assign Table'																								
                  }  																								
              }																								
              else if(this.selectedEntity && this.selectedEntity.Name){																								
                return {																								
                      ID: 1000002,																								
                      Name: 'Assign Record'																								
                    }									 															
              }																								
              else {																								
                return {																								
                      ID: 1000001,																								
                      Name: 'Assign Entity'																								
                    } 																								
              }	        																							
      }	,																							
      SelectedPath(){																								
        // if(this.SelectedStatus && this.SelectedStatus.ID){																								
        //   if(this.SelectedStatus.ID === 1000001){																								
        //     NewDoc.StorageRef = 'Unsorted/'+ file.name																								
        //   }																								
        //   else if(this.SelectedStatus.ID === 1000002){																								
        //     NewDoc.StorageRef = 'Unsorted/'+ file.name																								
        //   }																								
        //   else if(this.SelectedStatus.ID === 1000003){																								
        //     NewDoc.StorageRef = 'Unsorted/'+ file.name																								
        //   }																								
        // }																								
          																								
          																								
      },																								
      RMTopPadding(){																								
        if(this.$route.params.id){																								
          return ''																								
        }																								
        else{																								
          return 'padding-top: 170px'																								
        }																								
      },																								
      RMDocumentsArraybyStatus(){																								
        return this.RMDocumentsArray.filter(doc => {																								
          // console.log(this.active_tab)																								
          // console.log(this.TableType)																								
          if(this.active_tab === 0){																								
            return doc.Status.Name === 'Assign Entity'																								
          }																								
          else if(this.active_tab === 1 && this.TableType === 'Document Register' || this.active_tab === 1 && typeof this.TableType === 'undefined'){																								
            return doc.Status.Name === 'Assign Record'																								
          }																								
          else if(this.active_tab === 1 && this.TableType === 'Images'){																								
            return doc.Status.Name === 'Assign Record'	&& doc.FileType.includes('image')																							
          }																								
          else if(this.active_tab === 2 && this.TableType === 'Document Register'){																								
            return doc.Status.Name === 'Assign Table' && doc.Record.id === this.$route.params.id																								
          }																								
          else if(this.active_tab === 2 && typeof this.TableType === 'undefined'){																								
            // console.log(doc.Status.Name)																								
            return doc.Status.Name === 'Assign Table'																								
          }																								
          else if(this.active_tab === 2 && this.TableType === 'Images'){																								
            return doc.Status.Name === 'Assign Table' && doc.FileType.includes('image') && doc.Record.id === this.$route.params.id																								
          }																								
          else if(this.active_tab === 3 &&  typeof this.TableType !== 'undefined'){																								
            return doc.Status.Name === 'Assign Classes'	&& doc.Table.Type === this.TableType && doc.Record.id === this.$route.params.id																							
          }																								
          else if(this.active_tab === 3 &&  typeof this.TableType === 'undefined'){																								
            return doc.Status.Name === 'Assign Classes'																								
          }																								
        })																								
      },																								
      RMDocumentsArraybyStatusSearched(){																								
        return this.RMDocumentsArraybyStatus.filter(doc => {																								
          return doc.Name.toLowerCase().includes(this.search)																								
        }).filter(doc => {																								
            if(this.Created_ByFilter){																								
              //console.log("there is created filter")																								
              return doc.Created_By.id === this.Created_ByFilter.id																								
            }																								
            else{																								
              //console.log('no created by filter')																								
              return doc																								
            }																								
        })																								
        .filter(doc => {																								
          if(this.active_tab === 0){																								
            return doc																								
          }																								
          else if(this.active_tab === 1){																								
            if(this.PrimaryFilter){																								
              return doc.Entity.Name === this.PrimaryFilter.Name																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
          else if(this.active_tab === 2){																								
            if(this.PrimaryFilter){																								
              return doc.Record.id === this.PrimaryFilter.id																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
          else if(this.active_tab === 3){																								
            if(this.PrimaryFilter){																								
              console.log(this.PrimaryFilter)																								
              return doc.Table.Name === this.PrimaryFilter.Name																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
        })																								
        .filter(doc => {																								
          if(this.active_tab === 0 || this.active_tab === 1){																								
            return doc																								
          }																								
          else if(this.active_tab === 2){																								
            if(this.SecondaryFilter){																								
              return doc.Entity.Name === this.SecondaryFilter.Name																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
          else if(this.active_tab === 3){																								
            if(this.SecondaryFilter){																								
              return doc.Record.id === this.SecondaryFilter.id																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
        })																								
        .filter(doc => {																								
          if(this.active_tab === 0 || this.active_tab === 1 || this.active_tab === 2){																								
            return doc																								
          }																								
          else if(this.active_tab === 3){																								
            if(this.TertiaryFilter){																								
              return doc.Entity.Name === this.TertiaryFilter.Name																								
            }																								
            else{																								
              return doc																								
            }																								
          }																								
        })																								
      }	,																							
        RelevantEntitiyRecords(){																								
          if(!this.$route.params.id){																								
            return this.EntityRecords.filter(record => {																								
                return record.Collection === this. selectedEntity.Name																								
            })																								
          }																								
          else{																								
            return this.EntityRecords																								
          }																								
            																								
        },																								
        DocumentsbyEntities(){																								
            return this.Entities.map(entity => {																								
                entity.StoredDocuments = this.RMDocumentsArray.filter(doc => {																								
                    return doc.Entity.Name === entity.Name 																								
                })																								
                return entity																								
            })																								
        }																								
    },																								
  watch: {																								
    userLoggedIn (value) {																								
      if (value !== null && value !== undefined) {																								
        this.CheckRouting()																								
      }																								
    }																								
  },																								
  methods: {																								
    																								
    async GetRequestingUser(){																								
        var vm = this;																								
      await firebase.auth().onAuthStateChanged(function(user) {																								
        if (user) {																								
                db.collection('users').doc(user.uid).onSnapshot(snapshot => {																								
                  var userdetails = snapshot.data()																								
                  vm.UserRecord = userdetails																								
                  vm.UserRecord.id = user.uid																								
                  console.log(vm.UserRecord)																								
                })																								
            }																								
          })																								
        },																								
    CheckRouting(){																								
      // this.GetRequestingUser()																								
      if(this.$route.params.id){																								
        this.UserRecord = this.PushedUser																								
        console.log(this.RecordselectedRecord)																								
        console.log(this.RecordselectedEntity)																								
        this.selectedEntity = this.RecordselectedEntity																								
        this.selectedRecord = this.RecordselectedRecord																								
        this.EntityRecords.push(this.selectedRecord)																								
        console.log(this.EntityRecords)																								
        this.RMDocumentsCallEntityQuery(this.RecordselectedEntity.Name)																								
																								
        // if(this.RecordselectedEntity){																								
        //   this.RMDocumentsCallEntityQuery(this.RecordselectedEntity.Name)																								
        // }																								
      }																								
      else{																								
        this.RMDocumentsCall()																								
        this.EntityQueries()																								
      																								
      }																								
    },																								
    EntityQueries(){
      let vm = this
      
    },																						
    																								
    RemoveItems(){																								
      confirm('Are you sure you want to delete all the selected Documents?') && this.DocumentsSelected.map(item => {																								
        this.DissectListItemforDelete(item)																								
      })																								
    },																								
    DissectListItemforDelete(item){																								
      console.log(this.active_tab,item)																								
      if(this.active_tab === 0){																								
        console.log('stage 1',item)																								
        db.collection('RMDocuments').doc(item.id).delete()																								
        this.ProcessItemDelete(item)																								
      }																								
      else if(this.active_tab === 1){																								
        console.log('stage 2',item)																								
        db.collection('RMDocuments').doc(item.id).delete()																								
        this.ProcessItemDelete(item)																								
      }																								
      else if(this.active_tab === 2){																								
        console.log('stage 3, NOW programmed to delete',item)																								
        																								
        db.collection('RMDocuments').doc(item.id).delete()																								
          let docobj = this.RMDocumentsArray.find(obj => obj.Name == item.Name)																								
          let docindex = this.RMDocumentsArray.indexOf(docobj)																								
          this.RMDocumentsArray.splice(docindex,1)																								
          this.ProcessItemDelete(item)																								
      																								
      }																								
      else if(this.active_tab === 3){																								
        console.log('stage 4, not yet programmed to delete',item)																								
        db.collection('RMDocuments').doc(item.id).delete()																								
          let docobj = this.RMDocumentsArray.find(obj => obj.Name == item.Name)																								
          let docindex = this.RMDocumentsArray.indexOf(docobj)																								
          this.RMDocumentsArray.splice(docindex,1)																								
          this.ProcessItemDelete(item)																								
      }																								
    },																								
      PrepareItemsForRegistration(){																								
        this.EditingDocuments = true																								
        																								
            console.log(this.DocumentsSelected)																								
            if(this.active_tab === 0){																								
              console.log('stage 1')																								
              this.UploadItemsDialog = true																								
            }																								
            else if(this.active_tab === 1){																								
              console.log('stage 2')            																								
              this.UploadType = 'Register Entity'																								
              if(!this.$route.params.id){																								
              this.selectedEntity = this.Entities.find(obj => obj.Name === this.DocumentsSelected[0].Entity.Name)																								
              }																								
              this.UploadDocstoEntityStep = 1																								
              this.UploadItemstoEntityDialog = true																								
              																								
              																								
            }																								
            else if(this.active_tab === 2){																								
              console.log('stage 3')                            																								
              this.UploadType = 'Register Entity'																								
              this.selectedEntity = this.Entities.find(obj => obj.Name === this.DocumentsSelected[0].Entity.Name)																								
              this.selectedRecord = this.DocumentsSelected[0].Record																								
              this.WillUploadRecord = true																								
              this.UploadDocstoEntityStep = 2																								
              this.UploadItemstoEntityDialog = true																								
            }																								
            else if(this.active_tab === 3){																								
              console.log('stage 4')             																								
              this.UploadType = 'Register Entity'																								
              this.selectedEntity = this.Entities.find(obj => obj.Name === this.DocumentsSelected[0].Entity.Name)																								
              this.selectedRecord = this.DocumentsSelected[0].Record																								
              this.selectedTable = this.DocumentsSelected[0].Table																								
              this.WillUploadRecord = true																								
              this.WillUploadTable = true              																								
              this.UploadDocstoEntityStep = 3																								
              this.UploadItemstoEntityDialog = true																								
            }																								
      },																								
      ProcessItemsRegistration(){																								
          this.selectedDocument = this.DocumentsSelected[this.selectedEntityIndex]          																								
          this.RegisterDocDialog = true																								
      },																								
     saveDocumentRegistration(){																								
         console.log(this.selectedDocument)																								
         this.selectedEntityIndex = this.selectedEntityIndex-1+2																								
         this.ProcessItemsRegistration()																								
     },																								
     ProcessItemTransferDelete(item,NewDoc){																								
       if(this.EditingDocuments === true){																								
         this.ProcessItemDelete(item)																								
       }																								
       else{																								
         this.ProcessItemDelete(item)																								
       }																								
       																								
       console.log(item)																								
       console.log(NewDoc)																								
     },																								
     deleteItem(item){																								
       confirm('This will delete the record entirely, are you sure you want to do this?') && this.ProcessItemDelete(item)																								
     },																								
     ProcessAdditionalDeletes(item){																								
       console.log('yep we process additional deltes',item)																								
      if(item.Status.ID === 1000004 && this.SelectedStatus && this.SelectedStatus.ID !== 1000005 && this.SelectedStatus.ID !== 1000004){																								
         console.log('is stage 3 or 4',item)																								
         this.CallRecordRMTable(item.Entity,item.Record,item.Status,item.Table).then(ReturnObj => {																								
            console.log(ReturnObj)																								
                let TempRecordRemoveFromTableRef = ReturnObj.TableRef																								
                let TempRecordRemoveFromSubcollectionRef = ReturnObj.SubCollectionRef																								
                let obj = ''																								
                TempRecordRemoveFromSubcollectionRef.doc(obj.id).delete()																								
                																								
              })																								
       }																								
     },																								
    																								
     ProcessItemDelete(item){																								
             console.log('process delete',item)																								
             let ToImageTable = false																								
             if(this.selectedTable && this.selectedTable.Type === 'Images' && this.SelectedStatus.ID === 1000004){																								
               ToImageTable = true																								
             }																								
             console.log('condition 1 met','this.EditingDocuments',this.EditingDocuments)																								
              console.log('ToImageTable',ToImageTable)																								
              if(this.EditingDocuments === false || this.SelectedStatus && this.SelectedStatus.ID === 1000005 || ToImageTable){																								
                const index = this.RMDocumentsArray.indexOf(item)      																								
                db.collection('RMDocuments').doc(item.id).delete() && this.RMDocumentsArray.splice(index, 1)																								
                console.log('going PRIOR delete additional')																								
              }																								
              																								
              this.ProcessAdditionalDeletes(item)																								
              if(!ToImageTable){																								
                let storageRef = firebase.storage().ref(item.StorageRef);																								
                storageRef.delete().then(function() {																								
                  // File deleted successfully																								
                                                        																								
                }).catch(function(error) {																								
                  // Uh-oh, an error occurred!																								
                });																								
              }																								
              																								
     },																								
      editItem(item){																								
          																								
          console.log(item)																								
          this.selectedDocument = item																								
          this.selectedEntity = this.Entities.find(obj => obj.Name === item.Entity.Name)																								
          this.RegisterDocDialog = true																								
      },																								
      viewItem(item){																								
          window.open(item.url)																								
      },																								
      																								
      DocumentsArray(entity){																								
          console.log(this.RMDocumentsArray)																								
          this.RMDocumentsArray.find(obj => obj.Entity.Name === entity.Name)																								
      },																								
      ProcessItemsRegistrationwithEntity(){																								
        																								
        console.log('this.UploadDocstoEntityStep',this.UploadDocstoEntityStep)																								
        if(this.UploadDocstoEntityStep === 1){																								
          if(this.WillUploadRecord === true && !this.selectedRecord || this.WillUploadRecord && this.$route.params.id){																								
          this.UploadDocstoEntityStep = 2																								
          }																								
          else if(this.WillUploadRecord === true && this.WillUploadTable === true && this.selectedRecord){																								
          this.UploadDocstoEntityStep = 3																								
          }																								
          else{																								
            this.UploadItems()																								
          }																								
        }																								
        else if(this.UploadDocstoEntityStep === 2){																								
         																								
          if(this.WillUploadRecord === true && this.WillUploadTable === true && this.selectedRecord && this.selectedRecord.id){																								
          this.UploadDocstoEntityStep = 3																								
          }																								
          else{																								
            this.UploadItems()																								
          }																								
        }      																								
        else if(this.UploadDocstoEntityStep === 3){																								
          																								
          if(this.WillUploadRecord === true && this.WillUploadTable === true && this.WillUploadPrimaryOption === true && this.selectedRecord && this.selectedTable){																								
          this.UploadDocstoEntityStep = 4																								
          }																								
          else{																								
            this.UploadItems()																								
          }																								
        }     																								
        else{																								
          this.UploadItems()																								
        }																								
      },																								
      ProcessRegistrationType(){																								
        console.log(this.UploadType)																								
        if(this.UploadType === 'Register Entity'){																								
          this.UploadItemsDialog = false																								
          this.UploadItemstoEntityDialog = true																								
        }																								
        																								
        else if(this.UploadType === 'Register Later'){																								
          this.UploadItems()																								
        }																								
      },																								
      UploadItemsProcessDialog(){																								
        this.UploadItemsDialog = true																								
        																								
      },																								
      CancelUploadItems(){																								
        this.selectedDocumentsArray = []																								
        this.selectedDocumentList = {}																								
        this.UploadItemsDialog = false																								
      },																								
      CallRecordRMTable(entity,record,status,table){																								
        return new Promise(function(resolve, reject) {																								
        console.log('calling the RecordRMTable')																								
        console.log(entity)																								
        console.log(record)																								
        console.log(status)																								
        console.log(table)																								
          let vm = this																								
          let collectionname = entity.Name.split(' ').join('').toLowerCase()																								
          console.log(collectionname)																								
          let recordref = db.collection(collectionname).doc(record.id)																								
          console.log(recordref)																								
          let subcollectionref = ''																								
          let tableref = ''																								
          let tablename = ''																								
          let returnarray = []																								
          if(status && status.ID === 1000005){																								
																								
            tableref = table.Name.split(' ').join('').toLowerCase()																								
            console.log(tableref)																								
            																								
            																								
          subcollectionref = recordref.collection(tableref)																								
          console.log(subcollectionref) 																								
          let ReturnObj = {																								
                      																								
                      TableRef: tableref,																								
                      SubCollectionRef: subcollectionref,                      																								
                    }																								
                    resolve(ReturnObj)            																								
          }																								
          if(status && status.ID === 1000004){																								
            if(table && table.Type === 'Document Register'){																								
            tableref = table.Name.split(' ').join('').toLowerCase()																								
            console.log(tableref)																								
            subcollectionref = recordref.collection(tableref)																								
            let ReturnObj = {																								
                      																								
                      TableRef: tableref,																								
                      SubCollectionRef: subcollectionref,                      																								
                    }																								
                    resolve(ReturnObj)																								
            																								
            }																								
            else if (table && table.Type === 'Images'){																								
              tableref = table.Name.split(' ').join('').toLowerCase()+'imagesuploads'																								
              console.log(tableref)																								
              subcollectionref = recordref.collection(tableref)																								
              let ReturnObj = {																								
                     																								
                      TableRef: tableref,																								
                      SubCollectionRef: subcollectionref,                      																								
                    }																								
                    resolve(ReturnObj)																								
            }																								
            																								
                      																								
          }																								
        })																								
        },																								
      UploadItems(){																								
        console.log('calling the new upload items')																								
        if(this.SelectedStatus && this.SelectedStatus.ID === 1000004  || this.SelectedStatus && this.SelectedStatus.ID === 1000005){																								
              this.CallRecordRMTable(this.selectedEntity,this.selectedRecord,this.SelectedStatus,this.selectedTable).then(ReturnObj => {																								
                // this.RecordPushtoArray = ReturnObj.Array																								
                this.RecordPushToTableRef = ReturnObj.TableRef																								
                this.RecordPushtoSubcollectionRef = ReturnObj.SubCollectionRef																								
                // console.log(this.RecordPushtoArray)																								
                console.log(this.RecordPushToTableRef)																								
                console.log(this.RecordPushtoSubcollectionRef)																								
              })																								
            }																								
        if(this.EditingDocuments === false){																								
              this.RMDocsrunningpercentage = 0																								
              this.RMDocsrunningProgress = 0 																								
              this.UploadingRMDocs = true																								
              setTimeout(() => {																								
                this.RMDocstotallist = this.selectedDocumentList.length																								
                this.RMDocsuploadTotal = this.selectedDocumentList.length*100																								
                const topelmnt = document.getElementById('RMDocsprogressbar')																								
                topelmnt.style.display = "block";																								
                const elmnt = document.getElementById('RMDocsprogressgroupbar')      																								
                let oldtotalprogresselementptag = document.getElementById("RMDocstotalprogressbarptag")																								
                if(oldtotalprogresselementptag){																								
                  oldtotalprogresselementptag.remove();																								
                }   																								
                let oldtotalprogresselement = document.getElementById("RMDocstotalprogressbar")																								
                if(oldtotalprogresselement){																								
                  oldtotalprogresselement.remove();																								
                }																								
                																								
                if(elmnt.childNodes[0]){																								
                  elmnt.innerHTML = '';																								
                }																								
                const totalprogresselementptag = document.createElement('p')																								
                totalprogresselementptag.setAttribute('style', "font-size:16px;color:blue;")																								
                totalprogresselementptag.setAttribute('id','RMDocstotalprogressbarptag')																								
                const totalprogresselement = document.createElement('progress')																								
                totalprogresselement.setAttribute('id','RMDocstotalprogressbar')																								
                totalprogresselement.setAttribute('max','100')																								
                totalprogresselement.setAttribute('value',1)																								
                var startingtotaltext = document.createTextNode('0% complete  - 0 of '+this.RMDocstotallist+' files uploaded');																								
                totalprogresselementptag.appendChild(startingtotaltext);																								
                elmnt.append(totalprogresselement) 																								
                elmnt.append(totalprogresselementptag) 																								
                Array.from(Array(this.selectedDocumentList.length).keys()).map((x,index) => {																								
                this.uploadfile(this.selectedDocumentList[x],index-1+2,this.selectedDocumentList.length)																								
              })																								
              }, 300);																								
              																								
            }																								
            else {																								
              console.log(this.DocumentsSelected)																								
                this.DocumentsSelected.map((x,index) => {																								
                this.edituploadedfile(x,index-1+2,this.DocumentsSelected.length)																								
              })																								
              // }, 300);																								
              																								
            }																								
      },																								
      AddtoRecordTable(NewDoc){																								
        console.log('apparently we are adding')																								
        let vm = this																								
        return new Promise(function(resolve, reject) {																								
          vm.RecordPushtoSubcollectionRef.doc(NewDoc.id).set(NewDoc).then(doc => {																								
            vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
            vm.ValidateListCompletion()																								
            resolve('added new doc')																								
          })																								
          																								
        })																								
      },																								
      UpdateRecordTableDoc(Doc){																								
        let vm = this																								
        return new Promise(function(resolve, reject) {																								
        vm.RecordPushtoSubcollectionRef.doc(Doc.id).set(Doc).then(doc => {																								
          vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
          vm.ValidateListCompletion()																								
          resolve('updated the doc')																								
        })																								
        })																								
      },																								
      RemoveRecordTableDoc(Doc){																								
        let vm = this																								
        return new Promise(function(resolve, reject) {																								
        vm.RecordPushtoSubcollectionRef.doc(Doc.id).delete()																								
        // (Doc).then(doc => {																								
          vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
          vm.ValidateListCompletion()																								
          resolve('removed the doc')																								
        // })																								
        })																								
      },																								
      InitiateRecordTableUpdate(NewDoc,file){																								
          let vm = this																								
          console.log('unsorted or array specific record table for item',NewDoc)																								
          // if(this.EditingDocuments === true){																								
            if(this.SelectedStatus && this.SelectedStatus.ID === 1000004){																								
              let collectionname = this.selectedEntity.Name.split(' ').join('').toLowerCase()																								
                      console.log(collectionname)																								
                          if(NewDoc.Table && NewDoc.Table.Type === 'Document Register' || !NewDoc.Table){																								
                                this.AddtoRecordTable(NewDoc)																								
                                }																								
                          else if(NewDoc.Table && NewDoc.Table.Type === 'Images'){																								
                          let NewImage = {																								
                                path: NewDoc.url,																								
                                title: NewDoc.Name,																								
                                type: NewDoc.FileType,																								
                                Created_By: NewDoc.Created_By,																								
                                Created_Byid: NewDoc.Created_Byid,																								
                                Created_On: NewDoc.Created_On,																								
                                Modified_By: NewDoc.Modified_By,																								
                                Modified_Byid: NewDoc.Modified_Byid,																								
                                Modified_On: NewDoc.Modified_On																								
                              }																								
                            this.AddtoRecordTable(NewImage)																								
                        }																								
                      }																								
                     																								
                      if(this.EditingDocuments === true && file.Status.ID === 1000004){																								
                          this.RegressDatarecord(file)																								
                        }																								
        },																								
      																								
      edituploadedfile(file,listposition,listlength){																								
        this.UploadItemstoEntityProcessing = true																								
          let vm = this																								
            const NewDoc = Object.assign({},file)																								
            NewDoc.Status = this.SelectedStatus																								
            NewDoc.Modified_By = {Name: this.UserRecord.Name,Surname: this.UserRecord.Surname,Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id}																								
            NewDoc.Modified_Byid = this.UserRecord.id																								
            NewDoc.Modified_On = new Date()																								
            if(this.SelectedStatus && this.SelectedStatus.ID === 1000005){																								
                NewDoc.Table = this.selectedTable																								
                let PrimaryClassName = this.selectedTable.Name.split(' ').join('')+'PrimarySelectedOption'																								
                NewDoc[PrimaryClassName] = this.selectedPrimaryOption																								
                if(this.selectedTable && this.selectedTable.Type === 'Document Register'){																								
                  NewDoc.StorageRef =  this.selectedEntity.Name.split(' ').join('')+'/'+this.selectedRecord.id+'/'+this.selectedTable.Name.split(' ').join('')+'/'+this.selectedPrimaryOption.Name+'/'+ file.id+'_'+file.Name																								
                  delete NewDoc.Entity																								
                  delete NewDoc.Record																								
                  delete NewDoc.RecordName																								
                  delete NewDoc.Status																								
                  delete NewDoc.Table																								
                }																								
                else if (this.selectedTable && this.selectedTable.Type === 'Images'){																								
                  NewDoc.StorageRef =  this.selectedEntity.Name.split(' ').join('')+'/'+this.selectedRecord.id+'/'+this.selectedTable.Name.split(' ').join('')+'/'+ file.id+'_'+file.Name																								
                }																								
                this.MoveFiletoNewLocation(file.StorageRef,file.url,NewDoc.StorageRef).then(url => {																								
                  NewDoc.url = url																								
                  NewDoc.fileurl = 	url																							
                  console.log(this.selectedTable)																								
                  if(this.selectedTable && this.selectedTable.Type === 'Images'){																								
                    this.PrepareThumbnail(file,NewDoc.StorageRef,false).then(thumburl => {																								
                        NewDoc.ThumbURL = thumburl																								
                      console.log(thumburl)																								
                      this.AddtoRecordTable(NewDoc).then(result => {																								
                        console.log(result)																								
                        if(this.EditingDocuments === true){																								
                          this.ProcessItemDelete(file)																								
                        }																								
                      })                  																								
                  })																								
                  }																								
                  else{																								
                    this.AddtoRecordTable(NewDoc).then(result => {																								
                        console.log(result)																								
                        if(this.EditingDocuments === true){																								
                          this.ProcessItemDelete(file)																								
                        }																								
                      })																								
                  }																								
                      																								
                })																								
            }   																								
               else if(this.SelectedStatus && this.SelectedStatus.ID === 1000004){																								
                this.selectedPrimaryOption = ''																								
                let PrimaryField = this.selectedEntity.PrimaryField																								
                NewDoc.Record = {																								
                  [PrimaryField]: this.selectedRecord[PrimaryField],																								
                  id: this.selectedRecord.id																								
                }																								
                NewDoc.RecordName = this.selectedRecord[PrimaryField]																								
                NewDoc.Table = this.selectedTable																								
                NewDoc.StorageRef =  this.selectedEntity.Name.split(' ').join('')+'/'+this.selectedRecord.id+'/'+this.selectedTable.Name.split(' ').join('')+'/'+ file.id+'_'+file.Name																								
                																								
                  file.Entity = this.selectedEntity																								
                NewDoc.Entity = this.selectedEntity																								
                  this.MoveFiletoNewLocation(file.StorageRef,file.url,NewDoc.StorageRef).then(url => {																								
                    console.log(url)																								
                    NewDoc.url = url																								
                    if (this.selectedTable && this.selectedTable.Type === 'Document Register'){																								
                      this.UnsortedTableUpdateandRegressCheck(true,NewDoc,file)																								
                          this.InitiateRecordTableUpdate(NewDoc,file)																								
                    }																								
                    else if (this.selectedTable && this.selectedTable.Type === 'Images'){                  																								
                      NewDoc.fileurl = 	url																							
                      vm.PrepareThumbnail(file,NewDoc.StorageRef,false).then(thumburl => {																								
                        NewDoc.ThumbURL = thumburl																								
                            console.log(thumburl)																								
                            vm.AddtoRecordTable(NewDoc) 																								
                            if(this.EditingDocuments === true){																								
                                this.ProcessItemDelete(file)																								
                              } 	                																							
                        })                    																								
                    } 																								
                  })																								
                }																								
                else if(this.SelectedStatus && this.SelectedStatus.ID === 1000003){																								
                																								
                this.selectedTable =  {}																								
                this.selectedPrimaryOption = ''																								
                  console.log('yes this one')																								
                let PrimaryField = this.selectedEntity.PrimaryField																								
                console.log(PrimaryField)																								
                NewDoc.Record = {																								
                  [PrimaryField]: this.selectedRecord[PrimaryField],																								
                  id: this.selectedRecord.id																								
                }																								
                NewDoc.RecordName = this.selectedRecord[PrimaryField]																								
                NewDoc.Status = {																								
                    ID: 1000003,																								
                    Name: 'Assign Table'																								
                  }  																								
                if(NewDoc.Table){																								
                  delete NewDoc.Table																								
                }																								
                NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/'+this.selectedRecord.id+'/Unsorted/'+ file.id+'_'+file.Name																								
                																								
                NewDoc.Entity = Object.assign({},this.selectedEntity)																								
                file.Entity = Object.assign({},this.selectedEntity)																								
                console.log(NewDoc)																								
                this.MoveFiletoNewLocation(file.StorageRef,file.url,NewDoc.StorageRef).then(url => {																								
                console.log(url)																								
                NewDoc.url = url																								
                      this.UnsortedTableUpdateandRegressCheck(true,NewDoc,file).then(url => {																								
                        vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
                        vm.ValidateListCompletion()																								
                      // vm.InitiateRecordTableUpdate(NewDoc,file)																								
                      })																								
              })																								
              }																								
              else if(this.SelectedStatus && this.SelectedStatus.ID === 1000002){																								
                this.selectedRecord = ''																								
                this.selectedTable =  {}																								
                this.selectedPrimaryOption = ''																								
                console.log(this.selectedEntity)																								
                NewDoc.Entity = Object.assign({},this.selectedEntity)																								
                NewDoc.StorageRef = this.selectedEntity.Name.split(' ').join('')+'/Unsorted/'+ file.id+'_'+file.Name 																								
                if(NewDoc.Record){																								
                  delete NewDoc.Record																								
                }																								
                if(NewDoc.RecordName){																								
                  delete NewDoc.RecordName																								
                }																								
                if(NewDoc.Table){																								
                  delete NewDoc.Table																								
                }																								
                																								
                this.MoveFiletoNewLocation(file.StorageRef,file.url,NewDoc.StorageRef).then(url => {																								
                console.log(url)																								
                NewDoc.url = url																								
                        this.UnsortedTableUpdateandRegressCheck(true,NewDoc,file).then(resolve => {																								
                          console.log('here is should resolve')																								
                          console.log(resolve)																								
                          if(resolve){																								
                                  vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
                                  vm.ValidateListCompletion()																								
                                }																								
                      })																								
                }) 																								
              }																								
              else if(this.SelectedStatus && this.SelectedStatus.ID === 1000001){																								
                this.selectedRecord = ''																								
                this.selectedTable =  {}																								
                this.selectedPrimaryOption = ''																								
                this.selectedEntity = {}																								
                this.selectedEntityIndex = 0																								
                NewDoc.StorageRef = 'Unsorted/'+ file.id+'_'+file.Name																								
                if(NewDoc.Entity){																								
                  delete NewDoc.Entity																								
                }																								
                if(NewDoc.Record){																								
                  delete NewDoc.Record																								
                }																								
                if(NewDoc.RecordName){																								
                  delete NewDoc.RecordName																								
                }																								
                if(NewDoc.Table){																								
                  delete NewDoc.Table																								
                }																								
                this.MoveFiletoNewLocation(file.StorageRef,file.url,NewDoc.StorageRef).then(url => {                   																								
                      this.UnsortedTableUpdateandRegressCheck(false,NewDoc,file).then(resolve => {																								
                          if(resolve){																								
                                  vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
                                  vm.ValidateListCompletion()																								
                                }																								
                      })																								
                    })																								
              }																								
            																								
            console.log(file)																								
																								
        },																								
        																								
        SortedRecordTableUpdate(AddtoTable,NewDoc,file){																								
																								
            let collectionname = this.selectedEntity.Name.split(' ').join('').toLowerCase()																								
                      console.log(collectionname)																								
                      console.log('here is record',this.RecordData) 																								
                      console.log(NewDoc)																								
                        let tableref = this.selectedTable.Name.split(' ').join('').toLowerCase()																								
                        db.collection(collectionname).doc(this.selectedRecord.id).collection(tableref).doc(NewDoc.id).set(NewDoc)																								
        },																								
        PrepareThumbnail(file,NewDocStorageRef,rawfile){																								
          let vm = this																								
          let filename = ''																								
          console.log(file)																								
          if(file.name){																								
            filename = file.Name.split('.')[0]+'_'+file.size+'.'+file.Name.split('.')[1]																								
          }																								
          else if (file.Name){																								
            filename = file.Name																								
          }																								
          console.log('yes preparing thumbnail')																								
          return new Promise(function(resolve, reject) {																								
            if(rawfile === true){																								
                file.tmpsrc = URL.createObjectURL(file)																								
            }																								
            else{																								
                file.tmpsrc = file.url																								
            }																								
            console.log(file.tmpsrc)																								
                var img = document.createElement("IMG");																								
                  img.setAttribute('width',150)																								
                  img.setAttribute('src',file.tmpsrc)																								
                  img.setAttribute('crossOrigin','anonymous')																								
                  document.body.appendChild(img)																								
                  let vm = this																								
                  img.onload = function() {																								
                  var c = document.createElement("canvas");																								
                  c.setAttribute('crossOrigin','anonymous')																								
                  var ctx = c.getContext("2d");																								
                  var canvasRatio = img.naturalHeight / img.naturalWidth																								
                  c.width = 400																								
                  c.height = c.width*canvasRatio																								
                  ctx.drawImage(img, 0, 0, c.width, c.height);																								
                  c.toBlob(blob => {																								
                    let blobfile = new File([blob], filename, {																								
                type: file.type,																								
              });																								
              console.log(blobfile)																								
              var storageRef = firebase.storage().ref(NewDocStorageRef+'_thumb')																								
                  document.body.removeChild(img)																								
                  console.log(blobfile)																								
                  console.log(file)																								
                  var uploadTask = storageRef.put(blobfile)																								
                  uploadTask																								
                    .then(snapshot => snapshot.ref.getDownloadURL())																								
                      .then((thumburl) => {																								
                        console.log(thumburl)																								
                        resolve(thumburl)																								
                      })																								
                })																								
              }																								
          })																								
      },																								
        ValidateListCompletion(){																								
          console.log(this.EditingDocuments,this.RMDocsdatacapturecount,this.selectedDocumentList.length,this.RMDocsuploadPercentage)																								
          console.log(this.EditingDocuments,this.RMDocsdatacapturecount,this.DocumentsSelected.length,this.RMDocsuploadPercentage)																								
          if(this.EditingDocuments === false && this.RMDocsdatacapturecount === this.selectedDocumentList.length && this.RMDocsuploadPercentage === 100){																								
            this.CancelNewuploads()																								
            }																								
          else if (this.EditingDocuments === true && this.RMDocsdatacapturecount === this.DocumentsSelected.length && this.RMDocsuploadPercentage === 100){																								
            this.CancelNewuploads()																								
          }																								
        },																								
        UnsortedTableUpdateandRegressCheck(AddtoTable,NewDoc,file){																								
          let vm = this																								
          return new Promise(function(resolve, reject) {																								
          if(vm.EditingDocuments === true){																								
            if(AddtoTable === true){																								
              db.collection('RMDocuments').doc(NewDoc.id).set(NewDoc).then(doc => {																								
                            console.log('okay loaded it')																								
                            let docsobj = vm.RMDocumentsArray.find(obj => obj.id === file.id)																								
                            let docindex = vm.RMDocumentsArray.indexOf(docsobj) 																								
                            vm.RMDocumentsArray[docindex] = NewDoc																								
                            vm.active_tab = 0																								
                            vm.ProcessItemTransferDelete(file,NewDoc)																								
                            if(file.Status.ID === 1000004){																								
                            vm.RegressDatarecord(file)																								
                            resolve('Loaded RMDocs')																								
                          }																								
                          else{																								
                            resolve('Loaded RMDocs')																								
                          }					             																			
                        })																								
            }																								
            if(AddtoTable === false){																								
              vm.ProcessItemDelete(file)																								
              resolve('Loaded RMDocs')																								
            }																								
          }																								
          else{																								
            db.collection('RMDocuments').doc(NewDoc.id).set(NewDoc).then(doc => {																								
                console.log('okay loaded it')																								
                if(NewDoc.Status.ID !== 1000003 && NewDoc.Status.ID !== 1000004){																								
                  vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
                  vm.ValidateListCompletion()																								
                  resolve('Loaded RMDocs')																								
                }																								
                else{																								
                  resolve('Loaded RMDocs')																								
                }																								
            })																								
																								
          }																								
          																								
          })																								
        },																								
        RegressDatarecord(file){																								
          console.log('abbout to regress',file)																								
          this.CallRecordRMTable(file.Entity,file.Record,file.Status,file.Table).then(ReturnObj => {																								
            console.log(ReturnObj)																								
                let TempRecordRemoveFromArray = ReturnObj.Array																								
                let TempRecordRemoveFromTableRef = ReturnObj.TableRef																								
                let TempRecordRemoveFromSubcollectionRef = ReturnObj.SubCollectionRef																								
                let obj = ''																								
                if(file.Table && file.Table.Type === 'Images'){																								
                  obj = TempRecordRemoveFromArray.find(obj => obj.title === file.Name)																								
                }																								
                else{																								
                  obj = TempRecordRemoveFromArray.find(obj => obj.Name === file.Name)																								
                }																								
                TempRecordRemoveFromSubcollectionRef.doc(obj.id).delete()																								
                																								
              })																								
        },																								
        																								
        MoveFiletoNewLocation(currentPath, currentdownloadurl, destinationPath,OldRoot) {																								
          let vm = this																								
         return new Promise(function(resolve, reject) {																								
            let oldRef = firebase.storage().ref(currentPath)																								
            fetch(currentdownloadurl,{																								
                          method: 'GET', // *GET, POST, PUT, DELETE, etc.																								
                          mode: 'cors', // no-cors, *cors, same-origin																								
                      }).then(htmlReturn => {																								
                        let blob = htmlReturn.blob().then(b => {																								
                          console.log(destinationPath)																								
                          var storageRef = firebase.storage().ref(destinationPath)																								
                          var uploadTask = storageRef.put(b);																								
                                          																								
                          uploadTask.on('state_changed', function(snapshot){																								
                          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;																								
                          vm.RMDocsuploadPercentage = Number(progress.toFixed(2))																								
                          																								
                          if(vm.RMDocsuploadPercentage === 100){																								
                            vm.RMDocsrunningProgress = vm.RMDocsrunningProgress+vm.RMDocsuploadPercentage																								
                            vm.RMDocsrunningpercentage = Number(((vm.RMDocsrunningProgress/vm.RMDocsuploadTotal)*100).toFixed(2))                  																								
                            vm.RMDocslistuploadcount = vm.RMDocslistuploadcount-1+2																								
                          }																								
                          switch (snapshot.state) {																								
                            case firebase.storage.TaskState.PAUSED: // or 'paused'																								
                              console.log('Upload is paused');																								
                              break;																								
                            case firebase.storage.TaskState.RUNNING: // or 'running'																								
                              console.log('Upload is running');																								
                              break;																								
                          }																								
                        }, function(error) {																								
                          // Handle unsuccessful uploads																								
                        }, function() {																								
                          // Handle successful uploads on complete																								
                          // For instance, get the download URL: https://firebasestorage.googleapis.com/...																								
                          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {																								
                            resolve(downloadURL)																								
                          })																								
                        })																								
                    })																								
                })																								
         })																								
},																								
			FileStoreandProgressMeasurement(NewDoc,file){																					
        let vm = this																								
         return new Promise(function(resolve, reject) {																								
        // console.log(listlength,file,listposition)																								
        const totalprogresselement = document.getElementById('RMDocstotalprogressbar')																								
        const totalprogresselementptag = document.getElementById('RMDocstotalprogressbarptag')																								
        const elmnt = document.getElementById('RMDocsprogressgroupbar')																								
        const singleuploadptag = document.createElement('p')																								
        singleuploadptag.setAttribute('style', "font-size:12px;color:grey;")																								
        const singleuploaddiv = document.createElement('progress')																								
        singleuploaddiv.setAttribute('max','100')																								
        singleuploaddiv.setAttribute('value',1)																								
        var textnode = document.createTextNode("0% complete");																								
        singleuploaddiv.appendChild(textnode); 																								
        elmnt.append(singleuploaddiv) 																								
        elmnt.append(singleuploadptag)																								
        var storageRef = firebase.storage().ref(NewDoc.StorageRef);     																								
              																								
            var uploadTask = storageRef.put(file);																								
            uploadTask.on('state_changed', function(snapshot){																								
              let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;																								
                vm.RMDocsuploadPercentage = Number(progress.toFixed(2))																								
                singleuploaddiv.setAttribute('value',vm.RMDocsuploadPercentage)																								
                var textnode = document.createTextNode(vm.RMDocsuploadPercentage+"% complete for "+file.name);																								
                if(singleuploadptag.childNodes[0]){																								
                singleuploadptag.removeChild(singleuploadptag.childNodes[0])																								
                }         // Create a text node																								
                singleuploadptag.appendChild(textnode);																								
                if(vm.RMDocsuploadPercentage === 100){																								
                  vm.RMDocsrunningProgress = vm.RMDocsrunningProgress+vm.RMDocsuploadPercentage																								
                  vm.RMDocsrunningpercentage = Number(((vm.RMDocsrunningProgress/vm.RMDocsuploadTotal)*100).toFixed(2))                  																								
                  vm.RMDocslistuploadcount = vm.RMDocslistuploadcount-1+2																								
                  var totaltext = document.createTextNode(vm.RMDocsrunningpercentage+'% complete  - '+vm.RMDocslistuploadcount+' of '+vm.RMDocstotallist+' files uploaded');																								
                  totalprogresselement.setAttribute('value',vm.RMDocsrunningpercentage)																								
                  if(totalprogresselementptag.childNodes[0]){																								
                  totalprogresselementptag.removeChild(totalprogresselementptag.childNodes[0])																								
                  }         // Create a text node																								
                  totalprogresselementptag.appendChild(totaltext);																								
                }																								
                switch (snapshot.state) {																								
                  case firebase.storage.TaskState.PAUSED: // or 'paused'																								
                    console.log('Upload is paused');																								
                    break;																								
                  case firebase.storage.TaskState.RUNNING: // or 'running'																								
                    console.log('Upload is running');																								
                    break;																								
                }																								
              }, function(error) {																								
                // Handle unsuccessful uploads																								
              }, function() {																								
                // Handle successful uploads on complete																								
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...																								
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {																								
                  resolve(downloadURL)																								
                })																								
              })																								
         })																								
      },																								
																								
      uploadfile(file,listposition,listlength){																								
        																								
          let vm = this																								
            // vm.selectedDocument_URLFile = file            																								
          let filename = file.id+'_'+file.name																								
            const NewDoc = {																								
                id: file.id,																								
                Name: file.name,																								
                FileType: file.type,																								
                OldPath: file.webkitRelativePath,																								
                ModifiedDate: file.lastModifiedDate,																								
                url: '',																								
                Status: {																								
                ID: 1000001,																								
                Name: 'Assign Entity',                																								
                },																								
                Created_By: {Name: vm.UserRecord.Name,Surname: vm.UserRecord.Surname,Full_Name: vm.UserRecord.Full_Name,id: vm.UserRecord.id},																								
                Created_Byid: vm.UserRecord.id,          																								
                Created_On: new Date(),																								
                Status: vm.SelectedStatus																								
              																								
            }																								
            let Namesplit = '/'+NewDoc.Name																								
            NewDoc.OldRoot = NewDoc.OldPath.split(Namesplit).join('')																								
            if(vm.SelectedStatus && vm.SelectedStatus.ID === 1000001){																								
              NewDoc.StorageRef = 'Unsorted/'+ filename 																								
                vm.FileStoreandProgressMeasurement(NewDoc,file).then(function(url) {																								
                      console.log('File available at', url);																								
                      NewDoc.url = url																								
                      vm.UnsortedTableUpdateandRegressCheck(true,NewDoc)																								
                })																								
              }																								
            else if(vm.SelectedStatus && vm.SelectedStatus.ID === 1000002){																								
              																								
              NewDoc.Entity = vm.selectedEntity																								
              NewDoc.StorageRef = vm.selectedEntity.Name.split(' ').join('')+'/Unsorted/'+ filename																								
              vm.FileStoreandProgressMeasurement(NewDoc,file).then(function(url) {																								
                    console.log('File available at', url);																								
                      NewDoc.url = url																								
                    																								
                    vm.UnsortedTableUpdateandRegressCheck(true,NewDoc)																								
                  })																								
             	}																							
              else if(vm.SelectedStatus && vm.SelectedStatus.ID === 1000003){																								
                let PrimaryField = vm.selectedEntity.PrimaryField																								
                console.log(PrimaryField)																								
                NewDoc.Record = {																								
                  [PrimaryField]: vm.selectedRecord[PrimaryField],																								
                  id: vm.selectedRecord.id																								
                }																								
                NewDoc.RecordName = vm.selectedRecord[PrimaryField]																								
                NewDoc.Entity = Object.assign({},this.selectedEntity)																								
                console.log(NewDoc)																								
               	NewDoc.StorageRef = vm.selectedEntity.Name.split(' ').join('')+'/'+vm.selectedRecord.id+'/Unsorted/'+ filename																							
                vm.FileStoreandProgressMeasurement(NewDoc,file).then(function(url) {																								
                    console.log('File available at', url);																								
                      NewDoc.url = url																								
                      vm.UnsortedTableUpdateandRegressCheck(true,NewDoc,file).then(resolve => {																								
                      if(resolve){																								
                        vm.RMDocsdatacapturecount = vm.RMDocsdatacapturecount-1+2																								
                        vm.ValidateListCompletion()																								
                      //       vm.InitiateRecordTableUpdate(NewDoc,file)																								
                          } 																								
                      })																								
                      																								
                    																								
                  })																								
              }																								
                else if(vm.SelectedStatus && vm.SelectedStatus.ID === 1000004){																								
                NewDoc.Table = vm.selectedTable																								
                NewDoc.Entity = vm.selectedEntity																								
                let PrimaryField = vm.selectedEntity.PrimaryField																								
                console.log(PrimaryField)																								
                 NewDoc.Record = {																								
                  [PrimaryField]: vm.selectedRecord[PrimaryField],																								
                  id: vm.selectedRecord.id																								
                }																								
                NewDoc.RecordName = vm.selectedRecord[PrimaryField]																								
                  NewDoc.StorageRef =  vm.selectedEntity.Name.split(' ').join('')+'/'+vm.selectedRecord.id+'/'+vm.selectedTable.Name.split(' ').join('')+'/'+ filename																								
                  vm.FileStoreandProgressMeasurement(NewDoc,file).then(function(url) {																								
                      NewDoc.url = url																								
                      																								
                      if(vm.selectedTable.Type === 'Images'){																								
                        NewDoc.Status = {																								
                        ID: 1000005,																								
                        Name: 'Final'																								
                      }																								
                      vm.PrepareThumbnail(file,NewDoc.StorageRef,true).then(thumburl => {																								
                        NewDoc.ThumbURL = thumburl																								
                            console.log(thumburl)																								
                            vm.InitiateRecordTableUpdate(NewDoc,file)                    																								
                        })																								
                      }																								
                      else{																								
                        vm.UnsortedTableUpdateandRegressCheck(true,NewDoc,file).then(resolve => {																								
                          console.log(resolve)																								
                          if(resolve){																								
                            vm.InitiateRecordTableUpdate(NewDoc,file)																								
                          }                        																								
                        })																								
                      }																								
                  })																								
                }																								
                else if(vm.SelectedStatus && vm.SelectedStatus.ID === 1000005){																								
                NewDoc.Table = vm.selectedTable																								
                NewDoc.Entity = vm.selectedEntity																								
                let PrimaryClassName = vm.selectedTable.Name.split(' ').join('')+'PrimarySelectedOption'																								
                NewDoc[PrimaryClassName] = vm.selectedPrimaryOption																								
                NewDoc.StorageRef =  vm.selectedEntity.Name.split(' ').join('')+'/'+vm.selectedRecord.id+'/'+vm.selectedTable.Name.split(' ').join('')+'/'+vm.selectedPrimaryOption.Name+'/'+ filename																								
							  vm.FileStoreandProgressMeasurement(NewDoc,file).then(function(url) {																	
                      NewDoc.url = url																								
                        vm.UnsortedTableUpdateandRegressCheck(true,NewDoc,file).then(resolve => {																								
                          console.log(resolve)																								
                          if(resolve){																								
                            vm.SortedRecordTableUpdate(true,NewDoc,file)																								
                          }                        																								
                        })																								
                  })																								
                }																								
            console.log(NewDoc.Status)																								
        },																								
																								
      CancelNewuploads(){																								
        this.UploadItemstoEntityProcessing = false																								
        setTimeout(() => {																								
          let folderuploadtag = document.getElementById('folderuploadinput')																								
          if(folderuploadtag){																								
            folderuploadtag.value = ''																								
          }																								
          this.selectedDocumentsArray = []																								
          this.UploadItemstoEntityDialog = false																								
          this.selectedRecord = ''																								
          this.selectedTable =  {}																								
          this.selectedPrimaryOption = ''																								
          this.selectedEntity = {}																								
          this.selectedEntityIndex = 0																								
          this.DocumentsSelected = []																								
          this.WillUploadRecord = false																								
          this.WillUploadTable = false																								
          this.WillUploadPrimaryOption = false																								
           this.UploadingRMDocs = false																								
          this.RMDocsrunningProgress = 0																								
          this.RMDocsrunningpercentage = 0																								
          this.RMDocstotallist = 0																								
          this.RMDocsuploadTotal = 0																								
          this.RMDocsuploadPercentage = 0																								
          this.RMDocslistuploadcount = 0																								
          this.RMDocsdatacapturecount = 0																								
          this.UploadItemsDialog = false																								
          this.UploadType = false																								
        }, 300);																								
        																								
      },																								
      																								
      UploadFilesTest(event,entity){																								
        this.selectedDocumentList = event.target.files || event.dataTransfer.files																								
 																								
        let FileListArray = Array.from(this.selectedDocumentList)																								
        this.selectedDocumentsArray = []																								
        FileListArray.map((file,index) => {																								
					let ref = db.collection('RMDocuments').doc();																			
          let id = ref.id;																								
          let fileobj = {																								
            name: file.name,																								
            type: file.type,																								
            lastModifiedDate: file.lastModifiedDate,																								
            webkitRelativePath: file.webkitRelativePath	,																							
            localpath: URL.createObjectURL(file),																								
            id: id																								
          }																								
																								
						this.selectedDocumentList[index].id = id																		
          this.selectedDocumentsArray.push(fileobj)																								
        })																								
    																								
      },																								
      																								
																								
      																								
         																								
         GetCapitalEquipmentJobs(busunitchildren, busunitid) {																								
            let vm = this																								
            if(this.IsAdmin === false && this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){																								
                this.RoutetoErrorPage()																								
              }																								
            var collectionReference = db.collection('capitalequipmentjobs')																								
            console.log(busunitchildren)																								
            if(this.listAll || this.IsAdmin){																								
            this.CollectionRef = collectionReference;																								
            let payload = {																								
                  																								
                        query: this.CollectionRef,																								
                        arraymutation: 'setCapitalEquipmentJobsArray',																								
                        getter: 'getCapitalEquipmentJobsArray',																								
                        arrayname: 'CapitalEquipmentJobs'																								
                      }																								
                      console.log('DO NOT HAVE CapitalEquipmentJobsStore')																								
                        this.$store.dispatch('GetCollectionArray',payload).then(completedlog => {																								
                        let array = JSON.parse(JSON.stringify(this.CapitalEquipmentJobsStore))																								
                        array.map(obj => {																								
                            let returnobj = Object.assign({},obj)																								
                            returnobj.Collection = 'Capital Equipment Jobs'																								
                            returnobj.PrimaryField = 'Job_Number'																								
                            //console.log(returnobj)																								
                            this.EntityRecords.push(returnobj)																								
                        })																								
            })																								
            }																								
            else if(this.listUnitDown){																								
            this.CollectionRef = collectionReference.where('Business_Unitid', 'in', busunitchildren);																								
            let payload = {																								
                  																								
                        query: this.CollectionRef,																								
                        arraymutation: 'setCapitalEquipmentJobsArray',																								
                        getter: 'getCapitalEquipmentJobsArray',																								
                        arrayname: 'CapitalEquipmentJobs'																								
                      }																								
                      console.log('DO NOT HAVE CapitalEquipmentJobsStore')																								
                        this.$store.dispatch('GetCollectionArray',payload).then(completedlog => {																								
                        let array = JSON.parse(JSON.stringify(this.CapitalEquipmentJobsStore))																								
                        array.map(obj => {																								
                            let returnobj = Object.assign({},obj)																								
                            returnobj.Collection = 'Capital Equipment Jobs'																								
                            returnobj.PrimaryField = 'Job_Number'																								
                            //console.log(returnobj)																								
                            this.EntityRecords.push(returnobj)																								
                        })																								
                    })																								
            }																								
            else if(this.listUserUnit){																								
            this.CollectionRef = collectionReference.where('Business_Unitid', '==', busunitid);																								
            let payload = {																								
                  																								
                        query: this.CollectionRef,																								
                        arraymutation: 'setCapitalEquipmentJobsArray',																								
                        getter: 'getCapitalEquipmentJobsArray',																								
                        arrayname: 'CapitalEquipmentJobs'																								
                      }																								
                      console.log('DO NOT HAVE CapitalEquipmentJobsStore')																								
                        this.$store.dispatch('GetCollectionArray',payload).then(completedlog => {																								
                        let array = JSON.parse(JSON.stringify(this.CapitalEquipmentJobsStore))																								
                        array.map(obj => {																								
                            let returnobj = Object.assign({},obj)																								
                            returnobj.Collection = 'Capital Equipment Jobs'																								
                            returnobj.PrimaryField = 'Job_Number'																								
                            //console.log(returnobj)																								
                            this.EntityRecords.push(returnobj)																								
                        })																								
            })																								
            }																								
            else if(this.listOwner){																								
            this.CollectionRef = collectionReference.where('Ownerid', '==', this.UserRecord.id);																								
            let payload = {																								
                  																								
                        query: this.CollectionRef,																								
                        arraymutation: 'setCapitalEquipmentJobsArray',																								
                        getter: 'getCapitalEquipmentJobsArray',																								
                        arrayname: 'CapitalEquipmentJobs'																								
                      }																								
                      console.log('DO NOT HAVE CapitalEquipmentJobsStore')																								
                        this.$store.dispatch('GetCollectionArray',payload).then(completedlog => {																								
                        let array = JSON.parse(JSON.stringify(this.CapitalEquipmentJobsStore))																								
                        array.map(obj => {																								
                            let returnobj = Object.assign({},obj)																								
                            returnobj.Collection = 'Capital Equipment Jobs'																								
                            returnobj.PrimaryField = 'Job_Number'																								
                            //console.log(returnobj)																								
                            this.EntityRecords.push(returnobj)																								
                        })																								
            })																								
            }																								
																								
        },																								
        RMDocumentsCallEntityQuery(entityQuery){																								
																								
            db.collection('RMDocuments').where('Entity.Name','==',entityQuery).onSnapshot(res => {																								
                      																								
              const changes = res.docChanges();																								
              changes.forEach(change => {																								
                if (change.type === 'added') {																								
                  this.RMDocumentsArray.push({																								
                    ...change.doc.data(),																								
                    id: change.doc.id																								
                  })																								
                  // console.log(this.RMDocumentsArray)																								
                }																								
              })																								
            })																								
          },																								
        RMDocumentsCall(){																								
            let vm = this																								
            if(vm.RMDocumentsStore.length > 0){																								
              console.log('got from store')																								
              vm.RMDocumentsArray = vm.RMDocumentsStore																								
              vm.RMDocumentsCallSubQueries()																								
            }																								
            else{																								
              let query = db.collection('RMDocuments')																								
              let payload = { 																								
                  query: query,																								
                  arraymutation: 'setRMDocumentsArray',																								
                  getter: 'getRMDocumentsArray',																								
                  arrayname: 'RMDocumentsArray'																								
                }																								
                this.$store.dispatch('GetCollectionArray',payload).then(function(result) {																								
                      console.log(vm.RMDocumentsStore)																								
                      vm.RMDocumentsArray = vm.RMDocumentsStore																								
                      vm.RMDocumentsCallSubQueries()																								
                      })																								
            }																								
            																								
              },																								
              RMDocumentsCallSubQueries(){																								
                this.RMDocumentsArray.map(docobj => {																								
                  let createdbyobj = this.Created_ByUsers.find(obj => obj.id === docobj.Created_By.id)																								
                      if(!createdbyobj){																								
                        this.Created_ByUsers.push(docobj.Created_By)																								
                      }            																								
                    if(docobj.Table && docobj.Table.Name){																								
                      //console.log('has Table for sure')																								
                      let tableobj = this.RMDocsStatuses[3].PrimaryFilterOptions.find(obj => obj.Table === docobj.Table.Name)																								
                      if(!tableobj){																								
                        let TableObj = {Name: docobj.Table.Name}																								
                        this.RMDocsStatuses[3].PrimaryFilterOptions.push(TableObj)																								
                      }																								
                      let recordobj = this.RMDocsStatuses[3].SecondaryFilterOptions.find(obj => obj.id === docobj.Record.id)																								
                      if(!recordobj){																								
                        let RecordObj = {id: docobj.Record.id, RecordName: docobj.RecordName}																								
                        this.RMDocsStatuses[3].SecondaryFilterOptions.push(RecordObj)																								
                      }																								
                      let entityobj = this.RMDocsStatuses[3].TertiaryFilterOptions.find(obj => obj.Name === docobj.Entity.Name)																								
                      if(!entityobj){																								
                        this.RMDocsStatuses[3].TertiaryFilterOptions.push(docobj.Entity)																								
                      }																								
                    }																								
                    else if(docobj.Record && docobj.Record.id){																								
                      //console.log('has record for sure')																								
                      let recordobj = this.RMDocsStatuses[2].PrimaryFilterOptions.find(obj => obj.id === docobj.Record.id)																								
                      if(!recordobj){																								
                        let RecordObj = {id: docobj.Record.id, RecordName: docobj.RecordName}																								
                        this.RMDocsStatuses[2].PrimaryFilterOptions.push(RecordObj)																								
                      }																								
                      let entityobj = this.RMDocsStatuses[2].SecondaryFilterOptions.find(obj => obj.Name === docobj.Entity.Name)																								
                      if(!entityobj){																								
                        this.RMDocsStatuses[2].SecondaryFilterOptions.push(docobj.Entity)																								
                      }																								
                    }																								
                    else if(docobj.Entity && docobj.Entity.Name){																								
                      //console.log('has entity for sure')																								
                      let entityobj = this.RMDocsStatuses[1].PrimaryFilterOptions.find(obj => obj.Name === docobj.Entity.Name)																								
                      if(!entityobj){																								
                        this.RMDocsStatuses[1].PrimaryFilterOptions.push(docobj.Entity)																								
                      }																								
                    }																								
                })																								
              },																								
        																								
  }																								
}																								
</script>																								
																								
<style>																								
  #drop-area {																								
  border: 2px dashed #ccc;																								
  border-radius: 20px;																								
  margin: 50px auto;																								
  padding: 20px;																								
  background-color: rgb(235, 235, 235)																								
}																								
#drop-area.highlight {																								
  border-color: purple;																								
}																								
</style>
