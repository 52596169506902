
<template>
<v-card flat :AppisDarkMode="AppisDarkMode">
    <v-dialog v-model="TagLinkDialog" max-width="1200">
        <v-card width="100%">
            <v-card-title>
                Here is single tag dialog
            </v-card-title>
            <v-card-text>
         <v-autocomplete
                v-model="SingleDataTag"
                :items="TagsArray"
                item-text="tagname"
                hide-selected
                label="Search Data Tags"
                small-chips
                solo
                flat
                return-object
            ></v-autocomplete>
     </v-card-text>
     <v-card-actions>
         <v-btn @click="SaveSingleTag()">Save</v-btn>
     </v-card-actions>
        </v-card>
    </v-dialog>
    <v-card-text v-if="userLoggedIn">
          Tags
        <v-chip-group>
            <v-chip small
            v-for="tag in record.DataTags" :key="tag.itemObjKey"
            :to="tag.taglocation">
            <!-- <router-link> -->
                {{tag.tagname}}
            <!-- </router-link> -->
            </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-text v-if="UsercanTag">

    <v-card-subtitle>Tagging Component</v-card-subtitle>
     <v-card-text>
         <v-autocomplete
                v-model="record.DataTags"
                :items="TagsArray"
                item-text="tagname"
                hide-selected
                label="Search Data Tags"
                small-chips
                solo
                flat
                multiple
                return-object
            ></v-autocomplete>
     </v-card-text>
     <v-card-actions>
         <v-btn @click="SaveTags()">Save Tags</v-btn>
     </v-card-actions>
     </v-card-text>
      </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import NoticeSingle from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeSingle'


export default {
    props: ['clientDB','Description','Excerpt','Filter','HasFilter','AppisDarkModeCard','AppisDarkMode','UserView','UserID','record','TagLinkDialog','UsercanTag'],
    components: {
        NoticeSingle,
  },
    middleware: 'auth',
    data () {
        return {
            SingleDataTag: '',
            Notice: '',
            NoticeDialog: false,
          
        CollectionRef: db.collection('notices'),
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            notices: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {},

        }
    },
    computed: {
        SingleTagObject(){
     
          let obj = {tagname: this.SingleDataTag.tagname, id: this.SingleDataTag.id,taglocation: this.SingleDataTag.taglocation,tagtype: this.SingleDataTag.tagtype}
          return obj
    
        },
        TagObjects(){
        return this.record.DataTags.map(tag => {
          let obj = {tagname: tag.tagname, id: tag.id,taglocation: tag.taglocation,tagtype: tag.tagtype}
          return obj
        })
      },
      TagObjectIDs(){
        return this.record.DataTags.map(tag => {
         
          return tag.id
        })
      },
        UsersStore(){
            return this.$store.state.Users
        },
        TagsArray(){
            let MergedArray = []
              MergedArray = this.UsersStoreTagsArray
            return MergedArray
        },
        UsersStoreTagsArray(){
            return this.UsersStore.map(record => {
                record.tagname = record.Full_Name
                record.tagtype = 'Users'
                record.taglocation = '/SiteMember/'+record.id
                return record
            })
        },
        userLoggedIn () {
      return this.$store.getters.user
    },
    filterednotices() {
      
      return this.notices.filter(notice => {
          if(this.HasFilter){
          return this.Filter.includes(notice.type)
        }
          else{
            return notice
          }
      }).map(not => {
        if(typeof not.createdon.toDate !== 'undefined'){
        not.monthcreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'month')
        not.daycreated = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'date')        
        not.createddate = this.TimestampFormatterNoticeBoard(not.createdon.toDate(),'full')
        if(not.UserReadDate){
            not.UserReadDate = this.TimestampFormatterNoticeBoard(not.UserReadDate.toDate(),'full')
        }
        not.createdon = this.TimestampFormatterSTRING(not.createdon.toDate())
        }
        if(not.MustRead && not.MustConfirm && !not.UserRead || not.MustConfirm && !not.UserConfirmed){
          not.IconColor = 'red'
          not.Icon = 'mdi-clipboard-alert'
        }
        else if(not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'orange'
          not.Icon = 'mdi-clipboard-alert-outline'
        }
        else if(!not.MustRead &&!not.MustConfirm && !not.UserRead){
          not.IconColor = 'blue'
          not.Icon = 'mdi-clipboard-outline'
        }
        else{
          not.IconColor = 'green'
          not.Icon = 'mdi-clipboard-check-outline'
        }
        return not
      }).sort((a, b) => {
            var key1 = b.createdon;
            var key2 = a.createdon;

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
    
    },
    // FilterComputed(){
    //     return this.Filter.map(filter => {
    //         return filter.Name
    //     })
    // }
    },

    created() {
      // this.$store.dispatch('GetUserStateChange')
      if(this.userLoggedIn){
          this.UserRecord = this.userLoggedIn
      }
      console.log(this.Filter)
      if(this.UserView){
        this.CollectionRef = this.CollectionRef.where('creatorid','==',this.UserID)
        this.GetAllTaggingItems()
      }
      else{
        this.GetAllTaggingItems()
      } 
    
    // this.FocusedViewToggle()
    //   this.IntranetViewToggle()
    // //   this.GetRequestingUser()
},
    methods: {
        SaveSingleTag(){
            this.$emit('SaveSingleTag',this.SingleTagObject)
        },
        SaveTags(){
            let metadata = this.$route.meta
            console.log(metadata)
            console.log(this.record.DataTags)
            let tagsQuery = []
            if(this.record.DataTags){
              let tagsStringArray = this.record.DataTags.map(tag => {return tag.tagname.toLowerCase()})
              var lowertext = "";                
              var p;
              var y;
                let buildup = ''
               
                for (p = 0; p < tagsStringArray.length; p++) {
                      for (y = 0; y < tagsStringArray[p].length; y++) {
                        if(y === 0){
                          buildup = tagsStringArray[p][y]
                          lowertext += buildup.toLowerCase() + ",";
                        }
                        else{
                          buildup = buildup+ tagsStringArray[p][y]
                        lowertext += buildup.toLowerCase() + ",";
                        }
                        
                        }
                    }
                    tagsQuery = lowertext.split(',')  
                    tagsQuery.length = tagsQuery.length-1                         
            }
            let col = ''
            if(typeof this.record.EventisMapLocation !== 'undefined'){
              if(this.$route.path.includes('Group')){
              col = db.collection('groupevents').doc(this.record.id)
              }
              else{
                col = db.collection('siteevents').doc(this.record.id)
              }
            }
            else if(this.$route.path.includes('Group')){
              col = db.collection(metadata.collection).doc(this.$route.params.slug)
            }
            else{
              col = db.collection(metadata.collection).doc(this.$route.params.id)
            }
            col.update({
                DataTags: this.TagObjects,
                DataTagsid: this.TagObjectIDs,
                DataTagsQuery: tagsQuery,
            })
        },

        GetAllTaggingItems(){
        },
        SubmitResponse(){
            alert('wow you responded')
        },
        OpenNotice(notice){
            this.$router.push({ name: 'noticesingle', params: {Notice: notice }})
        },
        ViewNotice(notice){
            this.Notice = notice
            this.NoticeDialog = true
        },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
        }
      })
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
         GetNotices() {
           this.CollectionRef.onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
            let noticeobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                let responsedocdata = responddoc.data()
                if(responsedocdata && responsedocdata.Confirmed){
                    noticeobj.UserConfirmed = true
                }
                if(responsedocdata && responsedocdata.Read){
                    noticeobj.UserRead = true
                    noticeobj.UserReadDate = responsedocdata.ReadDate
                }
                if(responsedocdata && responsedocdata.ConfirmDate){
                    noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                }
                this.notices.push(noticeobj)
            })
            
          
        }  
      })
    })

    },
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    // var storageRef = firebase.storage()('Group_Logos/' + this.selectedFile.name);
                    // var uploadTask = storageRef.put(this.selectedFile);
                    //   uploadTask
                    //   .then(snapshot => snapshot.ref.getDownloadURL())
                    //     .then((url) => {     
                    this.name = this.editedItem.Name
                    this.Description = this.editedItem.Description
                    this.user = this.$store.state.user.id
                    this.GroupCategory = this.editedItem.GroupCategory
                    // this.FileURL = url

                        
                        const notice = { 
                              name: this.name,
                                Description: this.Description,
                                Created_By: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Created_Byid: this.UserRecord.id,
                                Created_On: new Date(),
                                GroupCategory: this.GroupCategory,
                                GroupPrivacy: 'Public',
                                GroupPrivacyIcon: 'mdi-lock',
                                GroupPublishStatus: 'Draft',
                                GroupPublishStatusIcon: 'mdi-eye-off',
                                EventsPrivacy: 'Public',
                                BlogPrivacy: 'Public',
                                MembersPrivacy: 'Public',
                                NewsPrivacy: 'Public',
                                ForumPrivacy: 'Public',
                                // logo: this.FileURL
                                
                            }
                            const groupmemberscollection =   db.collection('groupmembers')
                            let vm = this
                        db.collection('notices').add(notice).then(function(doc) {
                          console.log(doc.id)
                           const GroupMember = { 
                            Created_On: new Date(),
                            Userid: notice.Created_By.id,
                            Groupid: doc.id,
                            UserRole: 'Administrator'
                            
                            }
                            groupmemberscollection.add(GroupMember)
          vm.$router.push('/Group/'+GroupMember.Groupid)
                            })
                    this.Closedialog()
                    
                          // }),                         
                        
                   
                            this.dialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.noticeoutlined{
   border-style: solid;
    border-color: rgb(207, 205, 205);
   border-width: thin;
}
.outlinedavatar{
  border:1px double red;

  background-color: #2196F3;
}
</style>
  
    