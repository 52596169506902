
<template>
<v-card class="transparent" flat width="100%" height="100%" style="padding-bottom:50px;">

    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Meetup.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>Edit Meetup</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="MeetupTitle">
                </v-text-field>    
                <v-combobox return-object item-text="Name"
                  chips v-model="Category" :items="MeetupsCategories" label="Category" >
                </v-combobox>       
                <v-switch v-if="!userLoggedIn.IsGuestUser && System.Guests_can_Social || System.Guests_can_Social && GroupData" v-model="TempGuestsIncluded"  :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>   

                <v-list-item>
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu v-if="EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
          dense
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              <v-list-item-content>
                <v-checkbox dense v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
                  v-if="EventStartDate"
                    v-model="EventenddateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px" 
                    id="EventEndDatemenu"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="EventEndDate"
                        label="End Date"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        id="EventEndDate"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker dense v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
                  </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                      <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
                        v-model="EventEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        id="EventEndTimeMenu"
                        
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="EventEndTime"
                            label="End Time"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="EventEndTime"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="EventEndTime"
                          @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                          type="month"
                          width="290"
                          class="ml-4"
                          dense
                        :allowed-minutes="AllowedEndTimeMinutes"
                        :allowed-hours="AllowedEndTimeHours"
                        ></v-time-picker>
                      </v-menu>
                    </v-list-item-content>
                  </v-list-item>
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                <input
                v-if="!ImageShy"
            type="file"
            @change="onmeetupcoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout>
          <v-card-text>
              <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text>
            <v-card-actions>    
            <v-textarea label="Caption" v-model="Caption" :rules="[rules.max150Chars]" />
            </v-card-actions>
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-card-actions>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn @click="CancelEdit()" outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreMeetup()">
                    Save
                </v-btn>
                <v-card-actions>
              <TaggingComponent @SaveSingleTag="SaveSingleTag" :AppisDarkModeCard="AppisDarkModeCard" :record="FullArticleData" :TagLinkDialog="TagLinkDialog" />
            </v-card-actions>
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import TaggingComponent from '@/components/SocialNetwork/TaggingComponent';
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
    props: ['caption','title','pubdatastring','CurrentImageShy','Currentmeetupcontent','CurrentModerators','GuestsIncluded','GroupData'
    ,'SiteModerators','CurrentImage','FullArticleData'],
        middleware: 'auth',
      components: {
        TaggingComponent,
        ContentEditable
    // quillEditor

        
  },
  
    data() {
        return {
          Category: '',
          ItemCategory: '',
          TempGuestsIncluded: false,
          EventenddateMenu: false,
          EventEndDate: '',
          EventStartTime: '',
          EventStartTimeMenu: false,
          AllDayEvent: false,
          EventEndTime: '',
          EventEndTimeMenu: false,
          EventStartDate: '',
          TagLinkDialog: false,
          UsersArray: [],
          Caption: '',
          Moderators: [],
          PublishDate: '',
          PublishDatemenu: false,
          MeetupTitle: '',
          ImageShy: false,
        selectedFile: '',
        ImagelocalURL: '',
        MeetupContent: '',
        meetupquil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        meetupdialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      meetuptitle: null,
        meetupcontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        groupmeetups: [],
        groups: [],
        meetupid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        HasImage: false,
        rules: {
          max150Chars: value => value.length < 151 || "Max. 150 characters",
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            MeetupsCategories: []    
        }
    },
    computed: {
      
        System(){
          return this.$store.state.SystemObj
        },
        userLoggedIn () {
          return this.$store.getters.user
        },
        RecurranceMonthlyPatternOptions(){
        let daysofweek = ['Monday','Tuesday','Wednesday','Thursday','Friday','Staurday','Sunday']
        let daysinmonth = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
        if(this.RecurranceMonthlyPattern){
          if(this.RecurranceMonthlyPattern === 'Every First'){
            return daysofweek
          }
          else if(this.RecurranceMonthlyPattern === 'Every Last'){
            return daysofweek
          }
          else if(this.RecurranceMonthlyPattern === 'Day of Month'){
            return daysinmonth
          }
        }
        
      },
      AdvanceIntegerLabel(){
        if(this.Recurring && this.RecurranceFrequency){
          if(this.RecurranceFrequency === 'Every Day'){
            return 'Days in Advance'
          }
          else if(this.RecurranceFrequency === 'Every Week'){
            return 'Weeks in Advance'
          }
          else if(this.RecurranceFrequency === 'Every Month'){
            return 'Months in Advance'
          }

        }
      },
      AdvanceOptions(){
        if(this.Recurring && this.RecurranceFrequency){
          if(this.RecurranceFrequency === 'Every Day'){
            return [
              7,14
            ]
          }
          else if(this.RecurranceFrequency === 'Every Week'){
            return [
              2,4
            ]
          }
          else if(this.RecurranceFrequency === 'Every Month'){
            return [
              1,2,3
            ]
          }

        }
      },
      AllowedEndTimeHours(){

        let Hours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      return Hours.filter(hr => {
        if(this.EventStartTime){
          return hr >= Number(this.EventStartTime.split(':')[0])
        }
        else{
          return []
        }
        })
       console.log(this.AllowedEndTimeHours)
      },
      AllowedEndTimeMinutes(){
        let Minutes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        let starttimehour = Number(this.EventStartTime.split(':')[0])
        let endtimehour = Number(this.EventEndTime.split(':')[0])  
        console.log(starttimehour,endtimehour)        
        return Minutes.filter(min => {
          if(this.EventStartTime && this.EventEndTime){
            
            if(starttimehour === endtimehour){
              return min >= Number(this.EventStartTime.split(':')[1])
            }
            else{
              return Minutes
            }
          }
          else{
            return Minutes
          }
        })
      },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedMeetups () {
            return this.groupmeetups.filter(meetup => {
                return meetup.meetupid === this.meetupid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    created() {
        if(this.$route.name !== 'GroupMeetup'){
          this.MeetupsCategories = this.System.MeetupsCategories
        }
        else{
          this.MeetupsCategories = this.GroupData.MeetupsCategories
        }
       document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

      this.GetRequestingUser()
    
      this.GetProperties()

    },
    
    methods: {
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
        CancelEdit(){
            this.$emit('StopEdit')
        },
        GetProperties(){
          if(this.FullArticleData.Category){
            this.Category = this.FullArticleData.Category
            this.ItemCategory = this.FullArticleData.Category
          }
          if(this.GuestsIncluded){
            this.TempGuestsIncluded = this.GuestsIncluded
          }
          if(this.FullArticleData.start && this.FullArticleData.start.toDate){
            let start = this.FullArticleData.start.toDate()
            this.EventStartDate = format(start,'yyyy-MM-dd')
            let starthour = start.getHours()
            let startmins = start.getMinutes()
            if(startmins === 0){
              startmins = '00'
            }
            this.EventStartTime = starthour+':'+startmins
          }
          if(this.FullArticleData.end && this.FullArticleData.end.toDate){
            let end = this.FullArticleData.end.toDate()
            this.EventEndDate = format(end,'yyyy-MM-dd')
            let endhour = end.getHours()
            let endmins = end.getMinutes()
            if(endmins === 0){
              endmins = '00'
            }
            this.EventEndTime = endhour+':'+endmins
          }
          
            this.AllDayEvent = this.FullArticleData.AllDayEvent

            this.MeetupTitle = this.title
            this.PublishDate = this.pubdatastring
            console.log(this.PublishDate)
            this.ImageShy = this.CurrentImageShy
            if(this.CurrentImage){
              this.coverimagelocalurl = this.CurrentImage
              this.HasImage = true
            }
            if(this.caption){
              this.Caption = this.caption
            }
            console.log(this.SiteModerators)
            console.log(this.SiteModerators)
            this.Moderators = this.CurrentModerators.map(moderator => {
                let fullobj = this.SiteModerators.find(obj => obj.id === moderator)
                return fullobj
            })
            let elementid = 'NewBlogValue'            
            setTimeout(() => {
                let meetupcontentelmnt = document.getElementById(elementid)
                meetupcontentelmnt.innerHTML = this.Currentmeetupcontent
            }, 300);
            
        },
      
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
        onmeetupcoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.HasImage = false
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        GetMeetups(){
        db.collection('sitemeetups').onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.meetups.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        
        },
        StoreMeetup(){
         
          let vm = this
          vm.$emit('ActivateProcessing',true)
          let col = ''
          if(vm.Category && !vm.Category.ID){
            let length = vm.MeetupsCategories.length
            let int = 1000001+length
            let newcat = {
              ID: int,
              Name: vm.Category
            }
            vm.ItemCategory = newcat
            let catcollection = ''
            if(this.$route.name === 'GroupMeetup'){
              catcollection = db.collection('groups').doc(this.$route.params.id).collection('MeetupsCategories')
            }
            else{
              catcollection = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('MeetupsCategories')
            }
            catcollection.add(newcat)
          }
          else if(vm.Category && vm.Category.ID){
            vm.ItemCategory = vm.Category
          }
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
          let docid = this.$route.params.id
          let NewStartDateString = this.EventStartDate+'T'+this.EventStartTime
          let NewEndDateString = this.EventEndDate+'T'+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
          if(this.$route.name === 'GroupMeetup'){
            col = 'groupmeetups'
            routepath = '/Group-Meetup/'+this.$route.params.id+'/Meetup/'
            routeprop = 'title'
            docid = this.$route.params.slug
          }
          else{
            col = 'sitemeetups'
            routepath = '/Meetup/'
            routeprop = 'id'
          }
        
        let postelmnt = document.getElementById('NewBlogValue')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        if(this.selectedcoverimage && this.MeetupTitle && postinput || this.HasImage === true && this.MeetupTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewMeetup = {
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          caption: this.Caption,
          Likes: [],
          Shares: [],
          tags: [],
          title: this.MeetupTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: this.FullArticleData.createdon,
          // createdon: new Date(),
          Comments: [],
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,          
          GuestsIncluded: this.TempGuestsIncluded
        }
        let titleQuery = []
        let titleStringArray = NewMeetup.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                if(p === titleStringArray.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery = titleQuery.concat(NewMeetup.title.split(' ')) 
          console.log(titleQuery)
          NewMeetup.titleQuery = titleQuery.map(entry => {
                          return entry.toLowerCase()
                        })
        if(vm.ItemCategory){
          NewMeetup.Category = vm.ItemCategory
        }
        if(this.$route.name === 'GroupMeetup'){
          NewMeetup.groupid = this.$route.params.id
        }

        if(this.UserRecord.Profile_Photo){
          NewMeetup.creatorimg = this.UserRecord.Profile_Photo
        }
        if(this.HasImage === false){
          if(this.$route.name === 'GroupMeetup'){
            storagepath = 'GroupMeetups/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+this.selectedcoverimage
          }
          else{
            storagepath = 'SiteMeetups/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+this.selectedcoverimage
          }
          this.UpdateMeetupCoverImage(this.selectedcoverimage,storagepath).then(function(result) {
            if(result){
                NewMeetup.coverimage = result
                NewMeetup.CoverStorageRef = storagepath
               db.collection(col).doc(docid).set(NewMeetup).then(doc => {
                   
                   vm.$emit('StopEdit')
               })
               console.log(NewMeetup.post)
                vm.CancelNewMeetupDialog()
            }          
          })
        }
        else{
          NewMeetup.coverimage = this.CurrentImage
          db.collection(col).doc(docid).set(NewMeetup).then(doc => {
                  
                   vm.$emit('StopEdit')
               })
        }
          
        }
        else if(this.ImageShy && this.MeetupTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
        let NewMeetup = {
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          caption: this.Caption,
          Likes: [],
          Shares: [],
          tags: [],
          title: this.MeetupTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: this.FullArticleData.createdon,
          // createdon: new Date(),
          // PublishDate: this.DateFormatter(this.PublishDate),
          Comments: [],
          ImageShy: this.ImageShy,
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,          
          GuestsIncluded: this.TempGuestsIncluded
        }
        let titleQuery = []
        let titleStringArray = NewMeetup.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                if(p === titleStringArray.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery = titleQuery.concat(NewMeetup.title.split(' ')) 
          console.log(titleQuery)
          NewMeetup.titleQuery = titleQuery.map(entry => {
                          return entry.toLowerCase()
                        })
        if(vm.ItemCategory){
          NewMeetup.Category = vm.ItemCategory
        }
        if(this.UserRecord.Profile_Photo){
          NewMeetup.creatorimg = this.UserRecord.Profile_Photo
        }    
        
          db.collection('sitemeetups').doc(vm.$route.params.id).set(NewMeetup).then(doc => {
            vm.CancelEdit()
               })
               console.log(NewMeetup.post)          
        }
        else if(!this.selectedcoverimage && this.MeetupTitle && postinput && this.HasImage === false){
            alert('You have to upload an image')
        }
        else if(!this.selectedcoverimage && !this.MeetupTitle && postinput && this.HasImage === false){
            alert('You have to upload an image, and insert a title')
        }
        else if(!this.selectedcoverimage && !this.MeetupTitle && !postinput){
            alert('There is nothing to post!')
        }
        else if(!this.selectedcoverimage && this.MeetupTitle && !postinput){
            alert('There is no cover image, or post content!')
        }
        else if(this.selectedcoverimage && !this.MeetupTitle && postinput){
            alert('You have to insert a title')
        }
        else if(this.selectedcoverimage && !this.MeetupTitle && !postinput){
            alert('You have to insert a title, and content')
        }
        else if(this.selectedcoverimage && this.MeetupTitle && !postinput){
            alert('You have to insert content')
        }
        
      
        },

        UpdateMeetupCoverImage(picturefile,storagepath){
      let vm = this
      return new Promise(function(resolve, reject) {
      var storageRef = firebase.storage().ref(storagepath);
      var uploadTask = storageRef.put(picturefile);
      uploadTask
      .then(snapshot => snapshot.ref.getDownloadURL())
        .then((url) => {
          
          
          resolve(url)
              })
                
      })
    },
        CancelNewMeetupDialog(){
            this.MeetupTitle = ''
            this.selectedcoverimage = ''
        },
      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
    linkyoutubevideo(){
        this.Youtubelink = prompt("Enter the link")
        let validation = this.rules.youtubeurl(this.Youtubelink)

        if(validation === true){
        
        // let finalembed = `<iframe width="560" height="315" src="`+this.ComputedYoutubelinkEmbed+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        document.execCommand("insertHTML", false, this.ComputedYoutubelinkEmbed)
        this.Youtubelink = ''
        }
        else{
            confirm(validation) && this.linkyoutubevideo()
        }
    },


    copy() {
      document.execCommand("copy", false, "");
    },

    getImage() {
      console.log('this.ContentBlockObject',this.ContentBlockObject.Name)
      let elementid = 'meetupcontentdiv'
      console.log('elementid',elementid)
      var file = document.querySelector("input[type=file]").files[0];

      var reader = new FileReader();

      let dataURI;

      reader.addEventListener(
        "load",
        function() {
          dataURI = reader.result;

          const img = document.createElement("img");
          img.src = dataURI;
          let NEWeditorContent = document.getElementById(elementid)
          NEWeditorContent.appendChild(img);
        },
        false
      );

      if (file) {
        console.log("s");
        reader.readAsDataURL(file);
      }
    },

    getDocumentSelection(){
      this.SelectedStuff = document.getSelection()
      console.log(this.SelectedStuff) 
    },

    ChangefontSize(){
      document.execCommand('fontsize', false, this.fontsize)
    },
    changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.authorid = userdetails.id

              vm.author = userdetails.Name+' '+userdetails.Surname
              
              console.log('this is user name man '+vm.author)
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
        UpdateMeetup(){
          // console.log(this.editor.scrollingContainer.innerHTML)

          //   const UpdatedMeetup = {
          //       content: this.editor.scrollingContainer.innerHTML
          //   }

            db.collection('groupmeetups').doc(this.meetupid).update({
                content: UpdatedMeetup.content
            });
              this.snackbar = true
            this.meetupquil = false
        },
        onEditorChange() {
// alert('change')
        },
    // async getMeetupInformation() {
    //     let meetupcollection = db.collection('groupmeetups')
    //     let docref = meetupcollection.doc(this.meetupid)
    //     docref.onSnapshot(snapshot => {
    //       // this.UpdateRequestStatus()
    //       var meetupdata = snapshot.data()
        
      
    //     this.name = meetupdata.name
    //     this.content = meetupdata.content
    //     this.meetupcontent = meetupdata.content
        
    //     this.image = meetupdata.image
      

    //     })
    
    //     },
      OpenMeetupdialog() {
        this.meetupdialog = true
    },
    Closedialog() {
        this.meetupdialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
        this.ImagelocalURL = URL.createObjectURL(this.selectedFile)
      },
    
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>