
<template>
    
             <v-card class="transparent footermargin" flat>
            
              <div v-if="UserRecord">
              <div  v-if="SliderView">
                
                  <v-carousel v-model="model" height="400" cycle>
                      <v-carousel-item
                        v-for="article in RelatedBlogs.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          style="background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="ItemPath+'/Blog/'+article[ItemProp]" color="links black--text"  :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
        
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedBlogs" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.description.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark  :to="ItemPath+'/Blog/'+article[ItemProp]">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedBlogs.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                  <v-carousel v-model="model" height="400">
                      <v-carousel-item
                        v-for="article in RelatedBlogs.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                             <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              
                               <v-card-text style="font-size: 10px;padding-left:25px;" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'">
                                 {{article.caption}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn  :to="ItemPath+'/Blog/'+article[ItemProp]" color="links black--text"  :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list>
     <v-list-item  v-for="article in RelatedBlogs" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  {{article.pubDate.toDate()}}
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.caption.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                 <!-- <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip> -->
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark  :to="ItemPath+'/Blog/'+article[ItemProp]">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedBlogs.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
           
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['UserRecord','Origin','clientDB','Filter','Excerpt','Slice','SliderView','UserView','UserID'],
    components: {
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {
          
            model: 0,
            groupblogs: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Blogs') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.userLoggedIn.IsGuestUser && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      CollectionQuery(){
        let ref = this.CollectionRef
        if(this.CompanyOnlyFilter){
          ref = ref.where('Companyid','==',this.UserCompanyid)
        }
        return ref
      },
      userLoggedIn () {
        return this.$store.getters.user
        },
      ItemProp(){
        if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'){
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
        else{
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
      },
      ItemPath(){
        if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'){
          return this.userLoggedIn ? '' : ''
        }
        else{
          return this.userLoggedIn ? '/Group-Blog/'+this.$route.params.id : '/Group-Blog/'+this.$route.params.id
        }
      },
      CollectionRef(){
        if(this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'){
         return this.userLoggedIn ? db.collection('siteblogs') : db.collection('siteblogswiki') 
        }
        else{
          return db.collection('groupblogs').where('groupid','==',this.$route.params.id).where('GuestsIncluded','==',true)
        }
        
      },
        RelatedBlogs () {
            return this.groupblogs
            // .filter(blog => {
            //     let bloggroup = this.groups.find(obj => obj.id === blog.Groupid)
            //     blog.category = bloggroup.GroupCategory
            //     console.log(blog.grouptype)
            //     let blogmatch = this.Filter.find(obj => obj.Name === blog.grouptype)
            //     if(blogmatch){
            //         return blog
            //     }
            // })
        },

        BlogExcerpt () {
          return this.RelatedBlogs.filter(blog => {
            return blog.content === this.blog.content
          })
        }
    },

    created() {
      if(this.UserView){
        if(this.UserID === this.UserRecord.id){
          //If I am moderator then I should call all my drafts as well
          if(this.userLoggedIn.IsGuestUser){
          let CollectionRef = this.CollectionQuery
          this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
          if(!this.ChannelRestricted){
          this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
          }
          }
          else{
            let CollectionRef = this.CollectionQuery
            if(!this.ChannelRestricted){
            this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
            }
            else{
              this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            }
          }
        }
        else{
          if(this.userLoggedIn.IsGuestUser){
          let CollectionRef = this.CollectionQuery
          this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            if(!this.ChannelRestricted){
            this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
            }
          }
          else{
            let CollectionRef = this.CollectionQuery
             if(!this.ChannelRestricted){
            this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
             }
             else{
              this.getGroupBlogs(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
             }
          }
        }
      }
      else{
        if(this.UserRecord){
          let CollectionRef = this.CollectionQuery
          if(this.userLoggedIn.IsGuestUser && this.$route.name !== 'GroupPage'){
            this.getGroupBlogs(CollectionRef.where('PublishStatus','==','Published (Public)'))
             if(!this.ChannelRestricted){
            this.getGroupBlogs(CollectionRef.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))    
             }    
          }
          else{
             if(!this.ChannelRestricted){
            this.getGroupBlogs(CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
             }
             else{
              this.getGroupBlogs(CollectionRef.where('PublishStatus','==','Published (Public)'))
             }
          }
        }
        else{
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)')
          this.getGroupBlogs(CollectionRef)
        }
      }
        //console.log(this.userid)
},
    methods: {
      
         getGroupBlogs(CollectionRef) {
         
           CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.groupblogs.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Blog_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const blog = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('siteblogs').add(blog).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>