<template>
  <v-main>
      <v-card flat tile class="transparent">
          <v-card-title class="recordtoolbar white--text largeoverline">
              {{WarehouseName}}
        </v-card-title>
        <v-card-title class="recordtoolbar white--text mediumoverline">
         {{RecordData.Item_Name}} - Movement History <v-spacer></v-spacer><v-btn :to="'/'+SinglePath+'/'+RouteID">View Stock</v-btn>
        </v-card-title>
        <v-card-text>           
             <v-tabs centered v-model="MovementTab">
               <v-tab>Inbound
               </v-tab>
               <v-tab-item>
                   <v-card-text>
                   <v-layout class="justify-start my-0" row >
                    <v-flex lg10 md10>
                    <v-toolbar rounded dark color="primary">
                    <v-toolbar-title>Transactions</v-toolbar-title>

                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field 
                        v-model="docsearch"
                        append-icon="mdi-file-find-outline"
                        label="Search Warehouse"
                        single-line
                        hide-details
                        ></v-text-field><v-icon @click="ResetSearch()" v-if="docsearch !== ''" class="mx-10">mdi-close</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="DownloadMovementTransactions()"><v-icon>mdi-download</v-icon>Download</v-btn>
                    </v-toolbar>
                    </v-flex>
                </v-layout>
                
                 <v-data-table
                  :headers="InboundTransactionsHeaders"
                  :items="InboundTransactionsCompleted"
                  :items-per-page="5"
                  class="elevation-1"
                >
                <template v-slot:[`item.readdate`]="{ item }">
                  <v-tooltip
                    v-model="item.showdate"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                     <span class="caption">{{item.readdate}}</span>
                   
                  </v-tooltip>
                  
                 </template>
                 <template v-slot:[`item.Created_By.Full_Name`]="{ item }">
                   <v-tooltip
                    v-model="item.showcreatedby"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar v-bind="attrs"
                        v-on="on" size="40" v-if="item.Created_By.Profile_Photo">
                              <img :src="item.Created_By.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar v-bind="attrs"
                        v-on="on" color="grey" class="white--text ml-0" size="40" v-if="!item.Created_By.Profile_Photo">                               
                                {{item.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{item.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                     
                    </template>
                     <span class="caption">{{item.Created_By.Full_Name}}</span>
                   
                  </v-tooltip>
                 </template>
                 <template v-slot:[`item.Transaction_Type`]="{ item }">
                  <v-tooltip
                    v-model="item.showid"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        plain text
                        v-bind="attrs"
                        v-on="on"
                      >{{item.Transaction_Type}}</v-btn>
                    </template>
                     <span class="caption">{{item.id}}</span>
                   
                  </v-tooltip>
                  
                 </template>
                 <template v-slot:[`item.Reference_Number`]="{ item }">
                  <v-tooltip
                    v-model="item.shownote"
                    top v-if="!item.Receiving_Document"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-note-text</v-icon>
                      </v-btn>
                    </template>
                    <span v-if="!item.Receiving_Document" class="caption">{{item.Reference_Number}}</span>                   
                  </v-tooltip>
                  <v-tooltip
                    v-model="item.shownote"
                    top v-if="item.Receiving_Document"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn target="_blank"
                        icon :href="item.Receiving_Document.fileurl"
                        v-bind="attrs"
                        v-on="on"
                      ><v-icon left>mdi-note-text</v-icon><span class="caption">{{item.Reference_Number}}</span>
                        
                      </v-btn>
                    </template>
                    <a class="caption" target="_blank" :href="item.Receiving_Document.fileurl">{{item.Reference_Number}}
                    </a>
                   
                  </v-tooltip>
                 </template>
                <template v-slot:[`item.Related`]="{ item }">
                   <v-btn small v-if="item.Related" :to="'/'+item.Related.single.split(' ').join('')+'/'+item.Related.id">
                    {{item.Related[item.Related.identifier]}}
                   </v-btn>
                 </template>
                  <template v-slot:[`item.Document`]="{ item }">
                   <v-btn v-if="item.Transaction_Docid" small
                   :to="'/WarehouseTransaction/'+item.Warehouseid+'/'+item.Transaction_Docid+'?TransactionType='+item.TransactionType"
                   >
                    View
                   </v-btn>
                 </template>
                </v-data-table>
                </v-card-text>
               </v-tab-item>
                <v-tab>Outbound
               </v-tab>
               <v-tab-item>
                   <v-card-text>
                   <v-layout class="justify-start my-0" row >
                    <v-flex lg10 md10>
                    <v-toolbar rounded dark color="primary">
                    <v-toolbar-title>Transactions</v-toolbar-title>

                        <v-divider
                        class="mx-4"
                        inset
                        vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-text-field 
                        v-model="docsearch"
                        append-icon="mdi-file-find-outline"
                        label="Search Warehouse"
                        single-line
                        hide-details
                        ></v-text-field><v-icon @click="ResetSearch()" v-if="docsearch !== ''" class="mx-10">mdi-close</v-icon>
                        <v-spacer></v-spacer>
                        <v-btn @click="DownloadMovementTransactions()"><v-icon>mdi-download</v-icon>Download</v-btn>
                    </v-toolbar>
                    </v-flex>
                </v-layout>
                 <v-data-table
                  :headers="OutboundTransactionsHeaders"
                  :items="OutboundTransactionsCompleted"
                  :items-per-page="5"
                  class="elevation-1"
                >
                <template v-slot:[`item.readdate`]="{ item }">
                  <v-tooltip
                    v-model="item.showdate"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-calendar</v-icon>
                      </v-btn>
                    </template>
                     <span class="caption">{{item.readdate}}</span>
                   
                  </v-tooltip>
                  
                 </template>
                 <template v-slot:[`item.Created_By.Full_Name`]="{ item }">
                   <v-tooltip
                    v-model="item.showcreatedby"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-avatar v-bind="attrs"
                        v-on="on" size="40" v-if="item.Created_By.Profile_Photo">
                              <img :src="item.Created_By.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar v-bind="attrs"
                        v-on="on" color="grey" class="white--text ml-0" size="40" v-if="!item.Created_By.Profile_Photo">                               
                                {{item.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{item.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                     
                    </template>
                     <span class="caption">{{item.Created_By.Full_Name}}</span>
                   
                  </v-tooltip>
                 </template>
                 <template v-slot:[`item.Transaction_Type`]="{ item }">
                  <v-tooltip
                    v-model="item.showid"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        plain text
                        v-bind="attrs"
                        v-on="on"
                      >{{item.Transaction_Type}}</v-btn>
                    </template>
                     <span class="caption">{{item.id}}</span>
                   
                  </v-tooltip>
                  
                 </template>
                 <template v-slot:[`item.Reference_Number`]="{ item }">
                  <v-tooltip
                    v-model="item.shownote"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-note-text</v-icon>
                      </v-btn>
                    </template>
                     <span class="caption">{{item.Reference_Number}}</span>
                   
                  </v-tooltip>
                  
                 </template>
                 
                <template v-slot:[`item.Related`]="{ item }">
                   <v-btn small v-if="item.Related" :to="'/'+item.Related.single.split(' ').join('')+'/'+item.Related.id">
                    {{item.Related[item.Related.identifier]}}
                   </v-btn>
                 </template>
                 <template v-slot:[`item.Document`]="{ item }">
                  <v-btn v-if="item.Transaction_Docid" small
                   :to="'/WarehouseTransaction/'+item.Warehouseid+'/'+item.Transaction_Docid+'?TransactionType='+item.TransactionType"
                   >
                    View
                   </v-btn>
                 </template>
                 
                </v-data-table>
                   </v-card-text>
               </v-tab-item>
             </v-tabs>
        </v-card-text>
        <!-- <v-card-actions>
        
          <v-spacer></v-spacer>
          <v-btn @click="DownloadMovementTransactions()"><v-icon>mdi-download</v-icon>Download</v-btn>
        </v-card-actions> -->
      </v-card>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            docsearch: '',
            RecordData: {},
            Inbound_Transactions: [],
            Outbound_Transactions: [],
            InventoryAdjustments: [],
            PurchaseRequisitionsLine_Items: [],
            PurchaseOrdersLine_Items: [],
            GoodsReceivingVouchersLine_Items: [],
            PickingSlipsLine_Items: [],
            PackingListsLine_Items: [],
            DispatchInstructionsLine_Items: [],
            InboundTransactions: [],
            MovementTab: 0,
            InboundTransactionsHeaders: [
                
                {text: 'Created On',value: 'readdate',class: 'overline'},
                {text: 'Created By',value: 'Created_By.Full_Name',class: 'overline'},
                {text: 'Type',value: 'Transaction_Type',class: 'overline'},
                {text: 'Document',value: 'Document',class: 'overline'},
                {text: 'Qty',value: 'Qty',class: 'overline'},
                {text: 'Price',value: 'Price',class: 'overline'},
                {text: 'Progress',value: 'Progress.Name',class: 'overline'},
                {text: 'Reference',value: 'Reference_Number',class: 'overline'},         
                {text: 'Related',value: 'Related',class: 'overline'},
                    
            ],
            OutboundTransactions: [],
            OutboundTransactionsHeaders: [
                {text: 'Created On',value: 'readdate',class: 'overline'},
                {text: 'Created By',value: 'Created_By.Full_Name',class: 'overline'},
                {text: 'Type',value: 'Transaction_Type',class: 'overline'},
                {text: 'Document',value: 'Document',class: 'overline'},
                {text: 'Qty',value: 'Qty',class: 'overline'},
                {text: 'Price',value: 'Price',class: 'overline'},
                {text: 'Progress',value: 'Progress.Name',class: 'overline'},
                {text: 'Reference',value: 'Reference_Number',class: 'overline'},  
                {text: 'Related',value: 'Related',class: 'overline'},   
            ],
        }
    },
    computed:{
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        UsersArray(){
            return this.$store.state.Users
        },
         InboundTransactionsCompleted(){
      let types = [{Name: 'PickingSlipsLine_Items',TransactionTypeSingle: 'Picking Slip',TransactionType: 'PickingSlips'},
        {Name: 'PackingListsLine_Items',TransactionTypeSingle: 'Packing List',TransactionType: 'PackingLists'},
        {Name: 'DispatchInstructionsLine_Items',TransactionTypeSingle: 'Dispatch Instruction',TransactionType: 'DispatchInstructions'},
        {Name: 'GoodsReceivingVouchersLine_Items',TransactionTypeSingle: 'Goods Receiving Voucher',TransactionType: 'GoodsReceivingVouchers'},
        {Name: 'PurchaseOrdersLine_Items',TransactionTypeSingle: 'Purchase Order',TransactionType: 'PurchaseOrders'},
        {Name: 'PurchaseRequisitionsLine_Items',TransactionTypeSingle: 'Purchase Requisition',TransactionType: 'PurchaseRequisitions'},]
      let arr1 = this.GoodsReceivingVouchersLine_Items.filter(item => {
        return item.Progress && item.Progress.Name === 'Completed'
      })
      let arr2 = this.InventoryAdjustments.filter(item => {
        return item.Qty > 0
      })
      let arr3 = this.Inbound_Transactions.map(item => {
        if(!item.readdate && item.Created_On && item.Created_On.toDate){
          item.readdate = item.Created_On.toDate()
        }
        item.readdate = item.readdate
        if(item.LookupObj){
          item.Related = Object.assign({},item.LookupObj)
          item.Related.DisplayName = item.Related[item.Related.identifier]+' ('+item.Related.id+')'
        }
        else if(item.PurchaseOrderid){
          item.Related = {identifier: 'DisplayName',DisplayName: item.PurchaseOrderid,single: 'PurchaseOrder',id: item.PurchaseOrderid}
        }
        else if(item.PurchaseRequisitionid){
          item.Related = {identifier: 'DisplayName',DisplayName: item.PurchaseRequisitionid,single: 'PurchaseOrder',id: item.PurchaseRequisitionid}
        }
        if(!item.Reference_Number){
          if(item.Preceding_TransactionDocCollection){
            item.Reference_Number = 'Progressing '+item.Preceding_TransactionLineid
          }
          else if(item.LookupObj){
            item.Reference_Number = 'FulFilling '+item.Related.DisplayName
            if(item.Created_on_GRV){
              item.Reference_Number = item.Reference_Number+' through GRV'
            }
          }
          else if(item.Related){
            item.Reference_Number = 'FulFilling '+item.Related.DisplayName
          }
          else if(item.Transaction_Type === 'Purchase Order' && item.Supplier){
            item.Reference_Number = 'Purchase Order with '+item.Supplier.Supplier_Name
          }
        }
        item.TransactionType = types.find(obj => obj.TransactionTypeSingle === item.Transaction_Type).TransactionType
        //console.log('item typesd.find(obj => obj.TransactionTypeSingle === item.Transaction_Type)',item,types.find(obj => obj.TransactionTypeSingle === item.Transaction_Type))
        return item
      })
      return arr3     
      .map(item => {
        let userobj = this.UsersArray.find(obj => obj.id === item.Created_Byid)
        if(userobj && userobj.Profile_Photo){
            item.Created_By.Profile_Photo = userobj.Profile_Photo
        }
        item.Document = item.Transaction_Docid
        return item
      })
      .sort((a, b) => {
            //console.log('sorting again')
            //console.log(a.start)
            var key1 = a.readdate
            var key2 = b.readdate

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      }).filter(item => {
          if(this.docsearch){
              return item.Reference_Number.toLowerCase().includes(this.docsearch.toLowerCase()) || item.Related && item.Related.DisplayName.toLowerCase().includes(this.docsearch.toLowerCase())
          }
          else{
              return item
          }
      })
    },
    OutboundTransactionsCompleted(){
      //console.log('this.PickingSlipsLine_Items',this.PickingSlipsLine_Items)
      let types = [{Name: 'PickingSlipsLine_Items',TransactionTypeSingle: 'Picking Slip',TransactionType: 'PickingSlips'},
        {Name: 'PackingListsLine_Items',TransactionTypeSingle: 'Packing List',TransactionType: 'PackingLists'},
        {Name: 'DispatchInstructionsLine_Items',TransactionTypeSingle: 'Dispatch Instruction',TransactionType: 'DispatchInstructions'},
        {Name: 'GoodsReceivingVouchersLine_Items',TransactionTypeSingle: 'Goods Receiving Voucher',TransactionType: 'GoodsReceivingVouchers'},
        {Name: 'PurchaseOrdersLine_Items',TransactionTypeSingle: 'Purchase Order',TransactionType: 'PurchaseOrders'},
        {Name: 'PurchaseRequisitionsLine_Items',TransactionTypeSingle: 'Purchase Requisition',TransactionType: 'PurchaseRequisitions'},]
      let arr1 = this.DispatchInstructionsLine_Items.concat(this.PickingSlipsLine_Items,this.PackingListsLine_Items)
      // .filter(item => {
      //   return item.Progress && item.Progress.Name === 'Completed'
      // })
      let arr2 = this.InventoryAdjustments.filter(item => {
        return item.Qty > 0
      })
      let arr3 = this.Outbound_Transactions.map(item => {
        if(!item.readdate && item.Created_On && item.Created_On.toDate){
          item.readdate = item.Created_On.toDate()
        }
        else if(!item.readdate && item.Date_Received && item.Date_Received.toDate){
          item.readdate = item.Date_Received.toDate()
        }
        if(item.LookupObj){
          item.Related = Object.assign({},item.LookupObj)
          item.Related.DisplayName = item.Related[item.Related.identifier]+' ('+item.Related.id+')'
        }
        else if(item.PurchaseOrderid){
          item.Related = {identifier: 'DisplayName',DisplayName: item.PurchaseOrderid,single: 'PurchaseOrder',id: item.PurchaseOrderid}
        }
        else if(item.PurchaseRequisitionid){
          item.Related = {identifier: 'DisplayName',DisplayName: item.PurchaseRequisitionid,single: 'PurchaseOrder',id: item.PurchaseRequisitionid}
        }
        if(!item.Reference_Number){
          if(item.Preceding_TransactionDocCollection){
            item.Reference_Number = 'Progressing '+item.Preceding_TransactionLineid
          }
          else if(item.LookupObj){
            item.Reference_Number = 'FulFilling '+item.Related.DisplayName
            if(item.Created_on_GRV){
              item.Reference_Number = item.Reference_Number+' through GRV'
            }
          }
          else if(item.Related){
            item.Reference_Number = 'FulFilling '+item.Related.DisplayName
          }
          else if(item.Transaction_Type === 'Purchase Order' && item.Supplier){
            item.Reference_Number = 'Purchase Order with '+item.Supplier.Supplier_Name
          }
        }
        item.TransactionType = types.find(obj => obj.TransactionTypeSingle === item.Transaction_Type).TransactionType
        return item
      })
      return arr3
      .map(item => {
        let userobj = this.UsersArray.find(obj => obj.id === item.Created_Byid)
        if(userobj && userobj.Profile_Photo){
            item.Created_By.Profile_Photo = userobj.Profile_Photo
        }
        item.Document = item.Transaction_Docid
        return item
      })
      .sort((a, b) => {
            //console.log('sorting again')
            //console.log(a.start)
            var key1 = a.readdate
            var key2 = b.readdate

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      }).filter(item => {
          if(this.docsearch){
              return item.Reference_Number.toLowerCase().includes(this.docsearch.toLowerCase()) || item.Related && item.Related.DisplayName.toLowerCase().includes(this.docsearch.toLowerCase())
          }
          else{
              return item
          }
      })
    },
    WarehousesArray(){
        return this.$store.state.WarehousesArray
    },
    ActiveWarehouse(){
        return this.WarehousesArray.find(obj => obj.id === this.WarehouseID)
    },
    WarehouseName(){
        return this.ActiveWarehouse ? this.ActiveWarehouse.Name : ''
    },
    WarehouseID(){
        //groups coming
        return this.$route.params.slug
    },
    RouteID(){
        return this.$route.params.id
    },
    ComputedSystemEntities(){
        //groups coming later
        return this.SystemEntities
    },
    SinglePath(){
        return this.CurrentEntity.SingleName.split(' ').join('')
    },
    CurrentEntity(){
        return this.ComputedSystemEntities.find(obj => obj.id === this.EntityID)
    },
    EntityID(){
        return this.$route.meta.EntityID
    },
    DBRef(){
        return db.collection(this.EntityID.split('_').join('').toLowerCase()).doc(this.RouteID)
    },
    ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
    },
    created(){
        this.GetStockRecord()
        if(!this.ActiveSuiteApp && this.CurrentEntity.Primary_Appid){
          let app = this.$store.state.AppsDataBase.find(obj => obj.id === this.CurrentEntity.Primary_Appid)
          if(app){
            this.$store.commit('setActiveSuiteApp',app)
          }
        }
    },
    methods:{
        ResetSearch(){
            this.docsearch = ''
        },
        GetStockRecord(){
            this.DBRef.onSnapshot(stocksnap => {
                let stockdata = stocksnap.data()
                this.RecordData = stockdata
                this.GetMovement()
            })
        },
        GetStockMovementHistoryOld(collection){
      
       let warehousetransactions = [
        {Name: 'PickingSlipsLine_Items',TransactionTypeSingle: 'Picking Slip',TransactionType: 'PickingSlips'},
        {Name: 'PackingListsLine_Items',TransactionTypeSingle: 'Packing List',TransactionType: 'PackingLists'},
        {Name: 'DispatchInstructionsLine_Items',TransactionTypeSingle: 'Dispatch Instruction',TransactionType: 'DispatchInstructions'},
        {Name: 'GoodsReceivingVouchersLine_Items',TransactionTypeSingle: 'Goods Receiving Voucher',TransactionType: 'GoodsReceivingVouchers'},
        {Name: 'PurchaseOrdersLine_Items',TransactionTypeSingle: 'Purchase Order',TransactionType: 'PurchaseOrders'},
        {Name: 'PurchaseRequisitionsLine_Items',TransactionTypeSingle: 'Purchase Requisition',TransactionType: 'PurchaseRequisitions'},
        {Name: 'Inbound_Transactions',TransactionType: 'InboundTransactions'},
        {Name: 'Outbound_Transactions',TransactionType: 'OutboundTransactions'},
        ]
        //console.log('GetStockMovementHistoryOld',collection,warehousetransactions,warehousetransactions.find(obj => obj.Name === collection))
      
      db.collection('Warehouses').doc(this.$route.params.slug).collection(collection).where('Warehouseid','==',this.$route.params.slug).where('StockObj.id','==',this.RouteID).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                let arrayobj = this[collection].find(obj => obj.id == change.doc.id)
                if(!arrayobj){
                  this[collection].push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                
                }
                if (change.type === 'modified') {
                let arrayobj = this[collection].find(obj => obj.id == change.doc.id)
                let arrayobjindex = this[collection].indexOf(arrayobj)
                this[collection].splice(arrayobjindex, 1, {
                  ...change.doc.data(),
                  id: change.doc.id
                });
              }
              if (change.type === 'removed') {
                let arrayobj = this[collection].find(obj => obj.id == change.doc.id)
                let arrayobjindex = this[collection].indexOf(arrayobj)
                this[collection].splice(arrayobjindex, 1);
              }
            })
      })
    },
        GetMovement(){
            let wh = this.$store.state.WarehousesArray.find(obj => obj.id === this.$route.params.slug)
            //let called = false
            if(wh){
             let WarehouseActions = [
              {Display: 'Inbound',Prop: 'InboundTransaction', ArrayName: 'Inbound_Transactions'},
              {Display: 'Outbound',Prop: 'OutboundTransaction', ArrayName: 'Outbound_Transactions'},
            ]
              WarehouseActions.map(act => {
                //console.log('WarehouseActions',wh)
                if(this.userIsAdmin){
                  this.GetStockMovementHistoryOld(act.ArrayName)
                  this.$store.commit('SetCanViewMovementHistory',true)
                }
                else if(this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id]){
                  for(var prop in this.userLoggedIn.Warehouses[wh.id]) {
                    if (this.userLoggedIn.Warehouses[wh.id].hasOwnProperty(prop)) {
                      if(this.userLoggedIn.Warehouses[wh.id][prop] || this.userIsAdmin){
                        if(prop === 'Receiving'){
                         this.$store.commit('SetCanReceive',true)
                        }
                        this.$store.commit('SetCanViewMovementHistory',true)
                        this.GetStockMovementHistoryOld(act.ArrayName)
                        //called = true
                      }
                    }
                  }
                }
              }) 
            }
        },
        exportToCsv(filename,ExportHeaders,exportdata,forreimport) {
      console.log(filename,ExportHeaders,exportdata,forreimport)
      let tableheaders = JSON.parse(JSON.stringify(ExportHeaders))
      let dataarray = JSON.parse(JSON.stringify(exportdata))
      console.log(tableheaders,dataarray)
      let headers = tableheaders
     
      console.log(headers)
     
        if(forreimport === true){
          //headers.push('id')
          let idobj = { text: 'id', value: 'id'}
          headers.push(idobj)
          // headers.push('id')
          // headers.push(idobj)
        }
        const headerrows = [headers.map(header => {
        return header.text
      })]
        // console.log(this.ExportDataArray)
        // console.log(ExportHeaders)
        const datarows = dataarray.map(row => {
          row.csvdata =  headers.map(header => {
            
            let headervalue = ''
            let firstprop = header.value.split('.')[0]
            let secondprop = header.value.split('.')[1]
            if(firstprop === 'Related'){
              firstprop = 'Related'
              secondprop = 'DisplayName'
            }
            if(secondprop){
              
              if(row[firstprop]){
                if(row[firstprop][secondprop]){   
                  let thirdprop = row[firstprop][secondprop]
                              
                headervalue = row[firstprop][secondprop]
                }
              }
            }
            else{
              if(row[firstprop]){
                
              headervalue = row[firstprop]
              }
            }   
            if(header.text === 'Owner'){
              if(row.Owner){
                headervalue = row.Owner.Full_Name
              }
              
            }         
            return headervalue
          })
          return row
          }).map(row => {
            return row.csvdata
          })
        const rows = headerrows.concat(datarows)
        

        let csvContent = "data:text/csv;charset=utf-8," 
            + rows.map(e => e.join(",")).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", filename+".csv");
            document.body.appendChild(link); // Required for FF

            link.click();
        },
    DownloadMovementTransactions(){
      let movementtype = ''
      let headers = []
      let data = []
      if(this.MovementTab === 0){
        movementtype = 'Inbound_Transactions'
        headers = this.InboundTransactionsHeaders
        data = this.InboundTransactionsCompleted
      }
      else{
        movementtype = 'Outbound_Transactions'
        headers = this.OutboundTransactionsHeaders
        data = this.OutboundTransactionsCompleted
      }
      this.exportToCsv(this.RecordData.Item_Name+' - '+this.WarehouseName+' - '+movementtype,headers,data,true)
    },
    }
}
</script>

<style>

</style>


