
<template>
<v-main class="transparent">
<v-dialog v-model="BUSecurityRoledialog" fullscreen persistent>
    <v-card width="100%" height="100%">
      <!-- <v-btn text @click="saveRole()">Save</v-btn> -->
      <v-btn text @click="closeSecurityRoleDialog()">Close</v-btn>
      <v-card-title>{{SecurityRoleIndex === -1 ? 'New Role Details' : 'Configure the Role'}}</v-card-title>
      <v-card-text>
          <v-text-field disabled label="Name" v-model="editedRole.DisplayName"></v-text-field>
          <v-switch @click="UpdateRoleasGuestSafe(editedRole)" label="Is Guest Safe" v-model="editedRole.IsGuestSafe"></v-switch>
          <v-textarea label="Description" v-model="editedRole.Description"></v-textarea>
      </v-card-text>
       <v-card-title class="mx-3" style="padding:0;" v-if="SecurityRoleIndex > -1">
        Tables
      </v-card-title>
       <v-card-text v-if="SecurityRoleIndex > -1">
         <v-list dense>
               <v-list-item>
                <v-list-item-avatar>
                <v-icon>mdi-database</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                  
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
                 <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="editedRole.Builds.length === 0">No Tables tied to this Security Role
            </span>
         <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="editedRole.Builds"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                   <v-list-item >
                   <v-list-item-avatar>
                    <v-icon>{{item.Table_Icon}}</v-icon>
                  </v-list-item-avatar>
                   <v-list-item-content>
                      {{item.DisplayName}}
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <!-- <v-checkbox dense readonly v-model="item.createBoolean"></v-checkbox> -->
                      <v-select class="tinyinput" :disabled="!item.createBoolean" dense readonly v-model="item.createUnitConfig" :items="['All','UnitDown','UserUnit','Owner']"></v-select>
                      <span style="font-size: 12px;" v-if="!item.createBoolean" class="tooltiptext">
                        Role not permitted to Create {{item.DisplayName}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <!-- <v-checkbox dense readonly v-model="item.getBoolean"></v-checkbox> -->
                      <v-select class="tinyinput" :disabled="!item.getBoolean" dense readonly v-model="item.getUnitConfig" :items="['All','UnitDown','UserUnit','Owner']"></v-select>
                      <span style="font-size: 12px;" v-if="!item.getBoolean" class="tooltiptext">
                        Role not permitted to Get {{item.DisplayName}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <!-- <v-checkbox readonly v-model="item.listBoolean"></v-checkbox> -->
                      <v-select class="tinyinput" :disabled="!item.listBoolean" readonly v-model="item.listUnitConfig" :items="['All','UnitDown','UserUnit','Owner']"></v-select>
                      <span style="font-size: 12px;" v-if="!item.listBoolean" class="tooltiptext">
                        Role not permitted to List {{item.DisplayName}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <!-- <v-checkbox dense readonly v-model="item.updateBoolean"></v-checkbox> -->
                      <v-select class="tinyinput" :disabled="!item.updateBoolean" dense readonly v-model="item.updateUnitConfig" :items="['All','UnitDown','UserUnit','Owner']"></v-select>
                      <span style="font-size: 12px;" v-if="!item.updateBoolean" class="tooltiptext">
                        Role not permitted to Update {{item.DisplayName}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <!-- <v-checkbox dense readonly v-model="item.deleteBoolean"></v-checkbox> -->
                      <v-select class="tinyinput" :disabled="!item.deleteBoolean" dense readonly v-model="item.deleteUnitConfig" :items="['All','UnitDown','UserUnit','Owner']"></v-select>
                      <span style="font-size: 12px;" v-if="!item.deleteBoolean" class="tooltiptext">
                        Role not permitted to Delete {{item.DisplayName}}
                       </span>
                     </v-list-item-content>
                   </v-list-item>
                  </template>
              </v-virtual-scroll>
                 </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-title class="mx-3" style="padding:0;" v-if="SecurityRoleIndex > -1">
        Assigned Users
      </v-card-title>
        <v-card-text v-if="SecurityRoleIndex > -1">
         
            <v-list width="100%"  dense>
               <v-list-item v-if="ClientAppUsersbyRole.length > 0">
                <v-list-item-avatar>
                <v-icon small>mdi-tag-faces</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Full Name</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Business Unit<v-icon small>mdi-office-building</v-icon></strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Email<v-icon small>mdi-email</v-icon></strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Total Roles<v-icon small>mdi-counter</v-icon></strong>
                   </v-list-item-content>
                    <v-list-item-action>
                   <strong>View User</strong>
                    </v-list-item-action>
                 </v-list-item>
                  <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="ClientAppUsersbyRole.length === 0">No Users are assigned this Role
            </span>
          <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ClientAppUsersbyRole"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                    <v-list-item >
                <v-dialog max-width="400">
                        <template v-slot:activator="{ on }">
                      <v-list-item-avatar v-on="on" style="cursor: pointer;" size="30">
                        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"></v-img>
                        <v-img :src="item.Profile_Photo"  v-if="item.Profile_Photo"></v-img>
                      </v-list-item-avatar>
                        </template>
                    <v-card width="100%" height="100%">
                      <v-card-title>{{item.Full_Name}} Roles</v-card-title>
                      <v-card-subtitle v-if="item.IsSystemAdmin">System Admin does not require any roles</v-card-subtitle>
                      <v-card-text>
                        <v-select disabled v-model="item.rolesarrayQuery" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                      </v-card-text>
                      </v-card>
                    </v-dialog>
                  
                <v-list-item-content>
                  
                  <v-list-item-title>
                  {{item.Full_Name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Business_Unit.Name}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Email}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.rolesarrayQuery.length}}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn :to="'/User/'+item.id" x-small>view</v-btn>
                </v-list-item-action>
              </v-list-item>
                  </template>
              </v-virtual-scroll>
               </v-list-item>
            </v-list>
         
        </v-card-text>
      </v-card>
    </v-dialog>
    
    <v-dialog v-model="RMSecurityRoledialog" fullscreen persistent>
    <v-card width="100%" height="100%">
      
      <!-- <v-btn text @click="saveRole()">Save</v-btn> -->
        <v-btn text @click="closeSecurityRoleDialog()">Close</v-btn>
      <v-card-title>{{SecurityRoleIndex === -1 ? 'New Role Details' : 'Configure the Role'}}</v-card-title>
      <v-card-text>
        <v-text-field label="Name" v-model="editedRole.DisplayName"></v-text-field>
        <v-textarea label="Description" v-model="editedRole.Description"></v-textarea>
      </v-card-text>
      <v-card-text v-if="SecurityRoleIndex > -1">
         <v-list dense>
               <v-list-item>
                <v-list-item-avatar>
                <v-icon>mdi-database</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                  
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
                 <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="RMRoleBuildsbyeditedRole.length === 0">No Tables tied to this Security Role
            </span>
         <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="RMRoleBuildsbyeditedRole"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                   <v-list-item >
                   <v-list-item-avatar>
                    <v-icon>{{item.Table_Icon}}</v-icon>
                  </v-list-item-avatar>
                   <v-list-item-content>
                     <v-list-item-title>
                            {{item.DisplayName}}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{item.BuildDisplayName}}
                          </v-list-item-subtitle>
                     </v-list-item-content>
                      <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.CreateisCustom" readonly v-model="item.CustomcreateBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.CreateisCustom" class="tooltiptext">
                        Access to Create {{item.DisplayName}}  set to {{item.CreatePermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.GetisCustom" readonly v-model="item.CustomgetBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.GetisCustom" class="tooltiptext">
                        Access to Get {{item.DisplayName}}  set to {{item.GetPermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.ListisCustom" readonly v-model="item.CustomlistBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.ListisCustom" class="tooltiptext">
                        Access to List {{item.DisplayName}}  set to {{item.ListPermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.EditisCustom" readonly v-model="item.CustomupdateBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.EditisCustom" class="tooltiptext">
                         Access to Update {{item.DisplayName}}  set to {{item.UpdatePermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.DeleteisCustom" readonly v-model="item.CustomdeleteBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.DeleteisCustom" class="tooltiptext">
                        Access to Delete {{item.DisplayName}} set to {{item.DeletePermission}}
                       </span>
                     </v-list-item-content>
                   </v-list-item>
                  </template>
              </v-virtual-scroll>
                 </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-title class="mx-3" style="padding:0;" v-if="SecurityRoleIndex > -1">
        Assigned Users
      </v-card-title>
        <v-card-text v-if="SecurityRoleIndex > -1">
         
            <v-list width="100%"  dense>
               <v-list-item v-if="ClientAppUsersbyRole.length > 0">
                <v-list-item-avatar>
                <v-icon small>mdi-tag-faces</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Full Name</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Business Unit<v-icon small>mdi-office-building</v-icon></strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Email<v-icon small>mdi-email</v-icon></strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Total Roles<v-icon small>mdi-counter</v-icon></strong>
                   </v-list-item-content>
                    <v-list-item-action>
                   <strong>View User</strong>
                    </v-list-item-action>
                 </v-list-item>
                  <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="ClientAppUsersbyRole.length === 0">No Users are assigned this Role
            </span>
          <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ClientAppUsersbyRole"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                    <v-list-item >
                <v-dialog max-width="400">
                        <template v-slot:activator="{ on }">
                      <v-list-item-avatar v-on="on" style="cursor: pointer;" size="30">
                        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"></v-img>
                        <v-img :src="item.Profile_Photo"  v-if="item.Profile_Photo"></v-img>
                      </v-list-item-avatar>
                        </template>
                    <v-card width="100%" height="100%">
                      <v-card-title>{{item.Full_Name}} Roles</v-card-title>
                      <v-card-subtitle v-if="item.IsSystemAdmin">System Admin does not require any roles</v-card-subtitle>
                      <v-card-text>
                        <v-select disabled v-model="item.rolesarrayQuery" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                      </v-card-text>
                      </v-card>
                    </v-dialog>
                  
                <v-list-item-content>
                  
                  <v-list-item-title>
                  {{item.Full_Name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Business_Unit.Name}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Email}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.rolesarrayQuery.length}}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn :to="'/User/'+item.id" x-small>view</v-btn>
                </v-list-item-action>
              </v-list-item>
                  </template>
              </v-virtual-scroll>
               </v-list-item>
            </v-list>
         
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="CustomSecurityRoledialog" fullscreen persistent>
    <v-card width="100%" height="100%">
     <v-card-title>{{SecurityRoleIndex === -1 ? 'New Role Details' : 'Configure the Role'}}</v-card-title><br>
     <v-card-text>
      <v-text-field label="Name" v-model="editedRole.DisplayName"></v-text-field>
      <v-textarea label="Description" v-model="editedRole.Description"></v-textarea>
     </v-card-text>
     <v-card-actions>
      <v-btn v-if="SecurityRoleIndex === -1" text @click="saveRole()">Save</v-btn>
      <v-btn text @click="closeSecurityRoleDialog()">Close</v-btn>        
     </v-card-actions>
      <v-card-title class="mx-3" style="padding:0;" v-if="SecurityRoleIndex > -1">
        Tables
      </v-card-title>
        <v-card-text v-if="SecurityRoleIndex > -1">
         <v-list dense>
               <v-list-item>
                <v-list-item-avatar>
                <v-icon>mdi-database</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                  
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
                 <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="editedRole.Builds.length === 0">No Tables tied to this Security Role
            </span>
         <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="editedRole.Builds"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                   <v-list-item >
                   <v-list-item-avatar>
                    <v-icon>{{item.Table_Icon}}</v-icon>
                  </v-list-item-avatar>
                   <v-list-item-content>
                      {{item.DisplayName}}
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.CreateisCustom" readonly v-model="item.CustomcreateBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.CreateisCustom" class="tooltiptext">
                        Access to Create {{item.DisplayName}}  set to {{item.CreatePermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.GetisCustom" readonly v-model="item.CustomgetBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.GetisCustom" class="tooltiptext">
                        Access to Get {{item.DisplayName}}  set to {{item.GetPermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.ListisCustom" readonly v-model="item.CustomlistBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.ListisCustom" class="tooltiptext">
                        Access to List {{item.DisplayName}}  set to {{item.ListPermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.EditisCustom" readonly v-model="item.CustomupdateBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.EditisCustom" class="tooltiptext">
                         Access to Update {{item.DisplayName}}  set to {{item.UpdatePermission}}
                       </span>
                     </v-list-item-content>
                     <v-list-item-content class="hastooltip">
                      <v-checkbox dense :disabled="!item.DeleteisCustom" readonly v-model="item.CustomdeleteBoolean"></v-checkbox>
                      <span style="font-size: 12px;" v-if="!item.DeleteisCustom" class="tooltiptext">
                        Access to Delete {{item.DisplayName}} set to {{item.DeletePermission}}
                       </span>
                     </v-list-item-content>
                   </v-list-item>
                  </template>
              </v-virtual-scroll>
                 </v-list-item>
        </v-list>
      </v-card-text>
       <v-card-title class="mx-3" style="padding:0;" v-if="SecurityRoleIndex > -1">
        Assigned Users
      </v-card-title>
        <v-card-text v-if="SecurityRoleIndex > -1">
         
            <v-list width="100%"  dense>
               <v-list-item v-if="ClientAppUsersbyRole.length > 0">
                <v-list-item-avatar>
                <v-icon small>mdi-tag-faces</v-icon>
              </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Full Name</strong>
                   </v-list-item-content>
                    <v-list-item-content>
                   <strong>Business Unit<v-icon small>mdi-office-building</v-icon></strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Email<v-icon small>mdi-email</v-icon></strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Total Roles<v-icon small>mdi-counter</v-icon></strong>
                   </v-list-item-content>
                    <v-list-item-action>
                   <strong>View User</strong>
                    </v-list-item-action>
                 </v-list-item>
                  <v-list-item class="background" style="padding:0;">
            <span style="padding-left:10px;" v-if="ClientAppUsersbyRole.length === 0">No Users are assigned this Role
            </span>
          <v-virtual-scroll 
              style="overflow-x: hidden;padding:4px;"
                  class="transparent"
                    :items="ClientAppUsersbyRole"
                    :item-height="35"
                    :height="140"
                  >
                  <template v-slot:default="{ item }">
                    <v-list-item >
                <v-dialog max-width="400">
                        <template v-slot:activator="{ on }">
                      <v-list-item-avatar v-on="on" style="cursor: pointer;" size="30">
                        <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"></v-img>
                        <v-img :src="item.Profile_Photo"  v-if="item.Profile_Photo"></v-img>
                      </v-list-item-avatar>
                        </template>
                    <v-card width="100%" height="100%">
                      <v-card-title>{{item.Full_Name}} Roles</v-card-title>
                      <v-card-subtitle v-if="item.IsSystemAdmin">System Admin does not require any roles</v-card-subtitle>
                      <v-card-text>
                        <v-select disabled v-model="item.rolesarrayQuery" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                      </v-card-text>
                      </v-card>
                    </v-dialog>
                  
                <v-list-item-content>
                  
                  <v-list-item-title>
                  {{item.Full_Name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Business_Unit.Name}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.Email}}
                </v-list-item-content>
                <v-list-item-content>
                 {{item.rolesarrayQuery.length}}
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn :to="'/User/'+item.id" x-small>view</v-btn>
                </v-list-item-action>
              </v-list-item>
                  </template>
              </v-virtual-scroll>
               </v-list-item>
            </v-list>
         
        </v-card-text>
      </v-card>
    </v-dialog>
  <!-- <v-flex xl4 lg4 md4 sm4 xs4 class="justify-center"> -->
<v-navigation-drawer style="margin-top: 65px;" src="@/assets/QuoteBG2.jpeg" v-model="drawer" :mini-variant.sync="mini" permanent app right width="400">
      <v-card tile class="transparent mx-3" flat height="100%" width="100%" :dark="AppisDarkMode">
                    <v-card-title>Security Validation
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        @click.stop="mini = !mini"
                      >
                        <v-icon>mdi-chevron-left</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-actions v-if="!mini">
                       <v-icon @click="UpdateSystemSecurityRoles()" x-large class="soloactionicon" color="warning">mdi-security-network</v-icon><v-icon  class="soloactionicon" @click="UpdateUserSecurityRoles()" x-large color="black">mdi-tag-faces</v-icon>
                    </v-card-actions>
                     <SecurityRulesUpdateComponent @ActivateSnackbar="ActivateSnackbar" :System="System" :SystemEntities="SystemEntities" :CategoricalNavlists="CategoricalNavlists"
                      />
                    <!-- $store.state {{$store.state}} -->
                     <v-autocomplete clearable :items="ClientAppBU" item-text="Name" label="Select BU" return-object
                    v-model="selectBU"
                    />
                    <v-autocomplete clearable :items="AppUsers" item-text="Full_Name" label="Select User" return-object
                    v-model="selectUser"
                    />
                    <v-list v-if="!selectUser">
                      <v-layout fill-height row justify-center align-center>
                        <div class="BoldBuilderGradient" style="position: absolute;width: 80px;height: 80px;border-radius:50px;animation: spin 4   s linear infinite;">
                        </div>
                            <v-img height="70" style="position: absolute;opacity: 0.3;" contain  src="@/assets/RAFullLogo.png">
                            </v-img>
                    <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
                            :value="OverviewProgress" :size="100" :width="12" class="mediumoverline" :color="OverviewReadinessProgressColor">
                            <v-card-title :class="OverviewReadinessTitleColor+'--text mediumoverline'" style="text-shadow: 4px 4px rgba(0,0,0,0.16);position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;font-weight:500;">{{OverviewReadiness}}</v-card-title>
                        
                        <v-icon :color="OverviewReadinessTitleColor" size="50" style="text-shadow: 4px 4px rgba(0,0,0,0.16);margin-top:40px;animation: simplepulsing 1.5s infinite;">
                            {{OverviewReadinessIcon}}
                        </v-icon>
                        </v-progress-circular>
                        </v-layout>
                    </v-list>
                   
                    <v-list dense v-if="selectUser">
                     
                      <v-list-item class="justify-space-between" two-line>
                        <v-list-item-avatar size="135">
                        <!-- <v-avatar
                            color="grey"
                            size="135"                      
                          > -->
                          <v-img src="@/assets/BlankProfilePic.png" v-if="!selectUser.Profile_Photo"></v-img>
                          <v-img :src="selectUser.Profile_Photo"  v-if="selectUser.Profile_Photo"></v-img>
                        
                          <!-- <v-btn :href="Profile_Photo" v-if="!selectedprofilephotoFile" target="_new" color="#eb371b"  fab dark icon><v-icon>mdi-eye</v-icon></v-btn> -->
                        <!-- </v-avatar> -->
                        </v-list-item-avatar>
                        <v-list-item-content>
                        <v-card-title>
                        Full Name - {{selectUser.Full_Name}}
                        </v-card-title>
                        <v-card-subtitle>
                        Business Unit - {{selectUser.Business_Unit.Name}}
                        </v-card-subtitle>
                          <v-card-subtitle>
                        Email - {{selectUser.Email}}
                        </v-card-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <!-- <v-list-item two-line>
                        
                      </v-list-item> -->
                    </v-list>
                    <v-list>
                       <v-list-item v-if="!selectedTable || selectedTable && selectedTable.Security_Structure.Name === 'Business Unit'">
                          <v-list-item-content >
                            <v-list-item-title>Security Type - BU Roles</v-list-item-title> 
                            <v-list-item-subtitle>
                              Tables: {{BUTables.length}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Cabinets: {{BUTableswithCabinets.length}}
                            </v-list-item-subtitle>                            
                            <v-list-item-subtitle>
                              Roles: {{BUSecurityRoles.length}}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                             <v-autocomplete dense :readonly="ActiveEntity" :clearable="!ActiveEntity" @change="UpdateRoleMode('Business Unit')" :items="BUTables" item-text="DisplayName" label="Select Table" return-object
                              v-model="selectedTable"
                              >
                              <template v-slot:item="{ item }">
                                <v-list two-line>
                                 <v-list-item-title><v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}</v-list-item-title> 
                           <v-list-item-subtitle v-if="item.DocumentRegisters.length+item.Galleries.length > 0">
                               <span class="caption">{{item.DocumentRegisters.length+item.Galleries.length}} Cabinet<span v-if="item.DocumentRegisters.length+item.Galleries.length > 1">s</span></span>
                            </v-list-item-subtitle> 
                                </v-list>                           
                            </template>
                            <template v-slot:selection="{ item }">
                                 <v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}
                            </template>
                             </v-autocomplete>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="!selectedTable || selectedTable && selectedTable.Security_Structure.Name === 'Custom Roles'">
                          <v-list-item-content >
                            <v-list-item-title>Security Type - Custom Roles</v-list-item-title> 
                            <v-list-item-subtitle>
                              Tables: {{CustomRoleTables.length}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Cabinets: {{CustomRoleTableswithCabinets.length}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              Roles: {{CustomSecurityRoles.length}}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                           <v-list-item-content>
                             <v-autocomplete dense clearable @change="UpdateRoleMode('Custom Roles')" :items="CustomRoleTables" item-text="DisplayName" label="Select Table" return-object
                              v-model="selectedTable"
                              >
                              <template v-slot:item="{ item }">
                                <v-list two-line>
                                 <v-list-item-title><v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}</v-list-item-title> 
                            <v-list-item-subtitle v-if="item.DocumentRegisters.length+item.Galleries.length > 0">
                               <span class="caption">{{item.DocumentRegisters.length+item.Galleries.length}} Cabinet<span v-if="item.DocumentRegisters.length+item.Galleries.length > 1">s</span></span>
                            </v-list-item-subtitle>   
                                </v-list>                           
                            </template>
                            <template v-slot:selection="{ item }">
                                 <v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}
                            </template>
                             </v-autocomplete>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list v-if="selectedTableCabinets.length > 0">
                          This item has Cabinets!
                          <v-autocomplete dense clearable @change="UpdateRoleMode('RM Security Roles')" :items="selectedTableCabinets" item-text="DisplayName" label="Select Cabinet" return-object
                              v-model="selectedCabinet"
                              >
                              <template v-slot:item="{ item }">
                               <v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}
                            </template>
                            <template v-slot:selection="{ item }">
                                 <v-icon>{{item.Table_Icon}}</v-icon> {{item.DisplayName}}
                            </template>
                             </v-autocomplete>
                        </v-list>
                        <v-list-item v-if="selectedTable">
                          <v-list dense width="100%">
                            <v-list-item >
                              <v-list-item-content>
                            <v-btn @click="Refresh()">
                                Refresh
                              </v-btn>
                              </v-list-item-content>
                              <v-list-item-content>
                              <v-text-field clearable class="mx-3" v-model="UserSearch" label="Search" />
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item style="font-size:12px;text-align: center;">
                               
                              <v-list-item-avatar size="30">
                                  <v-img src="@/assets/BlankProfilePic.png"></v-img>
                                  
                                </v-list-item-avatar>
                              <v-list-item-content>
                                <b>User</b>
                              </v-list-item-content>
                              <v-list-item-content>
                                <b>Create</b>
                              </v-list-item-content>
                              <v-list-item-content>
                                <b>Get</b>
                                </v-list-item-content>
                              <v-list-item-content>
                                <b>List</b>
                              </v-list-item-content>
                              <v-list-item-content>
                                <b>Update</b>
                              </v-list-item-content>
                              <v-list-item-content>
                                <b>Delete</b>
                              </v-list-item-content>
                            </v-list-item>
                           
                            <v-virtual-scroll v-if="!selectedCabinet"
                            style="overflow-x: hidden;padding:4px;"
                                class="transparent"
                                  :items="SearchedClientAppUsersbyselectedBuild"
                                  :item-height="35"
                                  :height="140"
                                >
                                <template v-slot:default="{ item }">
                                  <v-list-item >
                              <v-dialog max-width="400">
                                 <template v-slot:activator="{ on }">
                                <v-list-item-avatar v-on="on" style="cursor: pointer;" size="30">
                                  <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"></v-img>
                                  <v-img :src="item.Profile_Photo"  v-if="item.Profile_Photo"></v-img>
                                </v-list-item-avatar>
                                 </template>
                              <v-card width="100%" height="100%">
                                <v-card-title>{{item.Full_Name}} Roles</v-card-title>
                                <v-card-subtitle v-if="item.IsSystemAdmin">System Admin does not require any roles</v-card-subtitle>
                                <v-card-text>
                                  <v-select :disabled="item.IsSystemAdmin" v-model="item.rolesarrayQuery" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                                </v-card-text>
                                </v-card>
                              </v-dialog>
                               
                              <v-list-item-content>
                               
                                <v-list-item-title>
                                {{item.Full_Name}}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanCreate ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanCreate? 'green' : 'red'">{{item.CanCreate? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanCreate,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanGet ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanGet? 'green' : 'red'">{{item.CanGet? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanGet,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanList ? `green--text hastooltip` : `grey--text`">
                               <v-icon :color="item.CanList? 'green' : 'red'">{{item.CanList? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanList,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanUpdate ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanUpdate? 'green' : 'red'">{{item.CanUpdate? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanUpdate,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanDelete ? `green--text hastooltip` : `grey--text`">
                               <v-icon :color="item.CanDelete? 'green' : 'red'">{{item.CanDelete? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanDelete,item)">
                                </span>
                              </v-list-item-content>
                            </v-list-item>
                                </template>
                            </v-virtual-scroll>
                             <v-virtual-scroll v-if="selectedCabinet"
                            style="overflow-x: hidden;padding:4px;"
                                class="transparent"
                                  :items="SearchedClientAppUsersbyselectedCabinet"
                                  :item-height="35"
                                  :height="140"
                                >
                                <template v-slot:default="{ item }">
                                  <v-list-item >
                              <v-dialog max-width="400">
                                 <template v-slot:activator="{ on }">
                                <v-list-item-avatar v-on="on" style="cursor: pointer;" size="30">
                                  <v-img src="@/assets/BlankProfilePic.png" v-if="!item.Profile_Photo"></v-img>
                                  <v-img :src="item.Profile_Photo"  v-if="item.Profile_Photo"></v-img>
                                </v-list-item-avatar>
                                 </template>
                              <v-card width="100%" height="100%">
                                <v-card-title>{{item.Full_Name}} Roles</v-card-title>
                                <v-card-subtitle v-if="item.IsSystemAdmin">System Admin does not require any roles</v-card-subtitle>
                                <v-card-text>
                                  <v-select :disabled="item.IsSystemAdmin" v-model="item.rolesarrayQuery" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                                </v-card-text>
                                </v-card>
                              </v-dialog>
                               
                              <v-list-item-content>
                               
                                <v-list-item-title>
                                {{item.Full_Name}}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanCreate ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanCreate? 'green' : 'red'">{{item.CanCreate? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanCreate,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanGet ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanGet? 'green' : 'red'">{{item.CanGet? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanGet,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanList ? `green--text hastooltip` : `grey--text`">
                               <v-icon :color="item.CanList? 'green' : 'red'">{{item.CanList? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanList,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanUpdate ? `green--text hastooltip` : `grey--text`">
                                <v-icon :color="item.CanUpdate? 'green' : 'red'">{{item.CanUpdate? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanUpdate,item)">
                                </span>
                              </v-list-item-content>
                              <v-list-item-content :class="item.CanDelete ? `green--text hastooltip` : `grey--text`">
                               <v-icon :color="item.CanDelete? 'green' : 'red'">{{item.CanDelete? 'mdi-check' : 'mdi-close'}}</v-icon>
                                <span class="tooltiptext" v-html="ReturnAccessLevel(item.CanDelete,item)">
                                </span>
                              </v-list-item-content>
                            </v-list-item>
                                </template>
                            </v-virtual-scroll>
                          </v-list>
                        </v-list-item>
                       
                    </v-list>
                    
              </v-card>
    </v-navigation-drawer>
  <!-- </v-flex> -->
  <!-- <v-layout class="justify-center fill-height"> -->
    
    <!-- <v-main> -->
    
    <v-card tile flat class="transparent" :style="ActiveEntity ? '' : 'padding-left:10px;padding-right:10px;margin-top:50px;'">
     <!-- <v-tabs>
       <v-tab>Table
       </v-tab>
       <v-tab-item> -->
         <v-list dense v-if="RoleMode === 'Business Unit'">
        <v-list-item >
          <v-list-item-content>
             {{selectedTable.DisplayName}}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-database</v-icon>
          </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                   <!-- <v-list-item-content>
                   <strong>Type</strong>
                   </v-list-item-content> -->
                   <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                   
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
        <v-list-item dense >
          <v-list-item-avatar>
             <v-icon>{{selectedTable.Table_Icon}}</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>
              {{selectedTable.DisplayName}}
              </v-list-item-content>
              <!-- <v-list-item-content>
              <v-icon v-if="selectedTable.Entity_Type" small color="blue">mdi-database</v-icon>
              </v-list-item-content> -->
                <v-list-item-content>
              Based on Role
              </v-list-item-content>
              <v-list-item-content>
              Based on Role                     
              </v-list-item-content>
              <v-list-item-content>
              Based on Role
              </v-list-item-content>
              <v-list-item-content>
              Based on Role
              </v-list-item-content>
              <v-list-item-content>
              Based on Role
              </v-list-item-content>
            </v-list-item>
      </v-list>
      <v-list dense v-if="RoleMode === 'RM Security Roles'">
        <v-list-item >
          <v-list-item-content>
             {{selectedTable.DisplayName}}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
           <v-icon>mdi-database</v-icon>
          </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                   <!-- <v-list-item-content>
                   <strong>Type</strong>
                   </v-list-item-content> -->
                   <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                   
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
        <v-list-item dense >
          <v-list-item-avatar>
            <v-icon>{{selectedTable.Table_Icon}}</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>
              {{selectedCabinet.DisplayName}}
              </v-list-item-content>
              <!-- <v-list-item-content>
              <v-icon v-if="selectedTable.Entity_Type" small color="blue">mdi-database</v-icon>
              </v-list-item-content> -->
                <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('CreatePermission',selectedCabinet,'CreateisCustom')" :items="RMRolePermissions" v-model="selectedCabinet.CreatePermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('GetPermission',selectedCabinet,'GetisCustom')" :items="RMRolePermissions" v-model="selectedCabinet.GetPermission"></v-select>                     
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('ListPermission',selectedCabinet,'ListisCustom')" :items="RMRolePermissions" v-model="selectedCabinet.ListPermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('UpdatePermission',selectedCabinet,'EditisCustom')" :items="RMRolePermissions" v-model="selectedCabinet.UpdatePermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('DeletePermission',selectedCabinet,'DeleteisCustom')" :items="RMRolePermissions" v-model="selectedCabinet.DeletePermission"></v-select>
              </v-list-item-content>
            </v-list-item>
      </v-list>
      <v-list dense v-if="RoleMode === 'Custom Roles'">
        <v-list-item >
          <v-list-item-content>
             {{selectedTable.DisplayName}}
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon>mdi-database</v-icon>
          </v-list-item-avatar>
                 <v-list-item-content>
                   <strong>Table</strong>
                   </v-list-item-content>
                   <!-- <v-list-item-content>
                   <strong>Type</strong>
                   </v-list-item-content> -->
                   <v-list-item-content>
                   <strong>Create</strong>
                   </v-list-item-content>
                 <v-list-item-content>
                   <strong>Get</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>List</strong>
                   </v-list-item-content>                   
                   <v-list-item-content>
                   <strong>Update</strong>
                   </v-list-item-content>
                   <v-list-item-content>
                   <strong>Delete</strong>
                   </v-list-item-content>
                 </v-list-item>
        <v-list-item dense >
           <v-list-item-avatar>
            <v-icon>{{selectedTable.Table_Icon}}</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>
              {{selectedTable.DisplayName}}
              </v-list-item-content>
              <!-- <v-list-item-content>
              <v-icon v-if="selectedTable.Entity_Type" small color="blue">mdi-database</v-icon>
              </v-list-item-content> -->
                <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('CreatePermission',selectedTable,'CreateisCustom')" :items="CustomRolePermissions" v-model="selectedTable.CreatePermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('GetPermission',selectedTable,'GetisCustom')" :items="CustomRolePermissions" v-model="selectedTable.GetPermission"></v-select>                     
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('ListPermission',selectedTable,'ListisCustom')" :items="CustomRolePermissions" v-model="selectedTable.ListPermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('UpdatePermission',selectedTable,'EditisCustom')" :items="CustomRolePermissions" v-model="selectedTable.UpdatePermission"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-select @change="UpdateCustomBoolean('DeletePermission',selectedTable,'DeleteisCustom')" :items="CustomRolePermissions" v-model="selectedTable.DeletePermission"></v-select>
              </v-list-item-content>
            </v-list-item>
      </v-list>
      
    <v-list dense v-if="RoleMode === 'Business Unit'">
        <v-btn @click="AddSecurityRoles('Business Unit')">Add Role</v-btn>
        <v-list-item v-for="role in ComputedBUSecurityRoles" :key="role.itemObjKey">
           <v-list-item-avatar>
           <v-icon @click="ActivateSecurityRoleEditdialog(role)">mdi-cogs</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>{{role.Name}}
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel" style="font-size:12px;" dense label="Role permits Create" v-model="GetActiveBuild(role,selectedTable).createBoolean"></v-checkbox>
                      <v-select dense v-model="GetActiveBuild(role,selectedTable).createUnitConfig" :items="BURoleTypes"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  style="font-size:12px;" dense label="Role permits Get" v-model="GetActiveBuild(role,selectedTable).getBoolean"></v-checkbox>
              <v-select dense v-model="GetActiveBuild(role,selectedTable).getUnitConfig" :items="BURoleTypes"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits List" v-model="GetActiveBuild(role,selectedTable).listBoolean"></v-checkbox>
                      <v-select dense v-model="GetActiveBuild(role,selectedTable).listUnitConfig" :items="BURoleTypes"></v-select>              
              </v-list-item-content>
              
              <v-list-item-content>
              <v-checkbox  class="tinylabel" dense label="Role permits Update" v-model="GetActiveBuild(role,selectedTable).updateBoolean"></v-checkbox>
                      <v-select dense v-model="GetActiveBuild(role,selectedTable).updateUnitConfig" :items="BURoleTypes"></v-select>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Delete" v-model="GetActiveBuild(role,selectedTable).deleteBoolean"></v-checkbox>
                      <v-select dense v-model="GetActiveBuild(role,selectedTable).deleteUnitConfig" :items="BURoleTypes"></v-select>
              </v-list-item-content>
            </v-list-item>
      </v-list>
    <v-list dense v-if="RoleMode === 'Custom Roles'">
        <v-btn @click="AddSecurityRoles('Custom Roles')">Add Role</v-btn>
        <v-list-item v-for="role in ComputedCustomSecurityRoles" :key="role.itemObjKey">
          <v-list-item-avatar>
           <v-icon @click="ActivateSecurityRoleEditdialog(role)">mdi-cogs</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>
              {{role.Name}}
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Create" :disabled="!GetActiveBuild(role,selectedTable).CreateisCustom" v-model="GetActiveBuild(role,selectedTable).CustomcreateBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Get" :disabled="!GetActiveBuild(role,selectedTable).GetisCustom" v-model="GetActiveBuild(role,selectedTable).CustomgetBoolean"></v-checkbox>                      
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits List" :disabled="!GetActiveBuild(role,selectedTable).ListisCustom" v-model="GetActiveBuild(role,selectedTable).CustomlistBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Update" :disabled="!GetActiveBuild(role,selectedTable).EditisCustom" v-model="GetActiveBuild(role,selectedTable).CustomupdateBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Delete" :disabled="!GetActiveBuild(role,selectedTable).DeleteisCustom" v-model="GetActiveBuild(role,selectedTable).CustomdeleteBoolean"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
      </v-list>
      <v-list dense v-if="RoleMode === 'RM Security Roles'">
        <v-btn @click="AddSecurityRoles('RM Security Roles')">Add Role</v-btn>
        <v-list-item v-for="role in ComputedRMSecurityRoles" :key="role.itemObjKey">
         <v-list-item-avatar>
           <v-icon @click="ActivateSecurityRoleEditdialog(role)">mdi-cogs</v-icon>
          </v-list-item-avatar>
            <v-list-item-content>
              {{role.Name}}
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Create" :disabled="GetActiveCabinet(role,selectedTable,selectedCabinet).CreatePermission !== 'Custom Role'" v-model="GetActiveCabinet(role,selectedTable,selectedCabinet).CustomcreateBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Get" :disabled="GetActiveCabinet(role,selectedTable,selectedCabinet).GetPermission !== 'Custom Role'" v-model="GetActiveCabinet(role,selectedTable,selectedCabinet).CustomgetBoolean"></v-checkbox>                      
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits List" :disabled="GetActiveCabinet(role,selectedTable,selectedCabinet).ListPermission !== 'Custom Role'" v-model="GetActiveCabinet(role,selectedTable,selectedCabinet).CustomlistBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Update" :disabled="GetActiveCabinet(role,selectedTable,selectedCabinet).UpdatePermission !== 'Custom Role'" v-model="GetActiveCabinet(role,selectedTable,selectedCabinet).CustomupdateBoolean"></v-checkbox>
              </v-list-item-content>
              <v-list-item-content>
              <v-checkbox class="tinylabel"  dense label="Role permits Delete" :disabled="GetActiveCabinet(role,selectedTable,selectedCabinet).DeletePermission !== 'Custom Role'" v-model="GetActiveCabinet(role,selectedTable,selectedCabinet).CustomdeleteBoolean"></v-checkbox>
              </v-list-item-content>
            </v-list-item>
      </v-list>
      
    </v-card>
    <!-- </v-main> -->
  <!-- </v-layout> -->
</v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import SecurityRulesUpdateComponent from '@/components/Database/SecurityRulesUpdateComponent';
export default {
  props: ['AppisDarkMode','UserisGuest','SystemEntities','System','RADB','CategoricalNavlists','ActiveEntity'],
  components: {InfoSheet,SecurityRulesUpdateComponent},
    data() {
        return {
          BURolesDefault: [
            {Name: 'AlllistRoles', BooleanProp: 'listBoolean', ConfigValue: 'All', ConfigProp: 'listUnitConfig'},
            {Name: 'UnitDownlistRoles', BooleanProp: 'listBoolean', ConfigValue: 'UnitDown', ConfigProp: 'listUnitConfig'},
            {Name: 'UserUnitlistRoles', BooleanProp: 'listBoolean', ConfigValue: 'UserUnit', ConfigProp: 'listUnitConfig'},
            {Name: 'OwnerlistRoles', BooleanProp: 'listBoolean', ConfigValue: 'Owner', ConfigProp: 'listUnitConfig'},
            {Name: 'AllgetRoles', BooleanProp: 'getBoolean', ConfigValue: 'All', ConfigProp: 'getUnitConfig'},
            {Name: 'UnitDowngetRoles', BooleanProp: 'getBoolean', ConfigValue: 'UnitDown', ConfigProp: 'getUnitConfig'},
            {Name: 'UserUnitgetRoles', BooleanProp: 'getBoolean', ConfigValue: 'UserUnit', ConfigProp: 'getUnitConfig'},
            {Name: 'OwnergetRoles', BooleanProp: 'getBoolean', ConfigValue: 'Owner', ConfigProp: 'getUnitConfig'},
            {Name: 'AllcreateRoles', BooleanProp: 'createBoolean', ConfigValue: 'All', ConfigProp: 'createUnitConfig'},
            {Name: 'UnitDowncreateRoles', BooleanProp: 'createBoolean', ConfigValue: 'UnitDown', ConfigProp: 'createUnitConfig'},
            {Name: 'UserUnitcreateRoles', BooleanProp: 'createBoolean', ConfigValue: 'UserUnit', ConfigProp: 'createUnitConfig'},
            {Name: 'OwnercreateRoles', BooleanProp: 'createBoolean', ConfigValue: 'Owner', ConfigProp: 'createUnitConfig'},
            {Name: 'AllupdateRoles', BooleanProp: 'updateBoolean', ConfigValue: 'All', ConfigProp: 'updateUnitConfig'},
            {Name: 'UnitDownupdateRoles', BooleanProp: 'updateBoolean', ConfigValue: 'UnitDown', ConfigProp: 'updateUnitConfig'},
            {Name: 'UserUnitupdateRoles', BooleanProp: 'updateBoolean', ConfigValue: 'UserUnit', ConfigProp: 'updateUnitConfig'},
            {Name: 'OwnerupdateRoles', BooleanProp: 'updateBoolean', ConfigValue: 'Owner', ConfigProp: 'updateUnitConfig'},
            {Name: 'AlldeleteRoles', BooleanProp: 'deleteBoolean', ConfigValue: 'All', ConfigProp: 'deleteUnitConfig'},
            {Name: 'UnitDowndeleteRoles', BooleanProp: 'deleteBoolean', ConfigValue: 'UnitDown', ConfigProp: 'deleteUnitConfig'},
            {Name: 'UserUnitdeleteRoles', BooleanProp: 'deleteBoolean', ConfigValue: 'UserUnit', ConfigProp: 'deleteUnitConfig'},
            {Name: 'OwnerdeleteRoles', BooleanProp: 'deleteBoolean', ConfigValue: 'Owner', ConfigProp: 'deleteUnitConfig'},
          ],
          UserSearch: '',
          BURoleTypes: ['All','UnitDown','UserUnit','Owner'],
          drawer: true,
          mini: false,
          ActiveUser: '',
          UserRolesDialog: false,
          RMRolePermissions: [
            'Admin',
            'Inherited',
            'Custom Role',
          ],
          CustomRolePermissions: [
            'Public',
            'Signed In',
            'Custom Role',
            'Admin'
          ],
          elmnt:  {	
                Width: 300,						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,								
            },	
            selectedCabinet: '',
            selectedTable: '',
            clientDB: '',
            ClientAppBU: [],
            selectBU: '',
            selectUser: '',
            ClientAppUsers: [],
            HasRMRoles: false,
            RoleMode: '',
            BUSecurityRoledialog: false,
            CustomSecurityRoledialog: false,
            RMSecurityRoledialog: false,
            
            editedRole: {Name: '', DisplayName: '', Description: '', Builds: []},
            defaultRole: {Name: '', DisplayName: '',Description: '', Builds: []},
            SecurityRoleIndex: -1,
            
            RMSecurityRoles: [],
            BUSecurityRoles: [],
            CustomSecurityRoles: [
              // {id: 'Guest', Name: 'Guest',DisplayName: 'Guest',Description: 'Default Guest Security Role that can not be modified',ReadOnly: true,Builds: []}
            ],            
        }
    },
    computed: {
      RMRoleBuildsbyeditedRole(){
        let array = []
        if(this.editedRole && this.editedRole.Builds && this.RMSecurityRoledialog){
          array = JSON.parse(JSON.stringify(this.editedRole.Builds))
          array = array.map(build => {
            return build.Elements.map(elmnt => {
              elmnt.BuildDisplayName = build.DisplayName
              return elmnt
            })
          }).flat()
        }
        return array
      },
      ClientAppUsersbyRole(){
        if(this.editedRole){
          return this.ClientAppUsers.filter(user => {
            return user.rolesarrayQuery.includes(this.editedRole.Name)
          })
        }
        else{
          return []
        }
      },
      selectedTableCabinets(){
         if(this.selectedTable){
           let array = this.selectedTable.DocumentRegisters.concat(this.selectedTable.Galleries)
           return array
         }
         else{
           return []
         }
      },
      ConfigDB(){
        return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID)
      },
      AllSecurityRoles(){
        return this.CustomSecurityRoles.concat(this.BUSecurityRoles,this.RMSecurityRoles)
      },
      SaveBUTables(){
        let tables = JSON.parse(JSON.stringify(this.BUTables))
        tables.map(table => {
          this.BURolesDefault.map(prop => {
            table[prop.Name] = this.ComputedBUSecurityRoles.filter(role => {
            let oncheck = role.Builds.find(obj => obj.id === table.id && obj[prop.BooleanProp] && obj[prop.ConfigProp] === prop.ConfigValue)
            return oncheck
          }).map(role => {return role.Name})
          })
          return table
        })
        return tables
      },
      SaveCustomRoleTables(){
        let tables = JSON.parse(JSON.stringify(this.CustomRoleTables))
        let customroletypes = [{Name: 'CustomCreateRoles',Prop: 'CreateisCustom',Bool: 'CustomcreateBoolean'},{Name: 'CustomGetRoles',Prop: 'GetisCustom',Bool: 'CustomgetBoolean'},{Name: 'CustomListRoles',Prop: 'ListisCustom',Bool: 'CustomlistBoolean'},{Name: 'CustomUpdateRoles',Prop: 'EditisCustom',Bool: 'CustomupdateBoolean'},{Name: 'CustomDeleteRoles',Prop: 'DeleteisCustom',Bool: 'CustomdeleteBoolean'}]
        
          tables.map(table => {
            customroletypes.map(prop => {
            table[prop.Name] = this.ComputedCustomSecurityRoles.filter(role => {
            let oncheck = role.Builds.find(obj => obj.id === table.id && obj[prop.Bool])
            return oncheck
          }).map(role => {return role.Name})          
          })
          return table
        })        
        return tables
      },
      SaveRMCabinets(){
        let cabinets = JSON.parse(JSON.stringify(this.AllTableswithCabinets))
        let roles = JSON.parse(JSON.stringify(this.SaveAllSecurityRoles.filter(role => {return role.RoleConfigCollection === 'RMsecurityroles'})))
        let customroletypes = [{Name: 'CustomCreateRoles',Prop: 'CreateisCustom',Bool: 'CustomcreateBoolean'},{Name: 'CustomGetRoles',Prop: 'GetisCustom',Bool: 'CustomgetBoolean'},{Name: 'CustomListRoles',Prop: 'ListisCustom',Bool: 'CustomlistBoolean'},{Name: 'CustomUpdateRoles',Prop: 'EditisCustom',Bool: 'CustomupdateBoolean'},{Name: 'CustomDeleteRoles',Prop: 'DeleteisCustom',Bool: 'CustomdeleteBoolean'}]
        
          cabinets.map(cabinet => {
            //console.log('cabinet.buildid',cabinet.buildid)
            customroletypes.map(prop => {              
            cabinet[prop.Name] = roles.map(role => {
            let oncheck = role.Builds.find(obj => obj.id === cabinet.buildid)
            if(oncheck){
              role.BuildMatch = oncheck
            }           
            
            return role
          })
          .filter(role => {
            return role.BuildMatch
          })
          .filter(role => {
            if(role.id === 'vaIQvOGHSUc7s2G41V7q' && cabinet.id === 'Road_Hazards'){
              //console.log('role.BuildMatch',role.id,cabinet.id,role.BuildMatch)
            }
              let elmntcheck = role.BuildMatch.Elements.find(obj => obj.id === cabinet.id && obj[prop.Bool])
              return elmntcheck
            })
          .map(role => {return role.Name})          
          })
          return cabinets
        })        
        return cabinets
      },
      SaveAllSecurityRoles(){     
        let customroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
        customroles.map(role => {
          role.RoleConfigCollection = 'Customsecurityroles'
          return role
        })
        let buroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
        buroles.map(role => {
          role.RoleConfigCollection = 'BUsecurityroles'
          return role
        })
        let rmroles = JSON.parse(JSON.stringify(this.RMSecurityRoles))
        rmroles.map(role => {
          role.RoleConfigCollection = 'RMsecurityroles'
          return role
        }).map(role => {
          role.Builds = role.Builds.filter(build => {
            let createpermitted = build.Elements.find(obj => obj.CustomcreateBoolean === true)
            let getpermitted = build.Elements.find(obj => obj.CustomgetBoolean === true)
            let listpermitted = build.Elements.find(obj => obj.CustomlistBoolean === true)
            let updatepermitted = build.Elements.find(obj => obj.CustomupdateBoolean === true)
            let deletepermitted = build.Elements.find(obj => obj.CustomdeleteBoolean === true)
            return createpermitted || getpermitted || listpermitted || updatepermitted || deletepermitted
          })
          return role
        })
        return customroles.concat(buroles,rmroles)
      },
      ComputedBUSecurityRoles(){
        return this.BUSecurityRoles.map(role => {
          if(this.selectedTable){
            let oncheck = role.Builds.find(obj => obj.id === this.selectedTable.id)
            
              let buildobj = {
                id: this.selectedTable.id,
                Primary_Field_Name: this.selectedTable.Primary_Field_Name,
                DisplayName: this.selectedTable.DisplayName,
                Name: this.selectedTable.Name,
                Table_Icon: this.selectedTable.Table_Icon,
                SingleName: this.selectedTable.SingleName,
                Security_Structure: this.selectedTable.Security_Structure,
                // CreatePermission: this.selectedTable.CreatePermission,
                // GetPermission: this.selectedTable.GetPermission,
                // ListPermission: this.selectedTable.ListPermission,
                // UpdatePermission: this.selectedTable.UpdatePermission,
                // DeletePermission: this.selectedTable.DeletePermission,
              }
              if(this.selectedTable.datasetsecuritystructure){
                buildobj.datasetsecuritystructure = this.selectedTable.datasetsecuritystructure
              }
              if(oncheck){
                if(oncheck.createUnitConfig){
                buildobj.createUnitConfig = oncheck.createUnitConfig
                }
                if(oncheck.createBoolean){
                  buildobj.createBoolean = oncheck.createBoolean
                }              
                if(oncheck.getBoolean){
                  buildobj.getBoolean = oncheck.getBoolean
                }
                if(oncheck.getUnitConfig){
                  buildobj.getUnitConfig = oncheck.getUnitConfig
                }              
                if(oncheck.listBoolean){
                  buildobj.listBoolean = oncheck.listBoolean
                }
                if(oncheck.listUnitConfig){
                  buildobj.listUnitConfig = oncheck.listUnitConfig
                }
                if(oncheck.updateBoolean){
                  buildobj.updateBoolean = oncheck.updateBoolean
                }
                if(oncheck.updateUnitConfig){
                  buildobj.updateUnitConfig = oncheck.updateUnitConfig
                }
                if(oncheck.deleteBoolean){
                  buildobj.deleteBoolean = oncheck.deleteBoolean
                }
                if(oncheck.deleteUnitConfig){
                  buildobj.deleteUnitConfig = oncheck.deleteUnitConfig
                }
              }
              
            if(!oncheck){
              role.Builds.push(buildobj)
            } 
            else{
              if(oncheck.Elements){
                buildobj.Elements = oncheck.Elements
              }
              
              let index = role.Builds.indexOf(oncheck)
              role.Builds[index] = buildobj
            }    
          }
          return role
        })
      },
      ComputedRMSecurityRoles(){
        return this.RMSecurityRoles.map(role => {
          if(this.selectedTable){
            let oncheck = role.Builds.find(obj => obj.id === this.selectedTable.id)
            
              let buildobj = {
                id: this.selectedTable.id,
                Primary_Field_Name: this.selectedTable.Primary_Field_Name,
                DisplayName: this.selectedTable.DisplayName,
                Name: this.selectedTable.Name,
                Table_Icon: this.selectedTable.Table_Icon,
                SingleName: this.selectedTable.SingleName,
                Security_Structure: this.selectedTable.Security_Structure,
              }
              if(this.selectedTable.datasetsecuritystructure){
                buildobj.datasetsecuritystructure = this.selectedTable.datasetsecuritystructure
              }
                if(buildobj.Security_Structure && buildobj.Security_Structure.Name === 'Custom Roles'){
                  if(this.selectedTable.CreatePermission){
                  buildobj.CreatePermission = this.selectedTable.CreatePermission
                  }
                  else{
                    buildobj.CreatePermission = 'Custom Role'
                  }
                  if(this.selectedTable.GetPermission){
                  buildobj.GetPermission = this.selectedTable.GetPermission
                  }
                  else{
                    buildobj.GetPermission = 'Custom Role'
                  }
                  if(this.selectedTable.ListPermission){
                  buildobj.ListPermission = this.selectedTable.ListPermission
                  }
                  else{
                    buildobj.ListPermission = 'Custom Role'
                  }
                  if(this.selectedTable.UpdatePermission){
                  buildobj.UpdatePermission = this.selectedTable.UpdatePermission
                  }
                  else{
                    buildobj.UpdatePermission = 'Custom Role'
                  }
                  if(this.selectedTable.DeletePermission){
                  buildobj.DeletePermission = this.selectedTable.DeletePermission
                  }
                  else{
                    buildobj.DeletePermission = 'Custom Role'
                  }
                }
                else if(buildobj.Security_Structure && buildobj.Security_Structure.Name === 'Business Unit'){
                  // buildobj.CreatePermission = this.selectedTable.CreatePermission
                  // buildobj.GetPermission = this.selectedTable.GetPermission
                  // buildobj.ListPermission = this.selectedTable.ListPermission
                  // buildobj.UpdatePermission = this.selectedTable.UpdatePermission
                  // buildobj.DeletePermission = this.selectedTable.DeletePermission
                }
                
              if(this.selectedTable.SingleBuildTabs){
                buildobj.SingleBuildTabs = this.selectedTable.SingleBuildTabs
              }
              buildobj.Elements = []
              let docregisters = JSON.parse(JSON.stringify(this.selectedTable.DocumentRegisters))
              let galleries = JSON.parse(JSON.stringify(this.selectedTable.Galleries))
              if(docregisters){
                buildobj.Elements = buildobj.Elements.concat(docregisters)
              }
              if(galleries){
                buildobj.Elements = buildobj.Elements.concat(galleries)
              }
              if(this.selectedTable.CreatePermission){
                buildobj.CreatePermission = this.selectedTable.CreatePermission
              }
              if(this.selectedTable.GetPermission){
                buildobj.GetPermission = this.selectedTable.GetPermission
              }
              if(this.selectedTable.ListPermission){
                buildobj.ListPermission = this.selectedTable.ListPermission
              }
              if(this.selectedTable.UpdatePermission){
                buildobj.UpdatePermission = this.selectedTable.UpdatePermission
              }
              if(this.selectedTable.DeletePermission){
                buildobj.DeletePermission = this.selectedTable.DeletePermission
              }
            if(!oncheck){
              role.Builds.push(buildobj)
            } 
            else{
              if(oncheck.Elements){
                buildobj.Elements = oncheck.Elements
              }
              let index = role.Builds.indexOf(oncheck)
              role.Builds[index] = buildobj
            }     
          }
          return role
        })
      },
      ComputedCustomSecurityRoles(){
        return this.CustomSecurityRoles.map(role => {
          if(this.selectedTable){
            let oncheck = role.Builds.find(obj => obj.id === this.selectedTable.id)
            
              let buildobj = {
                id: this.selectedTable.id,
                Primary_Field_Name: this.selectedTable.Primary_Field_Name,
                DisplayName: this.selectedTable.DisplayName,
                Name: this.selectedTable.Name,
                Table_Icon: this.selectedTable.Table_Icon,
                SingleName: this.selectedTable.SingleName,
                Security_Structure: this.selectedTable.Security_Structure,
                // CreatePermission: this.selectedTable.CreatePermission,
                // GetPermission: this.selectedTable.GetPermission,
                // ListPermission: this.selectedTable.ListPermission,
                // UpdatePermission: this.selectedTable.UpdatePermission,
                // DeletePermission: this.selectedTable.DeletePermission,
              }
              
              
               if(this.selectedTable.CreatePermission){
                  buildobj.CreatePermission = this.selectedTable.CreatePermission
                  }
                  else{
                    buildobj.CreatePermission = 'Custom Role'
                  }
                  if(this.selectedTable.GetPermission){
                  buildobj.GetPermission = this.selectedTable.GetPermission
                  }
                  else{
                    buildobj.GetPermission = 'Custom Role'
                  }
                  if(this.selectedTable.ListPermission){
                  buildobj.ListPermission = this.selectedTable.ListPermission
                  }
                  else{
                    buildobj.ListPermission = 'Custom Role'
                  }
                  if(this.selectedTable.UpdatePermission){
                  buildobj.UpdatePermission = this.selectedTable.UpdatePermission
                  }
                  else{
                    buildobj.UpdatePermission = 'Custom Role'
                  }
                  if(this.selectedTable.DeletePermission){
                  buildobj.DeletePermission = this.selectedTable.DeletePermission
                  }
                  else{
                    buildobj.DeletePermission = 'Custom Role'
                  }
              if(this.selectedTable.datasetsecuritystructure){
                buildobj.datasetsecuritystructure = this.selectedTable.datasetsecuritystructure
              }
              if(this.selectedTable.CreateisCustom){
                buildobj.CreateisCustom = this.selectedTable.CreateisCustom
              }
              if(this.selectedTable.GetisCustom){
                buildobj.GetisCustom = this.selectedTable.GetisCustom
              }
              if(this.selectedTable.ListisCustom){
                buildobj.ListisCustom = this.selectedTable.ListisCustom
              }
              if(this.selectedTable.EditisCustom){
                buildobj.EditisCustom = this.selectedTable.EditisCustom
              }
              if(this.selectedTable.DeleteisCustom){
                buildobj.DeleteisCustom = this.selectedTable.DeleteisCustom
              }
            if(!oncheck){
              role.Builds.push(buildobj)
            } 
            else{
              if(oncheck.CustomcreateBoolean){
                buildobj.CustomcreateBoolean = oncheck.CustomcreateBoolean
              }
              if(oncheck.CustomgetBoolean){
                buildobj.CustomgetBoolean = oncheck.CustomgetBoolean
              }
              if(oncheck.CustomlistBoolean){
                buildobj.CustomlistBoolean = oncheck.CustomlistBoolean
              }
              if(oncheck.CustomupdateBoolean){
                buildobj.CustomupdateBoolean = oncheck.CustomupdateBoolean
              }
              if(oncheck.CustomdeleteBoolean){
                buildobj.CustomdeleteBoolean = oncheck.CustomdeleteBoolean
              }
              //actually check if elements have these
              if(oncheck.Elements){
                buildobj.Elements = oncheck.Elements
              }
              let index = role.Builds.indexOf(oncheck)
              role.Builds[index] = buildobj
            }     
          }
          return role
        })
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      OverviewReadiness(){
        return 'Incomplete'
      },
      OverviewProgress(){
        return 30
      },
      OverviewReadinessTitleColor(){
        return 'red'
      },
      OverviewReadinessProgressColor(){
          return 'red'
      },
      OverviewReadinessIcon(){
        return 'mdi-alert'
      },
      BusinessUnits(){
        return this.$store.state.BusinessUnitsArray
      },
      AppUsers(){
        return this.$store.state.Users.filter(user => {
          if(this.selectBU){
            return user.Business_Unitid === this.selectBU.id
          } 
          else{
            return user
          }
        })
      },
      AllTableswithCabinets(){
        return this.BUTableswithCabinets.concat(this.CustomRoleTableswithCabinets)
      },
      BUTableswithCabinets(){
        let docregisters = this.BUTables.map(build => {
            return build.DocumentRegisters.map(docreg => {
              docreg.buildid = build.id
              docreg.CustomcreateBoolean = false
              docreg.CustomgetBoolean = false
              docreg.CustomlistBoolean = false
              docreg.CustomupdateBoolean = false
              docreg.CustomdeleteBoolean = false
              docreg.Security_Structure = build.Security_Structure.Name
              return docreg
            })
        }).flat()
        let galleries = this.BUTables.map(build => {
            return build.Galleries.map(gal => {
              gal.buildid = build.id
              gal.CustomcreateBoolean = false
              gal.CustomgetBoolean = false
              gal.CustomlistBoolean = false
              gal.CustomupdateBoolean = false
              gal.CustomdeleteBoolean = false
              gal.Security_Structure = build.Security_Structure.Name
              return gal
            })
        }).flat()
        return docregisters.concat(galleries)
      },
      CustomRoleTableswithCabinets(){
        let docregisters = this.CustomRoleTables.map(build => {
            return build.DocumentRegisters.map(docreg => {
              docreg.buildid = build.id
              docreg.CustomcreateBoolean = false
              docreg.CustomgetBoolean = false
              docreg.CustomlistBoolean = false
              docreg.CustomupdateBoolean = false
              docreg.CustomdeleteBoolean = false
              docreg.Security_Structure = build.Security_Structure.Name
              return docreg
            })
        }).flat()
        let galleries = this.CustomRoleTables.map(build => {
            return build.Galleries.map(gal => {
              gal.buildid = build.id
              gal.CustomcreateBoolean = false
              gal.CustomgetBoolean = false
              gal.CustomlistBoolean = false
              gal.CustomupdateBoolean = false
              gal.CustomdeleteBoolean = false
              gal.Security_Structure = build.Security_Structure.Name
              return gal
            })
        }).flat()
        return docregisters.concat(galleries)
      },
      BUTables(){
        return this.BuildswithSecurity.filter(build => {
          return build.Security_Structure.Name === 'Business Unit'
        })
      },
      CustomRoleTables(){
        return this.BuildswithSecurity.filter(build => {
          return build.Security_Structure.Name === 'Custom Roles'
        })
      },
      RMRoleBuilds(){
        return this.BuildswithSecurity.filter(build => {
          return build
        })
      },
      BuildswithSecurity(){
      return this. SystemEntities.filter(build => {
        return build.Security_Structure
      }).map(build => {
        //console.log(build)
        let RMRoles = JSON.parse(JSON.stringify(this.RMSecurityRoles))
          //console.log(RMRoles)
          let CreateRoles = JSON.parse(JSON.stringify(RMRoles))
          let GetRoles = JSON.parse(JSON.stringify(RMRoles))
          let ListRoles = JSON.parse(JSON.stringify(RMRoles))
          let UpdateRoles = JSON.parse(JSON.stringify(RMRoles))
          let DeleteRoles = JSON.parse(JSON.stringify(RMRoles))
          build.SingleBuildTabs = build.SingleBuildTabs.map(tab => {
            //console.log(tab)
            tab.Elements = tab.Elements.map(section => {
              //console.log(section)
              if(section.SectionType === 'Images' || section.SectionType === 'Document Register' || section.SectionType === 'Gallery'){
                if(section.UpdatePermission === 'Custom Role'){
                  section.CustomUpdateRoles = []
                  UpdateRoles.map(role => {
                    // console.log(role)
                    role.Builds.map(rolebuild => {
                      if(rolebuild.id === build.id){
                        rolebuild.Elements.map(rbsection => {
                          if(rbsection.CustomupdateBoolean && rbsection.Name === section.Name){                            
                            section.CustomUpdateRoles.push(role.Name)
                          }
                        })
                      }
                    })
                  })
                }
                if(section.CreatePermission === 'Custom Role'){
                  section.CustomCreateRoles = []
                  CreateRoles.map(role => {
                    
                    role.Builds.map(rolebuild => {
                      if(rolebuild.id === build.id){
                        
                        rolebuild.Elements.map(rbsection => {
                          if(rbsection.CustomcreateBoolean && rbsection.Name === section.Name){
                            section.CustomCreateRoles.push(role.Name)
                          }
                        })
                      }
                    })
                  })
                }
                if(section.GetPermission === 'Custom Role'){
                  section.CustomGetRoles = []
                  GetRoles.map(role => {
                    role.Builds.map(rolebuild => {
                      if(rolebuild.id === build.id){
                        rolebuild.Elements.map(rbsection => {
                          if(rbsection.CustomgetBoolean && rbsection.Name === section.Name){
                            section.CustomGetRoles.push(role.Name)
                          }
                        })
                      }
                    })
                  })
                }
                if(section.ListPermission === 'Custom Role'){
                  section.CustomListRoles = []
                  ListRoles.map(role => {
                    role.Builds.map(rolebuild => {
                      if(rolebuild.id === build.id){
                        rolebuild.Elements.map(rbsection => {
                          if(rbsection.CustomlistBoolean && rbsection.Name === section.Name){
                            section.CustomListRoles.push(role.Name)
                          }
                        })
                      }
                    })
                  })
                }
                
                if(section.DeletePermission === 'Custom Role'){
                  section.CustomDeleteRoles = []
                  DeleteRoles.map(role => {
                    role.Builds.map(rolebuild => {
                      if(rolebuild.id === build.id){
                        rolebuild.Elements.map(rbsection => {
                          if(rbsection.CustomdeleteBoolean && rbsection.Name === section.Name){
                            section.CustomDeleteRoles.push(role.Name)
                          }
                        })
                      }
                    })
                  })
                }
              }
              return section
            })
            return tab
          })
          // console.log(build.tempname,build.singlebuildtabs)
          return build
      }).map(build => {
        //and here we do BU securitystructure
        if(build.Security_Structure && build.Security_Structure.Name === 'Business Unit'){
          build.AllgetRoles = []
          build.UnitDowngetRoles = []
          build.UserUnitgetRoles = []
          build.OwnergetRoles = []
          build.AlllistRoles = []
          build.UnitDownlistRoles = []
          build.UserUnitlistRoles = []
          build.OwnerlistRoles = []

          build.AllcreateRoles = []
          build.UnitDowncreateRoles = []
          build.UserUnitcreateRoles = []
          build.OwnercreateRoles = []
          
          build.AllupdateRoles = []
          build.UnitDownupdateRoles = []
          build.UserUnitupdateRoles = []
          build.OwnerupdateRoles = []
          build.AlldeleteRoles = []
          build.UnitDowndeleteRoles = []
          build.UserUnitdeleteRoles = []
          build.OwnerdeleteRoles = []
          
          let BURoles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
          //console.log('and here we do BU securitystructure BURoles',BURoles)
        BURoles.map(role => {
          role.Builds.map(rolebuild => {
            if(rolebuild.id === build.id){
              if(rolebuild.getBoolean){
                if(rolebuild.getUnitConfig === 'All'){
                  build.AllgetRoles.push(role.DisplayName)
                }
                else if(rolebuild.getUnitConfig === 'UnitDown'){
                  build.UnitDowngetRoles.push(role.DisplayName)
                }
                else if(rolebuild.getUnitConfig === 'UserUnit'){
                  build.UserUnitgetRoles.push(role.DisplayName)
                }
                else if(rolebuild.getUnitConfig === 'Owner'){
                  build.OwnergetRoles.push(role.DisplayName)
                }
              }
              if(rolebuild.listBoolean){
                if(rolebuild.listUnitConfig === 'All'){
                  build.AlllistRoles.push(role.DisplayName)
                }
                else if(rolebuild.listUnitConfig === 'UnitDown'){
                  build.UnitDownlistRoles.push(role.DisplayName)
                }
                else if(rolebuild.listUnitConfig === 'UserUnit'){
                  build.UserUnitlistRoles.push(role.DisplayName)
                }
                else if(rolebuild.listUnitConfig === 'Owner'){
                  build.OwnerlistRoles.push(role.DisplayName)
                }
              }
              if(rolebuild.createBoolean){
                if(rolebuild.createUnitConfig === 'All'){
                  build.AllcreateRoles.push(role.DisplayName)
                }
                else if(rolebuild.createUnitConfig === 'UnitDown'){
                  build.UnitDowncreateRoles.push(role.DisplayName)
                }
                else if(rolebuild.createUnitConfig === 'UserUnit'){
                  build.UserUnitcreateRoles.push(role.DisplayName)
                }
                else if(rolebuild.createUnitConfig === 'Owner'){
                  build.OwnercreateRoles.push(role.DisplayName)
                }
              }
              if(rolebuild.updateBoolean){
                if(rolebuild.updateUnitConfig === 'All'){
                  build.AllupdateRoles.push(role.DisplayName)
                }
                else if(rolebuild.updateUnitConfig === 'UnitDown'){
                  build.UnitDownupdateRoles.push(role.DisplayName)
                }
                else if(rolebuild.updateUnitConfig === 'UserUnit'){
                  build.UserUnitupdateRoles.push(role.DisplayName)
                }
                else if(rolebuild.updateUnitConfig === 'Owner'){
                  build.OwnerupdateRoles.push(role.DisplayName)
                }
              }
              if(rolebuild.deleteBoolean){
                if(rolebuild.deleteUnitConfig === 'All'){
                  build.AlldeleteRoles.push(role.DisplayName)
                }
                else if(rolebuild.deleteUnitConfig === 'UnitDown'){
                  build.UnitDowndeleteRoles.push(role.DisplayName)
                }
                else if(rolebuild.deleteUnitConfig === 'UserUnit'){
                  build.UserUnitdeleteRoles.push(role.DisplayName)
                }
                else if(rolebuild.deleteUnitConfig === 'Owner'){
                  build.OwnerdeleteRoles.push(role.DisplayName)
                }
              }
            }
          })
        })
        }
        else if(build.Security_Structure && build.Security_Structure.Name === 'Custom Roles'){
          let createroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
          build.CustomCreateRoles = createroles.map(role => {
            role.Builds = role.Builds.filter(rolebuild => {
              return rolebuild.id === build.id
            }).filter(rolebuild => {
              return rolebuild.CustomcreateBoolean === true
            })
            return role
          }).filter(role => {
            return role.Builds.length>0
          }).map(role => {
            return role.Name
          })
          let getroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
          build.CustomGetRoles = getroles.map(role => {
            role.Builds = role.Builds.filter(rolebuild => {
              return rolebuild.id === build.id
            }).filter(rolebuild => {
              return rolebuild.CustomgetBoolean === true
            })
            return role
          }).filter(role => {
            return role.Builds.length>0
          }).map(role => {
            return role.Name
          })
          //console.log(build.id,build.CustomGetRoles)
          let listroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
          build.CustomListRoles = listroles.map(role => {
            role.Builds = role.Builds.filter(rolebuild => {
              return rolebuild.id === build.id
            }).filter(rolebuild => {
              return rolebuild.CustomlistBoolean === true
            })
            return role
          }).filter(role => {
            return role.Builds.length>0
          }).map(role => {
            return role.Name
          })
          let updateroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
          build.CustomUpdateRoles = updateroles.map(role => {
            role.Builds = role.Builds.filter(rolebuild => {
              return rolebuild.id === build.id
            }).filter(rolebuild => {
              return rolebuild.CustomupdateBoolean === true
            })
            return role
          }).filter(role => {
            return role.Builds.length>0
          }).map(role => {
            return role.Name
          })
           let deleteroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
          build.CustomDeleteRoles = deleteroles.map(role => {
            role.Builds = role.Builds.filter(rolebuild => {
              return rolebuild.id === build.id
            }).filter(rolebuild => {
              return rolebuild.CustomdeleteBoolean === true
            })
            return role
          }).filter(role => {
            return role.Builds.length>0
          }).map(role => {
            return role.Name
          })
        }
        // console.log(build.id,build)
      return build
      })
    },
        SearchedClientAppUsersbyselectedCabinet(){
          return this.ClientAppUsersbyselectedCabinet.filter(user => {
            if(this.UserSearch){
              return user.Full_Name.toLowerCase().includes(this.UserSearch.toLowerCase())
            }
            else{
              return user
            }
          })
        },
        SearchedClientAppUsersbyselectedBuild(){
          return this.ClientAppUsersbyselectedBuild.filter(user => {
            if(this.UserSearch){
              return user.Full_Name.toLowerCase().includes(this.UserSearch.toLowerCase())
            }
            else{
              return user
            }
          })
        },
        ClientAppUsersbyselectedCabinet(){
          let build = this.selectedTable
          let tableroles = ''
          let tabletype = build.Security_Structure.Name
          if(tabletype === 'Custom Roles'){            
            tableroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))       
          }
          else if(tabletype === 'Business Unit'){            
            tableroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
          }
          let tablecreateroles = JSON.parse(JSON.stringify(tableroles))
          let tablegetroles = JSON.parse(JSON.stringify(tableroles))
          let tablelistroles = JSON.parse(JSON.stringify(tableroles))
          let tableupdateroles = JSON.parse(JSON.stringify(tableroles))
          let tabledeleteroles = JSON.parse(JSON.stringify(tableroles))
          if(build){
          let cabinet = this.selectedCabinet
          if(cabinet){
            return this.drawer ? this.ClientAppUsers.map(user => {
              
              let userroles = []
              user.CanCreate = false
              user.CanGet = false
              user.CanList = false
              user.CanUpdate = false
              user.CanDelete = false
              
              if(user.rolesarrayQuery){
                userroles = user.rolesarrayQuery
              }
              let useradmintest = userroles.find(obj => obj == 'System Admin')
              
              if(useradmintest){
              
                user.CanCreate = true
                user.CanGet = true
                user.CanList = true
                user.CanUpdate = true
                user.CanDelete = true
              }
                
                let customroles = JSON.parse(JSON.stringify(this.RMSecurityRoles))
                let createroles = JSON.parse(JSON.stringify(customroles))
                let getroles = JSON.parse(JSON.stringify(customroles))
                let listroles = JSON.parse(JSON.stringify(customroles))
                let updateroles = JSON.parse(JSON.stringify(customroles))
                let deleteroles = JSON.parse(JSON.stringify(customroles))
                //console.log('selectedCabinet',cabinet,customroles)
                if(cabinet.CreatePermission === 'Custom Role'){
                  this.GetRMRoleOutput(user,userroles,'CanCreate',createroles,build,cabinet,'CustomcreateBoolean')
                }
                else if(cabinet.CreatePermission === 'Admin'){

                }
                else if(cabinet.CreatePermission === 'Inherited'){
                  if(tabletype === 'Custom Roles'){            
                    this.GetCustomRoleOutput(user,userroles,'CanCreate',tablecreateroles,build,'CustomcreateBoolean')      
                  }
                  else if(tabletype === 'Business Unit'){            
                    this.GetBURoleOutput(user,userroles,'CanCreate',tablecreateroles,build,'createBoolean','createUnitConfig')
                  }                  
                }
                
                else{
                  user.CanCreate = true
                }
                if(cabinet.GetPermission === 'Custom Role'){
                  this.GetRMRoleOutput(user,userroles,'CanGet',getroles,build,cabinet,'CustomgetBoolean')
                }
                else if(cabinet.GetPermission === 'Admin'){
                  
                }
                else if(cabinet.GetPermission === 'Inherited'){
                  if(tabletype === 'Custom Roles'){            
                    this.GetCustomRoleOutput(user,userroles,'CanGet',tablegetroles,build,'CustomgetBoolean')      
                  }
                  else if(tabletype === 'Business Unit'){     
                    this.GetBURoleOutput(user,userroles,'CanGet',tablegetroles,build,'getBoolean','getUnitConfig')
                  }
                }
                else{
                  user.CanGet = true
                }
                if(cabinet.ListPermission === 'Custom Role'){
                  this.GetRMRoleOutput(user,userroles,'CanList',listroles,build,cabinet,'CustomlistBoolean')
                }
                else if(cabinet.ListPermission === 'Admin'){
                  
                }
                else if(cabinet.ListPermission === 'Inherited'){
                  if(tabletype === 'Custom Roles'){            
                    this.GetCustomRoleOutput(user,userroles,'CanList',tablelistroles,build,'CustomlistBoolean')      
                  }
                  else if(tabletype === 'Business Unit'){       
                    this.GetBURoleOutput(user,userroles,'CanList',tablelistroles,build,'listBoolean','listUnitConfig') 
                  }
                }
                else{
                  user.CanList = true
                }
                if(cabinet.UpdatePermission === 'Custom Role'){
                  this.GetRMRoleOutput(user,userroles,'CanUpdate',updateroles,build,cabinet,'CustomupdateBoolean')
                }
                else if(cabinet.UpdatePermission === 'Admin'){

                }
                else if(cabinet.UpdatePermission === 'Inherited'){
                 if(tabletype === 'Custom Roles'){            
                    this.GetCustomRoleOutput(user,userroles,'CanUpdate',tableupdateroles,build,'CustomupdateBoolean')      
                  }
                  else if(tabletype === 'Business Unit'){      
                    this.GetBURoleOutput(user,userroles,'CanUpdate',tableupdateroles,build,'updateBoolean','updateUnitConfig')
                  }
                }
                else{
                  user.CanUpdate = true
                }
                if(cabinet.DeletePermission === 'Custom Role'){
                  this.GetRMRoleOutput(user,userroles,'CanDelete',deleteroles,build,cabinet,'CustomdeleteBoolean')
                }
                else if(cabinet.DeletePermission === 'Admin'){
                  
                }
                else if(cabinet.DeletePermission === 'Inherited'){
                  if(tabletype === 'Custom Roles'){            
                    this.GetCustomRoleOutput(user,userroles,'CanDelete',tabledeleteroles,build,'CustomdeleteBoolean')      
                  }
                  else if(tabletype === 'Business Unit'){      
                    this.GetBURoleOutput(user,userroles,'CanDelete',tabledeleteroles,build,'deleteBoolean','deleteUnitConfig')  
                  }
                }                
                else{
                  user.CanDelete = true
                }
            
              return user
            }).filter(user => {
              if(this.selectBU){
                return user.Business_Unitid === this.selectBU.id
              }
              else{
                return user
              }
            })
            .filter(user => {
              if(this.selectUser){
                return user.id === this.selectUser.id
              }
              else{
                return user
              }
            })
            : []
          }
          else{
            return this.ClientAppUsers
          }
          }
    },
        ClientAppUsersbyselectedBuild(){
      let build = this.selectedTable
      
      if(build){
        return this.drawer ? this.ClientAppUsers.map(user => {
          
          let userroles = []
          user.CanCreate = false
          user.CanGet = false
          user.CanList = false
          user.CanUpdate = false
          user.CanDelete = false
          
          if(user.rolesarrayQuery){
            userroles = user.rolesarrayQuery
          }
          let useradmintest = userroles.find(obj => obj == 'System Admin')
          
          if(useradmintest){
           
            user.CanCreate = true
            user.CanGet = true
            user.CanList = true
            user.CanUpdate = true
            user.CanDelete = true
          }
          
          if(build.Security_Structure && build.Security_Structure.Name === 'Custom Roles'){            
            let customroles = JSON.parse(JSON.stringify(this.CustomSecurityRoles))
            //console.log('customrolescustomrolescustomroles',customroles,build)
            let createroles = JSON.parse(JSON.stringify(customroles))
            let getroles = JSON.parse(JSON.stringify(customroles))
            let listroles = JSON.parse(JSON.stringify(customroles))
            let updateroles = JSON.parse(JSON.stringify(customroles))
            let deleteroles = JSON.parse(JSON.stringify(customroles))
            if(build.CreateisCustom || build.CreatePermission === 'Custom Role'){
              this.GetCustomRoleOutput(user,userroles,'CanCreate',createroles,build,'CustomcreateBoolean')
            }
            else if(build.CreatePermission === 'Admin'){
              
            }
            else{
              user.CanCreate = true
            }
            if(build.GetisCustom || build.GetPermission === 'Custom Role'){
              this.GetCustomRoleOutput(user,userroles,'CanGet',getroles,build,'CustomgetBoolean')
            }
            else if(build.GetPermission === 'Admin'){
              
            }
            else{
              user.CanGet = true
            }
            if(build.ListisCustom || build.ListPermission === 'Custom Role'){
              this.GetCustomRoleOutput(user,userroles,'CanList',listroles,build,'CustomlistBoolean')
            }
            else if(build.ListPermission === 'Admin'){
              
            }
            else{
              user.CanList = true
            }
            if(build.EditisCustom || build.EditPermission === 'Custom Role'){
              this.GetCustomRoleOutput(user,userroles,'CanUpdate',updateroles,build,'CustomupdateBoolean')
            }
            else if(build.UpdatePermission === 'Admin'){
              
            }
            else{
              user.CanUpdate = true
            }
            if(build.DeleteisCustom || build.DeletePermission === 'Custom Role'){
              this.GetCustomRoleOutput(user,userroles,'CanDelete',deleteroles,build,'CustomdeleteBoolean')
            }
            else if(build.DeletePermission === 'Admin'){
              
            }
            else{
              user.CanDelete = true
            }
          }
          else if(build.Security_Structure && build.Security_Structure.Name === 'Business Unit'){
            let createroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
            let getroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
            let listroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
            let updateroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
            let deleteroles = JSON.parse(JSON.stringify(this.BUSecurityRoles))
            this.GetBURoleOutput(user,userroles,'CanCreate',createroles,build,'createBoolean','createUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanGet',getroles,build,'getBoolean','getUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanList',listroles,build,'listBoolean','listUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanUpdate',updateroles,build,'updateBoolean','updateUnitConfig')
            this.GetBURoleOutput(user,userroles,'CanDelete',deleteroles,build,'deleteBoolean','deleteUnitConfig')           
          }
          
          return user
        }).filter(user => {
          if(this.selectBU){
            return user.Business_Unitid === this.selectBU.id
          }
          else{
            return user
          }
        })
        .filter(user => {
          if(this.selectUser){
            return user.id === this.selectUser.id
          }
          else{
            return user
          }
        })
        : []
      }
      else{
        return this.ClientAppUsers
      }
    },
    },
    watch: {
      selectUser (value) {
        if(value){
          this.selectBU = this.ClientAppBU.find(obj => obj.id === value.Business_Unitid)
        }
      },
      selectBU(value) {
        if(value === null || typeof value === 'undefined'){
          if(this.selectUser){
           this.selectUser = ''
         }
        }
      },
      selectedTable (value) {
        //console.log(value)
        if (value === null || typeof value === 'undefined') {
         //this.CheckAuth()
         this.selectedCabinet = ''
        }
      },
      selectedCabinet (value) {
        if (value === null || typeof value === 'undefined') {
         //this.CheckAuth()
         if(this.selectedTable){
           this.RoleMode = this.selectedTable.Security_Structure.Name
         }
        }
      },
    },
    created(){
      if(this.ActiveEntity){
        this.selectedTable = this.ActiveEntity
        this.UpdateRoleMode(this.ActiveEntity.Security_Structure.Name)
      }
       this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
      console.log(this.SystemEntities)
        this.GetClientAppBU()
        this.GetClientAppUsers()
        this.GetSecurityRoles(this.ConfigDB.collection('BUsecurityroles'),'BUSecurityRoles','BUsecurityroles')
        this.GetSecurityRoles(this.ConfigDB.collection('Customsecurityroles'),'CustomSecurityRoles','Customsecurityroles')
        this.GetSecurityRoles(this.ConfigDB.collection('RMsecurityroles'),'RMSecurityRoles','RMsecurityroles')
    },

    methods: {
      UpdateRoleasGuestSafe(editedRole){
        //console.log(editedRole)
        //IsGuestSafe
        this.ConfigDB.collection(editedRole.RoleConfigCollection).doc(editedRole.id).update({
          IsGuestSafe: editedRole.IsGuestSafe
        })
      },
      ActivateSnackbar(boolean,content){
        this.$emit('ActivateSnackbar',boolean,content)
      },
      UpdateUserSecurityRoles(){
        let userlength = this.ClientAppUsers.length
        this.ClientAppUsers.map((user,i) => {
          user.rolesarrayDBRules = {}
          user.rolesarrayQuery.map(role => {
            user.rolesarrayDBRules[role.split(' ').join('')] = true
          })
          db.collection('userroles').doc(user.id).update({
            rolesarrayDBRules: user.rolesarrayDBRules,
            rolesarrayQuery: user.rolesarrayQuery
          })
          if(i-1+2 === userlength){
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'All User Accounts updated with new Roles.',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
          }
        })
      },
      CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }              
            }
            else{   
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
              }
            }
          }
        }
      })
    },
      UpdateRACabinetThenClient(prop,cabinet){
          this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(cabinet.buildid).collection(cabinet.ConfigCollection).doc(cabinet.id).update({
              [prop]: cabinet[prop]
            })
      },
      UpdateClientDBCabinet(prop,cabinet){
        this.ConfigDB.collection('entities').doc(cabinet.buildid).collection(cabinet.ConfigCollection).doc(cabinet.id).update({
                  [prop]: cabinet[prop]
                })
      },
      UpdateRATableThenClient(prop,table,length,index){
        //console.log('calling update ra table',prop,table[prop],this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(table.id))
        this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(table.id).update({
                  [prop]: table[prop]
                }).then(radoc => {
                  this.UpdateClientDBTable(prop,table,length,index)
                })
      },
      UpdateClientDBTable(prop,table,length,index){
        this.ConfigDB.collection('entities').doc(table.id).update({
              [prop]: table[prop]
            }).then(updatedoc => {
              if(index-1+2 === length){
                let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Updated all affected "Business Unit" type Data Tables!',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
              }
            })
      },
      UpdateSystemSecurityRoles(){
          let fullarray = JSON.parse(JSON.stringify(this.SaveAllSecurityRoles))
          let roleslength = fullarray.length
          console.log(fullarray,this.SaveBUTables,this.SaveCustomRoleTables)
          this.CheckArrayforUndefined(1,fullarray,'(SaveAllSecurityRoles)')
          fullarray.map((role,i) => {
            if(role.id){
              if(role.Builds){
                role.Builds = role.Builds.map(build => {
                  let buildobj = Object.assign({},build)
                  delete buildobj.SingleBuildTabs
                  return buildobj
                })
              }
              this.ConfigDB.collection(role.RoleConfigCollection).doc(role.id).set(role).then(roledoc => {
                if(i-1+2 === roleslength){
                  let snackbarcontent = {
                      snackbartimeout: 4000,
                      snackbartext: 'Updated and Stored Security Roles in Database!',
                      snackbartop: true,
                      snackbarmultiLine: true
                    }
                    this.$emit('ActivateSnackbar',true,snackbarcontent)
                }
            })
            }
            
          })
          let butablelength = this.SaveBUTables.length
          if(butablelength === 0){
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'No "Business Unit" type Data Tables updated...',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
          }
          else{
            this.SaveBUTables.map((table,i) => {
            this.BURolesDefault.map(prop => {
              if(this.RADB){
                this.UpdateRATableThenClient(prop.Name,table,length,i)
              }
              else{
                this.UpdateClientDBTable(prop.Name,table,length,i)
              }            
            })
          })
          }
          
        
          let customroletypes = [{Name: 'CustomCreateRoles',Prop: 'CreatePermission',Bool: 'CreateisCustom'},{Name: 'CustomGetRoles',Prop: 'GetPermission',Bool: 'GetisCustom'},{Name: 'CustomListRoles',Prop: 'ListPermission',Bool: 'ListisCustom'},{Name: 'CustomUpdateRoles',Prop: 'UpdatePermission',Bool: 'EditisCustom'},{Name: 'CustomDeleteRoles',Prop: 'DeletePermission',Bool: 'DeleteisCustom'}]
          let customroletablelength = this.SaveCustomRoleTables.length
          if(customroletablelength === 0){
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'No "Custom Role" type Data Tables updated...',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
          }
          this.SaveCustomRoleTables.map((table,i) => {
            customroletypes.map(prop => {
              if(table[prop.Name]){
                if(this.RADB){
                  this.UpdateRATableThenClient(prop.Name,table,length,i)
                }
                else{
                  this.UpdateClientDBTable(prop.Name,table,length,i)
                }
              }
              if(table[prop.Prop]){
                if(this.RADB){
                  this.UpdateRATableThenClient(prop.Prop,table,length,i)
                }
                else{
                  this.UpdateClientDBTable(prop.Prop,table,length,i)
                }
              }
              if(typeof table[prop.Bool] !== 'undefined'){
                if(this.RADB){
                  this.UpdateRATableThenClient(prop.Bool,table,length,i)
                }
                else{
                  this.UpdateClientDBTable(prop.Bool,table,length,i)
                }
              }
              if(i-1+2 === customroletablelength){
                let snackbarcontent = {
                  snackbartimeout: 4000,
                  snackbartext: 'Updated all affected "Custom Role" type Data Tables!',
                  snackbartop: true,
                  snackbarmultiLine: true
                }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
              }
            })            
          })
          let rmcabinetslength = this.SaveRMCabinets.length
          if(rmcabinetslength === 0){
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'No "Cabinets" updated.',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
          }
          this.SaveRMCabinets.map((cabinet,i) => {
            customroletypes.map(prop => {
              if(cabinet[prop.Name]){
                if(this.RADB){
                  this.UpdateRACabinetThenClient(prop.Name,cabinet)
                }
                else{
                  this.UpdateClientDBCabinet(prop.Name,cabinet)
                }
              }
              if(cabinet[prop.Prop]){
                if(this.RADB){
                  this.UpdateRACabinetThenClient(prop.Prop,cabinet)
                }
                else{
                  this.UpdateClientDBCabinet(prop.Prop,cabinet)
                }
              }
              if(typeof cabinet[prop.Bool] !== 'undefined'){
                if(this.RADB){
                  this.UpdateRACabinetThenClient(prop.Bool,cabinet)
                }
                else{
                  this.UpdateClientDBCabinet(prop.Bool,cabinet)
                }
              }
            })  
            if(i-1+2 === rmcabinetslength){
              let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Updated all "Cabinets" affected.',
              snackbartop: true,
              snackbarmultiLine: true
            }
            this.$emit('ActivateSnackbar',true,snackbarcontent)
            }        
          })
          //console.log(this.SaveBUTables,this.SaveCustomRoleTables,this.SaveRMCabinets,this.ComputedRMSecurityRoles,this.RMSecurityRoles)
      },
      
      Refresh(){
        this.drawer = false
        setTimeout(() => {
            this.drawer = true
        }, 100);
      },
      GetSecurityRoles(query,array,col){
        query.onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let obj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                obj.RoleConfigCollection = col
                this[array].push(obj)
              }
            })
        })
      },
      ActivateSecurityRoleEditdialog(role){
        if(this.RoleMode === 'Custom Roles'){
        this.SecurityRoleIndex = this.CustomSecurityRoles.indexOf(role)
        this.editedRole = Object.assign({}, role)
          this.CustomSecurityRoledialog = true
        }
        else if(this.RoleMode === 'RM Security Roles'){
          this.SecurityRoleIndex = this.RMSecurityRoles.indexOf(role)
        this.editedRole = Object.assign({}, role)
          this.RMSecurityRoledialog = true
        }
        else{
          this.SecurityRoleIndex = this.BUSecurityRoles.indexOf(role)
        this.editedRole = Object.assign({}, role)
          this.BUSecurityRoledialog = true
        }
      },
      AddSecurityRoles(rolemode) {
        let NewSecurityRole = {
            Name: '',
            DisplayName: '',
            Description: 'Add more details about this new security role here',
            Builds: []
          }
        if(rolemode === 'Business Unit'){
          this.editedRole = NewSecurityRole
          this.CustomSecurityRoledialog = true
        }
        else if(rolemode === 'Custom Roles'){
          this.editedRole = NewSecurityRole
          this.CustomSecurityRoledialog = true
        }
        else if(rolemode === 'RM Security Roles'){
          this.editedRole = NewSecurityRole
          this.CustomSecurityRoledialog = true
        }
      },
      GetActiveCabinet(role,selectedTable,selectedCabinet){
          let build = role.Builds.find(obj => obj.id === selectedTable.id)
          //console.log(role.Name,build,selectedCabinet)
          let cabinet = build.Elements.find(obj => obj.id === selectedCabinet.id)
          cabinet.Roleid = role.id
          return cabinet
          
          //.Sections.find(obj => obj.id === selectedCabinet.id)
      },
      GetActiveBuild(role,selectedTable){
        //console.log('GetActiveBuild',role,selectedTable)
          return role.Builds.find(obj => obj.id === selectedTable.id)
      },
      // OpenUserPermissionsDialog(user){
      //   this.ActiveUser = user
      //   this.UserRolesDialog = true
      //   console.log(user)
      // },
      UpdateCustomBoolean(permissionname,item,permissionbooleanname){
        if(item[permissionname] === 'Custom Role'){
          item[permissionbooleanname] = true
        }
        else{
          item[permissionbooleanname] = false
        }
      },
      UpdateRoleMode(value){
        if(this.selectedTable){
          this.RoleMode = value
        }
        else{
          this.RoleMode = ''
        }
        this.HasRMRoles = false
        
      },
      saveRole(){
      let col = ''
      if(!this.editedRole.Name){
        this.editedRole.Name = this.editedRole.DisplayName
      }
      if(this.RoleMode === 'Business Unit'){
        col = 'BUsecurityroles'
      }
      else if(this.RoleMode === 'Custom Roles'){
        col = 'Customsecurityroles'
      }
      else if(this.RoleMode === 'RM Security Roles'){
        col = 'RMsecurityroles'
      }
      if(this.SecurityRoleIndex === -1){
         this.ConfigDB.collection(col).add(this.editedRole).then(doc => {
            //console.log('role created under '+doc.id)
            this.closeSecurityRoleDialog()
        })
      }
      else{
         this.ConfigDB.collection(col).doc(this.editedRole.id).set(this.editedRole).then(doc => {
            //console.log('role updated')
            this.closeSecurityRoleDialog()
        })
      }
      
    //   this.UpdateSystem()
    },
    closeSecurityRoleDialog(){
      this.BUSecurityRoledialog = false
      this.CustomSecurityRoledialog = false
      this.RMSecurityRoledialog = false
      setTimeout(() => {
      this.editedRole = Object.assign({}, this.defaultRole)
      this.SecurityRoleIndex = -1
      // this.RoleMode = ''
      }, 300)

    },
        ReturnAccessLevel(value,user){
      if(value === 1 || value === 2 || value === 3){
        return this.GetClientDBBU(value,user)
      } 
      if(value === 4){
        return 'Only where Owner'
      }
      else if (value){
        return 'All'
      }     
      
    },
    GetClientDBBU(value,user){
      if(value === 1){
        //let temparray = []
        let string = `<ul style="list-style-type: none; margin: 0; padding: 0;padding-left:4px;text-align: left;">`
        this.ClientAppBU.map(bu => {
          string = string+`<li>`+bu.Name+`</li>`
        })
        string = string+`</ul>`
        return string
      }
      if(value === 2){
        let string = `<ul style="list-style-type: none; margin: 0; padding: 0;padding-left:4px;text-align: left;">`
       let userbuobj = this.ClientAppBU.find(obj => obj.id === user.Business_Unitid)
      let test = userbuobj.childarrayQuery
      test.map(buid => {
        let returnbuobj = this.ClientAppBU.find(obj => obj.id === buid)
        string = string+`<li>`+returnbuobj.Name+`</li>`
        //return returnbuobj.Name
      })
      string = string+`</ul>`
      return string 
      }
      if(value === 3){
        let string = `<ul style="list-style-type: none; margin: 0; padding: 0;padding-left:4px;text-align: left;">`
      let userbuobj = this.ClientAppBU.find(obj => obj.id === user.Business_Unitid)
      string = string+`<li>`+userbuobj.Name+`</li>`
      string = string+`</ul>`
      return string
      }

    },
    GetBURoleOutput(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname,rolebuildpermissionconfig){
      //console.log(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname)
      let roles = JSON.parse(JSON.stringify(rolesarray))
      let newroles = roles.filter(role => {
          role.Builds = role.Builds.filter(rolebuild => {
            return rolebuild.id === build.id && rolebuild[rolebuildpermissionname] === true
          })   
          return role
        }).filter(role => {
          return role.Builds.length > 0
        })
        //console.log('newroles',newroles)
      newroles.map(role => {
                let userrolestest = userroles.find(obj => obj === role.Name)
                if(userrolestest){
                  //console.log(user)
                  let permissiontype = role.Builds[0][rolebuildpermissionconfig]
                  if(permissiontype === 'All'){
                    user[permissionname] = 1
                  }                 
                  else if(permissiontype === 'UnitDown' && !user[permissionname] || permissiontype === 'UnitDown' && user[permissionname] > 2){
                    user[permissionname] = 2
                  }
                  else if(permissiontype === 'UserUnit' && !user[permissionname] || permissiontype === 'UserUnit' && user[permissionname] > 3){
                    user[permissionname] = 3
                  }
                  else if(permissiontype === 'Owner' && !user[permissionname] || permissiontype === 'Owner' && user[permissionname] > 3){
                    user[permissionname] = 4
                  }
                }
              })
    },
    GetRMRoleOutput(user,userroles,permissionname,rolesarray,build,cabinet,rolebuildpermissionname){
      //console.log(user,userroles,permissionname,rolesarray,build,cabinet,rolebuildpermissionname)
      //let cabinettype = cabinet.ConfigCollection
      let newroles = rolesarray.filter(role => {
          role.Builds = role.Builds.filter(rolebuild => {
            let cabinetoncheck = rolebuild.Elements.find(obj => obj.id === cabinet.id)
            return rolebuild.id === build.id && cabinetoncheck && cabinetoncheck[rolebuildpermissionname] === true
          })   
          return role
        }).filter(role => {
          return role.Builds.length > 0
        })
      newroles.map(role => {
                let userrolestest = userroles.find(obj => obj === role.Name)
                if(userrolestest){
                  user[permissionname] = true
                }
              })
    },
    GetCustomRoleOutput(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname){
      //console.log(user,userroles,permissionname,rolesarray,build,rolebuildpermissionname)
      let newroles = rolesarray.filter(role => {
          role.Builds = role.Builds.filter(rolebuild => {
            return rolebuild.id === build.id && rolebuild[rolebuildpermissionname] === true
          })   
          return role
        }).filter(role => {
          return role.Builds.length > 0
        })
      newroles.map(role => {
                let userrolestest = userroles.find(obj => obj === role.Name)
                if(userrolestest){
                  user[permissionname] = true
                }
              })
    },
      GetClientAppBU(){
        let vm = this
        db.collection('businessunits').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                vm.ClientAppBU.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
        })
      },
      GetClientAppUsers(){
        let vm = this

        db.collection('userroles').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                let userobj = {
                ...change.doc.data(),
                id: change.doc.id
                }
                vm.GetClientAppUsersBasic(userobj.id,userobj).then(result => {
                userobj.Full_Name = result.Full_Name
                if(result.Profile_Photo){
                  userobj.Profile_Photo = result.Profile_Photo
                }
                vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                // vm.ClientAppUsers.push(userobj)
                })
                
            }
            })                 
        })
      },
      GetClientAppUsersBasic(userid,userobj){
        let vm = this
        return new Promise(function(resolve, reject) {
          db.collection('users').doc(userid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            resolve(userdata)
          })
        })
      },
    } 
}
</script>

<style>
.hastooltip .tooltiptext {
  visibility: hidden;
  width: 120px;

  /* background-color: rgb(0, 153, 255); */
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
 background-size: 400% 400%;
 animation: gradientBG 15s ease infinite;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 100;
  
}

.hastooltip:hover .tooltiptext {
  visibility: visible;
  z-index: 100;
  position: absolute;
}
</style>

    