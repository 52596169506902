<template>
  <div>
       <v-dialog v-model="AutomationFielddialog" width="400">
                  <v-card tile>
                    <v-card-title>{{AutoFieldeditItem.DisplayName}}</v-card-title>
                    <!-- <p>{{FieldFilter}}</p> -->
                    <v-select :items="['Custom Text', 'Parent Field']" clearable label ="Input Type" v-model="AutoFieldeditItem.MapType"></v-select>
                   
                    <span v-if="AutoFieldeditItem.MapType === 'Parent Field'">
                     
                      <v-select label="Field" :items="RelatedFields" item-text="sourcename" v-model="AutoFieldeditItem.ParentInput" @change="AssignFieldOutput()" return-object></v-select></span>
                    <span v-if="AutoFieldeditItem.MapType === 'Custom Text'"><v-text-field v-model="AutoFieldeditItem.DisplayName"></v-text-field></span>
                    </v-card>
                  </v-dialog>
      <v-dialog v-model="CalculatedNumberFieldMapDialog" width="800px">
        <v-card class="padded">
            <v-card-title class="headline blue-grey darken-2 white--text">Configure Calculated Field - {{FieldFilter.DisplayName}}<v-spacer></v-spacer><v-btn fab @click="SaveCalculatedNumberFieldMap()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelCalculatedNumberFieldMapDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn></v-card-title>
            <v-card-text>
                <v-list>
                    <v-btn @click="AddCalcFieldFormula()">Add</v-btn>
                    <v-list-item v-for="formula in FieldFilter.FieldFormulas" :key="formula.itemObjKey">
                        <v-list-item-content>
                          <v-select :items="['integer','field']" @change="ToggleCalculatedNumberFieldMapDialog()" v-model="formula.input" label="input"></v-select>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-select  label="field"  @change="ToggleCalculatedNumberFieldMapDialog()" v-if="formula.input === 'field'" :items="DatesandNumberFields" item-text="sourcename" v-model="formula.fieldName" return-object></v-select>
                          </v-list-item-content>
                        <v-list-item-content>
                          <v-text-field  label="number" type="number" v-if="formula.input==='integer'" v-model="formula.integer"></v-text-field>
                         
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-checkbox label="add operator" v-model="formula.hasoperation"></v-checkbox>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-select :items="['+','-','*','/','=']" v-model="formula.operation" @change="CheckOperator(formula.operation),ToggleCalculatedNumberFieldMapDialog()"></v-select>
                          </v-list-item-content>
                      </v-list-item>
                    </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
        <!-- <v-dialog v-model="AutomationDialog" width="400"> -->
            <v-card tile>
            <v-card-title class="justify-space-between recordtoolbar white--text">
                {{WorkFlowHeaderName}} <v-icon class="soloactionicon red" dark @click="ToggleAutomationDialog()">mdi-close</v-icon>
            </v-card-title>
            <v-card-subtitle class="recordtoolbar white--text">
              {{WorkFlowTitleName}} {{WorkFlowStepName}}
            </v-card-subtitle>
           <v-window v-model="workflowstep">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-list>
                            Select Below or 
                            <v-btn small text plain @click="AddNewAutomation()">Add New</v-btn>
                            <v-chip-group
                            class="background"
                                  active-class="primary--text"
                                  column
                              >
                              
                              <v-chip
                              class="pop" dark
                              @click="AssignEditAutomation(auto)"
                                  small  v-for="auto in BuildAutomations"
                                  :key="auto.itemObjKey"
                                  >
                                  {{auto.Name}}
                                  </v-chip>                           
                              </v-chip-group>                            
                            <v-text-field v-model="EditedAutomation.Name" label="Name"/>
                        </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                        <v-list>
                          Select Below or 
                            <v-btn small text plain @click="AddCondition()">Add New</v-btn>
                            <v-chip-group
                            class="background"
                                  active-class="primary--text"
                                  column
                              >
                              <v-chip
                              class="pop" dark
                              @click="AssignEditAutoCond(cond)"
                                  small  v-for="cond in EditedAutomation.Conditions"
                                  :key="cond.itemObjKey"
                                  >
                                  {{cond.Name}}
                                  </v-chip>                           
                              </v-chip-group>                      
                            <v-text-field v-model="EditAutoCond.Name" label="Name"/>
                            <v-checkbox label="Can call from Button" v-model="EditAutoCond.CallfromButton"></v-checkbox>
                            <v-text-field v-if="EditAutoCond.CallfromButton" label="Button Text" v-model="EditAutoCond.ApprovalButtonText"></v-text-field>
                            <v-btn v-if="EditAutoCond.CallfromButton">{{EditAutoCond.ApprovalButtonText}}</v-btn>
                        </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="3">
                    <v-card-text style="min-height: 200px;">
                        <v-btn class="mb-2" small @click="AddConditionCheck(EditAutoCond,'Condition')">Add (Optional)</v-btn>
                     
                        <div  v-if="EditAutoCond.Steps && EditAutoCond.Steps.length>0">
                        <v-list dense class="my-1 mx-1 basicoutlined" v-for="andstep in EditAutoCond.Steps" :key ="andstep.itemObjKey">  
                   
                      <v-list-item>
                        
                        <v-list-item-content>
                          {{andstep.StepNumber}}
                          </v-list-item-content>
                          <v-list-item-content>
                          
                          <v-select @change="RefreshAutoDialog()"  label="Condition" :items="['IF','ALWAYS']" v-model="andstep.Condition" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Condition">
                              <v-combobox @change="RefreshAutoDialog()" :items="AllCurrentBuildRelatedBuilds" item-text="Main" return-object label="Record" v-model="andstep.Record"></v-combobox>
                              </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="andstep.Condition !== 'ALWAYS'">
                        <v-list-item-content v-if="andstep.Record">
                          <v-select  label="Field" :items="AndStepRecordFields(andstep.Record)" item-text="DisplayName" v-model="andstep.FieldtoCheck" size="1" return-object></v-select>

                          </v-list-item-content> 
                          
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type !== 'Date' && andstep.FieldtoCheck.Type !== 'Lookup'">
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Single Line Text' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Multiple Lines Text'" label="Parameter" :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Boolean'" label="Parameter" :items="['true','false','false or undefined']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Single File Upload'" label="Parameter" :items="['Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Option Set' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Global Option Set'" label="Parameter" :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Number' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Calculated Field'" label="Parameter" :items="['===','!==','<','>','=>','<=','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'" :items="['On','Not On','On or After','On or Before']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Parameter && andstep.FieldtoCheck.Type !== 'Date' && andstep.FieldtoCheck.Type !== 'Lookup' && andstep.FieldtoCheck.Type !== 'Boolean'">
                          <v-select label="Value" item-text="Name" return-object v-if="andstep.FieldtoCheck.Type === 'Option Set' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Global Option Set'" :items="andstep.FieldtoCheck.Options" v-model="andstep.Value"></v-select>
                          <v-text-field label="Value" v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type !== 'Option Set'  && andstep.FieldtoCheck.Type !== 'Global Option Set' && andstep.Parameter !== 'Contains Data' && andstep.Parameter !== 'Does Not Contain Data'" v-model="andstep.Value"></v-text-field>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Parameter && andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Boolean'">
                          <v-switch v-if="andstep.Parameter === 'true'" :value="true" readonly filled></v-switch>
                          <v-switch  v-if="andstep.Parameter === 'false'" :value="false" readonly filled></v-switch>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'">
                            <v-select label="Date Check Type" :items="['Contains Data','Does Not Contain Data','Specific Date','Preset Options','Other Field','STD Date']" v-model="andstep.DateParameter"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.FieldtoCheck.RelatedBuildID !== RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field']" v-model="andstep.LookupParameter"></v-select>
                          </v-list-item-content>  
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.FieldtoCheck.RelatedBuildID === RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field','Current Record']" v-model="andstep.LookupParameter"></v-select>
                          </v-list-item-content>                        
                          <v-list-item-content v-if="andstep.DateParameter && andstep.FieldtoCheck.Type && andstep.FieldtoCheck.Type === 'Date'">
                          <v-select  v-if="andstep.DateParameter === 'Other Field' || andstep.DateParameter === 'STD Date'" :items="['===','!==','On or After','On or Before','Older Than','Later Than','Same Calendar Year','Same Fiscal','Same Fiscal Period']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.DateParameter === 'Specific Date'" :items="['===','!==','On or After','On or Before']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.LookupParameter && andstep.FieldtoCheck.Type && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-select  v-if="andstep.LookupParameter === 'Other Field'" :items="['===','!==','Same Business Unit','Same Owner']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>                                           
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date' && andstep.DateParameter && andstep.DateParameter === 'Preset Options' || andstep.DateParameter && andstep.Parameter && andstep.FieldtoCheck.Type === 'Date'">
                          <v-select label="Value" v-if="andstep.DateParameter === 'Preset Options'" :items="['This Fiscal','Last Fiscal','This Fiscal Period','Last Fiscal Period','This Calendar Year']" v-model="andstep.Value"></v-select>
                          <v-menu  v-if="andstep.DateParameter === 'Specific Date'"
                            v-model="andstep.DateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="andstep.Value"
                                label="Date"
                                prepend-icon="mdi-calendar-month"
                                readonly
                                v-on="on">
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="andstep.Value" @input="andstep.DateMenu = false"></v-date-picker>
                            </v-menu>                          
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.LookupParameter && andstep.LookupParameter === 'Preset Options' || andstep.LookupParameter && andstep.Parameter && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-select label="Value" v-if="andstep.LookupParameter === 'Preset Options'" :items="['Contains Data','Does Not Contain Data']" v-model="andstep.Value"></v-select>           
                          </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'">
                          <v-list-item-content v-if="andstep.DateParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="andstep.DateCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.DateParameter === 'Other Field' && andstep.DateCheckRecord" >
                          <v-select label="Value" :items="andstep.DateCheckRecord.DateFields" v-model="andstep.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.DateParameter === 'STD Date'" >
                          <v-select label="Value" :items="['Today']" v-model="andstep.Value"></v-select>
                          </v-list-item-content>                    
                          </v-list-item> 
                          <v-list-item v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-list-item-content v-if="andstep.LookupParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="andstep.LookupCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.LookupParameter === 'Other Field' && andstep.LookupCheckRecord">
                          <v-select label="Matching Lookup" :items="andstep.LookupCheckRecord.LookupFields" v-model="andstep.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>                    
                          </v-list-item>                          
                          <v-list-item>
                          <!-- <v-list-item-content v-if="andstep.Value || andstep.DateParameter === 'Contains Data' || andstep.DateParameter === 'Does Not Contain Data' || andstep.Parameter === 'Contains Data' || andstep.Parameter === 'Does Not Contain Data'">
                            <v-checkbox label="Process Action(s) Here?" v-model="andstep.TriggersAction"></v-checkbox>
                          <v-combobox v-if="andstep.TriggersAction" label="Select Action(s)" :items="EditedAutomation.AutomationActions" item-text="MethodName" v-model="andstep.ResultActions" size="1" multiple></v-combobox>
                          </v-list-item-content> -->
                          </v-list-item> 
                        </v-list>
                        </div>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                    <v-card-text>
                         <v-list>
                           Select (Multiple) Below or  
                            <v-btn small text plain @click="AddAutomationAction()">Add New</v-btn>
                            <v-tooltip
                                top max-width="300"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-combobox  v-bind="attrs"
                                    v-on="on" label="Select Actions for Condition" :items="ShortenedAutomationActions" item-text="MethodName" v-model="EditAutoCond.ResultActions" size="1" multiple></v-combobox>
                                </template>
                                <span>Which of the Actions within this Automation will take place when the Condition {{EditAutoCond.Name}} is Met?</span>
                              </v-tooltip>
                            
                             <v-chip-group
                            class="background"
                                  active-class="primary--text"
                                  column
                              >
                              <v-chip
                              class="pop" dark
                                  @click="AssignEditAutoAction(act)"
                                  small  v-for="act in EditAutoCond.ResultActions"
                                  :key="act.itemObjKey"
                                  >
                                  {{act.MethodName}}
                                  </v-chip>                           
                              </v-chip-group>
                              <v-text-field v-if="EditAutoAction.MethodName" v-model="EditAutoAction.MethodName" label="Method Name"/>
                              <v-select v-if="EditAutoAction.MethodName" :items="['Create','Update','Send Email']" label="Action Type" v-model="EditAutoAction.ActionType"></v-select>
                        </v-list>
                       
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="5">
                    <v-card-text>
                        <v-combobox v-if="EditAutoAction.ActionType === 'Create'" :items="BasicSystemEntities" item-text="DisplayName" return-object label="Record" v-model="EditAutoAction.Record"></v-combobox>
                        <v-combobox v-if="EditAutoAction.ActionType === 'Update'" :items="AllCurrentBuildRelatedBuilds" item-text="Main" return-object label="Record" v-model="EditAutoAction.Record"></v-combobox>
                        <v-card-text v-if="EditAutoAction.ActionType === 'Update'">
                        Update State?
                        </v-card-text>
                        <v-card-text v-if="RelatedRecordState && EditAutoAction.ActionType === 'Update'">
                        <v-select v-model="EditAutoAction.StatusUpdate" :items="RelatedRecordState.Options" return-object item-text="Name" label="Status"/>
                        <v-select v-if="EditAutoAction.StatusUpdate" :items="EditAutoAction.StatusUpdate.Options" return-object item-text="Name"
                        v-model="EditAutoAction.StatusReasonUpdate" label="Status Reason"/>
                        </v-card-text>
                        <v-select @change="LogFieldMapping(EditAutoAction.FieldMapping)" v-if="EditAutoAction.Record && !EditAutoAction.StatusUpdate" return-object :items="AutoActRecordFields" multiple item-text="id" label="Mapped Fields" v-model="EditAutoAction.FieldMapping"></v-select>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="6">
                    <v-card-text>
                      Select Field to configure Mapping:
                      <v-list-item>
                        
                            <v-chip-group
                            class="background"
                                  active-class="primary--text"
                                  column
                              >
                              <v-chip
                              class="pop" dark
                            @click="AssignFieldFilter(field)"
                            small  v-for="field in EditAutoAction.FieldMapping"
                            :key="field.itemObjKey"
                            >
                            {{field.Name}}
                            </v-chip>                           
                        </v-chip-group>
                    </v-list-item>
                         <!-- <v-select @change="RemoveFieldInputs()" clearable :items="FieldInputOptions" label="Input" v-model="FieldFilter.InputType"></v-select>
                         <v-divider></v-divider> -->
                         <v-list-item dark two-line class="headline blue-grey darken-2 white--text">
                    
                            <v-list-item-avatar>
                            <v-icon @click="fielddrawer = !fielddrawer">mdi-eye-off-outline</v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content>
                            <v-list-item-title class="red--text"><strong>{{FieldFilter.DisplayName}}</strong></v-list-item-title>
                            <v-list-item-subtitle>Input Configuration</v-list-item-subtitle>
                            
                            <v-select @change="RemoveFieldInputs()" clearable :items="FieldInputOptions" v-model="FieldFilter.InputType"></v-select>
                            </v-list-item-content>
                            <v-btn left bottom fab icon @click="AddAutomationFieldInput()" v-if="FieldFilter.InputType === 'Combined Value'"><v-icon>mdi-plus-thick</v-icon></v-btn>
                        </v-list-item>
                        <v-list dense v-if="FieldFilter && FieldFilter.InputType === 'Custom Value'">
                          <v-list-item
                            v-if="FieldFilter.Type === 'Boolean' && FieldFilter.Input"
                            class="fieldmenuitem">
                            <v-list-item-content>
                              <v-switch label="True or False"  v-model="FieldFilter.Input[0].Value"></v-switch>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-list dense v-if="FieldFilter.InputType === 'Combined Value'">
                          <v-list-item
                            v-for="(item,i) in FieldFilter.Input"
                            :key="item.itemObjKey"
                            class="fieldmenuitem"

                          >
                          

                            <v-list-item-content>
                              <v-list-item-title>{{ item.DisplayName }}</v-list-item-title>

                            </v-list-item-content>
                            <v-list-item-icon @click="ActivateAutomationFieldDialog(item,i)">
                              <v-icon  class="spin">mdi-cogs</v-icon>
                            </v-list-item-icon>
                          </v-list-item>

                        </v-list>
                        <v-list dense v-if="FieldFilter.InputType === 'Calculated Value'">
                          <v-list-item class="fieldmenuitem">                  
                            <v-list-item-content>
                              <v-select label="Output Type" v-model="FieldFilter.CalculatedOutputType" :items="['String','Number','Date']"></v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                        <v-list dense v-if="FieldFilter.InputType === 'Calculated Value' && FieldFilter.CalculatedOutputType">
                            <v-btn @click="AddAutomationFieldInput()" v-if="FieldFilter.InputType === 'Calculated Value'">Add Field</v-btn>
                          <v-list-item
                            v-for="(item,i) in FieldFilter.Input"
                            :key="item.itemObjKey"
                            class="fieldmenuitem"
                          >
                            <v-list-item-content>
                              <v-list-item-title>{{ item.DisplayName }}</v-list-item-title>
                          
                            </v-list-item-content>
                            <v-list-item-icon v-if="FieldFilter.Type !== 'Date'" @click="ActivateCalculatedNumberFieldMapDialog(item,i)">
                              <v-icon  class="spin">mdi-cogs</v-icon>
                            </v-list-item-icon>
                            <v-list-item-icon v-if="FieldFilter.Type === 'Date'" @click="ActivateCalculatedDateFieldMapDialog(item,i)">
                              <v-icon  class="spin">mdi-cogs</v-icon>
                            </v-list-item-icon>
                          </v-list-item>
                        </v-list>
                        <v-list dense v-if="FieldFilter.InputType === 'Parent Field'">
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon >mdi-database</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-select :items="AllCurrentBuildRelatedBuilds" @change="AssignFieldFilter(FieldFilter)" item-text="Main" clearable label="Source Record" v-model="FieldFilter.source" return-object></v-select>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon >mdi-text-recognition</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>                              
                              <v-select v-if="FieldFilter.source" :items="FieldFilterRelevantFields" clearable label="Source Field" item-text="DisplayName" v-model="TempParentInput" @change="AssignFieldOutput()" return-object></v-select>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="7">
                    <v-card-text>
                      <v-list v-if="MutatedEditAutoCond && MutatedEditAutoCond.Steps && MutatedEditAutoCond.Steps.length>0">
                              <v-list-item-title class="mx-1 green--text">
                                  Quality Checks:
                              </v-list-item-title> 
                              <v-list-item v-for="condstep in MutatedEditAutoCond.Steps" :key="condstep.itemObjKey">   
                                   <v-icon>mdi-check-decagram-outline</v-icon>{{condstep.CheckParams}}   
                              </v-list-item>

                          </v-list>
                          <v-list>
                            <v-list-item-title class="mx-1 green--text">
                                  Actions:
                              </v-list-item-title> 
                            <v-list-item v-for="act in EditAutoCond.ResultActions" :key="act.itemObjKey">
                              <v-icon>mdi-clipboard-list-outline</v-icon>{{act.MethodName}}
                            </v-list-item>
                          </v-list>
                    </v-card-text>
                    <v-card-text>
                      <v-chip-group active-class="primary--text"
                                  column>
                        <v-chip @click="QuickNavtoWindowStep(window)" v-for="window in WindowOptions" :key="window.itemObjKey">
                          {{window.Name}}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    :disabled="workflowstep === 1"
                    text
                    @click="ReverseWorkFlowStep()"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    :color="WorkFlowStepColor"
                    depressed
                    :disabled="WorkflowNextDisabled"
                    @click="AdvanceWorkFlowStep()"
                >
                    {{WorkflowStepButton}}
                </v-btn>
                </v-card-actions>
            </v-card>
        <!-- </v-dialog> -->
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    name: 'WorkFlowBuilder',
    props: ['System','SystemEntities','CurrentEntity'],
    components: {},
    data() {
        return {
            BuildAutomations: [],
            SavingAutomations: false,
            AutoFieldeditItem: '',
            AutomationFielddialog: false,
            TempParentInput: '',
            CalculatedNumberFieldMapDialog: false,
            LastAutomationInteger: 1000000,
            EditAutoCond: {},
            EditAutoAction: {},
            FieldFilter: {Output: '', Input:[], Name: {}, InputType: 'Parent Field'},
            FieldFilterSourceFields: [],
            DefaultFieldFilter: {Output: '', Input:[], Name: {}, InputType: 'Parent Field'},
            EditedAutomation: {
          },
            // DefaultEditedAutomation: {},
            workflowstep: 1,
        }
    },
    computed:{
      BasicSystemEntities(){
        return this.SystemEntities.map(entity => {
          let entityobj = {
            id: entity.id,
            DisplayName: entity.DisplayName,
            datasetsecuritystructure: entity.datasetsecuritystructure,
            RecordPrimaryField: entity.RecordPrimaryField,
          }
          if(entity.Table_Icon){
            entityobj.Table_Icon = entity.Table_Icon
          }
          return entityobj
        })
      },
      ComputedFieldFilterSourceFields(){
        return this.FieldFilterSourceFields.map(field => {
          let fieldobj = Object.assign({},field)
          delete fieldobj.Array
          delete fieldobj.MappedValues
          delete fieldobj.RecordData
          return fieldobj
        })
      },
      MutatedEditAutoCond(){
        let obj = Object.assign({},this.EditAutoCond)
        if(obj && obj.Steps){
          obj.Steps = obj.Steps.map(condstep => {
          if(condstep.LookupParameter === 'Preset Options' && condstep.FieldtoCheck){
                            if(condstep.Value === 'Contains Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Value === 'Does Not Contain Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Value === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set'){
                                    condstep.CheckParams = record[condstep.FieldtoCheck.Name].Name+' '+condstep.Value.Name
                                }                                
                            }
                            
                            else {
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value  
                            }
                        }
                        else if(condstep.FieldtoCheck){
                            let fieldcheck = condstep.FieldtoCheck
                            if(condstep.Parameter === 'Contains Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Parameter === 'Does Not Contain Data'){
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value
                            }
                            else if(condstep.Parameter === '==='){
                                if(condstep.FieldtoCheck.Type === 'Option Set' && condstep.Value){
                                    condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Parameter+' '+condstep.Value.Name
                                }                                
                            }
                            else if(condstep.FieldtoCheck.Type === 'Boolean'){
                              condstep.CheckParams = condstep.FieldtoCheck.Name+' === '+condstep.Parameter 
                            }
                            else {
                                condstep.CheckParams = condstep.FieldtoCheck.Name+' '+condstep.Value   
                            }
                        }
                        return condstep
        })
        }
        
        return obj
      },
        RelatedRecordState(){
      if(this.RelatedRecord){
        let statusobj = this.RelatedRecord.AllFields.find(obj => obj.Name === 'Status')
        if(statusobj){
          return statusobj
        }
      }
    },
        
        SystemConfig(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID)
        },
        ConfigDB(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.CurrentEntity.id).collection('automations')
        },
        AllFields() {
        return this.CurrentEntity.AllFields
        },
        LookupFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Lookup'
            })
        },
        DateFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Date'
            })
        },
        NumberFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Number Field'
            })
        },
        ParentFields(){
            return this.CurrentEntity.AllFields.filter(field => {
                return field.Type === 'Parent Field'
            })
        },
        MappedFields(){
        return this.AutoActRecordFields.filter(field => {
            return field.Input && field.Input.length > 0
        })
        .map(field => {
          let fieldobj = Object.assign({},field)
          fieldobj.Input = fieldobj.Input.map(input => {
            delete input.Array
            return input
          })
          delete fieldobj.Array
          return fieldobj
        })
    },
        
        RelatedFields(){
        return this.AllRelatedFields.filter(field => {
          return field.Type === 'Single Line Text' || field.Type === 'Date' || field.Type === 'Number'
        })
      },
      AllRelatedFields(){
          let arr1 = this.AllFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let AllLookupBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
             
              return AllLookupBuildFields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let AllLookupBuildLookups = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return AllLookupBuildLookups
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let AllParentBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })

              
              return AllParentBuildFields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyDateFields(){
        let arr1 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
            
             
              return builddatefields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return builddatefields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let builddatefields = build.AllFields.filter(field => {return field.type === 'Date'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return builddatefields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyNumberFields(){
        let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
            
             
              return buildnumberfields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      DatesandNumberFields(){
          let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr2 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  // console.log('field')
                  // console.log(field)
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('this.LookupBuildsLookups',this.LookupBuildsLookups)
          // console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          // console.log('this.ParentBuildsLookups',this.ParentBuildsLookups)
          // console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.AllFields.filter(field => {return field.type === 'Number Field'}).map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          // console.log('arr6')
          // console.log(arr6)
          //   console.log('arr5')
          // console.log(arr5)
          // console.log('arr4')
          // console.log(arr4)
          let arr3 = arr1.concat(arr2,arr4,arr5,arr6)
          return arr3
      },
      
      LookupBuilds() {
      return this.SystemEntities.map(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        
        if(lookupobj){
          //console.log('this.LookupBuilds lookupobj',lookupobj)
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
         // console.log('this.LookupBuilds newbuild',newbuild)          
        }
        return newbuild
      }).filter(build => {
        return build.SourceField
      })
      },
      ParentBuilds() {
      return this.SystemEntities.map(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.RelatedRecord = lookupobj.RelatedRecord
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          }
        return newbuild
      }).filter(build => {
        return build.SourceField
      })
      },
      CreateActionBuilds() {
        if(this.EditedAutomation && this.EditedAutomation.AutomationActions){
        let NewActions = this.EditedAutomation.AutomationActions
        let NewCreateActions = NewActions.filter(action => {
            return action.ActionType === 'Create' && action.Record && action.Record.id
          })
          // console.log('this.AutomationActions')
          // console.log(NewCreateActions)
          return NewCreateActions.map(action => {
        // let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        // if(lookupobj){
          //console.log('source action',action)
          let NewBuildsArray = Array.from(this.SystemEntities)
          
          let buildobj = NewBuildsArray.find(obj => obj.id === action.Record.id)
          if(buildobj){
          let actionbuild = {DisplayName: buildobj.DisplayName,id: buildobj.id, AllFields: buildobj.AllFields}          
          actionbuild.SourceField = action.MethodID
          actionbuild.sourcename = action.Record.DisplayName+' ('+action.MethodName+')'
          actionbuild.Main = actionbuild.sourcename
          actionbuild.Relationship = 'Create Method Build'
          actionbuild.RelationshipField = actionbuild.SourceField
          actionbuild.source = action.Record
          return actionbuild
          }
        // }
      })
        }
        else return []
      },
      ParentBuildsLookups() {
          let LookupBuildsArray = Array.from(this.ParentBuilds)
          
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
                let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
                if(lookupobj){
                    newbuild.SourceField = build.SourceField
                    newbuild.ParentSourceField = lookupobj.Name
                    newbuild.Parentsourcename = build.DisplayName
                    return newbuild
                }
        })
        return newarray       
      }).flat()
      },
      ParentLookupBuilds() {
      return this.SystemEntities.filter(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          return newbuild
        }
      })
      },
      LookupBuildsLookups() {
          let LookupBuildsArray = Array.from(this.LookupBuilds)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
            let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
            if(lookupobj){
                newbuild.SourceField = build.SourceField
                newbuild.ParentSourceField = lookupobj.Name
                newbuild.Parentsourcename = build.DisplayName
                return newbuild
            }
        })
        return newarray       
      }).flat()
      },
      
      LookupBuildsParents() {
          let LookupBuildsArray = Array.from(this.LookupBuildsParents)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
        let newbuild = Object.assign({},lookupbuild)
        let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
        if(lookupobj){
            newbuild.SourceField = build.SourceField
            newbuild.ParentSourceField = lookupobj.Name
            newbuild.Parentsourcename = build.DisplayName
          return newbuild
        }
        })
        return newarray       
      }).flat()
      },
      RelatedNumberFields(){
          let relbuildobj = this.SystemEntities.find(obj => obj.id === this.RelatedRecord.id)
          return relbuildobj.AllFields.filter(yyfield => {return field.Type == 'Number Field'})
      },
      FieldsforAutomation(){
      if(typeof this.FieldFilter !== 'undefined'){
      // if(this.FieldFilter.Type === 'Single Line Text'){
      //   return this.AllFields
      // }
      // else {
      // return this.AllFields.filter(field => {
      //  return field.Type === this.FieldFilter.Type && field.Type !== 'Option Set'
      // })
      // }
        return this.FieldFilter.Input
      }
    },
      AllCurrentBuildRelatedBuilds(){
        //    if(!this.SavingAutomations){
      let NewLookupBuilds = Array.from(this.LookupBuilds)
      let NewParentBuilds = Array.from(this.ParentBuilds)
      // console.log('this.ParentBuilds',this.ParentBuilds)
      // console.log('this.LookupBuilds',this.LookupBuilds)
      let arr1 = NewLookupBuilds.map(build => {
        //console.log('this.LookupBuilds NewLookupBuilds build',build)
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Lookup Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      let CurrentBuildObj = this.CurrentEntity
      CurrentBuildObj.Main = CurrentBuildObj.DisplayName
      CurrentBuildObj.Relationship = 'Build Self'
      let arr2 = [CurrentBuildObj]
      let arr3 = NewParentBuilds.map(build => {
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Parent Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      // console.log('arr1',arr1)
      // console.log('arr3',arr3)
      let arr4 = []
     
          arr4 = this.CreateActionBuilds
      //console.log('source arr1',arr1,'arr2',arr2,'arr3',arr3,'arr4',arr4)
      return arr1.concat(arr2,arr3,arr4).map(source => {
        let sourceobj = {}
        //console.log('source',source)
              if(source.Main && source.Relationship && source.RelationshipField){
                sourceobj = {Relationship: source.Relationship, RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(source.Main && source.Relationship && !source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(source.Main && !source.Relationship && !source.RelationshipField){
                 sourceobj = {id: source.id, DisplayName: source.DisplayName, Main: source.Main}
              }
              else if(!source.Main && source.Relationship && source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName, }
              }
              else if(source.Main && !source.Relationship && source.RelationshipField){
                 sourceobj = {RelationshipField: source.RelationshipField, id: source.id, DisplayName: source.DisplayName,  Main: source.Main}
              }
              else if(!source.Main && source.Relationship && !source.RelationshipField){
                 sourceobj = {Relationship: source.Relationship, id: source.id, DisplayName: source.DisplayName, }
              }
              else{
                sourceobj = {id: source.id, DisplayName: source.DisplayName, }
              }
              if(source.RelatedRecord){
                sourceobj.RelatedRecord = source.RelatedRecord
              }
              if(source.sourcename){
                sourceobj.sourcename = source.sourcename
              }
              if(source.SourceField){
                sourceobj.SourceField = source.SourceField
              }
              if(source.ParentSourceField){
                sourceobj.ParentSourceField = source.ParentSourceField
              }
              if(source.Parentsourcename){
                sourceobj.Parentsourcename = source.Parentsourcename
              }
              if(source.RecordPrimaryField){
                sourceobj.RecordPrimaryField = source.RecordPrimaryField
              }
              if(source.Table_Icon){
                sourceobj.Table_Icon = source.Table_Icon
              }
              return sourceobj
      })
    //   }
    //   else{
    //       return []
    //   }
    },
    FieldFilterRelevantFields(){
        // console.log('this.FieldFilter',this.FieldFilter)
      if(this.FieldFilter && this.FieldFilter.source && this.ComputedFieldFilterSourceFields){
       if(this.FieldFilter.Type === 'Single Line Text'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Single Line Text'
      })
       }
       else if(this.FieldFilter.Type === 'Radio Group'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Radio Group'
      })
       }
       else if(this.FieldFilter.Type === 'Common Field'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === this.FieldFilter.Type && field.CommonFieldType === this.FieldFilter.CommonFieldType
      })
       }
       else if(this.FieldFilter.Type === 'Multiple Lines Text'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        return field.Type === 'Multiple Lines Text' || field.Type === 'Single Line Text'
      })
       }
       else if(this.FieldFilter.Type === 'Number Field'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Number Field' || field.Type === 'Calculated Field' || field.Type === 'Rollup Calculation'
            })
          }
          else if(this.FieldFilter.Type === 'Date'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.Type === 'Option Set'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Global Option Set'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Global Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Boolean'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
          else if(this.FieldFilter.Type === 'Single File Upload'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single File Upload'
            })
          }
       else if(this.FieldFilter.Type === 'Lookup'){
      return this.ComputedFieldFilterSourceFields.filter(field => {
        if(field.Primary){
        // console.log('buildfield')
        // console.log(field)
        // console.log('this.FieldFilter.source')
        // console.log(this.FieldFilter.source)
        }
        return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.RelatedBuildID || this.FieldFilter.source.id === this.FieldFilter.RelatedBuildID && field.Primary
      })
       }
       else if(this.FieldFilter.Type === 'Parent Field'){
         if(this.FieldFilter.ParentFieldObj.Type === 'Lookup'){
           return this.ComputedFieldFilterSourceFields.filter(field => {
              return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID
      })
         }
         else if(this.FieldFilter.ParentFieldObj.ParentFieldObj && this.FieldFilter.ParentFieldObj.ParentFieldObj.Type === 'Lookup'){
           return this.ComputedFieldFilterSourceFields.filter(field => {
              return field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.ParentFieldObj && field.ParentFieldObj.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID
          })
         }
         else if(this.FieldFilter.ParentFieldObj.Type === 'Single Line Text'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single Line Text'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Number Field'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Number Field'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Date'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Boolean'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Single File Upload'){
          return this.ComputedFieldFilterSourceFields.filter(field => {
            return field.Type === 'Single File Upload'
            })
          }
       }
      }
    },
        FieldInputOptions(){
      if(this.FieldFilter.Type === 'Single Line Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Common Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Multiple Lines Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Radio Group'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Global Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Number Field' || this.FieldFilter.Type === 'Date'){
        return ['Parent Field','Calculated Value']
      }
      if(this.FieldFilter.Type === 'Lookup' || this.FieldFilter.Type === 'Parent Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Boolean'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Single File Upload'){
        return ['Parent Field']
      }
    },
        RelatedRecord(){
            if(this.EditAutoAction && this.EditAutoAction.Record && this.EditAutoAction.Record.id){
                return this.SystemEntities.find(obj => obj.id === this.EditAutoAction.Record.id)
            }
        },
        AutoActRecordFields(){
            if(this.RelatedRecord){
                return this.RelatedRecord.AllFields
                // .filter(field => {
                //     return field.IsHeader
                // })
            }
            else{
                return []
            }
        },
        
        NewAutomation(){
            let NewAutomation = {
              ID: this.LastAutomationInteger,
              Name: 'New Automation',
              AutomationActions: [],
              Conditions: []
          }
          return NewAutomation
        },
        BuildAutomationsShortened(){
      let NewArray = Array.from(this.BuildAutomations)
      let AutoArray = NewArray.map(auto => {
        if(auto.AutomationActions.length >0){
        let Actions = auto.AutomationActions
        let Conditions = auto.Conditions.map(condition => {
          condition.ResultActions = condition.ResultActions.map(resultact => {            
            return {MethodID: resultact.MethodID, MethodName: resultact.MethodName}
          })
          let buttonobj = this.ConditionswithButtons.find(obj => obj.StepNumber === condition.StepNumber)
          if(buttonobj && buttonobj.ApprovalButtonText){
            condition.ApprovalButtonText = buttonobj.ApprovalButtonText
          }
          return condition
        })
        auto.Conditions = Conditions
        auto.AutomationActions = Actions
        }
        return auto
      
      })
      return AutoArray
    },    
    ConditionswithButtons(){
      let NewArray = Array.from(this.BuildAutomations)
      return NewArray.map(auto => {
        return auto.Conditions
      }).flat().filter(cond => {
        return cond.CallfromButton
      })
    },
    ShortenedAutomationActions(){
      if(this.EditedAutomation && this.EditedAutomation.AutomationActions){
        // console.log("this.EditedAutomation",this.EditedAutomation)
      return this.EditedAutomation.AutomationActions
      // .map(auto => {
      //   return {MethodID: auto.MethodID, MethodName: auto.MethodName, FieldMapping: auto.FieldMapping}
      // })
      }
    },
        WorkflowStepButton  (){
           switch (this.workflowstep) {
                case 7: return this.EditedAutomation.id? 'Update' : 'Create'
                default: return 'Next'
            }
        }, 
        WorkFlowHeaderName(){
           switch (this.workflowstep) {
                case 2: return this.EditedAutomation.Name
                case 3: return this.EditedAutomation.Name
                case 4: return this.EditedAutomation.Name
                case 5: return this.EditedAutomation.Name
                case 6: return this.EditedAutomation.Name
                case 7: return this.EditedAutomation.Name
                default: return 'Pipeline Workflow Builder'
            }
        }, 
        WorkFlowTitleName(){
           switch (this.workflowstep) {
                case 1: return 'Select/ Create'
                case 2: return 'Select/ Create'
                case 3: return 'Define Quality Checks'
                case 4: return 'Select/ Create'
                case 5: return 'Select Data Table'
                case 6: return 'Select Field'
                case 7: return 'Review'
                default: return 'Pipeline Workflow'
            }
        }, 
        WorkFlowStepName(){
           switch (this.workflowstep) {
                case 1: return 'Entity Automation'
                case 2: return 'Conditions'
                case 3: return '(Optional)'
                case 4: return 'Action'
                case 5: return 'and Fields for Mapping'
                case 6: return 'and Confgure Mapping'
                case 7: return 'Quality Check and Actions'
                default: return 'List'
            }
        }, 
        WindowOptions(){
        let options = ['Automation','Conditions','Checks','Actions','Action Record','Field Mapping']
        return options
        .map((opt,index) => {
          let optpbj = {
            Name: opt,
            Step: index-1+2
          }
          return optpbj
        })
        .filter(opt => {
          if(this.EditAutoAction.StatusUpdate){
            return opt.Name !== 'Field Mapping'
          }
          else{
            return opt
          }
        })
      },
        WorkflowNextDisabled(){
          switch (this.workflowstep) {
                case 1: return !this.EditedAutomation.Name
                case 2: return !this.EditAutoCond.Name
                case 3: return false
                case 4: return !this.EditAutoAction.MethodID
                case 5: return !this.RelatedRecord
                case 6: return !this.EditAutoAction.FieldMapping
                case 7: return false
                default: return 'List'
            }
        },
        WorkFlowStepColor(){
           switch (this.workflowstep) {
                case 7: return 'red'
                default: return 'green'
            }
        },
    },
    created(){
        if(this.System.LastAutomationInteger){
            this.LastAutomationInteger = this.System.LastAutomationInteger
        }
        this.BuildAutomations = JSON.parse(JSON.stringify(this.CurrentEntity.automations))
        this.BuildAutomations.map(auto => {
            auto.Conditions.map(cond => {
                cond.ResultActions = cond.ResultActions.map(resact => {
                    let actionobj = auto.AutomationActions.find(obj => obj.MethodID === resact.MethodID)
                    // actionobj.FieldMapping = actionobj.FieldMapping.map(field => {
                    //   let sourcematch = this.SystemEntities.find(obj => obj.id === field.source.id)
                    //   field.source.AllFields = JSON.parse(JSON.stringify(sourcematch.AllFields))
                    //   return field
                    // })
                    return actionobj
                })
            })
        })
        console.log('this.BuildAutomations',this.BuildAutomations)
    },
    methods:{
      AndStepRecordFields(Record){
        let entity = this.SystemEntities.find(obj => obj.id === Record.id)
        return entity.AllFields
      },
      LogFieldMapping(FieldMapping){
        console.log(FieldMapping,this.EditAutoCond,this.EditAutoAction)
      },
      QuickNavtoWindowStep(window){
        this.workflowstep = window.Step
      },
      AssignEditAutomation(auto){
        this.EditedAutomation = {}
        this.EditedAutomation = auto
      },
      AssignEditAutoCond(cond){
        this.EditAutoCond = {}
        this.EditAutoCond = cond
      },
      AssignEditAutoAction(act){
        this.EditAutoAction = this.EditedAutomation.AutomationActions.find(obj => obj.MethodID === act.MethodID)
        act = this.EditAutoAction
      },
      ToggleAutomationDialog(){
        this.$emit('ToggleAutomationDialog')
      },
        AddConditionCheck(child,type){
       if(typeof child.Steps === 'undefined'){
        child.Steps = []
        let stepnumberstring = child.StepNumber+'.1'
        // console.log(stepnumberstring)
        let stepnumber = Number(stepnumberstring)
        // console.log(stepnumber)
        let NewStep = {
        Name: 'Name',
        StepNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAutomation.Conditions.indexOf(child)
     
      child.Steps.push(NewStep)
      }
      else{
        // console.log(child)
        let stepsuffix = child.Steps.length-1+2
      let stepnumberstring = child.StepNumber+'.'+stepsuffix
      // console.log(stepnumberstring)
      let stepnumber = Number(stepnumberstring)
      // console.log(stepnumber)
      let NewStep = {
        Name: 'Name',
        StepNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAutomation.Conditions.indexOf(child)
     
      child.Steps.push(NewStep)
      }
      
      // console.log(this.EditedAutomation.Conditions)
    },
        ActivateAutomationFieldDialog(field, fieldindex) {
        this.AutomationFielddialog = true
        this.AutoFieldeditItem = field
        },
        AssignFieldOutput(){
        console.log(this.FieldFilter,this.TempParentInput,this.EditAutoAction)

        if(this.FieldFilter.InputType === 'Parent Field'){
            this.TempParentInput.MapType = 'Parent Field'
            this.FieldFilter.Input = []
            this.FieldFilter.Input.push(this.TempParentInput)
            //this.FieldFilter.Input[0].ParentInput = Object.assign({}, this.TempParentInput)
            this.FieldFilter.Output = this.FieldFilter.Input[0].DisplayName
        }
        else{
            //alert('this is something else mate')
            let inputarray = this.FieldFilter.Input.map(input => {
            // if(this.FieldFilter.InputType !== 'Custom Value'){
                return input.DisplayName
            // }
            // else{
            //   return input
            // }            
            })
            let objectstring = '' + inputarray
            let finalstring = objectstring.split(',').join(' ')
            this.FieldFilter.Output = finalstring
        }
        },
            AddAutomationFieldInput(){

        // console.log('this fieldfilter')
        // console.log(this.FieldFilter)
        let fieldobject = this.FieldFilter
        let FieldInput = {
            Name: 'Field',
            DisplayName: 'Field'

        }
        let arraytest = Array.isArray(this.FieldFilter.Input)
        if(arraytest === true){
            this.FieldFilter.Input.push(FieldInput)
            this.AssignFieldOutput()
            }
            else{
            this.FieldFilter.Input = []
            this.FieldFilter.MapType = 'Parent Field'
            this.FieldFilter.Input.push(FieldInput)
            this.AssignFieldOutput()
            }

        },
            RemoveFieldInputs(){
        this.FieldFilter.Input = []
         
        if(this.FieldFilter.Type === 'Boolean'){
            this.AddAutomationFieldInput()
        }
        },
            RefreshAutoDialog(){
                this.AutomationDialog = false
                this.AutomationDialog = true
            },
            AddAutomationAction(){
            if(!this.EditedAutomation.AutomationActionLastInteger){
                this.EditedAutomation.AutomationActionLastInteger = 101
                }        
            
            let NewApprovalAction= {
                MethodName: 'New Action',
                ActionType: '',
                Record: '' ,
                MethodID: this.EditedAutomation.AutomationActionLastInteger+this.EditedAutomation.ID,
                FieldMapping: []
            }
            if(!this.EditedAutomation.AutomationActions){
                this.EditedAutomation.AutomationActions = []
            }
            this.EditedAutomation.AutomationActions.push(NewApprovalAction)
            this.EditedAutomation.AutomationActionLastInteger = this.EditedAutomation.AutomationActionLastInteger-1+2
            
            },
            AddCondition(){
            if(this.EditedAutomation.Conditions.length === 0){
                
                this.EditedAutomation.LastStepNumber = 1000
            }
            this.EditedAutomation.LastStepNumber++
                let stepsuffix = this.EditedAutomation.LastStepNumber
                let stepnumberstring = stepsuffix+this.EditedAutomation.ID
                // console.log(stepnumberstring)
                let stepnumber = Number(stepnumberstring)
                console.log(stepnumber,stepsuffix)
                let NewStep = {
                    Name: 'New Conditional Chain',
                    StepNumber: stepnumberstring,
                    Steps: [],
                    ResultActions: []
                }
                NewStep.CallfromButton = true
                NewStep.ApprovalButtonText = this.EditedAutomation.Name
                // this.EditedAutomation.NewStep.StepNumber
                this.EditedAutomation.Conditions.push(NewStep)
                // console.log(this.EditedAutomation.Conditions)
                
            },
            ReverseWorkFlowStep(){
              this.FieldFilter = Object.assign({},this.DefaultFieldFilter)
              this.TempParentInput = ''
              this.workflowstep--
              if(this.workflowstep === 6 && this.EditAutoAction.StatusUpdate){
                this.workflowstep--
              }
            },
            AdvanceWorkFlowStep(){
              this.FieldFilter = Object.assign({},this.DefaultFieldFilter)
                this.TempParentInput = ''
                if(this.workflowstep === 7){
                    this.ProcessSaveAutomation()
                }
                else if(this.workflowstep === 5 && this.EditAutoAction.StatusUpdate){
                    this.workflowstep++
                    this.workflowstep++                    
                }
                else if(this.workflowstep === 6){
                    this.AssignActionDetails()
                }
                else{
                    this.workflowstep++
                }
            },
            AssignFieldFilter(field) {
              console.log(field)
              this.FieldFilter = field
              if(typeof field.InputType !== 'undefined' && field.InputType === 'Parent Field'){
                if(this.FieldFilter.source.id === this.CurrentEntity.id){
                  this.FieldFilterSourceFields = this.AllFields
                }
                else{
                let buildobj  = this.SystemEntities.find(obj => obj.id === this.FieldFilter.source.id)
                this.FieldFilterSourceFields = buildobj.AllFields
                }
                if(field.Input.length>0){
                this.TempParentInput = field.Input[0]
                }
              }
              if(this.FieldFilter.Input){
                if(typeof this.FieldFilter.Input.length !== 'undefined'){

                }
              }
            },
            AddNewAutomation(){
              this.LastAutomationInteger++
              this.SystemConfig.update({
                LastAutomationInteger: this.LastAutomationInteger
              })
              console.log('this.BuildAutomations',this.BuildAutomations)
                this.BuildAutomations.push(this.NewAutomation)
            },
            CheckObjectforUndefined(lvl,obj,path){
      for(var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          let newpath = path+'/'+prop
          if(typeof obj[prop] === 'undefined'){
            console.log('LVL '+lvl+' prop UNDEFINED!!! '+newpath,obj)
          }
          else{
            if(!Array.isArray(obj[prop])){
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',obj[prop])
              //repeat this function somehow
              if(typeof obj[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,obj[prop],newpath)
              }
              
            }
            else{              
              //call array undefined check
              this.CheckArrayforUndefined(lvl-1+2,obj[prop],newpath)
            }
          }
        }
      }
    },
    CheckArrayforUndefined(lvl,obj,path){
      //console.log('checkingprop '+checkingprop+' on path '+path)
      obj.map(arrayrecord => {
        for(var prop in arrayrecord) {
          if (arrayrecord.hasOwnProperty(prop)) {
            let newpath = path+'/'+prop
            if(typeof arrayrecord[prop] === 'undefined'){
              console.log('LVL '+lvl+' array prop UNDEFINED!!! '+newpath,arrayrecord)
            }
            else{
              //console.log('LVL '+lvl+' prop OK '+newpath+' as ',arrayrecord[prop])
              if(!Array.isArray(arrayrecord[prop])){
                if(typeof arrayrecord[prop] !== 'string'){
                this.CheckObjectforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                }
              }
              else{
                this.CheckArrayforUndefined(lvl-1+2,arrayrecord[prop],newpath)
                //prop is array check for third prop
                // arrayrecord[prop].map(thirdrecord => {
                // })
              }
            }
          }
        }
      })
    },
    AssignActionDetails(){
        // let actionobj = this.EditedAutomation.AutomationActions.find(obj => obj.MethodID === this.EditAutoAction.MethodID)
        // actionobj.FieldMapping = this.MappedFields
        // this.EditAutoAction.FieldMapping = this.MappedFields
        // actionobj.MethodName = this.EditAutoAction.MethodName
        this.workflowstep++
    },
            ProcessSaveAutomation(){
                this.SavingAutomations = true
                this.CheckArrayforUndefined(1,this.BuildAutomationsShortened,'(BuildAutomationsShortened)')
                    let auto = this.BuildAutomationsShortened.find(obj => obj.ID === this.EditedAutomation.ID)
                  let autostring = auto.ID.toString()
                    this.ConfigDB.doc(autostring).set(auto).then(doc => {
                      console.log('updated '+autostring+' automation using this.BuildAutomationsShortened',this.ConfigDB)
                      this.$emit('ToggleAutomationDialog')
                  })  
                  setTimeout(() => {
                    this.SavingAutomations = false
                  }, 3000);
      },
    }
}
</script>

<style>

</style>
