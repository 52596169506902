<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}

          </v-card-subtitle>
        <v-card-text>
                <v-text-field  v-model="EditedTab.Title" label="Title"></v-text-field>
                              <v-select v-model="EditedTab.HeaderFontSize" :items="[12,14,18,24,36,42,50,60,75,100]"									
                            />
                            <v-switch v-if="EditedTab.Name !== 'Site Events'" v-model="EditedTab.SliderView" label="Slider View"/>
                            <v-switch v-if="EditedTab.Name === 'Site Events'" v-model="EditedTab.CalendarCardsView" label="Cards View"/>
                            <v-text-field :rules="[rules.required]" v-model="EditedTab.Excerpt" type="number" label="Excerpt"></v-text-field> 
                            <v-text-field :rules="[rules.required]" v-model="EditedTab.Slice" type="number" label="Slice"></v-text-field> 
                            <v-select v-if="System[EditedTab.CategorySystemProp] && !EditedTab.CatNavList" clearable :multiple="!System[EditedTab.CategorySystemProp].HasDeepHierarchy" label="Category" item-text="Name" :rules="[rules.required]" v-model="EditedTab.TypeCategories" :items="System[EditedTab.CategorySystemProp].Options" return-object></v-select>
                            <v-select v-if="System[EditedTab.CategorySystemProp] && EditedTab.CatNavList" clearable :multiple="!System[EditedTab.CategorySystemProp].HasDeepHierarchy" label="Primary Category" item-text="Name" :rules="[rules.required]" v-model="EditedTab.PrimaryCategoryFilter" :items="EditedTab.ListPrimaryField.Options" return-object></v-select>
                            <v-select v-if="System[EditedTab.CategorySystemProp] && EditedTab.CatNavList && EditedTab.PrimaryCategoryFilter" clearable :multiple="!System[EditedTab.CategorySystemProp].HasDeepHierarchy" label="Secondary Category" item-text="Name" :rules="[rules.required]" v-model="EditedTab.SecondaryCategoryFilter" :items="EditedTab.PrimaryCategoryFilter.Options" return-object></v-select>  
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
