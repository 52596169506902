
            <template>
  <v-content>
     <v-card width="100%" height="100%" flat color="rgba(255,255,255,0.05)">
       <v-img :src="require('@/assets/RABaseBG.jpeg')" height="150px">
       <h1 align="center" class="white--text">My Team Activities</h1>
      <v-layout class="justify-center">
       <v-badge color="pop" overlap  v-for="item in TeamUserswithActivities" :key="item.itemObjKey" dark class="mx-3">
       <v-avatar tile large><img :src="item.ProfilePic"/></v-avatar>
       <span slot="badge" v-if="item.count>0"> {{item.count }} </span>
                            </v-badge>
       </v-layout>
       </v-img>
       <v-layout row wrap class="justify-start">
       <v-flex xs6 sm6 md3 lg3 class="my-1" v-for="item in TeamUserswithActivities" :key="item.itemObjKey">
       <v-card class="mx-2" :href="'/MyTeamActivities/'+item.id">

             <v-layout row class="justify-start">
               <v-card-title>
            <v-avatar tile large><img :src="item.ProfilePic"/></v-avatar>

            <p v-if="item.count === 0">{{item.Name}} {{item.Surname}}</p>
            <p v-if="item.count > 0" class="pop--text"><strong>{{item.Name}} {{item.Surname}}({{item.count }})</strong></p>
                </v-card-title>
            </v-layout>

       </v-card>
       </v-flex>
       </v-layout>
     </v-card>
  </v-content>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'

export default {
  components: { BreadCrumbs },
  data() {
    return {
      Icons: [],
      drawer: false,
      items: [],
      itemsCount: [],
      UserFullName: '',
      UserAssignedRoles: {},
      MyActivitiesArray: [],
      UserRecord: {},
      UsersArray:[],
    }
  },
  computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },
    SubordinateUsers(){
        return this.UsersArray.filter(user => {
            return user.Managerid === this.UserRecord.id
        })
    },
    SubordinateUsersIDs(){
        return this.SubordinateUsers.map(user => {
            return user.id
        })
    },
    TeamUserswithActivities(){
        return this.SubordinateUsers.map(item => {
        let tempcount = this.MyActivitiesArray.filter(activity => {return activity.Ownerid === item.id && activity.status === 'Open'}).length
        item.count = tempcount
        return item
      })
    }


    // itemsCount(){
    //   return this.items.map(item => {
    //     item.count = this.MyActivitiesArray.filter(activity => {
    //       return activity.regardingtype === item.countname
    //     })
    //     return item
    //   })
    // },
    },

    watch: {
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          this.$router.push('/login')
        }
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

  created() {

    this.GetUsers()
    this.GetRequestingUser()
  },

  methods: {
      GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          db.collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()
          vm.UserRecord = userdetails
          vm.UserRecord.id = user.uid
          vm.GetActivities()
          vm.getUserActivityRelated()
          })
        }
      })
    },

    GetActivities(){
      db.collection('activities').where('Ownerid', 'in', this.SubordinateUsersIDs).get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.MyActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
      this.itemsCount = []
      this.items.map(item => {
        item.count = this.MyActivitiesArray.filter(activity => {
          return activity.regardingtype === item.countname
        }).length
        this.itemsCount.push(item)
      })
    })
    },


    async getUserActivityRelated() {
        var vm = this;

      await firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
        //console.log('AuthchangeLoggedIn but not dispatching userstate')
        // console.log(user.uid)
        // vm.$store.dispatch('UserState',user)
      vm.items = []
        db.collection('users').doc(user.uid).onSnapshot(snapshot => {
          var userdetails = snapshot.data()

          vm.UserFullName = userdetails.name+' '+userdetails.surname
          if(typeof userdetails.rolesarrayQuery !== 'undefined'){
          vm.UserAssignedRoles = userdetails.rolesarrayQuery

          let SystemAdminroletest = vm.UserAssignedRoles.find(obj => obj === 'System Admin')
      if(typeof SystemAdminroletest !== 'undefined'){

          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
          let Contactsitemobject = {
            icon: 'mdi-contacts',
            title: 'Contacts',
            to: '/MyActivities/Contact',
            countname: 'Contact'
          }
          let Contactsitemscheck = vm.items.find(obj => obj.title === 'Contacts')
          if(typeof Contactsitemscheck === 'undefined'){
          vm.items.push(Contactsitemobject)
          }
          let Opportunitiesitemobject = {
            icon: 'mdi-cash-register',
            title: 'Opportunities',
            to: '/MyActivities/Opportunity',
            countname: 'Opportunity'
          }
          let Opportunitiesitemscheck = vm.items.find(obj => obj.title === 'Opportunities')
          if(typeof Opportunitiesitemscheck === 'undefined'){
          vm.items.push(Opportunitiesitemobject)
          }
          let Usersitemobject = {
            icon: '',
            title: 'Users',
            to: '/MyActivities/User',
            countname: 'User'
          }
          let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
          if(typeof Usersitemscheck === 'undefined'){
          vm.items.push(Usersitemobject)
          }
          let BusinessUnitsitemobject = {
            icon: 'mdi-security-network',
            title: 'Business Units',
            to: '/MyActivities/BusinessUnit',
            countname: 'BusinessUnit'
          }
          let BusinessUnitsitemscheck = vm.items.find(obj => obj.title === 'Business Units')
          if(typeof BusinessUnitsitemscheck === 'undefined'){
          vm.items.push(BusinessUnitsitemobject)
          }
      }
          let CRMEditorroletest = vm.UserAssignedRoles.find(obj => obj === 'CRMEditor')
      if(typeof CRMEditorroletest !== 'undefined'){

          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
          let Contactsitemobject = {
            icon: 'mdi-contacts',
            title: 'Contacts',
            to: '/MyActivities/Contact',
            countname: 'Contact'
          }
          let Contactsitemscheck = vm.items.find(obj => obj.title === 'Contacts')
          if(typeof Contactsitemscheck === 'undefined'){
          vm.items.push(Contactsitemobject)
          }
          let Opportunitiesitemobject = {
            icon: 'mdi-cash-register',
            title: 'Opportunities',
            to: '/MyActivities/Opportunity',
            countname: 'Opportunity'
          }
          let Opportunitiesitemscheck = vm.items.find(obj => obj.title === 'Opportunities')
          if(typeof Opportunitiesitemscheck === 'undefined'){
          vm.items.push(Opportunitiesitemobject)
          }
          let Usersitemobject = {
            icon: '',
            title: 'Users',
            to: '/MyActivities/User',
            countname: 'User'
          }
          let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
          if(typeof Usersitemscheck === 'undefined'){
          vm.items.push(Usersitemobject)
          }
      }
          let CRMManagerroletest = vm.UserAssignedRoles.find(obj => obj === 'CRMManager')
      if(typeof CRMManagerroletest !== 'undefined'){

          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
          let Contactsitemobject = {
            icon: 'mdi-contacts',
            title: 'Contacts',
            to: '/MyActivities/Contact',
            countname: 'Contact'
          }
          let Contactsitemscheck = vm.items.find(obj => obj.title === 'Contacts')
          if(typeof Contactsitemscheck === 'undefined'){
          vm.items.push(Contactsitemobject)
          }
      }
          let CRMPleproletest = vm.UserAssignedRoles.find(obj => obj === 'CRMPlep')
      if(typeof CRMPleproletest !== 'undefined'){

          let Accountsitemobject = {
            icon: 'mdi-office-building',
            title: 'Accounts',
            to: '/MyActivities/Account',
            countname: 'Account'
          }
          let Accountsitemscheck = vm.items.find(obj => obj.title === 'Accounts')
          if(typeof Accountsitemscheck === 'undefined'){
          vm.items.push(Accountsitemobject)
          }
          let Contactsitemobject = {
            icon: 'mdi-contacts',
            title: 'Contacts',
            to: '/MyActivities/Contact',
            countname: 'Contact'
          }
          let Contactsitemscheck = vm.items.find(obj => obj.title === 'Contacts')
          if(typeof Contactsitemscheck === 'undefined'){
          vm.items.push(Contactsitemobject)
          }
          let Usersitemobject = {
            icon: '',
            title: 'Users',
            to: '/MyActivities/User',
            countname: 'User'
          }
          let Usersitemscheck = vm.items.find(obj => obj.title === 'Users')
          if(typeof Usersitemscheck === 'undefined'){
          vm.items.push(Usersitemobject)
          }
          let BusinessUnitsitemobject = {
            icon: 'mdi-security-network',
            title: 'Business Units',
            to: '/MyActivities/BusinessUnit',
            countname: 'BusinessUnit'
          }
          let BusinessUnitsitemscheck = vm.items.find(obj => obj.title === 'Business Units')
          if(typeof BusinessUnitsitemscheck === 'undefined'){
          vm.items.push(BusinessUnitsitemobject)
          }
      }
          }
        })



    }

   else {
        //console.log('AuthchangeLoggedOut')
        vm.pushLoggedOutItems

      vm.items = [

        {
          icon: 'mdi-login-variant',
          title: 'Login',
          to: '/Login'
        },
      ]

  }
    })
    }

  }

}
</script>

<style>
</style>

  