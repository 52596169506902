
<template>
 <v-hover v-slot="{ hover }">
    <v-card :class="tab.BoxTransparency+' '" :dark="tab.DarkForm" :height="!tab.HeightRestrictions? '100%' : ''" :color="IsSurveyForm? '' : tab.BGColor.hexa" 
    :elevation="IsSurveyForm? 0 : tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  
    :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'" >
                                 <v-expand-transition>
                                    <div
                                        v-if="!hover && tab.FormSubmitted"
                                        class="d-flex transition-fast-in-fast-out background display-1 red--text"
                                        style="height: 100%;position: absolute;opacity:0.8;align-items: center;justify-content: center;width:100%;z-index:2"
                                    >
                                    <p style="padding:20px;">
                                        Thank you!<br>
                                        Your submission has been processed...
                                    </p>
                                    </div>
                                    <div
                                        v-if="tab.FormProcessing && !tab.FormSubmitted && !tab.FromDatabase"
                                        :class="'d-flex transition-fast-in-fast-out v-card--reveal background mediumoverline white--text'"
                                        :style="'height: 100%;background-color:'+tab.BGColor.hexa+';'"
                                    >
                                        <v-progress-circular size="250  "
                                        :width="7"
                                        :color="'links'"
                                        indeterminate class="display-1"
                                        >Processing</v-progress-circular>
                                    </div>
                                    </v-expand-transition><v-expand-transition>
                                    </v-expand-transition>
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                    <v-card-title :class="tab.Center ? 'justify-center' : ''" v-if="!tab.HideFormName &&  $route.name !== 'SurveyBuilder'" :style="TitleStyle(tab)">
                                    <strong>{{tab.FormName}}</strong><v-btn small v-if="ViaDialog" @click="DeactivateWebDialog(DialogName)" color="red" icon><v-icon>mdi-close</v-icon></v-btn></v-card-title>
                                   <v-card-subtitle v-if="CanHideMappedFormFields">
                                       <v-switch v-model="ShowMappedFormFields" label="Show Mapped Fields" />
                                   </v-card-subtitle>
                                    <v-card-subtitle v-if="$route.name === 'SurveyBuilder'" :style="'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                    <ContentEditableField
                                        :FieldObject="tab" :FieldName="'Description'"
                                        :FieldValue="tab.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                                        </v-card-subtitle>
                                    <v-card-text style="padding-bottom: 0;padding-left: 30px;padding-right: 30px;padding-top:30px;">
                                        <v-form
                                        ref="form"
                                        v-model="valid"
                                        lazy-validation
                                    >
                                    <!-- WTF was this readonly on the v-if?
                                     ||
                                        MappedUserFieldStayHidden && field.LoggedInUserValue && !FieldisReadOnly(tab,field,userLoggedIn)"> -->
                                    <!-- !elmnt.HideUserInfoFields || elmnt.HideUserInfoFields && !field.MappedField || elmnt.HideUserInfoFields && field.MappedField && !field.MappedField.LoggedInUser && !field.MappedField.LoggedInUserValue -->
                                        <span v-for="field in tab.FormFields" :key="field.itemObjKey"><span v-if="ShowMappedFormFields && !MappedUserFieldStayHidden || !ShowMappedFormFields && !field.MappedForm
                                        || MappedUserFieldStayHidden  && !field.MappedField || MappedUserFieldStayHidden  && field.MappedField && !field.MappedField.LoggedInUser && !field.MappedField.LoggedInUserValue">
                                            <span v-if="field.Type === 'Option Set'">
                                            <v-select dense return-object :readonly="FieldisReadOnly(tab,field,userLoggedIn)" :rules="[rules.required]" v-model="field.Response" :items="field.Options" :label="field.DisplayName" item-text="Name"></v-select></span><span v-if="field.HasDeepHierarchy && field.Levels && field.Levels[1]">
                                            <v-select dense v-if="field.Response" return-object :readonly="FieldisReadOnly(tab,field,userLoggedIn)" :rules="[rules.required]" v-model="field.Level1Response" :items="field.Response.Options" :label="field.Levels[1].name" item-text="Name"></v-select></span><span v-if="field.Type === 'Single Line Text'">
                                            <v-text-field dense  :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Boolean'">
                                            <v-switch dense  :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required]" v-model="field.Response" :label="field.DisplayName"></v-switch></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Email'">
                              <v-text-field dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required,rules.email]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'URL Link'">
                              <v-text-field dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required,rules.url]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType && field.CommonFieldType === 'Telephone Number'">
                              <v-text-field dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required,rules.telnr]" v-model="field.Response" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address'">
                                                <v-list dense class="outline">
                                                    <v-list-item-title class="mediumoverline">{{field.DisplayName}}</v-list-item-title>
                                                    <v-list-item v-for="breakdownfield in field.FieldBreakdown" :key="breakdownfield.itemObjKey">
                                                        <v-text-field :rules="[rules.required]" dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)" v-model="field.Response[breakdownfield.Name]" :label="breakdownfield.DisplayName"/>
                                                    </v-list-item>
                                                </v-list>
                                                </span><span v-if="field.Type === 'Number Field'">
                                            <v-text-field dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.numberfield,rules.required]" v-model.number="field.Response" type="number" :label="field.DisplayName"></v-text-field></span><span v-if="field.Type === 'Multiple Lines Text'">
                                            <v-textarea class="smalltextarea" dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  :rules="[rules.required]" v-model="field.Response"  :label="field.DisplayName"></v-textarea></span><span v-if="field.Type === 'Lookup' && field.RelatedBuildID !== 'Users'">
                                            <v-autocomplete dense :readonly="FieldisReadOnly(tab,field,userLoggedIn)" :items="field.Array" :item-text="field.LookupFieldName" :rules="[rules.required]" v-model="field.Response" return-object :label="field.DisplayName"></v-autocomplete></span>
                                            <span v-if="field.Type === 'Lookup' && field.RelatedBuildID === 'Users'">
                                            <UserLookup :UsersLookupArray="field.Array" :ModelProp="'Response'" :RecordObj="field" @UpdateUserLookupProp="UpdateUserLookupProp"
                                                :FieldLabel="field.DisplayName" :ReadOnly="FieldisReadOnly(tab,field,userLoggedIn)" :Rules="[rules.required]"
                                                /></span>
                                                    <span v-if="field.Type === 'Date'">
                                            <v-menu 
                                            v-model="field.DateMenu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="200px"
                                            >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                :label="field.DisplayName"
                                                prepend-icon="mdi-calendar-month"
                                                readonly  :rules="[rules.required]"
                                                v-on="on"
                                                v-model="field.Response"
                                                ></v-text-field>
                                                </template>
                                            <v-date-picker :readonly="FieldisReadOnly(tab,field,userLoggedIn)"  v-model="field.Response"
                                                    @input="field.DateMenu = false"></v-date-picker>
                                            </v-menu>
                                            <v-menu v-if="field.HasTimeInput"
                                                v-model="field.TimeMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px" 
                                                id="fieldTimeMenu"
                                                
                                                >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                    v-model="field.TimeResponse"
                                                    :label="field.DisplayName+' Time'"
                                                    prepend-icon="mdi-calendar-month"
                                                    readonly
                                                    v-on="on"
                                                    :id="field.Name+'Time'"
                                                    clearable
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="field.TimeResponse"
                                                    @input="field.TimeMenu = false"
                                                    type="month" :readonly="FieldisReadOnly(tab,field,userLoggedIn)" 
                                                    width="290"
                                                    class="ml-4"
                                                ></v-time-picker>
                                                </v-menu></span><span v-if="field.Type === 'Single File Upload' && !field.Response">
                                            <input
                                            @change="UploadFile($event,tab,field)"
                                        type="file">
                                    <!--  <v-btn @click="$refs.{{field.Name}}fileInputer.click()"  v-if="!selected{{field.Name}}File" color="pop" dark>Upload {{field.Name}}</v-btn> --></span><span v-if="field.Type === 'Single File Upload' && field.Response">
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                {{field.DisplayName}}</v-list-item-title>
                                                <v-list-item-subtitle>
                                                <v-btn x-small>{{field.Response.filename}}</v-btn>
                                                </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                        </span><span v-if="field.Type === 'Radio Group' && $route.name !== 'MyDashboard'">
                            {{field.DisplayName}}:
                      <v-radio-group dense row :value="field.Response" multiple>
                        <!-- field.Type {{field.Type}} -->
                                        <v-radio dense
                                        @click="toggleMultipleRadio(field,option)"
                                          v-for="option in field.Options"
                                          :key="option.itemObjKey"
                                          :label="option.Name"
                                          :value="option"
                                        ></v-radio>
                                      </v-radio-group></span><span v-if="field.Type === 'Radio Group' && $route.name === 'MyDashboard'">
                                        <v-select item-text="Name" dense filled multiple return-object :items="field.Options" v-model="field.Response" />
                                        </span>
                                        </span></span>                                     
                                        <!-- <v-checkbox
                                        v-model="checkbox"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Do you agree?"
                                        required
                                        ></v-checkbox> -->
                                        
                                    </v-form>
                                    </v-card-text>
                                    <v-card-actions style="padding-top: 0px;" v-if="!userLoggedIn" id="recaptcha">
                                 </v-card-actions>
                                  <v-card-actions v-if="IsSurveyForm">
                                  <v-checkbox
                                        v-model="ValidatedSurveyInfo"
                                        @click="UpdateSurveyInfo(tab)"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        label="Is this Information Correct??"
                                        required
                                        ></v-checkbox>
                                 </v-card-actions>
                                <v-card-actions style="padding-top: 0px;" :class="tab.ButtonAlignment" v-if="!IsSurveyForm">
                                        <v-btn
                                        :disabled="tab.FormProcessing || tab.FormSubmitted || !ShowMappedFormFields"
                                        color="links"
                                        class="mr-4"
                                        @click="ValidateForm(tab)"
                                        >
                                       Submit
                                        </v-btn>
                                        </v-card-actions>
                                <!-- <v-card-text v-if="tab.HasImage"> -->
                                    <!-- <v-layout :class="tab.Justify" row> -->
                                        <v-card-text>
                                            <v-layout :class="tab.Justify" row>
                                        
                                    <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && tab.IMG"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                            </v-layout>
                                        </v-card-text>
                                   <!-- </v-layout> -->
                                 <!-- </v-card-text> -->
                            
                            <v-card-actions v-if="tab.HasActionButton && tab.Name === 'Web Form'" >
                                    <v-btn :dark="tab.ActionBtnDark" :style="'background-color:'+tab.ButonColor.hex">
                                        {{tab.ActionButtonName}}
                                    </v-btn>
                                </v-card-actions>
                                </v-card>
    </v-hover>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ContentEditableField from '@/components/Database/ContentEditableField'
import UserLookup from '@/components/General/UserLookup'

export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','PageName','PageID','rowindex','IsSurveyForm','DynamicWikiData','MappedUserFieldStayHidden','CanHideMappedFormFields','ViaDialog','DialogName'],
    components: {
        ContentEditableField,
        UserLookup
        },
    data() {
    return {
        ShowMappedFormFields: true,
        FileObjects: [], 
        ValidatedSurveyInfo: false,
      rules: {
          numberfield: value => {
            const pattern = /^[0-9]+$/im;
            return pattern.test(value) || "Not a valid number.";
          },
          telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },

    }
    },
    computed:{
        
      ActiveSite(){
            return this.$store.state.ActiveSite
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
       if(this.CanHideMappedFormFields){
           this.ShowMappedFormFields = false
       }

    },
    
    methods: {
        TitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor.hexa+';padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
        DeactivateWebDialog(DialogName){
            this.$emit('DeactivateWebDialog',DialogName)
        },
        UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
        FieldisReadOnly(tab,field,userLoggedIn){
            if(tab.FormProcessing){
                return true
            }
            else if(userLoggedIn && field.MappedField && field.MappedField.LoggedInUserValue){
                return userLoggedIn[field.MappedField.Name]
            }
            else if(userLoggedIn && field.MappedField && field.Response){
                return true
            }
            else if(userLoggedIn && field.MappedForm && field.Response){
               return true
            } 
            else if(!userLoggedIn && field.MappedField && !field.MappedField.LoggedInUserValue && field.Response){
                return true
            }                 
        },
        
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        console.log(this.field,FieldObject,this.Record)
        //   this.$emit('UpdateEditableField',this.field.Name,this.Record[this.field.Name],this.Record)
        //   alert('should have passed updateemit')
        },
        UploadFile(event,tab,field){
            let vm = this
            if(tab.EntityForm || tab.RelatedEntity){
                let file = event.target.files[0]
                let filename = file.name
                file.FileModifiedDate = new Date(file.lastModified)
                let storepath = tab.RelatedEntity.Name+'/'+tab.FormName+'/'+field.Name+'/'+filename
                var storageRef = firebase.storage().ref(storepath); 
                var uploadTask = storageRef.put(file);
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                .then((thumburl) => {
                let newfileobj = {
                    fileurl: url,
                    url: url,
                    FileType: file.type,                    
                    Modified_On: new Date(),
                    ModifiedDate: file.FileModifiedDate,
                    Size: file.size,
                    StorageRef: storepath,
                    filename: filename,
                    FieldName: field.Name
                    }
                if(vm.userLoggedIn){
                    newfileobj.Modified_By = {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id}
                    newfileobj.Modified_Byid = vm.userLoggedIn.id
                }
                field.Response = newfileobj
                })  
            }            
        },
        UpdateSurveyInfo(tab){
            
            if(this.$refs.form.validate()){
              this.$emit('UpdateSurveyInfo',tab)
           }
           else{
               
               alert('Please complete all Form Fields')
               this.ValidatedSurveyInfo = false
               setTimeout(() => {
                   this.ValidatedSurveyInfo = true
                   setTimeout(() => {
                   this.ValidatedSurveyInfo = false
               }, 50);
               }, 50);
           }
            
        },
        toggleMultipleRadio(field,_id) {
            console.log(_id)
            if (field.Response.includes(_id)) {
            field.Response = field.Response.filter((i) => {
            return i !== _id;
            });
        } else {
            field.Response = field.Response.concat(_id);
        }
            
        },
        DateFormatter(date){
    if(date){
      let removeT = date.split('T').join(' ')
      let removeFWDslash = date.split('/').join('-')
    let yearstring = removeFWDslash.split('-')[0]
      let monthstring = removeFWDslash.split('-')[1]
      let daystring = removeFWDslash.split('-')[2]
      let yearnumber = Number(yearstring)
      let monthnumber = Number(monthstring)
      let daynumber = Number(daystring)
      return new Date(yearnumber, monthnumber-1, daynumber)
      }
    else{
      return null
    }
  },
        ActivateOptionSetDialog(field,ContentBlockObject){
            this.$emit('ActivateOptionSetDialog',field,ContentBlockObject)
        },
     
       OptionNameSelect(option){
           this.$emit('ActivateOptionSetDialog',option)
       },
       VerifyCaptcha(tab){
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
        'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                this.SubmitForm(tab)
                // ...
            },
            'expired-callback': () => {
                // Response expired. Ask user to solve reCAPTCHA again.
                // ...
            }
            })
            window.recaptchaVerifier.render()
        
       },
       ValidateForm(tab){
           if(this.$refs.form.validate()){
               if(this.userLoggedIn){
                this.SubmitForm(tab)   
               }
               else{
                   this.VerifyCaptcha(tab)
               }
               
           }
           else{
               alert('Please complete all Form Fields')
           }
       },
        SubmitForm(tab){
            console.log(tab)
            tab.FormProcessing = true
            if(tab.FromDatabase){
                this.$emit('ProcessingData',true)
            }
            if(tab.FormEmailRecipient){
                let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - `+tab.FormName+` ("`+this.PageName+`" Page)</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                if(field.Type !== 'Lookup' && field.Type !== 'Option Set' && field.Type !== 'Radio Group' && field.Type !== 'Single File Upload'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Lookup'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response[field.LookupFieldName]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                } 
                else if(field.Type === 'Option Set'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response.Name+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Radio Group'){
                    let groupoptions = ''
                    field.Response.map((resp,respind) => {
                        if(respind !== 0){
                         groupoptions = groupoptions+','+resp.Name   
                        }
                        else{
                         groupoptions = groupoptions+resp.Name      
                        }
                        
                    })
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+groupoptions+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Single File Upload'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left"><a href="`+field.Response.fileurl+`>`+field.filename+`</a></td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
            })

            // let display = document.getElementById('SubmittedData')
            
            let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: tab.FormEmailRecipient,
                EmailSubject: 'New Enquiry - '+this.PageName,
                EmailBody: EmailBody
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
            }
            console.log('tab.EntityForm',tab.EntityForm)
            if(tab.EntityForm || tab.RelatedEntity){
                let NewRecord = {
                    Created_On: new Date()
                }
                let fields = tab.FormFields.filter(field => {
                    return typeof field.Response !== 'undefined'
                })
                let length = fields.length
                NewRecord.Status = tab.StatusDefault
                NewRecord.Status_Reason = tab.Status_ReasonDefault
                if(this.$route.meta && this.$route.meta.EventID === 5001 && this.DynamicWikiData && this.DynamicWikiData.Business_Unit){
                    NewRecord.Business_Unit = this.DynamicWikiData.Business_Unit
                    NewReocrd.Business_Unitid = this.DynamicWikiData.Business_Unitid
                }
                
                fields.map((field,i) => {
                    console.log('field.MappedValues',field.MappedValues,field.Response)
                NewRecord[field.Name.split(' ').join('')] = field.Response
                if(field.Type === 'Lookup'){
                    NewRecord[field.Name.split(' ').join('')] = {
                        [field.LookupFieldName]: field.Response[field.LookupFieldName],
                        id: field.Response.id
                    }
                    if(field.RelatedBuildID === 'Users' && field.Response.Name && field.Response.Surname){
                        NewRecord[field.Name.split(' ').join('')].Name = field.Response.Name
                        NewRecord[field.Name.split(' ').join('')].Surname = field.Response.Surname
                    }
                    NewRecord[field.Name.split(' ').join('')+'id'] = field.Response.id
                    if(field.MappedValues){
                        field.MappedValues.map(mapvalue => {
                            if(mapvalue.InputField.Type !== 'Common Field' || mapvalue.InputField.Type === 'Common Field' && mapvalue.InputField.CommonFieldType !== 'Physical Address' && mapvalue.InputField.CommonFieldType !== 'Postal Address'){
                                if(field.Response[mapvalue.InputField.Name]){
                                   NewRecord[mapvalue.OutputField.Name] = field.Response[mapvalue.InputField.Name] 
                                   if(mapvalue.OutputField.Type === 'Lookup'){
                                        NewRecord[mapvalue.OutputField.Name+'id'] = field.Response[mapvalue.InputField.Name+'id']
                                    }
                                }                            }
                            else if(mapvalue.InputField.Type === 'Common Field'){
                                mapvalue.OutputField.FieldBreakdown.map((breakdown,breakdownindex) => {
                                    let mapname = mapvalue.InputField.FieldBreakdown[breakdownindex].Name
                                    if(field.Response[mapname]){
                                     NewRecord[breakdown.Name] = field.Response[mapname]   
                                    }                                    
                                })
                            }                                                        
                        })                        
                    }
                }
                if(field.Type === 'Option Set' && field.HasDeepHierarchy && field.Levels && field.Levels[1]){
                    NewRecord[field.Levels[1].FieldName] = field.Level1Response
                }                
                if(field.Type === 'Date'){
                    if(!field.HasTimeInput){
                     let date = this.DateFormatter(field.Response)   
                     NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: date.getTime()} 
                    }
                    else{
                     console.log(field.Response,field.TimeResponse)
                     let finaldate = new Date(field.Response+' '+field.TimeResponse)
                     NewRecord[field.Name.split(' ').join('')] = {Type: 'Date', DateNumber: finaldate.getTime()}   
                    }    
                }
                if(field.Type === 'Common Field' && field.CommonFieldType === 'Physical Address' || field.Type === 'Common Field' && field.CommonFieldType === 'Postal Address'){                    
                        field.FieldBreakdown.map(breakdown => {
                            NewRecord[breakdown.Name] = field.Response[breakdown.Name] 
                        })
                }
                if(i-1+2 === length){
                  const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(!NewRecord.Created_By && this.userLoggedIn){
                      NewRecord.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
                      NewRecord.Created_Byid = this.userLoggedIn.id
                      
                  }
                  if(tab.DefaultOwner){                    
                    NewRecord.Business_Unit = tab.DefaultOwner.Business_Unit
                    NewRecord.Business_Unitid = tab.DefaultOwner.Business_Unitid
                    //because, if the record is BU based or whatever, we have that covered
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                    NewRecord.NewNotification = {
                    itemidprop: 'recordid',
                    Type: 'Web Form Submission',
                    Header: 'Form Submission for '+tab.FormName,
                    Content: 'New Submission for a '+tab.SingleName,
                    Owner: NewRecord.Owner,
                    Ownerid: NewRecord.Ownerid,
                    Read: false,
                    Path: '/'+tab.SingleName.split(' ').join(''),
                    Message: 'New Submission for a '+tab.SingleName,
                    }
                    if(this.userLoggedIn){
                        NewRecord.NewNotification.CreatorFullName = this.userLoggedIn.Full_Name
                        NewRecord.NewNotification.CreatorID = this.userLoggedIn.id
                        NewRecord.NewNotification.Content = NewRecord.NewNotification.Content+' by '+NewRecord.NewNotification.CreatorFullName
                        NewRecord.NewNotification.Message = NewRecord.NewNotification.Message+' by '+NewRecord.NewNotification.CreatorFullName
                    }
                  }
                  if(this.userLoggedIn){
                      NewRecord.WebSubmission = false
                  }
                  else{
                      NewRecord.WebSubmission = true
                  }
                  if(tab.RelatedEntity.id === 'activities' && tab.Regarding_Type && tab.Regarding_Record){
                      NewRecord.Regarding_Type = tab.Regarding_Type
                      NewRecord.Regarding_Record = tab.Regarding_Record
                      if(this.userLoggedIn){
                         NewRecord.FollowingUsers = [{id: NewRecord.Owner.id, Name: NewRecord.Owner.Name, Surname: NewRecord.Owner.Surname, Full_Name: NewRecord.Owner.Full_Name},{id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}]
                      }
                      else{
                          NewRecord.FollowingUsers = [{id: NewRecord.Owner.id, Name: NewRecord.Owner.Name, Surname: NewRecord.Owner.Surname, Full_Name: NewRecord.Owner.Full_Name}]
                      } 
                      let FollowingUserIDStrings = []
                        let FollowingUserIDKeys = {}
                        NewRecord.FollowingUsers.map(follower => {
                            FollowingUserIDKeys[follower.id] = true
                            FollowingUserIDStrings.push(follower.id)
                        })
                        NewRecord.FollowingUserIDKeys = FollowingUserIDKeys
                        NewRecord.FollowingUserIDStrings = FollowingUserIDStrings
                  }
                 
                  let obj = {
                    collection: tab.RelatedEntity.id,
                    data: NewRecord
                  } 
                   if(tab.WebFormType === 'Data Collection Form'){
                    //because Data Colleciton Form records in the destined parent table
                    obj.collection = tab.ParentEntity.id.split('_').join('').toLowerCase()
                   }
                  if(tab.GroupID){
                      obj.GroupID = tab.GroupID
                      obj.data.groupid = tab.GroupID
                  }  
                  if(this.ActiveSite){
                      obj.SiteId = this.ActiveSite.id
                  } 
                  let EmailBody = `<html>
<body>
<head></head>
<h2>New Enquiry - `+this.PageName+`</h2>
<table>`
             let length = tab.FormFields.length
            tab.FormFields.map((field,i) => {
                if(field.Type !== 'Lookup' && field.Type !== 'Option Set' && field.Type !== 'Radio Group' && field.Type !== 'Single File Upload'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Lookup'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response[field.LookupFieldName]+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                } 
                else if(field.Type === 'Option Set'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+field.Response.Name+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Radio Group'){
                    let groupoptions = ''
                    field.Response.map((resp,respind) => {
                        if(respind !== 0){
                         groupoptions = groupoptions+','+resp.Name   
                        }
                        else{
                         groupoptions = groupoptions+resp.Name      
                        }
                        
                    })
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left">`+groupoptions+`</td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
                else if(field.Type === 'Single File Upload'){
                EmailBody = EmailBody+`<tr>
<th align="left">`+field.Name+`:</th>
<td align="left"><a href="`+field.Response.fileurl+`>`+field.filename+`</a></td>
</tr>`
    if(i-1+2 === length){
        EmailBody = EmailBody+`
    </table>
</body>
</html>`    
                    }
                }
            })
                    obj.data.EmailBody = EmailBody
                    if(this.PageID && this.PageName){
                        obj.data.Form_Page_Obj = {
                            id: this.PageID,
                            Page_Name: this.PageName
                        }    
                    }
                    if(this.$store.state.ViewInstanceID){
                        obj.data.ViewInstanceID = this.$store.state.ViewInstanceID
                    }
                    
                  console.log(obj)  
                  this.$emit('DeactivateWebDialog')      
                  if(!tab.ExistingRecord){
                      SubmitFormData(obj).then(result => {
                        console.log(result)
                        if(tab.FromDatabase){
                            this.$emit('ConfirmSubmission',tab,obj,result.data)
                            this.$emit('ProcessingData',false)
                        }
                        else{
                        tab.FormSubmitted = true  
                        }
                        
                    }) 
                  }  
                  else{
                    if(tab.FromDatabase){
                        this.$emit('ConfirmSubmission',tab,obj,tab.ExistingRecord.id)
                        this.$emit('ProcessingData',false)
                    }
                    else{
                    tab.FormSubmitted = true  
                    }
                  }
            }
                })
            }
        },

     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize && !this.$vuetify.breakpoint.xs){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else if(!tab.HeaderFontSize && this.$vuetify.breakpoint.xs){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:25px;'  
               }
               else{
                   if(tabHeaderFontFamily && !this.$vuetify.breakpoint.xs){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else if(tabHeaderFontFamily && this.$vuetify.breakpoint.xs){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize/2+'px;'
                   }
                   else if(!tabHeaderFontFamily && this.$vuetify.breakpoint.xs){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize/2+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           //console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>