
     <template>
     <div>
        <v-card  tile>
    <v-app-bar      
      fixed
      :color="GroupData.NavbarBGColor ? GroupData.NavbarBGColor.hex :'pubnavheaderbg'"
       :style="AppBuilderView? 'margin-left: 100px;margin-top:65px;' : ''"
      prominent
      
      shrink-on-scroll
      
      fade-img-on-scroll>
      <v-app-bar-title class="appbartitlecenter justify-center">
        <div v-if="!userLoggedIn" style="text-align: center;align:center;"><router-link :to="'/'"><img contain height="100"
        :src="GroupData.NavBar_IMG && GroupData.NavBar_IMG.url ? GroupData.NavBar_IMG.url : require('@/assets/logo.png')"></router-link><br>
         <v-card-title :class="GroupData.NavbarTextColor+'--text justify-center'">
          {{GroupData.name}}
        </v-card-title>
          </div>
          <div v-if="userLoggedIn" style="text-align: center;align:center;"><router-link :to="'/Group/'+this.$route.params.id">
            <img contain :width="$vuetify.breakpoint.xs?'150px' : '150px'" :src="GroupData.NavBar_IMG && GroupData.NavBar_IMG.url ? GroupData.NavBar_IMG.url : require('@/assets/logo.png')">
            </router-link><br>
            <v-card-title :class="GroupData.NavbarTextColor+'--text justify-center'">
          {{GroupData.name}}
        </v-card-title>
            </div>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <v-list-item v-if="GroupData.Telephone" class="primary--text overline">                      
        <v-spacer></v-spacer><v-icon class="primary--text">mdi-phone</v-icon> <a :href="'tel:'+GroupData.Telephone" target="_blank" rel="noopener">{{GroupData.Telephone}}</a>
      </v-list-item>
      <template v-slot:extension>
       <v-tabs align-with-title dark :background-color="GroupData.NavItemsBGColor ? GroupData.NavItemsBGColor : 'pubnavbarbg'">
          <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer" /> -->
        <!-- <v-icon class="mx-3" @click="ToggleAppStylingView()" v-if="userIsAdmin">mdi-palette-advanced</v-icon> -->
          <v-list-item :class="GroupData.NavItemsBGColor ? GroupData.NavItemsBGColor+' hidden-sm-and-down' : 'pubnavbarbg hidden-sm-and-down'">
        
            <v-list-item-content class="justify-center" v-for="item in FinalRender" :key="item.itemObjKey">
                      <v-btn @click="RefreshRouter()" :class="GroupData.NavItemsTextColor ? GroupData.NavItemsTextColor+'--text' : 'pubnavbaritem--text'"
                      small plain :dark="false" :to="item.Path" v-if="!item.SubItems || !item.SubItems.length" text>{{item.DisplayName}}</v-btn>
                      <v-menu
                          v-if="item.SubItems && item.SubItems.length"
                          bottom
                          left
                          offset-y
                          :close-on-content-click="false"
                      >
                          <template v-slot:activator="{ on }">
                          <v-btn
                              text
                              small
                              class="align-self-center mr-4"
                              v-on="on"
                              :dark="false"
                          >
                          {{item.DisplayName}}
                              <v-icon right>mdi-menu-down</v-icon>
                          </v-btn>
                          </template>
                          <v-list dense :dark="false" color="#EEEEEE">
                          <v-list-item
                              v-for="subitem in item.SubItems"
                              :key="subitem.itemObjKey"
                          >                            
                              <v-btn small plain width="100%" :dark="false"  :to="subitem.Path" v-if="!subitem.SubChildren || !subitem.SubChildren.length" text>{{subitem.DisplayName}}</v-btn>
                              <v-list-group
                              v-if="subitem.SubChildren && subitem.SubChildren.length"
                            >
                              <template v-slot:activator>
                                <v-btn small plain width="100%" :dark="false" text>{{subitem.DisplayName.toUpperCase()}}<v-icon>mdi-menu-down</v-icon></v-btn>
                              </template>
                            
                                  <v-list-item
                                      v-for="subsub in subitem.SubChildren"
                                      :key="subsub.itemObjKey"
                                      :href="subsub.Path"
                                      :dark="false" color="#EEEEEE"
                                  >                                    
                                      <v-btn small plain width="100%" :dark="false" text>{{subsub.DisplayName.toUpperCase()}}</v-btn>
                                  </v-list-item>
                              </v-list-group>
                          </v-list-item>
                          </v-list>
                      </v-menu>                                        
              </v-list-item-content>
          </v-list-item>
         
        </v-tabs>

        <!-- <v-list width="100%" dense :dark="false" class="primary justify-end hidden-sm-and-down" > -->
                              
                            <!-- </v-list> -->
      </template>
    </v-app-bar>
    <v-sheet
      id="scrolling-techniques-3"
      class="overflow-y-auto"
      max-height="600"
    >
      <v-container style="height: 1000px;"></v-container>
    </v-sheet>
  </v-card>
  <BottomNavigation v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs" :style="'position: fixed;bottom: 50px;z-index: 5;'" :BottomNavigationItems="FinalRender" :ThemeColor="'pubnavbaritem'" :ThemeBGColor="'pubnavbarbg'"
    :UsersArray="UsersStore" :CurrentRecordName="GroupData.name" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList" @SelectBottomNavMenuItem="SelectBottomNavMenuItem"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView" :DBNavbar="false" :UserRecord="UserRecord"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField" :ReadNotifications="ReadNotifications"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB" :UnreadNotifications="UnreadNotifications"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements" :UserPicture="UserPicture" :BottomMenu="false" :TopMenu="true"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    />
    <v-navigation-drawer temporary class="waytop"       
      fixed :dark="true" right  v-model="drawer" color="primary">
      <v-list>
        <div v-for="item in FinalRender" :key="item.title">
          <v-list-item v-if="!item.SubItems || item.SubItems.length===0" :to="'/'+item.Path">
          <v-list-item-content>
            <v-list-item-title :to="'/'+item.Path">{{ item.DisplayName}}
            </v-list-item-title>
            </v-list-item-content>
           
          </v-list-item>
            <v-list-group
              v-if="item.SubItems && item.SubItems.length"
              >
                <template v-slot:activator>
                  <v-list-item-title>{{item.DisplayName}}</v-list-item-title>
                </template>
                <div v-for="subsub in item.SubItems"
                        :key="subsub.itemObjKey">
                    <v-list-item class="mx-3"
                        
                        :to="'/'+subsub.Path"
                    >                                   
                        <v-list-item-title>{{ subsub.DisplayName }}</v-list-item-title>
                    </v-list-item>
                    
                </div>
              </v-list-group>
          </div>
      </v-list>
    </v-navigation-drawer>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BottomNavigation from '@/components/Navigation/BottomNavigation'
export default {
  props: ['SitePages','System','GroupNavigationalItems'],
  components: {BottomNavigation},
  data() {
    return {
        FixedGroupNavigationalItems: [],
        GroupData: '',
      drawer: false,
      TopLocation: '',
      BusinessLocations: [],
      pubitems: [
        {
          DisplayName:'Home',
          Path:'/',
        },
        {
          DisplayName:'Practice Areas',
          Path:'/PracticeAreas',
        },
        {
          DisplayName:'Contact Us',
          Path:'/ContactUs',
        },
      ],
      login: {
          icon: 'mdi-door-open',
          title: 'Login',
          to: '/Login',
          target: 'self',
          color: 'pop--text'
        },
    }
  },
  watch: {
    GroupNavigationalItems: {
            handler: function(oldvalue, newvalue) {   
                console.log('GroupNavigationalItems 1',oldvalue, newvalue)           
              if(oldvalue !== newvalue){
               this.FixedGroupNavigationalItems = oldvalue          
              }
            },
            deep: true
        },
  },
  computed: {
    AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    userLoggedIn () {
      return this.$store.getters.user
    },
    PublicPages(){
      return this.SitePages.filter(page => {
        return page.PublishType === 'Public'
      })
    },
     FinalRender(){
       if(this.GroupData && this.FixedGroupNavigationalItems){
         return this.FixedGroupNavigationalItems
      //  if(this.GroupData && this.GroupData.GroupNavbarItems){
      //    return this.GroupData.GroupNavbarItems
         .filter(item => {
             return typeof item !== 'undefined'
         })
         .map(item => {
             let navobj = {
                 DisplayName: item.TabName,
                 TabType: item.TabType,
                 
             }
             navobj.title = navobj.DisplayName
             if(item.TabType === 'Group Page'){
               navobj.Path = '/Group-Page/'+this.$route.params.id+'/'+item.TabName
               if(item.icon){
                 navobj.icon = item.icon
               }
               else{
                 navobj.icon = 'mdi-menu'
               }
             }
             else{
               navobj.Path = item.TabName
             }
             if(item.TabName === 'Wall'){
               navobj.icon = 'mdi-post'
             }
             if(item.TabName !== 'Social'){
               navobj.IsRoute = true
             }
             else{
               navobj.icon = 'mdi-view-list'
             }
             if(item.SubItems){
                 navobj.SubItems = item.SubItems
                 .map(subitem => {
                   console.log('subitem',subitem)
                     let subnavobj = {
                        DisplayName: subitem.TabName,
                        TabType: subitem.TabType,
                        Path: subitem.TabName,
                        CanView: subitem.CanView,
                        title: subitem.TabName,
                        icon: subitem.icon
                    }
                    return subnavobj
                 })
                 .filter(subitem => {
                     return subitem.CanView
                 })
                 navobj.Children = navobj.SubItems
             }
             
             return navobj
         }).filter(item => {
             if(item.TabType === 'Group Component'){
                 return item.CanView
             }
             else {
                 return item
             }
         })
       }
       else{
         return []
       }
     }
    },

  created() {
    this.GetGroup(db.collection('groups').doc(this.$route.params.id))
  },

  methods: {
    SelectBottomNavMenuItem(item){
      if(!item.Children && !item.Method && !item.RouteBlank){
        this.$router.push(item.Path)
      }
      else if(item.Method){
        if(item.Method === 'IntranetViewToggle' && item.Prop === true){
          this[item.Method](item.Prop,true)
        }
        else if(item.SecondProp){
          this[item.Method](item.Prop,item.SecondProp)
        }
        else{
          this[item.Method](item.Prop)
        }
      }
    },
      GetGroup(query){
            query.onSnapshot(snapshot => {
                let groupdata = snapshot.data()
                if(groupdata){
                    console.log(groupdata)
                    this.GroupData = groupdata
                }
            })
        },
    ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
    RefreshRouter(){
      this.$emit('RefreshRouter')
    },
     GetBusinessLocations(){
            db.collection('businessunits').where('IsPublicLocation','==',true).onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        if(buobj.id !== 'Guest'){
                            this.BusinessLocations.push(buobj)
                            if(buobj.Top){
                                this.TopLocation = buobj                       
                            }
                        }
                          
                        }
                    }) 
            })
        },
    
    
  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */
.submenuitem{
    font-size: 12px;
}
.stickytop {
  position: fixed;
  z-index: 100;
  top: 0px
}
.stickytop2 {
  position: fixed;
  top: 100px;
  z-index: 100
}
</style>
    