<template>
  <span>
    <v-dialog v-model="ViewFileDialog" fullscreen>
                <v-card tile class="white" flat>
                <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
                <embed v-if="ViewFileURL"  :height="ImageHeight-25" :width="ImageWidth"  :src="ViewFileURL"/>
                <v-layout v-if="ViewIMGURL" row class="justify-center">
                <v-img :src="ViewIMGURL" contain  :height="ImageHeight-25" :width="ImageWidth" >
                </v-img>
                </v-layout>
                </v-card>
            </v-dialog>
      <v-btn v-if="tab.ActionButtonType === 'URL' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200"  :outlined="tab.Outlined" :href="tab.ActionButtonRoute" target="_blank" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-btn v-if="tab.ActionButtonType === 'Route' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined" :to="'/'+tab.ActionButtonRoute" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-btn v-if="tab.ActionButtonType === 'File Download' && tab.Fancy" :dark="tab.ActionBtnDark" x-large width="200" :outlined="tab.Outlined"  @click="DownloadFile(tab.DownloadFile)" :style="tab.Outlined? 'font-size:0.7em;' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
    <v-dialog v-model="WebFormDialogFancy" width="400">
            <template v-slot:activator="{ on }">
    <v-btn v-if="tab.ActionButtonType === 'Web Form Dialog' && tab.Fancy || tab.ActionButtonType === 'Content Dialog' && tab.Fancy" :dark="tab.ActionBtnDark" v-on="on" x-large width="200"  :outlined="tab.Outlined" :style="tab.Outlined? 'font-size:0.7em;color:'+tab.ButonColor.hexa+';' : 'font-size:0.7em;background-color:'+tab.ButonColor.hexa">
        {{tab.ActionButtonName}}
    </v-btn>
            </template>
           
            <v-card v-if="!FullScreenGalleryContentBox" class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                <v-btn style="position: absolute;z-index:2;padding:0;" v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
            <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"									
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"									
                />
                 <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :IsContentDialog="true"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :System="System"							
                />
            </v-card>
             <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"  v-else-if="WebFormDialogFancy"						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :IsContentDialog="true"								
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true" :System="System"							
                />
    </v-dialog>
        <v-btn v-if="tab.ActionButtonType === 'URL' && !tab.Fancy" :dark="tab.ActionBtnDark" :href="tab.ActionButtonRoute" :outlined="!tab.Outlined" target="_blank" :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
        <v-btn v-if="tab.ActionButtonType === 'Route' && !tab.Fancy" :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
         <v-btn v-if="tab.ActionButtonType === 'File Download' && !tab.Fancy" :dark="tab.ActionBtnDark" @click="DownloadFile(tab.DownloadFile)" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
    <v-dialog v-model="WebFormDialogNonFancy" width="400">
            <template v-slot:activator="{ on }">
        <v-btn v-if="tab.ActionButtonType === 'Web Form Dialog' && !tab.Fancy || tab.ActionButtonType === 'Content Dialog' && !tab.Fancy" :dark="tab.ActionBtnDark" v-on="on" :outlined="!tab.Outlined"  :style="tab.Outlined? '' : 'background-color:'+tab.ButonColor.hexa">
            {{tab.ActionButtonName}}
        </v-btn>
            </template>
            <v-card v-if="!FullScreenGalleryContentBox"  class="transition-fast-in-fast-out" width="400"
                style="height: 100%;bottom: 0;position: absolute;right: 0;">
                 <v-btn style="position: absolute;z-index:2;padding:0;" v-if="tab.ActionButtonType === 'Content Dialog'" @click="DeactivateWebDialog()" icon><v-icon dark color="red" class="soloactionicon">mdi-close</v-icon></v-btn>
                <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-if="tab.ActionButtonType === 'Web Form Dialog'"								
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                :tab="tab" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
                
                <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :System="System"									
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
            </v-card>
             <component :is="ContentDialogBoxType" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" v-else-if="WebFormDialogNonFancy"						
                :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" 									
                :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="rowindex" :System="System"									
                :tab="tab.ContentDialogBox" :i="i" @DeactivateWebDialog="DeactivateWebDialog" :ViaDialog="true"						
                />
    </v-dialog>
  </span>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';


export default {
    props: ['System','SystemEntities','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {WebFormTab},
    data() {
        return {
            ViewFileDialog: false,
            ViewFileURL: '',
            ViewIMGURL: '', 
            WebFormDialogFancy: false, 
            WebFormDialogNonFancy: false,
        }
    },
    computed:{
        FullScreenGalleryContentBox(){
            let dialbox = this.tab.ContentDialogBox
            return this.tab.ContentDialogType && this.tab.ContentDialogType.Name === 'Gallery' && dialbox && dialbox.FullScreenDialog && dialbox.Assets[0]
        },
        ContentDialogBoxType() {
            if(this.tab.ContentDialogType){
                return () => import(`@/components/WebPages/RenderComponents/`+this.tab.ContentDialogType.ComponentName.split(' ').join('')+`ReadOnly`);
            }
        },
        ImageHeight(){
        return window.innerHeight
        },
        ImageWidth(){
        return window.innerWidth
        },
    },
    created(){

    },
    methods:{
        CloseViewFileDialog(){
            this.ViewFileDialog = false
            this.ViewFileURL = ''
            this.ViewIMGURL = ''
        },
        DownloadFile(file){
            this.GetShortLivedURL(file)
        },
        GetShortLivedURL(item){
      //console.log(item)
        const functions = firebase.functions();
            const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
            console.log(item)
            GetSignedInURL(item).then(result => {
            console.log(result)
            if(item.FileType.includes('image')){
                this.ViewIMGURL = result.data[0]
                //console.log(window.innerHeight)
                this.ViewFileDialog = true
            }
            else{
                this.ViewFileURL = result.data[0]
                var ua = navigator.userAgent.toLowerCase();
                console.log(ua)
                var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
                if(isAndroid) {
                window.open(this.ViewFileURL)
                }
                else{
                //console.log(window.innerHeight)
                this.ViewFileDialog = true
                }
            }
            })
        },
         DeactivateWebDialog(){
            this.WebFormDialogFancy = false
            this.WebFormDialogNonFancy = false
        },
    }
}
</script>

<style>

</style>
