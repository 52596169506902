<template>
  <div>
    <v-dialog v-model="MemberGroupDialog" width="400">
              <v-card v-if="editedMemberGroup" flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                      {{editedMemberGroup.Name}} 
                  </v-card-title>
                  <v-card-text>
                    <v-text-field v-model="editedMemberGroup.Name" :readonly="editedMemberGroup.id" label="Name"
                    />
                    <ContentEditableField style="padding:15px;"
                    :FieldObject="editedMemberGroup" :FieldName="'Description'"
                    :FieldValue="editedMemberGroup.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
                     <!-- <v-list-item>
                            <v-list-item-content>
                              DB Navlist
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-select :items="DBNavLists" return-object dense item-text="Name" clearable
                              v-model="editedMemberGroup.DBNavList"></v-select>
                            </v-list-item-action>
                          </v-list-item> -->
                        
                     <v-select v-model="editedMemberGroup.SecurityRoles" :items="AllSecurityRoles" item-text="Name" label="Assign Security Roles (Multiple Allowed)" multiple></v-select>
                    <v-select :items="ComputedModerationPermissionsOptions"
                      v-model="editedMemberGroup.ModerationPermissions"  item-text="DisplayName" label="Moderator Roles (Multiple Allowed)" multiple
                      />
                      <v-list-item dense v-if="SystemSocialReady">
                      <v-switch label="Can Social" dense v-model="editedMemberGroup.CanSocial"></v-switch>
                      </v-list-item>
                       <v-list-item dense v-if="UserCompaniesActive">
                      <v-switch dense label="Company Restrictive" v-model="editedMemberGroup.CompanySocial"></v-switch>
                      </v-list-item>
                      <v-list-item dense label="Social Restrictions">
                      <v-switch label="Social Restrictions" dense v-model="editedMemberGroup.SocialRestrictions"></v-switch>
                      </v-list-item>                      
                      <v-list-item dense v-if="editedMemberGroup.SocialRestrictions">
                      <v-select :items="ComputedSocialChannelOptions" dense label="Allowed Channels" v-model="editedMemberGroup.SocialChannels" multiple></v-select>
                      </v-list-item>
                     
                    <v-expansion-panels>
                      
                        <v-expansion-panel>
                    <v-expansion-panel-header> Billing Accounts
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-select :items="ComputedBAAccountTypes" dense item-text="Name"
                              label="Allowed BA Types" v-model="editedMemberGroup.AllowedBATypes" multiple></v-select>   
                              <v-select :items="ComputedBAAccountTypes" dense item-text="Name"
                              label="Default BA Type" v-model="editedMemberGroup.DefaultBAType"></v-select>                              
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                    <v-expansion-panel-header> Default Routes
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                
                     <v-select :items="AppsDataBase" label="Default App" v-model="editedMemberGroup.Primary_Appid" item-text="id"
                      />
                             <v-list-item dense v-if="editedMemberGroup.AdditionalProperties.IsGuestUser && Has_Public_Signup">                     
                            <v-select v-model="editedMemberGroup.Guest_Landing_Page" item-text="path" return-object :items="RouteOptions({PathFilter: 'Guest Routes'})" label="Guest Landing Page"></v-select>
                            </v-list-item>
                            <v-list-item  dense v-if="!editedMemberGroup.AdditionalProperties.IsGuestUser && SocialNetworkisActive">                     
                            <v-select v-model="editedMemberGroup.Social_Landing_Page" item-text="path" return-object :items="RouteOptions({PathFilter: 'Internal Pages'})" label="Social Landing Page"></v-select>
                            </v-list-item>
                            <v-list-item dense v-if="SocialNetworkisActive">                     
                            <v-text-field v-model="editedMemberGroup.Social_Module_Name" label="Social Module Name">

                            </v-text-field>
                            </v-list-item>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header> Additional Properties
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                           
                           <v-list-item v-if="LoanOrdersActive">
                            <v-list-item-content>
                              Loan Entities
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-select dense :items="ActiveLoanOrderEntities" item-text="id" v-model="editedMemberGroup.AdditionalProperties.LoanEntities" multiple></v-select>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="StoreOrdersActive">
                            <v-list-item-content>
                              Store Entities
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-select dense :items="ActiveStoreOrderEntities" item-text="id" v-model="editedMemberGroup.AdditionalProperties.LoanEntities" multiple></v-select>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list v-if="editedMemberGroup.AdditionalProperties.LoanEntities">
                            Space for "Order Limit" per selected "Entity" in LoanOrders and StoreOrders
                          </v-list>
                          <v-list-item v-if="editedMemberGroup.AdditionalProperties.LoanEntities && editedMemberGroup.AdditionalProperties.LoanEntities.length > 0 ||
                            editedMemberGroup.AdditionalProperties.StoreEntities && editedMemberGroup.AdditionalProperties.StoreEntities.length > 0">
                            <!-- should probably also first check if LoanEntities or StoreEntities are larger than 0? Cause only define progress if access provided -->                        
                             <v-select label="Progress Tracker" dense :items="ActiveLoanOrderEntities" item-text="id" v-model="editedMemberGroup.AdditionalProperties.TrackedEntity"></v-select>
                          </v-list-item>
                          <v-list-item v-if="TrackedEntityStatusField">
                             <v-autocomplete label="Track Filter" dense :items="TrackedEntityStatusField.Options" item-text="ID" v-model="editedMemberGroup.AdditionalProperties.TrackFilter">
                                <template v-slot:selection="data">
                                  <v-list-item-content>
                                      <v-list-item-title v-html="data.item.Name"></v-list-item-title>
                                      <v-list-item-subtitle v-html="data.item.ID"></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:item="data">
                                  <v-list-item-content>
                                       <v-list-item-title v-html="data.item.Name"></v-list-item-title>
                                      <v-list-item-subtitle v-html="data.item.ID"></v-list-item-subtitle>
                                  </v-list-item-content>
                                </template>
                              </v-autocomplete>
                          </v-list-item>
                            <v-list-item>
                            <v-list-item-content>
                              Monetization Officer
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.MonetizationOfficer"></v-switch>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content>
                              Eagle View
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.IsEagleViewer"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                              Business Profile Admin
                            </v-list-item-content>
                            <v-list-item-action>
                             <v-switch dense v-model="editedMemberGroup.AdditionalProperties.BusinessProfileAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                              Site Content Admin
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.SiteContentAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                             Guest User
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.IsGuestUser"></v-switch>
                            </v-list-item-action>
                          </v-list-item> 
                          <v-list-item>
                            <v-list-item-content>
                               System Admin
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.IsSystemAdmin"></v-switch>
                            </v-list-item-action>
                          </v-list-item>     
                          <v-list-item>
                            <v-list-item-content>
                               Manages Others
                            </v-list-item-content>
                            <v-list-item-action>
                               <v-switch dense v-model="editedMemberGroup.AdditionalProperties.IsTeamActivityManager"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                               Invite Users
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch dense v-model="editedMemberGroup.AdditionalProperties.CanInviteUsers"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item v-if="editedMemberGroup.AdditionalProperties.CanInviteUsers">
                            <v-select v-model="editedMemberGroup.AdditionalProperties.InviteGroups" :items="InviteMemberGroupsArray" item-text="id" label="Invite Groups" multiple></v-select> 
                          </v-list-item>  
                          <v-list-item v-if="editedMemberGroup.AdditionalProperties.CanInviteUsers">
                           <v-switch dense label="Strictly Invite to Company" v-model="editedMemberGroup.AdditionalProperties.CompanyInviteOnly"></v-switch>
                          </v-list-item>    
                          <v-list-item>
                            <v-list-item-content>
                               Export Data
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch v-model="editedMemberGroup.AdditionalProperties.CanExport"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                          <v-list-item>
                            <v-list-item-content>
                               Import Data
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-switch v-model="editedMemberGroup.AdditionalProperties.CanImport"></v-switch>
                            </v-list-item-action>
                          </v-list-item>  
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel>
                                <v-expansion-panel-header> Data Filters
                                  </v-expansion-panel-header>
                                  <v-expansion-panel-content>
                                    <v-select multiple :items="SystemTableFilters" return-object dense item-text="Name" clearable
                                        v-model="editedMemberGroup.UserTableFilters"></v-select>
                                     <v-btn to="/SystemTableFilters">Add System Filter</v-btn> 
                                  
                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                      <v-expansion-panel>
                        <v-expansion-panel-header> Activity Queries
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                            <v-switch label="Related Owner" dense v-model="editedMemberGroup.ActivityQueries.ActivitiesRegardingOwner"></v-switch>
                            <v-switch label="Related Owner Manager" dense v-model="editedMemberGroup.ActivityQueries.ActivitiesRegardingSubordinateOwner"></v-switch>
                            <v-switch label="Related Business Unit" dense v-model="editedMemberGroup.ActivityQueries.ActivitiesRegardingUnit"></v-switch>
                            <v-switch label="Related Business Unit in Structure" dense v-model="editedMemberGroup.ActivityQueries.ActivitiesRegardingUnitStructure"></v-switch>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>           
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CancelMemberGroupDialog()" dark color="warning">
                          Cancel
                      </v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="SaveMemberGroup(editedMemberGroup)" dark color="green">
                          Save</v-btn>
                  </v-card-actions>
              </v-card>
      </v-dialog>
      <v-dialog v-model="UserMemberGroupdialog" width="400">
              <v-card v-if="editedUser" flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                       <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!editedUser.Profile_Photo">                               
                            {{editedUser.Full_Name.split(' ')[0].substr(0,1)}}{{editedUser.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-avatar>
                        <v-avatar size="40" v-if="editedUser.Profile_Photo">
                            <img :src="editedUser.Profile_Photo" style="object-fit: cover;" >
                        </v-avatar>
                      {{editedUser.Full_Name}} 
                  </v-card-title>
                  <v-card-text>
                      <v-select :disabled="editedUser.IsSystemAdmin" clearable @change="UpdateUserMemberGroup(editedUser)" :items="InviteMemberGroupsArray" placeholder="Member Group" v-model="editedUser.MemberGroup" item-text="Name" return-object
                      />
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseMemberGroupDialog()" dark color="warning">
                          Cancel
                      </v-btn>
                  </v-card-actions>
              </v-card>
      </v-dialog>
      <v-dialog v-model="UserAccessdialog" width="400">
              <v-card v-if="editedUser" flat tile>
                  <v-card-title class="mediumoverline recordtoolbar white--text">
                       <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!editedUser.Profile_Photo">                               
                            {{editedUser.Full_Name.split(' ')[0].substr(0,1)}}{{editedUser.Full_Name.split(' ')[1].substr(0,1)}}
                        </v-avatar>
                        <v-avatar size="40" v-if="editedUser.Profile_Photo">
                            <img :src="editedUser.Profile_Photo" style="object-fit: cover;" >
                        </v-avatar>
                      {{editedUser.Full_Name}} 
                  </v-card-title>
                  <v-card-text>
                      <v-select :items="AppsDataBase" label="Default App" v-model="editedUser.Primary_Appid" item-text="id"
                      />
                  </v-card-text>
                  <v-card-actions>
                      <v-btn @click="CloseNonGuestUserDialog()" dark color="warning">
                          Cancel
                      </v-btn>
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="UpdateUserData(editedUser)" dark color="green">
                          Save</v-btn>
                  </v-card-actions>
              </v-card>
      </v-dialog>
      <v-dialog v-model="NavItemsDialog" max-width="400">
              <v-card v-if="ActiveNavbar">
                  <v-card-title>Nav Items</v-card-title>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <v-menu  									
                      :close-on-content-click="false"									
                      :nudge-right="40"									
                      transition="scale-transition"									
                                      
                      min-width="200px"									
                      >									
                      <template v-slot:activator="{ on }">									
                        <v-btn v-on="on">Add New</v-btn>								
                      </template>					
                      <v-card>
                        <v-card-title>
                          New Item
                        </v-card-title>
                        <v-card-text>
                          <v-text-field dense v-model="NewItemTitle" label="Title"/>
                          <v-text-field dense v-model="NewItemIcon" label="Icon"/>
                          
                          <v-switch v-if="ActiveNavbar.Name === 'Public Navbar'" v-model="NavItemisDropdown" label="Drop Down"/>
                          <v-select v-if="ActiveNavbar.Name !== 'Public Navbar' && ActiveNavbar.Name !== 'Guest Navbar' || NavItemisDropdown" multiple v-model="NewItemRoutes" :items="ItemRouteOptions" return-object label="Routes" item-text="title"/>
                          <v-select v-if="ActiveNavbar.Name === 'Public Navbar' && !NavItemisDropdown" v-model="NewItemSingleRoute" :items="ItemRouteOptions" label="Route Single" item-text="title"/>
                          <v-select v-if="ActiveNavbar.Name === 'Guest Navbar'" v-model="NewItemSingleRoute" :items="ItemRouteOptions" label="Route Single" item-text="path"/>
                        </v-card-text>
                        <v-card-actions>
                          <v-btn @click="AddNavItem()">Add</v-btn>
                        </v-card-actions>
                      </v-card>									
                  </v-menu>
                        
                      </v-list-item>
                    <v-list v-for="(item,index) in NavItems" :key="item.itemObjKey">
                      <v-menu  
                        v-model="DeleteNaviItemMenu"									
                          :close-on-content-click="false"									
                          :nudge-right="40"									
                          transition="scale-transition"									
                                          
                          min-width="200px"									
                          >									
                          <template v-slot:activator="{ on }">									
                            <v-icon style="position: absolute;right:10px;" right v-on="on">mdi-delete-forever</v-icon>							
                          </template>
                          <v-card>
                            <v-card-title>
                              Delete Menu Item?
                            </v-card-title>
                            <v-card-actions>
                              <v-btn @click="DeleteNaviItemMenu = !DeleteNaviItemMenu">Cancel</v-btn>
                              <v-spacer></v-spacer>
                              <v-btn @click="DeleteMenuItem(item,index)">Delete</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>   
                    <v-list-item>
                      <v-text-field dense v-model="item.title" label="Title" />
                    </v-list-item>
                    <v-list-item>
                      <v-text-field dense v-model="item.icon" label="Icon" />
                    </v-list-item>
                    <v-list-item v-if="ActiveNavbar.Name === 'Public Navbar'">
                      <v-switch dense v-model="item.isDropdown" label="Drop Down"/>
                    </v-list-item>
                    <v-list-item v-if="ActiveNavbar.Name !== 'Public Navbar' && ActiveNavbar.Name !== 'Guest Navbar' || item.isDropdown">
                      <v-select dense multiple v-model="item.Children" :items="ItemRouteOptions" return-object label="Routes" item-text="title"/>
                    </v-list-item>
                    <v-list-item v-if="ActiveNavbar.Name === 'Public Navbar' && !item.isDropdown || ActiveNavbar.Name === 'Guest Navbar'">
                      <v-select dense v-model="item.Path" :items="ItemRouteOptions" label="Route Single" item-text="Path"/>
                    </v-list-item>
                    <v-list-item>
                    </v-list-item>
                    </v-list>
                    </v-list>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="CancelNavItemsDialog()">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn @click="UpdateNavItems()">Update</v-btn>
                  </v-card-actions>
                </v-card>
          </v-dialog>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/RAFullLogo.png"/>
        </v-avatar>
      Member Settings
      </v-card-title>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Notifications
      </v-card-subtitle>
      <v-card-text >
        <v-list-item dense>                     
            <v-switch class="machinetoggle" @change="UpdateAllowNotificaitonDisable()" v-model="System.Allow_Notification_Disable" label="Allow Users to Disable Notfications"></v-switch>
            </v-list-item>
      </v-card-text>
      </v-card>
       <v-card elevation="0" class="siteconfigcard" >
        <v-card-subtitle class="mediumoverline">
          Default Routes
      </v-card-subtitle>
      <v-card-text >
         <v-list-item dense>                     
            <v-select :disabled="!Has_Public_Signup" v-model="System.Guest_Landing_Page" item-text="path" return-object :items="RouteOptions({PathFilter: 'Guest Routes'})" label="Guest Landing Page"></v-select>
            </v-list-item>
            <v-list-item dense>                     
            <v-select :disabled="!SocialNetworkisActive" v-model="System.Social_Landing_Page" item-text="path" return-object :items="RouteOptions({PathFilter: 'Internal Pages'})" label="Social Landing Page"></v-select>
            </v-list-item>
              <v-list-item dense>                     
            <v-text-field :disabled="!SocialNetworkisActive" v-model="System.Social_Module_Name" label="Social Module Name">

            </v-text-field>
            </v-list-item>
              <v-list-item>
                <v-select @change="CheckActiveNavbar()" v-model="ActiveNavbar" item-text="Name" clearable
                 return-object :items="Navbars" label="Navbar"></v-select>                
              </v-list-item>
            
      </v-card-text>
      <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateAppRoutesandSocial()" dark color="success">
              Save
          </v-btn>
      </v-card-actions>
      </v-card>
      <v-card elevation="0" class="siteconfigcard"  v-if="!Has_Member_Groups">
        <v-card-subtitle class="mediumoverline">
          Non Guest Members
      </v-card-subtitle>
      <v-card-text>
            <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenNonGuestUserDialog" :AdditionalHeaders="[]" :ShowExpand="false"
        :UsersLookupArray="NonGuestUsers" :SystemEntities="SystemEntities" :TableName="'Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
        />
    </v-card-text>
      <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateAppRoutesandSocial()" dark color="success">
              Save
          </v-btn>
      </v-card-actions>
      </v-card>
      <v-card elevation="0" class="siteconfigcard" >
        <v-card-subtitle class="mediumoverline">
          Member Groups
      </v-card-subtitle>
      <v-card-text v-if="!Has_Member_Groups">
           If the Member Groups plugin is active, you can configure Member Groups here.
    </v-card-text>
    <v-card-text v-if="Has_Member_Groups">
         <v-btn @click="ActivateMemberGroupDialog()" dark color="success">Add</v-btn>
           <v-expansion-panels>
             
            <v-expansion-panel v-for="memgroup in MemberGroupsArray" :key="memgroup.itemObjKey">
            <v-expansion-panel-header> {{memgroup.Name}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                   <v-list-item>
                    <v-list-item-content>
                        <span v-html="memgroup.Description">
                        </span>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn :disabled="memgroup.id === 'System_Administrators'" outlined @click="ActivateMemberGroupDialog(memgroup)">Configure</v-btn>
                    </v-list-item-action>
                   </v-list-item>      
                        <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenMemberGroupDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                        :UsersLookupArray="MemberGroupUsers(memgroup)" :SystemEntities="SystemEntities" :TableName="'Member Group Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                        />   
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
            <v-expansion-panel-header> UNASSIGNED USERS
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                   <v-list-item>
                    <v-list-item-content>
                       Users below DO NOT have a Member Group assigned to them
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn disabled outlined @click="ActivateMemberGroupDialog(memgroup)">Configure</v-btn>
                    </v-list-item-action>
                   </v-list-item>      
                        <UserLookupTable :CanSelect="false" :SelectMethod="'OpenUserDialog'" @OpenUserDialog="OpenMemberGroupDialog" :AdditionalHeaders="[]" :ShowExpand="false"
                        :UsersLookupArray="NonMemberGroupUsers" :SystemEntities="SystemEntities" :TableName="'Member Group Users'" :Rules="[]" :UserHeaders="UserHeaders" :CanAdd="false" @AddMethod="''"
                        />   
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
    </v-card-text>
      <!-- <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateAppRoutesandSocial()" dark color="success">
              Save
          </v-btn>
      </v-card-actions> -->
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          User Invites
      </v-card-subtitle>
      <v-card-text >
        <UserInvites :System="System" :SystemEntities="SystemEntities"/>
      </v-card-text>
      </v-card>
      <v-card v-if="UserCompaniesActive" elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          User Companies
      </v-card-subtitle>
      <v-card-text >
        <UserCompanies :System="System" :SystemEntities="SystemEntities"/>
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Billing Accounts
      </v-card-subtitle>
      <v-card-text >
        <BillingAccounts :System="System" :SystemEntities="SystemEntities"/>
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import UserLookupTable from '@/components/General/UserLookupTable'
import ContentEditableField from '@/components/Database/ContentEditableField'
import UserInvites from '@/components/Dashboards/UserInvites'
import BillingAccounts from '@/components/Dashboards/BillingAccounts'
import UserCompanies from '@/components/Dashboards/UserCompanies'

export default {
    props: ['System','SystemEntities','SystemTableFilters','DBNavLists'],
    components: {InfoSheet,UserLookupTable,ContentEditableField,UserInvites,BillingAccounts,UserCompanies},
    data() {
        return {
          GuestNavbarItems: [],
          DBNavbarItems: [],
          SocialNavbarItems: [],
          PublicNavbarItems: [],
          ActiveNavbar: '',
          NavItemsDialog: false,
          NewItemTitle: '',
          NewItemIcon: '',
          NewItemRoutes: [],
          NavItemisDropdown: false,
          NewItemSingleRoute: '',
          Navbars: [
            {Name: 'Guest Navbar', Prop: 'GuestNavbarItems'},
            // {Name: 'DB Navbar', Prop: 'DBNavbarItems'},
            // {Name: 'Social Navbar', Prop: 'SocialNavbarItems'},
            // {Name: 'Public Navbar', Prop: 'PublicNavbarItems'}
          ],
              AccountTypes: [
                {ID: 1000001, Name: 'Store Account',Pluginid: 'Online_Store',AppPlugin: true},
                {ID: 1000002, Name: 'Group Account',Pluginid: 'Site_Groups'},
                {ID: 1000003, Name: 'Suite Ownership',Prop: 'SystemisRA'},
                {ID: 1000004, Name: 'App Membership',Pluginid: 'Monetization'},
                {ID: 1000005, Name: 'Cash Loan Account',Pluginid: 'Cash_Loans',AppPlugin: true},
                {ID: 1000006, Name: 'Market Account',Pluginid: 'Monetization'},
            ],
            RMSecurityRoles: [],
            BUSecurityRoles: [],
            CustomSecurityRoles: [],
            ClientAppUsers: [],
            editedMemberGroup: {
                Name: '',
                Description: '',
                SecurityRoles: [],
                AdditionalProperties: {
                    MonetizationOfficer: false,
                    IsEagleViewer: false,
                    BusinessProfileAdmin: false,
                    SiteContentAdmin: false,
                    IsGuestUser: true,
                    IsSystemAdmin: false,
                    IsTeamActivityManager: false,
                    CanInviteUsers: false,
                    InviteGroups: [],
                    CanExport: false,
                    CanImport: false,
                },
                CanSocial: true,
                SocialRestrictions: false,
                SocialChannels: [],
                CompanySocial: false,
                UserTableFilters: [],
                ActivityQueries: {},
                ModerationPermissions: [],
                // DBNavList: ''
            },
            defaultMemberGroup: {
                Name: '',
                Description: '',
                SecurityRoles: [],
                AdditionalProperties: {
                    MonetizationOfficer: false,
                    IsEagleViewer: false,
                    BusinessProfileAdmin: false,
                    SiteContentAdmin: false,
                    IsGuestUser: true,
                    IsSystemAdmin: false,
                    IsTeamActivityManager: false,
                    CanInviteUsers: false,
                    InviteGroups: [],
                    CanExport: false,
                    CanImport: false,
                },
                CanSocial: true,
                SocialRestrictions: false,
                SocialChannels: [],
                CompanySocial: false,
                UserTableFilters: [],
                ActivityQueries: {},
                ModerationPermissions: [],
                // DBNavList: ''
            },
            MemberGroupDialog: false,
            UserMemberGroupdialog: false,
            UserAccessdialog: false,
            editedUser: '',
            editedUserIndex: -1,
            UserHeaders: [
                {text: 'Full Name', value: 'Full_Name', class: 'overline'},
                {text: 'Business Unit', value: 'Business_Unit.Name', class: 'overline'}
            ],
            SystemTickets: [],
            RoadmapItems: [],
            ModerationPermissionsOptions: [
        'Articles',
        'Blogs',
        'Events',
        'Forums',
        'Polls',
        'Groups',
        'Meetups',
        'Members',
        'Landing Pages',
        'Banner Ads',
        'Notices',
        'Wiki',
        'Glossary',
        'Public Articles',
        'Public Blogs',
        'Public Events',
        'Public Forums',
        'Public Polls',
        'Public Groups',
        'Public Meetups',
        'Public Members',
        'Public Landing Pages',
        'Public Banner Ads',
        'Public Notices',
        'Public Wiki',
        'Public Glossary',
      ],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },
            SocialChannelOptions: [
              {Pluginid: 'Classifieds',Name: 'Classifieds'},
              {Pluginid: 'Site_Articles',Name: 'Site Articles'},
              {Pluginid: 'Site_Blogs',Name: 'Site Blogs'},
              {Pluginid: 'Site_Events',Name: 'Site Events'},
              {Pluginid: 'Site_Forums',Name: 'Site Forums'},
              {Pluginid: 'Site_Meetups',Name: 'Site Meetups'},
              {Pluginid: 'Site_Polls',Name: 'Site Polls'},
            ],
            ModeratorOptions: 	[
              {Name: 'Articles',Pluginid: 'Site_Articles'},
              {Name: 'Blogs',Pluginid: 'Site_Blogs'},
              {Name: 'Events',Pluginid: 'Site_Events'},
              {Name: 'Forums',Pluginid: 'Site_Forums'},
              {Name: 'Polls',Pluginid: 'Site_Polls'},
              {Name: 'Groups',Pluginid: 'Site_Groups'},
              {Name: 'Meetups',Pluginid: 'Site_Meetups'},
              {Name: 'Members'},
              {Name: 'Landing Pages'},
              {Name: 'Banner Ads'},
              {Name: 'Notices',Pluginid: 'Digital_Notice_Board'},
              {Name: 'Wiki'},
              {Name: 'Glossary',Pluginid: 'Glossary'},
              {Name: 'Public Articles',Pluginid: 'Site_Articles'},
              {Name: 'Public Blogs',Pluginid: 'Site_Blogs'},
              {Name: 'Public Events',Pluginid: 'Site_Events'},
              {Name: 'Public Forums',Pluginid: 'Site_Forums'},
              {Name: 'Public Polls',Pluginid: 'Site_Polls'},
              {Name: 'Public Groups',Pluginid: 'Site_Groups'},
              {Name: 'Public Meetups',Pluginid: 'Site_Meetups'},
              {Name: 'Public Members'},
              {Name: 'Public Landing Pages'},
              {Name: 'Public Banner Ads'},
              {Name: 'Public Notices',Pluginid: 'Digital_Notice_Board'},
              {Name: 'Public Wiki'},
              {Name: 'Public Glossary',Pluginid: 'Glossary'},
            ],
        }
    },	
    computed:{
      TrackedEntityStatusField(){
        return this.TrackedEntity ? this.TrackedEntity.AllFields.find(obj => obj.id === 'Status') : ''
      },
      TrackedEntity(){
        return this.editedMemberGroup.AdditionalProperties.TrackedEntity ? this.SystemEntities.find(obj => obj.id === this.editedMemberGroup.AdditionalProperties.TrackedEntity) : ''
      },
      ActiveLoanOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Cash Loan'
        })
      },
      ActiveStoreOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Store Order'
        })
      },
      StoreOrdersActive(){
        //we could also look particularly for a web form that matchies this entity, and ensure this web form also installs on plugin install, so...
        //the point is we keep wanting to use web forms which maybe we should not? but it's going to allow very custom and advanced setup later so...shall we do this?
        return this.AppsPlugins.includes('Online_Store') && this.ActiveStoreOrderEntities.length > 0
      },
      LoanOrdersActive(){
        //we could also look particularly for a web form that matchies this entity, and ensure this web form also installs on plugin install, so...
        //the point is we keep wanting to use web forms which maybe we should not? but it's going to allow very custom and advanced setup later so...shall we do this?
        return this.AppsPlugins.includes('Cash_Loans') && this.ActiveLoanOrderEntities.length > 0
      },
      NavItemsPropName(){
        return this.ActiveNavbar ? this.ActiveNavbar.Prop : ''
      },
      ItemRouteOptions(){
          let routes = this.$router.getRoutes()
            return routes.filter(route => {
                    if(this.ActiveNavbar.Name === 'DB Navbar'){
                    return route.meta && route.meta.type === 'Data Tables'
                    }
                    else if(this.ActiveNavbar.Name === 'Social Navbar' || this.ActiveNavbar.Name === 'Guest Navbar'){
                    return route.meta && route.meta.RouteEventID === 1027
                    }
                    else if(this.ActiveNavbar.Name === 'Public Navbar'){
                    return route.meta && route.meta.RouteEventID === 1028
                    }
            }).map(route => {
              let mapobj = {}
                if(this.ActiveNavbar.Name === 'DB Navbar'){
                  mapobj.icon = route.meta.icon,
                  mapobj.title = route.meta.subcat,
                  mapobj.to = '/'+route.meta.subcat.split(' ').join(''),
                  mapobj.target = 'self',
                  mapobj.QueryName = route.meta.subcat.split(' ').join('')+'Query'
                }
                else if(this.ActiveNavbar.Name === 'Social Navbar' || this.ActiveNavbar.Name === 'Guest Navbar'){
                  mapobj.title = route.meta.subcat
                  mapobj.Path = route.path
                }
                else if(this.ActiveNavbar.Name === 'Public Navbar'){
                  mapobj.title = route.meta.subcat
                  mapobj.Path = route.path
                }
                return mapobj
            })
        },
      NavItems(){
        return this.NavItemsPropName ? this[this.NavItemsPropName] : []
      },
      SystemSocialReady(){
        return this.PluginDataBase && this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active && this.System.Social_Landing_Page
      },
      ComputedBAAccountTypes(){
        return this.AccountTypes.filter(type => {
          return this.PluginDataBase && this.PluginDataBase[type.Pluginid] && this.PluginDataBase[type.Pluginid].Active || type.AppPlugin && this.AppsPlugins.includes(type.Pluginid)
        }).map(type => {
          return {
            Name: type.Name,
            ID:type.ID
          }
        })
      },
      SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
      ComputedSocialChannelOptions(){
        return this.SocialChannelOptions.filter(opt => {
          return this.PluginDataBase[opt.Pluginid] && this.PluginDataBase[opt.Pluginid].Active
        }).map(opt => {
          return opt.Name
        })
      },
      UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      NonMemberGroupUsers(){
        return this.ClientAppUsers.filter(user => {
                return !user.MemberGroupid
            })
      },
        InviteMemberGroupsArray(){
            return this.MemberGroupsArray.filter(item => {
                return item.id !== 'System_Administrators'
            })
        },
        ConfigDB(){
        return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID)
      },
        AllSecurityRoles(){
        return this.CustomSecurityRoles.concat(this.BUSecurityRoles,this.RMSecurityRoles)
      },
      FilteredModeratorOptions(){
          this.ModerationPermissionsOptions
          return this.ModeratorOptions.filter(opt => {
            if(opt.Pluginid){
              return this.PluginDataBase && this.PluginDataBase[opt.Pluginid] && this.PluginDataBase[opt.Pluginid].Active
            }
            else{
              return opt
            }
          }).map(opt => {
            return opt.Name
          })
      },
        ComputedModerationPermissionsOptions(){
      let arr1 = this.FilteredModeratorOptions
      let arr2 = this.SystemEntities.filter(obj => obj.Entity_Type && obj.Entity_Type.Name === 'Classified Listing').map(entity => {
        return entity.id.split('_').join(' ')+' Classified Listings'
      })
      return arr1.concat(arr2)
    },
        MemberGroupsArray(){
            return this.$store.state.MemberGroupsArray
        },
        AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        AppsPlugins(){
          return this.AppsDataBase.map(app => {
            return app.Plugins.filter(plug => {
              return plug.Active
            }).map(plug => {
              return plug.id
            })
          }).flat()
        },
        DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        CurrentUserProps(){
          let user = this.userLoggedIn
          let userobj = {
            Full_Name: user.Full_Name,
            Name: user.Name,
            Surname: user.Surname,
            id: user.id,
            Email: user.Email
          }
          return userobj
        },
        UsersArray(){
            return this.$store.state.Users
        },
        NonGuestUsers(){
            return this.UsersArray.filter(user => {
                return !user.IsGuestUser
            })
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
         Has_Member_Groups(){
         return this.PluginDataBase && this.PluginDataBase.Member_Groups ? this.PluginDataBase.Member_Groups.Active : false
       },
        Has_Public_Signup(){
         return this.PluginDataBase && this.PluginDataBase.Public_Signup ? this.PluginDataBase.Public_Signup.Active : false
       },
        WebsiteisActive(){
            return this.$store.state.WebsiteisActive
        },
        SocialNetworkisActive(){
            return this.$store.state.SocialNetworkisActive
        },
        DatabaseisActive(){
            return this.$store.state.DatabaseisActive
        },
        DocumentationisActive(){
            return this.$store.state.DocumentationisActive
        },
         ActiveDB(){
            return db
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
      System : {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue && !oldvalue){
                    this.PrepNavbarItems()
                    
                }
            },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetClientAppUsers()
        this.GetSecurityRoles(this.ConfigDB.collection('BUsecurityroles'),'BUSecurityRoles','BUsecurityroles')
        this.GetSecurityRoles(this.ConfigDB.collection('Customsecurityroles'),'CustomSecurityRoles','Customsecurityroles')
        this.GetSecurityRoles(this.ConfigDB.collection('RMsecurityroles'),'RMSecurityRoles','RMsecurityroles')
        this.PrepNavbarItems()
    },
    methods:{
      DeleteMenuItem(item,index){
        this.NavItems.splice(index,1)
      },
      PrepNavbarItems(){
        if(this.System.DBNavbarItems){
          this.DBNavbarItems = this.System.DBNavbarItems
        }
        if(this.System.GuestNavbarItems){
          this.GuestNavbarItems = this.System.GuestNavbarItems
        }
        if(this.System.SocialNavbarItems){
          this.SocialNavbarItems = this.System.SocialNavbarItems
        }
        if(this.System.PublicNavbarItems){
          this.PublicNavbarItems = this.System.PublicNavbarItems
        }
      },
      NavItemsDialogActivate(){
          this.NavItemsDialog = true
        },
      CancelNavItemsDialog(){
        this.NavItemsDialog = false
        this.NewItemIcon = ''
        this.NewItemTitle = ''
        this.NewItemRoutes = []
        this.ActiveNavbar = ''
      },
      UpdateNavItems(){
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
              [this.NavItemsPropName]: this[this.NavItemsPropName]
            })
            this.CancelNavItemsDialog()
      },
      AddNavItem(){
          if(this.ActiveNavbar === 'Public Navbar'){
            let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Path: this.NewItemSingleRoute.split(' ').join('-'),
                    // Children: this.NewItemRoutes
                  }
                  if(this.NavItemisDropdown){
                    newitem.Children = this.NewItemRoutes
                    newitem.isDropdown = true
                  }
              this.PublicNavbarItems.push(newitem)
            this.UpdateNavItems()
          }
          else if(this.ActiveNavbar === 'Guest Navbar'){
            let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Path: this.NewItemSingleRoute.split(' ').join('-'),
                    // Children: this.NewItemRoutes
                  }
              this.GuestNavbarItems.push(newitem)
            this.UpdateNavItems()
          }
          else{
             let newitem = {
                    icon: this.NewItemIcon,
                    title: this.NewItemTitle,
                    Children: this.NewItemRoutes
                  }
              this[this.NavItemsPropName].push(newitem)
            this.UpdateNavItems() 
          }
        },
      CheckActiveNavbar(){
        if(this.ActiveNavbar){
          this.NavItemsDialogActivate()
        }
        else{
          this.CancelNavItemsDialog()
        }
      },
      UpdateUserMemberGroup(user){
        if(user.MemberGroup){
          let memgroup = user.MemberGroup
          let NewUserObj = {
          }
          NewUserObj.MemberGroup = memgroup
          NewUserObj.MemberGroupid = memgroup.id
          NewUserObj.Modified_By = this.CurrentUserProps	
          NewUserObj.Modified_Byid = this.CurrentUserProps.id	
          NewUserObj.Modified_On = new Date()
          let addpropsobj = memgroup.AdditionalProperties
          console.log(addpropsobj)
            for(var prop in addpropsobj) {
              if (addpropsobj.hasOwnProperty(prop)) {
                if(typeof addpropsobj[prop] !== 'undefined'){
                    NewUserObj[prop] = addpropsobj[prop]
                }
              }
            }
          NewUserObj.rolesarrayQuery = []
          NewUserObj.rolesarrayDBRules = {}
          // if(memgroup.DBNavList){
          //   NewUserObj.DBNavList = memgroup.DBNavList
          // }
          memgroup.SecurityRoles.map(role => {
            NewUserObj.rolesarrayDBRules[role.split(' ').join('')] = true
            NewUserObj.rolesarrayQuery.push(role)
          })
          if(memgroup.UserTableFilters.length > 0){
            NewUserObj.UserTableFilters = memgroup.UserTableFilters
          }
          //let advprops = ['SocialRestrictions','SocialChannels','CompanySocial']
          //Just a note. we update these advprops by appendinging the new Member Group obj, never need to update on roledoc as well
          if(memgroup.Primary_Appid){
            //this one is an issue, because we insisted on doing it on user, not users roles. undoubtedly we will ahve issues with it until final call is made
            NewUserObj.Primary_Appid = memgroup.Primary_Appid
          }
          console.log(NewUserObj)
         db.collection('userroles').doc(user.id).update(NewUserObj) 
         this.UpdateUserMemberGroupModerationPermissions(user,memgroup)
        }
        else{
          db.collection('userroles').doc(user.id).update({
          MemberGroup: null,
          MemberGroupid: null
        })
        }
        
      },
      UpdateUserMemberGroupModerationPermissions(user,memgroup){
      let ModerationPermissions = memgroup.ModerationPermissions
        if(ModerationPermissions && ModerationPermissions.length > 0){
          db.collection('sitemoderators').doc(user.id).set({
            Full_Name: user.Full_Name,
            Name: user.Name,
            Surname: user.Surname,
            ModerationPermissions: ModerationPermissions,                        
          }).then(() => {
          }).catch(error => {
              console.log(error)
              //Look at the documentation for Callable Cloud Functions to adapt this part:
              //https://firebase.google.com/docs/functions/callable?authuser=0
            });
        }
      },
        MemberGroupUsers(memgroup){
            return this.ClientAppUsers.filter(user => {
                return user.MemberGroupid === memgroup.id
            })
        },
        UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
        FieldObject[prop] = value
        //console.log(AdditionalSaveMethod)
        if(AdditionalSaveMethod){
            this[AdditionalSaveMethod]()
        }
        //console.log(this.field,FieldObject,this.Record)
        },
        ActivateMemberGroupDialog(item){
            if(item){
                this.editedMemberGroup = Object.assign({},item)
            }
            this.MemberGroupDialog = true
        },
        CancelMemberGroupDialog(){
            this.editedMemberGroup = Object.assign({},this.defaultMemberGroup)
            this.MemberGroupDialog = false
        },
        SaveMemberGroup(editedMemberGroup){
            let id = ''
            if(editedMemberGroup.id){
                id = editedMemberGroup.id
            }
            else{
                id = editedMemberGroup.Name.split(' ').join('_')
                editedMemberGroup.Created_By = this.CurrentUserProps	
                editedMemberGroup.Created_Byid = this.CurrentUserProps.id		
                editedMemberGroup.Created_On = new Date()
                editedMemberGroup.Status = this.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
                editedMemberGroup.Status_Reason = editedMemberGroup.Status.Options.find(obj => obj.Name === 'Open')
                if(editedMemberGroup.AdditionalProperties.IsGuestUser && !this.System.Guests_can_Social){
                  //the default intention so far indicates guests are not supposed to social, thus the group being for guest users, assumes the default intention
                  //if intention was that they can indeed social, then the default render value of "true" applies so safe.
                  //finally yes this only applicable on create of editedmember group as obviously you may want to indeed set a specific group with social permissions
                  editedMemberGroup.CanSocial = false
                }
            }  
                      
            editedMemberGroup.Modified_By = this.CurrentUserProps	
            editedMemberGroup.Modified_Byid = this.CurrentUserProps.id	
            editedMemberGroup.Modified_On = new Date()
            // 1.AdditionalProperties assign, no shit
            //2. Roles got assigned, just remember we don't edit roles in here anywa should still allow for it but yeah
            //3.users must get updated now with these props. this the big one
            //ALSO PRIOR TO ALL THIS DO SET ALERTS FOR UERS WITH PERMISSIONS OUTSIDE RANGE OF MEMBER GROUP AND OFFER NEW GOROIUP OF "AS PER GROUP" AND KEEP AUDIT HISTORY ON USERROLES DOC
            //IU think user permissions though, we gonna utilize array mappings on UpdateUserMemberGroup method, which itself needds to include the rolesarray query and others.
            
            //LIKELY ALL NOTES ABOVE COVERED AND FOR REFERENCE ONLY
            //RIGHT NOW THOUGH ONE LITTLE TURD - SITE MODERATORS. TWO THINGS ON THIS
            //1. WE DON'T SEEM TO PROPOGATE SITEMODERATORS AT ALL, BUT PROBABLY MORE IMPORTANTLY
            //2. WE SEEM TO ALLOW MODERATION PERMISSIONS, WHILE RESTRICTING. WHICH MAKES SENSE COULD MODERATORE BLOGS, BUT BE RESTRICTED TO BLOGS ONLY RIGHT
            //HOWEVER IT'S LIKE THIS - THE SITEMODERATORS PROPOGATION MUST AUTOMATICALLY PUSH CHANNELS THE GROUP IS MDOERATORS OF     
            
            //Okay no no no think logically about this...So SocialReadPass checks Member Group permissions. 
            //Sure fronte dn we make memgroup permissions take preference and override
            //But imagine this now - dude is moderator, for their company...not other copmanies...
            //nothing wrong with that bro, just need to keep the companyid check in query..
            //okay so conclusion? In conclusion it means yes he MUSt be able to call articles, extend socialchannels with articles
            db.collection('membergroups').doc(id).set(editedMemberGroup)
            let users = this.MemberGroupUsers(editedMemberGroup).map(user => {
              let useorbj = Object.assign({},user)
              useorbj.MemberGroup = editedMemberGroup     
              return useorbj         
            })
            users.map(user => {
              this.UpdateUserMemberGroup(user)
            })
            this.CancelMemberGroupDialog()
        },
        UpdateActivityQueries(user){
             let props = ['ActivitiesRegardingOwner','ActivitiesRegardingSubordinateOwner','ActivitiesRegardingUnit','ActivitiesRegardingUnitStructure']
            let obj = {}
            props.map(prop => {
                if(typeof user[prop] !== 'undefined'){                    
                obj[prop] = user[prop]
                }
            })
            db.collection('userroles').doc(user.id).update(obj)
    },
        UpdateUserData(user){
            let props = ['Primary_Appid']
            let obj = {}
            props.map(prop => {
                obj[prop] = user[prop]
            })
            db.collection('users').doc(user.id).update(obj)
            this.CloseNonGuestUserDialog()
        },
        OpenMemberGroupDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.NonGuestUsers.indexOf(user)
            this.UserMemberGroupdialog = true            
        },
        CloseMemberGroupDialog(){            
            this.UserMemberGroupdialog = false         
            this.editedUser = ''
            this.editedUserIndex = -1   
        },
        OpenNonGuestUserDialog(user){
            this.editedUser = user
            this.editedUserIndex = this.NonGuestUsers.indexOf(user)
            this.UserAccessdialog = true            
        },
        CloseNonGuestUserDialog(){            
            this.UserAccessdialog = false         
            this.editedUser = ''
            this.editedUserIndex = -1   
        },
        GetSecurityRoles(query,array,col){
        query.onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let obj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                obj.RoleConfigCollection = col
                this[array].push(obj)
              }
            })
        })
      },
        GetClientAppUsers(){
        let vm = this
        db.collection('userroles').onSnapshot(res => {
            const changes = res.docChanges();
            changes.forEach(change => {
            if (change.type === 'added') {
                let userobj = {
                ...change.doc.data(),
                id: change.doc.id
                }
                vm.GetClientAppUsersBasic(userobj.id,userobj).then(result => {
                userobj.Full_Name = result.Full_Name
                if(result.Profile_Photo){
                  userobj.Profile_Photo = result.Profile_Photo
                }
                vm.ClientAppUsers.push(userobj)
                })
                
            }
            })                 
        })
      },
      GetClientAppUsersBasic(userid,userobj){
        let vm = this
        return new Promise(function(resolve, reject) {
          db.collection('users').doc(userid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            resolve(userdata)
          })
        })
      },
        UpdateAppRoutesandSocial(){
            let values = ['Guest_Landing_Page','Social_Landing_Page','Social_Module_Name']
            values.map(val => {
                if(typeof this.System[val] !== 'undefined'){
                     db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                        [val]: this.System[val],
                    })
                }
            })
           
        },
        UpdateAllowNotificaitonDisable(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                Allow_Notification_Disable: this.System.Allow_Notification_Disable,
            })
        },  
        NoPathFilterRoutePass(route){
        let routernonroutes = ['404','PasswordReset','UserInvite','ExpiredLink','404','landingpagesingle','PermissionError','login']
        //SiteEventSocialSingle and sarrticles etc excluded from route.meta.type Social
        let mainnonroutes = ['FeaturedMember','BusinessMember','SiteMemberSingle','SitePostSingle','noticesingle','ClassifiedSingle','ClassifiedEnquiry',
        'GroupArticle','GroupMeetup','GroupDashboard','GroupBlog','GroupForum','GroupPage','GroupPoll','GroupSingle','GroupEdit','GroupPageEditor','GroupNewPoll','GroupNewArticle','GroupNewMeetup','GroupNewBlog',
        'GroupNewPoll','GroupWebFormEditor','GroupWebFormBuilder','GroupPageBuilder',
        'LinkedInAuth','TeamMemberActivities','DashboardBuilder','PipelineManagement','PageEditor','newbannerad','newlandingpage','noticenew','MyActivitiesbyType','MyTeamMemberActivities',
        'TableViewer','EntryViewer','WebFormEditor','SurveyBuilder','DirectoryBuilder','PDFExportFunctionBuilder','WikiCollectionBuilder','DataImport','SystemTrainingDocument','FeaturedMemberEdit',
        'CustomDashboard'
        ]
        if(!routernonroutes.includes(route.name) && !mainnonroutes.includes(route.name)){
         return !route.meta || route.meta && route.meta.RouteEventID !== 1028 && route.meta.RouteEventID !== 1027 && route.meta.TopicCategory !== 'Social Page' &&
         route.meta.type !== 'Social' && route.meta.type !== 'Classified Listing Viewer' && route.meta.type !== 'Wiki Editor' && route.meta.type !== 'Wiki Viewer'
         && !route.meta.DataSingleBuilder && !route.meta.ClassifiedListingBuilder 
        }
        else{
          return false
        }        
      },
        RouteOptions(field){
            let routes = this.$router.getRoutes()
            return routes.filter(route => {
                if(field.PathFilter){
                    if(field.PathFilter === 'None'){
                    return this.NoPathFilterRoutePass(route)
                    }
                    else if(field.PathFilter === 'Non Guest Routes'){
                    return route.name === 'MyDashboard' || route.name === 'MyProfile' || route.name === 'Home' || route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Guest Routes'){
                      //we had this.System.Guests_can_Social && route.meta && route.meta.RouteEventID === 1027 but then typically "store" page not allowed
                    return route.name === 'MyAccount' || route.meta && route.meta.RouteEventID === 1027 || this.System.Guests_can_Social && route.meta &&  route.meta.TopicCategory === 'Social Page'
                    }
                    else if(field.PathFilter === 'Public Pages'){
                        return route.meta && route.meta.RouteEventID === 1028
                    }
                    else if(field.PathFilter === 'Internal Pages'){
                      return route.meta && route.meta.RouteEventID === 1027 || route.meta && route.meta.TopicCategory === 'Social Page'
                    }
                }                
                else{
                    return route
                }
            })
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



