<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
           <!-- <v-list  v-if="WikiType && $route.meta.ClassifiedListingBuilder">                             
                <v-list-item v-if="EditedTab.Name === 'HTML Box' || EditedTab.Name === 'Title' || EditedTab.Name === 'Image' || EditedTab.Name === 'Parallax'">									
                    <v-switch v-model="EditedTab.ContentFromField" label="From Field"/>									
                </v-list-item>
           </v-list> -->
           
           <!-- <v-list-item v-if="EditedTab.HasWaves">
             <v-text-field
          label="Box Gradient"
          v-model="EditedTab.WavesGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
           </v-list-item>
           <v-list-item>
            <v-textarea v-model="EditedTab.SVGPath" label="SVG Code"/>
           </v-list-item>
           <v-list-item>
            <svg height="100%" width="100%" v-html="EditedTab.SVGPath">
                
            </svg>
           </v-list-item>
           <v-list-item dense v-if="EditedTab.HasWaves">
            <v-slider
                    label="Wave Height"
                        v-model="EditedTab.WaveHeight"
                        :max="100"
                        :min="10"
                        hide-details
                        class="align-center"
                    />
            </v-list-item>
           <v-list-item v-if="EditedTab.HasWaves">
            <v-switch v-model="EditedTab.WavesAnimated" label="Waves Animated"/>
           </v-list-item> -->
           <!-- <v-list-item @click="ToggleAdvancedCSSDialog()">
            Advanced CSS
           </v-list-item> -->
           <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard">
                  <v-text-field
          label="Label Gradient"
          v-model="EditedTab.LabelGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === '3D Carousel'">
                  <v-text-field
          label="Box Gradient"
          v-model="EditedTab.BoxGradient"
        />
        <a href="https://cssgradient.io/" target="_blank">Get Code</a>
                </v-list-item>
                
                <v-list-item v-if="EditedTab.Name === 'Card Row' && EditedTab.FlipCard">
                <v-menu   									
                    :close-on-content-click="false"									
                    :nudge-right="40"									
                    transition="scale-transition"									
                    offset-y									
                    min-width="200px"									
                    >									
                    <template v-slot:activator="{ on }">									
                <v-icon v-on="on" left>mdi-palette-advanced</v-icon>Label BG						
                    </template>									
                <v-color-picker v-model="EditedTab.LabelBGColor"></v-color-picker>									
                </v-menu>	
                </v-list-item>
                
             <v-list-item dense v-if="EditedTab.Name !== 'Image' && EditedTab.Name !== 'Parallax' && EditedTab.Name !== 'Info Sheet' && EditedTab.Name !== 'RSS Feed' && EditedTab.Name !== 'Card Link' && EditedTab.Name !== 'Card Blocks'" >
                    <v-switch v-model="EditedTab.HasImage" label="HasImage"/>
                    <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.Animated" label="Animated"/>                    
                </v-list-item>
                 <v-list-item dense v-if="EditedTab.Name === 'Card Row Single' ||EditedTab.Name === 'HTML Box' || EditedTab.HasTextBox || EditedTab.Name === 'Timeline Item' || EditedTab.Name === 'Store Product Single' || 
                 EditedTab.Name === 'Clipped Tab'">
                        <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.HasContent" label="Has Content"/>
                    <v-switch v-model="EditedTab.HasActionButton" label="Has Action Button"/>
                        <v-switch  v-if="EditedTab.Name === 'Clipped Tab'" v-model="EditedTab.Floating" label="Floating"/>
                </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row'">
                <v-select @change="CheckMagazineStyle(EditedTab)" v-model="EditedTab.Type" label="Type" :items="['Standard','Magazine Style','3D Carousel']"/>
            </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Magazine Style'">
                <v-switch @change="UpdateClearBoxes(EditedTab)" label="Clear Boxes" v-model="EditedTab.ClearBoxes" />
             </v-list-item>
             <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type === 'Magazine Style'">
                <v-color-picker v-model="EditedTab.MagazineHighlightColor"></v-color-picker>
             </v-list-item>
             
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style'">
                <v-switch label="Flip Card" v-model="EditedTab.FlipCard" />
                <v-switch label="Contain IMG" v-model="EditedTab.ContainIMG" />
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style' && EditedTab.FlipCard">
            <v-slider thumb-label="always" v-model="EditedTab.TitleSize" label="Title Size" min="0.25" max="4" step="0.25"/>
            </v-list-item>
            
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">
                <v-switch label="Has Text Box" v-model="EditedTab.HasTextBox" />
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' && EditedTab.HasTextBox">
                <v-switch label="FlipCard Text" v-model="EditedTab.FlipCard" />
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">
                <v-switch label="Transparent" v-model="EditedTab.Transparent" />								
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && EditedTab.Type !== 'Magazine Style'">
                <v-switch label="IMG on Top" v-model="EditedTab.IMGonTop" />
                 <v-switch label="Tiled IMG" v-model="EditedTab.TiledIMG" />
                 <v-switch label="Full IMG" v-model="EditedTab.FullIMG" />
                
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Card Row' && !Refreshing">
                <v-text-field
                        v-model.number="EditedTab.IMGHeight"
                        class="mt-0 pt-0"
                        type="number"
                        >  
                        <template v-slot:append>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'IMGHeight','down',50)"
                            >50
                            <v-icon> mdi-chevron-down </v-icon>
                            </v-btn>
                            <v-btn small
                            @click="ImageHeight(EditedTab,'IMGHeight','up',50)"
                            >50
                            <v-icon> mdi-chevron-up </v-icon>
                            </v-btn>
                            </template>              
                        </v-text-field>
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">								
                                    <v-switch label="Has Logo" v-model="EditedTab.HasLogo" />									
                                    <v-switch label="Logo  at Bottom" v-model="EditedTab.LogoIsBottom" v-if="EditedTab.HasLogo"/>
            </v-list-item>
            <v-list-item dense v-if="EditedTab.Name === 'Parallax' && EditedTab.HasLogo || EditedTab.Name === 'Image' && EditedTab.HasLogo">
                                    <v-dialog max-width="800">								
                                        <template v-slot:activator="{ on }">									
                                    <v-icon v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>									
                                            <v-tabs v-if="!UseGroupAssets">									
                                            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">									
                                                {{lib.name}}									
                                            </v-tab>									
                                            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">							
                                                <v-card width="800">									
                                                <PhotoLibraryViewer :FullGallery="true"	:TableCollectionRef="lib.TableCollectionRef" :TableName="lib.name"							
                                                @AssignImage="AssignImage" :IMGProp="'LogoSRC'" :CurrentEntity="lib" :AppisDarkMode="true"									
                                                :tab="EditedTab" />									
                                                </v-card>									
                                            </v-tab-item>									
                                        </v-tabs>				
                                        <v-card-text v-if="UseGroupAssets">
                                            <v-row  v-if="SystemAssets.length > 0">
                                                <v-col
                                                v-for="n in SystemAssets"
                                                :key="n.itemOvjKey"
                                                class="d-flex child-flex"
                                                cols="3"
                                                >
                                                <v-img
                                                    :src="n.ThumbURL"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                                    @click="AssignImage(EditedTab,'LogoSRC',n)"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>                                        
                                    </v-dialog>							
                                    <v-select v-if="EditedTab.HasLogo" v-model="EditedTab.LogoJustify" label="Logo Justify" :items="['start','end','center']"/>
            </v-list-item>
         
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            Refreshing: false,
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
        AdvancedCSSDialog(){
            return this.$store.state.AdvancedCSSDialog
        },
    },
    created(){

    },
    methods:{
        ToggleAdvancedCSSDialog(){
            this.$store.commit('setEditedTab',this.EditedTab)
            this.$store.commit('ToggleAdvancedCSSDialog',!this.AdvancedCSSDialog) 
        },
        UpdateClearBoxes(tab){
            if(!tab.ClearBoxes){
                tab.Elements.map(elmnt => {
                    elmnt.BoxTransparency = ''
                    elmnt.Elevation = 2
                })
            }
        },
        isOdd(num) {
        return num % 2;
        },
        CheckMagazineStyle(tab){
            if(tab.Type === 'Magazine Style'){
                tab.Elements.map((elmnt,elmntindex) => {
                    if(!elmnt.MagType){
                        if(!this.isOdd(elmntindex)){
                           elmnt.MagType = 'Type 1'
                        }
                        else{
                            elmnt.MagType = 'Type 2'
                        }
                    }
                })
                if(!tab.MagazineHighlightColor){
                    tab.MagazineHighlightColor = {hex: '#FF0000'}
                }
            }
        },
        ImageHeight(tab,prop,direction,int){
            if(tab[prop] === 'undefined' || isNaN(tab[prop])){
                tab[prop] = Number(50)
            }
            else{
                if(direction === 'down'){
                    tab[prop] = Number(tab[prop])-Number(int)
                }
                else{
                    tab[prop] = Number(tab[prop])+Number(int)
                }
            }
            this.Refreshing = true
            setTimeout(() => {
                this.Refreshing = false
            }, 50);
            //console.log(tab)
        },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single" && EditedTab.Name !== 'Title')
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
