
<template>
<!-- <v-card color="rgba(44,113,151,0.8"> -->
    <v-row
    class="fill-height ma-0 cloudsbgcolor"
    align="center"
    justify="center">
     <v-dialog v-model="NewLoanAccountDialog" max-width="300px">
            <v-card flat max-width="600px" min-width="300px" :style="MiniView">
                <v-card-title>Select Account Type</v-card-title>
                <v-card-text>
                   <v-text-field label="Reference" v-model="BillingAccount.Client_Reference"
                   />
                </v-card-text>
                 <v-card-actions>
                    <v-btn @click="DeactivateNewLoanAccountDialog()" dark color="warning">Cancel
                    </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="SaveNewLoanAccount()" dark color="success">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    <div class="moving-clouds" 
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <div class="moving-clouds2" 
        style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
    <v-bottom-sheet v-model="helpsheet">
        <v-sheet class="text-center" height="200px">
            <v-btn
            class="mt-6"
            dark
            color="red"
            @click="helpsheet = !helpsheet"
            >close</v-btn>
            <div>This is the My Profile help sheet. What would you want to know?</div>
        </v-sheet>
        </v-bottom-sheet>
        <v-card
            class="mx-auto"
            max-width="500"
            min-width="350"            
            >
            <v-img
            
            src="@/assets/RABaseBG-Soft.png"
            width="350">
            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="pop lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                    <v-text-field
                        label="Email"
                        v-model="UserEmail"
                    ></v-text-field>              
                    <span class="caption grey--text text--darken-1">
                        This is the email you will use to login to your {{System.Name}} account
                    </span>
                    </v-card-text>
                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                    <v-select
                        label="Title"
                        v-model="Title"
                        :rules="[rules.required]"
                        :items="['Mr','Mrs','Ms']"
                    ></v-select> 
                    <v-text-field
                        label="Name"
                        v-model="Name"
                        :rules="[rules.required]"
                    ></v-text-field>
                    <v-text-field
                        label="Surname"
                        v-model="Surname"
                        :rules="[rules.required]"
                    ></v-text-field>                   
                    <span class="caption grey--text text--darken-1">
                        This is the email you will use to login to your {{System.Name}} account
                    </span>
                    </v-card-text>
                </v-window-item>
                <!-- <v-window-item :value="3">
                    
                    <v-card-text>
                     
                     <v-img v-if="!Profile_Photo "
                     width="200"
                     height="200" 
                        src="@/assets/BlankProfilePic.png"              
                      />
                      <v-img v-if="Profile_Photo "
                     width="200" 
                     height="200" 
                        :src="Profile_Photo"              
                      />
                      <input type="file"  accept="image/*" @change="SelectProfilePic($event)"/>
                    </v-card-text>
                </v-window-item> -->

                <v-window-item :value="3">
                    <v-card-text>
                    <v-text-field
                        label="Password"
                        type="password"
                        v-model="password"
                    ></v-text-field>
                    <v-text-field
                        label="Confirm Password"
                        type="password"
                        v-model="rePassword"
                        :rules="[rules.required, rules.min8Chars, passwordConfirmationRule]"
                    ></v-text-field>
                    <span class="caption grey--text text--darken-1">
                        Please enter a password for your account
                    </span>
                    </v-card-text>                    
                     <v-card-text v-if="InviteData.BillingAccount">
                        <FieldsForm :FormFields="AccountFields" :Validating="Validating" :DataTableView="false" @save="ProgressBAWindow" :DataObject="BillingAccount" :tab="{}" />
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="4">
                    <div class="pa-4 text-center">
                    <v-img
                        class="mb-4"
                        contain
                        height="128"
                        src="@/assets/logo.png"
                    ></v-img>
                    <h3 class="title font-weight-light mb-2">
                        Welcome to {{System.Name}}
                    </h3>
                    <span class="caption grey--text">Thanks for signing up!</span><br>
                    <span class="caption grey--text">An Email will be sent shortly, to confirm the account creation process. You may close the page</span>
                    </div>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                         
                <v-btn
                    :disabled="step === 1 || step === 4"
                    text
                    @click="step--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    :disabled="step === 4 || step === 3 && InviteData.BillingAccount"
                    color="pop"
                    depressed
                    @click="ProgressWindow()"
                >
                    {{StepProgressTitle}}
                </v-btn>
                </v-card-actions>
            </v-img>
            </v-card>      
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import FieldsForm from '@/components/Database/FieldsForm';	
export default {
    components: {FieldsForm},
    props: ['System'],
    data() {
        return {
            NewLoanAccountDialog: false,
          AccountTypesDials: [
            {ID: 1000001, Name: 'Store Account',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000002, Name: 'Group Account',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000003, Name: 'Suite Ownership',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000004, Name: 'App Membership',DialogMethod: 'ActivateGroupAccountDialog'},
            {ID: 1000005, Name: 'Cash Loan Account',DialogMethod: 'ActivateLoanOrderAccountDialog'},
            {ID: 1000006, Name: 'Market Account',DialogMethod: 'ActivateGroupAccountDialog'},
        ],
          AccountTypes: [
            {ID: 1000001, Name: 'Store Account'},
            {ID: 1000002, Name: 'Group Account'},
            {ID: 1000003, Name: 'Suite Ownership'},
            {ID: 1000004, Name: 'App Membership'},
            {ID: 1000005, Name: 'Cash Loan Account'},
            {ID: 1000006, Name: 'Market Account'},
        ],
            InviteData: '',
            IsGuestUser: true,
            UserEmail: '',
            UserPic: '',
            Profile_Photo: '',
            Title: '',
            Name: '',
            Surname: '',
            password: '',
            rePassword: '',
            BillingAccount: {},
            AccountFields: [
          {Name: 'Account_Name',DisplayName: 'Account Name',Type: 'Single Line Text', IsMandatory: true},
          {Name: 'Account_Email',DisplayName: 'Account Email',Type: 'Common Field',CommonFieldType: 'Email', IsMandatory: true},
          {Name: 'Account_Phone',DisplayName: 'Account Phone',Type: 'Common Field',CommonFieldType: 'Telephone Number', IsMandatory: true},
          {Name: 'Account_Website',DisplayName: 'Account Website (Optional)',Type: 'Common Field',CommonFieldType: 'URL Link'},
        ],
            Validating: false,
            step: 1,
            Step1Validating: false,
            Step2Validating: false,
            Step3Validating: false,
            Step4Validating: false,
            Step5Validating: false,
            rules: {
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        }
    },

    computed:{
        DefaultStatusField(){
            return this.System.DefaultStatusField ? 
            this.System.DefaultStatusField : 
            this.$store.state.DefaultStatusField
        },
        AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : {id: 'Head_Office',Name: 'Head Office'}
        },
        Full_Name(){
            return this.Name+' '+this.Surname
        },
        StepProgressTitle(){
            return this.step === 4 ? 'Create Profile' : 'Next'
        },
        passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },

        currentTitle () {
        switch (this.step) {
          case 1: return 'Confirm Email'
          case 2: return 'Basic Profile'
          case 3: return 'Create Password'
          default: return 'Account processed'
        }
      },
    },

    created(){
        this.GetUserInvite()
    },
    
    methods: {
        SaveNewLoanAccount(){
            this.BillingAccount.Account_Name = this.BillingAccount.Client_Reference
            this.BillingAccount.Billing_Type = {ID: 1000002, Name: 'Credit'}
            let Status = this.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
            let Status_Reason = Status.Options.find(obj => obj.Name === 'Open')
            this.BillingAccount.Status = Status
            this.BillingAccount.Status_Reason = Status_Reason
            // this.UpdateStep()
            // db.collection('userinvites').doc(this.$route.params.id).update({
            //     Status: {ID: 1000003,Name: 'Completed'}
            // }) 
            this.CreateUserProfile()
        },
        DeactivateNewLoanAccountDialog(){
            this.NewLoanAccountDialog = false
            this.$store.commit('setCustomProcessingDialog',false)
            this.BillingAccount = {}
            this.NewUserObj = {}
        },
        UpdateStep(){
            console.log('supposed to update step')
            //OKAY THIS WAS TERRIBLE!!! REMOVED PUBLIC ACCESS TO EDIT USERINVITE WHICH WAS SUPER STUPID!!!
            //UPDATE OF INVITE OCCURS SAFELY IN CALL METHOD
            // db.collection('userinvites').doc(this.$route.params.id).update({
            //         step: this.step,
            // })
        },
        ProgressBAWindow(){
            let length = this.AccountFields.length
            this.AccountFields.map((field,fieldindex) => {
                if(typeof field.Response !== 'undefined'){
                this.BillingAccount[field.Name] = field.Response
                }
                if(fieldindex-1+2 === length){
                this.step++
                this.Validating = false
                //console.log(this.BillingAccount)
                }
            })
            this.ProgressWindow()
        },
        ProgressWindow(){
            // if(!this.InviteData.BillingAccount || this.step !== 3){
            this.step++
            console.log(this.step)
            if(this.step === 2){
                // this.UpdateStep()
                // db.collection('userinvites').doc(this.$route.params.id).update({
                //     Status: {ID: 1000002,Name: 'Processing'}
                // })                
            }
            else if(this.step === 3){
                // this.UpdateStep()       
                // db.collection('userinvites').doc(this.$route.params.id).update({
                //     Name: this.Name,
                //     Surname: this.Surname,
                //     Title: this.Title,
                //     Full_Name: this.Full_Name
                // })       
            }
            // else if(this.step === 4){
            //     this.UpdateStep()
                              
            // }
            else if(this.step === 4){
                if(this.InviteData.MemberGroup && this.InviteData.MemberGroup.DefaultBAType){
                    let accttype = this.AccountTypes.find(obj => obj.Name === this.InviteData.MemberGroup.DefaultBAType)
                    this.BillingAccount = {
                        Account_Type: accttype,
                    }
                    let type = this.AccountTypesDials.find(obj => obj.Name === accttype.Name)
                    this[type.DialogMethod]()
                }
                else{
                // this.UpdateStep()
                // db.collection('userinvites').doc(this.$route.params.id).update({
                //     Status: {ID: 1000003,Name: 'Completed'}
                // })                
                 this.CreateUserProfile()    
                }                            
            }
            // }
        },
        ActivateLoanOrderAccountDialog(){
            this.BillingAccount.Client_Reference = this.Name+"'s Loan Account"
            this.BillingAccount.Account_Type = this.AccountTypes.find(obj => obj.Name === 'Cash Loan Account')
            this.NewLoanAccountDialog = true
        },
        UploadPic(){
            if(this.UserPic){
                var storageRef = firebase.storage().ref('User/profilephoto/'+this.$route.params.id);        
                var uploadTask = storageRef.put(this.UserPic);
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                      db.collection('userinvites').doc(this.$route.params.id).update({
                        Profile_Photo: url,
                        })
                  })            
            }
            else{
                return 'No Link'
            }
        },
        
        CreateUserProfile(){
            let vm = this
            //this.$store.dispatch('ClientSignupbyInvite', user)
            //db.collection('userinvites').doc(this.$route.params.id).update({
                    //Status: {
                        //ID: 1000004,
                        //Name: 'Completed'
                    //},
            //})
            //this.UploadPic()
            const functions = firebase.functions();
            const CreateUserviaInvite = functions.httpsCallable('CreateUserviaInvite');
            let NewUser = {
                Business_Unit: this.UserBU,
                Business_Unitid: this.UserBU.id,
                Name: this.Name,
                Surname: this.Surname,
                //was UserEmail but now we do not allow changing emial, if email to eb changed, change after user create
                Email: this.InviteData.Email,
                Title: this.Title,
                InviteID: this.$route.params.id,
                password: this.password,
                Full_Name: this.Full_Name,
                IsGuestUser: this.IsGuestUser,
                Invited_By: this.InviteData.Created_By,
                Invited_Byid: this.InviteData.Created_Byid
                }
                if(this.BillingAccount && this.BillingAccount.Billing_Type){
                    NewUser.BillingAccount = this.BillingAccount
                }
                if(this.InviteData.RelatedObj){
                    NewUser.RelatedObj = this.InviteData.RelatedObj
                }
                if(this.InviteData.RelatedField){
                    NewUser.RelatedField = this.InviteData.RelatedField
                }
                if(this.InviteData.MemberGroup){
                    NewUser.MemberGroup = this.InviteData.MemberGroup
                }
                let today = new Date()
                NewUser.SignupDateNumber = today.getTime()
                if(this.InviteData.Contact){
                    NewUser.Contact = this.InviteData.Contact
                    NewUser.Contactid = this.InviteData.Contactid
                }
                if(this.InviteData.UserTableFilters){
                    NewUser.UserTableFilters = this.InviteData.UserTableFilters
                }
                if(this.InviteData.Region){
                    NewUser.Region = this.InviteData.Region
                    NewUser.Regionid = this.InviteData.Regionid
                }
                if(this.InviteData.Company){
                    NewUser.Company = this.InviteData.Company
                    NewUser.Companyid = this.InviteData.Companyid
                }
                if(this.InviteData.CanImport){
                    NewUser.CanImport = this.InviteData.CanImport
                }
                if(this.InviteData.CanExport){
                    NewUser.CanExport = this.InviteData.CanExport
                }
                if(this.InviteData.rolesarrayDBRules && this.InviteData.rolesarrayQuery){
                    NewUser.rolesarrayDBRules = this.InviteData.rolesarrayDBRules
                    NewUser.rolesarrayQuery = this.InviteData.rolesarrayQuery
                }
                if(this.InviteData.CanInviteUsers){
                    NewUser.CanInviteUsers = this.InviteData.CanInviteUsers
                }
               const signinpayload = {email: NewUser.Email,password: vm.password} 
            CreateUserviaInvite(NewUser).then(result => {
                console.log('created user supposedly')
                console.log(result);
                // if(NewUser.IsGuestUser){
                //     this.$router.push('/MyAccount')
                // }
                
                
                vm.$store.dispatch('loginWithFirebase',signinpayload)
                vm.$router.push('/')
                }).catch(error => {
                console.log("error",error);
                });
            
        },
        SelectProfilePic(){
            this.UserPic = event.target.files[0]
            this.Profile_Photo = URL.createObjectURL(this.UserPic)
        },
        GetUserInvite(){
            db.collection('userinvites').doc(this.$route.params.id).onSnapshot(snapshot => {
                let invitedata = snapshot.data()
                this.InviteData = invitedata
                console.log(invitedata)
                if(typeof invitedata === 'undefined'){
                    this.$router.push('/Login')
                }
                if(this.InviteData.BillingAccount){
                    this.BillingAccount = this.InviteData.BillingAccount
                }
                this.IsGuestUser = invitedata.IsGuestUser
                if(this.invitedata && this.invitedata.Status && this.invitedata.Status.ID === 1000003){
                    console.log('is complete')
                    this.$router.push('/Login')
                }
                this.UserBU = invitedata.Business_Unit
                this.UserEmail = invitedata.Email
                if(invitedata.Profile_Photo){
                    this.Profile_Photo = invitedata.Profile_Photo
                }
                if(invitedata.step){
                    this.step = invitedata.step
                }
                if(invitedata.Name){
                    this.Name = invitedata.Name,
                    this.Surname = invitedata.Surname,
                    this.Title = invitedata.Title
                }
            },
            error => {
            if(error.code === 'permission-denied'){
                if(!this.userLoggedIn){
                this.$router.push('/404')  
                }
                else{
                this.$router.push('/PermissionError')
                }            
            }
            })
        },
    }
}
</script>

<style>
.moving-clouds {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.moving-clouds2 {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c2 80s linear infinite;


}
@keyframes c2 {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0;
  }
  
}
.cardimage {
  opacity: 0.5;
}
.cloudsbgcolor {
    background-color: rgba(28,138,240,1);
}
</style>

    