<template>
  <div>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/RAFullLogo.png"/>
        </v-avatar>
      Suite Security Settings
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard" v-for="mod in ModulePlugins" :key="mod.itemObjKey">
        <v-card-subtitle class="mediumoverline">
          {{mod.Name}}
      </v-card-subtitle>
      <v-card-text >
          <v-row class="justify-center">
          <div v-for="secmod in mod.SecurityModules" :key="secmod.itemObjKey" style="padding: 20px;" class="flex xl3 lg3 md4 sm6 xs12">
              <v-card height="250">
                  <v-card-title class="overline">                     
                        {{secmod.Name}}
                        <v-spacer>
                        </v-spacer>
                         <v-icon>{{secmod.Tab.DataContent.ContentIcon}}
                      </v-icon>
                  </v-card-title>
                  <v-card-text style="text-align: center;" class="caption" v-html="secmod.Tab.ContentDescription">
                  </v-card-text>
                  <v-card-actions class="justify-end">
                      <v-btn small v-if="mod.Active" color="purple" outlined style="position: absolute; bottom: 15px;" @click="GotoRoute('/'+secmod.Tab.DataContent.ContentLinkName)" dark>
                          Go
                      </v-btn>
                      <v-btn small v-if="!mod.Active" outlined style="position: absolute; bottom: 15px;" color="grey">
                          (Inactive)
                      </v-btn>
                  </v-card-actions>
              </v-card>
          </div>
          </v-row>
      </v-card-text>
      </v-card>
      <!-- <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Users
      </v-card-subtitle>
      <v-card-text >
         Prettified link to section based route
      </v-card-text>
      </v-card>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Security Roles
      </v-card-subtitle>
      <v-card-text >
         Prettified link to section based route
      </v-card-text>
      </v-card> -->
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import CardLinkTab from '@/components/WebPages/RenderComponents/CardLinkTab';

export default {
    props: ['System','SystemEntities'],
    components: {InfoSheet,CardLinkTab},
    data() {
        return {
            SecurityModules: [
                {
                Name: 'Business Units',
                Module: 'Database Builder',
                DataContent: {
                    ContentLinkName: 'BusinessUnits',
                    ContentIcon: 'mdi-security-network',
                    ContentDescription: `Configure the Business Structure in order to assign Users to particular business Units, and set security roles form there`
                }
                },
                {
                Name: 'Users',
                Module: 'Database Builder',                
                DataContent: {
                    ContentLinkName: 'Users',
                    ContentIcon: 'mdi-tag-faces',
                    ContentDescription: `Configure the Users and their various access permissions`
                }
                },
                {
                Name: 'Security Roles',
                Module: 'Database Builder',
                DataContent: {
                    ContentLinkName: 'SecurityOverview',
                    ContentIcon: 'mdi-server-security',
                    ContentDescription: `Create and Manage Security Roles, and their relationship tot eh various data tables in your database.`
                }
                },

            ],
            DefaultCardLinkTab: {
            
            Open: true,
            Transparent: true,
            Alignment: 'center',
            Justify: 'justify-center',
            RowTransparency: 'transparent',
            Name: 'Card Link',
            Type: 'Card Link',
            DisplayName: 'Card Link',
            HasImage: true,
            DataContent: {
                Name: 'Name of Data Link',
                dataseticon: 'mdi-eye',
                ContentLinkName: 'Somehwere',
                ContentLinkType: 'Build',
                ContentIcon: 'mdi-eye'
            },
            HasAlignment: true,
            Elements: [],
            RowColor: '#00ffff',
            ShowComponents: true,
            Icon: 'mdi-link-box',
            Title: 'About',
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,
            MarginTop: 0,
            MarginBottom: 0,
            MarginLeft: 0,
            MarginRight: 0,
            BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },
            FontColor :{
                    hex: '#000000'
            },
            HeaderColor: {
                hex: '#000000'
            },
            FlexXLRG:9,
            FlexLarge: 9,
            FlexMedium: 11,
            FlexSmall: 11,
            FlexXSmall: 10,
            Cols: 12,
            },	
            SystemTickets: [],
            RoadmapItems: [],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
        }
    },	
    computed:{
        ComputedSecurityModules(){
            return this.SecurityModules.map(secmod => {
                let secmodobj = Object.assign({},secmod)
                secmodobj.Tab = Object.assign({},this.DefaultCardLinkTab)
                secmodobj.Tab.DataContent = secmodobj.DataContent
                secmodobj.Tab.ContentDescription = secmodobj.DataContent.ContentDescription
                console.log(secmodobj)
                return secmodobj
            })
        },
        PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        ModulePlugins(){
          return this.RAPlugins.filter(plug => {
              return plug.Module_Category && plug.Module_Category.Name === 'Module'
          }).map(mod => {
              let modobj = Object.assign({},mod)
              modobj.SecurityModules = this.ComputedSecurityModules.filter(secmod => {
                  return secmod.Module === modobj.Name
              })
              modobj.Active = this.PluginDataBase[modobj.Name.split(' ').join('_')] && this.PluginDataBase[modobj.Name.split(' ').join('_')].Active
              return modobj
          })
      },
        ActivePlugins(){
          return this.RAPlugins.filter(plug => {
              return this.PluginDataBase[plug.id] && this.PluginDataBase[plug.id].Active
          })
      },
        RAPlugins(){
          return this.$store.state.RAPlugins
        },
         ActiveDB(){
            return db
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
    },
    methods:{
        GotoRoute(route){
            if(this.$route.path !== route){
                this.$router.push(route)
            }
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



