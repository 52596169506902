
    <template>
   <v-layout row class="justify-center">
  <v-card flat max-width="600px" min-width="400px" :style="Smallscreen" v-if="!System.Billing_Account_on_Signup">
     <v-form style="padding:20px;">
    <v-card-title class="title">New User Registration</v-card-title>

            <v-card-text >
              <v-text-field @keydown.space.prevent @input="removeWhiteSpace(email)" v-model.trim="email" :rules="[rules.email]" placeholder="Email"></v-text-field>
                    <v-text-field
                        placeholder="Password"
                        type="password"
                        :rules="[rules.required, rules.min8Chars, rules.passwordValidates]"
                        v-model.trim="password"
                    ></v-text-field>
                    <v-text-field
                        placeholder="Confirm Password"
                        type="password"
                        v-model.trim="rePassword"
                        :rules="[rules.required, rules.min8Chars, passwordConfirmationRule]"
                    ></v-text-field>
                    </v-card-text>
            <v-card-actions style="padding-right:30px;">
              <v-spacer></v-spacer>         
              <v-btn class="pop" dark @click="signUp()">Sign Up</v-btn>
            </v-card-actions>
            <v-card-text>
            <v-layout class="justify-center">
              <span class="overline">OR</span>                      
            </v-layout>
            <v-divider></v-divider>
             <v-layout class="justify-center">
              <v-btn width="100%" dark class="accent" @click="$router.push('PhoneLogin')">Use Phone <v-icon>mdi-phone</v-icon></v-btn>                     
            </v-layout>
            <v-layout class="justify-center">
              <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
            </v-layout>                  
            </v-card-text> 
     </v-form>
  </v-card>
  <v-card flat max-width="600px" min-width="400px" :style="Smallscreen" v-if="System.Billing_Account_on_Signup">
     <v-form style="padding:20px;">
    <v-card-title class="title">New Account Registration</v-card-title>
          <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title class="text-h6 font-weight-regular justify-space-between">
              <span>{{ currentTitle }}</span>
              <v-avatar
                color="primary lighten-2"
                class="subheading white--text"
                size="24"
                v-text="step"
              ></v-avatar>
            </v-card-title>

            <v-window v-model="step">
              <v-window-item :value="1">
                <v-card-text>
                   <FieldsForm :FormFields="AccountFields" :Validating="Validating" :DataTableView="false" @save="ProgressWindow" :DataObject="BillingAccount" :tab="{}" />
                </v-card-text>
              </v-window-item>

              <v-window-item :value="2">
                <v-card-text >
                  <v-select dense
                        placeholder="Title"
                        v-model="Title"
                        :rules="[rules.required]"
                        :items="['Mr','Mrs','Ms']"
                    ></v-select> 
                  <v-text-field dense placeholder="Name"  :rules="[rules.required]" v-model="Name"></v-text-field>
                  <v-text-field dense placeholder="Surname"  :rules="[rules.required]" v-model="Surname"></v-text-field>
              <v-text-field @keydown.space.prevent @input="removeWhiteSpace(email)" v-model.trim="email" :rules="[rules.email]" placeholder="Email"></v-text-field>
                    <v-text-field dense
                        placeholder="Password"
                        type="password"
                        v-model="password"
                        :rules="[rules.required, rules.min8Chars]"
                    ></v-text-field>
                    <v-text-field dense
                        placeholder="Confirm Password"
                        type="password"
                        v-model="rePassword"
                        :rules="[rules.required, passwordConfirmationRule]"
                    ></v-text-field>
                    </v-card-text>
              </v-window-item>

              <v-window-item :value="3">
                <div class="pa-4 text-center">
                  <v-img
                    class="mb-4"
                    contain
                    height="128"
                    src="@/assets/logo.png"
                  ></v-img>
                  <h3 class="text-h6 font-weight-light mb-2">
                    Welcome to {{System.Name}}
                  </h3>
                  <span class="text-caption grey--text">Thanks for signing up!</span>
                  <v-btn width="100%" dark class="success" @click="$router.push('Login')">Log in now<v-icon>mdi-lock-open</v-icon></v-btn>
                </div>
              </v-window-item>
            </v-window>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                :disabled="step === 1"
                text
                @click="step--"
              >
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="step === 3"
                color="primary"
                depressed
                @click="ValidateForm()"
              >
                Next
              </v-btn>
            </v-card-actions>
             <v-divider></v-divider>
             <v-card-actions>
             <v-layout class="justify-center">
              <v-btn width="100%" dark class="accent" @click="$router.push('PhoneLogin')">Use Phone <v-icon>mdi-phone</v-icon></v-btn>                     
            </v-layout>
            <v-layout class="justify-center">
              <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
            </v-layout>  
             </v-card-actions>
          </v-card>
     </v-form>
  </v-card>
   </v-layout>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import FieldsForm from '@/components/Database/FieldsForm';	
  export default {
    props: ['System'],
    components: {FieldsForm},
    name: 'signUp',
    data() {
      return {
        AccountFields: [
          {Name: 'Account_Name',DisplayName: 'Account Name',Type: 'Single Line Text', IsMandatory: true},
          {Name: 'Account_Email',DisplayName: 'Account Email',Type: 'Common Field',CommonFieldType: 'Email', IsMandatory: true},
          {Name: 'Account_Phone',DisplayName: 'Account Phone',Type: 'Common Field',CommonFieldType: 'Telephone Number', IsMandatory: true},
          {Name: 'Account_Website',DisplayName: 'Account Website (Optional)',Type: 'Common Field',CommonFieldType: 'URL Link'},
        ],
        BillingAccount: {},
        Validating: false,
        step: 1,
        Step1Validating: false,
        Step2Validating: false,
        Step3Validating: false,
        Step4Validating: false,
        Step5Validating: false,
        rules: {
          
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        email: '',
        password: '',
        rePassword: '',
        Title: '',

        Name: '',
        Surname: '',
        BusinessUnit: null,
        EmployeeCode: '',
        Position: '',
        TitleArray: [
          {index: 0, Name: 'Mr'},
          {index: 1, Name: 'Mrs'},
          {index: 2, Name: 'Ms'},

        ],
        GenderArray: [
          {index: 0, Name: 'Male'},
          {index: 1, Name: 'Female'},

        ],
        Gender: null
      }
    },

    computed: {
      Smallscreen(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs? 'padding-bottom:50px;margin-top:-100px;' : 'padding-bottom:50px;'
      },
      currentTitle () {
        switch (this.step) {
          case 1: return 'Register Account'
          case 2: return 'Create a password'
          default: return 'Successfully Registered'
        }
      },

      passwordConfirmationRule() {
            return () => (this.password === this.rePassword) || 'Password must match'
            },
      UserFullName(){
      return this.Name+' '+this.Surname
    },
      userLoggedIn () {
      return this.$store.getters.user
    }
    },

    watch: {
      userLoggedIn (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/')
        }
      }
    },

    created() {

    },

    methods: {

      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },

      ValidateForm(){
        if(this.step === 1){
          this.Validating = false
          setTimeout(() => {
            this.Validating = true
          }, 50);
        }
        else{
          this.ProcessSignup()
        }
      },
      ProgressWindow(){       
        
        if(this.step === 1){
          let length = this.AccountFields.length
          this.AccountFields.map((field,fieldindex) => {
            if(typeof field.Response !== 'undefined'){
              this.BillingAccount[field.Name] = field.Response
            }
            if(fieldindex-1+2 === length){
              this.step++
              this.Validating = false
              console.log(this.BillingAccount)
            }
          })
        }
        else if(this.step === 2){
          this.ProcessSignup()
        }
      },
      ProcessSignup(){
        let vm = this
        if(this.System.Default_Billing_Account_Type){
          this.BillingAccount.Account_Type = this.System.Default_Billing_Account_Type
        }
        else{
          this.BillingAccount.Account_Type = {
            ID: 1000001,
            Name: 'Store Account'
          }
        }
        this.$emit('ActivateProcessing',true)
        const user = {
        Email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.Name,
        Surname: this.Surname,
        Full_Name: this.Name+' '+this.Surname,
        Gender: this.Gender,
        IsGuestUser: true,
        BillingAccount: this.BillingAccount
      }
        console.log(user)
      this.$store.dispatch('signUpAction', user).then(response => {
        console.log(response)
        if(response){
          vm.$emit('ActivateProcessing',false)
          vm.step = 3
        }
      })
      },
      SignupwithFacebook(){
        let FBprovider = new firebase.auth.FacebookAuthProvider();
        let vm = this
        firebase
        .auth()
          .signInWithPopup(FBprovider)
            .then((result) => {
              var credential = result.credential;
              var user = result.user;
              console.log(user)
            })
      },

     signUp() {
      const user = {
        email: this.email,
        password: this.password,
        Title: this.Title,
        Name: this.Name,
        Surname: this.Surname,
        Gender: this.Gender,
        IsGuestUser: true,
      }

      this.$store.dispatch('signUpAction', user)
      // this.$store.dispatch('createUserDoc', userdoc)

    }

    }
  }

</script>


 <style scoped>
  .sign-up {
    margin-top: 40px;
  }
  input {
    margin: 10px 0;
    width: 20%;
    padding: 15px;
  }
  button {
    margin-top: 10px;
    width: 10%;
    cursor: pointer;
  }
  span {
    display: block;
    margin-top: 20px;
    font-size: 11px;
  }
</style>

  


  

  