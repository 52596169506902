<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
          <v-list>			
                              
                              <v-list-item>									
                                   <v-switch v-model="EditedTab.HideRoles" label="Hide Roles"/>									
                              </v-list-item>	
                              <v-list-item>
                                 <v-select v-model="EditedTab.TestimonialsType" label="Type" @change="CheckChangedValue(EditedTab)"
                :items="['Default','Flip Cards','STD Cards','Hover Cards']"
                />
                              </v-list-item>
                               <v-list-item>									
                                   <v-switch v-model="EditedTab.HideIMG" label="Hide IMG"/>									
                              </v-list-item>						
                              <v-btn @click="Onepropemit('AddTestimony',EditedTab)">Add Testimony</v-btn>									
                              <v-list-item v-for="(tes,index) in EditedTab.Elements" :key="tes.itemObjKey">									
                                  <v-list-item-content>									
                                      {{tes.Name}}									
                                  </v-list-item-content>									
                                  <v-list-item-content>									
                                      <v-icon @click="Twopropemit('EditTestimony',tes,index)">mdi-cogs</v-icon>									
                                  </v-list-item-content>	
                                  <v-list-item-content>									
                                      <v-icon @click="ConfirmDelete(EditedTab,index)">mdi-delete-forever</v-icon>									
                                  </v-list-item-content>									
                              </v-list-item>	
                               <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y v-if="EditedTab.TestimonialsType === 'Hover Cards'"
                                    min-width="200px"
                                    >
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on">mdi-palette-advanced</v-icon>
                                    
                                    </template>
                                    <v-color-picker v-model="EditedTab.HoverColor"></v-color-picker>
                                    </v-menu>	Hover Color							
                          </v-list>			
         
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        ConfirmDelete(tab,index){
            confirm('Are you sure you want to delte this testimonial?') && this.ProcessDelete(tab,index)
        },
        ProcessDelete(tab,index){
            tab.Elements.splice(index,1)

        },
        CheckChangedValue(tab){
        if(tab.TestimonialsType === 'Flip Cards' && typeof tab.FlipCard === 'undefined'){
          
          //tab.Elevation = 2
          tab.FlipCard = true
          let props = ['IMGonTop','TiledIMG','FullIMG','IMGHeight','FlipCard','LabelGradient','LabelBGColor','ContainIMG']
          tab.FullIMG = true
          tab.TiledIMG = true
          tab.IMGHeight = 150
          tab.ContainIMG = true
        }
        else if(tab.TestimonialsType === 'STD Cards' && typeof tab.SubHeaderColor === 'undefined'){
            tab.SubHeaderFontSize = 18                  
            tab.SubHeaderColor = {                 
                hex: '#FF0000FF'                  
            } 
        }
        else if(tab.TestimonialsType === 'Hover Cards' && !tab.HoverColor){
            tab.HoverColor = {hexa: '#FF0000FF'}
        }
        //console.log(tab)
      },
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
