
  <template>
<v-card class="transparent" width="100%" height="100%" style="padding-bottom:60px;">
<!-- <v-layout column class="createnotice-container" v-if="userisContentAdmin">
    <v-icon @click="AddSocialItemtoNotice()" class="soloactionicon white" x-large color="warning">mdi-clipboard-alert</v-icon>
  </v-layout> -->
<SocialSharing :ShareItemDialog="ShareItemDialog" @CancelShareItemDialog="CancelShareItemDialog" :LinkID="LinkID" :FriendsList="FriendsList"
  :InternalLinkType="InternalLinkType" :LinkComponent="LinkComponent" :UserRecord="UserRecord" :collectionname="SharedCollection" :SiteModerators="SiteModerators"/>
   <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{System.Name}}</h1>
                <h4 class="headline">Poll - {{forum.PollName}}</h4>
              </v-col>
            </v-row>
            </v-parallax>  
            <v-toolbar flat color="white--text" v-if="UserisOwner"  src='@/assets/RASectionBG.jpeg'>
    <v-toolbar-title>Votes Overview</v-toolbar-title>
        <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisOwner"
                                 v-if="UserRecord.id"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip small color="pop" class="white--text" v-on="on" v-if="UserRecord.id">
                              {{PublishStatus}}
                            </v-chip>
                        </template>
                        <v-list class="transparent" dark>
                          <div  v-for="status in PublishStatusOptions" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisOwner"
                                 v-if="UserRecord.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateStatus(status.Name)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>
      <v-spacer></v-spacer>
        <v-icon  @click="VotesOverviewMode = !VotesOverviewMode" dark>mdi-vote</v-icon>
        <v-icon  @click="PromptDeletePoll()" dark>mdi-delete</v-icon>
    </v-toolbar>
    <v-card v-if="VotesOverviewMode" flat>
      <SitePollsChart :ChartDatalength="PollVotes.length" @Viewtable="Viewtable"  :selectedLabelIdentifier="selectedLabelIdentifier" :selectedLabel="selectedLabel" @ActivateActiveChart="ActivateActiveChart" @ChartDataSelect="ChartDataSelect" :Labels="Labels" :ChartData="ChartData" />
      <v-data-table
      :headers="headers"
      :items="ComputedPollVotes"
      class="elevation-1"
      
      id="groupvotesdatatable"
    >
      <template v-slot:top>
        <v-toolbar src="@/assets/RASectionBG.jpeg" flat color="recordtoolbar white--text">
          <v-toolbar-title>{{forum.PollName}} Votes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            append-icon="mdi-file-find-outline"
            label="Search"
            single-line
            hide-details
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
      
  <v-toolbar v-if="PollVotes" flat dense dark color="recordtoolbar">
          <v-layout col class="justify-start">
            <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
            <v-icon @click="ResetFilters()">mdi-close</v-icon>
            </v-layout>
          </v-toolbar>
      </template>
        <template v-slot:[`item.Voter`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.voterimg" v-if="item && item.voterimg"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item || !item.voterimg"/>
        </v-avatar>
        <span v-if="item.Voter">{{' '+item.Voter.Full_Name}}</span>
    </template>
    <template v-slot:[`item.createdon`]="{ item }">
        <span  v-if="item.createdon.toDate">
        {{item.createdon.toDate()}}</span>
        <span v-if="typeof item.createdon.toDate === 'undefined'">{{item.createdon}}</span>
    </template>
    <template v-slot:[`item.option`]="{ item }">
        <span>{{item.option.Name}}</span>
    </template>
      <template v-slot:[`item.action`]="{ item }">
      <v-layout class="justify-center">
        <v-list dense  class="transparent" v-if="item">
          <v-list-item>
            <v-list-item-content>
              <v-icon
                small v-if="item.CanEdit"
                class="mr-2"
                @click="editItem(item)"
                color="orange"
              >
                mdi-pencil
              </v-icon>
            </v-list-item-content>          
            <v-list-item-content>
              <v-icon
                small v-if="item.CanView"
                @click="ViewItem(item)"
                color="blue"
              >
                mdi-eye
              </v-icon>
              </v-list-item-content>
            <v-list-item-content>
              <v-icon
                small v-if="item.CanDelete"
                @click="deleteItem(item)"
              >
                mdi-trash-can-outline
                color="red"
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        </v-layout>
      </template>
      <template v-slot:no-data>
        <v-btn color="recordtoolbar" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
    </v-card>
            <v-card flat class="transparent" height="60">
                <v-btn color="accent" to='/Polls'>Back to Polls</v-btn>
                <v-btn @click="ShareBlog()">Share<v-icon>mdi-share-circle</v-icon></v-btn>
            </v-card>
<v-layout class="justify-center">
    <v-flex lg8 md10>
<v-card flat class="white mx-3" width="100%" v-if="forum">
    <v-card-title class="headline justify-space-between">
        <v-list three-line class="transparent">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title><h2>{{forum.PollName}}</h2></v-list-item-title>
            <v-list-item-subtitle v-if="forum.PollEndDate.toDate">{{forum.PollEndDate.toDate()}}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="typeof forum.PollEndDate.toDate === 'undefined'">{{forum.PollEndDate}}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        </v-list>
          <v-avatar
            size="100"
          >
          <v-img  v-if="forum.creatorimg" :src="forum.creatorimg">
          </v-img>
          <v-img v-if="!forum.creatorimg" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-layout class="justify-center">
    <v-card flat tile width="100%" >
      <v-card-text v-html="forum.PollDescription">
          
      </v-card-text>
     
    </v-card>
        </v-layout>
    <v-card flat class="transparent" height="60">
            </v-card>
           
    <v-layout row dense class="justify-center">
        <v-col
            v-for="option in PollOptions" :key="option.itemObjKey"
        >
                    <v-card flat width="80%" class="my-3" :dark="PollDark" :color="PollColor.hex">
                         
                        <v-card flat width="100%" class="white" tile>
                          <v-layout class="justify-center">
                        <v-img :src="option.ImageURL" max-width="350" height="150" contain v-if="option.ImageURL">
                        </v-img>
                          </v-layout>
                        </v-card>
                        
                        <v-card-title v-if="!option.Editing">
                            {{option.Name}}
                        </v-card-title>
                        <v-card-title  v-if="option.Editing">
                            <v-text-field label="Name" v-model="option.Name"></v-text-field><v-icon @click="option.Editing =! option.Editing">mdi-content-save</v-icon>
                        </v-card-title>
                        <v-card-text>
                            <span v-if="!option.Editing">{{option.Description}}</span>
                            <v-textarea v-if="option.Editing" label="Description" v-model="option.Description"></v-textarea>
                        </v-card-text>
                        
                        <v-card-actions>
                            <v-spacer></v-spacer><v-btn :disabled="Hasvoted" class="success" @click="ConfirmUserVote(option)">Give My Vote</v-btn>
                        </v-card-actions>
                    </v-card>
        </v-col>
                </v-layout>
    <v-card-actions v-if="Hasvoted">
          You have already voted for {{Hasvoted.option.Name}} on {{Hasvoted.createdon.toDate()}}
      </v-card-actions>
</v-card>
    </v-flex>
</v-layout>
</v-card>
</template>

<script>

import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import SitePollsChart from '@/components/SuitePlugins/Polls/SitePollsChart'
import SocialSharing from '@/components/SocialNetwork/SocialSharing'

export default {
    props: ['FriendsList','System'],
    components: {
        SitePollsChart,
        SocialSharing
        },
    data() {
        return {
          Category: '',
          ItemCategory: '',
          PublishStatusOptions: [
            {
              Name: 'Draft',
              Class: 'green',
              Warning: 'Unpublish the item?'
            },
            {
              Name: 'Published (Internal)',
              Class: 'warning',
              Warning: 'Make the item avialable for viewing by all Users?'
            },
            // {
            //   Name: 'Published (Public)',
            //   Class: 'error',
            //   Warning: 'Make the item available for viewing by the Public?'
            // }
          ],
          PublishStatus: '',
          PollDocID: '',
          PollVotesQuery: '',
          PollVotesCol: '', 
          ShareItemDialog: false,
          InternalLinkType: '',
          SharedCollection: '',
          LinkComponent: '',
          LinkID: '',
          NoticeCoverIMGProp: 'image',
          chartview: false,
          Labels: [],
          ChartData: [],
          selectedData: {},
          selectedLabel: {},
          selectedLabelIdentifier: '',
          activeChart: '',
          VotesOverviewMode: false,
          headers: [
              { text: 'Voter', value: 'Voter'},
              { text: 'Voted On', value: 'createdon'},
              { text: 'Vote Option', value: 'option'},
              // { text: 'Owner', value: 'Owner'},
              // { text: 'Business Unit', value: 'option.Name'},
              { text: 'Actions', value: 'action', sortable: false },
            ],
          PollVotes: [],
            GroupData: '',
            forum: '',
            DiscussionTitle: '',
            DiscussionContent: '',
            DiscussionCreator: '',
            UserisOwner: false,
            PollOptions: [],
            PollColor: {hex: '#FFF176'},
            PollDark: false,
            Forums: [],
          NewCommentInput: '',
          NewReplyInput: '',
            Posts: [],
            SocialComments: [],
            SocialReplies: [],
            SocialLikes: [],
            WritingPost: false,
            inputwidth: '90%',
            postelmnt: '',
        }
        
    },
    watch: {
        'postelmnt' (value){
             if (value !== null && typeof value !== "undefined") {
                 
            }
        }
    },

    created(){
      this.CheckAuth()
        this.IntranetViewToggle()
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        
         console.log(this.$route.params)
      
      
      
      
        window.onload = setTimeout(() => {
          document.addEventListener("focusin", function(e) {
            var x = document.activeElement.id
          let stylebuttons = document.getElementById('PostInsertButtons')
          let createpostbtn = document.getElementById('CreatePostButton')
          let cancelpostbtn = document.getElementById('CancelPostButton')
          let postwrapper = document.getElementById('postwrapper')
          if(x === 'postinput'){
              stylebuttons.style.display = 'block'
              createpostbtn.style.display = 'block'
              cancelpostbtn.style.display = 'block'
              postwrapper.style.outlineStyle = 'solid'
              postwrapper.style.outlineColor = '#dee6ec'
              postwrapper.style.outlineWidth = "1px"
              document.getElementById("postinput").addEventListener("paste", function(e) {
            console.log('someone is pasting')
            e.preventDefault();
            if (e.clipboardData && e.clipboardData.getData) {
                var text = e.clipboardData.getData("text/plain");
                document.execCommand("insertHTML", false, text);
            } else if (window.clipboardData && window.clipboardData.getData) {
                var text = window.clipboardData.getData("Text");
                insertTextAtCursor(text);
            }
            });
          }
          
        });
          
          this.postelmnt = document.getElementById("postinput")          
            console.log(this.postelmnt)
            
        
      }, 2000);
    },

    watch: {
      UserRecord(value) {
         console.log('still')
        if (value !== null && value !== undefined) {
        this.GetPollData()
        }
      },
    // WritingPost (value) {
    //   if (value !== null && value !== undefined && value === true) {
    //     // this.inputwidth = '90%'
    //     // let postelmnt = document.getElementById('postinput')
    //     setTimeout(() => {
    //         this.postelmnt.focus()
    //       }, 100); 

    //   }
    //   else{
    //     // this.inputwidth = "100%"
    //   }
    // }
  },

    computed: {
      userLoggedIn () {
      return this.$store.getters.user
    },
      userisContentAdmin(){
        return this.userIsAdmin
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
      UserRecord(){
            return this.userLoggedIn
          },
      ComputedPollVotes(){
        return this.PollVotes.filter(vote => {
          if(this.selectedLabel && this.selectedLabel.Name){
            return vote.option.Name === this.selectedLabel.Name
          }
          else{
            return vote
          }
        })
      },
      VotesbyOption(){

    return this.PollOptions.map(pollvote => {
      return pollvote.Name
    }).flat()
  },
  VotesbyOptionData(){
    return this.PollOptions.map(pollvote => {
      console.log(pollvote)
      pollvote.Count = 0
      this.PollVotes.map(vote => {
        if(vote.option.Name === pollvote.Name){
          pollvote.Count = pollvote.Count-1+2
        }
      })
      
      return pollvote
    }).map(pollvote => {
      return pollvote.Count
    })
  },
      Hasvoted(){
        let uservote = this.PollVotes.find(obj => obj.Voterid == this.UserRecord.id)
        return uservote
      },
      ComputedSocialReplies(){
        return this.SocialReplies.map(mainreply => {
          this.SocialReplies.map(reply => {
                    if(reply.relatedid === mainreply.id){
                      let replyonmainreplycheck = mainreply.Replies.find(obj => obj.id == reply.id)
                      if(!replyonmainreplycheck){
                        mainreply.Replies.push(reply)
                      }
                    }
                  })
          return mainreply
        }).map(mainreply => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === mainreply.id){
                      let likeonmainreplycheck = mainreply.Likes.find(obj => obj.id == like.id)
                      if(!likeonmainreplycheck){
                        mainreply.Likes.push(like)
                      }
                    }
                  })
                  if(typeof mainreply.createdon.toDate !== 'undefined'){
                  mainreply.createdon = this.TimestampFormatterSTRING(mainreply.createdon.toDate())
                  }
          return mainreply
        })
      },
      ComputedSocialComments(){
        return this.SocialComments.map(comment => {
          this.ComputedSocialReplies.map(reply => {
                    if(reply.relatedid === comment.id){
                      let replyoncommentcheck = comment.Replies.find(obj => obj.id == reply.id)
                      if(!replyoncommentcheck){
                        if(reply.Replies){
                          comment.Replies = comment.Replies.concat(reply.Replies)
                        }
                        comment.Replies.push(reply)
                      }
                    }
                  })
          return comment
        }).map(comment => {
          this.SocialLikes.map(like => {
                    if(like.relatedid === comment.id){
                      let likeoncommentcheck = comment.Likes.find(obj => obj.id == like.id)
                      if(!likeoncommentcheck){
                        comment.Likes.push(like)
                      }
                    }
                  })
                  if(typeof comment.createdon.toDate !== 'undefined'){
                  comment.createdon = this.TimestampFormatterSTRING(comment.createdon.toDate())
                  }
          return comment
        })
      },
      PostsTimeline () {
        return this.Posts.map(post => {
          this.ComputedSocialComments.map(comment => {
            if(comment.relatedid === post.id){
              let oncheck = post.Comments.find(obj => obj.id == comment.id)
                if(!oncheck){
                  post.Comments.push(comment)
                }
              }   
          })
          return post
        }).map(post => {
          this.SocialLikes.map(like => {
            if(like.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == like.id)
                if(!oncheck){
                  post.Likes.push(like)
                }
              }   
          })
          return post
        }).map(post => {
          this.ComputedSocialReplies.map(reply => {
            if(reply.relatedid === post.id){
              let oncheck = post.Likes.find(obj => obj.id == reply.id)
                if(!oncheck){
                  post.Likes.push(reply)
                }
              }   
          })
          return post
        }).map(post => {          
          if(typeof post.createdon.toDate !== 'undefined'){
            let dataobj = post.createdon.toDate()
            post.createdon = this.TimestampFormatterSTRING(dataobj)
          }
          return post
        }).slice().reverse()
      },

    },

    methods: {
      PromptDeletePoll(){
        confirm('Do you want to completley erase this Poll forever?') && this.ProcessDeletePoll()
      },
      ProcessDeletePoll(){
        if(this.$route.name === 'GroupPoll'){
          db.collection('grouppolls').doc(this.$route.params.slug).delete().then(doc => {
            this.$router.push('/Group/'+this.$route.params.id)
          })
        }
        else{
          db.collection('sitepolls').doc(this.$route.params.id).delete().then(doc => {
            this.$router.push('/Polls')
          })
        }
      },
      UpdateStatus(status){
        if(this.$route.name === 'GroupPoll'){
          db.collection('grouppolls').doc(this.$route.params.slug).update({
            PublishStatus: status
          })
        }
        else{
          db.collection('sitepolls').doc(this.$route.params.id).update({
            PublishStatus: status
          })
        }
      
    },
      AddSocialItemtoNotice(){
        let socialitem = {
          LinkID: this.LinkID,
          InternalLinkType: this.InternalLinkType,
          ComponentName: this.LinkComponent,
          collectionname: this.SharedCollection,
          IsLink: true,
          InternalLink: true,
        }    
        if(this[this.NoticeCoverIMGProp]){
          socialitem.coverimage = this[this.NoticeCoverIMGProp]
        }      
        this.$router.push({ name: 'noticenew', params: {NoticeItem: socialitem }})
      },
      ShareBlog(){
        this.ShareItemDialog = true
        //this.CreatePost()
      },
      CancelShareItemDialog(){
        this.ShareItemDialog = false
      },
      async CheckAuth(){
      let vm = this
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          //vm.$store.dispatch('SetUserObj', user.uid)
          vm.CheckRouting()
        }
        else{
          
        }
    })
    },
    CheckRouting(){
      
          let vm = this
         let col = ''
          if(this.$route.name === 'GroupPoll'){
            col = 'grouppolls'
            vm.PollDocID = vm.$route.params.slug
            vm.PollVotesQuery = db.collection('grouppollvotes').where('groupid','==',this.$route.params.id).where('Pollid','==',this.$route.params.slug)
            vm.PollVotesCol = db.collection('grouppollvotes')
            vm.InternalLinkType = 'Group-Poll/'+this.$route.params.id+'/Poll'
            vm.SharedCollection = 'grouppolls'
            vm.LinkComponent = 'GroupPollShared'
            vm.LinkID = this.$route.params.slug
            vm.GetPollData(col)
          }
          else{
            col = 'sitepolls'
            vm.PollDocID = vm.$route.params.id
            vm.PollVotesQuery = db.collection('sitepollvotes').where('Pollid','==',this.$route.params.id)
            vm.PollVotesCol = db.collection('sitepollvotes')
            vm.InternalLinkType = 'SitePoll'
            vm.SharedCollection = 'sitepolls'
            vm.LinkComponent = 'SitePollShared'
            vm.LinkID = this.$route.params.id
            vm.GetPollData(col)
          }
        if(this.$route.params.id){
            console.log('has single')
        }
        },
      ChartDataSelect (chart, labelindex) {
    if(typeof chart !== 'undefined' && typeof labelindex !== 'undefined'){ 
      if(this.activeChart === 'Votes by Option'){
        this.selectedLabel = this.PollOptions[labelindex] 
        this.selectedLabelIdentifier = 'Name'  

        this.selectedData = this.PollOptions.filter(vote => {
          return vote.option.Name === this.selectedLabel.id
        })
      } 
    }
  },
      ActivateActiveChart(chart){
    console.log(chart)
    this.activeChart = chart 
     if(this.activeChart === 'Votes by Option'){
      this.Labels = this.VotesbyOption
      this.ChartData = this.VotesbyOptionData
      console.log(this.Labels)
      console.log(this.ChartData)

    } 
    else if(this.activeChart === 'Accounts by Owner'){
      this.Labels = this.AccountsbyOwnerLabels
      this.ChartData = this.AccountsbyOwnerData
      console.log(this.Labels)
      console.log(this.ChartData)

    }
    else{
      console.log('should cancel all values')
      this.selectedLabelIdentifier = ''
      this.selectedLabel = ''
      this.chartview = false
      this.activeChart = ''
      this.selectedData = ''        
    }
  },
      Viewtable(){
    let datatableelmnt = document.getElementById('groupvotesdatatable')
    datatableelmnt.scrollIntoView();
  },
      ConfirmUserVote(option){
        confirm('Do you want to make your vote for '+this.forum.PollName+' '+option.Name+'?') && this.ProcessUserVote(option)
      },
      ProcessUserVote(option){
        let NewPollVote = {
          option: option,
          Pollid: this.$route.params.id,
          PollName: this.forum.PollName,
          Voter: {id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name},
          Voterid: this.UserRecord.id,
          createdon: new Date()
        } 
        if(this.UserRecord.Profile_Photo){
          NewPollVote.voterimg = this.UserRecord.Profile_Photo
        }
        if(this.$route.name === 'GroupPoll'){
          NewPollVote.groupid = this.$route.params.id
          NewPollVote.Pollid = this.$route.params.slug
        }
        this.PollVotesCol.add(NewPollVote).then(doc => {
          alert('your vote has been process under '+doc.id)
        })
      },
        IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
        

        GetPollData(col){
            db.collection(col).doc(this.PollDocID).onSnapshot(snapshot => {
                var discussiondata = snapshot.data()
                console.log(discussiondata)
                this.forum = discussiondata
                this.forum.id = this.PollDocID
                if(discussiondata.PublishStatus){
                  this.PublishStatus = discussiondata.PublishStatus
                }
                if(discussiondata.GuestsIncluded){
                  this.GuestsIncluded = discussiondata.GuestsIncluded
                }
                this.DiscussionTitle = discussiondata.PollName,
                this.$emit('PushRecordName',discussiondata.PollName)
            this.DiscussionContent = discussiondata.PollDescription
            this.DiscussionCreator = discussiondata.Created_By
            if(discussiondata.Ownerid === this.UserRecord.id){
                this.UserisOwner = true
                // this.GetPollVotes()
            }
            if(discussiondata.Ownerid === this.userLoggedIn.id){
               this.GetPollVotes()
            }
            else{
              this.PollVotesQuery.where('Voterid','==',this.userLoggedIn.id)
              this.GetPollVotes()
            }
           
            // this.GetMyVote()
            this.PollOptions = discussiondata.PollOptions
            this.PollColor = discussiondata.PollColor
            this.PollDark = discussiondata.PollDark
            
            // this.GetSocialComments()
            // this.GetSocialReplies()
            // this.GetSitePosts()
            // this.GetSocialLikes()
            },
        error => {
          if(error.code === 'permission-denied'){
            if(!this.userLoggedIn){
              this.$router.push('/404')  
            }
            else{
              this.$router.push('/PermissionError')
            }            
          }
        })
        },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      
      GetPollVotes(){
          this.PollVotesQuery.onSnapshot(res => {

            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.PollVotes.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          })
        },
      
    },
}

</script>

<style>

</style>