
  <template>
  <v-row
    class="fill-height ma-0 transparent stickytopconscious"
    align="center"
    justify="center">
    <div class="moving-clouds" 
        :style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>
        <div class="moving-clouds2" 
        style="{ backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' }"></div>

        <v-card
            class="mx-auto"
            max-width="500"
            min-width="350"            
            >
            <v-img
            
            src="@/assets/RABaseBG-Soft.png"
            width="350">
            <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="pop lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                    <v-text-field type="text" v-model="mobilenumber" placeholder="Number" size="12" :rules="[rules.telnr]"/>
                    <span class="caption warning--text">Must be E164 format e.g. "012 345 6789" in RSA becomes "+27123456789"</span>
                    </v-card-text>
                     <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn dark class="accent" id="sign-in-button" @click="loginWithFirebase">OTP</v-btn>
                    </v-card-actions>
                    <v-card-text>              
                    <v-layout class="justify-center">
                    <span class="overline">OR</span>                      
                    </v-layout>
                    <v-divider></v-divider>
                    <v-layout class="justify-center">
                    <v-btn width="100%" dark class="success" @click="$router.push('Login')">Back to Login <v-icon>mdi-lock-open</v-icon></v-btn>                     
                    </v-layout>              
                    </v-card-text>

                </v-window-item>
                <v-window-item :value="2">
                    <v-card-text>
                        <v-text-field type="text" v-model="userOTPinput" placeholder="Number" size="12" :rules="[rules.required]"/><br>
                    </v-card-text>
                </v-window-item>
              
                </v-window>

                <v-divider v-if="step !== 1"></v-divider>

                <v-card-actions v-if="step !== 1">
                         
                <v-btn
                
                    
                    text
                    @click="step = 1"
                >
                    Login
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    dark
                    v-if="ProgressButtonDisabled"
                    color="pop"
                    depressed
                    @click="ProcessOTP()"
                >
                    {{StepProgressTitle}}
                </v-btn>
                </v-card-actions>
            </v-img>
            </v-card>      
    </v-row>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'


  export default {
    name: 'login',
    data() {
      return {
          
        rules: {
            telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
        step: 1,
        mobilenumber: '',
        userOTPinput: '',
        password: '',
        usertest: ''
      }
    },
     computed: {
       ProgressButtonDisabled(){
         if(this.step === 4){
           if(this.email && this.email.length>0){
             return true
           }
           else{
             return false
           }
         }
         else if(this.step === 3){
           return false
         }
         else {
           return true
         }
       },
      userLoggedIn () {
      return this.$store.getters.user
    },
    StepProgressTitle(){
            return this.step === 4 ||  this.step === 3? 'Action' : 'Next'
        },
    currentTitle () {
        switch (this.step) {
          case 1: return 'Sign In'
          case 2: return 'Choose an Option'
          case 3: return 'Reset Email'
          case 4: return 'Reset Password'
          default: return 'Request Processed'
        }
    }
    },

    watch: {
      userLoggedIn (value) {
        if (value !== null && value !== undefined) {
          this.$router.push('/MyProfile')
        }
      }
    },
    methods: {
      onSignInSubmit(){
          const phoneNumber = this.mobilenumber;
        const appVerifier = window.recaptchaVerifier;
        console.log(phoneNumber)
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                console.log(confirmationResult)
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                // ...
            }).catch((error) => {
                // Error; SMS not sent
                // ...
            });
        // [END auth_phone_signin]
      },
      ProcessOTP(){
          let vm = this
          const code = vm.userOTPinput;
                    window.confirmationResult.confirm(code).then((result) => {
                    // User signed in successfully.
                    const user = result.user;
                    vm.$router.push('/MyProfile')
                    // ...
                    }).catch((error) => {
                    // User couldn't sign in (bad verification code?)
                    // ...
                    });
      },
      loginWithFirebase () {
      let vm = this
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': (response) => {
            console.log(response)
        }
        });
        // E.164 format 
          const phoneNumber = this.mobilenumber;
        const appVerifier = window.recaptchaVerifier;
        firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
            .then((confirmationResult) => {
                console.log(confirmationResult)
                vm.step = 2
                //alert(vm.step)
                // SMS sent. Prompt user to type the code from the message, then sign the
                // user in with confirmationResult.confirm(code).
                window.confirmationResult = confirmationResult;
                
                // ...
            }).catch((error) => {
                console.log(error)
                // Error; SMS not sent
                // ...
            });
        // [END auth_phone_signin]
        


            }

  }
    }



</script>

<style scoped>
.signinpad {
  padding: 5%
}
  .login {
    margin-top: 40px;
    color: black
  }
  .logincard {
    padding: 10%
  }
  input {
    margin: 10px 0;
    width: 100%;
    padding: 15px;
  }
  button {
    margin-top: 20px;
    width: 10%;
    cursor: pointer;
  }
  p {
    margin-top: 40px;
    font-size: 13px;
  }
  p a {
    text-decoration: underline;
    cursor: pointer;
  }
  .social-button {
    width: 75px;
    background: white;
    padding: 10px;
    border-radius: 100%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    outline: 0;
    border: 0;
  }
  .social-button:active {
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
  }
  .social-button img {
    width: 100%;
  }
.stickytopconscious{
  top: 100px
}
</style>
  