<template>
  <div>
    <v-card-title style="border-radius: 15px;" class="BoldBuilderGradient justify-start mediumoverline">
        <v-avatar size="80" class="mx-1">
             <v-img contain src="@/assets/RAFullLogo.png"/>
        </v-avatar>
      Settings for your Suite
      </v-card-title>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Identification
      </v-card-subtitle>
      <v-card-text >
          <v-list-item dense>                     
            <v-text-field v-model="System.Name" label="App Name"></v-text-field>
            </v-list-item>
            <v-list-item dense>                     
            <v-text-field v-model="System.ClientAppURL" label="Domain"></v-text-field>
            </v-list-item>
      </v-card-text>
      <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateAppGeneral()" dark color="success">
              Save
          </v-btn>
      </v-card-actions>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Mail
      </v-card-subtitle>
      <v-card-text >
          <v-list class="transparent">                      
            <v-list-item dense>                     
            <v-text-field v-model="SiteEmailConfigObj.SystemFromEmailHost" label="Email smtp host"></v-text-field>
            </v-list-item>
            <v-list-item dense>                         
            <v-text-field v-model="SiteEmailConfigObj.SystemFromEmailAddress" label="Email Address"></v-text-field>
            </v-list-item>
            <v-list-item dense>
                <v-text-field v-model="SiteEmailConfigObj.SystemFromEmailPassword" type="password" label="Password"></v-text-field>
                </v-list-item>
            <v-list-item dense>
                <v-text-field v-model.number="SiteEmailConfigObj.SystemFromEmailPort" label="Port" type="number"></v-text-field>
                </v-list-item>
            <v-list-item dense>
                <v-switch dense
                v-model="SiteEmailConfigObj.SystemFromEmailSecure"
                class="mx-2"
                label="Secure Mail"
                ></v-switch>
            </v-list-item>    
        </v-list>
      </v-card-text>
      <v-card-actions>
          <v-spacer>
          </v-spacer>
          <v-btn @click="UpdateEmailConfig()" dark color="success">
              Save
          </v-btn>
      </v-card-actions>
      </v-card>
       <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Custom Fonts <v-icon>mdi-format-color-text</v-icon>
      </v-card-subtitle>
      <v-card-text >
         <v-list>
            <v-menu nudge-right="30"								
            :close-on-content-click="false"                            									
            transition="scale-transition"                            									
            >									
            <template v-slot:activator="{ on }">
            <v-btn outlined v-on="on" dark color="purple">Add Font</v-btn>
            </template>
            <v-card>
                <v-card-title>
                    New Font
                </v-card-title>
                <v-card-text>
                    <input id="fontfileuplaoder"
                    @change="UploadFontFileSelect($event)" style="visibility: hidden;display: none;"
                type="file">
                <label :for="'fontfileuplaoder'">
                    <!-- <span v-if="Record[field.Name] && Record[field.Name].UploadFile" class="texbtn btnhover elevation-1">
                  <v-icon class="btnhover" small>mdi-file</v-icon>Change File
                  </span> -->
                  <span class="texbtn btnhover elevation-1">
                    <v-icon class="btnhover" small>mdi-file</v-icon>Select File
                  </span>
                </label>
                <v-list-item v-if="CustomFontUploadFile">
                    <v-text-field v-model="CustomFontName" label="Font Display Name"/>
                </v-list-item>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="CancelUploadCustomFont()" dark color="warning" outlined>Cancel</v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="UploadCustomFont()" v-if="CustomFontUploadFile" dark color="success" outlined>
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
            </v-menu>
            <v-list-item v-for="item in System.CustomFonts" :key="item.itemObjKey" :style="'font-size:12px;font-family:'+item.Name">
                {{item.Name}}
            </v-list-item>
         </v-list>
      </v-card-text>
      </v-card>
      <v-card elevation="0" class="siteconfigcard">
        <v-card-subtitle class="mediumoverline">
          Recent Activity
      </v-card-subtitle>
      <v-card-text >
         Per Module
      </v-card-text>
      </v-card>
      
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
export default {
    props: ['System','SystemEntities'],
    components: {InfoSheet},
    data() {
        return {
            UploadingFileProgress: 0,
            CustomFontUploadFile: '',
            CustomFontName: '',
            SiteEmailConfigObj: {},
            SystemTickets: [],
            RoadmapItems: [],
            elmnt:  {	
                						
                RowColor: '#00ffff',									
                Name: 'Info Sheet',									
                ShowComponents: true,									
                Icon: 'mdi-alert',									
                Title: 'About',									
                Description: `Add the Ability to import Platform Features`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                Color: 'red',	
                Elevation: 6,
                AlertIcon: 'mdi-alert',	
                BorderPosition: 'top',
                ColoredBorder: true,							
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:3,									
                FlexLarge: 3,									
                FlexMedium: 4,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,	
                Progress: 0,
                // Prominent: true								
            },	
        }
    },	
    computed:{
         ActiveDB(){
            return db
        },
        IncompleteRoadmapItems(){
            return this.RoadmapItems.filter(item => {
                return item.Status && item.Status.Name !== 'InActive'
            })
        },
        OpenSystemTickets(){
            return this.SystemTickets.filter(ticket => {
                return ticket.Status && ticket.Status.Name !== 'InActive'
            })
        },
        HighlightedAlertElements(){
            let items = [
                {Title: 'Incomplete System Tickets',Array: 'OpenSystemTickets', TableName: 'System Tickets', TablePath: 'SystemTickets',IncompleteName: 'Open'},
                {Title: 'Incomplete Roadmap Items',Array: 'IncompleteRoadmapItems', TableName: 'Roadmap Items', TablePath: 'Roadmap',IncompleteName: 'Incompleted'},
            ]
            items.map(item => {
               if(this[item.Array] && this[item.Array].length > 0){
                        item.Description = 'You have a total of '+this[item.Array].length+' '+item.IncompleteName+' '+item.TableName+'. View them now?'
                        item.Color = 'red'
                        item.AlertIcon = 'mdi-alert'
                        item.HasActionButton = true
                        item.RoutePath = '/'+item.TablePath
                    }
                    else if(!this[item.Array] || this[item.Array] && this[item.Array].length === 0){
                        item.Description = 'No '+item.TableName+' '+item.IncompleteName+'.'
                        item.Color = 'green'
                        item.AlertIcon = 'mdi-check'
                    }
                return item
            })
            return items.map(item => {
                let elmnt = Object.assign({},this.elmnt)
                elmnt.Title = item.Title
                elmnt.Description = item.Description
                elmnt.Color = item.Color
                elmnt.AlertIcon = item.AlertIcon
                if(item.HasActionButton){
                    elmnt.HasActionButton = item.HasActionButton
                    elmnt.RoutePath = item.RoutePath
                }
                return elmnt
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        this.GetSiteEmailConfigObj()
    },
    methods:{
        UploadCustomFont(){
            this.$store.commit('SetSocialItemInteractMethodProp',true) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateUploadingSingleFileNotification')
        let customfontsarray = this.System.CustomFonts
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        console.log(barelmnt,bartextelmnt)
        
        let filename = this.CustomFontUploadFile.name
        const path = '/Fonts/'+filename
        const Newdocument = { 
          Name: this.CustomFontName,
        }
        var storageRef = firebase.storage().ref(path);
          var uploadTask = storageRef.put(this.CustomFontUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+filename);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$store.commit('SetSocialItemInteractMethodProp',false) 
                vm.$store.commit('SetSocialItemInteractMethod','ActivateUploadingSingleFileNotification')
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
              });
            });
            uploadTask
            .then(snapshot => snapshot.ref.getDownloadURL())
              .then((url) => {
                Newdocument.fileurl = url
                Newdocument.Path = 'url('+url+')'
                //TEMPARRAY.push(Newdocument)
                customfontsarray.push(Newdocument)
                db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                    CustomFonts: customfontsarray}).then(doc => {
                  this.CancelUploadCustomFont()
                })
              })
                
        }, 1000);
        },
        CancelUploadCustomFont(){
            this.CustomFontUploadFile = ''
            this.CustomFontName = ''
            this.$store.commit('SetSocialItemInteractMethodProp',false) 
            this.$store.commit('SetSocialItemInteractMethod','ActivateProcessing')
        },
        UploadFontFileSelect(event){
            let file = event.target.files[0]
            this.CustomFontUploadFile = file
            this.CustomFontName = file.name
        },
        UpdateAppGeneral(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
                Name: this.System.Name,
                ClientAppURL: this.System.ClientAppURL
            })
        },
        UpdateEmailConfig(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Options').doc('EmailSettings').set(this.SiteEmailConfigObj)
        },
        GetSiteEmailConfigObj(){
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Options').doc('EmailSettings').onSnapshot(snapshot => {
                let data = snapshot.data()
                if(data){
                    this.SiteEmailConfigObj = data
                }
            })
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



