<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
        <v-card-text>
         <v-select label="Half" v-if="EditedTab.Name === 'Parallax'" v-model="EditedTab.HalfParallaxClass" clearable :items="['halfparallaxleft','halfparallaxright','halfparallaxblockleft','halfparallaxblockright','halfparallaximg']" />									
                              <v-switch label="Clipped" v-model="EditedTab.Clipped" />									
                              <v-switch label="Clip by Path" v-model="EditedTab.ClippedbyPath" v-if="EditedTab.Clipped"/>									
                              <v-switch label="Clipped Animation" v-model="EditedTab.ClipAnimated" />									
                               <v-select  @change="Onepropemit('AssignClipDefaults',EditedTab)" v-if="EditedTab.Clipped && EditedTab.ClippedbyPath && !EditedTab.ClipAnimated" v-model="EditedTab.ClippedPathOption" label="Style" :items="['elipse','circle','beizer','polygon']"/>									
                                <div v-if="EditedTab.ClippedPathOption === 'polygon' && !EditedTab.ClipAnimated && EditedTab.ClippedbyPath">									
                                									
                                    <span class="caption">{{EditedTab.TopLeftCrnrXValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.TopLeftCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Left X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopLeftCrnrYValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.TopLeftCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Left Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopRightCrnrXValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.TopRightCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Right X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.TopRightCrnrYValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.TopRightCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Top Right Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomLeftCrnrXValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.BottomLeftCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Left X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomLeftCrnrYValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.BottomLeftCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Left Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomRightCrnrXValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.BottomRightCrnrXValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Right X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.BottomRightCrnrYValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.BottomRightCrnrYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Bottom Right Y Value"									
                                ></v-slider>									
                                									
                                </div>									
                                <div v-if="EditedTab.ClippedPathOption === 'elipse'">									
                                <span class="caption">{{EditedTab.ElipseXValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.ElipseXValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseYValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.ElipseYValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Value"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseXPositionValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.ElipseXPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Position"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.ElipseYPositionValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.ElipseYPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Position"									
                                ></v-slider>									
                                </div>									
                                <div v-if="EditedTab.ClippedPathOption === 'circle'">									
                                <span class="caption">{{EditedTab.CircleRadiusValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.CircleRadiusValue"									
                                    min="0"									
                                    max="100"									
                                    label="Radius"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.CircleXPositionValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.CircleXPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="X Position"									
                                ></v-slider>									
                                <span class="caption">{{EditedTab.CircleYPositionValue}}</span>									
                                <v-slider @change="Onepropemit('AssignClippingValue',EditedTab)"									
                                    v-model="EditedTab.CircleYPositionValue"									
                                    min="0"									
                                    max="100"									
                                    label="Y Position"									
                                ></v-slider>        									
                                </div>	
                                <v-dialog max-width="800">								
                                    <template v-slot:activator="{ on }">									
                                    <v-icon v-if="EditedTab.Name !== 'Clipped Tab'"
                                    v-on="on">mdi-image-edit-outline</v-icon>									
                                        </template>	
                                      						
                                            <v-tabs v-if="!UseGroupAssets">									
                                            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">									
                                                {{lib.name}}									
                                            </v-tab>									
                                            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">							
                                                <v-card width="800">									
                                                <PhotoLibraryViewer :FullGallery="true"	:TableCollectionRef="lib.TableCollectionRef" :TableName="lib.name"							
                                                @AssignImage="AssignImage" :IMGProp="'ClippingIMG'" :CurrentEntity="lib" :AppisDarkMode="true"									
                                                :tab="EditedTab" />									
                                                </v-card>									
                                            </v-tab-item>									
                                        </v-tabs>				
                                        <v-card-text v-if="UseGroupAssets">
                                            <v-row  v-if="SystemAssets.length > 0">
                                                <v-col
                                                v-for="n in SystemAssets"
                                                :key="n.itemOvjKey"
                                                class="d-flex child-flex"
                                                cols="3"
                                                >
                                                <v-img
                                                    :src="n.ThumbURL"
                                                    aspect-ratio="1"
                                                    class="grey lighten-2"
                                                    @click="AssignImage(EditedTab,'ClippingIMG',n)"
                                                >
                                                    <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <v-progress-circular
                                                        indeterminate
                                                        color="grey lighten-5"
                                                        ></v-progress-circular>
                                                    </v-row>
                                                    </template>
                                                </v-img>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>                                      
                                    </v-dialog>			
                                    <v-text-field v-if="EditedTab.ClippingIMG"  v-model="EditedTab.ClippingIMG" clearable/>
                                    <div v-if="EditedTab.ClipAnimated">									
                                    <v-select label="Style" @change="Onepropemit('AssignClippingAnimationValue',EditedTab)" v-model="EditedTab.ClipAnimation" :items="['Spotlight Search']"/>									
                                    <v-select label="Repeat" @change="Onepropemit('AssignClippingAnimationValue',EditedTab)" v-model="EditedTab.CircleAnimationRepeat" :items="['1','2','3','infinite']"/>									
                                									
                                </div>
        </v-card-text>
        </v-card>
</template>







<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            alert('This will only take effect on save and reload of the page')
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
