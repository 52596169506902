
<template>
    <!-- <v-card :class="tab.RowTransparency" :color="tab.RowColor" flat height="100%" width="100%" tile v-if="tab.Name === 'HTML Box'"> -->
    <!-- tab.Shaped {{tab.Shaped}} -->
            <v-card :class="tab.BoxTransparency+' '+tab.CustomClass+' mx-3' "  :color="tab.BGColor.hexa" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" 
            :id="tab.ElementID+'_Tab'"
             :style="tab.AnimationCSS && tab.AnimationCSS.Tab ? 
             BoxStyle(tab)+tab.AnimationCSS.Tab : 
             BoxStyle(tab)">
            <div :style="ArticleColStyle(tab,true)">
        
            <TitleTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />
                                <div :style="ArticleColStyle(tab)">
                                    <HTMLDescription
                                    :tab="tab" :PageContentFont="PageContentFont"
                                    />
                             <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <ImageRenderComp
                        :tab="tab" :IMGGradient="tab.HasParallaxGradient ? tab.ParallaxGradient : ''"
                        :IMGProp="'IMG'"
                    />
                                 </v-layout>
                             </v-card-text>
                                </div>
                        
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                            <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"								
                                />
                        </v-card-actions>
            </div>
                            </v-card>
                            
    <!-- </v-card> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ActionButtonEmbed from '@/components/WebPages/RenderComponents/ActionButtonEmbedReadOnly';
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import HTMLDescription from '@/components/WebPages/RenderComponents/HTMLDescription';
import ImageRenderComp from '@/components/WebPages/RenderComponents/ImageRenderComp';
export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','PageName','PageID'],
    components: {
        ActionButtonEmbed,
        TitleTab,
        HTMLDescription,
        ImageRenderComp
        },
    data() {
    return {    
        
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
        // let writeupid = this.tabindex+'_'+this.i+'_Description'
        // window.onload = setTimeout(() => {
            
        //     let writeupelmnt = document.getElementById(writeupid)
        //     var sheet = writeupelmnt.sheet

        //     // margin: 1% 3% -2px 0;
        //     // padding:0 5% 0;
        //     // font-size: 3em;;
        //     // line-height: 1.5;
        //     // float: left;
        //     // color:#f0f0f0;
        //     // font-weight:700;
        //     // background: #ff6633;
        //     // vertical-align:bottom;
        //     // var styles = '.'+writeupid+' {';
        //     // styles += 'text-align:right;';
        //     // styles += '};';
        //     // sheet.insertRule(styles, 0);
        //     console.log(writeupelmnt)
        // },100)

    },
    
    methods: {
        
        BaseTitleStyle(tab){
            return tab.HeaderBlendMode ? 'mix-blend-mode: '+tab.HeaderBlendMode+';'+this.TitleStyle(tab) : this.TitleStyle(tab)
        },
        ArticleColStyle(tab,boxwrap){
            let style = ''
            
            if(boxwrap && tab.HasArticleCols && !this.MiniView && tab.ColsWrapBox){
                style = 'columns:'+tab.TotalArticleCols+';column-gap:3rem;'
                if(tab.ColsBorder){
                    style = style+'border: '+tab.ColsBorderWidth+'px solid '+tab.ColsBorderColor.hexa+';border-radius: '+tab.ColsBorderRadius+'px;'
                    style = style+'padding:2em;'
                }
                else{
                    //0.65 looks real magazine style have to keep in consideration
                    //2 looks super cool as well dunno
                    style = style+'padding:1em;'
                }
            }
            else if(!boxwrap && tab.HasArticleCols && !this.MiniView && !tab.ColsWrapBox){
                style = 'padding:2em;columns:'+tab.TotalArticleCols+';column-gap:3rem;'
                if(tab.ColsBorder){
                    style = style+'border: '+tab.ColsBorderWidth+'px solid '+tab.ColsBorderColor.hexa+';border-radius: '+tab.ColsBorderRadius+'px;'
                }
            }
            return style
        },
        TabFontStyle(tab){
            return tab.FontBGColor && !tab.FontUnderline ? this.MiniView ? 'background-color: '+tab.FontBGColor+';width: 80%;' : 'background-color: '+tab.FontBGColor+';width: 50%;' :
            tab.FontUnderline && tab.FontUnderlineColor ? 'background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' :
            ''
        },
        TitleStyle(tab){
            // tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)"
            if(tab.HeaderBGColor){
                return tab.Center? 'background-color: '+tab.HeaderBGColor.hexa+';padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
            else{
                return tab.Center? 'padding-bottom: 20px;text-align: center;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily) : 'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+this.HeaderFontFamily(tab,tab.HeaderFontFamily)
            }
        },
       BoxStyle(tab){
        let style = ''
        if(tab.FullHeight){
            style = 'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;height: 100%;'
        }
        else{
            style = 'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'
        }
        style = style+tab.CustomStyle
        return style
       },
        GetTabClass(tab){
            if(tab.BoxTransparency && tab.CustomClass){
              return tab.BoxTransparency+' '+tab.CustomClass+' mx-3'  
            }
            
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>

.magartallp p::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }
.magartfirstp  p:first-of-type::first-letter {
    margin: 1% 3% -2px 0;
    padding:0 5% 0;
    font-size: 3em;;
    line-height: 1.5;
    float: left;
    color:#f0f0f0;
    font-weight:700;
    background: #ff6633;
    /* background: inherit; */
    vertical-align:bottom;
  }
</style>