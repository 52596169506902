
<template>
<div :class="PageClass" :style="PageRouteStyling+PageCustomStyle">
   <!-- <v-overlay v-if="PageOverlay" color="#ffffff00"  :style="PageOverlay">
      </v-overlay>   -->
    <v-dialog v-model="BannerDialog" width="80%">
        
         <BannerAdsCycle :BannerAds="FilteredUnClosedRelevantTopBannerAds" v-if="BannerDialog" :Popup="true"
         @CloseBannerDialogItem="CloseBannerDialogItem"
     />
    </v-dialog>
    <v-dialog v-if="userLoggedIn" v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>
     <BannerAdsCycle :BannerAds="FilteredRelevantTopBannerAds" v-if="ViewBannerAdsSTD"
     />
    
    <span v-if="CatNavList && CatNavList.id">
        <DirectoryViewer v-if="CatNavList && CatNavList.CategoricalListType && !Refreshing" :ProvidedAdditionalFilters="CatNavList.AdditionalFilters"
      :ProvidedNavList="CatNavList.NavList" :ProvidedCurrentEntity="CatNavList" :style="FinalPageStyling" @ActivateStoreView="ActivateStoreView"
      @ActivateTreeNode="ActivateTreeNode" :ProvidedFeatureList="CatNavList.FeatureList" @PushActiveProduct="PushActiveProduct" :CartItems="CartItems"
      />
      <BannerAdsCycle :BannerAds="RelevantTopBannerAds" v-if="RelevantTopBannerAds && RelevantTopBannerAds.length > 0"
     />
    </span>
    <div :class="PageContentClass" v-if="!CatNavList" :style="FinalPageStyling">
        <!-- PageCustomStyle {{PageCustomStyle}} PageData.PageCustomStyle {{PageData.PageCustomStyle}} -->
        <v-layout column class="sideactivitiestoggle-container" v-if="$vuetify.breakpoint.xs && IsDefaultSocialPage">
                    <v-icon @click="ShowSystemActivities = !ShowSystemActivities" class="soloactionicon" color="warning">mdi-timeline-alert</v-icon>
                  </v-layout> 
        <div v-if="IsDefaultSocialPage && ShowSystemActivities" class="flex xl2 lg3 md4 sm5 xs12"  style="padding:7px;margin-top:-55px;">
            <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%">
        <SystemActivitiesFeed style="padding-top:15px;" class="mx-3" :AppisDarkModeCard="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UsersRecord="UserRecord" :Origin="'Page'" :Excerpt="20" :HasFilter="false" />
            </v-card>
        </div>
         <v-layout column class="sideactivitiestoggle-container" v-if="$vuetify.breakpoint.xs && DocLibEditor">
                    <v-icon @click="ShowDocLibMenu = !ShowDocLibMenu" class="soloactionicon" color="warning">mdi-menu</v-icon>
                  </v-layout>
        <div v-if="DocLibEditor && ShowDocLibMenu && ActiveDocumentationLibrary" class="flex xl2 lg3 md4 sm5 xs12" style="height: 100%;">
            <!-- ActiveDocumentationLibrary.LibraryNavbarItems -->
            
           <v-navigation-drawer width="100%"  style="height: 100%;">
               <v-layout column align-center>
                   
                    <v-flex>
                    <v-avatar
                        class="profile"
                        size="220"
                        tile
                    >
                        <v-img contain src="@/assets/logo.png"></v-img>
                    </v-avatar>
                    
                    </v-flex>
                </v-layout>
                
                <v-divider>
                </v-divider>
                <v-list-item v-if="CanEditDocLib" class="justify-center">
                <v-btn @click="NavigatetoLibraryEditor(ActiveDocumentationLibrary)" small dark color="warning">Edit</v-btn>
                </v-list-item>
                <v-list-item class="justify-center">
                    
                    <v-icon style="padding-right: 7px;">
                        {{ActiveDocumentationLibrary.PageIcon}}
                    </v-icon>
                 {{ActiveDocumentationLibrary.Name}}
                 
                </v-list-item>
                 <v-divider>
                </v-divider>
               <v-list width="100%" height="100%" class="transparent" style="padding-top:20px;padding-left:5px;">
                <div v-for="item in ActiveDocumentationLibrary.LibraryNavbarItems" :key="item.title">
                <v-list-item v-if="!item.Children || item.Children.length===0" @click="ActivateLibraryPage(item)" style="text-align: -webkit-left;">
                <v-list-item-content>
                    <v-list-item-title >  <v-icon style="padding-right: 7px;">
                        {{item.icon}}
                    </v-icon>{{ item.title}}
                    </v-list-item-title>
                    </v-list-item-content>
                
                </v-list-item>
                    <v-list-group style="text-align: -webkit-left;"
                    v-if="item.Children && item.Children.length"
                    >
                        <template v-slot:activator>
                        <v-list-item-title>
                             <v-icon style="padding-right: 7px;">
                        {{item.icon}}
                    </v-icon>
                            {{item.title}}</v-list-item-title>
                        </template>
                        <div v-for="subsub in item.Children"
                                :key="subsub.itemObjKey">
                            <v-list-item class="mx-3"
                                
                                @click="ActivateLibraryPage(subsub)"
                            >                                   
                                <v-list-item-title style="padding-left:15px;">   <v-icon style="padding-right: 7px;">
                                    {{subsub.icon}}
                                </v-icon>
                                    {{ subsub.title }}</v-list-item-title>
                            </v-list-item>
                            
                        </div>
                    </v-list-group>
                </div>
            </v-list>
           </v-navigation-drawer>
        </div>
        <div :class="IsDefaultSocialPage || DocLibEditor ? 'flex xl10 lg9 md8 sm7 xs12': ''">
               <v-card-title class="justify-center recordtoolbar display-2 white--text" v-if="DocLibEditor && ActiveDocumentationLibrary">
                   <v-icon dark x-large style="padding-right: 15px;">
                       {{PageData.PageIcon}}
                   </v-icon>
            {{PageData.Name}}
        </v-card-title>
<!-- <v-main class="grey lighten-3" style="margin-top:0px;margin-bottom:-150px"> -->
<!-- <span v-if="HasActivitiesSideFeed">
                  <v-layout column class="sideactivitiestoggle-container" v-if="$vuetify.breakpoint.xs">
                    <v-icon @click="ShowSystemActivities = !ShowSystemActivities" class="soloactionicon" color="warning">mdi-clipboard-alert</v-icon>
                  </v-layout>

  <v-row>
      <v-flex lg2 md3 sm4 xs12  style="padding:7px;">
               <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%">
              <SystemActivitiesFeed style="padding-top:15px;" class="mx-3" :AppisDarkModeCard="AppisDarkModeCard" :AppisDarkMode="AppisDarkMode" :UsersRecord="UserRecord" :Origin="'Page'" :Excerpt="20" :HasFilter="false" />
              </v-card>
            </v-flex></span> -->
            <!-- <AppValidatorAlerts v-if="tab.SystemDashboard" :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
                            :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/> -->
            <div v-if="SingleRecordNavMenuItem && SingleRecordNavMenuItem.SystemDashboard && ActiveDB">
                            <AppValidatorAlerts :SingleRecordNavMenuItem="SingleRecordNavMenuItem" :ActiveDB="ActiveDB" :System="System"
                            :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides"/>
                            </div>
             <v-card :color="MatchColor(tab)" :class="tab.RowTransparency" tile  width="100%" flat  class="CodeRenders" id="PageCode" :style="GetTabStyle(tab)"	
                v-for="(tab,i) in ComputedPageTabs" :key="tab.itemObjKey" >
                <!-- <PageTabRenderer 
                            :tab="tab" :i="i" @OpenDialog="OpenDialog" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize"
                            :SystemEntities="ComputedSystemEntities" :EntityDataRef="EntityDataRef" :EntityCollectionRef="EntityCollectionRef" :SingleFormEditing="SingleFormEditing"
                            :NewTableDragging="NewTableDragging" :NewTableDraggingIndex="NewTableDraggingIndex" :ActiveEntityID="CurrentEntity.id" :CurrentEntity="CurrentEntity"
                            :Record="DynamicWikiData" :CanEdit="CanEdit" :CanGet="CanGet" :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                            :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                            :AdditionalSaveMethod="AdditionalSaveMethod" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
                            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :FeatureNavList="FeatureNavList"
                            @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"  :RADB="RADB" :SingleElementView="SingleElementView"
                            @NewTableDragfinish="NewTableDragfinish" @UpdateEditableField="UpdateEditableField" @UpdateRecordCalculation="UpdateRecordCalculation"      
                            :AppisDarkMode="AppisDarkMode" :SystemPhotoLibraries="SystemPhotoLibraries"
                            /> -->
<!-- :class="'mb-6 '+row.Justify"
            no-gutters
            v-for="(row,rowindex) in tab.Rows" :key="row.itemObjKey"
            :style="'align-self:'+row.Alignment" -->
                        <!-- <v-container class="grey lighten-5">  -->
                        <!-- <svg v-if="tab.HasWaves" height="0" width="0">
                                <clipPath id="svgPath" transform="scale(0.003033 0.0116279)" clipPathUnits="objectBoundingBox" >
                                    <path
                                    :style="tab.WavesAnimated ? 'animation: move-wavesforever 15s ease infinite;' : ''"
                                    d="M704,0H0V34.78c30,0,58-7.23,88-7.23s58,7.23,88,7.23,58-7.23,88-7.23,58,7.23,88,7.23,58-7.23,88-7.23,58,7.23,88,7.23,58-7.23,88-7.23,58,7.23,88,7.23,58-7.23,88-7.23,58,7.23,88,7.23V0h-176Z"/>
                                   
                                </clipPath>
                        </svg>
                        <div v-if="tab.HasWaves"  class="header" :style="TabWavesStyle(tab)">
                        </div> -->                      
                        <svg v-if="tab.HasTopWaves" :style="tab.TopWaveRestrictHeight ?
                         'display: block;width: 100%;margin: 0;z-index:2;top:0;position:absolute;float:left;transform: rotate(180deg);max-height: '+tab.TopWaveHeight+'px;' : 
                         'display: block;width: 100%;margin: 0;z-index:2;top:0;position:absolute;float:left;transform: rotate(180deg);height: -webkit-fill-available;'"
                            xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 106 40"
                                :preserveAspectRatio="$vuetify.breakpoint.xs ? tab.TopWaveAspectRatio : 'none'">
                            <defs v-html="tab.TopPathsHTML">
                            </defs>
                            <g :style="tab.TopWave1Style">
                            <use :href="'#'+i+'_TopWave1Path'" :x="tab.TopWave1XPos" :y="tab.TopWave1YPos" :fill="tab.TopWave1Color.hexa"/>
                            </g>
                                <g  :style="tab.TopWave2Style" v-if="tab.TopWaveCount > 1">
                            <use :href="'#'+i+'_TopWave2Path'" :x="tab.TopWave2XPos" :y="tab.TopWave2YPos" :fill="tab.TopWave2Color.hexa"/>
                                </g>
                                <g :style="tab.TopWave3Style" v-if="tab.TopWaveCount > 2">
                            <use :href="'#'+i+'_TopWave3Path'" :x="tab.TopWave3XPos" :y="tab.TopWave3YPos" :fill="tab.TopWave3Color.hexa"/>
                            </g>
                                <g :style="tab.TopWave4Style" v-if="tab.TopWaveCount > 3">
                            <use :href="'#'+i+'_TopWave4Path'" :x="tab.TopWave4XPos" :y="tab.TopWave4YPos" :fill="tab.TopWave4Color.hexa"/>  
                            </g>
                        </svg>
                        <!-- :style="'display: block;width: 100%;height: '+tab.BottomWaveHeight+'px;max-height: '+tab.BottomWaveHeight+'px;margin: 0;z-index:2;bottom:0;position:absolute;left:0px;float:left;'" -->
                        <svg v-if="tab.HasBottomWaves" :style="tab.BottomWaveRestrictHeight ? 
                        'display: block;width: 100%;margin: 0;z-index:2;bottom:0;position:absolute;float:left;max-height: '+tab.BottomWaveHeight+'px;' : 
                        'display: block;width: 100%;margin: 0;z-index:2;bottom:0;position:absolute;float:left;height: -webkit-fill-available;'"
                                xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    viewBox="0 0 106 40"
                                    :preserveAspectRatio="$vuetify.breakpoint.xs ? tab.BottomWaveAspectRatio : 'none'">
                                <defs v-html="tab.BottomPathsHTML">
                                </defs>
                                <g :style="'animation: move-forever1 '+tab.BottomWave1Speed+' linear infinite;'">
                                <use :xlink:href="'#'+i+'BottomWave1Path'" :x="tab.BottomWave1XPos" :y="tab.BottomWave1YPos"  :fill="tab.BottomWave1Color.hexa"/>
                                </g>
                                    <g :style="'animation: move-forever2 '+tab.BottomWave2Speed+' linear infinite;'" v-if="tab.BottomWaveCount > 1">
                                <use :xlink:href="'#'+i+'BottomWave2Path'" :x="tab.BottomWave2XPos" :y="tab.BottomWave2YPos"  :fill="tab.BottomWave2Color.hexa"/>
                                    </g>
                                    <g :style="'animation: move-forever3 '+tab.BottomWave3Speed+' linear infinite;'" v-if="tab.BottomWaveCount > 2">
                                <use :xlink:href="'#'+i+'BottomWave3Path'" :x="tab.BottomWave3XPos" :y="tab.BottomWave3YPos"  :fill="tab.BottomWave3Color.hexa"/>
                                </g>
                                    <g :style="'animation: move-forever4 '+tab.BottomWave4Speed+' linear infinite;'" v-if="tab.BottomWaveCount > 3">
                                <use :xlink:href="'#'+i+'BottomWave4Path'" :x="tab.BottomWave4XPos" :y="tab.BottomWave4YPos"  :fill="tab.BottomWave4Color.hexa"/>  
                                </g>
                            </svg>
                            <v-layout row justify-center v-if="tab.ShowComponents">
                              
                          <div :class="'flex xl'+tab.FlexXLRG+' lg'+tab.FlexLarge+' md'+tab.FlexMedium+' sm'+tab.FlexSmall+' xs'+tab.FlexXSmall" :style="tab.GalleryType  === 'Clipped Images' ? '' : ''">  
                              <!-- <v-flex :xl="tab.FlexXLRG" {{tab.FlexXLRG}} :lg="tab.FlexLarge" :md="tab.FlexMedium" :sm="tab.FlexSmall" :xs="tab.FlexXSmall"> -->
                     <div v-if="tab.Name === 'Card Row' && tab.Type !== '3D Carousel' && tab.Type !== 'Magazine Style'" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'">
                                 <v-card-title v-if="tab.HasHeaderImage">
                                  <v-img v-if="!tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               src="@/assets/ImageHolder.png"
                               /><v-img v-if="tab.HeaderImage "
                               contain
                               class="my-1"
                                height="80"
                               :src="tab.HeaderImage"
                               /></v-card-title>
                                <v-card-title :class="tab.Center" v-if="tab.Title && !tab.FancyTitle"
                                :style="tab.HeaderBGColor ? 'background-color: '+tab.HeaderBGColor+';padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily) : 
                                'padding-bottom: 20px;color:'+tab.HeaderColor.hex+';'+HeaderFontFamily(tab,tab.HeaderFontFamily)">
                                <strong>{{tab.Title}}</strong>
                                 <!-- <v-divider inset class="white" >
                                </v-divider> -->
                                <!-- <hr style="width:35%; color:blue;"> -->
                                </v-card-title>
                                <TitleTab v-if="tab.Title && tab.FancyTitle" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="tab" :i="i" :PageID="PageID" :PageName="PageName"	@OpenDialog="OpenDialog"								
                                />	
                                <!-- <v-divider inset class="white" >
                                </v-divider> -->
                            <v-card-text v-html="tab.Description" :class="tab.FillHeight"
                            :style="tab.FontBGColor && !tab.FontUnderline ? DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex+';background-color: '+tab.FontBGColor+';width: 50%;' :
                            tab.FontUnderline && tab.FontUnderlineColor ? DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex+';background-image: linear-gradient(180deg,'+tab.FontUnderlineColor+','+tab.FontUnderlineColor+');background-repeat: no-repeat;background-position: 4px bottom;background-size: 100% 10px;text-decoration: none;' : 
                            DefaultPageFont+'font-size:'+tab.FontSize+'px;color:'+tab.FontColor.hex">
                                </v-card-text>
                             
                             <v-card-text v-if="tab.HasImage">
                                 <v-layout :class="tab.Justify" row>
                                 <v-img  v-if="tab.HasImage && tab.IMG"
                                 :contain="!tab.Clipped"
                                   :height="tab.Height"
                                        :src="tab.IMG"
                                        :style="CheckifClipped(tab)"
                                       
                                        />
                                        <v-img :contain="!tab.Clipped" :style="CheckifClipped(tab)" v-if="tab.HasImage && !tab.IMG"
                                   :height="tab.Height"
                                        src="@/assets/ImageHolder.png"
                                        />
                                 </v-layout>
                             </v-card-text>
                        
                        <v-card-actions v-if="tab.HasActionButton" :class="tab.ButtonAlignment">
                            <v-btn :dark="tab.ActionBtnDark" :to="'/'+tab.ActionButtonRoute" :style="'background-color:'+tab.ButonColor.hex">
                                {{tab.ActionButtonName}}
                            </v-btn>
                        </v-card-actions>
                            </div>
                            
                    <v-row v-if="tab.DataViewName && !SingleRecordNavMenu || tab.DataViewName && SingleRecordNavMenu && SingleRecordNavMenuItem && SingleRecordNavMenuItem.Name === tab.Name || tab.DataViewName && SingleRecordNavMenuItem && SingleRecordNavMenuItem.SingleBuildTabs"
                    :class="'mb-6 '+tab.Justify"
                        no-gutters
                        :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                       
                        > 
                        <v-flex :xl="tab.FlexXLRG" :lg="tab.FlexLarge" :md="tab.FlexMedium" :sm="tab.FlexSmall" :xs="tab.FlexSmall">
                           
                        <!-- Standard
                        'tab','AppisDarkMode','CanEdit','Record','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','ActivitiesArray',
                        'SystemEntities','AdditionalSaveMethod','SingleFormEditing','tabindex','NewFieldDragging','NewFieldDraggingIndex','EntityDataRef'] -->
                        <DataSingleStandard v-if="tab.DataViewName === 'Data Single Standard' && !tab.TransposedTabs" :SingleRecordNavMenuItem="SingleRecordNavMenuItem"
                        :SystemEntities="ComputedSystemEntities" :EntityDataRef="EntityDataRef" :SingleFormEditing="SingleFormEditing" :FeatureNavList="FeatureNavList"
                        :NewFieldDragging="NewFieldDragging" :NewFieldDraggingIndex="NewFieldDraggingIndex" :CurrentEntity="CurrentEntity" :SingleElementView="SingleElementView"
                        :Record="DynamicWikiData" :CanEdit="CanEdit" :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                        :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                        :ActivitiesArray="ActivitiesArray" :AdditionalSaveMethod="AdditionalSaveMethod" @UpdateRecordCalculation="UpdateRecordCalculation"
                        @NewFieldDragfinish="NewFieldDragfinish" @UpdateEditableField="UpdateEditableField" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"              
                        :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
                        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" @ActivateProcessing="ActivateProcessing"
                        :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                        />
                        <!-- Cabinet
                        ['tab','AppisDarkMode','CanEdit','CanCreate','CanDelete','CanGet','Record','Business_Unit','PrimaryFieldName','RelatedObj','AppointmentsQuery','ActivitiesArray',
                        'SystemEntities','AdditionalSaveMethod','SingleFormEditing','tabindex','NewTableDragging','NewTableDraggingIndex','ActiveEntityID','EntityCollectionRef','EntityDataRef' -->
                        <DataSingleCabinet v-if="tab.DataViewName === 'Data Single Cabinet' || tab.DataViewName === 'Data Single Related' || tab.DataViewName === 'Data Single Standard' && tab.TransposedTabs"
                        :SystemEntities="ComputedSystemEntities" :EntityDataRef="EntityDataRef" :EntityCollectionRef="EntityCollectionRef" :SingleFormEditing="SingleFormEditing"
                        :NewTableDragging="NewTableDragging" :NewTableDraggingIndex="NewTableDraggingIndex" :ActiveEntityID="CurrentEntity.id" :CurrentEntity="CurrentEntity"
                        :Record="DynamicWikiData" :CanEdit="CanEdit" :CanGet="CanGet" :Business_Unit="Business_Unit" :PrimaryFieldName="PrimaryFieldName" :RelatedObj="RelatedObj"
                        :AppointmentsQuery="AppointmentsQuery" :TasksQuery="TasksQuery" :EmailsQuery="EmailsQuery" :NotesQuery="NotesQuery" :PhoneCallsQuery="PhoneCallsQuery"
                        :AdditionalSaveMethod="AdditionalSaveMethod" @onPhotoUpload="onPhotoUpload" @GetShortLivedURL="GetShortLivedURL"
                        @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :FeatureNavList="FeatureNavList"
                        @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"  :RADB="RADB" :SingleElementView="SingleElementView"
                        @NewTableDragfinish="NewTableDragfinish" @UpdateEditableField="UpdateEditableField" @UpdateRecordCalculation="UpdateRecordCalculation"      
                        :AppisDarkMode="AppisDarkMode" :SystemPhotoLibraries="SystemPhotoLibraries"
                        :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                        :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                        />
                        <!-- Data Single Standard
                        Data Single Cabinet -->
                        </v-flex>
                    </v-row>
                     
                     <v-row  v-if="tab.Name !== 'Parallax' && tab.Name !== 'Clipped Tab' && !tab.DataViewName && tab.Type !== '3D Carousel' && tab.Type !== 'Magazine Style'"        
                        :class="tab.AnimationKeyframesObject ? 'keyframelement mb-6 '+tab.Justify : 'mb-6 '+tab.Justify"
                        no-gutters :id="tab.ElementID+'_Tab'"
                        :style="tab.AnimationCSS && tab.AnimationCSS.Tab && tab.Animations.Tab && tab.AnimationsActive ? tab.CustomStyle+tab.AnimationCSS.Tab : tab.CustomStyle"
                       
                        >        
                        <!-- :style="tab.Name === 'Card Row' ? '' : 'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'" -->          
                        <v-col
                            v-for="(elmnt,index) in tab.Elements"
                            :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                            cols="12"    
                            :class="elmnt.Justify+' '+ColMY(elmnt)"  
                            :style="elmnt.Alignment ? 
                            'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment : 
                            'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+tab.Alignment"                      
                        >    
                        <!-- text,gradient,line -->
                        <v-card-title class="mediumoverline" v-if="IsDefaultSocialPage && elmnt.DisplayName" :style="SocialPageHeaderStyle">
                                {{elmnt.DisplayName}} 
                        </v-card-title>
                         <v-card-title class="mediumoverline" v-if="elmnt.Title && elmnt.SocialComponent || elmnt.Title && elmnt.Name === 'RSS Feed'" :style="HeaderFontFamily(elmnt,elmnt.HeaderFontFamily)">
                                {{elmnt.Title}} 
                        </v-card-title>		
                         <TimelineTab 
                            v-if="elmnt.Name === 'Timeline' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                            
                            <ImageTab v-if="elmnt.Name === 'Image' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                            />
                            <RSSFeedTab 
                            v-if="elmnt.Name === 'RSS Feed' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />

                            <SystemActivitiesFeed 
                            v-if="elmnt.Name === 'System Activities' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :System="System"
                             class="mx-3" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Filter="''"
                            />
                            <SocialSitePosts 
                            v-if="elmnt.Name === 'Social Site Posts' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :System="System"
                            :UserObj="userLoggedIn" :AppisDarkMode="AppisDarkMode" class="mx-3" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" 
                            :UserRecord="userLoggedIn" :UserID="userLoggedIn.id" :UserView="false" :Slice="10"
                            />                             
                             <SiteArticleFeed 
                            v-if="elmnt.Name === 'Site Articles' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />
                             <DirectoryViewer style="padding-left: 10px;padding-right:10px;" v-if="elmnt.Name === 'Site Groups' && elmnt.ShowComponents" :ProvidedAdditionalFilters="elmnt.CatNavList.AdditionalFilters"
                            :ProvidedNavList="elmnt.CatNavList.NavList" :ProvidedCurrentEntity="elmnt.CatNavList"
                            @ActivateTreeNode="ActivateTreeNode" :ProvidedFeatureList="elmnt.CatNavList.FeatureList" :style="FinalPageStyling"
                            />
                            <SiteBlogsFeed 
                            v-if="elmnt.Name === 'Site Blogs' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />                            
                            <SiteEventsFeed 
                            v-if="elmnt.Name === 'Site Events' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :FeedView="true" :AppisDarkMode="AppisDarkMode" :System="System"
                            />
                            <SiteMeetupsFeed 
                            v-if="elmnt.Name === 'Site Meetups' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :AppisDarkMode="AppisDarkMode" :System="System"
                            :SliderView="elmnt.SliderView ? true : false" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="elmnt.Excerpt ? elmnt.Excerpt : 250" :Slice="elmnt.Slice ? elmnt.Slice : 6" :Filter="''"
                            />
                            <NoticeboardFeed 
                            v-if="elmnt.Name === 'Notice Board' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SliderView="elmnt.SliderView ? true : false" 
                             style="padding:15px;" :FeedView="true" :AppisDarkMode="AppisDarkMode" :System="System"
                            />
                            <DigitalBusinessCards 
                            v-if="elmnt.Name === 'Business Members' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :SliderView="elmnt.SliderView ? true : false"
                             :AppisDarkMode="AppisDarkMode" :System="System"
                            />
                            
                            <InfoCardTab 
                            v-if="elmnt.Name === 'Info Card' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :DefaultPageFont="DefaultPageFont"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <CardLinkTab 
                            v-if="elmnt.Name === 'Card Link' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                        <TitleTab 
                            v-if="elmnt.Name === 'Title' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                           <CarouselTab 
                            v-if="elmnt.Name === 'Carousel' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            /> 
                            <DescriptionList 
                            v-if="elmnt.Name === 'Description List' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            /> 
                            <FAQ 
                            v-if="elmnt.Name === 'FAQ' && elmnt.ShowComponents && elmnt.SelectedFAQ.length>0" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <HTMLTable
                            v-if="elmnt.Name === 'HTML Table' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <DocumentsTableTab 
                            v-if="elmnt.Name === 'Documents Table' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <IFRAMETab  v-if="elmnt.Name === 'IFRAME' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                                :PageHeadersFontSize="PageHeadersFontSize" :tab="elmnt" @OpenDialog="OpenDialog" :i="i"
                                    />
                        <TableEmbedTab							
                v-if="elmnt.Name === 'Table Embed' && elmnt.Entityid && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"	
                @RefreshComponent="RefreshComponent"								
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :CurrentEntity="CurrentEntity" :RADB="RADB" :System="System"		
                :SystemEntities="ComputedSystemEntities" :RelatedObj="RelatedObj" :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="EntityDataRef"	@ActivateProcessing="ActivateProcessing"				
                :tab="elmnt" @OpenDialog="OpenDialog" :ParentTab="tab" :DynamicWikiData="DynamicWikiData" :SampleRecords="SampleRecords" @ActivateNotificationSnackbar="ActivateNotificationSnackbar"								
                />
                            <WikiCollection 									
                            v-if="elmnt.Name === 'Wiki Collection' && elmnt.ShowComponents && elmnt.Entityid" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex" :WikiEntities="WikiEntities"								
                            :tab="elmnt" @OpenDialog="OpenDialog" :Record="DynamicWikiData"								
                            />	
                            <GoogleMyMapsTab 
                            v-if="elmnt.Name === 'Google My Maps' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <HTMLBox 
                            v-if="elmnt.Name === 'HTML Box' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName"
                            />
                            <InfoSheet 									
                            v-if="elmnt.Name === 'Info Sheet' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                            :tab="elmnt" @OpenDialog="OpenDialog"									
                            />	
                            <WebFormTab v-if="elmnt.Name === 'Web Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                             :DynamicWikiData="DynamicWikiData" :MappedUserFieldStayHidden="elmnt.HideUserInfoFields && userLoggedIn"
                            :tab="elmnt" :i="i"
                            />
                            <SurveyForm v-if="elmnt.Name === 'Survey Form' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                             :DynamicWikiData="DynamicWikiData"
                            :tab="elmnt" :i="i"
                            />
                            <CardRowTab 
                            v-if="elmnt.Name === 'Card Row Single' && elmnt.ShowComponents && tab.Type !== '3D Carousel' && tab.Type !== 'Magazine Style'" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog" :PageID="ActivePageID" :PageName="PageName" :System="System"
                            />
                           <CardBlocksTab 
                            v-if="elmnt.Name === 'Card Block Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <CounterTab 
                            v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <TestimonialTab 
                            v-if="elmnt.Name === 'Testimonials' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            <StoreProductTab 
                            v-if="elmnt.Name === 'Store Product Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                            :tab="elmnt" @OpenDialog="OpenDialog"
                            />
                            
                        </v-col>
                       
                     </v-row>
                     <!-- <div v-if="tab.Name === 'Card Blocks'">

                                        <v-container class="pa-1">
                                            <v-row>
                                            <v-col
                                                v-for="(item, i) in tab.Elements"
                                                :key="i"
                                                cols="3"
                                                md="3"
                                            >
                                            </v-col>
                                            </v-row>
                                        </v-container>
                            </div> -->
                      <CardCarouselTab  v-if="tab.Name === 'Card Row' && tab.Type === '3D Carousel' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                                @DeleteTabAsset="DeleteTabAsset" :System="System" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                                />
                                <CardRowMagazine  v-if="tab.Name === 'Card Row' && tab.Type === 'Magazine Style' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :tabindex="i"
                        @DeleteTabAsset="DeleteTabAsset" :System="System" :style="'margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px;'"
                        />
                    <GalleryTab  v-if="tab.Name === 'Gallery' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                            :PageHeadersFontSize="PageHeadersFontSize" :tab="tab" @OpenDialog="OpenDialog" :i="i"
                                @DeleteTabAsset="DeleteTabAsset" :System="System"
                                />
                        
                   <ParallaxTab v-if="tab.Name === 'Parallax' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize" @CheckifClipped="CheckifClipped" @OpenContentTypesDialog="OpenContentTypesDialog"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i" :ActivePage="PageData" :System="System"
                    />
                 
                  
                    <ClippedTab 
                    v-if="tab.Name === 'Clipped Tab' && tab.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                    :PageHeadersFontSize="PageHeadersFontSize"
                    :tab="tab" @OpenDialog="OpenDialog" :i="i"
                    />
                    
                      </div>
                     
                      
                            </v-layout>    
                </v-card>
                <!-- DynamicWikiData.id {{$route.params.id}} -->
                <!-- DynamicWikiData {{DynamicWikiData}} -->
                <!-- <SiteArticleFeed v-if="DynamicWikiData.id" :TagFilter="true" :TagsID="DynamicWikiData.id" :SliderView="true" :UserRecord="userLoggedIn" :Origin="'Page'" :Excerpt="250" :Slice="20" :Filter="InthePublicFilter" /> -->
                <v-layout v-if="$route.name === 'landingpagesingle' || $route.meta && $route.meta.ClassifiedListingBuilder || WikiMode" column class="fab-container">
                    <v-btn v-if="PublishReadyClassifiedType" icon x-large @click="PublishClassifiedRecord()"><v-icon  color="blue" x-large>mdi-publish</v-icon></v-btn>
                    <v-btn v-if="PublishReadyWikiType" icon x-large @click="PublishWikiRecord()"><v-icon  color="blue" x-large>mdi-publish</v-icon></v-btn>
                    <v-btn v-if="userCanEdit" icon x-large @click="NavigatetoEditor()"><v-icon  color="orange" x-large>mdi-pencil</v-icon></v-btn>
                   
                <v-btn  icon x-large @click="ToggleMenu()"><v-icon  color="green" x-large>mdi-home</v-icon></v-btn>
            </v-layout>
             </div>
    </div>
</div>
</template>

<script>

import BannerAdsCycle from '@/components/WebPages/BannerAdsCycle'
import AppValidatorAlerts from '@/components/SuiteBuilder/Support/AppValidatorAlerts'
import DirectoryViewer from '@/components/Directories/DirectoryViewer';
import DataSingleStandard from '@/components/Database/DataSingleStandard.vue';
import DataSingleCabinet from '@/components/Database/DataSingleCabinet.vue';
import TableEmbedTab from '@/components/WebPages/RenderComponents/TableEmbedTab';	
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import TimelineTab from '@/components/WebPages/RenderComponents/TimelineTabReadOnly';
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import SurveyForm from '@/components/WebPages/RenderComponents/SurveyForm';

import InfoCardTab from '@/components/WebPages/RenderComponents/InfoCardTabReadOnly';
import CardLinkTab from '@/components/WebPages/RenderComponents/CardLinkTab';

import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';
import SiteArticleFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import SiteBlogsFeed from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';
import SiteEventsFeed from '@/components/SuitePlugins/Events/SiteEvents';
import SiteMeetupsFeed from '@/components/SuitePlugins/Meetups/SiteMeetupsFeed';
import NoticeboardFeed from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeboardFeed';
import DigitalBusinessCards from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCards';

import RSSFeedTab from '@/components/WebPages/RenderComponents/RSSFeedTab';

import CarouselTab from '@/components/WebPages/RenderComponents/CarouselTab';

import HTMLTable from '@/components/WebPages/RenderComponents/HTMLTable';
import DocumentsTableTab from '@/components/WebPages/RenderComponents/DocumentsTableTab';
import DescriptionList from '@/components/WebPages/RenderComponents/DescriptionList';
import FAQ from '@/components/WebPages/RenderComponents/FAQ';	
import TitleTab from '@/components/WebPages/RenderComponents/TitleTabReadOnly';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
import StoreProductTab from '@/components/WebPages/RenderComponents/StoreProductTabReadOnlyOLD';
import TestimonialTab from '@/components/WebPages/RenderComponents/TestimonialTabReadOnly';
import CardBlocksTab from '@/components/WebPages/RenderComponents/CardBlocksTabReadOnly';
import CardRowTab from '@/components/WebPages/RenderComponents/CardRowTabReadOnly';
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';	
import HTMLBox from '@/components/WebPages/RenderComponents/HTMLBoxReadOnly';
import ImageTab from '@/components/WebPages/RenderComponents/ImageTabReadOnly';
import ParallaxTab from '@/components/WebPages/RenderComponents/ParallaxTabReadOnly';
import ClippedTab from '@/components/WebPages/RenderComponents/ClippedTabReadOnly';
import IFRAMETab from '@/components/WebPages/RenderComponents/IFRAMETab';
import GalleryTab from '@/components/WebPages/RenderComponents/GalleryTabReadOnly';
import CardCarouselTab from '@/components/WebPages/RenderComponents/CardCarouselTabReadOnly';
import CardRowMagazine from '@/components/WebPages/RenderComponents/CardRowMagazineReadOnly';
import GoogleMyMapsTab from '@/components/WebPages/RenderComponents/GoogleMyMapsTab';
import WikiCollection from '@/components/WebPages/RenderComponents/WikiCollectionReadOnly';
export default {
    props: ['LoadedPageBGGradient','LoadedPageBGAsset','LoadedPageBGColor','DocumentationLibraries',
        'LoadedPageName','LoadedPageRoute','LoadedPageIcon','LoadedPageOverview','LoadedPublishType','LoadedPageContentFont','LoadedPageHeadersFontSize','LoadedPageHeadersFont','clientDB','SystemGuides','RADB','RAApp',
        'SingleFormEditing','DataSingleTabs', 'CategoricalNavlists','FrequentlyAskedQuestions' ,       
        'SinglePageBGGradient','EntityDataRef','EntityCollectionRef','CurrentEntity','CartItems',
        'System','AdditionalSaveMethod','SingleElementView','GuestNavbarView',
        'SystemEntities','AppisDarkMode','CanEdit','tab','DynamicWikiData','Business_Unit',
        'RelatedObj','AppointmentsQuery','TasksQuery','EmailsQuery','NotesQuery','PhoneCallsQuery','ActivitiesArray','PrimaryFieldName',        
        'SingleRecordNavMenu','SingleRecordNavMenuItem','SampleRecords','SystemPhotoLibraries','FeatureNavList','ClsfdNavList','WikiEntities'
    ],
    components: {
        BannerAdsCycle,
        AppValidatorAlerts,
        DirectoryViewer,
        TimelineTab,
        WebFormTab,
        SurveyForm,
        CarouselTab,
        HTMLTable,
        DocumentsTableTab,
        DescriptionList,
        FAQ,
        TitleTab,
        InfoCardTab,
        CardLinkTab,
        SiteArticleFeed,
        SiteBlogsFeed,
        SystemActivitiesFeed,
        SocialSitePosts,
        SiteEventsFeed,
        SiteMeetupsFeed,
        NoticeboardFeed,
        DigitalBusinessCards,
        RSSFeedTab,
        InfoSheet,
        HTMLBox,
        CardBlocksTab,
        CardRowTab,
        CounterTab,
        StoreProductTab,
        TestimonialTab,
        ImageTab,
        ParallaxTab,
        ClippedTab,        
        GalleryTab,
        CardCarouselTab,
        CardRowMagazine,
        IFRAMETab,
        GoogleMyMapsTab,
        WikiCollection,
        DataSingleStandard,
        DataSingleCabinet,
        TableEmbedTab
        },
    data() {
    return {  
        SVGPaths: ['TopWave1Path','TopWave2Path','TopWave3Path','TopWave4Path','BottomWave1Path','BottomWave2Path','BottomWave3Path','BottomWave4Path'],
        BannerDialog: false,
        ShowSystemActivities: false,
        ShowDocLibMenu: false,
        mustreadnotices: [],
        noticestep: 0,
        MustReadNoticeDialog: false,
        RelevantTopBannerAds: [],
        ExcludedBannerAds: [],
        PageBGGradient: '',
        PageBGAsset: '',
        PageBGColor: '',
        CatNavList: '',
        PageName: '',
        PageContentFont: 'Montserrat',
        PageHeadersFontSize: 42,
        PageHeadersFont: 'Raleway',
        PageData: '',
      PageTabs: [],
        PageCode: '',
        UserRecord: '',
        PageID: '',
        rules: {
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
                const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
                return urlpattern.test(value) || "Invalid Link.";
            }
            },
            

            }
    }, 

    computed:{
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        CanSocial(){
            return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
            this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
        },
        UserCompanyid(){
            return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
        },
        NonGroupRoute(){
            return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
        },
        CompanyOnlyFilter(){
            return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
        },
        PageOverlay(){
            return this.PageData.PageOverlay ? this.PageData.PageOverlay : ''
        },
        PageCustomStyle(){
            return this.PageData.PageCustomStyle ? this.PageData.PageCustomStyle : ''
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        PageRouteStyling(){
            if(this.DocLibEditor){
                return 'padding-bottom: 80px;'
            }
            else if(this.GuestNavbarView){
                return 'height: 100%;width: 100%;'
            }
            else if(this.ActiveSuiteApp){
                // margin-right: 100px;
                return ''
            }
            else {
                //with footer we needed 'padding-bottom:80px;'
                return this.$route.name === 'GroupPage' && !this.userLoggedIn || this.CatNavList && this.CatNavList.id ? '' : ''
            }
        },
        CanEditDocLib(){
            return this.ActiveDocumentationLibrary && !this.DocLibisRADB && this.userIsAdmin
            || this.ActiveDocumentationLibrary && this.DocLibisRADB && this.RAAdmin
        },
        RAAdmin(){
            return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
        },
        RAUserObj(){
            return this.$store.state.RAUserObj
        },
        RADB(){
            return this.$store.state.RADB
        },
        DocLibisRADB(){
            return this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.Database && this.ActiveDocumentationLibrary.Database === this.RADB
        },
        PageContentClass(){
            if(!this.DocLibEditor){
                return this.IsDefaultSocialPage? 'transparent layout row' : 'transparent'
            }
            else{
                return 'transparent layout row fill-height'
            }
        },
        DocLibEditor(){
            return this.$route.name === 'DocumentationLibrary'
        },
        DocumentationLibraryObj(){
            let doclibobj = this.DocumentationLibraries.find(obj => obj.id === this.$route.params.id)
            if(this.DocLibEditor && doclibobj){
                return doclibobj
            }
        },
        ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
        ActiveDocumentationLibraryPages(){
            return this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.Pages ? this.ActiveDocumentationLibrary.Pages : []
        },
        RouteQuery(){
        return this.$route.query
        },
        RouteQueries(){
            let arr = []
            if(this.RouteQuery){
            for(var prop in this.RouteQuery) {
                arr.push({Prop: prop,Value: this.RouteQuery[prop]})
            }
            }
            return arr
        },
        FilteredUnClosedRelevantTopBannerAds(){
            return this.FilteredRelevantTopBannerAds.filter(ad => {
                return !this.ExcludedBannerAds.includes(ad.id) || this.RouteQueries === ad.RouteQueries
                //I guess we could also filter out ads that have a query, that match the current query...the aboe does not work you cnanot check array this wayy but the sidenote
            })
            
        },
        FilteredRelevantTopBannerAds(){
            return this.RelevantTopBannerAds.filter(ad => {
                return ad
                //not filtering dialog here
            })
            
        },
        HaveBannerAds(){
            return this.FilteredRelevantTopBannerAds && this.FilteredRelevantTopBannerAds.length > 0
        },
        ViewBannerAdsSTD(){
            return this.HaveBannerAds && !this.CatNavList
        },
        ViewBannerAdsPopup(){
            return this.HaveBannerAds && this.CatNavList
        },
        CloseBannerAdsPopup(){
            return this.FilteredRelevantTopBannerAds.length === 0
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
        ComputedSystemEntities(){
            return this.$route.name === 'GroupPageEditor' || this.$route.name === 'GroupPage' ? this.GroupEntities.filter(entity => entity.groupid === this.$route.params.id) : this.SystemEntities
        },
        ActivePageID(){
            if(this.$route.meta){
                if(this.$route.meta.RouteEventID === 1027 || this.$route.meta.RouteEventID === 1028){
                    return this.$route.meta.PageID
                }
                else if(this.$route.meta.RouteEventID === 1032){
                    return this.PageID ? this.PageID : this.$route.params.id
                }
            }
        },
        SocialHeaderGradient(){
            return this.System.SocialHeaderGradient ? this.System.SocialHeaderGradient : 'background: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));'
        },
        SocialHeaderFont(){
            return this.System.SocialHeaderFont ? this.System.SocialHeaderFont : {hex: '#adff2f'}
        },
        SocialHeaderBorder(){
            return this.System.SocialHeaderBorder ? this.System.SocialHeaderBorder : {hex: '#adff2f'}
        },
        SocialPageHeaderStyle(){
            return 'color: '+this.SocialHeaderFont.hex+';border-top: 5px solid '+this.SocialHeaderBorder.hex+';'+this.SocialHeaderGradient
        },
        IsDefaultSocialPage(){
            return this.System.Social_Landing_Page === this.$route.path
        },
        XSBreakPoint(){
        return this.$vuetify.breakpoint.xs
      },
      currentNoticeTitle () {
        if(this.mustreadnotices.length > 0 && this.noticestep){
          //console.log(this.mustreadnotices)
          return this.mustreadnotices[this.noticestep].title
        }
        
      },
        DefaultPageFont(){
            return `font-family: "`+this.PageContentFont+`", sans-serif;`
        },
        FinalPageStyling(){
            let obj = Object.assign({},this.PageStyling)
            //console.log(obj)
            if(this.$route.meta && this.$route.meta.RouteEventID === 1028 || this.$route.name === 'PageEditor'){
                if(this.CatNavList && this.CatNavList.id){
                    obj.paddingBottom = '110px'
                }
                else{
                    obj.paddingBottom = '50px'
                }
            }
            if(this.ShowDocLibMenu){
                return {...obj,
                height: '100%'}
                //return obj
            }
            else{
                return obj
            }
        },
        PageStyling(){
            if(this.PageBGAsset && this.PageBGGradient){
                return { backgroundImage: this.PageBGGradient.split('background: ')[1].split(';')[0]+`,url('`+this.PageBGAsset+`')`,backgroundSize: `cover`}
            }
            else if(this.PageBGAsset && !this.PageBGGradient){
                return { backgroundImage: `url('`+this.PageBGAsset+`')`,backgroundSize: `cover` }
            }
            else if(this.PageBGGradient){
                return { backgroundImage: this.PageBGGradient.split('background: ')[1].split(';')[0]}
            }
            else if(this.PageBGColor){
                return { backgroundColor: this.PageBGColor+ `!important`}
            }
            else{
                return ''
            }
        },
        StoreSystemObj(){
            return this.$store.state.SystemObj
        },
        ActiveDB(){
            return this.clientDB ? this.clientDB : db
        },
        AnitmatedElements(){
            let array = []
            if(!this.DataSingleBuilder){
            let animatedrows = this.ComputedPageTabs.filter(tab => {
                return tab.AnimationsActive
            })
            let animatedelements = this.ComputedPageTabs.map(tab => {
                return tab.Elements
            }).flat().filter(elmnt => {
                return elmnt.AnimationsActive
            })
            array = animatedrows.concat(animatedelements)
            }
            return array
        },
        ComputedPageTabs(){
            return this.PageTabs.map((tabobj,tabindex) => {
                let tab = Object.assign({},tabobj)
                tab.ElementID = tabindex
                if(!this.DataSingleBuilder){
                if(tab.AnimationKeyframesObject){
                    //console.log(tab.AnimationKeyframesObject)
                }
                 let toppaths = this.SVGPaths.filter(path => {
                    return path.includes('Top')
                })
                let bottompaths = this.SVGPaths.filter(path => {
                    return path.includes('Bottom')
                })
                let svgtypes = ['<path','<polygon','<circle','<text','<rect','<g','<img ','<symbol','<elipse','<svg']
                if(tab.HasTopWaves){
                    tab.TopPathsHTML = ``
                    toppaths.map(pth => {
                        let svgtype = ''
                        svgtypes.map(type => {
                            if(tab[pth] && tab[pth].includes(type)){
                                svgtype = type
                            }
                        })
                        tab.TopPathsHTML = tab.TopPathsHTML+tab[pth].replace(svgtype,svgtype+' id="'+tabindex+'_'+pth+'"')
                    })
                    if(!tab.TopWave1Still){
                        tab.TopWave1Style = 'animation: move-forever4 '+tab.TopWave1Speed+' linear infinite;'
                    }
                    else{
                        tab.TopWave1Style = ''
                    }
                    if(!tab.TopWave2Still){
                        tab.TopWave2Style = 'animation: move-forever3 '+tab.TopWave2Speed+' linear infinite;'
                    }                    
                    else{
                        tab.TopWave2Style = ''
                    }
                    if(!tab.TopWave3Still){
                        tab.TopWave3Style = 'animation: move-forever2 '+tab.TopWave3Speed+' linear infinite;'
                    }
                    else{
                        tab.TopWave3Style = ''
                    }
                    if(!tab.TopWave4Still){
                        tab.TopWave4Style = 'animation: move-forever1 '+tab.TopWave4Speed+' linear infinite;'
                    }
                    else{
                        tab.TopWave4Style = ''
                    }
                }
                if(tab.HasBottomWaves){
                    tab.BottomPathsHTML = ``
                    bottompaths.map(pth => {
                        let svgtype = ''
                        svgtypes.map(type => {
                            if(tab[pth] && tab[pth].includes(type)){
                                svgtype = type
                            }
                        })
                        tab.BottomPathsHTML = tab.BottomPathsHTML+tab[pth].replace(svgtype,svgtype+' id="'+tabindex+'_'+pth+'"')
                    })
                    if(!tab.BottomWave1Still){
                        tab.BottomWave1Style = 'animation: move-forever4 '+tab.BottomWave1Speed+' linear infinite;'
                    }
                    else{
                        tab.BottomWave1Style = ''
                    }
                    if(!tab.BottomWave2Still){
                        tab.BottomWave2Style = 'animation: move-forever3 '+tab.BottomWave2Speed+' linear infinite;'
                    }                    
                    else{
                        tab.BottomWave2Style = ''
                    }
                    if(!tab.BottomWave3Still){
                        tab.BottomWave3Style = 'animation: move-forever2 '+tab.BottomWave3Speed+' linear infinite;'
                    }                    
                    else{
                        tab.BottomWave3Style = ''
                    }
                    if(!tab.BottomWave4Still){
                        tab.BottomWave4Style = 'animation: move-forever1 '+tab.BottomWave4Speed+' linear infinite;'
                    }                    
                    else{
                        tab.BottomWave4Style = ''
                    }
                }
                if(tab.Name === 'Card Row'){
                    //alert(tab.AlternateSlideIn)
                     if(typeof tab.TitleSize === 'undefined'){
                        tab.TitleSize = 2
                        }
                    if(tab.AlternateSlideIn){
                      tab.Elements =    tab.Elements.map((elmnt,elmntindex) => {
                        if(this.isOdd(elmntindex)){
                            elmnt.CustomClass = 'ScrollRevealLeftWithReverse'
                        }
                        else{
                            elmnt.CustomClass = 'ScrollRevealRightWithReverse'
                        }
                        return elmnt
                        })  
                    }
                    let props = ['IMGonTop','TiledIMG','FullIMG','IMGHeight','FlipCard','LabelGradient','LabelBGColor','ContainIMG','TitleSize']
                    tab.Elements = tab.Elements.map(elmnt => {
                        props.map(prp => {
                            if(typeof tab[prp] !== 'undefined'){
                                elmnt[prp] = tab[prp]
                            }
                        })
                        if(tab.ClearBoxes){
                            elmnt.Elevation = 0
                            elmnt.BoxTransparency = 'transparent'
                        }
                        return elmnt
                    })
                    
                }
                else if(tab.Name === 'blank'){
                    tab.Elements = tab.Elements.filter(elmnt => {
                        if(elmnt.Name === 'Social Site Posts' && !this.System.Disable_Guest_Posts){
                            return !this.userLoggedIn.IsGuestUser
                        }
                        else{
                            return elmnt
                        }
                    })
                }
                }
                if(tab.Elements){
                    tab.Elements = tab.Elements.map((elmnt,elmntindex) => {
                        elmnt.ElementID = tabindex+'_'+elmntindex
                        //console.log(elmnt.ElementID)
                        return elmnt
                    })
                }
                return tab
            })
        },
        PageTabsReady(){
            return this.PageTabs && this.PageTabs.length > 0
        },
        PageClass(){
            if(this.$route.meta && this.$route.meta.RouteEventID === 1028){
                return 'v-main__wrap transparent'
            }
            else if(this.GuestNavbarView){
                return 'v-main__wrap'
            }
            else if(this.$route.name === 'MyAccount'){
                return ''
            }
            else if(this.SingleElementView){
                return 'background'
            }
            else{
                return 'v-main__wrap'
            }
        },
    DataSingleBuilder(){
        return this.$route.meta && this.$route.meta.DataSingleBuilder
    },
    PageTabsPropName(){
        return this.DataSingleBuilder? 'SingleBuildTabs' : 'PageTabs'
    },
      userLoggedIn () {
      return this.$store.getters.user
    },
    userIsAdmin () {
        return this.$store.state.IsAdmin
    },
    PublishReadyWikiType(){
        return this.userCanEdit && this.WikiMode
    },
    PublishReadyClassifiedType(){
        return this.userCanEdit && this.$route.meta && this.$route.meta.ClassifiedListingBuilder && this.ClsfdNavList && this.ClsfdNavList.CombinedTitleMap
    },
    userCanEdit(){
        if(this.$route.name === 'landingpagesingle' || this.$route.meta && this.$route.meta.RouteEventID === 1028 || this.$route.meta && this.$route.meta.RouteEventID === 1027){
            return this.userIsAdmin || this.userisCreator
        }
        else if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder){
            return this.UserisClassifiedModerator
        }  
        else if(this.WikiMode){
            return this.userIsWikiModerator || this.userIsAdmin
        }  
    },
    WikiMode(){
            return this.$route && this.$route.meta.WikiListingBuilder
        },
    userIsWikiModerator () {
    if(this.UserRecord.ModerationPermissions){
      let match = this.UserRecord.ModerationPermissions.find(obj => obj === 'Wiki')
      if(match){
          return true
        }
      else{
      return this.userIsAdmin
      }
    }
    else{
      return this.userIsAdmin
    }

  },
    UserisClassifiedModerator(){
        //for now admin only
        return this.userIsAdmin || this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.find(obj => obj === this.CurrentEntity.id.split('_').join(' ')+' Classified Listings')
    },
    userIsCreator(){
        return ''
    },
    RevealClassMax(){
        return window.innerWidth/2
      },
    WindowHeight(){
        return window.innerHeight
      },
      RouteObj(){
            return this.$route
        }
      
      
      

    },
    watch: {
        ViewBannerAdsPopup(v){
            this.BannerDialog = true
        },
        CloseBannerAdsPopup(v){
            if(this.BannerDialog === true && this.FilteredRelevantTopBannerAds.length === 0){
            this.BannerDialog = false    
            }
            
            //actually, child component knows it's routequery, in routequery push funciton before push route push to parent the "filterid", which relevant topbanners need to exclude...
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['PublishClassifiedRecord','PublishWikiRecord']   
        if(v && acceptedmethods.includes(v) && this.$route.meta && this.$route.meta.ClassifiedListingBuilder ||
        v && acceptedmethods.includes(v) && this.WikiMode){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        XSBreakPoint (value) {
        if (value === false) {
          this.ShowSystemActivities = true
          this.ShowDocLibMenu = true
        }
        else{
          this.ShowSystemActivities = false
          this.ShowDocLibMenu = false
        }
      },
        mustreadnotices (value) {
        if (value.length>0) {
            this.MustReadNoticeDialog = true
        }
        },
        ActiveDocumentationLibraryPages : {
            handler: function(newvalue, oldvalue) {
                if(newvalue !== oldvalue){
                    let pageobj = this.ActiveDocumentationLibrary.Pages.find(obj => obj.id === this.$route.params.slug.split(' ').join('_').split('-').join('_'))
                       if(pageobj){
                        this.AssignPageProperties(pageobj)   
                       }
                }
            },deep: true
        },
        StoreSystemObj : {
            handler: function(newvalue, oldvalue) {
            // if(this.$route.name !== 'landingpagesingle' && this.$route.meta && this.$route.meta.RouteEventID === 1028 && this.$route.meta && this.$route.meta.RouteEventID === 1027){
            // }
            if(newvalue.AppDefaultHeader){
            this.PageHeadersFont = newvalue.AppDefaultHeader.Name
            this.PageHeadersFontSize = newvalue.AppDefaultHeader.SizeMultiplier
            }
            if(newvalue.AppDefaultBody){
            this.PageContentFont = newvalue.AppDefaultBody.Name
            }             
              
            },
            deep: true
    },
        PageTabsReady (value){
            if(value){
                this.PrepAnimations()
            }
        },          
         AnitmatedElements: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                    setTimeout(() => {
                     this.RunWebAnimations()   
                    }, 1500);
                    
                }
            },deep: true
    },
        RouteObj: {
            handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                    //hoping this be triggered from PageTabsReady comp this.RunWebAnimations()
                    this.CatNavList = ''
                    if(this.$route.name !== 'landingpagesingle'){
                        this.$emit('LandingPageView',false)
                    }
                    this.ScrolltoTop()
                if(this.$route.name !== 'DocumentationLibrary'
                    || this.$route.name === 'DocumentationLibrary' && !this.ActiveDocumentationLibrary
                    || this.$route.name === 'DocumentationLibrary' && this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.id !== this.$route.params.id){
                    this.RelevantTopBannerAds = []
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    this.PageBGAsset = ''
                    this.PageBGGradient = ''
                    this.PageBGColor = ''
                    this.PageTabs = []
                    this.CatNavList = ''
                  //console.log(oldvalue, newvalue)
                  this.FocusedViewToggle()
                  this.CheckUser()
                    }
                    else if(this.$route.name === 'DocumentationLibrary' && this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.id === this.$route.params.id){
                       let pageobj = this.ActiveDocumentationLibrary.Pages.find(obj => obj.id === this.$route.params.slug.split(' ').join('_').split('-').join('_'))
                       if(pageobj){
                        this.AssignPageProperties(pageobj)   
                       }
                        
                    }
                }
            },
            deep: true
        },
    },
    
    created(){
        //i+CustomStyleElmnt    
       
        if(this.$route.name !== 'SystemConfig'){
            //this.$store.commit('setSiteDialog',false)
            this.$store.commit('setBuilderView','')   
        }
        this.ScrolltoTop()
        
        //alert('SingleRecordNavMenu '+this.SingleRecordNavMenu)
        if(!this.XSBreakPoint && this.IsDefaultSocialPage && this.userLoggedIn){
            this.ShowSystemActivities = true
        }
        else if(!this.XSBreakPoint && this.DocLibEditor){
            this.ShowDocLibMenu = true
        }
        if(!this.DataSingleBuilder){
             document.body.scrollTop = 0;
                document.documentElement.scrollTop = 0;
        }
      
    //   window.onload = this.PrepAnimations()
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      //this.IntranetViewToggle()
      //console.log(this.$route)
      this.CheckUser()
      //console.log('this.AppointmentsQuery',this.AppointmentsQuery)
      //console.log('this.DynamicWikiData',this.DynamicWikiData,this.DataSingleTabs)
    
    },
    
    methods: {
        RunWebAnimations(){
            let Elements = this.AnitmatedElements
            //console.log(Elements)
            Elements.map((elmnt,elmntindex) => {
                let propoptions = ['Tab','Title','Description','IMG']
                propoptions.map(opt => {   
                     let e = document.getElementById(elmnt.ElementID+'_'+opt)
                    if(elmnt.AnimationKeyframesObject[opt] && elmnt.Animations[opt] && e){
                        let elmntkeyframes = []
                        let eanimobj = Object.assign({},elmnt.AnimationKeyframesObject[opt])
                        let animprops = {}
                        let cssprop = elmnt.AnimationProperties[opt]
                        // console.log(cssprop)
                        if(typeof cssprop.duration !== 'undefined'){
                            animprops.duration = cssprop.duration
                        }
                        if(typeof cssprop.fill !== 'undefined' && cssprop.fill !== null){
                            animprops.fill = cssprop.fill
                        }
                        if(typeof cssprop.easing !== 'undefined' && cssprop.easing !== null){
                            animprops.easing = cssprop.easing
                        }
                         if(typeof cssprop.iterations !== 'undefined'){
                            animprops.iterations = cssprop.iterations
                        }
                        Object.keys(eanimobj).forEach(function(prop,index) {
                            if(prop.includes('%')){
                                    //okay the point being now we must dissect this
                                    let perc = (Number(prop.split('%').join('')))/100
                                    if(!elmntkeyframes[index]){
                                        elmntkeyframes[index] = {}
                                    }
                                    elmntkeyframes[index].iterationStart = perc
                                }
                                for(var prp in eanimobj[prop]) {	                                    
                                    if (eanimobj[prop].hasOwnProperty(prp)) {
                                        if(!elmntkeyframes[index]){
                                            elmntkeyframes[index] = {}
                                        }
                                        elmntkeyframes[index][prp] = eanimobj[prop][prp]
                                    }
                                }
                        });
                        elmntkeyframes = elmntkeyframes.sort(function(a, b){return a.iterationStart - b.iterationStart})
                        //console.log(elmntkeyframes,e)
                        let animobj = new KeyframeEffect(
                        e, // element to animate
                        elmntkeyframes,
                        animprops // keyframe options
                        );
                        let newanim = new Animation(animobj, document.timeline)
                        newanim.play()     
                    }
                    
                        })
            })
        },
        TabWavesStyle(tab){
            let style = ``
            if(tab.WavesGradient){
               style = `clip-path: url(#svgPath);
                        background-size: cover;z-index:4 !important;padding: 0;position:absolute;width:100%;text-align:center;
                        `+tab.WavesGradient+`color:white;`
            }
            else{
                style = `clip-path: url(#svgPath);
                        background-size: cover;z-index:4 !important;padding: 0;position:absolute;width:100%;text-align:center;
                        background: linear-gradient(60deg, rgba(85,58,183,1) 0%, rgba(0,172,193,1) 100%);color:white;`
            }
            if(typeof tab.WaveHeight === 'number'){
                style = style+`height: `+tab.WaveHeight+`%;`
            }
            else{
                style = style+`height: 100%;`
            }
            return style
        },
        GetTabStyle(tab){
            let style = {}
            if(tab.RowBlendMode){
                    style.mixBlendMode = tab.RowBlendMode
                }
            if(tab.Name !== 'Card Row'){
                style.marginTop = tab.MarginTop+'px'
                style.marginBottom = tab.MarginBottom+'px'
                style.marginLeft = tab.MarginLeft+'px'
                style.marginRight = tab.MarginRight+'px'
            }
            if(tab.Floating){
                style.marginRight = 'absolute'
            }
             if(tab.BGIMG && tab.HasBGIMG){
                
                if(tab.HasBGGradient && tab.BGGradient){
                    style.backgroundImage = tab.BGGradient.split('background: ')[1].split(';')[0]+`,url('`+tab.BGIMG+`')`
                    style.backgroundSize = 'cover'
                }
                else{
                    style.backgroundImage = `url('`+tab.BGIMG+`')`
                    style.backgroundSize = 'cover'
                }    
                 if(tab.BGIMGFixed){
                    style.backgroundAttachment = 'fixed'
                    if(this.$vuetify.breakpoint.xs){
                      style.backgroundSize = 'auto'  
                    }                    
                }   
                if(tab.BGIMGCentered){
                    style.backgroundPosition = 'center'
                }             
            }
                return style
        },
        NavigatetoLibraryEditor(doclib){
            let path = '/DocumentationLibraryEditor/'+doclib.id+'/'+doclib.FirstPageName.split(' ').join('-')
            if(this.$route.path !== path){
                this.$router.push(path)
            }
        },
        ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        ActivateLibraryPage(item){
            let newpath = '/Documentation/'+this.$route.params.id+'/'+item.Path.split(' ').join('_').split('-').join('_')
            this.$router.push(newpath)
        },
        CloseBannerDialogItem(item){
            this.ExcludedBannerAds.push(item.id)
            if(this.FilteredUnClosedRelevantTopBannerAds.length === 0){
                this.BannerDialog = false
            }
        },
        CloseBannerDialog(){
            this.BannerDialog = false
        },
        ActivateStoreView(boolean){
      this.$emit('ActivateStoreView',boolean)
    },
        PushActiveProduct(item){
            this.$emit('PushActiveProduct',item)
        },
        RefreshComponent(boolean,tab){
            tab.ShowComponents = false
            setTimeout(() => {
                tab.ShowComponents = true
            }, 100);
        },
        ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      }, 
        isOdd(num) {
            return num % 2;
        },
        PrepAnimations(){
            
        setTimeout(() => {
          this.AnimationScrollRevealSections('ScrollRevealLeftWithReverse','scrollinfromleft',this.WindowHeight,'left','animscrollinfromleft',true,'scrollinfromleftreverse')
          this.AnimationScrollRevealSections('ScrollRevealRightWithReverse','scrollinfromright',this.WindowHeight,'right','animscrollinfromright',true,'scrollinfromrightreverse')
          this.AnimationScrollRevealSections('ScrollRevealLeft','scrollinfromleft',this.WindowHeight,'left','animscrollinfromleft')
          this.AnimationScrollRevealSections('ScrollRevealRight','scrollinfromright',this.WindowHeight,'right','animscrollinfromright')
          this.AnimationScrollRevealSectionsStick('ScrollRevealLeftStick','left',this.RevealClassMax,this.WindowHeight,200)
          this.AnimationScrollRevealSectionsStick('ScrollRevealRightStick','right',this.RevealClassMax,this.WindowHeight,200)
        //   setTimeout(() => {
           
        //   }, 1500);
              
          var docStyle = document.documentElement.style;
          docStyle.setProperty('--WindowWidth', window.innerWidth);
        }, 5);
      },
      AnimationScrollRevealSectionsStick(clssname,cssprop,maxvalue,heighttresh,finalheightpos){
        var Sections = Array.from(document.getElementsByClassName(clssname));
        if(Sections && Sections.length > 0){
         Sections.forEach((e, i) => {        
            e.style[cssprop] = -this.RevealClassMax+'px'
            e.style.visibility = 'hidden'
        })
        document.addEventListener('scroll', function () {
          Sections.forEach((e, i) => {           
          var top = (e.getBoundingClientRect().top);
          if(top < heighttresh && top >= finalheightpos){
            let proppos = ((finalheightpos-top)/finalheightpos*maxvalue)
            e.style[cssprop] = proppos+'px'
          }          
          });
        })   
        }
        
      },
      AnimationScrollRevealSections(clssname,newclssname,heighttresh,cssprop,animation,WithReverse,revclssname){
        var Sections = Array.from(document.getElementsByClassName(clssname));
        //console.log(typeof Sections,Sections)
        if(Sections && Sections.length > 0){
        Sections.forEach((e, i) => {        
            e.style[cssprop] = -this.RevealClassMax+'px'
            e.style.visibility = 'hidden'
        })
        document.addEventListener('scroll', function () {
          Sections.forEach((e, i) => {
          let name = e.style['animation-name']
          let direction = e.style['animation-direction']
          var top = (e.getBoundingClientRect().top);
          //console.log(top,e)
          let showheight = (heighttresh*0.7)
          let hideheight = heighttresh*2
          if (top < showheight) {
            //console.log(top,e)
            e.classList.remove(revclssname)
            if(e.style.visibility === 'hidden'){
             e.style.visibility = 'visible' 
            }            
          if(top !== 0){
            e.classList.remove(revclssname)
            
            setTimeout(() => {                
                e.classList.add(newclssname)
                e.style[cssprop] = '0px'
              }, 1);
          }
          
          }
          else {
        //   if(top < hideheight) {
            //console.log(name)
            if(WithReverse && e.classList.contains(newclssname)){
              //e.style.visibility = 'hidden'
              
              e.classList.remove(newclssname)
              
              setTimeout(() => {                
                e.classList.add(revclssname)
                e.style[cssprop] = -window.innerWidth+'px'
              }, 1);
              
            }
          }
          });
        })
        }
      },
        FixTabstoRecordData(){
           this.PageTabs
            .map(tab => {                
                if(tab.Name === 'Gallery' && tab.Galleryid && this.DynamicWikiData.Galleries){
                    let galleryobj = this.DynamicWikiData.Galleries.find(obj => obj.id === tab.Galleryid)
                    tab.Assets = galleryobj.DataArray                    
                }
                else if(tab.Name === 'Parallax'){
                    if(tab.ContentFromField && this.DynamicWikiData[tab.ContentField.Name]){
                       tab.IMG = this.DynamicWikiData[tab.ContentField.Name].fileurl  
                    }
                    if(tab.Elements){
                        tab.Elements.map(elmnt => {
                            if(elmnt.Name === 'Title' && elmnt.ContentFromField){
                                    if(elmnt.ContentField.Type === 'Option Set' && this.DynamicWikiData[elmnt.ContentField.Name]){
                                        elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name].Name
                                    }
                                    else if(elmnt.ContentField.Type === 'Lookup' && this.DynamicWikiData[elmnt.ContentField.Name]){
                                        elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name][elmnt.ContentField.LookupFieldName]
                                    }
                                    else{
                                        elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name]
                                    }
                                    
                                }
                        })
                    }                   
                }
                else if(tab.Name === 'blank'){
                    tab.Elements.map(elmnt => {
                        if(elmnt.Name === 'Carousel' && elmnt.Galleryid && this.DynamicWikiData.Galleries){
                                let galleryobj = this.DynamicWikiData.Galleries.find(obj => obj.id === elmnt.Galleryid)
                                elmnt.Assets = galleryobj.DataArray
                        }
                        else if(elmnt.Name === 'HTML Box' && elmnt.ContentFromField){
                            elmnt.Description = this.DynamicWikiData[elmnt.ContentField.Name]
                        }
                        else if(elmnt.Name === 'Title' && elmnt.ContentFromField){
                            if(elmnt.ContentField.Type === 'Option Set' && this.DynamicWikiData[elmnt.ContentField.Name]){
                                elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name].Name
                            }
                            else if(elmnt.ContentField.Type === 'Lookup' && this.DynamicWikiData[elmnt.ContentField.Name]){
                                elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name][elmnt.ContentField.LookupFieldName]
                            }
                            else{
                                elmnt.Title = this.DynamicWikiData[elmnt.ContentField.Name]
                            }
                            
                        }
                        else if(elmnt.Name === 'Image' && elmnt.ContentFromField){
                            elmnt.IMG = this.DynamicWikiData[elmnt.ContentField.Name].fileurl
                        }
                        else if(elmnt.Name === 'Description List' && !elmnt.WrapInExpansionPanel){
                            elmnt.ListItems.map(listitem => {
                                if(!listitem.Record){
                                    listitem.Record = {}
                                }
                                if(typeof this.DynamicWikiData[listitem.Name] !== 'undefined'){
                                 listitem.Record[listitem.Name] = this.DynamicWikiData[listitem.Name]   
                                }
                                else if(listitem.Type === 'Boolean'){
                                    listitem.Record[listitem.Name] = false
                                }                                
                                return listitem
                            })
                        }
                        else if(elmnt.Name === 'Description List' && elmnt.WrapInExpansionPanel){
                            elmnt.Panels.map(panel => {
                                panel.ListItems.map(listitem => {
                                    if(!listitem.Record){
                                        listitem.Record = {}
                                    }
                                    if(typeof this.DynamicWikiData[listitem.Name] !== 'undefined'){
                                    listitem.Record[listitem.Name] = this.DynamicWikiData[listitem.Name]   
                                    }
                                    else if(listitem.Type === 'Boolean'){
                                        listitem.Record[listitem.Name] = false
                                    }
                                    return listitem
                                })
                                return panel
                            })
                        }
                        return elmnt
                    })
                }
                return tab
            })
            // .map(tab => {
            //     if(tab.Elements){
            //         tab.Elements.map(elmnt => {
            //             if(elmnt.Name === 'HTML Box' && elmnt.ContentFromField){
            //             elmnt.Description = data[elmnt.ContentField.Name]
            //             }
            //             // else if(tab.Name === 'Carousel' && tab.Galleryid && tab.Galleryid === gal.id){
            //             //     data[elmnt.ContentField.Name]
            //             // }
            //             return elmnt
            //         })
            //     }
            //      if(tab.Name === 'Gallery' && tab.Galleryid && tab.Galleryid === gal.id){
            //             tab.Assets = []
            //             //console.log('this.DynamicRecordData[tab.Galleryid]',this.DynamicRecordData[tab.Galleryid])
            //             gal.DataArray.map(asset => {
            //                 this.AssignGalleryAssets(tab,asset)
            //             })
            //         }
            //         else if(tab.Name === 'blank'){
            //             tab.Elements.map(elmnt => {
            //                 if(elmnt.Name === 'Carousel' && elmnt.Galleryid && elmnt.Galleryid === gal.id){
                                
            //                     gal.DataArray.map(asset => {
            //                         this.AssignGalleryAssets(elmnt,asset)
            //                     })
            //                 }
            //             })
            //         }
            //     return tab                                
            // })
        },
        PublishWikiRecord(){
            
            let object = {
                PublishType: 'Public',
                WikiTabs: this.DataSingleTabs.map(tab => {
                    if(tab.Elements){
                        tab.Elements.map(elmnt => {
                            if(elmnt.FormFields){
                                elmnt.FormFields.map(field => {
                                    delete field.Response
                                    delete field.Array
                                    return field
                                })                                
                            }
                            return elmnt
                        })
                        return tab
                    }
                }),
            }      
            if(this.CurrentEntity.WikiFields){
                object.WikiTabs.map(tab => {
                    if(tab.SourceProps){
                        //console.log(tab,tab.SourceProps)
                        tab.SourceProps.map(prop => {
                            if(prop.SourceType !== 'Template Prop' && !prop.IsArray || prop.IsArray && prop.DefaultType === 'FAQ'){
                                if(typeof tab[prop.ElementProp] !== 'undefined'){
                                    if(prop.SourceType === 'Page Record Prop'){
                                            object[prop.DataProp.Name] = tab[prop.ElementProp]
                                            db.collection(this.$route.meta.DataCollection).doc(this.$route.params.id).update({
                                                [prop.DataProp.Name]: tab[prop.ElementProp]
                                            })
                                        }                                    
                                        else if(prop.SourceType === 'Data Field' && prop.DataProp.Type !== 'Single File Upload'){
                                            object[prop.DataProp.Name] = this.DynamicWikiData[prop.DataProp.Name]
                                             if(prop.DataProp.Type === 'Lookup' && prop.SourceType === 'Data Field'){
                                                object[prop.DataProp.Name+'id'] = this.DynamicWikiData[prop.DataProp.Name].id
                                            }
                                        } 
                                        else if(prop.SourceType === 'Data Field' && prop.DataProp.Type === 'Single File Upload'){
                                            object[prop.DataProp.Name] = tab[prop.ElementProp]
                                        } 
                                }                                
                            }                            
                        })
                    }
                    if(tab.Elements){
                        tab.Elements.map(elmnt => {
                            if(elmnt.SourceProps){
                                //console.log(elmnt,elmnt.SourceProps)
                             elmnt.SourceProps.map(prop => {
                                if(prop.SourceType !== 'Template Prop' && !prop.IsArray || prop.IsArray && prop.DefaultType === 'FAQ'){
                                    if(typeof elmnt[prop.ElementProp] !== 'undefined'){
                                        if(prop.SourceType === 'Page Record Prop'){
                                            object[prop.DataProp.Name] = elmnt[prop.ElementProp]
                                            db.collection(this.$route.meta.DataCollection).doc(this.$route.params.id).update({
                                                [prop.DataProp.Name]: elmnt[prop.ElementProp]
                                            })
                                        }                                    
                                        else if(prop.SourceType === 'Data Field' && prop.DataProp.Type !== 'Single File Upload'){
                                            object[prop.DataProp.Name] = this.DynamicWikiData[prop.DataProp.Name]
                                            if(prop.DataProp.Type === 'Lookup' && prop.SourceType === 'Data Field'){
                                                object[prop.DataProp.Name+'id'] = this.DynamicWikiData[prop.DataProp.Name].id
                                            }
                                        } 
                                        else if(prop.SourceType === 'Data Field' && prop.DataProp.Type === 'Single File Upload'){                                       
                                            object[prop.DataProp.Name] = elmnt[prop.ElementProp]
                                        }   
                                    }
                                    
                                }
                            })
                            }
                        })
                    }
                })
                this.CurrentEntity.WikiFields.map(field => {
                    let prop = field.Name
                    let value = this.DynamicWikiData[prop]
                    if(typeof object[prop] === 'undefined' && typeof value !== 'undefined' && field.Source === 'Data Field'){
                       if(field.Type !== 'Single File Upload'){
                           object[prop] = value
                           if(field.Type === 'Lookup'){
                               object[prop+'id'] = value.id
                           }
                       } 
                       else if(field.Type === 'Single File Upload'){
                           object[prop] = value.url
                       }
                    }
                })
            }
            db.collection(this.$route.meta.Collection).doc(this.$route.params.id).set(object).then(setobj => {
            })
        },
        PublishClassifiedRecord(){
            //right, notes
            //- before we can publish there needs to be a CombinedtitleMap on catnavlist
            //- once we done with buildup, check 

            let Combined_Title = ''
            let DisplayTitle = ''
            let hastitle = false
            if(this.DynamicWikiData.Combined_Title){
                Combined_Title = this.DynamicWikiData.Combined_Title
                DisplayTitle = this.DynamicWikiData.Combined_Title.split('_')[0]
                hastitle = true
                this.ProcessPublishClassifiedRecord(Combined_Title,DisplayTitle,hastitle)
            }
            else{
                let buildup = ''
                this.ClsfdNavList.CombinedTitleMap.map((field,fieldindex) => {
                    if(fieldindex === 0){
                        buildup = buildup
                    }
                    else{
                        buildup = buildup+'-'
                    }
                    if(field.Type === 'Option Set'){
                       buildup = buildup+this.DynamicWikiData[field.Name].Name.split(' ').join('-')
                    }
                    else if(field.Type === 'Lookup'){
                       buildup = buildup+this.DynamicWikiData[field.Name][field.LookupFieldName].split(' ').join('-')
                    }
                    else{
                        buildup = buildup+this.DynamicWikiData[field.Name].split(' ').join('-')
                    }
                    
                })
                this.ValidateExistingTitles(buildup)
                //console.log(buildup)
                //now we need to look for buildup, see how many items have a Displaytitle that match the build up. 
                //say count is 3, then this item gets "_3" added
            }
            
            // else{
            //     db.collection(this.$route.meta.Collection).doc(this.$route.params.id).set(object)
            // }
            //console.log(db.collection(this.$route.meta.Collection).doc(this.$route.params.id),object)
            //db.collection(this.$route.meta.Collection).doc(this.$route.params.id).set(object)
        },
        ValidateExistingTitles(buildup){
            
            let DisplayTitle = buildup.split('_').join('')
            //console.log(DisplayTitle)
            let query = db.collection(this.$route.meta.Collection).where('DisplayTitle','==',DisplayTitle)
            query.get().then((res) => {
                let length = res.docs.length
                //console.log(length)
                buildup = buildup+'_'+length
                this.ProcessPublishClassifiedRecord(buildup,DisplayTitle)
            })
        },
        ProcessPublishClassifiedRecord(Combined_Title,DisplayTitle,hastitle){
            //console.log(this.ClsfdNavList,this.FeatureNavList,DisplayTitle)
            let object = {
                PublishType: 'Public',
                ClassifiedTabs: this.DataSingleTabs.map(tab => {
                    if(tab.Elements){
                        tab.Elements.map(elmnt => {
                            if(elmnt.FormFields){
                                elmnt.FormFields.map(field => {
                                    delete field.Response
                                    delete field.Array
                                    return field
                                })                                
                            }
                            return elmnt
                        })
                        return tab
                    }
                }),
                Photo: this.DynamicWikiData.Photo,
                Description: this.DynamicWikiData.Description,
                Title: this.DynamicWikiData.Title,
                DisplayTitle: DisplayTitle,
                Combined_Title: Combined_Title,
                Recordid: this.$route.params.id,
                Price: Number(this.DynamicWikiData.Price)
            }
            if(this.DynamicWikiData.Business_Unit){
                object.Business_Unit = this.DynamicWikiData.Business_Unit,
                object.Business_Unitid = this.DynamicWikiData.Business_Unitid
            }
            if(this.DynamicWikiData.Status){
                object.Status = this.DynamicWikiData.Status
                object.Status_Reason = this.DynamicWikiData.Status_Reason
            }
            //FeatureNavList
            if(this.FeatureNavList){
                this.FeatureNavList.FeatureList.map(feature => {
                    let value = false
                    value = this.DynamicWikiData[feature.Name]
                    if(typeof value !== 'undefined'){
                      object[feature.Name] = value  
                    }                    
                })
            }
            if(this.ClsfdNavList.FeatureListLookupField){
                object[this.ClsfdNavList.FeatureListLookupField.Name] = this.DynamicWikiData[this.ClsfdNavList.FeatureListLookupField.Name]
                object[this.ClsfdNavList.FeatureListLookupField.Name+'id'] = this.DynamicWikiData[this.ClsfdNavList.FeatureListLookupField.Name+'id']
            }

            let navlist = this.ClsfdNavList
            //console.log(navlist,object)
            //console.log('this.DynamicWikiData',this.DynamicWikiData)
            if(typeof navlist.NavList !== 'undefined'){
                navlist.NavList.map(listitem => {
                    if(listitem.Field && !listitem.Filters){
                        let field = listitem.Field
                        object[field.Name] = this.DynamicWikiData[field.Name]
                    }
                    else if(listitem.Filters){
                        listitem.Filters.map(filter => {
                                let field = filter
                                object[field.Name] = this.DynamicWikiData[field.Name]
                        })
                    }
                })
            }
            if(typeof navlist.TaggedFields !== 'undefined'){
                    navlist.TaggedFields.map(field => {
                        object[field.Name] = this.DynamicWikiData[field.Name]
                    })
            }
            if(typeof navlist.TagField !== 'undefined'){
                let field = navlist.TagField
                object[field.Name] = this.DynamicWikiData[field.Name]
            }
            //console.log(object)
            db.collection(this.$route.meta.Collection).doc(this.$route.params.id).set(object).then(setobj => {
                if(!hastitle){
                    db.collection(this.$route.meta.DataCollection).doc(this.$route.params.id).update({
                        Combined_Title: Combined_Title
                    })
                }
            })
        },
        GetShortLivedURL(fileobject){
        this.$emit('GetShortLivedURL',fileobject)
      },
        onPhotoUpload(filename,file,storageref,field){
            this.$emit('onPhotoUpload',filename,file,storageref,field)
        },
        ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
        },
        NavigatetoEditor(){
            if(this.$route.name === 'landingpagesingle'){
                let routes = this.$router.getRoutes()
                let route = routes.find(obj =>obj.name === 'newlandingpage')
                route.params = {id: this.$route.params.id}
                this.$router.push(route)
            }
            else if(this.$route.meta.ClassifiedListingBuilder || this.WikiMode){
                this.$emit('CancelEditing',true)
            }
            else if(this.$route.meta.RouteEventID === 1028){

            }
            else if(this.$route.meta.RouteEventID === 1027){

            }
        },
        ActivateUploadingSingleFileNotification(boolean){
            this.$emit('ActivateUploadingSingleFileNotification',boolean)
        },
        ActivateMultipleFilesUploadNotification(boolean){
            this.$emit('ActivateMultipleFilesUploadNotification',boolean)
        },
        UpdateRecordCalculation(Record,field,value){
        this.$emit('UpdateRecordCalculation',Record,field,value)
      },
        UpdateEditableField(prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList){
            this.$emit('UpdateEditableField',prop,value,AdditionalSaveMethod,FieldObject,FromFeatureList)
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else if(tab.Name === 'HTML Box'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(tabHeaderFontFamily){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:50px;'  
               }
               else{
                return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
               }
               
           }
           else{
            return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
           }
       },
        MatchColor(tab){									
            if(tab.RowColor && tab.RowColor.hexa){									
                return tab.RowColor.hexa									
            }	
            else if(tab.RowColor){									
                return tab.RowColor									
            }								
            									
        },	
      CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
      ClassCheck(tab){
           var newclass = ''
           
            if(tab.Transparent){
                newclass = newclass+' red'
                tab.Color = '#ffffff'
            }
             if(tab.Clipped){
                newclass = newclass+' clipped'
            }
            return newclass
        },
      HeaderColor(tabHeaderColor){
            if(tabHeaderColor.hex){
                return tabHeaderColor.hex
            }
        },
        ToggleMenu(){
            this.$emit('LandingPageView',false)
        },
      IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      GetMustReadNotices(colref) {
           colref.onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead || noticeobj.MustConfirm && !noticeobj.UserConfirmed){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              //console.log(this.RelevantTopBannerAds)
            })
          })
        },
        GetLandingPagebyRoute(query){
            query.get()
                .then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        // doc.data() is never undefined for query doc snapshots
                        this.PageID = doc.id
                        this.GetLandingPage(db.collection('landingpages').doc(doc.id))
                    });
                })
        },
      CheckUser(){
          //console.log(this.$route)
            if(this.userLoggedIn){
                if(this.$route.meta && this.$route.meta.RouteEventID === 1027 || this.$route.meta && this.$route.meta.RouteEventID === 1028){
                    this.$store.commit('SetCurrentItemModerator',this.userIsAdmin)
                }
               this.UserRecord = this.userLoggedIn
              //console.log(this.UserRecord)
              let colref = db.collection('notices').where('PublishStatus','==','Published (Internal)').where('MustRead','==',true)
              if(this.userLoggedIn.IsGuestUser){
                  colref = colref.where('GuestsIncluded','==',true)
              }
              this.GetMustReadNotices(colref)
                let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',this.$route.name)           
                this.GetBannerAds(RouteBannerAdsRef)
            }
            else{
                let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',this.$route.name)
                this.GetBannerAds(PublicRouteBannerAdsRef)
            }
            if(this.$route.name === 'landingpagesingle'){
                this.$emit('LandingPageView',true)
                if(this.userLoggedIn){
                this.GetLandingPagebyRoute(db.collection('landingpages').where('PageRoute','==',this.$route.params.id).limit(1))
                }
                else{
                    this.GetLandingPagebyRoute(db.collection('landingpages').where('PublishType','==','Public').where('PageRoute','==',this.$route.params.id).limit(1))
                }
            }
            else if(this.$route.name === 'DocumentationLibrary'){  
                // if(this.userLoggedIn){
                //     this.IntranetViewToggle(true)
                // }
                    let libid = this.$route.params.id.split(' ').join('_')
                    if(!this.ActiveDocumentationLibrary || this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.id !== libid){
                        this.GetParentLibrary(this.DocumentationLibraryObj)
                    }
                    else{
                        let pageobj = this.ActiveDocumentationLibrary.Pages.find(obj => obj.id === this.$route.params.slug.split(' ').join('_').split('-').join('_'))
                        this.AssignPageProperties(pageobj)
                    }
                }
            else if(this.$route.meta && this.$route.meta.TableBuilder){
                //how to call table tabs TBD on table comp estblished
                this.$emit('LandingPageView',true)
                this.PageTabs = this.DataSingleTabs
            }
            else if(this.$route.meta && this.$route.meta.RouteEventID === 1028 || this.$route.meta && this.$route.meta.RouteEventID === 1027){
                this.GetLandingPage(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Pages').doc(this.$route.meta.PageID))
            }
            else{
                this.$emit('LandingPageView',true)
                //console.log('this.CurrentEntity',this.CurrentEntity)
                this.PageTabs = this.DataSingleTabs
                if(this.LoadedPageContentFont){
                  this.PageContentFont = this.LoadedPageContentFont  
                }
                if(this.LoadedPageHeadersFontSize){
                  this.PageHeadersFontSize = this.LoadedPageHeadersFontSize  
                }
                if(this.LoadedPageHeadersFont){
                  this.PageHeadersFont = this.LoadedPageHeadersFont  
                }
                if(this.LoadedName){
                  this.PageName = this.PageName  
                }
                if(this.LoadedPageBGGradient){									
                    this.PageBGGradient = this.LoadedPageBGGradient									
                }	
                if(this.LoadedPageBGAsset){									
                    this.PageBGAsset = this.LoadedPageBGAsset									
                }	
                if(this.LoadedPageBGColor){									
                    this.PageBGColor = this.LoadedPageBGColor									
                }
                //console.log(this.PageTabs,this.DynamicWikiData)
                //classifiedbuilder
                //classifiedsingle
                if(this.$route.name.includes('classifiedbuilder') || this.$route.name.includes('classifiedsingle') || this.$route.name.includes('wikisingle') || this.$route.name.includes('wikibuilder') || this.$route.name === 'GroupPage'){
                    //alert('classifiedbuilder classifiedsingle')
                    this.PageTabs.map(tab => {
                        //console.log(tab)
                        if(tab.SourceProps){
                            tab.SourceProps.map(prop => {
                                if(prop.SourceType !== 'Template Prop' && !prop.IsArray || prop.IsArray && prop.DefaultType === 'FAQ'){
                                    let value = this.DynamicWikiData[prop.DataProp.Name]
                                    //console.log(prop.DataProp.Name,value)
                                    if(typeof value !== 'undefined'){
                                            if(prop.DataProp.Type === 'Lookup' && prop.SourceType === 'Data Field'){
                                                value = value[prop.DataProp.LookupFieldName]
                                            }
                                            else if(prop.DataProp.Type === 'Option Set' && prop.SourceType === 'Data Field'){
                                                value = value.Name
                                            }
                                            else if(prop.DataProp.Type === 'Single File Upload' && prop.SourceType === 'Data Field'){
                                                 if(value.url){
                                                   value = value.url 
                                                }
                                            }
                                            tab[prop.ElementProp] = value
                                        }
                                    
                                }                            
                            })
                        }
                        if(tab.Elements){
                            tab.Elements.map(elmnt => {
                                if(elmnt.SourceProps){
                                elmnt.SourceProps.map(prop => {
                                    if(prop.SourceType !== 'Template Prop' && !prop.IsArray || prop.IsArray && prop.DefaultType === 'FAQ'){
                                        let value = this.DynamicWikiData[prop.DataProp.Name]
                                        
                                        if(typeof value !== 'undefined'){
                                            // removed  && this.CurrentEntity
                                            if(prop.DataProp.Type === 'Lookup' && prop.SourceType === 'Data Field'){
                                                value = value[prop.DataProp.LookupFieldName]
                                            }
                                            else if(prop.DataProp.Type === 'Option Set' && prop.SourceType === 'Data Field'){
                                                value = value.Name
                                            }
                                            else if(prop.DataProp.Type === 'Single File Upload' && prop.SourceType === 'Data Field'){
                                                if(value.url){
                                                   value = value.url 
                                                }
                                                
                                            }
                                            elmnt[prop.ElementProp] = value
                                            //console.log(prop.DataProp.Name,value,elmnt)
                                        }
                                    }
                                })
                                }
                            })
                        }
                        if(tab.Elements){
                            tab.Elements.map(elmnt => {
                                if(elmnt.FormFields){
                                    if(this.$route.name === 'GroupPage'){
                                        elmnt.GroupID = this.$route.params.id
                                    }
                                    elmnt.FormFields.map(field => {
                                        if(field.MappedField){
                                            let mappedfield = field.MappedField.Name
                                            if(field.Type === 'Lookup'){
                                                field.Array = []
                                                if(field.MappedField.Primary){
                                                    field.Response = {
                                                    [field.LookupFieldName]: this.DynamicWikiData[field.MappedField.Name],
                                                    id: this.DynamicWikiData.id
                                                    } 
                                                }
                                                else if(!field.MappedField.Primary && !field.MappedField.LoggedInUser){
                                                    field.Response = {
                                                    [field.LookupFieldName]: this.DynamicWikiData[mappedfield],
                                                    id: this.DynamicWikiData[mappedfield+'id']
                                                    } 
                                                }
                                                else if(!field.MappedField.Primary && field.MappedField.LoggedInUser && this.userLoggedIn){
                                                    field.Response = {
                                                        Full_Name: this.userLoggedIn.Full_Name,
                                                        Name: this.userLoggedIn.Name,
                                                        Surname: this.userLoggedIn.Surname,
                                                        id: this.userLoggedIn.id
                                                    }                                             
                                                }
                                                
                                                field.Array = [field.Response]
                                            }
                                            else if(field.MappedField.LoggedInUserValue && this.userLoggedIn){
                                                field.Response = this.userLoggedIn[mappedfield]
                                            }
                                            else if(field.Type !== 'Date'){
                                                field.Response = this[mappedfield]
                                            }
                                            else{
                                                field.Response = this[mappedfield].toDate()
                                            }
                                        }
                                        return field
                                    })
                                    if(!this.userLoggedIn){
                                        elmnt.FormFields = elmnt.FormFields.filter(field => {
                                            return !field.StrictlyLoggedIn
                                        })
                                    }
                                }
                                return elmnt
                            })
                        }
                        
                        return tab
                    })
                }
                //alert(this.$route.name)
            }
            if(this.$route.meta && this.$route.meta.ClassifiedListingBuilder || this.WikiMode){
                this.FixTabstoRecordData()
            }
            //console.log(this.PageTabs)
            
        },
        GetCategoricalNavList(id){
            //alert(id)
            db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('CategoricalNavlists').doc(id).onSnapshot(snapshot => {
                let data = snapshot.data()
                this.CatNavList = data
                this.CatNavList.id = id
                //console.log(data)
            })
        },
        GetParentLibrary(libobj){
          this.$store.dispatch('GetActiveDocumentationLibrary',libobj)
      },
        AssignPageProperties(pagedata){
            //console.log(pagedata)
            this.PageData = pagedata
                if(pagedata.PublishType === 'Members Only' && this.$route.meta && this.$route.meta.RouteEventID === 1027
                || pagedata.PublishType === 'Members Only' && this.$route.name === 'DocumentationLibrary'){
                    this.IntranetViewToggle(true)
                }
                else{
                    this.IntranetViewToggle(false)
                }
                if(this.$route.meta.CatNavList){
                    this.GetCategoricalNavList(this.$route.meta.CatNavList) 
                }
                if(pagedata.PageCode){
                    this.PageCode = pagedata.PageCode
                   
                }
                if(pagedata.PageTabs){
                  this.PageTabs = pagedata.PageTabs.map(tab => {
                       if(tab.Elements){
                            tab.Elements.map(elmnt => {
                                if(elmnt.FormFields){
                                  
                                    elmnt.FormFields = elmnt.FormFields.map(field => {
                                        if(field.Type === 'Radio Group'){
                                                field.Response = []
                                            }
                                        if(field.MappedField){
                                            let mappedfield = field.MappedField.Name
                                            if(field.Type === 'Lookup'){
                                                field.Array = []
                                                if(field.MappedField.Primary){
                                                    field.Response = {
                                                    [field.LookupFieldName]: this.DynamicWikiData[field.MappedField.Name],
                                                    id: this.DynamicWikiData.id
                                                    } 
                                                }
                                                else if(!field.MappedField.Primary && !field.MappedField.LoggedInUser){
                                                    field.Response = {
                                                    [field.LookupFieldName]: this.DynamicWikiData[mappedfield],
                                                    id: this.DynamicWikiData[mappedfield+'id']
                                                    } 
                                                }
                                                else if(!field.MappedField.Primary && field.MappedField.LoggedInUser && this.userLoggedIn){
                                                    field.Response = {
                                                        Full_Name: this.userLoggedIn.Full_Name,
                                                        Name: this.userLoggedIn.Name,
                                                        Surname: this.userLoggedIn.Surname,
                                                        id: this.userLoggedIn.id
                                                    }                                             
                                                }
                                                else{
                                                    let storearray = field.RelatedBuildID.split(' ').join('')+'Array'
                                                    let storequery = field.RelatedBuildID.split(' ').join('')+'Query'
                                                    if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                                                       field.Array = this.$store.state[field.RelatedBuildID.split(' ').join('')+'Array'] 
                                                    }
                                                    else if(this.$store.state[storequery]){
                                                        let storepayload = {
                                                            query: this.$store.state[storequery],
                                                            arrayname: storearray
                                                            }
                                                            this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                                                            field.Array = vm.$store.state[storearray]
                                                            })
                                                    }                                                    
                                                }
                                                
                                                field.Array = [field.Response]
                                            }
                                            else if(field.MappedField.LoggedInUserValue && this.userLoggedIn){
                                                field.Response = this.userLoggedIn[mappedfield]
                                            }
                                            else if(field.Type !== 'Date'){
                                                field.Response = this[mappedfield]
                                            }
                                            else{
                                                field.Response = this[mappedfield].toDate()
                                            }
                                        }
                                        else if(field.Type === 'Lookup'){
                                             
                                            let storearray = field.RelatedBuildID.split('_').join('').split(' ').join('')+'Array'
                                            let storequery = field.RelatedBuildID.split('_').join('')+'Query'
                                            if(this.$store.state[storearray] && this.$store.state[storearray].length > 0){
                                                field.Array = this.$store.state[field.RelatedBuildID.split('_').join('').split(' ').join('')+'Array'] 
                                            }
                                            else if(this.$store.state[storequery]){
                                                let storepayload = {
                                                    query: this.$store.state[storequery],
                                                    arrayname: storearray
                                                    }
                                                    this.$store.dispatch('GetCollectionArray',storepayload).then(result => {
                                                    field.Array = vm.$store.state[storearray]
                                                    })
                                            }
                                        }
                                        return field
                                    }).sort((a, b) => {			
                                            // console.log('sorting again')			
                                            // console.log(a.Value)			
                                            var key1 = b.WebFormIndex			
                                            var key2 = a.WebFormIndex			
			
                                            if (key1 > key2) {			
                                                return -1;			
                                            } else if (key1 == key2) {			
                                                return 0;			
                                            } else {			
                                                return 1;			
                                            }			
                                    })
                                    if(!this.userLoggedIn){
                                        elmnt.FormFields = elmnt.FormFields.filter(field => {
                                            return !field.StrictlyLoggedIn
                                        })
                                    }
                                }
                                return elmnt
                            })
                        }
                        
                        return tab
                  })
                }
                this.PageContentFont = pagedata.PageContentFont
                this.PageHeadersFontSize = pagedata.PageHeadersFontSize
                this.PageHeadersFont = pagedata.PageHeadersFont
                this.PageName = pagedata.Name
                if(pagedata.PageBGGradient){									
                    this.PageBGGradient = pagedata.PageBGGradient									
                }	
                if(pagedata.PageBGAsset){									
                    this.PageBGAsset = pagedata.PageBGAsset									
                }	
                if(pagedata.PageBGColor){									
                    this.PageBGColor = pagedata.PageBGColor									
                }	
                
                // alert(this.PageName)
        },	
        GetLandingPage(query){
            let vm = this
            //console.log('calling page')
            query.onSnapshot(snapshot => {
                let pagedata = snapshot.data()
                this.AssignPageProperties(pagedata)
                
            })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
@keyframes spotlightsearch {

    0% { clip-path: circle(50px at 0 100px); }
    15% { clip-path: circle(15% at 100% 50%); }
    50% { clip-path: circle(15% at 10% 80%); }
    60% { clip-path: circle(15% at 80% 70%); }
    75% { clip-path: circle(15% at 50% 50%); }
    100% { clip-path: circle(75%); }
}
@keyframes move-forever1 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever2 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
@keyframes move-forever3 {
  0% {
    transform: translate(85px, 0%);
  }
  100% {
    transform: translate(-90px, 0%);
  }
}
@keyframes move-forever4 {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}

</style>