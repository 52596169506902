
<template>
    <v-card class="transparent" flat tile width="100%" >
      
      <v-dialog v-model="InviteUserDialog" max-width="500">
      <v-card v-if="InviteUserDialog">
        <v-card-title class="recordtoolbar white--text">
          Invite User
        </v-card-title>
        <v-card-text v-if="!Has_Member_Groups">
          <v-switch v-model="editedInvite.IsGuestUser" :readonly="!userIsAdmin" label="Guest User" />
          <v-text-field v-model="editedInvite.Email" label="Email" :rules="[rules.required,rules.email]" />
          <v-select v-model="editedInvite.Business_Unit" :items="PreparedBusinessUnitsArray" :readonly="!userIsAdmin" label="Business Unit" :rules="[rules.required]" item-text="Name" return-object/>
          <v-select v-model="editedInvite.InvitingUserRoles" :items="AllSecurityRoles" label="Roles" item-text="Name" multiple/>
           <v-select v-if="!editedInvite.IsGuestUser && userIsAdmin" v-model="editedInvite.Primary_Appid" :items="$store.state.AppsDataBase" label="Primary App" item-text="id"/>
          <v-select multiple label="TableFilters" :items="SystemTableFilters" return-object dense item-text="Name" clearable
          v-model="editedInvite.UserTableFilters"></v-select>
          <!-- <div contenteditable id="previewsendemail" class="postinputbox" v-html="PresetEmailBody"/> -->
        </v-card-text>
         <v-card-text v-if="Has_Member_Groups">
          <v-text-field v-model="editedInvite.Email" label="Email" :rules="[rules.required,rules.email]" />
          <v-select v-model="editedInvite.Business_Unit" :items="PreparedBusinessUnitsArray" :readonly="!userIsAdmin" label="Business Unit" :rules="[rules.required]" item-text="Name" return-object/>
          <v-select v-model="editedInvite.MemberGroup" return-object :items="InviteMemberGroupsArray" item-text="id" label="Invite Group"></v-select> 
         </v-card-text>
        <v-card-actions>
          <v-btn @click="CancelInvitingUserEmail()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="SendInvitetoUser()">Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout row justify-center v-if="WebFormDialog">
          <v-dialog v-model="WebFormDialog" persistent fullscreen content-class="webform-dialog" >
            <v-container fill-height style="overflow-y: hidden;">
              <v-btn @click="DeactivateWebFormDialog()" width="100%" dark>
                Close
              </v-btn>
              <v-layout row justify-center align-center >
                 <v-card
            class="mx-auto"
            max-width="500"
            min-width="350"            
            >
             <v-card-title class="title font-weight-regular justify-space-between">
                <span>{{ webformTitle }}</span>
                <v-avatar
                    color="pop lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="WebFormStep"
                ></v-avatar>
                </v-card-title>
                <v-window v-model="WebFormStep">
                <v-window-item :value="1">
                   <WebFormTab v-if="WebForm" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :FromDatabase="true"
                            :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog" :PageID="ActivePageID" :PageName="PageName"
                            :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="i" :rowindex="rowindex" @ConfirmSubmission="ConfirmWebformSubmission"
                              :DynamicWikiData="DynamicWikiData" :MappedUserFieldStayHidden="RenderedWebFormTab.HideUserInfoFields && userLoggedIn"
                            :tab="RenderedWebFormTab" :i="0"
                            />
                </v-window-item>
                 <v-window-item :value="2">
                    <div class="pa-4 text-center background">
                    <v-img
                        class="mb-4"
                        contain
                        height="80"
                        src="@/assets/logo.png"
                    ></v-img>
                    <h3 class="title font-weight-light mb-2">
                        Submitted
                    </h3>
                    <span class="caption grey--text">Thanks!</span><br>
                    <span class="caption grey--text">One of our agents will be in cotact wiht you soon</span>
                    </div>
                </v-window-item>
                </v-window>
                 </v-card>
              </v-layout>
            </v-container>
          </v-dialog>
        </v-layout>
      <v-dialog v-model="ElementAnimationDialog" fullscreen style="margin-top:50px;">
        <v-card v-if="userLoggedIn && ElementAnimationDialog && EditedTab" tile>
          <v-card-text>
            <v-card dark tile>
              <v-card-text>
                <v-switch v-model="EditedTab.AnimationsActive" label="Active"/>
                <v-select @change="CheckAnimKeyframeObj(EditedTab,AnimationObject)"
                v-model="AnimationObject"
                  label="Object" :items="EditedTab.ContentItems"
                />
              </v-card-text>
              <v-card-text > 
                <v-row style="height: 300px;overflow-y: scroll!important;" v-if="AnimationObject"> 
                   <v-col cols="6">
                     <v-card-title>CSS</v-card-title>
                     <v-textarea v-model="EditedTab.AnimationCSS[AnimationObject]"
                     />
                   </v-col>
                  <v-col cols="6">
                     <v-card-title>Animation</v-card-title>
                     <!-- <v-card-subtitle class="error caption">
                      The below needs to be in the right format.
                       <v-tooltip bottom max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs">
                      View More
                      </v-btn>
                      </template>
                      <v-card>
                        <v-card-title>
                          Example
                        </v-card-title>
                      </v-card>
                       </v-tooltip>
                     </v-card-subtitle> -->
                    <v-card dark
                    width="90%" min-height="300px" outlined flat v-html="EditedTab.AnimationKeyframes[AnimationObject]" contenteditable  @blur="AnimationUpdate(AnimationObject,'AnimationKeyframes')"								
                      :id="'AnimationKeyframes'">									
                  </v-card>	
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="AnimationObject === 'Assets'">
                <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                  <v-textarea v-model="EditedTab.ClipAnimPathREV" label="Clip Animate Start"
                  />
                </v-list-item>   
                 <v-list-item v-if="EditedTab.GalleryType === 'Carousel'">
                  <v-textarea v-model="EditedTab.ClipAnimPathFWD" label="Clip Animate End"
                  />
                </v-list-item>
              </v-card-text>
            <v-list v-if="AnimationObject">
              <v-switch v-model="EditedTab.Animations[AnimationObject]" label="Active"/>
              <v-switch @change="CheckInfinite(EditedTab.AnimationProperties[AnimationObject])" v-model="EditedTab.AnimationProperties[AnimationObject].Infinite" label="Runs Infinitely"/>
              <v-list-item>
                <v-text-field v-if="!EditedTab.Infinite" v-model.number="EditedTab.AnimationProperties[AnimationObject].iterations" label="Iterations"/>
                <v-text-field v-model.number="EditedTab.AnimationProperties[AnimationObject].duration" label="Duration (ms)"/>
              </v-list-item>
              <v-list-item>                
                <v-select clearable v-model="EditedTab.AnimationProperties[AnimationObject].fill" :items="['forwards','backwards']" label="Fill Mode"/>
                <v-select clearable v-model="EditedTab.AnimationProperties[AnimationObject].easing" :items="['ease-in','ease-out','ease-in-out']" label="Easing"/>
              </v-list-item>
            </v-list>
            </v-card>
          </v-card-text>
          <v-card-text>
            <PageTabRenderer
                    :PageHeadersFont="EditedPageProperties.PageHeadersFont"
                    :PageContentFont="EditedPageProperties.PageContentFont"
                    :PageHeadersFontSize="EditedPageProperties.PageHeadersFontSize"
                    :System="System"
                    :SystemEntities="SystemEntities"
                    :tab="ComputedPageTabs[0]"
                    :i="0"
                    :downsized="false"
                    :SampleOnly="false"
                />
          </v-card-text>
        <v-card-actions>
          <v-btn dark outlined class="warning" @click="ToggleElementAnimationDialog()">Close</v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <LookupComponent v-if="ViewExistingLookupDialog || AddNewParentLookupDialog" :System="System" :SystemEntities="SystemEntities"
      :ParentCollection="ParentCollection" :ParentTitle="ParentTitle" @LinkParent="LinkParent" @UpdateNewChild="UpdateNewChild"
      @CancelSelection="CancelLookupSelection" @CancelAddParentDialog="CancelAddParentDialog"
      :LookupArrayHeaders="LookupArrayHeaders" :LookupArray="LookupArray" :FieldFilter="LookupFieldFilter"
      :RelatedObj="RelatedRecordObj" :NewParentAdditional="NewParentAdditional" :ParentLookup="ParentLookup"
      :NewParentPrimary="NewParentPrimary" :ViewExistingLookupDialog="ViewExistingLookupDialog" :AddNewParentLookupDialog="AddNewParentLookupDialog"/>
      <Stopwatch v-if="userLoggedIn && RouterReady" :System="System" :SystemEntities="SystemEntities" @CancelStopWatch="ToggleStopWatchDialog" :UserRecord="userLoggedIn" :PushedRelatedObj="TimesheetRelated" />
          <audio id="error_sound" src="@/assets/error_sound.mp3" preload="auto"></audio>
    <audio id="success_sound" src="@/assets/success_sound_classic.mp3" preload="auto"></audio>
    <audio id="pop_sound" src="@/assets/pop_sound.mp3" preload="auto"></audio>
    <v-dialog max-width="400" v-model="ConfirmMSGDialog" persistent>
      <v-card>
        <v-card-title>
          <v-icon dark x-large :color="ConfirmMSGIconColor">{{ConfirmMSGIcon}}</v-icon>
          {{ConfirmMSGTitle}}
        </v-card-title>
        <v-card-text v-html="ConfirmMSGText">
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn dark color="warning" @click="CloseConfirmMSGDialog()">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog max-width="400" v-model="NewLibraryDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewLibrary.Type}}
              </v-card-title>
              <v-card-text>
                 <v-text-field @input="removeWhiteSpace(NewLibrary.DisplayName)" v-model.trim="NewLibrary.DisplayName" label="DisplayName" />
                 <v-text-field @input="removeWhiteSpace(NewLibrary.SingleName)" v-model.trim="NewLibrary.SingleName" label="SingleName" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="NewLibrary.Icon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <v-icon>{{NewLibrary.Icon}}</v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewLibraryDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewLibrary()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
       <!-- <Callout  :PageHeadersFont="'Raleway'" :PageContentFont="'Raleway'"									
        :PageHeadersFontSize="30"	style="z-index:500;"							
        :tab="infoelmnt" @OpenDialog="OpenDialog" @ActionBtnMethod="ActionBtnMethod"
      /> -->
      
        <!-- <v-dialog persistent hide-overlay width="350" v-model="PhotoGalleryUploadsDialog"
        style="position: fixed;z-index: 400;bottom:15px !important;;right: 25px !important;;transform-origin: unset !important;"> -->
          <v-card width="350" v-if="PhotoGalleryUploadsDialog"
          :style="MiniView ? 'position: fixed;z-index: 400;bottom:15px !important;right: 0px !important;' : 'position: fixed;z-index: 400;bottom:15px !important;right: 25px !important;'">
              <v-card-title class="blue-grey darken-2 white--text subtleoverline">
                {{UploadingPhotoGalleryItems ? 'Uploaded ' : 'Proceed '}} {{CompletedUploadFiles.length}}/{{ComputedUploadFiles.length}} Files {{UploadingPhotoGalleryItems? '' : '?'}}
                <v-spacer>
                </v-spacer>
                <v-btn v-if="UploadingPhotoGalleryItems" dark fab icon :disabled="CompletedUploadFiles.length<ComputedUploadFiles.length" @click="ClosePhotoGalleryUploadDialog()">
                <v-icon color="red">mdi-close</v-icon>
                </v-btn>
                <v-btn v-if="!UploadingPhotoGalleryItems" dark fab icon @click="UploadLibraryAssets()">
                <v-icon color="success">mdi-content-save</v-icon>
                </v-btn>
                <v-btn v-if="!UploadingPhotoGalleryItems" dark fab icon @click="CancelUploadLibraryAssets(),ClosePhotoGalleryUploadDialog()">
                <v-icon color="red">mdi-cancel</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-list dense class="transparent">
                  <v-virtual-scroll 
                  style="overflow-x: hidden;padding:4px;"
                      class="transparent"
                        :items="ComputedUploadFiles"
                        :item-height="35"
                        :height="200"
                      >
                      <template v-slot:default="{ item }">
                      <v-list-item >
                      <v-list-item-avatar rounded size="25">
                        <v-img :src="item.url"/>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                                ...{{item.name.substr(item.name.length-10,item.name.length)}}
                              </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action class="grey--text">
                          {{item.MBSize}}.MB
                        </v-list-item-action>
                        <v-list-item-avatar v-if="item.Uploaded" color="success" size="20">
                          <v-icon x-small dark color="white">
                            mdi-check</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-if="!item.Uploaded" size="20">
                         <v-progress-circular color="blue" :indeterminate="UploadingPhotoGalleryItems">
                         </v-progress-circular>
                        </v-list-item-avatar>
                      </v-list-item>
                      </template>
                  </v-virtual-scroll>
                </v-list>
              </v-card-text>
            </v-card>
        <!-- </v-dialog> -->
      <v-dialog persistent :width="MiniView ? 350 : 500" v-model="MiniRecordDialog">
        <v-card tile width="100%">
          <v-card-title>{{MiniRecordDialogTitle}}
          </v-card-title>
          <v-img v-if="MiniRecordDialogIMG" :src="MiniRecordDialogIMG" :height="MiniView ? 150 : 250" contain
          />
          <v-card-text v-html="MiniRecordDialogDescription">
          </v-card-text>
          <!-- accutall just assignsocialinteractionmethod to handle it? but then again we loking to make this quite super usable for custom entity so here goes -->
          <v-card-text>
            <v-tabs v-model="MiniRecordActiveTab" centered>
              <v-tab v-for="tab in MiniRecordTabs" :key="tab.itemObjKey">
                {{tab.Name}}
              </v-tab>
              <v-tab-item v-for="tab in MiniRecordTabs" :key="tab.itemObjKey">
                  <FieldsForm :FormFields="tab.FormFields" :Validating="Validating" :DataTableView="false" @save="MiniRecordProcess"
                  @UploadFileSelect="UploadFileSelect" :DataObject="MiniRecordObject" :tab="{}" />
                   <v-card-actions>                     
                      <v-spacer>
                      </v-spacer>
                      <v-btn @click="MiniRecordProcess(tab)" dark color="success">
                        Update
                      </v-btn>
                    </v-card-actions>
              </v-tab-item>           
            </v-tabs>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="CloseMiniRecordDialog()" dark color="warning"
            >Cancel</v-btn>
            <v-spacer>
            </v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="GalleryDialog" :width="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '100%' : '80%'">
      <v-card style="overflow-x: hidden;" height="100%"
          class="mx-auto"
        >
        <v-card-title class="recordtoolbar white--text elevation-4" >
          Choose an image <v-spacer>
        </v-spacer>
          <v-icon class="soloactionicon" @click="ToggleGalleryDialog()" dark color="red">mdi-close</v-icon>
        </v-card-title>
          <v-layout row class="justify-center fill-height transparent"  v-if="UseGroupAssets">
            
            <v-col cols="4" >
              <v-list height="100%" class="background my-1">
              <v-chip @click="UploadingGalleryAssets = !UploadingGalleryAssets" dark class="mx-3 blue white--text">
                <v-icon dark>mdi-plus</v-icon>
                Upload Media
              </v-chip>
              <v-list-item>
                {{ActiveGroup.name}}
              </v-list-item>
              <v-list-item class="background" style="padding:0px;top: 20%;">
                <v-img height="200" contain v-if="SelectedImage" :src="SelectedImage.fileurl"/>
                </v-list-item>
                <v-list-item class="justify-center" style="padding:0px;top: 20%;">
                  {{SelectedImageDimensions}}
                </v-list-item>
                <v-list-item @click="AssignImageandCloseTab()" style="padding:0px;top: 20%;" v-if="SelectedImage && EditedTab || SelectedImage && IMGProp === 'PageBGAsset' || SelectedImage && IMGProp === 'AppStylingIMG'">
                  <v-chip color="warning" dark @click="SelectGalleryItem(SelectedImage)">
                    Select
                  </v-chip>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols="8" style="padding:40px;">
              <AssetsDirectory :Assets="GalleryAssets" :Cols="ImageCols" :HoverPreview="false" @SelectImage="SelectImage" v-if="!UploadingGalleryAssets" 
              />
              <v-card-text  v-if="UploadingGalleryAssets">
                <v-card v-if="UploadFileListFiles.length === 0" flat width="60%" @dragover.prevent @drop.prevent="PreviewAddingLibraryAssets($event, FilterType)" id="drop-area">
                    <v-layout class="justify-center">
                      <v-card-title class="grey--text">
                          Drop any attachment files in here
                      </v-card-title>
                    </v-layout>
                  </v-card>
                  <v-row  v-if="UploadFileListFiles.length > 0">
                    <v-col
                      v-for="n in UploadFileListFiles"
                      :key="n.itemOvjKey"
                      class="d-flex child-flex"
                      cols="3"
                    >
                      <v-img
                        :src="n"
                        aspect-ratio="1"
                        class="grey lighten-2"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-col>
                  </v-row>
              </v-card-text>
              
            </v-col>
          </v-layout>
           <v-layout row class="justify-center fill-height transparent" v-if="!UseGroupAssets">            
              <v-col cols="4" >
                <v-list height="100%" class="background my-1">
                <v-chip v-if="!DocLibisRADB" @click="ToggleNewPhotoLibraryDialog('Photo Library')" dark class="mx-3 blue white--text">
                  <v-icon dark>mdi-plus</v-icon>
                  Add Library
                </v-chip>
                <v-list-item @click="ActivateLib(libindex)" v-for="(lib,libindex) in GalleryLibraries"
                :key="lib.itemObjKey">
                    {{lib.name}}
                </v-list-item>
                <v-list-item class="black" style="padding:0px;top: 20%;">
                <v-img height="200" contain v-if="SelectedImage" :src="SelectedImage.fileurl"/>
                </v-list-item>
                  <v-list-item class="justify-center" v-if="SelectedImageDimensions" style="padding:0px;top: 20%;" >
                  {{SelectedImageDimensions}}
                </v-list-item>
                <v-list-item @click="AssignImageandCloseTab()" style="padding:0px;top: 20%;" v-if="SelectedImage && EditedTab
                || SelectedImage && ForGallery 
                || SelectedImage && IMGProp === 'PageBGAsset' || SelectedImage && IMGProp === 'AppStylingIMG'">
                
                  <v-chip color="warning"  dark @click="SelectGalleryItem(SelectedImage)">
                    Select
                  </v-chip>
                </v-list-item>
                
                </v-list>
              </v-col>
              <v-col cols="8" class="my-10">
                <PhotoLibraryViewer v-if="GalleryLibraries[activelibraryindex]"
                  :FullGallery="true"
                  :TableCollectionRef="GalleryLibraries[activelibraryindex].TableCollectionRef"
                  :TableName="GalleryLibraries[activelibraryindex].name"
                  @AssignGalleryAssets="SelectGalleryAssets" :EntityCollectionRef="GalleryLibraries[activelibraryindex].EntityCollectionRef"
                  :IMGProp="''"
                  :CurrentEntity="GalleryLibraries[activelibraryindex]"
                  :AppisDarkMode="true"
                  :tab="EditedTab"
              />	
              </v-col>
            </v-layout>
            
      </v-card>
    </v-dialog>
    <v-dialog max-width="400" v-model="NewDocLibDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewDocLib.PublishType}} Documentation Library
              </v-card-title>
              <v-card-text>
                 <v-text-field v-model="NewDocLib.Name" label="Name" />
                <!-- <v-text-field v-model="NewDocLib.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field @input="removeWhiteSpace(NewDocLib.PageIcon)" v-model.trim="NewDocLib.PageIcon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <v-text-field @input="removeWhiteSpace(NewDocLib.FirstPageName)" v-model.trim="NewDocLib.FirstPageName" label="First Page Name" />
                    
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewDocLibDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewDocLib()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
      <v-dialog max-width="400" v-model="NewPageDialog">
             <v-card >
              <v-card-title class="recordtoolbar white--text">
                New {{NewPage.PublishType}} Page
              </v-card-title>
              <v-card-text>
                 <v-text-field @input="removeWhiteSpace(NewPage.Name)" v-model.trim="NewPage.Name" label="Name" />
                <!-- <v-text-field v-model="NewPage.PageRoute" label="Page Route" /> -->
                <v-dialog  max-width="1000px" min-height="600">
                        <template v-slot:activator="{ on }">
                            <v-text-field @input="removeWhiteSpace(NewPage.PageIcon)" v-model.trim="NewPage.PageIcon" v-on="on" label="Icon" />	
                        </template>
                        <v-card min-height="600">
                                <iframe width="100%" height="600" src="https://pictogrammers.github.io/@mdi/font/5.9.55/" title="Material Design Icons"></iframe>
                        </v-card>
                    </v-dialog>
                    <v-icon>{{NewPage.PageIcon}}</v-icon>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="DeactivateNewPageDialog()">Cancel</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn @click="SaveNewPage()">Save</v-btn>
              </v-card-actions>
             </v-card>
          </v-dialog>
      <v-dialog v-model="OrderConfirmationDialog" style="z-index: 400;" fullscreen>
        <v-card tile class="white" flat>
          <v-btn v-if="OrderConfirmationPDF && MarketDialogType !== 'View Statement Invoice'" @click="ProcessOrder(OrderConfirmationPDF)" style="z-index:2;">Confirm Order</v-btn>
          <v-btn v-if="OrderConfirmationPDF && MarketDialogType !== 'View Statement Invoice'" @click="CloseOrderConfirmationDialog()" style="z-index:2;">Change Details</v-btn>
          <v-btn v-if="OrderConfirmationPDF && MarketDialogType === 'View Statement Invoice'" @click="CloseStatementInvoiceDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="WindowHeight-25" :width="WindowWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="WindowHeight-25" :width="WindowWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>
      <PDFExporter v-if="ExportingtoPDFDialog" :ExporttoPDFFunctions="[PDFFunctionData]"		:PassedFunction="PDFFunctionData"	@DeactivateExportingtoPDFDialog="DeactivateExportingtoPDFDialog"
        :FullItem="PDFDataRecord" :ExportingtoPDFDialog="ExportingtoPDFDialog" @ToggleDialog="ToggleDialog" :ExportonLoad="true" :PushCompletedFile="!PDFDownloadOnly"					
        />
        <v-dialog v-if="userLoggedIn" v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
          
          <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
          <router-link :to="'/SiteMember/'+notice.InviteData.Invited_Byid">
            {{notice.InviteData.Invited_By.Full_Name}}
          </router-link>
          </v-list-item>
          <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
           invited you to join the Group
           </v-list-item>
          <v-list-item class="justify-center" v-if="notice.IsGroupInvite"
          >
           <router-link :to="'/Group/'+notice.InviteData.GroupID">
            {{notice.InviteData.GroupName}}
          </router-link>           
          </v-list-item>
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="notice.IsGroupInvite ? '' : '/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>
        <v-dialog v-model="ReminderDialog" width="400" persistent>
          <v-btn @click="Snoozeall()">Snooze All (15 Mins)</v-btn>
                    <v-window
                      v-model="step"
                      class="elevation-1"
                    
                    >
                    <v-window-item  :value="i" v-for="(dragging,i) in ReminderNotifications" :key="dragging.ItemObjKey">
                     
                        <v-card
                          width="400"
                            class="transition-fast-in-fast-out"
                        >
                        <v-card-title style="padding-bottom:0;" class="recordtoolbar mediumoverline white--text">
                           Reminder {{step-1+2}} of {{ReminderNotifications.length}}
                            </v-card-title>
                           <!-- <v-card-title class="recordtoolbar overline white--text">
                          {{ dragging.Subject }}
                            </v-card-title> -->
                        <v-card-title class="recordtoolbar overline white--text" style="padding-top:0;">
                          <v-list-item-title>
                          <v-chip :color="GetActColor(dragging)" small>
                            <v-icon>
                              {{GetActIcon(dragging)}}
                            </v-icon>
                            {{dragging.Type.Name}}
                          </v-chip>
                          </v-list-item-title>
                          {{ dragging.Subject }}<v-spacer></v-spacer>
                             <v-list-item-avatar size="40" :style="dragging.overdue ? 'animation: borderlesspulse 0.75s infinite;font-size: 14px;' : 'font-size: 14px;'"
                              :class="dragging.overdue? 'noticeboarddate red white--text justify-center' : 'noticeboarddate primary white--text justify-center'" rounded>
                                  {{dragging.daydue}}<br>
                                  {{dragging.monthdue}}
                              </v-list-item-avatar>                                                       
                            </v-card-title>
                            <v-card-title class="recordtoolbar overline white--text" style="padding-top:0;">
                               <v-btn  v-if="dragging.QuickLink" target="_blank" x-small :href="dragging.QuickLink">Link</v-btn>
                              <v-menu
                                  v-model="dragging.duedatemenu"
                                  :close-on-content-click="false"
                                  :nudge-right="40"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px" 
                                  id="EventStartDatemenu"
                                >
                                  <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" v-if="dragging.Type.Name !== 'Appointment'" x-small>Change Due Date</v-btn>
                                  </template>
                                  <v-date-picker :allowed-dates="disablePastDates"
                                  v-model="NewDueDate" @input="duedatemenu = false,AssignNewDueDate(dragging)"></v-date-picker>
                                </v-menu>
                               
                            </v-card-title>
                           
                            <v-card-title v-if="dragging.overdue" style="animation: borderlesspulse 0.75s infinite;" class="largeoverline justify-center red--text mx-2">
                              Overdue!!
                            </v-card-title>
                           <v-layout row class="background justify-center my-1" >
                          <v-icon :color="dragging.overdue ? 'red' : 'warning'" style="animation: alarmring 3s infinite;" size="200">mdi-alarm</v-icon>
                        </v-layout>
                        <v-layout class="mx-3 my-2 justify-center">
                          <span v-html="dragging.Description" class="caption" style="font-size:10px;">
                          </span>
                          </v-layout>                 
                            <v-card-subtitle v-if="dragging.Regarding_Record">                                         
                                <span class="blue--text overline" style="font-size:10px;" v-if="dragging.Regarding_Record">
                                    Regarding: <router-link :to="dragging.Regarding_Record.Link">
                                    {{dragging.Regarding_Record.Name}}
                                    </router-link>
                                </span>
                            </v-card-subtitle> 
                            <v-card-actions>
                              <v-menu width="300"
                              v-model="dragging.SnoozeDial"
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                               
                                <v-btn
                                  v-on="on"
                                  text
                                >
                                  Snooze
                                </v-btn>
                            </template>
                            <v-card width="300">
                              <v-select :items="[1,2,5,10,15,30,60,90]" v-model="SnoozeForMinutes" label="Minutes" />
                              <v-card-actions>
                                <v-btn
                                  v-on="on"
                                  text
                                  @click="SnoozeItem(dragging)"
                                >
                                  Snooze
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                            </v-menu>
                             <v-menu width="380"
                              v-model="dragging.StatusDial"
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                               
                                <v-btn color="success" dark
                                  v-on="on"
                                >
                                  Update
                                </v-btn>
                            </template>
                            <v-card width="380">
                              <v-card-title>
                                Current Status - {{dragging.Status_Reason.Name}}
                                
                              </v-card-title>
                              
                              <v-card-text class="caption">                                 
                                <ul>Click below to update the Status of the Task, and notify the Creator:
                                   <li><v-btn x-small color="success" dark @click="CompletedTaskProcess(dragging)">Completed</v-btn> - Confirms to the Creator as "Completed" and reminders disappear.
                                  </li>
                                  <li><v-btn x-small color="blue" dark @click="OnItTaskProcess(dragging)">I am on it</v-btn> - Confirms to the Creator as "In Progress" and snoozes for 90 minutes.
                                  </li> 
                                  <li><v-btn x-small color="red" dark @click="OnHoldTaskProcess(dragging)">On Hold</v-btn> - Confirms to the Creator as "On Hold". Snoozes for 90 minutes.
                                  </li>                                 
                                </ul>
                              </v-card-text>
                            </v-card>
                            </v-menu>
                            <v-spacer></v-spacer>
                            <v-menu
                            v-model="dragging.DateMenu"
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                :disabled="dragging.overdue"
                                color="primary"
                                :style="dragging.overdue? 'color: red !important;' : ''"
                                depressed
                                :outlined="dragging.overdue"
                                v-on="on"                              
                              >
                                {{dragging.overdue? 'Overdue' : 'Remind Me on Date'}}
                              </v-btn>
                            </template>
                            <v-card>
                              <v-card-text>
                               <v-date-picker  v-model="SnoozeDate" :allowed-dates="disablePastDates" :max="disablePastDue(dragging)"
                                  @input="dragging.DateMenu = false,SnoozeItem(dragging)"></v-date-picker>
                              </v-card-text>
                            </v-card>
                            </v-menu>
                            </v-card-actions>
                        </v-card>
                      </v-window-item>
                    </v-window>
               </v-dialog>
        <v-dialog v-model="UploadingSingleFile" max-width="500px" persistent>
                <v-card min-height="300">
                <v-img
                width="500"
                src="@/assets/RABaseBG-Soft.png"
                ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                  <v-card-title class="mediumoverline white--text">
                    Uploading...
                  </v-card-title></div>
                    <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                      <v-layout row class="justify-center mx-3 my-3">
                          <ul>
                            <progress id="singlefileuploadprogressbar" max="100" value="0"></progress>
                            <p style="font-size:16px;color:blue;" id="singlefileuploadprogresstext">0% complete</p>
                          </ul>
                      </v-layout>
                    </v-row>
                    </v-img>
                 </v-card>
               </v-dialog>
        <v-dialog v-model="UploadingFiles" max-width="500px" persistent>
                   <v-card width="100%" min-height="300">
                          <v-img
                        width="500"
                        src="@/assets/RABaseBG-Soft.png"
                        ><div class="documenttypeshadow white--text" :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBGToolbars.jpeg') + ')',backgroundSize: 'cover' }">
                          <v-card-title class="mediumoverline white--text">
                            Uploading...
                          </v-card-title></div>
                      <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                        <v-layout row class="justify-center mx-3 my-3" id="uploadfilesprogressbar" style="display:none">
                            <ul id="uploadfilesprogressgroupbar">
                            </ul>
                        </v-layout>
                      </v-row>
                      </v-img>
                   </v-card>
                 </v-dialog>
                 
                 <v-list dense :dark="AppisDarkMode" class="windowcard transparent" max-width="400" style="padding:25px;z-index:6;right:0px;bottom:100px;">
            <!-- <v-snackbar :light="!AppisDarkMode" multi-line transition="slide-x-reverse-transition" bottom right absolute :style="{'margin-top':calcMargin(i)}" v-for="(s,i) in Computedsnackbars" :key="i" v-model="s.show" :timeout="s.timeout"> -->
                    <v-card class="mx-3 my-2" elevation="6" :dark="AppisDarkMode" v-for="(s,i) in Computedsnackbars" :key="i" v-model="s.show">
                        <v-card-subtitle ><v-btn color="red" x-small text @click="hide(s)"> Dismiss </v-btn>
                        <v-btn color="red"  v-if="s.IsNotification" x-small text @click="MarkasRead(s)"> Mark as Read </v-btn>
                        </v-card-subtitle>
                      <v-card-subtitle >{{s.title}}
                        </v-card-subtitle>
                        <!-- <v-card-text> -->
                 <v-list-item v-if="!s.IsNotification"  :to="'/NoticeSingle/'+s.id" >
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>
                <v-list-item v-if="s.IsNotification && !s.isHref"  @click="OpenNotification(s)">
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>
                 <v-list-item v-if="s.IsNotification && s.isHref"  :href="s.Route" :target="s.Target" >
                 <v-list-item-avatar size="70" tile class="justify-center" v-if="s.thumbnail" rounded>
                       <v-img :src="s.thumbnail"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="s.text" style="font-size:14px;">
                
                 </v-list-item-content>
                 </v-list-item>

                        <!-- </v-card-text> -->
                    </v-card>
            <!-- </v-snackbar> -->
                 </v-list>
                 <!-- <v-list v-if="windowSnackbars" :dark="AppisDarkMode" class="windowcard" width="400">
                   <v-snackbar 
                   :value="true"
                   
                   :timeout="200000"
                   v-for="(notice,i) in snackbars" :key="i" :style="{'margin-bottom':calcMargin(i)}">
                      {{notice.text}}
                   </v-snackbar> -->
                  <!-- <v-list-item :to="'/NoticeSingle/'+notice.id"  class="mx-1 my-1 windowcarditem" v-for="(notice,i) in snackbars" :key="notice.itemObjKey" >
                
                 <v-list-item-avatar size="90" tile class="justify-center" rounded>
                       <v-img src="https://cdn.vuetifyjs.com/images/cards/cooking.png"></v-img>
                    </v-list-item-avatar>
                 <v-list-item-content v-html="notice.text">
                
                 </v-list-item-content>
               <v-snackbar :style="{'margin-bottom':calcMargin(i)}" v-model="show" :timeout="2000">
                {{ notice.text }}
                <v-btn color="blue" text @click="hide(i)"> Close </v-btn>
            </v-snackbar> -->
                    <!-- <v-list-item-avatar size="40" class="noticeboarddate links white--text justify-center" rounded>
                         <v-icon class="white--text">
                           mdi-alert
                        </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>     
                                                    
                            <v-list-item-subtitle style="font-size:10px">
                            <v-icon small color="red">mdi-alert</v-icon>
                                                
                            <span class="blue--text"> 12 November 2020</span><span class="purple--text"> Category</span>
                            </v-list-item-subtitle>
                            <v-list-item-content class="caption grey--text">
                        ist-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                            Unconfirmed 
                        </v-list-item-content>
                        </v-list-item-content>
                        <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
                        <v-icon class="white--text">
                           mdi-alert
                        </v-icon>
                    </v-list-item-avatar>
                    </v-list-item> -->
                 <!-- </v-list> -->
                 
        <v-layout row justify-center v-if="LiaisonEntryDialog">
          <v-dialog v-model="LiaisonEntryDialog" persistent fullscreen :content-class="AppisDarkMode ? 'darkliaisonentrydialog' : 'liaisonentrydialog'">
            <v-card-title class="recordtoolbar white--text">
                {{LiaisonEntryNotification.Header}}
              </v-card-title>
            <v-container fill-height>
              
              <v-layout row justify-center align-center>
                
                <v-col v-if="!LiaisonEntryNotification.groupid" cols="4" md="2">
                  <v-card @click="SelectLiaisonView('Record View')">
                    <v-card-title>
                      <v-icon>mdi-database</v-icon>Record
                    </v-card-title>
                    <v-card-text>
                      View this Communication message from the Record View
                    </v-card-text>
                    
                  </v-card>
                </v-col>
                <v-col cols="4" md="2">
                  <v-card @click="SelectLiaisonView('Pipeline View')">
                   <v-card-title>
                      <v-icon>mdi-pipe</v-icon>Pipeline
                    </v-card-title>
                    <v-card-text>
                      View this Communication message from the Pipeline View
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-layout>
            </v-container>
          </v-dialog>
        </v-layout>
        <v-layout row justify-center v-if="Processing">
          <v-dialog v-model="Processing" persistent fullscreen content-class="loading-dialog">
            <v-container fill-height>
              <v-layout row justify-center align-center>
                <v-progress-circular indeterminate :size="250" :width="7" class="mediumoverline" color="purple">Processing</v-progress-circular>
              </v-layout>
            </v-container>
          </v-dialog>
        </v-layout>
        <!-- <v-layout row justify-center v-if="MarketDialog"> -->
          <v-overlay style="width:100%;" z-index="400" opacity="0.8" :value="MarketDialog" >
             <!-- <v-layout row justify-center align-center style="width:100%;" class="pink"> -->
            <v-card flat :width="WindowWidth*0.9" class="transparent" tile>
              <v-card-title>
                <v-avatar>
                  <img style="object-fit: cover;" :src="MarketDialogAvatar" />
                </v-avatar>
                 {{MarketDialogTitle}}
                 <v-spacer></v-spacer>
                 <v-icon :small="MiniView" dark style="position: absolute;right:15px;top: 0px;" large @click="CloseMarketDialog()" class="soloactionicon red">mdi-close</v-icon>
              </v-card-title>
              
              <v-card-text v-html="smart_substr(MarketDialogDescription,MiniView ? 170 : 350)">
              </v-card-text>
              <v-card-text>
                <v-row class="justify-center fill-height" v-if="field">
                  <!-- field.Array.concat(field.Array,field.Array,field.Array,field.Array) -->
                          <div class="flex xs12 sm12 md6 lg3 xl2" style="padding:20px;" v-for="item in field.Array" :key="item.itebObjKey">
                                <v-card height="100%" width="100%" class="SoftBuilderGradient">
                                  <v-card-title class="BuilderGradient" style="align-items: normal;padding-bottom:25px;">
                                    {{item[field.LookupFieldName]}}
                                      <v-spacer>
                                    </v-spacer>
                                  <v-icon v-if="item.Icon">{{item.Icon}}</v-icon>
                                    <!-- <span class="caption" v-if="field.LookupSubtitleField && field.LookupSubtitleField.IsCurrency">
                                    {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else-if="field.LookupSubtitleField">
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span> -->
                                  </v-card-title>
                                  <v-card-subtitle style="align-items: normal;" class="grey darken-4" v-if="field.LookupSubtitleField">
                                    <span v-if="field.LookupSubtitleField && field.LookupSubtitleField.IsCurrency" style="text-align: -webkit-right;">
                                    Price - {{CurrencyFormatter(item[field.LookupSubtitleField.Name],$store.state.DefaultCurrency.Currency)}}
                                  </span>
                                  <span v-else style="text-align: -webkit-right;">
                                    {{item[field.LookupSubtitleField.Name]}}
                                  </span>
                                  </v-card-subtitle>
                                  <v-card-text style="text-align: -webkit-center;" class="black" v-if="field.LookupAvatarField">
                                    <v-img height="150" v-if="item[field.LookupAvatarField.Name] && item[field.LookupAvatarField.Name].ThumbURL" :src="item[field.LookupAvatarField.Name].ThumbURL"
                                    />
                                    <v-img height="150" v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && $vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && $vuetify.theme.dark"  src="@/assets/ImageHolder_dark.png" />
                                    <v-img height="150"  v-if="item[field.LookupAvatarField.Name] && !item[field.LookupAvatarField.Name].ThumbURL && !$vuetify.theme.dark
                                    || !item[field.LookupAvatarField.Name] && !$vuetify.theme.dark"  src="@/assets/ImageHolder.png" />
                                  </v-card-text>
                                  <v-card-text v-if="field.LookupCaptionField" v-html="smart_substr(item[field.LookupCaptionField.Name],MiniView ? 130 : 170)">
                                  </v-card-text>
                                  <!--  style="position: absolute; bottom:10px;left:10px;" -->
                                  <v-card-actions>
                                    <v-btn @click="AssignSocialItemInteractMethod(MarketDialogInteractMethod,item)">Select</v-btn>
                                  </v-card-actions>
                                </v-card>
                              </div>
                         </v-row>
              </v-card-text>
            </v-card>
             
            <!-- </v-container> -->
            <!-- </v-layout> -->
          </v-overlay>
        <!-- </v-layout> -->
        <v-layout row justify-center v-if="CustomProcessingDialog">
          <v-overlay z-index="500" color="white" :opacity="0.8" :value="CustomProcessingDialog" >
              <v-layout row justify-center align-center class="pink--text">
               {{CustomProcessingDialogText}}
              </v-layout>
               <v-layout row justify-center align-center>
              <div class="square" style="margin-top:25px;">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
                <div class="four"></div>
              </div>
               </v-layout>
            <!-- </v-container> -->
          </v-overlay>
        </v-layout>
      <v-layout row justify-center v-if="RecordLoading">
          <v-overlay z-index="10" color="white" :opacity="0.8" :value="RecordLoading" >
              <v-layout row justify-center align-center class="pink--text">
               {{RecordLoadingText}}
              </v-layout>
               <v-layout row justify-center align-center>
              <div class="square" style="margin-top:25px;">
                <div class="one"></div>
                <div class="two"></div>
                <div class="three"></div>
                <div class="four"></div>
              </div>
               </v-layout>
            <!-- </v-container> -->
          </v-overlay>
        </v-layout>
          <v-overlay z-index="10" color="white" :opacity="0.8" :value="SystemLoading" >
        <v-layout row justify-center>
          <v-img height="200" contain src="@/assets/RAFullLogo.png"/>
        </v-layout>
        <v-layout row justify-center class="pink--text">
            {{SystemLoadingText}}
        </v-layout>
           <v-progress-linear
           style="margin-top:20px;border-radius: 100px;width: 300px;"
            :buffer-value="SystemLoadingvalue+25"
            :background-color="SystemLoadingColorNext+'55'"
            :value="SystemLoadingvalue"
            height="30"
            stream
            rounded
            :color="SystemLoadingColor"
          ></v-progress-linear>
        <v-layout row justify-center style="margin-top:100px;">
          <div class="RACubes">
            <div class="cube">
              <div class="cube__face cube__face--front"></div>
              <div class="cube__face cube__face--back"></div>
              <div class="cube__face cube__face--right"></div>
              <div class="cube__face cube__face--left"></div>
              <div class="cube__face cube__face--top"></div>
              <div class="cube__face cube__face--bottom"></div>
              
              <div class="square" style="margin-left:-0px;">
                <div style="margin-top:-0px;" class="one"></div>
                <div style="margin-top:-0px;" class="two"></div>
                <div style="margin-top:-0px;" class="three"></div>
                <div style="margin-top:-0px;" class="four"></div>
             </div>
            </div>
          </div>
        </v-layout>
          </v-overlay>
       <v-dialog
       persistent
       v-model="StandbyDialog"
         max-width="1000"
        >
        <v-card
        style="padding-top:10px;"
        width="100%"
        tile
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
       </v-dialog>
       <v-snackbar 
       color="links"
       :top="snackbartop"
      v-model="snackbar"
      :multi-line="snackbarmultiLine"
      :timeout="snackbartimeout"
    >
      <span v-html="snackbartext"></span>
         <template v-slot:action="{ attrs }">
        <v-btn
        v-if="!snackbartimeout && !snackbarpath"
          color="blue"
          text
          v-bind="attrs"
          @click="CloseSnackbar()"
        >
          Close
        </v-btn>
        <v-btn
        v-if="snackbarpath"
          color="blue"
          text
          v-bind="attrs"
          :to="snackbarpath"
           @click="CloseSnackbar()"
        >
          View
        </v-btn>

      </template>
      
    </v-snackbar>
    </v-card>
   
</template>

<script>
import firebase from 'firebase';
import format from 'date-fns/format'
import db from '@/main'
import TopicalLiaisonComponent from '@/components/General/TopicalLiaisonComponent'
import PDFExporter from '@/components/SuitePlugins/PDFBuilder/PDFExporter'
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';	
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import Callout from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import FieldsForm from '@/components/Database/FieldsForm';	
import Stopwatch from '@/components/SuitePlugins/Timesheets/Stopwatch'
import LookupComponent from '@/components/Database/LookupComponent'
import PageTabRenderer from '@/components/WebPages/PageTabRenderer';
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';		
export default {
    props: ['SystemLoading','ReminderDialog','ReminderNotifications','SystemLoadingvalue','AppisDarkMode','SystemEntities','System','SystemTableFilters',
    'StandbyDialog','snackbar','snackbarmultiLine','snackbartimeout','snackbartext','snackbartop','UploadingFiles','RouterReady','SystemPhotoLibraries',
    'UploadingSingleFile','AppisDarkMode','snackbars','snackbarpath','RecordLoadingvalue','RecordLoading','Processing','Notifications','UserisGuest'],
  components: {
    WebFormTab,
    PageTabRenderer,
    Stopwatch,
    LookupComponent,
    TopicalLiaisonComponent,
    PDFExporter,
    AssetsDirectory,
    PhotoLibraryViewer,
    Callout,
    FieldsForm
  },
    data() {
        return {
          WebFormStep: 1,
          WebForm: '',
          WebFormDialog: false,
          UploadingFileProgress: 0,
          editedInvite: {
            Email: '',
            Status: {
              ID: 1000001,
              Name: 'Sent'
            },
            Business_Unit: {
            },
            Business_Unitid: '',
            Created_By: {},
            Created_Byid: {},
            Created_On: '',
            IsGuestUser: true,
            UserTableFilters: []
          },
          defaulteditedInvite: {
            Email: '',
            Status: {
              ID: 1000001,
              Name: 'Sent'
            },
            Business_Unit: {
            },
            Business_Unitid: '',
            Created_By: {},
            Created_Byid: {},
            Created_On: '',
            IsGuestUser: true,
            UserTableFilters: []
          },
          InviteUserBU: '',
          PrimaryApp: '',
          GuestBusinessUnitArray : [],
          InviteUserDialog: false,
          InvitingUserEmail: '',
          InvitingUserRoles: [],
          AnimationObject: '',
          LookupFieldFilter: '',
          LookupArray: [],
          LookupArrayHeaders: [],
          ParentTitle: '',
          ParentCollection:  '',
          NewParentPrimary: '',
          ParentLookup: '',
          NewParentAdditional: [],
          AddNewParentLookupDialog: false,
          ViewExistingLookupDialog: false,
          ForGallery: false,
          AddedAssets: [],
          activelibraryindex: 0,
          NewLibraryDialog: false,
          DefaultNewLibrary: {
            Collection: '',
            CustomTagsInteger: 1000000,
            CustomTags: [],
            DisplayName: '',
            Icon: '',
            PublishType: 'Public',
            Security_Structure: 'Custom Roles',
            SingleName: '',
            SinglePath: '',
            Type: '',
          },
          NewLibrary: {
            Collection: '',
            CustomTagsInteger: 1000000,
            CustomTags: [],
            DisplayName: '',
            Icon: '',
            PublishType: 'Public',
            Security_Structure: 'Custom Roles',
            SingleName: '',
            SinglePath: '',
            Type: '',
          },
          UploadingPhotoGalleryItems: false,
          PhotoGalleryUploadsDialog: false,
          UploadingGalleryAssets: false,
          UploadFileListFiles: [],
          UploadFiles: [],
          MiniRecordActiveTab: 0,
          Validating: false,
          infoelmnt: {
            Checked: false,
            HasActionButton: true,
            ActionButtonMethod: 'SomeMethod',
            ActionhasConfirm: true,
            ActionConfirmLabel: 'Do not Show Again',
            ActionButtonName: 'Got it',
            Fixed: true,
            BorderPosition: 'right',
            Color: 'blue',
            Elevation: 6,
            // AlertIcon: 'mdi-information',
            ColoredBorder: true,
            Width: 400,
            MarginTop: 15,
            MarginBottom: 0,
            MarginLeft: 150,
            MarginRight: 0,
            Description: `<p>Toggle the Builder to change what you are editing</p>`,
            Dense: true,
            Prominent: true,
            Justify: 'justify-end',
            ActionList: [
            ]
          },
          SelectedImage: '',
          SelectedImageDimensions: '',
          GalleryDialog: false,
          DefaultNewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPage: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: []
          },
          NewPageDialog: false,
          NewDocLibDialog: false,
          DefaultNewDocLib: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: [],
            LibraryNavbarItems: []
          },
          NewDocLib: {
            PublishType: '',
            Name: '',
            Icon: '',
            PageIcon: '',
            Overview: '',
            PageContentFont: 'Montserrat',									
            PageHeadersFontSize: 42,									
            PageHeadersFont: 'Raleway',	
            PageRoute: '',
            PageTabs: [],
            LibraryNavbarItems: []
          },
          Company_Address: {Name: 'Company_Address',FieldBreakdown: [],DisplayName: 'Company Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          Billing_Address: {Name: 'Billing_Address',FieldBreakdown: [],DisplayName: 'Billing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          Delivery_Address: {Name: 'Delivery_Address',FieldBreakdown: [],DisplayName: 'Delivery Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
          
          Sales_Quotes: '',
          Sales_Invoices: '',
          Purchase_Invoices: '',
          WindowWidth: 0,
            WindowHeight: 0,
          RouteRefresh: false,
          mustreadnotices: [],
        noticestep: 0,
        MustReadNoticeDialog: false,
          LiaisonEntryDialog: false,
          LiaisonEntryNotification: '',
          LiaisonEntryPayload: '',
          NewDueDate: '',
          duedatemenu: false,
          // SnoozeDial: false,
          SnoozeDate: '',
          SnoozeForMinutes:  5,
          step: 0,
          CubeVar: '',
          cubeint: 0,
          cubecurrentClass: '',
          AlwaysOn: true,
          GettingTabsvalue: 0,
          interval: {},
            windowSnackbars: true,
            show: true,
        //     snackbars: [
        // {title: 'Number 1',timeout:4000,show: true, text: `Hey I am the 1st.
        // <p>For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.</p>`},
        // // {title: 'Number 2', text: `Hey I am the 2nd.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`},
        // // {title: 'Number 3', text: `Hey I am the 3rd.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`},
        // // {title: 'Number 4', text: `Hey I am the 4th.For three line lists, the subtitle will clamp vertically at 2 lines and then ellipsis. This feature uses line-clamp and is not supported in all browsers.`}
        // ],
            StaticNotices: [

            ],
            
            rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        GroupInvoiceLineHeaders:  [
              {text: 'Nr',propvalue: 'Nr',value: 'Nr',Type: 'Number Field',class: 'overline'},
              {text: 'Group',propvalue: 'Group',value: 'Group.name',Type: 'Lookup',LookupFieldName: 'name',class: 'overline'},
              {text: 'Name',propvalue: 'Name',value: 'Name',Type: 'Single Line Text',class: 'overline'},
              {text: 'Description',propvalue: 'Description',value: 'Description',Type: 'Multiple Lines Text',IsContentEditable: true,class: 'overline'},
              {text: 'Qty',propvalue: 'Qty',value: 'Qty',Type: 'Number Field',class: 'overline'},
              {text: 'Price',propvalue: 'Price',value: 'Price',Type: 'Number Field',IsCurrency: true,class: 'overline'},              
            ],
            
        }
    },
    watch: {
      AnitmatedElements: {
            handler: function(newvalue, oldvalue) {
              //console.log(oldvalue, newvalue)
                if(newvalue !== oldvalue){
                    setTimeout(() => {
                     this.RunWebAnimations()   
                    }, 1500);
                    
                }
            },deep: true
    },
      SelectedImage : {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue && newvalue){
                let vm = this
                const img = new Image();
                  img.onload = function() {
                    vm.SelectedImageDimensions = img.naturalWidth + 'x' + img.naturalHeight
                  }
                  img.src = newvalue.fileurl;

              }
            },deep: true
        },
        UploadFilesList  : {
            handler: function(newvalue, oldvalue) { 
              if(newvalue !== oldvalue && newvalue.length > 0){
                this.UploadFileListFiles = Array.from(Array(this.UploadFilesList.length).keys()).map(x => {
                let file = this.UploadFilesList[x]
                let tmpsrc = URL.createObjectURL(this.UploadFilesList[x])
              // console.log(this.UploadFilesList[x].File)
                this.UploadFiles.push({
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    Uploaded: false,
                    MBSize: ''
                  })
                  return tmpsrc
                })
                this.UploadFiles.map((file,fileindex) => {
                  file.url = this.UploadFileListFiles[fileindex]
                  let mbsize = file.size/1000000
                  file.MBSize = mbsize.toFixed(2)
                })
                this.PhotoGalleryUploadsDialog = true
              }            
            },deep: true
        },
      SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['ActivateWebFormDialog','ProcessSingleFileUpload','ToggleNewPageDialog','DeactivateNewPageDialog','AcceptInviteUserDialog',
        'ToggleGalleryDialog','UploadLibraryAssets','ToggleNewDocLibDialog','ToggleNewPhotoLibraryDialog','ToggleNewDocumentLibraryDialog','ActivateAddNewLookupDialog']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
       SelectedFunctionName  : {
            handler: function(newvalue, oldvalue) { 
              if(newvalue && newvalue !== oldvalue){
                if(!this[this.SelectedFunctionName]){
                  this.GetExportPDFFunction(this.SelectedFunctionName,true)
                }
                else{
                  this.$store.commit('setExportingtoPDFDialog',true)
                }
              }
            },deep: true
       },
      userLoggedIn  : {
            handler: function(newvalue, oldvalue) { 
              if(newvalue && newvalue.id && !oldvalue){
                  this.GetExportPDFFunction('Sales_Quotes')
                  this.GetExportPDFFunction('Sales_Invoices')
                  this.GetExportPDFFunction('Purchase_Orders')
                  this.CheckforTimesheet()
              }
              
            },deep: true
      },
      InvitedGroups  : {
            handler: function(newvalue, oldvalue) {   
              //console.log('this.InvitedGroups mustreadnotices',this.mustreadnotices)
            // if(newvalue !== oldvalue){
              this.mustreadnotices = this.InvitedGroups.map(group => {                
                  let groupobj = {
                      coverimageThumbURL: group.logo,
                      monthcreated: this.TimestampFormatterNoticeBoard(group.InviteData.Invite_Date.toDate(),'month'),
                      daycreated: this.TimestampFormatterNoticeBoard(group.InviteData.Invite_Date.toDate(),'date'),
                      createddate: this.TimestampFormatterNoticeBoard(group.InviteData.Invite_Date.toDate(),'full'),
                      caption: group.Description,
                      IconColor: 'warning',
                      Category : {Name: group.Category.Name,Icon: 'mdi-account-group'},
                      IsGroupInvite: true,
                      InviteData: group.InviteData,
                      GroupID: group.InviteData.GroupID,
                      Responded: group.Responded
                  }
                  return groupobj            
              })    
            // }    
            },
            deep: true
        },
        AlertSound (value) {
        if (value) {
          let sound = document.getElementById(value)
          sound.pause();
          sound.currentTime = 0;
          sound.play();
          this.$store.commit('SetAlertSound','')
        }
        },
      MustReadNotices (value) {
        if (value.length>0) {
            this.MustReadNoticeDialog = true
        }
        },
      SystemLoading (value) {
        if(!value){
          //alert('vlue she is not')
          clearInterval(this.CubeVar)
        }
        else if(value){
          this.CubeVar = setInterval(this.myCubeRoll,500)   
        }
      },
      //RouteRefresh
      RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  //this.$store.commit('setCustomProcessingDialog',false)
                  this.$store.commit('setExportingtoPDFDialog',false)
                  this.$store.commit('EmitMarketDialog',false)
                  this.$store.commit('setPDFDownloadOnly',true)
                  this.$store.commit('setPDFFunctionName','')
                  this.$store.commit('setPDFHeaderObj','')
                  this.$store.commit('setPDFFooterObj','')
                  this.$store.commit('setPDFDocLineItems',[])
                  this.$store.commit('EmitMarketDialogInteractMethod','')
                  this.$store.commit('setPDFExportDoc','')
                  this.$store.commit('setPDFDataRecord','')
                  this.$store.commit('setOrderConfirmationDialog',false)
                  this.CloseMarketDialog()
                  this.RouteRefresh = true
                  setTimeout(() => {
                    this.RouteRefresh = false
                  }, 10);
                }
            },
            deep: true
        },
      WishListItems: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  if(this.snackbar){
                    this.snackbar = false
                  }
                  this.snackbartop = true
                  this.snackbartimeout = 1000
                  this.snackbartext = `<p style="text-align: center;">Wishlist updated</p>`
                  this.snackbar = true
                }
            },
            deep: true
        },
    },
    computed: {
      webformTitle () {
        switch (this.WebFormStep) {
          case 1: return this.WebForm.title
          case 2: return 'Completed'
          default: return this.WebForm.title
        }
        },
      RenderedWebFormTab(){
            let tabobj = this.WebForm.WebFormTab
            tabobj.FormName = this.WebForm.DisplayName
            tabobj.RelatedEntity.id = this.WebForm.DisplayName.split(' ').join('_')
            tabobj.SingleName = this.WebForm.SingleName
            if(this.WebForm.WebFormType){
                tabobj.WebFormType = this.WebForm.WebFormType
            }
            if(this.WebForm.ParentEntity){
                tabobj.ParentEntity = this.WebForm.ParentEntity
            }
            tabobj.FormFields = tabobj.FormFields.filter(field => {
              return this.userLoggedIn ? field : 
              !field.MappedField || field.MappedField && !field.MappedField.LoggedInUser
            }).map((field,fieldindex) => {
                field.WebFormIndex = fieldindex
                if(field.MappedField){
                let mappedfield = field.MappedField.Name
                if(!field.MappedField.Primary && field.MappedField.LoggedInUser){
                    //alert('one')
                    field.Response = {
                        Full_Name: this.userLoggedIn.Full_Name,
                        Name: this.userLoggedIn.Name,
                        Surname: this.userLoggedIn.Surname,
                        id: this.userLoggedIn.id
                    } 
                    
                    field.Array = [field.Response]
                }
                else if(field.MappedField.LoggedInUserValue && this.userLoggedIn){
                    field.Response = this.userLoggedIn[mappedfield]
                    if(field.Type === 'Lookup'){
                        field.Array = [field.Response]
                    }
                }
                }
                //console.log(field)
                return field
            })
           
            //FormName
            //RelatedEntity.id
            //SingleName
            return tabobj
        },
       Has_Member_Groups(){
         return this.PluginDataBase && this.PluginDataBase.Member_Groups ? this.PluginDataBase.Member_Groups.Active : false
       },
       PluginDataBase(){
            return this.$store.state.PluginDataBase
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      InvitebyCompany(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties.CompanyInviteOnly && this.userLoggedIn.Company
      },
      InviteMemberGroupsArray(){
        return this.MemberGroupsArray.filter(memgroup => {
          return this.userIsAdmin && memgroup.id !== 'System_Administrators' || this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties.InviteGroups.includes(memgroup.id) && memgroup.id !== 'System_Administrators'
        })
      },
      MemberGroupsArray(){
            return this.$store.state.MemberGroupsArray
        },
      PreparedBusinessUnitsArray(){
        return this.BusinessUnitsArray.map(bu => {
          return {Name: bu.Name,id: bu.id}
        })
      },
      BusinessUnitsArray(){
      console.log('this.$store.state',this.$store.state)
      return this.$store.state.BusinessUnitsArray
    },
      AnitmatedElements(){
        //console.log(this.ComputedPageTabs)
            let animatedrows = this.ComputedPageTabs.filter(tab => {
                return tab.AnimationsActive
            })
            let animatedelements = this.ComputedPageTabs.map(tab => {
                return tab.Elements ? tab.Elements : []
            }).flat().filter(elmnt => {
                return elmnt.AnimationsActive
            })
            return animatedrows.concat(animatedelements)
        },
      ComputedPageTabs(){
        return this.EditedTab ? [this.EditedTab].map(tab => {
              let tabobj = Object.assign({},tab)
              tabobj.ElementID = tabobj.ElementID+'_Preview'
              if(tabobj.Elements){
                tabobj.Elements = tabobj.Elements.map(elmnt => {
                  if(elmnt.ElementID){
                    let elmntobj = Object.assign({},elmnt)
                    elmntobj.ElementID = elmntobj.ElementID+'_Preview'
                    return elmntobj
                  }
                  else{
                    return elmnt
                  }
                })
              }
              return tabobj
            }) : []
      },
       ElementAnimationDialog(){
            return this.$store.state.ElementAnimationDialog
        },
      RelatedRecordObj(){
        return this.$store.state.RelatedRecordObj
      },
      ConfirmMSGDialog(){
        return this.$store.state.ConfirmMSGDialog
      },
      ConfirmMSGText(){
        return this.$store.state.ConfirmMSGText
      },
      ConfirmMSGTitle(){
        return this.$store.state.ConfirmMSGTitle
      },
      ConfirmMSGIcon(){
        return this.$store.state.ConfirmMSGIcon
      },
      ConfirmMSGIconColor(){
        return this.$store.state.ConfirmMSGIconColor
      },
      AlertSound(){
        return this.$store.state.AlertSound
      },
      RADB(){
            return this.$store.state.RADB
        },
      ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
        DocLibisRADB(){
            return this.ActiveDocumentationLibrary && this.ActiveDocumentationLibrary.Database && this.ActiveDocumentationLibrary.Database === this.RADB
        },
      PhotoTagObjects(){
        return this.$store.state.PhotoTagObjects
      },
      UploadFilesList(){
        return this.$store.state.UploadFilesList
      },
      UploadFileStorageRef(){
        return this.$store.state.UploadFileStorageRef
      },
      UploadFilesDBStorageRef(){
        return this.$store.state.UploadFilesDBStorageRef
      },
      ComputedUploadFiles(){
        return this.UploadFiles
      },
      CompletedUploadFiles(){
        return this.UploadFiles.filter(file => {
          return file.Uploaded === true
        })
      },
      
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      MiniRecordDialog(){
        return this.$store.state.MiniRecordDialog
      },
      MiniRecordDialogTitle(){
        return this.$store.state.MiniRecordDialogTitle
      },
      MiniRecordDialogIMG(){
        return this.$store.state.MiniRecordDialogIMG
      },
      MiniRecordDialogDescription(){
        return this.$store.state.MiniRecordDialogDescription
      },
      MiniRecordObject(){
        return this.$store.state.MiniRecordObject
      },
      MiniRecordPath(){
        return this.$store.state.MiniRecordPath
      },
      MiniRecordTabs(){
        return this.$store.state.MiniRecordTabs
      },
      IsGroupView(){
        return this.$store.state.IsGroupView
    },
      BuilderView(){
      return this.$store.state.BuilderView
      },
      WebsiteBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Website Builder'
      },
      DBBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Database Builder'
      },
      NetworkBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
      },
      ActiveGroup(){
        return this.$store.state.ActiveGroup
      },
      IMGProp(){
        return this.$store.state.IMGProp
      },
      EditedPageProperties(){
        return this.$store.state.EditedPageProperties
      },
      EditedTab(){
        return this.$store.state.EditedTab
      },
      UseGroupAssets(){
          return this.$route.name === 'GroupPageBuilder' && this.ActiveGroup || this.$route.name === 'GroupPageEditor' && this.ActiveGroup
      },
      GalleryAssets(){
        return this.$store.state.GalleryAssets
      },
      GalleryLibraries(){
        if(this.ForGallery){
          return this.SystemPhotoLibraries.map(lib => {
            let routes = this.$router.getRoutes()
            let path = Object.assign({},routes.find(obj => obj.name === lib.Collection))
            path.TableCollectionRef = db.collection(lib.Collection)
            path.EntityCollectionRef = db.collection('SystemConfig').doc(this.System.id).collection('PhotoLibraries').doc(lib.id)
            return path
          })
        }
        else{
           return this.DocLibisRADB ? [
          {compname: 'SystemAssets',name: 'System Assets',TableCollectionRef: this.RADB.collection('systemassets'),CustomTags: [],Assets: this.$store.state.RASystemAssets}
        ]
        : this.$store.state.GalleryLibraries
        }
       
      },
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 12
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.xl){
          return 2
        }
      },
      RandomInteractProp(){
      return this.$store.state.RandomInteractProp
      },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
      ViewFileURL(){
        return this.OrderConfirmationPDF ? this.OrderConfirmationPDF.fileurl : ''
      },
      OrderConfirmationPDF(){
        return this.$store.state.OrderConfirmationPDF
      },
      OrderConfirmationDialog(){
        return this.$store.state.OrderConfirmationDialog
      },
      PDFDataRecord(){
         let sampleobj = JSON.parse(JSON.stringify(this.ComputedPDFObject))
        sampleobj.SubCollections = []
        return sampleobj
      },
      AppMonetizeBU(){
          return this.System.Monetization_BU ? this.System.Monetization_BU : ''
      },
      LineHeadersforTable(){
        if(this.SelectedFunctionName === 'Sales_Invoices' && this.MarketDialogType === 'Group Directory Listing'
        || this.SelectedFunctionName === 'Sales_Invoices' && this.MarketDialogType === 'Group Membership'
        || this.SelectedFunctionName === 'Sales_Invoices' && this.MarketDialogType === 'View Statement Invoice'){
          return this.GroupInvoiceLineHeaders.filter(head => {
             return !head.TableHidden
        })
        .filter(head => {
          if(this.MarketDialogType === 'View Statement Invoice'){
            return head.text !== 'Group'
          }
          else{
            return head
          }
             
        })
        }
      },
      LineItemsTable(){
          let headerslength = this.LineHeadersforTable.length
          let html = `<table style="width:550px;border: 1px solid grey;border-collapse: collapse;">
                      <tr style="border-bottom: 1px solid grey;padding-bottom: 3px;">`
          this.LineHeadersforTable.map((head,headindex) => {
              if(headindex-1+2 === headerslength){
              html = html+`
              <th class="overline" style="text-align: center;padding: 8px;border: 1px solid grey;">`+head.text+`</th>            
              </tr>`
              }
              else{
              html = html+`
              <th class="overline" style="text-align: center;padding: 8px;border: 1px solid #dddddd;">`+head.text+`</th>`    
              }
          })
          html = html+`
          </tr>`
          this.ComputedDocLineItems.map(lineitem => {
            html = html+`
            <tr class="detailslistoutline">`
            this.LineHeadersforTable.map((head,headnindex) => {
              //console.log(head.propvalue,lineitem,lineitem[head.propvalue])
              let value = lineitem[head.propvalue]
              if(head.Type === 'Number Field' && head.IsCurrency){
                value = this.CurrencyFormatter(value,this.$store.state.DefaultCurrency.Currency)
              }
              else if(head.Type === 'Date' && !head.HasTimeInput){
                value = value.toDateString()
              }
              else if(head.Type === 'Lookup'){
                value = value[head.LookupFieldName]
              }
              if(headnindex-1+2 === headerslength){
              html = html+`
              <td style="text-align: left;padding: 8px;border: 1px solid #dddddd;font-size: 10px;">`+value+`</td>            
              </tr>`
              }
              else{
              html = html+`
              <td style="text-align: left;padding: 8px;border: 1px solid #dddddd;font-size: 10px;">`+value+`</td>`    
              }
            })
          })
          html = html+`
          </table>`
          //console.log(html)
          return html
      },
      PDFHeaderObj(){
        return this.$store.state.PDFHeaderObj
      },
      PDFFooterObj(){
        return this.$store.state.PDFFooterObj
      },
      ComputedPDFObject(){
        let PDFObject = {}
        if(this.ExportingtoPDFDialog){
        //FROM HEADER OBJ
        PDFObject.RecordPrimaryFieldName = this.PDFHeaderObj.Primary_Field_Name
        PDFObject[PDFObject.RecordPrimaryFieldName] = this.PDFHeaderObj.Recordid        
        PDFObject.Created_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        PDFObject.Created_Byid = this.userLoggedIn.id
        PDFObject.Modified_By = {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}
        PDFObject.Modified_Byid = this.userLoggedIn.id
        PDFObject.Created_On = new Date()
        PDFObject.Modified_On = new Date()
        PDFObject.Client = this.PDFHeaderObj.Client
        PDFObject.Clientid = this.PDFHeaderObj.Clientid
        PDFObject.Company_Name = this.PDFHeaderObj.Company_Name
        PDFObject.Customer_Company = this.PDFHeaderObj.Customer_Company
        PDFObject.Customer_Contact = this.PDFHeaderObj.Customer_Contact
        PDFObject.Customer_ID = this.PDFHeaderObj.Customer_ID
        PDFObject.Delivery = this.PDFHeaderObj.Delivery
        PDFObject.Reference_Number = this.PDFHeaderObj.Reference_Number        
        PDFObject.Progress = this.PDFHeaderObj.Progress
        PDFObject.Business_Unit = this.PDFHeaderObj.Business_Unit
        PDFObject.Business_Unitid = this.PDFHeaderObj.Business_Unitid
        PDFObject.BillingAccount = this.PDFHeaderObj.BillingAccount
        PDFObject.BillingAccountid = this.PDFHeaderObj.BillingAccountid        
        PDFObject.Payment_Terms = this.PDFHeaderObj.Payment_Terms
        PDFObject.Payment_Period = this.PDFHeaderObj.Payment_Period
        PDFObject.Shipping_Method = this.PDFHeaderObj.Shipping_Method
        PDFObject.Due_Date = this.PDFHeaderObj.Due_Date
        //FROM FOOTEROBJ
        PDFObject.Footer_Note = this.PDFFooterObj.Footer_Note
        PDFObject.Tax_Total = this.PDFFooterObj.Tax_Total
        PDFObject.Tax_Percentage = this.PDFFooterObj.Tax_Percentage
        PDFObject.Sub_Total = this.PDFFooterObj.Sub_Total
        PDFObject.Discount_Total = this.PDFFooterObj.Discount_Total
        PDFObject.Freight_Total = this.PDFFooterObj.Freight_Total
        PDFObject.Grand_Total = this.PDFFooterObj.Grand_Total
        PDFObject[this.PDFFooterObj.TotalFieldProp] = this.PDFFooterObj.TotalFieldValue
        //the deliverya ddress could depend on the type of items exporting
        this.Company_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Company_Address_').join(this.PDFHeaderObj.Company_Address_Prop)
          let brdname = brd.Name
          if(this.PDFHeaderObj[brdname]){
            PDFObject[newname] = this.PDFHeaderObj[brdname]
          }
        })
        this.Billing_Address.FieldBreakdown.map(brd => {
          let newname = brd.Name.split('Billing_Address_').join(this.PDFHeaderObj.Billing_Address_Prop)
          let brdname = brd.Name
          if(this.PDFHeaderObj[brdname]){
            PDFObject[newname] = this.PDFHeaderObj[brdname]
          }
        })
        // PO has Supplier_Invoice_Address_  (suppl pobox), PO_Bill_To_Address_ (bill us, our address) , PO_Delivery_Address_ (if delivery, our WH delivery) , Supplier_Collection_Address_ (if not del, suppl phys add)
        //I am howvwer missing client delivery address for store order type
        }
        
        return PDFObject
      },
      ComputedDocLineItems(){
        return this.$store.state.PDFDocLineItems
      },
      PDFFunctionData(){
         if(this.ExportingtoPDFDialog){
           console.log(this.PDFExportFunctionData,this.SelectedFunctionName,this[this.SelectedFunctionName],this.Sales_Invoices)
        let functiondata = JSON.parse(JSON.stringify(this.PDFExportFunctionData))
        //LineItemsTable
        let newelemnt = {Name: '',AllowColumnRollover: false, AllowPageRollover: false,ForceNewColumn: false,ForceNewPage: false,LineColor: {r: 0,g:0,b:0},DrawColor: {r: 0,g:0,b:0},FillColor: {r: 0,g:0,b:0},FontColor: {r: 89,g:89,b:89}}
        newelemnt.Name = 'Goods Table'
        newelemnt.OutputType = 'HTML'
        newelemnt.RawHTML = true
        newelemnt.RawHTMLid = 'LineItemsTable'
        newelemnt.ElementWidth = 550
        newelemnt.DataSource = 'Custom Text'
        newelemnt.TextValue = this.LineItemsTable
        newelemnt.Justify = 'center'
        if(this.ComputedDocLineItems.length <= 3){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedDocLineItems.length <= 6){
          newelemnt.PaddingTop = 350
        }
        else if(this.ComputedDocLineItems.length <= 9){
          newelemnt.PaddingTop = 375
        }
        else if(this.ComputedDocLineItems.length <= 12){
          newelemnt.PaddingTop = 350
        }
        else if(this.ComputedDocLineItems.length <= 15){
          newelemnt.PaddingTop = 325
        }
        else if(this.ComputedDocLineItems.length <= 18){
          newelemnt.PaddingTop = 300
        }
        else {
          newelemnt.PaddingTop = 300
        }
        
        //if 3 or less then 450
        //else if 6 or less
        newelemnt.PaddingLeft = 0
        newelemnt.WidthOption = 'Full Page'
        if(this.ComputedDocLineItems.length > 0){
         functiondata.Pages[0].Elements.push(newelemnt) 
        }        
        return functiondata
         }
      },
      PDFExportFunctionData(){
        return this.SelectedFunctionName ? this[this.SelectedFunctionName] : ''
      },
      SelectedFunctionName(){
        return this.$store.state.PDFFunctionName
      },
      ExportingtoPDFDialog(){
        return this.$store.state.ExportingtoPDFDialog
      },
      PDFDownloadOnly(){
        return this.$store.state.PDFDownloadOnly
      },
      UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
        DefaultBillingAccount(){
          return this.UserBillingAccounts && this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Store Account' && obj.Type_Default) : ''
      },
        
        WishListItems(){
            return this.DefaultBillingAccount && this.DefaultBillingAccount.WishList ? this.DefaultBillingAccount.WishList : []
        },
      MarketDialogOptions(){
        return this.$store.state.MarketDialogOptions
      },
      MarketDialogObject(){
        return this.$store.state.MarketDialogObject
      },
      MarketDialog(){
        return this.$store.state.MarketDialog
      },
      MarketDialogType(){
        return this.$store.state.MarketDialogType
      },
      MarketDialogDescription(){
        return this.$store.state.MarketDialogDescription
      },
      field(){
        if(this.MarketDialogType === 'Group Membership' || this.MarketDialogType === 'Group Directory Listing'){
          return this.PackageField
        }
        else if(this.MarketDialogType === 'Plugin Purchase'){
          return this.PluginField
        }
      },
      PluginField(){
        let fieldobj = {id: 'Plugin',Name: 'Plugin',DisplayName: 'Plugin',value: 'Plugin',text: 'Plugin Package',
            Type: 'Lookup',LookupFieldName: 'Name',Array: this.MarketDialogOptions, Response: '',IsMandatory: true,
            LookupSubtitleField: {Name: 'Price',Type: 'Number Field',IsCurrency: true},
            LookupCaptionField: {Name: 'Description'},
            LookupAvatarField: {Name: 'IMG'},Has_Selection_Dial: true,DialType: 'Lookup Cards',DialHeader: 'Select a Subscription Package',DialCaption: 'The packages available for your selections are:'}
            return fieldobj
      },
      PackageField(){
            //console.log(this.NewGroupFeatures,this.NewGroupPaidFeatures,this.NewGroupScope,this.NewGroupPaidScope)
            let fieldobj = {id: 'Subscription_Package',Name: 'Subscription_Package',DisplayName: 'Subscription Package',value: 'Subscription_Package',text: 'Subscription Package',
            Type: 'Lookup',LookupFieldName: 'Name',Array: this.MarketDialogOptions, Response: '',IsMandatory: true,
            LookupSubtitleField: {Name: 'Price',Type: 'Number Field',IsCurrency: true},
            LookupCaptionField: {Name: 'Description'},
            LookupAvatarField: {Name: 'IMG'},Has_Selection_Dial: true,DialType: 'Lookup Cards',DialHeader: 'Select a Subscription Package',DialCaption: 'The packages available for your selections are:'}
            return fieldobj
        },
      MarketDialogTitle(){
        return this.$store.state.MarketDialogTitle
      },
      MarketDialogInteractMethod(){
        return this.$store.state.MarketDialogInteractMethod
      },
      MarketDialogAvatar(){
        return this.$store.state.MarketDialogAvatar
      },
      CustomProcessingDialog(){
        return this.$store.state.CustomProcessingDialog
      },
      CustomProcessingDialogText(){
        return this.$store.state.CustomProcessingDialogText
      },
      RouteObj(){
        return this.$route
      },
      MustReadNotices(){
        return this.RouteRefresh ? [] : this.mustreadnotices.filter(notice => {
          return !notice.Responded
        })
      },
      InvitedGroups(){
        return this.RouteRefresh ? [] : this.$store.state.InvitedGroups
        },
      userLoggedIn () {
        return this.$store.getters.user
      },
      userBUID () {
        return this.$store.getters.userBUID
      },
      userBUChildren () {
        return this.$store.getters.userBUChildren
      },
      userBUParents () {
        return this.$store.getters.userBUParents
      },
      userBU () {
      return this.$store.state.buobj
      },
      ActivityStatusField(){
      //console.log('SystemEntities',this.SystemEntities)
      let activityentity = this.SystemEntities.find(obj => obj.id === 'Activities')
      //console.log('SystemEntities activityentity',activityentity)
      if(activityentity){
        return activityentity.AllFields.find(obj => obj.id === 'Status')
      }
    },
      RecordLoadingText(){
        if(this.RecordLoadingvalue === 25){
          return 'Getting Config'
        }
        if(this.RecordLoadingvalue === 50){
          return 'Checking Routing'
        }
        if(this.RecordLoadingvalue === 75){
          return 'Getting Data'
        }
        if(this.RecordLoadingvalue === 100){
          return 'Finalizing'
        }
      },
      RecordLoadingColor(){
        if(this.RecordLoadingvalue === 25){
          return 'red'
          //#ee7752
        }
        if(this.RecordLoadingvalue === 50){
          return 'pink'
          //#e73c7e
        }
        if(this.RecordLoadingvalue === 75){
          return 'blue'
          //#23a6d5
        }
        if(this.RecordLoadingvalue === 100){
          return 'teal'
          //#23d5ab
        }
      },
      SystemLoadingText(){
        if(this.SystemLoadingvalue === 100){
          return 'Finalizing'
        }
        else if(this.SystemLoadingvalue >= 75){
          return 'Assigning User'
        }
        else if(this.SystemLoadingvalue >= 0){
          return 'Loading Suite'
        
        }
        // else if(this.SystemLoadingvalue >= 50){
        //   return 'Getting System'
        // }
      },
      SystemLoadingColorBG(){
        if(this.SystemLoadingvalue === 100){
          return '#20bc98'
        }
        else if(this.SystemLoadingvalue >= 75){
          return '#50a18e'
        }
        else if(this.SystemLoadingvalue >= 50){
          return '#001c2c'
        
        }
        else if(this.SystemLoadingvalue >= 0){
          return '#000000'
        }
      },
      SystemLoadingColorNext(){
        if(this.SystemLoadingvalue === 100){
          return '#23d5ab'
        }
        else if(this.SystemLoadingvalue >= 75){
          return '#23d5ab'
        }
        else if(this.SystemLoadingvalue >= 50){
          return '#23a6d5'
        
        }
        else if(this.SystemLoadingvalue >= 0){
          return '#e73c7e'
        }
      },
      SystemLoadingColor(){
        if(this.SystemLoadingvalue === 100){
          return '#23d5ab'
        }
        else if(this.SystemLoadingvalue >= 75){
          return '#23a6d5'
        }
        else if(this.SystemLoadingvalue >= 50){
          return '#e73c7e'
        
        }
        else if(this.SystemLoadingvalue >= 0){
          return '#ee7752'
        }
      },
        Computedsnackbars(){
            return this.snackbars
            .map(s => {
                if(s.timeout){
                     setTimeout(() => {
                   s.show = false 
                }, s.timeout);
                }
               
                return s
            })
            .filter(s => {
                return s.show
            })
        },
            FourSeconds(){
                let date = new Date()
                date.setSeconds(date.getSeconds() + 4)
                return date
            },
      TimesheetRelated(){
        return this.$store.state.TimesheetRelated
      },
      StopwatchDialog(){
        return this.$store.state.StopwatchDialog
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        
           
    },
    mounted () {
     
    },
    created(){
      this.CheckforTimesheet()
      this.PrepareAddressField('Company_Address')
      this.PrepareAddressField('Billing_Address')
      this.PrepareAddressField('Delivery_Address')
      
       window.onload = setTimeout(() => {
        this.ResizeWindow()
        }, 250)
        window.addEventListener("resize", this.ResizeWindow);
      //let myVar = setInterval(this.myTimer, 1000);       
    },
    methods: {
      ConfirmWebformSubmission(tab,obj,data){
        console.log(tab,obj,data)
        this.WebFormStep++
      },
      ActivateWebFormDialog(WebForm){
        console.log(WebForm)
        this.WebForm = WebForm
        this.WebFormDialog = true
      },
      DeactivateWebFormDialog(postsubmit){
        this.WebFormDialog = false
        this.WebForm = false
        this.WebFormStep = 1
      },
      ProcessSingleFileUpload(payload){
        let file = payload.file
        let filename = payload.filename
        let storepath = payload.storepath
        let returnmethod = payload.returnmethod
        let PassedObj = payload.PassedObj
        let vm = this
        var storageRef = firebase.storage().ref(storepath)
        var uploadTask = storageRef.put(file)
        vm.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+filename);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                 vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingFile = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
              });
            });
        uploadTask
        .then(snapshot => snapshot.ref.getDownloadURL())
          .then((url) => {
            let UploadObject = {
              fileurl: url,
              url: url,
              FileType: file.type,
              Modified_By: {Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
              Modified_Byid: vm.userLoggedIn.id,
              Modified_On: new Date(),
              ModifiedDate: new Date(file.lastModified),
              Size: file.size,
              StorageRef: storepath,
              filename: filename
            } 
            let returnobj = {UploadObject: UploadObject,PassedObj: PassedObj}           
            this.$store.commit('SetSocialItemInteractMethodProp',returnobj)
            this.$store.commit('SetSocialItemInteractMethod',returnmethod)
          })
          }, 1000);
      },
      SendInvitetoUser(){
      this.ProcessInvitetoUser()
    },
    ProcessInvitetoUser(){
      //console.log('will invite user on '+this.InvitingUserEmail)
      
      let NewUserObj = {
        Email: this.editedInvite.Email,
        Status: this.editedInvite.Status,
        Business_Unit: this.editedInvite.Business_Unit,
        Business_Unitid: this.editedInvite.Business_Unit.id,
        Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Created_Byid: this.userLoggedIn.id,
        Created_On: new Date(),
       
      }
      if(this.InvitebyCompany){
        NewUserObj.Company = {Company_Name: this.userLoggedIn.Company.Company_Name,id: this.userLoggedIn.Companyid}
        NewUserObj.Companyid = this.userLoggedIn.Companyid
      }
      if(this.editedInvite.RelatedObj){
        NewUserObj.RelatedObj = this.editedInvite.RelatedObj
      }
      if(this.editedInvite.RelatedField){
        NewUserObj.RelatedField = this.editedInvite.RelatedField
      }
      if(this.editedInvite.Primary_Appid){
        NewUserObj.Primary_Appid = this.editedInvite.Primary_Appid
      }
      if(this.editedInvite.UserTableFilters.length > 0){
        NewUserObj.UserTableFilters = this.editedInvite.UserTableFilters
      }
      if(this.editedInvite.InvitingUserRoles && this.editedInvite.InvitingUserRoles.length > 0){
        NewUserObj.rolesarrayQuery = []
        NewUserObj.rolesarrayDBRules = {}
        this.InvitingUserRoles.map(role => {
          NewUserObj.rolesarrayDBRules[role.split(' ').join('')] = true
          NewUserObj.rolesarrayQuery.push(role)
        })
        
      }
      
      if(this.editedInvite.MemberGroup){
        let memgroup = this.editedInvite.MemberGroup
      let addpropsobj = memgroup.AdditionalProperties
            for(var prop in addpropsobj) {
              if (addpropsobj.hasOwnProperty(prop)) {
                if(typeof addpropsobj[prop] !== 'undefined'){
                    NewUserObj[prop] = addpropsobj[prop]
                }
              }
            }
        NewUserObj.MemberGroup = this.editedInvite.MemberGroup
        NewUserObj.MemberGroupid = this.editedInvite.MemberGroup.id
        if(this.editedInvite.MemberGroup.UserTableFilters && this.editedInvite.MemberGroup.UserTableFilters.length > 0){
          NewUserObj.UserTableFilters = this.editedInvite.MemberGroup.UserTableFilters
        }
        if(this.editedInvite.MemberGroup.SecurityRoles && this.editedInvite.MemberGroup.SecurityRoles.length > 0){
          NewUserObj.rolesarrayQuery = []
          NewUserObj.rolesarrayDBRules = {}
          this.editedInvite.MemberGroup.SecurityRoles.map(role => {
            NewUserObj.rolesarrayDBRules[role.split(' ').join('')] = true
            NewUserObj.rolesarrayQuery.push(role)
          })
           this.CompleteInvitetoUser(NewUserObj)
        }
        else if(this.editedInvite.MemberGroup.SecurityRoles && this.editedInvite.MemberGroup.SecurityRoles.length === 0){
          this.CompleteInvitetoUser(NewUserObj)
        }
      }
      else{
         NewUserObj.IsGuestUser = this.editedInvite.IsGuestUser
        this.CompleteInvitetoUser(NewUserObj)
      }
      
    },
    CompleteInvitetoUser(NewUserObj){
      console.log(NewUserObj)
      if(!NewUserObj.IsGuestUser){
        NewUserObj.IsGuestUser = false
      }
      NewUserObj.Expired = false
      let vm = this
      let ref = db.collection('userinvites').doc()
      let newdocid = ref.id
      db.collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
        if(NewUserObj.RelatedObj){
          this.$store.commit('SetSocialItemInteractMethodProp',{RelatedObj: NewUserObj.RelatedObj,RelatedField: NewUserObj.RelatedField, InviteID: newdocid})
          this.$store.commit('SetSocialItemInteractMethod','AssignInviteid')
        }
        
      })

      let path = this.System.ClientAppURL+'/UserInvite/'+newdocid
      this.EmailBody = `Good Day<div><br></div><div>You have been invited to join `+this.System.Name+`. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
      <a href="`+path+`">Open your Invite</a><br>
      <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
      <div>Thank you in advance</div>`
      //console.log(this.EmailBody)
        let EmailObject = {          
          EmailFrom: 'notification@rapidapps.co.za',
          EmailTo: this.editedInvite.Email,
          EmailSubject: 'Invite to join - '+this.System.Name,
          EmailBody: this.EmailBody,

        }
            //alert('email sending')
            //console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            console.log(functions)
            console.log(SendEmailNow)
            SendEmailNow(EmailObject).then(result => {
              //console.log(result)
              let snackbarcontent = {
                  snackbartimeout: 4000,
                  snackbartext: 'Invite Sent to  - '+vm.editedInvite.Email,
                  snackbartop: true,
                  boolean: true
                }
                vm.$store.commit('SetSocialItemInteractMethodProp', snackbarcontent)
                vm.$store.commit('SetSocialItemInteractMethod', 'CommitSnackbar')
              vm.CancelInvitingUserEmail()
            })
    },
    CancelInvitingUserEmail(){
      this.editedInvite = Object.assign({},this.defaulteditedInvite)
      this.InviteUserDialog = false
      // this.InviteUserBU = ''
      // this.ModulePath = ''
    },
      AcceptInviteUserDialog(Related){
        if(Related){
          this.editedInvite = Object.assign({},this.defaulteditedInvite)
          this.editedInvite.RelatedObj = Related.RelatedObj
          this.editedInvite.RelatedField = Related.RelatedField
        }
      if(!this.userIsAdmin){
        this.editedInvite.Business_Unit = {id: 'Guest',Name: 'Guest'}
      }
      if(this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties.InviteGroups.length === 1){
        this.editedInvite.MemberGroup = this.MemberGroupsArray.find(obj => obj.id === this.ActiveMemberGroup.AdditionalProperties.InviteGroups[0])
      }
        console.log(this.editedInvite)
      this.InviteUserDialog = true
    },
      RunWebAnimations(){
            let Elements = this.AnitmatedElements
            //console.log(Elements)
            Elements.map((elmnt,elmntindex) => {
                let propoptions = ['Tab','Title','Description','IMG']
                propoptions.map(opt => {   
                     let e = document.getElementById(elmnt.ElementID+'_'+opt)
                     //console.log(e)
                    if(elmnt.AnimationKeyframesObject[opt] && elmnt.Animations[opt] && e && e !== null){
                        let elmntkeyframes = []
                        let eanimobj = Object.assign({},elmnt.AnimationKeyframesObject[opt])
                        let animprops = {}
                        let cssprop = elmnt.AnimationProperties[opt]
                        // console.log(cssprop)
                        if(typeof cssprop.duration !== 'undefined'){
                            animprops.duration = cssprop.duration
                        }
                        if(typeof cssprop.fill !== 'undefined' && cssprop.fill !== null){
                            animprops.fill = cssprop.fill
                        }
                        if(typeof cssprop.easing !== 'undefined' && cssprop.easing !== null){
                            animprops.easing = cssprop.easing
                        }
                         if(typeof cssprop.iterations !== 'undefined'){
                            animprops.iterations = cssprop.iterations
                        }
                        Object.keys(eanimobj).forEach(function(prop,index) {
                            if(prop.includes('%')){
                                    //okay the point being now we must dissect this
                                    let perc = (Number(prop.split('%').join('')))/100
                                    if(!elmntkeyframes[index]){
                                        elmntkeyframes[index] = {}
                                    }
                                    elmntkeyframes[index].iterationStart = perc
                                }
                                for(var prp in eanimobj[prop]) {	                                    
                                    if (eanimobj[prop].hasOwnProperty(prp)) {
                                        if(!elmntkeyframes[index]){
                                            elmntkeyframes[index] = {}
                                        }
                                        elmntkeyframes[index][prp] = eanimobj[prop][prp]
                                    }
                                }
                        });
                        elmntkeyframes = elmntkeyframes.sort(function(a, b){return a.iterationStart - b.iterationStart})
                        let animobj = new KeyframeEffect(
                        e, // element to animate
                        elmntkeyframes,
                        animprops // keyframe options
                        );
                        let newanim = new Animation(animobj, document.timeline)
                        newanim.play()     
                    }
                    
                        })
            })
        },
      CheckAnimKeyframeObj(tab,prop){
        if(!tab.Animations){
          tab.Animations = {}
        }
        if(!tab.Animations[prop]){
          tab.Animations[prop] = false
        }
        if(!tab.AnimationKeyframes){
          tab.AnimationKeyframes = {}
          tab.AnimationKeyframesObject = {}
        }
        if(!tab.AnimationCSS){
          tab.AnimationCSS = {}
        }
        if(!tab.AnimationKeyframesObject[prop]){
          tab.AnimationKeyframes[prop] = ''
          tab.AnimationKeyframesObject[prop] = {}
        }
        if(!tab.AnimationKeyframes[prop]){
          tab.AnimationKeyframes[prop] = ''
        }
        if(!tab.AnimationProperties){
          tab.AnimationProperties = {}
        }
        if(!tab.AnimationProperties[prop]){
          tab.AnimationProperties[prop] = {
                    duration: 2000,
                    fill: 'forwards',
                    easing: 'ease-in',
                    iterations: +Infinity,
                    Infinite: true,
                }
        }
      },
      isOdd(num) {
            return !num % 2;
        },
        AnimationUpdate(objprop,id){
          let propvalue = this.EditedTab.AnimationKeyframes[objprop]		
          //console.log(objprop,propvalue)
          let contentbox = document.getElementById(id)		
            //RIGHT SO BELOW WE GOT JSON OBJ AND EACH PRP ON IT IS EITHER PERCENTAGE OF COMPLETION, OR TO OR FROM
            //SO IF YOU COULD SPLIT PRP ITSELF WITH '%' YOU KNOW IT'S PERCENTAGE IF NOT IT'S EITHER OF THE OTHER
            //NONETHELESS THE BIG THING HERE IS NOW WELL...MAKING WORK OF THE REST
            //1. STD THINGS WE CAN USE 2. PRP THAT NEED NAME ALTERS 3. TBD			
            if(contentbox){									
                this.EditedTab.AnimationKeyframes[objprop] = contentbox.innerHTML
                let jsonobj = {}
                let innerHTML = contentbox.innerHTML
                let innerText = contentbox.innerText
                let outerHTML = contentbox.outerHTML
                let outerText = contentbox.outerText
                // console.log(innerHTML)	
                // console.log(innerText)
                // console.log(outerHTML)
                // console.log(outerText)	
               
                let lines = outerText.split('}').join('}|').split('|').map(line => {
                  return line.split('\n').join('').trim()
                 }).filter(nline => {
                  return nline
                 })
                console.log(lines)
                 let nonemptylines = lines.map(noline => {
                  let nolinetext = {
                    [noline.split(':')[0]]: noline.split(':')[1]
                  }
                  jsonobj[noline.split('{')[0].trim()] = {}
                  noline.split('{')[1].split('\n').map(item => {
                    return item.trim()
                  }).join('').split('}').map(item => {
                    return item.trim()
                  }).join('').split(';').map(item => {
                    return item.trim()
                  })
                  .filter(prp => {
                    return prp
                  }).map(prp => {
                    let prptxt = prp.split('"').join('')
                    let newprp = prptxt.split(':')[0].trim()
                    if(newprp.includes('-')){
                      newprp = newprp.split('-').map((str,strindex) => {
                        if(strindex !== 0){
                          str = str.charAt(0).toUpperCase()+str.slice(1)
                        }
                        return str
                      }).join('')
                    }
                    jsonobj[noline.split('{')[0].trim()][newprp] = prptxt.split(':')[1].trim()
                  })
                  return nolinetext
                 })
                 let stringvalue = JSON.stringify(jsonobj)
                 //console.log(nonemptylines,jsonobj,stringvalue)
                 this.EditedTab.AnimationKeyframesObject[objprop] = jsonobj
                }
            console.log(this.EditedTab)
        },
        AnimationKeyframeUpdate(prop,id){							
            let contentbox = document.getElementById(id)		
            //RIGHT SO BELOW WE GOT JSON OBJ AND EACH PRP ON IT IS EITHER PERCENTAGE OF COMPLETION, OR TO OR FROM
            //SO IF YOU COULD SPLIT PRP ITSELF WITH '%' YOU KNOW IT'S PERCENTAGE IF NOT IT'S EITHER OF THE OTHER
            //NONETHELESS THE BIG THING HERE IS NOW WELL...MAKING WORK OF THE REST
            //1. STD THINGS WE CAN USE 2. PRP THAT NEED NAME ALTERS 3. TBD			
            if(contentbox){									
                this.EditedTab[prop] = contentbox.innerHTML
                let jsonobj = {}
                let innerHTML = contentbox.innerHTML
                let innerText = contentbox.innerText
                let outerHTML = contentbox.outerHTML
                let outerText = contentbox.outerText
                console.log(innerHTML)	
                console.log(innerText)
                console.log(outerHTML)
                console.log(outerText)	
               
                let lines = outerText.split('}').join('}|').split('|').map(line => {
                  return line.split('\n').join('').trim()
                 }).filter(nline => {
                  return nline
                 })
                console.log(lines)
                //RETURNS OBJ WITH ARRAY OF PROPS UNSURE IF WANTED
                //  let nonemptylines = lines.map(noline => {
                //   let nolinetext = {
                //     [noline.split(':')[0]]: noline.split(':')[1]
                //   }
                //   jsonobj[noline.split('{')[0].trim()] = noline.split('{')[1].split('\n').map(item => {
                //     return item.trim()
                //   }).join('').split('}').map(item => {
                //     return item.trim()
                //   }).join('').split(';').map(item => {
                //     return item.trim()
                //   })
                //   .filter(prp => {
                //     return prp
                //   }).map(prp => {
                //     let prptxt = prp.split('"').join('')
                //     console.log(prptxt)
                //     let obj = {
                //       [prptxt.split(':')[0].trim()]: prptxt.split(':')[1].trim()
                //     }
                //     return obj
                //   })
                //   return nolinetext
                //  })
                 let nonemptylines = lines.map(noline => {
                  let nolinetext = {
                    [noline.split(':')[0]]: noline.split(':')[1]
                  }
                  jsonobj[noline.split('{')[0].trim()] = {}
                  noline.split('{')[1].split('\n').map(item => {
                    return item.trim()
                  }).join('').split('}').map(item => {
                    return item.trim()
                  }).join('').split(';').map(item => {
                    return item.trim()
                  })
                  .filter(prp => {
                    return prp
                  }).map(prp => {
                    let prptxt = prp.split('"').join('')
                    jsonobj[noline.split('{')[0].trim()][prptxt.split(':')[0].trim()] = prptxt.split(':')[1].trim()
                  })
                  return nolinetext
                 })
                 let stringvalue = JSON.stringify(jsonobj)
                 console.log(nonemptylines,jsonobj,stringvalue)
                 //okay jsonobj...so properties are assigned impressively well..question
                 //do we make subprops an array? like 50% has array of index 0 filter = blur(5px) then index 1 is opacity: 0.5 etc?

                // let colonsplit = nolines.split('|').map((line,index) => {
                //   let linetxt = line.trim()
                //   // if(this.isOdd(index)){
                //   //   linetxt = '"'+linetxt+'"'
                //   // }
                //   return linetxt
                // })
                // console.log(colonsplit)
                //  let obj = JSON.parse(contentbox.innerHTML)
                //  console.log(obj)
                 }
            console.log(this.EditedTab)
            
      },
      WriteupUpdate(prop,id){							
            let contentbox = document.getElementById(id)	
             //otxsplit is the stringvalue we typically use in elements, when it comes to css anyway
             //also for css, the jsonobj is perfect. as an obj, if any obj were needed
             //but we really only need objects on animattion					
            if(contentbox){									
                this.EditedTab[prop] = contentbox.innerHTML
                let jsonobj = {}
                let innerHTML = contentbox.innerHTML
                let innerText = contentbox.innerText
                let outerHTML = contentbox.outerHTML
                let outerText = contentbox.outerText
                console.log(innerHTML)	
                console.log(innerText)
                console.log(outerHTML)
                console.log(outerText)		
                let otxtsplit = outerText.split('}').join('').split('{').join('').split('\n').join('')
                console.log(otxtsplit)
               
                let lines = outerText.split(';').map(line => {
                  return line.trim()
                 }).filter(nline => {
                  return nline
                 })
                console.log(lines)
                 let nonemptylines = lines.map(noline => {
                  let nolinetext = {
                    [noline.split(':')[0]]: noline.split(':')[1]
                  }
                  jsonobj[noline.split(':')[0]] = noline.split(':')[1].split('\n').map(item => {
                    return item.trim()
                  }).join('').trim()
                  return nolinetext
                 })
                 let stringvalue = JSON.stringify(jsonobj)
                 console.log(nonemptylines,jsonobj,stringvalue)
                // let colonsplit = nolines.split('|').map((line,index) => {
                //   let linetxt = line.trim()
                //   // if(this.isOdd(index)){
                //   //   linetxt = '"'+linetxt+'"'
                //   // }
                //   return linetxt
                // })
                // console.log(colonsplit)
                //  let obj = JSON.parse(contentbox.innerHTML)
                //  console.log(obj)
                 }
            console.log(this.EditedTab)
            
      },
      CheckInfinite(tab){
        if(!tab.Infinite){
          tab.iterations = 1
        }
        else{
          tab.iterations = +Infinity
        }
      },
      ToggleElementAnimationDialog(){
            this.$store.commit('ToggleElementAnimationDialog',!this.ElementAnimationDialog) 
            this.$store.commit('SetEditedPageProperties',{})
            this.$store.commit('setEditedTab','')
            this.AnimationObject = ''
        },
      UpdateNewChild(parentobj){
        this.$store.commit('SetSocialItemInteractMethodProp',parentobj) 
        this.$store.commit('SetSocialItemInteractMethod','UpdateNewChild')
      },
      CancelAddParentDialog(){
          this.AddNewParentLookupDialog = false
        },
      CancelLookupSelection(){
        this.ViewExistingLookupDialog = false
        },
      ActivateAddNewLookupDialog(field){
        this.CancelLookupSelection()
        this.LookupArray = field.Array
        //console.log(this.field,this.LookupArray)
        //lookupentity headers
        let entity = this.SystemEntities.find(obj => obj.id === field.RelatedBuildID)
        let fields = JSON.parse(JSON.stringify(entity.AllFields))
        this.LookupArrayHeaders = fields.filter(field => {
            return field.IsHeader
            }).map(field => {
              field.Prop = field.id
              field.propvalue = field.id
              if(field.Type === 'Option Set'){
                field.propvalue = field.Prop+'.Name'
              }
                field.text = field.DisplayName
                field.value = field.Name
                if(field.Type === 'Lookup'){
                    field.value = field.value+'.'+field.LookupFieldName   
                    field.Array = []               
                    if(field.RelatedBuildID === 'Users'){
                      field.Array = this.$store.state.Users
                    }
                    else{
                      let arrayname = field.RelatedRecord.split(' ').join('')+'Array'
                      //console.log(arrayname,this.$store.state,this.$store.state[arrayname],field)
                      field.Array = this.$store.state[arrayname]
                    }
                }
                else if(field.Type === 'Option Set'){
                    field.value = field.value+'.Name'
                }
                field.class = 'overline'
                return field
            })
            //console.log(this.LookupArrayHeaders,fields)
        this.ParentTitle = field.Name
        this.ParentCollection = entity.DisplayName.split(' ').join('').toLowerCase()
        this.NewParentAdditional = this.LookupArrayHeaders.filter(header => {
          return !header.Primary
        })
        this.ParentLookup = {Type: 'Lookup', Name: field.Name, Prop: field.Name, Propid: field.Name+'id', Value: '',Array: field.Name+'Array',LookupFieldName: field.LookupFieldName}
        this.NewParentPrimary = {Type: 'Lookup', Name: field.Name, Prop: field.Name, Value: '',LookupFieldName: field.LookupFieldName,propvalue: field.LookupFieldName,RelatedBuildID: field.RelatedBuildID}
        this.AddNewParentLookupDialog = true
      },
      SelectGalleryItem(SelectedImage){
        console.log(SelectedImage,this.ForGallery)
        if(this.ForGallery){
          this.$store.commit('SetSocialItemInteractMethodProp',SelectedImage)
          this.$store.commit('SetSocialItemInteractMethod','SelectSingleGalleryItem')
        }
      },
      CheckforTimesheet(){
        if(this.userLoggedIn){
        if(this.userLoggedIn.CurrentJob){
          this.$store.commit('SetTimesheetRelated',this.userLoggedIn.CurrentJob.RelatedObj)
        }
        }
      },
      ToggleStopWatchDialog(){
        this.$store.commit('SetStopwatchDialog',!this.StopwatchDialog)
      },
      smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      CloseConfirmMSGDialog(){
        this.$store.commit('setConfirmMSGDialog',false)
        this.$store.commit('setConfirmMSGText','')
      },
      removeWhiteSpace(text) {
        return text.replace(/[\s\/]/g, '');
      },
      SaveNewLibrary(){
        this.NewLibrary.Collection = this.NewLibrary.DisplayName.split(' ').join('').toLowerCase()
        this.NewLibrary.SinglePath = '/'+this.NewLibrary.SingleName.split(' ').join('').toLowerCase()
        let col = ''
        if(this.NewLibrary.Type === 'Photo Library'){
          col = 'PhotoLibraries'
        }
        else{
          col = 'DocumentLibraries'
        }
          db.collection('SystemConfig').doc(this.System.id).collection(col).doc(this.NewLibrary.DisplayName.split(' ').join('_')).set(this.NewLibrary).then(doc => {
            this.DeactivateNewLibraryDialog()
          })
      },
      DeactivateNewLibraryDialog(){
        this.NewLibraryDialog = false
        this.NewLibrary = Object.assign({},this.DefaultNewLibrary)
      },
      ToggleNewDocumentLibraryDialog(type){
        this.NewLibrary.Type = type
        this.NewLibraryDialog = !this.NewLibraryDialog
      },
      ToggleNewPhotoLibraryDialog(type){
        this.NewLibrary.Type = type
        this.NewLibraryDialog = !this.NewLibraryDialog
      },
      ClosePhotoGalleryUploadDialog(){
        this.PhotoGalleryUploadsDialog = false
        this.UploadingPhotoGalleryItems = false
        this.UploadFiles = []
        this.CancelUploadLibraryAssets()
      },
      CancelUploadLibraryAssets(){
            this.$store.commit('setUploadFilesList',[])
            this.$store.commit('setPhotoTagObjects',[])            
            //clear these by any chance?
            // this.$store.commit('setUploadFileStorageRef','')
            // this.$store.commit('setUploadFilesDBStorageRef','')
            this.UploadFileListFiles = []
            this.UploadingGalleryAssets = false
            this.AddedAssets = []
      },
      
      UploadLibraryAssets(){
        this.AddedAssets = []
        this.UploadingPhotoGalleryItems = true
        //this.AssignSocialItemInteractMethod('ReceiveAddedAssets',this.AddedAssets)
        //this may as well be called from store...Correct? yep
         Array.from(Array(this.UploadFilesList.length).keys()).map(x => {
          this.PreparePhotosThumbnail(this.UploadFilesList[x],x,this.UploadFilesList.length)
        })
      },
      PreparePhotosThumbnail(file,index,listlength){
        let vm = this
        this.PhotoGalleryUploadsDialog = true
        //this.$emit('ActivateProcessing',true)
        let filename = file.name.split('.')[0]+'_'+file.size+'.'+file.name.split('.')[1]
        file.tmpsrc = URL.createObjectURL(file)
     
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 150
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            let ref = vm.UploadFileStorageRef+'/'+filename
            let dbrecordref = vm.UploadFilesDBStorageRef
            
            // var storageRef = firebase.storage().ref(ref)
            var thumbstorageRef = firebase.storage().ref(ref+'_thumb')
            console.log(vm.UploadFilesList,vm.UploadFileStorageRef,vm.UploadFilesDBStorageRef,thumbstorageRef,ref)
                document.body.removeChild(img)
                var uploadTask = thumbstorageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      // console.log(thumburl)
                      vm.AddLibraryAssetsSingle(file,index,listlength,thumburl,ref,dbrecordref)                    
                    })
              })
      }
      },

      AddLibraryAssetsSingle(file,index,listlength,thumburl,ref,TableCollectionRef) {
        var storageRef = firebase.storage().ref(ref)
        var uploadTask = storageRef.put(file);
        let vm = this
                uploadTask
                .then(snapshot => snapshot.ref.getDownloadURL())
                  .then((url) => {
                 vm.UploadFiles[index].Uploaded = true
                 vm.$store.commit('SetAlertSound','pop_sound')
                // console.log(vm.UploadFiles)
                      const newphoto = {
                              FileType: file.type,
                              url: url,
                              fileurl: url,
                              ThumbURL: thumburl,
                              Created_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Created_Byid: this.userLoggedIn.id,          
                              Created_On: new Date(),	
                              ModifiedDate: file.lastModifiedDate,
                              Modified_By: {Name: vm.userLoggedIn.Name,Surname: vm.userLoggedIn.Surname,Full_Name: vm.userLoggedIn.Full_Name,id: vm.userLoggedIn.id},
                              Modified_Byid: this.userLoggedIn.id,
                              Modified_On: new Date(),
                              Name: file.name,
                              path: url,
                              Path: url,
                              // DataTags: vm.PhotoTagObjects,
                              // DataTagsid: vm.PhotoTagObjectIDs,
                              StorageRef: ref
                              
                              }
                              if(vm.PhotoTagObjects){
                                console.log(vm.PhotoTagObjects)
                                newphoto.DataTags = vm.PhotoTagObjects
                                newphoto.DataTagsid = vm.PhotoTagObjects.map(tag => {
                                  if(tag.id){
                                    return tag.id
                                  }
                                  else{
                                    return tag.ID
                                  }          
                                })
                              }
                              this.AddedAssets.push(newphoto)
                              //console.log(newphoto)
                              if(TableCollectionRef){
                                TableCollectionRef.add(newphoto).then(photo => {
                                  if(vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length){
                                    vm.CancelUploadLibraryAssets()
                                  }
                              })
                              }
                              else{
                                
                                if(vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length){
                                  //console.log('no db ref','vm.ComputedUploadFiles.length === vm.CompletedUploadFiles.length',vm.AddedAssets)
                                  //adding from a table or single, finish the adding or updating or the record how?
                                  vm.AssignSocialItemInteractMethod('ReceiveAddedAssets',vm.AddedAssets)
                                  console.log(vm.$store.state.SocialItemInteractMethod,vm.$store.state.SocialItemInteractMethodProp)
                                  setTimeout(() => {
                                   vm.CancelUploadLibraryAssets() 
                                  }, 500);
                                  
                                }
                              }
                              
                      })              
      },
    PreviewAddingLibraryAssets(event){
      let vm = this
      this.$store.commit('setUploadFilesList',event.target.files || event.dataTransfer.files)
      let filestorageref = ''
      let dbrecordref = ''
        if(vm.IsGroupView){
          filestorageref = 'Group/'+vm.ActiveGroup.id+'/PageAssets/'+new Date()
          dbrecordref = db.collection('groups').doc(vm.ActiveGroup.id).collection('pageassets')
        }
        else if(vm.WebsiteBuilderView || vm.NetworkBuilderView){
          //still untrue child. we need to refer to the active gallery which we got don't we?
          filestorageref = ''
          dbrecordref = ''
          //GalleryLibraries
          //GalleryAssets
        }
        this.$store.commit('setUploadFileStorageRef',filestorageref)
        this.$store.commit('setUploadFilesDBStorageRef',dbrecordref)
        console.log(this.UploadFilesList,this.UploadFileStorageRef,this.UploadFilesDBStorageRef)
      },
      CloseMiniRecordDialog(){
        this.$store.commit('setMiniRecordDialog',false)
        this.$store.commit('setMiniRecordDialogDescription','')
        this.$store.commit('setMiniRecordDialogIMG','') 
        this.$store.commit('setMiniRecordPath','')
        this.$store.commit('setMiniRecordTabs',[])
        this.$store.commit('setMiniRecordDialogTitle','')
        this.$store.commit('setMiniRecordObject','') 
        this.MiniRecordActiveTab = 0
      },
      MiniRecordProcess(tab){
        console.log(this.MiniRecordPath,tab.FormFields)
        let NewDataObj = {}
        let fieldslength = tab.FormFields.length
        tab.FormFields.map((header,headerindex) => {
          NewDataObj[header.Name] = header.Response
          if(header.Type === 'Lookup'){
            NewDataObj[header.Name] = {
                [header.LookupFieldName]: NewDataObj[header.Name][header.LookupFieldName],
                id: NewDataObj[header.Name].id
            }
          }
          else if(header.Type === 'Date' && !header.HasTimeInput){
              NewDataObj[header.Name] = new Date(header.Response)
          }
          else if(header.Type === 'Date' && header.HasTimeInput){                       
              NewDataObj[header.Name] = new Date(header.Response+' '+header.TimeResponse)
              NewDataObj[header.Name+'TimeResponse'] = header.TimeResponse
          }
          else if(header.HasDeepHierarchy && header.Levels && header.Levels[1]){
              NewDataObj[header.Levels[1].FieldName] = header.Level1Response
          }
          if(header.Type === 'Common Field' && ['Physical Address','Postal Address'].includes(header.CommonFieldType)){
            header.FieldBreakdown.map(brk => {
              if(header.Response[brk.Name]){
                 this.MiniRecordPath.update({
                  [brk.Name]: header.Response[brk.Name]
                }).then(updateddoc => {
                  if(headerindex-1+2 === fieldslength){
                    if(this.MiniRecordTabs[this.MiniRecordActiveTab-1+2]){
                      this.MiniRecordActiveTab++
                    }
                    else{
                      this.CloseMiniRecordDialog()
                    }
                  }
                })
              }
            })
          }
          else{
            this.MiniRecordPath.update({
              [header.Name]: NewDataObj[header.Name]
            }).then(updateddoc => {
              if(headerindex-1+2 === fieldslength){
                if(this.MiniRecordTabs[this.MiniRecordActiveTab-1+2]){
                  this.MiniRecordActiveTab++
                }
                else{
                  this.CloseMiniRecordDialog()
                }
              }
            }) 
          }
          
          
        })
        //updates have to happen here, no assign method nonsense. otherwise this entire dynamica is pointless and should hae been in teh required component
        // this.MiniRecordPath.update({
        //   [header.propvalue]: NewDataObj[header.propvalue]
        // })
      },
      ActionBtnMethod(tab){
        this[tab.ActionButtonMethod](tab)
      },
      SomeMethod(tab){
        console.log(tab)
      },    
      AssignImageandCloseTab(){
        if(this.IMGProp === 'PageBGAsset'){
          this.$store.commit('SetSocialItemInteractMethod','UpdatePageBGAsset')
        }
        else{
          this.$store.commit('SetSocialItemInteractMethod','AssignImageandCloseTab')
        }
        //alert('eureka '+this.SocialItemInteractMethod)
        this.ToggleGalleryDialog()
      },      
      ActivateLib(libindex){
        this.activelibraryindex = -1
        setTimeout(() => {
        this.activelibraryindex = libindex  
        }, 100);
        
      },
      SelectImage(tab,prop,asset,IsThis){
          //AssignImage(tab,prop,asset,IsThis)
          this.SelectedImage = asset
          this.$store.commit('setSelectedGalleryAsset',asset)
          if(this.IMGProp !== 'PageBGAsset' && this.IMGProp !== 'AppStylingIMG'){
          this.$store.commit('setIMGProp',prop)
          }
        },
      SelectGalleryAssets(EditedTab,asset){
         this.SelectedImage = asset 
         this.$store.commit('setSelectedGalleryAsset',asset)
         if(this.IMGProp !== 'BGIMG'){          
         this.$store.commit('setIMGProp','LibraryAsset')
         }
        },  
      ToggleGalleryDialog(ForGallery){
        this.GalleryDialog = !this.GalleryDialog
        if(!this.GalleryDialog){
          this.SelectedImageDimensions = ''
          this.SelectedImage = ''
          //this.$store.commit('setSelectedGalleryAsset','')
        }
        if(ForGallery){
          this.ForGallery = true
        }
        else{
          this.ForGallery = false
        }
      },
      SaveNewPage(){
        this.NewPage.PageRoute = this.NewPage.Name.split(' ').join('-')
        let dbref = ''
        let newpath = ''
        if(this.$route.name === 'DocumentationLibraryEditor'){
          dbref = this.ActiveDocumentationLibrary.Database.collection('SystemConfig').doc(this.System.id).collection('DocumentationLibraries').doc(this.$route.params.id).collection('Pages')
          newpath = '/DocumentationLibraryEditor/'+this.$route.params.id+'/'+this.NewPage.Name.split(' ').join('_')
        }
        else if(this.IsGroupView){
          dbref = db.collection('groups').doc(this.$route.params.id).collection('pages')
          newpath = '/Group-PageEditor/'+this.$route.params.id+'/'+this.NewPage.Name.split(' ').join('_')
        }
        else {
          dbref = db.collection('SystemConfig').doc(this.System.id).collection('Pages')
          newpath = '/PageEditor/'+this.NewPage.Name.split(' ').join('_')
        }
        console.log(dbref,dbref.doc(this.NewPage.Name.split(' ').join('_')))
          dbref.doc(this.NewPage.Name.split(' ').join('_')).set(this.NewPage).then(doc => {
            if(this.NewPage.SubPage){
              this.NewPage.id = this.NewPage.Name.split(' ').join('_')
               this.AssignSocialItemInteractMethod('PushtoPreviousNavItem',this.NewPage)
             }
             if(this.NewPage.DefaultLandingPage){
              console.log('says it will')
               this.AssignSocialItemInteractMethod('UpdateSocialDefaultLandingPage','/'+this.NewPage.PageRoute)
             }
             else{
                this.DeactivateNewPageDialog()
                if(newpath){
                  this.$router.push(newpath)              
                }  
             }
                     
          })
      },
      DeactivateNewPageDialog(){
        this.NewPageDialog = false
        this.NewPage = Object.assign({},this.DefaultNewPage)
      },
      ToggleNewPageDialog(type){
        console.log(type)
        this.NewPage.PublishType = type
        if(this.RandomInteractProp === 'New Page as SubPage'){
          this.NewPage.SubPage = true
          this.$store.commit('SetRandomInteractProp','')
        }
        if(this.RandomInteractProp === 'Default Social Landing Page'){
          this.NewPage.DefaultLandingPage = true
          this.$store.commit('SetRandomInteractProp','')
        }
        console.log(this.NewPage)
        this.NewPageDialog = !this.NewPageDialog
      },
      ToggleNewDocLibDialog(type){
        this.NewDocLib.PublishType = type
        this.NewDocLibDialog = !this.NewDocLibDialog
      },
      DeactivateNewDocLibDialog(){
        this.NewDocLibDialog = false
        this.NewDocLib = Object.assign({},this.DefaultNewDocLib)
      },
      SaveNewDocLib(){
        this.NewDocLib.PageRoute = this.NewDocLib.Name.split(' ').join('-')
        let dbref = ''
        let newpath = ''
        if(this.IsGroupView){
          dbref = db.collection('groups').doc(this.$route.params.id).collection('pages')
          newpath = '/Group-PageEditor/'+this.$route.params.id+'/'+this.NewDocLib.Name.split(' ').join('_')
        }
        else {
          dbref = db.collection('SystemConfig').doc(this.System.id).collection('DocumentationLibraries')
          
        }
          dbref.doc(this.NewDocLib.Name.split(' ').join('_')).set(this.NewDocLib).then(doc => {
            let intropage = Object.assign({},this.NewDocLib)
            intropage.Name = this.NewDocLib.FirstPageName
            intropage.PageRoute = intropage.Name.split(' ').join('-')
            delete intropage.LibraryNavbarItems
            delete intropage.FirstPageName
            newpath = '/DocumentationLibraryEditor/'+this.NewDocLib.Name.split(' ').join('_')+'/'+intropage.Name.split(' ').join('_')
            dbref.doc(this.NewDocLib.Name.split(' ').join('_')).collection('Pages').doc(intropage.Name.split(' ').join('_')).set(intropage).then(doc => {
              this.DeactivateNewDocLibDialog()
              this.$router.push(newpath)
            })
            
          })
      },
      ProcessOrder(PDF_File){
        this.AssignSocialItemInteractMethod(this.MarketDialogInteractMethod,PDF_File)
      },
      CloseStatementInvoiceDialog(){
        this.CloseOrderConfirmationDialog()
      },
      CloseOrderConfirmationDialog(){
        this.$store.commit('setOrderConfirmationDialog',false)
        //but, don't just cancel uhm..do something else? They want to change details
      },
      DeactivateExportingtoPDFDialog(doc){      
        if(!this.PDFDownloadOnly){
            this.$store.commit('setPDFExportDoc',doc)
            this.$store.commit('setPDFDataRecord',this.ComputedPDFObject) 
            if(this.MarketDialogType !== 'View Statement Invoice'){
              this.AssignSocialItemInteractMethod(this.MarketDialogInteractMethod,doc)
            }             
        }     
        this.$store.commit('setExportingtoPDFDialog',false)
        //this.PDFDownloadOnly = false
      },
      AssignSocialItemInteractMethod(item,SecondProp){
      //console.log('AssignSocialItemInteractMethod',item,SecondProp)
      this.$store.commit('SetSocialItemInteractMethodProp','') 
      this.$store.commit('SetSocialItemInteractMethod','')
      if(SecondProp){
        this.$store.commit('SetSocialItemInteractMethodProp',SecondProp) 
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
      else if(item){
        this.$store.commit('SetSocialItemInteractMethod',item)
      }
    },
      CloseMarketDialog(){
        this.$store.commit('EmitMarketDialogObject','')
        this.$store.commit('EmitMarketDialogOptions',[])
        this.$store.commit('EmitMarketDialogType','')
        this.$store.commit('EmitMarketDialogDescription','')
        this.$store.commit('EmitMarketDialogTitle','')        
        this.$store.commit('EmitMarketDialogAvatar','')
        this.$store.commit('EmitMarketDialog',false)
      },
      PrepareAddressField(fieldname){
        let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
      },
      
      GetExportPDFFunction(functionid,openoncall){
        db.collection('pdfexportfunctions').doc(functionid).onSnapshot(snapshot => {
          let functiondata = snapshot.data()
          //console.log(functionid,functiondata)
          if(functiondata){
            this[functionid] = functiondata
            if(openoncall){
              this.$store.commit('setExportingtoPDFDialog',true)
            }
          }
          //console.log(this.FunctionData)
        })
      },
      
      ResizeWindow(){
        this.WindowWidth = window.innerWidth;
        this.WindowHeight = window.innerHeight; 
        
      },
      
      CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
      Snoozeall(){
        let length = this.ReminderNotifications.length
        this.ReminderNotifications.map((item,index) => {
          this.SnoozeForMinutes = 10
          this.SnoozeItem(item,true,index-1+2 === length)
        })
      },
      GetActColor(dragging){
        if(dragging.Type.Name === 'Appointment'){
          return 'success'
        }
        else if(dragging.Type.Name === 'Task'){
          return 'orange'
        }
      },
      GetActIcon(dragging){
        if(dragging.Type.Name === 'Appointment'){
          return 'mdi-calendar'
        }
        else if(dragging.Type.Name === 'Task'){
          return 'mdi-check'
        }
      },
      AssignNewDueDate(item){
        let OldDue_Date = item.Due_Date.toDate()
        let NewDue_Date = new Date(this.NewDueDate)
        //console.log(OldDue_Date,NewDue_Date)
        db.collection('activities').doc(item.id).update({
          Due_Date: NewDue_Date,          
        })
        this.NewDueDate = ''
        item.duedatemenu = false
        if(item.Created_Byid !== item.Ownerid){
          let notification = {
            Owner: item.Created_By,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Due Date change -'+item.Subject,
            Type: 'Task Modified',
            Message: this.userLoggedIn.Full_Name+' has updated the Due Date of '+item.Subject+' from '+OldDue_Date+' to '+NewDue_Date
          }
          //console.log(item,notification)
          this.ActivityNotification(item,notification)
        }
      },
      CompletedTaskProcess(dragging){ 
        let status = this.ActivityStatusField.Options[1]
        let statusreason = status.Options[1]
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          Completed_On: new Date(),
          State_Modified_Date: new Date()
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Task Completed -'+dragging.Subject,
            Type: 'Task Completed',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "Completed"',
          }
          this.ActivityNotification(dragging,notification)
        }
        dragging.StatusDial = false
      },
      OnHoldTaskProcess(dragging){
        let now = new Date()   
        let status = this.ActivityStatusField.Options[0]
        let statusreason = status.Options[2]
        let nowminutes = now.getMinutes()
        let snoozetime = now.setMinutes(nowminutes+90)
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          State_Modified_Date: new Date(),
          Snoozed_At: new Date(),
          Snoozed_Until: new Date(snoozetime)
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Task On Hold -'+dragging.Subject,
            Type: 'Task On Hold',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "On Hold"',
          }
          this.ActivityNotification(dragging,notification)
        }
        dragging.StatusDial = false
      },
      OnItTaskProcess(dragging){     
        let now = new Date()   
        let status = this.ActivityStatusField.Options[0]
        let statusreason = status.Options[1]
        let nowminutes = now.getMinutes()
        let snoozetime = now.setMinutes(nowminutes+90)
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          State_Modified_Date: new Date(),
          Snoozed_At: new Date(),
          Snoozed_Until: new Date(snoozetime)
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Task In Progress -'+dragging.Subject,
            Type: 'Task In Progress',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "In Progress"',
          }
          this.ActivityNotification(dragging,notification)
        }
        dragging.StatusDial = false
      },
      ActivityNotification(item,notification){
      const NewNotification = {
              relatedid: item.id,
              Type: notification.Type,
              Header: notification.Header,
              Content: item.Description,
              CreatorFullName: notification.Created_By.Full_Name,
              CreatorID: notification.Created_By.id,
              Owner: item.Created_By,
              Ownerid: item.Created_Byid,
              Read: false,
              Path: '/Activities/',
              Message: notification.Message,
              Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then({

            })
    },
      disablePastDue(dragging){
        return dragging.Due_Date.toDate().toISOString().substr(0, 10)
      },
      disablePastDates(val,dragging) {
       return val >= new Date().toISOString().substr(0, 10)
    },
      SnoozeItem(item,snoozeall,final){
        //console.log(item)
        let now = new Date()
        let nowminutes = now.getMinutes()
        let snoozetime = now.setMinutes(nowminutes+this.SnoozeForMinutes)
        if(this.SnoozeDate){
          //console.log('this.SnoozeDate',this.SnoozeDate)
          snoozetime = new Date(this.SnoozeDate)
        }
        item.SnoozeDial = false
        db.collection('activities').doc(item.id).update({
          Snoozed_At: new Date(),
          Snoozed_Until: new Date(snoozetime)
          // Reminder: false
        })
        if(!snoozeall || snoozeall && final){
          if(!this.ReminderNotifications[this.step]){
            this.$emit('ToggleReminderDialog')
            this.step = 0
          }
          this.SnoozeForMinutes = 5 
        }
        
        
       // console.log('this.ReminderNotifications',this.ReminderNotifications)
      },
      myCubeRoll(){        
        var cube = document.querySelector('.cube');
        if(cube){
        let classes = [
          'front','back','left','bottom','right','back','left','top','right','back','left','bottom','front'
        ]
        if(this.cubeint === classes.length-1){
          this.cubeint = 0
        }
        else{
          this.cubeint = this.cubeint-1+2
        }
        var showClass = 'show-' + classes[this.cubeint];
        if ( this.cubecurrentClass ) {
          cube.classList.remove( this.cubecurrentClass );
        }       
        cube.classList.add( showClass );
        this.cubecurrentClass = showClass;
        }
        else{
          clearInterval(this.CubeVar)
        }
        
      },
      myTimer(prop){
        if (this.RecordLoadingvalue === 100) {
          this.RecordLoadingvalue = 0
        }
        this.RecordLoadingvalue += 25
      },
      OpenNotification(s){
        //console.log(s)
        let notificationobj = this.Notifications.find(obj => obj.id === s.notificationid)
        //console.log(notificationobj)
        if(!notificationobj || notificationobj && !notificationobj.topicid){
          this.$router.push(notificationobj.Path)
        }
        else{
          let payload = {
            ActiveSessionid: notificationobj.topicid,
            ActiveRecord: notificationobj.clientrecords,
            IsLiaison: true,
          }
          if(notificationobj.ActiveProcessNumber){
            payload.ActiveProcessNumber = notificationobj.ActiveProcessNumber
          }
          if(notificationobj.groupid){
            payload.groupid = notificationobj.groupid
          }
          //console.log(payload,notificationobj)
          if(this.UserisGuest){
            this.$emit('TriggerDashboardbyNotification',payload)
          }
          else{
            this.ActivateRecordLiaisonEntryOptionDialog(payload,notificationobj)
          }
        }
      },
      ActivateRecordLiaisonEntryOptionDialog(payload,notificationobj){
        this.LiaisonEntryNotification = notificationobj
        this.LiaisonEntryPayload = payload
        this.LiaisonEntryDialog = true
      },
      DeActivateRecordLiaisonEntryOptionDialog(){
        this.LiaisonEntryDialog = false
      },
      SelectLiaisonView(View){
        if(View === 'Pipeline View'){
          this.$emit('TriggerDashboardbyNotification',this.LiaisonEntryPayload)
          this.DeActivateRecordLiaisonEntryOptionDialog()
        }
        else{
          console.log(this.LiaisonEntryPayload)
          if(this.$route.meta && this.$route.meta.DataSingleBuilder && this.$route.params.id === this.LiaisonEntryPayload.ActiveRecord){
           this.DeActivateRecordLiaisonEntryOptionDialog() 
          }
          else{
            let entity = this.SystemEntities.find(obj => obj.id === this.LiaisonEntryPayload.ActiveSessionid)
            if(entity){
              this.$router.push('/'+entity.SingleName.split(' ').join('')+'/'+this.LiaisonEntryPayload.ActiveRecord)
              this.DeActivateRecordLiaisonEntryOptionDialog() 
            }
            else{
              this.DeActivateRecordLiaisonEntryOptionDialog() 
            }
            
          }          
        }
      },
      MarkasRead(s){
        db.collection('notifications').doc(s.notificationid).update({
                Read: true
              })
              this.hide(s)
      },
        CheckSnack(s){
            console.log('check')
        },
        
        calcMargin(i) {
            return '-'+(i*170) + 'px'
        },
        hide(s){
            console.log('hiding')
            s.show = false
        },
        CloseSnackbar(){
         this.$emit('CloseSnackbar')   
        }
        
    }
}
</script>

<style>
.windowcard{
    position: fixed;
    bottom: 50px;
    right: 10px;
    z-index: 4;
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.windowcarditem{
   border-style: solid;
    border-color: rgb(204, 203, 203);
   border-width: thin;
   color: currentColor;
}
.loading-dialog{
 background-color: #c8e1f0b6 
}
.webform-dialog{
  background-color: #c8e1f06c
}
.liaisonentrydialog{
 background-color: #ffffffb6 
}
.darkliaisonentrydialog{
 background-color: #413e3eb6 
}
.RACubes{
 width: 100px;
  height: 100px;
  /* border: 1px solid #CCC; */
  /* margin: 80px; */
  
  perspective: 400px;
}
.cube {
  width: 100px;
  height: 100px;
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(-100px);
  transition: transform 0.75s;
  
}

/* .cube.show-front  { transform: translateZ(-100px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-100px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-100px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-100px) rotateY(  90deg); }
.cube.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-100px) rotateX(  90deg); } */
.cube.show-front  { transform: translateZ(-100px) rotateY(   0deg); }
.cube.show-right  { transform: translateZ(-100px) rotateY( -90deg); }
.cube.show-back   { transform: translateZ(-100px) rotateY(-180deg); }
.cube.show-left   { transform: translateZ(-100px) rotateY( -270deg); }
.cube.show-top    { transform: translateZ(-100px) rotateX( -90deg); }
.cube.show-bottom { transform: translateZ(-100px) rotateX(  -270deg);}

.cube__face {
  position: absolute;
  width: 100px;
  height: 100px;
  opacity: 0.5;
  
  border: 1px dotted gray;
  line-height: 200px;
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
}

.cube__face--front  { background: coral; }
.cube__face--right  { background: pink; }
.cube__face--back   { background: teal; }
.cube__face--left   { background: #e73c7e; }
.cube__face--top    { background: #23a6d5; }
.cube__face--bottom { background: #23d5ab; }

.cube__face--front  { transform: rotateY(  0deg) translateZ(100px); }
.cube__face--right  { transform: rotateY( 90deg) translateZ(100px); }
.cube__face--back   { transform: rotateY(180deg) translateZ(100px); }
.cube__face--left   { transform: rotateY(-90deg) translateZ(100px); }
.cube__face--top    { transform: rotateX( 90deg) translateZ(100px); }
.cube__face--bottom { transform: rotateX(-90deg) translateZ(100px); }

.square {
  width: 100px;
  height: 100px;
  position: relative;
  perspective: 200px;
  
}
.square .one {
  position: absolute;
  top: 0;
  height: 50px;
  width: 50px;
  background: #e73c7e;
  animation: flip 2s linear infinite;
  transform-origin: right bottom;
  opacity: 0.8;
  
}
.square .two {
  position: absolute;
  top: 0;
  height: 50px;
  width: 50px;
  background: coral;
  animation: flip 2s linear infinite;
  animation-delay: 0.5s;
  transform-origin: right bottom;
  opacity: 0.8;
}
.square .three {
  position: absolute;
  top: 0;
  height: 50px;
  width: 50px;
  background: teal;
  animation: flip 2s linear infinite;
  animation-delay: 1s;
  transform-origin: right bottom;
  opacity: 0.8;
}
.square .four {
  position: absolute;
  top: 0;
  height: 50px;
  width: 50px;
  background: #23a6d5;
  animation: flip 2s linear infinite;
  animation-delay: 1.5s;
  transform-origin: right bottom;
  opacity: 0.8;
}
@keyframes flip {
  0% { transform: rotateX(0) rotateY(0); }
  25% { transform: rotateX(0) rotateY(180deg); }
  50% { transform: rotateX(180deg) rotateY(180deg); }
  75% { transform: rotateX(180deg) rotateY(0deg); }
  100% { transform: rotateX(0) rotateY(0); }
}

</style>