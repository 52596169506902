<template>
<v-card tile flat class="white" height="100%" width="100%">
  
<v-main tile flat class="transparent" height="100%" width="100%">
 <!-- Activesession.name {{UserisGuest}} {{Activesession.name === 'My Profile'}} -->
<MyProfile v-if="Activesession.name === 'My Profile'" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System"
 @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"  style="margin-top:-60px;" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
 <!-- essentially if it worked out this way we COULD put a filter for users (users in my company) and PUSH the user prop utilized in the MyBilingAccounts comp -->
 <MyBillingAccounts v-if="Activesession.name === 'My Accounts'" @ActivateProcessing="ActivateProcessing"  :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
 :SubscriptionPackages="SubscriptionPackages"
 @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
 <MyCompanyComp v-if="Activesession.name === 'My Company'" @ActivateProcessing="ActivateProcessing" :SystemEntities="SystemEntities" :System="System" :ClientStoreOrders="ClientStoreOrders"
 :SubscriptionPackages="SubscriptionPackages"
 @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" />
<div v-if="Activesession.WikiData">
   <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
        </v-card-title>
  <v-list>
    <v-list-item class="detailslistoutline" v-for="wiki in Activesession.WikiData" :key="wiki.itemObjKey" @click="RouteToItem('/'+Activesession.SingleName.split(' ').join('')+'-Wiki/'+wiki[Activesession.RecordPrimaryField.Name])">
      {{wiki[Activesession.RecordPrimaryField.Name]}}
    </v-list-item>
  </v-list>
</div>

<div v-if="Activesession.IsTable">
   <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
        </v-card-title>
        <DataCollectionViewComponent class="background" :System="System"  :EntityRecord="Activesession" :AppisDarkMode="AppisDarkMode" @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar"/>
</div>
<div v-if="Activesession.IsSchedule">
   <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
        </v-card-title>
        <DataCollectionViewComponent class="background" :System="System" :EntityRecord="Activesession" :AppisDarkMode="AppisDarkMode" @ActivateProcessing="ActivateProcessing" @ActivateSnackbar="ActivateSnackbar"/>
</div>

<div v-if="Activesession.Data" class="white">
  <v-dialog max-width="1000" v-model="OpenFormComponent">
    <DigitizedFormComponent v-if="OpenFormComponent && ActiveForm" @SubmitForm="SubmitForm" :ActiveForm="ActiveForm" :comment="ActiveComment" :userLoggedIn="userLoggedIn"/>
  </v-dialog>
    <v-list v-if="!ActiveRecord">
        <v-card-title class="primary white--text mediumoverline">{{Activesession.name}}
        </v-card-title>
        <v-list-item class="detailslistoutline" v-for="record in Activesession.Data" :key="record.itemObjKey" @click="ActivateActiveRecord(record)">
          <v-badge
              bordered
              :color="record.NotifyColor"
              overlap
            > <span slot="badge"> {{record.Notifications.length}}</span>
            <v-list-item-content>
             
              {{Activesession.Entity_Type && Activesession.Entity_Type.Name === 'Store Order' ? record.id : record.name}}
            </v-list-item-content>
            </v-badge>
        </v-list-item>
    </v-list>

    
    <v-card-title v-if="ActiveRecord" class="mediumoverline">
      <v-btn v-if="ActiveRecord" @click="ActivateSession(Activesession),ToTop()">Back</v-btn>
        </v-card-title>
         <v-card-title v-if="ActiveRecord" class="mediumoverline">
      Update
        </v-card-title>

    <v-timeline class="background"
      dense
      clipped
      align-top
      v-if="ActiveRecord"
    >
      <v-timeline-item
        fill-dot
        class="white--text mb-12"
        color="orange"
        large
      >
        <template v-slot:icon>
          <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
        </template>
        <v-text-field
          v-model="input"
          hide-details
          flat
          label="Leave a comment..."
          solo
          @keydown.enter="comment(ActiveRecord,null,LiaisonUploadFile)"
        >
          <template v-slot:append>
          <v-menu
              
              top
              offset-y
              :close-on-content-click="false"
              >
              <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-link</v-icon>
              </template>
              <v-list>
                  <v-list-item>
                      <v-text-field v-model="NewLink" label="link" :rules="[rules.url]"/>
                  </v-list-item>
                  <v-list-item-action>
                      <v-btn @click="link(NewLink,record)">Save</v-btn>
                  </v-list-item-action>
              </v-list>
              </v-menu>

            <input v-if="!LiaisonUploadFile"
                style="display:none"
                type="file"
                @change="onFileselected($event)"
                id="fileInputer">
            <v-icon
            class="mx-0"
            depressed
            @click="FileInput('fileInputer')"
            >
            mdi-paperclip
            </v-icon>
           
            <v-btn
              class="mx-0"
              
              @click="comment(ActiveRecord,null,LiaisonUploadFile)"
            >
              Post
            </v-btn>
          </template>
        </v-text-field>
      </v-timeline-item>

      <v-slide-x-transition
        group
      >
        <v-timeline-item
          v-for="event in timeline"
          :key="event.id"
          class="mb-4"
          color="pink"
          small
        >
          <v-row justify="space-between">
            <v-col
              cols="7"
              v-text="event.text"
            ></v-col>
            <v-col
              class="text-right"
              cols="5"
              v-text="event.time"
            ></v-col>
          </v-row>
        </v-timeline-item>
      </v-slide-x-transition>

      <v-timeline-item
        style="padding:20px;"
        class="mb-4"
        color="grey"
        icon-color="grey lighten-2"
        small
        :hide-dot="comment.hidedot"
        v-for="comment in SortClientLiaison()" :key="comment.itemObjKey">
        <template v-slot:icon>
            <v-avatar>
            <img v-if="comment.ProfPic" :src="comment.ProfPic" style="object-fit: cover;" >
            <v-chip v-if="!comment.ProfPic"
            class="white--text ml-0"
            :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
            label
            small
            >
            {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
            </v-chip>
            </v-avatar>
        </template>
        <span class="caption">
        <span v-if="comment.Created_On.toDate">
                {{comment.Created_On.toDate()}}
            </span>
              <span v-if="!comment.Created_On.toDate && comment.Created_On.seconds">
                  {{new Date(comment.Created_On.seconds*1000)}}
            </span></span>
        <v-card class="elevation-2">
            <v-card-text v-html="comment.description">                            
            </v-card-text>
            <v-card-text v-if="comment.ThumbURL">
                <v-avatar tile size="150">
            <v-img contain
                :src="comment.ThumbURL"
                    />
            </v-avatar>                            
            </v-card-text>
            <v-card-actions  v-if="comment.fileurl">
                <v-btn small v-if="!comment.TokenURL"
                download
                @click="GetShortLivedURL(comment)"
                class="blue--text mx-0"
                >
                Get Token <v-icon small>mdi-security</v-icon>
                </v-btn><v-spacer></v-spacer>
                <v-btn small v-if="comment.TokenURL"
                :href="comment.TokenURL" target="_blank"
                class="orange--text mx-0"
                >
                View <v-icon>mdi-download</v-icon>
                </v-btn>
            </v-card-actions>
            <v-card-actions  v-if="comment.FormObj">
                  <v-btn
                    @click="OpenForm(comment.FormObj,comment)"
                    class="blue--text mx-0"
                    >
                    Open Form
                    </v-btn>
            </v-card-actions>
        </v-card>
        
      </v-timeline-item>
       <v-timeline-item>
            <v-btn @click="slice = slice+4">More</v-btn>
            <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
        </v-timeline-item>

    </v-timeline>
</div>
<!-- <VehiclesWiki v-if="Activesession.name === 'Vehicles Classifieds'" :AppisDarkMode="AppisDarkMode" :LinksGroupsearch="VehiclesLinksGroupsearch" DefaultView="ClassifiedsView" :userLoggedIn="userLoggedIn" @AssignViewbyOptions="AssignViewbyOptions"
                          :ViewType="VehiclesWikiViewType"  :ShowPagination="false"/> -->
</v-main>
</v-card>
</template>

<script>
// import VehiclesWiki from '@/views/VehiclesWiki'
// import VehicleApplicationsWiki from '@/views/VehicleApplicationsWiki'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import DigitizedFormComponent from '@/components/Client/DigitizedFormComponent'
import MyBillingAccounts from '@/components/Client/MyBillingAccounts'
import MyCompanyComp from '@/components/Client/MyCompanyComp'

import MyProfile from '@/views/MyProfile'
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: [{ScheduleEntityComponent: {			
			type: String,
			required: true
		}},'userLoggedIn','UserisGuest','AppisDarkMode','Notifications','NotificationObj','SystemEntities','System','SubscriptionPackages'],
    components: {
        MyProfile,
        DataCollectionViewComponent,
        DigitizedFormComponent,
        MyBillingAccounts,
        MyCompanyComp,
        // VehiclesWiki,
        // VehicleApplicationsWiki
    },
    data(){
        return {
          UserDashboards: [],
          rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
          //PageBGGradient: 'background: linear-gradient(150deg, rgba(46,95,144,0.6) 0%, rgba(192,77,139,0.6) 35%, rgba(238,147,126,0.6) 71%);',
          NewLink: '',
          slice: 4,
          UploadingFileProgress: 0,
          LiaisonUploadFile: '',
          OpenFormComponent: false,
          ActiveForm: '',
          ActiveComment: '',
            MyVehicles: [],
            events: [],
      input: '',
      nonce: 0,
            VehicleApplications: [
            ],
            ActiveRecord: '',
            Activesession: {name: 'My Profile'},
            VehiclesLinksGroupsearch: '',
      VehiclesWikiViewType: '',
      VehiclesWikiViewbyOptions: [],
            VehiclesWikiMode: false,
            storedrawernav: false,
            storedrawerdialog: false,
            ActiveProductFilter: '',
            items: [
                {
                    name: 'My Account',
                    ID: 1001,
                    Children: [
                        {
                            name: 'My Profile',
                            icon: 'mdi-star-face'
                        },
                        {
                            name: 'My Company',
                            icon: 'mdi-badge-account-horizontal'
                        },                        
                        {
                            name: 'My Accounts',
                            icon: 'mdi-bank'
                        }
                    ]
                },
                {
                  name: 'Schedules',
                  ID: 1005,
                  Children: [],
                  icon: 'mdi-calendar',
                  IsSchedule: true,
                },
                {
                  name: 'My Records',
                  ID: 1006,
                  Children: [],
                  icon: 'mdi-file-table',
                  IsTable: true,
                }
                
                // {
                //     name: 'Notifications',
                //     ID: 1004,
                //     Children: []
                // },
                
            ]
        }
    },
    computed:{
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      UserisGuest(){
        return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined'     
      },
      SystemSocialReady(){
        return this.PluginDataBase && this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active && this.System.Social_Landing_Page
      },
      CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
      },
      SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
      PluginDataBase(){
        return this.$store.state.PluginDataBase
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ClientStoreOrders(){
        let menuitem = this.items.find(obj => obj.ID === 1004)
        return menuitem.Children.map(Activesession => {
          return Activesession
        }).flat().filter(Activesession => {
          return Activesession.Entity_Type && Activesession.Entity_Type.Name === 'Store Order'
        }).map(channel => {
          return channel.Data
        }).flat()
      },
      UsersArray(){
        return this.$store.state.Users
      },
      NavStyling(){

      if(this.System.Guest_SideBar_IMG && this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]+`,url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_IMG && !this.System.Guest_SideBar_Gradient){
                return { backgroundImage: `url('`+this.System.Guest_SideBar_IMG.fileurl+`')`,backgroundSize: `cover` }
            }
            else if(this.System.Guest_SideBar_Gradient){
                return { backgroundImage: this.System.Guest_SideBar_Gradient.split('background: ')[1].split(';')[0]}
            }
            else if(this.PageBGColor){
                return { backgroundColor: this.PageBGColor+ `!important`}
            }
            else{
                return ''
            }
    },
      ScheduleEntityComponent(){
        if(this.Activesession && this.Activesession.IsSchedule){			
        return () => import(`@/views/${this.Activesession.name.split('_').join('')}.vue`);			
      }
      },
      UserBillingAccounts(){
        return this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
      UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
      MyCompany(){
      return this.$store.state.MyCompany
    },
      UserisCompanyAdmin(){
        return this.$store.state.UserisCompanyAdmin
      },
      Computeditems(){
        return this.items
        .map(item => {
          if(item.name === 'My Account'){
            item.Children = item.Children.filter(child => {
              if(this.UserCompaniesActive && this.UserisCompanyAdmin){
                return child
              }
              else{
                return child.name !== 'My Company'
              }
            })
          }
          return item
        })
        .map(item => {
          if(item.ID === 1001){
            if(!this.UserBillingAccounts){
               item.Children = item.Children.filter(child => {
                return child.Name !== 'My Accounts'
              })
            }
            else{
              // item.Children = item.Children.map(child => {
              //   if(child.Name === 'My Accounts'){
              //    child.Data = this.UserBillingAccounts 
              //   }
              //   return child
                
              // })
            }
          }
          if(item.ID === 1005){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name === 'Scheduled Booking' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsSchedule = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase()
            return entity
          })
          }
          else if(item.ID === 1006){
            item.Children = this.SystemEntities.filter(entity => {
            let storequery = this.$store.state[entity.DisplayName.split(' ').join('')+'Query']
            return storequery && entity.Entity_Type && entity.Entity_Type.Name !== 'Scheduled Booking' && entity.Permitted_Guest_Dashboard
          }).map(entity => {
            entity.name = entity.DisplayName
            entity.icon = entity.Table_Icon
            entity.IsTable = true
            entity.RouteName = entity.DisplayName.split(' ').join('').toLowerCase(),
            entity.RouteQueries = [
                {Prop: 'TableType', Value: 'Standard'},
                {Prop: 'TableName', Value: entity.RouteName }
              ]
            return entity
          })
          }
          else if(item.ID === 1002){
            item.Children = this.System.GuestNavbarItems.map(item => {
              let childobj ={
                name: item.title,
                icon: item.icon,
                IsPage: true,
                Path: item.Path,
              }
              return childobj
            })
          }
          
            item.Children = item.Children.map(child => {
                //console.log(child.Data)
              if(child.Data){
               child.Data = child.Data.map(record => {
                 if(record.ClientLiaison){
                   record.ClientLiaison = record.ClientLiaison.map(liase => {
                    liase.createdon = this.TimestampFormatterSTRING(liase.Created_On.toDate())
                    liase.TimestampSort = this.DateFormatterwithTime(liase.createdon)                    
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                 }                  
                  return record
                }) 
              }           
            return child
          })
          return item
        }).filter(item => {
          return item.Children.length > 0
        })
      },
      timeline () {
        return this.events.slice().reverse()
      },

        userLoggedIn () {
      return this.$store.getters.user
    },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
        ProductsClassesNEW(){
            return this.StoreProductsEntities
            .filter(entity => {
                return entity.HierarchyField
            })
            .map(entity => {
                let HierarchyField = entity.AllFields.find(obj => obj.id === entity.HierarchyField.id)
                 if(HierarchyField && HierarchyField.Levels && HierarchyField.Options){
                let array = JSON.parse(JSON.stringify(HierarchyField.Options))      
                return array
                .map(option => {
                    //console.log('option',option)
                let treeobj = {
                    name: option.Name,
                    id: option.ID,
                    EntityID: entity.id,
                    HierarchyFieldid: HierarchyField.id,
                    LevelFieldName: option.Level.FieldName,
                    Levelid: option.Level.id
                }
                // if(treeobj.Level && treeobj.Level.id === 1){
                    if(option.Options){
                    treeobj.children = option.Options.map(secondoption => {
                        let secondtreeobj = {
                        name: secondoption.Name,
                        id: secondoption.ID,
                        EntityID: entity.id,
                        HierarchyFieldid: HierarchyField.id,
                        LevelFieldName: secondoption.Level.FieldName,
                        Levelid: secondoption.Level.id
                        }
                        if(secondoption.Options){
                        secondtreeobj.children = secondoption.Options.map(thirdoption => {
                            let thirdtreeobj = {
                            name: thirdoption.Name,
                            id: thirdoption.ID,
                            EntityID: entity.id,
                            HierarchyFieldid: HierarchyField.id,
                            LevelFieldName: thirdoption.Level.FieldName,
                            Levelid: thirdoption.Level.id
                            }
                            if(thirdoption.Options){
                            thirdtreeobj.children = thirdoption.Options.map(fourthoption => {
                                let fourthtreeobj = {
                                name: fourthoption.Name,
                                id: fourthoption.ID,
                                EntityID: entity.id,
                                HierarchyFieldid: HierarchyField.id,
                                LevelFieldName: fourthoption.Level.FieldName,
                                Levelid: fourthoption.Level.id
                                }
                                return fourthtreeobj
                            })
                            }
                            return thirdtreeobj
                        })
                        }
                        return secondtreeobj
                    })
                    }
                // }
                return treeobj
                })
                
            }
            }).flat()  
        },        
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        NotificationObj (value) {
            if (value) {
                this.CheckNotificationObj()
            }
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateSession']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
        
    },
    created(){
      this.GetAllUserDashboards()
      this.$emit('IntranetViewToggle',false)
      if(!this.MiniView){
        this.storedrawernav = true
      }
      if(this.NotificationObj){
           this.CheckNotificationObj()
        }
      if(this.System.GuestNavbarItems){
        let pagesobj = {
              name: 'Pages',
              ID: 1002,
              Children: []
          }
        this.items.push(pagesobj)
      }
      // console.log('userLoggedIn',this.userLoggedIn)
      this.GetClientTopics()
      this.GetClientWikiTabs()
      // this.GetMyVehicles()
    },
    methods: {
      GetAllUserDashboards(){
      this.GetUserDashboards(db.collection('customdashboards').where('Ownerid','==',this.userLoggedIn.id))
      this.GetUserDashboards(db.collection('customdashboards').where('WhocanView','array-contains',this.userLoggedIn.id))
    },
    GetUserDashboards(query){
      let dashboardsitem = this.items.find(obj => obj.ID === 1001)
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let dashboardobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
            this.UserDashboards.push(dashboardobj)
            let dashboarditem = {
                      icon: 'mdi-desktop-mac-dashboard',
                      name: dashboardobj.DashboardName,
                      Path: '/CustomDashboard/'+dashboardobj.id,
                      target: 'self',
                      id: dashboardobj.id
                    }
                    let oncheck = dashboardsitem.Children.find(obj => obj.id === dashboarditem.id)
                    if(!oncheck){
                      dashboardsitem.Children.push(dashboarditem)
                    }
          }
        })
      })
    },
      IntranetViewToggle(boolean1,boolean2){
        this.$emit('IntranetViewToggle',boolean1,boolean2)
      },
      ToggleAppStylingView(){
      this.$emit('ToggleAppStylingView')
    },
     ToggleAppDarkMode(){
      if(this.AppisDarkMode){
        this.$emit('ToggleAppDarkMode',false)
      }
      else{
         this.$emit('ToggleAppDarkMode',true)
      }
      
    },
      ActivateSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateSnackbar',boolean,snackbarcontent)
      },
      RouteToItem(route){
        this.Activesession = ''
        this.ActiveRecord = ''
        this.$router.push(route)
      },
      ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      },
      GetShortLivedURL(item){
        //console.log(item)
        const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
          item.TokenURL = result.data[0]
          setTimeout(() => {
                item.TokenURL = ''
                let url = item.fileurl
                item.fileurl = ''
                item.fileurl = url
          }, 120000);
          let url = item.fileurl
          item.fileurl = ''
          item.fileurl = url
        })
        },
      CheckNotificationObj(){
        let menuobj = this.Computeditems.find(obj => obj.ID === 1004)
        //console.log(menuobj)
        this.Activesession = menuobj.Children.find(obj => obj.id === this.NotificationObj.ActiveSessionid)
        this.ActiveRecord = this.Activesession.Data.find(obj => obj.id === this.NotificationObj.ActiveRecord)
        //ActivateActiveRecord
        console.log(this.ActiveRecord,this.ActiveRecord.Notifications,this.NotificationObj)
        if(this.ActiveRecord.Notifications){
          this.ActiveRecord.Notifications.map(not => {
              db.collection('notifications').doc(not.id).update({
                Read: true
              })
            })        }
        
        },
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean)
      },
      link(url,record) {
        this.input = `See this link <a href="`+url+`" target="_blank">here</a>`
        document.execCommand("createLink", false, url);
        record.TimelineDialog = false
        record.TimelineDialog = true
        this.NewLink = ''
        },
      ToTop(){
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      },
      ActivateStoreDrawer(){
        if(this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs){
          this.storedrawernav = !this.storedrawernav
        }
        else{
          this.storedrawernav = !this.storedrawernav
        }
      },
      GetChildNotifyCount(child){
        let data = []
        if(child.Data){
          data = child.Data
        }
        return data.map(record => {
          let notificationslist = this.Notifications.filter(obj => {return obj.clientrecords === record.id && obj.Read === false})
          alert(notificationslist)
          record.NotificationCount = notificationslist.length
          record.Notifications = notificationslist
          if(record.NotificationCount>0){
            record.NotifyColor = 'red'
            child.NotifyColor = 'red'
          }
          else{
            record.NotifyColor = 'grey'
            if(!child.NotifyColor){
             child.NotifyColor = 'grey' 
            }
            
          }
          return record
        }).map(record => {
          return record.NotificationCount
        })
        .reduce((a, b) => a + b, 0)
        
      },

      ConfirmlogoutFromFirebase(){
      confirm('Are you sure you want to log out?') && this.logoutFromFirebase()
    },

    logoutFromFirebase () {
      db.collection('users').doc(this.userLoggedIn.id).update({
        onlinestatus: 'offline'
      })
      setTimeout(() => {
      this.$emit('SignoutNotifications')
      this.$router.replace('/Login')
      this.$store.dispatch('signOutAction')
      }, 500);      
    },
      FileInput(elid){
            let elmnt = document.getElementById(elid)
            elmnt.click()
        },
      onFileselected(event){
        this.LiaisonUploadFile = event.target.files[0]
        this.LiaisonUploadFile.FileModifiedDate = new Date(this.LiaisonUploadFile.lastModified)
        setTimeout(() => {
          let newinput = 'Please see attached file - '+this.LiaisonUploadFile.name 
          if(this.input){
            newinput = '. '+newinput
          }
         this.input = this.input+newinput  
         //console.log(this.input) 
        }, 300);
        
        
        },
      SaveNewcomms(NewComms,record){
        NewComms.recipientid = record.Owner.id
        //console.log(NewComms)
           db.collection('clientliaison').add(NewComms).then(doc => {
            //console.log('created new doc '+doc.id)

            const NewNotification = {
                ActiveProcessNumber: record.ActiveProcessNumber,
                itemidprop: 'clientrecords',
                itemid: NewComms.relatedid,
                Type: 'Feedback',
                topicid: NewComms.topicid,
                Header: 'Response on '+record.name,
                Content: NewComms.description,
                CreatorFullName: NewComms.Created_By.Full_Name,
                CreatorID: NewComms.Created_By.id,
                Owner: {id: record.Owner.id, Full_Name: record.Owner.Full_Name},
                Ownerid: record.Ownerid,
                Read: false,
                IsLiaison: true,
                Liaisonid: doc.id,
                Path: '/MyDashboard',
                Message: NewComms.Created_By.Full_Name+' has responded on '+record.name,
                }
            this.$store.dispatch('createNotification',NewNotification)
            this.LiaisonUploadFile = ''
            }) 
      },
      UploadFileandAttahctoNewComms(NewComms,record){
        let ref = db.collection('RMDocuments').doc()
        let newdocid = ref.id
        //this.UploadingMultipleFileUpload1File = true
        this.$emit('ActivateUploadingSingleFileNotification',true)
        setTimeout(() => {
        let filename = newdocid+'_'+this.LiaisonUploadFile.name
        //console.log(filename)
        const barelmnt = document.getElementById('singlefileuploadprogressbar')
        const bartextelmnt = document.getElementById('singlefileuploadprogresstext')
        const path = NewComms.relatedtype.split(' ').join('')+'/'+NewComms.relatedid+'/ClientLiaison/'+ filename
        NewComms.Uploadfilename = this.LiaisonUploadFile.name
        var storageRef = firebase.storage().ref(path)
        NewComms.StorageRef = path
        NewComms.newdocid = newdocid
        NewComms.FileType = this.LiaisonUploadFile.type
        NewComms.lastModifiedDate = this.LiaisonUploadFile.FileModifiedDate
          var uploadTask = storageRef.put(this.LiaisonUploadFile);
          let vm = this
          uploadTask.on('state_changed', function(snapshot){
          let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          vm.UploadingFileProgress = Number(progress.toFixed(2))
          barelmnt.setAttribute('value',vm.UploadingFileProgress)
          var textnode = document.createTextNode(vm.UploadingFileProgress+"% complete for "+vm.LiaisonUploadFile.name);
          if(bartextelmnt.childNodes[0]){
          bartextelmnt.removeChild(bartextelmnt.childNodes[0])
          }         // Create a text node
          bartextelmnt.appendChild(textnode);
          if(vm.UploadingFileProgress === 100){
              setTimeout(() => {
                vm.$emit('ActivateUploadingSingleFileNotification',false)
                //vm.UploadingMultipleFileUpload1File = false
                vm.UploadingFileProgress = 0
                barelmnt.setAttribute('value',0)
                bartextelmnt.removeChild(bartextelmnt.childNodes[0])
                var finalnode = document.createTextNode('0% complete')
                bartextelmnt.appendChild(finalnode)
                }, 300);
              }
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            }, function(error) {
              // Handle unsuccessful uploads
            }, function() {
              // Handle successful uploads on complete
              // For instance, get the download URL: https://firebasestorage.googleapis.com/...
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                //console.log('File available at', downloadURL);
                NewComms.fileurl = downloadURL
                if(NewComms.FileType.includes('image')){
                  vm.$emit('ActivateProcessing',true)
                  vm.PrepareThumbnail(NewComms.Uploadfilename,vm.LiaisonUploadFile,NewComms.StorageRef).then(thumbresult => {
                        NewComms.ThumbURL = thumbresult
                        vm.$emit('ActivateProcessing',false)
                        vm.SaveNewcomms(NewComms,record)
                    })
                }
                else{
                  vm.SaveNewcomms(NewComms,record)
                }
                
              });
            })
                
        }, 300);
      },
      PrepareThumbnail(filename,file,storageref){
        let vm = this
        return new Promise(function(resolve, reject) {	
        file.tmpsrc = URL.createObjectURL(file)
              //this.OutboundPhotosforUpload.push(file)
              var img = document.createElement("IMG");
                img.setAttribute('width',150)
                img.setAttribute('src',file.tmpsrc)
                document.body.appendChild(img)
                let vm = this
                img.onload = function() {
                var c = document.createElement("canvas");
                var ctx = c.getContext("2d");
                var canvasRatio = img.naturalHeight / img.naturalWidth
                c.width = 400
                c.height = c.width*canvasRatio
                ctx.drawImage(img, 0, 0, c.width, c.height);
                c.toBlob(blob => {
                  let blobfile = new File([blob], filename, {
              type: file.type,
            });
            //console.log(blobfile)
            var storageRef = firebase.storage().ref(storageref+'_thumb')
                document.body.removeChild(img)
                var uploadTask = storageRef.put(blobfile)
                uploadTask
                  .then(snapshot => snapshot.ref.getDownloadURL())
                    .then((thumburl) => {
                      //console.log(thumburl)
                      resolve(thumburl)
                    })
              })
                }
        })
      },
      Downloadfile(StorageRef,fileurl,Uploadfilename){
            

        var element = document.createElement('img')
            element.setAttribute('href',fileurl)
            element.setAttribute('download', this.ActiveRecord.name+' - '+Uploadfilename)
            // element.style.display = 'none';
          document.body.appendChild(element);
          //console.log(element)
          element.click();
          document.body.removeChild(element);
      },
      SubmitForm(form,comment){
        //console.log(form,comment)
        this.ActiveForm = ''
        this.ActiveComment = ''
        this.OpenFormComponent = false
        this.input = 'Submitted'
        form.readonly = true
        this.comment(this.ActiveRecord,form)
      },
      OpenForm(form,comment){
        this.ActiveForm = form
        this.ActiveComment = comment
        this.OpenFormComponent = true
      },
      DateFormatterwithTime(date){
      if(date){
        //console.log(date)
        //console.log(typeof date)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        
        var hr = date.split('-')[2].split(' ')[1].split(':')[0]
        var min = date.split('-')[2].split(' ')[1].split(':')[1]
        var sec = date.split('-')[2].split(' ')[1].split(':')[2]
        // if (min < 10) {
        //     min = "0" + min;
        // }
        let hrnumber = Number(hr)
        let minr = Number(min)
        let secnr = Number(sec)
        var ampm = "am";
        if(isNaN(secnr)){
          secnr = 0
        }
        //console.log(yearnumber,monthnumber,daynumber,hrnumber,minr,secnr)
        let newdate = new Date(yearnumber, monthnumber-1, daynumber,hrnumber,minr,secnr)
        //console.log(newdate)
        return newdate
        }
      else{
        return null
      }
},
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        var sec = d.getSeconds();
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min+ ":" + sec;
        //console.log(finaldate)
        return finaldate
      },
      GetClientWikiTabs(){
        let menuitem =  {
              name: 'My Products',
              ID: 1003,
              Children: []
          }
           db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('ClientWikiTables').onSnapshot(res => {
                  let listlength = res.docs.length
                  if(listlength === 0){
                    this.items.push(menuitem)
                  }
                  const changes = res.docChanges();
                  changes.forEach(change => {
                      if(change.type === 'added'){
                        let obj = {
                          ...change.doc.data(),
                          id: change.doc.id
                        }
                        obj.WikiData = []
                        obj.collectionname = obj.id.split('_').join('').toLowerCase()+'wiki'
                        obj.ClientField = obj.ClientLookupField.Name
                        obj.Clientid = obj.ClientField+'id'
                        obj.ClientValueField = obj.ClientLookupField.LookupFieldName
                        db.collection(obj.collectionname).where(obj.Clientid,'==',this.userLoggedIn.id).onSnapshot(wikires => {
                          let wikilength = wikires.docs.length
                          if(wikilength === 0){
                            menuitem.Children.push(obj)
                            if(menuitem.Children.length === listlength){                              
                              this.items.push(menuitem)
                            }
                          }
                          const wikichanges = wikires.docChanges();
                          wikichanges.forEach(wikichange => {
                            if(wikichange.type === 'added'){
                              let wikiobj = {
                                ...wikichange.doc.data(),
                                id: wikichange.id
                              }
                              obj.WikiData.push(wikiobj)
                            }
                            if(obj.WikiData.length === wikilength){
                              menuitem.Children.push(obj)
                              if(menuitem.Children.length === listlength){
                                this.items.push(menuitem)
                              }
                            }
                          })
                        })
                      }
                  });
           })
          // WikiData
      //   this.GetClientTopics()
      // this.GetClientWikiTabs()
//       <div v-if="Activesession.name === 'Vehicles'">
//   <v-list>
//     <v-list-item v-for="vehicle in MyVehicles" :key="vehicle.itemObjKey" :to="'/VehicleApplication-Wiki/'+vehicle.Name">
//       {{vehicle.Name}}
//     </v-list-item>
//   </v-list>
// </div>
      //My Purchases
      //ClientWikiTables
      // {
      //               name: 'My Products',
      //               ID: 1003,
      //               Children: [
      //               ]
      //           },
      },
      GetClientTopics(){
        //console.log(this.SystemEntities)
        let menuobj =  {
                    name: 'Notifications',
                    ID: 1004,
                    Children: []
                }
                this.items.push(menuobj)
                let menuitem = this.items.find(obj => obj.ID === 1004)
        db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('LiaisonTopics').onSnapshot(topicres => {
              // alert('boohooo')
                    const topicchanges = topicres.docChanges(); 
                    let topicslength = topicres.docs.length 
                      if(topicslength === 0){
                        // this.items.push(menuitem)
                      }
                    
                    if(topicslength !== 0){
                      // let commslength = commres.docs.length                 
                      topicchanges.forEach(topicchange => {
                        if (topicchange.type === 'added') {
                          let topicchild = {
                            ...topicchange.doc.data(),
                            id: topicchange.doc.id
                          }
                          let ent = this.SystemEntities.find(obj => obj.id === topicchild.id)
                          if(ent && ent.Entity_Type){
                            topicchild.Entity_Type = ent.Entity_Type
                          }
                          menuitem.Children.push(topicchild)
                          let obj = menuitem.Children.find(item => item.id === topicchange.doc.id)
                          obj.Data = []
                          obj.NotificationCount = 0  
                              db.collection('clientrecords').where('Clientid','==',this.userLoggedIn.id).where('topicid','==',obj.id).onSnapshot(res => {
                              const changes = res.docChanges();        
                              let recordslength = res.docs.length  
                              if(recordslength === 0){
                                // menuitem.Children.push(obj) 
                                if(menuitem.Children.length === topicslength){
                                  // this.items.push(menuitem)
                                }
                              }          
                              changes.forEach(change => {
                                if (change.type === 'added') {
                                  obj.Data.push({
                                    ...change.doc.data(),
                                    id: change.doc.id
                                  })
                                  let dataobj = obj.Data.find(item => item.id === change.doc.id)
                                  dataobj.Notifications = this.Notifications.filter(notobj => {return notobj.clientrecords === dataobj.id && notobj.Read === false})
                                  obj.NotificationCount = obj.NotificationCount+dataobj.Notifications.length
                                  dataobj.ClientLiaison = []
                                  let senderquery = db.collection('clientliaison').where('senderid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)
                                  let recipientquery = db.collection('clientliaison').where('recipientid','==',this.userLoggedIn.id).where('relatedid','==',dataobj.id)

                                  senderquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                  recipientquery.onSnapshot(commres => {
                                    const comchanges = commres.docChanges();  
                                    comchanges.forEach(comchange => {
                                      if (comchange.type === 'added') {
                                            dataobj.ClientLiaison.push({
                                          ...comchange.doc.data(),
                                          id: comchange.doc.id
                                        })
                                    
                                      }
                                    })                    
                                    })
                                }
                              })                    
                              })                   
                        }
                      })  
                    }
                                      
                    })
        
      },

      
        comment(record,form,LiaisonUploadFile) {
          //console.log(this.Activesession)
        let NewComms = {
          description: this.input,
          Created_On: new Date(),   
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          senderid: this.userLoggedIn.id,
          relatedid: record.id,
          relatedtype: this.Activesession.id.split('_').join(' '),
          topicid: this.Activesession.id,
        }
        if(form){
            NewComms.FormObj = form
            if(form.readonly){
              NewComms.readonly = true
            }
        }
         if(!LiaisonUploadFile){
         this.SaveNewcomms(NewComms,record)  
        }
        else{
          // console.log(this.ActiveSession)
            //first upload the file, return url and include url in NewComms
            this.UploadFileandAttahctoNewComms(NewComms,record)
        }

        this.input = null
      },
        SortClientLiaison(){
          return this.ActiveRecord.ClientLiaison.map(liase => {
            if(liase.Created_Byid){
                     let userobj = this.UsersArray.find(obj => obj.id === liase.Created_Byid)
                     if(userobj && userobj.Profile_Photo){
                         liase.ProfPic = userobj.Profile_Photo
                     }
                 }
                    liase.createdon = liase.Created_On.toDate()
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  }).slice(0,this.slice)
        },
        ActivateActiveRecord(record){
            this.ActiveRecord = Object.assign({},record)
            this.ActiveRecord.Notifications.map(not => {
              db.collection('notifications').doc(not.id).update({
                Read: true
              })
            })
            
            // this.storedrawer = false
        },
        ActivateSession(item){
          console.log(item)
          this.Activesession = ''
          if(this.MiniView){
            this.storedrawernav = false
          }
          if(item.IsStore){
            this.$router.push(item.Path)
          }
          else if(item.Path){
            this.$router.push(item.Path)
          }
          else if(item.RouteQueries){
            let queryobj = {}
            item.RouteQueries.map(query => {
              queryobj[query.Prop] = query.Value
            })
              this.$router.replace({ name: this.$route.name, query: queryobj})
            this.Activesession = item
            this.ActiveRecord = ''

          }
          else{
            if(this.$route.query && this.$route.query.SomeParams){
              let newroute = { path: this.$route.path}
              this.$router.push(newroute)
            }
            this.Activesession = item
            this.ActiveRecord = ''
          }
            // console.log(this.Activesession.Data.length)
        },
        AssignViewbyOptions(obj){
        //console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
        ToggleVehiclesWikiMode(boolean){
            // this.VehiclesWikiMode = boolean
            this.$router.push({ name: 'VehicleClassifieds', params: {DefaultView: 'ClassifiedsView',ViewType: this.VehiclesWikiViewType,LinksGroupsearch: this.VehiclesLinksGroupsearch }})
        }
    }
}
</script>

<style>

</style>