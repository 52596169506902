<template>
  <div>
    <v-list-item>
        <v-btn outlined @click="AcceptInviteUserDialog()">
            Invite User
        </v-btn>
    </v-list-item>
    <v-list-item v-if="ComputedUserInvites.length === 0">
        There are no invites
    </v-list-item>
       <v-list-item dense v-for="invite in ComputedUserInvites" :key="invite.itemObjKey" >
        <!-- <v-list-item-icon>
        <v-icon small>mdi-blogger</v-icon>
        </v-list-item-icon> -->
        <v-list-item-avatar size="40" class="mx-2">
            <v-img contain v-if="invite.CreatorProfile" :src="invite.CreatorProfile"/>
            <v-img contain v-if="!invite.CreatorProfile" src="@/assets/ImageHolder.png"/>
        </v-list-item-avatar>
        <v-list-item-content>            
            <v-list-item-title :class="invite.class">{{ invite.Email }}</v-list-item-title>            
            <v-list-item-subtitle :class="invite.class">{{ invite.Business_Unit.Name }}</v-list-item-subtitle>
            <v-list-item-subtitle v-if="invite.Company" :class="invite.class"><v-chip dark color="blue" x-small>{{ invite.Company.Company_Name }}</v-chip></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content>
            <v-switch dense v-model="invite.IsGuestUser" label="Guest User" disabled />
            
        </v-list-item-content>
        <v-list-item-action>
            <v-btn x-small @click="CopyInviteToClipBoard('/UserInvite/'+invite.id)">Copy Invite</v-btn>
        </v-list-item-action>
        <v-list-item-action>
            <v-menu
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y :disabled="invite.StatusDisabled"
              width="200px"
              >
                <template v-slot:activator="{ on }">
            <v-chip dark v-on="on" :color="invite.StatusColor"><v-icon>{{invite.StatusIcon}}</v-icon>{{invite.Status.Name}}</v-chip>
                </template>
                <v-card width="200px">
                    <v-card-title>
                        Cancel?
                    </v-card-title>
                    <v-card-actions>
                        <v-btn small dark color="success">No</v-btn>
                        <v-spacer>
                        </v-spacer>
                        <v-btn small @click="UpdateCancelInvite(invite)" dark color="error">Yes
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-menu>
        </v-list-item-action>
            <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                {{invite.monthcreated}}
                    <br>
                {{invite.daycreated}}
        </v-list-item-avatar>                    
        <!-- <v-list-item-content>
            {{invite.pubDate}} 
        </v-list-item-content> -->
    </v-list-item>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            UserInvites: []
        }
    },
    computed:{
        UserCompaniesActive(){
        return this.PluginDataBase && this.PluginDataBase.User_Companies && this.PluginDataBase.User_Companies.Active
      },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        userBU () {
        return this.$store.state.buobj
        },
        InvitebyCompany(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.AdditionalProperties.CompanyInviteOnly && this.userLoggedIn.Company
      },
      userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        InviteColRef(){
            let ref = db.collection('userinvites')
            if(this.InvitebyCompany){
                ref = ref.where('Companyid','==',this.userLoggedIn.Companyid)
            }
            else if(!this.userIsAdmin){
                ref = ref.where('Createdy_Byid','==',this.userLoggedIn.id)
            }
            return ref
        },
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        UsersArray(){
            return this.$store.state.Users
        },
        ComputedUserInvites(){
            return this.UserInvites.map(inv => {
                let inviteobj = Object.assign(inv)
                let userobj = this.UsersArray.find(obj => obj.id === inviteobj.Created_Byid)
                if(userobj && userobj.Profile_Photo){
                    inviteobj.CreatorProfile = userobj.Profile_Photo
                }
                inviteobj.monthcreated = this.TimestampFormatterNoticeBoard(inviteobj.Created_On.toDate(),'month')
                    inviteobj.daycreated = this.TimestampFormatterNoticeBoard(inviteobj.Created_On.toDate(),'date')        
                    inviteobj.createddate = this.TimestampFormatterNoticeBoard(inviteobj.Created_On.toDate(),'full')
                    if(inviteobj.Status.Name === 'Sent'){
                        inviteobj.StatusColor = 'blue'
                        inviteobj.StatusIcon = 'mdi-help'
                    }
                    else if(inviteobj.Status.Name === 'Processing'){
                        inviteobj.StatusColor = 'warning'
                        inviteobj.StatusIcon = 'mdi-timer-sand'
                    }
                    else if(inviteobj.Status.Name === 'Completed'){
                        inviteobj.StatusColor = 'success'
                        inviteobj.StatusIcon = 'mdi-account-check'
                        inviteobj.StatusDisabled = true
                        //Can check InviteID prop on susers to find match
                    }
                    else if(inviteobj.Status.Name === 'Cancelled'){
                        inviteobj.StatusColor = 'error'
                        inviteobj.StatusIcon = 'mdi-cancel'
                        inviteobj.StatusDisabled = true
                    }
                    
                return inviteobj
            })
        },
    },
    created(){
        this.GetUserInvites()
    },
    methods:{
        AcceptInviteUserDialog(){
            this.$store.commit('SetSocialItemInteractMethod','AcceptInviteUserDialog')
        },
        CopyInviteToClipBoard(text){
            let path = ''            
            if(window.location.port.length > 0){
                
                path = window.location.protocol+'//'+window.location.hostname+':'+window.location.port+text
            }
            else{
                path = window.location.protocol+'//'+window.location.hostname+text
            }
            navigator.clipboard.writeText(path).then(function() {
            console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
            console.error('Async: Could not copy text: ', err);
            });
        },
        UpdateCancelInvite(invite){
            db.collection('userinvites').doc(invite.id).update({
                    Status: {ID: 1000004,Name: 'Cancelled'}
                }) 
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        GetUserInvites(){
            this.InviteColRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    this.UserInvites.push({
                    ...change.doc.data(),
                    id: change.doc.id
                    })
                }
                if (change.type === 'modified') {
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.UserInvites.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.UserInvites.indexOf(oncheck)
                        if(index){
                            this.UserInvites.splice(index,1,invobj)
                        }
                    }
                }
                if(change.type === 'removed'){
                    let invobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                    }
                    let oncheck = this.UserInvites.find(obj => obj.id === invobj.id)
                    if(oncheck){
                        let index = this.UserInvites.indexOf(oncheck)
                        if(index){
                            this.UserInvites.splice(index,1)
                        }
                    }
                }
                
                //console.log('this.UserInvites',this.UserInvites)
                })
            })
        },
    }
}
</script>

<style>

</style>
