<template>
  <div style="margin-top:50px;">
    <v-list>
        <v-list-item>
            <v-list-item-content>
                Name
            </v-list-item-content>
            <v-list-item-content>
                Page Count
            </v-list-item-content>
             <v-list-item-action>
                View
             </v-list-item-action>
        </v-list-item>
        <v-list-item v-for="func in PDFFunctions" :key="func.itemObjKey">
            <v-list-item-content>
                <v-list-item-title>
            {{func.FunctionDisplayName}}
                </v-list-item-title>
                <v-list-item-subtitle>
                    {{func.FunctionEntity}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="func.FunctionOutput">
                    {{func.FunctionOutput.DisplayName}}
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-content>
                {{func.Pages.length}}
            </v-list-item-content>
            <v-list-item-action>
                <v-btn :to="'/PDFExportFunctionBuilder/'+func.id">View</v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            PDFFunctions: [],
            
        }
    },
    computed:{
        
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
    },
    created(){
        if(!this.userIsAdmin){
            //reroute
        }
        else{
          this.GetPDFExportFunctions()
        }
        
    },
    methods:{
       GetPDFExportFunctions(){
            db.collection('pdfexportfunctions').onSnapshot(res => {
                const changes = res.docChanges();
                    changes.forEach(change => {
                        if (change.type === 'added') {
                          let buobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        this.PDFFunctions.push(buobj)                          
                        }
                    }) 
            })
       }
    }
}
</script>

<style>

</style>
