
                <template>

  <v-main class="businessunits">
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Business Unit.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  
<v-parallax class="recordparallax2"
    height="150"
        src="@/assets/RASolidA.jpeg"
    >
    <v-row
          align="center"
          justify="center"
        >
    <v-col class="stdparallaxgradient text-center" cols="12">
      <span class="largeoverline font-weight-thin"><v-icon class="white--text" x-large>mdi-face</v-icon>Business Units</span>
      
    </v-col>
  </v-row>
    </v-parallax>
<v-layout style="padding-top:100px;" class="businessunits justify-center">
<v-flex xl11 lg11 md11 sm11 xs11>
<v-data-table
    :headers="headers"
    :items="BusinessUnitsFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Business Units</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <!-- <v-btn @click="Unlinkallrelationships()">UnlinkAll </v-btn> -->
        <v-text-field light
          v-model="search"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Business Unit</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.Name" label="Name"></v-text-field>

                    <v-textarea v-model="editedItem.Description" label="Description"></v-textarea>
                      
                  </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>

<v-toolbar v-if="BusinessUnitsArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>
</v-flex>
</v-layout>
  </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  data() {
    return {

      ParentArray: [],
      Parentfilter: {Name: ''},
      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      headers: [
      
        { text: 'Name', value: 'Name'},


        { text: 'Description', value: 'Description'},



        { text: 'Parent', value: 'Parent.Name'},

        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedBusinessUnitsnackbar: false,
      BusinessUnitEditdialog: false,
      newBusinessUnitdialog: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
          Name: '',
          Description: '',
          Parent: {id: '',  Name: ''},
      },
      editedItem: {
        id: '',
          Name: '',
          Description: '',
          Parent: {id: '',  Name: ''},
      },
    }
  },

  created(){
    this.ScrolltoTop()
     this.$store.commit('setBuilderView',{Name: 'Database Builder'}) 
    this.GetBusinessUnits()

      this.GetBusinessUnitsParent()

  },
  computed: {
    BusinessUnitsArray(){
      return this.$store.state.BusinessUnitsArray
    },
    FinalBusinessUnitsParentArray(){
      return this.BusinessUnitsArray.map(BusinessUnit => {
        let lookupobject = this.ParentArray.find(Parent => Parent.id === BusinessUnit.Parentid)
        BusinessUnit.Parent = lookupobject
        return BusinessUnit
      })
      },

    formTitle () {
        return this.editedIndex === -1 ? 'New Business Unit' : 'Edit Business Unit'
      },
    BusinessUnitsSearched() {
      return this.BusinessUnitsArray.filter(BusinessUnit => {
        if(this.search !== ''){
        if(BusinessUnit.Name){return BusinessUnit.Name.toLowerCase().includes(this.search.toLowerCase())}
        }
        else {
          return BusinessUnit
        }
      })
      },
      BusinessUnitsFilteredandSearched() {
      return this.BusinessUnitsSearched.filter(BusinessUnit => {return BusinessUnit})
      },

    },
  methods: {
    ScrolltoTop(){
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
    Unlinkallrelationships(){
      this.BusinessUnitsArray.map(unit => {
        this.FlattUnitStructure(unit.id)
      })
    },

    FlattUnitStructure(id){
      let Queryflatten = [id]
      let DbFlatten = {}
      DbFlatten[id] = true
      db.collection('businessunits').doc(id).update({
        Parent: firebase.firestore.FieldValue.delete(),
        Parentid: firebase.firestore.FieldValue.delete(),
        childarrayDBRules: DbFlatten,
        childarrayQuery: Queryflatten

      })
    },
    ResetFilters() {},
    ResetSearch() {
      this.search = ''
    },

    GetBusinessUnits() {
    //   db.collection('businessunits').onSnapshot(res => {

    //   const changes = res.docChanges();
    //   changes.forEach(change => {
    //     if (change.type === 'added') {
    //       this.BusinessUnitsArray.push({
    //         ...change.doc.data(),
    //         id: change.doc.id
    //       })
    //     }

    //   })
    // })
  },



      GetBusinessUnitsParent() {
      db.collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ParentArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }

      })
      //console.log(this.FinalBusinessUnitsParentArray)
    })
      },


      ViewItem (item) {
        this.$router.replace('/BusinessUnit/'+item.id)
      },

      deleteItem (item) {
        const index = this.BusinessUnitsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('businessunits').doc(item.id).delete() && this.BusinessUnitsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.BusinessUnitsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedBusinessUnit = {
    Name: this.editedItem.Name,
    Description: this.editedItem.Description
        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('businessunits').doc(this.mainrecordid).update({
        Name: EditedBusinessUnit.Name,
        Description: EditedBusinessUnit.Description,
              });

              Object.assign(this.BusinessUnitsArray[this.editedIndex], this.editedItem)
              this.closeBusinessUniteditDialog
              this.EditedBusinessUnitsnackbar = true
        }
         else {
          const NewBusinessUnit = {
      Name: EditedBusinessUnit.Name,
      Description: EditedBusinessUnit.Description,
          }

          db.collection('businessunits').add(NewBusinessUnit).then(doc => {
            let unitchildDBprep = {}
            unitchildDBprep[doc.id] = true
            let unitchildQueryprep = []
            unitchildQueryprep.push(doc.id)
            db.collection('businessunits').doc(doc.id).update({
            childarrayDBRules: unitchildDBprep,
            childarrayQuery: unitchildQueryprep,
          })
          })
        }

        this.close()
      },

  }
}

</script>

      