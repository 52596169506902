
<template>
<div>
    <v-card flat  :class="MobileView ? tab.RowTransparency : tab.RowTransparency+' mx-3'"  :color="tab.RowColor" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="ActiveSuiteApp ? '' : 'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
        <!-- <v-btn @click="TableReady = !TableReady">Refresh</v-btn> -->
        <!-- tab.EntitySource {{tab.EntitySource}} -->
        
        <v-skeleton-loader  v-if="!TableReady"
      class="mx-auto my-2"
      width="100%"
      type="card-heading,card-heading,table"
    ></v-skeleton-loader>
            <component v-bind:is="TableComponent" :IntranetView="IntranetView" v-if="TableReady"
            @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :MasterTableObj="MasterTableObj"
            :TableEntryPath="TableEntryPath" :DynamicWikiData="DynamicWikiData" @ActivateProcessing="ActivateProcessing"
            :Data="TableData" :Headers="TableHeaders" :TableName="TableName" :TableIcon="TableIcon" :PrimaryField="PrimaryField" :EntityCollectionRef="EntityCollectionRef"
            :TableCollectionRef="TableCollectionRef" :CanCreate="tab.EntitySource === 'SubCollection' ? tab.CanCreate : CanCreate"
            @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
            @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :RADB="RADB" :System="System"
            @RefreshComponent="RefreshComponent" :tab="tab" :RelatedObj="RelatedObj" :CurrentEntity="CurrentEntity" :SystemEntities="SystemEntities"
            :PageView="true" :PageFilter="TablePageFilter" :AppisDarkMode="AppisDarkMode"  :CompSize="TableCompsize" :id="tab.Entityid"/>
    </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: [{TableComponent: {			
			type: String,
			required: true
		}},'System','RADB','CurrentEntity','DynamicWikiData','ParentTab','AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','i','IsElement','tabindex','rowindex','SystemEntities','RelatedObj','EntityCollectionRef','EntityDataRef','SampleRecords'],
    components: {

        },
    data() {
    return {  
        PrimaryEntity: '',
        TableReady: false,
        TableEntity: '',
        CanCreate: false,
        PrimaryField: '',
        MasterData: [],
        MasterTableObj: '',
        MasterHeaders: [],
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    mounted(){
        this.$store.watch(
        state => state.TableData,
            (val)=>{
                //NOTDONE AT ALL
            //something changed do something
                    //console.log('store.TableData '+this.$route.name,val)
                    // val.map(table => {
                    //     if(this.CurrentEntity && this.CurrentEntity.id.split('_').join('')+'Array' === table.Name && this.TableBuilder){
                    //         this.MasterData = table.Data
                    //         this.DataInteractionPrep()
                    //         //console.log('state.TableData ',table.Data)
                    //     }
                    //     else if(this.FinalTableEntity && this.FinalTableEntity.id.split('_').join('')+'Array' === table.Name && this.IsRelatedTable){
                    //         this.MasterData = table.Data
                    //         this.DataInteractionPrep()
                    //         //console.log('state.TableData ',table.Data)
                    //     }
                    // })
            },
            {
                deep:true
            }
      );
    },
    watch: {
      CanCreate(value) {
            if (value && this.$route.meta && this.$route.meta.TableBuilder) {
              //this.$store.commit('SetCurrentItemModerator',value)              
            }
            else if(this.$route.meta && this.$route.meta.DataSingleBuilder){
                //this.tab.CanCreate = true
            }
        },
    },
    computed:{
        MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        ActiveSuiteApp(){
            return this.$store.state.ActiveSuiteApp
        },
        FinalTableEntity(){
        return this.IsRelatedTable ? this.SystemEntities.find(obj => obj.id === this.tab.Entityid) : this.CurrentEntity
      },
        IsRelatedTable(){
            return this.tab.Entityid !== this.CurrentEntity.id && this.$route.params.id && this.tab.RelationshipField
        },
        TableBuilder(){
            return this.$route.meta && this.$route.meta.TableBuilder || this.$route.name === 'MyAccount' || this.$route.name === 'MyDashboard'
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        // PrimaryField(){
        //     if(this.tab.EntitySource === 'SubCollection'){
        //         let subcol = this.DynamicWikiData.SubCollections.find(obj => obj.id === this.tab.Entityid)
        //         if(subcol && subcol.RecordPrimaryField){
        //             return subcol.RecordPrimaryField.Name
        //         }   
        //     }
        //     else if(this.tab.EntitySource === 'System Routes'){
        //         let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
        //         if(entity && entity.RecordPrimaryField){
        //             return entity.RecordPrimaryField.Name
        //         }
        //     }
            
        // },
        TableCollectionRef(){
            //this.DynamicWikiData
            //console.log('this.tab',this.tab)
            if(this.tab.Entityid && !this.tab.Dynamic && this.tab.EntitySource === 'System Routes'){	
                //console.log('this.tab',db.collection(this.TableNameSplit.toLowerCase()))		
                return db.collection(this.TableNameSplit.toLowerCase())		
            }
            else if(this.tab.Entityid && !this.tab.Dynamic && this.tab.EntitySource === 'Photo Library'){			
                return db.collection(this.TableNameSplit.toLowerCase())		
            }
            else if(this.tab.Entityid && this.tab.Dynamic && this.tab.EntitySource === 'SubCollection' && !this.PrimaryEntity){			
                return db.collection(this.DynamicWikiData.Collection).doc(this.$route.params.id).collection(this.tab.Entityid)
            } 
             else if(this.tab.Entityid && this.tab.EntitySource === 'SubCollection' && !this.PrimaryEntity){			
                return this.EntityDataRef.collection(this.tab.Entityid)
            } 
            else if(this.tab.Entityid && this.tab.EntitySource === 'SubCollection' && this.EntityDataRef && this.PrimaryEntity){			
                return this.EntityDataRef.collection(this.PrimaryEntity).doc(this.DynamicWikiData.id).collection(this.tab.Entityid)
            }            
        },
        TableIcon(){
            if(this.tab.EntitySource === 'System Routes'){
                let entityobj = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                if(entityobj){
                    return entityobj.Table_Icon
                }
            }
            else if(this.tab.EntitySource === 'SubCollection'){
                return this.tab.Icon
            }
            else{
                return this.tab.Icon
            }
        }, 
        TableName(){
            let entityobj = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
            if(entityobj && entityobj.DisplayName){
                return entityobj.DisplayName
            } 
            else{
              return this.tab.DisplayName !== 'Table Embed' ? this.tab.DisplayName : this.tab.Entityid  
            }            
        }, 
        TableNameSplit(){
            return this.TableName? this.TableName.split(' ').join('').split('_').join('') : ''
        },
        TableData(){
            if(this.tab.Entityid && !this.tab.Dynamic){	
                
                // let storearrayname = this.TableNameSplit+'Array'
                // console.log(storearrayname,this.$store.state[storearrayname],this.$store.state)		
                return this.MasterData		
            }
            else if(this.tab.Entityid && this.tab.Dynamic){		
                // let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                // this.MasterData = tableobj.Data
                return this.MasterData.map(item => {
                    item.CanView = false
                    item.CanEdit = false
                    item.CanDelete = false
                    if(this.tab.ViewersCanUpdate){
                        item.CanView = true
                        item.CanEdit = true
                        item.CanDelete = false
                    }
                    return item
                })
            }
        },
        TableHeaders(){
            let headers = this.MasterHeaders
            .map(header => {
                let headerobj = Object.assign({},header)
                if(this.IsRelatedTable && this.tab.RelationshipField && this.tab.RelationshipField.MappedValues && this.tab.RelationshipField.RecordData){
                    let oncheck = this.tab.RelationshipField.MappedValues.find(obj => obj.OutputField.id === headerobj.id)
                    if(oncheck){                        
                        headerobj.Response = this.tab.RelationshipField.RecordData[oncheck.InputField.Name] 
                        headerobj.Array = [headerobj.Response]    
                        headerobj.MappedRelationshipField = true
                        headerobj.ReadOnly = true               
                    }
                }
                return headerobj
            })
            .map(header => {
                if(header.Type === 'Lookup' && header.Name === 'Owner'){
                    let entityobj = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                    let tablesecuritystructure = entityobj.datasetsecuritystructure
                    let namecheck = entityobj.id.split('_').join('')
                    if(tablesecuritystructure === 'Business Unit'){
                        header.Array = header.Array.filter(item => {
                            if(this.$store.state[namecheck+'createAll']){
                                return item
                            }
                            else if(this.$store.state[namecheck+'createUnitDown']){
                                return this.userBUChildren.includes(item.Business_Unitid)
                            }
                            else if(this.$store.state[namecheck+'createUserUnit']){
                                return item.Business_Unitid === this.userBUID
                            }
                            else if(this.$store.state[namecheck+'createOwner']){
                                return item.id === this.userLoggedIn.id
                            }
                        })                        
                    }
                }
                return header
            })
            if(this.CurrentEntity && this.CurrentEntity.Entity_Type && this.CurrentEntity.Entity_Type.Name === 'Store Product'){
                let warehousefield = {
                    Name: 'Warehouse',DisplayName: 'Warehouse',id: 'Warehouse',Type: 'Lookup',text: 'Warehouse',value: 'Warehouse',propvalue: 'Warehouse',HeaderIndex: 2,RelatedBuildID: 'Warehouses',LookupFieldName: 'Name',class: 'overline',Array: this.$store.state.WarehousesArray.filter(item => {
                        return this.userLoggedIn && this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[item.id] && this.userLoggedIn.Warehouses[item.id].Create || this.userIsAdmin
                    })
                }
                headers.push(warehousefield)
            }
            return headers.filter(head => {
                if(this.$route.meta && this.$route.meta.TableBuilder){
                    return !head.ReadOnly || head.Primary
                } 
                else{
                    return head
                }
                })
                    // if(this.tab.Entityid && !this.tab.Dynamic){			
            //     // return []	
            //     let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                
            //     let headers = this.MasterHeaders
            //     return headers
            // }
            // else if(this.tab.Entityid && this.tab.Dynamic){			
            //      let tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
            //     let headers = this.MasterHeaders
            //     return headers
            // }
        },
      TableComponent(){
        if(this.tab.Entityid && !this.tab.Dynamic && this.tab.EntitySource !== 'Photo Library'){		
            //return () => import(`@/views/${this.TableNameSplit}.vue`);
            return () => import(`@/components/Database/DataTableViewer.vue`);			
        }
        else if(this.tab.Entityid && !this.tab.Dynamic && this.tab.EntitySource === 'Photo Library'){	
            console.log(this.tab)			
            //return () => import(`@/views/${this.TableNameSplit}.vue`);
            return () => import(`@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer.vue`);			
        }
        else if(this.tab.Entityid && this.tab.Dynamic){			
            console.log(this.tab)	
            return () => import(`@/components/Database/DataTableViewer.vue`);			
        }
      },
      TablePageFilter(){
          if(this.tab.Entityid && this.tab.ContentFilter){
               let FilterObj = {
                FieldName: this.tab.ContentFilter.Name,
                FieldType: this.tab.ContentFilter.Type,
                Filter: this.tab.ContentFilter.ContentFilterValue              
                }
                return FilterObj
          }
      },
      TableCompsize(){
        if(this.tab.Entityid){
          let comp = document.getElementById(this.tab.Entityid)
            if(comp){
            return this.$vuetify.breakpoint.width
            //return comp.offsetWidth
            }
            else{
                return this.$vuetify.breakpoint.width
            //return this.$vuetify.breakpoint.width/this.ParentTab.Elements.length
            }
        }
      },
    //   TablePageFilter(){
    //     if(this.tab.Entityid){		
    //         return this.TableNameSplit}+'PageFilter'			
    //     }	
    //   },
    //   TableCompsize(){
    //     if(this.tab.Entityid){			
    //         return this.TableNameSplit}+'size'			
    //     }	
    //   },
    //   TablePageFilter(){
    //     if(this.tab.Entityid){			
    //         return this.TableNameSplit}+'PageFilter'
    //     }
    //   },
    //   TableCompsize(){
    //     if(this.tab.Entityid){			
    //         return this.TableNameSplit}+'size'
    //     }
    //   },
    //   Tableid(){
    //        if(this.tab.Entityid){			
    //             return this.TableNameSplit}+'comp'
    //         }
    //   },
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      
      

    },
    created(){
        //console.log(this.$store.state)
        //console.log(this.DynamicWikiData)
        //console.log(this.tab.Entityid && this.tab.Dynamic && this.tab.EntitySource === 'SubCollection',this.tab,this.TableCollectionRef,this.EntityDataRef,this.DynamicWikiData,this.$router.getRoutes())
        this.PrepareTable()
        setTimeout(() => {
            // console.log(this.tab.Entityid && this.tab.Dynamic && this.tab.EntitySource === 'SubCollection',this.tab,this.TableCollectionRef,this.EntityDataRef,this.DynamicWikiData)
            this.TableReady = true
        }, 3000);
        
    },
    
    methods: {
        ActivateProcessing(boolean){
        this.$emit('ActivateProcessing',boolean)
      }, 
        ActivateNotificationSnackbar(boolean,snackbarcontent){
        this.$emit('ActivateNotificationSnackbar',boolean,snackbarcontent)
        },
        RefreshComponent(boolean){
            this.$emit('RefreshComponent',boolean,this.tab)
        },
        PrepareTable(){
            this.PreparePrimaryField()            
            
            
            
            
        },
        PreparePrimaryField(){
            if(this.$route.name === 'DashboardBuilder' || this.$route.name === 'CustomDashboard'){
                this.TableEntity = this.CurrentEntity
                this.PrepareHeaders()
                this.MasterData = this.tab.EntityData
            //     this.MasterHeaders = this.CurrentEntity.AllFields.filter(field => {
            //         return field.IsHeader
            //     }).sort((b, a) => {			
            //         //console.log('sorting again',a.HeaderIndex,b.HeaderIndex)
            //         var key1 = a.HeaderIndex			
            //         var key2 = b.HeaderIndex			

            //         if (key1 > key2) {			
            //             return -1;			
            //         } else if (key1 == key2) {			
            //             return 0;			
            //         } else {			
            //             return 1;			
            //         }			
            // })
            }
            else if(this.tab.EntitySource === 'SubCollection'){
                let subcol = this.DynamicWikiData.SubCollections.find(obj => obj.id === this.tab.Entityid)
                if(subcol && subcol.RecordPrimaryField){
                    this.PrimaryField = subcol.RecordPrimaryField.Name
                    
                }   
                this.TableEntryPath = this.tab.TableEntryPath
                this.PrepareHeaders()
            }
            else if(this.tab.EntitySource === 'System Routes'){
                //alert('ay yoh yeah')
                let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                this.TableEntity = entity
                //console.log(this.SystemEntities,this.tab,this.TableEntity)
                if(entity && entity.Primary_Field_Name){
                    this.PrimaryField = entity.Primary_Field_Name
                    this.TableEntryPath = entity.SingleName.split(' ').join('')
                    this.PrepareHeaders()
                }
            }
            
        },
        PrepareHeaders(){
            if(this.tab.EntitySource === 'SubCollection'){
                let tableobj = ''
                
                if(this.DynamicWikiData.LookupObj){                    
                    tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.SubColName === this.tab.Entityid)
                }
                else{
                    tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                }
                if(tableobj.HeadersSubCollection){
                    let HeadersSubCollection = this.DynamicWikiData.SubCollections.find(obj => obj.Name === tableobj.HeadersSubCollection)
                    //actually no. The issue is TableData. We need to refer to "AllFields" subcol data entries
                    //console.log(HeadersSubCollection)
                    let filteredheaders = HeadersSubCollection.Data.filter(header => {return header.IsHeader && !header.SystemRequired}).map(header => {
                        let newheaderobj = {
                            text: header.Name,
                            value: header.DisplayName,
                            Type: header.Type,
                            Name: header.Name,
                            DisplayName: header.DisplayName
                        }
                        if(header.Primary){
                            newheaderobj.Primary = header.Primary
                        }
                        if(header.Options){
                            newheaderobj.Options = header.Options
                        }
                        if(header.lastassignedinteger){
                            newheaderobj.lastassignedinteger = header.lastassignedinteger
                        }
                        if(header.HasDeepHierarchy){
                            newheaderobj.HasDeepHierarchy = header.HasDeepHierarchy
                        }
                        if(header.Levels){
                            newheaderobj.Levels = header.Levels
                        }
                        if(header.DefaultLevel2Option){
                            newheaderobj.DefaultLevel2Option = header.DefaultLevel2Option
                        }
                        if(header.DefaultLevel1Option){
                            newheaderobj.DefaultLevel1Option = header.DefaultLevel1Option
                        }
                        if(header.HasTimeInput){
                            newheaderobj.HasTimeInput = header.HasTimeInput
                        }
                        if(header.IsMandatory){
                            newheaderobj.IsMandatory = header.IsMandatory
                        }
                        if(header.IsContentEditable){
                            newheaderobj.IsContentEditable = header.IsContentEditable
                        }
                        return newheaderobj
                    })
                    //console.log(filteredheaders)
                    filteredheaders.map(header => {
                        let oncheck = tableobj.Headers.find(obj => obj.Name === header.Name)
                        if(!oncheck){
                            tableobj.Headers.push(header)
                        }
                    })
                    
                }
                //console.log('tableobj',tableobj,this.tab)
                this.PrimaryEntity = tableobj.PrimaryEntity
                //console.log('this.DynamicWikiData',this.DynamicWikiData,this.tab,tableobj,this.PrimaryEntity)

                let headers = tableobj.Headers.map(header => {
                    header.class = 'overline'
                    if(!header.propvalue){
                        header.propvalue = header.value
                    }
                    header.propvalue = header.propvalue.split(' ').join('_')
                    return header
                })
                let actionheader = {
                    text: 'Actions',
                    value: 'actions',
                    class: 'overline'
                }
                // let Primaryheader = {
                //     text: '',
                //     value: 'RecordPrimaryField',
                //     class: 'overline'
                // }
                if(this.tab.ViewersCanUpdate){
                    // let primaryon = headers.find(obj => obj.value === 'RecordPrimaryField')
                    // if(!primaryon){
                    //     headers.splice(0,0,Primaryheader)
                    // }
                }
                let oncheck = headers.find(obj => obj.value === 'actions')
                if(!oncheck){
                    headers.push(actionheader)
                }
                // let primaryheader = headers.find(obj => obj.Primary)                
                // this.MasterHeaders = headers   
                // this.MasterHeaders.splice(0,0,primaryheader)
                this.MasterHeaders = headers
                //console.log(this.MasterHeaders)
            }
            else if(this.tab.EntitySource === 'System Routes'){
            let entity = this.TableEntity
                //console.log(this.TableEntity)
            this.MasterHeaders = entity.AllFields.filter(field => {
                return field.IsHeader
            }).map((header,headerindex) => {
                header.RelatedReadOnly = false
                header.text = header.DisplayName,
                header.propvalue = header.Name
                header.value = header.Name,
                header.class = 'overline'
                if(header.Type === 'Option Set'){
                    
                    header.value = header.Name+'.Name'
                    if(header.DefaultOption){
                        header.Response = header.DefaultOption
                    }
                    //console.log('header Option Set',header)
                    if(header.HasDeepHierarchy && header.Levels && header.Levels[1] && header.DefaultLevel2Option){
                        header.Level1Response = header.DefaultLevel2Option
                    }
                }
                else if(header.Type === 'Lookup' || header.Type === 'Social Lookup'){
                    header.Array = []
                    if(header.RelatedBuildID === 'Users'){
                        header.Array = this.$store.state.Users
                    }
                    else{
                        let storearrayname = ''
                        if(header.RelatedBuildID === 'Users'){
                            storearrayname = 'Users'
                        }
                        else{
                            storearrayname = header.RelatedBuildID.split('_').join('')+'Array'
                        }
                        if(!this.$store.state[storearrayname] || this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                            let vm = this
                            let storequery = header.RelatedBuildID.split('_').join('')+'Query'
                            if(vm.$store.state[storequery]){
                                if(vm.IsRelatedTable && vm.tab.RelationshipField.Name === header.Name){
                                    header.Array.push(vm.tab.RelationshipField.RecordData)
                                    header.Response = vm.tab.RelationshipField.RecordData
                                    header.RelatedReadOnly = true
                                    
                                }
                                else{
                                    let payload = {                
                                    query: this.$store.state[storequery],
                                    arraymutation: 'set'+storearrayname,
                                    getter: 'get'+storearrayname,
                                    arrayname: storearrayname
                                    }
                                    vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
                                        header.Array = vm.$store.state[storearrayname]
                                    })
                                }                                
                            }
                        }
                        else if(this.$store.state[storearrayname]){
                            header.Array = this.$store.state[storearrayname]
                            .filter(item => {
                                if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name){
                                    return item.id === this.$route.params.id
                                }
                                else{
                                return item  
                                }                                
                            })
                            if(this.IsRelatedTable && this.tab.RelationshipField.Name === header.Name){
                                header.ReadOnly = true
                                header.Response = header.Array[0]
                            }
                        }
                    }
                }
                return header
            }).sort((b, a) => {			
                    //console.log('sorting again',a.HeaderIndex,b.HeaderIndex)
                    var key1 = a.HeaderIndex			
                    var key2 = b.HeaderIndex			

                    if (key1 > key2) {			
                        return -1;			
                    } else if (key1 == key2) {			
                        return 0;			
                    } else {			
                        return 1;			
                    }			
            })
            // let actionheader = {
            //         text: 'Actions',
            //         value: 'actions',
            //         class: 'overline'
            //     }
            // let oncheck = this.MasterHeaders.find(obj => obj.value === 'actions')
            //     if(!oncheck){
            //         this.MasterHeaders.push(actionheader)
            //     }
            //     let statusfield = entity.AllFields.find(obj => obj.Name === 'Status')
            //     statusfield = {
            //         text: 'Status',
            //         value: 'Status.Name',
            //         class: 'overline'
            //     }
                
            // let statusheader = this.MasterHeaders.find(obj => obj.value === 'Status')
            //     if(!statusheader){
            //         this.MasterHeaders.push(statusfield)
            //     }
            //     else{
            //         let statusheaderindex = this.MasterHeaders.indexOf(statusheader)
            //     }	
            let primaryheader = this.MasterHeaders.find(obj => obj.value === this.PrimaryField) 
            
            let currentprimaryindex = this.MasterHeaders.indexOf(primaryheader)
            //console.log(currentprimaryindex,primaryheader)
            this.MasterHeaders.splice(0,0,this.MasterHeaders.splice(currentprimaryindex,1)[0])
            //console.log(this.MasterHeaders)
            }
            this.PrepareData()
            
        },
        PrepareData(){
            let vm = this
             if(this.tab.EntitySource === 'System Routes' && !this.IsRelatedTable){	
                //  console.log('check for RelatedObj',this.tab)
                let matchinglookup = ''
                  if(vm.RelatedObj){
                        //console.log('RelatedObj',vm.RelatedObj,vm.TableEntity)
                        matchinglookup = this.TableEntity.AllFields.find(obj => obj.LookupFieldName === vm.RelatedObj.identifier)
                        //console.log('matchinglookup',matchinglookup)
                        // if(matchinglookup){

                        // }
                    }   
                let storearrayname = this.TableNameSplit+'Array'
                if(this.SampleRecords){
                    this.MasterData = this.SampleRecords.filter(record => {
                        if(matchinglookup){
                            //console.log('record[matchinglookup.Name+id',record[matchinglookup.Name+'id'],vm.RelatedObj.id)
                            return record[matchinglookup.Name+'id'] === vm.RelatedObj.id
                        }
                        else{
                            return record
                        }
                    })
                }
                
                else{
                  this.MasterData = this.$store.state[storearrayname].filter(record => {
                        if(matchinglookup){
                            //console.log('record[matchinglookup.Name+id',record[matchinglookup.Name+'id'],vm.RelatedObj.id)
                            return record[matchinglookup.Name+'id'] === vm.RelatedObj.id
                        }
                        else{
                            return record
                        }
                    })  
                }
                
                //console.log('first MasterData',vm.MasterData)
                if(!this.$store.state[storearrayname] || this.$store.state[storearrayname] && this.$store.state[storearrayname].length === 0){
                    //alert('yep - array empty')
                    //now hold up, also check if RelatedObj, which would always be true, unless I only pass it in "related"
                   
                    let storequery = this.TableNameSplit+'Query'
                    //console.log('ssems array is empty for '+storearrayname+'. Lets try query '+storequery)
                    if(this.$store.state[storequery]){
                        //alert('yep - query existing')
                        ///console.log('getting a query for '+storequery)
                        let payload = {                
                        query: vm.$store.state[storequery],
                        arraymutation: 'set'+storearrayname,
                        getter: 'get'+storearrayname,
                        arrayname: storearrayname
                        }
                        //console.log('here is payload ready to roll but we don not roll with it as we already call it for some damn reason, is it fmor accounts single that we run it/',payload)
                        
                        vm.$store.dispatch('GetCollectionArray',payload).then(function(result) {
                            //console.log('after GetCollectionArray',vm.$store.state[storearrayname],result)
                            vm.MasterData = vm.$store.state[storearrayname].filter(record => {
                                if(matchinglookup){
                                    //console.log('record[matchinglookup.Name+id',record[matchinglookup.Name+'id'],vm.RelatedObj.id)
                                    return record[matchinglookup.Name+'id'] === vm.RelatedObj.id
                                }
                                else{
                                    return record
                                }
                            })
                            //console.log('after queasdry MasterData',vm.MasterData)
                            //vm.AccountsArray = vm.AccountsStore
                            vm.LookupHeadersPrep()
                            vm.DataInteractionPrep()
                            vm.TableReady = true
                            })
                    }
                    else{
                        //alert('whatever man no query existing '+storequery)
                    }
                }
                else{
                    vm.MasterData = vm.$store.state[storearrayname].filter(record => {
                    if(matchinglookup){
                        //console.log('record[matchinglookup.Name+id',record[matchinglookup.Name+'id'],vm.RelatedObj.id)
                        return record[matchinglookup.Name+'id'] === vm.RelatedObj.id
                    }
                    else{
                        return record
                    }
                })
                    vm.TableReady = true
                    //console.log(vm.MasterData)
                    vm.LookupHeadersPrep()
                    vm.DataInteractionPrep()
                }
             }    
             else if(this.tab.EntitySource === 'System Routes' && this.IsRelatedTable){	
                 let relatedtable = this.DynamicWikiData.RelatedTables.find(obj => obj.id === this.tab.Entityid)
                 this.MasterData = relatedtable.StoreData
                 vm.DataInteractionPrep()
             }
             else if(this.tab.EntitySource === 'SubCollection'){	
                 //alert('subcol tablembed')	
                 //console.log('this.DynamicWikiData',this.DynamicWikiData)
                 //console.log('this.DynamicWikiData.SubCollections',this.DynamicWikiData.SubCollections)
                let tableobj = ''
                
                if(this.DynamicWikiData.LookupObj){                    
                    tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.SubColName === this.tab.Entityid)
                    this.MasterData = tableobj.SubColData
                }
                else{
                    tableobj = this.DynamicWikiData.SubCollections.find(obj => obj.Name === this.tab.Entityid)
                    this.MasterTableObj = tableobj
                    this.MasterData = tableobj.Data
                }
               this.PrimaryEntity = tableobj.PrimaryEntity
                //console.log(this.MasterData,tableobj,this.PrimaryEntity)
             }            
        },
        GetWarehouseTableInteraction(Data){
            let rules = [
                {rule: 'CanCreate',prop: 'Create'},
                {rule: 'CanView',prop: 'Get'},
                {rule: 'CanEdit',prop: 'Update'},
                {rule: 'CanDelete',prop: 'Delete'}
            ]
            return Data.map(data => {
                rules.map(rule => {
                    if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[data.Warehouseid] || this.userIsAdmin){
                        data[rule.rule] = this.userLoggedIn.Warehouses[data.Warehouseid][rule.prop]
                    }
                    else{
                        data[rule] = this.userIsAdmin    
                    }                    
                })
                return data                        
            })
        },
        GetCustomRolesTableInteraction(Data){
            let rules = [
                'CanCreate',
                'CanView',
                'CanEdit',
                'CanDelete'
            ]
            return Data.map(data => {
                rules.map(rule => {
                    data[rule] = this.$store.state[this.TableNameSplit+rule]
                    
                })
                return data                        
            })
        },
        GetBUTableInteraction(Data){
            
            let rules = [
                {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'CanList', PermissionLevel: 4},
                {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'CanList', PermissionLevel: 3},
                {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'CanList', PermissionLevel: 2},
                {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'CanList', PermissionLevel: 1},
                {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'CanGet', PermissionLevel: 4},
                {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'CanGet', PermissionLevel: 3},
                {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'CanGet', PermissionLevel: 2},
                {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'CanGet', PermissionLevel: 1},
                {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},
                {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'CanEdit', PermissionLevel: 4},
                {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'CanEdit', PermissionLevel: 3},
                {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'CanEdit', PermissionLevel: 2},
                {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'CanEdit', PermissionLevel: 1},
                {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'CanDelete', PermissionLevel: 4},
                {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'CanDelete', PermissionLevel: 3},
                {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'CanDelete', PermissionLevel: 2},
                {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'CanDelete', PermissionLevel: 1},
            ]
            return Data.map(data => {
                rules.map(rule => {
                    //console.log(rule.PermissionLevel,rule.Action,this.TableNameSplit+rule.Boolean,this.$store.state[this.TableNameSplit+rule.Boolean])
                    if(rule.PermissionLevel === 4){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean]){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 3){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && this.userBUChildren.includes(data.Business_Unitid)){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 2){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && data.Business_Unitid === this.userBUID){
                            data[rule.Action] = true          
                        } 
                    }
                    else if(rule.PermissionLevel === 1){
                       if(this.$store.state[this.TableNameSplit+rule.Boolean] && data.Ownerid === this.userLoggedIn.id){
                            data[rule.Action] = true          
                        } 
                    }
                    
                })
                return data                        
            })
        },
        DataInteractionPrep(){
            //So below I have the structure I must interrogate. 
            //I get the security structure from router. But perhaps t's even eaier to get from the storeconfig docs, for future modularity on router
            //go it, must call tablesecuritystructure
            let tablesecuritystructure = ''
            if(this.tab.EntitySource === 'System Routes'){	
                let entity = this.SystemEntities.find(obj => obj.id === this.tab.Entityid)
                if(!entity){
                    entity = this.TableEntity
                }
                tablesecuritystructure = entity.datasetsecuritystructure
                if(tablesecuritystructure === 'Warehouse'){
                    this.MasterData = this.GetWarehouseTableInteraction(this.MasterData)
                    if(this.userLoggedIn.Warehouses){
                        for(var prop in this.userLoggedIn.Warehouses) {
                            if (this.userLoggedIn.Warehouses.hasOwnProperty(prop)) {
                                if(this.userLoggedIn.Warehouses[prop].Create || this.userIsAdmin){
                                    this.CanCreate = true
                                }
                            }
                        }
                        
                    }
                }
                else if(tablesecuritystructure === 'Custom Roles'){
                    this.MasterData = this.GetCustomRolesTableInteraction(this.MasterData)
                    this.CanCreate = this.$store.state[this.TableNameSplit+'CanCreate']
                }
                else if(tablesecuritystructure === 'Business Unit'){
                    this.MasterData = this.GetBUTableInteraction(this.MasterData)
                    let CreateRules = [{Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'CanCreate', PermissionLevel: 4},
                    {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'CanCreate', PermissionLevel: 3},
                    {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'CanCreate', PermissionLevel: 2},
                    {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'CanCreate', PermissionLevel: 1},]
                    CreateRules.map(rule => {
                        if(this.$store.state[this.TableNameSplit+rule.Boolean]){
                            this.CanCreate = true
                        }
                    })
                }
            }
           
        },
    LookupHeadersPrep(){
        let vm = this
        vm.MasterHeaders = vm.MasterHeaders.map(header => {
                        if(header.Type === 'Lookup' || header.Type === 'Social Lookup'){
                            //console.log('header is lookup',header,vm.CurrentEntity,vm.$store.state)
                            let headerarray = vm.$store.state[header.RelatedRecord+'Array']
                            //console.log(headerarray,header.RelatedRecord+'Query',vm.$store.state[header.RelatedRecord+'Query'])
                            if(!headerarray || headerarray && headerarray.length === 0){
                                if(vm.$store.state[header.RelatedRecord+'Query']){
                                    //console.log('here is lookup query for empty array ',vm.$store.state[header.RelatedRecord+'Query'])
                                    let lookuppayload = {                
                                    query: vm.$store.state[header.RelatedRecord+'Query'],
                                    arraymutation: 'set'+header.RelatedRecord+'Array',
                                    getter: 'get'+header.RelatedRecord+'Array',
                                    arrayname: header.RelatedRecord+'Array'
                                    }
                                    vm.$store.dispatch('GetCollectionArray',lookuppayload).then(function(result) {
                                    //console.log(vm.$store.state[storearrayname])
                                    header.Array = headerarray
                                    })
                                }
                                
                            }
                            else{
                                header.Array = headerarray
                            }
                            if(vm.CurrentEntity.datasetsecuritystructure === 'Business Unit'){
                                if(header.RelatedBuildID === 'Business_Units'){
                                    let entityprop = vm.CurrentEntity.DisplayName.split(' ').join('')
                                    header.Array = header.Array.filter(item => {
                                        //console.log('vm.$store.state BU field',vm.$store.state)
                                        return vm.$store.state[entityprop+'createAll'] || 
                                        vm.$store.state[entityprop+'createUnitDown'] && vm.userBUChildren.includes(item.id) || 
                                        vm.$store.state[entityprop+'createUserUnit'] && vm.userBUID === item.id || 
                                        vm.$store.state[entityprop+'createOwner'] && vm.userBUID === item.id || vm.userIsAdmin
                                    })
                                }
                                else if(header.RelatedBuildID === 'Users' && header.Name === 'Owner'){
                                    let entityprop = vm.CurrentEntity.DisplayName.split(' ').join('')
                                    header.Array = header.Array.filter(item => {
                                        return vm.$store.state[entityprop+'createAll'] || 
                                        vm.$store.state[entityprop+'createUnitDown'] && vm.userBUChildren.includes(item.Business_Unitid) || 
                                        vm.$store.state[entityprop+'createUserUnit'] && vm.userBUID === item.Business_Unitid || 
                                        vm.$store.state[entityprop+'createOwner'] && vm.userLoggedIn.id === item.id
                                    })
                                }
                            }                           
                            
                        }
                        return header
                    })
    },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           //console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>


</style>