<template>
      <v-system-bar
      window
      dark
      style="position:absolute;"
    >
    <v-icon>mdi-alert</v-icon><span> You have outstanding configurations on this App</span>
      <v-spacer></v-spacer>
    <v-dialog max-width="1000px">
        <template v-slot:activator="{ on }">
           <v-icon v-on="on">mdi-eye</v-icon>
        </template>
        <v-card>
            <v-card-title>
            <span class="headline">Outstanding Configuration Actions</span>
            </v-card-title>
            
            <v-card-text >
                <v-tabs>
                  <v-tab>Welcome</v-tab>
                    <v-tab-item style="padding:25px;" class="BoldBuilderGradient">
                     
                        <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                                class="mx-auto"
                                max-width="400">
                            
                            <v-card-title class="pink--text mediumoverline">
                                Welcome to RapidApps
                                </v-card-title>
                                   
                                 <v-layout fill-height row justify-center align-center>
                                    <div class="BoldBuilderGradient" style="position: absolute;width: 150px;height: 150px;border-radius:100px;animation: spin 4   s linear infinite;">
                                    </div>
                                      <v-img height="100" style="position: absolute;opacity: 0.3;" contain  src="@/assets/RAFullLogo.png">
                                       </v-img>
                                <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
                                     :value="100" :size="180" :width="20" class="largeoverline" :color="'blue lighten-4'">
                                     <v-card-title class="white--text mediumoverline" style="text-shadow: 4px 4px rgba(0,0,0,0.16);position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;font-weight:500;">Ready</v-card-title>
                                  
                                    <v-icon color="green" size="100" style="text-shadow: 4px 4px rgba(0,0,0,0.16);margin-top:40px;animation: simplepulsing 1.5s infinite;">
                                       mdi-cloud-check
                                    </v-icon>
                                    </v-progress-circular>
                                 </v-layout>
                               
                                     <v-card-actions>
                                     <v-spacer></v-spacer>
                                     <v-btn dark color=green>Go</v-btn>
                                 </v-card-actions>

                               
                            </v-card>
                    </v-tab-item>
                    <v-tab >Database Module</v-tab>
                    <v-tab-item style="padding:25px;">
                        <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="SystemGuidesMatched('Database Module')" :tab="systemguideelmnt" />
                    </v-tab-item>
                    <v-tab >Social Module</v-tab>
                    <v-tab-item style="padding:25px;">
                        <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="SystemGuidesMatched('Social Module')" :tab="systemguideelmnt" />
                    </v-tab-item>
                    <v-tab >Website Module</v-tab>
                    <v-tab-item style="padding:25px;">
                        <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="SystemGuidesMatched('Website Module')" :tab="systemguideelmnt" />
                    </v-tab-item>
                    <!-- <v-tab v-for="item in ComputedTabs" :key="item.itemObjKey">{{item.Name}}</v-tab>
                    <v-tab-item style="padding:25px;">
                        <SystemGuideComponent :AppisDarkMode="AppisDarkMode" class="my-3" :GuideList="ComputedSystemGuides" :tab="systemguideelmnt" />
                    </v-tab-item> -->
                    <v-tab>Social Module</v-tab>
                    <v-tab-item style="padding:25px;" class="BoldBuilderGradient">
                        <v-card style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                                class="mx-auto"
                                max-width="400">
                            
                            <v-card-title class="pink--text mediumoverline">
                                Social Module
                                </v-card-title>
                                  
                                 <v-layout fill-height row justify-center align-center>
                                     
                                <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
                                     :value="100" :size="180" :width="20" class="largeoverline" :color="'blue lighten-4'">
                                     <v-card-title class="green--text mediumoverline" style="position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;">Ready</v-card-title>
                                     <!-- <div class="BuilderGradient" style="margin-top:40px;width: 60px;height: 60px;border-radius:20px;position: absolute;animation: spin 4   s linear infinite;">
                                    </div>
                                    <div class="BuilderGradient" style="margin-top:40px;width: 60px;height: 60px;border-radius:20px;position: absolute;animation: spin 3s linear infinite;">
                                    </div>
                                    <div class="BuilderGradient" style="margin-top:40px;width: 60px;height: 60px;border-radius:20px;position: absolute;animation: spin 2s linear infinite;">
                                    </div>
                                     <div class="BuilderGradient" style="margin-top:40px;width: 60px;height: 60px;border-radius:20px;position: absolute;animation: spin 1s linear infinite;">
                                    </div>
                                     <div class="BuilderGradient" style="margin-top:40px;width: 60px;height: 60px;border-radius:20px;position: absolute;animation: spin 0.5s linear infinite;">
                                    </div> -->
                                    <v-icon color="blue" size="100" style="margin-top:40px;animation: simplepulsing 1.5s infinite;">
                                       mdi-cloud-check
                                    </v-icon>
                                    </v-progress-circular>
                                    <div class="square" style="margin-top:-0px;">
                                        <div class="one"></div>
                                        <div class="two"></div>
                                        <div class="three"></div>
                                        <div class="four"></div>
                                    </div>
                                 </v-layout>
                               
                                     <v-card-actions>
                                     <v-spacer></v-spacer>
                                     <v-btn dark color=green>Go</v-btn>
                                 </v-card-actions>

                               
                            </v-card>
                    </v-tab-item>
                    
                </v-tabs>
                
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="RAAppAdminDialog" max-width="500">
    <v-card
    class="mx-auto"
    max-width="500"
    dark
  >
    <v-img src="@/assets/RABaseBG.jpeg">
      
    <v-card-title class="title font-weight-regular justify-space-between">
      <span>Sign in to RapidApps    </span>
      <v-avatar
        size="45"
      >
      <v-img  v-if="userLoggedIn && userLoggedIn.Profile_Photo" :src="userLoggedIn.Profile_Photo">
      </v-img>
      <v-img v-if="!userLoggedIn || userLoggedIn && userLoggedIn.Profile_Photo" src="@/assets/BlankProfilePic.png">
      </v-img>
      </v-avatar>
    </v-card-title>
    <v-card-subtitle>
      {{userLoggedIn.Full_Name}}
    </v-card-subtitle>
    <v-card-text>
      <v-text-field type="text" v-model="raloginemail" placeholder="Email" size="12"/><br>
        <v-text-field type="password" v-model="raloginpassword" placeholder="Password" @keydown.enter="loginClientAppRAAdmin"/>
       <span class="caption">You may be signed in to RapidApps, but in order to speak to your data on your app, please sign into your app as an administrator</span>
    </v-card-text>
    <v-card-actions><v-btn @click="loginClientAppRAAdmin">Sign In</v-btn></v-card-actions>
    </v-img>
    </v-card>
    </v-dialog>
    </v-system-bar>
     
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
import SystemGuideComponent from '@/components/WebPages/RenderComponents/SystemGuideComponent';

export default {
    props: ['System','SystemEntities','RAAppAdminDialog','RADB','RAApp','SystemGuides'],
    components: {InfoSheet,SystemGuideComponent},
    data() {
        return {
            raloginemail: '',
            raloginpassword: '',
            systemguideelmnt: {			
              HideGuidesCarousel: true,						
                RowColor: '#00ffff',									
                Name: 'System Guide',									
                ShowComponents: true,									
                Icon: 'mdi-card-text',									
                Title: 'About',									
                Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,		
                Elevation: 6,
                BoxTransparency: '',
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                FlexXLRG:6,									
                FlexLarge: 8,									
                FlexMedium: 10,									
                FlexSmall: 12,									
                FlexXSmall: 12,									
                Cols: 12,									
            },
          
        }
    },
    computed:{
      CurrentEntity(){
        let systemguides = JSON.parse(JSON.stringify(this.ComputedSystemGuides))
        let entity = {
          ClassifiedTabGuides: systemguides,
          WikiTabGuides: systemguides
        }
        return entity
      },
      ComputedTabs(){
            let Classifiedtabs = this.SystemEntities.filter(entity => {
              return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
            })
            console.log(Classifiedtabs)
            Classifiedtabs.map(tab => {
              tab.SystemGuides = this.CurrentEntity.ClassifiedTabGuides.map(guide => {
                if(guide.ConditionalDisabled && guide.ConditionalBooleans){
                  
                  guide.ConditionalBooleans.map(boolean => {
                    if(boolean.Value){
                      if(tab[boolean.Field]){
                        guide.Disabled = true
                      }
                    }
                  })
                  guide.Name = tab.DisplayName + '('+guide.Name+')'
                }
                return guide
              })
              return tab
            })
            let WikiTabs = this.SystemEntities.filter(entity => {
              return entity.Has_Wiki
            })
            WikiTabs.map(tab => {
              tab.SystemGuides = this.CurrentEntity.WikiTabGuides.map(guide => {
                if(guide.ConditionalDisabled && guide.ConditionalBooleans){                  
                  guide.ConditionalBooleans.map(boolean => {
                    if(boolean.Value){
                      if(entity[boolean.Field]){
                        guide.Disabled = true
                      }
                    }
                  })
                }
                guide.Name = tab.DisplayName + '('+guide.Name+')'
                return guide
              })
              return tab
            })
            return Classifiedtabs.concat(WikiTabs)
          },
        WindowWidth(){
        return window.innerWidth
        },
        WindowHeight(){
        return window.innerHeight
        },
        ComputedSystemGuides(){
            return this.SystemGuides.map(guide => {
                guide.elmnt = Object.assign({},this.systemguideelmnt)
                guide.elmnt.Description = guide.Description
                return guide
            })
        },
        userLoggedIn () {
            return this.$store.getters.user
            },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
    },
    

    

    created(){
    },
    methods:{
        SystemGuidesMatched(categoryname){
        return this.SystemGuides.filter(guide => {
          return guide.Category && guide.Category.Name === categoryname
        })
      },
        loginClientAppRAAdmin(){
      let vm = this
      const user = {
        email: this.raloginemail,
        password: this.raloginpassword
      }
      let payload = {
        user: user,
        SystemName: 'RapidApps',
        ClientFireStore: this.RAApp
      }
      
      this.$store.dispatch('signInClientAppAction', payload).then(response => {
        console.log(response)
        console.log(response.user)
        console.log(response.user.uid)
        if(response){
          vm.RADB.collection('users').doc(response.user.uid).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            userdata.id = user.uid
            console.log(userdata)
            // vm.$store.commit('setUserObj', userdata)
            alert('successfully signed in')
            vm.RAAppAdminDialog = false
          })
          
        }
         
      })

    
    },
    }
}
</script>

<style>
.iconpulse{
  animation: simplepulsing 1.0s infinite;
}
@keyframes simplepulsing {
	0% {
		transform: scale(0.95);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.95);
	}

}
</style>
