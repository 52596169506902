import { render, staticRenderFns } from "./RecordChatRoom.vue?vue&type=template&id=08b14e58&"
import script from "./RecordChatRoom.vue?vue&type=script&lang=js&"
export * from "./RecordChatRoom.vue?vue&type=script&lang=js&"
import style0 from "./RecordChatRoom.vue?vue&type=style&index=0&id=08b14e58&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports