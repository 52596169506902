
<template>
    
             <v-card class="transparent footermargin" flat>
              
               <div v-if="UserRecord">
              <div  v-if="SliderView">
                <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel cycle v-model="model" height="400"  v-if="RelatedArticles.length !== 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          style="background: linear-gradient(180deg, rgba(24,24,24,0.9) 0%, rgba(24,24,24,0.9) 100%);"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient+'font-size: 10px;padding-left:25px;' : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);font-size: 10px;padding-left:25px;'" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn  :to="ItemPath+'/Article/' + article[ItemProp]" color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list class="transparent">
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  <v-chip small v-if="article.PublishDate && article.PublishDate.toDate">
                  {{article.PublishDate.toDate().toDateString()}}</v-chip>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text style="font-size: 10px" class="white--text overline mx-3" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="ItemPath+'/Article/' + article[ItemProp]">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
              
               <div v-if="!UserRecord">
                  <div  v-if="SliderView">
                    <v-card flat v-if="RelatedArticles.length === 0">
                  <v-card-subtitle>
                    There are no Articles for this item at this stage
                  </v-card-subtitle>
                </v-card>
                  <v-carousel v-model="model" height="400" cycle  v-if="RelatedArticles.length > 0">
                      <v-carousel-item
                        v-for="article in RelatedArticles.slice(0,Slice)"
                        :key="article.id"
                      >
                      <v-img
                            :src="article.coverimage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="50%"
                          tile
                        >
                        
                   
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >
                          
                            <!-- <div class="display-3">
                              {{ article.title }}
                            </div> -->
                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-subtitle :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text largeoverline' : 'white--text articleoverline'" style="padding-left:25px;">
                                {{ article.title }}
                              </v-card-subtitle>
                              <!-- :class="$vuetify.breakpoint.lg ? 'white--text overline mx-3' : 'white--text mx-3'"  -->
                               <v-card-text :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient+'font-size: 10px;padding-left:25px;' : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);font-size: 10px;padding-left:25px;'" :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl ? 'white--text overline' : 'white--text articlecaption'" v-if="article.caption">
                                 {{article.caption.substr(0,150)}}
                               </v-card-text>
                          
                            <v-card-actions class="mx-3">
                              <v-btn :to="ItemPath+'/Article/' + article[ItemProp]"  color="links black--text" :x-large="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl" :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">Find out More <v-icon :small="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs">mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
               </div>
                  <div  v-if="!SliderView">
                    <v-list class="transparent">
     <v-list-item  v-for="article in RelatedArticles" :key="article.title">
          <v-layout class="justify-center">
              <v-card class="my-3 mx-3" hover width="90%" >
                  
                <v-img
                contain
                v-if="article.coverimage"
                  height="350px"
                  v-bind:src="article.coverimage"
                ></v-img>
                        <v-card-title class="headline">{{ article.title }}</v-card-title>
                <v-card-actions style="font-size:16px" class="mx-3 grey--text">
                  <v-chip small v-if="article.pubDate && article.pubDate.toDate">
                  {{article.pubDate.toDate().toDateString()}}</v-chip>
                </v-card-actions>
                <v-divider></v-divider>
                <v-card-text v-html="article.content.substr(0,Excerpt)">
                </v-card-text>
                <v-card-actions>
                  <v-chip small color="secondary" class="white--text">
                    {{article.author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-reddit</v-icon>
                  </v-btn>
                  <v-btn icon class="light-blue--text">
                    <v-icon small>mdi-twitter</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-facebook</v-icon>
                  </v-btn>
                  <v-btn icon class="red--text">
                    <v-icon small>mdi-google-plus</v-icon>
                  </v-btn>
                  <v-btn icon class="blue--text text--darken-4">
                    <v-icon small>mdi-linkedin</v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="accent" dark :to="ItemPath+'/Article/' + article[ItemProp]">Read More</v-btn>
                </v-card-actions>
                
              </v-card>
              </v-layout>
     </v-list-item>
        
        </v-list>
                  <!-- <v-card class="my-3"  :to="'/Article/' + article.title.split(' ').join('-')" v-for="article in RelatedArticles.slice(0,Slice)" :key="article.id">
                    <v-card-title>                
                    <span class="subheader">{{ article.title }}</span>
                    </v-card-title>
                    <v-img
                      max-height="100px"
                      v-bind:src="article.coverimage"
                    ></v-img>
                    <v-card-text style="font-size: 10px" v-html="article.content.substring(0,Excerpt)">
                    </v-card-text>
                    <v-card-actions>
               
                                  <v-chip x-small color="success" class="white--text">
                      {{article.PublishStatus}}
                    </v-chip>                
                    
                    
                  </v-card-actions>
                </v-card> -->
                  </div>
               </div>
             </v-card>
        
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
  props: ['System','UserRecord','Origin','clientDB','Filter','Excerpt','Slice','UserView','UserID','SliderView','TagFilter','TagsID'],
    components: {
        // FeedCard: () => import('@/components/FeedCard')
    },
    data () {
        return {

            grouparticles: [],
            groupid: this.$route.params.id,
            groupName: null,
            snackbar: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
            ],
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            //userid: this.$store.state.user.id           

        }
    },
    computed: {
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Site Articles') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.userLoggedIn.IsGuestUser && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial && this.NonGroupRoute
      },
      CollectionQuery(){
        let ref = this.CollectionRef
        if(this.CompanyOnlyFilter){
          ref = ref.where('Companyid','==',this.UserCompanyid)
        }
        return ref
      },
      ItemProp(){
        if(this.NonGroupRoute){
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
        else{
          return this.userLoggedIn ? 'id' : 'PublicURL'
        }
      },
      ItemPath(){
        if(this.NonGroupRoute){
          return this.userLoggedIn ? '' : ''
        }
        else{
          return this.userLoggedIn ? '/Group-Article/'+this.$route.params.id : '/Group-Article/'+this.$route.params.id
        }
      },
      CollectionRef(){
        if(this.NonGroupRoute){
         return this.userLoggedIn ? db.collection('sitearticles') : db.collection('sitearticleswiki') 
        }
        else{
          return db.collection('grouparticles').where('groupid','==',this.$route.params.id)
          //.where('GuestsIncluded','==',true)
        }
        
      },
      userLoggedIn () {
        return this.$store.getters.user
      },
        RelatedArticles () {
            return this.grouparticles.map(article => {
              if(article.PublishDate){
               article.pubdate = this.TimestampFormatterSTRING(article.PublishDate.toDate()) 
              }
              else if(article.pubDate){
                article.pubdate = this.TimestampFormatterSTRING(article.pubDate.toDate()) 
              }
            return article
          }).sort((a, b) => {
            // console.log('sorting again')
            // console.log(a.pubdate)
            var key1 = this.DateFormatter(b.pubdate);
            var key2 = this.DateFormatter(a.pubdate);

            if (key1 < key2) {
                return -1;
            } else if (key1 == key2) {
                return 0;
            } else {
                return 1;
            }
      })
            // .filter(article => {
            //     let articlegroup = this.groups.find(obj => obj.id === article.Groupid)
            //     article.category = articlegroup.GroupCategory
            //     console.log(article.grouptype)
            //     let articlematch = this.Filter.find(obj => obj.Name === article.grouptype)
            //     if(articlematch){
            //         return article
            //     }
            // })
        },

        ArticleExcerpt () {
          return this.RelatedArticles.filter(article => {
            return article.content === this.article.content
          })
        }
    },

    created() {
      if(this.UserView){
        if(this.UserID === this.UserRecord.id){
          //If I am moderator then I should call all my drafts as well
          if(this.userLoggedIn.IsGuestUser){
          let CollectionRef = this.CollectionQuery
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
          if(!this.ChannelRestricted){
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
          }
          }
          else{
            let CollectionRef = this.CollectionQuery
            if(!this.ChannelRestricted){
            this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
            }
            else{
              this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            }
          }
        }       
         else{          
          if(this.userLoggedIn.IsGuestUser){
          let CollectionRef = this.CollectionQuery
          this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Public)'))
            if(this.$route.name !== 'GroupPage'){
              if(!this.ChannelRestricted){
                this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))
              }              
            }
            else{
              this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','==','Published (Internal)'))
            }          
          }
          else{
            let CollectionRef = this.CollectionQuery
            this.getGroupArticles(CollectionRef.where('Creatorid','==',this.UserID).where('PublishStatus','in',['Published (Internal)','Published (Public)']))
          }
        }
      }
      else{
        if(this.UserRecord){
          if(this.TagFilter){          
          let CollectionRef = this.CollectionQuery.where('PublishStatus','in',['Published (Internal)','Published (Public)']).where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles(CollectionRef)
        }
        else{
          let CollectionRef = this.CollectionQuery
          if(this.userLoggedIn.IsGuestUser && this.$route.name !== 'GroupPage'){
            this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Public)'))
            console.log('this.ChannelRestricted'+this.ChannelRestricted,this.ActiveMemberGroup,CollectionRef)
            if(!this.ChannelRestricted){
            this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Internal)').where('GuestsIncluded','==',true))     
            }   
          }
          else{
            if(!this.ChannelRestricted){
            this.getGroupArticles(CollectionRef.where('PublishStatus','in',['Published (Internal)','Published (Public)']))
            }
            else{
              this.getGroupArticles(CollectionRef.where('PublishStatus','==','Published (Public)'))
            }
          }
        }
          
        }
        else{
          if(this.TagFilter){
          
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)').where('DataTagsid','array-contains',this.TagsID)
          this.getGroupArticles(CollectionRef)
        }
        else{
          //console.log('calling public')
          let CollectionRef = this.CollectionQuery.where('PublishStatus','==','Published (Public)')
        this.getGroupArticles(CollectionRef)
        }          
        }        
      }
        //console.log(this.UserID)
},
    methods: {
      TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        // console.log(finaldate)
        return finaldate
      },
        DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split(' ')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        // console.log(new Date(yearnumber, monthnumber-1, daynumber))
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
},
         getGroupArticles(CollectionRef) {     
           //console.log('this.CollectionQuery',this.CollectionQuery)
           CollectionRef.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.grouparticles.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                })
                })
           

    },
     
    Opendialog() {
        this.dialog = true
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    var storageRef = firebase.storage().ref('Group_Article_Photos/' + this.selectedFile.name);
                    var uploadTask = storageRef.put(this.selectedFile);
                      uploadTask
                      .then(snapshot => snapshot.ref.getDownloadURL())
                        .then((url) => {     
                    this.name = this.editedItem.Name
                    this.content = this.editedItem.content
                    this.author = this.$store.state.user.id
                    this.image = url

                        
                        const article = { 
                              Name: this.name,
                                Content: this.content,
                                Author: this.author,
                                Image: this.image
                                
                            }  
                        db.collection('sitearticles').add(article).then(() => {
                            })
                    this.Closedialog()
                          }),                         
                        
                   
                            this.quotedialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>