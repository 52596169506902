<template>
  <!-- <div > -->
      <v-card :elevation="elmnt.Elevation" :class="elmnt.BoxTransparency" height="100%">
        <v-img
      style="width: 100%;height: auto;float: right;" :src="elmnt.IMG"
    ></v-img>
    <!-- <img style="width: 100%;height: auto;float: right;" :src="elmnt.IMG"> -->
   <!-- combining h2 style="align-self: start;float: right;" with span float right. reduce width of par makes float of par wrapping around h2 tag -->
   <v-card-title :style="'align-self: start;text-align: right;'+HeaderFontFamily(tab,tab.HeaderFontFamily)">{{elmnt.Title}}
    
   </v-card-title>
   <v-card-text  style="text-align: right;">
    <span :style="ParStyle">
    <div v-html="elmnt.Description"/>
     <!-- <HTMLDescription
            :tab="elmnt" :PageContentFont="PageContentFont"
            /> -->
   </span>
   </v-card-text>
    <v-card-actions v-if="elmnt.HasActionButton" :class="elmnt.ButtonAlignment">
                            <ActionButtonEmbed :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                :PageHeadersFontSize="PageHeadersFontSize" :IsElement="true" :tabindex="tabindex" :rowindex="rowindex"									
                                :tab="elmnt" :i="elmntindex" :PageID="PageID" :PageName="PageName"								
                                />
                        </v-card-actions>
      </v-card>
  <!-- </div> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','tab','tabindex','elmnt','elmntindex','ElmntNumber',"tab",
    "PageHeadersFont",
    "PageContentFont",
    "PageHeadersFontSize",
    "tabindex",],
    components: {},
    data() {
        return {

        }
    },	
    computed:{
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      GridNrStyle(){
        if(this.elmnt.MagType === 'Type 1'){
            return 'align-self: end;font-size: 480px;margin-top:50px;color: rgba(104,23,186,0.5);position: absolute;'
          }
          if(this.elmnt.MagType === 'Type 2'){
            return 'font-size: 200px;color: #eee;position: absolute;'
          }
          if(this.elmnt.MagType === 'Type 3'){
            return 'grid-row: 6 / 7;grid-column: 3 / 5;border-radius: 130px;background: black;color: white;font-size: 100px;height: 130px;line-height: 130px;width: 130px;text-align: center;position: absolute;'
          }
          if(this.elmnt.MagType === 'Type 4'){
            return 'font-size: 320px;color: rgba(104,23,186,0.5);position: absolute;'
          }
      },
      ParStyle(){
        if(this.elmnt.MagType === 'Type 1'){
            return this.MiniView ? 'width: 60%;float: right;' : 'width: 40%;float: right;'
          }
          if(this.elmnt.MagType === 'Type 2'){
            return 'width: 70%;float: right;'
          }
          if(this.elmnt.MagType === 'Type 3'){
            return 'width: 100%;float: right;'
          }
          if(this.elmnt.MagType === 'Type 4'){
            return this.MiniView ? 'width: 80%;float: right;' : 'width: 60%;float: right;'
          }
      },
      NumberStyle(){
        if(this.elmnt.MagType === 'Type 1'){
            return 'position: absolute;font-size: 480px;color: rgba(104,23,186,0.5);'
          }
          if(this.elmnt.MagType === 'Type 2'){
            return 'position: absolute;font-size: 200px;line-height: 160px;color: #eee;'
          }
          if(this.elmnt.MagType === 'Type 3'){
            return 'position: absolute;border-radius: 130px;background: black;color: white;font-size: 100px;height: 130px;line-height: 130px;width: 130px;text-align: center;'
          }
          if(this.elmnt.MagType === 'Type 41'){
            return 'position: absolute;font-size: 320px;color: rgba(104,23,186,0.5);margin-top: 80px;'
          }
      },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },
    }
}
</script>

<style>

</style>



