
<template>
  <v-dialog v-model="StopwatchDialog" :fullscreen="MobileView"  :max-width="!MobileView ? '400px' :''">
  <v-card width="100%" dark>
    <!-- <link href='http://fonts.googleapis.com/css?family=Source+Sans+Pro' rel='stylesheet' type='text/css'> -->
    <v-card-title class="white--text BoldBuilderGradient justify-space-between">Timesheet - {{TimeSheetName}}
      <v-icon small class="soloactionicon" @click="ToggleStopWatchDialog()" large color="white">mdi-close</v-icon>
    </v-card-title>
    <v-card-title class="justify-center display-3 font-weight-bold mb-3" v-html="formatTime(lapsedMilliseconds)"></v-card-title>
    
    <v-card-text>
     
        <v-layout row>
          <v-select v-model="TimeSheetName" :items="ComputedSystemEntities" label="Table" item-text="SingleName"></v-select>
          <v-select v-if="RelatedTable" @change="storeInstance" v-model="SelectedRelatedObj" return-object :items="RelatedRecords" :label="RelatedTablePrimaryFieldDisplay" :item-text="RelatedTablePrimaryFieldName"></v-select>
          
        </v-layout>
        <!-- <v-layout row class="justify-center">
      <h1 class="display-3 font-weight-bold mb-3" v-html="formatTime(lapsedMilliseconds)"></h1>
      </v-layout> -->
       <v-layout row class="justify-space-between">
        <v-btn :disabled="state === 'reset'"
        outlined class="subtleoverline" color="grey" style="background-color: rgb(148,148,148,0.3);" fab v-if="state === 'started' || state === 'reset'" @click="saveInterval">Lap</v-btn>
        <v-btn outlined class="subtleoverline" color="blue" style="background-color: rgb(0,0,255,0.3);" fab v-if="state === 'paused'" @click="reset">Reset</v-btn>
      <v-btn outlined class="subtleoverline" color="success" style="background-color: rgb(0,255,0,0.3);" fab v-if="state === 'paused' && SelectedRelatedObj" @click="resume">Start</v-btn>
      <v-btn outlined class="subtleoverline" color="success" style="background-color: rgb(0,255,0,0.3);" fab v-if="state === 'reset' && SelectedRelatedObj" @click="start">Start</v-btn>
        <v-btn outlined class="subtleoverline" color="error" style="background-color: rgb(255,0,0,0.3);" fab v-if="state === 'started'" @click="pause">Stop</v-btn>
        
       </v-layout>
    <v-layout class="justify-center">  
      <v-card flat height="20px">
      </v-card>
    </v-layout>
    </v-card-text>
    <v-card-text v-if="stopwatchIntervals.length > 0" style="padding: 0px;">
      <v-card-subtitle>
          Intervals
        </v-card-subtitle>
         <v-divider>
        </v-divider>
        <v-virtual-scroll
            style="padding:4px;"
                class="transparent"
                  :items="stopwatchIntervals"
                  :item-height="150"
                  :height="180"
                >
                <template v-slot:default="{ item,index }">
                  <v-list dense>
            <v-list-item dense class="settingsfont justify-space-between">
              <v-list-item-content>
                <v-list-item-title>
                  Lap {{item.id}}
                </v-list-item-title>
                <v-list-item-subtitle v-if="index-1+2 === stopwatchIntervals.length">
                  {{formatTime(lastlapLapsedTime)}}
                </v-list-item-subtitle>
                <v-list-item-subtitle v-if="index-1+2 !== stopwatchIntervals.length">
                  {{ formatTime(item.interval.INT) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-content>
                <ContentEditableField style="padding:15px;"
                       :FieldObject="item" :FieldName="'note'"
                       :FieldValue="item.note" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
              </v-list-item-content> -->
              <v-list-item-action>
                 <v-icon small @click="removeInterval(item)">mdi-delete-forever</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-list-item class="justify-center">
              <ContentEditableField style="padding:15px;"
                       :FieldObject="item" :FieldName="'note'"
                       :FieldValue="item.note" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-list-item>           
          </v-list>
           <v-divider>
        </v-divider>
                </template>
        </v-virtual-scroll>
    </v-card-text>
    <v-card-actions class="justify-center">
       <v-btn outlined class="subtleoverline" style="background-color: rgb(255,255,255,0.3);" v-if="stopwatchIntervals.length > 0 && state !== 'started'" @click="csvExport(stopwatchIntervals)">
          <v-icon small>mdi-download</v-icon> CSV
        </v-btn>
        <v-btn outlined class="subtleoverline" color="success" style="background-color: rgb(0,255,0,0.3);" v-if="stopwatchIntervals.length > 0 && state !== 'started'" @click="SubmitJob(stopwatchIntervals)">
          Submit
        </v-btn>
    </v-card-actions>
      <!-- Settings Dialog -->
      <v-dialog v-model="settingsDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Stopwatch Settings</span>
            <v-spacer></v-spacer>
            <v-btn @click="settingsDialog = false" text icon><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-select
                          :items="[',', ';', ':', '.']"
                          label="Milliseconds Separator"
                          v-model="millisecondsSeparator"
                          required
                  ></v-select>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-select
                          :items="[0, 1, 2, 3]"
                          label="Milliseconds Digit amount"
                          v-model="millisecondsDigitAmount"
                          required
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-text-field label="CSV-Export File Name" v-model="csvExportFileName" required></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <h3>CSV-Export Column Order / Select Columns</h3>
                  <div class="text-xs-center">
                    <span v-for="(column, index) in csvExportColumnOrder" :key="column.column">
                      <v-chip :color="column.render === true ? 'green' : 'lightgrey'" @click="column.render = !column.render">{{ column.heading }}</v-chip>
                      <v-btn v-if="index < csvExportColumnOrder.length - 1" @click="swapArray(csvExportColumnOrder, index, index + 1)" icon><v-icon>swap_horiz</v-icon></v-btn>
                    </span>
                  </div>
                  <small>Change the order and select which columns should be exported to the CSV-file (click on column-name to enable/ disable).</small>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                        v-model="autoSaveState"
                        label="Autosave when Stopwatch is running"
                        class="mb-0"
                  ></v-switch>
                  <small>Save started time and intervals in the local storage (stopwatch can be restored, when the browser unexpectedly closed)</small>
                </v-flex>
                <h3 class="mt-4">Update interval of the displayed Stopwatch</h3>
                <v-flex xs9>
                  <v-slider v-model="intervalUpdateTime" :min="1" :max="100"></v-slider>
                </v-flex>
                <v-flex xs3>
                  <v-text-field v-model="intervalUpdateTime" type="number" :min="1" :max="100"></v-text-field>
                </v-flex>
                <small>
                  That only affects the refresh-time of the displayed time.
                  It does <strong>not</strong> affect the accuracy of the stopwatch itself.
                </small>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="error" text @click="deleteStoredSettings">Delete stored settings</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="saveSettings">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Help-Dialog -->
      <v-dialog v-model="helpDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Stopwatch Help</span>
            <v-spacer></v-spacer>
            <v-btn @click="helpDialog = false" icon><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <h3>Keyboard Shortcuts</h3>
                  Start / Stop / Resume: <v-chip>s</v-chip><br />
                  Add interval: <v-chip>i</v-chip> or <v-chip>space</v-chip><br />
                  Reset: <v-chip>ctrl + r</v-chip>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- Settings deleted snackbar -->
      <v-snackbar v-model="settingsDeletedSnackbar" bottom :timeout="20000">
        Settings deleted! Default settings will be applied on page-reload...
        <v-btn color="blue" text @click="settingsDeletedSnackbar = false">
          Close
        </v-btn>
      </v-snackbar>

      <!-- Stored Instance Detected Dialog -->
      <v-dialog v-model="storedInstanceDetectedModal" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Stored instance detected!</v-card-title>
          <v-card-text>There is a running instance of the stopwatch saved in your browser's local storage.<br/>Should that be restored?</v-card-text>
          <v-card-actions>
            <v-btn color="red darken-1" text @click="discardStoredInstance">No</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="restoreStoredInstance">Yes!</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    
  </v-card>
  </v-dialog>
</template>

<script>
import ContentEditableField from '@/components/Database/ContentEditableField'
import firebase from 'firebase';
import db from '@/main'
  const CSV_SEPARATOR = ';';
  export default {
    props: ['System','SystemEntities','SystemScope','PushedRelatedObj'],
    components: {ContentEditableField},
    data: () => ({
      RelatedRecords: [],
      // general stopwatch stuff
      SelectedRelatedObj: '',
      TimeSheetName: '',
      state: 'reset',
      startTime: Date.now(),
      currentTime: Date.now(),
      interval: null,
      intervalUpdateTime: 10,
      pauses: [],
      pausedTime: 0,
      // stopwatch interval
      stopwatchIntervals: [],
      intervalKeyCounter: 1,
      stopwatchIntervalHeaders: [
        { text: '#', value: 'id' },
        { text: 'Time since start', value: 'interval' },
        { text: 'Note', value: 'note' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      // settings
      millisecondsDigitAmount: 1,
      millisecondsSeparator: ':',
      csvExportFileName: 'export',
      csvExportColumnOrder: [
        { column: 'id', heading: 'Interval ID', render: true },
        { column: 'interval', heading: 'Interval', render: true },
        { column: 'interval', heading: 'TimeStamp', render: true },
        { column: 'note', heading: 'Note', render: true },
      ],
      autoSaveState: true,
      // dialog and modal
      settingsDialog: false,
      helpDialog: false,
      storedInstanceDetectedModal: false,
      settingsDeletedSnackbar: false
    }),
    watch: {
      PushedRelatedObj(value){
            if(value){
              this.PrepareRelated(value)
            }
      },
      TimeSheetName(value){
            if(value){
              this.storeInstance()
              this.CallEntityRecords()
            }
      },
    },
    created(){
      let currjob = this.userLoggedIn.CurrentJob
      if(currjob){
        //okay the issue is, you msut understand we cannot rakte the pushed relatedobj, because it may be ffrom a complete diff record that we view timesheets, and BTW test that
        this.PrepreDBStoredJob(currjob)
      }
      
      //console.log('now had created',this.PushedRelatedObj)
      
      else{        
        if(this.PushedRelatedObj){
          this.PrepareRelated(this.PushedRelatedObj)
        }
      this.checkForInstance();
      }
    },
    mounted() {
      this.loadSettings();
      //this.checkForInstance();
    },
    destroyed() {
      this.stopUpdateInterval();
    },
    computed: {
      StopwatchDialog(){
        return this.$store.state.StopwatchDialog
      },
      MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ComputedSystemEntities(){
          return this.SystemEntities.filter(entity => {
            return this.$store.state[entity.id.split('_').join('')+'Query']
          })
        },
        RelatedObj(){
            return this.SelectedRelatedObj ? {
                id: this.SelectedRelatedObj.id,
                [this.RelatedTablePrimaryFieldName]: this.SelectedRelatedObj[this.RelatedTablePrimaryFieldName],
                Link: this.RelatedTable.SingleName.split(' ').join(''),
                identifier: this.RelatedTablePrimaryFieldName,
                collection: this.RelatedTable.id.split('_').join('').toLowerCase()
            } : {}
        },
        RelatedTable(){
            return this.SystemEntities.find(obj => obj.SingleName === this.TimeSheetName)
        },
        
        RelatedTablePrimaryFieldDisplay(){
            return this.RelatedTable.RecordPrimaryField.DisplayName
        },
        RelatedTablePrimaryFieldName(){
            return this.RelatedTable.RecordPrimaryField.Name
        },
        userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },
      lapsedMilliseconds() {
        return this.currentTime - this.startTime - this.pausedTime;
      },
      lastlap(){
        return this.stopwatchIntervals[this.stopwatchIntervals.length-1]
      },
      lastlapLapsedTime() {
        if(this.lastlap){
          let lastintststart = this.lastlap.interval.TS
          if(lastintststart.toDate){
            lastintststart = lastintststart.toDate()
            }
          return this.lastlap ? this.currentTime - lastintststart - this.pausedTime : 0
        }
        else{
          return 0
        }
      },
    },
    methods: {
      ToggleStopWatchDialog(){
        this.$store.commit('SetStopwatchDialog',!this.StopwatchDialog)
      },
      IntervalLapsedTime(item){
        return this.formatTime(this.lapsedMilliseconds)
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
      PrepreDBStoredJob(currjob){        
        this.PrepareRelated(currjob.RelatedObj)
        let start = currjob.Created_On.toDate()
        this.TimeSheetName = currjob.Name
        //console.log(this.RelatedTable,currjob)
        // /this.currentTime - this.startTime - this.pausedTime
        this.currentTime = Date.now()
        this.startTime = start.getTime()        
        this.stopwatchIntervals = currjob.Intervals
        this.intervalKeyCounter = currjob.Intervals.length-1+2
        
        //bad boy, we don't store puases on db...
        this.pauses = []
        this.pausedTime = 0
        //again because we don't store puases or state on currjob we must assuem the default
        this.state = 'started'
        if (localStorage.getItem('instance-startTime') !== null) {
          this.startUpdateInterval()
        }
        else{
          this.storeInstance(true)
         this.startUpdateInterval()
        }
      },
      PrepareRelated(value){
        //okay so basically you coudl initiate a stopwatch
        console.log(value,this.ComputedSystemEntities)
        let entity = this.ComputedSystemEntities.find(obj => obj.id.split('_').join('').toLowerCase() === value.collection)
        this.TimeSheetName = entity.SingleName
        this.SelectedRelatedObj = {
          id: value.id,
          [value.identifier]: value[value.identifier]
        }
        //console.log(this.SelectedRelatedObj)
        // console.log(this.TimeSheetName,this.SelectedRelatedObj)
      },
    CallEntityRecords(){
      let vm = this
      let storearrayname = this.RelatedTable.DisplayName.split(' ').join('')+'Array'
      let array = this.$store.state[storearrayname]
      let storequery = this.$store.state[this.RelatedTable.DisplayName.split(' ').join('')+'Query']
      if(array && array.length === 0 || array && storequery){
        let payload = {                
        query: storequery,
        arraymutation: 'set'+storearrayname,
        getter: 'get'+storearrayname,
        arrayname: storearrayname
        }
        this.$store.dispatch('GetCollectionArray',payload).then(function(result) {  
          vm.RelatedRecords = vm.$store.state[storearrayname]
        })
      }
      else{
        this.RelatedRecords = this.$store.state[storearrayname]
      }
    },
    StoreUserJobStart(){
        let NewTimeSheet = {
            Intervals: this.stopwatchIntervals,
            Name: this.TimeSheetName,
            User: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname},
            RelatedObj: this.RelatedObj,
            RelatedObjid: this.RelatedObj.id,
            Created_On: new Date(),
            Created_By: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname},
            RelatedTable: this.RelatedTable.id,
            Userid: this.userLoggedIn.id
        }
        db.collection('users').doc(this.userLoggedIn.id).update({
            CurrentJob: NewTimeSheet
        })
    },
      DateFormatter(date){
      if(date){
        let NoZoneDate = new Date()
        let NoZoneDateString = NoZoneDate.toString()
        let TimeZone = NoZoneDateString.split('GMT')[1]
        let TZoperator = TimeZone.substr(0,1)
        console.log('TZoperator',TZoperator)
        let TZnumberstring = TimeZone.substr(1,2)
        let TZnumber = Number(TZnumberstring)
        console.log('TZnumber',TZnumber)
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2].split('T')[0]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        let timestring = date.split('T')[1]
        
        let hoursstring = timestring.split(':')[0]       
        let hours = Number(hoursstring)
        let minutesstring = timestring.split(':')[1]
        let minutes = Number(minutesstring)
        let secondsstring = timestring.split(':')[2].split('.')[0]
        let seconds = Number(secondsstring)
        let millisecondsstring = timestring.split(':')[2].split('.')[1].substr(0,3)
        let milliseconds = Number(millisecondsstring)
        if(TZoperator === '+'){
          hours = hours+TZnumber
        }
        else{
          hours = hours-TZnumber
        }
        return new Date(yearnumber, monthnumber-1, daynumber, hours, minutes, seconds, milliseconds)
        }
      else{
        return null
      }
    },
        SubmitJob(stopwatchIntervals){
            console.log('stopwatchIntervals',stopwatchIntervals)
            console.log('this.userLoggedIn',this.userLoggedIn)
            console.log('this.RelatedObj',this.RelatedObj)
            let JSDatedIntervals = Object.assign(stopwatchIntervals)
            JSDatedIntervals.map(int => {
              console.log('check for string',int)
              if(typeof int.interval.TS === 'string'){
                console.log('this one is a string',int.interval.TS)
                int.interval.TS = this.DateFormatter(int.interval.TS)
                console.log('but no more, it is now ', int.interval.TS)
              }
            })
            let NewTimeSheet = {
                Intervals: JSDatedIntervals,
                Name: this.TimeSheetName,
                User: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname},
                RelatedObj: this.RelatedObj,
                RelatedObjid: this.RelatedObj.id,
                Created_On: this.userLoggedIn.CurrentJob.Created_On,
                End_Date: new Date(),
                Created_By: {id: this.userLoggedIn.id, Full_Name: this.userLoggedIn.Full_Name, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname},
                RelatedTable: this.RelatedTable.id,
                Userid: this.userLoggedIn.id
            }
            console.log('NewTimeSheet',NewTimeSheet)
            db.collection('timesheets').add(NewTimeSheet)
            
            this.deleteStoredSettings()
            this.discardStoredInstance()
            this.reset()
            this.CancelStopWatch()
        },

      CancelStopWatch(){
        this.$emit('CancelStopWatch')
      },

      formatTime(lapsed) {
        if (lapsed === undefined || lapsed === null) {
          lapsed = this.lapsedMilliseconds;
        }
        let time = this.formatHours(lapsed) +
                ':' + this.formatMinutes(lapsed) +
                ':' + this.formatSeconds(lapsed);
        if (this.millisecondsDigitAmount > 0) {
          time += this.millisecondsSeparator +
                  this.formatMilliseconds(lapsed).substring(0, this.millisecondsDigitAmount);
        }
        return time;
      },
      formatHours(lapsed) {
        const hrs = Math.floor((lapsed / 1000 / 60 / 60));
        return hrs >= 10 ? hrs : '0' + hrs;
      },
      formatMinutes(lapsed) {
        const min = Math.floor((lapsed / 1000 / 60) % 60);
        return min >= 10 ? min : '0' + min;
      },
      formatSeconds(lapsed) {
        const sec = Math.floor((lapsed / 1000) % 60);
        return sec >= 10 ? sec : '0' + sec;
      },
      formatMilliseconds(lapsed) {
        const mil = Math.ceil(lapsed % 1000);
        if (mil < 10) {
          return '00' + mil;
        } else if (mil < 100) {
          return '0' + mil;
        } else {
          return '' + mil;
        }
      },
      start() {
        this.startTime = Date.now();
        this.state = 'started';
        this.saveInterval()
        this.startUpdateInterval();
        this.storeInstance();
        //actually when you start you need to start a new "lap" and run the timer on it. that's it, simple as it gets
      },
      reset: function() {
        this.stopUpdateInterval();
        this.state = "reset";
        this.startTime = Date.now();
        this.currentTime = Date.now();
        this.stopwatchIntervals = [];
        this.intervalKeyCounter = 1;
        this.pauses = [];
        this.pausedTime = 0;
        this.discardStoredInstance();
        db.collection('users').doc(this.userLoggedIn.id).update({
                CurrentJob: ''
            })
      },
      pause: function() {
        this.stopUpdateInterval();
        this.state = "paused";
        this.pauses.push({
          pauseStart: Date.now(),
          pauseEnd: null,
          pauseLength: null
        });
        this.storeInstance();
      },
      resume: function() {
        const end = Date.now();
        const start = this.pauses[this.pauses.length - 1].pauseStart;
        const length = end - start;
        this.pausedTime += length;
        this.pauses[this.pauses.length - 1].pauseEnd = end;
        this.pauses[this.pauses.length - 1].pauseLength = length;
        this.state = "started";
        this.startUpdateInterval();
        this.storeInstance();
      },
      updateCurrentTime() {
        this.currentTime = Date.now();
      },
      /** Start updating of current time */
      startUpdateInterval() {
        this.interval = setInterval(this.updateCurrentTime, this.intervalUpdateTime);
      },
      /** Stop updating of current time */
      stopUpdateInterval() {
        clearInterval(this.interval);
      },
      /** Save new interval (with current time) */
      saveInterval() {
        let intervallength = this.stopwatchIntervals.length
        let lastintervalindex = intervallength-1
        let interval = 0
        if(lastintervalindex === 0){
          this.stopwatchIntervals[lastintervalindex].interval.INT = this.lapsedMilliseconds
        }
        else if(lastintervalindex > 0){
          let intervalintegers = this.stopwatchIntervals.map(int => { return int.interval.INT}).flat()
          let subtotalint = intervalintegers.reduce((a,b) => a + b ,0)
          this.stopwatchIntervals[lastintervalindex].interval.INT = this.lapsedMilliseconds-subtotalint
        } 

        this.stopwatchIntervals.push({
          id: this.intervalKeyCounter,
          interval: {INT: interval, TS: new Date()},
          note: ''
        });
        this.intervalKeyCounter++;
        this.storeInstance();        
        this.StoreUserJobStart()
      },
      /** Remove specific interval from intervals */
      removeInterval(element) {
        const index = this.stopwatchIntervals.indexOf(element);
        this.stopwatchIntervals.splice(index, 1);
        this.storeInstance();
      },
      /**
       * Export current state (intervals) to a csv-file and download the file.
       */
      csvExport() {
        let csvContent = "data:text/csv;charset=utf-8,";
        // Headers
        let headers = [];
        for (const [key, column] of Object.entries(this.csvExportColumnOrder)) {
          if (column.render) {
            headers.push(column.heading);
          }
        }
        const headersRow = headers.join(CSV_SEPARATOR);
        csvContent += headersRow + '\n';
        // Body / Entries
        for (const [key, value] of Object.entries(this.stopwatchIntervals)) {
          let cells = [];
          for (const [key, column] of Object.entries(this.csvExportColumnOrder)) {
            if (column.render) {
              // Special case to catch the interval that has to be rendered
              // todo add renderer for that -> outsource from this for
              if (column.column === 'interval' && column.heading === 'Interval') {
                cells.push(this.formatTime(value[column.column].INT));
              }
              else if (column.column === 'interval' && column.heading === 'TimeStamp') {
                cells.push(value[column.column].TS);
              }
               else {
                cells.push(value[column.column]);
              }
            }
          }
          csvContent += cells.join(CSV_SEPARATOR) + '\n';
        }
        // CSV-"Security"
        csvContent.replace(/(^\[)|(\]$)/gm, "");
        // Creating file and download
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", this.csvExportFileName + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      openHelpDialog() {
        this.helpDialog = true;
      },
      openSettingsDialog() {
        this.settingsDialog = true;
      },
      /** Save current settings-state to the local storage */
      saveSettings() {
        localStorage.setItem('settings', JSON.stringify({
          millisecondsDigitAmount: this.millisecondsDigitAmount,
          csvExportFileName: this.csvExportFileName,
          millisecondsSeparator: this.millisecondsSeparator,
          autoSaveState: this.autoSaveState,
          csvExportColumnOrder: this.csvExportColumnOrder,
          intervalUpdateTime: this.intervalUpdateTime
        }));
        this.settingsDialog = false;
      },
      /** Load current settings state from the local storage */
      loadSettings() {
        // Check, if settings have been saved in Local Storage
        let settings = localStorage.getItem('settings');
        if (settings === null || settings === undefined) {
          return;
        }
        settings = JSON.parse(settings);
        this.csvExportFileName = settings.csvExportFileName;
        this.millisecondsDigitAmount = settings.millisecondsDigitAmount;
        this.millisecondsSeparator = settings.millisecondsSeparator !== undefined ? settings.millisecondsSeparator : this.millisecondsSeparator;
        this.autoSaveState = settings.autoSaveState !== undefined ? settings.autoSaveState : this.autoSaveState;
        this.csvExportColumnOrder = settings.csvExportColumnOrder !== undefined ? settings.csvExportColumnOrder : this.csvExportColumnOrder;
        this.intervalUpdateTime = settings.intervalUpdateTime !== undefined ? settings.intervalUpdateTime : this.intervalUpdateTime;
      },
      deleteStoredSettings() {
        localStorage.removeItem('settings');
        this.settingsDialog = false;
        this.settingsDeletedSnackbar = true;
      },
      /**
       * Store the current state of the instance.
       */
      storeInstance(forreinstate) {
        if (!this.autoSaveState) {
          this.discardStoredInstance();
          return;
        }
        localStorage.setItem('jobname',this.TimeSheetName)
        localStorage.setItem('instance-intervals', JSON.stringify(this.stopwatchIntervals));
        localStorage.setItem('instance-intervalKeyCounter', this.intervalKeyCounter);
        localStorage.setItem('instance-startTime', this.startTime);
        localStorage.setItem('instance-pauses', JSON.stringify(this.pauses));
        localStorage.setItem('instance-pausedTime', this.pausedTime);
        localStorage.setItem('instance-state', this.state);
        if(forreinstate){
          this.restoreStoredInstance()
        }
      },
      /**
       * Check whether an instance had been started but not reset manually, that is usually the
       * case when the browser/ system crashes or the user accidentally closed the browser (tab).
       */
      checkForInstance() {
        console.log('I am checking for instnace')
        if (localStorage.getItem('instance-startTime') !== null) {
          setTimeout(() => {
            this.storedInstanceDetectedModal = true;
          }, 500);
          
        }
      },
      /**
       * Restore the stored instance and start the stopwatch with the old parameters again.
       */
      restoreStoredInstance() {
        const job = localStorage.getItem('jobname',this.TimeSheetName);
        const instance = localStorage.getItem('instance-startTime');
        const intervals = localStorage.getItem('instance-intervals');
        const intervalKeyCounter = localStorage.getItem('instance-intervalKeyCounter');
        const pauses = localStorage.getItem('instance-pauses');
        const pausedTime = localStorage.getItem('instance-pausedTime');
        const state = localStorage.getItem('instance-state');
        if (instance !== null) {
          this.startTime = instance;
          this.pausedTime = parseInt(pausedTime);
          if (intervals !== null && intervals !== undefined) {
            this.stopwatchIntervals = JSON.parse(intervals);
            this.intervalKeyCounter = intervalKeyCounter;
          }
          if (pauses !== null && pauses !== undefined) {
            this.pauses = JSON.parse(pauses);
          }
          this.state = state;
          if (this.state === 'started') {
            this.startUpdateInterval();
          } else if (this.state === 'paused') {
            this.currentTime = this.pauses[this.pauses.length - 1].pauseStart;
          }
          if(job !== null){
            this.TimeSheetName = job
          }
        }
        this.SelectedRelatedObj = this.RelatedRecords.find(obj => obj.id === this.userLoggedIn.CurrentJob.RelatedObjid)
        this.storedInstanceDetectedModal = false;
      },
      /**
       * Delete the stored instance.
       */
      discardStoredInstance() {
        localStorage.removeItem('instance-jobname');
        localStorage.removeItem('instance-startTime');
        localStorage.removeItem('instance-intervals');
        localStorage.removeItem('instance-intervalKeyCounter');
        localStorage.removeItem('instance-pausedTime');
        localStorage.removeItem('instance-pauses');
        localStorage.removeItem('instance-state');
        this.storedInstanceDetectedModal = false;
      },
      /**
       * Swap two elements in an array.
       * (Helper function)
       */
      swapArray(array, l, m) {
        let a = array[l];
        let b = array[m];
        // Use Vue.set() to prevent re-loading/ binding problems in v-for
        this.$set(array, l, b);
        this.$set(array, m, a);
      }
    }
  }
</script>

<style>

</style>