        <template>
  <v-card flat tile class="transparent" width="100%" height="100%">
        <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        </v-card-title>
        <!-- ShowLoader {{ShowLoader}} -->
      <DashboardBuilder v-if="ActiveRecord" :PushedElmnt="ActiveRecord" :SystemEntities="SystemEntities"/>
        <v-row v-if="ShowLoader"
      align="center"
      justify="center"
    > 
    <v-card height="100%" width ="100%" class="center" color="rgb(240, 240, 240, 0.95)">
              <v-container bg fill-height grid-list-md text-xs-center>
              <v-layout row wrap align-center class="justify-center">
              <v-card-text class="justify-center">
                <v-layout class="justify-center">
                   <v-progress-circular size="225"
                      :width="15"
                      color="purple"
                      indeterminate
                    >Checking for Goals</v-progress-circular>
                  </v-layout>
              </v-card-text>
              </v-layout>
              </v-container>
        </v-card>
     
    </v-row>
    <v-card class="transparent" tile flat v-if="MyGoalsisEmpty">
        <v-card-text>
            You do not have any Goals at the moment.
        </v-card-text>
        <v-card-actions v-if="userIsAdmin">
            <v-btn :to="'/Goals'">Create Goals</v-btn>
        </v-card-actions>
    </v-card>
      <v-list :dark="AppisDarkMode" v-if="!ActiveRecord && !ShowLoader">
          <v-list-item v-for="goal in GoalsComputed" :key="goal.itemObjKey"  @click="PushItem(goal)">
            <v-list-item-avatar>
                    <v-icon small>mdi-vote</v-icon>
                </v-list-item-avatar>
            <v-list-item-content>
             <v-list-item-title>
                 {{goal.Name}} - {{goal.BreakdownName}}
                 </v-list-item-title>                    
                <v-list-item-subtitle>
                <v-progress-linear :color="goal.Color" :value="goal.Value"></v-progress-linear>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{goal.Value}}% Achieved
                </v-list-item-subtitle>  
            </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','MenuItem','MyGoals','MyGoalsisEmpty','SystemEntities'],
    components: {
        DashboardBuilder
    },
    data(){
        return{
            LoadingItems: true,
            TimelineFilterOptions: [

        {
          ID: 1000001,
          FilterName: 'This Week (Per Day)',
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',
          GiveName: 'Per Day',
          Name: 'This Week',
          StartDateVariable: 'ThisMonday',
          EndDateVariable: 'ThisSunday',
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],
          ChartlabelPeriodEndMethodX: 'EndofDay',
          ChartlabelPeriodEndMethodY: 'EndofDay',
        },
        {
          ID: 1000002,
          FilterName: 'This Month (Per Week)',
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',
          GiveName: 'Per Week',
          Name: 'This Month',
          StartDateVariable: 'CurrentMonthStart',
          EndDateVariable: 'CurrentMonthEnd',
          ChartLabelTrigger: 'CurrentMonthName',
          ChartLabelsisDynamic: true,
          DynamicChartLabelsProp: 'CurrentMonthLabels',         
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofWeek',
        },
        {
          ID: 1000003,
          FilterName: 'This Year (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month',
          Name: 'This Year',
          StartDateVariable: 'ThisJanuaryStart',
          EndDateVariable: 'ThisDecemberEnd',
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofMonth',
        },
        {
          ID: 1000004,
          FilterName: 'This Fiscal Year (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month (Fiscal)',
          Name: 'This Fiscal Year',
          StartDateVariable: 'ThisJanuaryStart',
          EndDateVariable: 'ThisDecemberEnd',
          ChartLabels: ['Q1','Q2','Q3','Q4'],
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],
          ChartlabelPeriodEndMethodX: 'EndofQuarter',
          ChartlabelPeriodEndMethodY: 'EndofQuarter'
        },
        {
          ID: 1000005,
          FilterName: 'This Fiscal Quarter (Per Month)',
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',
          GiveName: 'Per Month (Fiscal)',
          Name: 'This Fiscal Quarter',
          StartDateVariable: 'CurrentFiscalQStart',
          EndDateVariable: 'CurrentFiscalQEnd',
          ChartLabelsisDynamic: true,
          DynamicChartLabelsProp: 'CurrentFiscalLabels',
          ChartLabelTrigger: 'CurrentFiscalPeriod',
          ChartlabelPeriodEndMethodX: 'EndofMonth',
          ChartlabelPeriodEndMethodY: 'EndofMonth',
          
        },
        
      ],
            Today: new Date('2020-05-01'),
            ViewSample: false,
            ActiveRecord: '',
            MyGoalswithData: [],
            Goals: [
                {
                Name: 'Accounts Created',
                Timelines: [
                    {
                    Name: 'Overall',
                    Color: 'red',
                    Achieved: 833344,
                    Target: 1356550,
                    OriginalImport: true,
                    Period: 'This Year'
                    },
                    {
                    Name: 'This Month',
                    Color: 'red',
                    Achieved: 125778,
                    Target: 339175.5,
                    Period: 'This Month'
                    },
                    {
                    Name: 'This Week',
                    Color: 'red',
                    Achieved: 15705,
                    Target: 26087.5,
                    Period: 'This Week'
                    },
                ]
            }
            ]
        }
    },
    watch: {
      MyGoalswithData: {
        handler: function(value) {
          if (value !== null && value !== undefined && value.length>0) {
          this.LoadingItems = false
          }
        },
            deep: true
      },
      MyGoals: {
        handler: function(value) {
          //console.log('MyGoals value',value)
          if (value !== null && value !== undefined && value.length>0) {
              this.LoadingItems = true
                this.PrepareGoalswithData()
          }
           },
            deep: true
      },
      userLoggedIn (value) {
        //if (value !== null && value !== undefined) {
         // this.$router.push('/')
        //}
      },
    },
    computed:{
        UsersArray(){
            return this.$store.state.Users
        },
        ShowLoader(){
            if(this.GoalsComputed.length > 0){
                return false
            }
            else if(this.MyGoalsisEmpty){
                return false
            }
            else{
                return true
            }
        },
        GoalsComputed(){
            // return this.MyGoals
            // .map(goal => {
            //     goal.ComponentOne = {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}
            //     // goal data must be called, absolutely has to
            //     //Also, you still need to prepare the structure of the element. 
            //     //Now here is the bith
            //     //Goals could be with label or without, by count or by date orwhatever
            //     //goals OriginalImport matter as well
            //     //For context at the top, we actually need to convert "target" and "matching values" with the "timeline"
            //     //because goal.Timeline = what context sees. 
            //     //perhaps we should start with context, yes please it's easiest
            //     //but even so I guess we need everythign dashbaordbuilder needs. 
            //     //could we not please just call whatever needs to be called please? call dashboardbuilder in a loop?
            //     return goal
            // })
            return this.MyGoalswithData
            .map(goal => {
                goal.GoalMeasurements = true
                goal.ThemeColor = this.ThemeColor
                goal.ComponentOne = {CompName: 'DashboardElmtChart',Style: 'Top'}
                goal.ComponentTwo = {CompName: 'DashboardElmtDataContext',Style: 'List'},
                goal.ChartPosition = 'Top'
                goal.ChartType = 'line'
                goal.LookupArrays = [
                    {
                    Field: 'Created_By',
                    Array: this.UsersArray
                  },
                ]
                return goal
            })
            .map(goal => {
                //the issue is, we don't have the main timeline item. 
                //ExportTimelineType
                goal.Timelines = []
                let primarytimelineobj = {
                    Name: 'Overall',
                    Color: 'red',
                    OriginalImport: true,
                    Period: 'This Year'
                    }
                    //TimelineFilterOptions && TimelineType per target
                    //achieved would be whatever was created in the period so fine
                
                //target is target that is just easy but yet still withina
                console.log('goal.Targets',goal.Targets,goal)
                primarytimelineobj.Target = goal.Targets.filter(target => {return target.OriginalImport}).map(target => {return target.Target}).reduce((a, b) => a + b, 0)
                primarytimelineobj.Achieved = goal.Data.length
                goal.Timelines.push(primarytimelineobj)
                this.TimelineFilterOptions.map(opt => {
                    if(opt.ID !== goal.ExportTimelineType.ID){
                     let matchlist = goal.Targets.filter(obj => {return obj.TimelineType === opt.ID})
                    if(matchlist.length > 0){
                        let TimelineItem = {
                            Name: opt.Name,
                            OriginalImport: true,
                            Period: opt.Name,
                            TimelineID: opt.ID
                        }
                        //this is fine but it sucks. Like we have 100003 whihc is this year, but per month
                        //each month is loaded target is loaded as 1000002
                        //so the sum of every week is also brought in here. But why does this suck?
                        //dude, it does not work properly
                        TimelineItem.Target = matchlist.map(target => {return target.Target}).reduce((a, b) => a + b, 0)
                        TimelineItem.Achieved =  goal.Data.length
                        //here we must push the matching objects
                        goal.Timelines.push(TimelineItem)
                    }   
                    }
                    
                })

                // goal.Targets.filter(target => {return target.OriginalImport}).map(target => {return target.Target}).reduce((a, b) => a + b, 0)
                
                
                return goal
            })
            .map(goal => {
               let maintargetitem = goal.Timelines.find(obj => obj.OriginalImport)
               goal.BreakdownName = maintargetitem.Period
               if(maintargetitem.Target === 0){
                   goal.Value = 0
               }
               else{
                 goal.Value = Math.round((maintargetitem.Achieved/maintargetitem.Target)*100)  
               }
               
                let redcolor = Math.round(100-goal.Value)*2.55
                let greencolor = Math.round(goal.Value*2.55)                 
                goal.Color = 'rgb('+redcolor+','+greencolor+',0)'
                goal.Timelines = goal.Timelines.map(item => {
                item.Value = Math.round((item.Achieved/item.Target)*100)
                let redcolor = Math.round(100-item.Value)*2.55
                let greencolor = Math.round(item.Value*2.55)                 
                item.Color = 'rgb('+redcolor+','+greencolor+',0)'
                if(item.Name !== maintargetitem.Name){
                    item.PercentageUp = Math.round((item.Achieved/maintargetitem.Achieved)*100)
                    if(item.PercentageUp>0){
                        item.IncreaseColor = 'green'
                    }
                }
                return item
            })
                return goal
            })
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
            return this.$store.getters.user
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
    },
    created(){
        
        this.PrepareGoalswithData()
    },
    methods:{
        PrepareGoalswithData(){
            //console.log('this.MyGoals',this.MyGoals.length,this.MyGoals)
            let vm = this
            let array = this.MyGoals
        array.map(goal => {
            console.log('goal here',goal)
            goal.Data = []
            let goalquery = db.collection(goal.PrimaryEntity.split('_').join('').toLowerCase()).where('Ownerid','==',this.userLoggedIn.id)
            console.log(typeof goal.PrimaryFilterField.Name, goal.PrimaryFilterField)
            let queryfield = goal.PrimaryFilterField.Name
            if(goal.PrimaryFilterType === 'Lookup'){
                queryfield = queryfield+'id'
                goalquery = goalquery.where(queryfield,'==',this.userLoggedIn.id)
            }
            // else if(goal.PrimaryFilterType === 'Option Set'){
            //     queryfield = queryfield+'id'
            //     goalquery = goalquery.where(queryfield,'==',this.userLoggedIn.id)
            // }
            this.GetGoalData(goal,goalquery).then(result => {
                if(result){
                    console.log(result)
                    goal.Data = result
                    console.log(goal.Data)
                }
                vm.MyGoalswithData.push(goal)
            })
            
        })
        },
        GetGoalData(goal,goalquery){
            console.log(goalquery)
            goal.Data = []
            let vm = this
            return new Promise(function(resolve, reject) {
                goalquery.onSnapshot(res => {
                let array = []
                
                let listlength = res.docs.length
                console.log('listlength',listlength)
                    if(listlength === 0){
                    resolve(goal.Data)
                    }
                    const changes = res.docChanges();
                        changes.forEach(change => {
                            if (change.type === 'added') {
                            goal.Data.push({
                                ...change.doc.data(),
                                id: change.doc.id
                            })
                            }
                            if(goal.Data.length === listlength){
                                resolve(goal.Data)
                            }
                    })
                })
            })
        },
        GetMatchingStatus(item){
         if(this.MenuItem && this.MenuItem.Array){
           return this.MenuItem.Array
            .map(item => {
                item.Status = 'Active'
                return item
            })
            .filter(blog => {
                return blog.Status === item.title
            })
            .map(blog => {
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                blog.Value = Math.round((blog.Votes.length/this.UsersArray.length)*100)
                let redcolor = Math.round(100-blog.Value)*2.55
                let greencolor = Math.round(blog.Value*2.55)                 
                blog.Color = 'rgb('+redcolor+','+greencolor+',0)'
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                console.log(blog.Votes)
                blog.Votes.map(vote => {
                    vote.PrimaryDateSTRING = this.TimestampFormatterSTRING(vote.createdon.toDate())
                    vote.PrimaryDateObj = new Date(vote.PrimaryDateSTRING)
                })
                let date = new Date()
                    date.setHours(0,0,0,0)                    
                    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate()-this.ByDays)
                    console.log('d',d)
                let votesthisweek = blog.Votes.filter(vote => {
                  //console.log(PrimaryDateObj)
                    
                    return vote.PrimaryDateObj >= d
                }).length
                console.log('votesthisweek',votesthisweek)
                blog.PercentageUpThisWeek = Math.round((votesthisweek/blog.Votes.length)*100)
                if(blog.PercentageUpThisWeek > 0){
                    blog.WeekIncreaseColor = 'green'
                }
                return blog
            })
         }
         else{
           return []
         }
            
            
        },
        ViewChart(item){
            alert('yay here is the chart')
        },
        PushItem(item){
            this.ActiveRecord = item
            console.log(this.ActiveRecord)
        },
    }

}
</script>

<style>

</style>


    