
<template>
<v-card
      flat
      class="transparent"
      
    >
    <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
             <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{System.Name}} Members</h1>
                <h4 class="headline">{{Computedgroupmembers.length}}</h4>
              </v-col>
            </v-row>
             
            </v-parallax> 
<v-layout class="justify-center my-6">
<v-flex xs12 s12 md11 lg11>
        <v-toolbar rounded :style="{backgroundImage: GroupsGradient}" elevation="12" color="primary white--text">
          <v-toolbar-title  class="mediumoverline">
          <v-icon  class="mx-1" x-large color="white">mdi-face</v-icon>{{groupName}}</v-toolbar-title>
          <v-divider
            class="mx-4 white"
            inset
            vertical
          ></v-divider>
        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You changed a User Role.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
            <v-snackbar v-model="contributorsnackbar" :timeout="8000" top color="success">
      <span>Awesome! You added a Contributor, view them all and finalize their Contributor Profiles in "Member Contributors".</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-text-field dark
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item>
                Edit
              </v-list-item>
            </v-list>
        </v-menu>
          </v-toolbar>
            <v-row dense style="padding-top:10px;">
              <v-col
                v-for="member in Computedgroupmembers" :key="member.id"
                :cols="MemberCols"
              >
              <v-card :to="'/SiteMember/'+member.id"
                  class="mx-auto"
                  max-width="434"
                  tile
                >
                  <v-img                  
                    height="100"
                    src="@/assets/logo.png"
                  >
                  <!-- GroupsGradient
                  :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" -->
                        <v-list-item dark
                        
                        :style="{backgroundImage: GroupsGradient}"
                          
                          
                        >
                         <v-list-item-avatar size="80">
                            <v-img :src="member.Profile_Photo"></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title class="title">
                              {{member.Full_Name}}
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="member.Position">{{member.Position}}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="!member.Position">Member</v-list-item-subtitle>
                            <v-list-item-subtitle>{{member.FavoriteQuote}}</v-list-item-subtitle>
                          </v-list-item-content>
                         
                        </v-list-item>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
    </v-flex>
 </v-layout>
</v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
props: ['UserRecord','FriendsList','System'],
    data() {
        return {
          Company: 'Ignite Youth',
          hover: false,
          showerror: false,
          tags: [
                    'Auckland',
                    'Wellington',
                    'Very long string that would overflow'
                ],
          snackbar: false,
          contributorsnackbar: false,
              UserRolesType: [
      {index: 0, text: 'Follower'},
      {index: 1, text: 'Editor'},
      {index: 2, text: 'Administrator'},
        ],
        ListingContributors: false,
        EditingContributors: false,
        blogdialog: false,
        contributordialog: false,
        dialog: false,
        search: '',
        servicesearch: '',
        orgservicesearch: '',
        groupblogs: [],
        groupmembers: [],
        contributorgroups: [],
        contributingmembers: [],
        groupName: null,
        groups: [],
        users: [],
        // membersofgroup: [],
        groupid: this.$route.params.id,
        content: null,
        logo: '',
        image: '',
        description: null,
        Network: false,
        Editing: false,
        name: null,
        ServicestoOrganizations: [],
        DefaultServicestoOrganizations: [],
        ServicestoIndividuals: [],
        DefaultServicestoIndividuals: [],
        newservicetoindividual : {
          index: '', Name: '' ,checked: true
        },
        defaultservicetoindividual: {
          index: '', Name: '', check: true
        },
        editedContributor: {
              id: null,
              memberid: null,
              groupmemberid: null,
              ContributionCategory: null,
              ContributetoIndividuals: false,
              IndividualContributionDescription: null,
              OrganizationContributionDescription: null,
              ContributetoOrganizations: false,
              FreeConsultation: false,
              ServiceCategorytoIndividuals: null,
              //consulting,legal,financial,technical specialist,other
              ServiceCategorytoOrganizations: null,
              //consulting,legal,financial,technical specialist,other
              ServicestoIndividuals: [],
              ServicestoOrganizations: [],
              DefaultServicestoOrganizations: [],
              DefaultServicestoIndividuals: [],         


        },
            editedItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            defaultItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            blogtitle: null,
            blogcontent: null,
            image: null,
            //userid: this.$store.state.user.id,
            UserisMember: false,
            UserisAdmin: false,
            tetempusid: "'GNagVng6n1VgjtSXXrHDdEejjM93'"+","+"'Sk7cvSO6uXMJdN2qwJW5C9LSwzj1'", 
            Changes: false,
            GroupCategory: null,
            Contributorprofilefield: '',
            helpnewsheet: false,
            // MinistryContributionstoIndividialNames: '',
            MinistryContributionstoIndividualArr: [],
            BusinessContributionstoIndividualArr: [],
            FamilyContributionstoIndividualArr: [],
            MinistryContributionstoOrganizationsArr: [],
            BusinessContributionstoOrganizationsArr: [],
            FamilyContributionstoOrganizationsArr: [],
            HasOrgServices: false,
            HasIndServices: false,      
        
        }
    },
    computed: {
      GroupsGradient(){
      return this.$vuetify.theme.dark ? 'linear-gradient(338deg, '+this.System.Light_Social_App_SearchBar.hex+'EE 0%, '+this.System.Dark_Social_App_SearchBar.hex+'DD  100%)': 'linear-gradient(338deg, '+this.System.Dark_Social_App_SearchBar.hex+'DD 0%, '+this.System.Light_Social_App_SearchBar.hex+'DD  100%)' 
      },
      MemberCols(){
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else {
          return 2
        }
      },
      MyFriends(){
        return this.$store.state.FriendshipsArray
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      Computedgroupmembers(){
        return this.users.map(member => {
          if(member.Created_On){
            member.MembershipDate = this.TimestampFormatterSTRING(member.Created_On.toDate())
          }
          
          return member
          
        }).filter(member => {
          return member.Full_Name.toLowerCase().includes(this.search.toLowerCase())
        })
        .filter(member => {
          return member.id !== this.userLoggedIn.id
        })
        
                    
       },
    },
    watch: {
      MyFriends (value) {
            if (value) {
              //this.FriendsFirst()
            }
      },
      FriendsList (value) {
        console.log('FriendsList',value.length,value)
            if (value && value.length > 0) {
              //this.MyFriendsFirst(value)
            }
      },
        // MyFriends: {
        //     handler: function(oldvalue, newvalue) {
        //         if(oldvalue && newvalue && oldvalue.length !== newvalue.length){
        //            this.FriendsFirst()
        //         }
        //     },
        //     deep: true
        // },
    },
        created() {    
          setTimeout(() => {
            console.log(this.UserRecord)
          }, 1000); 
             this.IntranetViewToggle() 
             console.log(this.FriendsList)
              // let anybodyquery = db.collection('users').where('MyProfileViewPermissions','==','Anybody')
              // this.getUsers(anybodyquery)            
        //this.FriendsFirst()
        //let friendslength = this.FriendsList.length
        //this.MyFriendsFirst(this.FriendsList)
        let query = db.collection('users')
        this.getUsers(query)
    },

     methods: {
       MyFriendsFirst(value){
         let length = value.length
         value.map((friend,i) => {
                let oncheck = this.users.find(obj => obj.id === friend.id)
                if(!oncheck){
                  this.users.push(friend)
                  let FOFlist = friend.FriendIDStrings
                  .filter(fof => {
                    return fof !== this.userLoggedIn.id
                  })
                  .filter(fof => {
                    let myfriend = value.find(obj => obj.id === fof)
                    if(!myfriend){
                      return fof
                    }
                  })
                  console.log('FOFlist',FOFlist)
                  if(FOFlist.length > 0){                    
                    FOFlist.map(fofid => {
                      this.GetFriendofFriend(fofid)
                    })
                  }
                  
                }
                if(length === i-1+2){
                  let anybodyquery = db.collection('users').where('MyProfileViewPermissions','==','Anybody')
                  this.getUsers(anybodyquery)
                }
              })
       },           
       
       GetFriendofFriend(fofid){
         console.log(fofid)
         db.collection('users').doc(fofid).onSnapshot(snapshot => {
           let userobj = snapshot.data()
           userobj.id = fofid
           console.log(userobj)
            let oncheck = this.users.find(obj => obj.id === fofid)
            if(!oncheck){
              this.users.push(userobj)
            }
         })
       },
       IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
       TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        ToggleHelp() {         
            this.helpnewsheet = true
          },

        editItem (item) {
        this.editedIndex = this.GroupMembersWithSearch.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        // this.Editing = true
      },

      

   
        
    GetUserBUInformation(userobj){
      let vm = this
      return new Promise(function(resolve, reject) {
      db.collection('businessunits').doc(userobj.Business_Unit.id).onSnapshot(snapshot => {
          let buobj = snapshot.data()
          userobj.Business_Unit = buobj
          userobj.Business_Unit.id = userobj.Business_Unit.id
          resolve(userobj)
        })
      })
    },
        
    getUsers(query) {
      let vm = this
      query.onSnapshot(res => {
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {
              let userobj = {
                ...change.doc.data(),
                id: change.doc.id
              }
              let oncheck = this.users.find(obj => obj.id === userobj.id)
                if(!oncheck){
                  if(userobj.Business_Unit){
                    this.GetUserBUInformation(userobj).then(result => {
                    vm.users.push(result)
                  })
                  }
                  else{
                    this.users.push(userobj)
                  }
                }
            }  
          })
        })    
        },
          
            
     }
}
</script>

<style>
.tags-input {
    border: 1px solid #333;
    display: inline-block;
}
.UserName {
    font-size: 10px;
    font-weight: bold;
    color: #474747
}
.Memo {
      font-size: 10px;
}
.userprofileBG {
  align-self: center
}
.v-avatar.outlined {
                  border: 8px solid #048abf;
                  border-radius:100%;

}
.searchguide {
  color: #e4003b;
  font-size: 14px
}

</style>

    

    