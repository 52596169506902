<template>
<div>
    
        <v-card-title  class="justify-space-between recordtoolbar white--text">
            {{ActiveAccount.Client_Reference}}<v-spacer></v-spacer>
            <v-btn @click="DeactivateActiveAccountView()">All Accounts ({{ActiveAccount.User.Name}})</v-btn>
        </v-card-title>
         <v-tabs class="siteconfigcardtabs" style="background-color: #FFFFFF00;">
               <v-tab v-if="LoanOptionsActive">Request Loan
               </v-tab>
               <v-tab-item v-if="LoanOptionsActive" style="padding:10px;background-color: #FFFFFF00 !important;">
                <AccountLoanRequest :ActiveAccount="ActiveAccount" :SystemEntities="SystemEntities" :System="System"
                />
                    
               </v-tab-item>
               <v-tab>Statement
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00 !important;">
                <AccountStatement :ActiveAccount="ActiveAccount"
                />
                    
               </v-tab-item>
                <v-tab class="transparent">Basic Information
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;">
                    <AccountBasic :ActiveAccount="ActiveAccount"
                />
               </v-tab-item>
                <v-tab v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Group Account'"> Credit Tokens
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;" v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Group Account'">
                      <AccountCreditTokens :ActiveAccount="ActiveAccount"
                />
                     
               </v-tab-item>
                <v-tab v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account' || 
                ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Cash Loan Account'">Transactions
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;" v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account' || 
                ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Cash Loan Account'">
                    <AccountTransactions :ActiveAccount="ActiveAccount" :SystemEntities="SystemEntities"
                />
                   
               </v-tab-item>
               <v-tab v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account'">Previously Ordered
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;" v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account'">
                     <AccountOrderHistory :ActiveAccount="ActiveAccount" :ClientStoreOrders="ClientStoreOrders"
                      />
                     
               </v-tab-item>
               <v-tab v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account'">My Wishlist
               </v-tab>
               <v-tab-item style="padding:10px;background-color: #FFFFFF00;" v-if="ActiveAccount && ActiveAccount.Account_Type && ActiveAccount.Account_Type.Name === 'Store Account'">
                   <AccountWishlist :ActiveAccount="ActiveAccount"
                      />
                  
               </v-tab-item>
         </v-tabs>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
import AccountStatement from '@/components/Client/AccountStatement'
import AccountLoanRequest from '@/components/Client/AccountLoanRequest'
import AccountBasic from '@/components/Client/AccountBasic'
import AccountCreditTokens from '@/components/Client/AccountCreditTokens'
import AccountTransactions from '@/components/Client/AccountTransactions'
import AccountOrderHistory from '@/components/Client/AccountOrderHistory'
import AccountWishlist from '@/components/Client/AccountWishlist'
export default {
    props: ['System','SystemEntities','ActiveAccount','ClientStoreOrders','SubscriptionPackages'],
    components: {FieldValueEditerComponent,AccountStatement,AccountLoanRequest,AccountBasic,AccountCreditTokens,AccountTransactions,AccountOrderHistory,AccountWishlist},
    data() {
        return {
          Invoice_Address: {Name: 'Invoice_Address',FieldBreakdown: []},
          Collection_Address: {Name: 'Collection_Address',FieldBreakdown: []},
        }
    },	
    computed:{
      CompanyBillingAccounts(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.CompanyBillingAccounts
      },
      ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
      LoanAccount(){
          return this.ActiveAccount.Account_Type && this.ActiveAccount.Account_Type.Name === 'Cash Loan Account'
      },
      LoanOptionsActive(){
          //entities to follow soon
          return this.LoanAccount && this.userLoggedIn.LoanEntities && this.userLoggedIn.LoanEntities.length > 0 && this.userLoggedIn.id === this.ActiveAccount.Userid
      },
      TokenEndingBalanceLength(){
          let string = this.TokenEndingBalance.toString()
          return string.length
      },
      TokenEndingAmount(){
          return this.ComputedCreditTokenTransactions.map(trans => {
                  let total = Number(trans.Amount)
                  return total
              }).reduce((a, b) => a + b, 0)
      },
      TokenEndingBalance(){
          //125489015.12
          return this.ComputedCreditTokenTransactions.map(trans => {
                  let total = Number(trans.RevenueAmount)
                  return total
              }).reduce((a, b) => a + b, 0)
      },
      
      ActiveAccountRef(){
          return this.ActiveAccount && this.ActiveAccount.id ? db.collection('billingaccounts').doc(this.ActiveAccount.id) : ''
      },
      BusinessUnitsArray(){
          return this.$store.state.BusinessUnitsArray
      },
      AppMonetizeBU(){
          return this.System.Monetization_BU ? this.System.Monetization_BU : ''
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      ActiveAccountRef(){
            return this.ActiveAccount && this.ActiveAccount.id ? db.collection('billingaccounts').doc(this.ActiveAccount.id) : ''
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      //this.PrepareAccount()
      
     
    },
    methods:{
      DeactivateActiveAccountView(){
        this.$emit('DeactivateActiveAccountView')
      },      
      ActivateUploadingSingleFileNotification(boolean){
        this.$emit('ActivateUploadingSingleFileNotification',boolean)
      },
    }
}
</script>

<style>

</style>



