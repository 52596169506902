
<template>
  <div style="padding:15px;">
    <v-dialog max-width="400" v-model="NewTaskDialog">
      <v-card>
        <v-card-title class="recordtoolbar white--text">
          <v-icon dark>mdi-clipboard-list</v-icon>Create Activity
          
        </v-card-title>
        <v-card-title v-if="NewTask.Regarding_Type">
            <v-chip>
            {{NewTask.Regarding_Record[NewTask.Regarding_Record.identifier]}}
          </v-chip>
        </v-card-title>
        <v-card-text>
          <v-text-field label="Subject" v-model="NewTask.Subject" />
            <v-card-title class="justify-start">
              Description: 
            </v-card-title>
            <v-card-subtitle class="caption">
              Write the value using the editor below, and remember to click on the "save" to capture the value. 
            </v-card-subtitle>
            <v-card-text>
              <v-layout row class="justify-center" >
            <v-col cols="12" >
          <ContentEditableField style="padding:15px;"
          :FieldObject="NewTask" :FieldName="'Description'"
          :FieldValue="NewTask.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
            </v-col>
              </v-layout>
            </v-card-text>
          <!-- <v-textarea label="Description" v-model="NewTask.Description" /> -->
          <v-menu
                      v-model="NewTaskDueDatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="NewTask.Due_Date"
                          label="Due Date"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker :allowed-dates="disablePastDates" v-model="NewTask.Due_Date" @input="NewTaskDueDatemenu = false"></v-date-picker>
                    </v-menu>
          <v-switch label="Set Reminder" v-model="NewTask.Has_Reminder" />
          <v-menu
                      v-model="NewTaskSnoozed_UntilDatemenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field v-if="NewTask.Has_Reminder"
                          v-model="NewTask.Snoozed_Until"
                          label="Reminder Date"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker :allowed-dates="disablePastDates" :max="NewTask.Due_Date" v-model="NewTask.Snoozed_Until" @input="NewTaskSnoozed_UntilDatemenu = false"></v-date-picker>
                    </v-menu>
          <v-text-field label="QuickLink (Optional)" :rules="[rules.url]" v-model="NewTask.QuickLink" />
          
          <v-select item-text="Name" label="Type" v-model="NewTask.Type" :items="ActivityTypes" return-object />
          <!-- <v-select label="Owner" item-text="Full_Name" v-model="NewTask.Owner" :items="UsersStore" return-object /> -->
            <UserLookup :UsersLookupArray="TeamOwnersArray"  :ModelProp="'Owner'" :RecordObj="NewTask" @UpdateUserLookupProp="UpdateUserLookupProp"
                    :FieldLabel="'Owner'" :Rules="[rules.required]"
                    />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="CloseTaskDialog()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="CreateNewTask()">Save</v-btn>
        </v-card-actions>
      </v-card>
</v-dialog>
      <v-dialog v-model="ReadingTaskDialog" width="400" v-if="dragging">
          <!-- <v-expand-transition> -->
            <v-card tile
               width="400"
                class="transition-fast-in-fast-out"
                style="height: 100%;bottom: 0;position: absolute;right: 0;"
            >
            <v-list-item>
              <v-list-item-action>
                <v-icon class="soloactionicon" color="red" dark @click="CancelReadingTaskDialog()">mdi-close</v-icon>
              </v-list-item-action>
              <v-list-item-content>
              <v-list-item-title>
                <b >{{dragging.Subject}} - {{draggingstatus.Name}}</b>
              </v-list-item-title>
              <v-list-item-subtitle class="caption">
               Due Date - {{dragging.stringduedate}}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="caption overline red--text" style="font-size:10px;" v-if="dragging.overdue">
               Overdue!!!
              </v-list-item-subtitle>
               <v-list-item-subtitle style="padding:5px;" v-if="dragging.QuickLink">
                  <v-btn  v-if="dragging.QuickLink" target="_blank" :href="dragging.QuickLink" small>View Link</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :class="StatusColor(draggingstatus)">
              <v-list-item-title style="padding:5px;">
                <v-menu
                        v-model="duedatemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        id="EventStartDatemenu"
                      >
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" x-small>Change Due Date</v-btn>
                        </template>
                        <v-date-picker :allowed-dates="disablePastDates"
                        v-model="NewDueDate" @input="duedatemenu = false,AssignNewDueDate(dragging)"></v-date-picker>
                      </v-menu> 
                      <v-menu width="380"
                              v-model="StatusDialmenu"
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                             <v-btn color="success" dark
                                  v-on="on"  v-if="userLoggedIn.id === dragging.Ownerid" x-small>
                                
                                  Update
                                </v-btn>
                               
                            </template>
                            <v-card width="380">
                              <v-card-title>
                                Current Status - {{dragging.Status_Reason.Name}}
                                
                              </v-card-title>
                              <v-card-text class="caption">                                 
                                <ul>Click below to update the Status of the Task, and notify the Creator:
                                   <li><v-btn x-small color="success" dark @click="CompletedTaskProcess(dragging)">Completed</v-btn> - Confirms to the Creator as "Completed" and reminders disappear.
                                  </li>
                                  <li><v-btn x-small color="blue" dark @click="OnItTaskProcess(dragging)">I am on it</v-btn> - Confirms to the Creator as "In Progress".
                                  </li> 
                                  <li><v-btn x-small color="red" dark @click="OnHoldTaskProcess(dragging)">On Hold</v-btn> - Confirms to the Creator as "On Hold".
                                  </li>    
                                  <li><v-btn x-small color="grey" dark @click="CancelTask(dragging)">Cancel</v-btn> - Confirms to the Creator as "Cancelled".
                                  </li>                             
                                </ul>
                              </v-card-text>
                            </v-card>
                            </v-menu>  
                           
              </v-list-item-title>
              <v-list-item-content>           
               <v-list-item-subtitle class="caption">
               <i>Created by {{dragging.Created_By.Full_Name}}</i>
              </v-list-item-subtitle>
               <v-list-item-subtitle class="caption">
               <i>on {{dragging.stringcreatedon}}</i>
              </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
               
                <v-card-text v-if="dragging.Created_Byid === userLoggedIn.id && EditingDescription || dragging.Ownerid === userLoggedIn.id && EditingDescription">
                  <v-icon @click="EditingDescription = !EditingDescription" v-if="dragging.Created_Byid === userLoggedIn.id || dragging.Ownerid === userLoggedIn.id">mdi-close</v-icon>
                   <v-card-title class="justify-start">
                    Description: 
                  </v-card-title>
                  <v-card-subtitle class="caption">
                    Write the value using the editor below, and remember to click on the "save" to capture the value. 
                  </v-card-subtitle>
                  <v-card-text>
                    <v-layout row class="justify-center" >
                  <v-col cols="12" >
                <ContentEditableField style="padding:15px;font-size:10px;" class="caption"
                :FieldObject="dragging" :FieldName="'Description'"
                :FieldValue="dragging.Description" @UpdateContentEditableField="UpdateTaskDescription" :AdditionalSaveMethod="''" />
                  </v-col>
                    </v-layout>
                  </v-card-text>
                </v-card-text>
                <v-card-text v-if="dragging.Created_Byid !== userLoggedIn.id && dragging.Ownerid !== userLoggedIn.id || !EditingDescription">
                  <v-icon @click="EditingDescription = !EditingDescription" v-if="dragging.Created_Byid === userLoggedIn.id || dragging.Ownerid === userLoggedIn.id">mdi-pencil</v-icon>
                  <span v-html="dragging.Description" class="caption" style="font-size:10px;">
                  </span>
                </v-card-text>
            <v-card-actions class="hidden-md-and-up">
                <v-select @change="taskdragFinish(dragging.Status)" :items="Labels" item-text="Name" return-object v-model="dragging.Status"/>
            </v-card-actions> 
            <v-card-subtitle v-if="dragging.Regarding_Record">                                         
                <span class="blue--text overline" style="font-size:10px;" v-if="dragging.Regarding_Record">
                    Regarding: <router-link :to="dragging.Regarding_Record.Link+'/'+dragging.Regarding_Record.id">
                    {{dragging.Regarding_Record[dragging.Regarding_Record.identifier]}}
                    </router-link>
                </span>
                </v-card-subtitle>   
                <v-card-actions>
                 
                    <TopicalLiaisonComponent :itemsprop="'Comments'" :TimelineRecord="dragging"
                    @SaveNewcomms="PostComment" :ScrollHeight="WindowHeight*0.65"
                    :ActiveSession="ActiveSession"
                />
                </v-card-actions>
            </v-card>
            <!-- </v-expand-transition> -->
      </v-dialog>
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Activity.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-snackbar v-model="importsnackbar" :timeout="4000" top color="success">
      <span>Awesome! You imported the file. You can view your import results <a href="/DataImports" target="_blank">here</a></span>
      <v-btn color="white" @click="importsnackbar = false">Close</v-btn>
    </v-snackbar>
    <v-card-title class="recordtoolbar white--text" v-if="RelatedObj && $route.meta && !$route.meta.TableBuilder || RelatedObj && !$route.meta">
      {{RelatedObj[RelatedObj.identifier]}} - Activities
    </v-card-title>
    
      <v-layout row wrap class="justify-space-between mx-2">
  <v-tabs :style="RelatedObj && $route.meta && !$route.meta.TableBuilder ? 'padding-top: 50px;' : ''" centered>
    <v-tab>
      Tasks
    </v-tab>
    <v-tab-item>
      <v-list-item style="padding: 10px;">
      <v-btn @click="ToggleNewTaskDialog()" outlined>Add Task</v-btn>
      <!-- <v-btn outlined>Add Appointment</v-btn> -->
    </v-list-item>
     <v-layout row class="justify-center mx-3">   
        <v-chip-group   
                    v-if="TasksFilterMode == 'Regarding Type'"    
                    v-model="selectedType"    
                    active-class="deep-purple--text text--accent-4"   
                    column    
                        
                >   
                <v-chip v-for="type in STDActionTypesbyBoard" :key="type.Name" :value="type">   
                {{ type.Name }}   
                </v-chip>
                </v-chip-group>
                <v-chip-group   
                    v-if="TasksFilterMode"    
                    v-model="selectedFilter"    
                    @change="ActivateProcessMapFilter()"    
                    active-class="deep-purple--text text--accent-4"   
                    column  
                >   
                <v-chip v-for="type in FilteredArray" :key="type.id" :value="type">   
                {{ type[type.identifier] }}   
                </v-chip> 
            </v-chip-group>   
        </v-layout>
        <UserLookup style="padding-top: 70px;" :UsersLookupArray="TeamOwnersArray" :ModelProp="'ActivityOwnerFilter'" :RecordObj="this" @UpdateUserLookupProp="UpdateUserLookupProp"
                      :FieldLabel="'Owner'" :ReadOnly="false" v-if="TeamView"
                      :Rules="[]"
                      />
          <v-autocomplete clearable :items="['Today','This Week','Next Week','This Month']" label="Due By" item-text="Full_Name" v-model="Activitydueby"></v-autocomplete>
                <v-row v-if="RegardingTypefilter && ActivitiesArray"
                class="fill-height"   
                style="padding:30px;"
                align="start" ref="myDiv"  
                justify="center">        
                <!-- lg3 md3 sm6 xs12
                clientWidth {{clientWidth}}
                 xs {{ActivitiesRowElementXS}}
                  sm {{ActivitiesRowElementSM}}
                   md {{ActivitiesRowElementMD}}
                    lg {{ActivitiesRowElementLG}} -->
                <v-flex :[dynamicFlex]="true" class="my-3" v-for="status in ComputedLabels" :key="status.itemObjKey">   
                              
                        <v-card width="100%" :class="StatusBGColor(status)" tile @dragover.prevent @drop="taskdragFinish(status, $event)">    
                        <v-card-title :class="StatusColor(status)" style="font-size:12px;">
                            <v-icon color="white">{{status.Icon}}</v-icon>
                            {{status.Name}}
                            <v-spacer></v-spacer>{{Match(status).length}}</v-card-title>  
                                                  
                        <v-list dense class="transparent mx-1"    
                                min-height="100px"> 
                                <!-- <v-hover v-slot="{ hover }"  >    -->
                          <v-virtual-scroll 
                            width="100%"
                            class="transparent"
                            :items="Match(status)"
                            :item-height="70"
                            :height="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs ? WindowHeight*0.55 : WindowHeight*0.2"
                          >
                              <template v-slot:default="{ item }">   
                                <v-card :class="'my-1'" width="100%">
                                        
                                <v-list :dark="item.overdue" :class="item.overdue ? 'red' : ''">
                                    <v-list-item
                                    :draggable="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" @dragstart="taskdragstart(item, $event)"
                                >
                                <v-icon small v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
                                    mdi-drag
                                    </v-icon> 
                                    <v-list-item-avatar class="blue" size="25">
                                    <span class="white--text caption mx-1">
                                    {{item.Owner.Name.substr(0,1)}}{{item.Owner.Surname.substr(0,1)}}
                                    </span>
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                    <v-list-item-title>
                                         {{item.Subject}}
                                        <!-- <span class="caption red--text" style="font-size:10px;" v-if="item.overdue">Overdue</span> -->
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-icon x-small>mdi-calendar</v-icon> {{item.daycreated}}{{item.monthcreated}}
                                    </v-list-item-subtitle>
                                    <!-- <v-list-item-subtitle class="caption red--text" style="font-size:10px;" v-if="item.overdue">
                                         <v-icon color="red" x-small>mdi-alert</v-icon>Overdue
                                    </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                  
                                    <v-list-item-action>
                                        <v-icon
                                            small
                                            :color="item.overdue ? 'white' : ''"
                                             @click="ActivateTask(item,status)"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                        
                                    </v-list-item-action>
                                    <v-chip v-if="item.New" style="position: absolute;right: 0px;top: -7px;border-radius: 0px;" small color="error" outlined>
                                          New
                                          </v-chip>
                                </v-list-item>
                                
                               
                              
                            </v-list>                                           
                        </v-card>
                          </template>
                      </v-virtual-scroll>
                        <!-- </v-hover> -->
                    </v-list> 
                              
                </v-card> 
                  
            </v-flex>
        </v-row>
    </v-tab-item>
    <v-tab>
      Appointments
    </v-tab>
    <v-tab-item>
        <v-dialog v-model="NewEventDialog" max-width="800px">
      <v-card>
        <!-- <v-card-title class="primary white--text">       -->
        <v-toolbar flat dark height="100px">           
          <v-toolbar-title>New Event</v-toolbar-title>
         
        </v-toolbar>
            <!-- <v-list>          
          
        </v-list> -->
        <!-- </v-card-title> -->
        <v-list>
          <v-list-item>
            <v-list-item-content>
          <v-autocomplete :items="UsersArray" label="Owner" item-text="Full_Name" v-model="AppointmentOwner" return-object></v-autocomplete>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
          <v-text-field label="Subject" v-model="EventSubject" />
          </v-list-item>
            <div class="hovercontent mx-3"><v-icon @click="InvitingAttendees = !InvitingAttendees">mdi-account-multiple-plus<p class="tooltiptext">Invite Attendees</p></v-icon></div>
          
          <v-list-item v-if="InvitingAttendees">
            <v-list-item-avatar tile >               
                      <v-btn small @click="ToggleSelectDialog()" color="pop" dark>To:</v-btn>
                      </v-list-item-avatar>
            <v-list-item-content>               
                      <v-combobox v-model="Attendees" multiple  label="To" readonly :rules="[rules.required]" autocomplete="off"></v-combobox>  
                      </v-list-item-content> 
          </v-list-item>
           <v-list-item>
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu v-if="EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              <v-list-item-content>
                <v-checkbox v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
        v-if="EventStartDate"
          v-model="EventenddateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndDate"
              label="End Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
        </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
          v-model="EventEndTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventEndTimeMenu"
          
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventEndTime"
              label="End Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventEndTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
            v-model="EventEndTime"
             @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
            type="month"
            width="290"
            class="ml-4"
            
          :allowed-minutes="AllowedEndTimeMinutes"
          :allowed-hours="AllowedEndTimeHours"
          ></v-time-picker>
        </v-menu>           
                           
              </v-list-item-content>
          </v-list-item>
         
            <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
          <v-btn-toggle dark>
                          
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h1>')">
                            H1
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h2>')">
                            H2
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<h3>')">
                            H3
                          </v-btn>
                          <v-btn onclick="document.execCommand('formatBlock', false, '<p>')">
                            <v-icon>mdi-format-paragraph</v-icon>
                          </v-btn>
                          <!-- <v-select @click="getDocumentSelection()" :items="[2,4,6,8,10,12,14,16,18,20]" label="Size" @change="ChangefontSize()" v-model="fontsize">                        
                          </v-select> -->
                          <v-btn @click="copy()">
                            <v-icon>mdi-content-copy</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('delete',false,'')">
                            <v-icon>mdi-delete-forever</v-icon>
                          </v-btn>                      
                          <v-btn onclick="document.execCommand('cut',false,'')">
                            <v-icon>mdi-content-cut</v-icon>
                          </v-btn>                      
                        <!-- </v-btn-toggle>
                        <v-btn-toggle> -->
                          <v-btn onclick="document.execCommand('justifyLeft',false,'')">
                            <v-icon>mdi-format-align-left</v-icon>
                          </v-btn>
                          <!-- <v-btn>
                            <v-icon>mdi-format-align-center</v-icon>
                          </v-btn> -->
                          <v-btn onclick="document.execCommand('justifyCenter',false,'')">
                            <v-icon>mdi-format-align-justify</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('justifyRight',false,'')">
                            <v-icon>mdi-format-align-right</v-icon>
                          </v-btn>
                          
                          
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
            </v-list-item>
                        <v-list-item>
              <v-list-item-content>  
                 <v-layout class="justify-center">
                        <v-btn-toggle dark>
                          <v-btn onclick="document.execCommand('undo',false,'')">
                            <v-icon>mdi-undo</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('redo',false,'')">
                            <v-icon>mdi-redo</v-icon>
                          </v-btn>
                          
                          <v-btn onclick="document.execCommand('bold', false, '');">
                            <v-icon>mdi-format-bold</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('italic', false, '');">
                            <v-icon>mdi-format-italic</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('underline', false, '')">
                            <v-icon>mdi-format-underline</v-icon>
                          </v-btn>
                          <v-btn @click="changeColor()">
                            <v-icon>mdi-format-color-fill</v-icon>
                          </v-btn>
                          <v-btn  onclick="document.execCommand('selectAll',false,'')">
                            <v-icon>mdi-select-all</v-icon>
                          </v-btn>
                                                
                          <v-btn>
                            <!-- <input class="tool-items fa fa-file-image-o" type="file" accept="image/*" id="file" style="display: none;" @change="getImage()">

      <label for="file" class="tool-items fa fa-file-image-o"></label> -->
                            <v-icon>mdi-file-image</v-icon>
                          </v-btn>                      
                          <v-btn>
                            <v-icon @click="link()">mdi-link</v-icon>
                          </v-btn>
                          <v-btn onclick="document.execCommand('strikeThrough',false,'')">
                            <v-icon>mdi-format-strikethrough</v-icon>
                          </v-btn>
                        </v-btn-toggle>
                 </v-layout>
              </v-list-item-content>
                        </v-list-item>  
         
               <v-list-item>
              <v-list-item-content>
                <v-card width="90%" min-height="300px" outlined flat v-html="EventDescription" contenteditable id="previewsendemail">             
                </v-card>
              </v-list-item-content>
              </v-list-item>
               
               <v-list-item>
                 <v-list-item-content>
                    </v-list-item-content>
                 </v-list-item>
          
        </v-list>
        
            
        
        
        
     
        
        <v-card-actions>
          <v-btn @click="AddNewEvent()">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-sheet height="64" class="accent" dark>
        
        <v-toolbar flat dark>
            
          <v-btn outlined class="mr-4" color="secondary" @click="setToday">
            Today
          </v-btn>
          <v-btn fab text small color="secondary" @click="prev">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn fab text small color="secondary" @click="next">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>
          <v-toolbar-title>{{ datetitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu bottom right>
            <template v-slot:activator="{ on }">
              <v-btn
                outlined
                color="secondary"
                v-on="on"
              >
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = '4day'">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
     
      <v-sheet  height="100%" width="100%" v-if="AllRelevantEvents">
          <v-btn color="green" dark fab icon @click="ActivateNewEvent()"><v-icon>mdi-plus-thick</v-icon></v-btn>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="AllEventsViewFormatted"
          :event-color="getEventColor"
          :now="today"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar>
        <v-menu
          v-model="selectedOpen"
          :close-on-content-click="false"
          :activator="selectedElement"
          offset-x
        >
          <v-card
            color="grey lighten-4"
            min-width="350px"
            flat
          >
            <v-toolbar
              color="pop"
              dark
            >
              <v-btn icon>
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-toolbar-title v-html="selectedEvent.Subject"></v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="DeleteEvent()">
                <v-icon>mdi-delete-forever</v-icon>
              </v-btn>
              <v-menu v-if="selectedEvent.Regarding_Record"
                offset-x
              >
                <template v-slot:activator="{ on }">
                <v-btn  v-if="selectedEvent.Regarding_Record" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    Regarding
                  </v-card-title>
                  <v-card-text>
                     <router-link :to="selectedEvent.Regarding_Record.Link">
                    {{selectedEvent.Regarding_Record.Name}}
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-menu>                  
            </v-toolbar>
            <v-toolbar height="100px">
              <v-list>
                <v-list-item>
              <v-combobox v-model="selectedEvent.Attendees" multiple  label="To" readonly :rules="[rules.required]" autocomplete="off"></v-combobox>
                </v-list-item>
              </v-list>
            </v-toolbar>
            <v-card-text>
              <span v-html="selectedEvent.Description"></span>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                color="secondary"
                @click="selectedOpen = false"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-tab-item>
  </v-tabs>
  
  </v-layout>
  </div>
</template>
<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import ProcessMapComponent from '@/components/SuitePlugins/Pipelines/ProcessMapComponent'
import TopicalLiaisonComponent from '@/components/General/TopicalLiaisonComponent'
import ContentEditableField from '@/components/Database/ContentEditableField'
import UserLookup from '@/components/General/UserLookup'
export default {
  props: ['AppisDarkMode','ActivityType','UserRecord','AllEvents','ActivitiesArray','SystemEntities','RelatedObj','CurrentEntity','TeamView','UnreadNotifications','Record'],
  components: {
    ProcessMapComponent,
    TopicalLiaisonComponent,
    ContentEditableField,
    UserLookup
  },
  data() {
    return {
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },
      NewTaskDialog: false,
      NewTaskSnoozed_UntilDatemenu: false,
      NewTaskDueDatemenu: false,
      NewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
      DefaultNewTask: {
        Subject: '',
        Description: '',
        Type: '',
        Has_Reminder: true,
        Owner: '',
        Due_Date: '',
      },
      RelevantActivitiesArray: [],
      cancelmenu: false,
      EditingDescription: false,
      NewDueDate: '',
      StatusDialmenu: false,
      duedatemenu: false,
        draggingstatus: '',
        ReadingTaskDialog: false,
        ActiveSession: {title: 'Activities',topicid: 'Activities',LiaisonProp: 'InternalLiaison',SingleName: 'Activity',collection: 'activities',
        DigitizedForms: [],DocumentRegisters: [],Galleries: [],RecordPrimaryField: {Name: 'Subject'}},
        slice: 6,
        rules: {
                telnr: value => {
                    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
                    return pattern.test(value) || "Not a telephone number.";
                },
                youtubeurl: value => {
                    const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                    return pattern.test(value) || "Not a Youtube Video Link.";
                },
                min8Chars: value => value.length >= 8 || "Min. 8 characters",
                required: value => !!value || "Required.",
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || "Invalid e-mail.";
                },
                url: value => {
                    const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                '(\\#[-a-z\\d_]*)?$','i'); 
                    return urlpattern.test(value) || "Invalid Link.";
                }
                },
        focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            start: null,
            end: null,
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            TempAllEvents: [],
            NewEventDialog: false,
            EventstartdateMenu: false,
            AppointmentOwner: '',
            InvitingAttendees: false,
            AttendeeSelectDialog: false,
            EventRecordFilter: '',
            MailFieldFilter: '',
            UserHeaders:[
        { text: 'Selected', value: 'selected',sortable: false},
        { text: 'Title', value: 'Title'},
        { text: 'FullName', value: 'Full_Name'},
        { text: 'Name', value: 'Name'},
        { text: 'Surname', value: 'Surname'},
        { text: 'Gender', value: 'Gender'},
        { text: 'Business Unit', value: 'Business_Unit.Name'},
        { text: 'Manager', value: 'Manager.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
            ArrayFilter: 'To',
            relatedsearches: [],
            search: '',
            Attendees: [],
            EventenddateMenu: false,
            EventEndDate: '',
            EventStartTime: '',
            EventStartTimeMenu: false,
            AllDayEvent: false,
            EventEndTime: '',
            EventEndTimeMenu: false,
            EventStartDate: '',
            EventSubject: '',
            EventDescription: '',
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
            today: format(new Date(),'yyyy-MM-dd'),
      Labels: [
        {
          Name: 'Assigned',
          Icon: 'mdi-timeline-help-outline'
          },
          {
          Name: 'In Progress',
          Icon: 'mdi-timer'
          },
           {
          Name: 'On Hold',
          Icon: 'mdi-timeline-alert-outline'
          },
           {
          Name: 'Completed',
          Icon: 'mdi-marker-check'
          },

      ],
      EntityProcesses: [
  {
    "DisplayName": "Standard Task",
    "Number": 1000001,
    "ProcessStages": [
      {
        "Steps": [],
        "Number": 1000001,
        "IsHeader": true,
        "Automations": [],
        "Name": "Inbound",
        "Type": "Stage"
      },
      {
        "Automations": [],
        "Steps": [],
        "Type": "Stage",
        "Number": 1000002,
        "Name": "Stock",
        "IsHeader": true
      },
      {
        "Type": "Stage",
        "Steps": [],
        "Automations": [],
        "Name": "Outbound",
        "IsHeader": true,
        "Number": 1000003
      },
      {
        "Number": 1000004,
        "Automations": [],
        "Steps": [],
        "IsHeader": true,
        "Name": "Aftermarket",
        "Type": "Stage"
      }
    ],
    "Type": "Process",
    "Name": "Lifecycle",
    "IsHeader": true,
    "Automations": [],
    "ProcessStageInteger": 1000004
  }
],
      CreatedOnmenu: false,
      DueDatemenu: false,
      Month: {Name: '',Days: []},
      Years: ['2020','2021'],
      Year: '2020',
      Months: [{Name: 'January', Days: 31},{Name: 'February', Days: 28},{Name: 'March', Days: 31},{Name: 'April', Days: 30},{Name: 'May', Days: 31}],
      WeekDays: ['Monday','Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday'],
      ActivityTypeOptions: ['Email','Phone Call','Task','Appointment'],
      ActivityTypefilter: '',
      RegardingTypeOptions: [
  "Account",
  "SiteEvent",
  "User",
  "BusinessUnit"
],
      RegardingTypefilter: '',
      ActivityOwnerFilter: '',
      Activitydueby: '',
      UsersArray:[],
      mainrecordid: '',
      dialog: false,
      initialize: '',
      search: '',
      headers: [
      {
          text: 'id',
          align: 'left',
          sortable: false,
          value: 'id'
        },
        { text: 'Subject', value: 'Subject'},
        { text: 'Description', value: 'Description'},
        { text: 'Activity Type', value: 'Regarding'},
        { text: 'Regarding Type', value: 'Regarding_Type'},
        { text: 'Created On', value: 'Created_On'},
        { text: 'Due Date', value: 'Due_Date'},
        { text: 'Owner', value: 'Owner.Full_Name'},
        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      importdialog: false,
      importsnackbar: false,
      ImportHeaders: [],
      NewImportsArray: [],
      PartialFailuresArray: [],
      SuccessfulImportsArray: [],
      CSVResult: [],
      Import: {Name: '',AllImports: [],PartialFailures: [], Dataset: 'Activities', CreatedOn: '', CreatedBy: {id: '', Name: ''}},
      ImportFile: '',
      EditedActivitysnackbar: false,
      ActivityEditdialog: false,
      newActivitydialog: false,
      editedIndex: -1,
      defaultItem: {
        id: '',
          Subject: '',
          to: '',
          from: '',
          Description: '',
          Type: '',
          Regarding_Type: '',
          Created_On: '',
          Owner: {id: '',Name: '', Surname: '',Full_Name: ''},
          Due_Date: '',
      },
      editedItem: {
        id: '',
          Subject: '',
          to: '',
          from: '',
          Description: '',
          Type: '',
          Regarding_Type: '',
          Created_On: '',
          Owner: {id: '',Name: '', Surname: '',Full_Name: ''},
          Due_Date: '',
      },
      
      UserBusinessUnitID: '',
      UserRoles: [],
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnit: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: ['System Admin','CRMEditor','CRMManager','CRMPlep'],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      clientWidth: 0,
      ActivityTypes: [
        {
          ID: 1000001,
          Name: 'Task'
        },
        {
          ID: 1000002,
          Name: 'Appointment'
        },
      ],
      OwnerdeleteRoles: [],
        dragging: '',
        
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', this.handleResize)
  },
  created(){
      //console.log(this.ActivityType)
    this.RegardingTypefilter = this.ActivityType.countname
    //console.log(this.RegardingTypefilter,this.UserRecord)
    // this.ActivitiesArray = this.ActivityType.tasks
    // this.AllEvents = this.ActivityType.appointments
    // this.GetActivities()
    // this.GetBusinessUnits()
    this.UsersArray = this.UsersStore
    let ActivitiesRegardingQueries = ['ActivitiesRegardingSubordinateOwner','ActivitiesRegardingOwner','ActivitiesRegardingUnit','ActivitiesRegardingUnitStructure']
    ActivitiesRegardingQueries = ActivitiesRegardingQueries.filter(query => {
      return this.userLoggedIn[query]
    })
    //console.log('ActivitiesRegardingQueries',ActivitiesRegardingQueries)
    if(!this.RelatedObj && !this.TeamView){
      this.RelevantActivitiesArray = this.ActivitiesArray
    }
    else {
      if(!this.userIsEagleViewer && !this.userIsAdmin){
        if(ActivitiesRegardingQueries.length > 0){
          //console.log(this.userBUChildren,this.userBUID,this.userLoggedIn,ActivitiesRegardingQueries,this.userLoggedIn.Subordinatearrayquery)
          if(this.userLoggedIn.ActivitiesRegardingSubordinateOwner){
            this.userLoggedIn.Subordinatearrayquery.map(subord => {
          let query = db.collection('activities').where('Regarding_Record.Ownerid','==',subord)
          this.GetRelevantActivities(query,this.RelatedObj) 
            })
          }
          else if(this.userLoggedIn.ActivitiesRegardingOwner){
          let query = db.collection('activities').where('Regarding_Record.Ownerid','==',this.userLoggedIn.id)
          this.GetRelevantActivities(query,this.RelatedObj) 
          }
          else if(this.userLoggedIn.ActivitiesRegardingUnit){
          let query = db.collection('activities').where('Regarding_Record.Business_Unitid','==',this.userBUID)
          this.GetRelevantActivities(query,this.RelatedObj) 
          //what if regarding record does not match unit query, but activity is in fact owned by subordinate?
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
              let subordquery = db.collection('activities').where('Ownerid','==',subord)
              //requires index and update of rules to check if not match but subordinate match so pass on this, just double check double calling .where('Regarding_Record.Business_Unitid','!=',this.userBUID)
              this.GetRelevantActivities(subordquery,this.RelatedObj) 
            })
          }
          else if(this.userLoggedIn.ActivitiesRegardingUnitStructure){
            this.userBUChildren.map(buid => {
                let query = db.collection('activities').where('Regarding_Record.Business_Unitid','==',buid)
                this.GetRelevantActivities(query,this.RelatedObj) 
            })
          //again, what if regarding record does not match unit query, but activity is in fact owned by subordinate?
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
            this.userBUChildren.map(buid => {
                let query = db.collection('activities').where('Ownerid','==',subord)
              //requires index and update of rules to check if not match but subordinate match so pass on this, just double check double calling .where('Regarding_Record.Business_Unitid','!=',buid)
                this.GetRelevantActivities(query,this.RelatedObj) 
            })
            
            })
          }

        }
      else{
          this.userLoggedIn.Subordinatearrayquery.map(subord => {
          let query = db.collection('activities').where('Ownerid','==',subord)
          this.GetRelevantActivities(query,this.RelatedObj)         
        })
         let icreatequery = db.collection('activities').where('Created_Byid','==',this.userLoggedIn.id)
          this.GetRelevantActivities(icreatequery,this.RelatedObj)
        }
      }
      else{
          let query = db.collection('activities')
          this.GetRelevantActivities(query,this.RelatedObj)
        }
    }
    setTimeout(() => {
     this.handleResize() 
    }, 50);
    

  },
  computed: {
    ComputedActivitydueby(){
      let date = ''
      if(this.Activitydueby === 'Today'){
        let start = new Date()
        date = this.EndofDay(start)
      }
      else if(this.Activitydueby === 'This Week'){
        let start = new Date()
        date = this.EndofWeek(start,0)
      }
      else if(this.Activitydueby === 'Next Week'){
        let start = new Date()
        date = this.EndofWeek(start,7)
      }
      else if(this.Activitydueby === 'This Month'){
        let start = new Date()
        date = this.EndofMonth(start,7)
      }
      return date
    },
    TeamOwnersArray(){
      //let ActivitiesRegardingQueries = ['ActivitiesRegardingSubordinateOwner','ActivitiesRegardingOwner','ActivitiesRegardingUnit','ActivitiesRegardingUnitStructure']
      return this.UsersArray.filter(user => {
        if(this.userIsAdmin || this.userIsEagleViewer){
          return user
        }
        else if(this.userLoggedIn.ActivitiesRegardingSubordinateOwner){
          return this.userLoggedIn.Subordinatearrayquery.find(obj => obj === user.id)
        }
        else if(this.userLoggedIn.ActivitiesRegardingOwner){
          return user.id == this.userLoggedIn.id
        }
        else if(this.userLoggedIn.ActivitiesRegardingUnit){
          return user.Business_Unitid === this.userLoggedIn.Business_Unitid
        }
        else if(this.userLoggedIn.ActivitiesRegardingUnitStructure){
          return this.userBUChildren.find(obj => obj === user.Business_Unitid)
        }
        else{
          return this.userLoggedIn.Subordinatearrayquery.find(obj => obj === user.id)
        }
      })
    },
    userIsAdmin () {
      return this.$store.state.IsAdmin
    },
    userIsEagleViewer () {
      return this.$store.state.IsEagleView
    },
    dynamicFlex(){
      if(this.ActivitiesRowElementXS){
        return 'xs12'
      }
      else if(this.ActivitiesRowElementSM){
        return 'xs6'
      }
      else if(this.ActivitiesRowElementMD){
        return 'xs3'
      }
      else if(this.ActivitiesRowElementXS){
        return 'xs3'
      }
      
    },
    ActivitiesRowElementXS(){
      return this.clientWidth < 600
    },
    ActivitiesRowElementSM(){
      return this.clientWidth < 960 && this.clientWidth >= 600
    },
    ActivitiesRowElementMD(){
      return this.clientWidth < 1264 && this.clientWidth >= 960
    },
    ActivitiesRowElementLG(){
      return this.clientWidth >= 1264
    },
    AllowedDates(){
      return ['2021-10-04']
    },
    ComputedLabels(){
      return this.Labels.map(status => {
        if(status.Name === 'Assigned'){
          status.Status = this.ActivityStatusField.DefaultOption
          status.Status_Reason = this.ActivityStatusField.DefaultLevel2Option
        }
        else if(status.Name === 'In Progress'){
          status.Status = this.ActivityStatusField.DefaultOption
          status.Status_Reason = status.Status.Options[1]
        }
        else if(status.Name === 'On Hold'){
          status.Status = this.ActivityStatusField.DefaultOption
          status.Status_Reason = status.Status.Options[2]
        }
        else if(status.Name === 'Completed'){
          status.Status = this.ActivityStatusField.Options[1]
          status.Status_Reason = status.Status.Options[1]
        }
        return status
      })
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userBUID () {
      return this.$store.getters.userBUID
    },
    userBUChildren () {
      return this.$store.getters.userBUChildren
    },
    userBUParents () {
      return this.$store.getters.userBUParents
    },
    userBU () {
    return this.$store.state.buobj
    },
    ActivityStatusField(){
      //console.log('SystemEntities',this.SystemEntities)
      let activityentity = this.SystemEntities.find(obj => obj.id === 'Activities')
      //console.log('SystemEntities activityentity',activityentity)
      if(activityentity){
        return activityentity.AllFields.find(obj => obj.id === 'Status')
      }
    },
      WindowHeight(){
          return window.innerHeight
      },
      datetitle () {
        const { start, end } = this
        if (!start || !end) {
          return ''
        }

        const startMonth = this.monthFormatter(start)
        const endMonth = this.monthFormatter(end)
        const suffixMonth = startMonth === endMonth ? '' : endMonth

        const startYear = start.year
        const endYear = end.year
        const suffixYear = startYear === endYear ? '' : endYear

        const startDay = start.day + this.nth(start.day)
        const endDay = end.day + this.nth(end.day)

        switch (this.type) {
          case 'month':
            return `${startMonth} ${startYear}`
          case 'week':
          case '4day':
            return `${startMonth} ${startDay} ${startYear} - ${suffixMonth} ${endDay} ${suffixYear}`
          case 'day':
            return `${startMonth} ${startDay} ${startYear}`
        }
        return ''
      },
      monthFormatter () {
        return this.$refs.calendar.getFormatter({
          timeZone: 'UTC', month: 'long',
        })
      },
      AllRelevantEvents(){
        return this.RelatedObj || this.TeamView ? this.RelevantActivitiesArray.filter(act => {
          return act.Type && act.Type.ID === 1000002
        }) : this.AllEvents
      },
      AllEventsViewFormatted(){
      return this.AllRelevantEvents.map(event => {
        console.log(event)
          let eventobj = Object.assign({},event)
          if(!eventobj.start){
            eventobj.start = event.Start
          }
          if(!eventobj.end){
            eventobj.end = event.End 
          }          
             
        if(typeof eventobj.start.seconds !== 'undefined'){

        eventobj.start = this.TimestampFormatterSTRING(eventobj.start.toDate())
        }
        else{

        }
  
        
        if(typeof eventobj.end.seconds !== 'undefined'){
  
        eventobj.end = this.TimestampFormatterSTRING(eventobj.end.toDate())
        }
        else{
      
        }
        eventobj.name = event.Subject
        return eventobj
        
      })
    },
      UsersStore(){
          return this.$store.state.Users
      },
    ComputedStageNameOptions(){
  if(this.ProcessID){
    if(this.ProcessID === 1000001){
      return [
  {
    "Steps": [],
    "Number": 1000001,
    "IsHeader": true,
    "Automations": [],
    "Name": "Inbound",
    "Type": "Stage"
  },
  {
    "Automations": [],
    "Steps": [],
    "Type": "Stage",
    "Number": 1000002,
    "Name": "Stock",
    "IsHeader": true
  },
  {
    "Type": "Stage",
    "Steps": [],
    "Automations": [],
    "Name": "Outbound",
    "IsHeader": true,
    "Number": 1000003
  },
  {
    "Number": 1000004,
    "Automations": [],
    "Steps": [],
    "IsHeader": true,
    "Name": "Aftermarket",
    "Type": "Stage"
  }
]
    }
  }
},
    formTitle () {
        return this.editedIndex === -1 ? 'New Activity' : 'Edit Activity'
      },

      ActivitiesSearched() {
        //console.log('this.RelevantActivitiesArray',this.RelevantActivitiesArray)
      return this.RelevantActivitiesArray
      .map(item => {
        item.Notifications = this.UnreadNotifications.filter(not => {
              return not.Type.includes(item.Type.Name) && not.taskid === item.id
              || not.Type.includes(item.Type.Name) && not.appointmentid === item.id
          })
          if(item.Notifications.length > 0){
              item.New = true
          }
          else{
            item.New = false
          }
        return item
      })
      .filter(act => {
        if(act.Subject){
          return act.Subject.toLowerCase().includes(this.search.toLowerCase())
          }
          else if(act.Subject){
          return act.Subject.toLowerCase().includes(this.search.toLowerCase())
          }
          })
      },
      TasksFilteredandSearched(){
        return this.ActivitiesFilteredandSearched.filter(act => {
          return act.Type && act.Type.ID === 1000001
        }).filter(task => {
          if(this.ActivityOwnerFilter){
            return task.Ownerid === this.ActivityOwnerFilter.id
          }
          else{
            return task
          }
        })
        .filter(task => {          
          if(this.ComputedActivitydueby){
            return task.Due_Date.toDate() < this.ComputedActivitydueby
          }
          else{
            return task
          }
        })
        
      },
      ActivitiesFilteredandSearched() {
          //console.log('this.ActivitiesSearched,',this.ActivitiesSearched)
      return this.ActivitiesSearched.filter(act => {
            return act
                }).map(not => {
                    // console.log(not)
                    if(not.Due_Date){
                        not.stringduedate = this.TimestampFormatterSTRINGDayText(not.Due_Date.toDate())
                        not.monthcreated = this.TimestampFormatterNoticeBoard(not.Due_Date.toDate(),'month')
                        not.daycreated = this.TimestampFormatterNoticeBoard(not.Due_Date.toDate(),'date')
                        let today = new Date()
                        if(not.Due_Date.toDate()<today && not.Status_Reason.Name !== 'Completed' && not.Status_Reason.Name !== 'Cancelled'){
                          not.overdue = true
                        }
                        
                    }
                    if(not.Created_On){
                        not.stringcreatedon = this.TimestampFormatterSTRINGDayText(not.Created_On.toDate())
                    }
                        if(not.Type === 'Task'){
                          not.typeicon = 'mdi-check-box-outline'
                        }
                        return not
                      })
      },

      HeaderNames() {
        return this.headers.map(header => {
          return header.text
        }).filter(header => {
          return header !== 'id'
        })
      },
      HeaderNamesSplit() {
        return this.HeaderNames.map(header => {
         let correctheader = header.split(' ').join('').toLowerCase()
          return correctheader
        })
      }
    },
  methods: {
    ToggleNewTaskDialog(){
      if(this.RelatedObj){
          this.NewTask.Regarding_Type = this.CurrentEntity.SingleName.split(' ').join('')
          this.NewTask.Regarding_Record = this.RelatedObj
          this.NewTask.Regarding_Topic = this.CurrentEntity.id
          this.NewTask.Type = this.ActivityTypes.find(obj => obj.Name === 'Task')
          if(this.Record.Business_Unit){
            this.NewTask.Regarding_Record.Business_Unitid = this.Record.Business_Unit.id
          }
          if(this.Record.Owner){
            this.NewTask.Regarding_Record.Ownerid = this.Record.Owner.id
          }
      }
      this.NewTaskDialog = !this.NewTaskDialog
    },
    CreateNewTask(){
      const TasktoAdd = {
            Comments: [],
            Subject: this.NewTask.Subject,
            Description: this.NewTask.Description,
            Type: this.NewTask.Type,
            Due_Date: new Date(this.NewTask.Due_Date),
            Owner: {id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},
            Ownerid: this.NewTask.Owner.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Created_On: new Date(),
            Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Modified_Byid: this.userLoggedIn.id,
            Modified_On: new Date(),
            Status: this.ActivityStatusField.DefaultOption,
            Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
            FollowingUsers: [{id: this.NewTask.Owner.id, Name: this.NewTask.Owner.Name, Surname: this.NewTask.Owner.Surname, Full_Name: this.NewTask.Owner.Full_Name},{id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name}]
      }
      if(this.NewTask.Regarding_Type){
        TasktoAdd.Regarding_Type = this.NewTask.Regarding_Type
      }
      if(this.NewTask.Regarding_Topic){
        TasktoAdd.Regarding_Topic = this.NewTask.Regarding_Topic
      }
      if(this.NewTask.Regarding_Record){
        TasktoAdd.Regarding_Record = this.NewTask.Regarding_Record
      }
      let FollowingUserIDStrings = []
      let FollowingUserIDKeys = {}
       TasktoAdd.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       TasktoAdd.FollowingUserIDKeys = FollowingUserIDKeys
       TasktoAdd.FollowingUserIDStrings = FollowingUserIDStrings
      if(this.NewTask.QuickLink){
        TasktoAdd.QuickLink = this.NewTask.QuickLink
      }
      if(this.NewTask.Has_Reminder){
        TasktoAdd.Has_Reminder = this.NewTask.Has_Reminder
      }
      if(this.NewTask.Snoozed_Until){
        TasktoAdd.Snoozed_At = new Date()
        TasktoAdd.Snoozed_Until = new Date(this.NewTask.Snoozed_Until)
      }
      if(typeof this.NewTask.Owner.Business_Unit !== 'undefined'){
        TasktoAdd.Business_Unitid = this.NewTask.Owner.Business_Unit.id
        TasktoAdd.Business_Unit = {Name: this.NewTask.Owner.Business_Unit.Name, id: this.NewTask.Owner.Business_Unit.id}
      }
      db.collection('activities').add(TasktoAdd).then(doc => {
        console.log(TasktoAdd)

             if(TasktoAdd.Type.Name === 'Task' && TasktoAdd.Ownerid !== TasktoAdd.Created_Byid){
            const NewNotification = {
              taskid: doc.id,
              Type: 'Task Created',
              Header: 'New Task -'+TasktoAdd.Subject,
              Content: TasktoAdd.Description,
              CreatorFullName: TasktoAdd.Created_By.Full_Name,
              CreatorID: TasktoAdd.Created_Byid,
              Owner: TasktoAdd.Owner,
              Ownerid: TasktoAdd.Ownerid,
              Read: false,
              Path: '/Activity/'+doc.id,
              Message: 'a New Task has been assigned to you by '+TasktoAdd.Created_By.Full_Name
            }
            if(TasktoAdd.Regarding_Topic){
              NewNotification.topicid = TasktoAdd.Regarding_Topic
            }
            if(TasktoAdd.Regarding_Record){
              NewNotification.clientrecords = TasktoAdd.Regarding_Record.id
            }
            //console.log(TasktoAdd)
            db.collection('notifications').add(NewNotification).then(notedoc => {
              console.log('notedoc',notedoc)
            })
             }


          this.CloseTaskDialog()
          })
    },
    CloseTaskDialog(){
      this.NewTask = Object.assign({}, this.DefaultNewTask)
      this.NewTaskDialog = false
    },
    EndofDay(date){			
      var day = date.getDate()			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month, day)			
      d.setHours(23,59,59,999)			
      return d			
    },
    EndofWeek(date,addeddays){			     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();		
      lastdaylastweek = lastdaylastweek+addeddays
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },	
    EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },
    UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
        },
    GetRelevantActivities(query,RelatedObj){
      //console.log(query,RelatedObj)
      let finalquery = query
      if(RelatedObj){
        finalquery = finalquery.where('Regarding_Record.id','==',RelatedObj.id)
      }
      finalquery.onSnapshot(res => {
        const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.RelevantActivitiesArray.find(obj => obj.id === actobj.id)
          if(!oncheck){
            this.RelevantActivitiesArray.push(actobj)
          }
        }
        if(change.type === 'modified'){
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let item = this.RelevantActivitiesArray.find(obj => obj.id === actobj.id)
          if(item){
            let index = this.RelevantActivitiesArray.indexOf(item)
            this.RelevantActivitiesArray.splice(index,1,actobj)
            if(this.dragging && this.dragging.id === item.id){
              this.ReadingTaskDialog = false
              this.dragging = actobj
              setTimeout(() => {
                this.ReadingTaskDialog = true
              }, 1);
            }
          }
        }
        //console.log('this.RelevantActivitiesArray',this.RelevantActivitiesArray,this.RelatedObj)

      })
      })
    },
    handleResize (event) {
      if (this.$refs.myDiv) {
        this.clientWidth = this.$refs.myDiv.clientWidth
      }
    },

    CompletedTaskProcess(dragging){       
        let status = this.ActivityStatusField.Options[1]
        let statusreason = status.Options[1]
        //console.log('CompletedTaskProcess',dragging,status,statusreason)
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          Completed_On: new Date(),
          State_Modified_Date: new Date()
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,            
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: 'Task Completed -'+dragging.Subject,
            Type: 'Task Completed',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "Completed"',
          }
          this.ActivityNotification(dragging,notification)
        }
        this.StatusDialmenu = false
      },
      OnHoldTaskProcess(dragging){
        let now = new Date()   
        let status = this.ActivityStatusField.Options[0]
        let statusreason = status.Options[2]
        let nowminutes = now.getMinutes()
        let snoozetime = now.setMinutes(nowminutes+90)
        //console.log('OnHoldTaskProcess',dragging,status,statusreason,nowminutes,snoozetime)
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          State_Modified_Date: new Date(),
          Snoozed_At: new Date(),
          Snoozed_Until: new Date(snoozetime)
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: 'Task On Hold -'+dragging.Subject,
            Type: 'Task On Hold',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "On Hold"',
          }
          this.ActivityNotification(dragging,notification)
        }
        this.StatusDialmenu = false
      },
      OnItTaskProcess(dragging){     
        let now = new Date()   
        let status = this.ActivityStatusField.Options[0]
        let statusreason = status.Options[1]
        let nowminutes = now.getMinutes()
        let snoozetime = now.setMinutes(nowminutes+90)
         //console.log('OnItTaskProcess',dragging,status,statusreason,nowminutes,snoozetime)
        db.collection('activities').doc(dragging.id).update({
          Status: status,
          Status_Reason: statusreason,
          State_Modified_Date: new Date(),
          Snoozed_At: new Date(),
          Snoozed_Until: new Date(snoozetime)
        })
        if(this.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: 'Task In Progress -'+dragging.Subject,
            Type: 'Task In Progress',
            Message: this.userLoggedIn.Full_Name+' has confirmed the task '+dragging.Subject+' as "In Progress"',
          }
          this.ActivityNotification(dragging,notification)
        }
        this.StatusDialmenu = false
      },
    CancelTask(dragging){
      let vm = this
      let status = vm.ActivityStatusField.Options[1]
      let statusreason = status.Options[0]
      // console.log(status,statusreason)
      db.collection('activities').doc(dragging.id).update({
        Status: status,
        Status_Reason: statusreason,
        Cancel_Date: new Date()
      }).then(doc => {
       dragging.Status = status
       dragging.Status_Reason = statusreason
        if(vm.userLoggedIn.id !== dragging.Created_Byid){
          let notification = {
            Owner: dragging.Created_By,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: vm.userLoggedIn.id, Name: vm.userLoggedIn.Name, Surname: vm.userLoggedIn.Surname, Full_Name: vm.userLoggedIn.Full_Name},
            Created_Byid: vm.userLoggedIn.id,
            Header: 'Task Cancelled -'+dragging.Subject,
            Type: 'Task Cancelled',
            Message: vm.userLoggedIn.Full_Name+' has Cancelled the task '+dragging.Subject,
          }
          vm.ActivityNotification(dragging,notification)
        }
      })
    },
    UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
    },
    UpdateTaskDescription(prop,value,AdditionalSaveMethod,FieldObject){
      let OldDescription = FieldObject[prop]
      FieldObject[prop] = value
      db.collection('activities').doc(FieldObject.id).update({
        [prop]: value
      })
      this.EditingDescription = false
      if(this.userLoggedIn.id !== FieldObject.Created_Byid){
          let notification = {
            Owner: FieldObject.Created_By,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: 'Description change -'+FieldObject.Subject,
            Type: 'Task Modified',
            Message: this.userLoggedIn.Full_Name+' has updated the Description of '+FieldObject.Subject,
            MessageHTML: `<p>`+this.userLoggedIn.Full_Name+' has updated the Description of '+FieldObject.Subject+`</p><p>`+OldDescription+`</p><p>`+value+`</p>`,
          }
          this.ActivityNotification(FieldObject,notification)
        }
        else if(this.userLoggedIn.id !== FieldObject.Owner){
          let notification = {
            Owner: FieldObject.Owner,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Description change -'+FieldObject.Subject,
            Type: 'Task Modified',
            Message: this.userLoggedIn.Full_Name+' has updated the Description of '+FieldObject.Subject,
            MessageHTML: `<p>`+this.userLoggedIn.Full_Name+' has updated the Description of '+FieldObject.Subject+`</p><p>`+OldDescription+`</p><p>`+value+`</p>`,
          }
          this.ActivityNotification(FieldObject,notification)
        }
    },
    disablePastDates(val) {
       return val >= new Date().toISOString().substr(0, 10)
    },
    ActivityNotification(item,notification,fromcomments){
      if(!fromcomments){
       const NewNotification = {
              relatedid: item.id,
              Type: notification.Type,
              Header: notification.Header,
              Content: item.Description,
              CreatorFullName: item.Created_By.Full_Name,
              CreatorID: item.Created_Byid,
              Owner: item.Owner,
              Ownerid: item.Ownerid,
              Read: false,
              Path: '/Activities/',
              Message: item.Message,
              Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then(doc => {
              //console.log(doc.id,doc)
            }) 
      }
      else{
        const NewNotification = {
              relatedid: item.id,
              Type: notification.Type,
              Header: notification.Header,
              Content: notification.Message,
              CreatorFullName: notification.Created_By.Full_Name,
              CreatorID: notification.Created_Byid,
              Owner: notification.Owner,
              Ownerid: notification.Ownerid,
              Read: false,
              Path: '/Activity/'+item.id,
              Message: notification.Message,
              Date: new Date()
            }
            db.collection('notifications').add(NewNotification).then(doc => {
              //console.log(doc.id,doc)
            })
      }
      
    },
      AssignNewDueDate(item){
        let OldDue_Date = item.Due_Date.toDate()
        let NewDue_Date = new Date(this.NewDueDate)
        db.collection('activities').doc(item.id).update({
          Due_Date: NewDue_Date
        })
        this.NewDueDate = ''
        if(item.Created_Byid !== item.Ownerid){
          let notification = {
            Owner: item.Created_By,          
            Ownerid: dragging.Created_By.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Header: 'Due Date change -'+item.Subject,
            Type: 'Task Modified',
            Message: this.userLoggedIn.Full_Name+' has updated the Due Date of '+item.Subject+' from '+OldDue_Date+' to '+NewDue_Date
          }
          this.ActivityNotification(item,notification)
        }
      },
      ActivateTask(task,status){
          this.dragging = task
          this.draggingstatus = status
          this.ReadingTaskDialog = true
          if(task.Notifications){
            task.Notifications.map(not => {
              db.collection('notifications').doc(not.id).update({
                Read: true
              })
            })
          }
          
      },
      CancelReadingTaskDialog(){          
          this.ReadingTaskDialog = false
          this.dragging = ''
          this.draggingstatus = ''
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
      DeleteEvent(){
          confirm('Are you sure? This will completely remove the appointment') && this.ProcessDeleteEvent()
      },
      ProcessDeleteEvent(){
          let index = this.AllRelevantEvents.find(obj => obj.id === this.selectedEvent)
          db.collection('activities').doc(this.selectedEvent.id).delete().then(doc => {
              this.$emit('RemoveActivity',this.selectedEvent,'Event')
          })
      },
      CheckAllowedMinutes(){
       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
      AddNewEvent(){
        //console.log('this.AllJavaFormatEvents right before we push a new one')
        //console.log(this.AllJavaFormatEvents)
        //console.log(this.EventStartTime)
        let Regarding_Record = {}
        let NEWeditorContent = document.getElementById('previewsendemail')
        this.EventDescription = NEWeditorContent.innerHTML;
        //console.log(this.EventDescription)
        let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
        let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
         let NewJavaStartDate = new Date(NewStartDateString)
        let NewJavaEndDate = new Date(NewEndDateString)
        if(typeof this.$route.params.id !== 'undefined'){
        Regarding_Record = {
              Link:'/'+this.RelatedObj.Link+'/'+this.$route.params.id, Name:[this.RelatedObj.identifier], [this.RelatedObj.identifier]: this.RelatedObj[this.RelatedObj.identifier],id: this.$route.params.id
            }
            //console.log(Regarding_Record)
        }
        let NewEvent = {
            Owner: {Full_Name: this.AppointmentOwner.Full_Name,Name: this.AppointmentOwner.Name,Surname: this.AppointmentOwner.Surname,id: this.AppointmentOwner.id},
            Ownerid: this.AppointmentOwner.id,
            Attendees: this.Attendees,
       
          Subject: this.EventSubject,
          Description: this.EventDescription,
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          color: 'pop',
          Type: this.ActivityTypes[1],
          Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Created_Byid: this.userLoggedIn.id,
          Created_On: new Date(),
          Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
          Modified_Byid: this.userLoggedIn.id,
          Modified_On: new Date(),
          Status: this.ActivityStatusField.DefaultOption,
          Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
        AllDayEvent: this.AllDayEvent
        
        }
        if(typeof this.$route.params.id !== 'undefined'){
        NewEvent.Regarding_Record = Regarding_Record
        NewEvent.Regarding_Type = this.RelatedObj.Link
        }
        if(this.UserRecord.id === this.AppointmentOwner.id){
            NewEvent.FollowingUsers = [{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        else{
            NewEvent.FollowingUsers = [{id: this.AppointmentOwner.id, Name: this.AppointmentOwner.Name, Surname: this.AppointmentOwner.Surname, Full_Name: this.AppointmentOwner.Full_Name},{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
        }
        if(this.Attendees.length > 0){
          NewEvent.AttendeeUserIDStrings = []
          this.Attendees.map(attendee => {
            let userobj = this.UsersArray.find(obj => obj.Email === attendee)
            if(userobj){
              let attendeeobj = {id: userobj.id, Name: userobj.Name, Surname: userobj.Surname, Full_Name: userobj.Full_Name}
              NewEvent.AttendeeUserIDStrings.push(attendeeobj.id)
              let followingobj = NewEvent.FollowingUsers.find(obj => obj.id === attendeeobj.id)
              if(!followingobj){
                NewEvent.FollowingUsers.push(attendeeobj)
              }
            }
          })
        }
         if(this.Business_Unit && typeof this.Business_Unit.id !== 'undefined'){
        NewEvent.Business_Unitid = this.Business_Unit.id
      }
      let FollowingUserIDStrings = []
       let FollowingUserIDKeys = {}
       NewEvent.FollowingUsers.map(follower => {
         FollowingUserIDKeys[follower.id] = true
          FollowingUserIDStrings.push(follower.id)
       })
       NewEvent.FollowingUserIDKeys = FollowingUserIDKeys
       NewEvent.FollowingUserIDStrings = FollowingUserIDStrings

       
        console.log(NewJavaStartDate)
        console.log(NewJavaEndDate)
        let ref = db.collection('activities').doc()
        let newdocid = ref.id
        console.log(newdocid)
        db.collection('activities').doc(newdocid).set(NewEvent).then(res => {

        })
        this.AllDayEvent = false
        this.EventSubject = ''
        this.EventDescription = ''
        this.EventStartDate = ''
        this.EventStartTime = ''
        this.EventEndDate = ''
        this.EventEndTime = ''
        this.NewEventDialog = false        
      },
     
      nth (d) {
        return d > 3 && d < 21
          ? 'th'
          : ['th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th'][d % 10]
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = this.today
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {
        const open = () => {
          this.selectedEvent = event
          this.selectedElement = nativeEvent.target
          console.log(this.selectedEvent)
          setTimeout(() => this.selectedOpen = true, 10)
        }

        if (this.selectedOpen) {
          this.selectedOpen = false
          setTimeout(open, 10)
        } else {
          open()
        }

        nativeEvent.stopPropagation()
      },
      ActivateNewEvent(){
        this.Attendees = []
        this.Attendees.push(this.UserRecord.Email)
        this.NewEventDialog = true
      },
      AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
    StatusBGColor(status){
      if(status.Name === 'Assigned'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'In Progress'){
        return 'blue-grey lighten-4 mx-3'
      }
      else if(status.Name === 'On Hold'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'Completed'){
        return 'blue-grey lighten-4 mx-3'
      }
      
    },
    StatusColor(status){
      if(status.Name === 'Assigned'){
        return 'orange white--text'
      }
      else if(status.Name === 'In Progress'){
        return 'blue white--text'
      }
      else if(status.Name === 'On Hold'){
        return 'red white--text'
      }
      else if(status.Name === 'Completed'){
        return 'green white--text'
      }
      
    },
    taskdragstart(task){
        this.dragging = task
        console.log(this.dragging)
    },
    taskdragFinish(status){
        
        if(status.Name === 'Completed'){
            confirm('This will update the item to Completed, and remove it form the outstanding tasks. Are you sure?')
            && this.UpdateTaskStatus(this.dragging,status)
        }
        else{
            this.UpdateTaskStatus(this.dragging,status)
          
        }
        
        console.log(this.dragging)
    },
    UpdateTaskStatus(task,status){
        task.Status = status.Status
        task.Status_Reason = status.Status_Reason
        //console.log('task',task)
        if(status.Status_Reason === 'Completed'){
            db.collection('activities').doc(task.id).update({
            Status: status.Status,
            Status_Reason: status.Status_Reason,
            Completed_On: new Date()
            })
        }
        else{
          db.collection('activities').doc(task.id).update({
            Status: status.Status,
            Status_Reason: status.Status_Reason,
            })
        }
        
            this.dragging = ''
    },
    Match(status){    
      
            return this.TasksFilteredandSearched.filter(task => {   
                   //console.log('task.Status_Reason,status.Status_Reason',task.Status_Reason,status.Status_Reason)   
                return task.Status_Reason.ID === status.Status_Reason.ID      
            }).map(item => {
                if(!item.Comments){
                    item.Comments = []
                }
                if(typeof item.reveal === 'undefined'){
                    item.reveal = false
                }
                return item
            })    
        },  
    DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
      
     GetUsers(){
      db.collection('users').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.UsersArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }


      })
    })
    },

    
    ResetFilters() {
      this.ActivityTypefilter = ''
      this.RegardingTypefilter = ''
      this.ActivityOwnerFilter = ''
    },
    ResetSearch() {
      this.search = ''
    },

    GetActivities() {
      db.collection('activities').where('Ownerid','==',this.UserRecord.id).get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ActivitiesArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
        console.log(this.ActivitiesArray)


      })
    })
  },  

    SaveActivityStatus(item){
      //TBD
      db.collection('activities').doc(item.id).update({
        Status: item.Status,
        Status_Reason: item.Status_Reason
      })

    },



      PostComment(NewComment,item) {
      if(typeof item.Comments === 'undefined'){
        item.Comments = []
      }
      item.Comments.push(NewComment)
      this.SaveCommentsandReplies(item)
    },

    ReplytoComment(item,comment){
      console.log('initiating reply')
      console.log('item')
      console.log(item)
      console.log('comment')
      console.log(comment)
      comment.Replying = true
    },

    PostReplytoComment(item,comment,commentindex,ReplyMenu,ReplytoReplyMenu){
      console.log('posting reply')
      console.log(comment)
      let NewReply = {Content: comment.ReplyingContent, CreatorFullName: this.UserRecord.Full_Name, Date: format(new Date(),'yyyy-MM-dd'), CreatorId: this.UserRecord.id}
      let itemindex = this.ActivitiesArray.indexOf(item)
      item.Comments[commentindex].Replies.push(NewReply)
      comment.ReplyingContent = ''
      item.Comments[commentindex].ReplyMenu = false
      comment.ReplyMenu = false
      ReplyMenu = false
      ReplytoReplyMenu = false


      // this.ActivitiesArray[itemindex] = item
      this.SaveCommentsandReplies(item)
    },

    SaveCommentsandReplies(item){
      console.log(item)
      db.collection('activities').doc(item.id).update({
        Comments: item.Comments
      })
      item.FollowingUsers.map(user => {
        if(user.id !== this.userLoggedIn.id){
         let notification = {
            Owner: user,
            Ownerid: user.id,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Header: this.userLoggedIn.Full_Name+' commented on -'+item.Subject,
            Type: 'Task Comment',
            Message: this.userLoggedIn.Full_Name+' has commented on the Task '+item.Subject,
            MessageHTML: this.userLoggedIn.Full_Name+' has commented on the Task '+item.Subject,
          }
          console.log(notification)
          this.ActivityNotification(item,notification,true) 
        }
        
      })
      

    },


      deleteItem (item) {
        const index = this.ActivitiesArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('activities').doc(item.id).delete() && this.ActivitiesArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.ActivitiesArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },


      save () {
      const EditedActivity = {
    Subject: this.editedItem.Subject,
    Description: this.editedItem.Description,
    Regarding_Type: this.editedItem.Regarding_Type,
    Type: this.editedItem.Type,
    Created_On: new Date(),
    Due_Date: this.DateFormatter(this.editedItem.Due_Date),
    Owner: {id: this.editedItem.Owner.id, Name: this.editedItem.Owner.Name, Surname: this.editedItem.Owner.Surname, fullname: this.editedItem.Owner.Full_Name},
    Ownerid: this.editedItem.Owner.id,
    Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
    Created_Byid: this.userLoggedIn.id,
    Created_On: new Date(),
    Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
    Modified_Byid: this.userLoggedIn.id,
    Modified_On: new Date(),
    Status: this.ActivityStatusField.DefaultOption,
    Status_Reason: this.ActivityStatusField.DefaultLevel2Option,

        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('activities').doc(this.mainrecordid).update({
        Subject: EditedActivity.Subject,
        Description: EditedActivity.Description,
        Regarding_Type: EditedActivity.Regarding_Type,
        Type: editedItem.Type,
        Created_On: EditedActivity.Created_On,
        Due_Date: EditedActivity.Due_Date,
        Owner: EditedActivity.Owner,
        Ownerid: EditedActivity.Ownerid,
        Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Created_Byid: this.userLoggedIn.id,
        Created_On: new Date(),
        Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
        Modified_Byid: this.userLoggedIn.id,
        Modified_On: new Date(),

              });
              Object.assign(this.ActivitiesArray[this.editedIndex], this.editedItem)
              this.closeActivityeditDialog
              this.EditedActivitysnackbar = true
        }
         else {
          const NewActivity = {
            Subject: EditedActivity.Subject,
            Description: EditedActivity.Description,
            Regarding_Type: EditedActivity.Regarding_Type,
            Type: EditedActivity.Type,
            Created_On: EditedActivity.Created_On,
            Due_Date: EditedActivity.Due_Date,
            Owner: EditedActivity.Owner,
            Ownerid: EditedActivity.Ownerid,
            Created_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Created_Byid: this.userLoggedIn.id,
            Created_On: new Date(),
            Modified_By: {id: this.userLoggedIn.id, Name: this.userLoggedIn.Name, Surname: this.userLoggedIn.Surname, Full_Name: this.userLoggedIn.Full_Name},
            Modified_Byid: this.userLoggedIn.id,
            Modified_On: new Date(),
            Status: this.ActivityStatusField.DefaultOption,
            Status_Reason: this.ActivityStatusField.DefaultLevel2Option,
            FollowingUsers: [EditedActivity.Owner,{id: this.UserRecord.id, Name: this.UserRecord.Name, Surname: this.UserRecord.Surname, Full_Name: this.UserRecord.Full_Name}]
              }


          db.collection('activities').add(NewActivity).then(doc => {
            if(NewActivity.Type.Name === 'Task'){
              let notification = {
              Owner: NewActivity.Owner,          
              Ownerid: dragging.Created_By.id,
              Created_By: NewActivity.Created_By,
              Created_Byid: NewActivity.Created_By.id,
              Header: 'New Task -'+NewActivity.Subject,
              Type: 'Task Modified',
              Message: 'a New Task has been assigned to you by '+NewActivity.Created_By.Full_Name
            }
            this.ActivityNotification(item,notification)
          }
          })
        }

        this.close()
      },

  }
}

</script>
<style>
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;

}
.replybasicoutlined{

  border: 0.1px solid #dedee9;

}
.outlinefont{
  color: red
}
.noticeboarddate{
font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
}
.noticeoutlined{
   border-style: solid;
    border-color: rgb(207, 205, 205);
   border-width: thin;
}
.outlinedavatar{
  border:1px double red;

  background-color: #2196F3;
}
</style>