<template>
  <div>
      <v-card  style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);"
                class="mx-auto"
                height="100%"
                width="100%">
            
            <v-card-title :class="MiniView ? 'pink--text caption' : 'pink--text mediumoverline'">
                Validator - {{RelevantTab.Elements.length}} Checks Done
                </v-card-title>
                    
                    <v-layout fill-height row justify-center align-center>
                    <div class="BoldBuilderGradient" :style="ReadinessStyle">
                    </div>
                        <v-img :height="MiniView ? 50 : 100" style="position: absolute;opacity: 0.3;" contain  src="@/assets/RAFullLogo.png">
                        </v-img>
                <v-progress-circular style="animation: simplepulsing 1.5s infinite;"
                        :value="RelevantTab.Progress" :size="MiniView ? 90 : 180" :width="MiniView ? 10 : 20" class="largeoverline" :color="TabReadinessProgressColor(RelevantTab)">
                        <v-card-title :class="MiniView ? TabReadinessTitleColor(RelevantTab)+'--text subtleoverline'
                        : TabReadinessTitleColor(RelevantTab)+'--text mediumoverline'" style="text-shadow: 4px 4px rgba(0,0,0,0.16);position: absolute;margin-top:-80px;animation: simplepulsing 1.5s infinite;animation-delay: 0.15s;font-weight:500;">{{TabReadiness(RelevantTab)}}</v-card-title>
                    
                    <v-icon :color="TabReadinessTitleColor(RelevantTab)" :size="MiniView? 50 : 100" :style="MiniView? 'text-shadow: 4px 4px rgba(0,0,0,0.16);margin-top:10px;animation: simplepulsing 1.5s infinite;' :
                    'text-shadow: 4px 4px rgba(0,0,0,0.16);margin-top:40px;animation: simplepulsing 1.5s infinite;'">
                        {{TabReadinessIcon(RelevantTab)}}
                    </v-icon>
                    </v-progress-circular>
                    </v-layout>
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel class="my-1" :style="GetElmntReadiness(elmnt)" v-for="elmnt in RelevantTab.Elements" :key="elmnt.itemObjKey">
                                <v-expansion-panel-header>
                                    {{elmnt.Title}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-card-text v-html="elmnt.Brief">
                                    </v-card-text>
                                    <InfoSheet :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"									
                                        :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"									
                                        :tab="elmnt"									
                                        />
                                        
                                        <v-card-actions>
                                            <v-btn v-if="elmnt.ActionName" color="purple" outlined @click="InvokeConfigureAction(elmnt)" small>
                                            {{elmnt.ActionName}}
                                        </v-btn>
                                                <v-btn outlined :to="elmnt.DocumentationRoute"  small>
                                            Read More
                                        </v-btn>
                                        </v-card-actions>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn dark color=green>Go</v-btn>
                    </v-card-actions>
                
            </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import InfoSheet from '@/components/WebPages/RenderComponents/InfoSheetReadOnly';
export default {
    props: ['System','SystemEntities','RelevantTab'],
    components: {InfoSheet},
    data() {
        return {

        }
    },	
    computed:{
        ReadinessStyle(){
            return this.MiniView ? 'position: absolute;width: 75px;height: 75px;border-radius:50px;animation: spin 4   s linear infinite;' :
            'position: absolute;width: 150px;height: 150px;border-radius:100px;animation: spin 4   s linear infinite;'
        },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){

    },
    methods:{
        InvokeConfigureAction(elmnt){
            this.$emit('InvokeConfigureAction',elmnt)
        },
        GetElmntReadiness(tab){
            if(tab.Progress === 100){
                return 'border-left: 6px solid green;'
            }
             else if(tab.Progress >= 50){
                return 'border-left: 6px solid orange;'
            }
            else{
                return 'border-left: 6px solid red;'
            }
        },
        TabReadinessIcon(tab){
            if(tab.Progress === 100){
                return 'mdi-cloud-check'
            }
            else if(tab.Progress >= 50){
                return 'mdi-alert'
            }
            else{
                return 'mdi-alert'
            }
        },
        TabReadinessTitleColor(tab){
            if(tab.Progress === 100){
                return 'green'
            }
            else if(tab.Progress >= 50){
                return 'pink'
            }
            else{
                return 'red'
            }
        },
        TabReadinessProgressColor(tab){
            if(tab.Progress === 100){
                return 'blue lighten-4'
            }
            else if(tab.Progress >= 50){
                return 'warning'
            }
            else{
                return 'red'
            }
        },
        TabReadiness(tab){
            if(tab.Progress === 100){
                return 'Ready'
            }
            else{
                return 'Incomplete'
            }
        },
    }
}
</script>

<style>

</style>



