
  <template>
  <!--  :style="cssProps" -->
  <v-app :class="AppStylingView && !PublicPreview ? 'focusedviewborder grey lighten-4' : 'grey lighten-4'" :style="AppStyling" :dark="AppisDarkMode">
    <div v-if="System.User_Location_Enabled" id="map"></div>
  <v-dialog max-width="800" v-model="UserInfoRequiredDialog" persistent>
      <v-card>
        <v-card-title>Required Information</v-card-title>
        <v-card-text>
          <v-form id="infoform">
              <v-list>
                <v-list-item v-for="info in UserRequiredInfo" :key="info.itemObjKey">
                  <v-text-field :rules="[rules.required]" :label="info" v-model="UserObj[info]" />
                </v-list-item>
              </v-list>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <!-- <v-btn>Cancel</v-btn> -->
          <v-spacer></v-spacer>
          <v-btn @click="UpdateRequiredUserInformation()" dark class="green">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout  column style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" id="chatbtn" v-if="AppBuilderView">
    <v-btn small fab @click="ExitAppBuilder()" dark color="blue">
      <v-icon>mdi-exit-to-app</v-icon>
    </v-btn>
  </v-layout>
  <!-- <v-layout  column style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" id="chatbtn" v-if="userIsAdmin && RouterReady">
    <v-btn small fab @click="ToggleAppQuickStartDialog()" dark color="purple">
      <v-icon>mdi-help</v-icon>
    </v-btn>
  </v-layout> -->
    <v-layout style="position: fixed;
  bottom: 50px;
  right: 10px;
  z-index: 50" column id="chatbtn" v-if="ShowChatbot">
    <v-btn small fab @click="ToggleChatbotActive()" dark color="green">
      <v-icon>mdi-robot</v-icon>
    </v-btn>
  </v-layout>
  <v-layout  column class="order-container-btn" v-if="RouterReady && StoreView && $route.name === 'StoreItemSingle'">
    <v-btn fab @click="SetViewOrderNavbar()" dark color="links">
      <v-icon>mdi-cart</v-icon>
    </v-btn>
  </v-layout>
  <BuilderViewAppBar v-if="AppBuilderView && !SuiteAppView || AppQuickStartDialog" :SitePages="SitePages" :SystemDocumentLibraries="SystemDocumentLibraries"
  :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RADB="RADB" :RAApp="RAApp" :SiteModerators="SiteModerators"
    :DefaultThemes="DefaultThemes" @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" @ToggleAppDarkMode="ToggleAppDarkMode"
   @IntranetViewToggle="IntranetViewToggle"
  />
  <SuiteAppNavigator v-if="SuiteAppView && !RecordChatView" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :GraphicalElements="GraphicalElements" :RADB="RADB" :RAApp="RAApp"
    :DefaultThemes="DefaultThemes" @SetColorstoSystemColors="SetColorstoSystemColors" :GradientElements="GradientElements" :DocumentationLibraries="DocumentationLibraries"
    @IntranetViewToggle="IntranetViewToggle" @ActivateSearch="ActivateSearch" @DeactivateSearch="DeactivateSearch" :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
   :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities" :GroupEntities="GroupEntities" @ToggleAppDarkMode="ToggleAppDarkMode" :UserDBNavList="UserDBNavList"
    :UnreadNotifications="UnreadNotifications" :ActiveSearch="ActiveSearch"
  />
   <RecordChatRoom v-if="RecordChatView" :RecordData="ActiveChatRecord" :ActiveSession="CurrentEntity" :LiaisonDialogActive="true"
      :OperationalDB="ActiveChatRecordOperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification" :BackView="true"
      @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog" :FullonCreate="true" @GoBack="DeactivateActiveChatRecord()"
      :System="System" :SystemEntities="SystemEntities" :CanEdit="ActiveChatRecord.CanEdit" :ActiveChatRecord="ActiveChatRecord.ActiveProcess"
      />
  <GroupInteractionComponent v-if="IsGroupView" :System="System" :SystemEntities="SystemEntities" :SocialEntities="SocialEntities"
  />
    <ChatBot :ActiveSession="ActiveSession" @ToggleChatbotActive="ToggleChatbotActive" v-if="ChatbotActive" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName"  @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" />    
    <AppStylingComponent :ActiveNavbar="ActiveNavbar" :CategoricalNavlists="ComputedCategoricalNavlists" v-if="AppStylingView" :System="System" @RefreshRouter="RefreshRouter" @ToggleAppDarkMode="ToggleAppDarkMode" :DefaultThemes="DefaultThemes"
    @IntranetViewToggle="IntranetViewToggle" @TogglePublicPreview="TogglePublicPreview" :PublicPreview="PublicPreview" :GraphicalElements="GraphicalElements" :SystemPhotoLibraries="SystemPhotoLibraries"
    :FontOptions="FontOptions" :GradientElements="GradientElements" :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
    :SystemEntities="SystemEntities"
    @SetColorstoSystemColors="SetColorstoSystemColors" @ToggleCatNavlistView="ToggleCatNavlistView"/>
    <GroupNavbar v-if="GroupNavView" :GroupNavigationalItems="GroupNavigationalItems"
    :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
    <StoreNavbar v-if="StoreView" @ActivateStoreView="ActivateStoreView" @AddItemtoCart="AddItemtoCart" :CartItems="CartItems" @ToggleAppDarkMode="ToggleAppDarkMode" @ActivateStoreSearch="ActivateStoreSearch"
    :SystemEntities="SystemEntities" :ActiveSite="System" :ActiveProduct="ActiveProduct" :CategoricalNavlists="ComputedCategoricalNavlists" @PushActiveProduct="PushActiveProduct" :SitePages="SitePages"
    />
    <PublicNavbar v-if="ShowPublicNavbar" :SystemEntities="SystemEntities"
     :AppStylingView="AppStylingView" :System="System" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SitePages="SitePages" @RefreshRouter="RefreshRouter" @ToggleAppStylingView="ToggleAppStylingView" :GraphicalElements="GraphicalElements"/>
    <IntranetNavbar :FriendsList="FriendsList" :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" @ToggleAppStylingView="ToggleAppStylingView"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode" @IntranetViewToggle="IntranetViewToggle" :AppStylingView="AppStylingView"
    @DeactivateSearch="DeactivateSearch" @ActivateSearch="ActivateSearch" :UserRecord="UserRecord" :ActivityStatusField="ActivityStatusField"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" :GraphicalElements="GraphicalElements" :ActiveSearch="ActiveSearch" :SiteModerators="SiteModerators"
    :MyActivitiesArray="MyActivitiesArray" :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :SystemPhotoLibraries="SystemPhotoLibraries" :SystemDocumentLibraries="SystemDocumentLibraries"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" @AssignPushedNavItem="AssignPushedNavItem"
    :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
    v-if="ShowIntranetNavbar"/>
    <OffLineNotice  v-if="!onLine"
    />
    <RASignin class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin && RouterReady && GotEntities" @ToggleRAAppAdminDialog="ToggleRAAppAdminDialog" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :SystemEntities="SystemEntities" :System="System"
    />
    <!-- <AdminAlerts class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin && RouterReady && GotEntities" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :SystemEntities="SystemEntities" :System="System"
    /> -->
    <AppQuickStartComponent class="pop" style="position: relative;z-index:100;" v-if="userIsAdmin" :RAAppAdminDialog="RAAppAdminDialog"
    :RADB="RADB" :RAApp="RAApp" :AppisDarkMode="AppisDarkMode" :SitePages="SitePages"
    />
    <GuestNavbar v-if="GuestNavbarView" :userLoggedIn="userLoggedIn" :UserisGuest="UserisGuest" :AppisDarkMode="AppisDarkMode" :Notifications="Notifications"
    :NotificationObj="NotificationObj" :SystemEntities="SystemEntities" :System="System" :SubscriptionPackages="SubscriptionPackages"
    @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" @ToggleAppDarkMode="ToggleAppDarkMode"
    @ActivateSnackbar="ActivateSnackbar" @ActivateProcessing="ActivateProcessing" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
    @SignoutNotifications="SignoutNotifications"
    />
    <Navbar :UsersArray="UsersStore" :CurrentRecordName="CurrentRecordName" :System="System" :SystemEntities="SystemEntities" :UserDBNavList="UserDBNavList"
    @ToggleAppDarkMode="ToggleAppDarkMode"  :AppisDarkMode="AppisDarkMode"  @ToggleAppStylingView="ToggleAppStylingView" :AppStylingView="AppStylingView"
    @MarkasUnread="MarkasUnread" @NotificationRead="NotificationRead" @ActivateSnackbar="ActivateSnackbar" :ActivityStatusField="ActivityStatusField"
    @SignoutNotifications="SignoutNotifications" :Notifications="Notifications" :SystemPhotoLibraries="SystemPhotoLibraries" :RADB="RADB"
     :SystemDocumentLibraries="SystemDocumentLibraries" :SiteModerators="SiteModerators"
    @IntranetViewToggle="IntranetViewToggle"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" :GraphicalElements="GraphicalElements"
    :MyActivitiesArray="MyActivitiesArray" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemTableFilters="SystemTableFilters"
    @ToggleTimesheetDialog="ToggleTimesheetDialog" :TimesheetDialogActive="TimesheetDialogActive" :TimesheetRelated="TimesheetRelated" :SitePages="SitePages"
    :ActivityRegarding="ActivityRegarding" @SetActivityRegarding="SetActivityRegarding" @AssignPushedNavItem="AssignPushedNavItem" @TriggerDashboardbyNotification="TriggerDashboardbyNotification"
    v-if="DBView"/>
    
    <BreadCrumbs style="z-index:2;" :AppisDarkMode="AppisDarkMode" :AppisDarkModeCard="AppisDarkModeCard" :System="System" :SystemEntities="SystemEntities"
    :CurrentRecordName="CurrentRecordName" v-if="BreadCrumbsActive"
     class="breadcrumbstop" />
   
   <v-card tile flat :style="StyleMargin" :class="'fullapp '+BGImageClass" height="100%" v-if="!IntranetView && !SearchView && RouterReady && GotEntities && !RefreshRouteView || !IntranetView && !SearchView && RouterReady && PublicUser && !RefreshRouteView">
      <div class="cloudbg" v-if="RendersClouds"
        :style="SystemisRA ? { backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' } : { backgroundImage: 'url(' + require('@/assets/clouds.png') + ')' ,opacity: 0.4}"></div><div :class="SunorMoon" :style="SunorMoonTexture"  v-if="RendersClouds"/>
      <router-view :style="ComputedRouteStyle" @ActivateRecordLoadingDialog="ActivateRecordLoadingDialog" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView" @LandingPageView="LandingPageView" :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName"
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
       @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding"
      :RADB="RADB" :RAApp="RAApp" :SystemGuides="SystemGuides" :FavoriteFeatures="FavoriteFeatures" :DBNavLists="DBNavLists" :SystemTableFilters="SystemTableFilters"
      :AppStylingView="AppStylingView" @ToggleCatNavlistView="ToggleCatNavlistView" :CategoricalNavlists="ComputedCategoricalNavlists" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList"
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       :SystemDocumentLibraries="SystemDocumentLibraries" 
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveSite="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      @AssignAuth="AssignAuth" :AppisDarkMode="AppisDarkMode" :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :SubscriptionPackages="SubscriptionPackages"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries"
      :AppBuilderView="AppBuilderView"
      class="transparent"
    @ToggleAppDarkMode="ToggleAppDarkMode"></router-view>    
    </v-card>
    <v-parallax v-if="!PublicPageView && !AppStylingView && !$route.name === 'PageEditor' && !PageView && userLoggedIn && System && SystemisRA"
     style="margin-bottom:-100px;margin:top:100px;"     
    height="500"
    src='@/assets/RapidappsParallax.jpeg'
  ></v-parallax>
    <v-card tile flat  :color="'background'" :style="StyleMargin" class="fullapp"  height="100%"  v-if="IntranetView && !SearchView && RouterReady && !NonSocialGuest">
      <router-view :style="IntranetRouteStyling" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView"  :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName" :AppisDarkMode="AppisDarkMode" 
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
      @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding" :SubscriptionPackages="SubscriptionPackages"
      :AppStylingView="AppStylingView" :CategoricalNavlists="ComputedCategoricalNavlists" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemGuides="SystemGuides" :DynamicWikiData="System"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList" :RADB="RADB" :RAApp="RAApp" 
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing" :CurrentEntity="SystemConfigEntity"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveSite="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries" :AppBuilderView="AppBuilderView" :SystemDocumentLibraries="SystemDocumentLibraries" :GuestNavbarView="GuestNavbarView"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries"></router-view>
    </v-card>
    <v-card tile flat  :color="'background'" :style="StyleMargin" class="fullapp"  height="100%"  v-if="IntranetView && !SearchView && RouterReady && NonSocialGuest">
      <v-main>
      <router-view :style="IntranetRouteStyling" :UserisGuest="UserisGuest" :SiteModerators="SiteModerators" :IntranetView="IntranetView"  :UsersArray="UsersStore" v-if="RouterReady" @PushRecordName="PushRecordName" :AppisDarkMode="AppisDarkMode" 
      @ActivateStandbyDialog="ActivateStandbyDialog" @ActivateSnackbar="ActivateSnackbar" @ActivateNotificationSnackbar="ActivateNotificationSnackbar" :PushedNavItem="PushedNavItem" @AssignPushedNavItem="AssignPushedNavItem"
      @UpdateGroupNavigationalItems="UpdateGroupNavigationalItems" @ToggleTimesheetDialog="ToggleTimesheetDialog" @SetActivityRegarding="SetActivityRegarding" :SubscriptionPackages="SubscriptionPackages"
      :AppStylingView="AppStylingView" :CategoricalNavlists="ComputedCategoricalNavlists" :FrequentlyAskedQuestions="FrequentlyAskedQuestions" :SystemGuides="SystemGuides" :DynamicWikiData="System"
      :NotificationObj="NotificationObj" :UnreadNotifications="UnreadNotifications" :AppisDarkModeCard="AppisDarkModeCard" :userLoggedIn="userLoggedIn" :FriendsList="FriendsList" :RADB="RADB" :RAApp="RAApp" 
       :Notifications="Notifications" :MyActivitiesArray="MyActivitiesArray"  :MyGoals="MyGoals" :MyGoalsisEmpty="MyGoalsisEmpty" @ActivateProcessing="ActivateProcessing" :CurrentEntity="SystemConfigEntity"
      @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
       @ActivateStoreView="ActivateStoreView" @PushActiveProduct="PushActiveProduct" :ActiveSite="ActiveStore" :CartItems="CartItems" @ProcessClearStoreCart="ProcessClearStoreCart"
      :UserRecord="UserRecord" @IntranetViewToggle="IntranetViewToggle" @ToggleAppStylingView="ToggleAppStylingView" :WikiEntities="WikiEntities" :GroupEntities="GroupEntities"
      :DocumentationLibraries="DocumentationLibraries" :AppBuilderView="AppBuilderView" :SystemDocumentLibraries="SystemDocumentLibraries" :GuestNavbarView="GuestNavbarView"
      :SystemEntities="SystemEntities" :System="System" :SitePages="SitePages" :SystemPhotoLibraries="SystemPhotoLibraries"></router-view>
      </v-main>
    </v-card>
    <AppNotifications :StandbyDialog="StandbyDialog" :UploadingFiles="UploadingFiles" :snackbars="snackbars" :ReminderDialog="ReminderDialog" :ReminderNotifications="ReminderNotifications" @ToggleReminderDialog="ToggleReminderDialog"
    :UserisGuest="UserisGuest" :Notifications="Notifications" @TriggerDashboardbyNotification="TriggerDashboardbyNotification" :SystemLoading="SystemLoading" :SystemLoadingvalue="SystemLoadingvalue"
 :UploadingSingleFile="UploadingSingleFile" :AppisDarkMode="AppisDarkMode" :Processing="Processing" :RecordLoading="RecordLoading" :RecordLoadingvalue="RecordLoadingvalue"
 @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
 :snackbar="snackbar" :snackbarmultiLine="snackbarmultiLine" :snackbartimeout="snackbartimeout" :snackbartext="snackbartext" :snackbartop="snackbartop" :snackbarpath="snackbarpath" 
 :SystemEntities="SystemEntities" :System="System" :RouterReady="RouterReady" :SystemPhotoLibraries="SystemPhotoLibraries" :SystemTableFilters="SystemTableFilters" @ActivateSnackbar="ActivateSnackbar"
 @CloseSnackbar="CloseSnackbar"/>   
    <SiteSearch  @DeactivateSearch="DeactivateSearch" @ToggleAppDarkMode="ToggleAppDarkMode" class="footermargin" :UserRecord="UserRecord" v-if="SearchView" :ActiveSearch="ActiveSearch" :System="System" :SystemEntities="SystemEntities" />
    
    <PublicFooterComponent v-if="PublicFooterEngage" :FrequentlyAskedQuestions="FrequentlyAskedQuestions"/>
    
     <v-footer
      fixed
      app v-if="!AltPubNavbar || AltPubNavbar && !MiniView"
      :style="userLoggedIn ? 'z-index: 10;background: linear-gradient(345deg, rgba(78,85,143,1) 0%, rgba(192,77,139,1) 43%, rgba(238,147,126,1) 100%);' : 'z-index: 10;'"
      height="50px"
      :class="userLoggedIn ? 'white--text' : 'footerbg'"
    >
    <!--  -->
      <span><span class="font-weight-light hidden-sm-and-down"> {{System.Name}} </span><span class="font-weight-light hidden-md-and-up">  </span>© 2022</span><v-spacer></v-spacer>
      <span>Powered by</span><pre> </pre><a href="https://www.rapidapps.co.za/" style="color:white" target="_blank" class="txtlink">Rapid<span class="font-weight-light">Apps™</span></a>
    </v-footer>
  </v-app>
</template>

<script>
import db from '@/main'
import format from 'date-fns/format'
import Navbar from '@/components/Navigation/Navbar'
import GuestNavbar from '@/components/Navigation/GuestNavbar'
import IntranetNavbar from '@/components/Navigation/IntranetNavbar'
import GroupNavbar from '@/components/Navigation/GroupNavbar'
import PublicNavbar from '@/components/Navigation/PublicNavbar'
import StoreNavbar from '@/components/Navigation/StoreNavbar'
import AppStylingComponent from '@/components/SuiteBuilder/AppStylingComponent'
import BuilderViewAppBar from '@/components/SuiteBuilder/Navigators/BuilderViewAppBar';	
import firebase from 'firebase';
import AppQuickStartComponent from '@/components/SuiteBuilder/Support/AppQuickStartComponent'
import ChatBot from './components/SuitePlugins/Chatbot/Chatbot'
import OffLineNotice from '@/components/General/OffLineNotice'
import RASignin from '@/components/SuiteBuilder/Support/RASignin'
import AdminAlerts from '@/components/SuiteBuilder/AdminAlerts'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import SiteSearch from '@/components/SuitePlugins/SiteSearch/SiteSearch'
import AppNotifications from '@/components/General/AppNotifications'
import PublicFooterComponent from '@/components/General/PublicFooterComponent'
import LandingPageSingleVue from './components/WebPages/LandingPageSingle.vue'
import DataCollectionViewComponent from '@/components/Database/DataCollectionViewComponent';
import DirectoryBuilder from '@/components/Directories/DirectoryBuilder';
import DBNavlistsComponent from '@/components/General/DBNavlists';
import SystemTableFiltersComponent from '@/components/Database/SystemTableFiltersComponent';
import WebFormBuilder from '@/components/SuitePlugins/WebForms/WebFormBuilder';
import SurveyBuilder from '@/components/WebPages/SurveyBuilder';
import FAQAdmin from '@/components/SuitePlugins/FAQ/FAQAdmin'
import PDFExportFunctionBuilder from '@/components/SuitePlugins/PDFBuilder/PDFExportFunctionBuilder'
import MyAccount from '@/components/Client/MyAccount'
import WikiCollectionBuilder from '@/components/Directories/DirectoryBuilder';
import ContentLibraryBuilder from '@/components/ContentLibraryBuilder/BuilderStartup';
import WebForms from '@/components/SuitePlugins/WebForms/WebForms';
import RMAdmin from '@/components/SuiteBuilder/Support/RMAdministration.vue'
import SecurityOverview from '@/components/Database/SecurityOverview.vue'
import LandingPages from '@/components/WebPages/LandingPages'
import NewLandingPage from '@/components/WebPages/NewLandingPage';
import BannerAds from '@/components/WebPages/BannerAds'
import Campaigns from '@/components/WebPages/Campaigns';
import CampaignBuilder from '@/components/WebPages/CampaignBuilder';
import NewBannerAd from '@/components/WebPages/NewBannerAd';
import Roadmap from '@/components/SuiteBuilder/Support/Roadmap'
import SystemConfig from '@/components/Database/DataSingleViewComponent';
import MyProfile from '@/views/MyProfile'
import ChatbotBuilder from '@/components/SuitePlugins/Chatbot/ChatbotBuilder'
import DataImports from '@/views/DataImports'
import DataImport from '@/views/DataImport/Single'
import MyActivities from '@/views/MyActivities'
import MySchedule from '@/components/SuitePlugins/Activities/MySchedule'
import MyTeamSchedule from '@/components/SuitePlugins/Activities/MyTeamSchedule'
import MyActivitiesbyType from '@/views/Activities/MyActivitiesbyType';
import MyTeamActivities from '@/views/MyTeamActivities'
import MyTeamMemberActivities from '@/views/MyTeamMemberActivities'
import TeamMemberActivities from '@/views/TeamActivities/TeamMemberActivities'
import PipelineManagement from '@/components/SuitePlugins/Pipelines/PipelineManagment'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'
import SocialMediaPlatforms from '@/components/SuitePlugins/SocialCentre/SocialMediaPlatforms'
import Monetization from '@/components/SuitePlugins/Monetization/Monetization'
import LinkedInAuth from '@/components/SuitePlugins/SocialCentre/LinkedInAuth'
import Goals from '@/components/SuitePlugins/Goals/Goals'
import PDFExportTemplates from '@/components/SuitePlugins/PDFBuilder/PDFExportTemplates'
import GroupEdit from '@/components/SuitePlugins/Groups/Edit/Single';
import GroupNewBlog from '@/components/SuitePlugins/Blogs/New/Single';
import GroupNewArticle from '@/components/SuitePlugins/Articles/New/Single';
import GroupNewPoll from '@/components/SuitePlugins/Polls/New/Single';
import Groups from '@/components/SuitePlugins/Groups/Groups';
import GroupSingle from '@/components/SuitePlugins/Groups/Single';
import SiteMeetupNew from '@/components/SuitePlugins/Meetups/New/Single';
import SitePollNew from '@/components/SuitePlugins/Polls/New/Single';
import SignUp from '@/views/signup'
import LedgersCollection from '@/components/SuitePlugins/Accounting/LedgersCollection';
import axios from 'axios'	
import GroupInteractionComponent from '@/components/SuitePlugins/Groups/GroupInteractionComponent';
import DocumentationLibraryViewer from '@/components/WebPages/LandingPageSingle'
import DocumentationLibraryEditor from '@/components/WebPages/NewLandingPage';
import GettingStarted from '@/components/SuiteBuilder/GettingStarted';
import SuiteApp from '@/components/SuiteBuilder/SuiteApps/SuiteApp';
import SuiteAppNavigator from '@/components/SuiteBuilder/SuiteApps/SuiteAppNavigator';
import RecordChatRoom from '@/components/General/RecordChatRoom';
import WarehouseTransaction from '@/components/SuitePlugins/Warehousing/WarehouseTransaction';
import WarehousesCollection from '@/components/SuitePlugins/Warehousing/WarehousesCollection';
import WarehouseSingle from '@/components/SuitePlugins/Warehousing/WarehouseSingle';
import Picking from '@/components/SuitePlugins/Warehousing/Processing/Picking';
import Dispatching from '@/components/SuitePlugins/Warehousing/Processing/Dispatching';
import BackorderProcessing from '@/components/SuitePlugins/Warehousing/BackorderProcessing';
import GoodsReceiving from '@/components/SuitePlugins/Warehousing/Processing/GoodsReceiving';
import PurchaseOrderSingle from '@/components/SuitePlugins/Warehousing/PurchaseOrderSingle';
import PurchaseOrdersCollection from '@/components/SuitePlugins/Warehousing/PurchaseOrdersCollection';
import SalesOrderSingle from '@/components/SuitePlugins/Warehousing/SalesOrderSingle';


import GroupFeaturedMemberView from '@/components/SuitePlugins/Groups/GroupFeaturedMemberView';
import DigitalBusinessCards from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCards';
import DigitalBusinessCardSingle from '@/components/SuitePlugins/DigitalBusinessCards/DigitalBusinessCardSingle';
import GroupFeaturedMemberEdit from '@/components/SuitePlugins/Groups/GroupFeaturedMemberEdit';
import SiteMembers from '@/components/SocialNetwork/SiteMembers'
import SiteMemberSingle from '@/components/SocialNetwork/SiteMemberSingle';
import Glossary from '@/components/SuitePlugins/Glossary/Glossary';
import SitePostSingle from '@/components/SocialNetwork/SitePostSingle'
import NoticeNew from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeNew'
import NoticeSingle from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeSingle'
import SocialDashboard from '@/components/Dashboards/SocialDashboard'

import SiteArticles from '@/components/SuitePlugins/Articles/SiteArticles';
import SiteArticleSingle from '@/components/SuitePlugins/Articles/Single';
import SiteArticleNew from '@/components/SuitePlugins/Articles/New/Single';
import SiteBlogs from '@/components/SuitePlugins/Blogs/SiteBlogs';
import SiteBlogSingle from '@/components/SuitePlugins/Blogs/Single';
import SiteBlogNew from '@/components/SuitePlugins/Blogs/New/Single';
import Classifieds from '@/components/SuitePlugins/Classifieds/Classifieds';
import ClassifiedSingle from '@/components/SuitePlugins/Classifieds/ClassifiedSingle';
import SiteEventsSocial from '@/components/SuitePlugins/Events/SiteEvents';
import SiteForums from '@/components/SuitePlugins/Forums/SiteForums';
import SiteForumSingle from '@/components/SuitePlugins/Forums/SiteForumSingle';

import GroupPollsSingle from '@/components/SuitePlugins/Polls/Single';
import GroupBlog from '@/components/SuitePlugins/Blogs/Single';
import GroupForumSingle from '@/components/SuitePlugins/Forums/SiteForumSingle';
import GroupArticleSingle from '@/components/SuitePlugins/Articles/Single';
import MyDashboard from '@/components/Dashboards/MyDashboard'
import SiteMeetups from '@/components/SuitePlugins/Meetups/SiteMeetups';
import SiteMeetupSingle from '@/components/SuitePlugins/Meetups/Single';

import SitePolls from '@/components/SuitePlugins/Polls/SitePolls';
import SitePollSingle from '@/components/SuitePlugins/Polls/Single';
export default {
  components: { PublicFooterComponent,SiteSearch,BreadCrumbs,Navbar,GuestNavbar,IntranetNavbar,PublicNavbar,StoreNavbar,GroupNavbar,BuilderViewAppBar,AppStylingComponent,AppNotifications,AdminAlerts,OffLineNotice,RASignin,ChatBot,
  FAQAdmin,PDFExportFunctionBuilder,MyAccount,WikiCollectionBuilder,ContentLibraryBuilder,WebForms,RMAdmin,SecurityOverview,LandingPages,NewLandingPage,BannerAds,Campaigns,GroupInteractionComponent,
  CampaignBuilder,NewBannerAd,Roadmap,SystemConfig,MyProfile,ChatbotBuilder,DataImports,DataImport,MyActivities,MySchedule,MyTeamSchedule,MyActivitiesbyType,
   MyTeamActivities,MyTeamMemberActivities,TeamMemberActivities,PipelineManagement,DashboardBuilder,SocialMediaPlatforms,Monetization,LinkedInAuth,MyDashboard,Goals,PDFExportTemplates,SuiteApp,SuiteAppNavigator,
   GroupEdit,GroupNewBlog,GroupNewArticle,GroupNewPoll,Groups,GroupSingle,SiteMeetupNew,SitePollNew,AppQuickStartComponent,DocumentationLibraryEditor,DocumentationLibraryViewer,GettingStarted,
   WarehouseTransaction,WarehousesCollection,WarehouseSingle,Picking,Dispatching,BackorderProcessing,GoodsReceiving,PurchaseOrderSingle,PurchaseOrdersCollection,SalesOrderSingle,
   GroupFeaturedMemberView,DigitalBusinessCards,DigitalBusinessCardSingle,GroupFeaturedMemberEdit,SiteMembers,
SiteMemberSingle,Glossary,SitePostSingle,NoticeNew,NoticeSingle,SocialDashboard,SiteArticles,SiteArticleSingle,
SiteArticleNew,SiteBlogs,SiteBlogSingle,SiteBlogNew,Classifieds,ClassifiedSingle,SiteEventsSocial,SiteForums,
SiteForumSingle,GroupSingle,GroupPollsSingle,GroupBlog,GroupForumSingle,GroupArticleSingle,SiteMeetups,
SiteMeetupSingle,SitePolls,SitePollSingle,RecordChatRoom },
  name: 'App',
  data () {
    return {
      ModuleDatabase: {},
      ActivePlugins: [],
      DocumentationLibraries: [],
      GotConfigMaster: false,
      SubscriptionPackages: [],
      StoreView: false,
      CartItems: [],
      ActiveProduct: '',
      SocialCategories: ['ArticlesCategories','BlogsCategories','MeetupsCategories','NoticesCategories','PollsCategories'],
      PushedNavItem: '',
      ActivityRegarding: '',
      TimesheetDialogActive: false,
      GroupNavigationalItems: [],
      AdminRoutes: [
        {
          path: '/Getting-Started',
          name: 'GettingStarted',
          component: GettingStarted,
          Basic: true,
        },
        {
          path: '/DocumentationLibraryEditor/:id/:slug',
          name: 'DocumentationLibraryEditor',
          component: DocumentationLibraryEditor,
          ORPlugins: ['Documentation_Builder'],
        },
        {
          path: '/Monetization',
          name: 'Monetization',
          component: Monetization,
          ORPlugins: ['Monetization','Group_Directories','Group_Monetization']
        },
        {
          path: '/SocialMediaPlatforms',
          name: 'SocialMediaPlatforms',
          component: SocialMediaPlatforms,
          ORPlugins: ['Social_Centre']
        },
        {
          path: '/Goals',
          name: 'Goals',
          component: Goals,
          ANDPlugins: ['Goals','Database_Builder']
          //Basic: true
        },
       {
        path: '/WebFormBuilder/:id',
        name: 'WebFormEditor',
        component: WebFormBuilder,
        props: true,
        ORPlugins: ['Web_Forms']
      },
      {
        path: '/WebFormBuilder-New',
        name: 'WebFormBuilderNew',
        component: WebFormBuilder,
        props: true,
        ORPlugins: ['Web_Forms']
      },
      {
        path: '/SurveyBuilder/:id',
        name: 'SurveyBuilder',
        component: SurveyBuilder,
        ORPlugins: ['Surveys']
      },
      {
        path: '/SurveyBuilder-New',
        name: 'SurveyBuilderNew',
        component: SurveyBuilder,
        ORPlugins: ['Surveys']
      },
        {
          path: '/DirectoryBuilder/:id',
          name: 'DirectoryBuilder',
          component: DirectoryBuilder,
          ORPlugins: ['Database_Builder']
        },
        {
          path: '/DBNavlists',
          name: 'DBNavlists',
          component: DBNavlistsComponent
        },
         {
          path: '/SystemTableFilters',
          name: 'SystemTableFiltersComponent',
          component: SystemTableFiltersComponent,
          Basic: true
        },
        
          {
            path: '/FAQAdmin',
            name:'FAQAdmin', 
            component: FAQAdmin,
            ORPlugins: ['FAQ']
          },
          {
            path: '/PDFExportTemplates',
            name:'PDFExportTemplates', 
            component: PDFExportTemplates,
            ORPlugins: ['PDF_Builder']
          },
          {
            path: '/PDFExportFunctionBuilder/:slug',
            name: 'PDFExportFunctionBuilder',
            component: PDFExportFunctionBuilder,
            ORPlugins: ['PDF_Builder']
          },
          {
            path: '/PipelineManagement/:id',
            name:'PipelineManagement', 
            component: PipelineManagement,
            ORPlugins: ['Pipelines']
            //Basic: true
          },
          {
            path: '/WikiCollectionBuilder/:id',
            name: 'WikiCollectionBuilder',
            component: WikiCollectionBuilder,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Wiki Collection Builder',
              WikiCollectionBuilder: true,
              WikiType: true,
            },
            ORPlugins: ['Database_Builder']
          },
          //MISSING!! WHAT WAS THIS???
          // {
          //   path: '/ContentLibraryBuilder',
          //   name: 'ContentLibraryBuilder',
          //   component: ContentLibraryBuilder,
          //   meta: {
          //     IsTopic: true,
          //     TopicCategory: 'Administration',
          //     TopicName: 'HTML Library Builder',
          //     ContentLibraryBuilder: true,
          //   }
          // },
          {
            path: '/WebForms',
            name: 'WebForms',
            component: WebForms,
            meta: {
              IsTopic: true,
              TopicCategory: 'Administration',
              TopicName: 'Web Forms',
              WebFormCollection: true,
            },
            ORPlugins: ['Web_Forms']
          },
          {
    path: '/RMAdministration',
    name: 'RMAdmin',
    component: RMAdmin,
    ORPlugins: ['Cabinets']
  },
  {
    path: '/Campaigns',
    name: 'campaigns',
    component: Campaigns,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Campaigns'
    },
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/CampaignBuilder',
    name: 'campaignbuilder',
    component: CampaignBuilder,
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/BannerAds',
    name: 'bannerads',
    component: BannerAds,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Banner Ads'
    },
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/LandingPages',
    name: 'landingpages',
    component: LandingPages,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Landing Pages'
    },
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/BannerAd/Edit/:id',
    name: 'newbannerad',
    component: NewBannerAd,
    props:true,
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/LandingPage/Edit/:id',
    name: 'newlandingpage',
    component: NewLandingPage,
    props:true,
    meta: {
      
    },
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/PageBuilder',
    name: 'PageBuilder',
    component: NewLandingPage,
    props:true,
    meta: {
      PageBuilder: true
    },
    ORPlugins: ['Campaign_Builder']
  },
  {
    path: '/PageEditor/:id',
    name: 'PageEditor',
    component: NewLandingPage,
    props:true,
    meta: {
      PageEditor: true
    },
    ORPlugins: ['Website_Builder','Social_Network_Builder']
  },
  {
    path: '/SecurityOverview',
    name: 'SecurityOverview',
    component: SecurityOverview,
    props:true,
    Basic: true
  },
  {
    path: '/Roadmap',
    name: 'Roadmap',
    component: Roadmap,
    Basic: true
  },
  {
    path: '/SystemConfig/:id',
    name: 'SystemConfig',
    component: SystemConfig,
    props: true,
    meta: {
      RecordisSystem: true,
      Collection: 'SystemConfig',
      DataSingleBuilder: true,
      SystemConfigBuilder: true,
      icon: 'mdi-apps',
      identifier: 'Name',
      RouteEventID: 2001,
      type: 'System Config',
      subcat: 'System Config',
      EntityID: 'Systems',
      viewicon: 'mdi-library',
      SingleRecordNavMenuView: true,
      },
    Basic: true
  },
   {
    path: '/DataImports',
    name: 'DataImports',
    component: DataImports,
    meta: {
      IsTopic: true,
      TopicCategory: 'Administration',
      TopicName: 'Imports'
    },
    ORPlugins: ['Data_Importer']
  },
  {
    path: '/DataImport/:id',
    name: 'DataImport',
    component: DataImport,
    ORPlugins: ['Data_Importer']
  },
  {
    path: '/ChatbotBuilder',
    name: 'ChatbotBuilder',
    component: ChatbotBuilder,
    ORPlugins: ['Chatbot']
  },
      ],
      STDUserRoutes: [
        {
        path: '/MyAccount',
        name: 'MyAccount',
        component: MyAccount,
        Basic: true
        //ORPlugins: ['Client_Portal']
      },
        {
          path: '/Groups',
          name: 'Groups',
          component: Groups,
          meta: {
            RouteEventID: 1013,
            IsTopic: true,
            TopicCategory: 'Social Page',
            TopicName: 'Groups'
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
            path: '/NewPoll',
            name: 'SitePollNew',
            component: SitePollNew,
            //MISSING!! 
            ORPlugins: ['Site_Polls']
          },
        {
            path: '/NewMeetup',
            name: 'SiteMeetupNew',
            component: SiteMeetupNew,            
            //MISSING!! 
            ORPlugins: ['Site_Meetups']
          },
        //   {
        //   path: '/Site/:id',
        //   name: 'SiteSingle',
        //   component: GroupSingle,
        //   meta: {
        //     RouteEventID: 1014,
        //     icon: 'mdi-account-group-outline',
        //     type: 'Social',
        //     identifier: 'Name',
        //     subcat: 'Group',
        //     viewicon: 'mdi-account-group-outline',
            
        //     }
        // },
        {
          path: '/Group/:id',
          name: 'GroupSingle',
          component: GroupSingle,
          meta: {
            RouteEventID: 1014,
            icon: 'mdi-account-group-outline',
            type: 'Social',
            identifier: 'Name',
            subcat: 'Group',
            viewicon: 'mdi-account-group-outline',
            
            },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/Edit/:id',
          name: 'GroupEdit',
          component: GroupEdit,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewArticle/:id',
          name: 'GroupNewArticle',
          component: GroupNewArticle,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewMeetup/:id',
          name: 'GroupNewMeetup',
          component: SiteMeetupNew,
          props: true,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewBlog/:id',
          name: 'GroupNewBlog',
          component: GroupNewBlog,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group-PageEditor/:id/:slug',
          name: 'GroupPageEditor',
          component: NewLandingPage,
          meta: {
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group-PageBuilder/:id',
          name: 'GroupPageBuilder',
          component: NewLandingPage,
          meta: {
          },
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
          path: '/Group/NewPoll/:id',
          name: 'GroupNewPoll',
          component: GroupNewPoll,
          //MISSING!! 
          ORPlugins: ['Site_Groups']
        },
        {
        path: '/GroupWebFormBuilder/:id',
        name: 'GroupWebFormEditor',
        component: WebFormBuilder,
        props: true,
          //MISSING!! 
          ANDPlugins: ['Site_Groups','Web_Forms']
      },
          {
          path: '/GroupWebFormBuilder',
          name: 'GroupWebFormBuilder',
          component: WebFormBuilder,
          props: true,
          //MISSING!! 
          ANDPlugins: ['Site_Groups','Web_Forms']
        },
        {
        path: '/CustomDashboard/:slug',
        name: 'CustomDashboard',
        component: DashboardBuilder,
        Basic: true,
        meta: {
          RouteEventID: 1037,
        },
          //ORPlugins: ['Custom_Dashboard']
      },
        {
          path: '/LinkedInAuth',
          name: 'LinkedInAuth',
          component: LinkedInAuth,
           ORPlugins: ['Social_Centre']
        },      
       {
          path: '/MyActivities/:id',
          name: 'MyActivitiesbyType',
          component: MyActivitiesbyType,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MyTeamSchedule',
          name: 'MyTeamSchedule',
          component: MyTeamSchedule,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MySchedule',
          name: 'MySchedule',
          component: MySchedule,
          ORPlugins: ['Activities_and_Team']
        },
        
        {
          path: '/MyTeamActivities',
          name: 'MyTeamActivities',
          component: MyTeamActivities,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/MyTeamActivities/:id',
          name: 'MyTeamMemberActivities',
          component: MyTeamMemberActivities,
          ORPlugins: ['Activities_and_Team']
        },
        {
          path: '/TeamMemberActivities/:id/:prop',
          name: 'TeamMemberActivities',
          component: TeamMemberActivities,
          ORPlugins: ['Activities_and_Team']
        },
        // {
        //   path: '/Activity/:id',
        //   name: 'activity',
        //   component: Activity
        // },
        {
          path: '/MyActivities',
          name: 'MyActivities',
          component: MyActivities,
          ORPlugins: ['Activities_and_Team']
        },
      ],
      NonGuestRoutes: [{
              path: '/WarehouseTransaction/:slug/:id',
              name: 'WarehouseTransaction',
              component: WarehouseTransaction,
              meta: {
                RouteEventID: 1046,
                id: 'WarehouseTransaction',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehouseTransaction',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Transaction'
                }
            },
            {
              path: '/Warehouses/:id',
              name: 'WarehousesCollection',
              component: WarehousesCollection,
              meta: {
                RouteEventID: 1047,
                id: 'WarehousesCollection',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehousesCollection',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Collection'
                }
            },
            {
              path: '/Warehouse/:slug/:id',
              name: 'WarehouseSingle',
              component: WarehouseSingle,
              meta: {
                RouteEventID: 1048,
                id: 'WarehouseSingle',
                icon: 'mdi-warehouse',
                type: 'Warehouse',
                subcat: 'WarehouseSingle',
                viewicon: 'mdi-warehouse',
                IsTopic: true,
                TopicCategory: 'Warehouse',
                TopicName: 'Warehouse Single'
                }
            },
            {
              path: '/SalesOrder/:slug/:id',
              name: 'SalesOrderSingle',
              component: SalesOrderSingle,
              meta: {
                RouteEventID: 1049,
                id: 'SalesOrderSingle',
                icon: 'mdi-cart',
                type: 'SCM',
                subcat: 'SalesOrderSingle',
                viewicon: 'mdi-cart',
                IsTopic: true,
                TopicCategory: 'SalesOrder',
                TopicName: 'Sales Order Single'
                }
            },
            {
              path: '/Dispatching/:slug/:id',
              name: 'Dispatching',
              component: Dispatching,
              meta: {
                RouteEventID: 1053,
                id: 'Dispatching',
                icon: 'mdi-cube-send',
                type: 'Dispatching',
                subcat: 'Dispatching',
                viewicon: 'mdi-cube-send',
                IsTopic: true,
                TopicCategory: 'Dispatching',
                TopicName: 'Dispatching'
                }
            },
            {
              path: '/Picking/:id',
              name: 'Picking',
              component: Picking,
              meta: {
                RouteEventID: 1053,
                id: 'Picking',
                icon: 'mdi-hand-okay',
                type: 'Picking',
                subcat: 'Picking',
                viewicon: 'mdi-hand-okay',
                IsTopic: true,
                TopicCategory: 'Picking',
                TopicName: 'Picking '
                }
            },
            {
              path: '/PurchaseRequisitions',
              name: 'PurchaseRequisitions',
              component: BackorderProcessing,
              meta: {
                RouteEventID: 1056,
                id: 'PurchaseRequisitions',
                icon: 'mdi-factory',
                type: 'Purchase Requisitions',
                subcat: 'PurchaseRequisitions',
                viewicon: 'mdi-factory',
                IsTopic: true,
                TopicCategory: 'Purchase Requisitions',
                TopicName: 'Purchase Requisitions'
                }
            },
            {
              path: '/BackorderProcessing/:id',
              name: 'BackorderProcessing',
              component: BackorderProcessing,
              meta: {
                RouteEventID: 1050,
                id: 'BackorderProcessing',
                icon: 'mdi-factory',
                type: 'Backorder Processing',
                subcat: 'BackorderProcessing',
                viewicon: 'mdi-factory',
                IsTopic: true,
                TopicCategory: 'Backorder Processing',
                TopicName: 'Backorder Processing'
                }
            },
            {
              path: '/PurchaseOrder/:slug/:id',
              name: 'PurchaseOrderSingle',
              component: PurchaseOrderSingle,
              meta: {
                RouteEventID: 1051,
                id: 'PurchaseOrderSingle',
                icon: 'mdi-cash-register',
                type: 'Purchase Order',
                subcat: 'PurchaseOrder',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'Purchase Order',
                TopicName: 'Purchase Order Single'
                }
            },
            {
              path: '/PurchaseOrders/:id',
              name: 'WarehousePurchaseOrders',
              component: PurchaseOrdersCollection,
              meta: {
                RouteEventID: 1057,
                id: 'WarehousePurchaseOrders',
                icon: 'mdi-cash-register',
                type: 'Warehouse Purchase Orders',
                subcat: 'WarehousePurchaseOrders',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'WarehousePurchaseOrders',
                TopicName: 'Warehouse Purchase Orders'
                }
            },
            {
              path: '/PurchaseOrders',
              name: 'PurchaseOrders',
              component: PurchaseOrdersCollection,
              meta: {
                RouteEventID: 1058,
                id: 'PurchaseOrders',
                icon: 'mdi-cash-register',
                type: 'Purchase Orders',
                subcat: 'PurchaseOrders',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'PurchaseOrders',
                TopicName: 'Purchase Orders'
                }
            },
            {
              path: '/GoodsReceiving/:slug',
              name: 'GoodsReceivingCollection',
              component: GoodsReceiving,
              meta: {
                RouteEventID: 1052,
                id: 'GoodsReceiving',
                icon: 'mdi-cash-register',
                type: 'Goods Receiving',
                subcat: 'GoodsReceiving',
                viewicon: 'mdi-cash-register',
                IsTopic: true,
                TopicCategory: 'Goods Receiving',
                TopicName: 'Goods Receiving Collection'
                }
            },
        {
        path: '/App/:id',
        name: 'SuiteApp',
        component: SuiteApp,
        meta: {
          RouteEventID: 1059,
          IsTopic: true,
          TopicCategory: 'Suite App Single',
          TopicName: 'Suite Apps'
        }
      },
        {
        path: '/MyProfile',
        name: 'MyProfile',
        component: MyProfile,
        meta: {
          IsTopic: true,
          TopicCategory: 'Users',
          TopicName: 'My Profile'
        }
      },
       {
            path: '/MyDashboard',
            name: 'MyDashboard',
            component: MyDashboard,
            ORPlugins: ['Activities_and_Team','Pipelines','Goals','Social_Network_Builder']
          },
          {
        path: '/DashboardBuilder/:slug',
        name: 'DashboardBuilder',
        component: DashboardBuilder,  
        // props:true,      
        ORPlugins: ['Custom_Dashboard']
      },
      ],
      SignedInSocialRoutes: [
          {
            path: '/Articles',
            name: 'SiteArticles',
            component: SiteArticles,
          ORPlugins: ['Site_Articles'],
          Channel: 'Site Articles',
            meta: {
              RouteEventID: 1003,
              IsTopic: true,
              TopicCategory: 'Social Page',
              TopicName: 'Articles'
            }
          },
        {
              path: '/Blogs',
              name: 'SiteBlogs',
              component: SiteBlogs,
            ORPlugins: ['Site_Blogs'],
          Channel: 'Site Blogs',
              meta: {
                RouteEventID: 1001,
                IsTopic: true,
                TopicCategory: 'Social Page',
                TopicName: 'Blogs'
              }
            },
            {
                path: '/SiteMembers',
                name: 'SiteMembers',
                component: SiteMembers,
                ORPlugins: ['Social_Network_Builder'],
                meta: {
                  RouteEventID: 1035,
                }
              },
               {
                path: '/SiteMember/:id',
                name: 'SiteMemberSingle',
                component: SiteMemberSingle,
                ORPlugins: ['Social_Network_Builder'],
                meta: {
                  RouteEventID: 1036,
                }
              },
              {
                path: '/Glossary',
                name: 'Glossary',
                component: Glossary,
                ORPlugins: ['Glossary'],
                meta: {
                  RouteEventID: 1034,
                  IsTopic: true,
                  TopicName: 'Glossary',
                  TopicCategory: 'General'
                }
              },
              {
                path: '/SitePosts/:id/:slug',
                name: 'SitePostSingle',
                component: SitePostSingle,
                ORPlugins: ['Social_Network_Builder'],
                props:true,
                meta: {
                  RouteEventID: 1044,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Site Post'
                }
              },
              {
                path: '/SitePosts/:id',
                name: 'SitePostSingle',
                component: SitePostSingle,
                ORPlugins: ['Social_Network_Builder'],
                props:true,
                meta: {
                  RouteEventID: 1044,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Site Post'
                }
              },
              {
                path: '/NoticeSingle/:id',
                name: 'noticesingle',
                component: NoticeSingle,
                ORPlugins: ['Digital_Notice_Board'],
                props:true,
                meta: {
                  RouteEventID: 1031,
                  IsTopic: true,
                  TopicCategory: 'Moderators',
                  TopicName: 'Notices'
                }
              },
              {
                path: '/NoticeNew/:id',
                name: 'noticenew',
                component: NoticeNew,
                ORPlugins: ['Digital_Notice_Board'],
                props:true
              },
              {
                path: '/SocialDashboard',
                name: 'SocialDashboard',
                component: SocialDashboard,
                ORPlugins: ['Social_Network_Builder'],
              },
              
                 {
                  path: '/NewArticle',
                  name: 'SiteArticleNew',
                ORPlugins: ['Site_Articles'],
                  component: SiteArticleNew
                },                
                {
                  path: '/NewBlog',
                  name: 'SiteBlogNew',
                ORPlugins: ['Site_Blogs'],
                  component: SiteBlogNew
                },
                {
                  path: '/Events',
                  name: 'Events',
                  ORPlugins: ['Site_Events'],
          Channel: 'Site Events',
                  component: SiteEventsSocial,
                  meta: {
                    RouteEventID: 1007,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Events'
                  }
                },
                {
                  path: '/Meetups',
                  name: 'SiteMeetups',
                  ORPlugins: ['Site_Meetups'],
          Channel: 'Site Meetups',
                  component: SiteMeetups,
                  meta: {
                    RouteEventID: 1042,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Meetups'
                  }
                },
                {
                  path: '/Polls',
                  name: 'SitePolls',
                  ORPlugins: ['Site_Polls'],
          Channel: 'Site Polls',
                  component: SitePolls,
                  meta: {
                    RouteEventID: 1009,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Polls'
                  }
                },
                {
                  path: '/SitePoll/:id',
                  name: 'SitePollSingle',
                  ORPlugins: ['Site_Polls'],
                  component: SitePollSingle,
                  meta: {
                    RouteEventID: 1010,
                    icon: 'mdi-poll-box',
                    type: 'Social',
                    identifier: 'PollName',
                    subcat: 'Site Poll',
                    viewicon: 'mdi-poll-box',
                    collection:'sitepolls'
                    }
                },
      ],
      PublicSocialRoutes: [
              {
                path: '/BusinessMember/:id',
                name: 'BusinessMember',
                component: DigitalBusinessCardSingle,
                ORPlugins: ['Digital_Business_Cards']
              },
              {
                path: '/DigitalBusinessCards',
                name: 'DigitalBusinessCards',
                component: DigitalBusinessCards,
                ORPlugins: ['Digital_Business_Cards']
              },  
              {
                path: '/FeaturedGroupMember/:slug/:id',
                name: 'GroupFeaturedMemberView',
                component: GroupFeaturedMemberView,
                ORPlugins: ['Group_Featured_Members']
              },
              {
                path: '/FeaturedGroupMember/Edit/:slug/:id',
                name: 'FeaturedGroupMemberEdit',
                component: GroupFeaturedMemberEdit,
                props: true,
                ORPlugins: ['Group_Featured_Members']
              },
              
             
                {
                  path: '/Article/:id',
                  name: 'SiteArticleSingle',
                  component: SiteArticleSingle,
                ORPlugins: ['Site_Articles'],
                  meta: {
                    RouteEventID: 1004,
                    icon: 'mdi-poll-box',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Article',
                    viewicon: 'mdi-poll-box',
                    collection:'sitearticles'
                    }
                },               
                {
                  path: '/Blog/:id',
                  name: 'SiteBlogSingle',
                  component: SiteBlogSingle,
                ORPlugins: ['Site_Blogs'],
                  meta: {
                    RouteEventID: 1002,
                    icon: 'mdi-blogger',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Blog',
                    viewicon: 'mdi-blogger',
                    collection:'siteblogs'
                    }
                },
                {
                  path: '/Classified/:id',
                  name: 'ClassifiedSingle',
                ORPlugins: ['Classifieds'],
                  component: ClassifiedSingle,
                  meta: {
                    RouteEventID: 1012,
                  }
                },
                {
                  path: '/Classified/:id',
                  name: 'ClassifiedSingle',
                  ORPlugins: ['Classifieds'],
                  component: ClassifiedSingle,
                  meta: {
                    RouteEventID: 1012,
                  }
                },
                {
                  path: '/ClassifiedEnquiry/:id',
                  name: 'ClassifiedEnquiry',
                  ORPlugins: ['Classifieds'],
                  component: Classifieds,
                  props: {
                    IsUserView: true
                  },
                  meta: {
                    RouteEventID: 1012,
                  }
                },                
                
                 {
                  path: '/Events/:id',
                  name: 'SiteEventSocialSingle',
                  ORPlugins: ['Site_Events'],
                  component: SiteEventsSocial,
                  meta: {
                    RouteEventID: 1008,
                    icon: 'mdi-calendar-month',
                    type: 'Social',
                    identifier: 'name',
                    subcat: 'Site Event',
                    viewicon: 'mdi-calendar-month',
                    }
                },
                {
                  path: '/Forums',
                  name: 'SiteForums',
                  ORPlugins: ['Site_Forums'],
                  component: SiteForums,
                  meta: {
                    RouteEventID: 1005,
                    IsTopic: true,
                    TopicCategory: 'Social Page',
                    TopicName: 'Forums'
                  }
                },
                {
                  path: '/Forum/:id',
                  name: 'SiteForumSingle',
                  ORPlugins: ['Site_Forums'],
                  component: SiteForumSingle,
                  meta: {
                    RouteEventID: 1006,
                    icon: 'mdi-forum-outline',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Forum',
                    viewicon: 'mdi-forum-outline',
                    collection:'siteforumdiscussions'
                    }
                },
                {
                  path: '/Group-Article/:id/Article/:slug',
                  name: 'GroupArticle',
                  ORPlugins: ['Site_Groups'],
                  component: GroupArticleSingle,
                  meta: {
                    RouteEventID: 1018,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Article',
                    collection:'grouparticles'
                  }
                },
                
                {
                  path: '/Group-Meetup/:id/Meetup/:slug',
                  name: 'GroupMeetup',
                  ORPlugins: ['Site_Groups'],
                  component: SiteMeetupSingle,
                  meta: {
                    RouteEventID: 1019,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Meetup',
                    collection:'groupmeetups'
                  }
                },
                {
                  path: '/GroupDashboard/:id',
                  name: 'GroupDashboard',
                  ORPlugins: ['Site_Groups'],
                  component: MyDashboard
                },
                
                {
                  path: '/Group-Blog/:id/Blog/:slug',
                  name: 'GroupBlog',
                  ORPlugins: ['Site_Groups'],
                  component: GroupBlog,
                  meta: {
                    RouteEventID: 1016,
                    IsTopic: true,
                    TopicName: 'Group Blog',
                    TopicCategory: 'Social Group',
                    Help: 'www.carebears.com',
                    collection:'groupblogs'
                  }
                },
                {
                  path: '/Group-Forum/:id/Discussion/:slug',
                  name: 'GroupForum',
                  ORPlugins: ['Site_Groups'],
                  component: GroupForumSingle,
                  meta: {
                    RouteEventID: 1020,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Forum',
                    collection:'groupforumdiscussions'
                  }
                },
                {
                  path: '/Group-Page/:id/:slug',
                  name: 'GroupPage',
                  ORPlugins: ['Site_Groups'],
                  component: GroupSingle,
                  meta: {
                    RouteEventID: 1015,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Page',
                    type: 'Group Landing Pages'
                  }
                },
                
                {
                  path: '/Group-Poll/:id/Poll/:slug',
                  name: 'GroupPoll',
                  ORPlugins: ['Site_Groups'],
                  component: GroupPollsSingle,
                  meta: {
                    RouteEventID: 1024,
                    IsTopic: true,
                    TopicCategory: 'Social Group',
                    TopicName: 'Group Poll',
                    collection:'grouppolls'
                  }
                },
                {
                  path: '/Meetup/:id',
                  name: 'SiteMeetupSingle',
                  ORPlugins: ['Site_Meetups'],
                  component: SiteMeetupSingle,
                  meta: {
                    RouteEventID: 1043,
                    icon: 'mdi-blogger',
                    type: 'Social',
                    identifier: 'title',
                    subcat: 'Site Meetup',
                    viewicon: 'mdi-blogger',
                    collection:'sitemeetups'
                    }
                },
              
            ],
      GuestRoutes: [
        {
        path: '/MyAccount',
        name: 'MyAccount',
        component: MyAccount,  
        ORPlugins: ['Public_Signup']
      },
      
      ],
      EagleRoutes: [
        {
        path: '/Ledgers/:id',
        name: 'LedgersCollection',
        component: LedgersCollection,
        meta: {
          RouteEventID: 1055,
          id: 'LedgersCollection',
          icon: 'mdi-cash-register',
          type: 'Ledgers Collection',
          subcat: 'LedgersCollection',
          viewicon: 'mdi-cash-register',
          IsTopic: true,
          TopicCategory: 'Ledgers Collection',
          TopicName: 'Ledgers Collection'
          },
             
        ORPlugins: ['Online_Store','Monetization','Group_Monetization','Group_Directories','Cash_Loans']
      }
      
      ],
      SystemTableFilters: [],
      DBNavLists: [],
      ReminderDialog: false,
      ChatbotActive: false,
      onLine: navigator.onLine,
      showBackOnline: false,

      FavoriteFeatures: [],
      FrequentlyAskedQuestions: [],
      RefreshRouteView: false,
      PublicUser: false,
      SitePages: [],
      GotEntities: false,
      RAAppAdminUserBase: '',
      RAAppAdminDialog: false,
      RAAppAdminSignedIn: false,
      RAApp: '',
      RADB: '',
      System: '',
      OnLogin: false,
      SystemEntities: [],
      
      GroupEntities: [],
      SystemGuides: [],
      SystemPhotoLibraries: [],
      SystemDocumentLibraries: [],
      HasPublicFooter: false,
      NotificationObj: '',
      SiteModerators: [],
      MyGoals: [],
      MyGoalsisEmpty: false,
      MyActivitiesArray: [],
      Notifications: [],
      snackbars: [],
      Processing: false,
      SystemLoading: false,
      SystemLoadingvalue: 0,
      RecordLoading: false,
      RecordLoadingvalue: 0,
      UploadingSingleFile: false,
      UploadingFiles: false,
      snackbarmultiLine: true,
      snackbartimeout: 4000,
      snackbarpath: '',
      snackbartext: 'Here is a snackbar',
      snackbartop: true,
      StandbyDialog: false,
      snackbar: false,
  ActiveSession: '',
  CustomRolesDefault: 
      [
      {Name: 'CustomCreateRoles',Boolean: 'CreateisCustom',Prop: 'CreatePermission',IntBoolean: 'CanCreate', Action: 'Create'},
      {Name: 'CustomGetRoles',Boolean: 'GetisCustom',Prop: 'GetPermission',IntBoolean: 'CanView', Action: 'Get'},
      {Name: 'CustomListRoles',Boolean: 'ListisCustom',Prop: 'ListPermission',IntBoolean: 'CanList', Action: 'List'},
      {Name: 'CustomUpdateRoles',Boolean: 'EditisCustom',Prop: 'EditPermission',IntBoolean: 'CanEdit', Action: 'Update'},
      {Name: 'CustomDeleteRoles',Boolean: 'DeleteisCustom',Prop: 'DeletePermission',IntBoolean: 'CanDelete', Action: 'Delete'},
      ],
      BURolesDefault: [
        {Name: 'AlllistRoles', Boolean: 'listAll', Action: 'List', PermissionLevel: 4},
        {Name: 'UnitDownlistRoles', Boolean: 'listUnitDown', Action: 'List', PermissionLevel: 3},
        {Name: 'UserUnitlistRoles', Boolean: 'listUserUnit', Action: 'List', PermissionLevel: 2},
        {Name: 'OwnerlistRoles', Boolean: 'listOwner', Action: 'List', PermissionLevel: 1},
        {Name: 'AllgetRoles', Boolean: 'viewAll', Action: 'Get', PermissionLevel: 4},
        {Name: 'UnitDowngetRoles', Boolean: 'viewUnitdown', Action: 'Get', PermissionLevel: 3},
        {Name: 'UserUnitgetRoles', Boolean: 'viewUserUnit', Action: 'Get', PermissionLevel: 2},
        {Name: 'OwnergetRoles', Boolean: 'viewOwner', Action: 'Get', PermissionLevel: 1},
        {Name: 'AllcreateRoles', Boolean: 'createAll', Action: 'Create', PermissionLevel: 4},
        {Name: 'UnitDowncreateRoles', Boolean: 'createUnitDown', Action: 'Create', PermissionLevel: 3},
        {Name: 'UserUnitcreateRoles', Boolean: 'createUserUnit', Action: 'Create', PermissionLevel: 2},
        {Name: 'OwnercreateRoles', Boolean: 'createOwner', Action: 'Create', PermissionLevel: 1},
        {Name: 'AllupdateRoles', Boolean: 'editAll', Action: 'Update', PermissionLevel: 4},
        {Name: 'UnitDownupdateRoles', Boolean: 'editUnitdown', Action: 'Update', PermissionLevel: 3},
        {Name: 'UserUnitupdateRoles', Boolean: 'editUserUnit', Action: 'Update', PermissionLevel: 2},
        {Name: 'OwnerupdateRoles', Boolean: 'editOwner', Action: 'Update', PermissionLevel: 1},
        {Name: 'AlldeleteRoles', Boolean: 'delAll', Action: 'Delete', PermissionLevel: 4},
        {Name: 'UnitDowndeleteRoles', Boolean: 'delUnitdown', Action: 'Delete', PermissionLevel: 3},
        {Name: 'UserUnitdeleteRoles', Boolean: 'delUserUnit', Action: 'Delete', PermissionLevel: 2},
        {Name: 'OwnerdeleteRoles', Boolean: 'delOwner', Action: 'Delete', PermissionLevel: 1},
      ],
      AppisDarkMode: false,
      SignedIn: true,
      GradientElements: [
        {Name: 'DB Sidebar Gradient',Prop: 'DB_SideBar_Gradient',DBNavbar: true},
        {Name: 'Guest Sidebar Gradient',Prop: 'Guest_SideBar_Gradient',GuestNavbar: true},
        {Name: 'Social Navbar Gradient',Prop: 'Social_NavBar_Gradient',SocialNavbar: true},
        {Name: 'Social Parallax Gradient',Prop: 'Social_Parallax_Gradient',SocialNavbar: true},
        {Name: 'Public Navbar Gradient',Prop: 'Public_NavBar_Gradient',PublicNavbar: true},
      ],
      GraphicalElements: [
        {Name: 'DB Sidebar Image',Prop: 'DB_SideBar_IMG',DBNavbar: true,path: require('@/assets/RAFullLogo_tiny.png')},
        {Name: 'Guest Sidebar Image',Prop: 'Guest_SideBar_IMG',GuestNavbar: true,path: require('@/assets/RAFullLogo_tiny.png')},
        {Name: 'Social Navbar Image',Prop: 'Social_NavBar_IMG',SocialNavbar: true,path: require('@/assets/RAFullLogo_tiny.png')},        
        {Name: 'Social Parallax',Prop: 'Social_Parallax',SocialNavbar: true,path: require('@/assets/RapidappsParallax.jpeg')},
        {Name: 'Social Navbar Header Image',Prop: 'Social_NavBar_Header_IMG',SocialNavbar: true,path: require('@/assets/RAFullLogo_tiny.png')},        
        {Name: 'Public Navbar Image',Prop: 'Public_NavBar_IMG',PublicNavbar: true,path: require('@/assets/RAFullLogo_tiny.png')},
      ],
      DefaultThemes: [
          {Name: 'light', Colors: [
              {Theme: 'light', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Light_Primary', DefaultColor:  '#468FBE', DefaultColorObject: {hex: '#468FBE'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Light_Secondary', DefaultColor:  '#FFCDD2', DefaultColorObject: {hex: '#FFCDD2'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Light_Accent', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Light_Record_Toolbar', DefaultColor:  '#89C8F0', DefaultColorObject: {hex: '#89C8F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'light', Name: 'background',Element_Name: 'Background',SystemProp: 'Light_Background', DefaultColor:  '#EBF0F0', DefaultColorObject: {hex: '#EBF0F0'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Light_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Light_Pop', DefaultColor:  '#FF0000', DefaultColorObject: {hex: '#FF0000'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Light_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'night',Element_Name: 'Night',SystemProp: 'Light_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Light_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Light_Social_App_NavBar', DefaultColor:  '#FFFFFF', DefaultColorObject: {hex: '#FFFFFF'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Light_Social_Menu_Font', DefaultColor:  '#ff0000', DefaultColorObject: {hex: '#ff0000'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Light_DB_Card', DefaultColor:  '#E3F2FD', DefaultColorObject: {hex: '#E3F2FD'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Light_Field_Values', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Light_Field_Labels', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Light_Database_SideNavbar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Light_Database_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Light_Database_AppBar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Light_Guest_SideNavbar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Light_Guest_Navlist', DefaultColor:  '#76FF03', DefaultColorObject: {hex: '#76FF03'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Light_Guest_AppBar', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Light_Public_Navbar_Header_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Light_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Light_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Light_Footer_BG', DefaultColor:  '#f5f5f5', DefaultColorObject: {hex: '#f5f5f5'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          },
          {Name: 'dark', Colors: [
              {Theme: 'dark', Name: 'primary',Element_Name: 'Primary',SystemProp: 'Dark_Primary', DefaultColor:  '#03111f', DefaultColorObject: {hex: '#03111f'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'secondary',Element_Name: 'Secondary',SystemProp: 'Dark_Secondary', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'accent',Element_Name: 'Accent',SystemProp: 'Dark_Accent', DefaultColor:  '#467999', DefaultColorObject: {hex: '#467999'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'recordtoolbar',Element_Name: 'Record Toolbar',SystemProp: 'Dark_Record_Toolbar', DefaultColor:  '#607D8B', DefaultColorObject: {hex: '#607D8B'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},              
              {Theme: 'dark', Name: 'background',Element_Name: 'Background',SystemProp: 'Dark_Background', DefaultColor:  '#474747', DefaultColorObject: {hex: '#474747'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'fieldcard',Element_Name: 'Field Card',SystemProp: 'Dark_Field_Card', DefaultColor:  '#707A81', DefaultColorObject: {hex: '#707A81'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'light', Name: 'links',Element_Name: 'Links',SystemProp: 'Dark_Links', DefaultColor:  '#FFA200', DefaultColorObject: {hex: '#FFA200'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pop',Element_Name: 'Pop',SystemProp: 'Dark_Pop', DefaultColor:  '#ff4500', DefaultColorObject: {hex: '#ff4500'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'morning',Element_Name: 'Morning',SystemProp: 'Dark_Morning', DefaultColor:  '#7cb9fa', DefaultColorObject: {hex: '#7cb9fa'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'night',Element_Name: 'Night',SystemProp: 'Dark_Night', DefaultColor:  '#091319', DefaultColorObject: {hex: '#091319'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappsearchbar',Element_Name: 'Social App SearchBar',SystemProp: 'Dark_Social_App_SearchBar', DefaultColor:  '#84B2C9', DefaultColorObject: {hex: '#84B2C9'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialappnavbar',Element_Name: 'Social App NavBar',SystemProp: 'Dark_Social_App_NavBar', DefaultColor:  '#55798A', DefaultColorObject: {hex: '#55798A'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'socialmenufont',Element_Name: 'Social Menu Font',SystemProp: 'Dark_Social_Menu_Font', DefaultColor:  '#03A9F4', DefaultColorObject: {hex: '#03A9F4'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: true,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbcard',Element_Name: 'DB Card',SystemProp: 'Dark_DB_Card', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: false,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'datatext',Element_Name: 'Field Values',SystemProp: 'Dark_Field_Values', DefaultColor:  '#b6e1f1', DefaultColorObject: {hex: '#b6e1f1'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'configtext',Element_Name: 'Field Labels',SystemProp: 'Dark_Field_Labels', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: false,GuestNavbar: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavbar',Element_Name: 'Database SideNavbar',SystemProp: 'Dark_Database_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbnavlist',Element_Name: 'Database Navlist',SystemProp: 'Dark_Database_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'dbappbar',Element_Name: 'Database AppBar',SystemProp: 'Dark_Database_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavbar',Element_Name: 'Guest SideNavbar',SystemProp: 'Dark_Guest_SideNavbar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestnavlist',Element_Name: 'Guest Navlist',SystemProp: 'Dark_Guest_Navlist', DefaultColor:  '#edbb34', DefaultColorObject: {hex: '#edbb34'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'guestappbar',Element_Name: 'Guest AppBar',SystemProp: 'Dark_Guest_AppBar', DefaultColor:  '#455A64', DefaultColorObject: {hex: '#455A64'},
              StriclyNav: true,GuestNavbar: true, DBNavbar: false,SocialNavbar: false,PublicNavbar: false,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavheaderbg',Element_Name: 'Public Navbar Header BG',SystemProp: 'Dark_Public Navbar Header BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbarbg',Element_Name: 'Public Navbar BG',SystemProp: 'Dark_Public_Navbar_BG', DefaultColor:  '#44a6dc', DefaultColorObject: {hex: '#44a6dc'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'pubnavbaritem',Element_Name: 'Public Navbar Item',SystemProp: 'Dark_Public_Navbar_Item', DefaultColor:  '#ffffff', DefaultColorObject: {hex: '#ffffff'},
              StriclyNav: true,DBNavbar: false,SocialNavbar: false,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
              {Theme: 'dark', Name: 'footerbg',Element_Name: 'Footer BG',SystemProp: 'Dark_Footer_BG', DefaultColor:  '#272727', DefaultColorObject: {hex: '#272727'},
              StriclyNav: true,DBNavbar: true,SocialNavbar: true,PublicNavbar: true,Social: true,DBRecords: true,DataTables: true,Pages: true},
          ]
          }
      ],
      WikiEntities: [],
      StoredDefaultCategoricalNavlists: [],
      DefaultCategoricalNavlists: [
        {
          id: 'Store_Products',
          Name: 'Store Products'
        },
        {
          id: 'Classifieds',
          Name: 'Classifieds'
        },
        {
          id: 'Group_Categories',
          Name: 'Group Categories'
        },
        {
          id: 'Group_Featured_Members',
          Name: 'Group Featured Members'
        },
        // {
        //   id: 'Classified_Entity',
        //   Name: 'Classified Entity'
        // },

      ],
      FontOptions: [],
      CatNavlistView: false,
      AppStylingView: false,
      PublicPreview: false,
      IntranetView: false,
      UserRecord: {},
      SearchView: false,
      RouterReady: false,
      CurrentRecordName: '',
      LandingPageViewActive: false,
      carddarkcolor: 'blue-grey',
      cardlightcolor: 'white',
      UserInfoRequiredDialog: false,
      NowVar: '',
      NowTime: 0,
       UserObj: '',
       rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },            
            youtubeurl: value => {
                const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
                return pattern.test(value) || "Not a Youtube Video Link.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
      UserGroupsCalled: []

    }
  },
  watch: {
    SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['ActivateUploadingSingleFileNotification','CommitSnackbar','ActivateProcessing']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    UserisCompanyAdmin(v){  
      if(v){
        let companyaccountspayload = {                
          query: db.collection('billingaccounts').where('Companyid', '==', this.MyCompany.id),
          arrayname: 'CompanyBillingAccounts',
          }
          this.$store.dispatch('GetCollectionArray',companyaccountspayload).then(function(result) {
          })
      }
    },
    CompanyBillingAccounts: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                //actually CompanyLoanEntities and CompanyStoreEntities first
                //then for each a query to either CompanyLoanOrders and or CompanyStoreOrders
                //however still check if user is copmanyadmin first as store ent and shit set on memgroup NOT on company
                //unless that's the missing link though?
                //okay if it is per company that we set the LoanEntities and StoreEntities for the admin...then we also need to set a default thereof
                //but I do agree with this because memgroup cannot know if the users are in fact company admins
                let arrayquery = []
                this.CompanyBillingAccounts.map(ba => {
                  //even so Bob, we don't know the TrackedEntity....???
                  arrayquery.push(db.collection(this.userLoggedIn.TrackedEntity.split('_').join('').toLowerCase()).where('Companyid','==',this.MyCompany.id))
                  //CompanyTrackingOrders
                })
                let payload = {                
                query: arrayquery,
                arrayname: 'CompanyTrackingOrders',
                }
                //really not, this won't work
                // this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
                //   //console.log(vm.$store.state.RAPlugins)
                // })
              }
            },deep: true
    },
    UserMemberGroup: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('SetActiveMemberGroup',this.UserMemberGroup)
                if(this.UserMemberGroup && this.UserMemberGroup.AdditionalProperties.InviteGroups && this.UserMemberGroup.AdditionalProperties.InviteGroups.length > 0){
                  let membergroupspayload = {                
                    query: db.collection('membergroups').where(firebase.firestore.FieldPath.documentId(), 'in', this.UserMemberGroup.AdditionalProperties.InviteGroups),
                    arrayname: 'MemberGroupsArray',
                    }
                    // membergroupspayload.query = this.UserMemberGroup.InviteGroups.map(grp => {
                    //   return db.collection('membergroups')
                    // })
                    this.$store.dispatch('GetCollectionArray',membergroupspayload).then(function(result) {    
                      //console.log(vm.$store.state.RAPlugins)
                    })
                }
              }
            },deep: true
    },
    ComputedActivePlugins: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){    
                this.GetPluginRoutes('PublicSocialRoutes')  
                if(this.userLoggedIn){
                  this.GetPluginRoutes('SignedInSocialRoutes')  
                }          
                if(this.UserisGuest){
                  this.GetPluginRoutes('GuestRoutes')
                }
                else if(!this.userIsEagleViewer && !this.userIsAdmin){
                  this.GetPluginRoutes('STDUserRoutes')
                  this.AddNonGuestRoutes()
                }
                else if(this.userIsEagleViewer && !this.userIsAdmin){
                  this.GetPluginRoutes('STDUserRoutes')
                  this.GetPluginRoutes('EagleRoutes')
                  this.AddNonGuestRoutes()
                }
                else if(this.userIsAdmin){
                  //AddAdminRoutes
                  this.GetPluginRoutes('GuestRoutes')
                  this.GetPluginRoutes('EagleRoutes')
                  this.GetPluginRoutes('AdminRoutes')
                  this.AddNonGuestRoutes()                  
                }
              }
            },deep: true
    },
    ComputedApps: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                console.log(newvalue, oldvalue)
                this.$store.commit('setAppsDataBase',newvalue) 
              }
            },deep: true
    },
    ComputedPluginObj: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('setPluginDataBase',newvalue) 
              }
            },deep: true
    },
    UserGroups: {
            handler: function(oldvalue, newvalue) {
              if(oldvalue !== newvalue){
                if(this.UserGroups){
                    this.UserGroups.map(group => {
                      if(group.Administrator || group.IsTeamMember){
                        let oncheck = this.UserGroupsCalled.find(obj => obj === group.id)
                        if(!oncheck){
                         this.UserGroupsCalled.push(group.id)
                          this.GetEntities(db.collection('groups').doc(group.id).collection('entities'),'GroupEntities',group.id)
                        }
                      }
                    })
                  }
              }
            },deep: true
        },
    ComputedCategoricalNavlists: {
            handler: function(oldvalue, newvalue) {
              //alert('change navlists')
              if(oldvalue !== newvalue){
                //console.log('ComputedCategoricalNavlists ',oldvalue, newvalue)
                //console.log('store groupnavlist',groupnavlist,this.ComputedCategoricalNavlists)
                let groupnavlist = this.ComputedCategoricalNavlists.find(obj => obj.id === 'Group_Categories')
                //console.log('store groupnavlist',groupnavlist,this.ComputedCategoricalNavlists)
                if(groupnavlist){
                  let entities = this.$store.state.SocialEntities
                  let groupentity = entities.find(obj => obj.id === 'Site_Groups')
                  if(groupentity){
                    //let index = entities.indexOf(groupentity)
                    let additionalfields = groupnavlist.AdditionalFields
                    if(additionalfields){
                     let length = additionalfields.length
                    //console.log('store',length)
                    if(length > 0){
                      additionalfields.map((field,fieldindex) => {
                        let oncheck = groupentity.AllFields.find(obj => obj.id === field.id)
                        if(!oncheck){
                          groupentity.AllFields.push(field)
                        }                        
                        if(fieldindex-1+2 === length){
                          //console.log('store',entities)
                          this.$store.commit('setSocialEntities',entities)
                        }
                      })          
                    } 
                    }
                    
                  }
                }   
              }
            },
            deep: true
        },
    ReminderNotifications: {
            handler: function(oldvalue, newvalue) {
              
              if(oldvalue !== newvalue){
                //console.log('NowTime checking ReminderNotifications oldvalue, newvalue',oldvalue, newvalue) 
                if(oldvalue.length > 0 && this.NowTime !== 0 && !this.userLoggedIn.TaskNotificationsDisabled){
                  //console.log('NowTime !0 ReminderNotifications oldvalue, newvalue',oldvalue, newvalue)
                      this.ReminderDialog = true
                }
                else{
                  //console.log('no dial ReminderNotifications oldvalue, newvalue is 0 ',oldvalue, newvalue)
                  this.ReminderDialog = false
                }                 
              }
            },
            deep: true
        },
    MyActivitiesArray: {
            handler: function(oldvalue, newvalue) {
              
              if(oldvalue || newvalue){
                //console.log('MyActivitiesArray oldvalue, newvalue',oldvalue, newvalue)
                // oldvalue.map(item => {
                //   if(item.Has_Reminder && !this.ReminderDialog){
                //     setTimeout(() => {
                //       this.ReminderDialog = true  
                //       }, 5000);
                //   }
                // })
              }
            },
            deep: true
        },
    onLine(v) {
            if (v) {
                this.showBackOnline = true;
                setTimeout(() => {
                    this.showBackOnline = false;
                }, 1000);
            }
        },
    UILibDarkMode(v) {
            if (v) {
               this.UpdateLinksColor()
            }
            else{
              this.UpdateLinksColor()
            }
        },
    System : {
            handler: function(newvalue, oldvalue) {
              this.UpdateLinksColor()
              if(newvalue.AppDefaultHeader){
                this.SetHeaderFontStyles(newvalue.AppDefaultHeader)
              }
              else{
                this.SetHeaderFontStyles({Name: 'Roboto',SizeMultiplier: 1})
              }
              if(newvalue.AppDefaultBody){
                this.SetBodyFontStyles(newvalue.AppDefaultBody)
              }
              else{
                this.SetBodyFontStyles({Name: 'Roboto',SizeMultiplier: 1})
              }
              if(newvalue){
                let systempayload = {
                    system: this.System,
                }
                this.$store.commit('SetSystemObj', systempayload)
                 if(this.System.DefaultCurrency){
                  this.$store.commit('SetSystemDefaultCurrency', this.System.DefaultCurrency)
                }
              }
              if(this.System.Has_Public_Signup && !this.userLoggedIn){
                let signuproute = {
                  path: '/SignUp',
                  name: 'signup',
                  component: SignUp
                }
                this.$router.addRoute(signuproute)
              }
              
            },
            deep: true
    },
    RouteObj: {
            handler: function(newvalue, oldvalue) {
                if(oldvalue !== newvalue){
                  let IsGroupView = false
                  this.$store.commit('SetActiveChatRecord','')
                  if(this.$store.state.GroupRoutes.includes(this.$route.name)){
                    IsGroupView = true
                  }
                  else{
                    this.$store.commit('setActiveGroup','')
                    this.$store.commit('setUserMemberObj','')
                    this.$store.commit('setGroupForbidden',false)
                  }
                  this.$store.commit('setIsGroupView',IsGroupView)
                  let clearstoresearch = true 
                  if(oldvalue.name === 'StoreItemSingle'){
                    clearstoresearch  = false
                  }
                  if(clearstoresearch){
                    this.$store.commit('SetStoreSearch','')
                  }  
                  if(this.$route.name !== 'StoreItemSingle' && this.$route.meta && this.$route.meta.RouteEventID !==1027 && this.StoreView){
                    this.StoreView = false
                  }              
                  this.$store.commit('SetViewOrderNavbar',false)
                  this.$store.commit('SetCanReceive',false)
                  this.$store.commit('SetCanViewMovementHistory',false)
                  this.$store.commit('SetCurrentItemModerator','')
                  this.$store.commit('SetStoreItemPublisher','')                  
                  this.$store.commit('SetCurrentEntity','')
                  this.$store.commit('SetCurrentDataTabs',[]) 
                  this.$store.commit('SetSingleNavTabs',[])  
                  this.$store.commit('SetSelectedDataTabs',[])                
                  if(this.$route.meta && this.$route.meta.RouteEventID === 1027 && this.userIsAdmin){
                    this.$store.commit('SetCurrentItemModerator',true)
                  }
                  if(this.CatNavlistView && newvalue.name !== 'DirectoryBuilder'){
                    this.ToggleCatNavlistView()
                  }
                }
            },
            deep: true
        },
      //db.collection('billingaccounts').doc(payload.userid).collection('credittokens').where('Active','==',true).orderBy('Date','asc')
      
      UserAvailableTokens: {
            handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                this.$store.commit('setUserAvailableTokens',newvalue)
                console.log(this.$store.state.UserAvailableTokens)
              }
               },
            deep: true
        },
      userLoggedIn: {
            handler: function(newvalue, oldvalue) {
              let vm = this
        if (newvalue !== null && newvalue !== undefined && newvalue.id && !oldvalue || 
        newvalue !== null && newvalue !== undefined && newvalue.id && oldvalue && !oldvalue.id) {
          if(this.userLoggedIn.Company){
            this.GetMyCompany()
          }
          this.GetTrackingOrders()
          this.GetSentMessages()
          this.GetReceivedMessages()
          if(this.SystemisRA && this.$store.state.RAPlugins.length === 0){
              let pluginquery = db.collection('plugins')
              let pluginpayload = {                
                query: pluginquery,
                arrayname: 'RAPlugins',
                provider: 'RapidApps',
                Subcolpath: 'carouselitems',
                SubcolName: 'CarouselItems'
                }
                this.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
            }
          let newpath = this.$store.state.ReroutePath
           if(this.userLoggedIn && newpath){
             if(this.$route.path !== newpath){
               this.$store.commit('setReroutePath', '') 
               this.$router.push(newpath)
             }
            }
            if(!this.GotConfigMaster && this.userIsAdmin){
              this.CheckConfigSystemTabs()
            }            
          this.PrepareSocialEntities()
          this.GetSubscriptionPackages()
          if(!this.UserisGuest){
            if(this.userIsAdmin && this.$route.name !== 'GettingStarted'){
                //this.$router.push('/Getting-Started')
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                if(vm.userLoggedIn.Primary_Appid){
                  vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                }
                }
              }
             else if(vm.userLoggedIn.Primary_Appid){
               if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                  vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
               }
              }
              //replacing GetApps
             let pluginpayload = {                
              query: this.AppsPath,
              arrayname: 'AppsDataBase',
              SubCollections: [
                {
                  Subcolpath: 'plugins',
                  SubcolName: 'Plugins'
                },
                {
                  Subcolpath: 'sharedplugins',
                  SubcolName: 'SharedPlugins'
                }
              ]              
              }
              this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                console.log(vm.$store.state.AppsDataBase)
              }) 
            this.AddNonGuestRoutes()
            if(this.userLoggedIn.ModerationPermissions){
              let permissions = this.userLoggedIn.ModerationPermissions
              //console.log('this.userLoggedIn.ModerationPermissions',permissions)
              if(permissions.includes('Banner Ads')){
                this.AddBannerAdsModeratorRoutes()
              }
              if(permissions.includes('Landing Pages')){
                this.AddLandingPageModeratorRoutes()
              }              
            }
            
          }
          else{
            this.GetPluginRoutes('GuestRoutes')
            let pluginpayload = {                
              query: this.AppsPath,
              arrayname: 'AppsDataBase',
              SubCollections: [
                {
                  Subcolpath: 'plugins',
                  SubcolName: 'Plugins'
                },
                {
                  Subcolpath: 'sharedplugins',
                  SubcolName: 'SharedPlugins'
                }
              ]              
              }
              this.$store.dispatch('GetArrayandMultipleSubcols',pluginpayload).then(function(result) {    
                console.log(vm.$store.state.AppsDataBase)
              })
          }
          this.GetPluginRoutes('STDUserRoutes')
          
         //this.CheckAuth()
         this.UserObj = Object.assign({},newvalue)
         if(this.System.User_Location_Enabled){
           this.CheckLocation()
         }
        this.GetWarehouses()
        }
      },deep: true
      },
      
      userIsEagleViewer (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          this.GetPluginRoutes('EagleRoutes')
        }
      },
      userIsAdmin (value){
        //console.log('userIsAdminuserIsAdminuserIsAdminuserIsAdmin')
        if(value){
          let membergroupspayload = {                
                  query: db.collection('membergroups'),
                  arrayname: 'MemberGroupsArray',
                  }
                  this.$store.dispatch('GetCollectionArray',membergroupspayload).then(function(result) {    
                    //console.log(vm.$store.state.RAPlugins)
                  })
          this.GetPluginRoutes('GuestRoutes')
          this.GetPluginRoutes('EagleRoutes')
          this.GetPluginRoutes('AdminRoutes')
          this.GetPluginRoutes('STDUserRoutes')
          console.log(this.$store.state.RAPlugins)
        }
      },
      // RouterReady (value){
      //   if(value){
      //     this.SystemLoading = false
      //   }
      // },
    
    UserInfoRequired (value) {
        if (value !== null && value !== undefined) {
         this.UserInfoRequiredDialog = true
        }
      },
    UserBillingAccounts: {
            handler: function(newvalue, oldvalue) {
              //console.log('WarehousesArray',oldvalue !== newvalue,oldvalue, newvalue)
              if(oldvalue !== newvalue){
                //WHY IN THE WORLD DID WE HAVE THIS?/?
                // let statearray = this.$store.state.BillingAccountsArray
                // if(!statearray || statearray && statearray.length === 0){
                //   let arraypayload = {
                //     arrayname : 'BillingAccountsArray', 
                //     array: []
                //   }
                //   arraypayload.array = newvalue
                //   this.$store.commit('setCollectionArray',arraypayload)
                // }
                
              }
            },deep: true
        },
    WarehousesArray: {
            handler: function(oldvalue, newvalue) {
              //console.log('WarehousesArray',oldvalue !== newvalue,oldvalue, newvalue)
              if(oldvalue !== newvalue || oldvalue.length > 0){
                this.WarehouseQueries()
              }
            },deep: true
        },
    },
  mounted() {
        
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    },
    beforeDestroy() {
        window.removeEventListener('online', this.updateOnlineStatus);
        window.removeEventListener('offline', this.updateOnlineStatus);
    },
  
  computed: {
    CompanyBillingAccounts(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.CompanyBillingAccounts
      },
    UserisCompanyAdmin(){
        //console.log('this.MyCompany',this.MyCompany)
        return this.$store.state.UserisCompanyAdmin
      },
    NonSocialGuest(){
      return this.userLoggedIn && this.UserisGuest && !this.CanSocial && this.RouterReady && this.$route.name !== 'MyAccount'
    },
    GuestNavbarView(){
      return this.userLoggedIn && this.UserisGuest && !this.CanSocial && this.RouterReady || this.$route.name === 'MyAccount'
    },
    MyCompany(){
      return this.$store.state.MyCompany
    },
    InviteGroups(){
      return this.ActiveMemberGroup ? this.ActiveMemberGroup.AdditionalProperties.InviteGroups : []
    },
    ActiveMemberGroup(){
      return this.$store.state.ActiveMemberGroup
    },
    CanSocial(){
        return this.UserisGuest && !this.ActiveMemberGroup && this.System.Guests_can_Social || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial
      },
    ActiveMemberGroupAllowedChannels(){
      return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels ? this.ActiveMemberGroup.SocialChannels : ''
    },
    CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
    UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
    UserHasMemberGroup(){
      return this.userLoggedIn && this.userLoggedIn.MemberGroupid
    },
    UserMemberGroup(){
      return this.UserHasMemberGroup ? this.userLoggedIn.MemberGroup : ''
    },
    ConfigMasterPath(){
      return this.SystemisRA ? db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID) : 
      this.RADB.collection('SystemConfig').doc(process.env.VUE_APP_RA_PROVIDER_ID)
    },
    UILibDarkMode(){
      return this.$vuetify.theme.dark
    },
     SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    MiniView(){
        return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
      },
    CurrentEntity(){
      return this.$store.state.CurrentEntity
    },
    RecordChatView(){
        return this.ActiveChatRecord
      },
    ActiveChatRecordOperationalDB(){
      return this.ActiveChatRecord && this.ActiveChatRecord.id ? 
          db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.ActiveChatRecord.id)
        : ''
    },
    ActiveChatRecord(){
      return this.$store.state.ActiveChatRecord
    },
    ActiveSuiteApp(){
      return this.$store.state.ActiveSuiteApp
    } ,   
    BreadCrumbsActive(){
      return this.SignedIn && !this.StoreView && !this.IntranetView && this.RouterReady && !this.UserisGuest && !this.DBBuilderView && !this.AppBuilderView
    && this.$route.name !== 'bannerads' && this.$route.name !== 'MyDashboard' && this.$route.name !== 'MyAccount' && this.$route.name !== 'DashboardBuilder' && this.$route.name !== 'SystemConfig'
    && this.$route.name !== 'MyProfile' && this.$route.name !== 'GroupDashboard' && !this.CatNavlistView && this.$route.name !== 'Monetization'&& this.$route.name !== 'GroupPageEditor'
    && this.$route.name !== 'PageEditor' && this.$route.name !== 'GettingStarted' && !this.ActiveSuiteApp
    },
    ShowChatbot(){
      return this.$route.name !== 'Monetization' && this.$route.name !== 'ChatbotBuilder'
     && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && this.$route.name !== 'GroupEdit' 
     && this.$route.name !== 'DocumentationLibrary' && this.$route.name !== 'DocumentationLibraryEditor'
     && this.SignedIn && this.RouterReady && !this.StoreView && this.ChatbotPluginActive
    },
    ChatbotPluginActive(){
      return this.PluginDataBase && this.PluginDataBase.Chatbot && this.PluginDataBase.Chatbot.Active
    },
    AppQuickStartDialog(){
      return this.$store.state.AppQuickStartDialog
    },
    RAAdmin(){
        return this.RAUserObj && this.RAUserObj.rolesarrayDBRules && this.RAUserObj.rolesarrayDBRules.SystemAdmin
    },
    RAUserObj(){
        return this.$store.state.RAUserObj
    },
    AppsDataBase(){
            return this.$store.state.AppsDataBase
        },
        AllAppPlugins(){
        return this.AppsDataBase.map(app => {
            let appobj = Object.assign({},app)
            return appobj.Plugins
        }).flat()
        },
        AllAppPluginsIDs(){
          return this.AllAppPlugins.map(plug => {
            return plug.id
          })
        },
        SuitehasWarehouse(){
        return this.AllAppPlugins.find(obj => obj.id === 'Warehouse_&_Products')
        },
    ComputedActivePlugins(){
      return this.ActivePlugins.filter(plug => {
        //stupid, since already only call "Active" on query...BUT...because compute the prop we can watch
        return plug.Active
      })
    },
    ComputedPluginObj(){
      let plugindb = {}
      this.ActivePlugins
      .map(plug => {
        plugindb[plug.id] = plug
      })
      return plugindb
    },
    ActiveDocumentationLibrary(){
            return this.$store.state.ActiveDocumentationLibrary
        },
    GroupidRoutes(){
            return this.$store.state.GroupidRoutes
        },
        GroupslugRoutes(){
            return this.$store.state.GroupslugRoutes
        },
        GroupID(){
            if(this.GroupidRoutes.includes(this.$route.name)){
                return this.$route.params.id
            }
            else if(this.$route.query && this.$route.query.GroupID){
                return this.$route.query.GroupID
            }
            else if(this.GroupslugRoutes.includes(this.$route.name)){
                return this.$route.params.slug
            }
        },
    SystemConfigEntity(){
      return this.$store.state.SystemConfigEntity
    },
    AppBuilderView(){
      return this.WebsiteBuilderView || this.DBBuilderView || this.NetworkBuilderView || this.DocLibBuilderView
      || this.$route.name === 'GettingStarted' ||  this.$route.name === 'ChatbotBuilder' ||  this.$route.name === 'FAQAdmin'
      ||  this.$route.name === 'Glossary' && !this.IntranetNavView
    },
    BuilderView(){
    return this.$store.state.BuilderView
    },
    SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
    AppsPath(){
      return this.$store.state.AppsPath
    },
    PluginsPath(){
      return this.$store.state.PluginsPath
    },
    PluginDataBase(){
      return this.$store.state.PluginDataBase
    },
    DocLibBuilderView(){
        return this.BuilderView && this.BuilderView.Name === 'Documentation Builder'
      },
    WebsiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Website Builder'
    },
    SuiteBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Suite Builder'
    },
    DBBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Database Builder'
    },
    NetworkBuilderView(){
      return this.BuilderView && this.BuilderView.Name === 'Social Network Builder'
    },
    UserMarketAccount(){
      return this.UserBillingAccounts ? this.UserBillingAccounts.find(obj => obj.Account_Type && obj.Account_Type.Name === 'Market Account') : ''
    },
    IsGroupView(){
      //STDUserRoutes
        return this.$store.state.IsGroupView && this.RouterReady
    },
    UserBillingAccounts(){
        return this.userLoggedIn && this.userLoggedIn.BillingAccounts ? this.userLoggedIn.BillingAccounts : ''
      },
    WarehousesArray(){
      return this.$store.state.WarehousesArray
    },
    UserGroups(){
      return this.userLoggedIn && this.userLoggedIn.groups ? this.userLoggedIn.groups : ''
    },
    CurrentItemModerator(){
      return this.$store.state.CurrentItemModerator
    },
    CurrentisFavorite(){
      let path = this.$route.path
      if(this.userLoggedIn.FavoriteLinks){
      return this.userLoggedIn.FavoriteLinks.find(obj => obj.Path === path)       
      }
      else{
        return false
      }
    },
    
    ActivityStatusField(){
      let activityentity = this.SystemEntities.find(obj => obj.id === 'Activities')
      if(activityentity){
        return activityentity.AllFields.find(obj => obj.id === 'Status')
      }
    },
    ReminderNotifications(){
      //console.log('this.MyActivitiesArray',this.MyActivitiesArray)
      return this.MyActivitiesArray.filter(task => {
        return task.Has_Reminder
      })
      .filter(task => {
        if(task.End){
          let endataobj = task.End.toDate()
          let endtime = endataobj.getTime()
          return endtime > this.NowTime
        }
        else{
          return task
        }
      })
      .map(task => {
        if(task.Start){
          task.Due_Date = task.Start
        }
        return task
      })
      .filter(task => {   
        //console.log('task',task)     
        let snoozetime = this.NowTime-1
        if(task.Snoozed_Until){
          let snoozedataobj = task.Snoozed_Until.toDate()
          snoozetime = snoozedataobj.getTime()
        }
        //console.log('snoozetime vs this.NowTime',snoozetime,this.NowTime)        
        return snoozetime < this.NowTime && task.Status_Reason.Name !== 'Completed' && task.Status_Reason.Name !== 'Cancelled'
      }).map(task => {
        let today = new Date()
        if(task.Due_Date.toDate()<today){
            task.overdue = true
          }
        task.monthdue = this.TimestampFormatterNoticeBoard(task.Due_Date.toDate(),'month')
        task.daydue = this.TimestampFormatterNoticeBoard(task.Due_Date.toDate(),'date')
          return task
      })
    },
    RouteObj(){
    return this.$route
  },
    UserAvailableTokens(){
      return this.userLoggedIn ? this.userLoggedIn.CreditTokens
      .filter(tokpurch => {
        return tokpurch.Remaining_Tokens > 0
      })
      .map(tokpurch => {
        return tokpurch.Remaining_Tokens
      }).reduce((a, b) => a + b, 0) : 0
    },
    
    UserTableFilters(){
      return this.userLoggedIn.UserTableFilters
    },
    ComputedCategoricalNavlists(){
      let arr0 = this.DefaultCategoricalNavlists.filter(navlist => {
        let oncheck = this.StoredDefaultCategoricalNavlists.find(obj => obj.id === navlist.id)
        return !oncheck
      })
      let arr1 = this.StoredDefaultCategoricalNavlists
      let arr2 = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
      })
      .map(entity => {
        let obj = {
          id: entity.id,
          Name: entity.DisplayName
        }
        return obj
      })
      .filter(navlist => {
        let oncheck = this.StoredDefaultCategoricalNavlists.find(obj => obj.id === navlist.id)
        return !oncheck
      })
      return arr0.concat(arr1,arr2)
    },
    AppStyling(){
      if(!this.PublicUser && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor'){
        return 'padding-bottom:50px;'
      }
      else{
        return ''
      }
    },
    IntranetRouteStyling(){
      return this.$route.name === 'MyAccount' ? '' : 'z-index:1;'
    },
    cssProps () {
      var themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      return themeColors
   },
   ActiveNavbar(){
     if(this.$route.name === 'MyAccount'){
       return 'Guest Navbar'
     }
     else if(this.DBView){
       return 'DB Navbar'
     }
     else if(this.IntranetNavView){
       return 'Social Navbar'
     }
     else if(this.PublicNavView){
       return 'Public Navbar'
     }
   },
   IntranetNavView(){
     if(this.CanSocial){
        return this.IntranetView && this.RouterReady && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && this.$route.name !== 'Checkout' && !this.SuiteBuilderView && !this.WebsiteBuilderView
     }
     else if(this.System.Guests_can_Social){
        return this.SignedIn && this.IntranetView && this.RouterReady && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && this.$route.name !== 'Checkout' && !this.SuiteBuilderView && !this.WebsiteBuilderView
     }
     else{
       return this.SignedIn && this.IntranetView && this.RouterReady && !this.UserisGuest && !this.PublicPreview && !this.CatNavlistView && !this.StoreView && !this.SuiteBuilderView && !this.WebsiteBuilderView
     }     
   },
   WebsiteisActive(){
     return this.$store.state.WebsiteisActive
   },
   SocialNetworkisActive(){
     return this.$store.state.SocialNetworkisActive
   },
   DatabaseisActive(){
     return this.$store.state.DatabaseisActive
   },
   DocumentationisActive(){
     return this.$store.state.DocumentationisActive
   },
   ShowPublicNavbar(){
     return this.WebsiteisActive && this.PublicNavView
   },
   PublicNavView(){
     return !this.LandingPageViewActive && !this.SignedIn && !this.AppStylingView && !this.IntranetView && this.RouterReady && !this.CatNavlistView && this.$route.name !== 'GroupPage' && !this.StoreView
     || this.PublicPreview && !this.CatNavlistView && !this.StoreView || this.WebsiteBuilderView && !this.IsGroupView && this.$route.name !== 'WebFormEditor' && this.$route.name !== 'WebFormBuilderNew'
     && !this.$route.meta.DataSingleBuilder
     || this.PublicPreview && !this.CatNavlistView && !this.StoreView || this.WebsiteBuilderView && !this.IsGroupView && this.$route.name !== 'WebFormEditor' && this.$route.name !== 'WebFormBuilderNew'
     && this.$route.name === 'SystemConfig'
   },
    DBView(){
      return this.SignedIn && !this.IntranetView && this.RouterReady && !this.UserisGuest && !this.PublicPreview && !this.CatNavlistView && this.$route.name !== 'MyAccount'
      && this.$route.name !== 'GroupPage' && !this.StoreView && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && !this.AppBuilderView && !this.ActiveSuiteApp
      || this.WebsiteBuilderView && this.$route.meta && this.$route.meta.DataSingleBuilder && this.$route.name !== 'SystemConfig'
    },
    SuiteAppView(){
      return this.ActiveSuiteApp && this.$route.name !== 'GettingStarted' && !this.WebsiteBuilderView && !this.DocLibBuilderView
    },
    GroupNavView(){
      return this.$route.name === 'GroupPage' || this.$route.name === 'GroupPageEditor'
    },
    
    ComputedRouteStyle(){
      if(this.userLoggedIn && this.$route.name !== 'MyAccount' && !this.PublicPreview){
        return ''
      }
      else if(this.userLoggedIn && this.$route.name === 'MyAccount'){
        return 'z-index:1;'
      }
      else if(this.StoreView){
        return ''
      }
      else if(this.PublicPreview){
        return 'margin-top:198px;'
      }
      else if(this.LandingPageViewActive){
        return ''
      }
      else if(!this.WebsiteisActive){
        return ''
      }
      else{
        return !this.System.PublicNavbarStyle || this.System.PublicNavbarStyle === 'Default' ? 'margin-top:219px;' : 
        this.ActivePage && this.ActivePage.ClearNavbarColor ? '' : 'margin-top:100px;'
      }
    },
    AltPubNavbar(){
      return this.System.PublicNavbarStyle === 'STD Bar' && this.PublicNavView
    },
    ActivePage(){
      let page = this.SitePages.find(obj => obj.Name.split(' ').join('') === this.$route.path.split('/').join('').split('-').join(''))
      if(this.$route.path === '/' && this.System.Public_Landing_Page){
        return this.SitePages.find(obj => obj.Name === this.System.Public_Landing_Page)
      }
      else {
         return page
      }
      //console.log('page',page,this.SitePages,this.$route.path,this.$route.path === '/')
      return page
    },
    PublicFooterEngage(){
      return this.HasPublicFooter && !this.userLoggedIn && this.RouterReady && this.$route.name !== 'login' && this.$route.name !== 'PhoneLogin' && this.$route.name !== 'signup' && this.$route.name !== 'UserInvite'
    },
    UserRequiredInfo(){
    return this.$store.state.UserRequiredInfo
  },
  UserInfoRequired(){
    return this.$store.state.UserInfoRequired
  },
  AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return this.carddarkcolor
        }
        else{
          return this.cardlightcolor
        }
      },
  UnreadNotifications(){
            return this.Notifications.filter(not => {
                return !not.Read
            })
        },
  UserisGuest(){
    return this.userLoggedIn ? this.userLoggedIn.IsGuestUser : 'undefined' 
    
  },
  FriendsList(){
    // if(this.userLoggedIn && this.userLoggedIn.id){
      return this.UsersStore.map(user => {
          this.FriendshipsStore.map(friendship => {
            //console.log(user.id, friendship[user.id])
          if(friendship[user.id]){
            user.IsFriend = true
          }
        })
        return user
    }).filter(user => {
      // console.log(user)
      // console.log(this.userLoggedIn,user.id)
      return user.id !== this.userLoggedIn.id && user.IsFriend
    })
    // }
    
  },
   FriendshipsStore() {
      return this.$store.state.Friendships ? this.$store.state.Friendships : []
    },
  UsersStore(){
            return this.$store.getters.getUsersArray
        },
  userLoggedIn () {
      return this.$store.getters.user
    },
    AppMonetizeBU(){
            return this.System.Monetization_BU ? this.System.Monetization_BU : ''
        },
  userBUID () {
    return this.$store.getters.userBUID
  },
  userBUChildren () {
    return this.$store.getters.userBUChildren
  },
  userBUParents () {
    return this.$store.getters.userBUParents
  },
  userIsAdmin () {
    return this.$store.state.IsAdmin
  },
  userIsEagleViewer () {
    return this.$store.state.IsEagleView
  },
  RendersClouds(){
    if(this.$route.name === 'MyProfile' || this.$route.name === 'MyAccount' || this.$route.name === 'login' || this.$route.name === 'PhoneLogin' || this.$route.name === 'signup' || this.$route.name === 'UserInvite' || this.$route.name === 'PasswordReset' || this.$route.meta && this.$route.meta.type === 'Data Tables'){
      if(!this.System.RemoveRenderClouds && this.$route.name !== 'businessunits' && this.$route.name !== 'users'){
        return true
      }
    }
    else{
      return false
    }
  },
  SunorMoonTexture(){
      var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10) {
          return  ``
        } 
        else if (time < 18) {
          return  ``
        } else {
          return  ``
        }
        
  
    },
    SunorMoon(){
      var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10 && !this.userLoggedIn) {
          return  'MorningSun'
        } 
        else if (time < 18 && !this.userLoggedIn) {
          return  'MiddaySun'
        } else if(!this.userLoggedIn) {
          return  'Moon'
        }
        
  
    },
    BGImageClass(){
      if(this.System && this.SystemisRA && this.$route.meta && this.$route.meta.SystemBuilder
      || this.System && this.SystemisRA && this.$route.meta && this.$route.meta.EntityBuilder
      || this.$route.meta.TableBuilder){
        return 'BuilderGradient footermargin relative'
      }
      else if(this.$route.params.id && this.$route.name !== 'PageEditor' && this.$route.name !== 'GroupPageEditor' && this.$route.name !== 'landingpagesingle' && this.$route.name !== 'newlandingpage' && this.$route.name !== 'GroupPage'
      && !this.ActiveSuiteApp){      
        if(this.$route.name === 'StoreItemSingle'){
          return 'BGImage relative'
        }
        else if(this.$route.name === 'SystemConfig' || this.$route.name === 'GroupEdit'){
          return 'background BGImage relative'
        }
        else{
         return 'BGImage footermargin relative' 
        }
        
      }
      else if(this.$route.name === 'MyDashboard'){
        return 'BGImage relative'
      }
      else if(this.userLoggedIn && this.$route.meta && this.$route.meta.type === 'Pages' || this.userLoggedIn && this.$route.name === 'PageEditor'){  
         //alert('yay')
          return 'background'
        }
      else if(!this.RendersClouds){
        return 'background'
      }
      else{
        var daytimecolor;
        var time = new Date().getHours();
        //console.log(time)
        if (time < 10) {
          daytimecolor = "morning";
        } 
        else if (time < 18) {
          daytimecolor = "blue"
        } else {
          daytimecolor = 'night'
        }  
        if(!this.userLoggedIn && this.$route.meta && this.$route.meta.type === 'Pages'){     
          return 'background'
        }
        else if(!this.userLoggedIn){     
          return 'BGImage relative '+daytimecolor
        }
        
        else{
          return 'BGImage footermargin relative '+daytimecolor
        }
      }
    },
    ShowGroupsasPortal(){
      return !this.System.GroupSinglePortal
    },
    ShowIntranetNavbar(){
      if(this.ShowGroupsasPortal){
        return this.IntranetNavView && !this.SearchView && this.$route.name !== 'GroupSingle' && this.$route.name !== 'DirectoryBuilder' && !this.ActiveSuiteApp
      }
      else{
        return this.IntranetNavView && !this.SearchView && !this.ActiveSuiteApp
      }
      
    },
    StyleMargin(){
      if(!this.ShowIntranetNavbar){
        return this.AppStylingView? 'margin-top:60px;' : ''
      }
      else if(this.$route.meta && this.$route.meta.RouteEventID === 1027){
        if(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm){
          return this.AppStylingView? 'margin-top:340px;padding-bottom:180px;' : 'margin-top:225px;padding-bottom:50px;'
        }
        else{
          return this.AppStylingView? 'margin-top:340px;padding-bottom:180px;' : 'margin-top:225px;padding-bottom:50px;'
        }
        
      }
      else if(this.ShowIntranetNavbar){
        return this.$route.name === 'GroupSingle' && this.ShowGroupsasPortal || this.$route.name === 'DirectoryBuilder' ? 'padding-bottom:50px;' : 'margin-top:225px;padding-bottom:50px;'
      }
      else{
        return this.AppStylingView? 'margin-top:340px;' : 'margin-top:205px;'
      }
      
    },
    BGImageCurrent(){
      if(this.$route.params.id || this.IntranetView){
        if(this.AppisDarkMode){
       return 'background'
       
        }
        else{
          return 'background'
       
        }
      }
      else{
        return ''
      }
    }
  },
  created(){
    console.log(this.$store.state)
    // let root = document.documentElement;
    // root.style.setProperty('--app-linkscolor', `#FFA200`)
    //If not website only, then auth matters
  //   let Warehouseid = 'Z8WSyTNan523HzF3GOWk'
  //   let StockObj = 'gu4iIIppi9UHHC5JCzHl'
  //   db.collection('Warehouses').doc(Warehouseid).collection('PurchaseRequisitionsLine_Items').where('Open_Allocations','==',true).where('StockObj.id','==',StockObj).orderBy('Created_On', 'asc').get()
  // .then(doc => {
  //   console.log('wh subcol index',doc.data());
  // })
  // .catch(error => console.log(error));
    this.CheckAuth()
    this.NowVar = setInterval(this.setNowTime,61000)
    setTimeout(() => {
      this.setNowTime()
    }, 5000);
    window.addEventListener('scroll', this.RecordSlider)
    window.addEventListener('scroll', this.handleScrollRecordToolbar)
    window.addEventListener('scroll', this.handleSiteDialogToolbar)
    //<!-- window.onload = this.RecordBannerParallax() -->
  },
  methods: {
    GetTrackingOrders(){
      if(this.userLoggedIn.TrackedEntity && this.userLoggedIn.TrackFilter){
        let payload = {                
          query: db.collection(this.userLoggedIn.TrackedEntity.split('_').join('').toLowerCase()).where('Userid','==',this.userLoggedIn.id).where('Status.ID','==',this.userLoggedIn.TrackFilter),
          arrayname: 'TrackingOrders',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      }
    },
    GetMyCompany(){
      db.collection('usercompanies').doc(this.userLoggedIn.Companyid).onSnapshot(snapshot => {
        let usercomp = snapshot.data()
        usercomp.id = this.userLoggedIn.Companyid
        if(usercomp.Userid === this.userLoggedIn.id){
          this.$store.commit('setUserisCompanyAdmin',true)
          usercomp.LoanOrderTables = {}
          usercomp.StoreOrderTables = {}
          if(usercomp.LoanEntities){
            usercomp.LoanEntities.map(ent => {
              let payload = {                
                query: db.collection(ent.split('_').join('').toLowerCase()).where('Companyid','==',usercomp.id),
                arrayname: ent.split('_').join(''),
                prop: 'LoanOrderTables'
                }
                //console.log(payload,this.$store.state)
                
                this.$store.dispatch('GetCompanyCollectionArray',payload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
            })
          }
          //okay now we can check the entities. But bear in mind bob I still don't know how much this helps. And the reason I say that is simple
          //"CompanyLoanOrders" or "CompanyStoreOrders" alike could now really be a little confusing. If we don't need to interact with them, fine. If we do, uh oh
        }
        else{
          this.$store.commit('setUserisCompanyAdmin',false)
        }
        this.$store.commit('setMyCompany',usercomp)
      })
    },
    GetSentMessages(){
      let payload = {                
          query: db.collection('usermessages').where('senderid','==',this.userLoggedIn.id),
          arrayname: 'SentMessages',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      },
      GetReceivedMessages(){
        let payload = {                
          query: db.collection('usermessages').where('recipientid','==',this.userLoggedIn.id),
          arrayname: 'ReceivedMessages',
          }
          this.$store.dispatch('GetCollectionArray',payload).then(function(result) {    
            //console.log(vm.$store.state.RAPlugins)
          })
      },
    UpdateLinksColor(){
      let root = document.documentElement;
      if(this.System.Light_Links){
        root.style.setProperty('--app-lightlinkscolor', this.System.Light_Links.hex)
      }
      if(this.System.Dark_Links){
        root.style.setProperty('--app-darklinkscolor', this.System.Dark_Links.hex)
      }
    },
    DeactivateActiveChatRecord(){
      this.$store.commit('SetSocialItemInteractMethod','DeactivateActiveChatRecord')
    },
    ExitAppBuilder(){
      this.$store.commit('setBuilderView','')
      if(this.$route.name === 'PageEditor'){
        this.$router.push('/'+this.$route.params.id)
      }
      else if(this.IsGroupView){        
        this.$router.push('/Group/'+this.GroupID)
      }
    },
    CheckConfigSystemTabs(){
      let vm = this
      this.GotConfigMaster = true
      this.ConfigMasterPath.collection('entities').doc('Systems').get().then(snapshot => {
          let systemdata = snapshot.data()
          systemdata.id = 'Systems'
          systemdata.AllFields = []
          vm.ConfigMasterPath.collection('entities').doc('Systems').collection('AllFields').onSnapshot(res => {
            const changes = res.docChanges();
            let fieldslength = res.docs.length
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    systemdata.AllFields.push(item)
                }
                else if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = systemdata.AllFields.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = systemdata.AllFields.indexOf(oncheck)
                      systemdata.AllFields.splice(arrayobjindex, 1, item);
                    }
                } 
                })
                if(systemdata.AllFields.length === fieldslength){
                  this.$store.commit('SetSystemConfigEntity',systemdata)
                  this.$store.commit('SetSystemConfigTabs',systemdata.SingleBuildTabs)
                }
          })
      })
      
      },
    SetViewOrderNavbar(){
      this.$store.commit('SetViewOrderNavbar',!this.$store.state.ViewOrderNavbar)
    },
    ActivateStoreSearch(search){
      this.$store.commit('SetStoreSearch',search)
    },
    WarehouseQueries(){
      let vm = this
      let routes = vm.$router.getRoutes()
      let whroutes = routes.filter(route => {
        return route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Warehouse'
      })
      //console.log('whroutes',whroutes)
        whroutes.map((route,routeindex) => {
          
            let payload = {
              collectionname: route.name.split(' ').join('').toLowerCase(),
              collectionstate: route.meta.subcat.split(' ').join('')+'Query',
              query: []
            }
            //no UserTableFilters would apply here 
            if(this.WarehousesArray.length === 0){
              vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
               }) 
            }
            this.WarehousesArray.map((wh,whindex) => {
              //console.log('this.userLoggedIn.Warehouses[wh.id].List',this.userLoggedIn.Warehouses[wh.id].List)
              if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[wh.id] && this.userLoggedIn.Warehouses[wh.id].List || this.userIsAdmin){
                payload.query.push(db.collection(route.name.split(' ').join('').toLowerCase()).where('Warehouseid','==',wh.id))
              }
              if(whindex-1+2 === this.WarehousesArray.length){
                //alert('done wh queryd')
                //console.log('payload',payload,route.name.split(' ').join('').toLowerCase(),'Warehouseid','==',wh.id)
                vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
               }) 
              }
            })
        })
    },
    RemoveItemfromCart(product){
      let storeitem = this.CartItems.find(obj => obj.id === product.id)
      let index = this.CartItems.indexOf(storeitem)
      confirm('Are you sure you want to remove this item from the Cart?') && this.CartItems.splice(index,1)
    },
    ProcessClearStoreCart(){
      this.CartItems = []
    },
    AddItemtoCart(product){
      let oncheck = this.CartItems.find(obj => obj.id === product.id)
      if(!oncheck){
        this.CartItems.push(product)
      }
      else{
        oncheck.Qty = Number(product.Qty)+Number(oncheck.Qty)
      }
      //console.log(this.CartItems)
    },
    ActivateStoreView(boolean){
      //alert('sdfsdlhfhsdfhsdfh')
      this.StoreView = boolean
       if(!boolean && this.UserisGuest){
        //  if(!this.System.Guest_Landing_Page){
        //     this.$router.push('/MyAccount')
        //   }  
        //   else{
        //     this.$router.push(this.System.Guest_Landing_Page)
        //   }
         this.$router.push('/MyAccount')
       }
      //  if(boolean && this.UserisGuest){
      //    this.$router.push('/MyAccount')
      //  }
    },
    PushActiveProduct(product){
      this.ActiveProduct = product
    },
    GetSubscriptionPackages(){
      let PackagesRef = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('SubscriptionPackages')
      PackagesRef.onSnapshot(res => {
        const changes = res.docChanges();
                changes.forEach(change => {
                if (change.type === 'added') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.SubscriptionPackages.push(item)
                }
                else if (change.type === 'modified') {
                    let item = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.SubscriptionPackages.find(obj => obj.id === item.id)
                    if(oncheck){
                      let arrayobjindex = this.SubscriptionPackages.indexOf(oncheck)
                      this.SubscriptionPackages.splice(arrayobjindex, 1, item);
                    }
                   
                } 

                })
      })
    },
    PrepareSocialEntities(){
      if(this.PluginDataBase.Site_Groups){
         let entities = this.$store.state.SocialEntities
                
                let groupRecordPrimaryField = {Name: 'name',DisplayName: 'Name',Type: 'Single Line Text',Primary: true,IsHeader: true,HeaderIndex: 0}
                let groupentity = {id: 'Site_Groups',DisplayName: 'Site Groups',SingleName: 'Group',Entity_Type: {ID: 2000000,Name: 'Group Table'},Table_Icon: 'mdi-account-group',
                Security_Structure: {ID: 1000003,Name: 'Social'},datasetsecuritystructure: 'Social',SingleBuildTabs: [],RecordPrimaryField: groupRecordPrimaryField,AllFields: [
                    groupRecordPrimaryField,
                    {Name: 'Description',DisplayName: 'Description',Type: 'Multiple Lines Text'},
                    {Name: 'Logo',DisplayName: 'Logo',Type: 'Single File Upload',IsFeaturedPhoto: true},
                    {Name: 'Telephone',DisplayName: 'Telephone',Type: 'Common Field',CommonFieldType: 'Telephone Number'},
                ],Primary_Field_Name: 'name',FeatureList: []}
                let groupcat = ''
                if(this.System.Group_Categories){
                  groupcat = Object.assign({},this.System.Group_Categories)
                  groupcat.IsDynamic = false
                  groupcat.IsHeader = true
                  groupcat.HeaderIndex = 2
                }
                if(groupcat){
                  groupentity.AllFields.push(groupcat)
                  this.PrepareGroupsEntity(entities,groupentity)
                }
                else{
                  this.PrepareGroupsEntity(entities,groupentity)
                }                
        }
    },
    PrepareGroupsEntity(entities,groupentity){
      let oncheck = entities.find(obj => obj.id === groupentity.id)
      if(!oncheck){
                 entities.push(groupentity)
                this.$store.commit('setSocialEntities',entities)
                let loggedingroups = db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members')
                 let nonguestgroups = db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Non Guest Site Members')
                if(this.userLoggedIn.IsGuestUser){
                  if(this.CompanyOnlyFilter){
                    loggedingroups = loggedingroups.where('Companyid','==',this.UserCompanyid)
                  }
                    let payload = {
                    collectionname: 'groups',
                    collectionstate: 'SiteGroupsQuery',
                    query: [db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'),
                    loggedingroups]
                  }
                this.$store.dispatch('SetUserQueryPermission',payload)
                }
                else{
                  if(this.CompanyOnlyFilter){
                    loggedingroups = loggedingroups.where('Companyid','==',this.UserCompanyid)
                    nonguestgroups = nonguestgroups.where('Companyid','==',this.UserCompanyid)
                  }
                  let payload = {
                    collectionname: 'groups',
                    collectionstate: 'SiteGroupsQuery',
                    query: [db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','Public'),
                    loggedingroups,nonguestgroups]
                  }
                  this.$store.dispatch('SetUserQueryPermission',payload)
                }
                let arraypayload = {
                  arrayname : 'SiteGroupsArray', 
                  array: []
                }
                this.$store.commit('setCollectionArray',arraypayload) 
                }
    },
    AssignPushedNavItem(item){
      this.PushedNavItem = item
      if(this.$route.name !== 'My Dashboard' && item){
        this.$router.push('/MyDashboard')
      }
    },
    ToggleTimesheetDialog(item){
      this.TimesheetDialogActive = !this.TimesheetDialogActive
      if(!item){
        this.TimesheetRelated = ''
      }
      else{
        this.TimesheetRelated = item
      }
    },
    SetActivityRegarding(item){
      console.log(item)
      if(item){
        this.ActivityRegarding = item
      }
      else{
        this.ActivityRegarding = ''
      }
    },
    UpdateGroupNavigationalItems(array){
      this.GroupNavigationalItems = array
    } ,   
    CheckLocation(){
      navigator.geolocation.getCurrentPosition(position => {
        console.log('CheckLocation',position)
        this.ShowUserLocation(position.coords.latitude,position.coords.longitude)
          //this.getAddressFrom(position.coords.latitude,position.coords.longitude)
          // Show a map centered at latitude / longitude.
        });
    },
    ShowUserLocation(lat,long){
      let map = new google.maps.Map(document.getElementById('map'),{
          zoom: 15,
          center: new google.maps.LatLng(lat, long),
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });
        let icon = {
            url: "@/assets/BlankProfilePic.png", // url
            scaledSize: new google.maps.Size(50, 50), // scaled size
            origin: new google.maps.Point(0,0), // origin
            anchor: new google.maps.Point(0, 0) // anchor
        };
        let label = {
            text: this.userLoggedIn.Full_Name,
            color: "#C70E20",
            fontWeight: ""
          }
        let marker = {
          position: new google.maps.LatLng(lat, long),
          map: map,
          icon: icon,
          label: label

        }
        if(this.userLoggedIn.Profile_Photo){
          marker.icon.url = this.userLoggedIn.Profile_Photo
        }
        new google.maps.Marker(marker)
    },
    TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
    setNowTime(){
      let now = new Date()
      let nowtime = now.getTime()
      this.NowTime = nowtime
      //console.log('setNowTime this.NowTime',this.NowTime)
    },
    ToggleReminderDialog(){
      this.ReminderDialog = !this.ReminderDialog
    },
    ToggleChatbotActive(){
      this.ChatbotActive = !this.ChatbotActive
    },
    
    ToggleRAAppAdminDialog(boolean){
      this.RAAppAdminDialog = boolean
    },
    updateOnlineStatus(e) {
            const {
                type
            } = e;
            this.onLine = type === 'online';
        },
    GetAppFonts(){
      this.ConfigMasterPath.collection('AppFonts').onSnapshot(res =>{
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.FontOptions.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })        
      })
    },
    SetBodyFontStyles(bodyfont){
      let root = document.documentElement;
      root.style.setProperty('--app-bodyfont', `"`+bodyfont.Name+`", Tahoma, sans-serif`)
    },
    SetHeaderFontStyles(headerfont){
      let root = document.documentElement;
      root.style.setProperty('--app-headerfont', `"`+headerfont.Name+`", Tahoma, Trebuchet , sans-serif`)
      root.style.setProperty('--app-headerfont--articlecaption', headerfont.SizeMultiplier*1+`em`)
      root.style.setProperty('--app-headerfont--articleoverline',headerfont.SizeMultiplier*2+`em`)
      root.style.setProperty('--app-headerfont--largeoverline', headerfont.SizeMultiplier*2.5+`em`)
      root.style.setProperty('--app-headerfont--display1', headerfont.SizeMultiplier*2.125+`rem`)
      root.style.setProperty('--app-headerfont--display2', headerfont.SizeMultiplier*3+`rem`)
      root.style.setProperty('--app-headerfont--display3', headerfont.SizeMultiplier*3.75+`rem`)
      root.style.setProperty('--app-headerfont--display4', headerfont.SizeMultiplier*6+`rem`)
      root.style.setProperty('--app-headerfont--headline', headerfont.SizeMultiplier*1.5+`rem`)
      root.style.setProperty('--app-headerfont--title', headerfont.SizeMultiplier*1.25+`rem`)
      root.style.setProperty('--app-headerfont--subtitle-2', headerfont.SizeMultiplier*0.875+`rem`)
      root.style.setProperty('--app-headerfont--subtitle-1', headerfont.SizeMultiplier*1+`rem`)
      root.style.setProperty('--app-headerfont--mobile-row__header', headerfont.SizeMultiplier*0.75+`rem`)
      root.style.setProperty('--app-headerfont--subtleoverline', headerfont.SizeMultiplier*0.75+`em`)
      root.style.setProperty('--app-headerfont--intranetnavlinks', headerfont.SizeMultiplier*12+`px`)

    },
    ToggleAppQuickStartDialog(){
      this.$store.commit('setAppQuickStartDialog',!this.$store.state.AppQuickStartDialog)
    },
    
    AddLandingPageModeratorRoutes(){
      let routes = [
        {
          path: '/LandingPages',
          name: 'landingpages',
          component: LandingPages,
          meta: {
            IsTopic: true,
            TopicCategory: 'Administration',
            TopicName: 'Landing Pages'
          }
        },
        {
          path: '/LandingPage/Edit/:id',
          name: 'newlandingpage',
          component: NewLandingPage,
          props:true,
          meta: {
            
          }
        },] 
        routes.map(route => {
        this.$router.addRoute(route)
      })
    },
    AddBannerAdsModeratorRoutes(){
      let routes = [{
          path: '/BannerAds',
          name: 'bannerads',
          component: BannerAds,
          meta: {
            IsTopic: true,
            TopicCategory: 'Administration',
            TopicName: 'Banner Ads'
          }
        },
        {
          path: '/BannerAd/Edit/:id',
          name: 'newbannerad',
          component: NewBannerAd,
          props:true
        },] 
        routes.map(route => {
        this.$router.addRoute(route)
      })
    },
    AddNonGuestRoutes(){
      console.log(this.NonGuestRoutes)
      this.NonGuestRoutes.map(route => {
        this.$router.addRoute(route)
      })
    },
    AddSTDUserRoutes(){
      this.STDUserRoutes.map(route => {
        this.$router.addRoute(route)
      })
    },
    
    GetPluginRoutes(ArrayName){
      //console.log(this.AllAppPluginsIDs)
      let routes = this.$router.getRoutes()
      this[ArrayName].map(route => {
            let oncheck = routes.find(obj => obj.name === route.name)
            if(!oncheck){
              if(route.ANDPlugins){
                let pass = true
                route.ANDPlugins.map(plug => {
                  if(!this.ComputedPluginObj[plug] && !this.AllAppPluginsIDs.includes(plug)|| this.ComputedPluginObj[plug] && !this.ComputedPluginObj[plug].Active && !this.AllAppPluginsIDs.includes(plug)){
                    pass = false
                  }
                })
                if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                  pass = false
                }
                if(pass){
                  this.$router.addRoute(route)
                }
              }
              else if(route.ORPlugins){
                
                let length = route.ORPlugins.length
                for (let i = 0; i < length; i++) {
                  let plug = route.ORPlugins[i]
                  if(ArrayName === 'EagleRoutes'){
                    //console.log(route.name,plug,this.ComputedPluginObj[plug], this.AllAppPluginsIDs,this.AllAppPluginsIDs.includes(plug))
                  }
                  //console.log(route.name,plug,this.ComputedPluginObj[plug])
                  if (this.ComputedPluginObj[plug] && this.ComputedPluginObj[plug].Active || this.AllAppPluginsIDs.includes(plug)) {
                    if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                    this.$router.addRoute(route)
                    }
                    else if(route.Channel && !this.ActiveMemberGroupAllowedChannels){
                    this.$router.addRoute(route)
                    }
                     else if(!route.Channel){
                      this.$router.addRoute(route)
                }
                    break;
                    }
                }
              }
              else if(route.Basic){
                if(route.Channel && this.ActiveMemberGroupAllowedChannels && !this.ActiveMemberGroupAllowedChannels.includes(route.Channel)){
                 this.$router.addRoute(route)
                }
                else if(route.Channel && !this.ActiveMemberGroupAllowedChannels){
                this.$router.addRoute(route)
                }
                else if(!route.Channel){
                this.$router.addRoute(route)
                }
              }
            }
          })
    },
    ToggleCatNavlistView(boolean){
      this.CatNavlistView = boolean
      if(this.AppStylingView){
        this.AppStylingView = false
      }
    },
    TogglePublicPreview(boolean){
      if(boolean === true){
        this.PublicPreview = boolean
        if(this.System.Public_Landing_Page){
          this.$router.push(this.System.Public_Landing_Page)
        }
        
      }
      else if(this.IntranetView === false){
        this.PublicPreview = boolean
        this.$router.push('/MyDashboard')
      }
      
      
    },
    RefreshRouter(){
      this.RefreshRouteView = true
      setTimeout(() => {
          this.RefreshRouteView = false
      }, 1);
    },
    ActivateRecordLoadingDialog(boolean,int){
      this.RecordLoading = boolean
      this.RecordLoadingvalue = int
    },
    SetColorstoSystemColors(System){
      //console.log('app SetColorstoSystemColors')
        this.DefaultThemes = this.DefaultThemes.map(theme => {
          theme.Colors.map(color => {
            if(System[color.SystemProp]){
                this.$vuetify.theme.themes[color.Theme][color.Name] = System[color.SystemProp].hex
                color.DefaultColorObject = System[color.SystemProp]
            }
            else{
              System[color.SystemProp] = color.DefaultColorObject
              this.$vuetify.theme.themes[color.Theme][color.Name] = color.DefaultColorObject.hex
            }
            return color
          })
          return theme
        })
    },
    SetGraphicalElements(System){
      this.GraphicalElements = this.GraphicalElements.map(elmnt => {
        if(System[elmnt.Prop]){
          elmnt.path = System[elmnt.Prop].path
        }
        return elmnt
      })
    },
    GetSystemStyling(System){
      this.SetColorstoSystemColors(System)
      this.SetGraphicalElements(System)
      //console.log('GetSystemStyling(System)',System)
      //this.$vuetify.theme.themes.light.pop = '#eb371b'
    },
    
    GetSystemGuides(query,provider){
      //let vm = this
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let guidedoc = change.doc.data()
            guidedoc.id = change.doc.id
            if(provider){
              guidedoc.Provider = provider 
            }
            guidedoc.CarouselItems = []
            query.doc(guidedoc.id).collection('carouselitems').onSnapshot(galres => {
              const galchanges = galres.docChanges();
              let gallength = galres.docs.length
              galchanges.forEach((galchange,i) => {
                if (change.type === 'added') {
                  guidedoc.CarouselItems.push({
                    ...galchange.doc.data(),
                    id: galchange.doc.id})
                  if(guidedoc.CarouselItems.length === gallength){
                    this.SystemGuides.push(guidedoc)
                  }
                }
               
              })
              if(gallength === 0){
                this.SystemGuides.push(guidedoc)
              }
            })
          }
           if(change.type === 'modified'){
                  let guideobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                  //console.log('guideobj change',guideobj)
                  let oncheck = this.SystemGuides.find(obj => obj.id === guideobj.id)
                  if(oncheck){
                    let index = this.SystemGuides.indexOf(oncheck)
                    guideobj.CarouselItems = oncheck.CarouselItems
                    this.SystemGuides.splice(index,1,guideobj)
                    //console.log('this.SystemGuides update',this.SystemGuides)
                  }
                }
        })
      })
    },
    GetDocumentLibraries(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DocumentLibraries').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            this.SystemDocumentLibraries.push(photolibdoc)
            let libraryroute = {
              path: '/'+photolibdoc.Collection,
              name: photolibdoc.DisplayName.split(' ').join('').toLowerCase(),
              component: DataCollectionViewComponent,
              meta: {
                id: photolibdoc.id,
                icon: photolibdoc.Icon,
                photolibrary: true,
                type: 'Document Libraries',
                subcat: photolibdoc.DisplayName,
                EntityID: photolibdoc.id,
                viewicon: 'mdi-camera-image',
                IsTopic: true,
                TopicCategory: 'Media Library',
                TopicName: photolibdoc.DisplayName+' Document Library',
                Security: {
                  SecurityType: 'Custom Roles',
                  CreateisCustom: true,
                  CreatePermission: 'Custom Role',
                  CustomCreateRoles: [],
                  GetisCustom: false,
                  GetPermission: 'Signed In',
                  CustomGetRoles: [],
                  ListisCustom: false,
                  ListPermission: 'Signed In',
                  CustomListRoles: [],
                  EditisCustom: true,
                  EditPermission: 'Custom Role',
                  CustomUpdateRoles: [],
                  DeleteisCustom: false,
                  DeletePermission: 'Admin',
                  CustomDeleteRoles: [],

                }
                },
              
            }
            // if(photolibdoc.PublishType === 'Public'){
            //   libraryroute.meta.RouteEventID = 1028
            // }
            // else{
            //   libraryroute.meta.RouteEventID = 1027
            // }
            // if(Public_Landing_Page && Public_Landing_Page === '/'+photolibdoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+photolibdoc.Name.split(' ').join('-')
            // }
            // path: '/'+photolibdoc.Name.split(' ').join('-'),
            this.$router.addRoute(libraryroute)
          }
          if(change.type === 'modified'){
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            let oncheck = this.SystemDocumentLibraries.find(obj => obj.id === photolibdoc.id)
            if(oncheck){
              let index = this.SystemDocumentLibraries.indexOf(oncheck)
              this.SystemDocumentLibraries.splice(index,1,photolibdoc)
            }
          }
        })
      })
    },
    GetPhotoLibraries(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('PhotoLibraries').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
          if (change.type === 'added') {
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            this.SystemPhotoLibraries.push(photolibdoc)
            let libraryroute = {
              path: '/'+photolibdoc.Collection,
              name: photolibdoc.DisplayName.split(' ').join('').toLowerCase(),
              component: DataCollectionViewComponent,
              meta: {
                id: photolibdoc.id,
                icon: photolibdoc.Icon,
                photolibrary: true,
                type: 'Photo Libraries',
                subcat: photolibdoc.DisplayName,
                EntityID: photolibdoc.id,
                viewicon: 'mdi-camera-image',
                IsTopic: true,
                TopicCategory: 'Media Library',
                TopicName: photolibdoc.DisplayName+' Page',
                Security: {
                  SecurityType: 'Custom Roles',
                  CreateisCustom: true,
                  CreatePermission: 'Custom Role',
                  CustomCreateRoles: [],
                  GetisCustom: false,
                  GetPermission: 'Signed In',
                  CustomGetRoles: [],
                  ListisCustom: false,
                  ListPermission: 'Signed In',
                  CustomListRoles: [],
                  EditisCustom: true,
                  EditPermission: 'Custom Role',
                  CustomUpdateRoles: [],
                  DeleteisCustom: false,
                  DeletePermission: 'Admin',
                  CustomDeleteRoles: [],

                }
                },
              
            }
            // if(photolibdoc.PublishType === 'Public'){
            //   libraryroute.meta.RouteEventID = 1028
            // }
            // else{
            //   libraryroute.meta.RouteEventID = 1027
            // }
            // if(Public_Landing_Page && Public_Landing_Page === '/'+photolibdoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+photolibdoc.Name.split(' ').join('-')
            // }
            // path: '/'+photolibdoc.Name.split(' ').join('-'),
            this.$router.addRoute(libraryroute)
          }
          if(change.type === 'modified'){
            let photolibdoc = change.doc.data()
            photolibdoc.id = change.doc.id
            let oncheck = this.SystemPhotoLibraries.find(obj => obj.id === photolibdoc.id)
            if(oncheck){
              let index = this.SystemPhotoLibraries.indexOf(oncheck)
              this.SystemPhotoLibraries.splice(index,1,photolibdoc)
            }
          }
        })
      })
    },
    async ClientAppAdminSignin(){
      //console.log(firebase.apps)
           
              console.log('RAApp Active')
              // this.GetAppFonts()
              let vm = this
              await this.RAApp.auth().onAuthStateChanged(function(user) {
               
                  if(user){
                   
                    let currentuserpayload = {
                      userid: user.uid,
                      db: vm.RADB,
                      RapidAppsDB: true
                  }
                  vm.$store.dispatch('SetUserObj',currentuserpayload).then(function(result) {
                      console.log('RAUserObj',vm.$store.state.RAUserObj)
                      vm.RAAppAdminSignedIn = true
                      vm.RAAppAdminUserBase = user
                      console.log('vm.RAAppAdminUserBase',vm.RAAppAdminUserBase)
                      let provider = {
                        Name: 'RapidApps',                      
                      }
                      vm.GetDocumentationLibraries(vm.RADB.collection('SystemConfig').doc('Zszipp8pQYVmJQJylnt7').collection('DocumentationLibraries'),vm.RADB,'Zszipp8pQYVmJQJylnt7')
                      let pluginpackquery = vm.RADB.collection('SystemConfig').doc('Zszipp8pQYVmJQJylnt7').collection('SubscriptionPackages').where('Type.Name','==','Suite Plugin Package')
                      let pluginpackpayload = {                
                        query: pluginpackquery,
                        arrayname: 'RAPluginPackages'
                        }
                        vm.$store.dispatch('GetCollectionArray',pluginpackpayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPluginPackages)
                          })
                        let pluginquery = vm.RADB.collection('plugins')
                      let pluginpayload = {                
                        query: pluginquery,
                        arrayname: 'RAPlugins',
                        provider: provider,        
                        Subcolpath: 'carouselitems',
                        SubcolName: 'CarouselItems'
                        }
                        vm.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPlugins)
                        })
                      //vm.GetSystemGuides(vm.RADB.collection('plugins'),provider)
                      })
                      let provider = {
                        Name: 'RapidApps',                      
                      }
                      let entitiespayload = {                
                        query: vm.RADB.collection('entities'),
                        arrayname: 'EntitiesArray',
                        provider: provider,        
                        Subcolpath: 'AllFields',
                        SubcolName: 'AllFields'
                        }
                        vm.$store.dispatch('GetArrayandSubcols',entitiespayload).then(function(result) {    
                          //console.log(vm.$store.state.RAPlugins)
                        })
                    
                  }
                  else{
                    
                      vm.ToggleRAAppAdminDialog(true)
                  }
              })
    },
    GetSystemTableFilters(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('SystemTableFilters').onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let filterobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.SystemTableFilters.push(filterobj)
            }
            else if (change.type === 'modified') {
              let filterobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              let oncheck = this.SystemTableFilters.find(obj => obj.id === filterobj.id)
              if(oncheck){
                let index = this.SystemTableFilters.indexOf(oncheck)
                this.SystemTableFilters[index] = filterobj
              }
            }
        })
      })
    },
    GetDBNavLists(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DBNavlists').onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let navlistobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.DBNavLists.push(navlistobj)
            }
        })
      })
    },
    GetSocialCategories(query,cat){
      if(!this.System[cat]){
        this.System[cat] = []
      }
      query.onSnapshot(res =>{
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let catobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.System[cat].push(catobj)
            }
        })
      })
    },
    GetWarehouseSuppliers(){
      let dpayload = {                
        query: db.collection('WarehouseSuppliers'),
        arraymutation: 'setWarehouseSuppliers',
        getter: 'getWarehouseSuppliers',
            arrayname: 'WarehouseSuppliersArray'
      }
      let qpayload = {
          collectionname: 'WarehouseSuppliers',
          collectionstate: 'WarehouseSuppliersQuery',
          query: db.collection('WarehouseSuppliers')
        }  
        this.$store.dispatch('SetUserQueryPermission',qpayload)
        this.$store.dispatch('GetCollectionArray',dpayload)
    },
    GetWarehouses(){
      let dpayload = {                
        query: db.collection('Warehouses'),
        arraymutation: 'setWarehouses',
        getter: 'getWarehouses',
            arrayname: 'WarehousesArray'
      }
      let qpayload = {
          collectionname: 'Warehouses',
          collectionstate: 'WarehousesQuery',
          query: db.collection('Warehouses')
        }  
        if(this.userLoggedIn){
          if(!this.userLoggedIn.IsGuestUser){
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload)  
          }
          else{
          dpayload.query = db.collection('Warehouses').where('Guests_Allowed','==',true) 
          qpayload.query = db.collection('Warehouses').where('Guests_Allowed','==',true) 
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload) 
        }
        }
        else{
          dpayload.query = db.collection('Warehouses').where('Is_Public','==',true) 
          qpayload.query = db.collection('Warehouses').where('Is_Public','==',true) 
          this.$store.dispatch('SetUserQueryPermission',qpayload)
          this.$store.dispatch('GetCollectionArray',dpayload) 
        }
      
    },
    //ActivateAppsDB
    ActivatePluginDB(query){
      let vm = this
      query.onSnapshot(res => {
              let pluginlength = res.docs.length
              //console.log(pluginlength,this.userIsAdmin)
              if(pluginlength === 0 && this.userIsAdmin && this.$route.name !== 'GettingStarted'){
                this.$router.push('/Getting-Started')
              }
              const changes = res.docChanges();
              changes.forEach(change => {
                  if (change.type === 'added') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    this.ActivePlugins.push(plugin)
                  }
                  if (change.type === 'modified') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.ActivePlugins.find(obj => obj.id === plugin.id)
                    if(oncheck){
                      let index = this.ActivePlugins.indexOf(oncheck)
                      this.ActivePlugins.splice(index,1,plugin)
                    }
                  }
                  if (change.type === 'removed') {
                    let plugin = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let oncheck = this.ActivePlugins.find(obj => obj.id === plugin.id)
                    if(oncheck){
                      let index = this.ActivePlugins.indexOf(oncheck)
                      this.ActivePlugins.splice(index,1)
                    }
                  }
              })
            })
            //console.log(this.$store.state.RAPlugins,this.SystemisRA)
            if(this.SystemisRA && this.$store.state.RAPlugins.length === 0 && this.userLoggedIn){
              let pluginquery = db.collection('plugins')
              let pluginpayload = {                
                query: pluginquery,
                arrayname: 'RAPlugins',
                provider: 'RapidApps',
                Subcolpath: 'carouselitems',
                SubcolName: 'CarouselItems'
                }
                this.$store.dispatch('GetArrayandSubcols',pluginpayload).then(function(result) {    
                  //console.log(vm.$store.state.RAPlugins)
                })
            }
    },
    ActivateRAandPlugins(){
      const firebaseConfig = {
            apiKey: "AIzaSyBizqYTsY9oNY_0U1L6evK9a22kjUyr9ps",
            authDomain: "rapidapps-7d6e7.firebaseapp.com",
            databaseURL: "https://rapidapps-7d6e7.firebaseapp.com",
            projectId: "rapidapps-7d6e7",
            storageBucket: "rapidapps-7d6e7.appspot.com",
            messagingSenderId: "674721806504",
            appId: "1:674721806504:web:4596bc44637e9f37eb6a9d",
            measurementId: "G-J7T6TGDTTB"
          };
            let check = firebase.apps.find(obj => obj.name === 'RapidApps')
              if(check){
                this.RAApp = check
              }
              else{
                this.RAApp = firebase.initializeApp(firebaseConfig, 'RapidApps')
              }  
              this.$store.commit('setRAApp',this.RAApp)            
              this.RADB = this.RAApp.firestore()
              this.$store.commit('setRADB',this.RADB)              
            //   let pluginspayload = { 
            //     SystemID: process.env.VUE_APP_RA_SYSTEM_ID
            //   }
            // console.log(pluginspayload)
            // const RAfunctions = firebase.functions(this.RAApp);
            // const ObtainSystemPlugins = RAfunctions.httpsCallable('ObtainSystemPlugins');
            // ObtainSystemPlugins(pluginspayload).then(result => {
            //   result.data.map(plugin => {

            //   })
            // })
            this.ActivatePluginDB(this.RADB.collection('systems').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('plugins').where('Active','==',true))
            //used GetApps this.ActivateAppsDB(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('apps').where('Active','==',true))
            if(this.RAAdmin){
              let rasystemassetspayload = {                
                query: this.RADB.collection('systemassets'),
                arrayname: 'RASystemAssets'
              }
              console.log(rasystemassetspayload)
              this.$store.dispatch('GetCollectionArray',rasystemassetspayload)   
            }
    },
    GetSystem(stylingonly,user){
      //Changed from SystemConfigPath.onSnapshot(snapshot => { to SystemConfigPath.get().then(snapshot => { on main js, but kept snapshot here on App.vue
     //snapshot on app vuew looks at styling, but mainjs snapshot breaks all core things like security, routes, etc and thus breaks everything when changes made to system config.
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).onSnapshot(snapshot => {
        let systemobj = snapshot.data()
        this.System = systemobj
        if(!this.System.CustomFonts){
          this.System.CustomFonts = []
        }
        // let animobj = new KeyframeEffect(
        //   null, // element to animate
        //   [
        //     { filter: 'blur(0)' }, // keyframe
        //     //{ transform: 'translateY(100%)' } // keyframe
        //   ],
        //   { duration: 3000, fill: 'forwards' } // keyframe options
        // );
        // //let animobj = new Animation(rabbitDownKeyframes, document.timeline)
        // let newanim = new Animation(animobj, document.timeline)
        // //newanim.play()
        // console.log(newanim)
        this.System.CustomFonts.map(font => {
          let new_font = new FontFace(font.Name,font.Path)
          new_font.load().then(function(loaded_face) {
              document.fonts.add(loaded_face)
          }).catch(function(error) {
          });
        })
        this.System.id = process.env.VUE_APP_RA_SYSTEM_ID
        if(this.System && !this.System.SocialNavbarItems){
          this.System.SocialNavbarItems = []
        }
        //however, we should not repropogate things  liek claling warehouses, navlists etc. those are snapshots on their own. 
        //so we only do snapshot on system, to get update, but refrain from doing any of the others. should be clear cut full circle now
        if(!this.RouterReady && !this.OnLogin || this.OnLogin || this.PublicUser){
          
          if(!this.SystemisRA){
            //this is very wrong only system admin should get plugins from RA? oh no neevrmind uhm think I get it
            this.ActivateRAandPlugins()
          } 
          else if(this.SystemisRA){
            this.ActivatePluginDB(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('plugins').where('Active','==',true))
            //used GetApps this.ActivateAppsDB(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('apps').where('Active','==',true))
          }          
          if(this.SuitehasWarehouse && !user){
            // remove this.SuitehasWarehouse && ?
            this.GetWarehouses()
          }
        this.SocialCategories.map(cat => {
            this.GetSocialCategories(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection(cat),cat)
        })
        
         if(this.System.FavoriteFeatures){
                this.FavoriteFeatures = this.System.FavoriteFeatures
              }
        this.System.StoreProductsEntities = []
        this.GetSystemStyling(systemobj)
        let doclibquery = ''
        if(!stylingonly){
          this.GetSystemTableFilters()
          this.GetDBNavLists()
          this.GetEntities(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities'),'SystemEntities').then(result => {
            this.SystemLoadingvalue = 75
           this.AssignAuth(user) 
          })
        }
        let wikientityquery = ''
        if(user){
          doclibquery = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DocumentationLibraries')
          wikientityquery = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('wikientities')
        }
        else{
          doclibquery = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('DocumentationLibraries').where('PublishType','==','Public')
          wikientityquery = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('wikientities').where('PublishType','==','Public')
        }
        this.GetWikiEntities(wikientityquery)
        this.GetStoredDefaultCategoricalNavlists()
        this.GetDocumentationLibraries(doclibquery,db,process.env.VUE_APP_RA_SYSTEM_ID)
        }
                
        
      })
    },
    PrepareAddressField(field){
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        },
    GetWikiEntities(wikientityquery){
      wikientityquery.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
              let entityobj = {
                ...change.doc.data(),
                    id: change.doc.id
              }
              this.WikiEntities.push(entityobj)
            }
        })
        //console.log('this.WikiEntities',this.WikiEntities)
      })
    },
    GetStoredDefaultCategoricalNavlists(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('CategoricalNavlists').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach((change,i) => {
            if (change.type === 'added') {
                let navlist = change.doc.data()
                navlist.Name = navlist.DisplayName
                navlist.id = change.doc.id
                this.StoredDefaultCategoricalNavlists.push(navlist)
                let storeobjpayl = {
                  EntityID: navlist.id,
                  Collection: '',
                  Data: [],
                  CategoricalListType: navlist.CategoricalListType
                }
                //okay if CategoricalListType === 'Social Groups', then we should in fact first find the pages, being ALL pages it matches with
                
                if(navlist.Has_Ratings){
                  storeobjpayl.Has_Ratings = navlist.Has_Ratings
                }
                if(navlist.CategoricalListType === 'Social Groups'){
                  this.$store.commit('AddCatNavlistTable',storeobjpayl)
                  this.SitePages.map(page => {
                    let pagelements = page.PageTabs.map(tab => {
                      return tab.Elements
                    }).flat()
                    .filter(elmnt => {
                      return elmnt.CatNavList
                    })
                    if(pagelements.length > 0){
                      let newstoreobjpayl = Object.assign({},storeobjpayl)
                    newstoreobjpayl.EntityID = storeobjpayl.EntityID+'_'+page.id
                    //console.log(newstoreobjpayl)
                    this.$store.commit('AddCatNavlistTable',newstoreobjpayl)
                    }
                    
                  })
                }
                else{
                 this.$store.commit('AddCatNavlistTable',storeobjpayl) 
                }
                
                //getfeaures if has featuredlist
            }
            //console.log('StoredDefaultCategoricalNavlists',this.StoredDefaultCategoricalNavlists)
        })
      })
    },
    GetSurveyElements(entity){
      let vm = this
      return new Promise(function(resolve, reject) {
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(entity.id).collection('SurveyElements').onSnapshot(res => {
            const changes = res.docChanges();
            let arraylength = res.docs.length
            
            if(arraylength === 0){
              resolve('done getting SurveyElements')
            }
            else{
              changes.forEach(change => {
                  if (change.type === 'added') {
                      entity.SurveyTab.Elements.push({
                      ...change.doc.data(),
                      id: change.doc.id
                      })
                      if(entity.SurveyTab.Elements.length === arraylength){
                        resolve('done getting SurveyElements')
                      }
                  }
              })
            }
            
        })
      })
    },
    
    GetDocumentationLibraries(query,database,systemid){
        query.onSnapshot(res => {
          let length = res.docs.length
          const changes = res.docChanges();
          changes.forEach(change => {
            if (change.type === 'added') {    
              let doclibobj = {
                ...change.doc.data(),
                id: change.doc.id
              }   
              //console.log(doclibobj)
              doclibobj.Database = database
              doclibobj.SystemID = systemid
              doclibobj.Query = query.doc(doclibobj.id)
              doclibobj.Pages = []  
            this.DocumentationLibraries.push(doclibobj)
            }
            if (change.type === 'modified') {
              let doclibobj = {
                ...change.doc.data(),
                id: change.doc.id
              } 
              doclibobj.Database = database
              doclibobj.Pages = []  
              doclibobj.SystemID = systemid
              let oncheck =  this.DocumentationLibraries.find(obj => obj.id === doclibobj.id) 
              if(oncheck){
                let index = this.DocumentationLibraries.indexOf(oncheck)
                this.DocumentationLibraries.splice(index,1,doclibobj)
              }      
            }
          })
        })
    },
    GetEntities(configdb,array,groupid){      
            let vm = this
            if(array === 'SystemEntities'){
             vm.SystemLoadingvalue = 50 
            }            
            //vm.$emit('ActivateProcessing',true)
            //alert('getting entities')
            return new Promise(function(resolve, reject) {
            configdb.onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                  resolve('GotEntities')
                    //vm.$emit('ActivateProcessing',false)
                }
                else{
                  const changes = res.docChanges();
                    changes.forEach((change,i) => {
                   if (change.type === 'added') {
                        let build = change.doc.data()
                        build.id = change.doc.id
                        if(groupid){
                          build.groupid = groupid
                          //console.log('group web form build',build)
                        }
                        if(build.Entity_Type && build.Entity_Type.Name === 'Survey Form'){
                          vm.GetSurveyElements(configdb,build)
                        }
                        vm.GetAllEntityProps(configdb,build).then(function(allpropsresult) {
                          //console.log('allpropsresult',allpropsresult,build)                          
                            if(allpropsresult){
                               vm[array].push(build)
                               
                               if(vm[array].length === arraylength){
                                 if(array === 'SystemEntities'){
                                 vm.GotEntities = true
                                 }
                                //  vm.CheckAuth()
                                resolve('GotEntities')
                                //vm.SystemLoadingvalue = 75
                               }
                            }
                        })                        
                   }
                  })
                }
            })
            })
    },
    GetAllEntityProps(configdb,build){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(configdb,build).then(function(allfieldsresult) {
                if(allfieldsresult){
                  
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(configdb,build).then(function(subcolresult) {
                        // console.log(subcolresult)
                        
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(configdb,build).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(configdb,build).then(function(formsresult) {
                            vm.GetDocumentRegisters(configdb,build).then(function(docregresult) {  
                                vm.GetGalleries(configdb,build).then(function(galleryres) {                          
                                vm.GetEntityAutomations(configdb,build).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                    if(build.Entity_Type && build.Entity_Type.Name === 'Cash Loan'){
                                      vm.GetLoanPackages(configdb,build).then(function(packagesresult) {
                                        resolve('got the props for '+configdb,build.id)
                                      })
                                    }
                                    else{
                                      resolve('got the props for '+configdb,build.id)
                                    }
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetLoanPackages(configdb,build){    
            // console.log('calling fields') 
            build.LoanPackages = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.LoanPackages)
            configdb.doc(build.id).collection('LoanPackages').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities LoanPackages Finished for ' + build.LoanPackages.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.LoanPackages.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if (change.type === 'modified') {
                  let loanpackobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                  let oncheck = build.LoanPackages.find(obj => obj.id === loanpackobj.id)
                  if(oncheck){
                    let index = build.LoanPackages.indexOf(oncheck)
                    build.LoanPackages.splice(index,1,loanpackobj)
                  }
                }
                if (change.type === 'removed') {
                  let loanpackobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                }
                  let oncheck = build.LoanPackages.find(obj => obj.id === loanpackobj.id)
                  if(oncheck){
                    let index = build.LoanPackages.indexOf(oncheck)
                    build.LoanPackages.splice(index,1)
                  }
                }
                if(build.LoanPackages.length === arraylength){
                    
                resolve('Method SystemEntities LoanPackages Finished for ' + build.LoanPackages.length)
                }
                })
                })
            })
        },
    GetEntityAllFields(configdb,build){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(configdb,build){     
            // console.log('calling SubCollections')
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(configdb,build){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('EntityProcesses').where('Status','!=','InActive').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(configdb,build){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(configdb,build){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(configdb,build){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(configdb,build){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            configdb.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }
                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            
                            //console.log(targetslength,i-1+2)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
    ActivateSingleRecordNavMenuItem(item){
      console.log(item)
      this.SingleRecordNavMenuItem = item
      
    },
    SingleTabsLayout(array,itemname){
      console.log('SingleTabsLayout',array)
      if(array){
        this.SingleRecordNavMenuView = true
        this.SingleRecordNavMenuArray = array
        this.SingleRecordNavName = itemname
        //this.SingleRecordNavMenuItem = array[0]
      }
    },
    UpdateRequiredUserInformation(){
      this.UserRequiredInfo.map(info => {
        db.collection('users').doc(this.UserObj.id).update({
          [info]: this.UserObj[info]
        })
      })
      this.UserInfoRequiredDialog = false
    },
    TriggerDashboardbyNotification(payload){
      this.NotificationObj = payload
      console.log('NotificationObj',this.NotificationObj)
      if(this.UserisGuest && this.$route.name !== 'MyAccount'){
        if(!this.System.Guest_Default_Page){
          this.$router.push('/MyAccount')
        }
        else{
          this.$router.push(this.System.Guest_Default_Page)
        }
        
       setTimeout(() => {
         this.NotificationObj = ''
       }, 300);
      }
      else if(!this.UserisGuest && this.$route.name !== 'MyDashboard'){
        if(payload.groupid){
          let routeData = this.$router.resolve({name: 'GroupDashboard', params: {id: payload.groupid}});
          window.open(routeData.href);
        }
        else{
         this.$router.push('/MyDashboard')  
        }        
        setTimeout(() => {
         this.NotificationObj = ''
       }, 10000);
      }
      
    },
    ActivateNotificationSnackbar(boolean,snackbarcontent){
      // this.snackbar = boolean
      if(boolean === true){
        this.snackbars.push(snackbarcontent)
      }
    },
    CommitSnackbar(snackbar){
      let boolean = snackbar.boolean
      let snackbarcontent = snackbar
      this.ActivateSnackbar(boolean,snackbarcontent)
    },
    ActivateSnackbar(boolean,snackbarcontent){
      this.snackbar = boolean
      if(boolean === true){
        this.snackbartimeout = snackbarcontent.snackbartimeout
        this.snackbartext = snackbarcontent.snackbartext
        this.snackbartop = snackbarcontent.snackbartop
        this.snackbarpath = snackbarcontent.snackbarpath
        this.snackbarmultiLine = snackbarcontent.snackbarmultiLine
        let timeout = 4000
        if(this.snackbartimeout){
          timeout = this.snackbartimeout
        }
        setTimeout(() => {
        this.snackbartimeout = 4000
        this.snackbartext = ''
        this.snackbartop = true
        this.snackbarpath = ''
        this.snackbarmultiLine = true
        this.snackbar = false
        }, timeout);
      }
      
    },
    ActivateStandbyDialog(boolean){
      this.StandbyDialog = boolean
    },
    ActivateProcessing(boolean){
      this.Processing = boolean
    },
    ActivateUploadingSingleFileNotification(boolean){
      this.UploadingSingleFile = boolean
    },
    ActivateMultipleFilesUploadNotification(boolean){
      this.UploadingFiles = boolean
    },
    CloseSnackbar(){
      this.ActivateSnackbar(false)
    },
    LandingPageView(boolean){
      this.LandingPageViewActive = boolean
    },
    RecordSlider(event) {
      var invertedenvlopetopright = document.getElementById("invertedenvlopetopright");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopright){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopright.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetoprightforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightforwards')
          }
          
        }
        else {
          invertedenvlopetopright.setAttribute('class','secondary invertedenvlopetopright invertedenvlopetoprightbackwards');
        }
      }
      var invertedenvlopetopleft = document.getElementById("invertedenvlopetopleft");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopleft){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopleft.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopleftforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftforwards')
          }
          
        }
        else {
          invertedenvlopetopleft.setAttribute('class','secondary invertedenvlopetopleft invertedenvlopetopleftbackwards');
        }
      }
      var invertedenvlopetopcenter = document.getElementById("invertedenvlopetopcenter");
      // console.log(invertedenvlopetopright)
      if(invertedenvlopetopcenter){
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          var x = invertedenvlopetopcenter.getAttribute('class');
          // console.log(x)
          if(x.includes('invertedenvlopetopcenterforwards')){
            //console.log('already has this class')
          }
          else{
            invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterforwards')
          }
          
        }
        else {
          invertedenvlopetopcenter.setAttribute('class','white invertedenvlopetopcenter invertedenvlopetopcenterbackwards');
        }
      } 
    },
    PushRecordName(name){
      //console.log('pushing recordname',name)
      this.CurrentRecordName = name
    },
    ToggleAppDarkMode(){
  if(this.AppisDarkMode){
    this.AppisDarkMode = false
  
  }
  else{
    this.AppisDarkMode = true
  }
  this.$vuetify.theme.dark = !this.$vuetify.theme.dark
},
    ActivateSearch(search){
      //console.log('yep I activate search view')
      //console.log(search)
      this.SearchView = true
      this.ActiveSearch = search
      //console.log(this.SearchView)
      //console.log(this.ActiveSearch)
    },
    DeactivateSearch(){
      //console.log('yep I deactivate or try to')
      this.SearchView = false
      this.ActiveSearch = ''
    },
    ToggleAppStylingView(){
        this.AppStylingView = !this.AppStylingView    
    },
    IntranetViewToggle(boolean,tointranet){   
     //console.log('IntranetViewToggle ',this.IntranetView,boolean,tointranet)    
        if(this.IntranetView === true && boolean === false && this.userLoggedIn && !this.PublicPreview){
           this.IntranetView = boolean 
           if(this.UserisGuest){
              if(!this.System.Guest_Landing_Page){
                this.$router.push('/MyAccount')
              }
              else{
                this.$router.push(this.System.Guest_Landing_Page)
             }
           }
           else{
            if(!this.System.Social_Landing_Page){
                //this.$router.push('/MyAccount')
              }
              else{
                this.$router.push(this.System.Guest_Landing_Page)
             }
           }
          //  else{
          //    this.$router.push('/MyDashboard')          
          //  }
         
       }  
       else if(this.IntranetView === false && boolean === true && this.userLoggedIn){
         this.$store.commit('setActiveSuiteApp','')
         this.IntranetView = boolean
         if(tointranet){
           this.$router.push(this.System.Social_Landing_Page)
         }
         
       } 
       //console.log(this.IntranetView)
    },
    RecordBannerParallax(){
      setTimeout(() => {
      var recordbanner = document.getElementById("recordbanner");
      var recordparallax = document.getElementById("recordparallax");
      // console.log('recordbanner',recordbanner)
      // console.log('recordparallax',recordparallax)
      if(!recordbanner && recordparallax){
          recordparallax.style.top = '110px'
        }
        }, 500);
    },
    handleSiteDialogToolbar(event) {
      var sitetoolbar = document.getElementById("sitetoolbar");
      if(this.DBView && sitetoolbar){
       if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
         sitetoolbar.style.top = '135px'
        }
        else{
          sitetoolbar.style.top = '190px'
        } 
      }
      
    },
    handleScrollRecordToolbar(event) {
      var recordtoolbar = document.getElementById("recordtoolbar");
      var recordtoolbar2 = document.getElementById("recordtoolbar2");
      var recordownershipbanner1 = document.getElementById("recordownershipbanner1");
      var recordownershipbanner2 = document.getElementById("recordownershipbanner2");
      let logobar = document.getElementById('logobar')
      let belowlogobar = document.getElementById('belowlogobar')
      let socialsitequicknav = document.getElementById('socialsitequicknav')
      if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
        if(logobar && belowlogobar && this.IntranetView && !this.AppStylingView){
          logobar.style.display = 'none'
          belowlogobar.style.top = '50px'
          if(socialsitequicknav){
            socialsitequicknav.style.top = '115px'
          }
        }
        else if(logobar && belowlogobar && this.IntranetView && this.AppStylingView){
          logobar.style.display = 'none'
          belowlogobar.style.top = '260px'
          if(socialsitequicknav){
            socialsitequicknav.style.top = '325px'
          }
        }
        if(recordtoolbar && !this.AppStylingView){
        recordtoolbar.style.top = '50px'
        }
        else if(recordtoolbar && this.AppStylingView){
        recordtoolbar.style.top = '260px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '65px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '110px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '110px'
        }
      }
      else {
        if(logobar && belowlogobar && this.IntranetView && !this.AppStylingView){
          logobar.style.display = 'block'
          belowlogobar.style.top = "160px"
          if(socialsitequicknav){
            socialsitequicknav.style.top = '225px'
          }
        }
        else if(logobar && belowlogobar && this.IntranetView && this.AppStylingView){
          logobar.style.display = 'block'
          belowlogobar.style.top = "400px"
          if(socialsitequicknav){
            socialsitequicknav.style.top = '415px'
          }
        }
        if(recordtoolbar && !this.AppStylingView){
        recordtoolbar.style.top = '65px'
        }
        else if(recordtoolbar && this.AppStylingView){
        recordtoolbar.style.top = '260px'
        }
        if(recordtoolbar2){
        recordtoolbar2.style.top = '110px'
        }
        if(recordownershipbanner1){
        recordownershipbanner1.style.top = '130px'
        }
        if(recordownershipbanner2){
        recordownershipbanner2.style.top = '130px'
        }
      }
    },
    GetFAQ(query){
      query.onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {         
           this.FrequentlyAskedQuestions.push({
              ...change.doc.data(),
              id: change.doc.id
            })
          }
          else if (change.type === 'modified') {
            let oncheck =  this.FrequentlyAskedQuestions.find(obj => obj.id === change.doc.id) 
            if(oncheck){
              let index = this.FrequentlyAskedQuestions.indexOf(index)
                this.FrequentlyAskedQuestions[index] = {
                ...change.doc.data(),
                id: change.doc.id
              }
            }      
          }
      })
      })
    },
    
    //Basically, if not a website only, then this applies
    async CheckAuth(){
      let vm = this
      	
    await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
          vm.OnLogin = true
           
          vm.GetFAQ(db.collection('frequentlyaskedquestions'))
           vm.SystemLoadingvalue = 25
          //console.log(user)
          vm.SystemLoading = true
          
          vm.$store.commit('SetUserStateObj', user)
           user.getIdToken().then(function(idToken) {
              
            vm.$store.commit('SetUserIdToken', idToken)
          });
          
            vm.GetSystemGuides(db.collection('systemguides'))
            vm.GetPhotoLibraries()
            vm.GetDocumentLibraries()
            vm.GetSystem(false,user)
            if(vm.WebsiteisActive || vm.SocialNetworkisActive){
            vm.GetSitePages(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Pages'))
            }
             setTimeout(() => {
              vm.OnLogin = false
            }, 150);
         // console.log('seeing signed in')
        }
        else{
          vm.GetFAQ(db.collection('frequentlyaskedquestions').where('PublishType','==','Public'))
          vm.SignedIn = false
          vm.PublicUser = true
          vm.GetSystem(true)
          if(vm.WebsiteisActive){
          vm.GetSitePages(db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Pages').where('PublishType','==','Public'))  
          }  
          else{
            vm.RouterReady = true
          }        
          //vm.$router.push('/Home')
         
          //console.log('seeing as NOT signed in')
        }
    })
  },
  GetSitePages(query){
    let Public_Landing_Page = this.System.Public_Landing_Page
    let routes = this.$router.getRoutes()
              
    query.onSnapshot(res => {
      //console.log(res.docs)
      let length = res.docs.length
      
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let pageroute = {
              path: '/'+pagedoc.Name.split(' ').join('-'),
              name: pagedoc.Name.toLowerCase(),
              component: LandingPageSingleVue,
              meta: {
                id: pagedoc.id,
                icon: pagedoc.PageIcon,
                type: 'Pages',
                subcat: pagedoc.Name,
                PageID: pagedoc.id,
                viewicon: 'mdi-book-open-page-variant',
                IsTopic: true,
                TopicName: pagedoc.Name+' Page'
                }
            }
            if(pagedoc.PublishType === 'Public'){
              pageroute.meta.RouteEventID = 1028
              pageroute.meta.TopicCategory = 'Public Pages'
            }
            else{
              pageroute.meta.RouteEventID = 1027
              pageroute.meta.TopicCategory = 'Internal Pages'
            }
            if(pagedoc.CatNavList){
              pageroute.meta.CatNavList = pagedoc.CatNavList
            }
            //console.log(pageroute)
            // if(Public_Landing_Page && Public_Landing_Page === '/'+pagedoc.Name.split(' ').join('-')){
            //   pageroute.path = '/'
            // }
            // else{
            //   pageroute.path = '/'+pagedoc.Name.split(' ').join('-')
            // }
            // path: '/'+pagedoc.Name.split(' ').join('-'),
            let onroute = routes.find(obj => obj.name === pageroute.name)
            if(!onroute){
            this.$router.addRoute(pageroute)
            }
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(!oncheck){
            this.SitePages.push(pagedoc)
          }
        }   
        if (change.type === 'added') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(oncheck){
            let index = this.SitePages.indexOf(oncheck)
            this.SitePages.splice(index,1,pagedoc)
          }
        }
        if (change.type === 'modified') {         
          let pagedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.SitePages.find(obj => obj.id === pagedoc.id)
          if(oncheck){
            let index = this.SitePages.indexOf(oncheck)
            this.SitePages.splice(index,1,pagedoc)
          }
        }
        if(this.SitePages.length === length && this.PublicUser){
            setTimeout(() => {
              this.RouterReady = true  
            }, 1000);
           
        }
            
    })
    })
  },
  GetUserNotifications(query,timeout){
    //alert('yay')
    this.Notifications = []
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {         
          let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let oncheck = this.Notifications.find(obj => obj.id === notedoc)
          if(!oncheck){
            this.Notifications.push(notedoc)
          }
          // console.log(notedoc.id,notedoc.Read,notedoc.Message,notedoc.Content)
          // console.log(notedoc.Read === false)
          if(notedoc.Read === false){            
            // this.focusednotificationssnackbarcontent.title = notedoc.Message
            // this.focusednotificationssnackbarcontent.body = notedoc.Content
            // this.focusednotificationssnackbar = true
            let snackbarcontent = {
              timeout: timeout,
              text: notedoc.Content,
              title: notedoc.Message,
              show: true,
              IsNotification: true,
              notificationid: notedoc.id,
              thumbnail: ''
            }
            if(notedoc.fileurl){
              snackbarcontent.Route = notedoc.fileurl
              snackbarcontent.Target = '_blank'
              snackbarcontent.isHref = true
            }
            else{
              snackbarcontent.Route = notedoc.Path
              snackbarcontent.Target = 'self'
            }
            this.snackbars.push(snackbarcontent)
          }          
        }
         if (change.type === 'modified') {
           let notedoc = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let notobj = this.Notifications.find(obj => obj.id === change.doc.id)
          let notindex = this.Notifications.indexOf(notobj)
          this.Notifications.splice(notindex,1,notedoc)
        }
      })
    })
    },
    NotificationRead(notification){
      if(notification.Read === false){
       db.collection('notifications').doc(notification.id).update({
        Read: true
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = true
      this.$router.push(notification.Path)

      }

    },
  
    MarkasUnread(notification){
      if(notification.Read === true && notification.Type === 'Task Created'){
       db.collection('notifications').doc(notification.id).update({
        Read: false
      })
      let notificationIndex = this.Notifications.indexOf(notification)
      this.Notifications[notificationIndex].Read = false


      }
    },
    SignoutNotifications(){
    this.Notifications = []
  },
  GetSiteModerators(){
        db.collection('sitemoderators').onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              if (change.type === 'modified') {
                let newmodobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                let oncheck = this.SiteModerators.find(obj => obj.id === newmodobj.id)
                if(oncheck){
                  let index = this.SiteModerators.indexOf(oncheck)
                  this.SiteModerators.splice(index,1,newmodobj)
                }
              }
            })
          
        })
      },
  AssignAuth(user){
      let vm = this
    //console.log('assigning auth')
         let payload = {                
        query: db.collection('users'),
        arraymutation: 'setUsersArray',
        getter: 'getUsersArray',
            arrayname: 'Users'
      }
       let payload2 = {                
        query: db.collection('users'),
        arraymutation: 'setUsersArray',
        getter: 'getUsersArray',
            arrayname: 'UsersArray'
      }
      // if(vm.UserisGuest){  
      //   payload.query = db.collection('users').where('MyProfileViewPermissions','==','Anybody')
      // }
      // let usersquerypayload = {
      //     collectionstate: 'UsersQuery',
      //     query: payload.query
      //   }
      //   // console.log(payload)
      //   vm.$store.dispatch('SetUserQueryPermission',usersquerypayload).then(function(QueryResult) {
      //     console.log('set usersquerypayload ',QueryResult)
      //   })
    let friendhipspayload = {                
        query: db.collection('friendships').where(user.uid,'==',true),
        arraymutation: 'setFriendshipsArray',
        getter: 'getFriendshipsArray',
            arrayname: 'Friendships'
      }
      let currentuserpayload = {
        userid: user.uid,
        db: db,
        RapidAppsDB: false
      }
          vm.$store.dispatch('SetUserObj',currentuserpayload).then(function(result) {
          if(result){
            if(vm.userIsAdmin && !vm.SystemisRA){
              vm.ClientAppAdminSignin()
              vm.GetAppFonts()
             let entmasterfieldspayload = {                
                query: vm.ConfigMasterPath.collection('entities').doc('Entities').collection('AllFields'),
                arrayname: 'EntityMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',entmasterfieldspayload)
              let sysmasterfieldspayload = {                
                query: vm.ConfigMasterPath.collection('entities').doc('Systems').collection('AllFields'),
                arrayname: 'SystemMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',sysmasterfieldspayload)
            }
            else if(vm.userIsAdmin){
              vm.GetAppFonts()
              let entmasterfieldspayload = {                
                query: vm.ConfigMasterPath.collection('entities').doc('Entities').collection('AllFields'),
                arrayname: 'EntityMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',entmasterfieldspayload)
              let sysmasterfieldspayload = {                
                query: vm.ConfigMasterPath.collection('entities').doc('Systems').collection('AllFields'),
                arrayname: 'SystemMasterFields'
              }
              vm.$store.dispatch('GetCollectionArray',sysmasterfieldspayload)
            }
            let Session = localStorage.getItem('activesession')
            if (Session === null) {
              vm.ActiveSession = new Date()
              localStorage.setItem('activesession', vm.ActiveSession);
            }
            else{
              vm.ActiveSession = Session
            }
            //console.log(result)
            vm.$store.dispatch('SetUserBUObj',result.Business_Unitid).then(function(BUResult) {
              //console.log('in dispatch BUResult')
              if(BUResult){
                //console.log('if BUResult',BUResult)
              }
              let userroles = vm.userLoggedIn.rolesarrayQuery
              if(vm.UserisGuest){             
                vm.SignedIn = true
                // if(!vm.System.Guests_can_Social){
                  //basically we used to think is guest cansocial that social is primary
                  //but here with sore we see store is primary. If you wanted community primary just change it.
                  //but where guest cansocial or not it does not matter
                  //the only catch is, there are thus two landing pages, 1 for when a user toggles to "intranetnavbar", and the other, beign the default page a user lands on when they sign in
                  //essentially, the previous code kept rerouting the user to the damn store page and crap and that's just tardy
                  if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){  
                    if(!vm.System.Guest_Landing_Page){
                      vm.$router.push('/MyAccount')
                    }  
                    else{
                      vm.$router.push(vm.System.Guest_Landing_Page)
                    }                  
                  }
                // }
                //  else{
                //    if(!vm.System.Guest_Landing_Page){
                //         if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login' || vm.$route.path.toLowerCase() === '/signup'){
                //             vm.IntranetViewToggle(true,true)
                //         } 
                //       }
                //       else{
                //         vm.$router.push(vm.System.Guest_Landing_Page)
                //       } 
                    
                //   }      
                 let notificationsquery = db.collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                    vm.GetUserNotifications(notificationsquery,10000)
                    if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                      vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                    }
                     if(vm.UsersStore.length === 0){
                      if(vm.CompanyOnlyFilter){
                        //uhm okay this is not too kind....we must maintain companyid on users please...
                        payload.query = [payload.query.where('Companyid','==',vm.UserCompanyid),payload.query.where('Companyid','==','Non_Guest')]
                        payload2.query = payload.query
                      }
                      vm.$store.dispatch('GetCollectionArray',payload)
                      vm.$store.dispatch('GetCollectionArray',payload2)
                    }
                    let onlineobj = {
                      status: 'online',
                      uid: user.uid
                    }
                    vm.$store.dispatch('UserLoginStatus',onlineobj)
                    vm.GetUserAccess()
              }
              else{
                vm.ProcessBalance(BUResult,user,vm,friendhipspayload,payload)                
              }
              
          })
          }
          
        })
      
    },  
    ProcessBalance(BUResult,user,vm,friendhipspayload,payload){
      vm.SystemEntities.map(build => {
         vm.GetGoalMeasurements(build).then(function(goalsresult) {
      })
        return build
      })
    
      if(BUResult){
                vm.SystemLoadingvalue = 100
                vm.GetSiteModerators()
                vm.GetUserAccess()
                
                // vm.SystemLoading = false
                if(vm.$route.path === '/' || vm.$route.path.toLowerCase() === '/login'){
                  if(vm.userIsAdmin && vm.$route.name !== 'GettingStarted'){
                    vm.$router.push('/Getting-Started')
                    // if(vm.userLoggedIn.Primary_Appid){
                    //   vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                    // }
                  }
                  else if(vm.userLoggedIn.Primary_Appid){
                    vm.$router.push('/App/'+vm.userLoggedIn.Primary_Appid)
                  }
                  else{
                    vm.$router.push('/MyDashboard')
                  }
                    
                }
                let onlineobj = {
                  status: 'online',
                  uid: user.uid
                }
                if(!vm.FriendshipsStore || vm.FriendshipsStore && vm.FriendshipsStore.length === 0){
                  vm.$store.dispatch('GetCollectionArray',friendhipspayload)
                }
                if(vm.UsersStore.length === 0){
                  vm.$store.dispatch('GetCollectionArray',payload)
                }
                
                vm.$store.dispatch('UserLoginStatus',onlineobj)
                let notificationsquery = db.collection('notifications').where('Ownerid', '==', vm.userLoggedIn.id)
                vm.GetUserNotifications(notificationsquery,10000)
                let activitiesquery = db.collection('activities').where('Ownerid', '==', vm.userLoggedIn.id).where('Status_Reason.Name', 'in' ,['Assigned','Completed','In Progress','On Hold'])
                vm.GetUserActivities(activitiesquery)
                //GetMyTeamActivities - would actually return my activities as well mind you... 
                //usersubordinates().hasAny([RecordOwner()])
                // getUserRoleData().SubordinateArrayDBrules.keys()
                let goalsquery = db.collection('goals').where('UserListIDs', 'array-contains', vm.userLoggedIn.id)
                vm.GetUserGoals(goalsquery)
              }
    },
  
    GetUserGoals(query){
      let vm = this
      query.onSnapshot(res => {
        let listlength = res.docs.length
        if(listlength === 0){
          this.MyGoalsisEmpty = true
        }
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let obj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          obj.Targets = []
          this.GetGoalTargets(obj).then(result => {
              console.log(obj,result)
              vm.MyGoals.push(obj)
          })
        }
      })
    })
    },
    GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').doc(vm.userLoggedIn.id).onSnapshot(snapshot => {
                  let targetobj = snapshot.data()
                  if(targetobj){
                    let targetslength = targetobj.Targets.length
                  targetobj.Targets.map((target,i) => {
                  obj.Targets.push(target)                  
                  //console.log(targetslength,i-1+2)
                      if(i-1+2 === targetslength){                             
                          resolve('Got all targets for ',obj.id)  
                      }
                    }) 
                  }
                  else{
                    resolve('Got no targets for user on ',obj.id)
                  }
                   
                })
            })

        },
    GetUserActivities(query){
      query.onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          this.MyActivitiesArray.push(actobj)
        }
        if(change.type === 'modified'){
          let actobj = {
            ...change.doc.data(),
            id: change.doc.id
          }
          let item = this.MyActivitiesArray.find(obj => obj.id === actobj.id)
          if(item){
            let index = this.MyActivitiesArray.indexOf(item)
            this.MyActivitiesArray.splice(index,1,actobj)
            //console.log('modified this.MyActivitiesArray',this.MyActivitiesArray)
          }
        }
      })
    })
    }, 
    GetUserAccess(Business_Unitid){
      let vm = this
      
      vm.SignedIn = true
      vm.SystemLoadingvalue = 100
      if(vm.SystemLoading){
        vm.SystemLoading = false
      }      
      //console.log('UserTableFilters',vm.UserTableFilters)
      let routes = vm.$router.getRoutes()
        let userroles = vm.userLoggedIn.rolesarrayQuery
        let routeslength = routes.length
        routes.map((route,routeindex) => {
          if(route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Warehouse'){
            //no interaction? what then? how do we set the permission level? do we need to?
            //also don't think we should do anything else for that matter, all the runs should be done from userloggedin watcher
          }
          else if(route.meta && route.meta.Security && route.meta.Security.SecurityType === 'Custom Roles'){
            this.CustomRolesDefault.map(defrole => {
              if(route.meta.Security[defrole.Boolean] || route.meta.Security[defrole.Prop] === 'Custom Role'){
                
                vm.CustomRolesCheck(route.meta.Security[defrole.Name],userroles).then(PermissionLevel => {
                  if(PermissionLevel === 1){
                  let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }      
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }
                }) 
              }
              else if(route.meta.Security[defrole.Prop] === 'Signed In' || route.meta.Security[defrole.Prop] === 'Public'){
                let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }        
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
              }
              else  if(route.meta.Security[defrole.Prop] === 'Admin'){
                if(this.userIsAdmin){
                  let payload = {
                          collectionname: route.name,
                          collectionstate: route.meta.subcat.split(' ').join('')+defrole.IntBoolean,
                          boolean: true
                        }        
                    vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                }                
              }
              if(defrole.Action === 'List'){
                //console.log(defrole.Name+' as defrole.Name on '+route.name,route.meta.Security[defrole.Boolean],route.meta.Security[defrole.Prop])
                if(route.meta.Security[defrole.Boolean] && route.name !== 'users' && route.name !== 'businessunits'){
                 
                  vm.CustomRolesCheck(route.meta.Security[defrole.Name],userroles).then(PermissionLevel => {
                  if(PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    let entity = vm.SystemEntities.find(obj => obj.id === route.meta.EntityID)
                    if(entity && entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'){
                      
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  })
                }
                else if(route.name === 'businessunits'){
                    if(!vm.userLoggedIn.IsGuestUser){
                      let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: db.collection(route.name)
                      }      
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                        let bupayload = {                
                                query: payload.query,
                                arraymutation: 'setBusinessUnitsArray',
                                getter: 'getBusinessUnitsArray',
                                arrayname: 'BusinessUnitsArray'
                              }
                              vm.$store.dispatch('GetCollectionArray',bupayload)
                        })
                  }
                  else{
                    let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: db.collection(route.name).where(firebase.firestore.FieldPath.documentId(), 'in', vm.userBUChildren)
                      }   
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                        let bupayload = {                
                                query: payload.query,
                                arraymutation: 'setBusinessUnitsArray',
                                getter: 'getBusinessUnitsArray',
                                arrayname: 'BusinessUnitsArray'
                              }
                              vm.$store.dispatch('GetCollectionArray',bupayload)
                        })
                  }
                }
                else if(route.meta.Security[defrole.Prop] === 'Signed In' || route.meta.Security[defrole.Prop] === 'Public'){
                  let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                }
                else if(this.userIsAdmin){
                  // else if(route.meta.Security[defrole.Prop] === 'Admin'){
                  //alert(defrole.Name+' as defrole.Name on '+route.name+' '+route.meta.Security[defrole.Boolean] +'as'+route.meta.Security[defrole.Prop])
                  // if(this.userIsAdmin){
                    let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                        query: db.collection(route.name)
                      }
                      if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                      vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                      })
                  // }
                }
              }            
            })
            
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Business Unit' && route.meta.Collection !== 'Activities'){
            let RoleObject = {}
            let length = this.BURolesDefault.length
         
            this.BURolesDefault.map((defrole,i) => {
              RoleObject[defrole.Name] = route.meta.Security[defrole.Name]
              if(defrole.Action !== 'List'){
                vm.BURolesCheck(RoleObject,defrole,userroles).then(PermissionsObject => {
                  if(defrole.PermissionLevel === PermissionsObject.PermissionLevel){
                      let payload = {
                        collectionname: route.name,
                        collectionstate: route.meta.subcat.split(' ').join('')+defrole.Boolean,
                        boolean: true
                      }
                      vm.$store.dispatch('SetUserInteractPermission',payload).then(function(QueryResult) {
                      })
                  }                  
                })
              }              
              if(i-1+2 === length){
                
                vm.BUListRolesCheck(RoleObject,userroles).then(PermissionsObject => {
                  //console.log(PermissionsObject,route.name)
                  if(PermissionsObject.PermissionLevel === 4){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    // let entity = vm.SystemEntities.find(obj => obj.id === route.meta.EntityID)
                    // if(entity && entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'){
                    //   //the problem is, query is going to be an array
                    //   let whqueryarray = []
                    //   let whlength = vm.$store.state.WarehousesArray.length
                    //   vm.$store.state.WarehousesArray.map((wh,whindex) => {
                    //     let passcheck = wh.id+route.meta.EntityID
                    //     let whquery = payload.query.where('Warehouseid','==',wh.id)
                    //     let whpayload = payload
                    //     //yrqFisJXWV2atmzk0OQBWarehouse_Products
                    //     if(passcheck){
                    //       whqueryarray.push(whquery)
                    //     }
                    //     if(whindex-1+2 === whlength){
                    //       whpayload.query = whqueryarray
                    //       vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    //       })
                    //     }
                    //   })
                    // }
                    // else{
                       vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                    // }
                   
                  }
                  
                  else if(PermissionsObject.PermissionLevel === 3){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Business_Unitid', 'in', vm.userBUChildren)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 2){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Business_Unitid', '==', vm.userBUID)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(PermissionsObject.PermissionLevel === 1){
                    let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where('Ownerid', '==', this.userLoggedIn.id)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
                  else if(route.meta.EntityID === 'User_Companies' && this.MyCompany && this.MyCompany.Userid === this.userLoggedIn.id){                    
                     let payload = {
                      collectionname: route.name,
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name).where(firebase.firestore.FieldPath.documentId(), '==', this.MyCompany.id)
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
                  }
              })
              }
            })
              
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && route.name !== 'BusinessUnits' || route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && !this.UserisGuest){
            let payload = {
                      collectionname: route.name.split(' ').join('').toLowerCase(),
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name.split(' ').join('').toLowerCase())
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Signed In' && route.name === 'BusinessUnits' && this.UserisGuest){
            
            let payload = {
                      collectionname: route.name.split(' ').join('').toLowerCase(),
                      collectionstate: route.meta.subcat.split(' ').join('')+'Query',
                      query: db.collection(route.name.split(' ').join('').toLowerCase()).where('IsPublicLocation','==',true)
                    }
                    if(vm.UserTableFilters){
                      let oncheck = vm.UserTableFilters.find(obj => obj.Table === route.meta.EntityID)
                      if(oncheck){
                        let fieldprop = oncheck.Field+'id'
                        payload.query = payload.query.where(fieldprop,'==',vm.userLoggedIn.id)
                      }
                    }
                    vm.$store.dispatch('SetUserQueryPermission',payload).then(function(QueryResult) {
                    })
          }
          else if(route.meta.Security && route.meta.Security.SecurityType === 'Business Unit'){

          }
          setTimeout(() => {
            //THIS IS A CHEESY CHEAT!!
          if(routeindex-1+2 === routeslength){
            vm.RouterReady = true
          }  
          }, 1000);
          
        })
    },
    CustomRolesCheck(Roles,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        // console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          // console.log(i)
              let Rolestest = Roles.find(obj => obj == role)
                if(Rolestest || vm.userIsAdmin || vm.userIsEagleViewer){
                  PermissionLevel = 1
                }      
                if(i-1+2 === Roleslength){
                  resolve(PermissionLevel)
                }
              })

        })
      },
    BURolesCheck(RoleObject,defrole,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          let roletest = RoleObject[defrole.Name].find(obj => obj == role)
           
          if(typeof roletest !== 'undefined' || vm.userIsAdmin){
              PermissionLevel = defrole.PermissionLevel
          }
         
            if(i-1+2 === Roleslength){
              
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              // console.log('Roleslength done',PermissionsObject)
              resolve(PermissionsObject)
            }
          })
        })
      }, 
    BUListRolesCheck(RoleObject,UserRoles){
        let vm = this
        let PermissionLevel = 0
        let Roleslength = UserRoles.length
        //console.log(Roleslength)
        return new Promise(function(resolve, reject) {
        UserRoles.map((role,i) => {
          let alltest = RoleObject.AlllistRoles.find(obj => obj == role)
          let peertest = RoleObject.UnitDownlistRoles.find(obj => obj == role)
          let parenttest = RoleObject.UserUnitlistRoles.find(obj => obj == role)
          let childtest = RoleObject.OwnerlistRoles.find(obj => obj == role)
           
          if(typeof alltest !== 'undefined' || vm.userIsAdmin || vm.userIsEagleViewer){
              PermissionLevel = 4
          }
          else if(typeof peertest !== 'undefined'){
            if(PermissionLevel<3){
              PermissionLevel = 3
            }
          }
          else if(typeof parenttest !== 'undefined'){
            if(PermissionLevel<2){
              PermissionLevel = 2
            }
          }
          else if(typeof childtest !== 'undefined'){
            if(PermissionLevel<1){
              PermissionLevel = 1
            }          
          }
            if(i-1+2 === Roleslength){
              let PermissionsObject = {
                PermissionLevel: PermissionLevel,
              }
              resolve(PermissionsObject)
            }
          })
        })
      }, 



  
  }
}
// <style scoped>
//   .v-application a {
//     color: var(--v-pop);
//   }font-family: var(--app-headerfont) !important;
// </style>
// 'url(' + require('@/assets/clouds.png') + ')'

//font install official
// @font-face {
//   font-family: "Chocolate Paint";
//   src: local("Chocolate Paint"),
//    url(./assets/fonts/Painting_With_Chocolate.ttf) format("truetype");
// }
// @font-face {
//   font-family: "Dihjauti";
//   src: local("Dihjauti"),
//    url(./assets/fonts/Dihjauti-Regular.otf) format("opentype");
// }
</script>

<style>
.BoldBuilderGradient {
	background: linear-gradient(-45deg, #ee9e94de, #d4729bd5, #466ca4dc, #23d5abc9);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
@keyframes edge {
  0% {
	transform: scaleX(0);
  }
  100% {
	transform: scaleX(1);
  }
}
@keyframes edgetiplft {
	0% {
	transform: scaleX(0);
  left: -13px
  }
  100% {
	transform: scaleX(1);
  left: -51px
  }
}
@keyframes edgetirght {
	0% {
	transform: scaleX(0);
  right: -13px
  }
  100% {
	transform: scaleX(1);
  right: -51px
  }
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes move-wavesforever {
  0% {
   transform: translate3d(-174px,0,0);
  }
  100% { 
    transform: translate3d(0px,0,0);
  }
}
.RAWebBtnGradlight{
  background: linear-gradient(345deg, rgba(78,85,143,1) 0%, rgba(145,100,139,1) 43%, rgba(207,157,182,1) 100%);
}
.RAWebBtnGradlightsoft{
  background: linear-gradient(345deg, rgba(78,85,143,0.5) 0%, rgba(145,100,139,0.5) 43%, rgba(207,157,182,0.5) 100%);
}
.RAWebBtnGraddark{
  background: linear-gradient(345deg, rgba(207,157,182,1) 0%, rgba(145,100,139,1) 43%, rgba(78,85,143,1) 100%);
}
.RAWebBtnGraddarksoft{
  background: linear-gradient(345deg, rgba(207,157,182,0.5) 0%, rgba(145,100,139,0.5) 43%, rgba(78,85,143,0.5) 100%);
}
.RAWebBtnGradShiny{
  background: linear-gradient(315deg, rgba(78,85,143,1) 0%, rgba(145,100,139,1) 43%, rgba(242,108,175,1) 88%, rgba(255,255,255,1) 100%);
}
.BuilderGradient {
	background: linear-gradient(-45deg, #ee9e9480, #d4729b7c, #466ca444, #23d5ab41);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
.SoftBuilderGradient {
	background: linear-gradient(-45deg, #ee9e9433, #d4729b24, #466ca444, #23d5ab41);
	background-size: 400% 400%;
	animation: gradientBG 15s ease infinite;
}
@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.objectcontentheader {
    align-items: flex-start;
    align-self: flex-start;
}
.v-application a {
  color: var(--app-darklinkscolor) !important;
}
.v-application .theme--light a:not(.theme--dark) {
  color: var(--app-lightlinkscolor) !important;
}
/* .theme--light a {
  color: var(--app-lightlinkscolor) !important;
} */


/* .theme--light .theme--dark a {
  color: var(--app-darklinkscolor) !important;
}
.theme--dark .theme--light a {
  color: var(--app-lightlinkscolor) !important;
} */
/* .dbnavlist .v-list .v-list-item--active{
    color: #FFA200 !important;
} */
.relative{
  position: relative;
}
.cloudbg {
    position: absolute;
    z-index: 0;
    bottom: 0;
    left: 0;
    width: 250.625em;
    height: 43.75em;
    animation: c 40s linear infinite;

}
@keyframes c {
  0% {
    transform: translate3d(15%,0,0);
    opacity: 0.1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
        transform: translate3d(-50%,0,0);
        opacity: 0.1;
  }
  
}
.stdparallaxgradient{
  background-image: linear-gradient(to right, rgba(0,0,0,0.5), rgb(0,132,255, 1));
}
.stdparallaxgradientinvert{
  background-image: linear-gradient(to right bottom, rgb(248, 239, 191), rgb(0,132,255, 1));
}
.announcementpulse{
  animation: announcepulse 2s infinite;
}
@keyframes announcepulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(230, 196, 5, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(211, 197, 3, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}

}
.BGImage{
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.centertext {
text-align:center;
}
.slightmargin{
  padding: 25px;
}
.elevatedsectionheader{
  z-index: 2;
  box-shadow: 0 2px 8px 0 rgb(100, 100, 100);
}
.overlayed{
  margin-top: -60px;
}
.stickytopconscious{
  top: 160px
}
.publicnav{
  top: 100px
}
.recordparallax{
  top: 160px;
}
.recordparallax2{
  top: 50px;
}
.pagetop{
  top: 50px;
}
.breadcrumbstop{
  top: 50px;
  z-index: 1;
}
.stickytopright {
  position: fixed;
  top: 110px;
  right: 10px;
  z-index: 4
}
.stickytopleft {
  position: fixed;
  top: 100px;
  left: 50px;
  z-index: 4
}
.stickytopbanner2{
  position: fixed;
  top: 65px;
  z-index: 4
}
.stickytopbanner2appstyling{
  position: fixed;
  top: 260px;
  z-index: 4
}
.stickytopbanner3{
  position: fixed;
  top: 130px;
  z-index: 4
}
.stickytopbanner4{
  position: fixed;
  top: 220px;
  z-index: 4
}
.leftpadded{
   right: 40px;
}
.footermargin{  
 
  padding-bottom: 150px;
}
.bottomspace{
  padding-bottom: 40px;
}
.focusedviewborder{
  border: solid;
  border-color: rgb(36, 36, 36);
	border-left-width: 50px;
  border-right-width: 50px;
  border-top-width: 150px;
  border-bottom-width: 150px;
  /* border-image: 
    linear-gradient(
      -45deg, 
      #ee7752, #e73c7e, #23a6d5, #23d5ab
    ) 1 100%;
	animation: gradientBG 15s ease infinite; */
  
}
.incompletedmandatory{
  border: red;
  color: white;
  font-size: 20px;
  animation: pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes borderlesspulse {
	0% {
		transform: scale(1.1);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(1.1);
	}
}
progress[value] {
  /* Reset the default appearance */
  -webkit-appearance: none;
   appearance: none;

  width: 250px;
  height: 20px;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
progress[value]::-webkit-progress-value {
  background:
	   -webkit-linear-gradient(-45deg, 
	                           transparent 33%, rgba(0, 0, 0, .1) 33%, 
	                           rgba(0,0, 0, .1) 66%, transparent 66%),
	   -webkit-linear-gradient(top, 
	                           rgba(255, 255, 255, .25), 
	                           rgba(0, 0, 0, .25)),
	   -webkit-linear-gradient(left, #FFCDD2, #E53935);

    border-radius: 2px; 
    background-size: 35px 20px, 100% 100%, 100% 100%;
    -webkit-animation: animate-stripes 5s linear infinite;
        animation: animate-stripes 5s linear infinite;
}
@-webkit-keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes animate-stripes {
   0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
h2{
  color: black;
}
.fixedquicknav{
  position: fixed;
  top: 160px;
}
.txtlink {
    color: rgb(253, 253, 253);
    text-decoration: none;
    font-size: 16px;
}
.rmgradient {
  background-image: linear-gradient(to BOTTOM, #468FBE ,#FFCDD2);
}
.foldertab {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; 
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  background-color: #8f91a1;
  
}
.paddedtab {
  padding: 10px;
  background-image: linear-gradient(to bottom, #8f91a1 ,white);
  
}


.folderyellow{
    clip-path: polygon(0 0, 30% 0, 34% 30%, 100% 30%, 100% 100%, 0 100%);
}
.documenttypeshadow{
    filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.documenttypeshadowinvert{
    filter: drop-shadow(1px -3px 3px rgba(50, 50, 0, 0.2));
}
.documenttypeshadowinvertcolor{
    filter: drop-shadow(1px -3px 3px rgba(255, 255, 253, 0.2));
}
/* .stripe-4 {
  color: white;
  background: repeating-linear-gradient(
    -55deg,
    #FFA000,
    #FFA000 10px,
    #FF8F00 10px,
    #FF8F00 20px
  ); */
  .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;

}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.articleoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--articleoverline) !important;
  line-height: 1.0; 
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.articlecaption {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--articlecaption) !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.largeoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--largeoverline) !important;
  line-height: 1.0; 
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.xtralargeoverline{
  font-weight: 500;
  line-height: 1.0; 
  font-size: 2.6em !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.xtralargeoverlinemini{
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  line-height: 1.0; 
  font-size: 2.0em !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}

.mediumoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.5em;
  letter-spacing: 0.1666666667;

}
.justaboveoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.2em;
  letter-spacing: 0.1666666667;

}
.smallbanneroverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.5em;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
}
.navoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  var(--app-headerfont--articlecaption) !important;
  letter-spacing: 0.1666666667;

}
.v-application {
  font-family: var(--app-bodyfont) !important;
  line-height: 1.5;
}
.v-application .display-4 {
  font-size:  var(--app-headerfont--display4)rem !important;
  font-weight: 300;
  line-height: 6rem;
  letter-spacing: -0.015625em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-3 {
  font-size:  var(--app-headerfont--display3) !important;
  font-weight: 300;
  line-height: 3.75rem;
  letter-spacing: -0.0083333333em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-2 {
  font-size:  var(--app-headerfont--display2) !important;
  font-weight: 400;
  line-height: 3.125rem;
  letter-spacing: normal !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .display-1 {
  font-size:  var(--app-headerfont--display1) !important;
  font-weight: 400;
  line-height: 2.5;
  letter-spacing: 0.0073529412em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .headline {
  font-size:  var(--app-headerfont--headline) !important;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .title {
  font-size:  var(--app-headerfont--title) !important;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em !important;
  font-family: var(--app-headerfont) !important;
}
.v-application .v-card__title {
  word-break: normal !important;
  line-height: 1em;
}
.v-application .subtitle-2 {
  font-size:  var(--app-headerfont--subtitle-2) !important;
  font-weight: 500;
  letter-spacing: 0.0071428571em !important;
  line-height: 1.375rem;
  font-family: var(--app-headerfont) !important;
}
.v-application .subtitle-1 {
  font-size:  var(--app-headerfont--subtitle-1) !important;
  font-weight: normal;
  letter-spacing: 0.009375em !important;
  line-height: 1.75rem;
  font-family: var(--app-headerfont) !important;
}
.v-application .body-2 {
  font-size: 0.875rem !important;
  font-weight: 400;
  letter-spacing: 0.0178571429em !important;
  line-height: 1.25rem;
  font-family: var(--app-bodyfont) !important;
}
.v-application .body-1 {
  font-size: 1rem !important;
  font-weight: 400;
  letter-spacing: 0.03125em !important;
  line-height: 1.5rem;
  font-family: var(--app-bodyfont) !important;
}
.v-data-table{
  font-family: var(--app-bodyfont) !important;
  color:#757575;
}
.v-data-table .text-start{
  font-family: var(--app-bodyfont) !important;
  font-size: 0.85rem !important;
  color:#757575;
}
.v-data-table__mobile-row__header{
  font-size: var(--app-headerfont--mobile-row__header) !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  font-family: var(--app-headerfont) !important;
}
.v-data-table__mobile-row{
  font-family: var(--app-bodyfont) !important;
  font-size: 1rem !important;
  color:#757575;
}
.v-application .caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: var(--app-bodyfont) !important;
}
.v-application .overline {
  font-size: 0.75rem !important;
  font-weight: 500;
  letter-spacing: 0.1666666667em !important;
  line-height: 2rem;
  text-transform: uppercase;
  font-family: var(--app-bodyfont) !important;
}

/* .subtleoverline {
  font-family: var(--app-headerfont) !important;
  font-weight: 300;
  font-size:  1.1em;
  letter-spacing: 0.1666666667;

} */
.lesssubtleoverline {
  font-size: 0.9em !important;
  font-weight: 400;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}
.subtleoverline {
  font-size: var(--app-headerfont--subtleoverline) !important;
  font-weight: 300;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}
.stdfontsubtleoverline {
  font-weight: 300;
  letter-spacing: 0.1666666667em !important;
  font-family: var(--app-headerfont) !important;
}

.listoutline{
  border-bottom: 0.5px solid #3498db;
}
i.v-icon.actionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactionicon {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 5px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactioniconsml {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 1px;
  box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3);
  
} 
.soloactioniconsmlinactive {
  border: 0.5px solid currentColor;
  border-radius:50%;
  padding: 1px;
  /* box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.3); */
  
} 
.MorningSun {
  position: absolute;
  top: 160px;
  height: 105px;
  width: 105px;
  background-color: rgba(243, 230, 108,0.95);
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}
.MiddaySun {
  position: absolute;
  top: 160px;
  height: 110px;
  width: 110px;
  background-color: #f5e16d;
  border-radius: 50%;
  display: inline-block;
  -webkit-animation: glow 1s ease-in-out infinite alternate;
  -moz-animation: glow 1s ease-in-out infinite alternate;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 10px #f5ec6d, 0 0 20px rgba(245, 236, 109,0.5), 0 0 30px #f3e66c, 0 0 40px rgba(245, 236, 109,0.5), 0 0 50px rgba(245, 236, 109,0.5), 0 0 60px rgba(245, 236, 109,0.5), 0 0 70px rgba(245, 236, 109,0.5);
  }
  
  to {
    box-shadow: 0 0 20px #f5ec6d, 0 0 30px rgba(243, 218, 108, 0.5), 0 0 40px rgba(243, 218, 108, 0.5), 0 0 50px rgba(243, 218, 108, 0.5), 0 0 60px rgba(243, 218, 108, 0.5), 0 0 70px rgba(243, 218, 108, 0.5), 0 0 80px rgba(243, 218, 108, 0.5);
  }
}
.Moon {
  position: absolute;
  top: 160px;
  height: 100px;
  width: 100px;
  background-color: rgba(238, 237, 234);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 1px 4px 15px rgb(241, 241, 217);
}
.FormFields{
  font-family: 'Segoe UI', Tahoma, Georgia, Verdana, sans-serif;
  color:#757575;
}
.intranetnavlinks {
  font-family: var(--app-headerfont) !important;
  font-weight: 500;
  font-size:  var(--app-headerfont--intranetnavlinks) !important;
  letter-spacing: 0.1666666667;
  text-transform: uppercase;
  
}
.topcut{
  margin-top: -150px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topcutsml{
  margin-top: -300px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
}
.topimg{
  margin-bottom: 300px;
}
.invertedenvlopetopcenter{
 margin-top: -300px;
 z-index: -1  ;
}
.invertedenvlopetopcenterforwards{
  animation: invertedenvlopetopcenteranim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopcenterbackwards{
  animation: invertedenvlopetopcenteranimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopcenteranim {
  0% {
   margin-top: -300px;
  }



  100% {
   margin-top: -500px;
    
  }
}
@keyframes invertedenvlopetopcenteranimrev {
  0% {
    margin-top: -500px;
  }



  100% {
   margin-top: -300px;
    
  }
}
.envelopeheaderforwards{
  animation: envelopeheaderanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.envelopeheaderbackwards{
  animation: envelopeheaderanimrev 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
@keyframes envelopeheaderanim {
  0% {
    margin-left: 0px;
  }



  100% {
   margin-top: 200px;
    
  }
}
@keyframes envelopeheaderanimrev {
  0% {
    margin-left: 200px;
  }



  100% {
   margin-top: 0px;
    
  }
}
.invertedenvlopetopleft{
 
  clip-path: polygon(0 0, 25% 0, 0 100%);
}
.invertedenvlopetopleftforwards{
  animation: invertedenvlopetopleftanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetopleftbackwards{
  animation: invertedenvlopetopleftanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetopleftanim {
  0% {
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }
}
@keyframes invertedenvlopetopleftanimrev {
  0% {
    clip-path: polygon(0 0, 5% 0, 0 100%);
  }



  100% {
   
    clip-path: polygon(0 0, 25% 0, 0 100%);
  }
}

.invertedenvlopetopright {
 background-image: linear-gradient(to right, rgba(255,0,0,0), rgba(255,0,0,1));

 clip-path: polygon(100% 0, 75% 0, 100% 100%);
}
.invertedenvlopetoprightforwards{
  animation: invertedenvlopetoprightanim 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}
.invertedenvlopetoprightbackwards{
  animation: invertedenvlopetoprightanimrev 1s;
  animation-iteration-count: 1;
   animation-fill-mode: forwards;
}
@keyframes invertedenvlopetoprightanim {
  0% {
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }
}
@keyframes invertedenvlopetoprightanimrev {
  0% {
    clip-path: polygon(100% 0, 95% 0, 100% 100%);
  }



  100% {
   
    clip-path: polygon(100% 0, 75% 0, 100% 100%);
  }
}

/* .invertedenvlopetopleft{
  clip-path: polygon(0 0, 50% 0, 0 100%);
} */
.tag-wrap {
 
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.inverted{
  clip-path: polygon(0 0, 100% 100%, 0 100%);
  
}
.straight {
  clip-path: polygon(0 0, 100% 0, 100% 100%);
}
.parallaxbgcontentgrad {
  background-image: linear-gradient(to right, rgba(1,0,15,0.835171568627451), rgba(9,46,54,0.47942927170868344));
}
.order-container-btn {
  position: fixed;
  top: 70px;
  left: 10px;
  z-index: 5
}
.chat-container {
  position: fixed;
  bottom: 112px;
  left: 50px;
  z-index: 5
}
.fab-container {
  position: fixed;
  bottom: 112px;
  right: 50px;
  z-index: 100
}
.dashboard-straighten-container {
  position: fixed;
  bottom: 60px;
  right: 50px;
  z-index: 5
}
.dashboard-edit-container{
  position: fixed;
  bottom: 60px;
  right: 30px;
  z-index: 5
}
.dashboard-filters-container{
  position: fixed;
  bottom: 120px;
  right: 50px;
  z-index: 5
}
.dashboard-colors-container{
  position: fixed;
  bottom: 120px;
  right: 110px;
  z-index: 5
}
.app-dashboard-straighten-container {
  position: fixed;
  bottom: 60px;
  right: 180px;
  z-index: 5
}
.app-dashboard-edit-container{
  position: fixed;
  bottom: 60px;
  right: 115px;
  z-index: 5
}
.app-dashboard-filters-container{
  position: fixed;
  bottom: 120px;
  right: 115px;
  z-index: 5
}
.app-dashboard-colors-container{
  position: fixed;
  bottom: 120px;
  right: 180px;
  z-index: 5
}
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: .5s;
}

.dot-flashing::before, .dot-flashing::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 0s;
}

.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #3498db;
  color: #3498db;
  animation: dotFlashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dotFlashing {
  0% {
    background-color: #3498db;
  }
  50%,
  100% {
    background-color: #ebe6ff;
  }
}
.basepulse {
  color: red;
  animation: basepulseanim 2s;
  animation-iteration-count: infinite
}
@keyframes basepulseanim {
	0% {
    opacity: 0.5;
		transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 2, 2, 0.7);
	}

	50% {
    opacity: 1.0;
		transform: scale(2);
    color: 0 0 0 10px rgba(189, 14, 14, 0.568);
	}

	100% {
    opacity: 0.5;
		transform: scale(0.95);
    color: 0 0 0 0 rgba(173, 44, 44, 0.274);
	}
}
.detailslistoutline:nth-child(odd) {
  background: rgb(238, 238, 238);
}
.darkdetailslistoutline:nth-child(odd) {
  background: rgb(51, 51, 51);
}
.darkdetailslistoutline:nth-child(even) {
  background: rgb(97, 97, 97);
}
.carouseloutline:nth-child(odd) {
  background: rgb(255, 255, 255);
}
.carouseloutline:nth-child(even) {
  background: rgb(0, 0, 0);
}
.carouseloutline :hover{
  background-color: rgba(168, 167, 167, 0.4);
}
.TaggedField{
 
    font-weight: bold;
    text-decoration: underline;
    height: 15px;
    padding-top: 1px;
    padding-right: 2px;
    padding-left: 2px;
    overflow: hidden;
    margin-bottom: 0px;
    vertical-align: middle;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value{
    font-size: 24px;
    color: rgb(0, 0, 0);
    line-height: 18px;
    margin-bottom: 6px;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__title{
      font-size: 12px;
    font-weight: bold;
    color: gray;
}
.fullapp {
    max-width: 100%;
    overflow: hidden;
}
.sideactivitiestoggle-container{
  position: absolute;
  top: 80px;
  right: 20px;
  z-index: 100
}
.createnotice-container {
  position: fixed;
  top: 240px;
  right: 50px;
  z-index: 8
}
.meetupqr-container{
  position: fixed;
  top: 300px;
  right: 50px;
  z-index: 100
}
.meetupcheckedin-container{
  position: fixed;
  top: 360px;
  right: 50px;
  z-index: 8
}
.whitelabel .v-input__slot{
  background-color: white!important;
}
.blacklabel .v-input__slot{
  background-color: black!important;
}
.commentblock {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #f0f2f5
}
.commentblockdark {
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 25px;
  background-color: #607D8B
}
.maptagitem {
  visibility: hidden;
}
    
.maptag:hover .maptagitem {
  visibility: visible;

  /* width: 50px;
  height: 50px; */
}
.growheight {
  transition: all .2s ease-in-out;
  }
.growheight:hover {
  transform: scale(1.1);
  transform: translate(0,-0.25em);
  }
.growright {
  transition: all .2s ease-in-out;
  }
.growright:hover {
  transform: scale(1.1);
  transform: translate(0.5em,0);
  }
.growleft {
  transition: all .2s ease-in-out;
  }
.growleft:hover {
  transform: scale(1.1);
  transform: translate(-0.5em,0);
  }
  .halfparallaxright{
      top: -50%;
    left: 50%;
    width: 50%
}
.halfparallaxleft{
      top: -50%;
    left: 0;
    width: 50%
}
.halfparallaximg .v-parallax__image {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblock {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblockright {
    top: -70%;
    left: 50%;
    width: 50%;
    min-width: 50%;
}
.halfparallaxblockleft {
    top: -70%;
    left: 0%;
    width: 50%;
    }
.scrollinfromright{
  animation: animscrollinfromright forwards;
  animation-duration: 1s;
  transition: transform 2s;
}
.scrollinfromrightreverse{
  animation: animscrollinfromright forwards;
  animation-direction: reverse;
  animation-duration: 1s;
  transition: transform 2s;
}

@keyframes animscrollinfromright {
  0% {right: calc(var(--WindowWidth) * -1px); top:0px;}
  100% {right:0px; top:0px;}
}
.scrollinfromleft{
  animation: animscrollinfromleft forwards;
  animation-duration: 1s;
  transition: transform 2s;
}
.scrollinfromleftreverse{
  animation: animscrollinfromleft forwards;
  animation-direction: reverse;
  animation-duration: 1s;
  transition: transform 2s;
}

@keyframes animscrollinfromleft {
  0% {left: calc(var(--WindowWidth) * -1px); top:0px;}
  100% {left:0px; top:0px;}
}
.HTMLTable{
  /* font-family: var(--app-headerfont) !important; */
font-family: var(--app-headerfont) !important;
color:#757575;
}
.HTMLTableContent{
border: 1px solid #dddddd;
  text-align: left;
  padding: 4px;
}
.tinylabel .v-label{
  font-size: 12px;
}
.tinyinput{
  max-width: 50%;
  font-size:12px;
  padding: 0;
}
.smallinput{
  max-width: 100%;
  font-size:1em;
  padding: 0;
  height: 3em;
  
}
.smallinput .v-input__control .v-text-field__details {
    position: absolute;
    margin: -10px 0px;
}
.smallinputspecial{
  max-width: 100%;
  font-size:1em;
  padding: 0;
  /* height: 3em; */
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
@keyframes alarmring {
  0%, 8%, 19%{
		transform: scale(1);
	}
  4%, 16%, 24%{
		transform: scale(1.05);
	}
  25% {
		transform: scale(1);
	}
  25%, 35%, 45%{
		transform:  rotate(-5deg);
	}
  30%, 40%, 50%{
		transform: rotate(5deg);
	}
  51.25%, 53.75%, 56.25%, 58.75%, 60.25%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0) ;
  }
  50%, 52.5%, 55%, 57.5%, 59% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0) rotate(0deg);
  }
  60.5% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }  
  72.5%, 75%, 77.5%, 79.5%, 81.5%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  71.25%, 73.75%, 76.25%, 78.75%, 80.25% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0) scale(1) rotate(0deg);
  }
}
@keyframes alarmpulse {
  0%, 8%, 19%{
		transform: scale(1);
	}
  4%, 16%, 24%{
		transform: scale(1.05);
	}
  25% {
		transform: scale(1) rotate(0deg);
	}
  100% {
		transform: scale(1) rotate(0deg);
	}
}
@keyframes vibrate {
  51.25%, 53.75%, 56.25%, 58.75%, 60.25%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  50%, 52.5%, 55%, 57.5%, 59% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  60.5% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  70% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }  
  72.5%, 75%, 77.5%, 79.5%, 81.5%
  {
    -webkit-transform: translate3d(1.5px, 0, 0);
    transform: translate3d(1.5px, 0, 0);
  }
  71.25%, 73.75%, 76.25%, 78.75%, 80.25% 
  {
    -webkit-transform: translate3d(-1.5px, 0, 0);
    transform: translate3d(-1.5px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }

}
@keyframes wiggle {

  2.5% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    }

  5% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  7.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    }
  10% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  20% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  22.5% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
    }

  25% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  27.5% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
    }
  30% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    }
}
.v-parallax__content{
  padding: 0!important;
}
#map{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: red;
  z-index: 100;
}
.appbartitlecenter .v-app-bar-title__content{
  width: 100%!important;
}
.smalltextarea textarea {
  line-height: 1.0rem!important;
}
.stickytop3{
  position: fixed;
  width: 100%;
  z-index: 4;
  box-shadow: 10px 10px 8px #888888;

}
.shopoverflowfilterbtn .v-select__slot{
  border: 2px solid gainsboro;
  border-radius: 5px;  
}

.shopoverflowfilterbtn .v-input__slot{
  border-style: none !important;
}
.siteconfigcard{
  border-radius: 25px !important;
  padding-top: 15px !important;
  margin-top: 30px !important;
}
.siteconfigcardsections{
  border-radius: 25px !important;
  padding-top: 35px !important;
}
.siteconfigcardtabs 
  .v-tabs-items {
  background-color: rgba(255, 255, 255, 0) !important;
}
.siteconfigcardtabs 
  .v-tabs-bar {
  background-color: rgba(255, 255, 255, 0) !important;
}
.tabtoolbarshown{  
  position: absolute;
  z-index: 3;
  width: 100%;
  top:0px;
  left: 100px;
}
.sitenavitem:hover {
  background-color: #f1f4f6;
}
.sitenavitem:hover .sitenavitembtns{
display: block;
}
.sitenavitembtns{
  display: none;
}
.tabtoolbar{
  display: none;
  position: absolute;
  z-index: 5;
}
.tabonbuild:hover .tabtoolbar {
  display: block;
}
.tabelmnttoolbar{
  display: none;
  position: absolute;
  z-index: 5;
}
.tabelmnttoolbarshown{
  position: absolute;
  z-index: 5;
}
.tabelmnt:hover .tabelmnttoolbar {
  display: block;
}
.noborderoverflow .v-input__slot{
  border-style: none !important;
  box-shadow: none !important;
}
.settingsfont {
  font-size:13px;font-weight: 300;
}
.machinetoggle{
  border-radius: 0px !important;
}
.machinetoggle .v-input--switch__thumb{
  border-radius: 0px;
  height: 22px;
}
.machinetoggle .v-input--switch__track{
  border-radius: 0px;
  height: 16px;
  width: 36px !important;
}
.recordcabinet{
  position: fixed;
  left: 155px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
.recordcabinetmini{
  position: fixed;
  left: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
/* .recordcabinet:hover .recordcabinetdrawer {
  left: 50px;
} */
.recordcabinetdrawer{
  transition: 0.5s;
  border-radius: 0 5px 5px 0;
  width: 100%;
  left: 0px;
}
.recordcabinetdrawermini{
  transition: 0.5s;
  border-radius: 0 5px 5px 0;
  width: 100%;
  left: 0px;
}
.recordcabinetdrawer:hover {
  left: 100px;
}
.recordviewtabs{
  position: fixed;
  right: 0px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
.recordviewtabsmini{
  position: fixed;
  right: -100px;
  width: 150px;
  text-decoration: none;
  z-index: 3;
}
/* .recordviewtabs:hover .recordviewtabssection {
  left: 50px;
} */
.recordviewtabssection{
  transition: 0.5s;
  border-radius: 5px 0 0 5px;
  width: 100%;
  right: 0px;
}
.recordviewtabssectionmini{
  transition: 0.5s;
  border-radius: 5px 0 0 5px;
  width: 100%;
  right: 0px;
}
.recordviewtabssection:hover {
  right: 100px;
}
.downsized {
    -webkit-transform: scale(0.65); /* Saf3.1+, Chrome */
     -moz-transform: scale(0.65); /* FF3.5+ */
      -ms-transform: scale(0.65); /* IE9 */
       -o-transform: scale(0.65); /* Opera 10.5+ */
          transform: scale(0.65);
          height: 50%;
          /* width: 200%; */
             /* IE6–IE9 */
             /* filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand'); */
}
.stdmenubarbtn{
  border: none;
  padding: 15px 32px;
  list-style-type: none;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  font-size: 12px;
}

.galleryoverlay{
  background-color: rgba(0, 0, 0, 0.768) !important;
}
.pagerow :hover{
  background-image: linear-gradient(to right, rgba(0,132,255, 0.2), rgb(0,132,255, 0.2));
}
.flipcard{
  padding-right:10px;
  padding-left:10px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-perspective: 1000px;
	        perspective: 1000px;
}

.flipcardfront,
.flipcardback{
  background-size: cover;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
  border-radius: 10px;
	background-position: center;
	-webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
	-webkit-backface-visibility: hidden;
	        backface-visibility: hidden;
	text-align: center;
	min-height: 280px;
	height: auto;
	border-radius: 10px;
	color: #fff;
	font-size: 1.2rem;
}

/* .flipcardback{
  background: #cedce7;
  background: -webkit-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  background: -o-linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  background: linear-gradient(45deg,  #cedce7 0%,#596a72 100%);
  visibility: hidden; 
}*/

.flipcardfront:after{
	position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    opacity: 1;
    background-color: currentColor;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    border-radius: 10px;
}
.flipcard:hover .flipcardfront,
.flipcard:hover .flipcardback
{
    -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
    transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
}

.flipcardback{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.flipcardinner{
    -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
            transform: translateY(-50%) translateZ(60px) scale(0.94);
    top: 50%;
    position: absolute;
    left: 0;
    width: 100%;
    padding: 2rem;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    outline: 1px solid transparent;
    -webkit-perspective: inherit;
            perspective: inherit;
    z-index: 2;
}

.flipcard .flipcardback{
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flipcard .flipcardfront{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.flipcard:hover .flipcardback{
  -webkit-transform: rotateY(0deg);
          transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
          /* visibility: visible; */
}

.flipcard:hover .flipcardfront{
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}

.flipcardfront .flipcardinner p{
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.flipcardfront .flipcardinner p:after{
  content: '';
  width: 4rem;
  height: 2px;
  position: absolute;
  background: currentColor;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -.75rem;
}

.flipcardfront .flipcardinner span{
  color: rgba(255,255,255,0.7);
  font-family: 'Montserrat';
  font-weight: 300;
}
.gradoverlay{
  z-index: 0 !important;
}
.galcarousel .v-carousel__controls{
  display: none !important;
}
@keyframes imgfadeout {
  0% {
    opacity:1;
  }
  100% {
    opacity:1;
  }
}
@keyframes imgfadein {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}
.mainspecial{
  padding: 0px !important;
}
.unpaddedappbar .v-toolbar__content{
  padding: 0px !important;
}

.comparisonslider {
  position: absolute;
  left: 50%;
  inset: 0;
  cursor: pointer;
  opacity: 0;
  /* for Firefox */
  width: 100%;
  height: 100%;
}
.comparisonslider-line {
  position: absolute;
  inset: 0;
  width: .2rem;
  height: 100%;
  background-color: #fff;
  transform: translateX(-50%);
  pointer-events: none;
}

.comparisonslider-button {
  position: absolute;
  background-color: #fff;
  color: black;
  padding: .5rem;
  border-radius: 100vw;
  display: grid;
  place-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  box-shadow: 1px 1px 1px hsl(0, 50%, 2%, .5);
}
</style>