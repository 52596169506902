    <template>
  <nav>    

      <v-navigation-drawer
          :dark="AppisDarkMode"
          color="dbcard"
           v-if="!$vuetify.breakpoint.xs && !OldStyle"
          
          mini-variant-width="56"
        expand-on-hover app  style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
        >
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src='@/assets/logo.png'></v-img>
            </v-list-item-avatar>
            <v-list-item-title>
              {{ComputedSingleRecordNavName}}
            </v-list-item-title>
          </v-list-item>

          <v-divider></v-divider>

          <v-list
            
            nav
          >
            <v-list-item  @click="ActivateSingleRecordNavMenuItem(item)"
              v-for="item in ComputedSingleTabsLayout"
              :key="item.itemObjKey"
              link
            >
            <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                           <v-list-item-icon v-bind="attrs"
                             v-on="on">
                            <v-icon>{{item.Icon}}</v-icon>
                          </v-list-item-icon>
                          </template>
                          <v-card max-width="200" v-html="item.DisplayName" tile class="transparent white--text">
                          </v-card>
                      </v-tooltip> 
            
            <v-list-item-title>{{item.DisplayName}}</v-list-item-title>
              
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
    <v-navigation-drawer v-if="!$vuetify.breakpoint.xs && !OldStyle"
      permanent app right
         width="350"
    >
      <v-row
        class="fill-height"
        no-gutters
      >
       <v-list class="grow dbcard" :dark="AppisDarkMode">
         <v-list-item class="links">     
          <v-list-item-title class="overline">
              {{SingleRecordNavName}}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="overline background" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19)">
          Item List
        </v-list-item>
        <v-virtual-scroll v-if="ParentNavItem && ParentNavItem.Children"
            class="transparent"
              :items="ParentNavItem.Children"
              :item-height="40"
              :height="300"
            >
              <template v-slot:default="{ item }">
          <v-list-item @click="ActivateSingleRecordNavMenuItem(item)"
              link
          >
          <v-list-item-title style="font-size:14px;" class="navoverline" ><v-icon size="20" color="accent">{{ item.Icon }}</v-icon> {{ item.DisplayName }}</v-list-item-title>
          </v-list-item>
              </template>
        </v-virtual-scroll>
          <v-list-item  :style="ParentNavItem && ParentNavItem.Children ? 'box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.19)' : ''" class="background" v-if="ParentNavItem && ParentNavItem.SystemGuides">
         <v-text-field v-model="GuideSearch" label="Guide Search" />
        </v-list-item>
          <v-virtual-scroll v-if="ParentNavItem && ParentNavItem.SystemGuides"
            class="background"
              :items="ParentNavItemSystemGuides"
              :item-height="360"
              :height="WindowHeight*0.45"
            >
              <template v-slot:default="{ item }">
                <v-card class="my-3 mx-auto"
                max-width="250"
              >
              <v-sheet :class="item.Disabled ? 'grey lighten-4' : ''">
                <v-carousel
                    :cycle="!item.Disabled"
                    height="150"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                 
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  > <v-btn @click="ToggleFeatureFavorites(item)" v-if="$route.meta && $route.meta.RecordisSystem" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmap(item)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmap(item)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>

                <v-card-subtitle :class="item.Disabled ? 'pb-0 grey--text' : 'pb-0'">
                  {{item.Name}} <v-chip class="mx-2" small dark :color="item.DisabledColor" v-if="item.Disabled">{{item.DisabledName}} <v-icon>{{item.DisabledIcon}}</v-icon></v-chip>
                </v-card-subtitle>
                   
                <v-card-text class="text--primary">
                  <h5 :class="item.Disabled ? 'grey--text' : 'text--primary'" >{{item.Category.Name}}</h5>

                  <div  :class="item.Disabled ? 'caption grey--text' : 'caption'" v-html="item.Description"></div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="pink"
                    text
                    small
                    v-if="item.HasActionButton"
                    :disabled="item.Disabled"
                    @click="ActivateSingleRecordNavMenuItem(item)"
                  >
                    Add Now 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    target="_blank"
                    :href="item.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions>
              </v-sheet>
              </v-card>
              </template>
            </v-virtual-scroll>
          
          
        </v-list>
        

       
      </v-row>
    </v-navigation-drawer>
    <v-navigation-drawer
      permanent app v-if="OldStyle"
      right   width="350"
    >
      <v-row
        class="fill-height"
        no-gutters
      >
       <v-list class="grow dbcard" :dark="AppisDarkMode">
         <v-list-item class="links">     
          <v-list-item-title class="overline">
              {{SingleRecordNavName}}
          </v-list-item-title>
        </v-list-item>
        <v-list-item class="overline background" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.19)">
          Item List
        </v-list-item>
        <v-virtual-scroll v-if="ParentNavItem && ParentNavItem.Children"
            class="transparent"
              :items="ParentNavItem.Children"
              :item-height="40"
              :height="300"
            >
              <template v-slot:default="{ item }">
          <v-list-item @click="ActivateSingleRecordNavMenuItem(item)"
              link
          >
          <v-list-item-title style="font-size:14px;" class="navoverline" ><v-icon size="20" color="accent">{{ item.Icon }}</v-icon> {{ item.DisplayName }}</v-list-item-title>
          </v-list-item>
              </template>
        </v-virtual-scroll>
          <v-list-item  :style="ParentNavItem && ParentNavItem.Children ? 'box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.19)' : ''" class="background" v-if="ParentNavItem && ParentNavItem.SystemGuides">
         <v-text-field v-model="GuideSearch" label="Guide Search" />
        </v-list-item>
       <SystemGuideComponent v-if="ParentNavItemSystemGuides" :AppisDarkMode="AppisDarkMode"  :GuideList="ParentNavItemSystemGuides" :tab="ParentNavItem" />
          
          
        </v-list>
        <v-navigation-drawer
          :dark="AppisDarkMode"
          mini-variant
          mini-variant-width="56"
          permanent style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"
        >
          <v-list-item class="px-2">
            <v-list-item-avatar>
              <v-img src='@/assets/logo.png'></v-img>
            </v-list-item-avatar>
          </v-list-item>

          <v-divider></v-divider>

          <v-list
            
            nav
          >
            <v-list-item  @click="ActivateSingleRecordNavMenuItem(item)"
              v-for="item in ComputedSingleTabsLayout"
              :key="item.itemObjKey"
              
            >
              <v-list-item-action>
                <v-tooltip left>
                          <template v-slot:activator="{ on, attrs }">
                           <span v-bind="attrs"
                             v-on="on"> <v-icon v-on="on">{{ item.Icon }}</v-icon>
                           </span>
                          </template>
                          <v-card max-width="200" v-html="item.DisplayName" tile class="transparent white--text">
                          </v-card>
                      </v-tooltip>                
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>

       
      </v-row>
    </v-navigation-drawer>
    <!-- <v-navigation-drawer class="dbcard" :dark="AppisDarkMode" permanent app v-model="drawer">
    
      <v-list>   
        <v-list-item class="links" style="margin-bottom:10px;">     
          <v-list-item-title class="mediumoverline">
              {{SingleRecordNavName}}
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <div v-for="link in ComputedSingleTabsLayout" :key="link.title">
          <v-list-item class="dbcard darken-1" @click="ActivateSingleRecordNavMenuItem(link)">
            <v-list-item-title class="overline">
                {{link.DisplayName}}
            </v-list-item-title>
            <v-list-item-action>
              <v-icon color="configtext">{{ link.Icon }}</v-icon>
            </v-list-item-action>
              </v-list-item>
          <v-divider>
                </v-divider>
            <v-list
              dense
              v-if="link.Children && link.Children.length"
              >
                <template v-slot:activator>
                  <v-list-item-action>
                  <v-icon class="actionicon" color="accent">{{ link.Icon }}</v-icon>
                </v-list-item-action>
                  <v-list-item-title class="navoverline">{{link.DisplayName}}</v-list-item-title>
                </template>
                <div v-for="subsub in link.Children"
                        :key="subsub.itemObjKey">
                    <v-list-item @click="ActivateSingleRecordNavMenuItem(subsub)" class="mx-3" v-if="!subsub.Children && subsub.title !== 'Dashboard Builder'"
                        
                        :to="subsub.to" :target="subsub.target"
                    >          
                    <v-list-item-action>
                     <v-icon size="20" color="accent">{{ subsub.Icon }}</v-icon>
                      </v-list-item-action>                          
                        <v-list-item-title class="navoverline">{{ subsub.DisplayName }}</v-list-item-title>
                    </v-list-item>
                    <v-list-group
                    sub-group @click="ActivateSingleRecordNavMenuItem(subsub)"
                      v-if="subsub.Children && subsub.Children.length"
                      >
                        <template v-slot:activator>
                          <v-list-item-title class="navoverline">{{subsub.DisplayName}}</v-list-item-title>
                        </template>
                        <v-list-item class="mx-3" v-for="subchild in subsub.Children" :key="subchild.itemObjKey"
                          :to="subchild.to" :target="subchild.target" @click="ActivateSingleRecordNavMenuItem(subchild)"
                      >          
                      <v-list-item-action>
                      <v-icon small color="accent">{{ subchild.Icon }}</v-icon>
                          
                        </v-list-item-action>                          
                          <v-list-item-title class="navoverline">{{ subchild.DisplayName }}</v-list-item-title>
                      </v-list-item>
                    </v-list-group>
                </div>
              </v-list>
          </div>
      </v-list>

    </v-navigation-drawer> -->
    <v-dialog v-model="TicketLog" max-width="600px">
      <v-card width="100%">
        <SystemTicketsLogging @CancelSubmitSystemTicket="CancelSubmitSystemTicket" :UserRecord="UserRecord" :UsersArray="UsersArray" :RelatedObj="RelatedObj" :View="'Single'" />
      </v-card>
    </v-dialog>

  </nav>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import BreadCrumbs from '@/components/Navigation/BreadCrumbs'
import SystemTicketsLogging from '@/components/SuiteBuilder/Support/SystemTicketsLogging'

export default {
  props: ['AppisDarkMode','CategoricalNavlists','SingleBuildTabs','EntityReady','SubCollections','SingleRecordNavName','SitePages','ConfigSystemEntities','SystemPhotoLibraries','SingleRecordNavMenuItem','ParentNavItem','SystemEntities','FavoriteFeatures','System'],
  components: {
  BreadCrumbs,
  SystemTicketsLogging
  },
  data() {
    return {
      OldStyle: false,
      GuideSearch: '',
      items: [
          { title: 'Home', icon: 'mdi-view-dashboard' },
          { title: 'About', icon: 'mdi-forum' },
        ],
        links: ['Home', 'Contacts', 'Settings'],
        mini: true,

      InviteUserisAdmin: false,
      rules: {
      min8Chars: value => value.length >= 8 || "Min. 8 characters",
      required: value => !!value || "Required.",
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Invalid e-mail.";
      },
      url: value => {
        const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); 
        return urlpattern.test(value) || "Invalid Link.";
      }
    },

      InvitedUsersArray: [],
      IsGuestUser: true,
      Modules : [
      {
        Name: 'McCormick Training',
        URL: 'https://mccormick-training.web.app'
      },
      {
        Name: 'McCormick Training',
        URL: 'https://mccormick-training.web.app'
      },
      ],
      ModulePath: {
        Name: 'McCormick Training',
        URL: 'https://mccormick-training.web.app'
      },
      InviteUserBU: '',
      GuestBusinessUnitArray : [],
      InviteUserDialog: false,
      InvitingUserEmail: '',
      UserDashboards: [],
      RecordName: '',
      TicketLog: false,
      UsersArray: [],
      drawer: false,
  
      UserFullName: '',
      UserPicture: '',
      UserAssignedRoles: {},
      hover: false,
      
      ViewingNotificaitons: false,
      UserRecord: {},
    }
  },
  computed: {
    ComputedCategoricalNavlists(){
        return this.CategoricalNavlists
      },
    StoreSystemObj(){
            return this.$store.state.SystemObj
        },
    ComputedSingleRecordNavName(){
      if(this.$route.meta && this.$route.meta.RecordisSystem){
        return this.System.Name
      }
      else{
        return this.SingleRecordNavName
      }
    },
    ParentNavItemSystemGuides(){
      if(this.ParentNavItem && this.ParentNavItem.SystemGuides){
        return this.ParentNavItem.SystemGuides.filter(guide => {
          if(this.GuideSearch){
            return guide.Name.toLowerCase().includes(this.GuideSearch.toLowerCase()) || guide.Description.toLowerCase().includes(this.GuideSearch.toLowerCase())
          }
          else{
            return guide
          }
        })
      }
    },
    WindowWidth(){
      return window.innerWidth
    },
    WindowHeight(){
      return window.innerHeight
    },
    ComputedSingleTabsLayout(){
      let TransposedTabs = this.SingleBuildTabs.filter(tab => {
        return tab.TransposedTabs
      })
      console.log(TransposedTabs[0])
      let additionalmenu = [
        {
            Icon: 'mdi-account-question',
            Name: 'FAQ',
            DisplayName: 'FAQ',
            RouteOnly: true,
            RoutePath: '/FAQAdmin'
            
          },
          {
            Icon: 'mdi-file-pdf-box',
            Name: 'PDF Templates',
            DisplayName: 'PDF Templates',
            RouteOnly: true,
            RoutePath: '/PDFExportTemplates'
            
          },
          {
            Icon: 'mdi-cash-register',
            Name: 'Monetization',
            DisplayName: 'Monetization',
            RouteOnly: true,
            RoutePath: '/Monetization'
            
          },
          // {
          //   Icon: 'mdi-pipe',
          //   Name: 'Pipelines',
          //   DisplayName: 'Pipelines',
          //   RouteOnly: true,
          //   RoutePath: '/Pipelines'
            
          // },
          // {
          //   Icon: 'mdi-auto-fix',
          //   Name: 'Automations',
          //   DisplayName: 'Automations',
          //   RouteOnly: true,
          //   RoutePath: '/Automations'
            
          // },
          // {
          //   Icon: 'mdi-help',
          //   Name: 'Features',
          //   DisplayName: 'Features',
          //   FreezeViewPort: true,
          //   ChildrenisDialog: true,
          //   Children: [],
          //   Cards: [
          //     {
          //         HasCard: true,
          //         Name: 'Site Articles',
          //         DisplayName: 'Site Articles',
          //         Icon: 'mdi-newspaper', 
          //         GuideItem: TransposedTabs[0],
          //         Disabled: true,               
                  
          //       },
          //       {
          //         HasCard: true,
          //         Name: 'Site Blogs',
          //         DisplayName: 'Site Blogs',
          //         Icon: 'mdi-blogger',
          //         GuideItem: TransposedTabs[0]              
                  
          //       },
          //        {
          //         HasCard: true,
          //         Name: 'Site Articles',
          //         DisplayName: 'Site Articles',
          //         Icon: 'mdi-newspaper', 
          //         GuideItem: TransposedTabs[0]                
                  
          //       },
          //       {
          //         HasCard: true,
          //         Name: 'Site Blogs',
          //         DisplayName: 'Site Blogs',
          //         Icon: 'mdi-blogger',
          //         GuideItem: TransposedTabs[0]              
                  
          //       }
          //   ]
            
          // }
      ]
      console.log('SystemEntities',this.SystemEntities)
      let storeproductentities = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Store Product'
      }).map(entity => {
        let menuobj = {
                  Name: entity.DisplayName,
                  DisplayName: entity.DisplayName,
                  Icon: entity.Table_Icon,
                  CatNavlistBuilder: true,
                  id: entity.id
                }
        return menuobj
      })
      let classifiedentities = this.SystemEntities.filter(entity => {
        return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
      }).map(entity => {
        let menuobj = {
                  Name: entity.DisplayName,
                  DisplayName: entity.DisplayName,
                  Icon: entity.Table_Icon,
                  CatNavlistBuilder: true,
                  id: entity.id
                }
        return menuobj
      })
      // StoreSystemObj
      let socialclassifiedobj = 
      {
        Name: 'Classifieds',
        DisplayName: 'Classifieds',
        Icon: 'mdi-newspaper-variant-outline',
        CatNavlistBuilder: true,
        id: 'Classifieds'
      }
      let groupcategoriesobj = {
        Name: 'Group Categories',
        DisplayName: 'Group Categories',
        Icon: 'mdi-account-group',
        CatNavlistBuilder: true,
        id: 'Group_Categories'
      }
      let featuredmembersobj = {
        Name: 'Group Featured Members',
        DisplayName: 'Group Featured Members',
        Icon: 'mdi-account-star-outline',
        CatNavlistBuilder: true,
        id: 'Group_Featured_Members'
      }
      let CatnavlistLibraries = [
              {
                Icon: 'mdi-menu',
                Name: 'Data Navlists',
                DisplayName: 'Data Navlists',
                Children: storeproductentities.concat(classifiedentities)
              }
            ]
      if(this.StoreSystemObj){
        if(this.PluginDataBase.Site_Classifieds){
          CatnavlistLibraries[0].Children.push(socialclassifiedobj)
        }
        if(this.PluginDataBase.Site_Groups){
          CatnavlistLibraries[0].Children.push(groupcategoriesobj)
        }
        CatnavlistLibraries[0].Children.push(featuredmembersobj)
      }
      let wikientities = this.SystemEntities.filter(entity => {
        return entity.Has_Wiki
      }).map(entity => {
        let menuobj = {
                  Name: entity.DisplayName,
                  DisplayName: entity.DisplayName,
                  Icon: entity.Table_Icon,
                  WikiCollectionBuilder: true,
                  id: entity.id
                }
        return menuobj
      })
      
      let WikiLibraries = [
              {
                Icon: 'mdi-wikipedia',
                Name: 'Wiki Tables',
                DisplayName: 'Wiki Tables',
                Children: wikientities
              }
            ]
          let ContentLibraryBuilder = []
          let htmllibraries = []
          let htmllibbuilderobj = {
              Icon: 'mdi-library',
              Name: 'Content Libraries',
              DisplayName: 'Content Libraries',
              Children: [{
                  Name: 'New Library',
                  DisplayName: 'New Library',
                  Icon: 'mdi-plus-thick',
                  ContentLibraryBuilder: true,
                  id: 0
                }
              ].concat(htmllibraries)
            }
            ContentLibraryBuilder.push(htmllibbuilderobj)
          let librariesarray = []
          let pagesarray = []
          let publicpages = this.SitePages.filter(page => {
                return page.PublishType === 'Public'
              }).map(page => {
                let pageobj = {
                  Name: page.Name,
                  DisplayName: page.Name,
                  Icon: page.PageIcon,
                  IsPage: true,
                  id: page.id
                }
                return pageobj
              })
            let internalpages = this.SitePages.filter(page => {
                return page.PublishType === 'Members Only'
              }).map(page => {
                let pageobj = {
                  Name: page.Name,
                  DisplayName: page.Name,
                  Icon: page.PageIcon,
                  IsPage: true,
                  id: page.id
                }
                return pageobj
              })
          if(this.$route.meta && this.$route.meta.RecordisSystem && !this.$route.meta.query || this.$route.meta && this.$route.meta.RecordisSystem && this.$route.meta.query && !this.$route.meta.query.TableType){
            let publicpageobj = {
              Icon: 'mdi-search-web',
              Name: 'Public Pages',
              DisplayName: 'Public Pages',
              // PageBuilder: true,
               
              Children: [{
                  Name: 'New Page',
                  DisplayName: 'New Page',
                  Icon: 'mdi-plus-thick',
                  PageBuilder: true,
                  PublishType: 'Public',
                  id: 0
                }
              ].concat(publicpages)
            }
            pagesarray.push(publicpageobj)
            let nonpublicpageobj = {
              Icon: 'mdi-language-html5',
              Name: 'Internal Pages',
              DisplayName: 'Internal Pages',
           
              Children: [{
                  Name: 'New Page',
                  DisplayName: 'New Page',
                  Icon: 'mdi-plus-thick',
                  PageBuilder: true,
                  PublishType: 'Members Only',
                  id: 0
                }
              ].concat(internalpages)
            }
            pagesarray.push(nonpublicpageobj)
          
              let webformobj = {
              Icon: 'mdi-form-select',
              Name: 'Web Forms',
              DisplayName: 'Web Forms',
              IsWebFormBuilder: true,
              Children: [
                {
                  Name: 'New Web Form',
                  DisplayName: 'New Web Form',
                  Icon: 'mdi-plus-thick',
                  IsWebForm: true,
                  id: 'NewWebForm'
                },
                {
                  Name: 'Existing Forms',
                  DisplayName: 'Existing Forms',
                  Icon: 'mdi-form-select',
                  IsWebForm: true,
                  id: 'ExistingWebForms'
                }
              ]
            }
            pagesarray.push(webformobj)
            
            if(this.SystemPhotoLibraries){
              let newlibrary = {
                    Name: 'New Library',
                    DisplayName: 'New Library',
                    Icon: 'mdi-plus-thick',
                    RouteOnly: true,
                    RoutePath: '/NewPhotoLibrary'
                  }
                let libitems = [newlibrary]
                let liblist = this.SystemPhotoLibraries
                // .filter(page => {
                //   return page.PublishType === 'Members Only'
                // })
                .map(lib => {
                  let libobj = {
                    Name: lib.DisplayName,
                    DisplayName: lib.DisplayName,
                    Icon: lib.Icon,
                    IsPhotoLibrary: true,
                    id: lib.id
                  }
                  return libobj
                })
                libitems = libitems.concat(liblist) 
                let libraryobj = {
                Icon: 'mdi-folder-multiple-image',
                Name: 'Photo Libraries',
                DisplayName: 'Photo Libraries',
                PhotoLibraryBuilder: true,
                Children: libitems
              }
              librariesarray.push(libraryobj)
            }
            
            
          }
          let datasingletabs = this.SingleBuildTabs.filter(tab => {
      return !tab.TransposedTabs
    })
    .map(tab => {
      if(tab.DataViewName === 'Data Single Related'){
        tab.Children = []
        tab.Elements.map(section => {       
          if(section.DataViewName === 'Data Single SubCollection'){
         let subcolfullentity = this.SubCollections.find(obj => obj.id === section.Entityid)   
         console.log('subcolfullentity',subcolfullentity,this.SubCollections,section,tab) 
         let array = [] 
         if(subcolfullentity){
           array = subcolfullentity.Data.map(item => {
            //this PrimaryHeader is actually configured nowhere.
            item.Icon = section.Icon
            item.Name = item[subcolfullentity.PrimaryHeader]
            if(subcolfullentity.Type === 'Lookup' && item.LookupObj && item.LookupObj.list){
              let buildobj = this.ConfigSystemEntities.find(obj => obj.id === item.LookupObj.list)
              if(subcolfullentity.EntityBuilder){
                  item.RouteQuery = { TableType: 'System_Entity',TableEntity: buildobj.id,RecordCollection: section.Entityid,Record: item.id}
                }
              item.TabDisplayName = tab.DisplayName            
            }
            
            return item
          })
          console.log(array)
         }
          
          tab.Children = tab.Children.concat(array)
          }
        })
      }
      else if(tab.DataViewName === 'Data Single Standard'){
        let elements = tab.Elements.map(elmnt => { 
          if(elmnt.DataViewName === 'Data Single Fields'){  
            let tabobj = Object.assign({},tab)              
            let elmntobj = Object.assign({},elmnt)
            tabobj.Elements = []
            tabobj.Elements.push(elmntobj)
            //tabobj.Name = elmntobj.Name
            tabobj.DisplayName = elmntobj.DisplayName
            tabobj.RenderSingleSection = true
            tabobj.Icon = elmntobj.Icon
            delete tabobj.Children
            elmnt = tabobj
          }
          return elmnt
        })
        //console.log('TransposedTabs',tab.Name,TransposedTabs)
        let transposedchildren = TransposedTabs.filter(transtab => {
          console.log('TransposedTabs tab',transtab)
          return transtab.TransposedParent === tab.Name
          })
        console.log('TransposedTabs transposedchildren',tab.Name,TransposedTabs,transposedchildren)
        tab.Children = elements.concat(transposedchildren)
      }
      else{
        tab.Children = tab.Elements
      }
      console.log('tab',tab)
      return tab
    })
    let dashboards = [
      
    ]
    return this.EntityReady? dashboards.concat(datasingletabs,pagesarray,librariesarray,WikiLibraries,CatnavlistLibraries,ContentLibraryBuilder,additionalmenu) : []
  },
  PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
  
    },

    watch: {
      SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateSingleRecordNavMenuItem']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      userLoggedIn (value) {
        if (value === null || value === undefined) {
          if(this.$route.path.includes('UserInvite')){    
          }
          else{
          this.$router.push('/login')
          }
        }
        //if (value !== null && value !== undefined) {
        //  this.$router.push('/')
        //}
      },
      ComputedSingleTabsLayout:{
         handler: function(oldvalue, newvalue) {
           //alert('SingleRecordNavTabs')
          if(this.ComputedSingleTabsLayout){
            //console.log('SingleRecordNavTabs',this.ComputedSingleTabsLayout)
            this.$store.commit('SetSingleNavTabs',this.ComputedSingleTabsLayout)
          }
          },
          deep: true
      },
    },

  created() {
    console.log(this.AppisDarkMode,this.SingleBuildTabs,this.EntityReady,this.SubCollections,this.SingleRecordNavName,this.SitePages)
    console.log('SingleRecordNavTabs',this.ComputedSingleTabsLayout)
    if(!this.SingleRecordNavMenuItem && this.ComputedSingleTabsLayout[0] && this.ComputedSingleTabsLayout[0].Children[0]){
      this.ActivateSingleRecordNavMenuItem(this.ComputedSingleTabsLayout[0].Children[0],this.ComputedSingleTabsLayout[0])
    }
    this.$store.commit('SetSingleNavTabs',this.ComputedSingleTabsLayout)
  },

  methods: {
    
    UpdateSystemFonts(){
      db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
        AppDefaultBody: this.System.AppDefaultBody,
        AppDefaultHeader: this.System.AppDefaultHeader
      })
    },
    OnRoadmap(item){
      console.log('thisd.FavoriteFeatures',this.FavoriteFeatures,item)
      let oncheck = this.FavoriteFeatures.find(obj => obj === item.id)
      return oncheck
    },
    ToggleFeatureFavorites(item){
      this.$emit('ToggleFeatureFavorites',item)
      // db.collection('favoritefeatures').
    },
      ActivateSingleRecordNavMenuItem(item,ParentNavItem){
          console.log(item)
          this.$emit('ActivateSingleRecordNavMenuItem',item,ParentNavItem)
      }
  }

}
</script>

<style>
.notifications .viewingnotification {

  width: 250px;
  outline: 1px solid #dedee9;
  color: black;
  text-align: center;
  padding: 10px;
  font-size: 15px;



  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}
.notificationcontent {
  font-size: 12px;
  color: black;
  text-align: left;
}
.notificationcontent .tooltiptext {
  visibility: hidden;
  min-width: 250px;
  width: auto;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.notificationcontent:hover .tooltiptext {
  visibility: visible;
}
.notificationheader {
  color: black;
  text-align: left;
}
.viewingnotificationheader{
  align-self: left;
  border: 1px solid #dedee9;
  padding: 10px;
  background-color: rgb(0, 153, 255);
  color: white;
}
/* .notificationtabs{
  padding: 150px;
  margin-top: 1000px;
} */
/* .notifications{
  padding: 150px;
  margin-top: 100px;
} */
.basicoutlined{
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  color: black
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.outline {

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
/* #notificationbar {
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 72%;
    height: 30%;
    margin: auto;
}
#navbaravatar{
  position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 1;
    width: 70%;
    height: 30%;
    margin: auto;
} */

</style>
    