import store from '@/store'
import Vue from 'vue'
import firebase from 'firebase';
import VueRouter from 'vue-router'
import Component404 from '@/components/General/Component404'
import UserInvite from '@/components/General/UserInvite';
import PasswordReset from '@/components/General/PasswordReset'
import ExpiredLink from '@/components/General/ExpiredLink'
import Login from '../views/login'
import PermissionError from '@/views/PermissionError'
import PhoneLogin from '../views/PhoneLogin'
import Checkout from '@/components/SuitePlugins/OnlineStore/Checkout';
import StoreItemSingle from '@/components/SuitePlugins/OnlineStore/StoreItem';
import WarehouseTransaction from '@/components/SuitePlugins/Warehousing/WarehouseTransaction';

import PDFExportFunctionBuilder from '@/components/SuitePlugins/PDFBuilder/PDFExportFunctionBuilder'
import WikiCollectionBuilder from '@/components/Directories/DirectoryBuilder';
import ContentLibraryBuilder from '@/components/ContentLibraryBuilder/BuilderStartup';
import WebForms from '@/components/SuitePlugins/WebForms/WebForms';
import RMAdmin from '@/components/SuiteBuilder/Support/RMAdministration.vue'
import SecurityOverview from '@/components/Database/SecurityOverview.vue'
import LandingPages from '@/components/WebPages/LandingPages'
import NewLandingPage from '@/components/WebPages/NewLandingPage';

import BannerAds from '@/components/WebPages/BannerAds'
import Campaigns from '@/components/WebPages/Campaigns';
import CampaignBuilder from '@/components/WebPages/CampaignBuilder';
import NewBannerAd from '@/components/WebPages/NewBannerAd';
import Roadmap from '../components/SuiteBuilder/Support/Roadmap'
import SystemTicketsLogging from '../components/SuiteBuilder/Support/SystemTicketsLogging'
import SystemTickets from '../views/SystemTickets'
import SystemConfig from '@/components/Database/DataSingleViewComponent';
import MyProfile from '../views/MyProfile'
import ChatbotBuilder from '@/components/SuitePlugins/Chatbot/ChatbotBuilder'
import DataImports from '@/views/DataImports'
import DataImport from '@/views/DataImport/Single'
import MyActivities from '@/views/MyActivities'
import MySchedule from '@/components/SuitePlugins/Activities/MySchedule'
import MyTeamSchedule from '@/components/SuitePlugins/Activities/MyTeamSchedule'
import MyActivitiesbyType from '@/views/Activities/MyActivitiesbyType';
import MyTeamActivities from '@/views/MyTeamActivities'
import MyTeamMemberActivities from '@/views/MyTeamMemberActivities'
import TeamMemberActivities from '@/views/TeamActivities/TeamMemberActivities'
import PipelineManagement from '@/components/SuitePlugins/Pipelines/PipelineManagment'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder'
import SocialMediaPlatforms from '@/components/SuitePlugins/SocialCentre/SocialMediaPlatforms'
import LinkedInAuth from '@/components/SuitePlugins/SocialCentre/LinkedInAuth'
import MyDashboard from '@/components/Dashboards/MyDashboard'
import Goals from '@/components/SuitePlugins/Goals/Goals'
import MyAccount from '@/components/Client/MyAccount'
import FAQAdmin from '@/components/SuitePlugins/FAQ/FAQAdmin'
import PDFExportTemplates from '@/components/SuitePlugins/PDFBuilder/PDFExportTemplates'
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';
import DocumentationLibrary from '@/components/WebPages/LandingPageSingle';
import { Store } from 'vuex';

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name:'404', 
    component: Component404
  },
  {
    path: '/UserInvite/:id',
    name: 'UserInvite',
    component: UserInvite
  },
  {
    path: '/Password-Reset/:id',
    name: 'PasswordReset',
    component: PasswordReset,
    props: true
  },
  {
    path: '/Expired-Link',
    name: 'ExpiredLink',
    component: ExpiredLink,
    props: true
  },
  
  {
    path: '/Documentation/:id/:slug',
    name: 'DocumentationLibrary',
    component: DocumentationLibrary,
    props:true,
    meta: {
      RouteEventID: 1059,
      type: 'Documentation'
    }
  },
  {
    path: '/LandingPage/:id',
    name: 'landingpagesingle',
    component: LandingPageSingle,
    props:true,
    meta: {
      RouteEventID: 1032,
      type: 'Landing Pages'
    }
  },
  {
    path: '/PermissionError',
    name: 'PermissionError',
    component: PermissionError
  },
  {
    path: '/Login',
    name: 'login',
    component: Login
  },
  {
    path: '/PhoneLogin',
    name: 'PhoneLogin',
    component: PhoneLogin
  },
  
  
  {
    path: '/StoreItem/:slug/:id',
    name: 'StoreItemSingle',
    component: StoreItemSingle,
    meta: {
      RouteEventID: 1045,
      id: 'StoreItem',
      icon: 'mdi-cart',
      type: 'Pages',
      subcat: 'StoreItemSingle',
      viewicon: 'mdi-cart',
      IsTopic: true,
      TopicCategory: 'Pages',
      TopicName: 'Store Item'
      }
  },
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  const functions = firebase.functions();
  const RecordSiteAnalytics = functions.httpsCallable('RecordSiteAnalytics');
  //console.log(to.name,from.path,window.location.pathname)
  if(!store.state.user && to.name === '404'){
    store.commit('setReroutePath', window.location.pathname)
  }
  if(to.meta && to.meta.type && to.meta.RouteEventID !== 2001 || to.meta && to.meta.RouteEventID && to.meta.RouteEventID !== 2001){
    setTimeout(() => {
     //console.log('logging view '+to.meta.type)
    let obj = { RouteName: to.meta.type,PathTitle:to.name ,Path: to.path, Ignore: false}
    if(from && from.meta){
      obj.RouteFromMeta = from.meta
    }
    if(store.state.ViewInstanceID){
      obj.PrecedingViewInstanceID = store.state.ViewInstanceID
    }
    if(store.state.user){
      obj.Type = 'internal'
      obj.Userid = store.state.user.id
      obj.User = store.state.user.Full_Name
      obj.SignedInUser = true
    }
    else if(to.meta.RouteEventID !== 1028 && to.meta.RouteEventID !== 1032 && to.meta.RouteEventID !== 6001 && to.meta.RouteEventID !== 5001 && to.meta.RouteEventID !== 1015){
      obj.Type = 'internal'
      obj.PublicUser = true
      obj.PotentiallyUnPermitted = true  
    }
    else{
      obj.Type = 'public'
      obj.PublicUser = true
    }
    if(to.meta.TopicCategory){
      obj.TopicCategory = to.meta.TopicCategory
    }
    if(to.params.id){
      obj.RecordId = to.params.id
    }
    else if(to.meta.id){
      obj.RecordId = to.meta.id
    }
    if(to.meta.RouteEventID){
      obj.RouteEventID = to.meta.RouteEventID
    }
    //console.log(obj)
      RecordSiteAnalytics(obj).then(result => {
        store.commit('setViewInstanceID', result.data.id)
        //console.log(result)
      }) 
    }, 2000);
        
    //firebase.analytics().logEvent('page_view', obj);
  }
  return next();

});
export default router
    