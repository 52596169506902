<template>
  <v-card flat tile class="background">
    <v-card-subtitle class="background darken-1 mediumoverline">
              Header
          </v-card-subtitle>
      <v-list-item>
        <v-text-field v-model="EditedTab.Title" label="Title" />
      </v-list-item>
       <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">									
            <v-switch v-model="EditedTab.DisplayasChip" label="Display as Chip"/>
                <v-menu      									
            :close-on-content-click="false"									
            :nudge-right="40"									
            transition="scale-transition"									
            offset-y									
            min-width="200px"									
            >									
            <template v-slot:activator="{ on }">									
        <v-icon v-if="EditedTab.DisplayasChip" v-on="on">mdi-palette-advanced</v-icon>									
            </template>									
        <v-color-picker v-model="EditedTab.ChipColor"></v-color-picker>									
        </v-menu>										
        </v-list-item>
         <v-list-item v-if="EditedTab.DisplayasChip">
            <v-text-field v-model="EditedTab.ChipRoute" label="Route"/>	
        </v-list-item>
      <v-list-item v-if="!EditedTab.HasTextBox || EditedTab.Name === 'Parallax'">
        <v-switch
          label="Center Header"
          v-model="EditedTab.CenteredHeader"
          @change="Onepropemit('HeaderCenterClass', EditedTab)"
        />
        <v-switch
          label="Fancy Title" v-if="EditedTab.Name !== 'Title'"
          v-model="EditedTab.FancyTitle"
        />
        
      </v-list-item>
      
       <v-list-item>
           <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
          </template>
          <v-color-picker v-model="EditedTab.HeaderColor"></v-color-picker>
        </v-menu>
        <v-select clearable
          v-model="EditedTab.HeaderFontFamily"
          label="Font"
          :items="ComputedFontOptions"
        >
          <template v-slot:item="{ item }">
            <span>
              <p :style="'font-family:' + item" v-if="EditedTab.Title">
                {{ EditedTab.Title }}
              </p>
              <p :style="'font-family:' + item" v-if="!EditedTab.Title">
                {{ item }}
              </p>
              <p class="caption" v-if="EditedTab.Title">{{ item }}</p>
            </span>
          </template>
        </v-select>
        <v-select
          v-model="EditedTab.HeaderFontSize"
          :items="[12, 14, 18, 24, 36, 42, 50, 60, 75, 100]"
        />
        
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'Testimonials'">
           <v-menu
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="200px"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-palette-advanced</v-icon>
           
          </template>
          <v-color-picker v-model="EditedTab.SubHeaderColor"></v-color-picker>
        </v-menu> <v-select clearable
          v-model="EditedTab.SubHeaderFontFamily"
          label="Font"
          :items="ComputedFontOptions"
        >
          <template v-slot:item="{ item }">
            <span>
              <p :style="'font-family:' + item" v-if="EditedTab.Title">
                {{ EditedTab.Title }}
              </p>
              <p :style="'font-family:' + item" v-if="!EditedTab.Title">
                {{ item }}
              </p>
              <p class="caption" v-if="EditedTab.Title">{{ item }}</p>
            </span>
          </template>
        </v-select>
        <v-select
          v-model="EditedTab.SubHeaderFontSize"
          :items="[12, 14, 18, 24, 36, 42, 50, 60, 75, 100]"
        />
      </v-list-item>
      <v-list-item v-if="EditedTab.Name !== 'Image' && EditedTab.Name !== 'Card Row Single'">
        <v-switch
          label="Has Header Image"
          v-model="EditedTab.HasHeaderImage"
          v-if="
            EditedTab.Name !== 'Card Row' &&
            EditedTab.Name !== 'Counter Single' &&
            EditedTab.Name !== 'Store Product Single' &&
            EditedTab.Name !== 'Info Card'
          "
        />
        <v-dialog max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="
                EditedTab.Name !== 'Card Row' &&
                EditedTab.Name !== 'Counter Single' &&
                EditedTab.Name !== 'Store Product Single' &&
                EditedTab.Name !== 'Info Card' &&
                !EditedTab.HasTextBox
              "
              v-on="on"
              >mdi-image-edit-outline</v-icon
            >
          </template>
          <v-tabs v-if="!UseGroupAssets">
            <v-tab v-for="lib in GalleryLibraries" :key="lib.itemObjKey">
              {{ lib.name }}
            </v-tab>
            <v-tab-item v-for="lib in GalleryLibraries" :key="lib.itemObjKey">
              <v-card width="800">
                <PhotoLibraryViewer
                  :FullGallery="true"
                  :TableCollectionRef="lib.TableCollectionRef"
                  :TableName="lib.name"
                  @AssignImage="AssignImage"
                  :IMGProp="'HeaderImage'"
                  :CurrentEntity="lib"
                  :AppisDarkMode="true"
                  :tab="EditedTab"
                />
              </v-card>
            </v-tab-item>
          </v-tabs>
          <v-card-text v-if="UseGroupAssets">
            <v-row v-if="SystemAssets.length > 0">
              <v-col
                v-for="n in SystemAssets"
                :key="n.itemOvjKey"
                class="d-flex child-flex"
                cols="3"
              >
                <v-img
                  :src="n.ThumbURL"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="AssignImage(EditedTab, 'HeaderImage', n)"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-card-text>
        </v-dialog>
        <!-- <v-switch label="Center Header" v-model="EditedTab.CenteredHeader" @change="HeaderCenterClass(EditedTab)" /> -->
        
      </v-list-item>
      <!-- <v-list-item>
        <v-autocomplete clearable v-model="EditedTab.HeaderBGColor" label="BG Color" :items="$store.state.CSSColors"
        >
       <template v-slot:item="data">
        <v-list-item-avatar>
          <v-avatar :style="'background-color: '+data.item+';'">
          </v-avatar>
        </v-list-item-avatar>
              <v-list-item-content>
                  <v-list-item-subtitle v-html="data.item"></v-list-item-subtitle>
              </v-list-item-content>
            </template>
        </v-autocomplete>
      </v-list-item> -->
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Name !== 'Card Row Single'"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Header BG Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.HeaderBGColor"></v-color-picker>
          </v-menu>
          <v-icon right v-if="EditedTab.HeaderBGColor" @click="EditedTab.HeaderBGColor = ''">mdi-close</v-icon>
          <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">
        <v-switch
          label="Ribbon"
          v-model="EditedTab.Ribbon"
        />
         <v-switch
          label="Title Shadow"
          v-model="EditedTab.TitleTextShadow"
        />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Ribbon"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Ribbon Inner Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.RibbonInnerColor"></v-color-picker>
          </v-menu>
          <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.Ribbon"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Ribbon Outer Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.RibbonOuterColor"></v-color-picker>
          </v-menu>
      <v-list-item v-if="EditedTab.Name !== 'Card Row Single'">
         <v-select clearable v-model="EditedTab.HeaderBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">        
        <v-text-field v-model="EditedTab.SubTitleTop" label="Subtitle Top" />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.SubTitleTop"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Subtitle Top Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.SubTitleTopColor"></v-color-picker>
          </v-menu>
          <v-list-item v-if="EditedTab.SubTitleTop">        
        <v-switch v-model="EditedTab.SubTitleTopInline" label="Subtitle Top Inline" />
      </v-list-item>
      <v-list-item v-if="EditedTab.SubTitleTop">        
        <v-select  :items="['initial','center','end']" v-model="EditedTab.SubTitleTopTextAlign" label="Subtitle Top Align" />
      </v-list-item>
      <v-list-item v-if="EditedTab.Name === 'Title' || EditedTab.FancyTitle">        
        <v-text-field v-model="EditedTab.SubTitleBottom" label="Subtitle Btm" />
      </v-list-item>
      <v-list-item v-if="EditedTab.SubTitleBottom">        
        <v-select :items="['initial','center','end']" v-model="EditedTab.SubTitleBottomTextAlign" label="Subtitle Btm Align" />
      </v-list-item>
      <v-menu
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y v-if="EditedTab.SubTitleTop"
        min-width="200px"
        >
        <template v-slot:activator="{ on }">
          <v-list-item v-on="on">
            <v-icon left>mdi-palette-advanced</v-icon>
            Subtitle Bottom Color 
            </v-list-item>
            </template>
          <v-color-picker v-model="EditedTab.SubTitleBottomColor"></v-color-picker>
          </v-menu>
  </v-card>
</template>






<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
      ComputedFontOptions(){
            return this.FontOptions.concat(this.System.CustomFonts.map(font => {return font.Name}))
        },
    },
    created(){

    },
    methods:{
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
