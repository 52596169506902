
<template>
<div id="mainplugincolwindow">
<!-- <v-virtual-scroll v-if="GuideList"
  class="transparent"
    :items="GuideList"
    :item-height="!tab.HideGuidesCarousel ? 390 : 200"
    :height="ListHeight? ListHeight : 400"
  >
  <template v-slot:default="{ item }">
 <v-card @dragstart="guidedragstart(item, $event)" :draggable="$route.name === 'Roadmap'" class="background mx-2" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped"  :style="'padding:20px;margin-top:'+tab.MarginTop+'px;margin-bottom:'+tab.MarginBottom+'px;margin-left:'+tab.MarginLeft+'px;margin-right:'+tab.MarginRight+'px'">
   <v-sheet :class="item.Disabled ? 'grey lighten-4' : 'background'">
                <v-carousel v-if="!tab.HideGuidesCarousel"
                    :cycle="!item.Disabled"
                    height="150"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(item)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(item)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(item)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>

                <v-card-title :class="item.Disabled ? 'pb-0 grey--text' : 'pb-0 pop--text'" style="font-weight: 300 !important;font-size: 16px !important;">
                  {{item.Name}} <v-chip class="mx-2" small dark :color="item.DisabledColor" v-if="item.Disabled">{{item.DisabledName}} <v-icon>{{item.DisabledIcon}}</v-icon></v-chip>
                <v-spacer></v-spacer>
                <v-chip dark small color="purple" 
                          v-bind="attrs"
                            v-on="on"
                          v-if="item.Owned">
                        Owned
                      </v-chip>
                </v-card-title>
                   
                <v-card-text class="text--primary">
                  <h5 :class="item.Disabled ? 'grey--text' : 'text--primary'" >{{item.Module_Category.Name}}</h5>

                  <div  :class="item.Disabled ? 'caption grey--text' : 'caption'" v-html="item.Description"></div>
                </v-card-text>

                <v-card-actions>
                  <v-btn
                    color="pink"
                    text
                    small
                    v-if="item.HasActionButton"
                    :disabled="item.Disabled"
                    @click="ActivateSingleRecordNavMenuItem(item)"
                  >
                    Add Now 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    target="_blank"
                    :href="item.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions>
              </v-sheet>
 </v-card>
  </template>
</v-virtual-scroll> -->
<!-- <div > -->
  <v-row v-if="!ListPreviewOnly" :class="Squished ? 'justify-center' : 'justify-start mx-3'" :style="Squished ? '' : 'height: 70vh;'">
    <div v-if="!Squished" class="flex xl8 lg8 md8 sm6 xs6">
    <v-card style="padding:0px;" width="100%" height="100%" @dragstart="guidedragstart(SelectedPlugin, $event)" :draggable="$route.name === 'Roadmap'"
    class="background mx-2" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" >
     <span id="Transition" ></span>
      <v-sheet v-if="SelectedPlugin" :class="SelectedPlugin.Disabled ? 'grey lighten-4' : 'background'">
     
                <v-carousel v-if="!tab.HideGuidesCarousel"
                    :cycle="!SelectedPlugin.Disabled"
                    :height="MiniView ? 250 : 350"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in SelectedPlugin.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(SelectedPlugin)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(SelectedPlugin)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(SelectedPlugin)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="SelectedPlugin.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!SelectedPlugin.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>

                <v-card-title :class="SelectedPlugin.Disabled ? 'pb-0 grey--text' : 'pb-0 pop--text'" style="font-weight: 300 !important;font-size: 16px !important;">
                  {{SelectedPlugin.Name}} <v-chip class="mx-2" small dark :color="SelectedPlugin.DisabledColor" v-if="SelectedPlugin.Disabled">{{SelectedPlugin.DisabledName}} <v-icon>{{SelectedPlugin.DisabledIcon}}</v-icon></v-chip>
                  <v-spacer></v-spacer>
                   <v-chip dark small color="purple" 
                          v-bind="attrs"
                            v-on="on"
                          v-if="SelectedPlugin.Owned">
                        Owned
                      </v-chip>                  
                </v-card-title>
                   
                <v-card-text class="text--primary">
                  <h5 :class="SelectedPlugin.Disabled ? 'grey--text' : 'text--primary'" >{{SelectedPlugin.Module_Category.Name}}</h5>

                  <div  :class="SelectedPlugin.Disabled ? 'caption grey--text' : 'caption'" v-html="SelectedPlugin.Description"></div>
                </v-card-text>
 <!-- v-if="SelectedPlugin.HasActionButton" -->
                <v-card-actions class="justify-end" style="position: absolute; bottom: 20px;right: 30px;">
                  <v-btn
                    color="pink"
                    text
                    small
                   
                    :disabled="SelectedPlugin.Disabled"
                    @click="EmitMarketDialog(SelectedPlugin)"
                  >
                    Add Now 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    :to="SelectedPlugin.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions>
     
              </v-sheet>
          </v-card>
  <!-- </v-row> -->
  </div>
  <div v-if="!ListPreviewOnly" :class="Squished? 'flex xl12 lg12 md12 sm12 xs12' : 'flex xl4 lg4 md4 sm6 xs6'" :style="Squished ? '' : 'padding:20px;'">
   <v-toolbar >
     <v-text-field clearable v-model="guidesearch" label="Search"
     >
     </v-text-field>
     <v-divider>
     </v-divider>
   </v-toolbar>
   <v-list-item dense>
   </v-list-item>
  <!-- <v-row class="justify-center"> -->
      
         <v-virtual-scroll v-if="FilteredGuideList"
  class="transparent" :style="Squished ? '' : 'margin-top:20px;height: 60vh;'"
    :items="FilteredGuideList"
    :height="$vuetify.breakpoint.xs ? 300 : 380"
    :item-height="$vuetify.breakpoint.xs ? 270 : 350"
  >
  <template v-slot:default="{ item }">
     <v-card @click="PluginPicker ? PickPlugin(item) : PreviewPlugin(item)" width="100%" height="100%" @dragstart="guidedragstart(item, $event)" :draggable="$route.name === 'Roadmap'"
     :style="Squished ? '' : ''"
     :class="Squished ? '' : 'background'" :elevation="Squished? 4 : tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" >
          <v-sheet :class="item.Disabled ? 'grey lighten-4' : 'background'">
            
            <v-carousel v-if="!tab.HideGuidesCarousel"
                    :cycle="!item.Disabled"
                    :height="MiniView ? 200 : 200"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(item)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(item)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(item)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>
            <v-card-title :class="item.Disabled ? 'pb-0 grey--text' : 'pb-0 pop--text'" style="font-weight: 300 !important;font-size: 16px !important;">
                  {{item.Name}} <v-chip class="mx-2" small dark :color="item.DisabledColor" v-if="item.Disabled">{{item.DisabledName}} <v-icon>{{item.DisabledIcon}}</v-icon></v-chip>
                  <v-spacer></v-spacer>
                 <v-chip dark small color="purple" 
                          v-bind="attrs"
                            v-on="on"
                          v-if="item.Owned">
                        Owned
                      </v-chip>
                </v-card-title>
                   
                <v-card-text class="text--primary">
                  <h5 :class="item.Disabled ? 'grey--text' : 'text--primary'" >{{item.Module_Category.Name}}</h5>

                  <div  :class="item.Disabled ? 'caption grey--text' : 'caption'" v-html="smart_substr(item.Description,80)"></div>
                </v-card-text>
          </v-sheet>
        </v-card>
  </template>
</v-virtual-scroll>
       
      </div>
  </v-row>
  <v-row v-if="ListPreviewOnly" class="justify-center">
    <v-chip-group column>
      <v-chip @click="ActivateTypefilter(type)" v-for="type in TypeChips" :key="type.itemObjKey">
        {{type.Name}}
      </v-chip>
    </v-chip-group>
  </v-row>
   <v-row v-if="ListPreviewOnly" :class="ListPreviewOnly ? 'justify-center' : 'justify-start mx-3'" :style="ListPreviewOnly ? '' : 'height: 70vh;'">
     <span id="Transition" ></span>
      <div v-for="item in FilteredGuideList" :key="item.itemObjKey"
        :class="ListClass" :style="ListPreviewOnly ? 'padding:20px;' : 'padding:20px;'">
  
    <!-- :style="SelectedPlugin ? 'display: block;' : 'display: none;' @click="PreviewPlugin(item)"" -->
     <v-card width="100%" height="100%" @dragstart="guidedragstart(item, $event)" :draggable="$route.name === 'Roadmap'"
   
     class="background" :elevation="tab.Elevation" :tile="tab.Tile" :outlined="tab.Outlined" :shaped="tab.Shaped" >
          
          <v-sheet v-if="ListPreviewOnly" :class="item.Disabled ? 'grey lighten-4' : 'background'">
            <v-carousel v-if="!tab.HideGuidesCarousel"
                    :cycle="!item.Disabled"
                    :height="MiniView ? 120 : 150"
                    hide-delimiter-background
                    show-arrows-on-hover
                  >
                    <v-carousel-item
                    v-for="(slideitem,i) in item.CarouselItems"
                    :key="i"
                    :src="slideitem.ThumbURL"
                  >
                  <v-btn @click="ToggleRoadmapItemScope(item)" v-if="$route.name === 'Roadmap'" dark style="position: absolute;" x-large icon>
                    <v-icon v-if="!OnRoadmapItem(item)">mdi-heart-outline</v-icon>
                    <v-icon v-if="OnRoadmapItem(item)">mdi-heart</v-icon>
                  </v-btn>
                  <v-sheet v-if="item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(255,255,255,0.8) 17%, rgba(255,255,255,0.8) 37%, rgba(255,255,255,0.8) 67%, rgba(255,255,255,0.8) 81%);">
                    <v-card-title class="mediumoverline">Installed</v-card-title>
                  </v-sheet>
                  <v-sheet v-if="!item.Disabled" height="100%" style="background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);">
                    
                  </v-sheet>
                  </v-carousel-item>
                  </v-carousel>
            <v-card-title :class="item.Disabled ? 'pb-0 grey--text' : 'pb-0 pop--text'" style="font-weight: 300 !important;font-size: 16px !important;">
                  {{item.Name}} <v-chip class="mx-2" small dark :color="item.DisabledColor" v-if="item.Disabled">{{item.DisabledName}} <v-icon>{{item.DisabledIcon}}</v-icon></v-chip>
                <v-spacer></v-spacer>
                <v-chip dark small color="purple" 
                          v-bind="attrs"
                            v-on="on"
                          v-if="item.Owned">
                        Owned
                      </v-chip>
                </v-card-title>
                   
                <v-card-text class="text--primary">
                  <h5 :class="item.Disabled ? 'grey--text' : 'text--primary'" >{{item.Module_Category.Name}}</h5>

                  <div  :class="item.Disabled ? 'caption grey--text' : 'caption'" v-html="smart_substr(item.Description,80)"></div>
                </v-card-text>
                <v-card-actions class="justify-end" >
                   <!-- :disabled="item.Disabled || item.id !== SelectedPlugin.id" -->
                  <v-btn
                    color="pink"
                    text
                    small
                   
                    :disabled="item.Disabled"
                    @click="EmitMarketDialog(item)"
                  >
                    Add Now 
                  </v-btn>
                  <v-btn v-if="item.Module_Category && item.Module_Category.Name === 'Module'"
                    color="purple"
                    text
                    small
                    @click="ActivateModulePlugins(item)"
                  >
                    See Plugins 
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="teal"
                    text
                    small
                    :to="item.RoutePath"
                  >
                    Explore
                  </v-btn>
                </v-card-actions>
          </v-sheet>
        </v-card>
       
      </div>
  </v-row>
</div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'



export default {
    props: ['AppisDarkMode','tab','PageHeadersFont','PageContentFont','PageHeadersFontSize','Squished','PluginPicker',
    'i','IsElement','tabindex','rowindex','GuideList','ListHeight','FavoriteFeatures','ListPreviewOnly'],
    components: {

        },
    data() {
    return {     
      Activetypefilter: '',
      guidesearch: '',
      Transitioning: false,
      SelectedPlugin: '',
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      TypeChips(){
        let array = []
        this.GuideList.filter(item => {
          return item.Type
        }).map(item => {
          return item.Type
        })
        .map(type => {
          let oncheck = array.find(obj => obj.ID === type.ID)
          if(!oncheck){
            array.push(type)
          }
        })
        return array
      },
      FilteredGuideList(){
        return this.GuideList.filter(guide => {
          if(this.guidesearch){
            return guide.Name.toLowerCase().includes(this.guidesearch.toLowerCase()) || guide.Type && guide.Type.Name.toLowerCase().includes(this.guidesearch.toLowerCase())
            || guide.Module_Category && guide.Module_Category.Name.toLowerCase().includes(this.guidesearch.toLowerCase()) || guide.Description && guide.Description.toLowerCase().includes(this.guidesearch.toLowerCase())
          }
          else{
            return guide
          }
        }).filter(item => {
          if(this.Activetypefilter){
            return item.Type && item.Type.ID === this.Activetypefilter.ID
          }
          else{
            return item
          }
        })
      },
      ListClass(){
        if(this.Squished){
          return 'flex xl12'
        }
        else{
          return this.ListPreviewOnly? 'flex xl4 lg4 md6 sm12 xs12' : 'flex xl4 lg4 md4 sm6 xs6'
        }
      },
      MiniView(){
        if(this.Squished){
          return true
        }
        else{
          return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        }
            
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
    AppQuickStartDialog () {
            return this.$store.state.AppQuickStartDialog
        },
      

    },
    created(){
      
      if(!this.PluginPicker){
        window.onload = setTimeout(() => {
        if(this.GuideList[0]){
          this.PreviewPlugin(this.GuideList[0])
        }

        }, 1000)
      }
       

    },
    
    methods: {
      PickPlugin(item){
        //console.log(item)
        this.$emit('PickPlugin',item)
      },
      ActivateModulePlugins(item){
        this.$emit('ActivateModulePlugins',item)
      },
      ActivateTypefilter(type){
        if(this.Activetypefilter && this.Activetypefilter.ID === type.ID){
           this.Activetypefilter = ''
        }
        else{
           this.Activetypefilter = type
        }
       
      },
      smart_substr(str, len) {
            var total = str
            // var temp = str.substr(0, len);
            // if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
            //     temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
            // }
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
      ToggleAppQuickStartDialog(){        
        this.$store.commit('setAppQuickStartDialog',!this.AppQuickStartDialog)
      },
      EmitMarketDialog(SelectedPlugin,prop){
        console.log(SelectedPlugin)
        let img = ''
        if(SelectedPlugin.CarouselItems[0]){
          img = SelectedPlugin.CarouselItems[0]
        }
        let plugin = {
          Plugin: {Name: SelectedPlugin.Name,id: SelectedPlugin.id},
          Pluginid: SelectedPlugin.id,
          Price: 0,
          Description: SelectedPlugin.Description,
          IMG: img
        }
        if(typeof SelectedPlugin.Price !== 'undefined'){
          plugin.Price = SelectedPlugin.Price
        }
        let raplugins = this.$store.state.RAPluginPackages
        let pluginpackages = raplugins.filter(pack => {
            let packplugs = pack.Plugins.map(plug => {
                return plug.id
            })
            return packplugs.includes(SelectedPlugin.id)
        })
        // WE WILL concat from now on if(pluginpackages.length === 0){
          let option = Object.assign({},SelectedPlugin)
          option.IMG = img
          let pluginpack = {
            ...SelectedPlugin,
            Plugins: [option],
            IMG: img
          }
          pluginpackages = pluginpackages.concat([pluginpack])
          console.log(pluginpackages)
        // }
        this.$store.commit('EmitMarketDialogObject',SelectedPlugin)
        this.$store.commit('EmitMarketDialogOptions',pluginpackages)
        this.$store.commit('EmitMarketDialogType','Plugin Purchase')
        this.$store.commit('EmitMarketDialogDescription',SelectedPlugin.Description)
        this.$store.commit('EmitMarketDialogTitle',SelectedPlugin.Name)        
        this.$store.commit('EmitMarketDialogAvatar',img.url)
        this.$store.commit('EmitMarketDialogInteractMethod','PurchasePlugin')
        this.$store.commit('EmitMarketDialog',true)
      },
      PreviewPlugin(item){   
        if(!this.Transitioning && this.SelectedPlugin.id !== item.id && !this.$vuetify.breakpoint.xs){
        let elmnt = document.getElementById('Transition')
        elmnt.setAttribute('class','block')    
        this.Transitioning = true
        setTimeout(() => {
           this.SelectedPlugin = item
        }, 1000);
        setTimeout(() => {
          elmnt.removeAttribute('class','block')  
          this.Transitioning = false
        }, 2000); 
        }
        
      },
      
      OnRoadmapItem(item){
      console.log('thisd.FavoriteFeatures',this.FavoriteFeatures,item)
      let oncheck = this.FavoriteFeatures.find(obj => obj === item.id)
      return oncheck
    },
    ToggleRoadmapItemScope(item){
      this.$emit('ToggleRoadmapItemScope',item)
      // db.collection('favoritefeatures').
    },
      guidedragstart(guide){
        this.$emit('guidedragstart',guide)
      },
        GetTabClass(tab){
            if(tab.BoxTransparency && tab.CustomClass){
              return tab.BoxTransparency+' '+tab.CustomClass+' mx-3'  
            }
            
        },
     OpenDialog(tab,tabindex){
        
         if(this.rowindex > -1){
             
             this.$emit('OpenDialog',tab,tabindex,true,this.tabindex,true,this.rowindex)
         }
         else{
             this.$emit('OpenDialog',tab,tabindex,this.IsElement,this.tabindex)
         }
         
     },
     CheckifClipped(tab){
         if(tab.Clipped){
                if(tab.ClipAnimated){              
                    if(tab.ClipAnimation === 'Spotlight Search'){
                        return `animation: spotlightsearch 3s `+tab.CircleAnimationRepeat+`;`
                    }
                }
                else if(tab.ClippedbyPath){
                    if(tab.ClippedPathOption){
                        if(tab.ClippedPathOption === 'elipse'){
                            return `clip-path: ellipse(`+tab.ElipseX+` `+tab.EliseY+` at `+tab.ElipseXPosition+` `+tab.ElipseYPosition+`);`
                        }
                        if(tab.ClippedPathOption === 'circle'){
                             return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        }
                        if(tab.ClippedPathOption === 'polygon'){
                            return `clip-path: polygon(`+tab.TopLeftCrnrX+` `+tab.TopLeftCrnrY+`, `+tab.TopRightCrnrX+` `+tab.TopRightCrnrY+`, `+tab.BottomRightCrnrX+` `+tab.BottomRightCrnrY+`, `+tab.BottomLeftCrnrX+` `+tab.BottomLeftCrnrY+`);`
                        }
                        // if(tab.ClippedPathOption === 'beizer'){
                        //     return `clip-path: circle(`+tab.CircleRadius+` at `+tab.CircleXPosition+` `+tab.CircleYPosition+`);` 
                        //     d="M 0,1
                        //          L 0,0
                        //          L 1,0
                        //          L 1,1
                        //          C .65 .8, .35 .8, 0 1
                        //          Z"
                        // }
                    }
                    
                }
                else if(tab.ClippingIMG){
                 return `-webkit-mask-image: url(`+tab.ClippingIMG+`);
                                        -webkit-mask-repeat: no-repeat;
                                        -webkit-mask-size: contain;
                                        -webkit-mask-position: center;`   
                }
                
                
            }           
            
        },
        HeaderFontFamily(tab,tabHeaderFontFamily){
           if(this.PageHeadersFont){
               if(!tab.HeaderFontSize){
                 return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:50px;'  
               }
               else{
                   if(tabHeaderFontFamily){
                       return 'font-family: '+tabHeaderFontFamily+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'
                   }
                   else{
                     return 'font-family: '+this.PageHeadersFont+', sans-serif;font-size:'+tab.HeaderFontSize+'px;'  
                   }
                   
               }
               
           }
           console.log(tab,tabHeaderFontFamily)
       },

    }    
}
</script>

<style>

.smoothtransition {
  transition: all 2s ease;
}
.block {
        z-index: 10;
    
        width: 100%;
        height: 100%;
        position: absolute;
        /* height: inherit; */
        background: #a979a1fa;
        animation: mainBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
        display: block;
      }


@keyframes mainBlock {
  0% {
    width: 0%;
    left: 0;

  }
  50% {
    width: 100%;
    left: 0;

  }
  100% {
    width: 0;
    left: 100%;
  }
}
</style>