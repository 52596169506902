
<template>
<div>
  <v-card width="100%" height="100%" class="transparent" tile flat  v-if="IsUserView" :style="$route.name === 'ClassifiedEnquiry' || $route.name === 'GroupMemberEnquiry'? 'margin-top:180px;' : ''">
      <v-tabs v-model="ActiveTab" centered>
        <v-tab>
          List
        </v-tab>
        <v-tab-item>
          <v-data-table
          :headers="ClassifiedsHeaders"
          :items="UserClassifieds"
          >
          <template v-slot:[`item.action`]="{ item }">
            <v-btn small :to="item.Link">View</v-btn>
           </template>

          </v-data-table>
        </v-tab-item>
        <v-tab>
          Enquiries
        </v-tab>
        <v-tab-item>
          <v-list dense :id="enquiry.id" v-for="enquiry in ClassifiedsEnquiries" :key="enquiry.itemObjKey">
            <v-list-item>
              <v-list-item-content>
              <v-list-item-title v-if="EnquiriesCollection === 'classifiedenquiries'"><v-chip class="primary" small>{{enquiry.Classified.Title}}</v-chip></v-list-item-title> 
              <v-list-item-title v-if="EnquiriesCollection === 'groupmemberenquiries'">
                <v-chip class="primary" small>{{enquiry.Group_Type.Name}}</v-chip><v-chip  class="accent white--text" :to="'/Group/'+enquiry.Group.id" small>{{enquiry.Group.name}}</v-chip>
                </v-list-item-title>                   
              <v-list-item-subtitle  v-if="enquiry.EnquiryingUser">
                <router-link class="pop--text" :to="'/SiteMember/'+enquiry.EnquiryingUser.id">View Profile</router-link>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a :href="'mailto:'+enquiry.Email">{{enquiry.Email}}</a>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <a :href="'callto:'+enquiry.Mobile_Number">{{enquiry.Mobile_Number}}</a>
              </v-list-item-subtitle>
              <v-list-item-subtitle  class="pop--text" v-if="enquiry.EnquiryingUser">
                Online: Online Now
              </v-list-item-subtitle>
            </v-list-item-content>

            
              <v-list-item-icon>
                <v-dialog max-width="400" class="white">
                  <template v-slot:activator="{ on }">
                    <v-icon color="pop" :id="enquiry.id+'comments'" v-on="on">mdi-comment-outline</v-icon>
                  </template>
                  <v-card-title class="primary white--text">
                    {{enquiry.Enquiry}}
                    <v-spacer>
                    </v-spacer>
                    <v-avatar color="pop" v-if="enquiry.EnquiryingUser">
                    {{enquiry.EnquiryingUser.Full_Name.split(' ')[0].substr(0,1)}}{{enquiry.EnquiryingUser.Full_Name.split(' ')[1].substr(0,1)}}
                    </v-avatar>
                  </v-card-title>
              
                  <v-timeline dense clipped class="white" style="padding:20px;">
                    <v-timeline-item
                        fill-dot
                        class="white--text mb-12"
                        color="orange"
                        large
                    >
                        <template v-slot:icon>
                        <span>{{userLoggedIn.Full_Name.split(' ')[0].substr(0,1)}}{{userLoggedIn.Full_Name.split(' ')[1].substr(0,1)}}</span>
                        </template>
                            
                        <v-text-field
                        v-model="input"
                        hide-details
                        flat
                        label="Leave a comment..."
                        solo
                        @keydown.enter="comment(enquiry,null,LiasonUploadFile)"
                        >
                        <template v-slot:append>
                        <v-menu
                            
                            top
                            offset-y
                            :close-on-content-click="false"
                            >
                            <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-link</v-icon>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-text-field v-model="NewLink" label="link" :rules="[rules.url]"/>
                                </v-list-item>
                                <v-list-item-action>
                                    <v-btn @click="link(NewLink,TimelineRecord)">Save</v-btn>
                                </v-list-item-action>
                            </v-list>
                            </v-menu>

                            <input v-if="!LiasonUploadFile"
                                style="display:none"
                                type="file"
                                @change="onFileselected($event)"
                                id="fileInputer">
                            <v-icon
                            class="mx-0"
                            depressed
                            @click="FileInput('fileInputer')"
                            >
                            mdi-paperclip
                            </v-icon>
                                
                        <v-btn
                            class="mx-0"
                            small
                            @click="comment(enquiry,null,LiasonUploadFile)"
                            >
                            Post
                            </v-btn>
                        </template>
                        </v-text-field>
                        <!-- ActiveSession {{ActiveSession}} -->
                        
                    </v-timeline-item>
                    <v-timeline-item
                        class="mb-4"
                        color="grey"
                        icon-color="grey lighten-2"
                        small>
                        <v-row justify="space-between">
                        <v-col cols="7">
                            <v-chip
                            class="white--text ml-0"
                            :color="'purple'"
                            label
                            small
                             v-if="enquiry.EnquiryingUser"
                            >
                            {{enquiry.EnquiryingUser.Full_Name.split(' ')[0].substr(0,1)}}{{enquiry.EnquiryingUser.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-chip>
                            <span v-html="enquiry.Enquiry"></span>
                        </v-col>
                        <v-col
                            class="text-right caption"
                            cols="5"
                        >
                            {{enquiry.Created_On.toDate()}}
                        </v-col>
                        </v-row>
                    </v-timeline-item>
                    <v-timeline-item
                        class="mb-4"
                        color="grey"
                        icon-color="grey lighten-2"
                        small
                        :hide-dot="comment.hidedot"
                        v-for="comment in ByLatestLiason(enquiry.ClientLiason)" :key="comment.itemObjKey">
                    
                        <v-row justify="space-between">
                        <v-col cols="7">
                            <v-chip
                            class="white--text ml-0"
                            :color="comment.Created_Byid === userLoggedIn.id ? 'orange' : 'purple'"
                            label
                            small
                            >
                            {{comment.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{comment.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-chip>
                            <span v-html="comment.message"></span>
                        </v-col>
                        <v-col
                            class="text-right caption"
                            cols="5"
                        >
                            {{comment.Created_On.toDate()}}
                        </v-col>
                        <v-row justify="justify-center" v-if="comment.ThumbURL">        
                            <v-avatar tile size="150">
                            <v-img contain
                                :src="comment.ThumbURL"
                                    />
                            </v-avatar>
                            </v-row>
                        </v-row>
                            <v-row justify="space-between my-6" v-if="comment.fileurl">
                            <!-- ActiveRecord.name+' - '+Uploadfilename -->
                            <v-btn small v-if="!comment.TokenURL"
                            download
                            @click="GetShortLivedURL(comment)"
                            class="blue--text mx-0"
                            >
                            Get View Token <v-icon small>mdi-security</v-icon>
                            </v-btn>
                            <v-btn small v-if="comment.TokenURL"
                            :href="comment.TokenURL" target="_blank"
                            class="orange--text mx-0"
                            >
                            View File <v-icon>mdi-download</v-icon>
                            </v-btn>
                                <v-btn small v-if="!comment.Filed"
                            @click="OpenSaveFiletoRecord(comment)"
                            class="success--text mx-0"
                            >
                            Save to Record <v-icon color="success">mdi-content-save-move-outline</v-icon>
                            </v-btn>
                                    
                            </v-row>
                        <v-row justify="space-between my-6" v-if="comment.FormObj">
                            <v-btn
                            @click="OpenForm(comment.FormObj,comment)"
                            class="blue--text mx-0"
                            >
                            Open Form
                            </v-btn>
                            </v-row>

                    </v-timeline-item>
                        <v-timeline-item>
                        <v-btn @click="slice = slice+4">More</v-btn>
                        <v-btn v-if="slice>4" @click="slice = slice-4">Less</v-btn>
                    </v-timeline-item>
                </v-timeline>
                </v-dialog>
                
              </v-list-item-icon>
            </v-list-item>
          </v-list>

        </v-tab-item>
      </v-tabs>
    </v-card>
<v-card  class="bottomspace" width="100%" height="100%" :dark="AppisDarkMode" flat v-if="!IsUserView">
<v-dialog v-model="MustReadNoticeDialog" max-width="500">
       <v-card
    class="mx-auto"
    max-width="500"
  >
  
    <v-card-title class="title font-weight-regular justify-space-between">
      <span> Must Read Notices </span>
      <v-avatar
        color="primary lighten-2"
        class="subheading white--text"
        size="35"
        v-text="noticestep-1+2+' of '+mustreadnotices.length"
      ></v-avatar>

    </v-card-title>

    <v-window v-model="noticestep">
      <v-window-item :value="notice.itemObjKey" v-for="notice in mustreadnotices" :key="notice.itemObjKey">
        <v-list-item class="justify-center"
          >
          <v-img v-if="!notice.ImageShy"
          height="300"
          contain class="grey lighten-2"
           v-bind:src="notice.coverimageThumbURL"
          ></v-img>
          </v-list-item>
        <v-list-item :to="'/NoticeSingle/'+notice.id" class="mx-10 noticeoutlined"
          >
          
          <v-list-item-avatar size="40" class="noticeboarddate primary white--text justify-center" rounded>
            
                {{notice.monthcreated}}
                    <br>
                  {{notice.daycreated}}
          </v-list-item-avatar>
          <v-list-item-content>     
                                         
                <v-list-item-subtitle style="font-size:10px">
                  <v-icon small :color="notice.IconColor">{{notice.Icon}}</v-icon>
                                    
                  <span class="blue--text"> {{notice.createddate}}</span><span class="purple--text"> {{notice.Category.Name}}</span>
                </v-list-item-subtitle>
                <v-list-item-content class="caption grey--text">
              {{notice.caption}}
            </v-list-item-content>
                <v-list-item-subtitle class="red--text" v-if="notice.MustConfirm && !notice.UserConfirmed">
                Unconfirmed <v-icon color="red" x-small>mdi-cancel</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="links--text" v-if="!notice.UserRead && notice.MustRead">
                MUST READ
                </v-list-item-subtitle>
                <v-list-item-subtitle class="caption grey--text" style="font-size:8px" v-if="notice.UserRead">
                Read {{notice.UserReadDate}}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="grey--text"  v-if="notice.MustConfirm && notice.UserConfirmed">
                Confirmed <v-icon color="grey" x-small>mdi-check</v-icon>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-avatar class="noticeboarddate links justify-center" rounded>
             <v-icon class="white--text">
                 {{notice.Category.Icon}}
             </v-icon>
          </v-list-item-avatar>
          </v-list-item>
      </v-window-item>

    </v-window>
    <v-divider></v-divider>

    <v-card-actions>
      <v-btn
        :disabled="noticestep === 1"
        text
        @click="noticestep--"
      >
        Back
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :disabled="noticestep === mustreadnotices.length"
        color="primary"
        depressed
        @click="noticestep++"
      >
        Next
      </v-btn>
     
    </v-card-actions>
  </v-card>
     </v-dialog>

    <v-carousel v-model="model" height="400" v-if="RelevantTopBannerAds.length>0" cycle>
                      <v-carousel-item
                      v-for="bannerad in RelevantTopBannerAds" :key="bannerad.itemObjKey"
                      >
                      <v-img
                            :src="bannerad.BannerImage"
                            height="100%"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="80%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="largeoverline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                               <v-card-text style="font-size: 10px" class="white--text overline mx-3">
                                {{bannerad.BannerDescription}}
                               </v-card-text>
                             
                            <v-card-actions class="mx-3">
                              <v-btn  color="links black--text" x-large :to="bannerad.BannerLink">{{bannerad.ActionButtonName}} <v-icon>mdi-chevron-right-circle</v-icon></v-btn>
                            </v-card-actions>
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                        </v-img>
                      </v-carousel-item>
                    </v-carousel>
  <v-row class="justify-center">
      <!-- <v-flex lg2 md3 sm4 xs12 >
               <v-card  :class="AppisDarkModeCard"  :dark="AppisDarkMode" height="100%" style="padding-top:25px;">
              <v-list class="transparent">
                <v-list-group
                    v-for="item in ClassifiedsClassesCounted"
                    :key="item.itemObjKey"
                    v-model="item.active"
                    :prepend-icon="item.action"
                    no-action
                >
                    <template v-slot:activator>
                    <v-list-item-content class="title accent--text">
                      {{item.Name}}
                    </v-list-item-content>
                    <v-list-item-content>
                      ({{item.Count}})
                    </v-list-item-content>
                    </template>


                    <v-list-item @click="FilterClassifieds(item,child)"
                    v-for="child in item.Options"
                    :key="child.itemObjKey"
                    >
                    <v-list-item-content>
                       {{child.Name}}
                    </v-list-item-content>
                    <v-list-item-content>
                        ({{child.Count}})
                    </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                </v-list>
              </v-card>
            </v-flex> -->
              
            <v-flex lg12 md12 sm12 xs12>
           <v-card color="" class="transparent" tile  width="100%" flat >
           <v-layout row justify-center>
            <div class="flex xl12 lg12 md12 sm12 xs12">
            
             
            
<v-card color="#ffffff" class="transparent mx-3" tile  width="100%" flat :style="IntranetView? '' : 'margin-top:-80px;'">
    <!-- <v-container class="grey lighten-5"> -->
   <v-parallax
        
        height="300"
        src="https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FQSHCimvRtK2k5IgF1edf%2FIntranet_Page?alt=media&token=a4c7eaae-4f78-493c-9b19-5d1594ac631a"
        style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;;"
      >   
      <v-card class="transparent" flat tile style="background: linear-gradient(180deg, rgba(1,0,15,0.835171568627451) 0%, rgba(9,46,54,0.47942927170868344) 100%);" height="100%" width="100%">
      <v-container class="fill-height" >
   
          
        <v-row
            
            >
           
            <v-col
                 :xl="12" :lg="12" :md="12" :sm="12" :xs="12"
                cols="12"                            
            >

            <v-card class="transparent"  color="#00ffff" flat height="100%" style="margin-top:0px;margin-bottom:0px;margin-left:0px;margin-right:0px;">
    <v-card-title class="justify-center" style="padding-bottom: 20px;color:#FCF5F5;align-self:center;font-family: Montserrat, sans-serif;font-size:60px;">
                      
        Classifieds
    </v-card-title>
    
</v-card>


                
            
                
                
               
                
            </v-col>
            </v-row>

    
      </v-container>
      </v-card>
   </v-parallax>
   
</v-card>


             </div>
             </v-layout>
           </v-card>
            <v-card color="" class="transparent" tile  width="100%" flat >
               <v-card-title row class="justify-end">
                 <v-btn fab absolute outlined @click="AddClassified()">Add</v-btn>
               </v-card-title>
            </v-card>
            <v-card color="" class="transparent" tile  width="100%" flat >
            <v-card-text>
                <v-row>
                    <v-col class="px-4"  v-for="slider in Sliders" :key="slider.itemObjKey">
                    <v-range-slider
                    :label="slider.Name"
                        v-model="slider.range"
                        :max="slider.max"
                        :min="slider.min"
                        hide-details
                        class="align-center"
                    >
                        <template v-slot:prepend>
                        <v-text-field
                            :value="slider.range[0]"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(slider.range, 0, $event)"
                        ></v-text-field>
                        </template>
                        <template v-slot:append>
                        <v-text-field
                            :value="slider.range[1]"
                            class="mt-0 pt-0"
                            hide-details
                            single-line
                            type="number"
                            style="width: 60px"
                            @change="$set(slider.range, 1, $event)"
                        ></v-text-field>
                        </template>
                    </v-range-slider>
                    </v-col>
                    
                </v-row>
                </v-card-text>

               <v-layout row class="justify-center">
               </v-layout>
            </v-card>
          <v-card class="mx-3 background lighten-1" style="padding:20px;" height="100%">
               <v-pagination
                :total-visible="9"
                    v-model="Classifiedspage"
                    circle
                    :length="Math.ceil(FinalClassifiedsRender.length/9)"
                ></v-pagination>
           <v-layout row justify-center class="mx-1">
          
                  <div class="flex xl4 lg4 md6 sm12 xs12" v-for="item in FinalClassifiedsRender.slice(0, 9)"
                    :key="item.itemObjKey" style="padding:10px;">
                        <v-card
                        elevation="4"
                        class="mx-auto"
                        :dark="AppisDarkMode"
                        max-width="600"
                        height="450"
                        style="padding:5px;"
                        >
                        <!-- style="padding:0px;border-left: solid 10px;border-color: #ebebeb" -->
                        <v-btn dark style="z-index:1;" class="my-2" fab absolute right :to="item.Path">View</v-btn>
                    <v-img v-if="item.AttachmentsArray && item.AttachmentsArray.length>0 && item.AttachmentsArray[0].ThumbURL"
                            :src="item.AttachmentsArray[0].ThumbURL"
                            contain
                            height="200px"
                            >
                            <v-btn v-if="item.Vehicle_Brands" depressed outlined @click="ActivateViewPortFilter('Vehicle_Brands',item.Vehicle_Brands)" style="position:absolute;top:20px;min-width: 30% !important;min-height: 30% !important;" tile class="links" dark>{{item.Vehicle_Brands.Name}}</v-btn>
                             
                            </v-img>
                             <v-img v-if="item.AttachmentsArray && item.AttachmentsArray.length>0 && !item.AttachmentsArray[0].ThumbURL || item.AttachmentsArray && item.AttachmentsArray.length==0"
                                src="@/assets/ImageHolder.png"
                            contain
                            height="200px"
                            >
                            <!-- filter.ActiveFilter,Name: 'Filters' -->
                            <v-btn v-if="item.Vehicle_Brands" depressed outlined @click="ActivateViewPortFilter('Vehicle_Brands',item.Vehicle_Brands)" style="position:absolute;top:20px;min-width: 30% !important;min-height: 30% !important;" tile class="links" dark>{{item.Vehicle_Brands.Name}}</v-btn>
                            </v-img>
                            <v-list :dark="AppisDarkMode" dense class="transparent">
                            
                             <v-list-item class="overline accent--text">
                               {{item.Title}}
                            </v-list-item>
                            <v-list-item  class="mediumoverline links--text">
                              {{CurrencyFormatter(item.Price,'ZAR','R')}}
                            </v-list-item>
                          </v-list>
               
                    <v-card v-if="!TaggedFields" flat class="transparent background mx-3 my-2" height="100">
                    <v-card-subtitle v-html="item.Description.substr(0,250)" class="caption mx-1 my-1" >
                      
                    </v-card-subtitle>
                    </v-card>
                     <!-- <v-card-actions class="justify-end" v-if="item.Location">
                        <v-btn icon @click="ViewMapItem(item.Location.AddressLine1,item.Location.AddressLine2,item.Location.AddressLineCity,item.Location.AddressLinePostalCode,item.Location.AddressLineProvince,item.Location.AddressLineCountry)"><v-icon>mdi-google-maps</v-icon></v-btn>{{item.Location.Name}}
                        <v-btn icon :href="'tel:'+item.Location.Telephone"><v-icon>mdi-phone</v-icon></v-btn>{{item.Location.Telephone}}
                    </v-card-actions> -->
                     <!--  <v-card-actions class="justify-end">
                      <v-btn dark small :disabled="item.OnCompare" @click="AddtoCompare(item)">Add to Compare</v-btn>
                      <v-btn dark small :to="item.Path">View</v-btn>
                    </v-card-actions> -->
             </v-card>
             </div>
<!--                
            <div class="flex xl3 lg4 md6 sm12 xs12" v-for="item in FinalClassifiedsRender" style="padding:10px;"
                    :key="item.itemObjKey">
            
             <v-card  class="my-3 mx-0" :to="item.Path" >
                    <v-img v-if="item.IMG"
                    :src="item.IMG"
                    contain
                    height="200px"
                    ></v-img>
                    <v-img v-if="!item.IMG"
                        src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                        height="200px"
                        ></v-img>
                    <v-card-title>{{item.Title}}</v-card-title>
                    <v-card-text v-if="item.Description" v-html="item.Description.substr(0,200)">
                    </v-card-text>
             </v-card>
             </div> -->
             </v-layout>
           </v-card>
          
           
          
           
          
          </v-flex>
  </v-row>
    </v-card>
    </div>
</template>

  <script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import NoticeboardFeed from '@/components/SuitePlugins/DigitalNoticeBoard/NoticeboardFeed';
import GroupsFeed from '@/components/SuitePlugins/Groups/GroupsFeed';
import SiteBlogsFeed from '@/components/SuitePlugins/Blogs/SiteBlogsFeed';

import SiteEventsFeed from '@/components/SuitePlugins/Events/SiteEvents';
import SiteArticlesFeed from '@/components/SuitePlugins/Articles/SiteArticleFeed';
import SystemActivitiesFeed from '@/components/SocialNetwork/SystemActivitiesFeed';
import SocialSitePosts from '@/components/SocialNetwork/SocialSitePosts';


export default {
    props: ['AppisDarkMode','IntranetView','IsUserView','EnquiriesCollection','ClassifiedsClasses','mainclass','subclass','ActiveFilters','AdditionalFilters','NavList'],
    components: {
        NoticeboardFeed,
        GroupsFeed,
        SiteBlogsFeed,
        SiteArticlesFeed,
        SystemActivitiesFeed,
        SocialSitePosts,
        SiteEventsFeed,
        },
    data() {
    return {
      Classifiedspage: 1,
      ActiveTab: 0,
       input: null,
      ClassifiedsHeaders: [
        { text: 'Title', value: 'Title',class: "overline"},
        { text: 'Where', value: 'MainClass.Name',class: "overline"},
        { text: 'Link', value: 'action',class: "overline"},
      ],
        ClassifiedsEnquiries: [],
        ClassifiedsEnquiriesHeaders: [
        { text: 'Title', value: 'Title',class: "overline"},
        { text: 'Where', value: 'MainClass.Name',class: "overline"},
        { text: 'Link', value: 'action',class: "overline"},
        ],
        Sliders: [],
        mainclass: '',
      subclass: '',
      selectedtagtype: '',
        FeaturedGroupMembers: [],
        Classifieds: [],
        // ClassifiedsClasses: [],
        mustreadnotices: [],
        noticestep: 0,
          
          
          
          
      RelevantTopBannerAds: [],      
      UserRecord: '',
      
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    watch: {
    mustreadnotices (value) {
      if (value.length>0) {
        this.MustReadNoticeDialog = true
      }
    },
    UsersStore (value) {
      if (value.length>0) {
        this.UsersStore.map(userdoc => [
            this.GetFeaturedGroupMembers(userdoc)              
        ])
      }
    },
    },
    computed:{
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      MemberGroupAllow(){
        //just remember group is allowed if specifically allowed through channels OR group "CanSocial" and there are no social restrictions to group
        return this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.SocialChannels.includes('Classifieds') || 
        this.ActiveMemberGroup && this.ActiveMemberGroup.CanSocial && !this.ActiveMemberGroup.SocialRestrictions
      },
      ChannelRestricted(){
        //MemberGroupAllow always overrides defaults. Thus, guest checking but also double check there is no override from Member Group, ergo !this.MemberGroupAllow either as extension or indepednt
        return this.userLoggedIn && this.userLoggedIn.IsGuestUser && !this.System.Guests_can_Social && !this.MemberGroupAllow || this.ActiveMemberGroup && !this.MemberGroupAllow
      },
      UserCompanyid(){
        return this.userLoggedIn && this.userLoggedIn.Company ? this.userLoggedIn.Company.id : ''
      },
      NonGroupRoute(){
        return this.$route.name !== 'GroupSingle' && this.$route.name !== 'GroupNoticeNew' && this.$route.name !== 'GroupPage' && this.$route.name !== 'GroupPageEditor'
      },
      CompanyOnlyFilter(){
        return this.ActiveMemberGroup && this.ActiveMemberGroup.SocialRestrictions && this.ActiveMemberGroup.CompanySocial
      },
      UserClassifieds(){
        if(this.IsUserView && this.EnquiriesCollection === 'classifiedenquiries'){
          return this.Classifieds.filter(clsfd => {
            return clsfd.Created_Byid === this.userLoggedIn.id
          }).map(clsfd => {
            clsfd.Link = '/Classified/'+clsfd.id
            return clsfd
          })
        }
        else if(this.IsUserView && this.EnquiriesCollection === 'groupmemberenquiries'){
          return this.FeaturedGroupMembers.filter(member => {
            return member.userid === this.userLoggedIn.id
          }).map(member => {
            member.MainClass = {id: member.group.id,Name: member.group.name}
            member.Title = member.grouptype.Name
            member.Link = '/FeaturedGroupMember/'+member.groupid+'/'+member.userid+'?GroupType='+member.grouptype.Name.split(' ').join('-')
            return member
          })
        }
      },
      userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        UsersStore(){
        return this.$store.state.UsersArray
        },
        AllSliders(){
           return this.ClassifiedsClasses.map(clss => {
                console.log(clss.Options)
               return clss.Options
               .filter(subclass => {
                   return subclass.Options
               })
               .map(subclass => {
                  
                      let optionsliders = subclass.Options.filter(tag => {
                        return tag.Type === 'Option Set'
                    }).map(tag => {
                        let dataarray = tag.Options.filter(tagopt => {
                        return typeof tagopt.Name === 'number'
                    }).map(tagopt => {
                        return tagopt.Name
                    })
                    let rangeobj = {
                        range: [Math.min(...dataarray),Math.max(...dataarray)],
                        min: Math.min(...dataarray),
                        max: Math.max(...dataarray),
                        Name: tag.Name,
                        classID: clss.ID,
                        subclassID: subclass.ID
                    }
                    console.log(rangeobj)
                    return rangeobj
                    })  
                    

                    let numbersliders = subclass.Options.filter(tag => {
                        return tag.Type === 'Number Field'
                    })
                    .map(tag => {
                        let rangeobj = {
                            range: [0,500000],
                            min: 0,
                            max: 500000,
                            Name: tag.Name,
                            classID: clss.ID,
                            subclassID: subclass.ID
                        }
                        console.log(rangeobj)
                        return rangeobj
                    })
                    
                    return optionsliders.concat(numbersliders)
                    
                }).flat()
            }).flat()
        },
        ClassifiedsRender(){
            return this.Classifieds
            // .concat(this.FeaturedGroupMembers.map(member => {
            //   member.MainClass = {
            //     ID: 1000003,
            //     Name: 'Groups'
            //   }
            //   let groupsmain = this.ClassifiedsClasses.find(obj => obj.ID === 1000003)
            //   let subclass = groupsmain.items.find(obj => obj.ID === Number(member.id))
            //   member.SubClass = {
            //     ID: Number(member.id),
            //     Name: subclass.Name
            //   }
            //   return member
            // }))
        },
        ClassifiedsClassesCounted(){
            return this.ClassifiedsClasses
            .map(clss => {
              clss.Count = this.ClassifiedsRender.filter(clsfd => {
                return clsfd.MainClass && clsfd.MainClass.ID === clss.ID
              }).length
            clss.Options.map(child => { 
              child.Count = this.ClassifiedsRender.filter(clsf => {
                return clsf.MainClass && clsf.MainClass.ID === clss.ID && clsf.SubClass && clsf.SubClass.ID === child.ID
              }).length                   
            return child
            })
            return clss
              
            })
        },
        FeaturedGroupMemberClasses(){
            let tags = this.ClassifiedsbyClasses.map(ad => {
                return ad.tags
            }).flat()
             let uniq = [...new Set(tags)];
            return uniq
        },
        FinalClassifiedsRender(){
          return this.ClassifiedsbyClasses.map(clsfd => {
            
            clsfd.Render = true
            this.ActiveFilters.map(filter => {
              if(!clsfd[filter.PropName]){
                clsfd.Render = false
              }
              else{
                if(filter.Type === 'Option Set'){
                  if(clsfd[filter.PropName].ID !== filter.ID){
                    clsfd.Render = false
                  }
                }
                else if(filter.Type === 'Number Field' && filter.IsOptionList){
                  if(clsfd[filter.PropName] < filter.Range[0] || clsfd[filter.PropName] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }})
            this.AdditionalFilters.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(!clsfd[filter.Field.Name]){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            this.NavList.map(filter => {
              if(filter.Type === 'Single Number Filter'){
                if(!clsfd[filter.Field.Name]){
                  clsfd.Render = false
                }
                else{
                  if(clsfd[filter.Field.Name] < filter.Range[0] || clsfd[filter.Field.Name] > filter.Range[1]){
                    clsfd.Render = false
                  }
                }
              }
            })
            return clsfd       
          })
          .filter(clsfd => {
            return clsfd.Render
          })
          //ActiveFilters
          //return arr1.concat(arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1,arr1)
        },
        ClassifiedsbyClasses(){
            if(this.mainclass && this.subclass){
            let Members =  this.FeaturedGroupMembers.map(record => {
                let obj = {
                    Title: record.Full_Name,
                    IMG: record.Profile_Photo,
                    Description: record.FavoriteQuote,
                    Path: '/FeaturedGroupMember/'+record.groupid+'/'+record.userid+'?GroupType='+record.grouptype.Name.split(' ').join('-'),
                    tags: record.tags,
                    groupid: record.groupid,
                    grouptype: record.grouptype,
                    userid: record.userid
                }
               
                return obj
            })
            //console.log(Members)
            if(this.mainclass && this.mainclass.ID === 1000003){
                console.log('this is groups')
                return Members.filter(member => {
                    console.log(member.grouptype.ID,this.subclass.ID)
                    console.log(member.grouptype.ID === this.subclass.ID)
                        return member.grouptype.ID === this.subclass.ID
                    })
                    .filter(obj => {
                    if(this.selectedtagtype.length>0){
                     console.log('this.devious route')   
                    let tagtypematch = obj.tags.flat()
                        console.log(tagtypematch,this.selectedtagtype)
                        if(this.contains(tagtypematch, this.selectedtagtype)){
                        return obj
                        }
                    }
                    else{
                        console.log('simply return')
                    return obj
                    }
                })
            }
            
                else {
                    return this.Classifieds.filter(clasfd => {
                        return clasfd.SubClass.ID === this.subclass.ID && clasfd.MainClass.ID === this.mainclass.ID
                    })
                    .filter(clasfd => {
                        clasfd.InRange = {}
                        this.Sliders.map(slider => {
                            if(clasfd[slider.Name] && clasfd[slider.Name] >= slider.range[0] && clasfd[slider.Name] <= slider.range[1]){
                                clasfd.InRange[slider.Name] = true
                            }
                            else{
                                clasfd.InRange[slider.Name] = false
                            }
                        })
                        return clasfd
                    })
                    .map(clasfd => {                      
                        Object.keys(clasfd.InRange).map(slidervalue => {
                            if(slidervalue === false){
                                clasfd.Hide = true
                            }
                        })
                        return clasfd
                    })
                    .filter(clasfd => {
                        return Object.keys(clasfd.InRange).every((k) => clasfd.InRange[k])
                    })
                }  
            }
            else{
                return this.Classifieds
                }
        },
      currentNoticeTitle () {
        if(this.mustreadnotices.length > 0 && this.noticestep){
          console.log(this.mustreadnotices)
          return this.mustreadnotices[this.noticestep].title
        }
        
      },
      AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return 'blue-grey darken-4'
        }
        else{
          return 'blue-grey lighten-4'
        }
      },
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      SiteBlogsFilter(){
        return        
      },
      
      
      
      SiteArticlesFilter(){
        return        
      },
      

    },
    created(){
      console.log(this.ClassifiedsClasses)
      if(!this.IsUserView){
        document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      }
      if(this.IsUserView){
        if(this.$route.name === 'ClassifiedEnquiry' || this.$route.name === 'GroupMemberEnquiry'){
          this.ActiveTab = 1
          setTimeout(() => {
              let commentbtn = document.getElementById(this.$route.params.id+'comments')
              commentbtn.click()
          }, 2000);
        }
        this.GetUserClassifiedsEnquiries(this.EnquiriesCollection)
      }
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
      ActivateViewPortFilter(PropName,Value){
        this.$emit('ActivateViewPortFilter',PropName,Value)
      },
      CurrencyFormatter(value,currency,shortcurrency){
      const currencyformatter = this.PrepareCurrency(currency)
      let formalformat = currencyformatter.format(value)
      return formalformat.split(currency).join(shortcurrency)
    },
    PrepareCurrency(currency){			
      currency
      return new Intl.NumberFormat('en-US', {			
            style: 'currency',			
            currency: currency,			
            minimumFractionDigits: 2			
        })
       
    },	
      SaveNewMSG(NewMessage,record){
        if(record.EnquiryingUser){
            const functions = firebase.functions();
            const SendUnAuthorizedMessage = functions.httpsCallable('SendUnAuthorizedMessage');
            SendUnAuthorizedMessage(NewMessage).then(result => {
              console.log(result)
            })
        }
        else{
          let emailmessage = `<div style="text-align:center;"><i>"`+NewMessage.message+`"</i><p>By `+this.userLoggedIn.Full_Name+`</p></div>`
          emailmessage = emailmessage+`<p>In Response to `+record.Classified.Title+` where `+record.Email+` enquiried the below on `+record.Created_On.toDate()+`:</p>`
          emailmessage = emailmessage+`<div style="text-align:center;"><i>"`+record.Enquiry+`"</i></div>`
          let EmailObject = {
                EmailFrom: 'notification@rapidapps.co.za',
                EmailTo: record.Email,
                EmailSubject: 'Reply to Enquiry - '+record.Classified.Title,
                EmailBody: emailmessage
            }
            console.log(EmailObject)
            const functions = firebase.functions();
            const SendEmailNow = functions.httpsCallable('sendEmailNow');
            SendEmailNow(EmailObject).then(result => {
              console.log(result)
              this.EmailBody = ''
            })
        }



              
      },
      comment(record,form,LiasonUploadFile) {
        let NewMessage = {message: this.input}
        if(record.EnquiryingUser){
          NewMessage.recipientid = record.EnquiryingUser.id
          NewMessage.senderid = this.userLoggedIn.id
          NewMessage.Created_On = new Date()
          NewMessage.Read = false
          NewMessage.subjectid = record.Classifiedid
        }       
        if(form){
            NewMessage.FormObj = form.Tabs[0].Sections[0].Content[0]
            if(form.readonly){
              NewMessage.readonly = true
            }
        }
        if(!LiasonUploadFile){
         this.SaveNewMSG(NewMessage,record)  
        }
        else{
            this.UploadFileandAttahctoNewComms(NewMessage,record)
        }
        
      },
      ByLatestLiason(ClientLiason){
            return ClientLiason.map(liase => {
                    liase.createdon = liase.Created_On.toDate()
                    liase.TimestampSort = liase.createdon.getTime()
                    return liase
                  }).sort((a, b) => {
                        var key1 = b.TimestampSort;
                        var key2 = a.TimestampSort;

                        if (key1 < key2) {
                            return -1;
                        } else if (key1 == key2) {
                            return 0;
                        } else {
                            return 1;
                        }
                  })
                  .slice(0,this.slice)
        },
      GetSentorRecievedEnquiryMessages(enquiryobj,prop,altprop,subjectid){
        let vm = this
        return new Promise(function(resolve, reject) {
          db.collection('usermessages').where(prop,'==',vm.userLoggedIn.id).where(altprop,'==',enquiryobj.EnquiryingUser.id).where('subjectid','==',subjectid).onSnapshot(res => {
              let listlength = res.docs.length                            
              if(listlength === 0){
                resolve(enquiryobj.ClientLiason)
              }
              else{
                const changes = res.docChanges();            
                changes.forEach(change => {              
                  if (change.type === 'added') {
                    let msgobj = {
                    ...change.doc.data(),
                    id: change.doc.id
                  }
                    msgobj.Created_By = vm.UsersStore.find(obj => obj.id === msgobj.senderid)
                    enquiryobj.ClientLiason.push(msgobj)
                    console.log(enquiryobj.ClientLiason)
                  if(enquiryobj.ClientLiason.length >= listlength){
                    resolve(enquiryobj.ClientLiason)
                  }
                  }
                })
              }
              

          })
        })
      },
      GetUserClassifiedsEnquiries(col){
         let vm = this
        db.collection(col).where('Ownerid','==',this.userLoggedIn.id).onSnapshot(res => {
          const changes = res.docChanges();            
            changes.forEach(change => {              
              if (change.type === 'added') {
                let enquiryobj = {
                  ...change.doc.data(),
                  ClientLiason: [],
                  id: change.doc.id
                }
                if(enquiryobj.EnquiryingUser){
                   let subjectid = ''
                  if(col === 'classifiedenquiries'){
                    subjectid = enquiryobj.Classifiedid
                  }
                  else if(col === 'groupmemberenquiries'){
                    subjectid = vm.userLoggedIn.id
                  }
                  this.GetSentorRecievedEnquiryMessages(enquiryobj,'senderid','recipientid',subjectid).then(sentresult => {
                  if(sentresult){
                    console.log(sentresult)
                    vm.GetSentorRecievedEnquiryMessages(enquiryobj,'recipientid','senderid',subjectid).then(receivedresult => {                    
                    if(receivedresult){
                      console.log(receivedresult)
                      console.log(enquiryobj)
                      vm.ClassifiedsEnquiries.push(enquiryobj)
                    }
                  })
                  }
                  
                })
                }
                else{
                  vm.ClassifiedsEnquiries.push(enquiryobj)
                }
                
                  
                  }
              })
        })
      },

        AddClassified(){
        let ref = db.collection('classifieds').doc()
        let newdocid = ref.id
          this.$router.push({ name: 'ClassifiedSingle', params: {id: newdocid, UserRecord: this.userLoggedIn}})
        },
        ActivateTagTypeFilter(tag){
            this.selectedtagtype = tag
        },
        contains(a, obj) {
            for (var i = 0; i < a.length; i++) {
                if (a[i].includes(obj)) {
                    return true;
                }
            }
            return false;
        },
        FilterClassifieds(mainclass,child){
            this.Sliders = []
            console.log(mainclass,child)
            console.log(typeof this.selectedtagtype)
            this.mainclass = mainclass
            this.subclass = child
            if(mainclass.ID === 1000003){
                console.log(this.mainclass,this.subclass)

            }
            this.Sliders = this.AllSliders.filter(slider => {
                return slider.classID === mainclass.ID && slider.subclassID === child.ID
            })
        },
      
      GetMustReadNotices() {
        let col = db.collection('notices').where('MustRead','==',true)
        if(this.userLoggedIn.IsGuestUser){
          col = col.where('GuestsIncluded','==',true)
        }
           col.onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {
              
              if (change.type === 'added') {
                  let noticeobj = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                db.collection('notices').doc(noticeobj.id).collection('responses').doc(this.UserRecord.id).onSnapshot(responddoc => {
                      let responsedocdata = responddoc.data()
                      if(responsedocdata && responsedocdata.Confirmed){
                          noticeobj.UserConfirmed = true
                      }
                      if(responsedocdata && responsedocdata.Read){
                          noticeobj.UserRead = true
                          noticeobj.UserReadDate = responsedocdata.ReadDate
                      }
                      if(responsedocdata && responsedocdata.ConfirmDate){
                          noticeobj.UserconfirmationDate = responsedocdata.ConfirmDate
                      }
                      if(!noticeobj.UserRead){
                        this.mustreadnotices.push(noticeobj)
                      }
                      
                  })
                  
                
              }  
              

            })
          })

          },
      GetBannerAds(query){
         
          query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.RelevantTopBannerAds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
              console.log(this.RelevantTopBannerAds)
            })
          })
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      CheckUser(){
             let vm = this
            if(this.userLoggedIn){
              if(!this.ChannelRestricted){
                vm.GetClassifieds()
               if(this.UsersStore.length>0){
                 this.UsersStore.map(userdoc => [
                      this.GetFeaturedGroupMembers(userdoc)              
                  ])
               }
              vm.UserRecord = this.userLoggedIn
              vm.GetMustReadNotices()
              let RouteBannerAdsRef =  db.collection('bannerads').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)           
              vm.GetBannerAds(RouteBannerAdsRef)
              console.log(vm.UserRecord)
                }
                else{
                  //of course here we should rather redirect and stuff but...the truth is I don't think this component will hit as route should not be propogated if not allowed
                }
              }
                
                else{
                  vm.$router.push('/Login')
                  let PublicRouteBannerAdsRef =  db.collection('bannerads').where('PublishType','==','Public').where('Expired','==',false).where('BannerTypeID','==',1000003).where('RecordSpecific','==',false).where('BannerComponentsNames','array-contains',vm.$route.name)
                 
                  vm.GetBannerAds(PublicRouteBannerAdsRef)
                }
        },
        GetClassifieds(){
            let col = db.collection('classifieds')
            if(this.CompanyOnlyFilter){
              col = col.where('Companyid','==',this.UserCompanyid)
            }
            col.onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {              
              if (change.type === 'added') {
                  this.Classifieds.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                
              }
            })
            })
        },
        GetFeaturedGroupMembers(userdoc){

            db.collection('featuredgroupmembers').doc(userdoc.id).collection('grouptypes').onSnapshot(res => {
            const changes = res.docChanges();
            
            changes.forEach(change => {              
              if (change.type === 'added') {
                  this.FeaturedGroupMembers.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
            })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}

</style>
  
            
        


    