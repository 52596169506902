
<template>

  <div class="businessunits">
            <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You added a new Business Unit.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <div class="hidden-sm-and-down">
        <h1 class="Header">Business Units</h1>
    </div>


The system should not show this part, if this relatedcomponent has more than one relationship with the relatedcomponent

<v-data-table
    :headers="headers"
    :items="BusinessUnitsFilteredandSearched"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>My Business Units</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-text-field light
          v-model="relatedbusinessunitsearch"
          append-icon="mdi-file-find-outline"
          label="Search"
          single-line
          hide-details
        ></v-text-field><v-icon @click="ResetSearch()" v-if="relatedbusinessunitsearch !== ''" class="mx-10">mdi-close</v-icon>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="mb-2" v-on="on">New Business Unit</v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="editedItem.Name" label="Name"></v-text-field>
                    <v-textarea v-model="editedItem.Description" label="Description"></v-textarea>

                      <v-combobox
                        v-model="editedItem.Parent"
                        :items="ParentArray"
                        item-text="Name"
                        hide-selected
                        label="Search Parent"
                        small-chips
                        solo
                        flat
                        readonly
                      ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save()">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
<v-toolbar v-if="BusinessUnitsArray" flat dense dark color="primary">
        <v-layout col class="justify-start">
          <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
          <v-icon @click="ResetFilters()">mdi-close</v-icon>
          </v-layout>
        </v-toolbar>
    </template>
    <template v-slot:[`item.action`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-icon
        small
        @click="ViewItem(item)"
      >
        mdi-eye
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">Reset</v-btn>
    </template>
  </v-data-table>



<div class="hidden-md-and-up">

<v-data-table
      :headers="headers"
      :items="BusinessUnitsFilteredandSearched"
      :search="relatedbusinessunitsearch"
      class="mobile" dark>

      <template v-slot:items="props" pagination.sync {rowsPerPage: -1}>
                  <tr class="hidden-md-and-up">
                  <td>
                    <ul class="flex-content">
                      <v-flex xs6 sm6>
                      <li data-label="Name">{ props.item.Name}}</li>
                      </v-flex>
                      <v-flex xs6 sm6>
                      <li data-label="Description">{ props.item.Description}}</li>
                      </v-flex>
                      <v-flex xs6 sm6>
                      <li data-label="Parent">{ props.item.Parent}}</li>
                      </v-flex>
                      <v-flex xs6 sm6>
                      <v-btn flat :to="'/business-unit/' + props.item.id"><v-icon >mdi-eye</v-icon></v-btn>
                      </v-flex>
                      <v-icon flat @click="editBusinessUnitItem(props.item)">mdi-pencil</v-icon>
                    </ul>
                  </td>
</tr>

      </template>
      <!-- <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template> -->
    </v-data-table>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
  data() {
    return {
      ParentArray: [],
      Parentfilter: {Name: ''},
      mainrecordid: '',
      RelatedMasterName: '',
      RelatedMaster: '',
      dialog: false,
      initialize: '',
      relatedbusinessunitsearch: '',
      headers: [
      
        { text: 'Name', value: 'Name'},


        { text: 'Description', value: 'Description'},



        { text: 'Parent', value: 'Parent.Name'},

        { text: 'Actions', value: 'action', sortable: false },
      ],
      snackbar: false,
      EditedBusinessUnitsnackbar: false,
      BusinessUnitEditdialog: false,
      newBusinessUnitdialog: false,
      BusinessUnitsArray: [],
      editedIndex: -1,
      defaultItem: {
        id: '',
          Name: '',
          Description: '',
          Parent: {id: '',  Name: ''},
      },
      editedItem: {
        id: '',
          Name: '',
          Description: '',
          Parent: {id: '',  Name: ''},
      },
      UserRecord: {},
      UserBusinessUnitID: '',
      UserRoles: '',
      UserBusUnitChildren: [],
      UserBusUnitParents: [],
      getAll: false,
      getUnitDown: false,
      getUserUnit: false,
      getOwner: false,
      listAll: false,
      listUnitDown: false,
      listUserUnit: false,
      listOwner: false,
      createAll: false,
      createUnitDown: false,
      createUserUnitget: false,
      createOwner: false,
      updateAll: false,
      updateUnitDown: false,
      updateUserUnit: false,
      updateOwner: false,
      deleteAll: false,
      deleteUnitDown: false,
      deleteUserUnit: false,
      deleteOwner: false,
      AllgetRoles: [
  "System Admin"
],
      UnitDowngetRoles: [],
      UserUnitgetRoles: [],
      OwnergetRoles: [],
      AlllistRoles: [
  "System Admin"
],
      UnitDownlistRoles: [],
      UserUnitlistRoles: [],
      OwnerlistRoles: [],
      AllcreateRoles: [
  "System Admin"
],
      UnitDowncreateRoles: [],
      UserUnitcreateRoles: [],
      OwnercreateRoles: [],
      AllupdateRoles: [
  "System Admin"
],
      UnitDownupdateRoles: [],
      UserUnitupdateRoles: [],
      OwnerupdateRoles: [],
      AlldeleteRoles: [
  "System Admin"
],
      UnitDowndeleteRoles: [],
      UserUnitdeleteRoles: [],
      OwnerdeleteRoles: [],
      URLPATHNAME: ''
    }
  },

  created(){
    let currenturl= window.location.pathname
    let urlpath = currenturl.replace(this.$route.params.id,'')
    this.URLPATHNAME = urlpath.split('/').join('')
    this.GetUser()

      this.GetBusinessUnitsParent()

    this.GetRelatedMaster()
  },
  computed: {

    formTitle () {
        return this.editedIndex === -1 ? 'New Business Unit' : 'Edit Business Unit'
      },
    BusinessUnitsSearched() {
        return this.RelatedBusinessUnits.filter(BusinessUnit => {
        return BusinessUnit.Name.toLowerCase().includes(this.relatedbusinessunitsearch.toLowerCase())
      })

      },
      RelatedBusinessUnits() {
        let RelatedToID = this.$route.params.id
        let currenturl= window.location.pathname
        let urlpath = currenturl.replace(this.$route.params.id,'')
        if (urlpath === '/BusinessUnit/') {
       return this.BusinessUnitsArray.filter(BusinessUnit => {
         return BusinessUnit.Parentid === RelatedToID
        })
        }
      },
      BusinessUnitsFilteredandSearched() {
      return this.BusinessUnitsSearched.filter(BusinessUnit => {return BusinessUnit})
      },
    },
  methods: {
    ResetFilters() {},
    ResetSearch() {
      this.relatedbusinessunitsearch = ''
    },

    async GetUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRoles = userdetails.rolesarrayQuery
              vm.UserBusinessUnitID = userdetails.Business_Unitid
              console.log(vm.UserBusinessUnitID)
              
              db.collection('businessunits').doc(vm.UserBusinessUnitID).onSnapshot(snapshot => {
             var businessunitdata = snapshot.data()
             vm.UserBusUnitChildren = businessunitdata.childarrayQuery
             vm.UserBusUnitParents = businessunitdata.parentarray
             console.log(vm.UserRoles)
             vm.UserRoles.map(role => {
              let alltest = vm.AlllistRoles.find(obj => obj == role)
              let peertest = vm.UnitDownlistRoles.find(obj => obj == role)
              let parenttest = vm.UserUnitlistRoles.find(obj => obj == role)
              let childtest = vm.OwnerlistRoles.find(obj => obj == role)
              if(typeof alltest !== 'undefined'){
                vm.listAll = true
                console.log('Related BU permission is All')
              }
              else if(typeof peertest !== 'undefined'){
                vm.listUnitDown = true
                console.log('Related BU permission is UnitDown')
              }
              else if(typeof parenttest !== 'undefined'){
                vm.listUserUnit = true
                console.log('Related BU permission is UserUnit')
              }
              else if(typeof childtest !== 'undefined'){
                vm.listOwner = true
                console.log('Related BU permission is Owner')
              }
             })
             vm.GetBusinessUnits(vm.UserBusUnitChildren, vm.UserBusinessUnitID)
           })

            })

          }
      else {
          }
          })
      },

    RoutetoErrorPage(){
        this.$router.replace('/PermissionError')
      },

    GetBusinessUnits(busunitchildren, busunitid) {
      if(this.listAll === false && this.listUnitDown === false && this.listUserUnit === false && this.listOwner === false){
          this.RoutetoErrorPage()
        }
      var collectionReference = db.collection('businessunits')
      console.log(busunitchildren)
      if(this.listAll){
      var query = collectionReference;
      this.BusinessUnitsCall(query)
      }
      else if(this.listUnitDown){
      var query = collectionReference.where('Business_Unitid', 'in', busunitchildren);
      this.BusinessUnitsCall(query)
      }
      else if(this.listUserUnit){
      var query = collectionReference.where('Business_Unitid', '==', busunitid);
      this.BusinessUnitsCall(query)
      }
      else if(this.listOwner){
      var query = collectionReference.where('Business_Unitid', '==', this.UserRecord.id);
      this.BusinessUnitsCall(query)
      }

  },

  BusinessUnitsCall(query){
    query.get().then(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.BusinessUnitsArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    })
  },
    FullReset(){
      this.ResetFilters()
      this.ResetSearch()
      this.editedItem = Object.assign({},this.defaultItem)
    },

    AssignEditedItem(clickfrom){
    },

    GetRelatedMaster(){
      let RelatedToID = this.$route.params.id
      let currenturl= window.location.pathname
      let urlpath = currenturl.replace(this.$route.params.id,'')
        if (urlpath === '/BusinessUnit/') {
        this.GetParent()
        }
    },


      GetParent() {
      db.collection('businessunits').doc(this.$route.params.id).onSnapshot(snapshot => {
        let masterrecord = snapshot.data()
        this.RelatedMasterName = masterrecord.Name
        this.defaultItem.Parent = {Name: this.RelatedMasterName, id: this.$route.params.id}
        this.editedItem = this.defaultItem
      })

      },




      GetBusinessUnitsParent() {
      db.collection('businessunits').onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.ParentArray.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }

      })
    })
      },


      ViewItem (item) {
        this.$router.replace('/BusinessUnit/'+item.id)
      },

      deleteItem (item) {
        const index = this.BusinessUnitsArray.indexOf(item)
        confirm('Are you sure you want to delete this item?') && db.collection('businessunits').doc(item.id).delete() && this.BusinessUnitsArray.splice(index, 1)
      },

      editItem (item) {
        this.editedIndex = this.BusinessUnitsArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.mainrecordid = item.id
        this.dialog = true
      },
      close () {
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.mainrecordid = ''
        }, 300)
      },

      save () {
      const EditedBusinessUnit = {
    Name: this.editedItem.Name,
    Description: this.editedItem.Description,
    Parent: this.editedItem.Parent.Name,
      Parentid: this.editedItem.Parent.id,
        }
        if (this.editedIndex > -1) {
          this.mainrecordid = this.editedItem.id

      db.collection('businessunits').doc(this.mainrecordid).update({
        Name: EditedBusinessUnit.Name,

        Description: EditedBusinessUnit.Description,


        Parent: {Name: this.editedItem.Parent.Name,id: this.editedItem.Parent.id},
        Parentid: this.editedItem.Parent.id,
              });
              Object.assign(this.BusinessUnitsArray[this.editedIndex], this.editedItem)
              this.closeBusinessUniteditDialog
              this.EditedBusinessUnitsnackbar = true
        }
         else {
          const NewBusinessUnit = {
      Name: EditedBusinessUnit.Name,

      Description: EditedBusinessUnit.Description,


        Parent: {Name: this.editedItem.Parent.Name,id: this.editedItem.Parent.id},
        Parentid: this.editedItem.Parent.id,
          }
          db.collection('businessunits').add(NewBusinessUnit).then({

          })
        }
        this.close()
      },

  }
}

</script>

      