<template>
  <div>
    <v-card  class="siteconfigcard"  width="100%">
                          <v-card-title class="mediumoverline" style="padding-top:30px;">
                       
                       <v-spacer v-if="!MiniView">
                           </v-spacer>

                           <!-- <v-list-item dense> -->
                               <v-list-item-content style="border: 2px solid grey;border-radius: 12px;padding:25px;" v-if="TokenEndingBalance > 0">
                                    <span style="align-self: baseline;padding-bottom: 20px;" class="justaboveoverline">
                                  {{TokenEndingAmount}}  Credit Tokens</span>
                                   <v-list-item-title  :style="MiniView ? 'text-transform: none !important;text-align-last: justify;padding-bottom: 10px;' : 'text-align-last: justify;padding-bottom: 10px;'" :class="MiniView ? 'stdfontsubtleoverline' : 'justaboveoverline'">
                                       <span >
                                       {{TokenEndingBalanceLength > 9 && MiniView ? 'Bal:': 'Balance'}}</span>
                                       <span >
                                       {{CurrencyFormatter(TokenEndingBalance,$store.state.DefaultCurrency.Currency)}}</span>
                                   </v-list-item-title>
                                   <v-list-item-subtitle style="text-align: end;">                                       
                           <!-- withdraw immediate on e-commerce otherwise create "purchaseinvoice" and amound paid confirm completes transaction until which point ammount payable applies,
                           Transfer converts from GRoup to user tokens. At this stage user tokens is non refundable and essentially a forfeit of tokens -->
                           <v-btn :small="MiniView" v-if="TokenEndingBalance > 0" color="warning" dark outlined>Withdraw</v-btn>
                           <!-- Let's stick with Withdraw, the term makes sense. But it is effectively then a transfer from my Group to my personal user account. -->
                           <!-- <v-btn  :small="MiniView" v-if="TokenEndingBalance > 0"  color="success" dark outlined>Transfer</v-btn> -->
                                   </v-list-item-subtitle>
                               </v-list-item-content>
                           <!-- </v-list-item> -->
                    </v-card-title>
                   <v-list>
                       <v-list-item :style="MiniView ? 'text-transform: none !important;' : ''" :class="MiniView ? 'overline' : ''">
                           <v-list-item-content>
                               Date
                           </v-list-item-content>
                        <v-list-item-content  >
                               Description
                            </v-list-item-content>
                            <v-list-item-content  style="text-align: end;">
                               <span>Amount</span>
                            </v-list-item-content>
                        <v-list-item-content style="text-align: end;">
                           <span>Currency Conversion</span>
                        </v-list-item-content>
                        </v-list-item>
                       <v-list-item :class="MiniView ? 'caption' : ''" v-for="credittoken in ComputedCreditTokenTransactions" :key="credittoken.itemObjKey">
                           <v-list-item-content>
                            <v-list-item-subtitle  :class="MiniView ? 'caption' : ''">
                                   {{credittoken.DateString}}
                               </v-list-item-subtitle>
                               <v-list-item-title v-if="credittoken.Content" :class="MiniView ? 'caption grey--text' : 'grey--text'">
                                   <!-- Group-Blog/JAX0003/Blog/LVGYDEQqLKyDWRGZz3c7 -->
                                   <v-chip :to="'/'+credittoken.Content.Type.split(' ').join('-')+'/'+ActiveAccount.id+'/'+credittoken.Content.Type.split('Group ').join('')+'/'+credittoken.Content.Contentid">
                                 {{credittoken.Content.ContentTitle.substr(0,25)}}
                                   </v-chip>
                               </v-list-item-title>
                           </v-list-item-content>
                        <v-list-item-content  >
                               <span v-html="credittoken.Reference"></span>
                            </v-list-item-content>
                            <v-list-item-content style="text-align: end;">
                            <span>{{credittoken.Amount}}</span>
                        </v-list-item-content>
                            <v-list-item-content  style="text-align: end;">
                               <span class="blue--text" v-if="credittoken.TransferType === 'Debit'">-{{CurrencyFormatter(credittoken.RevenueAmount,$store.state.DefaultCurrency.Currency)}}</span>
                                <span class="green--text" v-if="credittoken.TransferType === 'Credit'">{{CurrencyFormatter(credittoken.RevenueAmount,$store.state.DefaultCurrency.Currency)}}</span>
                            </v-list-item-content>
                        
                        </v-list-item>
                   
                       <!-- <v-list-item  :class="MiniView ? 'background overline' : 'background justaboveoverline'">
                           Ending Balance <v-spacer></v-spacer>
                            {{CurrencyFormatter(TokenEndingBalance,$store.state.DefaultCurrency.Currency)}}
                            </v-list-item> -->
                   </v-list>
                     </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/FieldValueEditerComponent';
export default {
    props: ['System','SystemEntities','ActiveAccount'],
    components: {FieldValueEditerComponent},
    data() {
        return {
            
            CreditTokens: [],
            CreditTokenTransactions: [],
        }
    },	
    computed: {
        ComputedCreditTokenTransactions(){
             return this.CreditTokenTransactions.map(entry => {
                let date = entry.Date.toDate()
                entry.DateString = date.toDateString()
                if(entry.Type.Name === 'Group Content Purchase' && this.ActiveAccount && this.ActiveAccount.Account_Type.Name === 'Group Account'){
                    entry.TransferType = 'Credit'
                }
                entry.RevenueAmount = entry.Conversions.map(conv => {
                    let total = Number(conv.ROE)*Number(conv.Amount)
                    return total
                }).reduce((a, b) => a + b, 0)
                        // if(entry.Type = 'Debit'){
                        //     entry.Debit = entry.Amount
                        // }
                        // else{
                        //     entry.Credit = entry.Amount
                        // }
                        return entry
                    })
                    .sort((a, b) => {
                    var key1 = a.createdon;
                    var key2 = b.createdon;

                    if (key1 < key2) {
                        return -1;
                    } else if (key1 == key2) {
                        return 0;
                    } else {
                        return 1;
                    }
            })
        },
    TokenEndingBalanceLength(){
          let string = this.TokenEndingBalance.toString()
          return string.length
      },
      TokenEndingAmount(){
          return this.ComputedCreditTokenTransactions.map(trans => {
                  let total = Number(trans.Amount)
                  return total
              }).reduce((a, b) => a + b, 0)
      },
      TokenEndingBalance(){
          //125489015.12
          return this.ComputedCreditTokenTransactions.map(trans => {
                  let total = Number(trans.RevenueAmount)
                  return total
              }).reduce((a, b) => a + b, 0)
      },
        MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        if(this.ActiveAccount.Account_Type && this.ActiveAccount.Account_Type.Name ==='Group Account'){                    
            //this.GetTokenCredits()
            // let creditpath = db.collection('tokentransactions').where('CreditedAccountid','==',this.ActiveAccount.id).where('Withdrawn','==',false)
            // this.GetCreditTokenTransactions(creditpath)
            // let debitedpath = db.collection('tokentransactions').where('DebitedAccountid','==',this.ActiveAccount.id).where('Withdrawn','==',false)
            // this.GetCreditTokenTransactions(debitedpath)
                let grouppath = db.collection('tokentransactions').where('Groupid','==',this.ActiveAccount.id).where('Withdrawn','==',false)
            this.GetCreditTokenTransactions(grouppath)
        } 
    },
    methods:{
        GetCreditTokenTransactions(path){
            
                path.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                    if (change.type === 'added') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        this.CreditTokenTransactions.push(tokenobj)
                    }
                    if (change.type === 'modified') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CreditTokenTransactions.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CreditTokenTransactions.indexOf(oncheck)
                            this.CreditTokenTransactions.splice(index,1,tokenobj)
                        }
                    }
                    if (change.type === 'removed') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CreditTokenTransactions.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CreditTokenTransactions.indexOf(oncheck)
                            this.CreditTokenTransactions.splice(index,1)
                        }
                    }
                    })
                })
        },
        GetTokenCredits(){
            let path = db.collection('billingaccounts').doc(this.ActiveAccount.id).collection('credittokens')
                path.onSnapshot(res => {
                    const changes = res.docChanges();
                    changes.forEach(change => {
                    if (change.type === 'added') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        this.CreditTokens.push(tokenobj)
                    }
                    if (change.type === 'modified') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CreditTokens.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CreditTokens.indexOf(oncheck)
                            this.CreditTokens.splice(index,1,tokenobj)
                        }
                    }
                    if (change.type === 'removed') {
                        let tokenobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                        }
                        let oncheck = this.CreditTokens.find(obj => obj.id === tokenobj.id)
                        if(oncheck){
                            let index = this.CreditTokens.indexOf(oncheck)
                            this.CreditTokens.splice(index,1)
                        }
                    }
                    })
                })
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PrepareAddressField(fieldname){
            let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
        field.Response = {
            [field.Name+'_Street_Number'] : '',
            [field.Name+'_Street_Name'] : '',
            [field.Name+'_Suburb'] : '',
            [field.Name+'_City'] : '',
            [field.Name+'_Postal_Code'] : '',
            [field.Name+'_State_Province'] : '',
            [field.Name+'_Country_Region'] : ''
        }
        //console.log(field)
        },  
        UpdateBillingAccount(){
            console.log(this.Invoicing_Address,this.ActiveAccount)
            let brklength = this.Invoicing_Address.FieldBreakdown.length 
            this.Invoicing_Address.FieldBreakdown.map((brk,brkindex) => {
                db.collection('billingaccounts').doc(this.ActiveAccount.id).update({
                [brk.Name]: this.ActiveAccount[brk.Name],
                }).then(updateddoc => {
                    if(brkindex-1+2 === brklength){
                        db.collection('billingaccounts').doc(this.ActiveAccount.id).update({
                            Account_Name: this.ActiveAccount.Account_Name,
                            Account_Email: this.ActiveAccount.Account_Name,
                            Account_Phone: this.ActiveAccount.Account_Phone,
                            Client_Reference: this.ActiveAccount.Client_Reference,
                            Account_Website: this.ActiveAccount.Account_Website,
                        }).then(updateddoc => {
                            this.snackbartext = 'Updated Billing Account '+this.ActiveAccount.id
                            this.snackbar = true
                        })
                    }
                })
            })
        },
    }
}
</script>

<style>

</style>



