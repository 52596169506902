<template>
  <v-list-item @click="PrepareSecurityRules()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Publish Security Rules
          </v-list-item-title>
        <v-list-item-action>
            <v-icon color="warning">mdi-security-network</v-icon>
          </v-list-item-action>
        </v-list-item>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    props: ['System','SystemEntities','CategoricalNavlists'],
    components: {

    },
    data(){
        return {

        }
    },
    computed:{
        SocialNetworkActive(){
        return this.PluginDataBase.Social_Network_Builder && this.PluginDataBase.Social_Network_Builder.Active
      },
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        },
        SystemisRA(){
        return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
        },
        ComputedSystemEntities(){
            return this.SystemEntities.map(ent => {
                let entityobj = Object.assign({},ent)
                if(ent.Entity_Type && ent.Entity_Type.Name === 'Web Form'){
                    //entityobj.datasetsecuritystructure = 'Custom Roles'
                }
                return entityobj
            })
            .filter(ent => {
                return ent.Entity_Type && ent.Entity_Type.Name !== 'Billing Account'
            })
        },
        StoreEntities(){
            return this.CategoricalNavlists.filter(navlist => {
                return navlist.CategoricalListType === 'Store Products'
            })
        },
        EntitiesWithActivities(){
            return this.ComputedSystemEntities.filter(entity => {
                return entity.HasActivities && entity.id !== 'Users' && entity.id !== 'Business_Units'
            })
        }
    },
    created(){
                
        //REMOVED BUT MAY RECYCLE
        // function UnPublishedGroupPass(){
        // return existingData().IsPaid == true && request.resource.data.GroupPublishStatus != 'Published' && request.resource.data.MonetizingScheduleID == resource.data.MonetizingScheduleID && request.resource.data.MonetizationPackage == resource.data.MonetizationPackage
        // }    

        // function PublishedGroupPass(){
        // return existingData().IsPaid == true && resource.data.GroupPublishStatus == 'Published' && request.resource.data.MonetizingScheduleID == resource.data.MonetizingScheduleID && request.resource.data.MonetizationPackage == resource.data.MonetizationPackage
        // }  

        // function UserisAuthorizedWHVisitor(whid){
        // return getUserRoleData().Warehouses.includes(whid)
        // }  
        
    },
    methods:{
        
        GetActivitiesPermissions(build,index,listlength,permissionname,permissionbooleanname,permissionarray,Allarray,Unitdownarray,UserUnitarray,Ownerarray){
        console.log(build.DisplayName,build,index,listlength,permissionname,permissionbooleanname,permissionarray,Allarray,Unitdownarray,UserUnitarray,Ownerarray)
        let listposition = index-1+2
        let separator = ' || '
        if(listposition === listlength){
          separator = ''
        }
        let buildname = build.SingleName.split(' ').join('')
        if(build.datasetsecuritystructure === 'Business Unit'){
          // let permitted = BURolesArray.map(roletype => {
          //   this.ReturnActivitiesPermissionsforBU(build,roletype,buildname,separator)
          // })
          // return permitted
          let Allget = this.ReturnActivitiesPermissionsforBU(build,Allarray,buildname,separator)
          let Unitdownget = this.ReturnActivitiesPermissionsforBU(build,Unitdownarray,buildname,separator)
          let UserUnitget = this.ReturnActivitiesPermissionsforBU(build,UserUnitarray,buildname,separator)
          let Ownerget = this.ReturnActivitiesPermissionsforBU(build,Ownerarray,buildname,separator)
          return Allget+` || `+Unitdownget+` && RecordWithinBusinessUnitStructure() || `+UserUnitget+` && RecordInUserBusinessUnit() || `+Ownerget+` && UserisOwner()`+separator
        }
        else if(build.datasetsecuritystructure === 'Custom Roles'){
          //console.log()
          
          if(build.datasetsecuritystructure === 'Custom Roles' && build[permissionname] === 'Inherited'){
            return `ActivityRegardingType() == '`+buildname+`'`+separator
          }
          else if(build.datasetsecuritystructure === 'Custom Roles' && build[permissionname] === 'Signed In'){
            return `ActivityRegardingType() == '`+buildname+`' && isSignedIn()`+separator
          }
          else if(build.datasetsecuritystructure === 'Custom Roles' && build[permissionname] === 'Admin'){
            return `ActivityRegardingType() == '`+buildname+`'`+separator
          }
          else if(build.datasetsecuritystructure === 'Custom Roles' && build[permissionname] === 'Owner'){
            return `ActivityRegardingType() == '`+buildname+`'`+separator
          }
          else if(build.datasetsecuritystructure === 'Custom Roles' && build[permissionname] === 'Custom Role'){
            let roleslist = this.ReturnPermittedRoles(build,permissionarray)
            return `ActivityRegardingType() == '`+buildname+`' && useroles().hasAny([`+roleslist+`])`+separator
          }
         
        }
        else if(build.datasetsecuritystructure === 'Social Site'){
          // console.log('Social Site Rs2oless',permissionname)
          // console.log(build[permissionname],build)
          return `ActivityRegardingType() == '`+buildname+`' && GlobalItemModerator('`+build.ModeratorCheck+`')`+separator
          
        }
      },
      ReturnActivitiesPermissionsforBU(build,rolesarray,buildname,separator){
        // console.log(rolesarray,build)
        // console.log(build[rolesarray])
        let roleslist = this.ReturnPermittedRoles(build,rolesarray)
        return `ActivityRegardingType() == '`+buildname+`' && useroles().hasAny([`+roleslist+`])`
      },
        ReturnRMCustomPermission(section,permissionname,permissionbooleanname,permissionarrayname,buildid,build,buildindex){
        if(section[permissionname] === 'Custom Role'){
          return `useroles().hasAny(['SystemAdmin']) || useroles().hasAny(['`+section[permissionarrayname]+`'])`
        }
        else if(section[permissionname] === 'Admin'){
          return `useroles().hasAny(['SystemAdmin'])`
        }
        else if(section[permissionname] === 'Inherited'){
          if(build.datasetsecuritystructure === 'Warehouse'){
            let buildname = build.SingleName.split(' ').join('')
            if(build[permissionname] === 'Signed In'){
              return `isSignedIn()`
            }
            else{
                let prop = permissionname.split('Permission')[0]                
              return `allow create: if WarehouseModerator(GetParentRecord('`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'`+prop+`')`
            }
            
          }
          else if(build.datasetsecuritystructure === 'Custom Roles'){
            let buildname = build.SingleName.split(' ').join('')
            if(build[permissionname] === 'Signed In'){
              return `isSignedIn()`
            }
            else{
              return this.ReturnSTDPermissionsforCustomRoles(build,permissionarrayname,buildname)
            }
            
          }
          else if(build.datasetsecuritystructure === 'Business Unit'){
            let forsubcollection = true
            if(permissionname === 'CreatePermission'){
              return this.GetBUPermissions(build,buildindex,0,'AllupdateRoles','UnitDownupdateRoles','UserUnitupdateRoles','OwnerupdateRoles',forsubcollection)
            }
            else if(permissionname === 'GetPermission'){
              return this.GetBUPermissions(build,buildindex,0,'AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles',forsubcollection)
            }
            else if(permissionname === 'ListPermission'){
              return this.GetBUPermissions(build,buildindex,0,'AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles',forsubcollection) 
            }
            else if(permissionname === 'UpdatePermission'){
              return this.GetBUPermissions(build,buildindex,0,'AllupdateRoles','UnitDownupdateRoles','UserUnitupdateRoles','OwnerupdateRoles',forsubcollection)              
            }
            else if(permissionname === 'DeletePermission'){
              return this.GetBUPermissions(build,buildindex,0,'AlldeleteRoles','UnitDowndeleteRoles','UserUnitdeleteRoles','OwnerdeleteRoles',forsubcollection) 
            }
            
          }
        }
        else{
          return `useroles().hasAny(['SystemAdmin'])`
        }
          
      },
      NewBURules(action,build,subcollection){
        let rule = ``
        let prefix = ``
        let andorsuffix = ``
         
         //BUT REALLY WE MADE BA STATIC RENDER, EVENB REMOVE FROM ENTITIES COMPUTATION SO THIS WILL NEVER HIT
        // if(build.Entity_Type && build.Entity_Type.Name === 'Billing Account' && !subcollection){
        //     
        //            if(action === 'Get' || action === 'List'){
        //             let bauser = `existingData().Userid == request.auth.uid`
        //             let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
        //             let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid)`
        //             let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid)`
        //             prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
        //             }
        //             else if(action === 'Create'){                        
        //             let bauser = `incomingData().Userid == request.auth.uid`
        //             let bacompuser = `GetParentRecord('usercompanies',incomingData().Companyid).Userid == request.auth.uid`
        //             let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid)`
        //             let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid)`
        //             prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
        //             }
        //             else if(action === 'Update'){
        //                 //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                        
        //             let baownerprotection = `existingData().Userid == incomingData().Userid`
        //             let baprotection = `existingData().Billing_Type.Name == incomingData().Billing_Type.Name`
        //             let bauser = `existingData().Userid == request.auth.uid && `+baownerprotection+` && `+baprotection
        //             let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid && `+baownerprotection+` && `+baprotection
        //             let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid) && `+baownerprotection
        //             let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid) && `+baownerprotection
        //             prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
        //             }
        //         }
        //        else 
               if(build.Entity_Type && build.Entity_Type.Name === 'Sales Invoice' && !subcollection ||
                build.Entity_Type && build.Entity_Type.Name === 'Sales Quote' && !subcollection ||
                build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice' && !subcollection){
                    let accountingclerkprop = ''
                    if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice'){
                        accountingclerkprop = 'Creditor'
                    }
                    else{
                        accountingclerkprop = 'Debtor'
                    }
                    //if store order build we are pleased for now any subcol, later need to limit to cart items subcol only. for now do not want to set it fixed either
                    //also need logic still for "store" who can create (maybe user single we set per inventory article build)
                    //also need warehouse items who can publish to store..sshould be a WH boolean moderation
                   if(action === 'Get' || action === 'List'){
                    //GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || 
                    let bauser = `GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid`
                    let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                    let accountinguser = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)`
                    prefix = bauser+` || `+bacompuser+` || `+accountinguser+` || `
                    }
                    else if(action === 'Create'){
                    prefix = `GetParentRecord('billingaccounts',incomingData().BillingAccountid).Userid == request.auth.uid || `
                    }
                    else if(action === 'Update'){
                    //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                    //NO NEVER EVER, ONLY ACCOUNTINGCLERKS CAN UPDATE THESE THANK YOU VERY MUCH!
                    let baprotection = `existingData().BillingAccountid == incomingData().BillingAccountid`
                    //let progressprotection = `existingData().Progress.Name == incomingData().Progress.Name`
                    //let bauser = `existingData().Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    //let bacompuser = `GetParentRecord('usercompanies',GetParentRecord('billingaccounts',existingData().BillingAccountid).Companyid).Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    let accountingusers = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)  && `+baprotection
                    prefix = accountingusers+` || `
                    }
                }
                else if(subcollection && build.Entity_Type && build.Entity_Type.Name === 'Store Order'){
                    let accountingclerkprop = ''
                    if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice'){
                        accountingclerkprop = 'Creditor'
                    }
                    else{
                        accountingclerkprop = 'Debtor'
                    }
                    //if store order build we are pleased for now any subcol, later need to limit to cart items subcol only. for now do not want to set it fixed either
                    //also need logic still for "store" who can create (maybe user single we set per inventory article build)
                    //also need warehouse items who can publish to store..sshould be a WH boolean moderation
                   if(action === 'Get' || action === 'List'){
                    //GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || 
                    let bauser = `GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid`
                    let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                    let accountinguser = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)`
                    prefix = bauser+` || `+bacompuser+` || `+accountinguser+` || `
                    }
                    else if(action === 'Create'){
                    prefix = `GetParentRecord('billingaccounts',incomingData().BillingAccountid).Userid == request.auth.uid || `
                    }
                    else if(action === 'Update'){
                        //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                        let baprotection = `existingData().BillingAccountid == incomingData().BillingAccountid`
                        let progressprotection = `existingData().Progress.Name == incomingData().Progress.Name`
                        let bauser = `existingData().Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                        let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                        let accountingusers = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)  && `+baprotection
                        prefix = bauser+` || `+bacompuser+` || `+accountingusers+` || `
                    }
                }
                else if(build.Entity_Type && build.Entity_Type.Name === 'User Company' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update
                   if(action === 'Get' || action === 'List'){
                    let bauser = `existingData().Userid == request.auth.uid`
                    let bacompuser = `getUserRoleData().Companyid == `+build.SingleName.split(' ').join('').toLowerCase()+`id`
                    prefix = bauser+` || `+bacompuser+` || `
                    }
                    //yet to see what this becomes I believe update should NOT be allowed, only update permission based on std BU roles
                    //yeah on other end how do they update basic info if they cannot change?
                    else if(action === 'Update'){
                    prefix = `existingData().Userid == request.auth.uid || `
                    }
                }
                
                else if(build.Entity_Type && build.Entity_Type.Name === 'Cash Loan' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update - COULD append with userdata.TrackedEntity
                   if(action === 'Get' || action === 'List'){
                    let bauser = `getUserRoleData().Companyid == existingData().Companyid`
                    let bacompuser = `existingData().Userid == request.auth.uid`
                    prefix = bauser+` || `+bacompuser+` || `
                    }                    
                }
                else if(build.Entity_Type && build.Entity_Type.Name === 'Store Order' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update - COULD append with userdata.TrackedEntity
                   if(action === 'Get' || action === 'List'){
                    let bauser = `getUserRoleData().Companyid == existingData().Companyid`
                    let bacompuser = `existingData().Userid == request.auth.uid`
                    prefix = bauser+` || `+bacompuser+` || `
                    }                    
                }
                //UNDERSTANDING THE ANDORSFFIX - There are static rules to these types, refer to "prefix". However, there are also dynamic configoptions, dynamic rules for dynamic roles. 
                //These dynamic rules must be appended with the rules safeguarding fields that protect the integrity of the accounting module
                //therefore, STILL perform andorsuffix for these
                //HOWEVER THE MAJOR COMES IN WITH THE CODE WE HAD BEFORE 1) WE RENDER "RULE" TWICE AND 2)WE SEEM TO PLACE STATIC ("PREFIX") RULES IN HERE
                //ESSENTIALLY the question is - can a debtor maintain the debtors of any table, or specific tables only?
                //understand this...these tables are static. salesinvoices is salesinvoices there aren't 2 tables for it. so prefix is enough
                //IN A NUTSHELL - ANDORSUFFIX IS EXCLUSIVE FOR USE ON CUSTOM ROLES, BY EXTENDING CUSTOM ROLES WITH INTEGRITY FIELD PROTECTION
                if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice' && !subcollection){
                    //PurchaseInv - only credit clerk can update Amunt_Paid, Sales Invoice - only debtors clerk, Sales Quote - only debtors clerk
                    if(action === 'Update'){
                        andorsuffix = ` && existingData().Amount_Paid == incomingData().Amount_Paid && existingData().BillingAccountid == incomingData().BillingAccountid`
                        //the issue here is we want logic+limitation || logic+unrestricted (creditor/debtor) etc
                    }                    
                }
                if(build.Entity_Type && build.Entity_Type.Name === 'Sales Invoice' && !subcollection || build.Entity_Type && build.Entity_Type.Name === 'Sales Quote' && !subcollection){
                    if(action === 'Update'){
                        andorsuffix = ` && existingData().Progress.Name == incomingData().Progress.Name && existingData().BillingAccountid == incomingData().BillingAccountid`
                        //the issue here is we want logic+limitation || logic+unrestricted (creditor/debtor) etc
                    }
                }
            if(action === 'Create'){
               rule = rule+`EntityCreatePass('`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
            }
            else if(action === 'Get'){
                rule = rule+`EntityGetBUPass('`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
            }
            else if(action === 'List'){
                rule = rule+`EntityListBUPass('`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
            }
            else if(action === 'Update'){
                rule = rule+`EntityUpdateBUPass('`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
            }
            else if(action === 'Delete'){
                rule = rule+`EntityDeleteBUPass('`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
            }
            let final = ``
            if(andorsuffix){
                final = prefix+rule+andorsuffix
            }
            else{
                final = prefix+rule
            }
            return final
        },
        FinalPublish(securityRules){
            let vm = this
            let req = {
                securityRules: securityRules
            }
            console.log(securityRules)
            const functions = firebase.functions();
            const UpdateSystemSecurityRules = functions.httpsCallable('UpdateSystemSecurityRules');
            UpdateSystemSecurityRules(req).then(result => {
            console.log(result)
            let snackbarcontent = {
              snackbartimeout: 4000,
              snackbartext: 'Updated the Server with all new Configuration.',
              snackbartop: true,
              snackbarmultiLine: true
            }
            vm.$store.commit('setCustomProcessingDialogText','Done')
            vm.$store.commit('setCustomProcessingDialog',false)
            vm.$emit('ActivateSnackbar',true,snackbarcontent)
            })
        },
        PrepareStoreEntities(securityRules){
            let vm = this
            let entitieslength = this.StoreEntities.length
            return new Promise(function (resolve, reject) {
                if(entitieslength === 0){
                    resolve(securityRules)
                }
                else{
                    securityRules = securityRules+`
        //STORE Entities Start`
                    vm.StoreEntities.map((entity,entityindex) => {
                        let entityrule = ``
                        let pathstring = entity.id.split('_').join('').toLowerCase()+'store'
                        entityrule = entityrule+`
        match /`+pathstring+`/{document=**} {
            allow read: if true
            }
        match /`+pathstring+`/{inventoryarticleid} {
            allow create: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles')+`
            allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles')+`
            }
        match /`+pathstring+`/{inventoryarticleid}/Warehouses/{storeproductid} {
            allow create: if WarehouseModerator(request.resource.id,'Publish')
            allow update: if WarehouseModerator(resource.id,'Publish')
            }`
                        securityRules = securityRules+``+entityrule
                        if(entityindex-1+2 === entitieslength){
                        securityRules = securityRules+`
        // STORE Entities End
        `
                        resolve(securityRules)
                        }
                    })
                }
            })
        },
        PrepareEntitiesWiki(securityRules,Type){
            let vm = this
            let entitieslist = []
            let suffix = ''            
            if(Type === 'Classified Listings'){
                entitieslist = vm.ComputedSystemEntities.filter(entity => {
                    return entity.Entity_Type && entity.Entity_Type.Name === 'Classified Listing'
                })
                suffix = 'listings'
            }
            else if(Type === 'Wiki'){
                entitieslist = vm.ComputedSystemEntities.filter(entity => {
                    return entity.Has_Wiki
                })
                //console.log(entitieslist)
                suffix = 'wiki'
            }
            let entitieslength = entitieslist.length
            //alert(Type + entitieslength)
            return new Promise(function (resolve, reject) {
                if(entitieslength === 0){
                    resolve(securityRules)
                }
                else{
                    securityRules = securityRules+`
        //`+Type+` Entities Start`
                    entitieslist.map((entity,entityindex) => {
                        let typestring = Type
                        let entityrule = ``
                        if(Type === 'Classified Listings'){
                        typestring = entity.id.split('_').join(' ')+' '+Type
                        }
                        //space here for future consideration of "ClientWiki"
            //             <span v-if="build.IsClientWiki">
            //also PublishType or PublishStatus??
            // allow read: if useroles().hasAny(['EagleView']) || existingData().PublishStatus == ('Published (Public)') || isSignedIn() && IsGuestUser() == false || IsGuestUser() && existingData().{{build.ClientLookupField.Name}}id == request.auth.uid || GlobalItemModerator('Wiki')</span>
        entityrule = entityrule+`
        match /`+entity.id.split('_').join('').toLowerCase()+suffix+`/{`+entity.SingleName.split(' ').join('').toLowerCase()+`id} {
            allow create: if useroles().hasAny(['System Admin']) || GlobalItemModerator('`+typestring+`')
            allow update: if useroles().hasAny(['System Admin']) || GlobalItemModerator('`+typestring+`')
            allow read: if resource.data.PublishType == 'Public' || isSignedIn()
            }`
            securityRules = securityRules+``+entityrule
            if(entityindex-1+2 === entitieslength){
                        securityRules = securityRules+`
        //`+Type+` Entities End
        `
                        resolve(securityRules)                    
                    }
                    })
                }
            })
        },
        PrepareEntitiesSTD(securityRules){
            let vm = this
            let entitieslength = vm.ComputedSystemEntities.length
            securityRules = securityRules+`

        //STD Entities Start`
            return new Promise(function (resolve, reject) {
            if(entitieslength === 0){
                resolve(securityRules)
            }
            else{
                let BuildswithBUSecurity = vm.ComputedSystemEntities.filter(entity => {return entity.datasetsecuritystructure === 'Business Unit'})
            //console.log(entitieslength)
            vm.ComputedSystemEntities
            .map((entity,entityindex) => {
                entity.Cabinets = entity.DocumentRegisters.concat(entity.Galleries)
                let entityrule = ``             
                    if(entity.datasetsecuritystructure === 'Warehouse' && entity.id !== 'Business_Units' && entity.id !== 'Users'){                    
                        entityrule = `
        match /`+entity.id.split('_').join('').toLowerCase()+`/{`+entity.SingleName.split(' ').join('').toLowerCase()+`id} {  
            allow create: if WarehouseModerator(incomingData().Warehouseid,'Create')
            allow get: if WarehouseModerator(existingData().Warehouseid,'Get') || useroles().hasAny(['EagleView'])
            allow list: if WarehouseModerator(existingData().Warehouseid,'List')|| useroles().hasAny(['EagleView'])
            allow update: if WarehouseModerator(existingData().Warehouseid,'Update')
            allow delete: if WarehouseModerator(existingData().Warehouseid,'Delete')
                match /processes/{processid} {
                    allow create: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow get: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Get') || useroles().hasAny(['EagleView'])
                    allow list: if  WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'List') || useroles().hasAny(['EagleView'])
                    allow update: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow delete: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                }
                match /InternalLiaison/{InternalLiaisonid} {
                    allow create: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow get: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Get') || useroles().hasAny(['EagleView'])
                    allow list: if  WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'List') || useroles().hasAny(['EagleView'])
                    allow update: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow delete: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                }
            `
            if(entity.SubCollections){
                entity.SubCollections.map(subcol => {
                    entityrule = entityrule+`
                match /`+subcol.id+`/{document=**} {
                    allow create: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow get: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Get') || useroles().hasAny(['EagleView'])
                    allow list: if  WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'List') || useroles().hasAny(['EagleView'])
                    allow update: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                    allow delete: if WarehouseModerator(GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Warehouseid,'Update')
                }`
                })
            }
            if(entity.Cabinets){
                entity.Cabinets.map(section => {
                    entityrule = entityrule+`
                match /`+section.id.split('_').join('').toLowerCase()+`/{`+section.id.split('_').join('').toLowerCase()+`docid} {
                allow create: if `+vm.ReturnRMCustomPermission(section,'CreatePermission','CustomcreateBoolean','CustomCreateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow get: if `+vm.ReturnRMCustomPermission(section,'GetPermission','CustomgetBoolean','CustomGeteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow list: if `+vm.ReturnRMCustomPermission(section,'ListPermission','CustomlistBoolean','CustomListRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow update: if `+vm.ReturnRMCustomPermission(section,'UpdatePermission','CustomupdateBoolean','CustomUpdateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow delete: if `+vm.ReturnRMCustomPermission(section,'DeletePermission','CustomdeleteBoolean','CustomDeleteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                }`
                })
            }
            entityrule = entityrule+
            `
        }
        `
                        //console.log(entityrule)
                    }
                    else if(entity.datasetsecuritystructure === 'Custom Roles' && entity.id !== 'Business_Units' && entity.id !== 'Users'){                    
                        entityrule = `
        match /`+entity.id.split('_').join('').toLowerCase()+`/{`+entity.SingleName.split(' ').join('').toLowerCase()+`id} {  
            allow create: if `+vm.GetCustomRolesPermissions(entity,'CreatePermission','CustomcreateBoolean','CustomCreateRoles')+`
            allow get: if `+vm.GetCustomRolesPermissions(entity,'GetPermission','CustomgetBoolean','CustomGetRoles')+` || useroles().hasAny(['EagleView'])
            allow list: if `+vm.GetCustomRolesPermissions(entity,'ListPermission','CustomlistBoolean','CustomListRoles')+` || useroles().hasAny(['EagleView'])
            allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles')+`
            allow delete: if `+vm.GetCustomRolesPermissions(entity,'DeletePermission','CustomdeleteBoolean','CustomDeleteRoles')+`
                match /processes/{processid} {
                    allow create: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                    allow get: if `+vm.GetCustomRolesPermissions(entity,'GetPermission','CustomgetBoolean','CustomGetRoles',true)+` || useroles().hasAny(['EagleView'])
                    allow list: if `+vm.GetCustomRolesPermissions(entity,'ListPermission','CustomlistBoolean','CustomListRoles',true)+` || useroles().hasAny(['EagleView'])
                    allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                    allow delete: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                }
                match /InternalLiaison/{InternalLiaisonid} {
                    allow create: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                    allow get: if `+vm.GetCustomRolesPermissions(entity,'GetPermission','CustomgetBoolean','CustomGetRoles',true)+` || useroles().hasAny(['EagleView'])
                    allow list: if `+vm.GetCustomRolesPermissions(entity,'ListPermission','CustomlistBoolean','CustomListRoles',true)+` || useroles().hasAny(['EagleView'])
                    allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                    allow delete: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                }
            `
            if(entity.SubCollections){
                entity.SubCollections.map(subcol => {
                    entityrule = entityrule+`
                match /`+subcol.id+`/{document=**} {
                allow create: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                allow get: if `+vm.GetCustomRolesPermissions(entity,'GetPermission','CustomgetBoolean','CustomGetRoles',true)+` || useroles().hasAny(['EagleView'])
                allow list: if `+vm.GetCustomRolesPermissions(entity,'GetPermission','CustomgetBoolean','CustomGetRoles',true)+` || useroles().hasAny(['EagleView'])
                allow update: if `+vm.GetCustomRolesPermissions(entity,'EditPermission','CustomupdateBoolean','CustomUpdateRoles',true)+`
                allow delete: if `+vm.GetCustomRolesPermissions(entity,'DeletePermission','CustomdeleteBoolean','CustomDeleteRoles',true)+`
                }`
                })
            }
            if(entity.Cabinets){
                entity.Cabinets.map(section => {
                    entityrule = entityrule+`
                match /`+section.id.split('_').join('').toLowerCase()+`/{`+section.id.split('_').join('').toLowerCase()+`docid} {
                allow create: if `+vm.ReturnRMCustomPermission(section,'CreatePermission','CustomcreateBoolean','CustomCreateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow get: if `+vm.ReturnRMCustomPermission(section,'GetPermission','CustomgetBoolean','CustomGeteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow list: if `+vm.ReturnRMCustomPermission(section,'ListPermission','CustomlistBoolean','CustomListRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow update: if `+vm.ReturnRMCustomPermission(section,'UpdatePermission','CustomupdateBoolean','CustomUpdateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow delete: if `+vm.ReturnRMCustomPermission(section,'DeletePermission','CustomdeleteBoolean','CustomDeleteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                }`
                })
            }
            entityrule = entityrule+
            `
        }
        `
                        //console.log(entityrule)
                        //okay stop. if entity type is Billing Account, then subcol of credits and crap
                    }
                    else if(entity.datasetsecuritystructure === 'Business Unit' && entity.id !== 'Activities'){                    
                        entityrule = `
        match /`+entity.id.split('_').join('').toLowerCase()+`/{`+entity.SingleName.split(' ').join('').toLowerCase()+`id} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || `+vm.NewBURules('Create',entity)+`
            allow get: if useroles().hasAny(['SystemAdmin']) || `+vm.NewBURules('Get',entity)+` || useroles().hasAny(['EagleView'])
            allow list: if useroles().hasAny(['SystemAdmin']) ||  `+vm.NewBURules('List',entity)+` || useroles().hasAny(['EagleView'])
            allow update: if useroles().hasAny(['SystemAdmin']) || `+vm.NewBURules('Update',entity)+`
            allow delete: if useroles().hasAny(['SystemAdmin']) || `+vm.NewBURules('Delete',entity)+`
            match /processes/{processid} {
                    allow create: if EntityCreatePass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                    allow get: if useroles().hasAny(['SystemAdmin']) || EntityGetBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || useroles().hasAny(['EagleView'])
                    allow list: if useroles().hasAny(['SystemAdmin']) || EntityListBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || useroles().hasAny(['EagleView'])
                    allow update: if useroles().hasAny(['SystemAdmin']) || EntityUpdateBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                    allow delete: if useroles().hasAny(['SystemAdmin']) || EntityDeleteBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                }
            match /InternalLiaison/{InternalLiaisonid} {
                    allow create: if EntityCreatePass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                    allow get: if useroles().hasAny(['SystemAdmin']) || EntityGetBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || useroles().hasAny(['EagleView'])
                    allow list: if useroles().hasAny(['SystemAdmin']) || EntityListBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || useroles().hasAny(['EagleView'])
                    allow update: if useroles().hasAny(['SystemAdmin']) || EntityUpdateBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                    allow delete: if useroles().hasAny(['SystemAdmin']) || EntityDeleteBUPass('`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) && ParentUserTableFilterKeysPass('UserFilter`+entity.id+`','`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id)
                }
            `
            if(entity.SubCollections){
                entity.SubCollections.map(subcol => {
                    entityrule = entityrule+`
                match /`+subcol.id+`/{document=**} {
                allow create: if `+vm.GetBUPermissions(entity,entityindex,BuildswithBUSecurity.length,'AllupdateRoles','UnitDownupdateRoles','UserUnitupdateRoles','OwnerupdateRoles',true)+`
                allow get: if `+vm.GetBUPermissions(entity,entityindex,BuildswithBUSecurity.length,'AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles',true)+` || useroles().hasAny(['EagleView'])
                allow list: if `+vm.GetBUPermissions(entity,entityindex,BuildswithBUSecurity.length,'AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles',true)+` || useroles().hasAny(['EagleView'])
                allow update: if `+vm.GetBUPermissions(entity,entityindex,BuildswithBUSecurity.length,'AllupdateRoles','UnitDownupdateRoles','UserUnitupdateRoles','OwnerupdateRoles',true)+`
                allow delete: if `+vm.GetBUPermissions(entity,entityindex,BuildswithBUSecurity.length,'AlldeleteRoles','UnitDowndeleteRoles','UserUnitdeleteRoles','OwnerdeleteRoles',true)+`
                }`
                })
            }
            if(entity.Cabinets){
                entity.Cabinets.map(section => {
                    entityrule = entityrule+`
                match /`+section.id.split('_').join('').toLowerCase()+`/{`+section.id.split('_').join('').toLowerCase()+`docid} {
                allow create: if `+vm.ReturnRMCustomPermission(section,'CreatePermission','CustomcreateBoolean','CustomCreateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow get: if `+vm.ReturnRMCustomPermission(section,'GetPermission','CustomgetBoolean','CustomGeteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow list: if `+vm.ReturnRMCustomPermission(section,'ListPermission','CustomlistBoolean','CustomListRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+` || useroles().hasAny(['EagleView'])
                allow update: if `+vm.ReturnRMCustomPermission(section,'UpdatePermission','CustomupdateBoolean','CustomUpdateRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                allow delete: if `+vm.ReturnRMCustomPermission(section,'DeletePermission','CustomdeleteBoolean','CustomDeleteRoles',entity.SingleName.split(' ').join('').toLowerCase()+'id',entity,entityindex)+`
                }`
                })
            }
            if(entity.Entity_Type && entity.Entity_Type.Name === 'Billing Account'){
                entityrule = entityrule+`
                match /subscriptions/{subscriptionid} {
                    allow get: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /contentpurchases/{tokenpurchaseid} {
                    allow get: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /credittokens/{tokenpurchaseid} {
                    allow get: if existingData().Userid == request.auth.uid || GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if existingData().Userid == request.auth.uid || GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /credits/{creditid} {
                    allow get: if GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                }
                match /debits/{debitid} {
                    allow get: if GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id).Userid == request.auth.uid || UserisParentOwner('`+entity.id.split('_').join('').toLowerCase()+`',`+entity.SingleName.split(' ').join('').toLowerCase()+`id) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                }`
            }
            entityrule = entityrule+
            `
        }
        `
                        //console.log(entityrule)
                    }
                    securityRules = securityRules+``+entityrule
                    if(entityindex-1+2 === entitieslength){
                        securityRules = securityRules+`
        //STD Entities End
        `
                        resolve(securityRules)                    
                    }
                })                
            }

            })
        },
        ClosingArguments(securityRules){
            let vm = this
            //Do not get confused here. NonGuestSocial && GuestSocial simply disappears where SocialReadPass occurs. 
        securityRules = securityRules+`
        /// Function ///
        function SocialReadPass(channeltype){
            return GuestSocial() && ActiveMemberGroup() == null || NonGuestSocial() && ActiveMemberGroup() == null ||            
            MemberGroupCanSocial() && MemberGroupExistingSocialPass(channeltype)
        }
        function MemberGroupExistingSocialPass(channeltype){
            return ActiveMemberGroup().SocialRestrictions == false ||
            ActiveMemberGroup().SocialRestrictions && ActiveMemberGroup().SocialChannels.hasAny([channeltype]) && ActiveMemberGroup().CompanySocial == false ||
            ActiveMemberGroup().SocialRestrictions && ActiveMemberGroup().SocialChannels.hasAny([channeltype]) && ActiveMemberGroup().CompanySocial && existingData().Companyid == MemberCompany()
        }
        function MemberGroupIncomingSocialPass(channeltype){
            return ActiveMemberGroup().SocialRestrictions == false ||
            ActiveMemberGroup().SocialRestrictions && ActiveMemberGroup().SocialChannels.hasAny([channeltype]) && ActiveMemberGroup().CompanySocial == false ||
            ActiveMemberGroup().SocialRestrictions && ActiveMemberGroup().SocialChannels.hasAny([channeltype]) && ActiveMemberGroup().CompanySocial && incomingData().Companyid == MemberCompany()
        }
        function MemberCompany(){
            return getUserRoleData().Companyid
        }
        function MemberGroupCanSocial(){
            return ActiveMemberGroup().CanSocial
        }
        function ActiveMemberGroup(){
            return getUserRoleData().MemberGroup
        }
        function EntityCreatePass(entityid,entitycollection,recordid){
            return EntitySecuritybyBU(entityid) && EntityCreateBUPass(entityid,entitycollection,recordid)
            // to follow || EntitySecuritybyCR(entityid) && EntityCreateCRPass(entityid)
        }
        function EntityCreateBUPass(entityid,entitycollection,recordid){
            return EntityCreateALLBUPass(entityid,entitycollection,recordid) || EntityCreateUNITDOWNBUPass(entityid,entitycollection,recordid) || EntityCreateUSERUNITBUPass(entityid,entitycollection,recordid) || EntityCreateOWNERBUPass(entityid,entitycollection,recordid)
        }
        function EntityCreateALLBUPass(entityid,entitycollection,recordid){
            return GetEntityData(entityid).AllcreateRoles.hasAny(useroles())
        }
        function EntityCreateUNITDOWNBUPass(entityid,entitycollection,recordid){
            return NEWRecordWithinBusinessUnitStructure() && GetEntityData(entityid).UnitDowncreateRoles.hasAny(useroles())
        }
        function EntityCreateUSERUNITBUPass(entityid,entitycollection,recordid){
            return NEWRecordBusinessunit() && GetEntityData(entityid).UserUnitcreateRoles.hasAny(useroles())
        }
        function EntityCreateOWNERBUPass(entityid,entitycollection,recordid){
            return UserisNEWrecordOwner() && GetEntityData(entityid).OwnercreateRoles.hasAny(useroles())
        }
        function EntityGetBUPass(entityid,entitycollection,recordid){
            return EntityGetALLBUPass(entityid,entitycollection,recordid) || EntityGetUNITDOWNBUPass(entityid,entitycollection,recordid) || EntityGetUSERUNITBUPass(entityid,entitycollection,recordid) || EntityGetOWNERBUPass(entityid,entitycollection,recordid)
        }
        function EntityGetALLBUPass(entityid,entitycollection,recordid){
            return GetEntityData(entityid).AllgetRoles.hasAny(useroles())
        }
        function EntityGetUNITDOWNBUPass(entityid,entitycollection,recordid){
            return RecordWithinBusinessUnitStructure() && GetEntityData(entityid).UnitDowngetRoles.hasAny(useroles())
            || ParentRecordWithinBusinessUnitStructure(entitycollection,recordid) && GetEntityData(entityid).UnitDowngetRoles.hasAny(useroles())
        }
        function EntityGetUSERUNITBUPass(entityid,entitycollection,recordid){
            return RecordInUserBusinessUnit() && GetEntityData(entityid).UserUnitgetRoles.hasAny(useroles())
            || ParentRecordInUserBusinessUnit(entitycollection,recordid) && GetEntityData(entityid).UserUnitgetRoles.hasAny(useroles())
        }
        function EntityGetOWNERBUPass(entityid,entitycollection,recordid){
            return UserisOwner() && GetEntityData(entityid).OwnergetRoles.hasAny(useroles())
            || UserisParentOwner(entitycollection,recordid) && GetEntityData(entityid).OwnergetRoles.hasAny(useroles())
        }
        function EntityListBUPass(entityid,entitycollection,recordid){
            return EntityListALLBUPass(entityid,entitycollection,recordid) || EntityListUNITDOWNBUPass(entityid,entitycollection,recordid) || EntityListUSERUNITBUPass(entityid,entitycollection,recordid) || EntityListOWNERBUPass(entityid,entitycollection,recordid)
        }
        function EntityListALLBUPass(entityid,entitycollection,recordid){
            return GetEntityData(entityid).AlllistRoles.hasAny(useroles())
        }
        function EntityListUNITDOWNBUPass(entityid,entitycollection,recordid){
            return RecordWithinBusinessUnitStructure() && GetEntityData(entityid).UnitDownlistRoles.hasAny(useroles())
            || ParentRecordWithinBusinessUnitStructure(entitycollection,recordid) && GetEntityData(entityid).UnitDownlistRoles.hasAny(useroles())
        }
        function EntityListUSERUNITBUPass(entityid,entitycollection,recordid){
            return RecordInUserBusinessUnit() && GetEntityData(entityid).UserUnitlistRoles.hasAny(useroles())
            || ParentRecordInUserBusinessUnit(entitycollection,recordid) && GetEntityData(entityid).UserUnitlistRoles.hasAny(useroles())
        }
        function EntityListOWNERBUPass(entityid,entitycollection,recordid){
            return UserisOwner() && GetEntityData(entityid).OwnerlistRoles.hasAny(useroles())
            || UserisParentOwner(entitycollection,recordid) && GetEntityData(entityid).OwnerlistRoles.hasAny(useroles())
        }
        function EntityUpdateBUPass(entityid,entitycollection,recordid){
            return EntityUpdateALLBUPass(entityid,entitycollection,recordid) || EntityUpdateUNITDOWNBUPass(entityid,entitycollection,recordid) || EntityUpdateUSERUNITBUPass(entityid,entitycollection,recordid) || EntityUpdateOWNERBUPass(entityid,entitycollection,recordid)
        }
        function EntityUpdateALLBUPass(entityid,entitycollection,recordid){
            return GetEntityData(entityid).AllupdateRoles.hasAny(useroles())
        }
        function EntityUpdateUNITDOWNBUPass(entityid,entitycollection,recordid){
            return RecordWithinBusinessUnitStructure() && GetEntityData(entityid).UnitDownupdateRoles.hasAny(useroles())
            || ParentRecordWithinBusinessUnitStructure(entitycollection,recordid) && GetEntityData(entityid).UnitDownupdateRoles.hasAny(useroles())
        }
        function EntityUpdateUSERUNITBUPass(entityid,entitycollection,recordid){
            return RecordInUserBusinessUnit() && GetEntityData(entityid).UserUnitupdateRoles.hasAny(useroles())
            || ParentRecordInUserBusinessUnit(entitycollection,recordid) && GetEntityData(entityid).UserUnitupdateRoles.hasAny(useroles())
        }
        function EntityUpdateOWNERBUPass(entityid,entitycollection,recordid){
            return UserisOwner() && GetEntityData(entityid).OwnerupdateRoles.hasAny(useroles())
            || UserisParentOwner(entitycollection,recordid) && GetEntityData(entityid).OwnerupdateRoles.hasAny(useroles())
        }
        function EntityDeleteBUPass(entityid,entitycollection,recordid){
            return EntityDeleteALLBUPass(entityid,entitycollection,recordid) || EntityDeleteUNITDOWNBUPass(entityid,entitycollection,recordid) || EntityDeleteUSERUNITBUPass(entityid,entitycollection,recordid) || EntityDeleteOWNERBUPass(entityid,entitycollection,recordid)
        }
        function EntityDeleteALLBUPass(entityid,entitycollection,recordid){
            return GetEntityData(entityid).AlldeleteRoles.hasAny(useroles())
        }
        function EntityDeleteUNITDOWNBUPass(entityid,entitycollection,recordid){
            return RecordWithinBusinessUnitStructure() && GetEntityData(entityid).UnitDowndeleteRoles.hasAny(useroles())
            || ParentRecordWithinBusinessUnitStructure(entitycollection,recordid) && GetEntityData(entityid).UnitDowndeleteRoles.hasAny(useroles())
        }
        function EntityDeleteUSERUNITBUPass(entityid,entitycollection,recordid){
            return RecordInUserBusinessUnit() && GetEntityData(entityid).UserUnitdeleteRoles.hasAny(useroles())
            || ParentRecordInUserBusinessUnit(entitycollection,recordid) && GetEntityData(entityid).UserUnitdeleteRoles.hasAny(useroles())
        }
        function EntityDeleteOWNERBUPass(entityid,entitycollection,recordid){
            return UserisOwner() && GetEntityData(entityid).OwnerdeleteRoles.hasAny(useroles())
            || UserisParentOwner(entitycollection,recordid) && GetEntityData(entityid).OwnerdeleteRoles.hasAny(useroles())
        }
        function GetEntityData(entityid){
            return get(/databases/$(database)/documents/SystemConfig/`+process.env.VUE_APP_RA_SYSTEM_ID+`/entities/$(entityid)).data
        }
        function EntitySecurityStructure(entityid){
            return GetEntityData(entityid).datasetsecuritystructure
        }
        function EntitySecuritybyBU(entityid){
            return EntitySecurityStructure(entityid) == 'Business Unit'
        }
        // function EntitySecuritybyCR(entityid){
        //     return EntitySecurityStructure(entityid) == 'Custom Role'
        // }
        function getUserRoleData() {
        return get(/databases/$(database)/documents/userroles/$(request.auth.uid)).data
        }
        function isSignedIn() {
        return request.auth != null
        }
        function UserCanInvite(){
        return getUserRoleData().CanInviteUsers
        }

        function WarehouseModerator(whid,accesstype) {
        return getUserRoleData().Warehouses[whid][accesstype]
        }

        function UserisMonetizationOfficer(){
        return getUserRoleData().MonetizationOfficer
        }
    
        function RecordisUser(){
        return request.auth.uid == resource.id
        }

        function IncomingRecordisUser(){
        return request.resource.id == request.auth.uid
        }

        function existingData() {
        return resource.data
        }

        function incomingData() {
        return request.resource.data
        }

        function useroles(){
        return getUserRoleData().rolesarrayQuery
        }

        function UserTableFilterKeysPass(tableid){
        return (!(tableid in getUserRoleData() )) || resource.data[getUserRoleData()[tableid]] == request.auth.uid
        }

        function ParentUserTableFilterKeysPass(tableid,collectionname,parentid){
        return (!(tableid in getUserRoleData() )) || GetParentRecord(collectionname,parentid)[getUserRoleData()[tableid]] == request.auth.uid
        }

        function IsGuestUser(){
        return getUserRoleData().IsGuestUser
        }

        function IsAccountingClerk(accountingclerkprop,BUid){
        return getUserRoleData().BusinessAccounting[BUid][accountingclerkprop]
        }

        function getModeratordata() {
        return get(/databases/$(database)/documents/sitemoderators/$(request.auth.uid)).data
        }

        function GlobalItemModerator(item){
        return getModeratordata().ModerationPermissions.hasAny([item])
        }

        function usersubordinates(){
        return getUserRoleData().SubordinateArrayDBrules.keys()
        }

        function GetParentRecord(collectionname,parentid){
        return get(/databases/$(database)/documents/$(collectionname)/$(parentid)).data
        }

        // function ActivityRegardingType()
        // return existingData().Regarding_Type
        // }

        function getBusinessUnitData() {
        return get(/databases/$(database)/documents/businessunits/$(getBusinessUnitID())).data
        }

        function getBusinessUnitID() {
        return getUserRoleData().Business_Unitid
        }

        function ParentRecordWithinBusinessUnitStructure(collectionname,parentid){
        return getBusinessUnitData().childarrayDBRules.keys().hasAny([GetParentRecord(collectionname,parentid).Business_Unitid])
        }

        function ParentRecordInUserBusinessUnit(collectionname,parentid){
        return GetParentRecord(collectionname,parentid).Business_Unitid == getBusinessUnitID()
        }

        function UserisParentOwner(collectionname,parentid){
        return ParentRecordOwner(collectionname,parentid) == request.auth.uid
        }

        function ParentRecordOwner(collectionname,parentid){
        return GetParentRecord(collectionname,parentid).Ownerid
        }

        function RecordWithinBusinessUnitStructure(){
        return getBusinessUnitData().childarrayDBRules.keys().hasAny([existingData().Business_Unitid])
        }

        function NEWRecordWithinBusinessUnitStructure(){
        return getBusinessUnitData().childarrayDBRules.keys().hasAny([incomingData().Business_Unitid])
        }

        function RecordBusinessunit(){
        return existingData().Business_Unitid
        }

        function NEWRecordBusinessunit(){
        return incomingData().Business_Unitid
        }

        function RecordOwner(){
        return existingData().Ownerid
        }

        function NEWRecordOwner(){
        return incomingData().Ownerid
        }    

        function RecordCreator(){
        return existingData().CreatorID
        }

        function UserisOwner(){
        return RecordOwner() == request.auth.uid
        }       

        function UserCanImport(){
        return getUserRoleData().CanImport
        }

        function UserisNEWrecordOwner(){
        return NEWRecordOwner() == request.auth.uid
        }   
        function CreatedByUser(){
        return existingData().Created_Byid == request.auth.uid
        }
        function UserisCreator(){
        return RecordCreator() == request.auth.uid
        }
        function UserisFollower(){
        return request.auth.uid in existingData().FollowingUserIDStrings
        }

        function UserisAttendee(){
        return request.auth.uid in existingData().AttendeeUserIDStrings
        }    

        function UserisAttendeeManager(){
        return existingData().AttendeeUserIDStrings.hasAny(usersubordinates())
        } 

        function RecordInUserBusinessUnit(){
        return RecordBusinessunit() == getBusinessUnitID()
        }`
        if(vm.SocialNetworkActive){
            securityRules = securityRules+`
        function ItemModerator(){
        return existingData().moderatorrolesarrayQuery.hasAny([request.auth.uid])
        }
        //function ParentModerator(collectionname,parentid){
        //return GetParentRecord(collectionname,parentid).moderatorrolesarrayQuery.hasAny([request.auth.uid])
        //}              
        function GuestSocial(){
        return IsGuestUser() && GuestsCanSocial() && existingData().GuestsIncluded
        }

        function NonGuestSocial(){
        return isSignedIn() && IsGuestUser() == false
        }`
        if(vm.System.Guests_can_Social){
            securityRules = securityRules+`
        function GuestsCanSocial(){
        return true
        }`
        }
        else{
            securityRules = securityRules+`
        function GuestsCanSocial(){
        return false
        }`
        }
        // if(vm.PluginDataBase.Site_Groups){
        securityRules = securityRules+`
        
        function isGroupAdmin(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.Administrator    
        }
        function GroupArticlesGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupArticlesPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupArticlesPrivacy(groupid,'All Site Members') ||
        GroupArticlesPrivacy(groupid,'Public') ||
        GroupArticlesPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupBlogsGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupBlogsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupBlogsPrivacy(groupid,'All Site Members') ||
        GroupBlogsPrivacy(groupid,'Public') ||
        GroupBlogsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupEventsGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupEventsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupEventsPrivacy(groupid,'All Site Members') ||
        GroupEventsPrivacy(groupid,'Public') ||
        GroupEventsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupForumsGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupForumsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupForumsPrivacy(groupid,'All Site Members') ||
        GroupForumsPrivacy(groupid,'Public') ||
        GroupForumsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupMeetupsGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupMeetupsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupMeetupsPrivacy(groupid,'All Site Members') ||
        GroupMeetupsPrivacy(groupid,'Public') ||
        GroupMeetupsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }
        function GroupPollsGuestSocial(groupid){
        return isSignedIn() && IsGuestUser() == false && GroupPollsPrivacy(groupid,'Non Guest Site Members') ||
        isSignedIn() && GroupPollsPrivacy(groupid,'All Site Members') ||
        GroupPollsPrivacy(groupid,'Public') ||
        GroupPollsPrivacy(groupid,'Members Only') && UserisGroupMember(groupid)
        }    
        function isGroupTeamMember(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.IsTeamMember    
        }
        function isGroupCreator(groupid){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.Created_Byid == request.auth.uid    
        }
        function UseronGroupInviteList(groupid){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.Invitees.hasAny([request.auth.uid])    
        }
        function GroupPrivacyType(groupid,item){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.WhocanJoin == item  
        }
        function UserisGroupMember(groupid){
        return get(/databases/$(database)/documents/groupmembers/$(request.auth.uid)/groups/$(groupid)).data.Status == 'Active'   
        }
        function GroupArticlesModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.ArticlesModerator
        }
        function GroupBlogsModerator(userid,groupid){      
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.BlogsModerator
        }
        function GroupEventsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.EventsModerator
        }
        function GroupForumsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.ForumsModerator
        }
        function GroupMeetupsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.MeetupsModerator
        }
        function GroupPollsModerator(userid,groupid){
        return get(/databases/$(database)/documents/groupmembers/$(userid)/groups/$(groupid)).data.PollsModerator
        }
        function GroupBlogsPrivacy(groupid,item){
        return get(/databases/$(database)/documents/groups/$(groupid)).data.BlogPrivacy == item
        }
        function GroupArticlesPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.ArticlesPrivacy == item
        }
        function GroupForumsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.ForumPrivacy == item
        }
        function GroupPollsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.PollsPrivacy == item
        }
        function GroupEventsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.EventsPrivacy == item
        }
        function GroupMeetupsPrivacy(groupid,item){
            return get(/databases/$(database)/documents/groups/$(groupid)).data.MeetupsPrivacy == item
        }
        function BasicPermissionsOnly(){
        return incomingData().Administrator != true && 
        incomingData().ForumsModerator != true  && 
        incomingData().MeetupsModerator != true  && 
        incomingData().BlogsModerator != true  && 
        incomingData().EventsModerator != true && 
        incomingData().ArticlesModerator != true && 
        incomingData().PollsModerator != true
        }
        function ExistingBasicPermissionsOnly(){
        return existingData().Administrator != true && 
        existingData().ForumsModerator != true  && 
        existingData().MeetupsModerator != true  && 
        existingData().BlogsModerator != true  && 
        existingData().EventsModerator != true && 
        existingData().ArticlesModerator != true && 
        existingData().PollsModerator != true
        }  
        `        
            // }
        }

        securityRules = securityRules+
`        
    }
}`
//STD

//getBusinessUnitData() STD CONFIG
//UserisOwner STD TOOLS
//UserisCreator STD TOOLS (activities)
//RecordOwner STD TOOLS (activities)
//UserCanImport STD TOOLS (imports)

//ItemModerator if has socialcomp

//if groups...
//isGroupAdmin if groups 
//UserisGroupMember if groups
//GroupEventsModerator if groups
//GroupPollsModerator
//GroupBlogsModerator
//GroupArticlesModerator
//UseronGroupInviteList
//GroupForumsModerator


//** featuredgroupmembers only if groups

            //console.log(securityRules)
            
            this.FinalPublish(securityRules)
        },
        PrepareSecurityRules(){
            let vm = this
            vm.$store.commit('setCustomProcessingDialog',true)
            vm.$store.commit('setCustomProcessingDialogText','Preparing Config')
            let securityRules = `rules_version = '2';
    service cloud.firestore {
    match /databases/{database}/documents {
        match /{document=**} {
        allow read, write: if useroles().hasAny(['System Admin']);
        }

        //System Config Start

        match /SystemConfig/{systemid} {  
        allow get: if systemid == 'ConfigMaster' && isSignedIn() || systemid == '`+this.System.id+`'
        allow list: if isSignedIn()
        allow update: if useroles().hasAny(['System Admin'])
        allow create: if useroles().hasAny(['System Admin'])
        match /SubscriptionPackages/{packid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            }
        match /ArticlesCategories/{catid} {  
            allow get: if true
            allow list: if true
            allow update: if isSignedIn() && GlobalItemModerator('Articles')
            allow create: if isSignedIn() && GlobalItemModerator('Articles')
            }
        match /BlogsCategories/{catid} {  
            allow get: if true
            allow list: if true
            allow update: if isSignedIn() && GlobalItemModerator('Blogs')
            allow create: if isSignedIn() && GlobalItemModerator('Blogs')
            }
        match /MeetupsCategories/{catid} {  
            allow get: if true
            allow list: if true
            allow update: if isSignedIn() && GlobalItemModerator('Meetups')
            allow create: if isSignedIn() && GlobalItemModerator('Meetups')
            }
        match /NoticesCategories/{catid} {  
            allow get: if true
            allow list: if true
            allow update: if isSignedIn() && GlobalItemModerator('Notices')
            allow create: if isSignedIn() && GlobalItemModerator('Notices')
            }
        match /PollsCategories/{catid} {  
            allow get: if true
            allow list: if true
            allow update: if isSignedIn() && GlobalItemModerator('Polls')
            allow create: if isSignedIn() && GlobalItemModerator('Polls')
            } 
        match /Pages/{pageid} {  
            allow get: if resource.data.PublishType == 'Public' || isSignedIn()
            allow list: if resource.data.PublishType == 'Public' || isSignedIn()
            allow update: if useroles().hasAny(['System Admin'])
            allow create: if useroles().hasAny(['System Admin'])
            }   
        match /DocumentationLibraries/{doclibid} {  
            allow get: if resource.data.PublishType == 'Public' || isSignedIn()
            allow list: if resource.data.PublishType == 'Public' || isSignedIn()
            allow update: if useroles().hasAny(['System Admin'])
            allow create: if useroles().hasAny(['System Admin'])
            match /Pages/{doclibpaged} {  
                allow get: if resource.data.PublishType == 'Public' || isSignedIn()
                allow list: if resource.data.PublishType == 'Public' || isSignedIn()
                allow update: if useroles().hasAny(['System Admin'])
                allow create: if useroles().hasAny(['System Admin'])
                }
            }  
        match /BUsecurityroles/{document=**} {  
            allow get: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow list: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /Customsecurityroles/{document=**} {  
            allow get: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow list: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
      	match /RMsecurityroles/{document=**} {  
            allow get: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow list: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanInvite();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }            
        match /entities/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /SystemTableFilters/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /DBNavlists/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /LiaisonTopics/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /CategoricalNavlists/{document=**} {  
            allow get: if true;
            allow list: if true;
            allow update:  if useroles().hasAny(['System Admin']) || GlobalItemModerator(resource.id.split('_').join(' ')+' Classified Listings')
            allow create:  if useroles().hasAny(['System Admin']) || GlobalItemModerator(request.resource.id.split('_').join(' ')+' Classified Listings')
            }
        match /PhotoLibraries/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
         match /DocumentLibraries/{document=**} {  
            allow get: if isSignedIn();
            allow list: if isSignedIn();
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            } 
        match /classifiedentities/{classifiedentityid} {  
            allow get:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow list:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow update:  if useroles().hasAny(['System Admin']) || GlobalItemModerator(classifiedentityid.split('_').join(' ')+' Classified Listings')
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /wikientities/{wikientityid} {  
            allow get:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow list:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /ClientWikiTables/{clientwikitableid} {
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update:  if useroles().hasAny(['SystemAdmin'])
            allow create:  if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            }
        match /LiasonTopics/{liasontopicid} {
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update:  if useroles().hasAny(['SystemAdmin'])
            allow create:  if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            }
        }
        match /systemguides/{systemguideid} {  
            allow get:  if isSignedIn()
            allow list:  if isSignedIn()
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            match /carouselitems/{carouselitemid} {
                allow get:  if isSignedIn()
                allow list:  if isSignedIn()
                allow update:  if useroles().hasAny(['System Admin'])
                allow create:  if useroles().hasAny(['System Admin'])
             }            
            }
        match /frequentlyaskedquestions/{faqid} {  
            allow get:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow list:  if resource.data.PublishType == 'Public' || isSignedIn()
            allow update:  if useroles().hasAny(['System Admin'])
            allow create:  if useroles().hasAny(['System Admin'])
            }
        match /friendrequests/{friendrequestid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
            }        
        match /friendships/{friendshipid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
        }
        match /notes/{noteid} {  
            allow create: if isSignedIn() && IsGuestUser() == false
            allow read: if isSignedIn() && IsGuestUser() == false
            allow write: if isSignedIn() && IsGuestUser() == false
        }
             

        //System Config End

        //System Public Start
        match /passwordresets/{passwordresetid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if existingData().Expired == false      
            allow list: if useroles().hasAny(['SystemAdmin'])
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        match /userinvites/{userinviteid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && IsGuestUser() == false && UserCanInvite()|| isSignedIn() && UserCanInvite() && IsGuestUser() && incomingData().IsGuestUser 
            allow get: if existingData().Status.Name != 'Cancelled' && existingData().Expired == false   
            allow list: if useroles().hasAny(['SystemAdmin']) || UserCanInvite() && existingData.Created_Byid == request.auth.uid || UserCanInvite() && GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid
            allow update: if useroles().hasAny(['SystemAdmin']) || UserCanInvite() && existingData.Created_Byid == request.auth.uid && existingData().Status.Name != 'Completed' && existingData().Status.Name != 'Cancelled'
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }`
        if(this.SystemisRA){
            securityRules = securityRules+`
        match /systems/{systemid}/plugins/{pluginid} {  
            allow get: if true      
            allow list: if true
            allow update: if UserisParentOwner('systems',systemid) && incomingData().Active == existingData().Active
            allow create: if UserisParentOwner('systems',systemid)
        }
        match /systems/{systemid}/apps/{document=**} {  
            allow get: if true      
            allow list: if true
            allow update: if UserisParentOwner('systems',systemid) && incomingData().Active == existingData().Active
            allow create: if UserisParentOwner('systems',systemid)
        }
        match /SystemConfig/{systemid}/plugins/{document=**} {  
            allow get: if true      
            allow list: if true
        }
        match /pagepresets/{document=**} {  
            allow get: if true      
            allow list: if true
        }
        match /SystemConfig/{systemid}/apps/{document=**} {  
            allow get: if true      
            allow list: if true
        }`
        }
        securityRules = securityRules+`

        match /businessunits/{businessunitid} { 
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if isSignedIn() && IsGuestUser() == false || existingData().IsPublicLocation || isSignedIn() && IsGuestUser() && getBusinessUnitData().childarrayQuery.hasAny([businessunitid]) || isSignedIn()
            allow list: if isSignedIn() && IsGuestUser() == false || existingData().IsPublicLocation || isSignedIn() && IsGuestUser() && getBusinessUnitData().childarrayQuery.hasAny([businessunitid])
            allow update: if useroles().hasAny(['SystemAdmin']) || getUserRoleData().BusinessProfileAdmin
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /businessmembers/{businessmemberid} {
            allow get: if true;
            allow list: if true;
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /membergroups/{membergroupid} {
            allow get: if ActiveMemberGroup().AdditionalProperties.InviteGroups.hasAny([membergroupid]);
            allow list: if ActiveMemberGroup().AdditionalProperties.InviteGroups.hasAny([membergroupid]);
            allow update: if useroles().hasAny(['SystemAdmin']) || ActiveMemberGroup().ManagesInviteGroups && ActiveMemberGroup().AdditionalProperties.InviteGroups.hasAny([membergroupid])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /bannerads/{banneradid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads') || GlobalItemModerator('Banner Ads') && incomingData().PublishType == 'Members Only'
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads') || GlobalItemModerator('Banner Ads') && existingData().PublishType == 'Members Only'
            allow delete: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Banner Ads') || GlobalItemModerator('Banner Ads') && existingData().PublishType == 'Members Only'
        }
    
        match /landingpages/{landingpageid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages') || GlobalItemModerator('Landing Pages') && incomingData().PublishType == 'Members Only'
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages') || GlobalItemModerator('Landing Pages') && existingData().PublishType == 'Members Only'
            allow delete: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Landing Pages') || GlobalItemModerator('Landing Pages') && existingData().PublishType == 'Members Only'                
        }

        match /systemassets/{systemassetid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if isSignedIn() || existingData().PublishType == 'Public'
            allow list: if isSignedIn() || existingData().PublishType == 'Public'
            allow update: if isSignedIn()
            allow delete: if isSignedIn()                
        }

        match /Warehouses/{warehouseid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow read: if isSignedIn() && IsGuestUser() == false || isSignedIn() && existingData().Guests_Allowed == true || existingData().Is_Public == true
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])            
            
            match /Inbound_Transactions/{Outbound_Transactioniid} {
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
            }
            match /Outbound_Transactions/{Outbound_Transactionid} {
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
            }
            match /PurchaseRequisitions/{PurchaseRequisitionid} {
                allow create: if isSignedIn() && WarehouseModerator(incomingData().Warehouseid,'Requisition')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
            }
            
            match /PurchaseOrders/{PurchaseOrderid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
            }
            match /GoodsReceivingVouchers/{GoodsReceivingVoucherid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
            }
            match /PickingSlips/{PickingSlipid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
            }
            match /PackingLists/{PackingListid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing')
            }
            match /DispatchInstructions/{DispatchInstructionid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
            }
            match /PurchaseRequisitionsLine_Items/{PurchaseRequisitionlineitemid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Requisition') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
            }
            match /PurchaseOrdersLine_Items/{PurchaseOrderlineitemid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Ordering')
            }
            match /GoodsReceivingVouchersLine_Items/{GoodsReceivingVoucherlinteitemid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Receiving')
            }
            match /PickingSlipsLine_Items/{PickingSliplinteitemid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
            }
            match /PackingListsLine_Items/{PackingListlineitemid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Picking')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Packing')
            } 
            match /DispatchInstructionsLine_Items/{DispatchInstructionid} {
                allow create: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
                allow update: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
                allow read: if isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Auditing') || isSignedIn() && WarehouseModerator(existingData().Warehouseid,'Dispatching')
            }
            
        }
        match /subscriptionorders/{subscriptionid} {
            allow get: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow list: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
        }
        match /tokentransactions/{tokentransactionid} {
            allow get: if existingData().Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().CreditedAccountid).Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().DebitedAccountid).Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow list: if existingData().Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().CreditedAccountid).Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().DebitedAccountid).Userid == request.auth.uid || GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow update: if UserisMonetizationOfficer() || IsAccountingClerk('Debtor',existingData().Business_Unitid)
            allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',existingData().BillingAccountid) || UserisMonetizationOfficer() || isGroupAdmin(existingData().Groupid) || IsAccountingClerk('Debtor',existingData().Business_Unitid)
        }
        //System Public End

        
        `
        vm.$store.commit('setCustomProcessingDialogText','Preparing System Tools')
        securityRules = securityRules+this.PrepareSystemTools()
        //if(vm.SocialNetworkActive){
            vm.$store.commit('setCustomProcessingDialogText','Preparing Social Module')
            securityRules = securityRules+this.PrepareSystemSocialBasic()
            securityRules = securityRules+this.PrepareSystemSocialExtended()   
        //}        
        //we ignored "featuredmemebrs re public and "businessmemebrs areprivate
        //also FeaturedMembersPublic and System.Guests_can_Social
        vm.$store.commit('setCustomProcessingDialogText','Preparing Data Tables')
                this.PrepareEntitiesSTD(securityRules).then(stdentitiesresult=> {
                    if(stdentitiesresult){
                     vm.$store.commit('setCustomProcessingDialogText','Preparing Activities')   
                    }                    
                vm.PrepareActivitiesRules(stdentitiesresult).then(activitiesresult=> {
                        securityRules = activitiesresult+``
                        if(activitiesresult){
                           vm.$store.commit('setCustomProcessingDialogText','Preparing Wiki')  
                        }
                       
                        vm.PrepareEntitiesWiki(securityRules,'Wiki').then(wikientitiesresult=> {
                            securityRules = wikientitiesresult+``
                            if(wikientitiesresult){
                            vm.$store.commit('setCustomProcessingDialogText','Preparing Classified Listings')
                            }
                            vm.PrepareEntitiesWiki(securityRules,'Classified Listings').then(clasfdentitiesresult=> {
                                securityRules = clasfdentitiesresult
                                if(clasfdentitiesresult){
                                vm.$store.commit('setCustomProcessingDialogText','Preparing Store Entities')
                                }
                                vm.PrepareStoreEntities(securityRules).then(storeentitiesresult=> {
                                    securityRules = storeentitiesresult
                                    if(storeentitiesresult){
                                    vm.$store.commit('setCustomProcessingDialogText','Uploading to Server')
                                    }
                                    vm.ClosingArguments(securityRules)
                                })
                    
                            })
                        })
                    }) 
                })          
                    
            },
            ActivitiesGetPermisionbyEntity(securityRules){
                let vm = this
                let listlength = vm.EntitiesWithActivities.length
                return new Promise(function (resolve, reject) {
                    vm.EntitiesWithActivities.map((build,index) => {
                        securityRules = securityRules+vm.GetActivitiesPermissions(build,index,listlength,'GetPermission','CustomgetBoolean','CustomGetRoles','AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles')
                        if(index-1+2 === listlength){
                            resolve(securityRules)
                        }
                    })
                })
            },
            ActivitiesListPermisionbyEntity(securityRules){
                let vm = this
                let listlength = vm.EntitiesWithActivities.length
                return new Promise(function (resolve, reject) {
                    vm.EntitiesWithActivities.map((build,index) => {
                        securityRules = securityRules+vm.GetActivitiesPermissions(build,index,listlength,'ListPermission','CustomlistBoolean','CustomListRoles','AlllistRoles','UnitDownlistRoles','UserUnitlistRoles','OwnerlistRoles')
                        if(index-1+2 === listlength){
                            resolve(securityRules)
                        }
                    })  
                })
            },
            PrepareActivitiesRules(securityRules){
                let vm = this
                //we should actually since activiies is an Entity and BU based, add in roles in between "Owner" and "User Unit", something "Owner Manager" which looks like this
                //useroles().hasAny(['INSERT CUSTOM ROLE HERE']) && usersubordinates().hasAny([RecordOwner()])
                return new Promise(function (resolve, reject) {
                // if(vm.EntitiesWithActivities.length === 0){
                securityRules = securityRules+`
        match /activities/{activityid} {
            allow create: if isSignedIn()
            allow read: if existingData() == request.auth.uid || UserisOwner() || UserisFollower()
            || getUserRoleData().ActivitiesRegardingUnitStructure == true && getBusinessUnitData().childarrayDBRules.keys().hasAny([existingData().Regarding_Record.Business_Unitid])
            || getUserRoleData().ActivitiesRegardingUnit == true && existingData().Regarding_Record.Business_Unitid == getBusinessUnitID()
            || getUserRoleData().ActivitiesRegardingOwner == true && existingData().Regarding_Record.Ownerid == request.auth.uid
            || useroles().hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager() || CreatedByUser() || usersubordinates().hasAny([RecordOwner()]) || useroles().hasAny(['SystemAdmin'])
            allow update: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        `
        resolve(securityRules)
        //     }
        //     else{
        //         securityRules = securityRules+`
        // match /activities/{activityid} {
        //     allow create: if isSignedIn()
        //     allow get: if UserisOwner() || UserisFollower() || useroles().hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager() || UserisCreator() || usersubordinates().hasAny([RecordOwner()]) || useroles().hasAny(['SystemAdmin']) || `
        //     vm.ActivitiesGetPermisionbyEntity(securityRules).then(activitiesgetresult => {
        //         securityRules = activitiesgetresult+`
        //     allow list: if UserisOwner() || UserisFollower() || useroles().hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager() || UserisCreator() || usersubordinates().hasAny([RecordOwner()]) || useroles().hasAny(['SystemAdmin']) || `
        //         vm.ActivitiesListPermisionbyEntity(securityRules).then(activitieslistresult => {
        //             securityRules = activitieslistresult+`
        //     allow update: if isSignedIn()
        //     allow delete: if useroles().hasAny(['SystemAdmin'])
        // }
        // `
        //         resolve(securityRules)
        //         })
        //     })
        //     }
        // else{
        //         securityRules = securityRules+`
        // match /activities/{activityid} {
        //     allow create: if isSignedIn()
        //     allow get: if UserisOwner() || UserisFollower() || useroles().hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager() || UserisCreator() || usersubordinates().hasAny([RecordOwner()]) || useroles().hasAny(['SystemAdmin']) || `+vm.GetActivitiesPermissions(build,index,listlength,'GetPermission','CustomgetBoolean','CustomGetRoles','AllgetRoles','UnitDowngetRoles','UserUnitgetRoles','OwnergetRoles')+`
        //     allow list: if UserisOwner() || UserisFollower() || useroles().hasAny(['EagleView']) || UserisAttendee() || UserisAttendeeManager() || UserisCreator() || usersubordinates().hasAny([RecordOwner()]) || useroles().hasAny(['SystemAdmin']) || `+vm.GetActivitiesPermissions(build,index,listlength,'ListPermission','CustomlistBoolean','CustomListRoles','AlllistRoles','UnitDownlistRoles','UserUnitlistRoles','OwnerlistRoles')+`
        //     allow create: if isSignedIn()
        //     allow delete: if useroles().hasAny(['SystemAdmin'])
        // }
        // `
        //         return securityRules
        //     }
                })
            },
            PrepareSystemTools(){
                //ANYWHO LATER POINT AND TIME NEED TO SEPARATE CREDIT BA FROM DEBIT BA, DEBTORS ONLY UPDATE DEBTOR BA, ETC.
                //GetParentRecord('usercompanies',GetParentRecord('billingaccounts',existingData().BillingAccountid).Companyid).Userid == request.auth.uid
                let bauserread = `existingData().Userid == request.auth.uid`
                let bacompuserread = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                let creditclerkusersread = `IsAccountingClerk('Creditor',existingData().Business_Unitid)`
                let debtorclerkusersread = `IsAccountingClerk('Debtor',existingData().Business_Unitid)`
                let baownerprotection = `existingData().Userid == incomingData().Userid && existingData().Account_Type.Name == incomingData().Account_Type.Name`
                let bacreditoverride = `GetParentRecord('usercompanies',existingData().Companyid).Account_Limits_by_Admin`
                let bacreditprotection = `existingData().Credit_Limit == incomingData().Credit_Limit`
                let baprotection = `existingData().Billing_Type.Name == incomingData().Billing_Type.Name`
                let bauser = bauserread+` && `+baownerprotection+` && `+baprotection+` && `+bacreditprotection                
                let bacompuser = bacompuserread+` && `+baownerprotection+` && `+baprotection
                let creditclerkusers = creditclerkusersread+` && `+baownerprotection
                let debtorclerkusers = debtorclerkusersread+` && `+baownerprotection
                
                let baupdateprefix = bauser+` || `+bacompuser+` && `+bacreditprotection+` || `+bacompuser+` && `+bacreditoverride+` || `+creditclerkusers+` || `+debtorclerkusers
                return`

        //System Tools Start
        match /billingaccounts/{billingaccountid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || incomingData().Userid == request.auth.uid || IsAccountingClerk('Creditor',existingData().Business_Unitid) || IsAccountingClerk('Debtor',existingData().Business_Unitid) || EntityCreatePass('Billing_Accounts','billingaccounts',billingaccountid) && UserTableFilterKeysPass('UserFilterBilling_Accounts')
            allow get: if useroles().hasAny(['SystemAdmin']) || `+bauserread+`|| `+bacompuserread+` || `+creditclerkusersread+` || `+debtorclerkusersread+` || UserisOwner() || EntityGetBUPass('Billing_Accounts','billingaccounts',billingaccountid) && UserTableFilterKeysPass('UserFilterBilling_Accounts') || useroles().hasAny(['EagleView'])
            allow list: if useroles().hasAny(['SystemAdmin']) || `+bauserread+`|| `+bacompuserread+` || `+creditclerkusersread+` || `+debtorclerkusersread+` || UserisOwner() || EntityListBUPass('Billing_Accounts','billingaccounts',billingaccountid) && UserTableFilterKeysPass('UserFilterBilling_Accounts') || useroles().hasAny(['EagleView'])
            allow update: if useroles().hasAny(['SystemAdmin']) || `+baupdateprefix+` || EntityUpdateBUPass('Billing_Accounts','billingaccounts',billingaccountid) && UserTableFilterKeysPass('UserFilterBilling_Accounts') && `+baownerprotection+` && `+baprotection+`
            allow delete: if useroles().hasAny(['SystemAdmin']) || EntityDeleteBUPass('Billing_Accounts','billingaccounts',billingaccountid) && UserTableFilterKeysPass('UserFilterBilling_Accounts')
            match /processes/{processid} {
                    allow create: if EntityCreatePass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                    allow get: if useroles().hasAny(['SystemAdmin']) || EntityGetBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid) || useroles().hasAny(['EagleView'])
                    allow list: if useroles().hasAny(['SystemAdmin']) || EntityListBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid) || useroles().hasAny(['EagleView'])
                    allow update: if useroles().hasAny(['SystemAdmin']) || EntityUpdateBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                    allow delete: if useroles().hasAny(['SystemAdmin']) || EntityDeleteBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                }
            match /InternalLiaison/{InternalLiaisonid} {
                    allow create: if EntityCreatePass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                    allow get: if useroles().hasAny(['SystemAdmin']) || EntityGetBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid) || useroles().hasAny(['EagleView'])
                    allow list: if useroles().hasAny(['SystemAdmin']) || EntityListBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid) || useroles().hasAny(['EagleView'])
                    allow update: if useroles().hasAny(['SystemAdmin']) || EntityUpdateBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                    allow delete: if useroles().hasAny(['SystemAdmin']) || EntityDeleteBUPass('Billing_Accounts','billingaccounts',billingaccountid) && ParentUserTableFilterKeysPass('UserFilterBilling_Accounts','billingaccounts',billingaccountid)
                }
            
                match /subscriptions/{subscriptionid} {
                    allow get: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /contentpurchases/{tokenpurchaseid} {
                    allow get: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /credittokens/{tokenpurchaseid} {
                    allow get: if existingData().Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if existingData().Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow update: if existingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account'
                    allow create:  if incomingData().Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && incomingData().Account_Type.Name == 'Group Account'
                }
                match /credits/{creditid} {
                    allow get: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                }
                match /debits/{debitid} {
                    allow get: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                    allow list: if GetParentRecord('usercompanies',GetParentRecord('billingaccounts',billingaccountid).Companyid).Userid == request.auth.uid || GetParentRecord('billingaccounts',billingaccountid).Userid == request.auth.uid || UserisParentOwner('billingaccounts',billingaccountid) || UserisMonetizationOfficer() && existingData().Account_Type.Name == 'Group Account' || IsAccountingClerk('Debtor',existingData().Business_Unitid)
                }
        }
        match /journalentries/{journentryid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if useroles().hasAny(['EagleView']) || useroles().hasAny(['SystemAdmin'])
            allow list: if useroles().hasAny(['EagleView']) || useroles().hasAny(['SystemAdmin'])
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        match /users/{userroleid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin']) || RecordisUser() &&  !('Market_Accountid' in existingData()) || RecordisUser() && existingData().Market_Accountid && incomingData().Market_Accountid == existingData().Market_Accountid
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /subscriptions/{subscriptionid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow update: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer()
                }
                match /contentpurchases/{tokenpurchaseid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow update: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer()
                }
                match /credittokens/{tokenpurchaseid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow create:  if userroleid == request.auth.uid || UserisMonetizationOfficer()
                }
                match /credits/{creditid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                }
                match /debits/{debitid} {
                    allow get: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                    allow list: if userroleid == request.auth.uid || UserisMonetizationOfficer()
                }
        }

        match /timesheets/{timesheetid} {  
            allow create: if request.resource.data.Userid == request.auth.uid
            allow get: if isSignedIn() && IsGuestUser() == false
            allow list: if isSignedIn() && IsGuestUser() == false
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /siteanalytics/{analyticid} {  
            allow get: if isGroupAdmin(existingData().RecordId) && existingData().RouteEventID == 1015
            allow list: if isGroupAdmin(existingData().RecordId) && existingData().RouteEventID == 1015
        }

        match /userroles/{userroleid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])        
        }

        match /notifications/{notificationid} {
            allow read: if UserisCreator() || UserisOwner() || useroles().hasAny(['SystemAdmin']) || useroles().hasAny(['EagleView'])
            allow write: if UserisCreator() || UserisOwner() || useroles().hasAny(['SystemAdmin'])
            allow create: if isSignedIn() || useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /RMDocuments/{document=**} { 
            allow read,write: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        match /monetizationpaymentschedule/{scheduleid}/{document=**} { 
            allow get: if isGroupAdmin(existingData().Related_Recordid)
            allow list: if isGroupAdmin(existingData().Related_Recordid)
        }
        match /dataimports/{dataimportid} {
            allow get: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && IsGuestUser() == false
            allow list: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && IsGuestUser() == false
            allow create: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && UserCanImport()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin']) || useroles().hasAny(['SystemAdmin'])
        }

        match /customdashboards/{customdashboardid} {      
            allow get,list: if isSignedIn() && request.auth.uid == existingData().Ownerid || isSignedIn() && existingData().WhocanView.hasAny([request.auth.uid]) || isSignedIn() && resource == null
            allow create:  if isSignedIn() && request.auth.uid == incomingData().Ownerid
            allow update: if isSignedIn() && request.auth.uid == existingData().Ownerid || isSignedIn() && existingData().WhocanEdit.hasAny([request.auth.uid])
            allow delete: if isSignedIn() && request.auth.uid == existingData().Ownerid
        }

        match /defaultchatbotresponses/{defaultchatbotresponseid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create:  if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /Responses/{Responseid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles().hasAny(['SystemAdmin'])
                allow create:  if useroles().hasAny(['SystemAdmin'])
                allow delete: if useroles().hasAny(['SystemAdmin'])
            }
        }
        match /chatbotmessages/{chatbotresponseid} {  
            allow get: if useroles().hasAny(['SystemAdmin']) || request.auth.uid == existingData().recipientid || request.auth.uid == existingData().senderid
            allow list: if useroles().hasAny(['SystemAdmin']) || request.auth.uid == existingData().recipientid || request.auth.uid == existingData().senderid
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create:  if true
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /chatbotresponses/{chatbotresponseid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create:  if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /Responses/{Responseid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles().hasAny(['SystemAdmin'])
                allow create:  if useroles().hasAny(['SystemAdmin'])
                allow delete: if useroles().hasAny(['SystemAdmin'])
             }
        }

        match /clientrecords/{clientrecordid} {  
            allow get: if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == existingData().Clientid
            allow list: if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == existingData().Clientid
            allow update: if isSignedIn() && IsGuestUser() == false
            allow create:  if isSignedIn() && IsGuestUser() == false
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        
        match /clientliaison/{clientliaisonid} {  
            allow get: if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == existingData().recipientid || IsGuestUser() && request.auth.uid == existingData().senderid
            allow list: if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == existingData().recipientid || IsGuestUser() && request.auth.uid == existingData().senderid
            allow update: if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == existingData().recipientid
            allow create:  if isSignedIn() && IsGuestUser() == false || IsGuestUser() && request.auth.uid == incomingData().senderid
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
            
        match /goals/{goalid} {
            allow get: if isSignedIn() && IsGuestUser() == false
            allow list: if isSignedIn() && IsGuestUser() == false
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /Targets/{targetid} {
                allow get: if isSignedIn() && IsGuestUser() == false
                allow list: if isSignedIn() && IsGuestUser() == false
                allow update: if useroles().hasAny(['SystemAdmin'])
                allow create: if useroles().hasAny(['SystemAdmin'])
                allow delete: if useroles().hasAny(['SystemAdmin'])
            }
        }

        match /pdfexportfunctions/{pdfexportfunctionid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create:  if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }
        
        //System Tools End
        
        `

            },
            PrepareSystemSocialExtended(){
                let vm = this
                let all = ``
                if(vm.PluginDataBase.Site_Articles && vm.PluginDataBase.Site_Articles.Active){
                    all = all+this.ReturnSystemSocialArticles()
                }
                if(vm.PluginDataBase.Site_Blogs && vm.PluginDataBase.Site_Blogs.Active){
                    all = all+this.ReturnSystemSocialBlogs()
                }
                // if(vm.System.Site_Has_Classifieds){
                    all = all+this.ReturnSystemSocialClassifieds()
                // }
                if(vm.PluginDataBase.Site_Events && vm.PluginDataBase.Site_Events.Active){
                    all = all+this.ReturnSystemSocialEvents()
                }
                if(vm.PluginDataBase.Site_Forums && vm.PluginDataBase.Site_Forums.Active){
                    all = all+this.ReturnSystemSocialForums()
                }
                // if(vm.System.Site_Has_Groups){
                    all = all+this.ReturnSystemSocialGroups()
                // }
                if(vm.PluginDataBase.Site_Meetups && vm.PluginDataBase.Site_Meetups.Active){
                    all = all+this.ReturnSystemSocialMeetups()
                }
                if(vm.PluginDataBase.Site_Polls && vm.PluginDataBase.Site_Polls.Active){
                    all = all+this.ReturnSystemSocialPolls()
                }
                return all
            },
            PrepareSystemSocialBasic(){
            return`

        //System Social Start
        match /sitemoderators/{sitemoderatorid} {  
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow read: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /usermessages/{usermessageid} {  
            allow create: if isSignedIn() && incomingData().senderid == request.auth.uid || isSignedIn() && isGroupTeamMember(incomingData().groupid) 
            allow read: if isSignedIn() && existingData().senderid == request.auth.uid || isSignedIn() && existingData().recipientid == request.auth.uid || isSignedIn() && isGroupTeamMember(existingData().groupid) || isSignedIn() && existingData().IsProductRating == true || existingData().IsGroupRating == true || isSignedIn() && isGroupAdmin(existingData().groupid)
            allow write: if isSignedIn() && existingData().senderid == request.auth.uid || isSignedIn() && existingData().recipientid == request.auth.uid || isSignedIn() && isGroupTeamMember(existingData().groupid) || isSignedIn() && isGroupAdmin(existingData().groupid)
        }
        match /systemactivities/{systemactivityid} {  
        allow create: if isSignedIn()
        allow read: if isSignedIn()
        allow write: if isSignedIn()
        }
        match /notices/{noticeid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices')
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices')
            allow delete: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices')
            match /responses/{responseid} {
                allow get: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices') || responseid == request.auth.uid || useroles().hasAny(['EagleView'])
                allow list: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices') || responseid == request.auth.uid || useroles().hasAny(['EagleView'])
                allow update:  if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices') || responseid == request.auth.uid
                allow create:  if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices') || responseid == request.auth.uid
                allow delete: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Notices')
            }      
        }

        match /friendrequests/{friendrequestid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn()
        }

        match /socialsiteposts/{socialsitepostid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if isSignedIn() && request.auth.uid == existingData().Creatorid || isSignedIn() && isGroupAdmin(existingData().WallID)
        }
        match /socialsiteimages/{socialsiteimageid} {
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        match /sitemaptags/{sitemaptagid} {  
            allow create: if isSignedIn() && request.auth.uid == incomingData().PostOwnerid
            allow read: if isSignedIn()
            allow update: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
            allow delete: if isSignedIn() && request.auth.uid == existingData().Created_Byid || isSignedIn() && request.auth.uid == existingData().PostOwnerid
        }

        match /socialsitelikes/{socialsitelikeid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Likedbyid
        }

        match /socialsitecomments/{socialsitecommentid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        match /socialsitereplies/{socialsitereplyid} {  
        // && !incomingData().GroupID || incomingData().GroupID && UserisGroupMember(incomingData().GroupID)
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow update: if isSignedIn()
            allow delete: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && request.auth.uid == existingData().Creatorid
        }

        match /glossary/{glossaryid} {          
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if GlobalItemModerator('Glossary') || useroles().hasAny(['SystemAdmin'])
            allow create: if GlobalItemModerator('Glossary') || useroles().hasAny(['SystemAdmin'])
            allow delete: if GlobalItemModerator('Glossary') || useroles().hasAny(['SystemAdmin'])
        }

        match /featuredmembers/{featuredmemberid} {
            allow get: if true;
            allow list: if true;
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
        }

        match /featuredgroupmembers/{featuredmemberid} {
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /grouptypes/{grouptypeid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if useroles().hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
                allow create: if useroles().hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
                allow delete: if useroles().hasAny(['SystemAdmin']) || isGroupAdmin(existingData().groupid)
            }
        }

        match /frequentedsitemeetups/{frequentedsitemeetupid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups') || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
            allow write: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups') && ItemModerator() || GroupMeetupsModerator(request.auth.uid,existingData().groupid)
            allow read: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups') || isSignedIn() && GroupMeetupsModerator(request.auth.uid,existingData().groupid)
        }

        //System Social End`
            },
            ReturnSystemSocialArticles(securityRules){
                return `
        match /sitearticles/{sitearticleid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Articles')
            allow update: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Articles') && ItemModerator()
            allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass('Site Articles') || isSignedIn() && GlobalItemModerator('Articles') && SocialReadPass('Site Articles') || existingData().PublishStatus == ('Published (Public)')
        }
        
        match /sitearticleswiki/{sitearticlewikiid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Articles')
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Articles') && ItemModerator()
            allow read: if existingData().PublishStatus == ('Published (Public)') || existingData().PublishStatus == ('Published (Internal)') && isSignedIn()
        }
                `
            },
            ReturnSystemSocialBlogs(securityRules){
                return`
        match /siteblogs/{siteblogid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Blogs')
            allow update: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Blogs') && ItemModerator()
            allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass('Site Blogs') || isSignedIn() && GlobalItemModerator('Blogs') && SocialReadPass('Site Blogs') || existingData().PublishStatus == ('Published (Public)')
        }
        
        match /siteblogswiki/{siteblogwikiid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Blogs')
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Blogs') && ItemModerator()
            allow read: if existingData().PublishStatus == ('Published (Public)') || existingData().PublishStatus == ('Published (Internal)') && isSignedIn()
        }
    `
            },
            ReturnSystemSocialClassifieds(securityRules){
                return`
        match /classifieds/{classifiedid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Classifieds') || isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Classifieds') && request.auth.uid == existingData().Created_Byid
            allow read: if true;
        }

        match /classifiedenquiries/{classifiedenquiryid} {  
            allow create: if incomingData().EnquiryingUserid == request.auth.uid || incomingData().Ownerid == request.auth.uid
            allow update: if existingData().EnquiryingUserid == request.auth.uid || existingData().Ownerid == request.auth.uid
            allow read: if isSignedIn()
        }
    `
            },
            ReturnSystemSocialEvents(securityRules){
                return `
        match /siteevents/{siteeventid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Events')
            allow update: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Events') && ItemModerator()
            allow read: if isSignedIn()
            match /Attending_Users/{Attending_Userid} {
                allow get: if isSignedIn()
                allow list: if NonGuestSocial() || GuestSocial()
                allow update: if GlobalItemModerator('Events') || isSignedIn() && existingData().recordid == request.auth.uid
                allow create:  if GlobalItemModerator('Events') || isSignedIn() && incomingData().recordid == request.auth.uid
                allow delete: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Events') 
                }
        }
    `
            },
            ReturnSystemSocialForums(securityRules){
                return `
        match /siteforumdiscussions/{siteforumid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Forums')
            allow read: if SocialReadPass('Site Forums') || existingData().PublishStatus == ('Published (Public)')
            allow write: if isSignedIn() && UserisOwner()
        }

        match /siteforumcomments/{siteforumcommentid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn() && UserisCreator()
        }
        
        match /siteforumreplies/{siteforumreplyid} {  
            allow create: if isSignedIn()
            allow read: if isSignedIn()
            allow write: if isSignedIn() && UserisCreator()
        }
    `
            },
            
            ReturnSystemSocialGroups(securityRules){
                return `
        match /groups/{groupid}/{document=**} { 
            allow create: if isGroupAdmin(groupid)
            allow get: if isGroupAdmin(groupid) || isGroupTeamMember(groupid) || UserisMonetizationOfficer()
            allow list: if isGroupAdmin(groupid) || isGroupTeamMember(groupid) || UserisMonetizationOfficer()
            allow update: if isGroupAdmin(groupid) && IsGuestUser() == false || UserisMonetizationOfficer() || GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid
        }
        match /groups/{groupid} {       
            allow read: if useroles().hasAny(['EagleView']) || isSignedIn() && existingData().GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && existingData().GroupPrivacy == 'Non Guest Site Members' || existingData().GroupPrivacy == 'Public' || existingData().Invitees.hasAny([request.auth.uid]) || existingData().GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
            allow create: if GlobalItemModerator('Groups') || GetParentRecord('billingaccounts',incomingData().BillingAccountid).Userid == request.auth.uid
            allow delete: if isGroupAdmin(groupid)
            match /adminonly/{adminonlyid} {
                allow create: if isGroupAdmin(groupid)
                allow delete: if isGroupAdmin(groupid)
            }
            match /invites/{inviteid} {
                allow get: if RecordisUser()
                allow list: if RecordisUser()
                allow update: if RecordisUser()
                allow create: if isGroupAdmin(groupid)
                allow delete: if isGroupAdmin(groupid)
            }
            match /pages/{pageid} {
                allow get: if useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && GetParentRecord('groups',groupid).Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow list: if useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && GetParentRecord('groups',groupid).Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow create: if isGroupAdmin(groupid)
                allow update: if isGroupAdmin(groupid)
                allow delete: if isGroupAdmin(groupid)
            }
            match /pageassets/{pageassetid} {
                allow create: if isGroupAdmin(groupid)
                allow delete: if isGroupAdmin(groupid)
            }
            match /entities/{entityid}/datarecords/{document=**} {
                allow update: if isGroupTeamMember(groupid) || isGroupAdmin(groupid)
                allow create: if isGroupAdmin(groupid) || isGroupTeamMember(groupid)
                allow delete: if isGroupAdmin(groupid)
            } 
             match /ArticlesCategories/{catid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow write: if isSignedIn() && GroupArticlesModerator(request.auth.uid,groupid)
                }
            match /BlogsCategories/{catid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow write: if isSignedIn() && GroupBlogsModerator(request.auth.uid,groupid)
                }
            match /MeetupsCategories/{catid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow write: if isSignedIn() && GroupMeetupsModerator(request.auth.uid,groupid)
                }
            match /PollsCategories/{catid} {  
                allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isSignedIn() && GetParentRecord('groups',groupid).GroupPrivacy == 'All Site Members' || isSignedIn() && IsGuestUser() == false && GetParentRecord('groups',groupid).GroupPrivacy == 'Non Guest Site Members' || GetParentRecord('groups',groupid).GroupPrivacy == 'Public' || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && existingData().Invitees.hasAny([request.auth.uid]) || GetParentRecord('groups',groupid).GroupPrivacy == 'Invite Only' && UserisGroupMember(groupid)
                allow write: if isSignedIn() && GroupPollsModerator(request.auth.uid,groupid)
                }   
             match /SubscriptionPackages/{packageid} {  
                allow read: if isSignedIn()
                }            
        }

        match /groupmembers/{groupmemberid} {  
            allow get: if isSignedIn()
            allow list: if isSignedIn()
            allow update: if useroles().hasAny(['SystemAdmin'])
            allow create: if useroles().hasAny(['SystemAdmin'])
            allow delete: if useroles().hasAny(['SystemAdmin'])
            match /groups/{membergroupid} {
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if isGroupAdmin(membergroupid) || isSignedIn() && IsGuestUser() == false && GroupPrivacyType(membergroupid,'Non Guest Site Members') && ExistingBasicPermissionsOnly() && request.auth.uid == groupmemberid || GroupPrivacyType(membergroupid,'All Site Members') && ExistingBasicPermissionsOnly() && request.auth.uid == groupmemberid  || GroupPrivacyType(membergroupid,'Invite Only') && UserisGroupMember(membergroupid) && request.auth.uid == groupmemberid
                allow create:  if isGroupCreator(membergroupid) && request.auth.uid == groupmemberid || isGroupAdmin(membergroupid) || isSignedIn() && IsGuestUser() == false && GroupPrivacyType(membergroupid,'Non Guest Site Members') && BasicPermissionsOnly() && request.auth.uid == groupmemberid || GroupPrivacyType(membergroupid,'All Site Members') && BasicPermissionsOnly() && request.auth.uid == groupmemberid || UseronGroupInviteList(membergroupid) && request.auth.uid == groupmemberid
                allow delete: if isSignedIn()
                match /subscriptions/{subscriptionid} {
                    allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow delete: if UserisMonetizationOfficer()
                }
                match /credits/{creditid} {
                    allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow delete: if UserisMonetizationOfficer()
                }
                match /debits/{debitid} {
                    allow get: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow list: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow update: if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow create:  if groupmemberid == request.auth.uid || UserisMonetizationOfficer() || isGroupAdmin(membergroupid)
                    allow delete: if UserisMonetizationOfficer()
                }
            }
        }

        match /grouparticles/{grouparticleid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupArticlesGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupArticlesGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false || existingData().PublishStatus == 'Published (Public)' || GroupArticlesModerator(request.auth.uid,existingData().groupid)
            allow update: if isGroupAdmin(existingData().groupid) || GroupArticlesModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupArticlesModerator(request.auth.uid,incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }

        match /groupblogs/{groupblogid} {
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupBlogsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupBlogsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false || existingData().PublishStatus == 'Published (Public)' || GroupBlogsModerator(request.auth.uid,incomingData().groupid)
            allow update: if isGroupAdmin(existingData().groupid) || GroupBlogsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupBlogsModerator(request.auth.uid,incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }

        match /groupevents/{groupeventid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupEventsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupEventsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false || existingData().PublishStatus == 'Published (Public)' || GroupEventsModerator(request.auth.uid,existingData().groupid)
            allow update: if isGroupAdmin(existingData().groupid) || GroupEventsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupEventsModerator(request.auth.uid,incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
            match /Attending_Users/{Attending_Userid} {
                allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupEventsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && GetParentRecord('groupevents',groupeventid).GuestsIncluded || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
                allow update: if isGroupAdmin(existingData().groupid) || isSignedIn() && IsGuestUser() == false && GroupEventsPrivacy(existingData().groupid,'Non Guest Site Members') && existingData().recordid == request.auth.uid || isSignedIn() && GroupEventsPrivacy(existingData().groupid,'All Site Members') && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Public') && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Team Members Only') && isGroupTeamMember(existingData().groupid) && existingData().recordid == request.auth.uid || GroupEventsPrivacy(existingData().groupid,'Members Only') && UserisGroupMember(existingData().groupid) && existingData().recordid == request.auth.uid || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
                allow create:  if isGroupAdmin(incomingData().groupid) || isSignedIn() && IsGuestUser() == false && GroupEventsPrivacy(incomingData().groupid,'Non Guest Site Members') && incomingData().recordid == request.auth.uid || isSignedIn() && GroupEventsPrivacy(incomingData().groupid,'All Site Members') && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Public') && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Team Members Only') && isGroupTeamMember(incomingData().groupid) && incomingData().recordid == request.auth.uid || GroupEventsPrivacy(incomingData().groupid,'Members Only') && UserisGroupMember(incomingData().groupid) && incomingData().recordid == request.auth.uid || GroupEventsModerator(request.auth.uid,incomingData().groupid) && incomingData().Ownerid == request.auth.uid
                allow delete: if isGroupAdmin(existingData().groupid) || GroupEventsModerator(request.auth.uid,existingData().groupid) && existingData().Ownerid == request.auth.uid
            }
        }
 
        match /groupforumdiscussions/{groupforumdiscussionid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(existingData().groupid) && existingData().GuestsIncluded || GroupForumsGuestSocial(existingData().groupid) && isSignedIn() && IsGuestUser() == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow update: if isGroupAdmin(existingData().groupid) || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupForumsModerator(request.auth.uid,incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }

        match /groupforumreplies/{groupforumreplyid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(existingData().groupid) && GetParentRecord('groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(existingData().groupid) && isSignedIn() && IsGuestUser() == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Creatorid
            allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }
            
        match /groupforumcomments/{groupforumcommentid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(existingData().groupid) && GetParentRecord('groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(existingData().groupid) && isSignedIn() && IsGuestUser() == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Creatorid
            allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }

        match /groupforumlikes/{groupforumlikeid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupForumsGuestSocial(existingData().groupid) && GetParentRecord('groupforumdiscussions',existingData().forumid).GuestsIncluded || GroupForumsGuestSocial(existingData().groupid) && isSignedIn() && IsGuestUser() == false || GroupForumsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow update: if isGroupAdmin(existingData().groupid) || request.auth.uid == existingData().Likedbyid
            allow create:  if isGroupAdmin(incomingData().groupid) || UserisGroupMember(incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }
                
        match /groupmeetups/{groupmeetupid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupMeetupsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupMeetupsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false || GroupMeetupsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow update: if isGroupAdmin(existingData().groupid) || GroupMeetupsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().authorid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
           match /checkins/{checkinid} { 
                allow create: if IncomingRecordisUser()
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if RecordisUser() || GroupMeetupsModerator(request.auth.uid,incomingData().groupid)
                allow delete:  if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups')
            }
        }
    
        match /grouppolls/{grouppollid} {  
            allow read: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid || useroles().hasAny(['EagleView']) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupPollsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && existingData().GuestsIncluded || GroupPollsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false
            allow write: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Ownerid
            allow create:  if isGroupAdmin(incomingData().groupid) || GroupPollsModerator(request.auth.uid,incomingData().groupid)
        }
            
        match /grouppollvotes/{grouppollvoteid} {  
            allow read: if isGroupAdmin(existingData().groupid) || useroles().hasAny(['EagleView']) || useroles().hasAny(['EagleView']) || isGroupTeamMember(existingData().groupid) || GroupPollsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && GetParentRecord('grouppolls',existingData().Pollid).GuestsIncluded || GroupPollsGuestSocial(existingData().groupid) && existingData().PublishStatus != 'Draft' && isSignedIn() && IsGuestUser() == false || GroupPollsModerator(request.auth.uid,existingData().groupid)
            allow update: if isGroupAdmin(existingData().groupid) || GroupPollsModerator(request.auth.uid,existingData().groupid) && request.auth.uid == existingData().Voterid
            allow create:  if isGroupAdmin(incomingData().groupid) ||  request.auth.uid == incomingData().Voterid && UserisGroupMember(incomingData().groupid)
            allow delete: if isGroupAdmin(existingData().groupid)
        }
                `
            },            
            
            ReturnSystemSocialMeetups(securityRules){
                return`
        match /sitemeetups/{sitemeetupid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups')
            allow update: if useroles().hasAny(['SystemAdmin']) || isSignedIn() && GlobalItemModerator('Meetups') && ItemModerator()
            allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass('Site Meetups') || isSignedIn() && GlobalItemModerator('Meetups') && SocialReadPass('Site Meetups') || existingData().PublishStatus == ('Draft') && ItemModerator() || existingData().PublishStatus == ('Published (Public)')
            match /checkins/{checkinid} { 
                allow create: if IncomingRecordisUser()
                allow get: if isSignedIn()
                allow list: if isSignedIn()
                allow update: if RecordisUser() || ItemModerator()
                allow delete:  if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Meetups')
            }
        }  
        
        match /sitemeetupswiki/{sitemeetupwikiid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Meetups')
            allow update: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Public Meetups') && ItemModerator()
            allow read: if existingData().PublishStatus == ('Published (Public)') || existingData().PublishStatus == ('Published (Internal)') && isSignedIn()
        }
    `
            },
            ReturnSystemSocialPolls(securityRules){
                return `
        match /sitepolls/{sitepollid} {  
            allow create: if useroles().hasAny(['SystemAdmin']) || GlobalItemModerator('Polls')
            allow read: if existingData().PublishStatus != 'Draft' && SocialReadPass('Site Polls') || GlobalItemModerator('Polls') && request.auth.uid == existingData().Ownerid && SocialReadPass('Site Polls') || existingData().PublishStatus == ('Published (Public)')
            allow write: if isSignedIn() && request.auth.uid == existingData().Ownerid
        }

        match /sitepollvotes/{sitepollvoteid} {  
            allow create: if isSignedIn()
            allow read: if NonGuestSocial() && GetParentRecord('sitepolls',existingData().Pollid).PublishStatus != 'Draft' || GuestSocial() && GetParentRecord('sitepolls',existingData().Pollid).PublishStatus != 'Draft' || GetParentRecord('sitepolls',existingData().Pollid).PublishStatus == ('Published (Public)') || GlobalItemModerator('Polls') && request.auth.uid == GetParentRecord('sitepolls',existingData().Pollid).Ownerid
            allow write: if isSignedIn() && request.auth.uid == existingData().Voterid
            allow delete: if isSignedIn() && request.auth.uid == GetParentRecord('sitepolls',existingData().Pollid).Ownerid
        }
`
            },
            GetBUPermissions(build,index,listlength,Allarray,Unitdownarray,UserUnitarray,Ownerarray,subcollection){
                let InBUStructure = ``
                let InBU = ``
                let Owner = ``
                if(Allarray === 'AllcreateRoles'){
                InBUStructure = `NEWRecordWithinBusinessUnitStructure()`
                InBU = `NEWRecordBusinessunit()`
                Owner = `UserisNEWrecordOwner()`
                }
                else{
                InBUStructure = `RecordWithinBusinessUnitStructure()`
                InBU = `RecordInUserBusinessUnit()`
                Owner = `UserisOwner()`
                }        
                if(subcollection){
                let variables = build.DisplayName.split(' ').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id`
                InBUStructure = `ParentRecordWithinBusinessUnitStructure('`+variables+`)`
                InBU = `ParentRecordInUserBusinessUnit('`+variables+`)`
                Owner = `UserisParentOwner('`+variables+`)`
                }
                let listposition = index-1+2
                let separator = ' || '
                if(listposition === listlength){
                separator = ''
                }
                let buildname = build.SingleName.split(' ').join('')
                let Allget = this.ReturnSTDPermissionsforBU(build,Allarray,subcollection,buildname,separator)
                let Unitdownget = this.ReturnSTDPermissionsforBU(build,Unitdownarray,subcollection,buildname,separator)
                let UserUnitget = this.ReturnSTDPermissionsforBU(build,UserUnitarray,subcollection,buildname,separator)
                let Ownerget = this.ReturnSTDPermissionsforBU(build,Ownerarray,subcollection,buildname,separator)
                let prefix = ``
                let andorsuffix = ``
                let permittedsuffix = ``
                //do we extend like build.Entity_Type && build.Entity_Type.Name === 'Store Order' to prevent update of Amount_Invoiced and Amount_Outstanding?

                
                //BUT REALLY WE MADE BA STATIC RENDER, EVENB REMOVE FROM ENTITIES COMPUTATION SO THIS WILL NEVER HIT
                // if(build.Entity_Type && build.Entity_Type.Name === 'Billing Account' && !subcollection){
                //     //this too requires usercompanies interrogate but it HAS TO assume companyid assigned to BA right?
                //     //for transactions though it's gonna be a little harder
                //    if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){
                //     let bauser = `existingData().Userid == request.auth.uid`
                //     let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                //     let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid)`
                //     let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid)`
                //     prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
                //     }
                //     else if(Allarray === 'AllcreateRoles'){
                //     let bauser = `incomingData().Userid == request.auth.uid`
                //     let bacompuser = `GetParentRecord('usercompanies',incomingData().Companyid).Userid == request.auth.uid`
                //     let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid)`
                //     let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid)`
                //     prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
                //     }
                //     else if(Allarray === 'AllupdateRoles'){
                //         //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                //         let baownerprotection = `existingData().Userid == incomingData().Userid`
                //     let baprotection = `existingData().Billing_Type.Name == incomingData().Billing_Type.Name`
                //     let bauser = `existingData().Userid == request.auth.uid && `+baownerprotection+` && `+baprotection
                //     let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid && `+baownerprotection+` && `+baprotection
                //     let creditclerkusers = `IsAccountingClerk('Creditor',existingData().Business_Unitid) && `+baownerprotection
                //     let debtorclerkusers = `IsAccountingClerk('Debtor',existingData().Business_Unitid) && `+baownerprotection
                //     prefix = bauser+` || `+bacompuser+` || `+creditclerkusers+` || `+debtorclerkusers+` || `
                //     }
                // }
                // else 
                if(build.Entity_Type && build.Entity_Type.Name === 'User Company' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update
                   if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){
                    let bauser = `getUserRoleData().Companyid == `+build.SingleName.split(' ').join('').toLowerCase()+`id`
                    let bacompuser = `existingData().Userid == request.auth.uid`
                    prefix = bauser+` || `+bacompuser+` || `
                    }
                    //yet to see what this becomes I believe update should NOT be allowed, only update permission based on std BU roles
                    //yeah on other end how do they update basic info if they cannot change?
                    else if(Allarray === 'AllupdateRoles'){
                    prefix = `existingData().Userid == request.auth.uid || `
                    }
                }
                else if(build.Entity_Type && build.Entity_Type.Name === 'Cash Loan' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update - COULD append with userdata.TrackedEntity
                   if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){
                    let bauser = `getUserRoleData().Companyid == existingData().Companyid`
                    let bacompuser = `existingData().Userid == request.auth.uid`
                    prefix = bauser+` || `+bacompuser+` || `
                    }                    
                }
                else if(build.Entity_Type && build.Entity_Type.Name === 'Store Order' && !subcollection){
                    //Custodians and copmany users can get, while custodians can update - COULD append with userdata.TrackedEntity
                   if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){
                    let bauser = `getUserRoleData().Companyid == existingData().Companyid`
                    let bacompuser = `existingData().Userid == request.auth.uid`
                    prefix = bauser+` || `+bacompuser+` || `
                    }                    
                }
                else if(build.Entity_Type && build.Entity_Type.Name === 'Sales Invoice' && !subcollection ||
                build.Entity_Type && build.Entity_Type.Name === 'Sales Quote' && !subcollection ||
                build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice' && !subcollection){
                    let accountingclerkprop = ''
                    if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice'){
                        accountingclerkprop = 'Creditor'
                    }
                    else{
                        accountingclerkprop = 'Debtor'
                    }
                    //if store order entity we are pleased for now any subcol, later need to limit to cart items subcol only. for now do not want to set it fixed either
                    //also need logic still for "store" who can create (maybe user single we set per inventory article entity)
                    //also need warehouse items who can publish to store..sshould be a WH boolean moderation
                   if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){                    
                    let bauser = `GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid`
                    let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                    let accountinguser = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)`
                    prefix = bauser+` || `+bacompuser+` || `+accountinguser+` || `
                    }
                    else if(Allarray === 'AllcreateRoles'){
                    prefix = `GetParentRecord('billingaccounts',incomingData().BillingAccountid).Userid == request.auth.uid || `
                    }
                    else if(Allarray === 'AllupdateRoles'){
                    //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                    //NO NEVER EVER, ONLY ACCOUNTINGCLERKS CAN UPDATE THESE THANK YOU VERY MUCH!
                    let baprotection = `existingData().BillingAccountid == incomingData().BillingAccountid`
                    //let progressprotection = `existingData().Progress.Name == incomingData().Progress.Name`
                    //let bauser = `existingData().Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    //let bacompuser = `GetParentRecord('usercompanies',GetParentRecord('billingaccounts',existingData().BillingAccountid).Companyid).Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    let accountingusers = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)  && `+baprotection
                    prefix = accountingusers+` || `
                    }
                }
                else if(subcollection && build.Entity_Type && build.Entity_Type.Name === 'Store Order'){
                    let accountingclerkprop = ''
                    if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice'){
                        accountingclerkprop = 'Creditor'
                    }
                    else{
                        accountingclerkprop = 'Debtor'
                    }
                    //if store order entity we are pleased for now any subcol, later need to limit to cart items subcol only. for now do not want to set it fixed either
                    //also need logic still for "store" who can create (maybe user single we set per inventory article entity)
                    //also need warehouse items who can publish to store..sshould be a WH boolean moderation
                   if(Allarray === 'AllgetRoles' || Allarray === 'AlllistRoles'){                    
                    let bauser = `GetParentRecord('billingaccounts',existingData().BillingAccountid).Userid == request.auth.uid`
                    let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid`
                    let accountinguser = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)`
                    prefix = bauser+` || `+bacompuser+` || `+accountinguser+` || `
                    }
                    else if(Allarray === 'AllcreateRoles'){
                    prefix = `GetParentRecord('billingaccounts',incomingData().BillingAccountid).Userid == request.auth.uid || `
                    }
                    else if(Allarray === 'AllupdateRoles'){
                        //I do not htink object match is option it aalways returns fals when mathcing option does it not?
                    let baprotection = `existingData().BillingAccountid == incomingData().BillingAccountid`
                    let progressprotection = `existingData().Progress.Name == incomingData().Progress.Name`
                    let bauser = `existingData().Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    let bacompuser = `GetParentRecord('usercompanies',existingData().Companyid).Userid == request.auth.uid && `+baprotection+` && `+progressprotection
                    let accountingusers = `IsAccountingClerk('`+accountingclerkprop+`',existingData().Business_Unitid)  && `+baprotection
                    prefix = bauser+` || `+bacompuser+` || `+accountingusers+` || `
                    }
                }
                //UNDERSTANDING THE ANDORSFFIX - There are static rules to these types, refer to "prefix". However, there are also dynamic configoptions, dynamic rules for dynamic roles. 
                //These dynamic rules must be appended with the rules safeguarding fields that protect the integrity of the accounting module
                //therefore, STILL perform andorsuffix for these
                //HOWEVER THE MAJOR COMES IN WITH THE CODE WE HAD BEFORE 1) WE RENDER "RULE" TWICE AND 2)WE SEEM TO PLACE STATIC ("PREFIX") RULES IN HERE
                //ESSENTIALLY the question is - can a debtor maintain the debtors of any table, or specific tables only?
                //understand this...these tables are static. salesinvoices is salesinvoices there aren't 2 tables for it. so prefix is enough
                //IN A NUTSHELL - ANDORSUFFIX IS EXCLUSIVE FOR USE ON CUSTOM ROLES, BY EXTENDING CUSTOM ROLES WITH INTEGRITY FIELD PROTECTION
                if(build.Entity_Type && build.Entity_Type.Name === 'Purchase Invoice' && !subcollection){
                    //PurchaseInv - only credit clerk can update Amunt_Paid, Sales Invoice - only debtors clerk, Sales Quote - only debtors clerk
                    if(Allarray === 'AllupdateRoles'){
                        andorsuffix = ` && existingData().Amount_Paid == incomingData().Amount_Paid && existingData().BillingAccountid == incomingData().BillingAccountid`
                        //the issue here is we want logic+limitation || logic+unrestricted (creditor/debtor) etc
                    }                    
                }
                if(build.Entity_Type && build.Entity_Type.Name === 'Sales Invoice' && !subcollection || build.Entity_Type && build.Entity_Type.Name === 'Sales Quote' && !subcollection){
                    if(action === 'Update'){
                        andorsuffix = ` && existingData().Progress.Name == incomingData().Progress.Name && existingData().BillingAccountid == incomingData().BillingAccountid`
                        //the issue here is we want logic+limitation || logic+unrestricted (creditor/debtor) etc
                    }
                }
                let all = Allget
                let unitdown = Unitdownget+` && `+InBUStructure
                let userunit = UserUnitget+` && `+InBU
                let owner = Ownerget+` && `+Owner
                if(andorsuffix){
                    all = all+andorsuffix
                    unitdown = unitdown+andorsuffix
                    userunit = userunit+andorsuffix
                    owner = owner+andorsuffix
                }
                return prefix+all+` || `+unitdown+` || `+userunit+` || `+owner
            },
            ReturnSTDPermissionsforBU(build,rolesarray,subcollection,buildname,separator){
                // console.log(rolesarray,build)
                // console.log(build[rolesarray])
                let roleslist = this.ReturnPermittedRoles(build,rolesarray)
                if(subcollection){
                    return `useroles().hasAny([`+roleslist+`]) && ParentUserTableFilterKeysPass('UserFilter`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id)`    
                }
                else{
                    return `useroles().hasAny([`+roleslist+`]) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
                }
                
            },
            ReturnPermittedRoles(build,rolesarray){
                if(build[rolesarray]){
                return build[rolesarray].map((role,i) => {
                    let separator = ','
                    if(i-1+2 === build[rolesarray].length){
                    separator = ''
                    }
                    return `'`+role+`'`
                })
                }
                else {
                return ``
                }
            },
            GetCustomRolesPermissions(build,permissionname,permissionbooleanname,rolesarrayname,subcollection){
                //console.log(build)
                if(!build[rolesarrayname]){
                    build[rolesarrayname] = []
                }
                if(!build[permissionname]){
                    build[permissionname] = 'Custom Role'
                }
                //console.log(build)
                let buildname = build.SingleName.split(' ').join('')
                
                if(build[permissionname] === 'Public'){
                return `true`
                }
                else if(build[permissionname] === 'Signed In'){
                    return `isSignedIn()`
                }
                else if(build[permissionname] === 'Owner'){
                if(permissionname === 'CreatePermission'){
                    return `UserisNEWrecordOwner()`
                }
                else{
                    return `UserisOwner()`
                }
                
                }
                else if(build[permissionname] === 'Admin'){
                return `useroles().hasAny(['System Admin'])`
                }
                else if(build[permissionname] === 'Custom Role'){
                
                
                return this.ReturnSTDPermissionsforCustomRoles(build,rolesarrayname,subcollection,buildname)
                }
                
                
            },
            ReturnSTDPermissionsforCustomRoles(build,rolesarray,subcollection,buildname){
                // console.log(rolesarray,build)
                // console.log(build[rolesarray])
                let roleslist = this.ReturnPermittedRoles(build,rolesarray)
                if(subcollection){
                    return `useroles().hasAny([`+roleslist+`]) && ParentUserTableFilterKeysPass('UserFilter`+build.id+`','`+build.id.split('_').join('').toLowerCase()+`',`+build.SingleName.split(' ').join('').toLowerCase()+`id)`    
                }
                else{
                    return `useroles().hasAny([`+roleslist+`]) && UserTableFilterKeysPass('UserFilter`+build.id+`')`
                }
            },
            ReturnPermittedRoles(build,rolesarray){
                if(build[rolesarray]){
                return build[rolesarray].map((role,i) => {
                    let separator = ','
                    if(i-1+2 === build[rolesarray].length){
                    separator = ''
                    }
                    return `'`+role+`'`
                })
                }
                else {
                return ``
                }
            },
    }
}
</script>

<style>

</style>