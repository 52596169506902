<template>
  <v-card flat tile class="transparent">
      <LookupSubColDialogComponent v-if="SubColLookupDialogActive" @DeactivateSubColLookupDialog="DeactivateSubColLookupDialog" :MasterTableObj="MasterTableObj"
      :CurrentEntity="CurrentEntity" :TableCollectionRef="TableCollectionRef" :RelatedObj="RelatedObj" :RelatedObjidentifier="CurrentEntity.Primary_Field_Name" :System="System" :SystemEntities="SystemEntities"
      />
       <v-card-title class="mediumoverline">
              Line Items: 
          </v-card-title>    
              <v-list-item >
                  <v-text-field v-model="itemsearch" label="Search" append-icon="mdi-file-search">
                  </v-text-field>
                  <v-spacer>
                  </v-spacer>
                  <!-- <v-rowd
                        align="center"
                    > -->
                      <v-btn v-if="!ParentDisabled && $route.query.TransactionType !== 'DispatchInstructions'" @click="ActivateRelevantdialog()" outlined >                          
                          Add New<v-icon>mdi-plus</v-icon></v-btn>
                  <!-- </v-row> -->
                  </v-list-item>
              <v-list class="basicoutlined background" style="padding:30px;">
                  <v-data-table
                    :headers="ComputedLine_ItemsHeaders"
                    :items="ComputedLine_Items"
                    :single-expand="true"
                    item-key="Item_Name"
                    show-expand v-if="Type === 'SCM'"
                    class="elevation-1"
                >
                    <!-- <template v-slot:top>
                    <v-toolbar flat>
                        <v-toolbar-title>Expandable Table</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-switch
                        v-model="singleExpand"
                        label="Single expand"
                        class="mt-2"
                        ></v-switch>
                    </v-toolbar>
                    </template> -->
                    <template v-slot:[`item.Created_By`]="{ item }">
                        <v-avatar size="40" v-if="item.Created_By.Profile_Photo">
                              <img :src="item.Created_By.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!item.Created_By.Profile_Photo">                               
                                {{item.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{item.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                    </template>
                    <template v-slot:[`item.Created_On`]="{ item }">
                       <v-list-item-avatar style="margin-right: 10px;" size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                            {{item.monthcreated}}
                                <br>
                            {{item.daycreated}}
                    </v-list-item-avatar>
                    </template>
                    <template v-slot:[`item.Stock_Obj`]="{ item }">
                         <v-list-item-content>
                      <v-list-item-title v-if="item[RelatedObjProp]">
                         <v-chip small :to="'/'+item[RelatedObjProp].single.split(' ').join('')+'/'+item[RelatedObjProp].id">
                          {{item[RelatedObjProp].Item_Name}}
                          <!-- Stock Item -->
                          </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item[RelatedObjProp]" class="mx-1">
                          {{item[RelatedObjProp].list.split('_').join(' ')}}
                      </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                    <template v-slot:[`item.Stage`]="{ item }">
                        <v-chip class="white--text" :color="StageColor(item.Stage)" v-if="item.Stage" small :to="'/'+item[RelatedObjProp].single.split(' ').join('')+'/'+item[RelatedObjProp].id">
                                {{item.Stage.Name}}
                          <!-- Stock Item -->
                          </v-chip>
                    </template>
                    <template v-slot:expanded-item="{  item }">
                        <td class="background" :colspan="LineProgresstypesHeaders.length">
                        <v-list-item >
                            <v-list-item-content :class="head.class" style="justify-content: end;" v-for="head in LineProgresstypesHeaders" :key="head.itemObjKey">
                                {{head.text}}
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item >
                            <v-list-item-content :class="head.class" style="justify-content: end;" v-for="head in LineProgresstypesHeaders" :key="head.itemObjKey">
                                {{item[head.value]}}
                            </v-list-item-content>
                        </v-list-item>
                        </td>
                    </template>
                </v-data-table>        
                  <v-virtual-scroll  v-if="Type !== 'SCM'"
              style="overflow-x: hidden;"                  
                    :items="ComputedLine_Items"
                    :item-height="65"
                    :height="ScrollHeight"
                  >
                  <template v-slot:default="{ item }">    
                  <v-list-item dense :style="ListStyle">
                      <v-list-item-action v-if="item.Created_By">
                          <v-avatar size="40" v-if="item.Created_By.Profile_Photo">
                              <img :src="item.Created_By.Profile_Photo" style="object-fit: cover;" >
                            </v-avatar>
                            <v-avatar color="grey" class="white--text ml-0" size="40" v-if="!item.Created_By.Profile_Photo">                               
                                {{item.Created_By.Full_Name.split(' ')[0].substr(0,1)}}{{item.Created_By.Full_Name.split(' ')[1].substr(0,1)}}
                            </v-avatar>
                      <!-- <v-list-item-subtitle class="caption">
                          {{item.Created_On.toDate()}}
                      </v-list-item-subtitle> -->
                      </v-list-item-action>
                      <v-list-item-avatar style="margin-right: 10px;" size="40" class="noticeboarddate primary white--text justify-center" rounded>                        
                            {{item.monthcreated}}
                                <br>
                            {{item.daycreated}}
                    </v-list-item-avatar>
                      <v-list-item-content>
                      <v-list-item-title v-if="item[RelatedObjProp]">
                         <v-chip small :to="'/'+item[RelatedObjProp].single.split(' ').join('')+'/'+item[RelatedObjProp].id">
                          {{item[RelatedObjProp].Item_Name}}
                          <!-- Stock Item -->
                          </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle v-if="item[RelatedObjProp]" class="mx-1">
                          {{item[RelatedObjProp].list.split('_').join(' ')}}
                      </v-list-item-subtitle>
                      </v-list-item-content>
                      
                        <v-list-item-content>
                          {{item.Qty}}
                          </v-list-item-content>
                          <v-list-item-content v-if="item.Unit_Price">
                              {{CurrencyFormatter(item.Unit_Price, $store.state.DefaultCurrency.Currency)}}
                          </v-list-item-content>
                           <v-list-item-content v-if="item.SubTotal">
                               {{CurrencyFormatter(item.SubTotal, $store.state.DefaultCurrency.Currency)}}
                          </v-list-item-content>
                          <!-- <v-list-item-content v-for="header in LineProgresstypesHeaders" :key="header.itemObjKey">
                              {{item[header.value]}}
                          </v-list-item-content> -->
                          <v-list-item-action v-if="item.Stage">
                              <v-chip  small :to="'/'+item[RelatedObjProp].single.split(' ').join('')+'/'+item[RelatedObjProp].id">
                                {{item.StageName}}
                          <!-- Stock Item -->
                          </v-chip>
                          </v-list-item-action>
                          <!-- <v-list-item-content>
                          <v-list-item-action>
                              <v-checkbox v-model="item.Completed">
                              </v-checkbox>
                          </v-list-item-action>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-list-item-action>
                          <v-chip>
                              {{item.Progress.Name}}
                          </v-chip>
                          </v-list-item-action>
                           </v-list-item-content> -->
                           <!-- <v-list-item-content> -->
                           <v-list-item-action>
                               <!-- <v-select chips v-model="item.Progress" item-text="Name" return-object :items="ProgressItems" label="Progress"/>
                          <v-chip @click="UpdateEditingProgress(item)" v-if="!item.EditingProgress">
                              {{item.Progress.Name}}
                          </v-chip> -->
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="ProgressChangeDisabled(item)"
                                 v-if="userLoggedIn.id && item.Progress"
                                >
                        <template v-slot:activator="{ on }">
                           <v-chip v-if="item.Progress" small :color="ProgressColor(item)" class="white--text" v-on="on">
                              {{item.Progress.Name}}
                            </v-chip>
                        </template>
                        <v-list v-if="item.Progress">
                          <div  v-for="status in RelevantProgressItems(item)" :key="status.itemObjKey">
                          <v-menu :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                                :disabled="!UserisModerator"
                                 v-if="userLoggedIn.id"
                                >
                               <template v-slot:activator="{ on }">
                          <v-list-item  :class="status.Class" v-on="on">
                            {{status.Name}}
                          </v-list-item>
                               </template>
                               <v-card>
                                 <v-card-text>
                                 {{status.Warning}}
                                 </v-card-text>
                                 <v-card-actions>
                                   <v-btn @click="UpdateProgress(item,status)">Proceed</v-btn>
                                 </v-card-actions>
                               </v-card>
                           </v-menu>
                           </div>
                        </v-list>
                  </v-menu>  
                          </v-list-item-action>
                           <!-- </v-list-item-content> -->
                  </v-list-item>
                  <v-divider></v-divider>
                  </template>
                  </v-virtual-scroll>
              </v-list>
  </v-card>
</template>
<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import LookupSubColDialogComponent from '@/components/Database/LookupComponentSubCollection'

export default {
    props: ['System','SystemEntities','Line_Items','Type','MasterTableObj','RelatedObj','CurrentEntity','TableCollectionRef','ParentDisabled'],
    components: {LookupSubColDialogComponent},
    data() {
        return {
            itemsearch: '',
            LineProgresstypes: [                
                {TotalProp: 'Total_Dispatching',FWDProp: 'Dispatch_Instructions_Progressed',REVProp: 'Dispatch_Instructions_Regressed', Name: 'Dispatching'},
                {TotalProp: 'Total_Packing',FWDProp: 'Packing_Lists_Progressed',REVProp: 'Packing_Lists_Regressed', Name: 'Packing'},
                {TotalProp: 'Total_Picking',FWDProp: 'Picking_Slips_Progressed',REVProp: 'Picking_Slips_Regressed', Name: 'Picking'},
                {TotalProp: 'Total_Receiving',FWDProp: 'Goods_Receiving_Vouchers_Progressed',REVProp: 'Goods_Receiving_Vouchers_Regressed', Name: 'Receiving'},
                {TotalProp: 'Total_Ordered',FWDProp: 'Purchase_Orders_Progressed',REVProp: 'Purchase_Orders_Regressed', Name: 'Ordered'},
                {TotalProp: 'Total_Backorder',FWDProp: 'Purchase_Requisitions_Progressed',REVProp: 'Purchase_Requisitions_Regressed', Name: 'Backorder'}
            ],
            SubColLookupDialogActive: false,
            DefaultHeaders: [
                {text: 'Created By',value: 'Created_By',class: 'overline'},
                {text: 'Created On',value: 'Created_On',class: 'overline'},
                {text: 'Stock Obj',value: 'Stock_Obj',class: 'overline'},
                {text: 'Qty',value: 'Qty',class: 'overline'},
                {text: 'Stage',value: 'Stage',class: 'overline'},
            ],
        }
    },
    computed:{
        CurrentEntityCycleStart(){
            return this.CurrentEntity && this.CurrentEntity.Warehouse_Dispatch_After ? this.CurrentEntity && this.CurrentEntity.Warehouse_Dispatch_After.Name : ''
        },
        Store_Entity(){
            if(this.MasterTableObj){
                return this.SystemEntities.find(obj => obj.id === this.MasterTableObj.LookupBuilds[0].id)
            }
        },
        ComputedLine_ItemsHeaders(){
            let arr1 = this.DefaultHeaders
            if(this.Type === 'SCM'){
                return arr1
            }
            else{
                return arr1
            }
        },
        LineProgresstypesHeaders(){
            let arr1 = this.LineProgresstypes.filter(head => {
                if(this.CurrentEntityCycleStart === 'Picking'){
                    return head.Name !== 'Packing'
                }
                else if(this.CurrentEntityCycleStart === 'Packing'){
                }
                else{
                    return head
                }
            })
            .map(head => {
                let headerobj = {
                    text: head.Name,value: head.TotalProp,class: 'overline'
                }
                if(this.CurrentEntityCycleStart === 'Picking' && headerobj.text === 'Dispatching'){
                    headerobj.text = 'Invoicing'
                }
                return headerobj
            })
            let arr2 = arr1.concat([
                {text: 'UnAllocated Qty',value: 'UnAllocatedQty',class: 'overline red--text'},
                {text: 'Outstanding Qty',value: 'Outstanding_Qty',class: 'overline red--text'},
            ]).reverse()
            return arr2.concat([
                {text: 'Invoiced Qty', value: 'Invoiced_Qty',class: 'overline green--text'}
            ])
        },
        ActiveWarehouse(){
            return this.$route.params.slug ? this.$store.state.WarehousesArray.find(obj => obj.id === this.$route.params.slug) : this.$store.state.WarehousesArray.find(obj => obj.id === this.$route.params.id)
        },  
        PermissionObject(){
            let WarehouseActions = [
              {Display: 'Requisitions',Prop: 'Requisitions', ArrayName: 'PurchaseRequisitions'},
              {Display: 'Ordering',Prop: 'Ordering', ArrayName: 'PurchaseOrders'},
              {Display: 'Receiving',Prop: 'Receiving', ArrayName: 'GoodsReceivingVouchers'},
              {Display: 'Picking',Prop: 'Picking', ArrayName: 'PickingSlips'},
              {Display: 'Packing',Prop: 'Packing', ArrayName: 'PackingLists'},
              {Display: 'Dispatching',Prop: 'Dispatching', ArrayName: 'DispatchInstructions'},
            ]
            let active = ''
            if(this.Type !== 'Warehouse Transaction' && this.Type !== 'SCM'){
                console.log(this.Type)
                active = WarehouseActions.find(obj => obj.Prop === this.Type)
            }
            else{
                active = WarehouseActions.find(obj => obj.ArrayName === this.$route.query.TransactionType)
            }
            return active.Prop
        },
        UserisModerator(){
            let permissioncheck = ''
            if(this.userLoggedIn.Warehouses && this.userLoggedIn.Warehouses[this.ActiveWarehouse.id]){
                this.userLoggedIn.Warehouses[this.ActiveWarehouse.id][this.PermissionObject]
            }            
            return permissioncheck ? permissioncheck || this.userIsAdmin : this.userIsAdmin
        },
        RelatedObjProp(){
            if(this.Type === 'SCM'){
                return 'LookupObj'
            }
            else{
                return 'StockObj'
            }
        },
        UsersArray(){
            return this.$store.state.Users
        },
        ComputedLine_Items(){
            return this.Line_Items.map(item => {
                if(item.Created_By){
                    let userobj = this.UsersArray.find(obj => obj.id === item.Created_Byid)
                    if(userobj){
                        item.Created_By.Profile_Photo = userobj.Profile_Photo
                    }
                }
                if(item.Created_On.toDate){
                item.monthcreated = this.TimestampFormatterNoticeBoard(item.Created_On.toDate(),'month')
                item.daycreated = this.TimestampFormatterNoticeBoard(item.Created_On.toDate(),'date') 
                }
                else{
                    item.monthcreated = this.TimestampFormatterNoticeBoard(item.Created_On,'month')
                item.daycreated = this.TimestampFormatterNoticeBoard(item.Created_On,'date')
                }
               
                return item
            }).map(item => {
                let counter = 0
                if(this.Type === 'SCM'){
                    let paidprogressive = item.Dispatch_Instructions_Progressed.filter(disp => {
                            return disp.Progress && disp.Progress.Name === 'Paid'
                        })
                        .map(child => {
                            return child.Qty
                        }).reduce((a, b) => a + b, 0)
                        let invoicedprogressive = item.Dispatch_Instructions_Progressed.filter(disp => {
                            return disp.Progress && disp.Progress.Name === 'Completed'
                        }).map(child => {
                            return child.Qty
                        }).reduce((a, b) => a + b, 0)
                    item.Invoiced_Qty = Number(paidprogressive)+Number(invoicedprogressive)
                    if(item.Invoiced_Qty >= item.Qty){
                        item.Stage = {
                                ID: 10000006,
                                Name: 'Invoiced'
                            }
                            item.StageName = item.Stage.Name
                    }
                    this.LineProgresstypes.map((type,typeindex) => {
                        let indexnumber = typeindex-1+2
                        item[type.TotalProp] = 0
                        let progressed = item[type.FWDProp].map(child => {
                        return child.Qty
                    }).reduce((a, b) => a + b, 0)
                        let reversed = item[type.REVProp].map(child => {
                        return child.Qty
                    }).reduce((a, b) => a + b, 0)
                        item[type.TotalProp] = progressed-reversed
                        counter = counter+item[type.TotalProp]
                        if(counter >= item.Qty && !item.Stage){
                            item.Stage = {
                                ID: 1000000+indexnumber,
                                Name: type.Name
                            }
                            item.StageName = item.Stage.Name
                            console.log(item.StageName)
                            if(item.StageName === 'Dispatching'){
                                console.log(item,type)
                            }
                        }
                        if(indexnumber === this.LineProgresstypes.length){
                            if(counter < item.Qty){
                                item.Stage = {
                                    ID: 2000001,
                                    Name: 'UnAllocated'
                                }
                                item.UnAllocatedQty = item.Qty-counter
                                
                                item.StageName = item.Stage.Name+' ('+item.UnAllocatedQty+')'
                            }
                            item.Outstanding_Qty = item.Qty-item[type.TotalProp]
                        }
                        //console.log('itemitem',type.FWDProp,progressed,type.REVProp,reversed,type.TotalProp,item[type.TotalProp])
                        //console.log('item.Outstanding_Qty',item.Outstanding_Qty)
                        //console.log('item.UnAllocatedQty',item.UnAllocatedQty)
                        //okay so 1. if reversed balances out, it is "name reversed"
                        //2. which stage is it in? If Dispatching covers Qty then there. If packing, then there. If Picking then there, etc.
                })
                }
                //console.log('itemitem',item)
                return item
            }).filter(item => {
                if(this.itemsearch && this.Store_Entity){
                    return item[this.Store_Entity.Primary_Field_Name] && item[this.Store_Entity.Primary_Field_Name].toLowerCase().includes(this.itemsearch.toLowerCase())
                }
                else{
                    return item
                }
            })
        },
        ProgressItems(){
            return [
                {ID: 1000001,Name: 'Issued'},
                {ID: 1000002,Name: 'In Progress'},
                {ID: 1000003,Name: 'Returned'},
                {ID: 1000004,Name: 'Cancelled'},
                {ID: 1000005,Name: 'Completed'}
            ]
        },
        ItemTotal(){
            return this.ComputedLine_Items.map(item => {
                return item.Qty*item.Qty
            }).reduce((a, b) => a + b, 0)
        },
        ScrollHeight(){
            let length = this.ComputedLine_Items.length
            let relaxfit = 5
            if(length > relaxfit){
                if(!this.$vuetify.breakpoint.sm && !this.$vuetify.breakpoint.xs){
                    return this.WindowHeight*0.35
                }
                else{
                    return this.WindowHeight*0.25
                }
            }
            else{
              return (this.WindowHeight*0.35)/(relaxfit/length)
            }
        },
        WindowHeight(){
          return window.innerHeight
      },
        ListStyle(){
            return this.$vuetify.theme.dark ? 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #787878;' : 'border: 1px solid #dedee9;outline: 1px solid #dedee9;background: #f8f8f8;'
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
    },
    created(){

    },
    methods:{
        StageColor(itemStage){
            let target = 1000006
            let targetstring = target.toString()
            let targetvalue = Number(targetstring.slice(6,7))
            let achievedstring = itemStage.ID.toString()
            let achievedvalue = Number(achievedstring.slice(6,7))
            console.log('itemStage',itemStage)
            if(itemStage.ID === 2000001){
                achievedvalue = 100
            }
            let value = Math.round((achievedvalue/targetvalue)*100)	
            //console.log(targetstring)
            let greencolor = Math.round(100-value)*2.55			
            let redcolor = Math.round(value*2.55)                 			
            return 'rgb('+redcolor+','+greencolor+',0)'
        },
        ProgressColor(item){
            if(this.ProgressChangeDisabled(item)){
                return 'grey'
            }
            else if(item.Progress.Name === 'Completed'){
                return 'success'
            }
            else{
                return 'pop'
            }
        },
        ProgressChangeDisabled(item){
            return !this.UserisModerator || item.Progress.Name === 'Returned' || item.Progress.Name === 'Cancelled' || this.ParentDisabled
        },
        RelevantProgressItems(item){
            let items = this.ProgressItems.filter(stat => {
                return stat.Name !== item.Progress.Name
            })
            if(item.Progress.Name === 'Issued'){
                return items.filter(stat => {
                    return stat.Name !== 'In Progress'
                })
            }
            else if(item.Progress.Name === 'Completed'){
                return items.filter(stat => {
                    return stat.Name !== 'In Progress' && stat.Name !== 'Issued'
                })
            }
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        ActivateRelevantdialog(){
        //this.close(true)
        if(this.Type === 'SCM'){
          this.SubColLookupDialogActive = true
        }
        else{
          this.dialog = !this.dialog
        }
        
      },
        DeactivateSubColLookupDialog(){
            this.SubColLookupDialogActive = false
        },
        UpdateProgress(item,status){
            this.$emit('UpdateProgress',item,status)
        },
        TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },  
    }
}
</script>

<style>

</style>


