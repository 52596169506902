
          <template>
          <!-- <v-main :style="AppBuilderView ? 'margin-top:60px;' : ''"> -->
    <v-card class="transparent" width="100%" height="100%" :dark="AppisDarkMode" flat>
    <!-- <v-card class="transparent"> -->
      <!-- <div  class="my-10"> -->
          
           <v-dialog v-model="ViewGlossaryItemDialog" max-width="400">
               <v-card tile>
                <v-card-title class="primary white--text">
                    {{ViewingGlossaryItem.Title}}
                </v-card-title>
                <v-card-text >
                  <span v-html="ViewingGlossaryItem.Response">
                  </span>
                </v-card-text>
               </v-card>
           </v-dialog>
          <v-dialog v-model="NewGlossaryItemDialog" :width="MiniView ? '80%' : '50%'">
              <v-card>
                <v-card-title>
                    New Glossary Item
                </v-card-title>
                <v-card-text>
                    <v-list>
                      <!-- DynamicFilter {{DynamicFilter}}
                     RelevantDataFilter {{RelevantDataFilter}} -->
                        <v-text-field v-model="NewGlossaryItem.Title" label="Title"/>
                        <v-select v-model="NewGlossaryItem.TopicCategory" :items="TopicCategories" label="Category"/>
                        <v-switch v-model="NewGlossaryItem.TopicSignedIn" label="Must be Signed In" />
                        <v-combobox
                    v-model="NewGlossaryItem.KeyWords"
                    :items="items"
                    label="Response KeyWords"
                    multiple
                    chips
                    >
                    <template v-slot:selection="data">
                        <v-chip
                        :key="JSON.stringify(data.item)"
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        :disabled="data.disabled"
                        @click:close="data.parent.selectItem(data.item)"
                        >
                        <v-avatar
                            class="accent white--text"
                            left
                            v-text="data.item.slice(0, 1).toUpperCase()"
                        ></v-avatar>
                        {{ data.item }}
                        </v-chip>
                    </template>
                    </v-combobox>
                        <v-layout class="justify-center">
            <v-btn-toggle dark>
                <v-btn :x-small="MobileView" onclick="document.execCommand('justifyLeft',false,'')">
                <v-icon :x-small="MobileView">mdi-format-align-left</v-icon>
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('justifyCenter',false,'')">
                <v-icon :x-small="MobileView">mdi-format-align-justify</v-icon>
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('justifyRight',false,'')">
                <v-icon :x-small="MobileView">mdi-format-align-right</v-icon>
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('insertUnorderedList',false,'')">
                <v-icon :x-small="MobileView">mdi-format-list-bulleted</v-icon>
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('strikeThrough',false,'')">
                                <v-icon :x-small="MobileView">mdi-format-strikethrough</v-icon>
                            </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('formatBlock', false, '<h1>')">
                H1
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('formatBlock', false, '<h2>')">
                H2
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('formatBlock', false, '<h3>')">
                H3
                </v-btn>
                <v-btn :x-small="MobileView" onclick="document.execCommand('formatBlock', false, '<p>')">
                <v-icon :x-small="MobileView">mdi-format-paragraph</v-icon>
                </v-btn>
                <v-btn :x-small="MobileView" @click="copy()">
                <v-icon :x-small="MobileView">mdi-content-copy</v-icon>
                </v-btn>
                <!-- <v-btn :x-small="MobileView" onclick="document.execCommand('delete',false,'')">
                <v-icon :x-small="MobileView">mdi-delete-forever</v-icon>
                </v-btn>                       -->
                <v-btn :x-small="MobileView" onclick="document.execCommand('cut',false,'')">
                <v-icon :x-small="MobileView">mdi-content-cut</v-icon>
                </v-btn>
                
                
            </v-btn-toggle>
            </v-layout>
                <v-layout class="justify-center">
                            <v-btn-toggle dark>
                            <v-btn :x-small="MobileView" onclick="document.execCommand('undo',false,'')">
                                <v-icon :x-small="MobileView">mdi-undo</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" onclick="document.execCommand('redo',false,'')">
                                <v-icon :x-small="MobileView">mdi-redo</v-icon>
                            </v-btn>
                            
                            <v-btn :x-small="MobileView" onclick="document.execCommand('bold', false, '');">
                                <v-icon :x-small="MobileView">mdi-format-bold</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" onclick="document.execCommand('italic', false, '');">
                                <v-icon :x-small="MobileView">mdi-format-italic</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" onclick="document.execCommand('underline', false, '')">
                                <v-icon :x-small="MobileView">mdi-format-underline</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" @click="changeColor()">
                                <v-icon :x-small="MobileView">mdi-format-color-fill</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView"  onclick="document.execCommand('selectAll',false,'')">
                                <v-icon :x-small="MobileView">mdi-select-all</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" >
                                <v-icon :x-small="MobileView" @click="link()">mdi-link</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" >
                                <v-icon :x-small="MobileView" @click="linkyoutubevideo()">mdi-youtube</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" >
                                <v-icon :x-small="MobileView" @click="addphone()">mdi-phone</v-icon>
                            </v-btn>
                            <v-btn :x-small="MobileView" >
                                <v-icon :x-small="MobileView" @click="addemail()">mdi-email</v-icon>
                            </v-btn>
                            <!-- <v-btn :x-small="MobileView" onclick="document.execCommand('strikeThrough',false,'')">
                                <v-icon :x-small="MobileView">mdi-format-strikethrough</v-icon>
                            </v-btn> -->
                            </v-btn-toggle> 
                            
            </v-layout>
            <!-- <v-btn @click="UpdateResponse(option,resp)">Save</v-btn> -->
                        <v-card min-height="300"  contenteditable id="NewGlossaryIteminput" v-html="NewGlossaryItem.Response"/>
                      
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click="CancelNewGlossaryItemDialog()">Cancel
                    </v-btn>
                    <v-spacer>
                    </v-spacer>
                    <v-btn @click="CreateNewGlossaryItem(NewGlossaryItem)">Save
                    </v-btn>
                </v-card-actions>
            </v-card>
          </v-dialog>
        <v-card class="transparent my-2" width="100%" flat>
           <v-parallax v-if="$route.name !== 'GroupSingle' && !NoticeSelect"
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.ParallaxSocial_Parallax_Gradient ? System.ParallaxSocial_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{System.Name}}</h1>
                <h4 class="headline">Glossary</h4>
              </v-col>
            </v-row>
            </v-parallax>           
            </v-card>
            <!-- </div>-->
      <!-- <div  class="my-10"> -->
        <v-card class="transparent my-2" width="100%" flat>
            <v-layout row wrap class="justify-center mx-2">
                <v-flex lg11 md12 sm11 xs12 class="mx-2 my-2">
                    <v-btn v-if="UserisGlossaryModerator" @click="ActivateNewGlossaryItemDialog">Add New</v-btn>
                    <v-btn @click="RefreshGlossary">Refresh</v-btn>
                  <v-card v-if="!Refreshing" flat tile
                  height="100%" class="mx-1 transparent">
                  <v-card-title>
                    <v-text-field label="Search" v-model="Search" />
                  </v-card-title>
                    <v-card-text>
                        <v-row>
                 <v-col cols="12" :xl="3" :lg="4" :md="6" :sm="6" :xs="12" v-for="item in ComputedGlossaryItems" :key="item.itemObjKey">
                  <v-card outlined height="250"> 
                            <v-card-title>
                                <v-btn outlined plain text @click="OpenGlossaryItem(item)">{{item.Title}}</v-btn>
                               
                                <v-spacer></v-spacer>
                                <v-btn small fab icon outlined color="pop" @click="DeleteGlossaryItem(item)"><v-icon small v-if="UserisGlossaryModerator">mdi-delete-forever</v-icon></v-btn>
                                <v-btn small fab icon outlined color="pop" @click="EditGlossaryItem(item)"><v-icon small v-if="UserisGlossaryModerator">mdi-pencil</v-icon></v-btn>
                            </v-card-title>
                            <v-card-text v-html="smart_substr(item.Response,SubstrCut)">
                                </v-card-text>
                        
                        <!-- <v-card-actions>
                            <v-btn>
                                Wow I can edit
                            </v-btn>
                        </v-card-actions> -->
                            </v-card>
                 </v-col>
                        </v-row>
               </v-card-text>
                </v-card>
                </v-flex>
                </v-layout>            
            </v-card>
            <!-- </div>-->
        </v-card> 
          <!-- </v-main> -->
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'


export default {
    props: ['AppisDarkMode','IntranetView','System','AppBuilderView'],
    components: {
        },
    data() {
    return {     
        Search: '',
        Refreshing: false,
        ResponseTypes: [
          {
            Name: 'Quirky',
            Triggers: [
                'opinion'
            ]
            },
            {
            Name: 'Search Result',
            Triggers: [
                'what'
            ]
            },
            {
            Name: 'System Technical',
            Triggers: [
                'why'
            ]
            },
          {
            Name: 'Greetings',
            Triggers: [
                'hello'
            ]
            },
            {
            Name: 'How To',
            Triggers: [
                'how'
            ]
            },
            {
            Name: 'Navigation',
            Triggers: [
                'where'
            ]
            },
            {
            Name: 'General',
            Triggers: [
                'learn'
            ]
            },
            {
            Name: 'Virtual Assist',
            Triggers: [
                'assist'
            ]
            }
            ],
        TopicCategories: ['Social','Database','Pages','Wiki','Media Library','Document Library','Greeting Lines','Administration','Users','Moderators','Teams','Navigation','Interface','General'],
        NewGlossaryItemDialog: false,
        NewGlossaryItem: {
            active: true,
            Title: '',
            IsTopic: true,
            TopicCategory: 'General',
            TopicSignedIn: true,
            Response: '',
            KeyWords: [],
            Responses: []
        },
        ViewingGlossaryItem: '',
        ViewGlossaryItemDialog: false,
        DefaultGlossaryItem: {
            active: true,
            IsTopic: true,
            Title: '',
            TopicCategory: 'General',
            TopicSignedIn: true,
            Response: '',
            KeyWords: [],
            Responses: []
        },
      GlossaryItems: [],   
      UserRecord: {},
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
    }
    },
    computed:{
      SubstrCut(){
        if(this.$vuetify.breakpoint.xl){
          return 250
        }
        else if(this.$vuetify.breakpoint.lg){
          return 200
        }
        else if(this.$vuetify.breakpoint.md){
          return 150
        }
        else{
          return 200
        }
      },
       MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
      MedView(){
            return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        userIsAdmin() {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGlossaryModerator(){
            if(this.userLoggedIn && this.userLoggedIn.ModerationPermissions && this.userLoggedIn.ModerationPermissions.includes('Glossary')){
                return true
              }
              else {
                  return this.userIsAdmin
              }
        },
        ComputedGlossaryItems(){
            return this.GlossaryItems.map(item => {
                //console.log(item)
                item.LowerKeyWords = item.KeyWords.map(kw => {
                    return kw.toLowerCase()
                })
                return item
            }).filter(item => {
              if(this.Search){
                return item.LowerKeyWords.includes(this.Search.toLowerCase()) || item.Title.toLowerCase().includes(this.Search.toLowerCase())
              }
              else{
                return item
              }
            })
        },
      AppisDarkModeCard(){
        if(this.AppisDarkMode){
          return 'blue-grey darken-4'
        }
        else{
          return 'blue-grey lighten-4'
        }
      },
      userLoggedIn () {
      return this.$store.getters.user
    },
      CompanyDocumentssize(){
        let comp = document.getElementById('CompanyDocumentscomp')
        if(comp){
          return comp.offsetWidth
        }
        else{
          return this.$vuetify.breakpoint.width/1
        }
      },
      CompanyDocumentsPageFilter(){
          return this.CompanyDocumentsPrimaryTypeOptions.filter(option => {
          return option.ID === 1000001 ||option.ID === 1000002 ||option.ID === 1000003 ||option.ID === 1000004 ||option.ID === 1000008 ||option.ID === 1000009    
        })
        
        return FilterObj
      },
      ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="100%" height="100%" style="padding:20px;" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="100%" height="100%" style="padding:20px;" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
        smart_substr(str, len) {
            var total = str
            if(total.lastIndexOf('<') > total.lastIndexOf('>')) {
                total = str.substr(0, 1 + str.indexOf('>', total.lastIndexOf('<')));
            }
            var temp = total.substr(0, len);
            if(total.length > len){
                temp = temp+'...'
            }
            return temp;
        },
        changeColor() {
  var color = prompt("Enter your color in hex ex:#f1f233");
  document.execCommand("foreColor", false, color);
},
      addphone(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'tel:'+url);
       },
       addemail(){
         var url = prompt("Enter the Number internationalformat (e.g. +27123457890")
         document.execCommand("CreateLink", false, 'mailto:'+url);
       },
       link() {
    var url = prompt("Enter the URL");
    document.execCommand("createLink", false, url);
    },
    linkyoutubevideo(){
        this.Youtubelink = prompt("Enter the link")
        let validation = this.rules.youtubeurl(this.Youtubelink)

        if(validation === true){
        
        // let finalembed = `<iframe width="560" height="315" src="`+this.ComputedYoutubelinkEmbed+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
        document.execCommand("insertHTML", false, this.ComputedYoutubelinkEmbed)
        this.Youtubelink = ''
        }
        else{
            confirm(validation) && this.linkyoutubevideo()
        }
    },


    copy() {
      document.execCommand("copy", false, "");
    },
        RefreshGlossary(){
            this.Refreshing = true
            setTimeout(() => {
                    this.Refreshing = false
            }, 500);
        },
        OpenGlossaryItem(item){
            this.ViewingGlossaryItem = item
            this.ViewGlossaryItemDialog = true
        },
        DeleteGlossaryItem(item){
            confirm('This will delete the item from Glossary entirely. Are you sure you want to continue?') && this.ProcessDeleteGlossaryItem(item)
        },
        ProcessDeleteGlossaryItem(item){    
            db.collection('glossary').doc(item.id).delete().then(response => {
                let obj = this.GlossaryItems.find(obj => obj.id === item.id)
                let index = this.GlossaryItems.indexOf(obj)
                this.GlossaryItems.splice(index,1)
            })
        },
        EditGlossaryItem(item){
            this.NewGlossaryItem = Object.assign({},item)
            this.NewGlossaryItemDialog = true
        },
        CreateNewGlossaryItem(NewGlossaryItem){
            let inputelement = document.getElementById('NewGlossaryIteminput')
            
         if(inputelement){
             NewGlossaryItem.Response = inputelement.innerHTML
             NewGlossaryItem.LowerKeyWords = NewGlossaryItem.KeyWords.map(kw => {
                 return kw.toLowerCase()
             })
             if(NewGlossaryItem.id){
                 this.NewGlossaryItem = Object.assign({},NewGlossaryItem)
                 db.collection('glossary').doc(NewGlossaryItem.id).set(NewGlossaryItem).then(doc => {
                    console.log('great, edited the item')
                    console.log(this.NewGlossaryItem)
                    this.CancelNewGlossaryItemDialog()
                }) 
             }
             else{
                db.collection('glossary').add(NewGlossaryItem).then(doc => {
                    console.log('great, added new item at '+doc.id)
                    this.CancelNewGlossaryItemDialog()
                }) 
             }
             
         }
            
        },
        GetGlossaryItems(){
            db.collection('glossary').onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.GlossaryItems.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                    if (change.type === 'modified') {
                        let modifiedobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    let obj = this.GlossaryItems.find(obj => obj.id === modifiedobj.id)
                    let index = this.GlossaryItems.indexOf(obj)
                    this.GlossaryItems[index] = modifiedobj
                    console.log(this.GlossaryItems)
                    } 
                })
                })
        },
        ActivateNewGlossaryItemDialog(){
            this.NewGlossaryItemDialog = true
        },
        CancelNewGlossaryItemDialog(){
            this.NewGlossaryItemDialog = false
            this.NewGlossaryItem = Object.assign({},this.DefaultGlossaryItem)
        },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      async CheckUser(){
            let vm = this
            await firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                  db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()
                vm.GetGlossaryItems()
              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              //console.log(vm.UserRecord)
                  })
                }
                else{
                  vm.$router.push('/Login')
                }
            })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.parallaxbgcontentgrad {
  background-image: linear-gradient(to right, rgba(0,185,255,0.5), rgb(165,223,245, 1));
  border-top: solid #FF0000;
  border-bottom: solid #FF0000;
  border-width: 1px;
}
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}

</style>
    