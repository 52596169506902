
<template>
<v-card flat class="transparent">


    <!-- <v-flex xs8 s6 md10 lg8>
      <v-card flat light class="transparent"> -->
         <v-toolbar flat dark color="accent">
             <v-toolbar-title>{{groupName}} Members</v-toolbar-title>
        <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You changed a User Role.</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
            <v-snackbar v-model="contributorsnackbar" :timeout="8000" top color="success">
      <span>Awesome! You added a Contributor, view them all and finalize their Contributor Profiles in "Member Contributors".</span>
      <v-btn color="white" @click="snackbar = false">Close</v-btn>
    </v-snackbar>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
 <!-- <v-img align="left"
          :src="require('@/assets/elb_twirl.png')"
          class="my-3"
          
          height="40px"
        ></v-img> -->
      <v-spacer></v-spacer>
      <v-text-field dark
        v-model="search"
        append-icon="mdi-file-find-outline"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
        <v-menu>
            <template v-slot:activator="{ on }">
              <v-icon  v-on="on" color="white">mdi-dots-vertical</v-icon>
            </template>
            <v-list>
              <v-list-item>
                Edit
              </v-list-item>
            </v-list>
        </v-menu>
          </v-toolbar>
          <v-layout class="justify-center">
        <!-- <v-card-text> -->
          <!-- <v-flex> -->
         <v-layout row class="transparent justify-start" style="padding:50px;">
        <v-col cols="12" lg="3" md="4" sm="6" xs="12"  v-for="member in Computedgroupmembers" :key="member.id" :style="{ backgroundImage: 'url(' + logo + ')' ,backgroundSize: 'cover'}">
            <div :style="GradientStyle">
            
              <v-menu>
                          <template v-slot:activator="{ on }">
                        <v-icon v-if="UserisAdministrator" v-on="on">mdi-pencil</v-icon>
                          </template>
                          <v-list>
                            <v-list-item v-if="member.Status === 'Active'" @click="ConfirmDeactivateMember(member)">
                              Deactivate Member
                            </v-list-item>
                            <v-list-item v-if="member.Status === 'Pending Approval'" @click="ConfirmActivateMember(member)">
                              Activate Member
                            </v-list-item>
                            <v-list-item v-if="!member.Featured"  @click="FeatureMember(member)">
                              Feature Member
                            </v-list-item>
                            <v-list-item v-if="member.Featured"  @click="FeatureMember(member)">
                              Edit Member Feature
                            </v-list-item>

                          </v-list>
                        </v-menu>
                      <v-list-item >
                        <!-- <v-list-item-content > -->
                        <v-list-item-avatar class="transparent" tile size="80">
                          <img v-if="member.Profile_Photo "
                          :src="member.Profile_Photo "    
                          style="object-fit: cover;"          
                          >
                          <img v-if="!member.Profile_Photo "
                            src="@/assets/BlankProfilePic.png"   
                            style="object-fit: cover;"           
                          >                      
                          </v-list-item-avatar>
                        <!-- </v-list-item-content> -->
                        <v-list-item-content >
                          <v-list-item-title>{{member.Full_Name}}</v-list-item-title> 
                          <v-list-item-subtitle v-if="member.Featured">
                            <router-link  :to="'/FeaturedGroupMember/'+member.groupid+'/'+member.id+'?GroupType='+member.grouptype.Name.split(' ').join('-')">Group Featured Member</router-link><v-icon color="links">mdi-account-star-outline</v-icon>
                          </v-list-item-subtitle>
                          <v-list-item-content v-if="member.Status === 'Active'">
                                <b>Member Since:</b> <span style="font-size:10px">{{member.MembershipDate}}</span>
                              </v-list-item-content>
                        <v-list-item-content v-if="member.Status === 'Pending Approval'">
                                <b>Awaiting Approval Since:</b> <span style="font-size:10px">{{member.MembershipDate}}</span>
                              </v-list-item-content>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                          <v-list-item-content>
                                  <b>{{member.Status === 'Active' ? 'Role:' : 'Approval'}}</b>
                                </v-list-item-content>
                                <v-list-item-content>
                                  {{member.Status === 'Active' ? member.UserRole : 'Pending'}}
                                </v-list-item-content>
                              </v-list-item>
                              <!-- <v-list-item>
                                <v-list-item-content>
                                  <b>Since:</b>
                                </v-list-item-content>
                                <v-list-item-content style="font-size:10px">
                                  {{member.MembershipDate}}
                                </v-list-item-content>
                      </v-list-item> -->
            </div>
        </v-col>
         </v-layout>

         
          <!-- </v-flex> -->
        <!-- </v-card-text> -->
      <!-- </v-card>
    </v-flex> -->
 </v-layout>
</v-card>

</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'

export default {
props: ['System','UserRecord','UserisAdministrator','UsersArray','logo','GroupCategory','pushedgroupmembers'],
    data() {
        return {
          hover: false,
          showerror: false,
          tags: [
                    'Auckland',
                    'Wellington',
                    'Very long string that would overflow'
                ],
          snackbar: false,
          contributorsnackbar: false,
              UserRolesType: [
      {index: 0, text: 'Follower'},
      {index: 1, text: 'Editor'},
      {index: 2, text: 'Administrator'},
        ],
        ListingContributors: false,
        EditingContributors: false,
        blogdialog: false,
        contributordialog: false,
        dialog: false,
        search: '',
        servicesearch: '',
        orgservicesearch: '',
        groupblogs: [],
        groupmembers: [],
        contributorgroups: [],
        contributingmembers: [],
        groupName: null,
        groups: [],
        users: [],
        // membersofgroup: [],
        groupid: this.$route.params.id,
        content: null,
        logo: '',
        image: '',
        description: null,
        Network: false,
        Editing: false,
        name: null,
        ServicestoOrganizations: [],
        DefaultServicestoOrganizations: [],
        ServicestoIndividuals: [],
        DefaultServicestoIndividuals: [],
        newservicetoindividual : {
          index: '', Name: '' ,checked: true
        },
        defaultservicetoindividual: {
          index: '', Name: '', check: true
        },
        editedContributor: {
              id: null,
              memberid: null,
              groupmemberid: null,
              ContributionCategory: null,
              ContributetoIndividuals: false,
              IndividualContributionDescription: null,
              OrganizationContributionDescription: null,
              ContributetoOrganizations: false,
              FreeConsultation: false,
              ServiceCategorytoIndividuals: null,
              //consulting,legal,financial,technical specialist,other
              ServiceCategorytoOrganizations: null,
              //consulting,legal,financial,technical specialist,other
              ServicestoIndividuals: [],
              ServicestoOrganizations: [],
              DefaultServicestoOrganizations: [],
              DefaultServicestoIndividuals: [],         


        },
            editedItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            defaultItem: {
                id: null,
                name: null,
                content: null,
                description: null,
                image: null,
                userrole: null,
                ProfilePic: null,
                userid: null,
                useriscontributor: null,                
            },
            blogtitle: null,
            blogcontent: null,
            image: null,
            //userid: this.$store.state.user.id,
            UserisMember: false,
            UserisAdmin: false,
            tetempusid: "'GNagVng6n1VgjtSXXrHDdEejjM93'"+","+"'Sk7cvSO6uXMJdN2qwJW5C9LSwzj1'", 
            Changes: false,
            Contributorprofilefield: '',
            helpnewsheet: false,
            // MinistryContributionstoIndividialNames: '',
            MinistryContributionstoIndividualArr: [],
            BusinessContributionstoIndividualArr: [],
            FamilyContributionstoIndividualArr: [],
            MinistryContributionstoOrganizationsArr: [],
            BusinessContributionstoOrganizationsArr: [],
            FamilyContributionstoOrganizationsArr: [],
            HasOrgServices: false,
            HasIndServices: false,      
        
        }
    },
    computed: {
      GradientStyle(){
        let lighbg = this.System.Light_Background.hex
        let darkbg = this.System.Dark_Background.hex
        if(lighbg && !this.$vuetify.theme.dark){
          return 'height:100%; background: linear-gradient(60deg, '+lighbg+' 70%, '+lighbg+' 100%);'
        }
        else if(!this.$vuetify.theme.dark){
          return 'height:100%; background: linear-gradient(60deg, #EBF0F0 70%, #EBF0F07D 100%);'
        }
        else if(darkbg && this.$vuetify.theme.dark){
          return 'height:100%; background: linear-gradient(60deg, '+darkbg+' 70%, '+darkbg+' 100%);'
        }
        else if(this.$vuetify.theme.dark){
          return 'height:100%; background: linear-gradient(60deg, #474747 70%, #4747477D 100%);'
        }
      },
      Computedgroupmembers(){
        console.log(this.UsersArray)
        return this.groupmembers.map(member => {
          console.log(member)
          
          let userobj = this.UsersArray.find(obj => obj.id == member.Userid)
          if(userobj){
          userobj.UserRole = member.UserRole
          userobj.MembershipDate = this.TimestampFormatterSTRING(member.MembershipDate.toDate())
          userobj.Memberid = member.id,
          userobj.Featured = member.Featured
          userobj.Status = member.Status
          return userobj
          }
        })
      },
        //SECTION 1 - CURRENT USER, OTHER USERS AND THEIR RELATIONSHIP TO THE GROUP

        //Viewing user's relationship to Group
        RelatedGroupMembersLength () {
            let relatedgroupmembercheck = this.groupmembers.filter(groupmember => {
                return groupmember.Groupid === this.groupid && groupmember.Userid === this.UserRecord.id
            })
            return relatedgroupmembercheck.length
        },
        RelatedGroupAdministratorsLength () {
            let relatedgroupadmincheck = this.groupmembers.filter(groupmember => {
                return groupmember.Groupid === this.groupid && groupmember.Userid === this.UserRecord.id && groupmember.UserRole === 'Administrator'
            })
            return relatedgroupadmincheck.length
        },


        //obtaining the id array of contributingmembers and groupmembers to filter usercollection by
        RelatedContributorsId () {
        let contributorsid = this.contributingmembers.map(groupcontributor => {return groupcontributor.memberid;});
            return contributorsid  
        },

        RelatedGroupMembersId () {
        let membersid = this.groupmembers.map(groupmember => {return groupmember.userid;});  
        return membersid    
        },

        //returning users that are members and contributors, and sorting them
        MemberUsers () {

        let relatedusersofgroup = this.users.filter(user => this.RelatedGroupMembersId.includes(user.id))

        return relatedusersofgroup

            },
        ContributorUsers () {

        let relatedcontributorsofgroup = this.users.filter(user => this.RelatedContributorsId.includes(user.id))

        return relatedcontributorsofgroup

            },
        sortedMemberUsers() {
        return this.MemberUsers.sort((a,b)=>{
         return (a.id > b.id) ? 1 : -1;
        })
        },
        sortedContributorUsers() {
        return this.ContributorUsers.sort((a,b)=>{
         return (a.id > b.id) ? 1 : -1;
        })
        },
        
        //sorting the contributing members and groupmembers relating to the group

        sortedContributingMembers() {
        return this.contributingmembers.sort((a,b)=>{
         return (a.memberid > b.memberid) ? 1 : -1;
        })
        },
        sortedRelatedGroupMembers() {
        return this.groupmembers.sort(function (a,b){
        return (a.userid > b.userid) ? 1 : -1;
        }) 
        },


        

        //pusing the new array of the user collection, that adds details from groupmembers and contributingmembers collection, ultmiately adding search
        GroupMembersNewArray() {
          let arr1 = this.sortedMemberUsers
          let arr2 = this.groupmembers
          let arr3 = [];

          arr1.forEach((itm, i) => {
            arr3.push(Object.assign({}, itm, arr2[i]));
          });
          return arr3 
       },

          ContributingMembersNewArray() {
          let arr1 = this.sortedContributorUsers
          let arr2 = this.contributingmembers
          let arr3 = [];

          arr1.forEach((itm, i) => {
            arr3.push(Object.assign({}, itm, arr2[i]));
          });
          return arr3 
       },


          GroupMembersWithSearch() {
          return this.GroupMembersNewArray.filter(member => {
            return member.Name.toLowerCase().includes(this.search.toLowerCase()) || member.Surname.toLowerCase().includes(this.search.toLowerCase())
          })

          arr1.forEach((itm, i) => {
            arr3.push(Object.assign({}, itm, arr2[i]));
          });

          return arr3

       },



          ContributingMembersWithSearch() {
          return this.ContributingMembersNewArray.filter(member => {
            return member.Name.toLowerCase().includes(this.search.toLowerCase()) || member.Surname.toLowerCase().includes(this.search.toLowerCase())
          })

          arr1.forEach((itm, i) => {
            arr3.push(Object.assign({}, itm, arr2[i]));
          });

          return arr3

       },


       //SECTION 2 - CONTRIBUTING MEMBER SERVICES, AND CONTRIBUTING SERVICES

       //The next section is the section around contributing members, ContributingMembersNewArray that covers the contributorservices and ultimately pushes a brand new array
       //obtaining services to individuals and organizations, by contributioncategorynames to prepare mapping of new array
       //but why get Default AND another that seems to be the same? Well Default does not change, helps identify if new services were added
          GroupMembersContributorGroupIds() {
        return this.GroupMembersWithSearch.map(member => {
        return member.userministrycontributorprofilegroup
            }).sort(function (a,b){
        return (a > b) ? 1 : -1;
        });
          },
          DefaultservicesNames() {
        let servicenames = this.DefaultServicestoIndividuals.map(service => {return service.Name;}).sort(function (a,b){
        return (a > b) ? 1 : -1;
        });  
        return servicenames  
       },
        DefaultOrgservicesNames() {
        let servicenames = this.DefaultServicestoOrganizations.map(service => {return service.Name;}).sort(function (a,b){
        return (a > b) ? 1 : -1;
        });  
        return servicenames  
       },

       //here we get a repeat, but what we get here changes when new services are added. The previous array is static until services from database gets refreshed. 
        ServicesNames() {
        let servicenames = this.ServicestoIndividuals.map(service => {return service.Name;}).sort(function (a,b){
        return (a.Name > b.Name) ? 1 : -1;
        });  
        return servicenames  
       },
        OrgServicesNames() {
        let servicenames = this.ServicestoOrganizations.map(service => {return service.Name;}).sort(function (a,b){
        return (a.Name > b.Name) ? 1 : -1;
        });  
        return servicenames  
       },

       //Here we do the match by the contributioncategory, the list of servicenames against the Contributor services
       //What I do not get is WHY NOT against defaultservicenames?
       //Okay, this is once again only further preparation for the array that is to become comboservicenames 
      DefaultServiceNamesNotInd() {
         if(this.ContributionCategory === 'Business') {
            let mix = this.ServicesNames.filter(service => {
           return !this.editedContributor.BusinessContributionstoIndividualArr.includes(service)})
        return mix
         }
         if(this.ContributionCategory === 'Family') {
            let mix = this.ServicesNames.filter(service => {
           return !this.editedContributor.FamilyContributionstoIndividualArr.includes(service)})
        return mix
         } 
         if(this.ContributionCategory === 'Ministry') {
            let mix = this.ServicesNames.filter(service => {
           return !this.editedContributor.MinistryContributionstoIndividualArr.includes(service)})
        return mix
         } 
       },
      DefaultOrgServiceNamesNotInd() {
         if(this.ContributionCategory === 'Business') {
            let mix = this.OrgServicesNames.filter(service => {
           return !this.editedContributor.BusinessContributionstoOrganizationsArr.includes(service)})
        return mix
         }
         if(this.ContributionCategory === 'Family') {
            let mix = this.OrgServicesNames.filter(service => {
           return !this.editedContributor.FamilyContributionstoOrganizationsArr.includes(service)})
        return mix
         } 
         if(this.ContributionCategory === 'Ministry') {
            let mix = this.OrgServicesNames.filter(service => {
           return !this.editedContributor.MinistryContributionstoOrganizationsArr.includes(service)})
        return mix
         } 
       },

        //MAIN PURPOSE HERE - Be able to see a new service added, int eh checkbox BEFORE we click on save
        //This gets the list of servicenames from the database and merge with user list
        //very simply put, the main reason for this is if we use ServiceNames adding new services do not get added to the checkbox BEFORE save
        //also, that's why we do not use DefaultServices but actually use ServicetoIndividuals. Because DefaultServiestoIndividuals is unaffected by new services added
      
       ComboBoxServiceNames() {
         if(this.ContributionCategory === 'Ministry') {
                if (this.editedContributor.MinistryContributionstoIndividualArr) {
          let arr1 = this.editedContributor.MinistryContributionstoIndividualArr
          let arr2 = this.DefaultServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.ServicesNames
          }
         }
          if(this.ContributionCategory === 'Business') {
                if (this.editedContributor.BusinessContributionstoIndividualArr) {
          let arr1 = this.editedContributor.BusinessContributionstoIndividualArr
          let arr2 = this.DefaultServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.ServicesNames
          }
         }
         if(this.ContributionCategory === 'Family') {
                if (this.editedContributor.FamilyContributionstoIndividualArr) {
          let arr1 = this.editedContributor.FamilyContributionstoIndividualArr
          let arr2 = this.DefaultServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.ServicesNames
          }
         }
       },
       ComboBoxOrgServiceNames() {
         if(this.ContributionCategory === 'Ministry') {
                if (this.editedContributor.MinistryContributionstoOrganizationsArr) {
          let arr1 = this.editedContributor.MinistryContributionstoOrganizationsArr
          let arr2 = this.DefaultOrgServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.OrgServicesNames
          }
         }
          if(this.ContributionCategory === 'Business') {
                if (this.editedContributor.BusinessContributionstoOrganizationsArr) {
          let arr1 = this.editedContributor.BusinessContributionstoOrganizationsArr
          let arr2 = this.DefaultOrgServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.OrgServicesNames
          }
         }
         if(this.ContributionCategory === 'Family') {
                if (this.editedContributor.FamilyContributionstoOrganizationsArr) {
          let arr1 = this.editedContributor.FamilyContributionstoOrganizationsArr
          let arr2 = this.DefaultOrgServiceNamesNotInd
          let arr3 = arr1.concat(arr2).sort(function (a,b){
        return (a > b) ? 1 : -1;
        })
          return arr3}
          else {
          return this.OrgServicesNames
          }
         }
       },



//Here we compare defaultservicenames to see if there is a new service or not. 
//Just remember this checked against DefaulServiceNames that uses DefaultServicesto, not the DefaultServiceNamesNOTInd that uses Servicesto...which means this checks database only.

//ONE FORM OF SUCCESS NOW AT LEAST!!!!
//AT LEAST I KNOW THIS IS WHERE THE PROBLEM LIES ALTHOUGH I AM YET TO DETERMINE WHY
//OKAY GOT IT - DEFAULTSERVICENAMES IS ACTUALLY EMPTY WHEN I TRY SAVE. THE COMPUTED PROPERTY IS NOT YET COMPUTED UPDON SAVE
//OKAY WAIT, I JUST ADDED INDIVIDUAL SERVICE AND CONSOLE.LOG OF ONLYNEWINDSERVNEW HAD A LENGTH OF 0, BUT IT STILL LOGGED "NEW SERVICES" WHICH ONLY UNDER "IF LENGTH >0"
//PERHAPS THIS CHECKING THE LENGTH OF THIS FILTERED ARRAY IS IN FACT THE PROBLEM.
//OKAY I GOT IT
//ONLY when there is absolutely NO service, is the OnlyNewserv value 0, otherwise it's not 0
//STILL do not know why some times it is greater than 0 though that makes not sense, but it means the entire thign could be in jeopardy 
//Okay WAIT...That just happened...You see, the length it is picking up is great there is not problem with that. But it just picked up OnlyNewindServNew is greater than 0
//Consistently every time it works great with the new < 1 as opposed to == 0. Anyway, problem is, the OnlyNewIndeServNew array is actually wrong
//Because it's actualy the full list of services on the individual
//Okay got it! DefaultServicestoIndividuals is never called. Or remains empty. I put a consolelog in where it shoudl eb pushed and nothing...
//Well there I got it and yet still nothing. There is something wrong with this whole defaultservices and services thing, something seriously wrong. 

              OnlyNewIndServNew() {

        if (this.ContributionCategory === 'Business') {
        let mix = this.editedContributor.BusinessContributionstoIndividualArr.filter(service => {
           return !this.DefaultservicesNames.includes(service)})
        return mix
              }
              if (this.ContributionCategory === 'Family') {
        let mix = this.editedContributor.FamilyContributionstoIndividualArr.filter(service => {
           return !this.DefaultservicesNames.includes(service)})
        return mix
              }
              if (this.ContributionCategory === 'Ministry') {
        let mix = this.editedContributor.MinistryContributionstoIndividualArr.filter(service => {
           return !this.DefaultservicesNames.includes(service)})
        return mix
              }
                 

       },

                     OnlyNewOrgServNew() {
                       
        if (this.ContributionCategory === 'Business') {
        let mix = this.editedContributor.BusinessContributionstoOrganizationsArr.filter(service => {
           return !this.DefaultOrgservicesNames.includes(service)})
        return mix
              }
              if (this.ContributionCategory === 'Family') {
        let mix = this.editedContributor.FamilyContributionstoOrganizationsArr.filter(service => {
           return !this.DefaultOrgservicesNames.includes(service)})
        return mix
              }
              if (this.ContributionCategory === 'Ministry') {
        let mix = this.editedContributor.MinistryContributionstoOrganizationsArr.filter(service => {
           return !this.DefaultOrgservicesNames.includes(service)})
        return mix
              }
                      
       },



      
    },
        created() {    
          // setTimeout(() => {
            console.log('this.UserRecord',this.UserRecord,this.pushedgroupmembers)
          // }, 1000); 
          this.groupmembers = this.pushedgroupmembers   
        // this.MergeStuff()
        //this.getGroupInformation()
        // this.getUsers()
        // this.getUserGrouprelationship()
    },

     methods: {
       FeatureMember(member){
         this.$router.push({ name: 'FeaturedMemberEdit', params: {id: member.id,slug: this.GroupCategory.Name.split(' ').join('-'),groupname: this.groupName,groupid: this.$route.params.id,grouptype: this.GroupCategory}})
       },
       ConfirmActivateMember(member){
         console.log('member',member)
         confirm('This will deactive the user and revoke their permissions to the Group. Are you sure you want to continue?') && this.ProcessActivateMember(member)
       },
       ConfirmDeactivateMember(member){
         confirm('This will deactive the user and revoke their permissions to the Group. Are you sure you want to continue?') && this.ProcessDeactivateMember(member)
       },
       ProcessActivateMember(member){
         db.collection('groupmembers').doc(member.id).collection('groups').doc(this.$route.params.id).update({
           Status: 'Active',
           MembershipDate: new Date(),
           MembershipRequestDate: new Date(member.MembershipDate)
         })
         member.Status = 'Active'
       },
       ProcessDeactivateMember(member){
         db.collection('groupmembers').doc(member.id).collection('groups').doc(this.$route.params.id).update({
           Status: 'InActive',
           Follower: false,
           Administrator: false,
            BlogsModerator: false,
            ArticlesModerator: false,
            EventsModerator: false,
            PollsModerator: false,
            MeetupsModerator: false,
            ForumsModerator: false,
         })
       },
       TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
        ToggleHelp() {         
            this.helpnewsheet = true
          },

        editItem (item) {
        this.editedIndex = this.GroupMembersWithSearch.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
        // this.Editing = true
      },

      RoutetoItem (item) {
        this.editedIndex = this.GroupMembersNewArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.$router.push('/userprofile/' + this.editedItem.userid)
      },
        async CancelGroupMemberUpdate() {
            this.editedItem.userrole = this.defaultItem.userrole
            this.dialog = false
            this.Changes = false
            this.Editing = false
        },

                SaveUpdatedGroupMembers() {
          this.userrole = this.editedItem.userrole
          const UpdatedGroupMember = {
            userrole: this.userrole
          }
            db.collection('groupmembers').doc(this.editedItem.id).update({
              userrole: UpdatedGroupMember.userrole
            })
              this.CancelGroupMemberUpdate()
              this.snackbar = true
            
            
        },
               ChangesareMade(event) {
          this.Changes = true
        },


        //SECTION - CONTRIBUTOR MEMBER


//SECTION - CONTRIBUTOR SERVICES
//SUBSSECTION - DATACALLING
async getContributingMembers() {

    await db.collection('contributingmembers').where("networkgroupid", "==", this.groupid).onSnapshot(res => {

      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.contributingmembers.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }  
      })
    })    
    
        },
        async getOrganizationsServices(){

     await db.collection('Individual2OrganizationsServices')
  .where("ContributionCategory", "==", this.ContributionCategory)
  .get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.ServicestoOrganizations.push(doc.data());
    });

  });

       await db.collection('Individual2OrganizationsServices')
  .where("ContributionCategory", "==", this.ContributionCategory)
  .get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.DefaultServicestoOrganizations.push(doc.data());
    });
      console.log(this.DefaultServicestoOrganizations)  
  });
      

}, 
     async getIndividualsServices(){

     await db.collection('Individual2IndividualServices')
  .where("ContributionCategory", "==", this.ContributionCategory)
  .get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.ServicestoIndividuals.push(doc.data());
    });
    console.log('here is the dynamicservice list bro')
      console.log(this.ServicestoIndividuals)  
  });

       await db.collection('Individual2IndividualServices')
  .where("ContributionCategory", "==", this.ContributionCategory)
  .get()
  .then(querySnapshot => {
    querySnapshot.forEach(doc => {
      this.DefaultServicestoIndividuals.push(doc.data());
    });
    console.log('here is the defaultservices bro')
      console.log(this.DefaultServicestoIndividuals)  
  });
      

},


//SECTION - CONTRIBUTOR MEMBERS
//SUBSECTION - DATA PROCESSING

//HERE we take the item we clicked on, and assign the item/user's details to the variable editedContributor 

              async editContributor (item) {
        
        console.log(this.DefaultServicestoIndividuals)
        //very important to note. Every time we open a user, click on a contributing user, we reload services, refer to below funciton for details
        this.ProcessContributorMemberUpdate()
        this.editedIndex = this.ContributingMembersWithSearch.indexOf(item)
        this.editedContributor = Object.assign({}, item)
        
        this.contributordialog = true
        // this.ServicestoIndividuals = this.MinistryServicesbyEditor
        // console.log('here is service to individuals')
        // console.log(this.ServicestoIndividuals)
        //         console.log('here is comboservicenames')
        // console.log(this.ComboBoxServiceNames)
        // this.MinistryContributionstoIndividialNames = this.editedContributor.MinistryContributionstoIndividialNames
        // this.MinistryContributionstoOrganizationsArr = Object.assign({}, item.MinistryContributionstoOrganizationsArr)
        // this.FamilyContributionstoOrganizationsArr = Object.assign({}, item.FamilyContributionstoOrganizationsArr)
        // this.BusinessContributionstoOrganizationsArr = Object.assign({}, item.BusinessContributionstoOrganizationsArr)
        // this.MinistryContributionstoIndividualArr = Object.assign({}, item.MinistryContributionstoIndividualArr)
        // this.FamilyContributionstoIndividualArr = Object.assign({}, item.FamilyContributionstoIndividualArr)
        // this.BusinessContributionstoIndividualArr = Object.assign({}, item.BusinessContributionstoIndividualArr)
  
      },

      //triggered every time we open a user, this function ensures the latest and greatest services are in the array of services to assign to the user
      async ProcessContributorMemberUpdate() {
          this.ServicestoIndividuals = []
          this.ServicestoOrganizations = []
          this.DefaultServicestoIndividuals = []
          this.DefaultServicestoOrganizations = []
              
          await this.getIndividualsServices()
          await this.getOrganizationsServices()
          // this.ServicestoIndividuals = this.MinistryServicesbyEditor
        },

//HERE we aim to to empty all arrays completely, to call the database again. And of course PURPOSE is to cancel the user we were editing so we can edit another instead without conflict 
        async CancelIndContributorMemberUpdate() {
          this.ServicestoIndividuals = []
          this.DefaultServicestoIndividuals = []
          this.contributordialog = false 
        },
        
        async CancelOrgContributorMemberUpdate() {
          this.ServicestoOrganizations = []
          this.DefaultServicestoOrganizations = []
          this.HasOrgServices = false
          this.contributordialog = false 
        },

        async CancelContributorMemberUpdate() {
          //WE NO LONGER USE THIS BECAUSE IF WE SIMPLY UPDATE THE SERVICES THEN, WELL IT WORKS ANYWAY. SO WE SIMPLY UPDATE THE PROCESSCONTRIBUTOR.
          // this.users = []
          // this.contributingmembers = []
          // // this.groupmembers = []          
          // await this.getUsers()          
          // await this.getContributingMembers()
          // await this.getGroupMembers()
          await this.ProcessContributorMemberUpdate       
          
          this.contributordialog = false       
        },
        
//This the the SAVE action for contributorservices. 
//Basically, we check what the contributioncateory is, to know which array to check in the user profile
//Then we check to see if there is anything in that array because that is what we must save
//however, we also need to check if any of this is additional services to add to the general services database
//so therefore we also check the OnlyNewService lengths for individual and organizational
        PrepareAdditionalServices() {

          if (this.ContributionCategory === 'Ministry') {
            if (this.editedContributor.MinistryContributionstoOrganizationsArr) {
                if(this.OnlyNewOrgServNew.length > 0){
                    let newsorgservicelist = this.OnlyNewOrgServNew
                    Array.from(Array(newsorgservicelist.length).keys()).map(x => {
                      this.UploadNewServicesOrg(newsorgservicelist[x])
                      this.SaveContributorMemberOrgServUpdate()
                    })
                    }
                if(this.OnlyNewOrgServNew.length < 1){
                  this.SaveContributorMemberOrgServUpdate()
                }
            }
            if (this.editedContributor.MinistryContributionstoIndividualArr) {
                if(this.OnlyNewIndServNew.length > 0){
                    let newsindservicelist = this.OnlyNewIndServNew
                    Array.from(Array(newsindservicelist.length).keys()).map(x => {
                      this.UploadNewServicesInd(newsindservicelist[x])
                      this.SaveContributorMemberIndServUpdate()
                    })
                    }
                if(this.OnlyNewIndServNew.length < 1){
                  this.SaveContributorMemberIndServUpdate()
                }
            }
          }
          if (this.ContributionCategory === 'Family') {
            if (this.editedContributor.FamilyContributionstoOrganizationsArr) {
                if(this.OnlyNewOrgServNew.length > 0){
                    let newsorgservicelist = this.OnlyNewOrgServNew
                    Array.from(Array(newsorgservicelist.length).keys()).map(x => {
                      this.UploadNewServicesOrg(newsorgservicelist[x])
                      this.SaveContributorMemberOrgServUpdate()
                    })
                    }
                if(this.OnlyNewOrgServNew.length < 1){
                  this.SaveContributorMemberOrgServUpdate()
                }
            }
            if (this.editedContributor.FamilyContributionstoIndividualArr) {
                if(this.OnlyNewIndServNew.length > 0){
                    let newsindservicelist = this.OnlyNewIndServNew
                    Array.from(Array(newsindservicelist.length).keys()).map(x => {
                      this.UploadNewServicesInd(newsindservicelist[x])
                      this.SaveContributorMemberIndServUpdate()
                    })
                    }
                if(this.OnlyNewIndServNew.length < 1){
                  this.SaveContributorMemberIndServUpdate()
                }
            }
          }
          if (this.ContributionCategory === 'Business') {
            if (this.editedContributor.BusinessContributionstoOrganizationsArr) {
                if(this.OnlyNewOrgServNew.length > 0){
                    let newsorgservicelist = this.OnlyNewOrgServNew
                    Array.from(Array(newsorgservicelist.length).keys()).map(x => {
                      this.UploadNewServicesOrg(newsorgservicelist[x])
                      this.SaveContributorMemberOrgServUpdate()
                    })
                    }
                if(this.OnlyNewOrgServNew.length < 1){
                  this.SaveContributorMemberOrgServUpdate()
                }
            }
            if (this.editedContributor.BusinessContributionstoIndividualArr) {
                if(this.OnlyNewIndServNew.length > 0){
                    let newsindservicelist = this.OnlyNewIndServNew
                    Array.from(Array(newsindservicelist.length).keys()).map(x => {
                      this.UploadNewServicesInd(newsindservicelist[x])
                      this.SaveContributorMemberIndServUpdate()
                    })
                    }
                if(this.OnlyNewIndServNew.length < 1){                  
                  this.SaveContributorMemberIndServUpdate()
                }
            }
          }

      },


//PURPOSE of this section is to update 
       UploadNewServicesInd(service) {
           
                    const IndContrServ = {
                  createdfor: this.editedContributor.memberid,
                  createdby: this.UserRecord.id,
                  ContributionCategory: this.ContributionCategory,
                  Name: service,
                  
          }
          db.collection('Individual2IndividualServices').add(IndContrServ)

        
       },
       

        UploadNewServicesOrg(service) {
    

              const Ind2OrgContrServ = {
            createdfor: this.editedContributor.memberid,
            createdby: this.UserRecord.id,
            ContributionCategory: this.ContributionCategory,
            Name: service,
                  
          }
          db.collection('Individual2OrganizationsServices').add(Ind2OrgContrServ)


       },

        async SaveContributorMemberIndServUpdate() {
          if(this.ContributionCategory === 'Ministry') {
          db.collection('users').doc(this.editedContributor.memberid).update({
              MinistryContributionstoIndividualArr: this.editedContributor.MinistryContributionstoIndividualArr,           
          })
          }
          if(this.ContributionCategory === 'Business') {
          db.collection('users').doc(this.editedContributor.memberid).update({
              BusinessContributionstoIndividualArr: this.editedContributor.BusinessContributionstoIndividualArr,
          })
          }
          if(this.ContributionCategory === 'Family') {
          db.collection('users').doc(this.editedContributor.memberid).update({
              FamilyContributionstoIndividualArr: this.editedContributor.FamilyContributionstoIndividualArr,
          })
          }
          

              this.CancelIndContributorMemberUpdate()
            

          
        },
        async SaveContributorMemberOrgServUpdate() {
          if(this.ContributionCategory === 'Ministry') {
          db.collection('users').doc(this.editedContributor.memberid).update({           
              MinistryContributionstoOrganizationsArr: this.editedContributor.MinistryContributionstoOrganizationsArr
          })
          }
          if(this.ContributionCategory === 'Business') {
          db.collection('users').doc(this.editedContributor.memberid).update({         
              BusinessContributionstoOrganizationsArr: this.editedContributor.BusinessContributionstoOrganizationsArr
          })
          }
          if(this.ContributionCategory === 'Family') {
          db.collection('users').doc(this.editedContributor.memberid).update({         
              FamilyContributionstoOrganizationsArr: this.editedContributor.FamilyContributionstoOrganizationsArr
          })
          }
          
            
            this.CancelOrgContributorMemberUpdate()

          
        },
        PrepareContributors() {
          this.ListingContributors = true
        },
        EditContributors() {
          this.EditingContributors = true
        },
        CancelMarkingContributors() {
          this.ListingContributors = false
        },
                CancelEditContributors() {
          this.EditingContributors = false
        },

        ListMemberasFamilyContributor(item){
          this.editedIndex = this.GroupMembersNewArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        item.useriscontributor = true
            this.groupmemberid = this.editedItem.id
            this.memberid = this.editedItem.userid
            this.groupid = this.groupid
            this.ContributionCategory = 'Family'
            this.Contributorprofilefield = 'userfamilycontributorstatus'


            
            const ContributingMember = {
              groupmemberid: this.groupmemberid,
              memberid: this.memberid,
              networkgroupid: this.groupid,
              ContributionCategory: this.ContributionCategory,
              Status: 'Draft'
            }

            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
              
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userfamilycontributorstatus: 'Active',
                  userfamilycontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))
                

        },
        ListMemberasBusinessContributor(item){
          this.editedIndex = this.GroupMembersNewArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        item.useriscontributor = true
            this.groupmemberid = this.editedItem.id
            this.memberid = this.editedItem.userid
            this.groupid = this.groupid
            this.ContributionCategory = 'Family'
            this.Contributorprofilefield = 'userbusinesscontributorstatus'

            
            const ContributingMember = {
              groupmemberid: this.groupmemberid,
              memberid: this.memberid,
              networkgroupid: this.groupid,
              ContributionCategory: this.ContributionCategory,
              Status: 'Draft'
            }


            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userbusinesscontributorstatus: 'Active',
                  userbusinesscontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))
                
        },
        ListMemberasMinistryContributor(item){
          this.editedIndex = this.GroupMembersNewArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        item.useriscontributor = true
            this.groupmemberid = this.editedItem.id
            this.memberid = this.editedItem.userid
            this.groupid = this.groupid
            this.ContributionCategory = 'Ministry'
            this.Contributorprofilefield = 'userministrycontributorstatus'
            
            const ContributingMember = {
              groupmemberid: this.groupmemberid,
              memberid: this.memberid,
              networkgroupid: this.groupid,
              ContributionCategory: this.ContributionCategory,
              Status: 'Draft'
            }           

            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userministrycontributorstatus: 'Active',
                  userministrycontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))                
        },

        ListMemberasContributor(item) {
        this.editedIndex = this.GroupMembersNewArray.indexOf(item)
        this.editedItem = Object.assign({}, item)
        item.useriscontributor = true
            this.groupmemberid = this.editedItem.id
            this.memberid = this.editedItem.userid
            this.groupid = this.groupid
        if (this.GroupCategory === 'Ministry Network')
            this.ContributionCategory = 'Ministry'
            this.Contributorprofilefield = 'userministrycontributorstatus'
        if (this.GroupCategory === 'Family Network')
            this.ContributionCategory = 'Family'
            this.Contributorprofilefield = 'userfamilycontributorstatus'
        if (this.GroupCategory === 'Business Network')
            this.ContributionCategory = 'Family'
            this.Contributorprofilefield = 'userbusinesscontributorstatus'

            
            const ContributingMember = {
              groupmemberid: this.groupmemberid,
              memberid: this.memberid,
              networkgroupid: this.groupid,
              ContributionCategory: this.ContributionCategory,
              Status: 'Draft'
            }

            if (ContributingMember.ContributionCategory === 'Ministry'){

            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userministrycontributorstatus: 'Active',
                  userministrycontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))
                }
            if (ContributingMember.ContributionCategory === 'Family'){

            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
              
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userfamilycontributorstatus: 'Active',
                  userfamilycontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))
                }
            if (ContributingMember.ContributionCategory === 'Business'){

            db.collection('contributingmembers').add(ContributingMember).then(
              db.collection('groupmembers').doc(ContributingMember.groupmemberid).update({
              useriscontributor: true
            }).then(
                
              db.collection('users').doc(ContributingMember.memberid).update({
                  userbusinesscontributorstatus: 'Active',
                  userbusinesscontributorprofilegroup: ContributingMember.networkgroupid
              }).then(

              this.contributorsnackbar = true,
              this.CancelGroupMemberUpdate()              
            )))
                }
        },

        UnListMemberasFamilyContributor(item) {
          alert('we will add this part to remove field value from user document reverse ListMemberasFamilyContributor')
        },
        UnListMemberasMinistryContributor(item) {
          alert('we will add this part to remove field value from user document reverse ListMemberasMinistryContributor')
        },
        UnListMemberasBusinessContributor(item) {
          alert('we will add this part to remove field value from user document reverse ListMemberasBusinessContributor')
        },

        UnListMemberasContributor(item) {
          this.CancelGroupMemberUpdate()
        },

        

       async MergeStuff() {

         console.log('starting MergeStuff')
         
       },


//SECTION END - CONTRIBUTINGMEMBERS

    async getContributorGroups() {
      await this.getGroupMembers()
      await db.collection('groups').where("id", "==", this.GroupMembersContributorGroupIds).onSnapshot(res => {
      const changes = res.docChanges();
      changes.forEach(change => {
        if (change.type === 'added') {
          this.contributorgroups.push({
            ...change.doc.data(),
            id: change.doc.id
          })
        }
      })
    }) 
    },

   getGroupMembers() {
      this.UsersArray.map(user => {
        console.log(user.id,this.$route.params.id)
        db.collection('groupmembers').doc(user.id).collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
          let groupmemberdata = snapshot.data()
          if(typeof groupmemberdata !== 'undefined' && groupmemberdata.Status  && groupmemberdata.Status === 'Active'){
            groupmemberdata.id = this.$route.params.id
            this.groupmembers.push(groupmemberdata)
            console.log(groupmemberdata)
          }
          
          })
      })
        
        },

        
           getUsers() {
             let vm = this
             return new Promise((resolve, reject) => {
            db.collection('users').onSnapshot(res => {
                let listlength = res.docs.length
                const changes = res.docChanges();
                changes.forEach(change => {
                  if (change.type === 'added') {
                    vm.users.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }  
                  if(vm.users.length === listlength){
                    resolve('done getting users')
                  }
                })
              })
             })
        },
          waitForMe() {
    return new Promise((resolve, reject) => {
      setTimeout(() => resolve('Resolved'), 1000)
      
    })
  },

        async getUserGrouprelationship() {
            await this.waitForMe()
            // console.log('getting relationship')
            // console.log(this.RelatedGroupMembersLength)
            // console.log(this.RelatedGroupMembers)
         if (this.RelatedGroupMembersLength > 0) {         
            this.UserisMember = true 
         }
          if (this.RelatedGroupAdministratorsLength > 0) {
             this.UserisAdmin = true
             console.log('User is Admin')
         }    
        },
        AddMember(){
            const GroupMember = { 
            Userid: this.UserRecord.id,
            Groupid: this.groupid,
            Relationship: 'Active Member'
            }
            db.collection('groupmembers').add(GroupMember)
            this.UserisMember = true
        },
    async getGroupInformation() {
      //console.log('getting gorup')
        let groupcollection = db.collection('groups')
        let docref = groupcollection.doc(this.groupid)
        docref.onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var groupdata = snapshot.data()
        
        this.groupName = groupdata.name
        // this.name = groupdata.name
          let vm = this
          // this.getUsers().then(result => {
            // vm.getGroupMembers()
            vm.groupmembers = vm.pushedgroupmembers
          // })

        })
            
        },
            
     }
}
</script>

<style>
.tags-input {
    border: 1px solid #333;
    display: inline-block;
}
.UserName {
    font-size: 10px;
    font-weight: bold;
    color: #474747
}
.Memo {
      font-size: 10px;
}
.userprofileBG {
  align-self: center
}
.v-avatar.outlined {
                  border: 8px solid #048abf;
                  border-radius:100%;

}
.searchguide {
  color: #e4003b;
  font-size: 14px
}

</style>   

    