<template>
  <span :class="MobileView ? 'settingsfont' : ''">
      <!-- <span v-if="field.Icon"><v-icon :dark="DarkIcons">{{field.Icon}}</v-icon> </span> -->
      <span v-if="field.Type === 'Single File Upload' && !field.Primary"><span v-if="field.FeaturedPhoto">
      <v-avatar
            color="grey"
            size="150" 
            tile>           
            <v-btn @click="GetShortLivedURL(Record[field.Name])" v-if="Record[field.Name]" class="blue white--text" icon small absolute top right><v-icon>mdi-eye</v-icon></v-btn>
            <img style="object-fit: cover;" v-if="field.localURL" :src="field.localURL" height="150px">
            <img style="object-fit: cover;" v-if="Record[field.Name] && !field.localURL" :src="Record[field.Name].ThumbURL" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !field.localURL && AppisDarkMode" src="@/assets/ImageHolder_dark.png" height="150px">
            <img style="object-fit: cover;" v-if="!Record[field.Name] && !field.localURL && !AppisDarkMode" src="@/assets/ImageHolder.png" height="150px">
        </v-avatar>
      </span><span v-if="!field.FeaturedPhoto && Record[field.Name]">
          <v-btn color="links" dark small @click="GetShortLivedURL(Record[field.Name])">{{Record[field.Name].filename.substr(0,20)}}</v-btn>
          </span></span>
      <span v-else-if="field.Type === 'Single Line Text'"><span v-if="field.IsPassword">
           <v-text-field readonly dense type="password" v-model="Record[field.Name]"/>
          </span><span v-if="!field.IsPassword">
               {{Record[field.Name]}}
          </span>
      </span>
      <span v-else-if="field.Type === 'Boolean'">
          <v-switch :dense="MobileView" readonly disabled v-model="Record[field.Name]" :label="MobileView ? '' : field.DisplayName" />
      </span>
      <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && UserCanGetLookup && !field.IsMultiple">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <router-link :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id">{{Record[field.Name][field.LookupFieldName]}}</router-link>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
       <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && UserCanGetLookup && field.IsMultiple">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <v-chip small v-for="lu in Record[field.Name]" :key="lu.itemObjKey">{{lu[field.LookupFieldName]}}
          </v-chip>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
      <span v-else-if="field.Type === 'Social Lookup' && Record[field.Name]">
          <span v-if="field.RelatedBuildID !== 'Users'">
          <router-link :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id">{{Record[field.Name][field.LookupFieldName]}}</router-link>
          </span><span v-if="field.RelatedBuildID === 'Users'">
            <v-chip color="configtext" small :to="'/'+field.RelatedLocation.split(' ').join('')+'/'+Record[field.Name].id"
                    >
                <v-avatar size="50" left>
                <img v-if="Record[field.Name].Profile_Photo" :src="Record[field.Name].Profile_Photo" style="object-fit: cover;">
                <img v-if="!Record[field.Name].Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                </v-avatar>
                {{ Record[field.Name].Full_Name }}
            </v-chip>
            </span>
      </span>
      <span v-else-if="field.Type === 'Parent Field' && field.RelatedRecord && Record[field.RelatedRecord.RelationshipField.Name]">
          <!-- {{Record[field.RelatedRecord.RelationshipField.Name][field.ParentFieldObj.Name]}} -->
          <ParentFieldViewerComponent :AppisDarkMode="AppisDarkMode" :CurrentEntity="CurrentEntity" @RefreshField="RefreshField"
            :Record="Record[field.RelatedRecord.RelationshipField.Name]" :field="field.ParentFieldObj" @GetShortLivedURL="GetShortLivedURL" v-if="!field.Refreshing" @UpdateRecordCalculation="UpdateRecordCalculation"
            />
      </span>
      <span v-else-if="field.Type === 'Lookup' && Record[field.Name] && !UserCanGetLookup">
          {{Record[field.Name][field.LookupFieldName]}}
      </span>
      <!-- <span v-else-if="field.Type === 'Social Lookup' && Record[field.Name] && !UserCanGetLookup">
          {{Record[field.Name][field.LookupFieldName]}}
      </span> -->
      <span v-else-if="field.Type === 'Option Set' && Record[field.Name] && !field.IsDynamic">
         {{Record[field.Name].Name}}
      </span>
      <span v-else-if="field.Type === 'Option Set' && Record[field.Name] && field.IsDynamic">
    
          <v-select small-chips return-object readonly v-model="Record[field.Name].Options" :items="Record[field.Name].Options" multiple :label="field.DisplayName" item-text="Name"></v-select>
      </span>
      <span v-else-if="field.Type === 'Date' && Record[field.Name] && Record[field.Name].toDate && !field.GraphicDisplay">
          {{DateFieldValue(Record[field.Name].toDate(),field)}}
      </span>
      <span v-else-if="field.Type === 'Date' && Record[field.Name] && Record[field.Name].toDate && field.GraphicDisplay">
         <v-card style="-webkit-transform: scale(0.7);" :class="$vuetify.theme.dark ? '' : 'grey lighten-2'">
            <v-list-item :class="$vuetify.theme.dark ? field.GraphicColor+' darken-3' : field.GraphicColor+' darken-1 white--text'" style="text-align: center;">
                <v-list-item-content>
                    <v-list-item-subtitle class="white--text">
                        {{DateGraphicValue(Record[field.Name],'Year')}}
                    </v-list-item-subtitle>
                    <v-list-item-title style="font-size: 1.8em;">
                        {{DateGraphicValue(Record[field.Name],'Week Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- <span style="display: inline-block;" class="red">s
            </span> -->
            <!-- <v-card style="padding:10px;" outlined>
                <v-card-subtitle :class="field.GraphicColor+' lighten-2 white--text'" style="text-align: center;" >
                    {{DateGraphicValue(Record[field.Name],'Month Name')}}
                </v-card-subtitle>
                <v-card-text class="display-2" style="text-align: center;">
                     {{DateGraphicValue(Record[field.Name],'Day')}}
                </v-card-text>
            </v-card> -->
            <!-- <v-list-item style="text-align: center;padding:5px;" :class="$vuetify.theme.dark ? 'grey darken-3' : 'white'">                
                <v-list-item-content :class="$vuetify.theme.dark ? field.GraphicColor : 'white '+field.GraphicColor+'--text'">
                    <v-list-item-title :class="field.GraphicColor+' lighten-2 white--text'" style="padding:0px;height: 100%;padding: 10px;">
                        {{DateGraphicValue(Record[field.Name],'Month Name')}}
                    </v-list-item-title>
                    <v-divider></v-divider>
                    <v-list-item-title class="display-2">
                        {{DateGraphicValue(Record[field.Name],'Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->
            <v-list-item style="text-align: center;padding:5px;" :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">                
                <v-list-item-content :class="$vuetify.theme.dark ? field.GraphicColor : 'white '+field.GraphicColor+'--text'">
                    <v-list-item-title style="padding:0px;">
                        {{DateGraphicValue(Record[field.Name],'Month Name')}}
                    </v-list-item-title>
                    <v-divider :class="''"></v-divider>
                    <v-list-item-title class="display-2">
                        {{DateGraphicValue(Record[field.Name],'Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-card>
      </span>
      <span v-else-if="field.Type === 'Common Field' && field.CommonFieldType === 'Email'">
          <a :href="'mailto:'+Record[field.Name]" target="_top">{{Record[field.Name]}}</a>
      </span>
       <span v-else-if="field.Type === 'Common Field' && field.CommonFieldType === 'Telephone Number'">
           <a :href="'callto:'+Record[field.Name]" target="_top">{{Record[field.Name]}}</a>
      </span>
       <span v-else-if="field.Type === 'Common Field' && field.CommonFieldType === 'URL Link'">
           <a :href="Record[field.Name]" target="blank">{{Record[field.Name]}}</a>
      </span>
      <span v-else-if="field.CommonFieldType === 'QR Link'" style="padding-top:10px;padding-bottom:10px;">
             
    <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Current Record' && System.ClientAppURL">
        <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+System.ClientAppURL.split(' ').join('').split('firebaseapp.com').join('web.app')+'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+Record.id" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Current Record' && System.ClientAppURL">
            <v-btn @click="DownloadQR(System.ClientAppURL.split(' ').join('').split('firebaseapp.com').join('web.app'),'/'+CurrentEntity.SingleName.split(' ').join('')+'/'+Record.id,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Related Record' && System.ClientAppURL && field.QRLinkFieldLookup">
        <img v-if="Record[field.QRLinkFieldLookup.Name]" :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+System.ClientAppURL.split(' ').join('').split('firebaseapp.com').join('web.app')+'/'+field.QRLinkFieldLookup.RelatedLocation.split(' ').join('')+'/'+Record[field.QRLinkFieldLookup.Name+'id']" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Related Record' && System.ClientAppURL && field.QRLinkFieldLookup">
            <v-btn @click="DownloadQR(System.ClientAppURL.split(' ').join('').split('firebaseapp.com').join('web.app'),'/'+CurrentEntity.SingleName.split(' ').join('')+'/',Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Link Field' && System.ClientAppURL && field.QRLinkFieldLink">
        <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+field.QRLinkFieldLink" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Link Field' && System.ClientAppURL"><v-btn @click="DownloadStaticQR(field.QRLinkFieldLink,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
    <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Static URL' && field.StaticQRURL">
        <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='+field.StaticQRURL" height="150px" style="object-fit: cover;"/>
        </v-layout>
        <v-layout row class="justify-start my-1" v-if="field.QRLinkFieldType === 'Static URL' && System.ClientAppURL"><v-btn @click="DownloadStaticQR(field.StaticQRURL,Record[CurrentEntity.Primary_Field_Name]+' '+field.Name)" dark width="150px">Download<v-icon>mdi-download</v-icon></v-btn>
        </v-layout>
        </span>
       <span v-else-if="field.Type === 'Multiple Lines Text' && field.IsContentEditable" v-html="Record[field.Name]">
          
      </span>
      <span v-else-if="field.Type === 'Route Path'">
          <v-btn plain text :to="Record[field.Name]">{{Record[field.Name]}}</v-btn>
      </span>
      <span v-else-if="field.Type === 'Calculated Field'">
          <span v-if="field.IsCurrency && PerformedCalculation">
              {{CurrencyFormatter(PerformedCalculation,$store.state.DefaultCurrency.Currency)}}
          </span>
          <span v-if="!field.IsCurrency">
          {{PerformedCalculation}}
          </span>
      </span>
       <span v-else-if="field.Type === 'Rollup Calculation' && Record.RelatedTables">
            <span v-if="field.IsCurrency && RollupValue">
              {{CurrencyFormatter(RollupValue,$store.state.DefaultCurrency.Currency)}}
          </span>
          <span v-if="!field.IsCurrency">
          {{RollupValue}}
          </span>
      </span>
    <span v-else-if="field.Type === 'Radio Group'">
        <v-select item-text="Name" dense filled readonly multiple return-object :items="field.Options" v-model="Record[field.Name]" />
      </span>
      <span v-else-if="field.Type === 'Custom Object'">
        <v-list class="transparent" v-if="Record[field.Name]">
            <v-list-item v-for="prop in Record[field.Name]" :key="prop.itemObjKey">
            
                <span v-if="prop.Type === 'Boolean'">
                    <v-switch readonly class="smallinput" v-model="prop.Response" :label="prop.Label"></v-switch></span>
                    <span v-if="prop.Type === 'Single Line Text'">
                    <v-text-field readonly class="smallinput"  v-model="prop.Response" :label="prop.Label"></v-text-field></span>
                    <span v-if="prop.Type === 'Number Field'">
                    <v-text-field readonly :rules="[rules.numberfield]" v-model.number="prop.Response" type="number" :label="prop.Label"></v-text-field></span>
            </v-list-item>	
        </v-list>
      </span>
      <span v-else-if="field.Type === 'Date' && Record[field.Name] && !Record[field.Name].toDate && !field.GraphicDisplay">
         {{DateFieldValue(Record[field.Name],field,true)}}
      </span>
      <span v-else-if="field.Type === 'Date' && Record[field.Name] && !Record[field.Name].toDate && field.GraphicDisplay">
        <v-card style="-webkit-transform: scale(0.7);" :class="$vuetify.theme.dark ? '' : 'grey lighten-2'">
            <v-list-item :class="$vuetify.theme.dark ? field.GraphicColor+' darken-3' : field.GraphicColor+' darken-1 white--text'" style="text-align: center;">
                <v-list-item-content>
                    <v-list-item-subtitle class="white--text">
                        {{DateGraphicValue(Record[field.Name],'Year')}}
                    </v-list-item-subtitle>
                    <v-list-item-title style="font-size: 1.8em;">
                        {{DateGraphicValue(Record[field.Name],'Week Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <!-- <span style="display: inline-block;" class="red">s
            </span> -->
            <!-- <v-card style="padding:10px;" outlined>
                <v-card-subtitle :class="field.GraphicColor+' lighten-2 white--text'" style="text-align: center;" >
                    {{DateGraphicValue(Record[field.Name],'Month Name')}}
                </v-card-subtitle>
                <v-card-text class="display-2" style="text-align: center;">
                     {{DateGraphicValue(Record[field.Name],'Day')}}
                </v-card-text>
            </v-card> -->
            <!-- <v-list-item style="text-align: center;padding:5px;" :class="$vuetify.theme.dark ? 'grey darken-3' : 'white'">                
                <v-list-item-content :class="$vuetify.theme.dark ? field.GraphicColor : 'white '+field.GraphicColor+'--text'">
                    <v-list-item-title :class="field.GraphicColor+' lighten-2 white--text'" style="padding:0px;height: 100%;padding: 10px;">
                        {{DateGraphicValue(Record[field.Name],'Month Name')}}
                    </v-list-item-title>
                    <v-divider></v-divider>
                    <v-list-item-title class="display-2">
                        {{DateGraphicValue(Record[field.Name],'Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->
            <v-list-item style="text-align: center;padding:5px;" :class="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-2'">                
                <v-list-item-content :class="$vuetify.theme.dark ? field.GraphicColor : 'white '+field.GraphicColor+'--text'">
                    <v-list-item-title style="padding:0px;">
                        {{DateGraphicValue(Record[field.Name],'Month Name')}}
                    </v-list-item-title>
                    <v-divider :class="''"></v-divider>
                    <v-list-item-title class="display-2">
                        {{DateGraphicValue(Record[field.Name],'Day')}}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-card>
      </span>
      <span v-else-if="field.Type === 'Number Field' && Record[field.Name] && field.IsCurrency">
          {{CurrencyFormatter(Record[field.Name],$store.state.DefaultCurrency.Currency)}}
      </span>
      <span v-else-if="field.Type === 'Number Field' && Record[field.Name] && field.IsPercentage">
          {{Record[field.Name]}}%
      </span>
      <span v-else>
          {{Record[field.Name]}}
      </span>
       </span>
</template>

<script>
import LookupComponent from '@/components/Database/LookupComponent'
import ParentFieldViewerComponent from '@/components/Database/ParentFieldViewerComponent'

export default {
    props: ['field','Record','AppisDarkMode','DarkIcons','CurrentEntity'],
    components: {
        ParentFieldViewerComponent
    },
    data(){
        return {
           
        }
    },
    computed:{
         MobileView(){
        return this.$vuetify.breakpoint.xs
      },
      SmallView(){
        return this.$vuetify.breakpoint.sm
      },
      MiniView(){
            return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs
        },
        RollupValue(){
           let table = ''
           if(this.field.TableType === 'Related Data'){
                table =  this.Record.RelatedTables.find(obj => obj.id === this.field.RelatedTable)
                if(table){
                    return table.StoreData.map(item => {                
                    return this.PerformCalulation(item,this.field,true)
                    }).reduce((a, b) => a + b, 0)
                } 
           }
           else{
               table = this.Record.SubCollections.find(obj => obj.id === this.field.RelatedTable)
                if(table){
                    //console.log('table,table.Data,Record',table,table.Data,this.Record)
                    return table.Data.map(item => {                
                    return this.PerformCalulation(item,this.field,true)
                    }).reduce((a, b) => a + b, 0)
                }
           }
          
        },
        PerformedCalculation(){
            return this.PerformCalulation(this.Record,this.field)
        },
        userIsAdmin () {
        return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        userBUID () {
        return this.$store.getters.userBUID
        },
        userBUChildren () {
        return this.$store.getters.userBUChildren
        },
        userBUParents () {
        return this.$store.getters.userBUParents
        },
        LookupRecord(){
            if(this.Record[this.field.Name]){
                //console.log(this.Record[this.field.Name].id,this.field,this.Record[this.field.Name])
                let matchingrecord = this.field.Array.find(obj => obj.id === this.Record[this.field.Name].id)
                if(matchingrecord){
                    return matchingrecord
                }
                else{
                    return this.Record[this.field.Name]
                }
            }
        },
        LookupRoute(){
            let routes = this.$router.getRoutes()
            let routeitem = routes.find(obj => obj.meta && obj.meta.RouteEventID === 1029 && obj.meta.EntityID === this.field.RelatedBuildID)
            return routeitem
        },
        LookupRouteName(){
            return this.LookupRoute ? this.LookupRoute.path.split('/').join('') : ''
        },
        LookupRouteSecurity(){
            return this.LookupRoute ? this.LookupRoute.meta.Security : ''
        },
        LookupRouteSecurityType(){
            return this.LookupRouteSecurity ? this.LookupRouteSecurity.SecurityType : ''
        },
        UserCanGetLookup(){
            let systemrecord = this.LookupRecord
            //console.log('LookupRecord',systemrecord,this.field,this.$store.state,this.LookupRouteName)
            if(systemrecord && this.LookupRouteSecurityType === 'Business Unit'){
                //console.log('LookupRouteName',this.LookupRouteName+'viewAll',this.LookupRouteName+'viewUnitdown',this.LookupRouteName+'viewUserUnit',this.LookupRouteName+'viewOwner')
                if(this.$store.state[this.LookupRouteName+'viewAll']){
                    return true
                }
                else if(this.$store.state[this.LookupRouteName+'viewUnitdown']){
                    //LookupRecord
                    return this.userBUChildren.includes(systemrecord.Business_Unitid)
                }
                else if(this.$store.state[this.LookupRouteName+'viewUserUnit']){
                    //LookupRecord
                    return systemrecord.Business_Unitid === this.userBUID
                }
                else if(this.$store.state[this.LookupRouteName+'viewOwner']){
                    //LookupRecord
                    return systemrecord.Ownerid === this.userLoggedIn.id
                }
            }
            else if(systemrecord && this.LookupRouteSecurityType === 'Custom Roles'){
                return this.$store.state[this.LookupRouteName+'CanView']
            }
        },
        System(){
            return this.$store.state.SystemObj
        }
    },
    created(){
        
    },
    methods:{
        DateGraphicValue(javadate,format){
            let d = new Date()
            let string = ''
            const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]
var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
            if(typeof javadate === 'string'){
                d = new Date(javadate)
            }
            else{
                d = javadate
            }
            if(format==='Year'){
                string = d.getFullYear()
            }
            else if(format==='Week Day'){
                string = days[d.getDay()].Longtext
            }
            else if(format==='Month Name'){
                string = monthNames[d.getMonth()]
            }
            else if(format==='Day'){
                string = d.getDate()
            }
            return string
                        
        },
        DateFieldValue(javadate,field,direct,asobject){
            let date = ''
            if(typeof javadate === 'string'){
                date = new Date(javadate)
            }
            else{
                date = javadate
            }
            if(!direct){
             if(field.HasTimeInput){
                return date
                }
                else{
                    return date.toDateString()
                }   
            }
            else{
                if(field.HasTimeInput){
                    return date+this.Record[field.Name+'TimeResponse']
                }
                else{
                    return date.toDateString()
                }
            }
            
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        PerformCalulation(Record,field,IsRollup){
            console.log('PerformCalulation',Record,field)
            let result = 0
                let formulas = JSON.parse(JSON.stringify(field.FieldFormulas))
                formulas = formulas.map((formula,i) => {                
                    if(formula.input === 'percentage field' && formula.fieldName || formula.input === 'field' && formula.fieldName){
                        if(Record[formula.fieldName.Name]){
                            formula.integer = Record[formula.fieldName.Name]
                            //console.log(formula.integer)
                            if(formula.input === 'percentage field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                                formula.integer = Record[formulas[i-1].fieldName.Name]*(formula.integer/100)
                            }
                            else if(formula.input === 'field' && formula.fieldName){
                                formula.integer = Record[formula.fieldName.Name]
                            } 
                            //console.log(formula.integer)
                        }
                    }
                    return formula
                })
                formulas.map((formula,i) => {
                    if(i === 0){
                        result = formula.integer
                    }
                    else if(formulas[i-1].operation === '-'){
                        result = result-formula.integer
                    }
                    else if(formulas[i-1].operation === '+'){
                        result = result+formula.integer
                    }
                    else if(formulas[i-1].operation === '*'){
                        result = result*formula.integer
                    }
                    else if(formulas[i-1].operation === '/'){
                        result = result/formula.integer
                    }
                    else if(formulas[i-1].operation === '='){
                        result = result
                    }
                })
                // if(IsRollup)
            this.$emit('UpdateRecordCalculation',this.Record,field,result)
            return result
        },
        UpdateRecordCalculation(RecordObj,field,result){
            this.$emit('UpdateRecordCalculation',RecordObj,field,result)
        },
        GetShortLivedURL(fileobject){
            this.$emit('GetShortLivedURL',fileobject)
        },
        DownloadQR(appurl,path,final,name){
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path+final
      var hostingURL = appurl  
      var QRUrl = hostingURL+RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
    DownloadStaticQR(path,name){
      var QRAPI = "https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data="
      var options = {
                      method: 'GET',
                      mode: 'cors'
                    }
      var RoutingUrl = path  
      var QRUrl = RoutingUrl
      var FetchURL = QRAPI+QRUrl
      fetch(FetchURL, options).then((resp) => resp.blob()
        .then(QRimage => {
        var QRLocalURL = URL.createObjectURL(QRimage)
            var element = document.createElement('a')
            element.setAttribute('href',QRLocalURL)
            element.setAttribute('download', name+' QRCode')
            element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
      )
    },
    }
}
</script>

<style>

</style>