<template>
  <v-menu      									
    :close-on-content-click="false"									
    :nudge-right="40"									
    transition="scale-transition"									
    offset-y									
    min-width="400px"									
    >									
    <template v-slot:activator="{ on }">									
<v-icon v-on="on">mdi-image-edit-outline</v-icon>									
    </template>									
    <v-layout row class="justify-center">									
<v-card width="100%" max-width="1200px" flat style="padding-bottom:50px" dark>									
<v-pagination									
    :total-visible="9"									
    v-model="SystemAssetsPage"									
    circle									
    :length="Math.round(SystemAssets.length/10)"									
    ></v-pagination>									

<v-layout row wrap class="justify-center">									
    <v-card flat tile class="mx-3 my-3 transparent" 									
    v-for="photo in SystemAssetsPaginated"									
    :key="photo.itemObjKey"									
    max-width="200px"									
                                        
    >    									
        <v-img									
        :src="photo.Path"									
        aspect-ratio="1"									
        width="100"									
        >									
        <template v-slot:placeholder>									
            <v-row									
            class="fill-height ma-0"									
            align="center"									
            justify="center"									
            >									
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>									
            </v-row>									
        </template>									
        </v-img>									
        <v-btn dark color="green" icon									
        @click="AssignImage(item,Prop,photo)">									
        <v-icon>									
        mdi-check									
        </v-icon>									
    </v-btn>									
                
        </v-card>									
</v-layout>									
</v-card>									
    </v-layout>									
                                
</v-menu>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'

export default {
    props: ['item','Prop','URLOnly'],
    components: {

    },
    data(){
        return{
            SystemAssetsPage: 1,
            SystemAssets: []
        }        
    },
    computed:{
        SystemAssetsPaginated(){									
            let displayrange = 10*this.SystemAssetsPage-10									
            return this.SystemAssets.slice(displayrange, displayrange+10) 
        },
    },
    created(){
        this.GetSystemAssets()
    },
    methods:{
        AssignImage(item,prop,photo){
            console.log(item)
            if(this.URLOnly){
               item[prop] = photo.url
            }
            else{
                item[prop] = photo 
            }
            
        },
        GetSystemAssets(){
            db.collection('systemassets').onSnapshot(res => {
                const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.SystemAssets.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
            })
        }
    }

}
</script>

<style>

</style>