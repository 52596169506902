<template>
  <v-card flat tile class="transparent" width="100%" height="100%">
      <div v-if="!ActiveRecord">
      <!-- <v-tabs centered v-if="!ActiveRecord">
          <v-tab>List</v-tab>
          <v-tab-item> -->
              <v-select dense :items="ByDaysOptions" v-model="ByDays" label="By Days"/>
      <v-list
      
         :dark="AppisDarkMode"
        dense                
        nav
        
        v-for="item in items"
        :key="item.title"
        >
         
        <v-list-item class="overline">
           <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
        <!-- <v-list-item-icon>
            <v-icon :color="ThemeColor">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title :class="ThemeColor+'--text'">{{ item.title }}</v-list-item-title>
            </v-list-item-content> -->
        </v-list-item>
        <v-divider/>
        <v-list  :dark="AppisDarkMode"  v-if="GetMatchingStatus(item).length>0">
        <v-list-item v-for="poll in GetMatchingStatus(item)" :key="poll.itemObjKey"  @click="PushItem(poll)">
            <!-- <v-list-item-avatar>
                    <v-icon small>mdi-vote</v-icon>
                </v-list-item-avatar> -->
            <v-list-item-content>
             <v-list-item-title>
                 {{ poll.PollName }}
                 </v-list-item-title>                    
                <v-list-item-subtitle>
                <v-progress-linear :color="poll.Color" :value="poll.Value"></v-progress-linear>
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                {{poll.Votes.length}} out of {{UsersArray.length}} Voted
                </v-list-item-subtitle>                          
            </v-list-item-content>
            <v-list-item-content>
            <v-list-item-title class="justify-end">
                 <h2 :class="poll.WeekIncreaseColor+'--text justify-end caption'"><v-icon :color="poll.WeekIncreaseColor">mdi-menu-up</v-icon>
                        {{poll.PercentageUpThisWeek}}%</h2>
                 </v-list-item-title>                    
                <v-list-item-subtitle class="caption justify-end">
                    {{ByDays}} days
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="poll.NotificationCount && poll.NotificationCount>0">
                      <v-menu
                          open-on-hover
                          top
                          offset-y
                        >
                         <template v-slot:activator="{ on, attrs }">
                      <v-icon color="red" size="20" v-bind="attrs" v-on="on">
                        mdi-bell-check
                        </v-icon>
                        <v-badge color="warning" class="mx-1" v-if="poll.NotificationCount && poll.NotificationCount>0" overlap>
                            <span slot="badge"> {{poll.NotificationCount}}</span>
                            </v-badge>
                         </template>
                         <v-card max-width="400">
                           <v-card-title>
                             Notifications
                           </v-card-title>
                           <v-card-text class="caption"  v-html="poll.Notice">

                           </v-card-text>
                         </v-card>
                         
                      </v-menu>
                    </v-list-item-action>
        </v-list-item>
    </v-list>
    <v-list :dark="AppisDarkMode" v-else>
        <v-list-item >
            <v-list-item-icon>
            <v-icon color="red" small>mdi-cancel</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title  style="font-weight:300;"><i>There are no items for this Status at the moment</i></v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-list>
    </v-list>
          <!-- </v-tab-item>
          <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
               You know, this is possibly "Stats" as in relating to User, and Polls in geenral. It may become useful but nothing for now
            </v-tab-item>
      </v-tabs> -->
      </div>
        <v-card-title v-if="ActiveRecord">
        <v-btn  @click="ActiveRecord=''">Back</v-btn>
        <v-btn :to="$route.name === 'MyDashboard' ? '/SitePoll/'+ActiveRecord.id : '/Group-Poll/'+$route.params.id+'/Poll/'+ActiveRecord.id">View</v-btn>
        <v-spacer></v-spacer>
         <v-icon color="red">mdi-delete-forever</v-icon>
        </v-card-title>
        <!-- {{ActiveRecord}} -->
        <div v-if="ActiveRecord"> 
        <!-- <v-tabs v-if="ActiveRecord" centered>            
           <v-tab>
                Votes
            </v-tab>
            <v-tab-item> -->
                <SitePollsChart :ChartDatalength="ActiveRecord.Votes.length" @Viewtable="Viewtable"  :selectedLabelIdentifier="selectedLabelIdentifier" :selectedLabel="selectedLabel" @ActivateActiveChart="ActivateActiveChart" @ChartDataSelect="ChartDataSelect" :Labels="Labels" :ChartData="ChartData" />
                <v-data-table
      :headers="headers"
      :items="ComputedPollVotes"
      class="elevation-1"
      dark
       :style="{ backgroundImage: 'url(' + require('@/assets/RABaseBG.jpeg') + ')' }"
      id="groupvotesdatatable"
    >
      <template v-slot:top>
        <v-toolbar src="@/assets/RABaseBG.jpeg" flat color="primary white--text">
          <v-toolbar-title>{{ActiveRecord.PollName}} Votes</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="search"
            append-icon="mdi-file-find-outline"
            label="Search"
            single-line
            hide-details
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search !== ''" class="mx-10">mdi-close</v-icon>
          <v-spacer></v-spacer>
        </v-toolbar>
  
  <v-toolbar v-if="ActiveRecord.Votes" flat dense dark color="secondary">
          <v-layout col class="justify-start">
            <h3 class="my-8"> Filters</h3><v-spacer></v-spacer>
            <v-icon @click="ResetFilters()">mdi-close</v-icon>
            </v-layout>
          </v-toolbar>
      </template>
        <template v-slot:[`item.Voter`]="{ item }">
      <v-avatar size="35">
        <v-img :src="item.voterimg" v-if="item && item.voterimg"/>
        <v-img src="@/assets/BlankProfilePic.png" v-if="!item || !item.voterimg"/>
        </v-avatar>
        <span v-if="item.Voter">{{' '+item.Voter.Full_Name}}</span>
    </template>
    <template v-slot:[`item.createdon`]="{ item }">
        <span  v-if="item.createdon.toDate !== 'undefined'">
        {{item.createdon.toDate()}}</span>
        <span v-if="typeof item.createdon.toDate === 'undefined'">{{item.createdon}}</span>
    </template>
    <template v-slot:[`item.option`]="{ item }">
        <span>{{item.option.Name}}</span>
    </template>
      <template v-slot:[`item.action`]="{ item }">
      <v-layout class="justify-center">
        <v-list dense  class="transparent" v-if="item">
          <v-list-item>
            <v-list-item-content>
              <v-icon
                small v-if="item.CanEdit"
                class="mr-2"
                @click="editItem(item)"
                color="orange"
              >
                mdi-pencil
              </v-icon>
            </v-list-item-content>          
            <v-list-item-content>
              <v-icon
                small v-if="item.CanView"
                @click="ViewItem(item)"
                color="blue"
              >
                mdi-eye
              </v-icon>
              </v-list-item-content>
            <v-list-item-content>
              <v-icon
                small v-if="item.CanDelete"
                @click="deleteItem(item)"
              >
                mdi-trash-can-outline
                color="red"
              </v-icon>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        </v-layout>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>
    </v-data-table>
        </div>
            <!-- </v-tab-item>
            <v-tab>
                Stats
            </v-tab>
            <v-tab-item>
                TBD
            </v-tab-item>
        </v-tabs> -->
  </v-card>
</template>

<script>
import format from 'date-fns/format'
import firebase from 'firebase';
import db from '@/main'
import ItemNotifications from '@/components/Dashboards/ItemNotifications'
import SitePollsChart from '@/components/SuitePlugins/Polls/SitePollsChart'

export default {
 props: ['AppisDarkMode','ThemeColor','ActiveSession','UsersArray','MenuItem'],
    components: {
        ItemNotifications,
        SitePollsChart

    },
    data(){
        return{
            headers: [
              { text: 'Voter', value: 'Voter'},
              { text: 'createdon', value: 'createdon'},
              { text: 'option', value: 'option'},
              // { text: 'Owner', value: 'Owner'},
              // { text: 'Business Unit', value: 'option.Name'},
              { text: 'Actions', value: 'action', sortable: false },
            ],
            chartview: false,
            Labels: [],
            ChartData: [],
            selectedData: {},
            selectedLabel: {},
            selectedLabelIdentifier: 'Name',
            ByDays: 7,
            ByDaysOptions: [7,14,30,60,90,120,150,300],
            slidermin: 0,
            slidermax: 100,
            slidervalue: 5,
            items: [
                {
                    icon: 'mdi-blogger',
                    title: 'Draft'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Internal)'
                },
                {
                    icon: 'mdi-blogger',
                    title: 'Published (Public)'
                },
            ],
            ActiveRecord: '',
        SitePolls: [],
        search: '',
        cardsPerPage: 6,
        CardPage: 1,
        activeChart: ''
        }
    },
    computed:{
        ComputedPollVotes(){
        return this.ActiveRecord.Votes.filter(vote => {
          if(this.selectedLabel && this.selectedLabel.Name){
            return vote.option.Name === this.selectedLabel.Name
          }
          else{
            return vote
          }
        })
      },
        slidercolor(){
            let redcolor = Math.round(100-this.slidervalue)*2.55
            let greencolor = Math.round(this.slidervalue*2.55)
            return 'rgb('+redcolor+','+greencolor+',0)'
        },
        ComputedNotificationsQuery(){
            return db.collection('notifications').where('pollid','==',this.ActiveRecord.id)
        },
            SitePollsSearched(){
                return this.SitePolls.map(poll => {
                    poll.Status = 'Active'
                    return poll
                })
            },
            userIsAdmin () {
                return this.$store.state.IsAdmin
            },
            userLoggedIn () {
                return this.$store.getters.user
            },
            userBUID () {
                return this.$store.getters.userBUID
            },
            userBUChildren () {
                return this.$store.getters.userBUChildren
            },
            userBUParents () {
                return this.$store.getters.userBUParents
            },
            VotesbyOption(){

            return this.ActiveRecord.PollOptions.map(pollvote => {
            return pollvote.Name
            }).flat()
        },
        VotesbyOptionData(){
            return this.ActiveRecord.PollOptions.map(pollvote => {
            // console.log(pollvote)
            pollvote.Count = 0
            this.ActiveRecord.Votes.map(vote => {
                if(vote.option.Name === pollvote.Name){
                pollvote.Count = pollvote.Count-1+2
                }
            })
            
            return pollvote
            }).map(pollvote => {
            return pollvote.Count
            })
        },
    },
    created(){
        this.GetQuery(this.MenuItem)
        //this.GetPolls()
    },
    watch: {
      ActiveRecord(value){
          if (value !== null && value !== undefined ) {
              this.Attending_Users = []
          
          
          }
      },
    },
    methods:{
      GetQuery(item){
            if(this.$route.params.id && item && item.groupcollectionname){
           
                let query = db.collection(item.groupcollectionname).where(item.groupcolfield,'==',this.$route.params.id).where('Ownerid','==',this.userLoggedIn.id)
                console.log(item.groupcollectionname,'where ',item.groupcolfield,'==',this.$route.params.id)
                this.GetPolls(query)
            }
            else if(item && item.sitecollectionname){
               let query = db.collection(item.sitecollectionname).where(item.sitecolfield,'==',this.userLoggedIn.id) 
               console.log(item.sitecollectionname,'where ',item.sitecolfield,'==',this.userLoggedIn.id)
               this.GetPolls(query)
            }
        },
        ChartDataSelect (chart, labelindex) {
    if(typeof chart !== 'undefined' && typeof labelindex !== 'undefined'){ 
      if(this.activeChart === 'Votes by Option'){
        this.selectedLabel = this.ActiveRecord.PollOptions[labelindex] 
        this.selectedLabelIdentifier = 'Name'  

        this.selectedData = this.ActiveRecord.PollOptions.filter(vote => {
          return vote.option.Name === this.selectedLabel.id
        })
      } 
    }
  },
        ActivateActiveChart(chart){
    // console.log(chart)
    this.activeChart = chart 
     if(this.activeChart === 'Votes by Option'){
      this.Labels = this.VotesbyOption
      this.ChartData = this.VotesbyOptionData
      // console.log(this.Labels)
      // console.log(this.ChartData)

    } 
    else{
      // console.log('should cancel all values')
      this.selectedLabelIdentifier = ''
      this.selectedLabel = ''
      this.chartview = false
      this.activeChart = ''
      this.selectedData = ''        
    }
  },
        Viewtable(){
    let datatableelmnt = document.getElementById('groupvotesdatatable')
    datatableelmnt.scrollIntoView();
  },
        TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        //console.log(finaldate)
        return finaldate
      },
        GetProgressColor(total,actual){
            return Math.round(actual/total)*100
        },
       GetMatchingStatus(item){
         if(this.SitePolls){
           return this.SitePolls
            .filter(blog => {
                return blog.PublishStatus === item.title
            })
            .map(blog => {
              let votes = blog.Votes.length
                if(item.title === 'Draft'){
                    blog.class = 'warning--text'
                }
                if(votes === 0){
                  blog.Value = 0
                }
                else{
                 blog.Value = Math.round((votes/this.UsersArray.length)*100) 
                }                
                let redcolor = Math.round(100-blog.Value)*2.55
                let greencolor = Math.round(blog.Value*2.55)                 
                blog.Color = 'rgb('+redcolor+','+greencolor+',0)'
                // else {
                //     blog.class = 'grey lighten-3--text'
                // }
                console.log(blog.Votes)
                blog.Votes.map(vote => {
                    vote.PrimaryDateSTRING = this.TimestampFormatterSTRING(vote.createdon.toDate())
                    vote.PrimaryDateObj = new Date(vote.PrimaryDateSTRING)
                })
                let date = new Date()
                    date.setHours(0,0,0,0)                    
                    let d = new Date(date.getFullYear(), date.getMonth(), date.getDate()-this.ByDays)
                    console.log('d',d)
                let votesthisweek = blog.Votes.filter(vote => {
                  //console.log(PrimaryDateObj)
                    
                    return vote.PrimaryDateObj >= d
                }).length
                console.log('votesthisweek',votesthisweek)
                if(votes === 0){
                    blog.PercentageUpThisWeek = 0
                }
                else{
                    blog.PercentageUpThisWeek = Math.round((votesthisweek/blog.Votes.length)*100)
                }
                if(blog.PercentageUpThisWeek > 0){
                    blog.WeekIncreaseColor = 'green'
                }
                return blog
            })
         }
         else{
           return []
         }
            
            
        },
        PushItem(item){
            this.ActiveRecord = this.SitePollsSearched.find(obj => obj.id === item.id)
        },
        GetPolls(query){
          let subcol = ''
          let votescol = ''
            if(this.$route.params.id){
                subcol = 'grouppollvotes'
                votescol = db.collection(subcol).where('groupid','==',this.$route.params.id)
            }
            else{
                subcol = 'sitepollvotes'
                votescol = db.collection(subcol)
            }
            query.onSnapshot(res => {
                let pollslength = res.docs.length
                // alert(pollslength)
                const changes = res.docChanges();
                  changes.forEach(change => {
                  if (change.type === 'added') {
                      
                          let pollobj ={
                      ...change.doc.data(),
                      id: change.doc.id
                      }
                      pollobj.Votes = []
                      // console.log(pollobj)
                      

                votescol.where('Pollid','==',pollobj.id).onSnapshot(votesres => {
                    const voteschanges = votesres.docChanges();
                    let listlength = votesres.docs.length
                    if(listlength === 0){
                      this.SitePolls.push(pollobj)
                      if(this.SitePolls.length === pollslength){
                            this.$emit('PushArray',this.MenuItem,this.SitePolls)
                        }
                    }
                    voteschanges.forEach(votechange => {
                    if (votechange.type === 'added') {
                        pollobj.Votes.push({
                        ...votechange.doc.data(),
                        id: change.doc.id
                        })
                    }
                    if(pollobj.Votes.length === listlength){
                        this.SitePolls.push(pollobj)
                        if(this.SitePolls.length === pollslength){
                            this.$emit('PushArray',this.MenuItem,this.SitePolls)
                        }
                    }
                })
                      })
                  }
              })
            })
        }
    }

}
</script>

<style>

</style>


    