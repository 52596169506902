<template>
  <div style="margin-top:30px;">
      <v-dialog   	
        v-model="SiteAnalyticsClearDialog"
        width="400"			
        >	
            <template v-slot:activator="{ on }">	
                <v-btn small v-on="on" >Clear Statistics</v-btn>	
            </template>
            <v-card
                class="mx-auto"
                max-width="500"
            >
                <v-card-title class="text-h6 font-weight-regular justify-space-between">
                <span>{{ currentTitle }}</span>
                <v-avatar
                    color="primary lighten-2"
                    class="subheading white--text"
                    size="24"
                    v-text="step"
                ></v-avatar>
                </v-card-title>

                <v-window v-model="step">
                <v-window-item :value="1">
                    <v-card-text>
                        <v-alert
                        :border="'top'"
                        :colored-border="true"
                        :icon="'mdi-alert'"
                        :elevation="6"
                        :color="'red'"
                        
                        >
                        <v-card-text>
                            Clearing Statistics is PERMANENT and should only be used to clear illigetimate stats
                        </v-card-text>
                        </v-alert>
                        <v-select :items="StatTypes" return-object item-text="Name" v-model="ClearStatType" />
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="2">
                    <v-card-text>
                        There are {{ClearStatTypeCount}} Stats that will be cleared.
                        Ensure you select a Date and Time to narrow down what will be cleared
                    </v-card-text>
                    <v-card-text>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearStartDateDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearStartDate" label="Start Date" prepend-icon="mdi-calendar-month"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-date-picker class="FormFields" v-model="StatClearStartDate" @input="StatClearStartDateDialog = false"></v-date-picker>	
                        </v-menu>
                         <v-menu			
                            class="FormFields"			
                            v-model="StatClearStartTimeDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearStartTime" label="Start Time" prepend-icon="mdi-clock-time-twelve-outline" clearable			
                                    readonly v-on="on"/>
                            </template>
                            <v-time-picker class="FormFields" v-model="StatClearStartTime" @input="StatClearStartTimeDialog = false"></v-time-picker>	
                        </v-menu>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearEndDateDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                            <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearEndDate" label="End Date" prepend-icon="mdi-calendar-month"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-date-picker class="FormFields" v-model="StatClearEndDate" @input="StatClearEndDateDialog = false"></v-date-picker>	
                        </v-menu>
                        <v-menu			
                            class="FormFields"			
                            v-model="StatClearEndTimeDialog"			
                            :close-on-content-click="false"			
                            :nudge-right="40"			
                            transition="scale-transition"			
                            offset-y			
                            min-width="290px"			
                            >	
                           <template v-slot:activator="{ on }">	
                                <v-text-field v-model="StatClearEndTime" label="End Time" prepend-icon="mdi-clock-time-twelve-outline"	clearable		
                                    readonly v-on="on"/>
                            </template>
                            <v-time-picker class="FormFields" v-model="StatClearEndTime" @input="StatClearEndTimeDialog = false"></v-time-picker>	
                        </v-menu>
                    </v-card-text>
                </v-window-item>

                <v-window-item :value="3">
                     <v-card-text>
                         There are {{FinalClearStatTypeCount}} Stats that will be cleared.
                            You can specify by Route
                     </v-card-text>
                     <v-card-text>
                         <v-select v-model="ClearSpecificPath" :items="ClearStatTypeItemsPaths" item-text="Page_Name" label="Route"/>
                     </v-card-text>
                </v-window-item>
                <v-window-item :value="4">
                     <v-card-text>
                     <v-alert
                        :border="'top'"
                        :colored-border="true"
                        :icon="'mdi-alert'"
                        :elevation="6"
                        :color="'red'"
                        
                        >
                        <v-card-text>
                            The clearing of {{FinalClearStatTypeCount}} will be PERMANENT. Are you sure?
                        </v-card-text>
                        </v-alert>
                     </v-card-text>
                </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                <v-btn
                    :disabled="step === 1"
                    text
                    @click="step--"
                >
                    Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :color="step === 4 ? 'error' : 'primary'"
                    depressed
                    @click="step !== 4 ? step++ : ClearStatistics()"
                >
                    {{currentContinue}}
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div v-if="!DrillDownItem"> 
        <v-row       
            :class="'mb-6 '+StatsCounterTab.Justify"
            no-gutters
            :style="'margin-top:'+StatsCounterTab.MarginTop+'px;margin-bottom:'+StatsCounterTab.MarginBottom+'px;margin-left:'+StatsCounterTab.MarginLeft+'px;margin-right:'+StatsCounterTab.MarginRight+'px;'"            
            >  
             <v-col
                v-for="(elmnt,index) in StatsCounterTab.Elements"
                :key="elmnt" :xl="elmnt.FlexXLRG" :lg="elmnt.FlexLarge" :md="elmnt.FlexMedium" :sm="elmnt.FlexSmall" :xs="elmnt.FlexXSmall"
                cols="12"    
                :class="elmnt.Justify+' '+ColMY(elmnt)"  
                :style="'padding-top:'+elmnt.MarginTop+'px;padding-bottom:'+elmnt.MarginBottom+'px;padding-left:'+elmnt.MarginLeft+'px;padding-right:'+elmnt.MarginRight+'px;align-self:'+elmnt.Alignment"                      
            >   
                <CounterTab 
                v-if="elmnt.Name === 'Counter Single' && elmnt.ShowComponents" :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont"
                :PageHeadersFontSize="PageHeadersFontSize" :i="index" :IsElement="true" :tabindex="i" :rowindex="rowindex"
                :tab="elmnt" @OpenDialog="OpenDialog" @EmitDrillDown="EmitDrillDown"
                />
             </v-col>
        </v-row>
       
       
        <DashboardBuilder v-for="item in ComputedStatisticCharts" :key="item.itemObjKey" :SystemEntities="ValidatorSystemEntities" :PushedElmnt="item"
        />
       </div>
       <div v-if="DrillDown" style="margin-top:-90px;">
      <!-- PublicWebFormSubMissions {{PublicWebFormSubMissions}} -->
      
        <v-parallax
            height="150"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="text-center" :style="System.Social_Parallax_Gradient ? System.Social_Parallax_Gradient : 'background: linear-gradient(150deg, rgba(238,119,82,0.6) 17%, rgba(231,60,126,0.6) 37%, rgba(35,166,213,0.6) 67%, rgba(35,213,171,0.6) 81%);'" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{DrillDownItem.Title}}</h1>
                <h4 class="headline">DrillDown</h4>
                <h5><v-btn small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
                
                </h5>                
              </v-col>
            </v-row>
            </v-parallax>
            
        <!-- Context on DrillDown? -->
       <!-- <v-btn style="position: absolute;z-index:5;" small v-if="DrillDown" @click="DrillDown = !DrillDown,DrillDownItem = ''"><v-icon>mdi-chevron-left</v-icon>Back</v-btn> -->
       
        <DashboardBuilder :PushedRows="ComputedStatisticCharts" :SystemEntities="ValidatorSystemEntities" :PushedElmnt="DrillDownItem"
        />
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import DashboardBuilder from '@/components/SuitePlugins/CustomDashboard/DashboardBuilder';
import CounterTab from '@/components/WebPages/RenderComponents/CounterTabReadOnly';
export default {
    props: ['System','SystemEntities'],
    components: {DashboardBuilder,CounterTab},
    data() {
        return {
            step: 1,
            StatTypes: [
                {ID: 1000001,Name: 'Public Page Stats',ItemsProp: 'PublicPageViews'},
                {ID: 1000002,Name: 'Landing Page Stats',ItemsProp: 'LandingPageViews'},
                {ID: 1000003,Name: 'Social Page Stats',ItemsProp: 'SocialPageViews'},
            ],
            BasicPieChart: {
                HideActions: true,
                ChartBGObj: {hex: '#c67eb7'},
                HasChart: true,			
                HasDataContext: true,			
                ShowTitle: true,	
                ChartPosition: 'Right',			
                DataContextStyle: 'List',			
                ChartType: 'pie',			
                ChartLabelsfromTimeline: false,
            },
            TimeLineStatsSample: {
                    HideActions: true,
                    AccumulativeGraph: true,			
                    ShowSummary: true,			
                    ShowSummaryOnly: true,			
                    HasDataContext: true,
                    DataContextStyle: 'List',
                    Sort: 'Descending (Value)',			
                    PrimaryLabelType: 'From Timeline',
                    PrimaryEntity: 'Users',
                    LookupArrays: [],
                    Data: [],
                    ChartLabelsfromTimeline: true,
                    ChartLabels: [],
                    PrimaryDateFilter: 
                        {
                            Name: 'Created_On',
                            Type: 'Date'
                        },
                    TimeFilterOption:   {			
          ID: 1000000,			
          FilterName: 'This Day (Per 2 Hrs)',			
          FilterDescription: 'When viewing, this filter returns the result of Day, by every 2 Hours',			
          GiveName: 'Per 2 Hrs',			
          Name: 'This Day',			
          StartDateVariable: 'ThisToday',			
          EndDateVariable: 'ThisTodayEnd',			
          ChartLabels: ['0AM - 2AM','2AM - 4AM','4AM - 6AM','6AM - 8AM','8AM - 10AM','10AM - 12PM','12PM - 14PM','14PM - 16PM','16PM - 18PM','18PM - 20PM','20PM - 22PM','22PM - 0AM'],			
          ChartLabelsVariables: ['ThisToday','Todayby2AM','Todayby4AM','Todayby6AM','Todayby8AM','Todayby10AM','Todayby12PM','Todayby14PM','Todayby16PM','Todayby18PM','Todayby20PM','Todayby22PM'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'Endof2Hours',			
        },				
                    ChartData: [],	
                    ChartType: 'bar',	
                    ColumnWidth: 'Full Column',	
                    HasChart: true,		
                    Title: 'New Users',			
                    Type: '',			
                    Sort: 'Descending (Value)',			
                    Description: 'Lorem Ipsum this is good!',			
                    ValueField: 'Full_Name',			
                    ValueType: 'Count',			
                    ValueCurrency: '',			
                    EntityRelatedfield: '',			
                    Entity: '',			
                    ChartBGObj: {hex: '#c67eb7'},	
                    ChartPosition: 'Top',	
                    ShowSummaryOnly: true,	
                    Content: [			
                                    
                    ],	
                    Context: [
                        {
                            ContextData: []
                        }
                    ],
                    
                    // Tile: 'ad',
                    // ShowTitle: true,		
                    HasData: true,	
                    DashboardEntity: {
                        id: 'Users',
                        AllFields: [
                            {
                                Name: 'Full_Name',
                                Type: 'Single Line Text'
                            },
                            {
                                Name: 'Created_On',
                                Type: 'Date'
                            }
                        ]
                    }	
                },
            StatisticCharts: [
               {
                    HideActions: true,
                    AccumulativeGraph: true,			
                    ShowSummary: true,			
                    ShowSummaryOnly: true,			
                    HasDataContext: true,
                    DataContextStyle: 'List',
                    Sort: 'Descending (Value)',			
                    PrimaryLabelType: 'From Timeline',
                    PrimaryEntity: 'Users',
                    LookupArrays: [],
                    Data: [],
                    ChartLabelsfromTimeline: true,
                    ChartLabels: [],
                    PrimaryDateFilter: 
                        {
                            Name: 'Created_On',
                            Type: 'Date'
                        },
                    TimeFilterOption:  {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
                    ChartData: [],	
                    ChartType: 'bar',	
                    ColumnWidth: 'Full Column',	
                    HasChart: true,		
                    Title: 'New Users',			
                    Type: '',			
                    Sort: 'Descending (Value)',			
                    Description: 'Lorem Ipsum this is good!',			
                    ValueField: 'Full_Name',			
                    ValueType: 'Count',			
                    ValueCurrency: '',			
                    EntityRelatedfield: '',			
                    Entity: '',			
                    ChartBGObj: {hex: '#c67eb7'},	
                    ChartPosition: 'Top',	
                    ShowSummaryOnly: true,	
                    Content: [			
                                    
                    ],	
                    Context: [
                        {
                            ContextData: []
                        }
                    ],
                    
                    // Tile: 'ad',
                    // ShowTitle: true,		
                    HasData: true,	
                    DashboardEntity: {
                        id: 'Users',
                        AllFields: [
                            {
                                Name: 'Full_Name',
                                Type: 'Single Line Text'
                            },
                            {
                                Name: 'Created_On',
                                Type: 'Date'
                            }
                        ]
                    }	
                }
            ],
            AppValidatorEntities: [
                {id: 'Site_Analytics',AllFields: [
                        {
                            Name: 'Path',
                            Type: 'Single Line Text',
                            Primary: true
                        },
                        {
                            Name: 'Date',
                            Type: 'Date'
                        },
                        {
                            Name: 'Page',
                            DisplayName: 'Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        },
                        {
                            Name: 'Most_Viewed_From_Page',
                            DisplayName: 'Most Viewed From Page',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'PageName'
                        }
                    ],
                GoalMeasurements: [
                    {
                        id: 'Session_Conversions',
                        Targets: []
                    }
                ]
                },
                
                {id: 'Public_Web_Forms',AllFields: [
                        {
                            Name: 'Form_Page_Obj',
                            DisplayName: 'Form_Page_Obj',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Pages',
                            LookupFieldName: 'Page_Name'
                        },
                        {
                            Name: 'Related_Session',
                            DisplayName: 'Related Session',
                            Type: 'Lookup',
                            RelatedBuildID: 'Site_Analytics',
                            LookupFieldName: 'Path'
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Site_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
                {id: 'Landing_Pages',AllFields: [
                        {
                            Name: 'Page_Name',
                            DisplayName: 'Page Name',
                            Type: 'Single Line Text',
                            Primary: true,
                        },
                        {
                            Name: 'PublishType',
                            DisplayName: 'Publish Type',
                            Type: 'Single Line Text'
                        }
                    ]
                },
            ],
            ClearStatType: '',
            StatClearStartDateDialog: false,
            StatClearStartTimeDialog: false,
            StatClearEndDateDialog: false,
            StatClearEndTimeDialog: false,
            StatClearStartDate: '',
            StatClearStartTime: '',
            StatClearEndDate: '',
            StatClearEndTime: '',
            ClearSpecificPath: '',
            SiteAnalyticsClearDialog: false,
            DrillDown: false,
            DrillDownItem: '',
            SystemUsers: [],
            SystemBusinessUnits: [],
            PublicPageViews: [],
            GroupLandingPageViews: [],
            LandingPageViews: [],
            SiteModerators: [],
            SitePages: [],
            PublicLandingPages: [],
            InternalLandingPages: [],
            SocialPageViews: [],
            colors: [									
                    'red',									
                    'pink',									
                    'purple',									
                    'deep-purple',									
                    'indigo',									
                    'blue',									
                    'light-blue',									
                    'cyan',									
                    'teal',									
                ],
            DefaultCounterElmnt: {									
                CounterIcon: 'mdi-excavator',									
                CounterInteger: 0,									
                Center: 'justify-center',									
                CenteredHeader: true,									
                Name: 'Counter Single',									
                HeaderFontFamily: 'Roboto',									
                HeaderFontSize: 18,									
                Tile: true,									
                HeaderColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                FontSize: 10,									
                FontColor: { "alpha": 1, "hex": "#162E4D", "hexa": "#162E4DFF", "hsla": { "h": 213.8181818181818, "s": 0.5555555555555557, "l": 0.1941176470588235, "a": 1 }, "hsva": { "h": 213.8181818181818, "s": 0.7142857142857143, "v": 0.30196078431372547, "a": 1 }, "hue": 213.8181818181818, "rgba": { "r": 22, "g": 46, "b": 77, "a": 1 } },									
                									
                Title: 'Title',									
                Description: `<div style="text-align: center;">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non lacus nisi.</div>`,									
                FlexXLRG:2,									
                FlexLarge: 3,									
                FlexMedium: 3,									
                FlexSmall: 6,									
                FlexXSmall: 12,									
                Cols: 3,									
                Justify: 'justify-center',									
                RowColor: '#00ffff',									
                ShowComponents: true,
                // HideActions: true,								
                Icon: 'mdi-cards',									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                BGColor: {
                    hex: 'rgb(198,126,183)',
                    hexa: 'rgb(198,126,183)'
            },									
                IsDark: true,						
            },
           DefaultCounterTab: {									
            Open: true,									
            SectionType: true,									
            Transparent: true,									
            Alignment: 'center',									
            Justify: 'justify-center',									
            RowTransparency: 'transparent',									
            Name: 'Counter',									
            Elements: [
                {}
            ],									
            RowColor: '#00ffff',									
            ShowComponents: true,									
            Icon: 'mdi-counter',									
            Title: 'About',									
            Description: `Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet`,									
            MarginTop: 0,									
            MarginBottom: 0,									
            MarginLeft: 0,									
            MarginRight: 0,									
            BGColor: {									
                    hex: '#ffffff',
                    hexa: '#ffffffff',									
            },									
            FontColor :{									
                    hex: '#000000'									
            },									
            HeaderColor: {									
                hex: '#000000'									
            },									
            FlexXLRG:9,									
            FlexLarge: 9,									
            FlexMedium: 11,									
            FlexSmall: 11,									
            FlexXSmall: 10,									
            Cols: 12,									
            },
        }
    },	
    computed:{
        ValidatorSystemEntities(){
            //now we will be able to add entities such as Pages
            return this.SystemEntities.concat(this.PreparedAppValidatorEntities)
        },
        PreparedAppValidatorEntities(){
            return this.AppValidatorEntities
            // .map(entity => {
            //     if(entity.id === 'Site_Analytics'){
            //         entity.GoalMeasurements[0].Targets = this.PublicPageSessions.map(session => {
            //             let targetobj = {
            //                 StartDate = session.Date,
            //                 Target: 1,
            //             }
            //             return targetobj
            //         })
            //     }
            //     return entity
            // })
        },
        ComputedStatisticCharts(){
            return this.DrillDownItem? this.DrillDownItem.Charts
            .map(chart => {
                //console.log('this.DrillDownItem',this.DrillDownItem)
                let chartobj = ''
                if(chart.Preset === 'Static Timeline Filter'){
                    chartobj = Object.assign({},this.TimeLineStatsSample)
                   // chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title 
                }
                 else if(chart.Preset === 'Pie Chart'){
                    chartobj = Object.assign({},this.BasicPieChart)
                    chartobj.PrimaryLabelType = chart.PrimaryLabelType
                    //chartobj.PrimaryEntity = chart.PrimaryEntity
                    chartobj.PrimaryDateFilter = chart.PrimaryDateFilter
                    // chartobj.DashboardEntity = chart.DashboardEntity
                    chartobj.ValueField = chart.ValueField
                    chartobj.ValueType = chart.ValueType
                    chartobj.ValueCurrency = chart.ValueCurrency
                    chartobj.ChartType = chart.ChartType
                    chartobj.ColumnWidth = chart.ColumnWidth
                    chartobj.HasChart = chart.HasChart
                    chartobj.Title = chart.Title
                    if(chart.RelatedBuildID){
                        chartobj.RelatedBuildID = chart.RelatedBuildID
                    }
                    
                 }
                 if(chart.SublabelField){
                     chartobj.SublabelField = chart.SublabelField
                 }
                 if(chart.PrimaryLabelField){
                        chartobj.PrimaryLabelField = chart.PrimaryLabelField
                    }
                    if(chart.PrimaryFilterType){
                        chartobj.PrimaryFilterType = chart.PrimaryFilterType
                    }
                     if(chart.PrimaryFilterField){
                        chartobj.PrimaryFilterField = chart.PrimaryFilterField
                    }
                    if(chart.ConfigName){
                        chartobj.ConfigName = chart.ConfigName
                    }
                if(this.DrillDownItem.Name === 'Counter Single'){
                chartobj.Data = this.DrillDownItem.Data
                }
                console.log('chartobj',chartobj)
                //later advanced
                // EntityRelatedfield: '',			
                // Entity: '',
                
                return chartobj
            }) 
            : this.StatisticCharts.map(elmnt => {
                elmnt = this.PrepareElementComponents(elmnt)
                return elmnt
            })
            .map(elmnt => {
                if(elmnt.DashboardEntity.id === 'Users'){
                    elmnt.Data = this.$store.state.Users
                   // console.log('elmnt.FullArray',elmnt.Data)
                }
                return elmnt
            })
           
        },
        ConfigSystemid(){
            return process.env.VUE_APP_RA_SYSTEM_ID
        },
        ActiveDB(){
            return db
        },
        StatsCounterTab(){
            let tab = this.DefaultCounterTab
            let elements = [
                {CounterIcon: 'mdi-face-agent',Data: this.SystemUsers,CounterInteger: this.SystemUsers.length, Title: 'Total Users',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-office-building',Data: this.SystemBusinessUnits,CounterInteger: this.SystemBusinessUnits.length, Title: 'Business Units',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-map-marker',Data: this.PublicLocations,CounterInteger: this.PublicLocations.length, Title: 'Public Locations',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-newspaper',Data: this.SocialComponents,CounterInteger: this.SocialComponents.length, Title: 'Social Components',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-tag-faces',Data: this.SiteModerators,CounterInteger: this.SiteModerators.length, Title: 'Social Moderators',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},
                {CounterIcon: 'mdi-book-open-page-variant',Data: this.SocialPages,CounterInteger: this.SocialPages.length, Title: 'Social Pages',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'}},                
                {CounterIcon: 'mdi-search-web',Data: this.SocialPageViews,CounterInteger: this.SocialPageViews.length, Title: 'Social Page Views',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: '#c67eb7',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Social Page Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'User_Obj',Name: 'User_Obj',DisplayName: 'Full Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Full_Name',RelatedBuildID: 'Users'},                   
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPages,CounterInteger: this.PublicPages.length, Title: 'Public Pages',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},},
                {CounterIcon: 'mdi-search-web',Data: this.ComputedPublicPageViews,CounterInteger: this.ComputedPublicPageViews.length, Title: 'Public Page Views',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: '#c67eb7',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Public Page Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Views by Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                // LookupArrays: [{
                //                     Field: 'Most_Viewed_From_Page',
                //                     Array: this.PublicPageViewPageObjects
                //                 }]
                },
                                
                {CounterIcon: 'mdi-search-web',Data: this.PublicLandingPageViews,CounterInteger: this.PublicLandingPageViews.length, Title: 'Public LP Views',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: '#c67eb7',
                Charts: [
                {Preset: 'Static Timeline Filter',Title: 'Public LP Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'By Public LP',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Landing_Pages'},           
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPageSessions,CounterInteger: this.PublicPageSessions.length, Title: 'Public Page Sessions',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Site_Analytics',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: '#c67eb7',
                Charts: [
              
                {Preset: 'Static Timeline Filter',Title: 'Public Sessions Started',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true,
                //SublabelField:{id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages',GoalID: 'Session_Conversions'}
                },
                {Preset: 'Pie Chart',Title: 'Sessions by Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Page Views by Ses. Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: {id: 'Views',Name: 'Views',DisplayName: 'Views'},ValueType: 'Amount',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'Mins per Sess. by Start Page',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {id: 'Page_Obj',Name: 'Page_Obj',DisplayName: 'Page Name',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},
                // PrimaryFilterType: 'Lookup',PrimaryFilterField: {id: 'Most_Viewed_From_Page',Name: 'Most_Viewed_From_Page',DisplayName: 'Most Viewed From Page',Type: 'Lookup',Primary: false,LookupFieldName: 'Page_Name',RelatedBuildID: 'Site_Pages'},          
                ValueField: {id: 'Total_Minutes',Name: 'Total_Minutes',DisplayName: 'Total Mins'},ValueType: 'Amount',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                ],
                NumberFields: [{id: 'Views',Name: 'Views',DisplayName: 'Views'},{id: 'Total_Minutes',Name: 'Total_Minutes',DisplayName: 'Total Mins'}]
                // LookupArrays: [{
                //                     Field: 'Most_Viewed_From_Page',
                //                     Array: this.PublicPageViewPageObjects
                //                 }]Total_Minutes
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicWebFormSubMissions,CounterInteger: this.PublicWebFormSubMissions.length, Title: 'Public Form Submits',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},
                HasAnalysisDrillDown: true,PrimaryEntity: 'Public_Web_Forms',AnalysisDrillDownMethod: 'ToggleStatisticsView',HasCounterData: true,ThemeColor: '#c67eb7',
                Charts: [
                // {Preset: 'Static Timeline Filter',Title: 'Public LP Views',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},
                // ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'bar',ColumnWidth: 'Full Column',HasChart: true},
                {Preset: 'Pie Chart',Title: 'By Public Session',PrimaryDateFilter: {Name: 'Date',Type: 'Date'},ConfigName: 'Charts and Context',
                PrimaryLabelType: 'Lookup',PrimaryLabelField: {Name: 'Related_Session',DisplayName: 'Related Session',Type: 'Lookup',Primary: false,LookupFieldName: 'Path',RelatedBuildID: 'Site_Analytics'},           
                ValueField: 'Path',ValueType: 'Count',ValueCurrency: '',ChartType: 'pie',ColumnWidth: 'Full Column',HasChart: true},
                ],
                },
                {CounterIcon: 'mdi-search-web',Data: this.PublicPagesConversions,CounterInteger: this.PublicConversionRate, Title: 'Submits/Session (Rate)',BGColor: {hex: '#c67eb7'},HeaderColor: {hex: 'links'},FontColor: {hex: 'links'},},
                
            ]
            tab.Elements = elements.map(elmnt => {
                // let ranint = Math.floor(Math.random() * Math.floor(this.colors.length))
                // alert(ranint+' '+this.colors[ranint])	
                let baseelmnt = Object.assign({},this.DefaultCounterElmnt)
                let requiredprops = ['BGColor','HeaderColor','FontColor','CounterIcon','CounterInteger','Title','HasCounterData','Data','Charts','HasAnalysisDrillDown',
                'ThemeColor','PrimaryEntity','AnalysisDrillDownMethod','LookupArrays','NumberFields']
                requiredprops.map(prop => {
                    if(elmnt[prop]){
                        baseelmnt[prop] = elmnt[prop]
                    }
                })
                //console.log('baseelmnt',baseelmnt)
                return baseelmnt
            })
            return tab
        },
        WebModuleItems(){
            return this.PublicPages
        },
        SocialModuleItems(){
            return this.SocialPages.concat(this.SocialComponents)
        },
        
        PluginDataBase(){
        return this.$store.state.PluginDataBase
        }, 
        SocialComponents(){
            let array = [
                {id: 'Site_Articles',Name: 'Site Articles',DisplayName: 'Site Articles',PluralName: 'Articles',SingleName: 'Article',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Articles',HasCategories: true,CatProp: 'Article_Categories'},
                {id: 'Site_Blogs',Name: 'Site Blogs',DisplayName: 'Site Blogs',PluralName: 'Blogs',SingleName: 'Blog',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Blogs',HasCategories: true,CatProp: 'Blog_Categories'},
                {id: 'Site_Classifieds',Name: 'Site Classifieds',DisplayName: 'Site Classifieds',PluralName: 'Classifieds',SingleName: 'Classified',PageIcon: 'mdi-newspaper',Pluginid: 'Classifieds'},
                {id: 'Site_Events',Name: 'Site Events',DisplayName: 'Site Events',PluralName: 'Events',SingleName: 'Event',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Events'},
                {id: 'Site_Forums',Name: 'Site Forums',DisplayName: 'Site Forums',PluralName: 'Forums',SingleName: 'Forum',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Forums'},
                {id: 'Site_Groups',Name: 'Site Groups',DisplayName: 'Site Groups',PluralName: 'Groups',SingleName: 'Group',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Groups',HasCategories: true,CatProp: 'Group_Categories'},
                {id: 'Site_Meetups',Name: 'Site Meetups',DisplayName: 'Site Meetups',PluralName: 'Meetups',SingleName: 'Meetup',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Meetups'},
                {id: 'Site_Polls',Name: 'Site Polls',DisplayName: 'Site Polls',PluralName: 'Polls',SingleName: 'Poll',PageIcon: 'mdi-newspaper',Pluginid: 'Site_Polls'},
            ]
             return array.filter(item => {
                return this.PluginDataBase[item.Pluginid] && this.PluginDataBase[item.Pluginid].Active
            })
        },
        SocialPages(){
            return this.SitePages.filter(page => {
                return page.PublishType !== 'Public'
            })
        },
        PublicPages(){
            return this.SitePages.filter(page => {
                return page.PublishType === 'Public'
            })
        },
        PublicLocations(){
            return this.SystemBusinessUnits.filter(bu => {
                return bu.IsPublicLocation
            })
        },
        ClearStatTypeItems(){
            let startdate = ''
            let enddate = ''
            new Date(this.StatClearStartDate)
            if(this.ClearStatType){
                let arr = this[this.ClearStatType.ItemsProp]
                if(this.StatClearStartDate && !this.StatClearStartTime){
                    startdate = new Date(this.StatClearStartDate)
                }
                else if(this.StatClearStartDate && this.StatClearStartTime){
                    let time = this.StatClearStartTime
                    let hours = time.split(':')[0]
                    let minutes = time.split(':')[1]                    
                    startdate = new Date(this.StatClearStartDate)
                    startdate.setHours(hours,minutes)
                    //console.log(hours,minutes,startdate)
                }
                if(this.StatClearEndDate && !this.StatClearEndTime){
                    enddate = new Date(this.StatClearEndDate)
                    enddate.setHours(23,59,59,999)
                }
                else if(this.StatClearEndDate && this.StatClearEndTime){
                    let time = this.StatClearEndTime
                    let hours = time.split(':')[0]
                    let minutes = time.split(':')[1]
                    enddate = new Date(this.StatClearEndDate)
                    enddate.setHours(hours,minutes)
                    //console.log(hours,minutes,enddate)
                }
                if(this.StatClearStartDate){
                    return this[this.ClearStatType.ItemsProp].filter(item => {
                        let javadate = item.Date.toDate()
                        if(this.StatClearEndDate){
                          return javadate >= startdate && javadate <= enddate  
                        }
                        else{
                            return javadate >= startdate
                        }                        
                    })
                }
                else{
                    return this[this.ClearStatType.ItemsProp].filter(item => {
                        return !item
                    })
                }
                
            }
            else{
                return []
            }
        },
        ClearStatTypeItemsPaths(){
             return this.ClearStatTypeItems.map(item => {
                    return item.Path
                })
        },
        FinalClearStatTypeItems(){
            return this.ClearStatTypeItems.filter(item => {
                if(this.ClearSpecificPath){
                    return item.Path === this.ClearSpecificPath
                }
                else{
                    return item
                }
            })
        },
        ClearStatTypeCount(){
            return this.ClearStatTypeItems.length
        },
        FinalClearStatTypeCount(){
            return this.FinalClearStatTypeItems.length
        },
        currentTitle () {
            switch (this.step) {
            case 1: return 'Which Stats'
            case 2: return 'Which Date'
            default: return 'Clear?'
            }
        },
        currentContinue () {
            switch (this.step) {
            case 4: return 'Clear'
            default: return 'Next?'
            }
        },
        PublicPagesConversions(){
            return this.PublicWebFormSubMissions
        },
        PublicConversionRate(){
            if(this.PublicWebFormSubMissions && this.PublicWebFormSubMissions.length > 0 && this.PublicPageSessions && this.PublicPageSessions.length > 0){
                let num = (this.PublicWebFormSubMissions.length/this.PublicPageSessions.length)*100
               return num.toFixed(2)+'%'
            }
            else {
                return '0%'
            }
            
        },
        WebFormEntities(){
            return this.SystemEntities.filter(entity => {
                return entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'
            })
        },
        WebFormSubmissionsAllEntities(){
            return this.WebFormEntities.map(entity => {
                return entity.WebFormSubmissions
            }).flat()
        },
        PublicWebFormSubMissions(){
            return this.WebFormSubmissionsAllEntities.filter(submission => {
                return !submission.Created_By
            })
            .map(submission => {
                submission.Related_Session = this.PublicPageSessions.find(session => session.Submissions.includes(submission.id))
                return submission
            })
        },
        CollaborativePublicPageViews(){
            return this.PublicPageViews.concat(this.LandingPageViews)
        },
        SessionBasedPublicPageView(){
            return this.CollaborativePublicPageViews.map(item => {
                item.SessionChildren = this.CollaborativePublicPageViews.filter(obj => {return obj.PrecedingViewInstanceID === item.id})
                item.Views = item.SessionChildren.length
                return item
            })
        },
        PublicPageSessions(){
            return this.SessionBasedPublicPageView.filter(view => {
                return !view.PrecedingViewInstanceID
            })
            .map(session => {
                session.Views = 0
                session.Views = this.ForeverLoopChildren(session,'SessionChildren',session.Views)
                session.Total_Minutes = 0
                session.Total_Minutes = this.ForeverLoopChildrenTimeDiff(session,'SessionChildren','Date',session.Total_Minutes,'Minutes')
                session.Submissions = this.WebFormSubmissionsAllEntities.filter(submission => {
                    return submission.ViewInstanceID === session.id || this.GetMatchinObjectorChildren(session,'SessionChildren',session.Submissions,submission.ViewInstanceID)
                }).map(submission => {return submission.id})
                //console.log('session',session)
                return session
            })
        },
       
        ComputedPublicPageViews(){
            return this.PublicPageViews.map(view => {
                // view.Most_Viewed_From_Page = Object.assign({},view.Page_Obj)
                // console.log('view.Most_Viewed_From_Page',view.Most_Viewed_From_Page)
                //view.RouteFromMeta must match this page, MAX
                //so first filter by that which amtches most, return ITS PageBJ
                //in fact here we msut decide if we take RouteFromMeta, OR, PreceidingSession...
                return view
            })
        },
        PublicPageViewPageObjects(){
            return this.PublicPageViews.map(view => {
                return view.Page_Obj
            })
        },
        PublicLandingPageViews(){
            return this.LandingPageViews.map(item => {
                let pageobj = this.PublicLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
                if(pageobj){
                    item.Page_Obj.Page_Name = pageobj.Name
                    item.PageTabs = pageobj.PageTabs
                }
                return item
            })
            .filter(item => {
                return this.PublicLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
            })
        },
        InternalLandingPageViews(){
            return this.LandingPageViews.map(item => {
                let pageobj = this.InternalLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
                if(pageobj){
                    item.Page_Obj.Page_Name = pageobj.Name
                    item.PageTabs = pageobj.PageTabs
                }
                return item
            })
            .filter(item => {
                return this.InternalLandingPages.find(obj => obj.PageRoute === item.Page_Obj.id)
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        let systemquery = this.ActiveDB.collection('SystemConfig').doc(this.ConfigSystemid)
         this.GetArrayData(this.ActiveDB.collection('users'),'SystemUsers')
        this.GetArrayData(this.ActiveDB.collection('businessunits'),'SystemBusinessUnits')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','in',[1028,5001]).where('Type','==','public').where('PublicUser','==',true),'PublicPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1027).where('Type','==','internal').where('SignedInUser','==',true),'SocialPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1032).where('Type','==','public').where('PublicUser','==',true),'LandingPageViews')
        this.GetArrayData(this.ActiveDB.collection('siteanalytics').where('RouteEventID','==',1015).where('Type','==','public').where('PublicUser','==',true),'GroupLandingPageViews')
        this.GetSiteModerators(this.ActiveDB.collection('sitemoderators'))
        this.GetSitePages(systemquery.collection('Pages'))
        this.GetArrayData(this.ActiveDB.collection('landingpages').where('PublishType','==','Public'),'PublicLandingPages')
        this.GetArrayData(this.ActiveDB.collection('landingpages').where('PublishType','!=','Public'),'InternalLandingPages')
    },
    methods:{
        PrepareElementComponents(elmnt){			
        if(elmnt.HasDataContext && elmnt.HasChart){			
                    elmnt.HasTwoColumns = this.GetHasTwoColumns(elmnt)			
                    elmnt.HasTwoRows = !elmnt.HasTwoColumns			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)			
                    elmnt.ComponentTwo = this.GetComponentTwo(elmnt)			
                }			
                // else if(elmnt.HasDataContext && elmnt.HasChart){			
                // }			
                else{			
                    elmnt.HasTwoColumns = false			
                    elmnt.HasTwoRows = false			
                    elmnt.ComponentTwo = ''			
                    // console.log('from here')			
                    elmnt.ComponentOne = this.GetComponentOne(elmnt)                			
                }			
                            
                return elmnt			
        },
        GetComponentOne(elmnt){			
        if(!elmnt.ChartPosition){			
            if(elmnt.HasChart){			
            // console.log('from here')			
            return {CompName: 'DashboardElmtChart',Style: 'Pipeline'}			
            }			
            else if(elmnt.HasDataContext){          			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
            }			
        }			
        else if(elmnt.ChartPosition === 'Top' && elmnt.HasChart){			
            return {CompName: 'DashboardElmtChart',Style: elmnt.ChartPosition}			
        }			
        else if(elmnt.ChartPosition === 'Right' || elmnt.HasDataContext && !elmnt.HasChart){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        },			
        GetComponentTwo(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }			
        else if(elmnt.ChartPosition === 'Right'){			
            return {CompName: 'DashboardElmtChart',Style: 'Pie'}			
        }			
        else if(elmnt.ChartPosition === 'Left'){			
            return {CompName: 'DashboardElmtDataContext',Style: elmnt.DataContextStyle}			
        }  			
        },
        GetHasTwoColumns(elmnt){			
        if(elmnt.ChartPosition === 'Top'){			
            return false			
        }			
        else{			
            return true			
        }			
        },
        ColMY(tab){
            if(tab.Name === 'Card Block Single'){
                return ''
            }
            else{
                return ' my-3'
            }
        },
        
        ForeverLoopChildren(item,childarray,int){
            item[childarray].map(child => {
                int++
                if(child[childarray]){
                    int = this.ForeverLoopChildren(child,childarray,int)
                }
            })
            return int
        },
        CancelClearStats(){
            this.StatClearStartDateDialog = false            
            this.ClearStatType = ''
            this.ClearSpecificPath = ''
            this.StatClearStartDate = ''
            this.StatClearStartTime = ''
            this.StatClearEndDate = ''
            this.StatClearEndTime = ''
        },
        ClearStatistics(){
            console.log('this.FinalClearStatTypeItems',this.FinalClearStatTypeItems)
            this.FinalClearStatTypeItems.map((item,index) => {
                db.collection('siteanalytics').doc(item.id).delete().then(doc => {
                    //console.log('deleted item '+item.id)
                    if(index-1+2 === this.FinalClearStatTypeItems.length){
                        this.CancelClearStats()                        
                    }
                })
            })            
        },
        ForeverLoopChildrenTimeDiff(item,childarray,datefield,int,Type){
            //console.log('ForeverLoopChildrenTimeDiff ',item,childarray,datefield,int,Type)
            let parentdate = item[datefield].toDate()
            //let parenttime = parentdate.getTime()
            item[childarray].map(child => {
                let childdate = child[datefield].toDate()
                var diffMs = childdate-parentdate
                var diffDays = Math.floor(diffMs / 86400000) // days
                var diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
                var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
                //let childtime = childdate.getTime()
                if(Type === 'Minutes'){
                    int = int+diffMins
                }
                else if(Type === 'Days'){
                    int = int+diffDays
                }
                else if(Type === 'Hours'){
                    int = int+diffHrs
                }
                if(child[childarray]){
                    int = this.ForeverLoopChildrenTimeDiff(child,childarray,datefield,int,Type)
                }
            })
            return int
        },
        GetMatchinObjectorChildren(item,childarray,matchid){
            let match = item[childarray].find(obj => obj.id === matchid)
            if(match){
                return match
            }
            else{
                item[childarray].map(child => {
                    return this.GetMatchinObjectorChildren(child,childarray,matchid)
                })
                
            }
        },
        ForeverLoopChildren(item,childarray,int){
            item[childarray].map(child => {
                int++
                if(child[childarray]){
                    int = this.ForeverLoopChildren(child,childarray,int)
                }
            })
            return int
        },
        EmitDrillDown(MethodName, tab){
            console.log(MethodName,tab)
            this.DrillDown = true
            this.DrillDownItem = tab
        },
        GetSitePages(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.SitePages.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                }
                })
            })
        },
        GetSiteModerators(query){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.SiteModerators.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                }
                })
            })
        },
        GetArrayData(query,array){
            query.onSnapshot(res => {
                const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    let recordobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    if(array === 'GroupLandingPageViews'){                        
                        recordobj.Page_Obj = {
                            id: recordobj.Path.split('/')[3].split(' ').join('_'),
                            Page_Name: recordobj.Path.split('/')[3]
                        }
                        recordobj.Group_Obj = {
                            id: recordobj.RecordId,
                            Group_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        //console.log('GroupLandingPageViews',recordobj)
                    }
                    if(array === 'PublicPageViews' || array === 'SocialPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join(' ')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    if(array === 'LandingPageViews'){
                        recordobj.Page_Obj = {
                            id: recordobj.RecordId,
                            Page_Name: recordobj.RecordId.split('_').join('')
                        }
                        if(recordobj.User && recordobj.Userid){
                            recordobj.User_Obj = {
                                Full_Name: recordobj.User,
                                id: recordobj.Userid
                            }
                        }
                    }
                    this[array].push(recordobj)
                }
                })
            })
        },
    }
}
</script>

<style>

</style>



