<template>
<div>
     <v-list-item class="justify-center" v-if="ViewFullGalleryTop">
        <v-btn @click="ActivateFullGallery()" dark class="warning">{{ViewFullGalleryText}}</v-btn>
    </v-list-item>
  <v-row v-if="ComputedAssets && ComputedAssets.length > 0" style="padding:15px; ">
        <v-col v-for="n in ComputedAssets"
        :key="n.itemObjKey" @click="EditedTab.Name === 'Gallery' ? RemoveItemConfirmation(n) : ''"
        :cols="Cols" style="padding: 4px;"
        >
        
        <v-hover
            v-slot="{ hover }"
            open-delay="200" v-if="!HoverPreview"
        >
            <v-card
            :elevation="hover ? 16 : 2"
            :class="{ 'on-hover': hover }"
            class="mx-auto"
            >
            <v-img
            :src="n.ThumbURL"
            aspect-ratio="1"
            class="grey lighten-2"
            @click="SelectImage(EditedTab, 'IMG', n)"
        >
            <v-expand-transition>
            <div
                v-if="hover && !HideSelect"
                class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal white--text"
                style="height: 100%;"
            >
                <v-chip style="cursor: pointer;" dark>{{SelectName ? SelectName : 'Select'}}</v-chip>
            </div>
            </v-expand-transition>
                    </v-img>
            </v-card>
        </v-hover>
        <v-menu
            open-on-hover
            >
            <template v-slot:activator="{ on, attrs }">
                <v-card v-if="HoverPreview"
                class="mx-auto"
                >
                <v-img v-bind="attrs"
                v-on="on"
                :src="n.ThumbURL"
                aspect-ratio="1"
                class="grey lighten-2"
                 >
                </v-img>
                </v-card>
               
            </template>
            <v-img v-bind="attrs" contain width="250"
                :src="n.ThumbURL"
                aspect-ratio="1"
                class="grey lighten-2"
                 >
                </v-img>
        </v-menu>
        </v-col>
    </v-row>
     <v-list-item class="justify-center" v-if="ViewFullGalleryBottom">
        <v-btn @click="ActivateFullGallery()" dark class="warning">{{ViewFullGalleryText}}</v-btn>
    </v-list-item>
    </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','Assets','Cols','HoverPreview','ViewFullGallery','ViewFullGalleryText','HideSelect','Slice',
    'ViewFullGalleryTop','ViewFullGalleryBottom','SelectName','EditedTab'],
    components: {},
    data() {
        return {

        }
    },
    computed:{
        ComputedAssets(){
            return this.Slice ? this.Assets.slice(0,this.Slice) : this.Assets
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
    },
    created(){
        
    },
    methods:{
        RemoveItemConfirmation(n){
            confirm('Do you want to remove this item?') && this.ProcessRemoveItem(n)
        },
        ProcessRemoveItem(n){
            let index = this.EditedTab.Assets.indexOf(n)
            this.EditedTab.Assets.splice(index,1)
        },
        ActivateFullGallery(){
            this.$emit('ActivateFullGallery')
        },
        SelectImage(tab,prop,asset,IsThis){
            this.$emit('SelectImage',tab,prop,asset,IsThis,this.SelectName)
        },
    }
}
</script>

<style>

</style>


