<template>
  <div>
    <v-snackbar
    
       color="links"
       top
      v-model="invitesnackbar"
      :timeout="4000"
    >
      Invite has been sent to {{InvitingUserEmail}}
    </v-snackbar>
      <SystemBuilderQuickstart :ClientDBBusinessUnits="ClientDBBusinessUnits" :ClientDBAdministrators="ClientDBAdministrators"
  v-if="$route.meta && $route.meta.SystemBuilder" :QuickstartStep="QuickstartStep" :clientDB="clientDB" :clientApp="clientApp"
  :QuickStartDialog="QuickStartDialog" :ClientFireStore="ClientFireStore" :SystemObj="Record"
  :ClientAppAuthDialog="ClientAppAuthDialog" :ViewingSystemID="Record.id" @ToggleQuickStartDialog="ToggleQuickStartDialog"
  />

  <!-- ClientAppSignedIn {{ClientAppSignedIn}} -->
  <v-list  >
          <v-list-item two-line subheader v-if="ClientAppSignedIn">
            <v-list-item-content>          
            <v-list-item-title>Signed In</v-list-item-title>
            <v-list-item-subtitle>
              {{ClientappSignedInEmail}}
            </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
            <v-icon color="green">mdi-check-outline</v-icon>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!ClientAppSignedIn"  @click="AuthenticateClientApp()">
            <v-list-item-title class="warning--text fieldmappings">Sign In<p class="tooltiptext">Sign into your App</p></v-list-item-title>
            <v-list-item-action>
            <v-icon color="green">mdi-login</v-icon>
            </v-list-item-action>
          </v-list-item>
           <v-dialog v-model="invitedialog" v-if="ClientappSignedInEmail && SystemObj && SystemObj.ClientAppURL"
            max-width="800"                         
            >
            <template v-slot:activator="{ on }">
                <v-list-item v-if="ClientappSignedInEmail && SystemObj && SystemObj.ClientAppURL"  v-on="on">
                  <v-list-item-title class="warning--text fieldmappings">Invite<p class="tooltiptext">New Admin</p></v-list-item-title>
                  <v-list-item-action>
                  <v-icon color="green">mdi-login</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <v-card tile flat>
                <v-card-title>
                  New Admin Email
                </v-card-title>
                <v-card-text>
                  Invite new admin to {{SystemObj.Name}} on {{SystemObj.ClientAppURL}}
                  <v-text-field :rules="[rules.email]" type="text" v-model="InvitingUserEmail" placeholder="Email" size="12"/><br>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn @click="ProcessInvitetoUser()">Process</v-btn>
                </v-card-actions>
              </v-card>
           </v-dialog>
          <!-- <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemConfigMastSubCols()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            SubCol Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item> -->
        <!-- <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemMasterBU()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            BU Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
      <v-list-item v-if="ClientAppSignedIn && BaseClientSystemReady" @click="ReplicateSystemConfig()" class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Replicate
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="pop">mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item> -->
        
        </v-list>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import SystemBuilderQuickstart from '@/components/RAExclusive/SystemBuilderQuickstart';


export default {
    props: [
    'SystemEntities','System','CurrentEntity','Record','SystemPhotoLibraries'],
    components:{
      SystemBuilderQuickstart
    },
    data(){
        return{
          rules: {
              numberfield: value => {
            const pattern = /^[+-]?(\d+(?:[\.\,]\d{2})?)$/;
            return pattern.test(value) || "Not a valid number.";
          },
        telnr: value => {
            const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
            return pattern.test(value) || "Not a telephone number.";
          },
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        invitedialog: false,
          invitesnackbar: false,
          ClientAppURL: '',
          InvitingUserEmail: '',
          BUEntity: '',
          ClientAppSignedIn: false,
      ClientAppAuthDialog: false,
      ConfigSystemEntities: [],
      ClientappSignedInEmail: '',
      ClientFireStore: {},
      QuickstartStep: 1,
      QuickStartDialog: false,
      ClientSystemAdminDialog: false,
      ClientDBBusinessUnits: [],
      ClientDBAdministrators: [],
      ClientAppUsers: [],
      clientApp: {},
      clientDB: '',
      BaseClientSystemReady: false,
      firebaseConfig: '',
      SubCollections: [],
      BannerTabs: [],
        }
    },
    computed:{
         ConfigDB(){
      return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.ConfigEntityID)
    },
        ConfigEntityID(){
            return this.CurrentEntity.id
        },
        SystemisRA(){
      return process.env.VUE_APP_RA_SYSTEM_ID === process.env.VUE_APP_RA_PROVIDER_ID
    },
        EntityCollectionRef(){
            return db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc(this.CurrentEntity.id)
        },
        OperationalDB(){
            return db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.Record.id)
        },
      userLoggedIn () {
      return this.$store.getters.user
    },
    },
    created(){
        //simply moved here to actually get subcol data now let's log and watch
        this.SubCollections = this.CurrentEntity.SubCollections.map(subcol => {
            let newsubcol = JSON.parse(JSON.stringify(subcol))
            newsubcol.Data = []
            return newsubcol           
        })
        this.SubCollections .map(newsubcol => {
            this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds)
        })
        //this.GetConfigSystemEntities(db.collection('SystemConfig').doc('ConfigMaster').collection('entities'))
        //lol okay that is too complex. we need this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds) on system entities, period.
      // alert('builder called')
      this.GetSystemConfig(this.Record)
    //   db.collection('systems').doc()
    //     this.BUEntity = this.SystemEntities.find(obj => obj.id === 'Business_Units')
        // console.log(this.QuickstartStep)
        // console.log(this.CurrentEntity)
        // console.log(this.Record)
    },
    methods:{
        ToggleQuickStartDialog(bool){
            this.QuickStartDialog = bool
        },
        GetUserdata(userrole){
      //console.log(userrole)
      this.clientDB.collection('users').doc(userrole.id).onSnapshot(snapshot => {
        let userdata = snapshot.data()
        this.ClientDBAdministrators.push(userdata)
      })
    },
        GetSystemConfig(system){
      if(typeof system.QuickstartStep !== 'undefined'){
        //this.FinalizerecordLoadingdialog()
           this.QuickstartStep = system.QuickstartStep 
           if(this.QuickstartStep !== 6){
                this.QuickStartDialog = true
              }
        if(system.ClientFireStore){
                
          //console.log(system)
          let firebaseConfig = system.ClientFireStore
              this.ClientFireStore = firebaseConfig
              //console.log('const firebaseConfig = firebaseConfig',firebaseConfig)
              //console.log(this.ClientFireStore,firebase.apps)
              let check = firebase.apps.find(obj => obj.name === system.Name)
                if(check){
                  this.clientApp = check
                }
                else{
                  this.clientApp = firebase.initializeApp(this.ClientFireStore, system.Name)
                }
                this.ClientAppAuth()
            }
      }   
      else{
        //this.FinalizerecordLoadingdialog()
        this.QuickStartDialog = true
      } 
        
      },
        async ClientAppAuth(){
      const clientdb = this.clientApp.firestore();
      let vm= this
      vm.clientDB = vm.clientApp.firestore()
                await this.clientApp.auth().onAuthStateChanged(function(user) {
                  if(user){
                    vm.ClientAppSignedIn = true
                    
                    vm.clientDB.collection('businessunits').onSnapshot(res => {
                                    const changes = res.docChanges();
                                    changes.forEach(change => {
                                      if (change.type === 'added') {
                                        vm.ClientDBBusinessUnits.push({
                                          ...change.doc.data(),
                                          id: change.doc.id
                                        })
                                      }
                                    })                 
                                  })
                    vm.clientDB.collection('users').doc(user.uid).onSnapshot(snapshot => {
                          vm.ClientAppUser = snapshot.data()
                          vm.ClientAppUser.id = user.uid
                          vm.GetClientDBSystem()
                          //console.log(vm.ClientAppUser)
                          if(vm.ClientAppUser && vm.ClientAppUser.id){
                            // vm.ClientAppUser.id = user.uid
                            vm.UserPicture = vm.ClientAppUser.Profile_Pic
                            vm.clientDB.collection('userroles').doc(user.uid).onSnapshot(rolesnapshot => {
                              if(rolesnapshot){
                               
                             vm.ClientappSignedInEmail = user.email
                              //vm.getTickets(clientdb)                    
                              
                              vm.clientDB.collection('userroles').where('rolesarrayQuery','array-contains','System Admin').onSnapshot(res => {
                                    const changes = res.docChanges();
                                    changes.forEach(change => {
                                      if (change.type === 'added') {
                                        let obj = {
                                          ...change.doc.data(),
                                          id: change.doc.id
                                        }
                                        vm.GetClientAppUsers()
                                        vm.GetUserdata(obj)
                                      }
                                    })                 
                                  })
                              }
                            
                              })
                          }                   
                          
                        })                        
                  }
                  else{
                    //okay I think if no users in users in client db, then..create everythign needed in client db. the base stuff
                    //remember it is within quickstartdialog that you first give the firebaseconfig, and therfore nothing could hae been done prior to this actually. 
                    if(vm.QuickstartStep === 8){
                      vm.ClientAppAuthDialog = true
                    }
                     
                    // if(vm.QuickstartStep === 7){
                    //   vm.ClientAppAuthDialog = true
                    // }
                    // if(vm.QuickstartStep > 2){
                      
                    //  vm.clientDB.collection('businessunits').onSnapshot(res => {
                    //       const changes = res.docChanges();
                    //       changes.forEach(change => {
                    //         if (change.type === 'added') {
                    //           vm.ClientDBBusinessUnits.push({
                    //             ...change.doc.data(),
                    //             id: change.doc.id
                    //           })
                    //         }
                    //       })                 
                    //     })
                    // }
                   
                  }
                })
    },
    GetClientAppUsers(){ 
      //alert('getting client users')     
      this.clientDB.collection('users').onSnapshot(res => {
        const changes = res.docChanges();
        changes.forEach(change => {
          if (change.type === 'added') {
            let userobj = {
              ...change.doc.data(),
              id: change.doc.id
            }
              this.clientDB.collection('userroles').doc(userobj.id).onSnapshot(snapshot => {
              let userdata = snapshot.data()
              //console.log('userdata',userdata)
              if(userdata.IsGuestUser){
                userobj.IsGuestUser = userdata.IsGuestUser
              }
              if(userdata.CanInviteUsers){
                userobj.CanInviteUsers = userdata.CanInviteUsers
              }
              userobj.Business_Unit = userdata.Business_Unit
              userobj.Business_Unitid = userdata.Business_Unitid              
      
              userobj.Subordinatearrayquery = userdata.Subordinatearrayquery
              userobj.SubordinateArrayDBrules = userdata.SubordinateArrayDBrules
              userobj.rolesarrayDBRules = userdata.rolesarrayDBRules
              userobj.rolesarrayQuery = userdata.rolesarrayQuery   
              this.clientDB.collection('sitemoderators').doc(userobj.id).onSnapshot(modsnapshot => {
                let moderatordata = modsnapshot.data()
                //console.log('moderatordata',moderatordata)
                if(moderatordata && moderatordata.ModerationPermissions){
                  userobj.ModerationPermissions = moderatordata.ModerationPermissions
                  this.ClientAppUsers.push(userobj)
                }
                else{
                  this.ClientAppUsers.push(userobj)  
                }                                       
              })           
               
            })
          }         
                              
        })
      })
    },
    GetClientDBSystem(){
      //okay just hold a minute here honestly this is super overkill.
      //this very function only gets calls when we hit a client user...why in the world would we have a client user and not haveclient  configmaster, or even client master,
      //IF the client config master and system master replication occurs upon create of system admin?
      //okay well its simple. there is plenty that needs to be called. you get? meaning there is so much entities and all fields and whatever, this needs to be called before replication
      //so technically really yes, I think this function is called and should be called two times
      //1. When I create client admin, meaning it does not have config master
      //2. when the system is opened again at a later stage. 
      //therefore it has to check whether it has this shit or not
      //perhaps the overcomplication is really about checking system master AND then configmaster, considering bothc reated same time. but something could go wrong so let's humor this
      //essentially then yescalling from these two places and as a failsafe, if no system master first check if it needs config master as well
            ////console.log('GetClientDBSystem',this.clientDB,this.ClientAppSignedIn)
      this.clientDB.collection('SystemConfig').doc(this.Record.id).onSnapshot(snapshot => {
        let system = snapshot.data()
        //console.log('ClientDBSystem',system)
        if(!system){
            
          this.CheckConfigMaster(this.clientDB.collection('SystemConfig').doc('ConfigMaster'),this.clientDB,this.Record.id,this.Record,this.ClientAppUser)
          //this.CheckConfigMaster(query,activedb,systemid,System,Administrator){
           //SO MY THINKING IS WE JUST NEED TO REPLICATE SYSTEM CONFIG AND CONFIG MASTER
           //this.ReplicateSystemConfig()
        }
        else{
          this.BaseClientSystemReady = true
          
          //this.FinalizerecordLoadingdialog() 
        }
      })
    },
        AuthenticateClientApp(){
      this.ClientAppAuthDialog = true
    },
    CheckConfigMaster(query,activedb,systemid,System,Administrator){
        //nw this function, yes. only ever called from GetclientDBSystem. and this only called either on adding admin, or reopening system
        //now the GetclientDBsystem function only triggers this very function IF and ONLY IF there is no system master on client end.
        //but the function calls configmaster, this is what we call here, client db config master
      query.onSnapshot(snapshot => {
        let systemdata = snapshot.data()
        
        //console.log('CheckConfigMaster systemdata',systemdata,query,activedb,systemid,System,Administrator,this.userLoggedIn)
        if(systemdata && Administrator.id === this.userLoggedIn.id){
            //why would my admin id ever match my Ra user id?
            //I am telling you this soag is about Ra recreate, it will NEVER be used...
          this.GetConfigSystemEntities(activedb.collection('SystemConfig').doc('ConfigMaster').collection('entities'))
        }
        else{
          if(!systemdata){
            //probably on first load we never created configmaster on client
            //yes we do NOt have config master
          //this.CreateBasicConfigMaster(activedb.collection('SystemConfig').doc('ConfigMaster'),this.System,systemid,Administrator)
          //okay now this is WRONG. CreateBasicConfigMaster as only created for recreate of RA mind you totally stupid if you ask me. 
          //what we need here is to ReplicateconfigMasters() this way it needs not be in below "else" statement
          this.ReplicatetoConfigMasters()
          }
          else{
            console.log('this would create client system master')
            //now we gotta concern ourselves on system master. but now we must remember
            //this entire function only called when no system master was found. so it's very safe to say let us then replicate the config. 
            //however!!!!! It's not to say we have all we needed let's first log this shit

            //this.ReplicateSystemConfig()
            //the above would trigger
            // this.ReplicateClientMaster()
            // this.ReplicatetoConfigMasters()
            // this.CopyAutoNumbers()

            //do we have the values, are the entities and everything called?
            //no we have nothing. The previous version called from dynamicredodata from datasingleviewcomp that already called all subcollections

            //okay no wait. ReplicateSystemConfig() would 
            //1. Replcicate from currententity all fields, all values
            console.log(this.CurrentEntity.AllFields)
            
            //2. it would ReplicateClientMaster() which is effectively running through subcol prop. Entities and the AllFields of each. we have nothing onthis so far
            //need to run from GetConfigSystemEntities
            console.log(this.CurrentEntity,this.SubCollections)
            //HOWEVER WE USE this.Record.SubCollections IN FUNCTION which sucks dont it?

            //3. it would ReplicatetoConfigMasters() which truly 
            //3.1 looping through this.CurrentEntity.SubCollections but what for I do not know!!
            //3.2 ReplicateFonts() which yes we know it should
            //3.3 then ProcessReplicateSystemConfig() I have no freaking clue why...
            
            //I think ReplicatetoConfigMasters() , apart from replicatefonts is a dud. 
            //it's got no value. like we only ever need Fields, Entities and Systems entities. And in all honesty I don't even think we need them anymore bt...what's the purpose? 
            //the goal initially was we could dynamically maintain system config options to client Suites through the Ui updates we performe on RA...right? The issue being WTf is the point now?
            //because now I must update the config mater of each client is that easier than updating the code on eahc? like truly?

            //4. it would CopyAutoNumbers(). The issue with this is I am unsure how we should approach autonumbers/ But it would seem we are replicating in configmater as well as system id. I guess whatever. 
            //one day remove whichever one not needed. My thoughts are - sure we need it in client system master if the client uses it. but until it's activated for a client through plugin configmaster can hold it

            //now what about the missing stuff though? Well we need
            //5. PhotoLibraries (DDED NOW)
            //6. PDf ExportFunctions (which is probably also best left in configmaster until plugin triggers it) (ADDED NOW)

            //so before we launch this simply FIRSTLY what happens with ReplicateconfigMasters?
            //keep it but then let's lose ProcessReplicateSystemConfig I think it had more to do with replicating from our systemconfig view, to the configmaster...
            //nope okay nevermind keep it all
            //wait
            //WTF is ReplicateconfigMasters about in this context where we know we got configmasters???
            //good so we remove it, then thus

            //ReplicateSystemConfig() would
            //1. Replcicate from currententity all fields, all values
            //2. it would ReplicateClientMaster() which is effectively running through subcol prop. Entities and the AllFields of each. running from this.SubCollections
            //4. it would CopyAutoNumbers()
            //5. PhotoLibraries (DDED NOW)
            //6. PDf ExportFunctions
            //there fore only outstanding is?
            //get currententitysubcollections, or like SubCollections array for this.Record

            this.ReplicateSystemConfig(true)
          }
          
          //if(this.SystemisRA){
          //this was the problem the system is always ra right? I think the intention was if the systemobj is systembuilder, like we rebuilding RA...
          //this.CreateBasicConfigMaster(activedb.collection('SystemConfig').doc(systemid),this.System,systemid,Administrator,true)
          //honestly we can worry about rebuilding RA again later not happenign soon...
          //if(systemid !== process.env.VUE_APP_RA_SYSTEM_ID){
            //WHAT WAS THIS ABOUT? if it would ALWAYS be the case! unless we maintain Ra from Ra, but we got ways for that so this is uneccesary check 
            //this.CreateClientSystemMaster()
          //}

        }
        
      })
      
    },
    CreateConfigEntity(Type,configpath,System,systemid,Administrator,systemmaster){
    let configentity = ''
    let entityrecords = []
    if(Type === 'Systems'){
     configentity = {
        DisplayName: 'Systems',
        SingleName: 'System',
        Entity_Type: {ID: 1000001, Name: 'System Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Business Unit',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'Name',
          DisplayName: 'Name',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'Name',
        SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
    }
    else if(Type === 'Entities'){
     configentity = {
        DisplayName: 'Entities',
        SingleName: 'Entity',
        Entity_Type: {ID: 1000002, Name: 'Entity Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Custom Roles',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'DisplayName',
          DisplayName: 'DisplayName',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: true,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'DisplayName',
        // SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
    }
    else if(Type === 'Fields'){
     configentity = {
        DisplayName: 'Fields',
        SingleName: 'Field',
        Entity_Type: {ID: 1000003, Name: 'Field Builder'},
        dataseticon: 'mdi-apps',
        datasetsecuritystructure: 'Custom Roles',
        systemid: systemid,
        system: {
          name: System.Name,
          id: systemid
        },
        RecordPrimaryField: {
          Name: 'Type',
          DisplayName: 'Type',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        Primary_Field_Name: 'Type',
        // SingleRecordNavMenu: true,
        SingleBuildTabs: []
      }
      let fieldtypes = ['Single Line Text','Multiple Lines Text','Boolean','Option Set','Date','AutoNumber Field','Number Field','Single File Upload','Calculated Field','Map Location','Shared Doc Link','Lookup']
      entityrecords = fieldtypes.map(type => {
        let fieldobj = {
          Type: type,
          Created_By: {id: Administrator.id, Name: Administrator.Name, Surname: Administrator.Surname, Full_Name: Administrator.Full_Name},
          Created_Byid: Administrator.id,
          Created_On: new Date(),
          Modified_By: {id: Administrator.id, Name: Administrator.Name, Surname: Administrator.Surname, Full_Name: Administrator.Full_Name},
          Modifiedid: Administrator.id,
          Modified_On: new Date(),
        }
        let Status = this.DefaultStatusField.Options.find(obj => obj.Name === 'Active')
        let StatusReason = Status.Options.find(obj => obj.Name === 'Open')
        fieldobj.Status = Status
        fieldobj.Status_Reason = StatusReason
        return fieldobj
      })
      
    }
    
      let entityfields = [
        {Name: 'Created_By', DisplayName: 'Created By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Created_On', DisplayName: 'Created On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Modified_By', DisplayName: 'Modified By', Type: 'Lookup',LookupFieldName: 'Full_Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Modified_On', DisplayName: 'Modified On', Type: 'Date',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        
      ]
      entityfields.push(this.DefaultStatusField)
      if(Type === 'Systems'){
        let additionalfields = [{Name: 'Owner', DisplayName: 'Owner', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Users',RelatedLocation: 'User', RelatedRecord: 'Users',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
        {Name: 'Business_Unit', DisplayName: 'Business Unit', Type: 'Lookup',LookupFieldName: 'Name',RelatedBuildID: 'Business_Units',RelatedLocation: 'BusinessUnit', RelatedRecord: 'BusinessUnits',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true,  SystemRequired: true},
         {Name: 'Company', DisplayName: 'Company', Type: 'Lookup',LookupFieldName: 'Company_Name',RelatedBuildID: 'Accounts',RelatedLocation: 'Account', RelatedRecord: 'Accounts',Primary: false, Created: true, IsMandatory: false, IsHeader: false, UsedonCompForm: false, UsedonForm: true},
         {
          Name: 'Name',
          DisplayName: 'Name',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        }
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      else if(Type === 'Entities'){
        let additionalfields = [
          {
          Name: 'DisplayName',
          DisplayName: 'DisplayName',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: true,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        {
          Name: 'Entity Type',
          DisplayName: 'Entity Type',
          Type: 'Option Set',
          UsedonForm: true,
          IsHeader: true,
          HeaderIndex: 2,
          Options: [
            
            {
              ID: 1000000,
              Name: 'Activities'
            },
            {
              ID: 1000001,
              Name: 'System Builder'
            },
            {
              ID: 1000002,
              Name: 'Entity Builder'
            },
            {
              ID: 1000003,
              Name: 'Field Builder'
            },
            {
              ID: 1000004,
              Name: 'User Entity'
            },
            {
              ID: 1000005,
              Name: 'Business Unit Entity'
            },
            {
              ID: 1000006,
              Name: 'Standard'
            },
            {
              ID: 1000007,
              Name: 'Store Product'
            },
            {
              ID: 1000008,
              Name: 'Classified Listing'
            },
            {
              ID: 1000009,
              Name: 'Calendar'
            },
            {
              ID: 1000010,
              Name: 'Scheduled Booking'
            },
            {
              ID: 1000011,
              Name: 'Document Library'
            },
            {
              ID: 1000012,
              Name: 'Web Form'
            },
            {
              ID: 1000013,
              Name: 'Inventory Article'
            },
            {
              ID: 1000014,
              Name: 'Ticketing Table'
            },
             {
              ID: 1000015,
              Name: 'User Guide'
            }
          ],
          lastassignedinteger: 1000013

        },
        {
          Name: 'Description',
          DisplayName: 'Description',
          Type: 'Multiple Lines Text',
          IsHeader: true,
          UsedonForm: false,
          HeaderIndex: 3
        },
        {
          Name: 'Primary Field Name',
          DisplayName: 'Primary Field Name',
          Type: 'Single Line Text',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 4
        },
        {
          Name: 'Table Icon',
          DisplayName: 'Table Icon',
          Type: 'Single Line Text',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 5
        },
        {
          Name: 'Security Structure',
          DisplayName: 'Security Structure',
          Type: 'Option Set',
          UsedonForm: false,
          IsHeader: true,
          HeaderIndex: 6,
          Options: [
            {
              ID: 1000001,
              Name: 'Custom Roles'
            },
            {
              ID: 1000002,
              Name: 'Business Unit'
            },
            {
              ID: 1000003,
              Name: 'Warehouse'
            }
          ],
          lastassignedinteger: 1000003
        },
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      else if(Type === 'Fields'){
        let additionalfields = [
          {
          Name: 'Type',
          DisplayName: 'Type',
          Type: 'Single Line Text',
          Created: true,
          UsedonForm: false,
          Primary: true,
          IsHeader: true,
          IsMandatory: true,
          IsSearch: true,
          HeaderIndex: 1
        },
        ]
        entityfields = entityfields.concat(additionalfields)
      }
      //Okay system master and configmaster alike for a SystemBuilder actaully needs to here do something else. 
      //we need the subcollections applicable to a system to be added automatically. you get? So we don't ahve that now
      //Additionally remember "entities" subcolleciton needs to be fitted with a boolean "EntityBuilder" which will be used to respond to RecordPrimaryField and Primary Field Name and whatever. 
      //Also we need to fit "entities" subcol with something that would sync in two ways
      //1. The first sync is to sync with the template it generated from, a button to "update"
      //2. the second seync actually is a matter of checking updates on client end somehwere somehow check client db see what changes move over to RA, and vice versa
      if(!systemmaster && Type === 'Systems'){
        
        configpath.set({Disabled: false}).then(configdoc => {
       let systemdocpath =  configpath.collection('entities').doc(configentity.DisplayName.split(' ').join('_'))
        systemdocpath.set(configentity).then(doc => {
          entityfields.map((field,fieldindex) => {
            field.Name = field.Name.split(' ').join('_')
            systemdocpath.collection('AllFields').doc(field.Name).set(field).then(fielddoc => {
            })
          })          
        })
      })
      }
      else{
        let systemdocpath =  configpath.collection('entities').doc(configentity.DisplayName.split(' ').join('_'))
        systemdocpath.set(configentity).then(doc => {
          entityfields.map((field,fieldindex) => {
            field.Name = field.Name.split(' ').join('_')
            systemdocpath.collection('AllFields').doc(field.Name).set(field).then(fielddoc => {
            })
          })    
        }) 
        //console.log('entityrecords',entityrecords)
        entityrecords.map(record => {
            //console.log('record',record,configentity.DisplayName.split(' ').join('').toLowerCase(),[configentity.Primary_Field_Name],record[configentity.Primary_Field_Name].split(' ').join('_'))
            db.collection(configentity.DisplayName.split(' ').join('').toLowerCase()).doc(record[configentity.Primary_Field_Name].split(' ').join('_')).set(record)
          }) 
      }
    },
    GetConfigSystemEntities(query){
        let vm = this
        //console.log('query',query,vm.ConfigEntityID)
        query.onSnapshot(res => {
            const changes = res.docChanges();
            let arraylength = res.docs.length
            changes.forEach(change => {
              if (change.type === 'added') {
                let build = change.doc.data()
                build.id = change.doc.id
                //console.log('build',build)
                vm.GetAllEntityProps(build,query).then(function(allpropsresult) {
                    //console.log('allpropsresult',allpropsresult,build)
                    if(allpropsresult){
                        vm.ConfigSystemEntities.push(build)
                        //console.log(vm.ConfigSystemEntities.length,arraylength,vm.ConfigSystemEntities.length === arraylength)
                        if(vm.ConfigSystemEntities.length === arraylength){
                          if(vm.$route.query && vm.$route.query.Parent){
                            //vm.CurrentEntity = vm.ConfigSystemEntities.find(obj => obj.id === vm.ConfigEntityID)
                            vm.StatusField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
                          }
                          else{
                            //vm.CurrentEntity = vm.ConfigSystemEntities.find(obj => obj.id === vm.ConfigEntityID)
                            vm.StatusField = vm.CurrentEntity.AllFields.find(obj => obj.id === 'Status')
                          }  
                          //console.log(vm.ConfigEntityID,vm.ConfigDB,vm.CurrentEntity,vm.ConfigSystemEntities,vm.EntityReady)                          
                            vm.GetEntitySingleBuildTabs(vm.ConfigDB)
                        }
                    }
                })  
              }
            })
            
        })
    },
    GetAllEntityProps(build,query){
        let vm = this
        // console.log('calling props')
        return new Promise(function(resolve, reject) {
        vm.GetEntityAllFields(build,query).then(function(allfieldsresult) {
                if(allfieldsresult){
                    // console.log(allfieldsresult)
                    vm.GetEntitySubCollections(build,query).then(function(subcolresult) {
                        // console.log(subcolresult)
                    if(allfieldsresult && subcolresult){
                        vm.GetEntityProcesses(build,query).then(function(processesresult) {
                        // console.log(processesresult)
                        vm.GetDigitizedForms(build,query).then(function(formsresult) {
                            vm.GetDocumentRegisters(build,query).then(function(docregresult) {  
                                vm.GetGalleries(build,query).then(function(galleryres) {                          
                                vm.GetEntityAutomations(build,query).then(function(automationsresult) {
                                    //console.log(automationsresult)
                                vm.GetGoalMeasurements(build).then(function(goalsresult) {
                                    if(allfieldsresult && subcolresult && processesresult && formsresult && automationsresult && goalsresult && docregresult && galleryres){
                                        resolve('got the props for '+build.id)
                                    }
                                })
                                })
                                })
                                })
                        })
                        })
                    }
                    })
                }           
            })
        })
        },
    GetEntityAllFields(build,query){    
            // console.log('calling fields') 
            build.AllFields = []
                   
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('AllFields').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.AllFields.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.AllFields.length === arraylength){
                    
                resolve('Method SystemEntities AllFields Finished for ' + build.AllFields.length)
                }
                })
                })
            })
        },
        GetEntitySubCollections(build,query){     
            //console.log('calling SubCollections',build,query)
            build.SubCollections = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('SubCollections').onSnapshot(res => {
            let arraylength = res.docs.length
            if(arraylength === 0){
                    resolve('Method SystemEntities AllFields Finished for ' + build.SubCollections.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.SubCollections.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.SubCollections.length === arraylength){
                    
                resolve('Method SystemEntities SubCollections Finished for ' + build.SubCollections.length)
                }
                
                })
                })
            })
        },
        GetEntityProcesses(build,query){    
            // console.log('calling fields') 
            build.EntityProcesses = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('EntityProcesses').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.EntityProcesses.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.EntityProcesses.length === arraylength){
                    
                resolve('Method SystemEntities EntityProcesses Finished for ' + build.EntityProcesses.length)
                }
                })
                })
            })
        },
        GetDigitizedForms(build,query){    
            // console.log('calling fields') 
            build.DigitizedForms = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('DigitizedForms').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DigitizedForms.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DigitizedForms.length === arraylength){
                    
                resolve('Method SystemEntities DigitizedForms Finished for ' + build.DigitizedForms.length)
                }
                })
                })
            })
        },
        GetDocumentRegisters(build,query){
            build.DocumentRegisters = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('DocumentRegisters').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.DocumentRegisters.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.DocumentRegisters.length === arraylength){
                    
                resolve('Method SystemEntities DocumentRegisters Finished for ' + build.DocumentRegisters.length)
                }
                })
                })
            })
        },
        GetGalleries(build,query){
            build.Galleries = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('Galleries').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.Galleries.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.Galleries.length === arraylength){
                    
                resolve('Method SystemEntities Galleries Finished for ' + build.Galleries.length)
                }
                })
                })
            })
        },
        GetEntityAutomations(build,query){    
            // console.log('calling fields') 
            build.automations = []            
            let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            query.doc(build.id).collection('automations').onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                build.automations.push({
                    ...change.doc.data(),
                    id: change.doc.id
                })
                }
                if(build.automations.length === arraylength){
                    
                resolve('Method SystemEntities automations Finished for ' + build.automations.length)
                }
                })
                })
            })
        },
        GetGoalMeasurements(build){
          build.GoalMeasurements = []
          let vm = this
            return new Promise(function(resolve, reject) {
                // console.log('attempting to get all fields for '+build.id,build.AllFields)
            db.collection('goals').where('PrimaryEntity','==',build.id).onSnapshot(res => {
                let arraylength = res.docs.length
                if(arraylength === 0){
                    resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                }
            const changes = res.docChanges();
            changes.forEach(change => {
                if (change.type === 'added') {
                  let obj = {
                        ...change.doc.data(),
                        id: change.doc.id
                    }
                    obj.Targets = []
                    vm.GetGoalTargets(obj).then(result => {
                        //console.log(obj,result)
                        build.GoalMeasurements.push(obj)
                        if(build.GoalMeasurements.length === arraylength){                    
                        resolve('Method SystemEntities GoalMeasurements Finished for ' + build.GoalMeasurements.length)
                        }
                    })
                }
                
                })
                })
            })
        },
        GetGoalTargets(obj){
            let vm = this
            return new Promise(function(resolve, reject) {
                db.collection('goals').doc(obj.id).collection('Targets').onSnapshot(res => {
                    let listlength = res.docs.length
                    if(listlength === 0){
                        resolve('No target for '+obj.id)
                    }
                    let counter = 0
                    const changes = res.docChanges();
                    changes.forEach((change,i) => {
                    if (change.type === 'added') {
                        let targetobj = {
                            ...change.doc.data(),
                            id: change.doc.id
                        }                        
                        let targetslength = targetobj.Targets.length
                        targetobj.Targets.map((target,i) => {
                            obj.Targets.push(target)
                            if(i-1+2 === targetslength){
                                counter = counter-1+2
                                if(counter === listlength){
                                 resolve('Got all targets for ',obj.id)   
                                }
                                
                            }
                        })                        
                    }
                     
                    })
                   
                })
            })

        },
    GetEntitySingleBuildTabs(query){
      query.onSnapshot(snapshot => {        
        let entity = snapshot.data()     
        entity.id = this.ConfigEntityID   
        //console.log('1SystemConfigentity',entity,this.ConfigSystemEntities)
          if(entity){
            // if(this.$route.meta && this.$route.meta.RecordisSystem){
             
            //   this.FinalizerecordLoadingdialog() 
            // }
            //okay we need to call ConfigSystemEntities if RecordisSystem
            // if(this.CurrentEntity && this.CurrentEntity.SingleRecordNavMenu){
            //   this.SingleRecordNavMenu = true
            // }
            //console.log('this.CurrentEntity',this.CurrentEntity)
            this.PrimaryFieldName = entity.RecordPrimaryField.Name
            if(this.CurrentEntity && this.CurrentEntity.SubCollections){
              //alert('yep it has subcols')
              this.SubCollections = []
              this.CurrentEntity.SubCollections.map(subcol => {
                let subcolheaders = []
                subcol.Headers.map(header => {
                  let headerobj = { text: header.text, value: header.value, Type: header.Type }
                  if(headerobj.Type === 'Option Set'){
                    headerobj.Options = header.Options
                    headerobj.propvalue = header.value
                    headerobj.value = headerobj.value+'.Name'
                  }
                  if(header.Primary){
                    headerobj.Primary = header.Primary
                  } 
                  if(header.CommonFieldType){
                    headerobj.CommonFieldType = header.CommonFieldType
                  }
                  if(header.IsContentEditable){
                    headerobj.IsContentEditable = header.IsContentEditable
                  }
                  if(header.FieldFormulas){
                    headerobj.FieldFormulas = header.FieldFormulas
                  }
                  subcolheaders.push(headerobj)
                })
                subcolheaders.push({ text: 'Linked From', value: 'LookupObj'})
                subcolheaders.push({ text: 'Action', value: 'action'})         
                let subcolobj = {
                  id: subcol.id,
                  Name: subcol.id,
                  Data: [],
                  Headers: subcolheaders,
                  Type: subcol.Type
                }
                if(subcol.Type === 'Lookup' && subcol.LookupBuilds){
                  //console.log('subcolobj',subcol,subcolobj)
                  subcolobj.LookupBuilds = subcol.LookupBuilds.map(build => {
                  let newbuildobj = {id: build.id, identifier: build.recordprimaryfield.Name,recordprimaryfield: build.recordprimaryfield}
                  let lookupbuild = this.ConfigSystemEntities.find(obj => obj.id === build.id)
                  //console.log('subcol obj',lookupbuild,this.ConfigSystemEntities,build.id)
                  if(lookupbuild){
                    newbuildobj.AllFields = lookupbuild.AllFields
                  }
                  
                   if(build.LinkedFields){
                    newbuildobj.LinkedFields = build.LinkedFields
                  }
                  if(build.single){
                    newbuildobj.single = build.single
                  }
                  if(build.SubCollections){
                    newbuildobj.SubCollections = build.SubCollections
                  }
                  //newbuildobj.recordprimaryfield = build.recordprimaryfield
                  if(build.Entity_Type){
                    newbuildobj.Entity_Type = build.Entity_Type
                    if(build.Entity_Type.Name === 'Store Product'){ 
                      let buildapp = this.AppsDataBase.find(obj => obj.id === lookupbuild.Primary_Appid)                     
                      let articletablefield = lookupbuild.AllFields.find(obj => obj.id === 'Inventory_Article')
                      if(articletablefield){
                        newbuildobj.ArticleTableField = articletablefield
                      }
                      if(buildapp && !buildapp.Plugins.find(obj => obj.id === 'Warehouse_Outbound')){
                        newbuildobj.Bypass_Picking_and_Packing = true
                      }
                      if(typeof subcol.Available_Stock_Only !== 'undefined'){
                        newbuildobj.Available_Stock_Only = subcol.Available_Stock_Only
                      }
                    }
                  }
                  if(lookupbuild.Security_Structure){
                    newbuildobj.Security_Structure = lookupbuild.Security_Structure
                  }
                  //console.log('newbuildobj',newbuildobj)
                  // Bypass_Receiving
                  return newbuildobj
                  })
                }
                if(subcol.ConfigValueasDocID){
                  subcolobj.ConfigValueasDocID = subcol.ConfigValueasDocID
                  subcolobj.DocIDProp = subcol.DocIDProp
                }
                if(subcol.Lookup_Once_Only){
                  subcolobj.Lookup_Once_Only = subcol.Lookup_Once_Only
                }
                if(subcol.PrimaryHeader){
                  subcolobj.PrimaryHeader = subcol.PrimaryHeader
                }
                if(subcol.HasSingleView){
                  subcolobj.HasSingleView = subcol.HasSingleView
                }
                 if(subcol.EntityBuilder){
                  subcolobj.EntityBuilder = subcol.EntityBuilder
                }
                 if(subcol.HeadersFromSubCol){
                  subcolobj.HeadersFromSubCol = subcol.HeadersFromSubCol
                  subcolobj.HeadersSubCollection = subcol.HeadersSubCollection
                }
                this.SubCollections.push(subcolobj)
                this.SubCollections.map(newsubcol => {
                  this.GetSubCollectionData(newsubcol.id,newsubcol.Data,newsubcol.LookupBuilds)
                  
                })
              })
              //now we still need to call the subcollectiondata and push to subcolobj.Data
              //this[subcol.id]
            }
            let array = JSON.parse(JSON.stringify(entity.SingleBuildTabs))
            if(entity.Entity_Type && entity.Entity_Type.Name === 'Web Form'){
              let tab = {									
                RowColor: '#ffffff',									
                Name: 'Form Data',
                DisplayName: 'Form Data',
                DataViewName: 'Data Single Standard',	
                Icon: '',								
                SectionType: true,									
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                    Open: true,									
                    ShowComponents: true,									
                    Icon: 'mdi-database',									
                    Height: 300,									
                    Elements: [
                      {									
                RowColor: '#ffffff',									
                Name: this.CurrentEntity.WebFormTab.SingleName+' Form',
                DisplayName: this.CurrentEntity.WebFormTab.SingleName+' Form',
                DataViewName: 'Data Single Fields',	
                DataSingleStandard: true,	
                Fields: [],			
                Transparent: true,									
                Alignment: 'center',									
                Justify: 'justify-center',									
                RowTransparency: 'transparent',									
                Open: true,									
                ShowComponents: true,									
                Icon: 'mdi-clipboard-list',									
                Height: 300,									
                Rows: [],									
                BGColor: {
                    hex: '#ffffff',
                    hexa: '#ffffffff'
            },									
                FontColor :{									
                        hex: '#000000'									
                },									
                HeaderColor: {									
                    hex: '#000000'									
                },									
                MarginTop: 0,									
                MarginBottom: 0,									
                MarginLeft: 0,									
                MarginRight: 0,									
                FlexXLRG:11,									
                FlexLarge: 11,									
                FlexMedium: 11,									
                FlexSmall: 11,									
                FlexXSmall: 11,										
                Cols: 12,									
            },
                    ],									
                    Rows: [],									
                    BGColor: {
                        hex: '#ffffff',
                        hexa: '#ffffffff'
                },									
                    FontColor :{									
                            hex: '#000000'									
                    },									
                    HeaderColor: {									
                        hex: '#000000'									
                    },									
                    MarginTop: 0,									
                    MarginBottom: 0,									
                    MarginLeft: 0,									
                    MarginRight: 0,									
                    FlexXLRG:10,									
                    FlexLarge: 10,									
                    FlexMedium: 10,									
                    FlexSmall: 11,									
                    FlexXSmall: 11,									
                    Cols: 12,									
                }
                //console.log(this.CurrentEntity)
                let defaultwebformtabfields = [this.CurrentEntity.RecordPrimaryField] 
                defaultwebformtabfields = defaultwebformtabfields.concat(this.CurrentEntity.AllFields.filter(field => {
                  return field.UsedonWebForm
                }))
                tab.Elements[0].Fields = defaultwebformtabfields
                if(array.length === 0){
                  array.push(tab)
                }
                else{
                  //array.push(tab)
                }
                
            }
            if(entity.SinglePageBGGradient){
              this.PageBGGradient = entity.SinglePageBGGradient
              this.SinglePageBGGradient = entity.SinglePageBGGradient
            }
            this.SingleBuildTabs = array.map(tab => {
    
              // if(tab.InQuickNav){
                // let bannertab = {
                //   Name: tab.Name.split(' ').join(''),
                //   Icon: tab.Icon
                //   }                 
                // let banneron = this.BannerTabs.find(obj => obj.Name === bannertab.Name)
                // if(!banneron){
                //   this.BannerTabs.push(bannertab)
                // } 
               
              // }
              if(tab.Elements){
                tab.Elements.map(section => {
                  if(section.DataViewName === 'Data Single Activities' || section.DataViewName === 'Data Single Fields'){
                    if(!section.Name){
                      section.Name = section.DisplayName
                    }
                    if(section.Name){
                     let bannertab = {
                        Name: section.Name.split(' ').join(''),
                        }    
                        if(section.DataViewName === 'Data Single Fields' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-database'
                        }  
                        else if(section.DataViewName === 'Data Single Activities' && !bannertab.Icon){
                          bannertab.Icon = 'mdi-clipboard-list'
                        }            
                      let banneron = this.BannerTabs.find(obj => obj.Name === bannertab.Name)
                      if(!banneron){
                        this.BannerTabs.push(bannertab)
                      } 
                    }
                    
                  }                    
                })
              }
              
              if(tab.Type === 'Standard'){
                //alert(typeof 12/tab.Sections.length)
                let sectionlength = tab.Sections.length
                //alert(typeof sectionlength)
                tab.Sections = tab.Sections.map(section => {
                  section.Cols = 12/sectionlength
                  section.ColsMD = section.Cols
                 
                  if(sectionlength > 1){
                    section.ColsSM = 6
                  }
                  else{
                    section.ColsSM = 12
                  }
                   if(!section.FlexXLRG){
                    section.FlexXLRG = section.ColsMD
                    section.FlexLarge = section.ColsMD
                    section.FlexMedium = section.ColsMD
                    section.FlexSmall = section.ColsSM
                    section.FlexXSmall = 12
                  }
                  if(section.SectionType === 'Fields'){
                    section.Fields = section.Fields.map(field => {
                      if(field.Name && field.Name.Name){
                        return field.Name
                      }
                      else{
                        return field
                      }
                      
                    })
                  }
                  return section
                })
              }
              else if(tab.DataViewName === 'Data Single Cabinet'){
                if(tab.Elements){
                  tab.Elements.map(elmnt => {
                    if(elmnt.MultipleFileUploadType === 'Images' || elmnt.MultipleFileUploadType === 'Document Register' || elmnt.MultipleFileUploadType === 'Gallery'){
                      elmnt.DataArray = []
                      let cabinet = ''
                      if(elmnt.MultipleFileUploadType === 'Document Register'){
                        cabinet = this.CurrentEntity.DocumentRegisters.find(obj => obj.id === elmnt.Entityid)
                        if(cabinet && cabinet.PrimaryOptions){
                          elmnt.PrimaryOptions = cabinet.PrimaryOptions
                          elmnt.PrimaryOptionsInteger = cabinet.PrimaryOptionsInteger
                        }
                        if(!elmnt.PrimaryOptions){
                          elmnt.PrimaryOptions = []
                        }
                        if(!elmnt.PrimaryOptionsInteger){
                          elmnt.PrimaryOptionsInteger = 1000000
                        }
                      }
                      else if(elmnt.MultipleFileUploadType === 'Gallery'){
                        cabinet = this.CurrentEntity.Galleries.find(obj => obj.id === elmnt.Entityid)
                        
                      }
                      this.CustomRolesDefault.map(defrole => {
                        //console.log(defrole.Prop,cabinet[defrole.Prop],cabinet)
                          if(cabinet[defrole.Prop]){
                            elmnt[defrole.Prop] = cabinet[defrole.Prop]
                          }
                          if(cabinet[defrole.Name]){
                            elmnt[defrole.Name] = cabinet[defrole.Name]
                          }
                          if(!elmnt[defrole.Name]){
                            elmnt[defrole.Name] = []
                          }
                        })
                      this.GetCabinetDocuments(this.OperationalDB.collection(elmnt.Name.split(' ').join('').split('_').join('').toLowerCase()),elmnt)
                    }
                  })
                }
              }
              return tab
            })
            //console.log(this.SingleBuildTabs)
            if(this.$route.query && this.$route.query.TableType === 'Operational_Entity'){
              //this.FinalizerecordLoadingdialog()
            }
            // this.EntityReady = true
          }
          else{
            // this.EntityReady = true
          }
      })
    },
    GetSubCollectionData(subcolarrayname,subcoldata,LookupBuilds){
      this.OperationalDB.collection(subcolarrayname).onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let subcolentry = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
               subcoldata.push(subcolentry)
                // if(LookupBuilds){
                //   buildswithsubcol = build.SubCollections.filter(build => {return build.SubCollections && build.SubCollections.length > 0})                    
                //     if(buildswithsubcol.length === 0){
                //       subcoldata.push(subcolentry)
                //     }
                //     else{
                //       buildswithsubcol.map(build => {
                //       if(build.SubCollections){
                //         build.SubCollections.map(subcolsubcol => {
                //           build.SubCollections = []
                //           this.GetSubColSubCol(build,subcolsubcol,this.OperationalDB.collection(newsubcol.id).doc(subcolentry.id).collection(subcolsubcol))
                //         })                        
                //       }                      
                //     })
                //     }
                //   }
                //   else{
                //     subcoldata.push(subcolentry)
                //   }
              }
            })
      })
    },
    GetCabinetDocuments(query,elmnt){      
      query.onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                elmnt.DataArray.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
      })
    },
    CreateBasicConfigMaster(configpath,System,systemid,Administrator,systemmaster){
      //console.log('this.System',System)
      this.CreateConfigEntity('Systems',configpath,System,systemid,Administrator,systemmaster)     
      this.CreateConfigEntity('Entities',configpath,System,systemid,Administrator,systemmaster) 
      this.CreateConfigEntity('Fields',configpath,System,systemid,Administrator,systemmaster)
      //this.FinalizerecordLoadingdialog() 
    },
      ProcessInvitetoUser(){
        console.log('will invite user on '+this.InvitingUserEmail)
        let ref = this.clientDB.collection('users').doc()
        let newdocid = ref.id
        let NewUserObj = {
          Email: this.InvitingUserEmail,
          Status: {
            ID: 1000001,
            Name: 'Sent'
          },
          Business_Unit: {
            id: 'Head_Office',
            Name: 'Head Office'
          },
          Business_Unitid: 'Head Office',
          Created_By: {id: this.ClientAppUser.id, Name: this.ClientAppUser.Name, Surname: this.ClientAppUser.Surname, Full_Name: this.ClientAppUser.Full_Name},
          Created_Byid: this.ClientAppUser.id,
          Created_On: new Date(),
        }
        NewUserObj.Expired = false
        if(this.InviteUserisAdmin){
          NewUserObj.rolesarrayQuery = ['System Admin']
          NewUserObj.rolesarrayDBRules = {SystemAdmin: true}
        }
        this.clientDB.collection('userinvites').doc(newdocid).set(NewUserObj).then(doc => {
          console.log('created user invite record')
        })

        let path = this.SystemObj.ClientAppURL+'/UserInvite/'+newdocid
        let EmailBody = `Good Day<div><br></div><div>You have been invited to join `+this.SystemObj.Name+`. </div><div>Please click on the below link to accept and process your invite.</div><div><br></div>
        <a href="`+path+`">Open your Invite</a><br>
        <div>Please note that this link is PRIVATE and you can NOT share the link with anybody else, lest you leave your pending profile at risk for attacks</div>
        <div>Thank you in advance</div>`
        console.log(EmailBody)
          let EmailObject = {          
            EmailFrom: 'notification@rapidapps.co.za',
            EmailTo: this.InvitingUserEmail,
            EmailSubject: 'Invite to join - '+this.SystemObj.Name,
            EmailBody: EmailBody,

          }
              //alert('email sending')
              console.log(EmailObject)
              const functions = firebase.functions();
              const SendEmailNow = functions.httpsCallable('sendEmailNow');
              SendEmailNow(EmailObject).then(result => {
                console.log(result)
             
                  this.invitesnackbar = true
                this.CancelInvitingUserEmail()
              })
      },
      CancelInvitingUserEmail(){
        this.invitedialog = false
        setTimeout(() => {
          this.InvitingUserEmail = ''
        }, 8000);
      },
        ReplicateSystemMasterBU(){
          console.log(this.BUEntity)
          let path = db.collection('systems').doc(this.Record.id).collection('entities').doc(this.BUEntity.id)
          let buobj = Object.assign({},this.BUEntity)
          delete buobj.AllFields
          delete buobj.SubCollections
          delete buobj.Galleries
          delete buobj.DocumentRegisters
          delete buobj.EntityProcesses
          delete buobj.GoalMeasurements
          delete buobj.DigitizedForms
          delete buobj.buildid
          delete buobj.automations
          //buobj.Table_Icon = this.BUEntity.dataseticon
          buobj.Security_Structure = {
            ID: 1000001,
            Name: 'Custom Roles'
          }
          buobj.Entity_Type = {
            ID: 1000005,
            Name: 'Business Unit Entity'
          }
          buobj.SystemRequired = true
          delete buobj.dataseticon
          console.log(path,buobj)
          //okay actually we would do best to put this under current system ops doc "entities" SubColleciton. to be honest.

          path.set(buobj).then(doc => {
            this.BUEntity.AllFields.map(field => {
              path.collection('AllFields').doc(field.id).set(field)
            })
            
          })
        },
        ReplicateSystemConfigMastSubCols(){
      console.log(this.CurrentEntity)
      this.CurrentEntity.SubCollections.map(subcol => {
        db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('SubCollections').doc(subcol.id).set(subcol)
      })
    },
    ReplicateFonts(){
      db.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').onSnapshot(res => {
        const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                let fontdoc = {
                  ...change.doc.data(),
                  id: change.doc.id
                }
                console.log(fontdoc)
                this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AppFonts').doc(fontdoc.id).set(fontdoc)
              }
            })
      })
    },
    ReplicatetoConfigMasters(){
      this.ReplicateFonts()
      this.CurrentEntity.SubCollections.map(subcol => {
        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc('Systems').collection('SubCollections').doc(subcol.id).set(subcol)
      })
      //what about system "AllFields"
      this.ProcessReplicateSystemConfig(this.CurrentEntity)
      let entityobj = this.SystemEntities.find(obj => obj.id === 'Entities')
      this.ProcessReplicateSystemConfig(entityobj)
      let fieldobj = this.SystemEntities.find(obj => obj.id === 'Fields')
      this.ProcessReplicateSystemConfig(fieldobj)

    },
    ProcessReplicateSystemConfig(entity){
      let cliententityref = this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc(entity.id)
      //no more, we do from datarecordtoolbar for us now - let raentityref = db.collection('SystemConfig').doc('ConfigMaster').collection('entities').doc(entity.id)
      //one for client
      cliententityref.update({
        SingleBuildTabs: entity.SingleBuildTabs
      })
      entity.AllFields.map(field => {
        cliententityref.collection('AllFields').doc(field.id).set(field)
      })
      //another for us no more
      // raentityref.update({
      //   SingleBuildTabs: entity.SingleBuildTabs
      // })
      // entity.AllFields.map(field => {
      //   raentityref.collection('AllFields').doc(field.id).set(field)
      // })
      
    },
    ReplicateClientMaster(){
      
      
      //okay we create systemconfig and shite already as is all perfect, no worries so go ahead and replicate entities and shit
      if(this.SubCollections && this.SubCollections.length > 0){
        this.SubCollections.map(subcol => {
            if(subcol.Data){
                //right we need to add the data per subcol like uhm... hmmm
                    subcol.Data.map(subcolrecord => {
                    //console.log(subcolrecord.LookupObj,subcol)
                    let obj = Object.assign({},subcolrecord)
                    delete obj.CanGet
                    delete obj.CanDelete
                    delete obj.CanEdit
                    delete obj.SubCollections
                    delete obj.id
                    //console.log(obj)
                    if(subcol.id === 'entities' && obj.Security_Structure){
                      //alert('yay one')
                      obj.datasetsecuritystructure = obj.Security_Structure.Name
                      //console.log(obj)
                    }
                    this.clientDB.collection('SystemConfig').doc(this.Record.id).collection(subcol.id).doc(subcolrecord.id).set(obj).then(subcoldoc => {
                        //if(subcolrecord.id === 'Business_Units' && !subcolrecord.LookupObj){
                         if(!subcolrecord.LookupObj){
                          subcolrecord.LookupObj = {
                            list: 'Entities'
                          }
                        }
                        if(subcolrecord.LookupObj && subcolrecord.LookupObj.list){
                        let build = subcol.LookupBuilds.find(obj => obj.id === subcolrecord.LookupObj.list)
                        //console.log(build)
                            if(build.SubCollections){
                              if(subcol.EntityBuilder && build.id === 'Entities'){
                                //simply push the required
                                let gallreyon = build.SubCollections.find(obj => obj === 'Galleries')
                                if(!gallreyon){
                                  build.SubCollections.push('Galleries')
                                }
                                let docregon = build.SubCollections.find(obj => obj === 'Document_Registers')
                                if(!docregon){
                                  build.SubCollections.push('Document_Registers')
                                }
                                } 
                            let childsubcollength = build.SubCollections.length
                                build.SubCollections.map((subcolchild,subcolchildindex) => {
                                    subcolrecord.SubCollections = []
                                    this.GetSubColSubCol(build.id,subcolrecord,subcolchild,this.OperationalDB.collection(subcol.id).doc(subcolrecord.id).collection(subcolchild)).then(result => {
                                    if(subcolchildindex-1+2 === childsubcollength){
                                        //console.log('result',result)
                                        result.SubCollections.map(finalsubcol => {
                                            let docref = this.clientDB.collection('SystemConfig').doc(this.Record.id).collection(subcol.id).doc(result.id).collection(finalsubcol.SubColName)
                                            finalsubcol.SubColData.map(entry => {
                                                docref.doc(entry.id).set(entry)
                                            })
                                        })
                                        // subcolrecord = result
                                        // this.clientDB.collection('SystemConfig').doc(this.Record.id).collection(subcol.id).doc(result.id).collection(subcolchild.id).set(result).then(subcoldoc => {
                                        //     subcol.SubCollections.map(subcolchild)
                                        // })
                                        //okay now we got all subcols ready to add subcol under systemmaster
                                    }
                                    })
                                })   
                            }
                            else{
                                //there are no subcols, just add record right?
                            }
                        }
                        else{
                            //this is not a Lookup type subcolrecord,, like samplerecords...do we just add?
                        }   
                    })
                    
                })  
            }
            else{
                //okay there is a subcol but no data, so nothing to be added all is good
            }
          
          
        })
      }
      else{
          //there are not subcollecitons for teh system, actually we should not even have this method as an option anymore
      }
    },
    ReplicateSystemConfig(oncreate){
      //two paths here. One replicates the system record and all of its subcols and crap into the systemmaster of the client db
      //a second copies the current systemmaster into the configmaster of client end, as well as Ra end
      //but first, something preceding this, we push the basic system single values to the clientmaster
      console.log(this.SingleTabsLayout,this.SystemEntities,this.CurrentEntity,this.clientDB,this.Record,oncreate)
      // let Social_Landing_PageField = {
      //   id: 'Social_Landing_Page',
      //   Name: 'Social_Landing_Page',
      //   DisplayName: 'Social Landing Page',
      //   Type: 'Route Path',
      //   PathFilter: 'Internal Pages'
      // }
      let AllFields = this.CurrentEntity.AllFields
      let CompletedFields = AllFields.filter(field => {
        return typeof this.Record[field.Name] !== 'undefined'
      })
      let fieldlength = CompletedFields.length
      let updateobj = {}
      //console.log(CompletedFields)
      // AllFields.push(Social_Landing_PageField)
      CompletedFields.map((field,index) => {
        if(typeof this.Record[field.Name] !== 'undefined' && oncreate){
            updateobj[field.Name] = this.Record[field.Name]
            //console.log(updateobj,index,fieldlength)
            if(index-1+2 === fieldlength){
                console.log('is complete')
                console.log(updateobj,this.clientDB.collection('SystemConfig').doc(this.Record.id))
             this.clientDB.collection('SystemConfig').doc(this.Record.id).set(updateobj).then(updateddoc => {
                //console.log('updateddoc',updateddoc)
                this.ReplicateClientMaster()
                this.CopyAutoNumbers()
                this.CopyPDFTemplates()
                this.CopyPhotoLib()
                this.CopyAccountingLedger()
             })
            }
        } 
        else if(typeof this.Record[field.Name] !== 'undefined' && !oncreate){
          this.clientDB.collection('SystemConfig').doc(this.Record.id).update({
            [field.Name]: this.Record[field.Name]
          })
          if(index-1+2 === fieldlength){
            this.ReplicateClientMaster()
            this.CopyAutoNumbers()
            this.CopyPDFTemplates()
            this.CopyPhotoLib()
                this.CopyAccountingLedger()
          }
        }
               
      })
      //  this.clientDB.collection('SystemConfig').doc(this.Record.id).update({
      //       ClientAppURL: this.Record.ClientAppURL
      //     })
      
      //right, now we can actually dynamically push db, and path, along with "this"[item]
      this.ReplicateClientMaster()
      //okay so ReplicatetoConfigMasters() moved to run only when configmaster does NOt exist...
      //this.ReplicatetoConfigMasters()
      this.CopyAutoNumbers()
      this.CopyPDFTemplates()
      this.CopyPhotoLib()
                this.CopyAccountingLedger()
    },
    CopyPhotoLib(){
        let photolib = Object.assign({},this.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets'))
        photolib.CustomTags = []
        photolib.CustomTagsInteger = 1000000
        this.clientDB.collection('SystemConfig').doc(this.Record.id).collection('PhotoLibraries').doc(photolib.id).set(photolib).then(addedsysassetdoc => {

        })
    },
    CopyAccountingLedger(){
          let collection = ['General','Sales_Invoices','Sales_Quotes']
                collection.map(col => {
                    let docpath = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('Ledgers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc(this.Record.id).collection('Ledgers').doc(docid).set(docdata)
                    })
                })
        },
    CopyPDFTemplates(){
             let pdfcollection = ['Purchase_Orders','Sales_Invoices','Sales_Quotes']
                pdfcollection.map(col => {
                    let docpath = db.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('pdfexportfunctions').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                            //okay some point and time we will reduce this actually
                            this.clientDB.collection('pdfexportfunctions').doc(docid).set(docdata)
                        }
                    })
                })
        },
    CopyAutoNumbers(){
             let autonumberscollection = ['StoreOrderEntityDefault','StoreSupplierEntityDefault',
                 'BillingAccounts_Customer_ID','Business_Unit_Code','DispatchInstructions_Number','GoodsReceivingVouchers_GRV_Number','PackingLists_Number','Payment_Invoices_Invoice_Number',
                 'PickingSlips_Number','PurchaseRequisitions_Number','Purchase_Orders_PO_Number','Sales_Quotes_Quote_Number','Statement_Invoices_Invoice_Number','Subscription_Orders_Order_Number','Warehouse_Warehouse_Number']
                autonumberscollection.map(col => {
                    let docpath = db.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(col)
                    docpath.get().then(snapshot => {
                        let docdata = snapshot.data()
                        docdata.LastNumbers = []
                        docdata.Last_Number = 0
                        docdata.Last_Value = 0
                        let docid = col
                        this.clientDB.collection('SystemConfig').doc('ConfigMaster').collection('AutoNumbers').doc(docid).set(docdata)
                        if(!docdata.IsEntityDefault){
                          this.clientDB.collection('SystemConfig').doc(this.Record.id).collection('AutoNumbers').doc(docid).get().then(autonrsnap => {
                            let autodoc = autonrsnap.data()
                            if(!autodoc){
                              this.clientDB.collection('SystemConfig').doc(this.Record.id).collection('AutoNumbers').doc(docid).set(docdata)
                            }
                          })
                        }
                    })
                })
        },
    GetSubColSubCol(entityid,record,subcolname,subcolquery,headers){
      //console.log('GetSubColSubCol',entityid,record,subcolname,subcolquery,headers)
      let subcol = {
        SubColName: subcolname,
        SubColData: [],
        
      }
      if(entityid){
        subcol.PrimaryEntity = entityid
      }
      if(headers){
        subcol.Headers = headers
      }
      let vm = this									
       return new Promise(function(resolve, reject) {		
         subcolquery.onSnapshot(res=> {
           let length = res.docs.length
           if(length === 0){
             resolve(record)
           }
           else{
             if(!record.SubCollections){
               record.SubCollections = []
             }
            const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                subcol.SubColData.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
                if(subcol.SubColData.length === length){
                  record.SubCollections.push(subcol)
                  resolve(record)
                }
              }
            })
           }
           
         })
       })
    },
    }
}
</script>

<style>

</style>