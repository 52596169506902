
<template>
      <v-card  :dark="AppisDarkMode" flat>
        <!-- Option1 {{Option1}}
        Option2 {{Option2}}
        Option3 {{Option3}} -->
        {{Description}}
     <v-row
        class="fill-height ma-0"
        align="center"
        v-if="Option1"
      >
      <v-hover v-slot:default="{ hover }" v-for="group in filteredGroups.slice(0,Excerpt)"
            :key="group.id">
            <v-card
            :to="'/Group/' + group.id"
              :elevation="hover ? 12 : 2"
              class="grouphovercard mx-1"
              max-width="100px"
              max-height="100px"
            >
           <v-row class="fill-height mx-0" align="center" justify="center">
            <v-img v-if="!group.logo" contain class="mx-1"
            gradient="to top right, rgba(100,115,201,.33), rgba(240,240,240,.7), rgba(240,240,240,1)"           
            width="100px"
            height="100px"
            src="@/assets/logo.png">
            <v-row class="fill-height mx-0" align="center" justify="center">
              <v-card-title class="pop--text">                  
                    <span class="subheading text-left" style="font-size:16px">{{ group.name }}</span>
                </v-card-title>
            </v-row>
            </v-img>
            
            <v-img  v-if="group.logo" contain class="mx-1"
            gradient="to top right, rgba(100,115,201,.33), rgba(240,240,240,.7), rgba(240,240,240,1)"           
            width="100px"
            height="100px"
            :src="group.logo">
            <v-row class="fill-height mx-0" align="center" justify="center">
              <v-card-title class="pop--text">                  
                    <span class="subheading text-left" style="font-size:16px">{{ group.name }}</span>
                </v-card-title>
            </v-row>
            </v-img>
           </v-row>
            </v-card>
      </v-hover>
      </v-row>
      <v-row
        class="fill-height ma-0"
        align="center"
        v-if="Option2">
        <v-flex v-for="group in filteredGroups.slice(0,Excerpt)"
          :key="group.id">
      <v-card
             :style="{backgroundImage: GroupsGradient}"
            :to="'/Group/' + group.id"
            outlined            
            elevation="2"
            class="my-1 mx-1"
          >
           
          <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title
                  class="subheader white--text"
                  v-text="group.name"
                ></v-card-title>

                <v-card-subtitle class="white--text" v-text="group.Description"></v-card-subtitle>
              </div>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                <v-img :src="group.logo"></v-img>
              </v-avatar>
            </div>    
                
          </v-card>
        </v-flex>
      </v-row>
      <span
        v-if="Option3"
      >
      <v-card
          v-for="group in filteredGroups.slice(0,Excerpt)"
          :key="group.id"
            :to="'/Group/' + group.id"
            outlined            
            elevation="2"
            class="my-1 mx-1"
          >
         
          <v-list-item three-line>
              <v-list-item-content class="align-self-start">
                <v-container grid-list-xl>
                <v-layout row wrap>
                  
                    <v-flex xs12 s12 md3 lg2>
                  <v-layout column> 
                  <v-avatar tile><v-img :src="group.logo"></v-img></v-avatar></v-layout>

                </v-flex>
                           
                   <v-layout row>
                                 <h2> <v-list-item-title class="primary--text mx-3"
                  v-text="group.name" 
                ></v-list-item-title></h2>
                   </v-layout>
                 <v-layout column>
                   <v-flex xs12 s12 md10 lg8>
                  <h6 id="Grouptitle" class="mx-3"> {{group.GroupCategory}} <v-icon v-if="group.EndorsingNetwork" light color="white" medium>cloud_done</v-icon></h6></v-flex>
                    </v-layout>
                    <v-layout column>                 
                     <h5 class="mx-3">Group has a Privacy of {{group.GroupPrivacy}} <v-icon>{{group.GroupPrivacyIcon}}</v-icon></h5>
                    </v-layout> 
                                         <v-layout row wrap>
               </v-layout>            
                </v-layout><v-divider></v-divider><br>
                <v-layout row wrap>
                <v-list-item-action-text v-text="group.Description"></v-list-item-action-text></v-layout>
                </v-container>   
              </v-list-item-content>  
            </v-list-item>
       
           </v-card>
      </span>
      </v-card>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'

export default {
    props: ['clientDB','Description','Excerpt','Filter','View','UserView','UserID','UsersArray','AppisDarkMode'],
    middleware: 'auth',
    data () {
        return {
          Option1: false,
          Option2: false,
          Option3: false,
          
          tempid: 'new',
          //userid: this.$store.state.user.id,
        GroupCategoryTypes: [],          
            snackbar: false,
            helpsheet: false,
            helpnewsheet: false,
            groups: [],
            dialog: false,
            search: '',
            inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
            editedItem: {
                Name: null,
                GroupCategory: null,
                Description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                Description: null,
                url: null,
                logo: null,
                                
            },
            UserRecord: {}, 
            CollectionRef: db.collection('groups').where('GroupPublishStatus','==','Published').where('GroupPrivacy','==','All Site Members')          

        }
    },
    computed: {
      ActiveMemberGroup(){
        return this.$store.state.ActiveMemberGroup
      },
      System(){
        return this.$store.state.SystemObj
      },
      GroupsGradient(){
      return this.$vuetify.theme.dark ? 'linear-gradient(338deg, '+this.System.Light_Social_App_SearchBar.hex+'EE 0%, '+this.System.Dark_Social_App_SearchBar.hex+'DD  100%)': 'linear-gradient(338deg, '+this.System.Dark_Social_App_SearchBar.hex+'DD 0%, '+this.System.Light_Social_App_SearchBar.hex+'DD  100%)' 
      },
      userIsAdmin () {
        return this.$store.state.IsAdmin
      },
    userLoggedIn () {
        return this.$store.getters.user
        },
    filteredGroups() {
      
      return this.groups.filter(group => {
          //return this.FilterComputed.length > 0 ? this.FilterComputed.includes(group.GroupCategory.ID) : group
          return group
      });
    
    },
    FilterComputed(){
        return this.Filter.map(filter => {
            return filter.ID
        })
    }
    },

    created() {
      // this.$store.dispatch('GetUserStateChange')
      console.log(this.Filter)
      setTimeout(() => {
        this[this.View] = true
        console.log(this.View,this[this.View])
      }, 300);
      if(this.UserView){
        console.log('is user view',this.UserID)
        // this.CollectionRef = this.CollectionRef.where('groupmembers','array-contains',this.UserID)
        // this.getGroups()
      }
      else{
        
        // this.getGroups()
      }
   
    this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.GetRequestingUser()
},
    methods: {
      CallUserGroup(groupid){
        //alert('calling user group '+groupid)
            db.collection('groups').doc(groupid).onSnapshot(snapshot =>{
              let groupdata = snapshot.data()
              groupdata.id = groupid
              this.groups.push(groupdata)
              console.log('this.groups',this.groups)
            })
          },
        GetGroupMemberData(){
          let vm = this
          return new Promise(function(resolve, reject) {
            //membergroup.Status === 'Active' && membergroup.GroupPublishStatus !== 'Draft'
            db.collection('groupmembers').doc(vm.UserID).collection('groups').where('Status','==','Active').where('GroupPublishStatus','==','Published').onSnapshot(res => {
              let membergroups = []
              let listlength = res.docs.length
              const changes = res.docChanges();
              changes.forEach(change => {
                if (change.type === 'added') {              
                      membergroups.push({
                      ...change.doc.data(),
                      id: change.doc.id
                    })
                  }
                  if(membergroups.length === listlength){
                    resolve(membergroups)
                  }
              })
            })
          })
        },
       GetRequestingUser(){
        var vm = this;
        this.UserRecord = this.userLoggedIn
        if(this.UserRecord){
          this.GetGroupMemberData().then(membergroups => {
            if(membergroups){
              console.log('membergroups',membergroups,this.UserRecord.groups)
              membergroups.map(membergroup => {
                // if(membergroup.Status === 'Active' && membergroup.GroupPublishStatus !== 'Draft'){
                  //here we build in check for guest
                  if(this.userLoggedIn.IsGuestUser){
                    if(membergroup.GroupPrivacy === 'Public' || membergroup.GroupPrivacy === 'All Site Members'){
                      this.CallUserGroup(membergroup.id)
                    }
                    else{
                    let match = this.UserRecord.groups.find(obj => obj.Groupid === membergroup.id)
                    if(match){
                      this.CallUserGroup(membergroup.id)
                    }
                  }
                  }
                  else{
                    if(membergroup.GroupPrivacy === 'Public' || membergroup.GroupPrivacy === 'All Site Members' || membergroup.GroupPrivacy === 'Non Guest Site Members'){
                      this.CallUserGroup(membergroup.id)
                    }
                    else{
                    let match = this.UserRecord.groups.find(obj => obj.Groupid === membergroup.Groupid)
                      if(match){
                        this.CallUserGroup(membergroup.id)
                      }
                    }
                  }
                // }
                
              
              })
            }
          })
        }
        

      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            ToggleHelp() {
            this.helpsheet = true
          },
          ToggleNewHelp() {
         
            this.helpnewsheet = true
          },
         
    Opendialog() {
        this.dialog = true
        // $nuxt.$router.push('/new_group/'+this.tempid)
    },
    Closedialog() {
        this.dialog = false
        this.editedItem = this.defaultItem
        this.selectedFile = null
    },
             onFileselected(event) {
        this.selectedFile = event.target.files[0]
      },
      save() {
           if(this.$refs.form.validate()) {
                    // var storageRef = firebase.storage()('Group_Logos/' + this.selectedFile.name);
                    // var uploadTask = storageRef.put(this.selectedFile);
                    //   uploadTask
                    //   .then(snapshot => snapshot.ref.getDownloadURL())
                    //     .then((url) => {     
                    this.name = this.editedItem.Name
                    this.Description = this.editedItem.Description
                    this.user = this.$store.state.user.id
                    this.GroupCategory = this.editedItem.GroupCategory
                    // this.FileURL = url

                        
                        const group = { 
                              name: this.name,
                                Description: this.Description,
                                Created_By: {Full_Name: this.UserRecord.Full_Name, id: this.UserRecord.id},
                                Created_Byid: this.UserRecord.id,
                                Created_On: new Date(),
                                GroupCategory: this.GroupCategory,
                                GroupPrivacy: 'Public',
                                GroupPrivacyIcon: 'mdi-lock',
                                GroupPublishStatus: 'Draft',
                                GroupPublishStatusIcon: 'mdi-eye-off',
                                EventsPrivacy: 'Public',
                                BlogPrivacy: 'Public',
                                MembersPrivacy: 'Public',
                                NewsPrivacy: 'Public',
                                ForumPrivacy: 'Public',
                                // logo: this.FileURL
                                
                            }
                            const groupmemberscollection =   db.collection('groupmembers')
                            let vm = this
                        db.collection('groups').add(group).then(function(doc) {
                          console.log(doc.id)
                           const GroupMember = { 
                            Created_On: new Date(),
                            Userid: group.Created_By.id,
                            Groupid: doc.id,
                            UserRole: 'Administrator'
                            
                            }
                            groupmemberscollection.add(GroupMember)
          vm.$router.push('/Group/'+GroupMember.Groupid)
                            })
                    this.Closedialog()
                    
                          // }),                         
                        
                   
                            this.dialog = false
                            this.snackbar = true
                        }
      }
      },


}
</script>

<style>
/* .profilediv {
  align-content: center
}
.groupDescription {
  font-size: 12px;
  min-height: 100px
}
.GroupTitle {
    font-size: 20px;
    font-weight: bold;
    align-content: left;
    margin: 0.5em
}
.GroupCard {
  max-height: 370px
} */
.stickytopconscious{
  top: 100px
}
.grouphovercard{  
  opacity: 0.6;
}
.grouphovercard:hover {
  opacity: 1;
  transition: opacity .4s ease-in-out;
}
</style>
    
    