<template>
  <v-card flat tile class="background">
    
      <v-card-subtitle class="background darken-1 mediumoverline">
              Image
          </v-card-subtitle>
          <v-card-text style="background: linear-gradient(180deg, rgba(217,232,240,1) 0%, rgba(217,232,240,0.3) 100%);padding:0px;">
          <v-img v-if="EditedTab.IMG" contain :src="EditedTab.IMG" height="200">
            <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog()" :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
          </v-img>
      <v-img v-if="!EditedTab.IMG" contain :src="$vuetify.theme.dark ? require('@/assets/ImageHolder_dark.png') : require('@/assets/ImageHolder.png')" height="200">
      <v-row style="left: 12px;position: absolute; bottom: 40px;width: 100%;height:50px;padding-top:10px;background: linear-gradient(180deg, rgba(222,221,221,0.5) 0%, rgba(222,221,221,0.5) 100%);">
            <v-chip @click="ActivateGalleryDialog()"   :class="$vuetify.theme.dark ? 'mx-3' : 'white mx-3'">Change</v-chip>
            </v-row>
          </v-img>
          </v-card-text>
     <!-- <v-card-subtitle class="my-3 background darken-1 mediumoverline">
              Image Styling
          </v-card-subtitle> -->
          <v-list-item>
             <v-switch v-if="EditedTab.Name !== 'Title'"
          label="Comparing Images" @change="CheckIMGRotation(EditedTab)"
          v-model="EditedTab.ComparingImages"
        />
          </v-list-item>
          <v-list-item v-if="!EditedTab.ComparingImages">
             <v-switch v-if="EditedTab.Name !== 'Title'"
          label="Rotating Images" @change="CheckIMGRotation(EditedTab)"
          v-model="EditedTab.IMGRotation"
        />
          </v-list-item>
          <v-list-item v-if="EditedTab.IMGRotation && !EditedTab.ComparingImages">
         <v-text-field label="Cycle Time (ms)"
                  v-model.number="EditedTab.CycleTime"
                  class="mt-0 pt-0"
                  type="number"
                />  
          </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab)">
         <v-text-field
                  v-model.number="EditedTab.Height"
                  class="mt-0 pt-0"
                  type="number"
                >  
                <template v-slot:append>
                    <v-btn small
                    :disabled="EditedTab.Height === '10%'"
                    @click="Twopropemit('ImageHeight', EditedTab, 'down')"
                    >50
                    <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                    <v-btn small
                    :disabled="EditedTab.Height === '100%'"
                    @click="Twopropemit('ImageHeight', EditedTab, 'up')"
                    >50
                    <v-icon> mdi-chevron-up </v-icon>
                    </v-btn>
                    </template>              
                </v-text-field>
      </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab)">
        <v-text-field type="number" label="Width" v-model="EditedTab.Width" />
      </v-list-item>
      <v-list-item dense v-if="EditedTab.Name === 'Title'">
                <v-switch label="Contain IMG" v-model="EditedTab.ContainIMG" />
            </v-list-item>
      <v-list-item dense v-if="TabImageHasSizing(EditedTab) || EditedTab.Name === 'Title'">
              <v-switch
          v-if="EditedTab.Name === 'Title'"
          label="Tile"
          v-model="EditedTab.Tile"
        />
        <v-switch v-if="EditedTab.Name !== 'Title'"
          label="Has Gradient"
          v-model="EditedTab.HasParallaxGradient"
        />
        
        
        <v-text-field
          label="Gradient"
          v-model="EditedTab.ParallaxGradient"
          v-if="EditedTab.HasParallaxGradient"
        />
        <a v-if="EditedTab.HasParallaxGradient" href="https://cssgradient.io/" target="_blank">Get Code</a>
          <!-- </v-col> -->
      </v-list-item>
      <!-- <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                          <v-switch label="Clipped" v-model="EditedTab.Clipped" />									
                      </v-list-item>									 -->
                      <v-list-item v-if="EditedTab.Name === 'Parallax' || EditedTab.Name === 'Image'">									
                          <v-switch @change="UpdateRowTransparency(EditedTab)" label="Transparent" v-model="EditedTab.Transparent" />									
                      </v-list-item>	
      <v-list-item
        dense
        v-if="
          EditedTab.Name === 'Image' ||
          (EditedTab.HasImage && !EditedTab.DataViewName)
        "
      >
        <v-select clearable v-model="EditedTab.IMGBlendMode" label="Blend Mode" :items="$store.state.CSSBlendModes"/>
      </v-list-item>
  </v-card>
</template>






<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
import AssetsDirectory from '@/components/WebPages/ConfigComponents/AssetsDirectory';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets','GroupData',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer,AssetsDirectory},
    data() {
        return {
          
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{
      SelectedImage(){
        return this.$store.state.SelectedGalleryAsset
      },
      IMGProp(){
        return this.$store.state.IMGProp
      },
      ImageCols(){
        if(this.$vuetify.breakpoint.xs){
          return 12
        }
        else if(this.$vuetify.breakpoint.sm){
          return 6
        }
        else if(this.$vuetify.breakpoint.md){
          return 4
        }
        else if(this.$vuetify.breakpoint.lg){
          return 3
        }
        else if(this.$vuetify.breakpoint.xl){
          return 2
        }
      }, 
      SocialItemInteractMethod(){
      return this.$store.state.SocialItemInteractMethod
      },
      SocialItemInteractMethodProp(){
        return this.$store.state.SocialItemInteractMethodProp
      },
    },
    watch: {
      SocialItemInteractMethod(v){   
        //console.log(v)
        let acceptedmethods = ['AssignImageandCloseTab']   
        //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            //console.log('yes with prop',this.SocialItemInteractMethod,this.SocialItemInteractMethodProp)
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      //console.log(this.SocialItemInteractMethod)
    },
    methods:{
      CheckIMGRotation(tab){
        if(!tab.Assets){
          tab.Assets = []
        }
        if(typeof tab.CycleTime === 'undefined'){
          tab.CycleTime = 3000
        }
        if(tab.ComparingImages){
          tab.IMGRotation = true
        }
      },
      UpdateRowTransparency(tab){
        if(tab.Transparent){
          tab.RowTransparency = 'transparent'
        }
        else{
          tab.RowTransparency = ''
        }
      },
      ActivateGalleryDialog(){
        this.$store.commit('setGalleryLibraries',this.GalleryLibraries)
        this.$store.commit('setGalleryAssets',this.SystemAssets)
        this.$store.commit('setEditedTab',this.EditedTab)
        this.$store.commit('SetSocialItemInteractMethod','ToggleGalleryDialog')
        this.$store.commit('setIMGProp','IMG')
        
        //GalleryLibraries
        //methods ? SelectGalleryAssets
      },
        AssignGalleryAssets(EditedTab){
          this.Twopropemit('AssignGalleryAssets',EditedTab,this.SelectedImage)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single" &&
              EditedTab.Name !== "Title")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImageandCloseTab(){
          console.log('AssignImageandCloseTab',this.SelectedImage)
          if(this.IMGProp === 'LibraryAsset'){
            //console.log('as LibraryAsset')
            this.AssignGalleryAssets(this.EditedTab,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
          else{
            //console.log('as else')
              this.AssignImage(this.EditedTab,this.IMGProp,this.SelectedImage)
              this.NoPropsemit('DeactivateTabDialog')
          }
         
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>
</style>
