<template>
  <div>
    <v-dialog v-model="ViewFileDialog" fullscreen>
        <v-card tile class="white" flat>
          <v-btn @click="CloseViewFileDialog()" style="z-index:2;">Close</v-btn>
          <embed v-if="ViewFileURL" :height="ImageHeight-25" :width="ImageWidth" :src="ViewFileURL"/>
          <v-layout v-if="ViewIMGURL" row class="justify-center">
          <v-img :src="ViewIMGURL" contain :height="ImageHeight-25" :width="ImageWidth">
          </v-img>
          </v-layout>
          </v-card>
      </v-dialog>
    <v-card-title class="mediumoverline" style="padding-top:30px;">
                        Process Loan Request
                        <v-spacer>
                        </v-spacer>
                        <v-btn v-if="ActiveEntity" @click="ActiveEntity = ''">All
                        </v-btn>
    </v-card-title>
                     <v-card  class="siteconfigcard"  width="100%">
                        <v-card-text v-if="!ActiveEntity">
                            <v-list-item @click="SelectActiveEntity(ent)" v-for="ent in LoanOrderEntities" :key="ent.itemObjKey">
                                <v-list-item-avatar>
                                    <v-icon>
                                    {{ent.Table_Icon}}
                                    </v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                {{ent.DisplayName}}
                                </v-list-item-content>
                            </v-list-item>
                        </v-card-text>
                        <v-card-title class="mediumoverline" v-if="ActiveEntity && Disabled && TrackedOrder">
                                You have an Open Loan Request
                            </v-card-title>
                         <v-card-text v-if="ActiveEntity && Disabled && TrackedOrder">
                            
                            Your open Loan Request <v-chip outlined small color="warning">{{TrackedOrder.id}}</v-chip> is in Status <v-chip dark color="blue" small>{{TrackedOrder.Status_Reason.Name}}</v-chip> and needs to be Completed first before you can request another loan.
                         </v-card-text>
                        <v-card-text v-if="ActiveEntity && !Disabled">
                            <v-card
                            class="mx-auto"
                            max-width="500"
                        >
                            <v-card-title class="text-h6 font-weight-regular justify-space-between">
                            <span>{{ currentTitle }}</span>
                            <v-avatar
                                color="primary lighten-2"
                                class="subheading white--text"
                                size="24"
                                v-text="step"
                            ></v-avatar>
                            </v-card-title>

                            <v-window v-model="step">
                            <v-window-item :value="1">
                                <v-card-text>
                                <v-text-field :rules="creditMaxRules(Loan_Amount)" label="Loan Amount" v-model.number="Loan_Amount"
                                     />
                                <span class="text-caption grey--text text--darken-1">
                                    The amount you want to loan
                                </span>
                                </v-card-text>
                            </v-window-item>

                            <v-window-item :value="2">
                                <v-card-text>
                               <v-card
                                    flat
                                    tile
                                >
                                    <v-window
                                    v-model="packageindex"
                                    vertical
                                    >
                                    <v-window-item
                                        v-for="pack in LoanPackages" :key="pack.itemObjKey"
                                    >
                                        <v-card
                                        color="grey lighten-4"
                                        height="200"
                                        >
                                        <v-card-title class="justify-center mediumoverline"> {{pack.Name}}
                                        </v-card-title>
                                        <v-card-text>
                                          <span v-html="pack.Description">
                                            </span>
                                        </v-card-text>
                                        <!-- <v-row
                                            class="fill-height"
                                            align="center"
                                            justify="center"
                                        >
                                            <h2
                                            class="white--text"
                                            >
                                            {{pack.Name}}
                                            </h2>
                                            <span v-html="pack.Description">
                                            </span>
                                        </v-row> -->
                                        </v-card>
                                    </v-window-item>
                                    </v-window>

                                    <v-card-actions class="justify-space-between">
                                    <v-btn
                                        text
                                        @click="prev"
                                    >
                                        <v-icon>mdi-chevron-left</v-icon>
                                    </v-btn>
                                    <v-item-group
                                        v-model="packageindex"
                                        class="text-center"
                                        mandatory
                                    >
                                        <v-item
                                        v-for="n in PackagesLength"
                                        :key="`btn-${n}`"
                                        v-slot="{ active, toggle }"
                                        >
                                        <v-btn
                                            :input-value="active"
                                            icon
                                            @click="toggle"
                                        >
                                            <v-icon>mdi-record</v-icon>
                                        </v-btn>
                                        </v-item>
                                    </v-item-group>
                                    <v-btn
                                        text
                                        @click="next"
                                    >
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-btn>
                                    </v-card-actions>
                                </v-card>
                                </v-card-text>
                            </v-window-item>

                            <v-window-item :value="3">
                                 <v-card-subtitle class="mediumoverline">
                                    Over a period of {{SelectedPackage.Period}} months.
                                        </v-card-subtitle>
                                <v-card-text class="text-center">
                                    <!--  grey lighten-4 -->
                                    <v-list class="transparent">
                                       
                                    <v-list-item class="detailslistoutline" v-for="inst in SelectedPackageInstallments" :key="inst.itemObjKey">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-calendar</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-content class="caption" style="text-align: start;">
                                            {{TimestampFormatterSTRINGDayText(inst.Due_Date)}}
                                        </v-list-item-content>
                                        <v-list-item-action style="text-align: end;">
                                            {{CurrencyFormatter(inst.Amount,$store.state.DefaultCurrency.Currency)}}
                                        </v-list-item-action>
                                    </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-card-title class="subtleoverline justify-end">
                                    Total Repayment - {{ComputedTotalCurrency}}
                                </v-card-title>
                                <v-card-subtitle v-if="SelectedPackage.TermsDocument">
                                    <v-list-item class="background text-caption grey--text text--darken-1">
                                       <!-- <span class="text-caption grey--text text--darken-1"> -->
                                    <v-checkbox
                                        v-model="TermsAccepted" @change="RegisterAcceptedTerms(SelectedPackage.TermsDocument)"
                                        :rules="[v => !!v || 'You must agree to continue!']"
                                        required
                                        ></v-checkbox>I accept the <a @click="GetShortLivedURL(SelectedPackage.TermsDocument)"> terms and conditions</a>
                                    <!-- </span> -->
                                    </v-list-item>
                                </v-card-subtitle>
                              
                                <!-- <div class="pa-4 text-center">
                                <v-img
                                    class="mb-4"
                                    contain
                                    height="128"
                                    src="https://cdn.vuetifyjs.com/images/logos/v.svg"
                                ></v-img>
                                <h3 class="text-h6 font-weight-light mb-2">
                                    Welcome to Vuetify
                                </h3>
                                <span class="text-caption grey--text">Thanks for signing up!</span>
                                </div> -->
                            </v-window-item>
                             <v-window-item :value="4">
                                <div class="pa-4 text-center">
                                <v-img
                                    class="mb-4"
                                    contain
                                    height="128"
                                    src="@/assets/logo.png"
                                ></v-img>
                                <h3 class="text-h6 font-weight-light mb-2">
                                    You Loan Request has been processed
                                </h3>
                                <span class="text-caption grey--text">Thank you!</span>
                                </div>
                            </v-window-item>
                            </v-window>

                            <v-divider></v-divider>

                            <v-card-actions>
                            <v-btn
                                :disabled="step === 1"
                                text
                                @click="step--"
                            >
                                Back
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                :disabled="LoanProgressDisabled"
                                color="primary"
                                depressed
                                @click="ProgressLoanRequest()"
                            >
                                Next
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                            <!-- Amount must be by credit limit -->
                            
                        </v-card-text>
                     </v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities','ActiveAccount','ClientStoreOrders'],
    components: {},
    data() {
        return {
            TermsAccepted: false,
            AcceptedTerms: '',
            ViewFileDialog: false,
          ViewFileURL: '',
          ViewIMGURL: '',
            FirstDue_Date: '',
            SelectedPackage: '',
            packageindex: 1,
            step: 1,
            ActiveEntity: '',
            Loan_Amount: 0,
            LoanPackages: [
                {Name: 'Month End',Description: 'Lorem ipsum borther mother and son',Interest: 0.69,Period: 1},
                {Name: '2 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.79,Period: 2},
                {Name: '3 Months',Description: 'Lorem ipsum borther mother and son',Interest: 0.93,Period: 3}
            ]
        }
    },	
    computed:{
        LoanProgressDisabled(){
            return this.step === 4 || this.Disabled
        },
        ExceedsLimit(){
            return this.ActiveAccount ? this.Loan_Amount > this.ActiveAccount.Credit_Limit : ''
        },
        Disabled(){
            //console.log('this.TrackingOrders',this.TrackingOrders,this.TrackedOrder)
            return this.TrackedOrder && this.TrackedOrder.id || this.ExceedsLimit
        },
        TrackedOrder(){
        return this.AccountOrders && this.AccountOrders[0] ? this.AccountOrders[0] : ''
      }, 
      AccountOrders(){
        return this.TrackingOrders.filter(order => {
            return this.ActiveAccount && order.BillingAccountid === this.ActiveAccount.id
        })
      },
      TrackingOrders(){
        return this.$store.state.TrackingOrders
      },
        IsTrackedEntity(){
            return this.ActiveEntity && this.TrackedEntity && this.ActiveEntity.id === this.TrackedEntity
        },
        TrackedEntity(){
        return this.userLoggedIn.TrackedEntity ? this.SystemEntities.find(obj => obj.id === this.userLoggedIn.TrackedEntity) : ''
      },
        EntityDefaultStatusField(){
            return this.ActiveEntity ? this.ActiveEntity.AllFields.find(obj => obj.id === 'Status') : ''
        },
        TermsLabel(){
            return `Do you accept the <a href="`+this.SelectedPackage.TermsDocument.fileurl+`>terms and conditions?</a>`
        },
        PackagesLength(){
            return this.LoanPackages.length
        },
        ComputedTotalCurrency(){
            return this.CurrencyFormatter(this.ComputedTotal,this.$store.state.DefaultCurrency.Currency)
        },
        SelectedPackageInstallments(){
            let array = []
            if(this.SelectedPackage){
                for (var x = 0; x<this.SelectedPackage.Period;x++){
                    var today = new Date();
                    let linedue =  new Date(this.EndofMonth(new Date(today.getFullYear(), today.getMonth()+1+x, 0)))
                    let amount = this.ComputedTotal/this.SelectedPackage.Period            
                    let interest = (this.Loan_Amount/this.SelectedPackage.Period)*this.SelectedPackage.Interest
                    array.push(
                        {Amount: amount, Due_Date: linedue, LineNr: x-1+2,Interest_Amount: interest}
                    )
                }
            }
            return array
        },
        ComputedTotal(){
            return this.SelectedPackage ? this.Loan_Amount+(this.Loan_Amount*this.SelectedPackage.Interest) : 0
        },
        ComputedPackages(){
            return this.LoanPackages
        },
        currentTitle () {
        switch (this.step) {
          case 1: return 'Loan Amount'
          case 2: return 'Select Package'
           case 3: return 'Review'
          default: return 'Processed'
        }
      },
        CurrentUserProps(){
          let user = this.userLoggedIn
          let userobj = {
            Full_Name: user.Full_Name,
            Name: user.Name,
            Surname: user.Surname,
            id: user.id,
            Email: user.Email
          }
          return userobj
        },
        LoanOrderEntities(){
            return this.ActiveLoanOrderEntities.filter(ent => {
                return this.userLoggedIn.LoanEntities.includes(ent.id) 
            })
        },
        ActiveMemberGroup(){
            return this.$store.state.ActiveMemberGroup
        },
        ActiveLoanOrderEntities(){
        return this.SystemEntities.filter(ent => {
          return ent.Entity_Type.Name === 'Cash Loan'
        })
      },
        OrderedItemsbyArticle(){
            //ProvidedCurrentEntity
            return this.OrderLineItems.map(item => {
                let itemobj = item.LookupObj
                itemobj.SalesOrderid = item.SalesOrderid
                itemobj.Qty = item.Qty
                itemobj.Created_On = item.Created_On      
                return itemobj          
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
        let d = new Date()
        this.FirstDue_Date = this.EndofMonth(d)
    },
    methods:{
        RegisterAcceptedTerms(doc){
            this.AcceptedTerms = doc
        },
        GetShortLivedURL(item){
      //console.log(item)
       const functions = firebase.functions();
        const GetSignedInURL = functions.httpsCallable('GetSignedInURL');
        GetSignedInURL(item).then(result => {
          //console.log(result)
          if(item.FileType.includes('image')){
            this.ViewIMGURL = result.data[0]
            //console.log(window.innerHeight)
            this.ViewFileDialog = true
          }
          else{
            this.ViewFileURL = result.data[0]
            var ua = navigator.userAgent.toLowerCase();
            //console.log(ua)
            var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
            if(isAndroid) {
              window.open(this.ViewFileURL)
            }
            else{
              //console.log(window.innerHeight)
              this.ViewFileDialog = true
            }
          }
        })
    },
    CloseViewFileDialog(){
      this.ViewFileDialog = false
      this.ViewFileURL = ''
      this.ViewIMGURL = ''
    },
        TimestampFormatterSTRINGDayText(d){
       
        var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        var days = [{Longtext: "Sunday",Shorttext: 'Sun'},{Longtext: "Monday",Shorttext: 'Mon'},{Longtext: "Tuesday",Shorttext: 'Tue'},{Longtext: "Wednesday",Shorttext: 'Wed'}, {Longtext: "Thursday",Shorttext: 'Thu'}, {Longtext: "Friday",Shorttext: 'Fri'},{Longtext: "Saturday",Shorttext: 'Sat'}]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }

        var daytext = days[d.getDay()].Longtext
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = daytext+' '+date+', '+month+', '+year

        return finaldate
      },
        EndofMonth(date){
            console.log(date)
            var month = date.getMonth()
            var year = date.getFullYear()
            var d = new Date(year, month + 1, 0)
            d.setHours(23,59,59,999)
            return d
            },
        ProgressLoanRequest(){
        if(!this.Disabled){
            if(this.step === 2){
                this.SelectedPackage = this.LoanPackages[this.packageindex]
            }
            if(this.step === 3){
                this.SubmitForm()
            }
            else{
            this.step++
            }    
        }
            
        },
        next () {
            if(!this.Disabled){
        this.packageindex = this.packageindex + 1 === this.PackagesLength
          ? 0
          : this.packageindex + 1
            }
      },
      prev () {
        if(!this.Disabled){
        this.packageindex = this.packageindex - 1 < 0
          ? this.PackagesLength - 1
          : this.packageindex - 1
        }
      },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        creditMaxRules(v) {
            let belowmin = this.CurrencyFormatter(0,this.$store.state.DefaultCurrency.Currency)
            let max = this.CurrencyFormatter(this.ActiveAccount.Credit_Limit,this.$store.state.DefaultCurrency.Currency)
            return [ 
                v => !!v || "This field is required",
                v => ( v && v >= 0 ) || "Loan should be above "+belowmin,
                v => ( v && v <= this.ActiveAccount.Credit_Limit ) || "Your Credit Limit is "+max,
            ]
        },
        SelectActiveEntity(ent){
            this.ActiveEntity = ent
            if(ent.LoanPackages){
                this.LoanPackages = ent.LoanPackages
            }
            //console.log(this.EntityDefaultStatusField)
        },
        SubmitForm(tab){
            if(!this.Disabled){
            let vm = this
                let NewRecord = {
                    AutoConfigDocid: 'Staff_Loans_Order_Number',
                    AutoConfigProp: 'Order_Number'
                }
                NewRecord.BillingAccount = {Client_Reference: this.ActiveAccount.Client_Reference,id: this.ActiveAccount.id}
                NewRecord.BillingAccountid = this.ActiveAccount.id
                if(this.userLoggedIn.Company){
                    NewRecord.Company = this.userLoggedIn.Company
                    NewRecord.Companyid = this.userLoggedIn.Companyid
                }
                NewRecord.Grand_Total = this.ComputedTotal
                NewRecord.Created_By = this.CurrentUserProps	
                NewRecord.Created_Byid = this.CurrentUserProps.id
                NewRecord.Modified_By = this.CurrentUserProps	
                NewRecord.Modified_Byid = this.CurrentUserProps.id
                NewRecord.User = this.CurrentUserProps	
                NewRecord.Userid = this.CurrentUserProps.id
                NewRecord.Status = this.EntityDefaultStatusField.DefaultOption
                NewRecord.Status_Reason = this.EntityDefaultStatusField.DefaultLevel2Option
                NewRecord.Loan_Amount = this.Loan_Amount
                NewRecord.Package = {...this.SelectedPackage,Line_Items: this.SelectedPackageInstallments}
                if(this.AcceptedTerms){
                    NewRecord.Terms_Document = this.AcceptedTerms
                }
                if(this.TermsAccepted){
                    NewRecord.TermsAccepted = this.TermsAccepted
                }
                //BU????
                const functions = firebase.functions();
                  const SubmitFormData = functions.httpsCallable('SubmitFormData'); 
                  if(this.DefaultOwner){                    
                    NewRecord.Business_Unit = tab.DefaultOwner.Business_Unit
                    NewRecord.Business_Unitid = tab.DefaultOwner.Business_Unitid
                    //because, if the record is BU based or whatever, we have that covered
                    NewRecord.Owner = tab.DefaultOwner
                    NewRecord.Ownerid = tab.DefaultOwner.id
                    NewRecord.NewNotification = {
                    itemidprop: 'recordid',
                    Type: 'Web Form Submission',
                    Header: 'Form Submission for '+tab.FormName,
                    Content: 'New Submission for a '+tab.SingleName,
                    Owner: NewRecord.Owner,
                    Ownerid: NewRecord.Ownerid,
                    Read: false,
                    Path: '/'+tab.SingleName.split(' ').join(''),
                    Message: 'New Submission for a '+tab.SingleName,
                    }
                    if(this.userLoggedIn){
                        NewRecord.NewNotification.CreatorFullName = this.userLoggedIn.Full_Name
                        NewRecord.NewNotification.CreatorID = this.userLoggedIn.id
                        NewRecord.NewNotification.Content = NewRecord.NewNotification.Content+' by '+NewRecord.NewNotification.CreatorFullName
                        NewRecord.NewNotification.Message = NewRecord.NewNotification.Message+' by '+NewRecord.NewNotification.CreatorFullName
                    }
                  }                 
                  let obj = {
                    collection: this.ActiveEntity.id.split('_').join(''),
                    data: NewRecord
                  } 
                  //console.log(obj)
                    vm.$store.commit('setCustomProcessingDialog',true)
                    vm.$store.commit('setCustomProcessingDialogText','Submitting Loan Request')
                  SubmitFormData(obj).then(result => {
                        console.log(result)
                        vm.step++
                        vm.$store.commit('setCustomProcessingDialog',false)
                  })
            }
        },
    }
}
</script>

<style>

</style>



