
<template>
<v-main class="grey lighten-3" style="margin-top:80px;margin-bottom:-150px">
  <!-- above was style="margin-top:-60px;margin-bottom:-150px -->
    <!-- <v-card class="grey lighten-4 stickytopconscious" width="100%" height="100%" flat> -->
    <!-- <v-card class="transparent"> -->
      <!-- <div  class="my-10"> -->
        <!-- <v-card class="transparent" width="100%" flat tile
        style="margin-bottom:-150px;z-index:0">
          <v-img src="@/assets/RASolidA-Light.jpeg"  
        height="550">
            <v-img
        height="350"
        src="@/assets/RAInvert-BG.png"
            contain
        >
              
             
            </v-img>  
          </v-img>
        </v-card> -->
       
             <v-card class="transparent my-2" width="100%"  flat >
            <v-layout row wrap class="justify-center mx-2">
                <v-flex lg5 md6 sm11 xs12 class="my-2 mx-2" v-for="bannerad in BannerAds" :key="bannerad.itemObjKey">
                    <!-- <v-hover v-slot="{ hover }"> -->
                  <v-card  max-height="400" style="padding-right:20px;padding-left:20px" 
                  elevation="4" class="oflow"
                  
                  >
                    <v-card-title class="display-2 links--text justify-center"
                    > {{bannerad.BannerName}}
                     
                    </v-card-title>
                    <v-card-subtitle 
                    > {{bannerad.BannerType.Name}}
                     
                    </v-card-subtitle>
                    <v-card-text>
                        <v-card v-if="bannerad.BannerType.ID === 1000003">
                           <v-img
                            :src="bannerad.BannerImage"
                            height="100"
                          >
                        <v-sheet
                          color="rgba(24,24,24,0.9)"
                          class="mx-10 my-4"
                          height="60%"
                          width="60%"
                          tile
                        >
                        
                  
                                   
                          <v-row
                            class="fill-height"
                            align="center"
                            justify="center"
                          >

                            <v-card class="transparent" flat width="100%" min-height="270">
                              <v-card-title class="white--text overline mx-3">
                                {{bannerad.BannerHeader}}                                
                                </v-card-title>
                              
                            
                            </v-card>
                          </v-row>
                        
                        </v-sheet>
                           </v-img>
                        </v-card>
                  </v-card-text>
                <v-card-actions>
                        <v-spacer></v-spacer>
                        <!-- v-if="!bannerad.RouteQuery" -->
                        <v-btn  :to="'/BannerAd/Edit/'+bannerad.id">View</v-btn>
                    </v-card-actions>
                </v-card>
                    <!-- </v-hover> -->
                </v-flex>
                
                </v-layout>            
            </v-card>
         
            <!-- <v-card class="grey lighten-4" width="100%" flat> -->
           
            <!-- </div>-->
      <!-- <div  class="my-10"> -->
         
            
        <!-- </v-card>  -->
          </v-main>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'


export default {
    props: ['AppisDarkMode'],
    components: {
        },
    data() {
    return {      
        BannerAds: [],
      UserRecord: '',
      rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
        BannerAdView: false,
        LandingPageView: false,
        NewBannerAdID: '',
        NewLandingePageID: '',

        }
    }, 

    computed:{
      
      userLoggedIn () {
      return this.$store.getters.user
    },
      
      
      
      

    },
    created(){
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      setTimeout(() => {
        }, 1000);
      this.FocusedViewToggle()
      this.IntranetViewToggle()
      this.CheckUser()

    },
    
    methods: {
        PrepareNewBannerAd(){
            let ref = db.collection('bannerads').doc()
        let newdocid = ref.id
        this.$router.push({ name: 'newbannerad', params: {id: newdocid }})
        
        },
        PrepareNewLandingPage(){
            let ref = db.collection('bannerads').doc()
        let newdocid = ref.id
        this.$router.push({ name: 'newlandingpage', params: {id: newdocid }})
        
        },
      AssignViewbyOptions(obj){
        console.log(obj)
        this[obj.prop] = obj.options
        if(obj.DefaultView){
          this[obj.defaultprop] = obj.DefaultView
        }
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',false)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
      async CheckUser(){
            let vm = this
            await firebase.auth().onAuthStateChanged(function(user) {
                if (user) {
                  db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              console.log(vm.UserRecord)
              vm.GetLandingPages()
                  })
                }
                else{
                }
            })
        },
        GetLandingPages(){
            db.collection('bannerads').onSnapshot(res => {
                        const changes = res.docChanges();
                        changes.forEach(change => {
                          if (change.type === 'added') {
                            this.BannerAds.push({
                              ...change.doc.data(),
                              id: change.doc.id
                            })
                          }
                        })
                      })
        },
          DateFormatter(date){
            if(date){
            let yearstring = date.split('-')[0]
              let monthstring = date.split('-')[1]
              let daystring = date.split('-')[2]
              let yearnumber = Number(yearstring)
              let monthnumber = Number(monthstring)
              let daynumber = Number(daystring)
              return new Date(yearnumber, monthnumber-1, daynumber)
              }
            else{
              return null
            }
          },

    }    
}
</script>

<style>
.imgcontentgrad {
  background-image: linear-gradient(to right, rgba(255,0,0,0.5), rgb(238,255,0, 1));
}
.transoutline{
    border: 1px solid black;
    background-color:pink
    /* color: rgba(255,255,255,0.05) */
}
.v-card--reveal {
    /* background-color: rgba(176, 7, 255, 0.5); */
  background-color: rgba(255, 193, 7, 0.75);
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index:10
}

.iconoutline{
  text-shadow: 0 0 3px #FF0000;
}

#my-lax .v-img__content {
  background: linear-gradient(135deg,  rgba(231,60,126,0.8), rgb(35,166,213, 0.5));
}
.clip-svg{
  mask-size: contain;
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/DefaultSystemAssets%2FExports%2FRAInvert?alt=media&token=54d7ec67-2109-4eda-93c0-7bf98f55dbf8);
  mask-repeat: no-repeat;
  mask-position: center;
}
.clipped{
      mask-size: contain;
  mask-image: url(https://firebasestorage.googleapis.com/v0/b/templatizer-9620d.appspot.com/o/Assets%2FQSHCimvRtK2k5IgF1edf%2FELBInvert_Window?alt=media&token=6d585c59-3127-4665-9a02-aa113f654ac1);
  mask-repeat: no-repeat;
  mask-position: center; 
}
.oflow{
    overflow:  hidden 
}
</style>

        

    