<template>
  <div>
    <v-dialog v-model="RecordLiaisonDialog" width="400" v-if="dragging">
          <!-- <v-expand-transition> -->
            <v-card tile
               width="400"
                class="transition-fast-in-fast-out"
                style="height: 100%;bottom: 0;position: absolute;right: 0;"
            >
            <v-list-item>
              <v-list-item-action>
                <v-icon class="soloactionicon" color="red" dark @click="CancelReadingTaskDialog()">mdi-close</v-icon>
              </v-list-item-action>
              <v-list-item-content>
              <v-list-item-title>
                <b >{{dragging[CurrentEntity.Primary_Field_Name]}} - {{dragging[Label.Name].Name}}</b>
              </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :class="StatusColor(dragging[Label.Name])">
              <v-list-item-title style="padding:5px;">
              <v-menu offset-y :close-on-content-click="false" top left min-width="400px" max-height="700px">
                    <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" >Change {{Label.DisplayName}}</v-btn>
                    </template>
                    <v-card outlined>
                       <v-card-text>
                        <v-select :items="Label.Options" return-object item-text="Name" v-model="dragging[Label.Name]" @change="UpdateStatus(dragging)" />
                       </v-card-text>
                    </v-card>
                </v-menu>
              </v-list-item-title>
              <v-list-item-content>           
               <v-list-item-subtitle class="caption" v-if="dragging.Created_By">
               <i>Created by {{dragging.Created_By.Full_Name}}</i>
              </v-list-item-subtitle>
               <v-list-item-subtitle class="caption">
               <i>on {{dragging.stringcreatedon}}</i>
              </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
               
                <v-card-text style="padding: 0px; !important">
                 <RecordLiaison :RecordData="dragging" :ActiveSession="CurrentEntity" :LiaisonDialogActive="true" :CommentsOnly="true"
                        :OperationalDB="ItemOperationalDB" @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
                        @ActivateProcessing="ActivateProcessing" @ToggleLiaisonDialog="ToggleLiaisonDialog" :FullonCreate="true"
                        :System="System" :SystemEntities="SystemEntities" :CanEdit="dragging.CanEdit" :ActiveRecord="dragging.ActiveProcess"
                        />
                </v-card-text>
            
            </v-card>
            <!-- </v-expand-transition> -->
      </v-dialog>      
      <!-- {{Label.BoardFilterDates}}
      LabelBoardFilterDates {{LabelBoardFilterDates}} -->
      <v-row style="padding: 20px;">
         <v-btn v-if="!NotesbyDatainView" @click="NotesbyDatainView = true" outlined width="100%">Notes by Records in View</v-btn>
          <v-btn v-if="NotesbyDatainView" @click="NotesbyDatainView = false" outlined width="100%">All Notes</v-btn>
         </v-row>
     
      <v-layout row class="justify-center mx-3">   
                              <v-btn
                                  fab
                                  color="primary"
                                  right
                                  absolute
                                  small
                                  @click="ConfirmAddNote()"
                                ><v-icon>mdi-plus-thick</v-icon>
                                </v-btn>
                                <v-btn dark
                                  fab style="right:60px;"
                                  color="green"
                                  right
                                  absolute
                                  small
                                  @click="SaveNotes()"
                                ><v-icon>mdi-content-save</v-icon>
                                </v-btn>
        <v-card elevation="3" tile class="noteentry my-1 mx-2" max-width="250" :color="NewNote.Color" :dark="NewNote.Dark" v-if="NewNote">
          <v-btn 
          @click="NewNote = ''" outlined width="50%">Cancel</v-btn>
          <v-btn
          @click="AddNotetoDB()" outlined width="50%">Save</v-btn>
          <v-card-title class="justify-space-between">
          
            <v-text-field v-model="NewNote.Title" label="Title"/>
             <input type="color" id="notebgcolor" style="position: absolute;z-index:1;right: 5px;" v-model="NewNote.Color" />        
                                        
          </v-card-title>
          <v-card-text class="justify-center">
             <ContentEditableField style="padding:15px;"
                  :FieldObject="NewNote" :FieldName="'Description'"
                  :FieldValue="NewNote.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
              </v-card-text>
              <v-card flat tile style="min-height: 200px;" @dragover.prevent @drop="recordtaggingfinish(NewNote, $event)">
                 <v-card-text>
                  {{NewNote.Records.length}} Tagged (filters may affect if all show in list below)
                <v-virtual-scroll
                style="padding:4px;"
                    class="transparent"
                      :items="FullDataRecords(NewNote.Records)"
                      :item-height="20"
                      :height="190"
                    >
                <template v-slot:default="{ item }">
                <v-chip  x-small>
                   <v-icon x-small @click="RemoveNoteTagItem(NewNote,item.id)" dark color="red">
                    mdi-close
                  </v-icon>
                  {{item[CurrentEntity.Primary_Field_Name]}}
                </v-chip>
               </template>
                </v-virtual-scroll>
                 </v-card-text>
              </v-card>
              <v-card-actions class="justify-space-between">
                <v-btn small v-if="NewNote.Records.length === 0" @click="TagRecordsinView(NewNote)" dark color="warning"><v-icon small>mdi-tag</v-icon>All in View</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn small v-if="NewNote.Records.length > 0" @click="ClearNoteRecords(NewNote)" dark color="error">Clear</v-btn>
              </v-card-actions>
               <v-card-actions class="justify-space-between">
                
                <v-spacer>
                </v-spacer>
                <v-btn small @click="AddNotetoDB()" dark color="green">Add</v-btn>
              </v-card-actions>
            </v-card>
            <v-dialog v-model="NewNoteActionDial" width="300px">              
              <v-card outlined>
                <v-card-title>
                  New Action - {{ActionNote.Title}}
                </v-card-title>
                  <v-card-text>
                  <v-text-field  dense v-model="NewNoteAction.Description"/>
                  </v-card-text>
                  <v-card-actions class="justify-space-between">
                    <v-btn x-small @click="CancelAddAction(ActionNote)">Cancel</v-btn>
                    <v-spacer></v-spacer>
                  <v-btn x-small @click="AddAction(ActionNote)">Add</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>
          <!-- <v-layout row class="justify-center" style="padding-bottom: 10px;"> -->
          
          <!-- </v-layout> -->
        <v-card elevation="3" tile class="noteentry my-1 mx-2" max-width="250" :color="note.Color" :dark="note.Dark" v-for="note in ComputedNotesArray" :key="note.itemObjKey">
          <v-btn x-small v-if="!note.Editing && note.Editors.includes(userLoggedIn.id) || !note.Editing && userIsAdmin"
          @click="note.Editing = true" fab icon style="position: absolute;top:3px;left:3px;"><v-icon color="red">mdi-pencil</v-icon></v-btn>
          <v-btn v-if="!note.Editing" x-small style="position: absolute;top:3px;left:25px;" @click="ActivateNewNoteActionDial(note)" fab icon>
            <v-icon >mdi-clipboard-check</v-icon> 
            </v-btn>
          <v-btn outlined small v-if="note.Editing" width="50%"
            @click="note.Editing = false">
              Cancel
            </v-btn>
            <v-btn outlined small width="50%" v-if="note.Editing" @click="ConfirmNoteDelete(note.id)">
              Delete
            </v-btn>
            <v-card-title class="justify-end subtleoverline">
              {{note.Owner.Full_Name}}
            </v-card-title>
            <v-card-subtitle class="caption">
              {{note.Created_On.toDate().toLocaleString()}}
            </v-card-subtitle>
          <v-card-title class="justify-space-between">            
            <v-text-field v-if="note.Editing"  v-model="note.Title" label="Title"/>
             <input v-if="note.Editing" type="color" id="notebgcolor" style="position: absolute;z-index:1;right: 5px;" v-model="note.Color" />        
             <span v-if="!note.Editing">
              {{note.Title}}
             </span>                              
          </v-card-title>
          
          <v-card-text v-if="note.Editing" class="justify-center">
             <ContentEditableField style="padding:15px;"
                  :FieldObject="note" :FieldName="'Description'"
                  :FieldValue="note.Description" @UpdateContentEditableField="UpdateContentEditableField" :AdditionalSaveMethod="''" />
              </v-card-text>
              <v-card-text v-if="!note.Editing" class="justify-center" v-html="note.Description">
              </v-card-text>
              <v-list class="transparent">
                
                <!-- <v-menu offset-y :close-on-content-click="false" top width="300px;">
                    <template v-slot:activator="{ on }">
                    <v-btn v-on="on" fab icon><v-icon class="soloactionicon">mdi-plus</v-icon>  </v-btn>
                    </template>
                    <v-card outlined>
                       <v-card-text>
                        <v-text-field  dense v-model="NewNoteAction.Description"/>
                       </v-card-text>
                       <v-card-actions class="justify-space-between">
                        <v-btn x-small @click="AddAction(note)">Add</v-btn>
                       </v-card-actions>
                    </v-card>
                </v-menu> -->
              <!-- <v-icon @click="AddAction(note)" class="soloactionicon">mdi-plus</v-icon>              -->
                  <v-list-item dense class="caption" v-for="act in note.Actions" :key="act.itemObjKey">
                    <!-- <v-list-item-action>
                      <v-checkbox @change="CheckActionCompleted(note,act)" v-model="act.Completed"></v-checkbox>
                    </v-list-item-action> -->
                    <v-list-item-action style="margin-right: 0px !important;">
                      <v-checkbox @change="CheckActionCompleted(note,act)" v-model="act.Completed"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-avatar class="blue" size="25">
                      <span class="white--text caption mx-1" v-if="!act.Completed">
                        {{act.Owner.Name.substr(0,1)}}{{act.Owner.Surname.substr(0,1)}}
                        </span>
                        <span class="white--text caption mx-1" v-if="act.Completed">
                        {{act.Completed_By.Name.substr(0,1)}}{{act.Completed_By.Surname.substr(0,1)}}
                        </span>
                    </v-list-item-avatar>
                    <v-list-item-content class="caption" >
                      <v-list-item-title class="caption"  :style="act.Completed? 'text-decoration: line-through;' : ''"> {{act.Description}}</v-list-item-title>
                    </v-list-item-content>
                     <v-list-item-action class="caption" >
                      <v-icon v-if="note.Editing && note.Editors.includes(userLoggedIn.id)" @click="ConfirmDeleteNoteAction(note,act)">mdi-delete-forever</v-icon>
                     </v-list-item-action>
                  </v-list-item>
              </v-list>
              <v-card flat tile style="min-height: 200px;" v-if="note.Editing" @dragover.prevent @drop="recordtaggingfinish(note, $event)">
                <v-card-text>
                  {{note.Records.length}} Tagged (filters may affect if all show in list below)
               <v-virtual-scroll
                style="padding:4px;"
                    class="transparent"
                      :items="FullDataRecords(note.Records)"
                      :item-height="20"
                      :height="190"
                    >
                <template v-slot:default="{ item }">
                <v-chip  x-small>
                   <v-icon x-small @click="RemoveNoteTagItem(note,item.id)" dark color="red">
                    mdi-close
                  </v-icon>
                  {{item[CurrentEntity.Primary_Field_Name]}}
                </v-chip>
               </template>
                </v-virtual-scroll>
                </v-card-text>                
              </v-card>
               <v-card-actions v-if="note.Editing">
                
                <v-btn small @click="TagRecordsinView(note)" dark color="warning"><v-icon small>mdi-tag</v-icon>All in View</v-btn>
                <v-spacer>
                </v-spacer>
                <v-btn small v-if="note.Records.length > 0" @click="ClearNoteRecords(note)" dark color="error">Clear</v-btn>
              </v-card-actions>
              <v-card-actions v-if="note.Editing">
                <v-autocomplete multiple class="caption" @change="CheckEditors(note)" :items="NonGuestUsersArray" v-model="note.Editors" item-text="id" label="Editors">
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="removeEditor(note,data.item)"
                    >
                      <v-avatar left class="blue">
                        <v-img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo"></v-img>
                        <span class="white--text caption mx-1" v-if="!data.item.Profile_Photo" >
                        {{data.item.Name.substr(0,1)}}{{data.item.Surname.substr(0,1)}}
                        </span>
                      </v-avatar>
                      {{ data.item.Full_Name }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                          <v-list-item-avatar>
                            <img v-if="data.item.Profile_Photo" :src="data.item.Profile_Photo" style="object-fit: cover;">
                            <img v-if="!data.item.Profile_Photo"  src="@/assets/BlankProfilePic.png" style="object-fit: cover;">
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title >{{data.item.Full_Name}}</v-list-item-title>
                            <v-list-item-subtitle v-if="data.item.Business_Unit" class="caption links--text" v-html="data.item.Business_Unit.Name"></v-list-item-subtitle>
                            <v-list-item-subtitle class="caption" v-html="data.item.Email"></v-list-item-subtitle>                            
                          </v-list-item-content>
                        </template>
                </v-autocomplete>
                <v-spacer>
                </v-spacer>   
              </v-card-actions>
              <v-card-actions v-if="note.Editing">
              
                <v-spacer>
                </v-spacer>             
                <v-btn small @click="SaveNote(note)" dark color="green">Save</v-btn>
              </v-card-actions>
              <v-card-actions v-if="!note.Editing" style="bottom: 0px;position: absolute;right: 0px;">
                <v-btn small @click="FilterbyNoteRecords(note,note.Records)" fab icon><v-icon>mdi-filter</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>                             
      </v-layout>
    <v-layout row class="justify-center mx-3">   
        <v-chip-group   
                    v-if="TasksFilterMode == 'Type'"    
                    v-model="selectedType"    
                    active-class="deep-purple--text text--accent-4"   
                    column    
                        
                >   
                <v-chip v-for="type in STDActionTypesbyBoard" :key="type.Name" :value="type">   
                {{ type.Name }}   
                </v-chip>
                </v-chip-group>
                <!-- <v-chip-group   
                    v-if="TasksFilterMode"    
                    v-model="selectedFilter"    
                    @change="ActivateProcessMapFilter()"    
                    active-class="deep-purple--text text--accent-4"   
                    column  
                >   
                <v-chip v-for="type in FilteredArray" :key="type.id" :value="type">   
                {{ type[type.identifier] }}   
                </v-chip> 
            </v-chip-group>    -->
        </v-layout> 
        <v-card outlined style="margin-top: 20px;">
          <v-card-text>
         <v-row v-if="Data && HasOwnerField" 
                class="fill-height"   
                align="start"   
                justify="center">
                <v-chip-group>
                  <v-chip @click="ActivateOwnerFilter(user)" v-for="user in UsersArray" :key="user.itemObjKey" 
                  :draggable="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" @dragstart="ownerdragstart(user, $event)">
                     <v-avatar size="35" >
                  <v-img :src="user.Profile_Photo" v-if="user.Profile_Photo"/>
                  <v-img src="@/assets/BlankProfilePic.png" v-if="!user.Profile_Photo"/>
                  </v-avatar>
                  {{user.Full_Name}}
                  </v-chip>
                </v-chip-group>
         </v-row>
          <v-layout row class="justify-center mx-3" v-if="Label && Label.BoardFilterDates && Label.BoardFilterDates.length > 0">
        <span v-for="field in LabelBoardFilterDates" :key="field.itemObjKey">
        <v-menu 
                    v-model="field.DateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="200px"
                    >
                    <template v-slot:activator="{ on }">
                        <v-text-field class="smallinput"
                        :label="field.DisplayName"
                        prepend-icon="mdi-calendar-month"
                        v-on="on" clearable
                        v-model="field.DateFilter"
                        ></v-text-field>
                        </template>
                    <v-date-picker  v-model="field.DateFilter" :readonly="field.ReadOnly"
                            @input="CheckDateFilter(field)"></v-date-picker>
                    </v-menu>
                    <v-select clearable v-model="field.TimelineFilter" item-text="FilterName" label="Timeline Filter" @change="CheckTimelineFilter(field)"
                     :items="['Today','This Week','Next Week','This Month']"
                    />
        </span>
      </v-layout>
      <v-card-title class="justify-center mediumoverline" v-if="OptionSetsFilterFields && OptionSetsFilterFields.length > 0">
        <span v-for="textfield in OptionSetsFilterFields" :key="textfield.itemObjKey">
         <div width="100px"><br>
           <v-autocomplete class="mx-5"
            :items="textfield.Options" 
            v-model="textfield.ActiveFilter"          
            item-text="Name"
            clearable
            :label="textfield.DisplayName"
            autowidth
            return-object
            chips
            flat dense
            />
           </div>
           </span> 
      </v-card-title>
      <v-card-title class="justify-center mediumoverline" v-if="NotefilterRecords.length > 0">
        The result list is filtered by a Note "{{FilterNote.Title}}" <v-btn @click="FilterbyNoteRecords(FilterNote,NotefilterRecords)">Clear</v-btn>
      </v-card-title>
      <v-toolbar rounded elevation="12"
        :style="{ zIndex:2 }"
          class="BoldBuilderGradient white--text"
        >
          <v-spacer></v-spacer>
          <v-text-field dark
            v-model="boardsearch"            
            label="Search"
            single-line
            hide-details
            class="mediumoverline"
          ></v-text-field><v-icon @click="ResetSearch()" v-if="search === ''" dark>mdi-file-search</v-icon><v-icon @click="ResetSearch()" v-if="search !== ''" dark>mdi-close</v-icon>
          <v-spacer></v-spacer>
      </v-toolbar>
      
      <v-row v-if="Data" style="margin-top: 20px;"
                class="fill-height"   
                align="start"   
                justify="center"> 
                    
                <v-flex xl3 lg4 md6 sm12 xs12 class="my-3" style="height: 100%;" v-for="status in Labels" :key="status.itemObjKey">                       
                        <v-card height="100%" width="100%" :class="StatusBGColor(status)" tile @dragover.prevent @drop="taskdragFinish(status, $event)">    
                        <v-card-title :class="StatusColor(status)" style="font-size:12px;">
                            <v-icon color="white">{{status.Icon}}</v-icon>
                            {{status.Name}}
                            <v-spacer></v-spacer>{{Match(status).length}}</v-card-title>  
                                                  
                        <v-list dense class="transparent mx-1"    
                                min-height="100px"> 
                                <!-- <v-hover v-slot="{ hover }"  >    -->
                                  <v-virtual-scroll
                                style="padding:4px;"
                                    class="transparent"
                                      :items="Match(status)"
                                      :item-height="120"
                                      :height="250"
                                    >
                                  <template v-slot:default="{ item }">
                                   <v-card   @dragover.prevent @drop="ownerdragging ? ownerdragFinish(item, $event) : ''" :class="`chip ${item.status} my-1`" width="100%" >
                                        
                                <v-list>
                                    <v-list-item
                                    :draggable="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs" @dragstart="taskdragstart(item, $event)"
                                ><v-icon small v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs">
                                    mdi-drag
                                    </v-icon>
                                    <v-list-item-avatar class="blue" size="25">
                                    <span class="white--text caption mx-1" v-if="!item.Owner.Profile_Photo">
                                    {{item.Owner.Name.substr(0,1)}}{{item.Owner.Surname.substr(0,1)}}
                                    </span>
                                     <v-img :src="item.Owner.Profile_Photo" v-if="item.Owner && item.Owner.Profile_Photo"/>
                                   
                                    </v-list-item-avatar>

                                    <v-list-item-content>
                                    <v-list-item-title>
                                         {{item[CurrentEntity.Primary_Field_Name]}}
                                        <!-- <span class="caption red--text" style="font-size:10px;" v-if="item.overdue">Overdue</span> -->
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                      <v-chip-group column>
                                        <v-chip x-small v-for="textfield in ComputedOptionSetsFilterFields" :key="textfield.itemObjKey">
                                         <span v-if="item[textfield.id]">
                                          {{item[textfield.id].Name}}
                                         </span>
                                         <!--   @click="CheckforFilter(item,textfield)" -->
                                        </v-chip> 
                                      </v-chip-group>
                                    </v-list-item-subtitle>
                                    <!-- <v-list-item-subtitle class="caption red--text" style="font-size:10px;" v-if="item.overdue">
                                         <v-icon color="red" x-small>mdi-alert</v-icon>Overdue
                                    </v-list-item-subtitle> -->
                                    </v-list-item-content>
                                  
                                    <v-list-item-action>
                                        <v-icon
                                            small
                                             @click="ActivateRecordLiaison(item)"
                                        >
                                            mdi-information-outline
                                        </v-icon>
                                        
                                    </v-list-item-action>
                                </v-list-item>
                                
                               
                              
                            </v-list>                                           
                        </v-card>
                                </template>
                              </v-virtual-scroll>   
                               
                        <!-- </v-hover> -->
                    </v-list> 
                              
                </v-card> 
                  
            </v-flex>
        </v-row>
          </v-card-text>
          
        </v-card>
                
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import RecordLiaison from '@/components/General/RecordLiaison';
import ContentEditableField from '@/components/Database/ContentEditableField'
export default {
    props: ['System','SystemEntities','Data','CanCreate','CurrentEntity','Label','HasOwnerField','OptionSetsFilterFields','search'],
    components: {RecordLiaison,ContentEditableField},
    data() {
        return {
          boardsearch: '',
          NewNoteActionDial: false,
          ActionNote: '',
          NewNoteAction: {
            Description: 'Action',
            Completed: false
          },
          DefaultNewNoteAction: {
            Description: 'Action',
            Completed: false
          },
          defaultNewNote: {
            Title: 'New Note',
            Description: 'Add Description to the Note',
            Color: '#ECEC93FF',
            TitleColor: '#EBEB6C',
            Dark: false,
            Editing: false,
            Records: [],
            Editors: [],
            Actions: [],
          },
          NewNote: '',
          NotefilterRecords: [],
          NotesArray: [
          ],
          LabelBoardFilterDates: [],
          BoardDateFilters: [],
          Ownerfilter: '',
          ownerdragging: '',
          dragging: '',
          RecordLiaisonDialog: false,
          Today: '',
          ActualToday: '',			
          ActualNow: '',			
          TimelineFilterOptions: [	
        {			
          ID: 1000001,			
          FilterName: 'This Week (Per Day)',			
          FilterDescription: 'When viewing, this filter returns each Day of the Current Week, and relevant results on each Day',			
          GiveName: 'Per Day',			
          Name: 'This Week',			
          StartDateVariable: 'ThisMonday',			
          EndDateVariable: 'ThisSunday',			
          ChartLabels: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun'],			
          ChartLabelsVariables: ['ThisMonday','ThisTuesday','ThisWednesday','ThisThursday','ThisFriday','ThisSaturday','ThisSunday'],			
          ChartlabelPeriodEndMethodX: 'EndofDay',			
          ChartlabelPeriodEndMethodY: 'EndofDay',			
        },			
        {			
          ID: 1000002,			
          AccumulatorID: 1000001,			
          FilterName: 'This Month (Per Week)',			
          FilterDescription: 'When viewing, this filter returns each Week for the total number of Weeks of the Current Month, and relevant results on each Week. For Goals, the results shown is by default filtered by the current Week',			
          GiveName: 'Per Week',			
          Name: 'This Month',			
          StartDateVariable: 'CurrentMonthStart',			
          EndDateVariable: 'CurrentMonthEnd',			
          ChartLabelTrigger: 'CurrentMonthName',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentMonthLabels',         			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofWeek',			
        },			
        {			
          ID: 1000003,			
          AccumulatorID: 1000002,			
          FilterName: 'This Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month',			
          Name: 'This Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'],          			
          ChartLabelsVariables: ['ThisJanuaryStart','ThisFebruaryStart','ThisMarchStart','ThisAprilStart','ThisMayStart','ThisJuneStart','ThisJulyStart','ThisAugustStart','ThisSeptemberStart','ThisOctoberStart','ThisNovemberStart','ThisDecemberStart'],			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
        },			
        {			
          ID: 1000004,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Year (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Year, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Year',			
          StartDateVariable: 'ThisJanuaryStart',			
          EndDateVariable: 'ThisDecemberEnd',			
          ChartLabels: ['Q1','Q2','Q3','Q4'],			
          ChartLabelsVariables: ['FiscalQ1Start','FiscalQ2Start','FiscalQ3Start','FiscalQ4Start'],			
          ChartlabelPeriodEndMethodX: 'EndofQuarter',			
          ChartlabelPeriodEndMethodY: 'EndofQuarter'			
        },			
        {			
          ID: 1000005,			
          AccumulatorID: 1000002,			
          FilterName: 'This Fiscal Quarter (Per Month)',			
          FilterDescription: 'When viewing, this filter returns each Month of the Current Fiscal Quarter, and relevant results on each Month. For Goals, the results shown is by default filtered by the current Month',			
          GiveName: 'Per Month (Fiscal)',			
          Name: 'This Fiscal Quarter',			
          StartDateVariable: 'CurrentFiscalQStart',			
          EndDateVariable: 'CurrentFiscalQEnd',			
          ChartLabelsisDynamic: true,			
          DynamicChartLabelsProp: 'CurrentFiscalLabels',			
          ChartLabelTrigger: 'CurrentFiscalPeriod',			
          ChartlabelPeriodEndMethodX: 'EndofMonth',			
          ChartlabelPeriodEndMethodY: 'EndofMonth',			
          			
        },			
        			
      ],		
      NotesbyDatainView: false,	
      FilterNote: '',
        }
    },	
    computed:{
      DataListIds(){
        return this.DataFilteredandSearched.map(record => {
          return record.id
        })
      },
      ComputedNotesArray(){
        return this.NotesArray.filter(note => {
          if(this.NotesbyDatainView){
            return note.Records.some(r=> this.DataListIds.includes(r))
          }
          else if(this.NotefilterRecords && this.NotefilterRecords.length > 0){
            return note.Records.some(r=> this.NotefilterRecords.includes(r))
          }
          else{
            return note
          }
        })
      },
      ComputedOptionSetsFilterFields(){
        return this.OptionSetsFilterFields.filter(field => {
          return field.id !== 'Status'
        })
      },
      NonGuestUsersArray(){
        return this.UsersArray.filter(user => {
          return !user.IsGuestUser
        })
      },
      CurrentMonthLabels(){			
      //to return the weeks of the month. But possible return as objects for looping purposes, TBD			
       let variabletableobj = {			
        ChartLabels: ['Week 1','Week 2','Week 3','Week 4','Week 5'],          			
        ChartLabelsVariables: ['MonthWeek1Start','MonthWeek2Start','MonthWeek3Start','MonthWeek4Start','MonthWeek5Start'],			
      }			
      let Wvarobj = {			
        Labels: [],			
        ChartLabelsVariables : []			
      }			
      let length = 4			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setDate(firstdayofmonth+28)			
      d.setHours(0,0,0,0);			
      let week5month = d.getMonth()			
      if(week5month === month){			
        length = 5			
      }			
      for(var i = 0; i < length; i++){			
        Wvarobj.Labels[i] = variabletableobj.ChartLabels[i]			
        Wvarobj.ChartLabelsVariables[i] = variabletableobj.ChartLabelsVariables[i]			
      }			
      return Wvarobj			
    },			
    MonthWeek1Start(){			
      let newtoday = new Date(this.Today)			
      var firstdayofmonth = 1;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek2Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek1Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek3Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek2Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek4Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek3Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },			
    MonthWeek5Start(){			
      let newtoday = this.EndofWeek(this.MonthWeek4Start)			
      var firstdayofweek = newtoday.getDate()-1+2;			
      var year = newtoday.getFullYear();			
      var month = newtoday.getMonth();			
      var d = new Date(year, month, firstdayofweek)			
      // d.setDate(firstdayofmonth)			
      d.setHours(0,0,0,0);			
      return d			
    },	
      ThisJanuaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 0;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisFebruaryStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 1;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMarchStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 2;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAprilStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 3;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisMayStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 4;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJuneStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 5;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisJulyStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 6;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisAugustStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 7;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisSeptemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 8;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisOctoberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 9;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisNovemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 10;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },			
    ThisDecemberStart(){			
      var year = this.ComputedToday.getFullYear();			
      var month = 11;			
      var day = this.ComputedToday.getDate();			
      var d = new Date(year, month, day)			
      d.setDate(1);			
      d.setHours(0,0,0,0);			
      return d			
    },
    ComputedToday(){			
          let date = new Date(this.Today)			
          date.setHours(0,0,0,0)			
          return date			
      },
      DayofView(){
            return {Start: this.Today,End: this.EndofDay(this.Today)}
        },
        ThisWeek(){
            return {Start: this.ThisMonday,End: this.ThisSunday}
        },
        ThisMonth(){
            return {Start: this.CurrentMonthStart,End: this.CurrentMonthEnd}
        },
        ThisYear(){
            return {Start: this.ThisJanuaryStart,End: this.ThisDecemberEnd}
        },
        CurrentMonthStart(){
            let d = new Date(this.Today)
            d.setDate(1);	
            d.setHours(0,0,0,0);
            return d
        }, 
        CurrentMonthEnd(){
            let d = new Date(this.Today)
            return this.EndofMonth(d)
        }, 
        ThisMonday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+1))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisTuesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+2))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisWednesday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+3))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisThursday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+4))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisFriday(){			
      let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+5))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSaturday(){			
       let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+6))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      ThisSunday(){			
        let newtoday = new Date(this.Today)			
        var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
        let date = new Date(newtoday.setDate(lastdaylastweek+7))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      LastweekLastDate(){			
        let newtoday = new Date(this.Today)		
          var lastdaylastweek = newtoday.getDate() - newtoday.getDay();			
          let date = new Date(newtoday.setDate(lastdaylastweek))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      NextweekFirstDate(){			
        let sunday = this.ThisSunday
          var dayofsunday = sunday.getDate()
          let date = new Date(sunday.setDate(dayofsunday + 1))			
          date.setHours(0,0,0,0)			
          return date			
      },			
      CurrentYear(){			
          return this.ComputedToday.getFullYear()			
      },
        ItemOperationalDB(){
            return this.dragging ? db.collection(this.CurrentEntity.id.split('_').join('').toLowerCase()).doc(this.dragging.id) : ''
        },
        UsersArray(){
            return this.$store.state.Users
        },
        Labels(){
            return this.Label.Options
        },
        DateFiltersPrepped(){
          return this.LabelBoardFilterDates.map(date => {
            let dateobj = {}
            dateobj.id = date.id
            dateobj.Filter = false
            //console.log(date.DateFilter,date.TimelineFilter)
            if(date.DateFilter){
              dateobj.Filter = true
              dateobj.StartDateObj = new Date(date.DateFilter)
              dateobj.StartDateObj.setHours(0,0,0,0)
              dateobj.EndDateObj = new Date(date.DateFilter)
              dateobj.EndDateObj.setHours(23,59,59,999)              
            }
            else if(date.TimelineFilter){
              dateobj.Filter = true
              // if(!date.TimelineFilter.ChartLabelsisDynamic){			
              //   let filterChartlabelPeriodEndMethodX = date.TimelineFilter.ChartlabelPeriodEndMethodX			
              //   let filterChartLabelsVariables = date.TimelineFilter.ChartLabelsVariables			
              //   let lastvariable = filterChartLabelsVariables.length-1                    			
              //   dateobj.StartDateObj = this[filterChartLabelsVariables[0]]			
              //   dateobj.EndDateObj = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])
              //   dateobj.id = date.id
              // }			
              // else if(date.TimelineFilter.ChartLabelsisDynamic){			
              //   let filterChartlabelPeriodEndMethodX = date.TimelineFilter.ChartlabelPeriodEndMethodX			
              //   let filterChartLabelsVariables = this[date.TimelineFilter.DynamicChartLabelsProp].ChartLabelsVariables			
              //   let lastvariable = filterChartLabelsVariables.length-1                    			
              //   dateobj.StartDateObj = this[filterChartLabelsVariables[0]]			
              //   dateobj.EndDateObj = this[filterChartlabelPeriodEndMethodX](this[filterChartLabelsVariables[lastvariable]])
              //   dateobj.id = date.id
              // }
              if(date.TimelineFilter === 'Today'){
                dateobj.StartDateObj = new Date()
                dateobj.StartDateObj.setHours(0,0,0,0)
                dateobj.EndDateObj = this.EndofDay(dateobj.StartDateObj)
              }
              else if(date.TimelineFilter === 'This Week'){
                dateobj.StartDateObj = this.ThisMonday
                dateobj.StartDateObj.setHours(0,0,0,0)
                dateobj.EndDateObj = this.EndofWeek(dateobj.StartDateObj,0)
              }
              else if(date.TimelineFilter === 'Next Week'){
                dateobj.StartDateObj = this.NextweekFirstDate
                dateobj.StartDateObj.setHours(0,0,0,0)
                dateobj.EndDateObj = this.EndofWeek(dateobj.StartDateObj)
              }
              else if(date.TimelineFilter === 'This Month'){
                dateobj.StartDateObj = this.CurrentMonthStart
                dateobj.EndDateObj = this.EndofMonth(dateobj.StartDateObj)
              }
              //console.log('dateobj',dateobj)
            }
            //console.log(dateobj)
            return dateobj
          })
        },
        DataFilteredbyNotes(){
          return this.DataFilteredandSearched.filter(record => {
              if(this.NotefilterRecords && this.NotefilterRecords.length > 0){
                return this.NotefilterRecords.includes(record.id)
              }
              else{
                return record
              }
            })
        },
        DataFilteredandSearched(){
            return this.Data.filter(item => {
              if(this.Ownerfilter && this.HasOwnerField){
                return item.Owner && item.Ownerid === this.Ownerfilter.id
              }
              else{
                return item
              }
            }).filter(item => {
              if(this.DateFiltersPrepped && this.DateFiltersPrepped.length > 0){
                let pass = true
                this.DateFiltersPrepped.map(date => {
                  if(date.Filter){
                    pass = false
                    //console.log(item.id,date.id,item[date.id])
                    if(item[date.id] && item[date.id].toDate){
                      let itemdateobj = item[date.id].toDate()                      
                      // let itemdatetime = itemdateobj.getTime()
                      //console.log(date,itemdateobj)
                      pass = itemdateobj >= date.StartDateObj && itemdateobj <= date.EndDateObj
                    }
                    else if(item[date.id] && typeof item[date.id] === 'string'){
                      let itemdateobj = new Date(item[date.id])                     
                      // let itemdatetime = itemdateobj.getTime()
                      //console.log(date,itemdateobj)
                      pass = itemdateobj >= date.StartDateObj && itemdateobj <= date.EndDateObj
                    }
                  }
                })
                return pass
              }
              else{
                return item
              }
            })
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
      boardsearch: {
          handler: function(oldvalue, newvalue) {
          if(oldvalue !== newvalue){
            this.$emit('UpdateSearch',this.boardsearch)
          }
        },deep: true
        },
       Label:{        
         handler: function(oldvalue, newvalue) {
          if(this.Label.BoardFilterDates){
            this.PrepareDatefilters()
          }
          this.GetNotes()
          },
          deep: true
      },
      
    },
    created(){
      this.PrepareDatefilters()
      this.PrepareDateFilterOptions()
      this.GetNotes()
    },
    methods:{
      ResetSearch(){
        this.boardsearch = ''
      },
      CheckforFilter(notice,textfield){
        if(notice[textfield.id]){
          let option = textfield.Options.find(obj => obj.ID === notice[textfield.id].ID)
          if(option){
            textfield.ActiveFilter = option
          }
        }
      },
      removeEditor (note,item) {
        const index = note.Editors.indexOf(item.id)
        if (index >= 0) note.Editors.splice(index, 1)
      },
      CheckEditors(note){
        if(note.Editors.length === 0){
          note.Editors.push(this.userLoggedIn.id)
        }
        if(!note.Editors.includes(note.Owner.id)){
          note.Editors.push(note.Owner.id)
        }
        this.SaveNote(note,true)
      },
      ConfirmDeleteNoteAction(note,act){
        let actobj = note.Actions.find(obj => obj === act)
        let index = note.Actions.indexOf(actobj)
        confirm('Are you sure you want to remove this action?') && this.ProcessDeleteNoteAction(note,index)
      },
      ProcessDeleteNoteAction(note,index){
        note.Actions.splice(index,1)
        this.SaveNote(note)
      },
      ActivateNewNoteActionDial(note){
        this.ActionNote = note
        this.NewNoteActionDial = true
      },
      CheckActionCompleted(note,act){
        if(act.Completed){
          act.Completed_By = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname}
          act.Completed_Byid = this.userLoggedIn.id
        }
        this.SaveNote(note)
      },
      CancelAddAction(){
        this.NewNoteAction = Object.assign({},this.DefaultNewNoteAction)
        this.NewNoteActionDial = false
        this.ActionNote = ''
      },
      AddAction(note){
        this.NewNoteAction.Owner = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id,Name: this.userLoggedIn.Name,Surname: this.userLoggedIn.Surname}
        this.NewNoteAction.Ownerid = this.userLoggedIn.id
        this.NewNoteAction.Created_On = new Date()
        note.Actions.push(this.NewNoteAction)
        db.collection('notes').doc(note.id).set(note).then(addeddoc => {
          this.CancelAddAction()
        })
      },
      SaveNote(note,fromeditors){
        if(!fromeditors){          
        note.Editing = false
        } 
         db.collection('notes').doc(note.id).set(note).then(addeddoc => {
          
        })
      },
      ClearNoteRecords(note){
        confirm('Are you sure you want to clear all '+note.Records.length+' tags in this Note?') && this.ConfirmClearNoteRecords(note)
      },
      ConfirmClearNoteRecords(note){
        note.Records =[]
      },
      TagRecordsinView(note){
        note.Records = note.Records.concat(this.DataFilteredbyNotes.map(data => {
          return data.id
        }).filter(data => { return !note.Records.includes(data)}))
        //console.log(note.Records)
      },
      RemoveNoteTagItem(note,recordid){
        let recordobj = note.Records.find(obj => obj === recordid)
        let index = note.Records.indexOf(recordobj)
        confirm('Are you sure you want to remove this tag?') && this.ConfirmRemoveNoteTag(note,index)
      },
      ConfirmRemoveNoteTag(note,index){
        note.Records.splice(index,1)
      },
      FullDataRecords(ids){
        return this.Data.filter(data => {
          return ids.includes(data.id)
        })
      },
      recordtaggingfinish(note){
        note.Records.push(this.dragging.id)       
      },
      FilterbyNoteRecords(note,Records){
        if(this.NotefilterRecords === Records){
          this.NotefilterRecords = []
          this.FilterNote = ''
        }
        else{
          this.NotefilterRecords = Records
          this.FilterNote = note
        }    
        this.NotesbyDatainView = false    
      },
      UpdateContentEditableField(prop,value,AdditionalSaveMethod,FieldObject){
      FieldObject[prop] = value
      console.log(AdditionalSaveMethod)
      if(AdditionalSaveMethod){
        this[AdditionalSaveMethod]()
      }
      console.log(this.field,FieldObject,this.Record)
    },
      GetNotes(){
        this.NotesArray = []
        this.NotefilterRecords = []
        db.collection('notes').where('topicid','==',this.CurrentEntity.id).where('Label','==',this.Label.id).onSnapshot(res => {
              const changes = res.docChanges();
                changes.forEach(change => {
                    if (change.type === 'added') {
                    this.NotesArray.push({
                        ...change.doc.data(),
                        id: change.doc.id
                    })
                    }  
                    if (change.type === 'modified') {
                       let newmodobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      let oncheck = this.NotesArray.find(obj => obj.id === newmodobj.id)
                      if(oncheck){
                        let index = this.NotesArray.indexOf(oncheck)
                        this.NotesArray.splice(index,1,newmodobj)
                      }
                    } 
                    if (change.type === 'removed') {
                       let newmodobj = {
                        ...change.doc.data(),
                        id: change.doc.id
                      }
                      let oncheck = this.NotesArray.find(obj => obj.id === newmodobj.id)
                      if(oncheck){
                        let index = this.NotesArray.indexOf(oncheck)
                        this.NotesArray.splice(index,1)
                      }
                    }
                })
        })
      },
      SaveNotes(){
        this.NotesArray.map(note => {
          note.Editing = false
           db.collection('notes').doc(note.id).set(note).then(updatednotedoc => {

           })
        })       
      },
      ConfirmNoteDelete(noteid){
        confirm('Are you sure you want to delete this item?') && this.ProcessNoteDelete(noteid)
      },
      ProcessNoteDelete(noteid){
        db.collection('notes').doc(noteid).delete()
      },
      ConfirmAddNote(){
        let ref = db.collection('notes').doc()
        let newdocid = ref.id
        this.NewNote = Object.assign({},this.defaultNewNote)
        this.NewNote.Owner = {Full_Name: this.userLoggedIn.Full_Name, id:this.userLoggedIn.id}
        this.NewNote.Ownerid = this.userLoggedIn.id
        this.NewNote.topicid = this.CurrentEntity.id
        this.NewNote.Label = this.Label.id
        this.NewNote.id = newdocid
      },
      AddNotetoDB(){
        this.NewNote.Created_On = new Date()
        this.NewNote.Editors.push(this.userLoggedIn.id)
        db.collection('notes').doc(this.NewNote.id).set(this.NewNote).then(addeddoc => {
          this.NewNote = ''
        })
      },
      CheckDateFilter(field){
        //console.log(field)
        //field.DateMenu = false
      },
      CheckTimelineFilter(field){
        if(field.TimelineFilter){
          field.DateFilter = ''
        }
      },
      PrepareDateFilterOptions(){			
          this.Today = format(new Date(),'yyyy-MM-dd')			
         this.ActualToday = new Date()			
         this.ActualToday.setHours(0,0,0,0)			
         this.ActualNow = new Date()			
        			
      },	
      EndofMonth(date){			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofQuarter(date){			
      //console.log(date)			
      var year = date.getFullYear();			
      var month = date.getMonth();			
      var day = date.getDate();			
      return this.EndofMonth(new Date(year, month+2, day));			
    },		
    Endof2Hours(date){			
      var hours = date.getHours()
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month + 1, 0)	
      d.setHours(hours,59,59,999)			
      return d			
    },
    EndofDay(date){			
      var day = date.getDate()			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var d = new Date(year, month, day)			
      d.setHours(23,59,59,999)			
      return d			
    },			
    EndofWeek(date){			
     			
      var month = date.getMonth()			
      var year = date.getFullYear()			
      var lastdaylastweek = date.getDate() - date.getDay();			
      let d = new Date(year, month, lastdaylastweek+7)			
      d.setHours(23,59,59,999)			
      return d			
    },	
      PrepareDatefilters(){        
        if(this.Label && this.Label.BoardFilterDates){
          this.LabelBoardFilterDates = this.Label.BoardFilterDates.map(field => {
            let fieldobj = this.CurrentEntity.AllFields.find(obj => obj.id === field)
            //console.log(field,fieldobj)
              if(fieldobj && typeof fieldobj.DateMenu === 'undefined'){
                fieldobj.DateMenu = false
              }
              return fieldobj
            }).filter(field => {
              return field && field.id
            })
            //console.log('LabelBoardFilterDates',this.LabelBoardFilterDates)
        }        
      },
      ActivateOwnerFilter(owner){
        this.Ownerfilter = owner
      },
        UpdateStatus(dragging){
            this.ItemOperationalDB.update({
            [this.Label.Name]: dragging[this.Label.Name]
            })
        },
        CancelReadingTaskDialog(){
            this.ToggleLiaisonDialog()
            this.dragging = ''
        },
        ToggleLiaisonDialog(){
      this.RecordLiaisonDialog = !this.RecordLiaisonDialog
    },
        ActivateProcessing(bool){
        this.$emit('ActivateProcessing',bool)
      },
        ActivateRecordLiaison(notice){
            this.dragging = notice
            this.RecordLiaisonDialog = true
        },
        StatusBGColor(status){
      if(status.Name === 'Open'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'In Progress'){
        return 'blue-grey lighten-4 mx-3'
      }
      else if(status.Name === 'On Hold'){
        return 'blue-grey lighten-5 mx-3'
      }
      else if(status.Name === 'Completed'){
        return 'blue-grey lighten-4 mx-3'
      }
      
    },
    StatusColor(status){
      return status.Color
      
    },
    ownerdragstart(owner){
        this.ownerdragging = owner
        //console.log(this.ownerdragging)
    },
    ownerdragFinish(item){     
      this.dragging = item   
      let ownerupdateobj = {
        Owner: {
          Full_Name: {},
          id: this.ownerdragging.id
        },
        Ownerid: this.ownerdragging.id        
      }
      let recordobj = Object.assign({},item)
      recordobj.Owner = ownerupdateobj.Owner
      recordobj.Ownerid = ownerupdateobj.Ownerid
       this.ItemOperationalDB.update(ownerupdateobj).then(updateddoc => {
        this.$emit('CreateLiaisonNotificationtoOwner',recordobj)
        this.dragging = ''
        this.ownerdragging = ''
       })
    },
    taskdragstart(task){
        this.dragging = task
        //console.log(this.dragging)
    },
    taskdragFinish(status){        
        this.UpdateBoardLabel(this.dragging,status)        
        //console.log(this.dragging)
    },
    UpdateBoardLabel(task,status){
        task.Status = status
        this.ItemOperationalDB.update({
            [this.Label.Name]: status
            })
            this.dragging = ''
    },
    Match(opt){    
          //console.log(opt,this.Label,this.DataFilteredbyNotes)   
            return this.DataFilteredbyNotes.filter(item => {    
                return item[this.Label.Name] && item[this.Label.Name].ID === opt.ID    
            }).map(item => {
                if(item.Owner){
                    item.Owner = this.UsersArray.find(obj => obj.id === item.Ownerid)
                }
                return item
            })
        },  
    DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
    TimestampFormatterSTRING(d){
       
        var months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
        return finaldate
      },
      TimestampFormatterNoticeBoard(d,prop){
       
        var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
      
        var hr = d.getHours();
       
        var min = d.getMinutes();
        if (min < 10) {
            min = "0" + min;
        }
        if(hr < 10){
          hr = "0"+hr
        }
        
        var date = d.getDate();
        var month = months[d.getMonth()];
        var year = d.getFullYear();
        // 2020-03-15 09:00'
        let finaldate = format(d,'yyyy-MM-dd')+' '+hr+ ":" + min;
        console.log(finaldate)
       if(prop === 'month'){
           return month;
       }
       else if(prop === 'date'){
           return date;
       }
       else if(prop === 'full'){
           return month+' '+date+', '+year
       }
        
      },
    }
}
</script>

<style>
.noteentry{
  transform: rotate(-2deg);
}
.noteentry:nth-child(){
  transform:rotate(3deg);
  position:relative;
  top:5px;
}
.noteentry:nth-child(3n){
  transform:rotate(-1deg);
  position:relative;
  top:-5px;
}
.noteentry:nth-child(5n){
  transform:rotate(2deg);
  position:relative;
  top:-10px;
}
</style>



