<template>
  <v-card flat tile class="tansparent" height="100%" width="100%">
      <v-card-title>
          {{ActiveForm.Name}}
      </v-card-title>
      <v-card-text v-html="ActiveForm.Output">
        
      </v-card-text>
      <v-card-actions>
            <v-row>
            <v-col cols="6" v-for="signature in ActiveForm.Signatures" :key="signature.itemObjKey">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-content @click="ActivateSignature('DisclosureAgreementReadonly','UserCanSignDisclosureAgreementClient','Anybody',signature,signature.IMG)">
                            {{signature.Name}}
                        </v-list-item-content>
                        <v-list-item-avatar tile size="120" style="border-bottom: 0.5px solid grey;">
                            <v-img v-if="signature.IMG"
                            height="120" contain
                            :src="signature.IMG"
                            />
                        </v-list-item-avatar>
                    </v-list-item>        
                    </v-list>
                </v-col>
            </v-row>
      </v-card-actions>
      <v-card-actions v-if="!ActiveForm.readonly">
                <v-spacer></v-spacer>
                <v-btn @click="SubmitForm()">
                    Submit</v-btn>
            </v-card-actions>
            <v-card-actions v-if="ActiveForm.readonly">
                <v-spacer></v-spacer>
                <v-btn @click="FileForm()">
                    File</v-btn>
            </v-card-actions>
            <v-dialog v-model="SignaturePadDialog" width=400  height=100%>
     
      <v-card width=400  height=100% v-if="!FormisReadonly && UserCanSign" >
      
      <canvas id="signature-pad" class="signature-pad" width=400 height=200></canvas>
      <v-btn @click="ClearSignature()">Clear</v-btn>
      <v-btn @click="SaveSignature()">Save</v-btn>
      </v-card>
      <v-card width=400  height=100% v-if="FormisReadonly && UserCanSign || !UserCanSign">
          <v-img
            v-if="ActiveSignatureLocalIMG"
            contain
            :src="ActiveSignatureLocalIMG"
            />
            <v-img
            v-if="!ActiveSignatureLocalIMG"
            height="80"
            contain
            src="@/assets/access_denied.png"
            />
            <v-card-text>
                You cannot Sign here, only {{Signator}} can Sign here
            </v-card-text>
      </v-card>
  </v-dialog>
  </v-card>
</template>

<script>
import SignaturePad from 'signature_pad'

export default {
    props: ['userLoggedIn','ActiveForm','comment'],
    components:{
        SignaturePad,
    },
    data(){
        return {
            DisclosureAgreementReadonly: false,
            UserCanSignDisclosureAgreementClient: true,
            DisclosureAgreementParagraphTab0Section0Content0ClientSignaturePad: '',
            DisclosureAgreementParagraphTab0Section0Content0ClientSignaturePadImage: '',
            Client: '',
            ActiveSignature: {},
            ActiveSignatureLocalIMG: '',
            SignaturePadDialog: false,
            FormisReadonly: false,
            UserCanSign: false,
            ViewFormsDialog: false,
            Signator: '',
            ActiveSignaturePad: '',
        }
    },
    computed: {

    },
    created(){

    },
    methods:{
        FileForm(){
            this.$emit('SubmitForm',this.ActiveForm,this.comment,true)
        },
        SubmitForm(){
            this.$emit('SubmitForm',this.ActiveForm,this.comment)
        },
        ActivateSignature(readonlyprop,usercansignprop,signatorprop,active,localimg){
        console.log(readonlyprop,this[readonlyprop])
          if(!this[usercansignprop]){
              this.UserCanSign = false
              if(signatorprop !== 'Anybody'){
                if(this.VehicleFormData[signatorprop]){
                  this.Signator = this.VehicleFormData[signatorprop].Full_Name
                }
                else{
                  this.Signator = 'Unknown'
                }
                  
              }
              else{
                  this.Signator = 'Anybody'
              }
          }
          else{
            //alert('hey man')
              this.UserCanSign = true
          }
          if(this[readonlyprop]){
            this.ActiveSignature = {}
            this.ActiveSignatureLocalIMG = this[localimg]
            this.SignaturePadDialog = true
            this.FormisReadonly = true
          }
          else if(!this[usercansignprop]){
            this.ActiveSignature = {}
            this.ActiveSignatureLocalIMG = this[localimg]
            this.SignaturePadDialog = true
            this.FormisReadonly = false
          }
          else{
            this.FormisReadonly = false
            this.ActiveSignature = {}
            this.ActiveSignatureLocalIMG = ''
            this.ActiveSignature = active
            this.ActiveSignatureLocalIMG = localimg
            this.SignaturePadDialog = true
            console.log(this.SignaturePadDialog)
            setTimeout(() => {
            var canvas = document.getElementById('signature-pad');          
                    if(!this.ActiveSignaturePad){
                        this.ActiveSignaturePad = new SignaturePad(canvas, {
                            backgroundColor: 'rgb(255, 255, 255)' // necessary for saving image as JPEG; can be removed is only saving as PNG or SVG
                            });   
                        } 
            }, 1000);
          }
      },
      SaveSignature(){
        if (this.ActiveSignaturePad.isEmpty()) {
            return alert("Please provide a signature first.");
        }  
        else{
         this.ActiveSignature.IMG = this.ActiveSignaturePad.toDataURL('image/png');   
        }      
        
      },
      ClearSignature(){     
        this.ActiveSignaturePad.clear();
      },
    }
}
</script>

<style>

</style>

    