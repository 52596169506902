
<template>
<v-card class="transparent" flat width="100%" height="100%">

    <v-parallax
            height="150"
            src="@/assets/RABaseBG.jpeg"
            >
              
              <v-row
              align="center"
              justify="center"
            >
            
              <v-col class="parallaxbgcontentgrad text-center" cols="12">
                <h1 class="display-2 font-weight-thin mb-4">{{RouteName}}</h1>
                <h4 class="headline">New Meetup</h4>
              </v-col>
            </v-row>
            </v-parallax>             
    <v-snackbar v-model="snackbar" :timeout="4000" top color="success">
      <span>Awesome! You updated the Meetup.</span>
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-layout justify-center>
    <v-flex xs12 s12 m10 lg10>
    <v-card-title class="title font-weight-regular justify-space-between">
          <span>New Meetup</span>
          <v-avatar
            size="45"
          >
          <v-img  v-if="UserRecord.Profile_Photo" :src="UserRecord.Profile_Photo">
          </v-img>
          <v-img v-if="!UserRecord.Profile_Photo" src="@/assets/BlankProfilePic.png">
          </v-img>
          </v-avatar>
        </v-card-title>
        <v-card-subtitle>
          {{UserRecord.Full_Name}}
        </v-card-subtitle>
        <v-layout class="justify-center" style="padding-bottom:50px;">
        <v-card flat outlined light width="90%" class="my-3">
            <v-card-text>
                <v-text-field label="Title" v-model="MeetupTitle">
                </v-text-field>  
                <v-combobox return-object item-text="Name" v-if="!GroupData"
                  chips v-model="Category" :items="MeetupsCategories" label="Category" >
                </v-combobox>
                 <v-switch v-if="!userLoggedIn.IsGuestUser && System.Guests_can_Social || System.Guests_can_Social && GroupData" v-model="GuestsIncluded" :label="GroupData ? 'Include Non Team Members' : 'Include Guest Users'"/>
                <v-list-item>
                  <v-checkbox label="Is Recurring" v-model="Recurring"/>                    
                </v-list-item>
                <v-list-item v-if="Recurring">
                  <v-select :items="['Every Day','Every Week','Every Month']" v-model="RecurranceFrequency" label="Frequency"/>             
                </v-list-item>
                <v-list-item v-if="Recurring && RecurranceFrequency === 'Every Day'">
                  
                  <v-list-item-content>
                    <v-menu
                      v-model="EventStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px" 
                      id="EventStartTimeMenu"
                    
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="EventStartTime"
                          label="Start Time"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          id="EventStartTime"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-time-picker
                      dense
                        v-model="EventStartTime"
                        @input="EventStartTimeMenu = false"
                        type="month"
                        width="290"
                        class="ml-4"
                      ></v-time-picker>
                    </v-menu>            
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventStartTime"
                  v-model="EventEndTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px" 
                  id="EventEndTimeMenu"
                  
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EventEndTime"
                      label="End Time"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-on="on"
                      id="EventEndTime"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="EventEndTime"
                    @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                    type="month"
                    width="290"
                    class="ml-4"
                    dense
                  :allowed-minutes="AllowedEndTimeMinutes"
                  :allowed-hours="AllowedEndTimeHours"
                  ></v-time-picker>
                </v-menu>           
                                  
                      </v-list-item-content>      
                </v-list-item>
                <v-list-item v-if="Recurring && RecurranceFrequency === 'Every Week'">
                  <v-select multiple :items="['Monday','Tuesday','Wednesday','Thursday','Friday','Staurday','Sunday']" v-model="RecurrancePattern" label="Days of Week"/>
                  <v-list-item-content>
                    <v-menu
                      v-model="EventStartTimeMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px" 
                      id="EventStartTimeMenu"
                    
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="EventStartTime"
                          label="Start Time"
                          prepend-icon="mdi-calendar-month"
                          readonly
                          v-on="on"
                          id="EventStartTime"
                          clearable
                        ></v-text-field>
                      </template>
                      <v-time-picker
                      dense
                        v-model="EventStartTime"
                        @input="EventStartTimeMenu = false"
                        type="month"
                        width="290"
                        class="ml-4"
                      ></v-time-picker>
                    </v-menu>            
              </v-list-item-content>
              <v-list-item-content>  
                <v-menu  v-if="EventStartTime"
                  v-model="EventEndTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px" 
                  id="EventEndTimeMenu"
                  
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EventEndTime"
                      label="End Time"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-on="on"
                      id="EventEndTime"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="EventEndTime"
                    @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                    type="month"
                    width="290"
                    class="ml-4"
                    dense
                  :allowed-minutes="AllowedEndTimeMinutes"
                  :allowed-hours="AllowedEndTimeHours"
                  ></v-time-picker>
                </v-menu>           
                                  
                      </v-list-item-content>          
                </v-list-item>
                <v-list-item v-if="Recurring && RecurranceFrequency === 'Every Month'">
                
                  <v-select :items="['Every First','Every Last','Day of Month']" v-model="RecurranceMonthlyPattern" label="Days of Month"/>
                  <v-select v-if="RecurranceMonthlyPattern" :items="RecurranceMonthlyPatternOptions" v-model="RecurrancePattern" :label="RecurranceMonthlyPattern"/>
   
                </v-list-item>
                
                <v-list-item >
                 <v-text-field type="number" v-model.number="CheckAheadMinutes" label="Check Ahead Minutes"/>         
                </v-list-item>
                <v-list-item v-if="Recurring">
                 <v-select :items="AdvanceOptions" v-model="AdvanceInteger" :label="AdvanceIntegerLabel"/>         
                </v-list-item>
                <v-list-item v-if="AdvanceInteger">
                  <v-list-item-content>
                 <v-menu
                    v-model="EventstartdateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px" 
                    id="EventStartDatemenu"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="EventStartDate"
                        label="Start Date"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        id="EventStartDate"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
                  </v-menu>  
                  </v-list-item-content>    
                  <v-list-item-content> 
                <v-menu v-if="EventStartDate"
                  v-model="EventStartTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px" 
                  id="EventStartTimeMenu"
                
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="EventStartTime"
                      label="Start Time"
                      prepend-icon="mdi-calendar-month"
                      readonly
                      v-on="on"
                      id="EventStartTime"
                      clearable
                    ></v-text-field>
                  </template>
                  <v-time-picker
                  dense
                    v-model="EventStartTime"
                    @input="EventStartTimeMenu = false"
                    type="month"
                    width="290"
                    class="ml-4"
                  ></v-time-picker>
                </v-menu>            
              </v-list-item-content>      
                </v-list-item>
                <v-list-item v-if="!Recurring">
              <v-list-item-content>
                <v-menu
          v-model="EventstartdateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartDatemenu"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartDate"
              label="Start Date"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartDate"
              clearable
            ></v-text-field>
          </template>
          <v-date-picker dense v-model="EventStartDate" @input="EventstartdateMenu = false,AssignEventEndDate()"></v-date-picker>
        </v-menu>             
              </v-list-item-content>
              <v-list-item-content> 
                <v-menu v-if="EventStartDate"
          v-model="EventStartTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px" 
          id="EventStartTimeMenu"
        
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="EventStartTime"
              label="Start Time"
              prepend-icon="mdi-calendar-month"
              readonly
              v-on="on"
              id="EventStartTime"
              clearable
            ></v-text-field>
          </template>
          <v-time-picker
          dense
            v-model="EventStartTime"
             @input="EventStartTimeMenu = false"
            type="month"
            width="290"
            class="ml-4"
          ></v-time-picker>
        </v-menu>            
              </v-list-item-content>
              <v-list-item-content>
                <v-checkbox dense v-model="AllDayEvent" label="All day Event"></v-checkbox>
                    </v-list-item-content>
              </v-list-item>
              <v-list-item>
              <v-list-item-content>  
                <v-menu
                  v-if="EventStartDate"
                    v-model="EventenddateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px" 
                    id="EventEndDatemenu"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="EventEndDate"
                        label="End Date"
                        prepend-icon="mdi-calendar-month"
                        readonly
                        v-on="on"
                        id="EventEndDate"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker dense v-model="EventEndDate" @input="EventenddateMenu = false"></v-date-picker>
                  </v-menu>           
              </v-list-item-content>
              <v-list-item-content>  
                      <v-menu  v-if="EventEndDate && EventStartDate && EventStartTime"
                        v-model="EventEndTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="290px" 
                        id="EventEndTimeMenu"
                        
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            v-model="EventEndTime"
                            label="End Time"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            v-on="on"
                            id="EventEndTime"
                            clearable
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-model="EventEndTime"
                          @input="EventEndTimeMenu = false,CheckAllowedMinutes()"
                          type="month"
                          width="290"
                          class="ml-4"
                          dense
                        :allowed-minutes="AllowedEndTimeMinutes"
                        :allowed-hours="AllowedEndTimeHours"
                        ></v-time-picker>
                      </v-menu>
                    </v-list-item-content>
                  </v-list-item>
                <v-checkbox label="Is Image Shy" v-model="ImageShy"/>
                <input
                v-if="!ImageShy"
            type="file"
            @change="onmeetupcoverimageselect($event)"
            ref="coverimageinputter">
           
                </v-card-text>
                <v-layout class="justify-center">
                
          <v-img v-if="coverimagelocalurl" :src="coverimagelocalurl">
          </v-img>
                </v-layout>
          <v-card-text>
              <ContentEditable @ActivateTagLinkDialog="ActivateTagLinkDialog"/>
            </v-card-text>
            
            <v-card-actions>                
                <v-select multiple :items="SiteModerators" item-text="Full_Name" v-model="Moderators" return-object label="Select Moderators"/>                
            </v-card-actions>
            <v-card-actions>
                
                <v-chip small color="secondary" class="white--text">
                    {{author}}
                  </v-chip>
                  <v-spacer></v-spacer>
                <v-btn outlined color="orange">
                    Cancel
                </v-btn>
                <v-btn outlined color="green" @click="StoreMeetup()">
                    Post
                </v-btn>
                
            </v-card-actions>
        </v-card>
        </v-layout>
   
    
   </v-flex>
</v-layout>
</v-card>
</template>


<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import axios from 'axios'
import ContentEditable from '@/components/SocialNetwork/ContentEditable';
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'

export default {
  props: ['userLoggedIn','System'],
        middleware: 'auth',
      components: {
        ContentEditable
    // quillEditor

        
  },
  
    data() {
        return {    
          Category: '',
          ItemCategory: '',      
          GuestsIncluded: false,
          AdvanceInteger: '',
          RecurringTemplate: '',
          Recurring: false,
          RecurranceFrequency: '',
          RecurranceMonthlyPattern: '',
          RecurrancePattern: '',
          EventenddateMenu: false,
          EventEndDate: '',
          EventStartTime: '',
          EventStartTimeMenu: false,
          EventstartdateMenu: false,
          AllDayEvent: false,
          EventEndTime: '',
          EventEndTimeMenu: false,
          EventStartDate: '',
          CheckAheadMinutes: 30,
          TagLinkDialog: false,
          UsersArray: [],
          SiteModerators: [],
          Moderators: [],          
          MeetupTitle: '',
          ImageShy: false,
            GroupData: '',
        selectedFile: '',
        ImagelocalURL: '',
        MeetupContent: '',
        meetupquil: false,
        snackbar: false,
            // content: '<h2>I am Example</h2>',
        editorOption: {
          // some quill options
        },
        meetupdialog: false,
                    inputRules: [
            v => !!v || 'This field is required',
            v => v.length >= 3 || 'Minimum length is 3 characters'
      ],
      UserRecord: {},
      meetuptitle: null,
        meetupcontent: '',
        author: '',
        authorid: '',
        // userid: this.$store.state.user.id,
        groupmeetups: [],
        groups: [],
        meetupid: this.$route.params.id,
        content: null,
        selectedcoverimage: '',
        coverimagelocalurl: '',
        image: '',
        Youtubelink: '',
        rules: {
        youtubeurl: value => {
            const pattern = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
            return pattern.test(value) || "Not a Youtube Video Link.";
          },
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          url: value => {
            const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); 
            return urlpattern.test(value) || "Invalid Link.";
          }
        },
       
            editedItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            defaultItem: {
                Name: null,
                description: null,
                url: null,
                logo: null,                
            },
            MeetupsCategories: []    
        }
    },
    computed: {
      
      RecurranceMonthlyPatternOptions(){
        let daysofweek = ['Monday','Tuesday','Wednesday','Thursday','Friday','Staurday','Sunday']
        let daysinmonth = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27]
        if(this.RecurranceMonthlyPattern){
          if(this.RecurranceMonthlyPattern === 'Every First'){
            return daysofweek
          }
          else if(this.RecurranceMonthlyPattern === 'Every Last'){
            return daysofweek
          }
          else if(this.RecurranceMonthlyPattern === 'Day of Month'){
            return daysinmonth
          }
        }
        
      },
      AdvanceIntegerLabel(){
        if(this.Recurring && this.RecurranceFrequency){
          if(this.RecurranceFrequency === 'Every Day'){
            return 'Days in Advance'
          }
          else if(this.RecurranceFrequency === 'Every Week'){
            return 'Weeks in Advance'
          }
          else if(this.RecurranceFrequency === 'Every Month'){
            return 'Months in Advance'
          }

        }
      },
      AdvanceOptions(){
        if(this.Recurring && this.RecurranceFrequency){
          if(this.RecurranceFrequency === 'Every Day'){
            return [
              7,14
            ]
          }
          else if(this.RecurranceFrequency === 'Every Week'){
            return [
              2,4
            ]
          }
          else if(this.RecurranceFrequency === 'Every Month'){
            return [
              1,2,3
            ]
          }

        }
      },
      AllowedEndTimeHours(){

        let Hours = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23]
      return Hours.filter(hr => {
        if(this.EventStartTime){
          return hr >= Number(this.EventStartTime.split(':')[0])
        }
        else{
          return []
        }
        })
       console.log(this.AllowedEndTimeHours)
      },
      AllowedEndTimeMinutes(){
        let Minutes = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59]
        let starttimehour = Number(this.EventStartTime.split(':')[0])
        let endtimehour = Number(this.EventEndTime.split(':')[0])  
        console.log(starttimehour,endtimehour)        
        return Minutes.filter(min => {
          if(this.EventStartTime && this.EventEndTime){
            
            if(starttimehour === endtimehour){
              return min >= Number(this.EventStartTime.split(':')[1])
            }
            else{
              return Minutes
            }
          }
          else{
            return Minutes
          }
        })
      },
      UserIsModerator(){
          if(this.$route.name === 'GroupNewMeetup'){
             let memberobj =  this.userLoggedIn.groups.find(obj => obj.id === this.$route.params.id)
             return memberobj.Administrator || memberobj.MeetupsModerator
          }
          else{
              let moderator = this.userLoggedIn.ModerationPermissions.find(obj => obj === 'Meetups')
              if(moderator){
                return true
              }
              else{
                return false
              }
          }
        },
        NonModeratorRoute(){
          if(this.$route.name === 'GroupNewMeetup'){
            return '/Group/'+this.$route.params.id
          }
          else{
            return '/Meetups'
          }
        },
        RouteName(){
          return this.$route.params.id ? this.GroupData.name+' Meetup' : 'Ignite Youth'
        },
        ModeratorsIDStrings(){
          return this.Moderators.map(mod => {
            mod.id
          })
        },
        ComputedYoutubelinkEmbed(){
            if(this.Youtubelink.includes('iframe')){
                console.log('includes frame')
                return this.Youtubelink
            }
            else if(this.Youtubelink.includes('embed')){
                return `<iframe width="560" height="315" src="`+this.Youtubelink+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
            else{
                return `<iframe width="560" height="315" src="`+'https://www.youtube.com/embed/' + this.Youtubelink.split('watch?v=')[1].split('&')[0]+`" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`
            }
      },
        RelatedMeetups () {
            return this.groupmeetups.filter(meetup => {
                return meetup.meetupid === this.meetupid
            })
        },
      //         editor() {
      //   return this.$refs.myQuillEditor.quill
      // }
    },
    
    created() {
      if(this.$route.name !== 'GroupNewMeetup'){
        this.MeetupsCategories = this.System.MeetupsCategories
      }
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        if(this.UserIsModerator){
           if(this.System.Social_Content_for_Guests || this.userLoggedIn.IsGuestUser){
            this.GuestsIncluded = true
          }
            this.FocusedViewToggle()
            this.IntranetViewToggle()
            this.GetRequestingUser()
            let moderatorsquery = db.collection('sitemoderators')
            if(!this.$route.params.id){
              this.GetSiteModerators(moderatorsquery)
            }
            else{
              this.GetGroup()
            }
        }
        else{
          this.$router.push(this.NonModeratorRoute)
        }

    },
    
    methods: {
      AssignEventEndDate(){
        console.log('assigning')
        this.EventEndDate = Object.assign(this.EventStartDate)
      },
      CheckAllowedMinutes(){       
        if(Number(this.EventStartTime.split(':')[0]) === Number(this.EventEndTime.split(':')[0]) && Number(this.EventStartTime.split(':')[1]) >= Number(this.EventEndTime.split(':')[1])){
          this.EventEndTime = ''
          alert('You have to choose an ending time that is LATER than the Starting time')
        }
      },
       GetGroup(){
            db.collection('groups').doc(this.$route.params.id).onSnapshot(snapshot => {
                this.GroupData = snapshot.data()
                db.collection('groups').doc(this.$route.params.id).collection('MeetupsCategories').onSnapshot(res => {
                  const changes = res.docChanges();
                    changes.forEach(change => {
                      if (change.type === 'added') {
                        this.MeetupsCategories.push({
                          ...change.doc.data(),
                          id: change.doc.id
                        })
                      }
                    })
                })
                //let moderatorsquery = db.collection('groupmembers')
                let Moderator = {
                  Full_Name: this.userLoggedIn.Full_Name,
                  Name: this.userLoggedIn.Name,
                  Surname: this.userLoggedIn.Surname,
                  ModerationPermissions: this.userLoggedIn.ModerationPermissions,
                  id: this.userLoggedIn.id
                }
                this.Moderators.push(Moderator)
                this.SiteModerators.push(Moderator)
            })
        },
      ActivateTagLinkDialog(boolean){
        this.TagLinkDialog = boolean  
      },
      taglink(){
        this.TagLinkDialog = true
      },
      SaveSingleTag(tagitem){
        console.log(tagitem)
        this.TagLinkDialog = false  
        document.execCommand("createLink", false, tagitem.taglocation);
      },
      GetSiteModerators(query){
        let Moderator = {
          Full_Name: this.userLoggedIn.Full_Name,
          Name: this.userLoggedIn.Name,
          Surname: this.userLoggedIn.Surname,
          ModerationPermissions: this.userLoggedIn.ModerationPermissions,
          id: this.userLoggedIn.id
        }
        this.Moderators.push(Moderator)
        query.onSnapshot(res => {
          const changes = res.docChanges();
            changes.forEach(change => {
              if (change.type === 'added') {
                this.SiteModerators.push({
                  ...change.doc.data(),
                  id: change.doc.id
                })
              }
            })
          
        })
      },
   DateFormatter(date){
      if(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
        }
      else{
        return null
      }
   },
        onmeetupcoverimageselect(event) {
      this.selectedcoverimage = event.target.files[0]
      this.coverimagelocalurl = URL.createObjectURL(this.selectedcoverimage)
    },
        
        StoreMeetup(){
          let vm = this
          let col = ''
          let frequentedpath = 'frequentedsitemeetups'
          if(this.$route.params.id){
            frequentedpath = 'frequentedsitemeetups'
          }
          if(vm.Category && !vm.Category.ID){
            let length = vm.MeetupsCategories.length
            let int = 1000001+length
            let newcat = {
              ID: int,
              Name: vm.Category
            }
            vm.ItemCategory = newcat
            let catcollection = ''
            if(this.$route.name === 'GroupNewMeetup'){
              catcollection = db.collection('groups').doc(this.$route.params.id).collection('MeetupsCategories')
            }
            else{
              catcollection = db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('MeetupsCategories')
            }
            catcollection.add(newcat)
          }
          else if(vm.Category && vm.Category.ID){
            vm.ItemCategory = vm.Category
          }
          let routepath = ''
          let routeprop = ''
          let storagepath = ''
          if(this.$route.params.id){
            col = 'groupmeetups'
            routepath = '/Group-Meetup/'+this.$route.params.id+'/Meetup/'
            routeprop = 'id'
          }
          else{
            col = 'sitemeetups'
            routepath = '/Meetup/'
            routeprop = 'id'
          }
          let ref = db.collection(col).doc()
          let newdocid = ref.id
          vm.$emit('ActivateProcessing',true)
        let postelmnt = document.getElementById('NewBlogValue')
        console.log(postelmnt)
        let postinput = postelmnt.innerHTML
        if(this.selectedcoverimage && this.MeetupTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
          let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
          let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
        
        let NewMeetup = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.MeetupTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          Comments: [],
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          CheckAheadMinutes: this.CheckAheadMinutes,
          GuestsIncluded: this.GuestsIncluded
        }
        if(vm.ItemCategory){
          NewMeetup.Category = vm.ItemCategory
        }
        if(this.Recurring){
          NewMeetup.Recurring = this.Recurring
          NewMeetup.RecurranceFrequency = this.RecurranceFrequency
          if(this.RecurranceFrequency === 'Every Month'){
            NewMeetup.RecurranceMonthlyPattern = this.RecurranceMonthlyPattern
          }
          NewMeetup.RecurrancePattern = this.RecurrancePattern
          NewMeetup.AdvanceInteger = this.AdvanceInteger
          //Now also work out the first date
          let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
          let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
          NewMeetup.start = NewJavaStartDate
          NewMeetup.end = NewJavaEndDate
          
        }
        if(this.$route.params.id){
          NewMeetup.groupid = this.$route.params.id
        }
         if(vm.userLoggedIn && vm.userLoggedIn.Company){
          NewMeetup.Company = vm.userLoggedIn.Company
          NewMeetup.Companyid = vm.userLoggedIn.Companyid
        }
        let titleQuery = []
        let titleStringArray = NewMeetup.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                if(p === titleStringArray.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery = titleQuery.concat(NewMeetup.title.split(' ')) 
          console.log(titleQuery)
          NewMeetup.titleQuery = titleQuery.map(entry => {
                          return entry.toLowerCase()
                        })
        
        if(this.UserRecord.Profile_Photo){
          NewMeetup.creatorimg = this.UserRecord.Profile_Photo
        }
        let filename = this.selectedcoverimage.name.split('.')[0]+'_'+this.selectedcoverimage.size+'.'+this.selectedcoverimage.name.split('.')[1]
        if(this.$route.params.id){
            storagepath = 'GroupMeetups/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+filename
          }
          else{
            storagepath = 'SiteMeetups/'+this.$route.params.id+'/CoverImage/'+new Date()+'/'+filename
          }
        
        this.UpdateMeetupCoverImage(this.selectedcoverimage,storagepath).then(function(result) {
            if(result){
                NewMeetup.coverimage = result
                NewMeetup.CoverStorageRef = storagepath
                 
                vm.$emit('ActivateProcessing',false)
                if(vm.Recurring){
                   if(vm.$route.params.id){
                     
                   }
                   else{
                     NewMeetup.SiteMeetup = true
                   }
                  db.collection(frequentedpath).add(NewMeetup).then(recdoc => {
                    NewMeetup.title = NewMeetup.title+' '+vm.EventStartDate
                    vm.RecurringTemplate = recdoc.id
                    console.log('created new frequented meetup on '+recdoc.id)
                    NewMeetup.RecurringTemplate = recdoc.id
                    db.collection(col).add(NewMeetup).then(doc => {
                      db.collection(frequentedpath).doc(recdoc.id).update({
                        LastMeetupID: doc.id
                      }).then(updatedtemplate => {
                        NewMeetup.id = doc.id
                        vm.$router.push(routepath+NewMeetup[routeprop])
                      })
                        
                    })
                    console.log(NewMeetup.post)
                    vm.CancelNewMeetupDialog()
                  })
                }
                else{
                  db.collection(col).add(NewMeetup).then(doc => {
                      NewMeetup.id = doc.id
                        vm.$router.push(routepath+NewMeetup[routeprop])
                  })
                  console.log(NewMeetup.post)
                  vm.CancelNewMeetupDialog()
                }
               
            }          
          })  
        }
        else if(this.ImageShy && this.MeetupTitle && postinput){
          let ModeratorRolesObject = {}
          let ModeratorRolesArray= []
          this.Moderators.map(mod => {
            ModeratorRolesObject[mod.id] = true
            ModeratorRolesArray.push(mod.id)
          })
          let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
          let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
        let NewMeetup = {
          Likes: [],
          Shares: [],
          tags: [],
          title: this.MeetupTitle,
          Creator: {Full_Name: this.UserRecord.Full_Name,id: this.UserRecord.id},          
          Creatorid: this.UserRecord.id,
          author: this.UserRecord.Full_Name,
          authorid: this.UserRecord.id,
          description: postinput,
          createdon: new Date(),
          start: NewJavaStartDate,
          end: NewJavaEndDate,
          Comments: [],
          ImageShy: this.ImageShy,
          PublishStatus: 'Draft',
          Moderators: this.Moderators,
          moderatorrolesarrayDBRules: ModeratorRolesObject,
          moderatorrolesarrayQuery: ModeratorRolesArray,
          GuestsIncluded: this.GuestsIncluded
        }
        if(vm.ItemCategory){
          NewMeetup.Category = vm.ItemCategory
        }
        if(this.$route.params.id){
          NewMeetup.groupid = this.$route.params.id
        }
        if(vm.userLoggedIn && vm.userLoggedIn.Company){
          NewMeetup.Company = vm.userLoggedIn.Company
          NewMeetup.Companyid = vm.userLoggedIn.Companyid
        }
        let titleQuery = []
        let titleStringArray = NewMeetup.title.split('')
        var lowertext = "";                
        var p;
          let buildup = ''
          console.log(titleStringArray.length,titleStringArray)
          for (p = 0; p < titleStringArray.length; p++) {
              buildup = buildup+ titleStringArray[p]
                if(p === titleStringArray.length-1){
                  lowertext += buildup.toLowerCase();
                }
                else{
                  lowertext += buildup.toLowerCase() + ",";
                }
                }                   
          titleQuery = lowertext.split(',')  
          titleQuery = titleQuery.concat(NewMeetup.title.split(' ')) 
          console.log(titleQuery)
          NewMeetup.titleQuery = titleQuery.map(entry => {
                          return entry.toLowerCase()
                        })
        
        if(this.UserRecord.Profile_Photo){
          NewMeetup.creatorimg = this.UserRecord.Profile_Photo
        }
        if(this.Recurring){
          NewMeetup.Recurring = this.Recurring
          NewMeetup.RecurranceFrequency = this.RecurranceFrequency
          if(this.RecurranceFrequency === 'Every Month'){
            NewMeetup.RecurranceMonthlyPattern = this.RecurranceMonthlyPattern
          }
          NewMeetup.RecurrancePattern = this.RecurrancePattern
          NewMeetup.AdvanceInteger = this.AdvanceInteger
          //Now also work out the first date
          let NewStartDateString = this.EventStartDate+' '+this.EventStartTime
          let NewEndDateString = this.EventEndDate+' '+this.EventEndTime
          let NewJavaStartDate = new Date(NewStartDateString)
          let NewJavaEndDate = new Date(NewEndDateString)
          NewMeetup.start = NewJavaStartDate
          NewMeetup.end = NewJavaEndDate
        } 
        console.log(NewMeetup)
          if(this.Recurring){
            if(this.$route.params.id){
              
            }
            else{
              NewMeetup.SiteMeetup = true
            }
            db.collection(frequentedpath).add(NewMeetup).then(recdoc => {
              vm.RecurringTemplate = recdoc.id
              console.log('created new frequented meetup on '+recdoc.id)
              NewMeetup.RecurringTemplate = recdoc.id
              NewMeetup.title = NewMeetup.title+' '+this.EventStartDate
              db.collection(col).add(NewMeetup).then(doc => {
                db.collection(frequentedpath).doc(recdoc.id).update({
                  LastMeetupID: doc.id
                }).then(updatedtemplate => {
                  NewMeetup.id = doc.id
                  vm.$router.push(routepath+NewMeetup[routeprop])
                })
                  
              })
              console.log(NewMeetup.post)
              vm.CancelNewMeetupDialog()
            })
          }
          else{
            db.collection(col).add(NewMeetup).then(doc => {
                NewMeetup.id = doc.id
                  vm.$router.push(routepath+NewMeetup[routeprop])
            })
            console.log(NewMeetup.post)
            vm.CancelNewMeetupDialog()
          }
               console.log(NewMeetup.post)          
        }
        else if(!this.selectedcoverimage && this.MeetupTitle && postinput){
            alert('You have to upload an image')
        }
        else if(!this.selectedcoverimage && !this.MeetupTitle && postinput){
            alert('You have to upload an image, and insert a title')
        }
        else if(!this.selectedcoverimage && !this.MeetupTitle && !postinput){
            alert('There is nothing to post!')
        }
        else if(!this.selectedcoverimage && this.MeetupTitle && !postinput){
            alert('There is no cover image, or post content!')
        }
        else if(this.selectedcoverimage && !this.MeetupTitle && postinput){
            alert('You have to insert a title')
        }
        else if(this.selectedcoverimage && !this.MeetupTitle && !postinput){
            alert('You have to insert a title, and content')
        }
        else if(this.selectedcoverimage && this.MeetupTitle && !postinput){
            alert('You have to insert content')
        }
        
      
        },


        UpdateMeetupCoverImage(picturefile,storagepath){
          let vm = this
          return new Promise(function(resolve, reject) {
          var storageRef = firebase.storage().ref(storagepath);
          var uploadTask = storageRef.put(picturefile);
          uploadTask
          .then(snapshot => snapshot.ref.getDownloadURL())
            .then((url) => {
              
              
              resolve(url)
                  })
                    
          })
        },
        CancelNewMeetupDialog(){
            this.MeetupTitle = ''
            this.selectedcoverimage = ''
        },
     
      async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {

            db.collection('users').doc(user.uid).onSnapshot(snapshot => {
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
              vm.authorid = userdetails.id

              vm.author = userdetails.Name+' '+userdetails.Surname
              
              console.log('this is user name man '+vm.author)
            })
        }
      })
      },
      IntranetViewToggle(){
        this.$emit('IntranetViewToggle',true)
      },
      FocusedViewToggle(){
        this.$emit('FocusedViewToggle',false)
      },
            getUserInformation() {
                db.collection('users').doc(this.UserRecord.id).onSnapshot(snapshot => {
          // this.UpdateRequestStatus()
          var userdata = snapshot.data()
        this.authorid = userdata.id
      
        this.author = userdata.Name+' '+userdata.Surname
console.log('this is user name man '+this.author)
      

        })
            },
        
      
          
      }
      
    }
// }
</script>
<style>
.tabsinner {
    background-color: #f8f8f8;
    color: #575757;
}
.Grouptitle {
color: #048abf;
margin-left: 0.5em
}
.Groupdescription {
color: #70cbf3;

}
.Image {

margin-left: 0.5em
}
h2{
  color: 'primary';
}
    
</style>