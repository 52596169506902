<template>
<v-list class="transparent" dense style="font-size:14px;" v-if="navitem.Field">
      <v-subheader class="overline"> {{navitem.Title}} <v-icon  v-if="$route.name === 'DirectoryBuilder'" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon></v-subheader>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
           v-for="opt in navitem.Field.Options" :key="opt.itemObjKey" @click="ActivateFilter(navitem,opt)"
        >
         
          <v-list-item-content>
            <v-list-item-title style="font-size:12px;font-weight:400;" v-text="opt.Name"></v-list-item-title>
          </v-list-item-content>
           <v-list-item-icon v-if="navitem.ActiveFilter && navitem.ActiveFilter.ID === opt.ID">
            <v-icon >mdi-close</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!-- <v-list class="transparent" dense style="font-size:14px;" v-if="navitem.Field">                                          
        <v-list-item  class="overline">
        {{navitem.Title}} <v-icon  v-if="$route.name === 'DirectoryBuilder'" @click="EditNavItem(navitem,navitemindex)">mdi-cogs</v-icon>
        <v-icon v-if="ActiveBrandFilter" @click="ActiveBrandFilter = ''">mdi-close</v-icon>
        </v-list-item>
            <v-list class="transparent" dense style="font-size:12px;">
            <v-list-item v-for="opt in navitem.Field.Options" :key="opt.itemObjKey" @click="ActivateFilter(navitem,opt)">
                {{opt.Name}}
            </v-list-item>
            </v-list>
    </v-list> -->
</template>

<script>
export default {
    props: ['navitem','navitemindex'],
    components: {

    },
    data(){
        return {

        }
    },
    created(){

    },
    computed:{

    },
    methods: {
        EditNavItem(navitem,navitemindex){
            this.$emit('EditNavItem',navitem,navitemindex)
        },
        ActivateFilter(navitem,opt){
            
            this.$emit('ActivateFilter',navitem,'Single Option Filter',opt)
        }
    }
}
</script>

<style>

</style>