<template>
   <v-card flat tile class="background">
        <v-card-subtitle class="background darken-1 mediumoverline">
              {{Name}}
          </v-card-subtitle>
          <v-card-text class="justify-center">
          <v-select v-model="EditedTab.TextBoxFlexAlign" label="Align Flex" :items="['center','flex-start','flex-end']"/>		
          <!-- <v-list-item > -->
              <v-card flat width="100%" height="100%">									
                                  <v-card-title class="overline justify-center"> Top</v-card-title>									
                                  <v-list-item class="justify-center">				
                                      <v-btn-toggle v-model="toggletop">                                          									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(0,3)" :key="wrapopt.itemObjKey" @click="Twopropemit('BoxWrapping',EditedTab,wrapopt)">									
                                          <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>
                                     </v-list-item>		
                                     <v-card-title class="overline justify-center"> Middle</v-card-title>
                                    <v-list-item class="justify-center">			
                                      <v-btn-toggle v-model="togglecenter">									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(3,6)" :key="wrapopt.itemObjKey" @click="Twopropemit('BoxWrapping',EditedTab,wrapopt)">									
                                         <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>	
                                     </v-list-item>
                                     <v-card-title class="overline justify-center"> Bottom</v-card-title>
                                    <v-list-item class="justify-center">				
                                    <v-btn-toggle v-model="togglebottom">									
                                        <v-btn x-small v-for="wrapopt in WrappingOptions.slice(6,9)" :key="wrapopt.itemObjKey" @click="Twopropemit('BoxWrapping',EditedTab,wrapopt)">									
                                         <v-icon>{{wrapopt.JustifyIcon}}</v-icon>									
                                      </v-btn>									
                                    </v-btn-toggle>									
                                  </v-list-item>									
                              </v-card>	
          <!-- </v-list-item> mdi-align-horizontal-center mdi-align-horizontal-left mdi-align-horizontal-right mdi-align-vertical-bottom mdi-align-vertical-center mdi-align-vertical-top-->
          </v-card-text>
        </v-card>
</template>





<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import PhotoLibraryViewer from '@/components/SuitePlugins/PhotoLibrary/PhotoLibraryViewer';
export default {
    props: ['System','SystemEntities','EditedTab','Name','GalleryLibraries','WriteupEditorid','WriteupUpdateMethod','SystemAssets','FontOptions','RelatedObj','DynamicWikiData',
  'AdvancedComputedLibraryColors','DataCountCollections','TableEmbedTables','UseGroupAssets',
    'WebFormEntities','WikiType','WikiBooleanProperties','CurrentEntity','FieldTypes','RenderedContentBlocks','EntityFieldsandFeatures'],
    components: {PhotoLibraryViewer},
    data() {
        return {
            WrappingOptions: [
            {
                DisplayName: 'Top Left',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Top Center',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Top Right',
                FlexValue: 'align-self:flex-start',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Center Left',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Absolute Center',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Center Right',
                FlexValue: 'align-self:center',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            {
                DisplayName: 'Bottom Left',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-start',
                JustifyIcon: 'mdi-format-align-left'
            },
            {
                DisplayName: 'Bottom Center',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-center',
                JustifyIcon: 'mdi-format-align-center'
            },
            {
                DisplayName: 'Bottom Right',
                FlexValue: 'align-self:flex-end',
                LayoutValue: 'fill-height justify-end',
                JustifyIcon: 'mdi-format-align-right'
            },
            
        ],
            rules: {
            telnr: value => {
              const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
              return pattern.test(value) || "Not a telephone number.";
            },
            min8Chars: value => value.length >= 8 || "Min. 8 characters",
            required: value => !!value || "Required.",
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              return pattern.test(value) || "Invalid e-mail.";
            },
            url: value => {
              const urlpattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); 
              return urlpattern.test(value) || "Invalid Link.";
            }
          },
        }
    },
    computed:{

    },
    created(){

    },
    methods:{
        AssignGalleryAssets(EditedTab,asset){
          this.Twopropemit('AssignGalleryAssets',EditedTab,asset)  
        },
        TabImageHasSizing(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.Name === "Clipped Tab" ||
            (EditedTab.HasImage &&
              EditedTab.Name !== "Card Block Single" &&
              EditedTab.Name !== "Card Row Single")
        : "";
    },
    TabImageHasClipping(EditedTab) {
      return !EditedTab.DataViewName
        ? EditedTab.Name === "Parallax" ||
            EditedTab.Name === "Image" ||
            EditedTab.HasImage ||
            EditedTab.Name === "Clipped Tab"
        : "";
    },
        WriteupUpdate(WriteupUpdateMethod){
            this.NoPropsemit(WriteupUpdateMethod)
        },
        AssignImage(tab,prop,asset,IsThis){
            this.$emit('AssignImage',tab,prop,asset,IsThis)
        },
        NoPropsemit(MethodName){
            this.$emit('NoPropsemit',MethodName)
        },
        TabPropEmit(MethodName){
            this.$emit('TabPropEmit',MethodName)
        },
        Onepropemit(MethodName,prop){
            console.log(MethodName,prop)
            this.$emit('Onepropemit',MethodName,prop)
        },
        Twopropemit(MethodName,firstprop,secondprop){
            this.$emit('Twopropemit',MethodName,firstprop,secondprop)
            setTimeout(() => {
                this.TypeChange = ''
            }, 50);
        },
        Threepropemit(MethodName,firstprop,secondprop,thirdprop){
            this.$emit('Threepropemit',MethodName,firstprop,secondprop,thirdprop)
        },
    }
}
</script>

<style>

</style>
